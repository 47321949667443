import React from 'react'
import styled from 'styled-components'

import translations from '../../../../translations/keys'
import Modal from '../..//Modal'
import Typography from '../../../ui/Typography'
import { TYPOGRAPHY_NAMES } from '../../../ui/Typography/Typography.types'
import Button from '../../../ui/Button'

import { SuccessChangedIcon } from './assets/SuccessChangedIcon'

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 24px;
`
const StyledIcon = styled(SuccessChangedIcon)`
  margin-bottom: 40px;
`
const StyledTitle = styled(Typography)`
  margin-bottom: 24px;
`
const StyledDescription = styled.p`
  margin-bottom: 126px;
`

interface SuccessChangedModalProps {
  onClose: () => void
}

export const SuccessChangedModal: React.FC<SuccessChangedModalProps> = ({ onClose }) => (
  <Modal onClose={onClose}>
    <StyledContent>
      <StyledIcon />
      <StyledTitle name={TYPOGRAPHY_NAMES.H1}>{translations.SUCCESS}</StyledTitle>
      <StyledDescription>{translations.RESTORE_PASSWORD_SUCCESS}</StyledDescription>
      <Button onClick={onClose} fullWidth>
        {translations.OK}
      </Button>
    </StyledContent>
  </Modal>
)
