import React, { useEffect, useState } from 'react'

import translations from '../../../translations/keys'
import { validateFieldDate } from '../../../utils/documentField/documentField'

import { DocFieldProps } from './DocField.types'
import { DocTextField } from './DocField'

const DATE_MASK = '99.99.9999'

export const DocDateField: React.FC<DocFieldProps> = ({ text, ...props }) => {
  const [error, setError] = useState<string | null>(null)
  const [touched, setTouched] = useState<boolean>(false)

  const handleBlur = () => {
    setTouched(true)
  }

  useEffect(() => {
    if (touched) {
      setError(validateFieldDate(text) ? null : translations.TEMPLATES_DATE_FIELD_INCORRECT)
    }
  }, [text, touched])

  return (
    <DocTextField
      {...props}
      text={text}
      error={error}
      mask={DATE_MASK}
      placeholder={translations.DATE_FIELD_PLACEHOLDER}
      onBlur={handleBlur}
    />
  )
}
