import React from 'react'

import AuthorizationTemplate from '../../components/Authorization/AuthorizationTemplate'

import { Registration as RegistrationPage } from './Registration'

export const Registration = () => (
  <AuthorizationTemplate>
    <RegistrationPage />
  </AuthorizationTemplate>
)
