import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { useDispatchUnmount } from '../useDispatchUnmount'
import { clearFields, fetchFields } from '../../store/templates/fields/fields.actions'

export const useTemplateFieldsFetch = (templateId: string) => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchFields(templateId))
  }, [])

  useDispatchUnmount(clearFields)
}
