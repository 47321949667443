import { Action } from 'redux'

import { FetchFailurePayload, FETCH_STATUSES } from '../../../types/fetch'

export interface RenameState {
  fetchStatus: FETCH_STATUSES
  error: FetchFailurePayload | null
}

export enum RenameActions {
  'RENAME_TEMPLATE' = 'RENAME_TEMPLATE',
  'CLEAR_RENAME_TEMPLATE' = 'CLEAR_RENAME_TEMPLATE',
}

export interface FetchRenameTemplate extends Action {
  payload: { id: string; name: string; isFolder: boolean }
}

export interface FetchRenameTemplateSuccess extends Action {
  payload: { id: string; name: string }
}
