import React, { useEffect, useRef } from 'react'

import { PointerModes } from '../DocViewer/DocViewer.types'

import { CommentProps } from './Comment.types'
import * as Styled from './Comment.styles'

export const Comment: React.FC<CommentProps> = ({
  id,
  x,
  y,
  counter,
  autoFocus = false,
  onClick,
  status,
  ...props
}) => {
  const commentRef = useRef<HTMLDivElement | null>(null)
  const hasComments = Boolean(counter)
  const multipleComments = Boolean(counter && counter > 1)

  const handleClick = (event: React.MouseEvent) => {
    onClick && onClick(id, event.target as HTMLDivElement)
  }

  useEffect(() => {
    if (autoFocus && !hasComments) {
      // setTimeout is necessary because need some time for outside click in ClickOutsideListener
      setTimeout(() => commentRef.current?.click())
    }
  }, [hasComments, autoFocus])

  return (
    <Styled.Wrapper
      data-testid="comment-wrapper"
      data-ignore-collision
      data-id={id}
      data-type={!hasComments && PointerModes.DRAG}
      ref={commentRef}
      style={{ left: x, top: y }}
      onClick={handleClick}
      {...props}
    >
      <Styled.Content data-testid="comment-content">
        {multipleComments && <Styled.GreyIcon data-testid="comment-icon" />}
        <Styled.NormalIcon status={status} data-testid="comment-icon" />
      </Styled.Content>
    </Styled.Wrapper>
  )
}
