import { Form, Formik, FormikProps } from 'formik'
import React, { FC, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { changePhoneInitialValues } from '../../../../../initialValues/changePhoneInitialValues'
import {
  fetchEditProfileChangePhone,
  resetEditProfileChangePhone,
} from '../../../../../store/editProfile/editProfile.actions'
import { closeModal, openModal } from '../../../../../store/modals/modals.actions'
import translations from '../../../../../translations/keys'
import { ChangePhoneFields, ChangePhoneFormValues } from '../../../../../types/changePhone'
import { changePhoneValidationSchema } from '../../../../../validation/changePhone/changePhoneValidationSchema'
import { PhoneIcon } from '../../../../assets/PhoneIcon'
import { EDIT_PROFILE_MODALS } from '../../../../constants/editProfile'
import FormPhone from '../../../../ui/Forms/FormPhone'
import Modal from '../../../Modal'
import { TYPOGRAPHY_COMPONENTS, TYPOGRAPHY_NAMES } from '../../../../ui/Typography/Typography.types'
import {
  editProfilePhoneSuccessSelector,
  editProfilePhoneErrorSelector,
} from '../../../../../store/editProfile/editProfile.selectors'

import { StyledButton, StyledContent, StyledDescription, StyledTitle } from './EditPhoneModal.styles'
import { EditPhoneModalProps } from './EditPhoneModal.types'

export const EditPhoneModal: FC<EditPhoneModalProps> = ({ onClose }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const formRef: React.Ref<FormikProps<ChangePhoneFormValues>> = useRef(null)
  const phoneSuccess = useSelector(editProfilePhoneSuccessSelector)
  const phoneError = useSelector(editProfilePhoneErrorSelector)
  const onSubmit = (values: ChangePhoneFormValues) => {
    dispatch(fetchEditProfileChangePhone(values.phone))
  }
  const renderForm = () => (
    <Form>
      <FormPhone name={ChangePhoneFields.phone} label={t(translations.PHONE_LABEL)} />
      <StyledButton type="submit" fullWidth>
        {t(translations.SEND_CODE)}
      </StyledButton>
    </Form>
  )

  useEffect(() => {
    if (phoneSuccess) {
      dispatch(openModal(EDIT_PROFILE_MODALS.EDIT_PROFILE_CONFIRM_PHONE_MODAL))
      dispatch(closeModal(EDIT_PROFILE_MODALS.EDIT_PROFILE_EDIT_PHONE_MODAL))
    }
  }, [phoneSuccess])
  useEffect(() => {
    const form = formRef.current
    if (form && phoneError) {
      form.setFieldError(ChangePhoneFields.phone, phoneError.lastErrorCode)
    }
  }, [phoneError, formRef.current])
  useEffect(
    () => () => {
      dispatch(resetEditProfileChangePhone())
    },
    []
  )

  return (
    <Modal onClose={onClose}>
      <StyledContent>
        <PhoneIcon />
        <StyledTitle name={TYPOGRAPHY_NAMES.H1} component={TYPOGRAPHY_COMPONENTS.p}>
          {t(translations.CHANGE_PHONE_NUMBER_TITLE)}
        </StyledTitle>
        <StyledDescription name={TYPOGRAPHY_NAMES.body14} component={TYPOGRAPHY_COMPONENTS.p}>
          {t(translations.CHANGE_PHONE_NUMBER_DESCRIPTION)}
        </StyledDescription>
        <Formik
          innerRef={formRef}
          initialValues={changePhoneInitialValues}
          onSubmit={onSubmit}
          validationSchema={changePhoneValidationSchema}
        >
          {renderForm}
        </Formik>
      </StyledContent>
    </Modal>
  )
}
