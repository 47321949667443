import React from 'react'

import { ChevronRightIcon } from '../../assets/icons'
import Button from '../../ui/Button'
import { BUTTON_VARIANTS } from '../../ui/Button/Button.types'
import Box from '../../ui/Box'

import { BreadcrumbsProps } from './Breadcrumbs.types'

export const Breadcrumbs: React.FC<BreadcrumbsProps> = React.memo(({ items, onClick }) => {
  const length = items.length

  if (length <= 1) {
    return null
  }

  return (
    <Box display="flex" alignItems="center">
      {items.map((item, index) => {
        const handleClick = () => {
          onClick(item)
        }

        return (
          <React.Fragment key={item.value}>
            <Button
              textColor="grey"
              onClick={handleClick}
              disabled={index === length - 1}
              variant={BUTTON_VARIANTS.INACTIVE}
              data-testid="breadcrumb"
            >
              {item.name}
            </Button>
            {index !== length - 1 && (
              <Box mr={1} ml={1} display="flex" alignItems="center" data-testid="breadcrumb-chevron">
                <ChevronRightIcon color="grey" />
              </Box>
            )}
          </React.Fragment>
        )
      })}
    </Box>
  )
})
