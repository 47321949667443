import { AddContractFields } from '../types/addContract'
import { CONTRACT_STATUS } from '../types/contracts'

import { DRAFTS_VALUE } from './documents'

export enum CONTRACTS_MODALS {
  UPLOAD_CONTRACT = 'UPLOAD_CONTRACT',
  UPLOAD_CONTRACT_TO = 'UPLOAD_CONTRACT_TO',
  UPLOAD_CONTRACT_CREATE_FOLDER = 'UPLOAD_CONTRACT_CREATE_FOLDER',
  CONTRACTS_CREATE_FOLDER = 'CONTRACTS_CREATE_FOLDER',
  DELETE_CONTRACT = 'DELETE_CONTRACT',
  RENAME_CONTRACT = 'RENAME_CONTRACT',
  CONTRACT_MOVE_TO_FOLDER = 'CONTRACT_MOVE_TO_FOLDER',
  DUPLICATE_CONTRACT = 'DUPLICATE_CONTRACT',
  VIEW_CONTRACT_INFO = 'VIEW_CONTRACT_INFO',
  SAVE_CONTRACT_AS_TEMPLATE = 'SAVE_CONTRACT_AS_TEMPLATE',
  CREATE_FOLDER_FOR_TEMPLATE = 'CREATE_FOLDER_FOR_TEMPLATE',
  CREATE_CONTRACT_FROM_TEMPLATE = 'CREATE_CONTRACT_FROM_TEMPLATE',
  OTP_VERIFICATION = 'OTP_VERIFICATION',
  EKYC_VERIFICATION = 'EKYC_VERIFICATION',
  ADD_NEW_CONTRACT = 'ADD_NEW_CONTRACT',
  OPEN_CONTRACT_AS = 'OPEN_CONTRACT_AS',
}

export const contractsColumns = [
  {
    field: AddContractFields.name,
    name: 'Title',
    searchable: true,
    sortable: true,
  },
]

export const CONTRACTS_FILTERS = [
  CONTRACT_STATUS.SENT,
  CONTRACT_STATUS.VOIDED,
  CONTRACT_STATUS.SIGNED,
  CONTRACT_STATUS.COMPLETED,
  CONTRACT_STATUS.COMMENTED,
  CONTRACT_STATUS.EXPIRED,
]

export const CONTRACT_EXISTS = 'CONTRACT_EXISTS'

export const NEGATIVE_STATUSES = [
  CONTRACT_STATUS.DECLINED,
  CONTRACT_STATUS.VOIDED,
  CONTRACT_STATUS.EXPIRED,
  CONTRACT_STATUS.VERIFICATION_FAILED,
  CONTRACT_STATUS.LEFT_CORRECTION
]

export const DRAFTS_FOLDER = {
  name: 'All Contracts',
  value: DRAFTS_VALUE,
}

export const CLM_IS_NOT_ALLOWED = 'CLM_IS_NOT_ALLOWED'
