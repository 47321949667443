import styled from 'styled-components'

import Box from '../Box'

export const StyledBullet = styled(Box).attrs({
  pl: 2,
})`
  position: relative;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 8px;
    width: 6px;
    height: 6px;
    background-color: ${(props) => props.theme.palette.blue};
    border-radius: 50%;
  }
`
