import { useTranslation } from 'react-i18next'

import { SUBSCRIPTION_YEAR } from '../../constants/subscription'
import translations from '../../translations'
import keys from '../../translations/keys'

export const preparePrice = (term: number, price: number, useKeys?: boolean) => {
  const { t } = useTranslation()
  const label = term === SUBSCRIPTION_YEAR ? translations.YEAR : translations.MO
  const keysLabel = term === SUBSCRIPTION_YEAR ? t(keys.YEAR) : t(keys.MO)
  return `$${price}/${useKeys ? keysLabel : label}`
}
