import React from 'react'
import { Switch, Redirect } from 'react-router-dom'

import { SubscriptionFeatures } from '../../types/subscription'
import { TEMPLATES_CATEGORIES } from '../../types/templates'
import { location } from '../../utils/location'
import {
  Templates,
  TemplatePreview,
  TemplateAddRecipients,
  TemplateRecipientsDiagram,
  TemplatePlacement,
  TemplatesFillRecipients,
  TemplateFillFields,
} from '../pages'
import { TemplateView } from '../pages/TemplateView/TemplateView'

import { PrivateRoute } from './components/PrivateRoute'

const TemplatesRoutes = () => (
  <Switch>
    <PrivateRoute exact path={location.templatesAllUrl()} feature={SubscriptionFeatures.FEATURE_USER_TEMPLATES}>
      <Templates key="all-templates" />
    </PrivateRoute>
    <PrivateRoute exact path={location.templatesStarredUrl()} feature={SubscriptionFeatures.FEATURE_USER_TEMPLATES}>
      <Templates key="starred-templates" category={TEMPLATES_CATEGORIES.STARRED} />
    </PrivateRoute>
    <PrivateRoute exact path={location.templatesDocumentsUrl()} feature={SubscriptionFeatures.FEATURE_USER_TEMPLATES}>
      <Templates key="documents-templates" category={TEMPLATES_CATEGORIES.WITH_DOCUMENTS} />
    </PrivateRoute>
    <PrivateRoute exact path={location.templatesProceduresUrl()} feature={SubscriptionFeatures.FEATURE_USER_TEMPLATES}>
      <Templates key="procedures-templates" category={TEMPLATES_CATEGORIES.PROCEDURES} />
    </PrivateRoute>
    <PrivateRoute exact path={location.templatesPreviewUrl()}>
      <TemplatePreview />
    </PrivateRoute>
    <PrivateRoute
      exact
      path={location.templatesClmOnlyAddRecipientsUrl()}
      feature={SubscriptionFeatures.FEATURE_USER_TEMPLATES}
    >
      <TemplateAddRecipients />
    </PrivateRoute>
    <PrivateRoute
      exact
      path={location.templatesAddRecipientsUrl()}
      feature={SubscriptionFeatures.FEATURE_USER_TEMPLATES}
    >
      <TemplateAddRecipients />
    </PrivateRoute>
    <PrivateRoute
      exact
      path={location.templatesRecipientsDiagramUrl()}
      feature={SubscriptionFeatures.FEATURE_USER_TEMPLATES}
    >
      <TemplateRecipientsDiagram />
    </PrivateRoute>
    <PrivateRoute exact path={location.templatesPlacementUrl()} feature={SubscriptionFeatures.FEATURE_USER_TEMPLATES}>
      <TemplatePlacement />
    </PrivateRoute>
    <PrivateRoute exact path={location.templatesViewUrl()} feature={SubscriptionFeatures.FEATURE_USER_TEMPLATES}>
      <TemplateView />
    </PrivateRoute>
    <PrivateRoute
      exact
      path={location.templatesFillRecipientsUrl()}
      feature={SubscriptionFeatures.FEATURE_USER_TEMPLATES}
    >
      <TemplatesFillRecipients />
    </PrivateRoute>
    <PrivateRoute exact path={location.templatesFillFieldsUrl()} feature={SubscriptionFeatures.FEATURE_USER_TEMPLATES}>
      <TemplateFillFields />
    </PrivateRoute>
    <Redirect to={location.error404Url()} />
  </Switch>
)

export default TemplatesRoutes
