import { FetchFailureAction, FETCH_STATUSES } from '../../../types/fetch'
import { createReducer } from '../../createReducer'

import * as ACTIONS from './saveAsTemplate.actions'
import * as TYPES from './saveAsTemplate.types'

type Actions = FetchFailureAction | TYPES.FetchSaveAsTemplateSuccess

export const initialState: TYPES.SaveAsTemplateState = {
  id: null,
  fetchStatus: FETCH_STATUSES.IDLE,
  error: null,
}

export const saveAsTemplate = createReducer<TYPES.SaveAsTemplateState, Actions>(initialState, {
  [ACTIONS.fetchSaveAsTemplateTypes.request]: (state) => ({
    ...state,
    fetchStatus: FETCH_STATUSES.REQUEST,
  }),
  [ACTIONS.fetchSaveAsTemplateTypes.success]: (state, action) => ({
    ...state,
    id: (action as TYPES.FetchSaveAsTemplateSuccess).payload.id,
    fetchStatus: FETCH_STATUSES.SUCCESS,
  }),
  [ACTIONS.fetchSaveAsTemplateTypes.failure]: (state, action) => ({
    ...state,
    fetchStatus: FETCH_STATUSES.FAILURE,
    error: (action as FetchFailureAction).payload,
  }),
  [TYPES.SaveAsTemplateActions.CLEAN_SAVE_CONTRACT_AS_TEMPLATE]: () => ({ ...initialState }),
})
