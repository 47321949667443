import { useEffect, useMemo, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { FormikProps } from 'formik'

import translations from '../../../../translations/keys'
import { location } from '../../../../utils/location'
import { FETCH_STATUSES } from '../../../../types/fetch'
import { ContractRouteParams } from '../../../types/signing'
import { DocumentMessageForm } from '../../../../types/documents'
import { useShowFetchError } from '../../../hooks/useShowFetchError'
import { fetchGeneratePlacement } from '../../../../store/contracts/placement/placement.actions'
import {
  placementFetchGenerateSelector,
  placementFetchSelector,
  placementShouldRegenerateSelector,
  placementSortedSelector,
} from '../../../../store/contracts/placement/placement.selectors'
import { fetchSaveContractMessage } from '../../../../store/contracts/message/message.actions'
import {
  messageIsSuccessSelector,
  messageFetchStatusSelector,
  messageErrorSelector,
} from '../../../../store/contracts/message/message.selectors'
import { BUTTON_VARIANTS } from '../../../ui/Button/Button.types'
import { PlayIcon } from '../../../assets/icons'
import { recipientsSortedSelector } from '../../../../store/contracts/recipients/recipients.selectors'
import { openModal } from '../../../../store/modals/modals.actions'
import { COMMON_MODALS_IDS } from '../../../ui/Modals/CommonModals/CommonModals.constants'
import { NOTIFICATION_MODAL_TYPES } from '../../../ui/Modals/NotificationModal/NotificationModal.constants'

export const useButtons = (formRef: React.MutableRefObject<FormikProps<DocumentMessageForm> | null>) => {
  const dispatch = useDispatch()
  const { contractId } = useParams<ContractRouteParams>()
  const [redirectUrl, setRedirectUrl] = useState(location.contractsPlacementUrl(contractId))
  const history = useHistory()
  const placementSorted = useSelector(placementSortedSelector)
  const placementStatus = useSelector(placementFetchSelector)
  const generateStatus = useSelector(placementFetchGenerateSelector)
  const saveMessageSuccess = useSelector(messageIsSuccessSelector)
  const saveMessageStatus = useSelector(messageFetchStatusSelector)
  const saveMessageError = useSelector(messageErrorSelector)
  const sorted = useSelector(recipientsSortedSelector)
  const loading = saveMessageStatus === FETCH_STATUSES.REQUEST || generateStatus.status === FETCH_STATUSES.REQUEST
  const emptyRecipients = sorted.length === 0

  // TODO: remove when API is ready
  const shouldRegenerate = useSelector(placementShouldRegenerateSelector)
  const emptyPlacement =
    placementStatus.status === FETCH_STATUSES.FAILURE ||
    (placementStatus.status === FETCH_STATUSES.SUCCESS && placementSorted.length === 0)

  const handleSubmit = (values: DocumentMessageForm) => {
    dispatch(fetchSaveContractMessage(contractId, values))
  }

  const buttons = useMemo(
    () => [
      {
        label: translations.SAVE_AND_CLOSE,
        disabled: loading,
        MobileIcon: PlayIcon,
        variant: BUTTON_VARIANTS.SECONDARY,
        onClick: () => {
          formRef.current?.handleSubmit()
          setRedirectUrl(location.contractsAllUrl())
        },
      },
      {
        label: translations.NEXT,
        disabled: loading,
        MobileIcon: PlayIcon,
        onClick: () => {
          if (emptyRecipients) {
            dispatch(
              openModal(COMMON_MODALS_IDS.NOTIFICATION, {
                type: NOTIFICATION_MODAL_TYPES.WARNING,
                description: translations.CANT_START_CYCLE,
              })
            )
            return
          }
          formRef.current?.handleSubmit()
          if (emptyPlacement || shouldRegenerate) {
            dispatch(fetchGeneratePlacement(contractId))
          } else {
            setRedirectUrl(location.contractsPlacementUrl(contractId))
          }
        },
      },
    ],
    [loading, contractId, placementSorted, placementStatus, shouldRegenerate, emptyPlacement, emptyRecipients]
  )

  useEffect(() => {
    if (saveMessageSuccess && generateStatus.status !== FETCH_STATUSES.REQUEST) {
      history.push(redirectUrl)
    }
  }, [saveMessageStatus, generateStatus])

  useShowFetchError(generateStatus.status, generateStatus.error)
  useShowFetchError(saveMessageStatus, saveMessageError)

  return { buttons, handleSubmit }
}
