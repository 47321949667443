import { combineEpics, Epic, ofType } from 'redux-observable'
import { catchError, map, mergeMap, takeUntil } from 'rxjs/operators'

import { profileService } from '../../api'
import { catchFetchError } from '../../utils/catchFetchError'

import * as ACTIONS from './changePassword.actions'

const fetchChangePassword: Epic = (action$) =>
  action$.pipe(
    ofType(ACTIONS.fetchChangePasswordTypes.request),
    mergeMap(({ payload }) =>
      profileService.fetchResetPassword(payload.body).pipe(
        map(() => ACTIONS.fetchChangePasswordSuccess()),
        catchError(catchFetchError(ACTIONS.fetchChangePasswordFailure)),
        takeUntil(
          action$.pipe(ofType(ACTIONS.fetchChangePasswordTypes.success, ACTIONS.fetchChangePasswordTypes.failure))
        )
      )
    )
  )

export const changePasswordEpics = combineEpics(fetchChangePassword)
