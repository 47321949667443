import { DocFieldWithText } from './documentField'

export interface CreateContractFromTemplateBody {
  templateId: string
  name: string
  folderName: string
  fields: DocFieldWithText[]
}

export enum CreateContractFromTemplateFields {
  templateId = 'templateId',
}

export interface CreateContractFromTemplateForm {
  templateId: string
}
