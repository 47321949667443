import styled from 'styled-components'

export const StyledCalendarWrapper = styled.div<{ isSelected?: boolean }>`
  overflow: auto;
  background-color: ${(props) => props.theme.palette.white};
  border: ${(props) => `1px solid ${props.theme.palette.strokeGrey}`};

  border-radius: 4px;
  width: 332px;

  .react-calendar {
    border: 0;
  }

  .react-calendar__viewContainer {
    padding: 0 12px 16px;
  }

  .react-calendar__navigation {
    height: auto;
    padding: 24px 24px 22px;
    margin-bottom: 0;
    align-items: center;

    button {
      &:hover,
      &:focus,
      &:disabled {
        background: ${(props) => props.theme.palette.white};
      }
    }

    .react-calendar__navigation__arrow {
      padding: 0;
      min-width: 16px;
      height: 16px;
    }
  }

  .react-calendar__navigation__label {
    color: ${(props) => props.theme.palette.textColor};
    font-family: ${(props) => props.theme.textStyles.body14['font-family']};
    font-size: ${(props) => props.theme.textStyles.body14['font-size']};
    line-height: ${(props) => props.theme.textStyles.body14['line-height']};
    height: 20px;

    &:hover {
      background-color: ${(props) => props.theme.palette.white};
    }
  }

  .react-calendar__tile {
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    padding: 0 8px;
    border-radius: 50%;
    height: 24px;
    width: 24px;
    box-sizing: border-box;
    margin: 8px 0 4px;

    :hover {
      background-color: transparent;

      abbr {
        background: ${(props) => props.theme.palette.blue};
        color: ${(props) => props.theme.palette.white};
        border-radius: 50%;
      }
    }

    &:disabled {
      background-color: transparent;
      color: ${(props) => props.theme.palette.grey};
      &:hover {
        abbr {
          background-color: transparent;
          color: ${(props) => props.theme.palette.grey};
        }
      }
    }

    abbr {
      font-family: ${(props) => props.theme.textStyles.body14['font-family']};
      font-size: ${(props) => props.theme.textStyles.body14['font-size']};
      line-height: 24px;
      display: inline-block;
      width: 24px;
      height: 24px;
    }
  }

  .react-calendar__tile--now {
    background-color: transparent;
    &:hover {
      background-color: transparent;
    }
  }

  .react-calendar__tile--active {
    background-color: transparent;
    border-radius: 50%;

    abbr {
      background: ${(props) => props.theme.palette.blue};
      color: ${(props) => props.theme.palette.white};
      border-radius: 50%;
    }

    &:hover {
      background: transparent;

      abbr {
        background: ${(props) => props.theme.palette.blueDark};
      }
    }
  }

  .react-calendar__month-view__days__day--neighboringMonth {
    color: ${(props) => props.theme.palette.grey};

    &.react-calendar__month-view__days__day--weekend {
      color: ${(props) => props.theme.palette.grey};
    }
  }

  .react-calendar__month-view__days__day--weekend {
    color: ${(props) => props.theme.palette.textColor};
  }

  .react-calendar__month-view__weekdays__weekday {
    display: flex;
    justify-content: center;
    background-color: none;

    abbr {
      text-decoration: none;
      color: ${(props) => props.theme.palette.grey};
      font-family: ${(props) => props.theme.palette.fontFamily};
      font-size: 12px;
      line-height: 18px;
      font-weight: 400;
      text-transform: none;
    }
  }
`
