import React from 'react'
import styled from 'styled-components'

import { DEFAULT_DATE_FORMAT } from '../../constants/date'
import highlightText from '../../utils/highlightText'
import { HighlightedText } from '../ui/Table/Table.styles'
import { TableData } from '../ui/Table/Table.types'
import Typography from '../ui/Typography'
import { TYPOGRAPHY_COMPONENTS, TYPOGRAPHY_NAMES } from '../ui/Typography/Typography.types'
import Box from '../ui/Box'
import { getFormattedDate } from '../../utils/date'
import { Contract } from '../../types/contracts'
import { Template } from '../../types/templates'
import translations from '../../translations/keys'

const StyledFullName = styled(Typography).attrs({
  name: TYPOGRAPHY_NAMES.body14,
  component: TYPOGRAPHY_COMPONENTS.p,
})`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`

export const renderDate = (key: 'createDate' | 'updateDate', userEmail?: string) => (
  data: TableData,
  highlightedText?: string
) => {
  const fieldData = data as Contract | Template
  const field = fieldData[key]

  return field ? (
    <Box width="100%" pr={1}>
      <StyledFullName>
        {userEmail === field.userEmail
          ? translations.ME
          : highlightText(field.userName, highlightedText, HighlightedText, 'span')}
      </StyledFullName>
      <Typography name={TYPOGRAPHY_NAMES.body14} color="grey">
        {getFormattedDate(new Date(field.date), DEFAULT_DATE_FORMAT)}
      </Typography>
    </Box>
  ) : null
}
