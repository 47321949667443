import React, { createContext } from 'react'

import useProvideNavControls from '../hooks/useProvideNavControls'

interface NavControlsContext {
  sidebarOpen: boolean
  setSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>
  mainmenuOpen: boolean
  setMainmenuOpen: React.Dispatch<React.SetStateAction<boolean>>
}

export const navControlsContext = createContext<NavControlsContext>({
  sidebarOpen: false,
  setSidebarOpen: () => {},
  mainmenuOpen: false,
  setMainmenuOpen: () => {},
})

const NavControlsProvider: React.FC = ({ children }) => {
  const auth = useProvideNavControls()

  return <navControlsContext.Provider value={auth}>{children}</navControlsContext.Provider>
}

export default NavControlsProvider
