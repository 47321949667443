import styled from 'styled-components'

import { LogoIcon } from '../../assets/LogoIcon'
import Button from '../Button'
import { BUTTON_VARIANTS } from '../Button/Button.types'

export const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
  position: fixed;
  padding: 5px 23px 5px;
  width: 100%;
  height: 50px;
  background-color: ${({ theme }) => theme.palette.blueExtraLight};
`

export const CloseButton = styled(Button).attrs({ variant: BUTTON_VARIANTS.TEXT })``

export const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: 15px;
`

export const Logo = styled(LogoIcon)`
  height: 34px;
`

export const StyledTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  line-height: 15px;
  width: 100%;
`
