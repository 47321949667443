import { Action } from 'redux'

import { CreateTemplateFromContractBody } from '../../../types/createTemplateFromContract'
import { FetchFailurePayload, FETCH_STATUSES } from '../../../types/fetch'

export interface CreateFromContractState {
  id: string | null
  fetchStatus: FETCH_STATUSES
  error: FetchFailurePayload | null
}

export enum CreateFromContractActions {
  'FETCH_CREATE_TEMPLATE_FROM_CONTRACT' = 'FETCH_CREATE_TEMPLATE_FROM_CONTRACT',
  'CLEAN_CREATE_TEMPLATE_FROM_CONTRACT' = 'CLEAN_CREATE_TEMPLATE_FROM_CONTRACT',
}

export interface FetchCreateTemplateFromContract extends Action {
  payload: { body: CreateTemplateFromContractBody }
}

export interface FetchCreateTemplateFromContractSuccess extends Action {
  payload: { id: string }
}
