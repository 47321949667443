import React from 'react'

import { SvgIconProps } from '../../ui/SvgIcon/SvgIcon.types'
import SvgIcon from '../../ui/SvgIcon'

export const IdIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <path d="M21.75 3.75H2.25C1.83516 3.75 1.5 4.08516 1.5 4.5V19.5C1.5 19.9148 1.83516 20.25 2.25 20.25H21.75C22.1648 20.25 22.5 19.9148 22.5 19.5V4.5C22.5 4.08516 22.1648 3.75 21.75 3.75ZM20.8125 18.5625H3.1875V5.4375H20.8125V18.5625ZM14.3039 11.1562H17.1961C17.2266 11.1562 17.25 11.0719 17.25 10.9688V9.84375C17.25 9.74062 17.2266 9.65625 17.1961 9.65625H14.3039C14.2734 9.65625 14.25 9.74062 14.25 9.84375V10.9688C14.25 11.0719 14.2734 11.1562 14.3039 11.1562ZM14.4164 14.5312H18.7687C18.8602 14.5312 18.9352 14.4469 18.9352 14.3438V13.2188C18.9352 13.1156 18.8602 13.0312 18.7687 13.0312H14.4164C14.325 13.0312 14.25 13.1156 14.25 13.2188V14.3438C14.25 14.4469 14.325 14.5312 14.4164 14.5312ZM5.25 15.7734H6.27891C6.37734 15.7734 6.45703 15.6961 6.46406 15.5977C6.55312 14.4141 7.54219 13.4766 8.74219 13.4766C9.94219 13.4766 10.9313 14.4141 11.0203 15.5977C11.0273 15.6961 11.107 15.7734 11.2055 15.7734H12.2344C12.2598 15.7735 12.285 15.7683 12.3084 15.7583C12.3317 15.7483 12.3528 15.7337 12.3704 15.7152C12.3879 15.6968 12.4015 15.675 12.4104 15.6512C12.4192 15.6274 12.4231 15.602 12.4219 15.5766C12.3562 14.3273 11.6719 13.2398 10.6734 12.6211C11.1137 12.1371 11.357 11.5059 11.3555 10.8516C11.3555 9.40078 10.1859 8.22656 8.74453 8.22656C7.30313 8.22656 6.13359 9.40078 6.13359 10.8516C6.13359 11.5336 6.39141 12.1523 6.81562 12.6211C6.30733 12.9361 5.88283 13.3694 5.57836 13.8841C5.27389 14.3987 5.09851 14.9794 5.06719 15.5766C5.05781 15.6844 5.14219 15.7734 5.25 15.7734ZM8.74219 9.63281C9.41016 9.63281 9.95391 10.1789 9.95391 10.8516C9.95391 11.5242 9.41016 12.0703 8.74219 12.0703C8.07422 12.0703 7.53047 11.5242 7.53047 10.8516C7.53047 10.1789 8.07422 9.63281 8.74219 9.63281Z" />
  </SvgIcon>
)
