import React from 'react'
import { Switch, Redirect } from 'react-router-dom'

import { CONTRACT_STATUS } from '../../types/contracts'
import { location } from '../../utils/location'
import { Contracts, ContractPreview } from '../pages'
import { ContractAddRecipients } from '../pages/ContractAddRecipients'
import { ContractOwnerView } from '../pages/ContractOwnerView'
import { ContractPlacement } from '../pages/ContractPlacement/ContractPlacement'
import { ContractRecipientsDiagram } from '../pages/ContractRecipientsDiagram'
import { ContractSign } from '../pages/ContractSign'

import { PrivateRoute } from './components/PrivateRoute'

const ContractsRoutes = () => (
  <Switch>
    <PrivateRoute exact path={location.contractsAllUrl()}>
      <Contracts key="all-contracts" />
    </PrivateRoute>
    <PrivateRoute exact path={location.contractsReceivedUrl()}>
      <Contracts key="received-contracts" category="received" />
    </PrivateRoute>
    <PrivateRoute exact path={location.contractsSentUrl()}>
      <Contracts key="sent-contracts" category={CONTRACT_STATUS.SENT} />
    </PrivateRoute>
    <PrivateRoute exact path={location.contractsDraftsUrl()}>
      <Contracts key="drafts-contracts" category={CONTRACT_STATUS.DRAFT} />
    </PrivateRoute>
    <PrivateRoute exact path={location.contractsDeletedUrl()}>
      <Contracts key="deleted-contracts" category={CONTRACT_STATUS.DELETED} />
    </PrivateRoute>
    <PrivateRoute exact path={location.contractsPreviewUrl()}>
      <ContractPreview />
    </PrivateRoute>
    <PrivateRoute exact path={location.contractsAddRecipientsUrl()}>
      <ContractAddRecipients />
    </PrivateRoute>
    <PrivateRoute exact path={location.contractsRecipientsDiagramUrl()}>
      <ContractRecipientsDiagram />
    </PrivateRoute>
    <PrivateRoute exact path={location.contractsPlacementUrl()}>
      <ContractPlacement />
    </PrivateRoute>
    <PrivateRoute exact path={location.contractsSignUrl()}>
      <ContractSign />
    </PrivateRoute>
    <PrivateRoute exact path={location.contractsOwnerViewUrl()}>
      <ContractOwnerView />
    </PrivateRoute>
    <Redirect to={location.error404Url()} />
  </Switch>
)

export default ContractsRoutes
