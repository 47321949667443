import { Form } from 'formik'
import styled from 'styled-components'

import Typography from '../../ui/Typography'

const LIST_OFFSET = '16px'

export const StyledTitle = styled(Typography)`
  margin-bottom: 24px;
`
export const StyledRequirementsInfo = styled(Typography)`
  margin-bottom: 16px;
`
export const StyledItem = styled(Typography)`
  position: relative;
  padding-left: ${LIST_OFFSET};
  line-height: 28px;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 6px;
    height: 6px;
    background-color: ${(props) => props.theme.palette.blue};
    border-radius: 50%;
  }
`
export const StyledAtLeastOne = styled(Typography)`
  padding-left: ${LIST_OFFSET};
  line-height: 28px;
`
export const StyledForm = styled(Form)`
  margin-top: 40px;
  width: 320px;

  ${({ theme }) => `@media ${theme.devices.laptop} {
    width: 224px;
  }`}

  ${({ theme }) => `@media ${theme.devices.mobile} {
    width: 100%;
  }`}
`
export const StyledButtonWrapper = styled.div`
  margin-top: 16px;
  width: 148px;

  ${({ theme }) => `@media ${theme.devices.mobile} {
    width: 100%;
  }`}
`
