import { combineEpics, Epic, ofType } from 'redux-observable'
import { catchError, debounceTime, map, mergeMap, takeUntil } from 'rxjs/operators'

import { templateService } from '../../../api'
import { catchFetchError } from '../../../utils/catchFetchError'
import { prepareTemplatesList } from '../../../utils/templates/prepareTemplatesList'
import { authUserSelector } from '../../auth/auth.selectors'

import * as ACTIONS from './list.actions'

const FETCH_DEBOUNCE_TIME = 500

export const fetchListData: Epic = (action$, state$) =>
  action$.pipe(
    ofType(ACTIONS.fetchTemplatesListDataTypes.request),
    debounceTime(FETCH_DEBOUNCE_TIME),
    mergeMap(({ payload }) =>
      templateService.fetchTemplatesList(payload.params).pipe(
        map((response) =>
          ACTIONS.fetchTemplatesListDataSuccess(prepareTemplatesList(response, authUserSelector(state$.value)))
        ),
        catchError(catchFetchError(ACTIONS.fetchTemplatesListDataFailure)),
        takeUntil(
          action$.pipe(ofType(ACTIONS.fetchTemplatesListDataTypes.success, ACTIONS.fetchTemplatesListDataTypes.failure))
        )
      )
    )
  )

export const listEpics = combineEpics(fetchListData)
