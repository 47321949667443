import styled from 'styled-components'

import Box from '../../../../ui/Box'

export const StyledTitle = styled(Box).attrs({
  pb: 3,
  display: 'flex',
  justifyContent: 'space-between',
})`
  border-bottom: ${({ theme }) => `1px solid ${theme.palette.strokeGrey}`};
`

export const StyledOption = styled(Box).attrs({
  height: 56,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
})`
  border-bottom: ${({ theme }) => `1px solid ${theme.palette.strokeGrey}`};
`
