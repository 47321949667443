import React from 'react'

import MainTemplate from '../../components/Main/MainTemplate'

import { SelectSubscription as SelectSubscriptionView } from './SelectSubscription'

export const SelectSubscription = () => (
  <MainTemplate simple>
    <SelectSubscriptionView />
  </MainTemplate>
)
