import { FetchFailureAction, FetchFailurePayload } from '../../../types/fetch'
import { Notification } from '../../../types/notifications'
import { createFetchActionTypes } from '../../../utils/createFetchActionTypes'

import * as TYPES from './delete.types'

export const fetchDeleteTemplateTypes = createFetchActionTypes(TYPES.DeleteActions.DELETE_TEMPLATE)

export const fetchDeleteTemplate = (id: string, isFolder = false): TYPES.FetchDeleteTemplate => ({
  type: fetchDeleteTemplateTypes.request,
  payload: { id, isFolder },
})
export const fetchDeleteTemplateSuccess = (notification: Notification): TYPES.FetchDeleteTemplateSuccess => ({
  type: fetchDeleteTemplateTypes.success,
  payload: { notification },
})
export const fetchDeleteTemplateFailure = (payload: FetchFailurePayload): FetchFailureAction => ({
  type: fetchDeleteTemplateTypes.failure,
  payload,
})

export const clearDeleteTemplate = () => ({
  type: TYPES.DeleteActions.CLEAR_DELETE_TEMPLATE,
})
