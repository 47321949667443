import React from 'react'

import ErrorBoundary from '../../components/ErrorBoundary/ErrorBoundary'

import { ContractUnregisteredSign as ContractUnregisteredSignPage } from './ContractUnregisteredSign'

export const ContractUnregisteredSign = () => (
  <ErrorBoundary>
    <ContractUnregisteredSignPage />
  </ErrorBoundary>
)
