export const EXPLAIN_DECLINE = 'EXPLAIN_DECLINE'

export enum CONTRACT_MODALS {
  CONTRACT_PROGRESS = 'CONTRACT_PROGRESS',
  CONTRACT_RESEND = 'CONTRACT_RESEND',
  CONTRACT_COMMENTS = 'CONTRACT_COMMENTS',
  CONTRACT_HISTORY = 'CONTRACT_HISTORY',
  CONTRACT_SAVE_ANNOTATION_PROMPT = 'CONTRACT_SAVE_ANNOTATION_PROMPT',
  DOWNLOAD_CONTRACT = 'DOWNLOAD_CONTRACT',
  CONTRACT_DOWNLOAD_PROGRESS = 'CONTRACT_DOWNLOAD_PROGRESS',
  DECLINE_TO_SIGN_CONTRACT = 'DECLINE_TO_SIGN_CONTRACT',
  SIGNATURE_SEQUENCE_SELECT = 'SIGNATURE_SEQUENCE_SELECT',
  CONFIRM_ACCEPT_REJECT = 'CONFIRM_ACCEPT_REJECT',
  ROLE_SELECT = 'ROLE_SELECT',
  COMMENTS_MODEL = 'COMMENTS_MODEL',
}
