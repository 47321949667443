import { ContractHistoryAction } from '../types/contractHistory'

export const historyActionsWithoutDocument: ContractHistoryAction[] = [
  ContractHistoryAction.COMMENTED,
  ContractHistoryAction.CROSSED_OVER,
  ContractHistoryAction.LEFT_CORRECTION,
  ContractHistoryAction.ACCEPTED,
  ContractHistoryAction.REJECTED,
  ContractHistoryAction.CANCELLED_ACCEPTATION,
  ContractHistoryAction.CANCELLED_REJECTION,
  ContractHistoryAction.CLEARED_CORRECTIONS,
]
