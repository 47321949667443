import { Epic, combineEpics, ofType } from 'redux-observable'
import { of } from 'rxjs'
import { catchError, delay, map, mergeMap, switchMap, takeUntil } from 'rxjs/operators'

import translations from '../../../translations'
import { fieldService } from '../../../api'
import { catchFetchError } from '../../../utils/catchFetchError'
import {
  prepareFieldsForStore,
  prepareFieldsForRequest,
  getFirstEmptyField,
  validateAllFields,
} from '../../../utils/documentField'
import { COMMON_MODALS_IDS } from '../../../web/ui/Modals/CommonModals/CommonModals.constants'
import { NOTIFICATION_MODAL_TYPES } from '../../../web/ui/Modals/NotificationModal/NotificationModal.constants'
import { openModal } from '../../modals/modals.actions'

import * as ACTIONS from './fields.actions'
import { fieldsByIdSelector, fieldsByPagesSelector } from './fields.selectors'

const SCROLL_DELAY = 100

const fetchFields: Epic = (action$) =>
  action$.pipe(
    ofType(ACTIONS.fetchFieldsTypes.request),
    mergeMap(({ payload }) =>
      fieldService.fetchFields(payload.templateId).pipe(
        map((response) => ACTIONS.fetchFieldsSuccess(...prepareFieldsForStore(response))),
        catchError(catchFetchError(ACTIONS.fetchFieldsFailure)),
        takeUntil(action$.pipe(ofType(ACTIONS.fetchFieldsTypes.success, ACTIONS.fetchFieldsTypes.failure)))
      )
    )
  )

const fetchUpdateFields: Epic = (action$) =>
  action$.pipe(
    ofType(ACTIONS.fetchUpdateFieldsTypes.request),
    switchMap(({ payload }) =>
      fieldService.fetchAddFields(payload.templateId, prepareFieldsForRequest(payload.byId)).pipe(
        map(({ response }) => ACTIONS.fetchUpdateFieldsSuccess(response)),
        catchError(catchFetchError(ACTIONS.fetchUpdateFieldsFailure)),
        takeUntil(action$.pipe(ofType(ACTIONS.fetchUpdateFieldsTypes.success, ACTIONS.fetchUpdateFieldsTypes.failure)))
      )
    )
  )

const scrollToEmptyField: Epic = (action$, state$) =>
  action$.pipe(
    ofType(ACTIONS.scrollToEmptyFieldTypes.request),
    delay(SCROLL_DELAY),
    switchMap(() => {
      const nextId = getFirstEmptyField(fieldsByIdSelector(state$.value), fieldsByPagesSelector(state$.value))

      if (nextId) {
        return of(ACTIONS.scrollToEmptyFieldSuccess(nextId))
      }

      const error = validateAllFields(fieldsByIdSelector(state$.value))

      if (error) {
        return of(
          openModal(COMMON_MODALS_IDS.NOTIFICATION, {
            type: NOTIFICATION_MODAL_TYPES.WARNING,
            description: translations.TEMPLATES_INCORRECT_FIELDS,
          })
        )
      }

      return of(
        openModal(COMMON_MODALS_IDS.NOTIFICATION, {
          type: NOTIFICATION_MODAL_TYPES.SUCCESS,
          description: translations.TEMPLATES_FIELDS_FILLED,
        })
      )
    })
  )

export const fieldsEpics = combineEpics(fetchFields, fetchUpdateFields, scrollToEmptyField)
