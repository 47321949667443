import { FetchFailureAction, FetchFailurePayload } from '../../../types/fetch'
import { createFetchActionTypes } from '../../../utils/createFetchActionTypes'

import * as TYPES from './void.types'

export const fetchVoidContractTypes = createFetchActionTypes(TYPES.VoidActions.FETCH_VOID_CONTRACT)

export const fetchVoidContract = (contractId: string): TYPES.FetchVoidContract => ({
  type: fetchVoidContractTypes.request,
  payload: { contractId },
})

export const fetchVoidContractSuccess = (contractId = ''): TYPES.FetchVoidContractSuccess => ({
  type: fetchVoidContractTypes.success,
  payload: { contractId },
})

export const fetchVoidContractFailure = (payload: FetchFailurePayload): FetchFailureAction => ({
  type: fetchVoidContractTypes.failure,
  payload,
})

export const cleanVoidContract = () => ({
  type: TYPES.VoidActions.CLEAN_VOID_CONTRACT,
})
