import React from 'react'
import styled from 'styled-components'

import { GuidesProps } from './Guides.types'

const FULL = 100
const HOR_FACTOR = 2
const VER_FACTOR = 4
const SYMMETRIC_FACTOR = 3

const StyledWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  transform: translateZ(0);
`
const StyledLine = styled.div`
  position: absolute;
  background-color: ${({ theme }) => theme.palette.blueOcean};
`

export const Guides: React.FC<GuidesProps> = ({ asymmetric = false }) => {
  const horWidth = FULL * (asymmetric ? HOR_FACTOR : SYMMETRIC_FACTOR)
  const verHeight = FULL * (asymmetric ? VER_FACTOR : SYMMETRIC_FACTOR)
  const topLeft = { top: -1, left: `${-(horWidth - FULL) / 2}%`, width: `${horWidth}%`, height: 1 }
  const topRight = { top: `${-(verHeight - FULL) / 2}%`, right: -1, width: 1, height: `${verHeight}%` }
  const bottomRight = { top: `${FULL}%`, left: `${-(horWidth - FULL) / 2}%`, width: `${horWidth}%`, height: 1 }
  const bottomLeft = { top: `${-(verHeight - FULL) / 2}%`, left: -1, width: 1, height: `${verHeight}%` }

  return (
    <StyledWrapper>
      <StyledLine style={topLeft} />
      <StyledLine style={topRight} />
      <StyledLine style={bottomRight} />
      <StyledLine style={bottomLeft} />
    </StyledWrapper>
  )
}
