import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { clearContacts, fetchContacts } from '../../store/contacts/contacts.actions'
import * as SELECTORS from '../../store/contacts/contacts.selectors'
import { useDispatchUnmount } from '../useDispatchUnmount'

export const useContactsFetch = () => {
  const dispatch = useDispatch()
  const sorting = useSelector(SELECTORS.contactsSortingSelector)
  const searchText = useSelector(SELECTORS.contactsSearchTextSelector)
  const offset = useSelector(SELECTORS.contactsOffsetSelector)
  const limit = useSelector(SELECTORS.contactsLimitSelector)

  useEffect(() => {
    dispatch(
      fetchContacts({
        offset,
        limit,
        orderByField: sorting.field,
        order: sorting.direction,
        searchedString: searchText,
      })
    )
  }, [offset, limit, sorting, searchText])

  useDispatchUnmount(clearContacts)
}
