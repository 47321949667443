import * as colors from '../../../themes/colors'

export enum TYPOGRAPHY_NAMES {
  H1 = 'H1',
  H2 = 'H2',
  H3 = 'H3',
  H4 = 'H4',
  body14 = 'body14',
  body16 = 'body16',
  bodySmall12 = 'bodySmall12',
  bodySmall6 = 'bodySmall6',
  bold14 = 'bold14',
  bold16 = 'bold16',
  tableColumn = 'tableColumn',
  buttons_14 = 'buttons_14',
  regularH1 = 'regularH1',
  errorTitle32 = 'errorTitle32',
  errorTitle28 = 'errorTitle28',
}

export enum TYPOGRAPHY_COMPONENTS {
  span = 'span',
  div = 'div',
  h1 = 'h1',
  h2 = 'h2',
  h3 = 'h3',
  h4 = 'h4',
  p = 'p',
  li = 'li',
}

export type TextCase = 'uppercase' | 'lowercase'

export interface TypographyProps {
  name: TYPOGRAPHY_NAMES
  mobileName?: TYPOGRAPHY_NAMES
  laptopName?: TYPOGRAPHY_NAMES
  component?: TYPOGRAPHY_COMPONENTS
  textCase?: TextCase
  isCapitalize?: boolean
  color?: keyof typeof colors
}
