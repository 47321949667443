import React from 'react'

import AuthorizationTemplate from '../../components/Authorization/AuthorizationTemplate'

import { RestorePassword as RestorePasswordPage } from './RestorePassword'

export const RestorePassword = () => (
  <AuthorizationTemplate>
    <RestorePasswordPage />
  </AuthorizationTemplate>
)
