import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { modalsVisibleSelector } from '../../../../store/modals/modals.selectors'
import { closeModal } from '../../../../store/modals/modals.actions'
import { CONTRACTS_MODALS } from '../../../../constants/contracts'
import { UploadContractToModal } from '../../../components/Modals/ContractsModals/UploadContractToModal/UploadContractToModal'
import { UploadContractCreateFolderModal } from '../../../components/Modals/ContractsModals/UploadContractCreateFolderModal/UploadContractCreateFolderModal'
import { ContractsCreateFolderModal } from '../../../components/Modals/ContractsModals/ContractsCreateFolderModal/ContractsCreateFolderModal'
import { DeleteContractModal } from '../../../components/Modals/ContractsModals/DeleteContractModal/DeleteContractModal'
import { RenameContractModal } from '../../../components/Modals/ContractsModals/RenameContractModal/RenameContractModal'
import { MoveToFolderModal } from '../../../components/Modals/ContractsModals/MoveToFolderModal/MoveToFolderModal'
import { DuplicateContractModal } from '../../../components/Modals/ContractsModals/DuplicateContractModal/DuplicateContractModal'
import { ViewContractInfoModal } from '../../../components/Modals/ContractsModals/ViewContractInfoModal/ViewContractInfoModal'
import { OpenContractAsModal } from '../../../components/Modals/OpenContractAsModals/OpenContractAsModals'
import UploadContractModal from '../../../components/Modals/ContractsModals/UploadContractModal'
import SaveAsTemplateModal from '../../../components/Modals/ContractsModals/SaveAsTemplateModal'
import CreateFolderForTemplateModal from '../../../components/Modals/TemplatesModals/CreateFolderForTemplateModal'
import CreateContractFromTemplateModal from '../../../components/Modals/ContractsModals/CreateContractFromTemplateModal'
import ContractOTPVerificationModal from '../../../components/Modals/ContractsModals/ContractOTPVerificationModal'
import StubEKYCModal from '../../../components/Modals/VerificationsModals/StubEKYCModal'
import translations from '../../../../translations/keys'

export const ContractsModals: React.FC = () => {
  const dispatch = useDispatch()

  const showUploadContract = useSelector(modalsVisibleSelector(CONTRACTS_MODALS.UPLOAD_CONTRACT))
  const handleUploadContractClose = () => {
    dispatch(closeModal(CONTRACTS_MODALS.UPLOAD_CONTRACT))
  }

  const showUploadToContract = useSelector(modalsVisibleSelector(CONTRACTS_MODALS.UPLOAD_CONTRACT_TO))
  const handleUploadToContractClose = () => {
    dispatch(closeModal(CONTRACTS_MODALS.UPLOAD_CONTRACT_TO))
  }

  const showUploadContractCreateFolder = useSelector(
    modalsVisibleSelector(CONTRACTS_MODALS.UPLOAD_CONTRACT_CREATE_FOLDER)
  )
  const handleUploadContractCreateFolderClose = () => {
    dispatch(closeModal(CONTRACTS_MODALS.UPLOAD_CONTRACT_CREATE_FOLDER))
  }

  const showContractsCreateFolder = useSelector(modalsVisibleSelector(CONTRACTS_MODALS.CONTRACTS_CREATE_FOLDER))
  const handleContractsCreateFolderClose = () => {
    dispatch(closeModal(CONTRACTS_MODALS.CONTRACTS_CREATE_FOLDER))
  }

  const showDeleteContract = useSelector(modalsVisibleSelector(CONTRACTS_MODALS.DELETE_CONTRACT))
  const handleDeleteContractClose = () => {
    dispatch(closeModal(CONTRACTS_MODALS.DELETE_CONTRACT))
  }

  const showRenameContract = useSelector(modalsVisibleSelector(CONTRACTS_MODALS.RENAME_CONTRACT))
  const handleRenameContractClose = () => {
    dispatch(closeModal(CONTRACTS_MODALS.RENAME_CONTRACT))
  }

  const showMoveToFolder = useSelector(modalsVisibleSelector(CONTRACTS_MODALS.CONTRACT_MOVE_TO_FOLDER))
  const handleMoveToFolderClose = () => {
    dispatch(closeModal(CONTRACTS_MODALS.CONTRACT_MOVE_TO_FOLDER))
  }

  const showDuplicateContract = useSelector(modalsVisibleSelector(CONTRACTS_MODALS.DUPLICATE_CONTRACT))
  const handleDuplicateContractClose = () => {
    dispatch(closeModal(CONTRACTS_MODALS.DUPLICATE_CONTRACT))
  }

  const showViewContractInfo = useSelector(modalsVisibleSelector(CONTRACTS_MODALS.VIEW_CONTRACT_INFO))
  const handleViewContractInfoClose = () => {
    dispatch(closeModal(CONTRACTS_MODALS.VIEW_CONTRACT_INFO))
  }

  const showSaveContractAsTemplate = useSelector(modalsVisibleSelector(CONTRACTS_MODALS.SAVE_CONTRACT_AS_TEMPLATE))
  const handleSaveContractAsTemplateClose = () => {
    dispatch(closeModal(CONTRACTS_MODALS.SAVE_CONTRACT_AS_TEMPLATE))
  }

  const showCreateFolderForTemplate = useSelector(modalsVisibleSelector(CONTRACTS_MODALS.CREATE_FOLDER_FOR_TEMPLATE))
  const handleCreateFolderForTemplateClose = () => {
    dispatch(closeModal(CONTRACTS_MODALS.CREATE_FOLDER_FOR_TEMPLATE))
  }

  const showCreateContractFromTemplate = useSelector(
    modalsVisibleSelector(CONTRACTS_MODALS.CREATE_CONTRACT_FROM_TEMPLATE)
  )
  const handleCreateContractFromTemplateClose = () => {
    dispatch(closeModal(CONTRACTS_MODALS.CREATE_CONTRACT_FROM_TEMPLATE))
  }

  const showOTPVerification = useSelector(modalsVisibleSelector(CONTRACTS_MODALS.OTP_VERIFICATION))
  const handleOTPVerificationClose = () => {
    dispatch(closeModal(CONTRACTS_MODALS.OTP_VERIFICATION))
  }

  const showEKYCVerification = useSelector(modalsVisibleSelector(CONTRACTS_MODALS.EKYC_VERIFICATION))
  const handleEKYCVerificationClose = () => {
    dispatch(closeModal(CONTRACTS_MODALS.EKYC_VERIFICATION))
  }

  const showOpenContractAs = useSelector(modalsVisibleSelector(CONTRACTS_MODALS.OPEN_CONTRACT_AS))
  const handleShowOpenContractAsClose = () => {
    dispatch(closeModal(CONTRACTS_MODALS.OPEN_CONTRACT_AS))
  }

  return (
    <>
      {showUploadContract && <UploadContractModal onClose={handleUploadContractClose} />}
      {showUploadToContract && <UploadContractToModal onClose={handleUploadToContractClose} />}
      {showUploadContractCreateFolder && (
        <UploadContractCreateFolderModal onClose={handleUploadContractCreateFolderClose} />
      )}
      {showContractsCreateFolder && <ContractsCreateFolderModal onClose={handleContractsCreateFolderClose} />}
      {showDeleteContract && <DeleteContractModal onClose={handleDeleteContractClose} />}
      {showRenameContract && <RenameContractModal onClose={handleRenameContractClose} />}
      {showMoveToFolder && <MoveToFolderModal onClose={handleMoveToFolderClose} />}
      {showDuplicateContract && <DuplicateContractModal onClose={handleDuplicateContractClose} />}
      {showViewContractInfo && <ViewContractInfoModal onClose={handleViewContractInfoClose} />}
      {showSaveContractAsTemplate && <SaveAsTemplateModal onClose={handleSaveContractAsTemplateClose} />}
      {showCreateFolderForTemplate && <CreateFolderForTemplateModal onClose={handleCreateFolderForTemplateClose} />}
      {showCreateContractFromTemplate && (
        <CreateContractFromTemplateModal onClose={handleCreateContractFromTemplateClose} />
      )}
      {showOTPVerification && <ContractOTPVerificationModal onClose={handleOTPVerificationClose} />}
      {showEKYCVerification && (
        <StubEKYCModal
          onClose={handleEKYCVerificationClose}
          description={translations.EKYC_STUB_DESCRIPTION}
          title={translations.EKYC_STUB_TITLE}
        />
      )}
      {showOpenContractAs && <OpenContractAsModal onClose={handleShowOpenContractAsClose} />}
    </>
  )
}
