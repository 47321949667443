import styled from 'styled-components'

import Box from '../../../../../ui/Box'

export const StyledSignaturePadWrapper = styled(Box)`
  position: relative;
  border: 1px dashed ${(props) => props.theme.palette.blue};
  display: flex;
  background-color: ${({ theme }) => theme.palette.blueExtraLight};
`
