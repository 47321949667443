import {
  PreferencesNotifications,
  PreferencesNotificationsBunches,
  PreferencesNotificationsTypes,
} from '../types/preferencesNotifications'

export const USER_NOTIFICATIONS_TYPES = [
  { value: PreferencesNotificationsTypes.SOCKET, label: 'IN_APP' },
  { value: PreferencesNotificationsTypes.EMAIL, label: 'EMAIL' },
  { value: PreferencesNotificationsTypes.PUSH, label: 'PUSH' },
]

export const BUNCHED_NOTIFICATIONS = {
  [PreferencesNotificationsBunches.contract]: [
    PreferencesNotifications.NOTIFICATION_CONTRACT_PENDING,
    PreferencesNotifications.NOTIFICATION_CONTRACT_SIGNED,
    PreferencesNotifications.NOTIFICATION_CONTRACT_DECLINED,
    PreferencesNotifications.NOTIFICATION_CONTRACT_CHANGED,
    PreferencesNotifications.NOTIFICATION_CONTRACT_COMMENTED,
    PreferencesNotifications.NOTIFICATION_CONTRACT_MENTIONED,
    PreferencesNotifications.NOTIFICATION_CONTRACT_SENT,
    PreferencesNotifications.NOTIFICATION_CONTRACT_UPDATED,
    PreferencesNotifications.NOTIFICATION_CONTRACT_DELETED,
    PreferencesNotifications.NOTIFICATION_CONTRACT_COMPLETED,
    PreferencesNotifications.NOTIFICATION_CONTRACT_RECIPIENTS_UPDATED,
    PreferencesNotifications.NOTIFICATION_CONTRACT_VOIDED,
    PreferencesNotifications.NOTIFICATION_CONTRACT_CORRECTIONS_REMOVED,
  ],
  [PreferencesNotificationsBunches.reminder]: [PreferencesNotifications.NOTIFICATION_REMINDER_BEFORE_AND_ON_DAY],
  [PreferencesNotificationsBunches.chasers]: [PreferencesNotifications.NOTIFICATION_CHASERS_DEADLINE_MISSED],
}
