import React from 'react'

import { Tooltip as StyledTooltip } from './Tooltip.styles'
import { TooltipProps } from './Tooltip.types'

export const Tooltip: React.FC<TooltipProps> = ({ top, left, title }) => (
  <StyledTooltip top={top} left={left}>
    {title}
  </StyledTooltip>
)
