import { combineEpics } from 'redux-observable'

import { deleteEpics } from './delete/delete.epics'
import { duplicateEpics } from './duplicate/duplicate.epics'
import { foldersEpics } from './folders/folders.epics'
import { listEpics } from './list/list.epics'
import { moveToFolderEpics } from './moveToFolder/moveToFolder.epics'
import { renameEpics } from './rename/rename.epics'
import { uploadEpics } from './upload/upload.epics'
import { reuploadEpics } from './reupload/reupload.epics'
import { previewEpics } from './preview/preview.epics'
import { recipientsEpics } from './recipients/recipients.epics'
import { placementEpics } from './placement/placement.epics'
import { createFromContractEpics } from './createFromContract/createFromContract.epics'
import { createContractEpics } from './createContract/createContract.epics'
import { briefListEpics } from './briefList/briefList.epics'
import { favoriteEpics } from './favorite/favorite.epics'
import { fieldsEpics } from './fields/fields.epics'
import { messageEpics } from './message/message.epics'

export const templatesEpics = combineEpics(
  listEpics,
  renameEpics,
  deleteEpics,
  foldersEpics,
  moveToFolderEpics,
  duplicateEpics,
  uploadEpics,
  reuploadEpics,
  previewEpics,
  recipientsEpics,
  placementEpics,
  createFromContractEpics,
  createContractEpics,
  briefListEpics,
  favoriteEpics,
  fieldsEpics,
  messageEpics
)
