import { Action } from 'redux'

import { DuplicateDocumentRequestBody } from '../../../types/duplicateDocument'
import { FetchFailurePayload, FETCH_STATUSES } from '../../../types/fetch'

export interface DuplicateState {
  fetchStatus: FETCH_STATUSES
  error: FetchFailurePayload | null
}

export enum DuplicateActions {
  'DUPLICATE_TEMPLATE' = 'DUPLICATE_TEMPLATE',
  'CLEAR_DUPLICATE_TEMPLATE' = 'CLEAR_DUPLICATE_TEMPLATE',
}

export interface FetchDuplicateTemplate extends Action {
  payload: { id: string; body: DuplicateDocumentRequestBody }
}

export type FetchDuplicateTemplateSuccess = Action

export type ClearDuplicateTemplate = Action
