import { DocumentMessageBody } from '../../../types/documents'
import { FetchFailureAction, FetchFailurePayload } from '../../../types/fetch'
import { createFetchActionTypes } from '../../../utils/createFetchActionTypes'

import * as TYPES from './message.types'

export const fetchSaveTemplateMessageTypes = createFetchActionTypes(TYPES.MessageActions.FETCH_SAVE_TEMPLATE_MESSAGE)

export const fetchSaveTemplateMessage = (
  templateId: string,
  body: DocumentMessageBody
): TYPES.FetchSaveTemplateMessage => ({
  type: fetchSaveTemplateMessageTypes.request,
  payload: { templateId, body },
})
export const fetchSaveTemplateMessageSuccess = (): TYPES.FetchSaveTemplateMessageSuccess => ({
  type: fetchSaveTemplateMessageTypes.success,
})
export const fetchSaveTemplateMessageFailure = (payload: FetchFailurePayload): FetchFailureAction => ({
  type: fetchSaveTemplateMessageTypes.failure,
  payload,
})

export const clearTemplateMessage = (): TYPES.ClearTemplateMessageAction => ({
  type: TYPES.MessageActions.CLEAR_TEMPLATE_MESSAGE,
})
