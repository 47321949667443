import { Action } from 'redux'

import { FetchFailurePayload, FETCH_STATUSES } from '../../../types/fetch'
import { DocumentPreviewDocumentData } from '../../../types/documentPreview'

export interface PreviewState {
  data: DocumentPreviewDocumentData | null
  fetchStatus: FETCH_STATUSES
  successMessage: string
  error: FetchFailurePayload | null
}

export enum PreviewActions {
  'TEMPLATES_SET_SUCCESS_MESSAGE' = 'TEMPLATES_SET_SUCCESS_MESSAGE',
  'TEMPLATES_CLEAR_SUCCESS_MESSAGE' = 'TEMPLATES_CLEAR_SUCCESS_MESSAGE',
  'TEMPLATES_CLEAR_PREVIEW' = 'TEMPLATES_CLEAR_PREVIEW',
  'TEMPLATES_FETCH_PREVIEW' = 'TEMPLATES_FETCH_PREVIEW',
}

export enum SuccessMessages {
  'NONE' = '',
  'DOCUMENT_UPLOADED_SUCCESS' = 'DOCUMENT_UPLOADED_SUCCESS',
  'CREATING_CONTRACT_ABORTED' = 'CREATING_CONTRACT_ABORTED',
  'TEMPLATES_SUCCESSFUL_CREATION' = 'TEMPLATES_SUCCESSFUL_CREATION',
  'TEMPLATES_CUSTOM_FIELDS_PROMPT' = 'TEMPLATES_CUSTOM_FIELDS_PROMPT',
}

export interface SetSuccessMessageAction extends Action {
  payload: { message: string }
}
export type ClearSuccessMessageAction = Action
export type ClearPreviewAction = Action

export interface FetchPreviewAction extends Action {
  payload: { templateId: string }
}
export interface FetchPreviewSuccessAction extends Action {
  payload: DocumentPreviewDocumentData
}
