import { useEffect, useState } from 'react'
import debounce from 'lodash.debounce'

const RESIZE_INTERVAL = 50

export const useForceUpdate = () => {
  const [forceUpdateKey, setForceUpdateKey] = useState(1)

  useEffect(() => {
    const handlerResize = debounce(() => {
      setForceUpdateKey((prevKey) => prevKey + 1)
    }, RESIZE_INTERVAL)
    window.addEventListener('resize', handlerResize)

    return () => {
      window.removeEventListener('resize', handlerResize)
    }
  }, [])

  return forceUpdateKey
}
