import React from 'react'

import MainTemplate from '../../components/Main/MainTemplate'

import { EKYC as EKYCPage } from './EKYC'

export const EKYC = () => (
  <MainTemplate>
    <EKYCPage />
  </MainTemplate>
)
