import { CONTRACT_STATUS, ContractsCategory } from '../../types/contracts'
import { ContractsFiltersForm } from '../../types/contractsFilters'

export const prepareStatuses = (statuses: CONTRACT_STATUS[]) => {
  const isSignedFilter = statuses.includes(CONTRACT_STATUS.SIGNED)

  if (isSignedFilter) {
    return {
      statuses: statuses.reduce<CONTRACT_STATUS[]>(
        (res, status) => [...res, status === CONTRACT_STATUS.SIGNED ? CONTRACT_STATUS.PENDING_RECIPIENTS : status],
        []
      ),
      isSignedFilter,
    }
  }

  return { statuses, isSignedFilter }
}

export const prepareContractsFetchParams = (
  searchText: string,
  folderId: string,
  isAppliedFilters: boolean,
  filters: ContractsFiltersForm,
  category?: ContractsCategory
) => {
  const { statuses, isSignedFilter } = prepareStatuses(filters.statuses)

  return {
    getStatus: () => {
      if (!searchText && category) {
        if (category === CONTRACT_STATUS.SENT) {
          return [category, CONTRACT_STATUS.COMPLETED, ...statuses]
        }
        if (category !== 'received') {
          return [category, ...statuses]
        }
      }

      return statuses
    },
    getFolderId: () => {
      if (searchText || (category && category !== CONTRACT_STATUS.DELETED)) {
        return ''
      }
      if (folderId) {
        return folderId
      }
      if (!category && isAppliedFilters) {
        return ''
      }

      return 'null'
    },
    getReceived: () => {
      if (!searchText && category === 'received') {
        return 'true'
      }

      return ''
    },
    getOwner: () => {
      if (!searchText && category && category === CONTRACT_STATUS.SENT) {
        return 'true'
      }

      return ''
    },
    getSigned: () => {
      if (isSignedFilter) {
        return 'true'
      }

      return ''
    },
  }
}
