import { Action } from 'redux'

import { ConfirmationStatus } from '../../../types/confirmationActions'

export interface ViewOptionsState {
  isSignatureNamesVisible: boolean
  confirmationLabel?: ConfirmationLabelPayload | null
}

export enum ViewOptionsActions {
  'CONTRACTS_TOGGLE_SIGNATURE_NAMES_VISIBLE' = 'CONTRACTS_TOGGLE_SIGNATURE_NAMES_VISIBLE',
  'CLEAN_CONTRACTS_VIEW_OPTIONS' = 'CLEAN_CONTRACTS_VIEW_OPTIONS',
  'SET_CONFIRMATION_LABEL' = 'SET_CONFIRMATION_LABEL',
  'UNSET_CONFIRMATION_LABEL' = 'UNSET_CONFIRMATION_LABEL',
}

export interface ConfirmationLabelPayload {
  id: string
  fullName: string
  date: string
  status: ConfirmationStatus
}

export interface SetConfirmationLabelAction extends Action {
  payload: ConfirmationLabelPayload
}
