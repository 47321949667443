import React from 'react'
import { useTranslation } from 'react-i18next'

import translations from '../../../../../translations/keys'
import { FileSvg } from '../../../../assets/FileSvg'
import Button from '../../../../ui/Button'
import { BUTTON_VARIANTS } from '../../../../ui/Button/Button.types'
import Modal from '../../..//Modal'
import Typography from '../../../../ui/Typography'
import { TYPOGRAPHY_NAMES } from '../../../../ui/Typography/Typography.types'
import Box from '../../../../ui/Box'

import { AskModalProps } from './AskModal.types'

export const AskModal: React.FC<AskModalProps> = ({
  onCancel,
  onSuccess,
  onClose,
  title,
  successButtonText = translations.YES,
}) => {
  const { t } = useTranslation()

  return (
    <Modal onClose={onClose}>
      <Box display="flex" flexDirection="column" alignItems="center" width={320} textAlign="center">
        <Box mb={3}>
          <FileSvg />
        </Box>
        <Typography name={TYPOGRAPHY_NAMES.H1}>{t(title)}</Typography>
        <Box mt={3} mb={onCancel ? 3 : 0} width="100%">
          <Button onClick={onSuccess} fullWidth>
            {t(successButtonText)}
          </Button>
        </Box>
        {onCancel && (
          <Button onClick={onCancel} fullWidth variant={BUTTON_VARIANTS.SECONDARY}>
            {t(translations.NO)}
          </Button>
        )}
      </Box>
    </Modal>
  )
}
