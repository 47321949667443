import React from 'react'

import Box from '../../../ui/Box'
import Typography from '../../../ui/Typography'
import { TYPOGRAPHY_NAMES } from '../../../ui/Typography/Typography.types'

interface TitledParagraphProps {
  title: string
  children: React.ReactNode
}

export const TitledParagraph: React.FC<TitledParagraphProps> = ({ title, children }) => (
  <div>
    <Box mt={3} mb={2}>
      <Typography name={TYPOGRAPHY_NAMES.H3}>{title}</Typography>
    </Box>
    <>{children}</>
  </div>
)
