import { combineEpics, Epic, ofType } from 'redux-observable'
import { catchError, debounceTime, map, mergeMap, takeUntil } from 'rxjs/operators'
import { concat, of } from 'rxjs'

import { catchFetchError } from '../../../utils/catchFetchError'
import { contractsService, foldersService } from '../../../api'

import * as ACTIONS from './contractsList.actions'
import { prepareContractsOffset, prepareFolderId, prepareContractsListFetch } from './contractsList.utils'

const FETCH_DEBOUNCE_TIME = 500

export const fetchContractsListData: Epic = (action$) =>
  action$.pipe(
    ofType(ACTIONS.fetchContractsListDataTypes.request),
    debounceTime(FETCH_DEBOUNCE_TIME),
    mergeMap(({ payload }) =>
      contractsService.fetchContractsList(payload.params).pipe(
        map((response) => ACTIONS.fetchContractsListDataSuccess(response)),
        catchError(catchFetchError(ACTIONS.fetchContractsListDataFailure)),
        takeUntil(
          action$.pipe(ofType(ACTIONS.fetchContractsListDataTypes.success, ACTIONS.fetchContractsListDataTypes.failure))
        )
      )
    )
  )

export const fetchDeleteContract: Epic = (action$, state$) =>
  action$.pipe(
    ofType(ACTIONS.fetchDeleteContractTypes.request),
    mergeMap(({ payload }) => {
      let request = contractsService.fetchDeleteContract

      if (payload.isFolder) {
        request = foldersService.fetchDeleteFolder
      } else if (payload.recipient) {
        request = contractsService.fetchDeleteRecipientContract
      }

      return concat(
        request(payload.id).pipe(
          map(() => ACTIONS.fetchDeleteContractSuccess(payload.isFolder)),
          catchError(catchFetchError(ACTIONS.fetchDeleteContractFailure)),
          takeUntil(
            action$.pipe(ofType(ACTIONS.fetchDeleteContractTypes.success, ACTIONS.fetchDeleteContractTypes.failure))
          )
        ),
        payload.isMobile
          ? of(
              ACTIONS.contractsListSetOffset(state$.value.contracts.contractsList.offset),
              ACTIONS.fetchContractsListData(
                prepareContractsListFetch(state$.value.contracts.contractsList, payload.category)
              )
            )
          : of(
              ACTIONS.contractsListSetOffset(prepareContractsOffset(state$.value.contracts.contractsList)),
              ACTIONS.contractsListForceUpdate()
            )
      )
    })
  )

export const fetchRenameContract: Epic = (action$) =>
  action$.pipe(
    ofType(ACTIONS.fetchRenameContractTypes.request),
    mergeMap(({ payload }) => {
      const update = payload.isFolder ? foldersService.fetchUpdateFolder : contractsService.fetchUpdateContract

      return update(payload.id, { name: payload.name }).pipe(
        map(() => ACTIONS.fetchRenameContractSuccess(payload.id, payload.name)),
        catchError(catchFetchError(ACTIONS.fetchRenameContractFailure)),
        takeUntil(
          action$.pipe(ofType(ACTIONS.fetchRenameContractTypes.success, ACTIONS.fetchRenameContractTypes.failure))
        )
      )
    })
  )

export const fetchMoveContractToFolder: Epic = (action$, state$) =>
  action$.pipe(
    ofType(ACTIONS.fetchMoveContractToFolderTypes.request),
    mergeMap(({ payload }) => {
      const request = payload.recipient
        ? contractsService.fetchUpdateRecipientContract
        : contractsService.fetchUpdateContract

      return request(payload.id, {
        folderId: prepareFolderId(payload.folderId),
      }).pipe(
        mergeMap(() =>
          payload.isMobile
            ? of(
                ACTIONS.contractsListSetOffset(state$.value.contracts.contractsList.offset),
                ACTIONS.fetchContractsListData(
                  prepareContractsListFetch(state$.value.contracts.contractsList, payload.category)
                ),
                ACTIONS.fetchMoveContractToFolderSuccess()
              )
            : of(
                ACTIONS.contractsListSetOffset(prepareContractsOffset(state$.value.contracts.contractsList)),
                ACTIONS.contractsListForceUpdate(),
                ACTIONS.fetchMoveContractToFolderSuccess()
              )
        ),
        catchError(catchFetchError(ACTIONS.fetchMoveContractToFolderFailure)),
        takeUntil(
          action$.pipe(
            ofType(ACTIONS.fetchMoveContractToFolderTypes.success, ACTIONS.fetchMoveContractToFolderTypes.failure)
          )
        )
      )
    })
  )

export const fetchDuplicateContract: Epic = (action$, state$) =>
  action$.pipe(
    ofType(ACTIONS.fetchDuplicateContractTypes.request),
    mergeMap(({ payload }) =>
      concat(
        contractsService
          .fetchDuplicateContract(payload.id, {
            ...payload.body,
            folderId: prepareFolderId(payload.body.folderId),
          })
          .pipe(
            map(() => ACTIONS.fetchDuplicateContractSuccess()),
            catchError(catchFetchError(ACTIONS.fetchDuplicateContractFailure)),
            takeUntil(
              action$.pipe(
                ofType(ACTIONS.fetchDuplicateContractTypes.success, ACTIONS.fetchDuplicateContractTypes.failure)
              )
            )
          ),
        payload.isMobile
          ? of(
              ACTIONS.contractsListSetOffset(state$.value.contracts.contractsList.offset),
              ACTIONS.fetchContractsListData(
                prepareContractsListFetch(state$.value.contracts.contractsList, payload.category)
              )
            )
          : of(ACTIONS.contractsListForceUpdate())
      )
    )
  )

export const contractsListEpics = combineEpics(
  fetchContractsListData,
  fetchDeleteContract,
  fetchRenameContract,
  fetchMoveContractToFolder,
  fetchDuplicateContract
)
