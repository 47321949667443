import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'
import styled from 'styled-components'

import { registrationUserIdSelector } from '../../../store/registration/registration.selectors'
import translations from '../../../translations/keys'
import { location } from '../../../utils/location'
import { EmailIcon } from '../../assets/EmailIcon'
import Box from '../../ui/Box'
import FormWrapper from '../../components/FormWrapper'
import AuthorizationBox from '../../components/Authorization/AuthorizationBox'
import Link from '../../ui/Link'
import Typography from '../../ui/Typography'
import { TYPOGRAPHY_COMPONENTS, TYPOGRAPHY_NAMES } from '../../ui/Typography/Typography.types'
import { resetRegistration } from '../../../store/registration/registration.actions'

const StyledDescription = styled.p`
  margin-bottom: 260px;
  text-align: center;
  ${({ theme }) => `@media ${theme.devices.mobile} {
    margin-bottom: 142px;
  }`}
`

export const ConfirmEmail = () => {
  const dispatch = useDispatch()
  const userId = useSelector(registrationUserIdSelector)

  if (!userId) {
    return <Redirect to={location.registrationUrl()} />
  }

  useEffect(
    () => () => {
      dispatch(resetRegistration())
    },
    []
  )

  return (
    <AuthorizationBox noLogo>
      <FormWrapper>
        <Box textAlign="center" mb={5}>
          <EmailIcon />
        </Box>
        <Box textAlign="center" mb={3}>
          <Typography name={TYPOGRAPHY_NAMES.H1} component={TYPOGRAPHY_COMPONENTS.h1}>
            {translations.CONFIRM_EMAIL_TITLE}
          </Typography>
        </Box>
        <StyledDescription>{translations.CONFIRM_EMAIL_DESCRIPTION}</StyledDescription>
        <Box textAlign="center" mb={6}>
          <Link to={location.loginUrl()}>{translations.BACK_TO_LOG_IN}</Link>
        </Box>
      </FormWrapper>
    </AuthorizationBox>
  )
}
