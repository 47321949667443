import React from 'react'
import { useField } from 'formik'
import { useTranslation } from 'react-i18next'

import TextInput from '../../TextInput'

import { FormTextInputProps } from './FormTextInput.types'

export const FormTextInput = (props: FormTextInputProps) => {
  const [field, meta] = useField(props)
  const { t } = useTranslation()

  return <TextInput {...props} {...field} {...meta} error={meta.touched ? meta.error && t(meta.error) : ''} />
}
