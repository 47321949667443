import { Action } from 'redux'

import { FetchFailurePayload, FETCH_STATUSES } from '../../types/fetch'
import { Sorting } from '../../types/table'
import { Contact, ContactResponse } from '../../types/contacts'
import { AddContactsRequestBody } from '../../types/addContact'
import { UpdateContactsForm } from '../../types/updateContact'
import { Params } from '../../utils/prepareUrlParams/prepareUrlParams'
import { Notification } from '../../types/notifications'

export interface ContactsState {
  sorted: Contact[]
  sorting: Sorting
  searchText: string
  offset: number
  total: number
  limit: number
  isFirstLoad: boolean
  loading: boolean
  fetchStatus: FETCH_STATUSES
  successMessage: SuccessMessages
  error: FetchFailurePayload | null
  loadMore: boolean
  notification: Notification | null
}

export enum ContactsActions {
  'SET_SORTING' = 'SET_SORTING',
  'SET_SEARCH_TEXT' = 'SET_SEARCH_TEXT',
  'SET_OFFSET' = 'SET_OFFSET',
  'CLEAR_SUCCESS_MESSAGE' = 'CLEAR_SUCCESS_MESSAGE',
  'CLEAR_FETCH_STATUS' = 'CLEAR_FETCH_STATUS',
  'CLEAR_ERROR' = 'CLEAR_ERROR',
  'CLEAR_CONTACTS' = 'CLEAR_CONTACTS',
  'FETCH_CONTACTS' = 'FETCH_CONTACTS',
  'FETCH_CREATE_CONTACT' = 'FETCH_CREATE_CONTACT',
  'FETCH_UPDATE_CONTACT' = 'FETCH_UPDATE_CONTACT',
  'FETCH_DELETE_CONTACT' = 'FETCH_DELETE_CONTACT',
  'SET_CONTACTS_NOTIFICATION' = 'SET_CONTACTS_NOTIFICATION',
  'CLEAN_CONTACTS_NOTIFICATION' = 'CLEAN_CONTACTS_NOTIFICATION',
}

export enum SuccessMessages {
  'NONE' = '',
  'CONTACT_SAVED' = 'CONTACT_SAVED',
  'CONTACT_CREATED' = 'CONTACT_CREATED',
  'CONTACT_DELETED' = 'CONTACT_DELETED',
}

export interface SetSorting extends Action {
  payload: Sorting
}
export interface SetSearchText extends Action {
  payload: { searchText: string }
}
export interface SetOffset extends Action {
  payload: { offset: number; loadMore: boolean }
}
export type ClearSuccessMessageAction = Action
export type ClearErrorAction = Action
export type ClearFetchStatusAction = Action
export type ClearContactsAction = Action

export interface FetchContactsAction extends Action {
  payload: { params: Params }
}
export interface FetchContactsSuccessAction extends Action {
  payload: ContactResponse
}

export interface FetchCreateContactAction extends Action {
  payload: { body: AddContactsRequestBody }
}
export interface FetchCreateContactSuccessAction extends Action {
  payload: { contact: Contact }
}

export interface FetchUpdateContactAction extends Action {
  payload: { id: string; body: UpdateContactsForm }
}
export interface FetchUpdateContactSuccessAction extends Action {
  payload: { contact: Contact }
}

export interface FetchDeleteContactAction extends Action {
  payload: { id: string; isMobile: boolean }
}
export interface FetchDeleteContactSuccessAction extends Action {
  payload: { id: string }
}

export interface SetContactsNotificationAction extends Action {
  payload: Notification
}
