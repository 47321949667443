import { useMemo } from 'react'

import { PreferencesNotifications, PreferencesNotificationsTypes } from '../../types/preferencesNotifications'

export const useDisabledFields = () => {
  const disabledFields = useMemo(
    () => ({
      [PreferencesNotificationsTypes.EMAIL]: {
        [PreferencesNotifications.NOTIFICATION_CONTRACT_PENDING]: true,
        [PreferencesNotifications.NOTIFICATION_CONTRACT_SIGNED]: true,
        [PreferencesNotifications.NOTIFICATION_CONTRACT_DECLINED]: true,
        [PreferencesNotifications.NOTIFICATION_CONTRACT_CHANGED]: true,
        [PreferencesNotifications.NOTIFICATION_CONTRACT_COMMENTED]: false,
        [PreferencesNotifications.NOTIFICATION_CONTRACT_MENTIONED]: true,
        [PreferencesNotifications.NOTIFICATION_CONTRACT_SENT]: true,
        [PreferencesNotifications.NOTIFICATION_CONTRACT_UPDATED]: true,
        [PreferencesNotifications.NOTIFICATION_CONTRACT_DELETED]: true,
        [PreferencesNotifications.NOTIFICATION_CONTRACT_COMPLETED]: true,
        [PreferencesNotifications.NOTIFICATION_CONTRACT_RECIPIENTS_UPDATED]: true,
        [PreferencesNotifications.NOTIFICATION_CONTRACT_VOIDED]: true,
        [PreferencesNotifications.NOTIFICATION_CONTRACT_CORRECTIONS_REMOVED]: true,
        [PreferencesNotifications.NOTIFICATION_REMINDER_BEFORE_AND_ON_DAY]: true,
        [PreferencesNotifications.NOTIFICATION_CHASERS_DEADLINE_MISSED]: true,
        [PreferencesNotifications.STRIKE_ADDED]: true,
        [PreferencesNotifications.CORRECTION_ADDED]: true,
      },
      [PreferencesNotificationsTypes.SOCKET]: null,
      [PreferencesNotificationsTypes.PUSH]: null,
    }),
    []
  )

  return disabledFields
}
