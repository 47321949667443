import React from 'react'

export const GreyVisaSvg = () => (
  <svg width="30" height="10" viewBox="0 0 30 10" fill="none">
    <g opacity="0.6" clipPath="url(#clip0)">
      <path
        d="M13.1035 9.35393H10.8374L12.2537 0.649673H14.5201L13.1035 9.35393ZM8.93092 0.649673L6.77047 6.63653L6.51482 5.34733L6.51505 5.34779L5.75252 1.43339C5.75252 1.43339 5.66032 0.649673 4.67753 0.649673H1.10587L1.06396 0.797057C1.06396 0.797057 2.15618 1.0243 3.43444 1.79196L5.40327 9.35416H7.76443L11.3698 0.649673H8.93092ZM26.7555 9.35393H28.8363L27.022 0.649441H25.2004C24.3591 0.649441 24.1542 1.29812 24.1542 1.29812L20.7744 9.35393H23.1368L23.6092 8.06101H26.49L26.7555 9.35393ZM24.2618 6.27494L25.4525 3.01759L26.1224 6.27494H24.2618ZM20.9516 2.74285L21.275 0.873659C21.275 0.873659 20.2771 0.494141 19.2368 0.494141C18.1122 0.494141 15.4416 0.985653 15.4416 3.37569C15.4416 5.6244 18.576 5.65234 18.576 6.83351C18.576 8.01467 15.7646 7.80303 14.8367 7.05819L14.4998 9.0126C14.4998 9.0126 15.5117 9.50411 17.0577 9.50411C18.6042 9.50411 20.9372 8.70339 20.9372 6.52407C20.9372 4.26093 17.7746 4.05021 17.7746 3.06626C17.7749 2.08207 19.9819 2.2085 20.9516 2.74285Z"
        fill="white"
      />
      <path
        d="M6.51512 5.34851L5.75258 1.43411C5.75258 1.43411 5.66038 0.650391 4.67758 0.650391H1.10588L1.06396 0.797774C1.06396 0.797774 2.78066 1.15354 4.42728 2.48652C6.00172 3.76058 6.51512 5.34851 6.51512 5.34851Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="29.7" height="9.9" fill="white" transform="translate(0.100098 0.0507812)" />
      </clipPath>
    </defs>
  </svg>
)
