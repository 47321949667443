import React from 'react'

import { Accordion } from '../../ui/Accordion/Accordion'
import Typography from '../../ui/Typography'
import { TYPOGRAPHY_NAMES } from '../../ui/Typography/Typography.types'
import Box from '../../ui/Box'

import { StyledMain, StyledFaqContent } from './Faq.styles'
import { FaqHeader } from './components/FaqHeader/FaqHeader'
import { FaqCategory } from './components/FaqCategory/FaqCategory'

export const Faq = () => {
  const faqListMock = [
    {
      title: 'The services we provide',
      description:
        'Facebook builds technologies and services that enable people to connect with each other, build communities,',
    },
    {
      title: 'The services we provide',
      description:
        'Facebook builds technologies and services that enable people to connect with each other, build communities,',
    },
    {
      title: 'The services we provide',
      description:
        'Facebook builds technologies and services that enable people to connect with each other, build communities,',
    },
  ]

  const ifFindResultMock = true
  return (
    <StyledMain>
      <FaqHeader />
      <StyledFaqContent>
        {ifFindResultMock ? (
          <>
            <FaqCategory />
            <div>
              {faqListMock.map((item, index) => (
                <Accordion key={index} item={item} />
              ))}
            </div>
          </>
        ) : (
          <Box display="flex" justifyContent="center" mt={4}>
            <Typography name={TYPOGRAPHY_NAMES.body14}>No results found. Please try your request again</Typography>
          </Box>
        )}
      </StyledFaqContent>
    </StyledMain>
  )
}
