import React from 'react'
import { useField } from 'formik'

import Checkbox from '../../Checkbox'

import { FormCheckboxProps } from './FormCheckbox.types'

export const FormCheckbox = (props: FormCheckboxProps) => {
  const [field, meta] = useField({ ...props, type: 'checkbox' })

  return <Checkbox {...field} {...meta} {...props} error={meta.touched ? meta.error : ''} />
}
