import React from 'react'

import MainTemplate from '../../components/Main/MainTemplate'

import { ChangePassword as ChangePasswordPage } from './ChangePassword'

export const ChangePassword = () => (
  <MainTemplate>
    <ChangePasswordPage />
  </MainTemplate>
)
