import { AddContactForm } from './addContact'

export enum UpdateContactFields {
  fullName = 'fullName',
  email = 'email',
  phone = 'phone',
  department = 'department',
}

export type UpdateContactsForm = AddContactForm
