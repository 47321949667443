import React from 'react'
import { useField } from 'formik'
import { useTranslation } from 'react-i18next'

import Dropdown from '../../Dropdown'

import { FormDropdownProps } from './FormDropdown.types'

export const FormDropdown = ({ onChange, ...props }: FormDropdownProps) => {
  const { t } = useTranslation()
  const [field, meta] = useField(props)
  const onChangeDropdown = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(e)
    }
    field.onChange(e)
  }

  return (
    <Dropdown
      {...props}
      {...field}
      {...meta}
      onChange={onChangeDropdown}
      error={meta.touched ? meta.error && t(meta.error) : ''}
    />
  )
}
