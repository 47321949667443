import React from 'react'

import SvgIcon from '../../ui/SvgIcon'
import { SvgIconProps } from '../../ui/SvgIcon/SvgIcon.types'

export const TriangleDownIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon viewBox="0 0 16 16" {...props}>
    <path d="M7.57066 10.9281L4.17906 7.53653C3.79655 7.15402 4.06746 6.5 4.6084 6.5H11.3916C11.9325 6.5 12.2034 7.15402 11.8209 7.53653L8.42934 10.9281C8.19222 11.1652 7.80778 11.1652 7.57066 10.9281Z" />
  </SvgIcon>
)
