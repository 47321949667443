import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import translations from '../../../../../translations/keys'
import { ContractStatusTypes } from '../../../../../types/contracts'
import { getFormattedDate } from '../../../../../utils/date'
import * as RECIPIENTS_SELECTORS from '../../../../../store/contracts/recipients/recipients.selectors'
import Box from '../../../../ui/Box'
import Modal from '../../../Modal'
import Button from '../../../../ui/Button'
import { BUTTON_VARIANTS } from '../../../../ui/Button/Button.types'
import Typography from '../../../../ui/Typography'
import { TYPOGRAPHY_COMPONENTS, TYPOGRAPHY_NAMES } from '../../../../ui/Typography/Typography.types'
import { FileSvg } from '../../../../assets/FileSvg'
import { isString } from '../../../../../utils/isString'

import { ResendContractModalProps } from './ResendContractModal.types'
import * as Styled from './ResendContractModal.styles'

export const ResendContractModal: React.FC<ResendContractModalProps> = ({ onResend, onClose }) => {
  const recipientsById = useSelector(RECIPIENTS_SELECTORS.recipientsByIdSelector)
  const { t } = useTranslation()
  const recipientsSorted = useSelector(RECIPIENTS_SELECTORS.recipientsSortedSelector)
  const negativeRecipients = recipientsSorted
    .filter((id) => recipientsById[id].status?.type === ContractStatusTypes.NEGATIVE)
    .map((id) => recipientsById[id])

  return (
    <Modal onClose={onClose}>
      <Box display="flex" flexDirection="column" alignItems="center" width={320} textAlign="center">
        <Box mb={3}>
          <FileSvg />
        </Box>
        <Typography name={TYPOGRAPHY_NAMES.H1}>{t(translations.RESEND_CONTRACT)}</Typography>
        <Box mt={2} mb={2} textAlign="center">
          {t(translations.RESEND_CONTRACT_MODAL_HEADER_TEXT)}
        </Box>
        <Styled.List>
          {negativeRecipients.map((recipient) => (
            <Styled.ListItem key={recipient.id}>
              <Box pr={3}>
                <Typography component={TYPOGRAPHY_COMPONENTS.div} name={TYPOGRAPHY_NAMES.body14}>
                  {recipient.name}
                </Typography>
                <Typography component={TYPOGRAPHY_COMPONENTS.div} name={TYPOGRAPHY_NAMES.body14} color="grey">
                  {recipient.email}
                </Typography>
              </Box>
              {recipient.deadline && (
                <Box>
                  {getFormattedDate(isString(recipient.deadline) ? new Date(recipient.deadline) : recipient.deadline)}
                </Box>
              )}
            </Styled.ListItem>
          ))}
        </Styled.List>
        <Box mt={2} mb={2} textAlign="center">
          {t(translations.RESEND_CONTRACT_MODAL_FOOTER_TEXT)}
        </Box>
        <Box mt={3} mb={3} width="100%">
          <Button onClick={onResend} fullWidth>
            {t(translations.RESEND)}
          </Button>
        </Box>
        <Button onClick={onClose} fullWidth variant={BUTTON_VARIANTS.SECONDARY}>
          {t(translations.CANCEL)}
        </Button>
      </Box>
    </Modal>
  )
}
