import { combineEpics, Epic, ofType } from 'redux-observable'
import { catchError, mergeMap, takeUntil } from 'rxjs/operators'
import { of } from 'rxjs'

import { catchFetchError } from '../../../utils/catchFetchError'
import { signingService } from '../../../api'
import { SessionStorageKeys } from '../../../constants/sessionStorage'
import SessionStorage from '../../../utils/SessionStorage'
import { signingRecipientTokenSelector } from '../signing/signing.selectors'
import * as VERIFICATION_ACTIONS from '../../../store/contracts/verification/verification.actions'

import * as ACTIONS from './noneVerification.actions'

export const fetchNoneVerification: Epic = (action$, state$) =>
  action$.pipe(
    ofType(ACTIONS.fetchNoneVerificationTypes.request),
    mergeMap(({ payload }) =>
      signingService.fetchContract(payload.contractId, signingRecipientTokenSelector(state$.value)).pipe(
        mergeMap(({ recipient }) =>
          signingService.fetchVerifyNone(recipient.id, signingRecipientTokenSelector(state$.value)).pipe(
            mergeMap(({ response }) => {
              // SessionStorage.set(SessionStorageKeys.VERIFICATION_TOKEN, response?.signingToken)

              if (payload.onSuccess) {
                payload.onSuccess()
              }

              return of(
                VERIFICATION_ACTIONS.fetchVerification(payload.contractId),
                ACTIONS.fetchNoneVerificationSuccess()
              )
            }),
            catchError(catchFetchError(ACTIONS.fetchNoneVerificationFailure)),
            takeUntil(
              action$.pipe(
                ofType(ACTIONS.fetchNoneVerificationTypes.success, ACTIONS.fetchNoneVerificationTypes.failure)
              )
            )
          )
        ),
        catchError(catchFetchError(ACTIONS.fetchNoneVerificationFailure)),
        takeUntil(
          action$.pipe(ofType(ACTIONS.fetchNoneVerificationTypes.success, ACTIONS.fetchNoneVerificationTypes.failure))
        )
      )
    )
  )

export const noneVerificationEpics = combineEpics(fetchNoneVerification)
