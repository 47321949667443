import { FetchError } from '../../types/fetch'

import * as TYPES from './countries.types'

export const fetchCountries = (): TYPES.FetchCountriesAction => ({
  type: TYPES.CountriesActions.FETCH_COUNTRIES,
})

export const fetchCountriesSuccess = (countries: TYPES.Countries): TYPES.FetchCountriesSuccessAction => ({
  type: TYPES.CountriesActions.FETCH_COUNTRIES_SUCCESS,
  payload: countries,
})

export const fetchCountriesFailure = (error: FetchError): TYPES.FetchCountriesFailureAction => ({
  type: TYPES.CountriesActions.FETCH_COUNTRIES_FAILURE,
  payload: error,
})
