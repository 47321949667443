import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { closeModal } from '../../../../store/modals/modals.actions'
import { modalsVisibleSelector } from '../../../../store/modals/modals.selectors'
import { COMMON_MODAL_NODE_ID } from '../../../constants/modals'
import NotificationModal from '../NotificationModal'
import SubscriptionWarningModal from '../SubscriptionModals/SubscriptionWarningModal'

import { COMMON_MODALS_IDS } from './CommonModals.constants'

export const CommonModals = () => {
  const dispatch = useDispatch()

  const showNotificationModal = useSelector(modalsVisibleSelector(COMMON_MODALS_IDS.NOTIFICATION))
  const onCloseNotificationModal = () => {
    dispatch(closeModal(COMMON_MODALS_IDS.NOTIFICATION))
  }

  const showSubscriptionWarningModal = useSelector(modalsVisibleSelector(COMMON_MODALS_IDS.SUBSCRIPTION_WARNING))
  const onCloseSubscriptionWarningModal = () => {
    dispatch(closeModal(COMMON_MODALS_IDS.SUBSCRIPTION_WARNING))
  }

  return (
    <>
      {showNotificationModal && <NotificationModal onClose={onCloseNotificationModal} />}
      {showSubscriptionWarningModal && <SubscriptionWarningModal onClose={onCloseSubscriptionWarningModal} />}
      <div id={COMMON_MODAL_NODE_ID} />
    </>
  )
}
