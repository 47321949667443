import { FETCH_STATUSES } from '../../types/fetch'
import { VerifyPasswordKeys } from '../../types/verifyPassword'
import { RootState } from '../rootReducer.types'

export const verifyPasswordPasswordTokenSelector = (key: VerifyPasswordKeys) => (state: RootState) =>
  state.verifyPassword[key]?.token

export const verifyPasswordSuccessSelector = (key: VerifyPasswordKeys) => (state: RootState) =>
  state.verifyPassword[key]?.fetchStatus === FETCH_STATUSES.SUCCESS

export const verifyPasswordErrorSelector = (key: VerifyPasswordKeys) => (state: RootState) =>
  state.verifyPassword[key]?.error

export const verifyPasswordIsFailureSelector = (key: VerifyPasswordKeys) => (state: RootState) =>
  state.verifyPassword[key]?.fetchStatus === FETCH_STATUSES.FAILURE
