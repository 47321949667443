import { Ajax } from '../ajax'
import { Contact, ContactResponse } from '../../types/contacts'
import { AddContactsRequestBody } from '../../types/addContact'
import prepareUrlParams from '../../utils/prepareUrlParams'
import { Params } from '../../utils/prepareUrlParams/prepareUrlParams'

class ContactsService extends Ajax {
  pathname = 'contact'

  fetchContacts = (params: Params) => this.getJSON<ContactResponse>(`${this.pathname}?${prepareUrlParams(params)}`)

  fetchCreateContact = (body: AddContactsRequestBody) => this.post(this.pathname, body)

  fetchUpdateContact = (id: string, body: Contact) => this.patch(`${this.pathname}/${id}`, body)

  fetchDeleteContact = (id: string) => this.delete(`${this.pathname}/${id}`)
}

export const contactsService = new ContactsService()
