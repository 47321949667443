import { contractsFiltersInitialValues } from '../../../initialValues/contractsFiltersInitialValues'
import { CONTRACT_STATUS, ContractSuccessMessages } from '../../../types/contracts'
import { FetchFailureAction, FETCH_STATUSES } from '../../../types/fetch'
import { Sorting } from '../../../types/table'
import { createReducer } from '../../createReducer'
import { fetchSendContractTypes } from '../placement/placement.actions'
import { fetchUploadFolderTypes } from '../folders/folders.actions'
import { fetchDeclineSigningTypes, fetchSignContractTypes } from '../signing/signing.actions'
import { fetchVoidContractTypes } from '../void/void.actions'
import { FetchVoidContractSuccess } from '../void/void.types'
import { FetchSignContractSuccess } from '../signing/signing.types'

import * as ACTIONS from './contractsList.actions'
import * as TYPES from './contractsList.types'

type Actions =
  | TYPES.FetchContractsListSuccess
  | FetchFailureAction
  | TYPES.ContractsListSetSorting
  | TYPES.ContractsListSetOffset
  | TYPES.ContractsListSetSearchText
  | TYPES.ContractsListSetFilters
  | TYPES.FetchRenameContractSuccess
  | TYPES.ContractsListGoToFolder
  | TYPES.FetchDeleteContractSuccess
  | TYPES.SetContractsNotificationAction
  | FetchSignContractSuccess
  | FetchVoidContractSuccess
  | TYPES.SetContractStatusAction

const SORTING: Sorting = { field: 'createDate', direction: 'DESC' }
const LIMIT = 20

export const initialState: TYPES.ContractsListState = {
  error: null,
  fetchStatus: FETCH_STATUSES.IDLE,
  data: [],
  sorting: SORTING,
  loadMore: false,
  offset: 0,
  total: 0,
  limit: LIMIT,
  searchText: '',
  filters: contractsFiltersInitialValues,
  isFirstLoad: true,
  isAppliedFilters: false,
  delete: {
    fetchStatus: FETCH_STATUSES.IDLE,
    error: null,
  },
  successMessage: ContractSuccessMessages.NONE,
  forceUpdate: 0,
  rename: {
    fetchStatus: FETCH_STATUSES.IDLE,
    error: null,
  },
  moveToFolder: {
    fetchStatus: FETCH_STATUSES.IDLE,
    error: null,
  },
  duplicate: {
    fetchStatus: FETCH_STATUSES.IDLE,
    error: null,
  },
  breadcrumbs: [
    {
      name: 'All contracts',
      value: '',
    },
  ],
  folderId: '',
  isEmptyFolder: false,
  isEmptyContracts: true,
  notification: null,
}

export const contractsList = createReducer<TYPES.ContractsListState, Actions>(initialState, {
  [ACTIONS.fetchContractsListDataTypes.request]: (state) => ({
    ...state,
    fetchStatus: FETCH_STATUSES.REQUEST,
  }),
  [ACTIONS.fetchContractsListDataTypes.success]: (state, action) => {
    const { data, totalCount } = (action as TYPES.FetchContractsListSuccess).payload.body
    const newData = state.loadMore && state.offset !== initialState.offset ? [...state.data, ...data] : data
    const noData = !newData.length

    return {
      ...state,
      data: newData,
      loading: false,
      offset: state.offset,
      total: totalCount,
      fetchStatus: FETCH_STATUSES.SUCCESS,
      error: null,
      isFirstLoad: (state.isFirstLoad || (!state.isAppliedFilters && !state.searchText && !state.folderId)) && noData,
      isEmptyFolder: !state.searchText && !!state.folderId && noData,
      isEmptyContracts: (!!state.searchText || !state.folderId) && noData,
    }
  },
  [ACTIONS.fetchContractsListDataTypes.failure]: (state, action) => ({
    ...state,
    fetchStatus: FETCH_STATUSES.FAILURE,
    error: (action as FetchFailureAction).payload,
    isFirstLoad: false,
  }),

  [TYPES.ContractsListActions.CONTRACTS_LIST_SET_SORTING]: (state, action) => ({
    ...state,
    sorting: (action as TYPES.ContractsListSetSorting).payload,
    offset: state.loadMore ? initialState.offset : state.offset,
  }),
  [TYPES.ContractsListActions.CLEAR_CONTRACTS]: () => ({ ...initialState }),
  [TYPES.ContractsListActions.CLEAR_CONTRACTS_DATA]: (state) => ({ ...state, data: initialState.data }),
  [TYPES.ContractsListActions.CONTRACTS_LIST_SET_OFFSET]: (state, action) => {
    const act = action as TYPES.ContractsListSetOffset
    return {
      ...state,
      offset: act.payload.offset,
      loadMore: act.payload.loadMore,
    }
  },
  [TYPES.ContractsListActions.CONTRACTS_LIST_SET_SEARCH_TEXT]: (state, action) => ({
    ...state,
    offset: initialState.offset,
    searchText: (action as TYPES.ContractsListSetSearchText).payload.searchText,
  }),
  [TYPES.ContractsListActions.CONTRACTS_LIST_SET_FILTERS]: (state, action) => {
    const filters = (action as TYPES.ContractsListSetFilters).payload.filters
    const isAppliedFilters = Object.values(filters).some((filter) => (Array.isArray(filter) ? filter.length : filter))

    return {
      ...state,
      filters,
      offset: initialState.offset,
      isAppliedFilters,
      fetchStatus: FETCH_STATUSES.REQUEST,
    }
  },

  [ACTIONS.fetchDeleteContractTypes.request]: (state) => ({
    ...state,
    delete: {
      ...state.delete,
      fetchStatus: FETCH_STATUSES.REQUEST,
    },
  }),
  [ACTIONS.fetchDeleteContractTypes.success]: (state, action) => ({
    ...state,
    successMessage: (action as TYPES.FetchDeleteContractSuccess).payload.isFolder
      ? ContractSuccessMessages.CONTRACT_FOLDER_DELETED
      : ContractSuccessMessages.CONTRACT_DELETED,
    delete: {
      ...state.delete,
      fetchStatus: FETCH_STATUSES.SUCCESS,
      error: null,
    },
  }),
  [ACTIONS.fetchDeleteContractTypes.failure]: (state, action) => ({
    ...state,
    delete: {
      ...state.delete,
      fetchStatus: FETCH_STATUSES.FAILURE,
      error: (action as FetchFailureAction).payload,
    },
  }),

  [TYPES.ContractsListActions.CLEAR_DELETE_CONTRACT]: (state) => ({
    ...state,
    delete: initialState.delete,
  }),
  [TYPES.ContractsListActions.CONTRACTS_LIST_CLEAR_SUCCESS_MESSAGE]: (state) => ({
    ...state,
    successMessage: initialState.successMessage,
  }),
  [TYPES.ContractsListActions.CONTRACTS_LIST_FORCE_UPDATE]: (state) => ({
    ...state,
    forceUpdate: state.forceUpdate + 1,
  }),
  [TYPES.ContractsListActions.CONTRACTS_LIST_SET_IS_FIRST_LOAD]: (state) => ({
    ...state,
    isFirstLoad: initialState.isFirstLoad,
  }),

  [ACTIONS.fetchRenameContractTypes.request]: (state) => ({
    ...state,
    rename: {
      ...state.rename,
      fetchStatus: FETCH_STATUSES.REQUEST,
    },
  }),
  [ACTIONS.fetchRenameContractTypes.success]: (state, action) => {
    const payload = (action as TYPES.FetchRenameContractSuccess).payload
    const data = state.data.map((item) => (item.id === payload.id ? { ...item, name: payload.name } : item))

    return {
      ...state,
      data,
      successMessage: ContractSuccessMessages.NAME_CHANGED,
      rename: {
        ...state.rename,
        fetchStatus: FETCH_STATUSES.SUCCESS,
        error: null,
      },
    }
  },
  [ACTIONS.fetchRenameContractTypes.failure]: (state, action) => ({
    ...state,
    rename: {
      ...state.rename,
      fetchStatus: FETCH_STATUSES.FAILURE,
      error: (action as FetchFailureAction).payload,
    },
  }),

  [TYPES.ContractsListActions.CLEAR_RENAME_CONTRACT]: (state) => ({
    ...state,
    rename: initialState.rename,
  }),

  [ACTIONS.fetchMoveContractToFolderTypes.request]: (state) => ({
    ...state,
    moveToFolder: {
      ...state.moveToFolder,
      fetchStatus: FETCH_STATUSES.REQUEST,
    },
  }),
  [ACTIONS.fetchMoveContractToFolderTypes.success]: (state) => ({
    ...state,
    successMessage: ContractSuccessMessages.CONTRACT_MOVED_TO_FOLDER,
    moveToFolder: {
      ...state.moveToFolder,
      fetchStatus: FETCH_STATUSES.SUCCESS,
      error: null,
    },
  }),
  [ACTIONS.fetchMoveContractToFolderTypes.failure]: (state, action) => ({
    ...state,
    moveToFolder: {
      ...state.moveToFolder,
      fetchStatus: FETCH_STATUSES.FAILURE,
      error: (action as FetchFailureAction).payload,
    },
  }),

  [TYPES.ContractsListActions.CLEAR_MOVE_CONTRACT_TO_FOLDER]: (state) => ({
    ...state,
    moveToFolder: initialState.moveToFolder,
  }),

  [ACTIONS.fetchDuplicateContractTypes.request]: (state) => ({
    ...state,
    duplicate: {
      ...state.duplicate,
      fetchStatus: FETCH_STATUSES.REQUEST,
    },
  }),
  [ACTIONS.fetchDuplicateContractTypes.success]: (state) => ({
    ...state,
    successMessage: ContractSuccessMessages.CONTRACT_DUPLICATED,
    duplicate: {
      ...state.duplicate,
      fetchStatus: FETCH_STATUSES.SUCCESS,
      error: null,
    },
  }),
  [ACTIONS.fetchDuplicateContractTypes.failure]: (state, action) => ({
    ...state,
    duplicate: {
      ...state.duplicate,
      fetchStatus: FETCH_STATUSES.FAILURE,
      error: (action as FetchFailureAction).payload,
    },
  }),

  [TYPES.ContractsListActions.CLEAR_DUPLICATE_CONTRACT]: (state) => ({
    ...state,
    duplicate: initialState.duplicate,
  }),
  [TYPES.ContractsListActions.CONTRACTS_LIST_GO_TO_FOLDER]: (state, action) => {
    const breadcrumb = (action as TYPES.ContractsListGoToFolder).payload.breadcrumb
    const rootFolderId = state.breadcrumbs[0].value

    return {
      ...state,
      breadcrumbs:
        rootFolderId === breadcrumb.value ? [...initialState.breadcrumbs] : [...state.breadcrumbs, breadcrumb],
      folderId: breadcrumb.value,
      fetchStatus: FETCH_STATUSES.REQUEST,
      offset: initialState.offset,
    }
  },

  [fetchUploadFolderTypes.success]: (state) => ({
    ...state,
    successMessage: ContractSuccessMessages.FOLDER_UPLOADED,
  }),
  [fetchSendContractTypes.success]: (state) => ({
    ...state,
    successMessage: ContractSuccessMessages.CONTRACT_SENT_SUCCESSFULLY,
  }),
  [fetchDeclineSigningTypes.success]: (state) => ({
    ...state,
    successMessage: ContractSuccessMessages.CONTRACT_DECLINED,
  }),

  [fetchVoidContractTypes.success]: (state, action) => {
    const { contractId } = (action as FetchVoidContractSuccess).payload
    const data = state.data.map((item) => (item.id === contractId ? { ...item, status: CONTRACT_STATUS.VOIDED } : item))

    return { ...state, data }
  },

  [fetchSignContractTypes.success]: (state, action) => {
    const { contractId } = (action as FetchSignContractSuccess).payload
    const data = state.data.map((item) => (item.id === contractId ? { ...item, status: CONTRACT_STATUS.SIGNED } : item))

    return { ...state, data }
  },

  [TYPES.ContractsListActions.SET_CONTRACTS_NOTIFICATION]: (state, action) => ({
    ...state,
    notification: (action as TYPES.SetContractsNotificationAction).payload,
  }),
  [TYPES.ContractsListActions.CLEAN_CONTRACTS_NOTIFICATION]: (state) => ({
    ...state,
    notification: initialState.notification,
    successMessage: initialState.successMessage,
    error: initialState.error,
  }),
  [TYPES.ContractsListActions.SET_CONTRACT_STATUS]: (state, action) => {
    const { contractId, status } = (action as TYPES.SetContractStatusAction).payload
    const data = state.data.map((item) => (item.id === contractId ? { ...item, status } : item))

    return { ...state, data }
  },
})
