import styled from 'styled-components'

export const Bell = styled.div`
  position: relative;
`

export const UnreadMark = styled.div`
  position: absolute;
  top: 1px;
  right: -3px;
  width: 16px;
  height: 16px;
  background-color: ${({ theme }) => theme.palette.errorColor};
  border-radius: 50%;
  border: 1px solid ${({ theme }) => theme.palette.white};
`
