import * as yup from 'yup'

export const EMAIL_MIN_LENGTH = 6
export const EMAIL_MAX_LENGTH = 320

export const emailValidationSchema = yup
  .string()
  .required('REQUIRED_FIELD')
  .email('INVALID_EMAIL')
  .min(EMAIL_MIN_LENGTH, 'INVALID_EMAIL')
  .max(EMAIL_MAX_LENGTH, 'INVALID_EMAIL')
