import * as yup from 'yup'

import { ContactUsFields } from '../../types/contactUs'

export const CONTACT_US_MESSAGE_MAX_LENGTH = 1000

export const contactUsValidationSchema = yup.object().shape({
  [ContactUsFields.message]: yup
    .string()
    .trim()
    .required('REQUIRED_FIELD')
    .max(CONTACT_US_MESSAGE_MAX_LENGTH, 'INVALID_MESSAGE'),
})
