import React from 'react'

import { SvgIconProps } from '../../ui/SvgIcon/SvgIcon.types'
import SvgIcon from '../../ui/SvgIcon'

export const DocApprovedIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <path d="M16.125 7.3125V6.1875C16.125 6.08437 16.0406 6 15.9375 6H6.9375C6.83437 6 6.75 6.08437 6.75 6.1875V7.3125C6.75 7.41563 6.83437 7.5 6.9375 7.5H15.9375C16.0406 7.5 16.125 7.41563 16.125 7.3125ZM6.9375 9.375C6.83437 9.375 6.75 9.45937 6.75 9.5625V10.6875C6.75 10.7906 6.83437 10.875 6.9375 10.875H11.25C11.3531 10.875 11.4375 10.7906 11.4375 10.6875V9.5625C11.4375 9.45937 11.3531 9.375 11.25 9.375H6.9375ZM15.75 12.0938C12.9539 12.0938 10.6875 14.3602 10.6875 17.1562C10.6875 19.9523 12.9539 22.2188 15.75 22.2188C18.5461 22.2188 20.8125 19.9523 20.8125 17.1562C20.8125 14.3602 18.5461 12.0938 15.75 12.0938ZM18.2695 19.6758C17.5969 20.3484 16.7016 20.7188 15.75 20.7188C14.7984 20.7188 13.9031 20.3484 13.2305 19.6758C12.5578 19.0031 12.1875 18.1078 12.1875 17.1562C12.1875 16.2047 12.5578 15.3094 13.2305 14.6367C13.9031 13.9641 14.7984 13.5938 15.75 13.5938C16.7016 13.5938 17.5969 13.9641 18.2695 14.6367C18.9422 15.3094 19.3125 16.2047 19.3125 17.1562C19.3125 18.1078 18.9422 19.0031 18.2695 19.6758ZM17.8359 15.375H16.7977C16.7367 15.375 16.6805 15.4031 16.6453 15.4523L15.157 17.5102L14.6156 16.7625C14.5983 16.7383 14.5754 16.7187 14.5489 16.7053C14.5224 16.6918 14.493 16.6849 14.4633 16.6852H13.4297C13.2773 16.6852 13.1883 16.8586 13.2773 16.9828L15.007 19.3758C15.082 19.4789 15.2344 19.4789 15.3094 19.3758L17.9859 15.6727C18.0773 15.5484 17.9883 15.375 17.8359 15.375ZM10.3125 19.9688H4.875V3.46875H18V11.5312C18 11.6344 18.0844 11.7188 18.1875 11.7188H19.5C19.6031 11.7188 19.6875 11.6344 19.6875 11.5312V2.53125C19.6875 2.11641 19.3523 1.78125 18.9375 1.78125H3.9375C3.52266 1.78125 3.1875 2.11641 3.1875 2.53125V20.9062C3.1875 21.3211 3.52266 21.6562 3.9375 21.6562H10.3125C10.4156 21.6562 10.5 21.5719 10.5 21.4688V20.1562C10.5 20.0531 10.4156 19.9688 10.3125 19.9688Z" />
  </SvgIcon>
)
