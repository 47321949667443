import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ReactDOM from 'react-dom'

import { Contact } from '../../../../../types/contacts'
import { RECIPIENTS_DIAGRAM_MODALS } from '../../../../constants/recipientsDiagram'
import { ADD_RECIPIENTS_MODALS } from '../../../../constants/addRecipients'
import { COMMON_MODAL_NODE_ID } from '../../../../constants/modals'
import { modalsVisibleSelector } from '../../../../../store/modals/modals.selectors'
import { closeModal } from '../../../../../store/modals/modals.actions'
import { ContactsModal } from '../../../../ui/Modals/ContractAddRecipientsModals/ContactsModal/ContactsModal'
import CancelChangesModal from '../CancelChangesModal'
import SaveConfirmModal from '../SaveConfirmModal'

interface ModalsProps {
  onSelectContact: (contact: Contact) => void
  onConfirmUpdate?: () => void
  onCloseRecipientModal: () => void
}

export const Modals: React.FC<ModalsProps> = ({ onSelectContact, onConfirmUpdate, onCloseRecipientModal }) => {
  const commonModalNode = document.getElementById(COMMON_MODAL_NODE_ID)
  const dispatch = useDispatch()

  const showContactsModal = useSelector(modalsVisibleSelector(ADD_RECIPIENTS_MODALS.ADD_RECIPIENTS_CONTACTS))
  const onCloseContactsModal = () => {
    dispatch(closeModal(ADD_RECIPIENTS_MODALS.ADD_RECIPIENTS_CONTACTS))
  }

  const showCancelChangesModal = useSelector(modalsVisibleSelector(ADD_RECIPIENTS_MODALS.CANCEL_RECIPIENT_CHANGES))
  const onCloseCancelChangesModal = () => {
    dispatch(closeModal(ADD_RECIPIENTS_MODALS.CANCEL_RECIPIENT_CHANGES))
  }

  const showSaveConfirmModal = useSelector(
    modalsVisibleSelector(RECIPIENTS_DIAGRAM_MODALS.CONFIRM_SAVE_DIAGRAM_RECIPIENT)
  )
  const handleCloseSaveConfirmModal = () => {
    dispatch(closeModal(RECIPIENTS_DIAGRAM_MODALS.CONFIRM_SAVE_DIAGRAM_RECIPIENT))
  }

  const onCancelChanges = () => {
    onCloseRecipientModal()
    onCloseCancelChangesModal()
  }

  if (!commonModalNode) {
    return null
  }

  return (
    <>
      {showContactsModal &&
        ReactDOM.createPortal(
          <ContactsModal onClose={onCloseContactsModal} onSelect={onSelectContact} />,
          commonModalNode
        )}
      {showCancelChangesModal &&
        ReactDOM.createPortal(
          <CancelChangesModal onClose={onCloseCancelChangesModal} onSuccess={onCancelChanges} />,
          commonModalNode
        )}
      {showSaveConfirmModal &&
        onConfirmUpdate &&
        ReactDOM.createPortal(
          <SaveConfirmModal onConfirmUpdate={onConfirmUpdate} onClose={handleCloseSaveConfirmModal} />,
          commonModalNode
        )}
    </>
  )
}
