import { createReducer } from '../createReducer'

import * as TYPES from './modals.types'

type Actions = TYPES.CloseModalAction | TYPES.OpenModalAction<unknown>

export const initialState: TYPES.ModalsState = {
  visible: {},
  payloads: {},
}

export const modals = createReducer<TYPES.ModalsState, Actions>(initialState, {
  [TYPES.ModalsActions.OPEN_MODAL]: (state, { payload }: TYPES.OpenModalAction<unknown>) => ({
    ...state,
    visible: {
      ...state.visible,
      [payload.id]: true,
    },
    payloads: {
      ...state.payloads,
      [payload.id]: payload.data,
    },
  }),
  [TYPES.ModalsActions.CLOSE_MODAL]: (state, { payload }: TYPES.CloseModalAction) => ({
    ...state,
    visible: {
      ...state.visible,
      [payload.id]: false,
    },
    payloads: {
      ...state.payloads,
      [payload.id]: null,
    },
  }),
})
