import React from 'react'

import { SpinnerProps } from './Spinner.types'
import { StyledWrapper, StyledCircleBg, StyledCircle, StyledSVG } from './Spinner.styles'

const RADIUS = 15.915
const POSITION = RADIUS + 1
const WIDTH = POSITION * 2
const VIEW_BOX = `0 0 ${WIDTH} ${WIDTH}`

export const Spinner: React.FC<SpinnerProps> = ({ show }) => (
  <StyledWrapper test-id="spinner-div">
    {show && (
      <StyledSVG test-id="spinner-svg" viewBox={VIEW_BOX}>
        <StyledCircleBg test-id="spinner-circle-bg" cx={POSITION} cy={POSITION} r={RADIUS} />
        <StyledCircle test-id="spinner-circle" cx={POSITION} cy={POSITION} r={RADIUS} />
      </StyledSVG>
    )}
  </StyledWrapper>
)
