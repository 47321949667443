import { useEffect, useState } from 'react'

export const useMediaMode = (mode: 'portrait' | 'landscape') => {
  const mediaQueryList = window.matchMedia(`(orientation: ${mode})`)
  const [value, setValue] = useState(mediaQueryList.matches)

  useEffect(() => {
    const handler = (event: MediaQueryListEvent) => {
      setValue(event.matches)
    }

    mediaQueryList.addListener(handler)

    return () => {
      mediaQueryList.removeListener(handler)
    }
  }, [])

  return value
}
