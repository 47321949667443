import React from 'react'

export const EmailIcon: React.FC = (props) => (
  <svg width="156" height="96" viewBox="0 0 156 96" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M38 43.5V39.3365L46 32.8722V36.7292L41 40.7694V42.0392L46 45.9558V49.5L38 43.5Z" fill="#6085B3" />
    <path
      d="M110 49.5L117.89 43.5V39.3365L110 32.9609V36.8178L114.89 40.7694V42.0888L110 46.1368V49.5Z"
      fill="#6085B3"
    />
    <path
      d="M81.7613 14H86.5345L80.0144 8.73148C78.8073 7.75617 77.083 7.75617 75.8759 8.73148L69.3558 14H74.1291L77.7614 11.0649C77.8686 10.9784 78.0218 10.9784 78.1289 11.0649L81.7613 14Z"
      fill="#6085B3"
    />
    <path d="M95.824 36.863H59.6631V32.8451H95.824V36.863Z" fill="#C3C8D2" />
    <path d="M95.824 50.2708H59.6631V46.2529H95.824V50.2708Z" fill="#C3C8D2" />
    <path
      d="M105.982 50V16.1414H49.9122V50L45.9395 49V14.1099C45.9395 12.9813 46.8423 12.1235 47.9258 12.1235H108.013C109.142 12.1235 110 13.0264 110 14.1099V49L105.982 50Z"
      fill="#C3C8D2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M68 63L38 39.4092V84.4309C38 86.2482 39.4748 87.7231 41.2922 87.7231H114.598C116.415 87.7231 117.89 86.2482 117.89 84.4309V39.4092L89 63L78.5 55.5L68 63ZM78.5 59.1867L67.9135 66.7485L41 45.5847V84.4309C41 84.5914 41.1317 84.7231 41.2922 84.7231H114.598C114.759 84.7231 114.89 84.5914 114.89 84.4309V45.732L89.1218 66.7737L78.5 59.1867Z"
      fill="#6085B3"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M78.2007 58.5832L45.7805 84.723H110.987L78.5675 58.5832L80.4505 56.2478L117.446 86.0769C117.275 86.372 117.06 86.6383 116.81 86.8664C116.251 87.3757 115.516 87.695 114.706 87.7212C114.67 87.7224 114.634 87.723 114.598 87.723H41.2922C40.2727 87.723 39.3619 87.2592 38.7578 86.5316L76.3177 56.2478C77.5236 55.2755 79.2445 55.2755 80.4505 56.2478L78.5675 58.5832C78.4605 58.497 78.3076 58.497 78.2007 58.5832Z"
      fill="#6085B3"
    />
  </svg>
)
