import { FetchFailureAction, FetchFailurePayload } from '../../../types/fetch'
import { Option } from '../../../types/options'
import { createFetchActionTypes } from '../../../utils/createFetchActionTypes'

import * as TYPES from './briefList.types'

export const fetchBriefListTypes = createFetchActionTypes(TYPES.BriefListActions.FETCH_BRIEF_CONTRACTS)

export const fetchBriefList = (): TYPES.FetchBriefList => ({
  type: fetchBriefListTypes.request,
})

export const fetchBriefListSuccess = (data: Option[]): TYPES.FetchBriefListSuccess => ({
  type: fetchBriefListTypes.success,
  payload: { data },
})

export const fetchBriefListFailure = (payload: FetchFailurePayload): FetchFailureAction => ({
  type: fetchBriefListTypes.failure,
  payload,
})

export const cleanBriefList = () => ({
  type: TYPES.BriefListActions.CLEAN_BRIEF_CONTRACTS,
})
