import { EditableElem } from '../types/editableElem'

export enum DrawableCompNames {
  COMMENT = 'COMMENT',
  CORRECTION = 'CORRECTION',
  STRIKETHROUGH = 'STRIKETHROUGH',
  TEXT = 'TEXT',
  DATE = 'DATE',
}

export type DrawingStyle = 'one-step' | 'two-step'

export interface DrawableElem extends EditableElem {
  type: DrawableCompNames
  page: number
}
