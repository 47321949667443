import React from 'react'
import { Provider } from 'react-redux'
import { ThemeProvider, createGlobalStyle, ThemedStyledProps } from 'styled-components'
import { Reset as ResetCSS } from 'styled-reset'

import theme, { StyledThemeScheme } from '../themes/light'
import store from '../store'
import RTL from '../utils/rtl'

import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary'
import NavControlsProvider from './providers/NavControlsProvider'
import { Router } from './routes/routing'
import NotificationsSubscriber from './components/NotificationsSubscriber'

import './index.css'
import '../translations/i18n'

export const GlobalCommonStyle = createGlobalStyle`
  html {
    height: 100%;
    box-sizing: border-box;
  }
  *, *:before, *:after {
    box-sizing: inherit;
  }
  body {
    height: 100%;
    ${({ theme }: ThemedStyledProps<unknown, StyledThemeScheme>) => ({ ...theme.textStyles.body14 })};
  }
  b, strong {
    font-family: 'Tahoma-Bold';
  }
  #root {
    height: 100%;
  }
`
export const App = () => (
  <ErrorBoundary global>
    <Provider store={store}>
      <ResetCSS />
      <ThemeProvider theme={theme}>
        <RTL>
          <NavControlsProvider>
            <GlobalCommonStyle theme={theme} />
            <NotificationsSubscriber />
            <Router />
          </NavControlsProvider>
        </RTL>
      </ThemeProvider>
    </Provider>
  </ErrorBoundary>
)
