import React, { useState, forwardRef } from 'react'

import { HelpTooltip } from '../HelpTooltip/HelpTooltip'
import translations from '../../../translations/keys'
import { inputIcons } from '../InputIcons'

import { TextInputProps, TEXT_INPUT_TYPES } from './TextInput.types'
import {
  StyledWrapper,
  StyledSearchWrapper,
  StyledInputWrapper,
  StyledLabel,
  StyledPrefix,
  StyledInput,
  StyledIconWrapper,
  StyledError,
  StyledNote,
} from './TextInput.styles'

export const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
  (
    {
      error,
      label,
      icon,
      onClickIcon,
      prefix,
      onClick,
      disabled,
      onFocus,
      searchable,
      noError,
      iconColor,
      iconViewBox,
      iconSize,
      note,
      help,
      onChange,
      trim,
      ...rest
    },
    ref
  ) => {
    const [isFocus, setIsFocus] = useState(false)
    const Icon = icon ? inputIcons[icon] : undefined
    const SearchIcon = inputIcons.search
    const InputWrapper = searchable ? StyledSearchWrapper : StyledInputWrapper

    const handleFocus = () => {
      setIsFocus(true)
      onFocus && onFocus()
    }
    const handleBlur = () => {
      setIsFocus(false)
    }
    const handleChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
      if (!onChange) {
        return null
      }

      if (trim) {
        event.target.value = event.target.value.trim()
      }

      onChange(event)
    }

    return (
      <StyledWrapper noError={noError}>
        {label && (
          <StyledLabel data-testid="text-input-label" disabled={disabled}>
            {label}
            {help && <HelpTooltip content={help} />}
          </StyledLabel>
        )}
        <InputWrapper isFocus={isFocus} isError={!!error} disabled={disabled}>
          {prefix && (
            <StyledPrefix data-testid="text-input-prefix" disabled={disabled}>
              {prefix}
            </StyledPrefix>
          )}
          {searchable && (
            <StyledPrefix data-testid="text-input-search-icon">
              <SearchIcon />
            </StyledPrefix>
          )}
          <StyledInput
            ref={ref}
            onClick={onClick}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onChange={handleChange}
            data-testid={`text-input-${rest.type}`}
            disabled={disabled}
            {...rest}
          />
          {Icon && (
            <StyledIconWrapper onClick={onClickIcon} data-testid="text-input-icon" disabled={disabled}>
              <Icon color={iconColor} viewBox={iconViewBox} size={iconSize} />
            </StyledIconWrapper>
          )}
        </InputWrapper>
        {!noError && !!error && (
          <StyledError data-testid="text-input-error">{translations[error] || error}</StyledError>
        )}
        {!error && note && <StyledNote>{note}</StyledNote>}
      </StyledWrapper>
    )
  }
)

TextInput.defaultProps = {
  value: '',
  type: TEXT_INPUT_TYPES.TEXT,
  autoComplete: 'off',
  searchable: false,
}

TextInput.displayName = 'TextInput'
