import React from 'react'

import ErrorBoundary from '../../components/ErrorBoundary/ErrorBoundary'

import { TemplateRecipientsDiagram as TemplateRecipientsDiagramView } from './TemplateRecipientsDiagram'

export const TemplateRecipientsDiagram = () => (
  <ErrorBoundary>
    <TemplateRecipientsDiagramView />
  </ErrorBoundary>
)
