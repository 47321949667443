import React from 'react'

import { SvgIconProps } from '../../ui/SvgIcon/SvgIcon.types'
import SvgIcon from '../../ui/SvgIcon'

export const InteractionIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <path d="M20.625 2.625H3.375C2.96016 2.625 2.625 2.96016 2.625 3.375V20.625C2.625 21.0398 2.96016 21.375 3.375 21.375H20.625C21.0398 21.375 21.375 21.0398 21.375 20.625V3.375C21.375 2.96016 21.0398 2.625 20.625 2.625ZM19.6875 19.6875H4.3125V4.3125H19.6875V19.6875ZM7.14375 12.2812H8.33203C8.41875 12.2812 8.49141 12.2109 8.49141 12.1219V10.2727C8.49141 9.81094 8.86406 9.43828 9.32344 9.43828H14.1445V10.6898C14.1445 10.8234 14.2969 10.8961 14.4 10.8141L16.957 8.80547C17.0391 8.74219 17.0391 8.61797 16.957 8.55469L14.4 6.54609C14.2969 6.46406 14.1445 6.53906 14.1445 6.67031V7.92188H9.32109C8.02969 7.92188 6.98438 8.97188 6.98438 10.268V12.1172C6.98438 12.2109 7.05469 12.2812 7.14375 12.2812ZM7.04531 15.443L9.60234 17.4516C9.70547 17.5336 9.85781 17.4586 9.85781 17.3273V16.0758H14.6789C15.9703 16.0758 17.0156 15.0258 17.0156 13.7297V11.8805C17.0156 11.7937 16.9453 11.7211 16.8563 11.7211H15.668C15.5813 11.7211 15.5086 11.7914 15.5086 11.8805V13.7297C15.5086 14.1914 15.1359 14.5641 14.6766 14.5641H9.85781V13.3125C9.85781 13.1789 9.70547 13.1063 9.60234 13.1883L7.04531 15.1969C6.96328 15.2555 6.96328 15.3797 7.04531 15.443Z" />
  </SvgIcon>
)
