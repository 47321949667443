import React from 'react'

import { SvgIconProps } from '../../ui/SvgIcon/SvgIcon.types'
import SvgIcon from '../../ui/SvgIcon'

export const EditIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <path d="M22 20H2V22H22V20Z" />
    <path d="M16.3402 0L4.00028 12.34V18H9.66028L22.0002 5.66L16.3402 0ZM8.83027 15.9999H6.00029V13.17L16.3402 2.82998L19.1702 5.65996L8.83027 15.9999Z" />
  </SvgIcon>
)
