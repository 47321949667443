import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { RESTORE_PASSWORD_NOTIFICATIONS } from '../../../constants/restorePassword'
import { SuccessChangedModal } from '../../components/Modals/RestorePasswordModals/SuccessChangedModal'
import { modalsVisibleSelector } from '../../../store/modals/modals.selectors'
import { closeModal } from '../../../store/modals/modals.actions'
import { location } from '../../../utils/location'

export const RestorePasswordModals = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const showNotificationSuccessChanged = useSelector(
    modalsVisibleSelector(RESTORE_PASSWORD_NOTIFICATIONS.NOTIFICATION_SUCCESS_CHANGED)
  )
  const onCloseNotificationSuccessChanged = () => {
    dispatch(closeModal(RESTORE_PASSWORD_NOTIFICATIONS.NOTIFICATION_SUCCESS_CHANGED))
    history.push(location.loginUrl())
  }

  return <>{showNotificationSuccessChanged && <SuccessChangedModal onClose={onCloseNotificationSuccessChanged} />}</>
}
