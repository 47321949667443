import { FormikProps } from 'formik'
import { useEffect } from 'react'

interface Options {
  errorCode?: string
}

export const useCleanFields = <V>(
  clean = false,
  fields: string[],
  formRef: React.RefObject<FormikProps<V>>,
  options?: Options
) => {
  useEffect(() => {
    const form = formRef.current

    if (clean && form) {
      fields.forEach((field) => {
        form.setFieldValue(field, '', false)

        if (options?.errorCode) {
          form.setFieldError(field, options.errorCode)
        } else {
          form.setFieldTouched(field, false, false)
        }
      })
    }
  }, [clean, formRef.current])
}
