export enum SaveAsTemplateFields {
  folderName = 'folderName',
  name = 'name',
}

export interface SaveAsTemplateForm {
  folderName: string
  name: string
}

export interface SaveAsTemplateBody extends SaveAsTemplateForm {
  contractId: string
}

export interface SaveAsTemplateResponse {
  id: string
}
