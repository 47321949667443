import styled from 'styled-components'

import Box from '../../../Box'

export const StyledTab = styled(Box).attrs({
  pb: 1,
  pt: 1,
  pl: 2,
  pr: 2,
})<{ isActive: boolean; animating: boolean }>`
  position: relative;
  border-bottom: 1px solid
    ${({ theme, isActive, animating }) => (!animating && isActive ? theme.palette.blue : theme.palette.strokeGrey)};
  color: ${({ theme, isActive }) => (isActive ? theme.palette.textColor : theme.palette.grey)};
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.palette.textColor};
  }
`

export const StyledErrorsCount = styled.div`
  position: absolute;
  color: ${({ theme }) => theme.palette.errorColor};
  border: 1px solid ${({ theme }) => theme.palette.errorColor};
  border-radius: 50%;
  width: 16px;
  height: 16px;
  line-height: 15px;
  text-align: center;
  font-size: 8px;
  top: 1px;
  right: -3px;
`
