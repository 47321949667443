import { useDispatch } from 'react-redux'
import { useTheme } from 'styled-components'
import { Dispatch } from 'redux'
import { useMemo } from 'react'
import { History } from 'history'
import { useHistory } from 'react-router'

import translations from '../../../../../../translations/keys'
import {
  CopyIcon,
  DeleteIcon,
  EmptyFileIcon,
  FolderIcon,
  ForwardIcon,
  InfoIcon,
  RenameIcon,
} from '../../../../../assets/icons'
import { TableData } from '../../../../../ui/Table/Table.types'
import { Contract, CONTRACT_STATUS } from '../../../../../../types/contracts'
import { openModal } from '../../../../../../store/modals/modals.actions'
import { CONTRACTS_MODALS } from '../../../../../../constants/contracts'
import { contractsListGoToFolder } from '../../../../../../store/contracts/contractsList/contractsList.actions'
import { TableAction } from '../../../../../ui/TableActions/TableActions.types'
import { StyledThemeScheme } from '../../../../../../themes/light'
import useMediaQuery from '../../../../../hooks/useMediaQuery'
import { location } from '../../../../../../utils/location'
import { useAccessFeature } from '../../../../../../hooks/subscription/useAccessFeature'
import { SubscriptionFeatures } from '../../../../../../types/subscription'
import { fetchSigningContractDocument } from '../../../../../../store/contracts/signing/signing.actions'
import { COMMON_MODALS_IDS } from '../../../../../ui/Modals/CommonModals/CommonModals.constants'
import { NOTIFICATION_MODAL_TYPES } from '../../../../../ui/Modals/NotificationModal/NotificationModal.constants'

export const openContract = (dispatch: Dispatch, history: History, contract: Contract) => {
  if (contract.isFolder) {
    dispatch(contractsListGoToFolder({ name: contract.name, value: contract.id }))
  } else {
    const { signed, owner, id, recipient, status } = contract
    const isPreview = owner && status === CONTRACT_STATUS.DRAFT
    const isSign = (recipient && !owner) || (recipient && owner && status === CONTRACT_STATUS.READY_TO_SIGN)
    const isExpired = status === CONTRACT_STATUS.USER_TERM_EXPIRED && !owner
    const isOwnerView = owner && status !== CONTRACT_STATUS.READY_TO_SIGN
    const isOpeningChoice = !signed && owner && recipient && status !== CONTRACT_STATUS.COMPLETED

    if (isExpired) {
      dispatch(
        openModal(COMMON_MODALS_IDS.NOTIFICATION, {
          type: NOTIFICATION_MODAL_TYPES.WARNING,
          description: translations.EXPIRED_CONTRACT_NOTIFICATION,
        })
      )
    } else if (isOpeningChoice) {
      dispatch(openModal(CONTRACTS_MODALS.OPEN_CONTRACT_AS, contract))
    } else if (isSign) {
      dispatch(fetchSigningContractDocument(id))
    } else if (isPreview) {
      history.push(location.contractsPreviewUrl(id))
    } else if (isOwnerView) {
      history.push(location.contractsOwnerViewUrl(id))
    }
  }
}

export const useActions = (): TableAction[] => {
  const history = useHistory()
  const dispatch = useDispatch()
  const theme = useTheme() as StyledThemeScheme
  const mobileScreen = useMediaQuery(theme.devices.mobile)
  const { checkAccess } = useAccessFeature()

  return useMemo(
    () => [
      {
        name: translations.OPEN,
        Icon: ForwardIcon,
        callback: (data) => {
          openContract(dispatch, history, data as Contract)
        },
      },
      {
        name: translations.RENAME,
        Icon: RenameIcon,
        callback: ({ id, name, isFolder }) => {
          dispatch(
            openModal(CONTRACTS_MODALS.RENAME_CONTRACT, {
              id,
              title: isFolder ? translations.RENAME_FOLDER : translations.RENAME_FILE,
              oldName: name,
              isFolder,
            })
          )
        },
        show: (data: TableData) => {
          const contract = data as Contract

          return contract.isFolder || contract.owner
        },
      },
      {
        name: translations.MOVE_TO_FOLDER,
        Icon: FolderIcon,
        callback: ({ id, recipient }) => {
          dispatch(openModal(CONTRACTS_MODALS.CONTRACT_MOVE_TO_FOLDER, { id, recipient }))
        },
        show: (data: TableData) => {
          const contract = data as Contract

          return !contract.isFolder
        },
      },
      {
        name: translations.CONTRACT_SAVE_AS_TEMPLATE,
        Icon: EmptyFileIcon,
        callback: ({ id, name }) => {
          if (checkAccess(SubscriptionFeatures.FEATURE_USER_TEMPLATES)) {
            dispatch(openModal(CONTRACTS_MODALS.SAVE_CONTRACT_AS_TEMPLATE, { id, oldName: name }))
          }
        },
        show: (data: TableData) => {
          const contract = data as Contract

          return !contract.isFolder && contract.owner
        },
      },
      {
        name: translations.DUPLICATE,
        Icon: CopyIcon,
        callback: ({ id, name }) => {
          dispatch(openModal(CONTRACTS_MODALS.DUPLICATE_CONTRACT, { id, oldName: name }))
        },
        show: (data) => {
          const contract = data as Contract

          return !contract.isFolder && contract.owner
        },
      },
      {
        name: translations.VIEW_INFO,
        Icon: InfoIcon,
        callback: (contract) => {
          dispatch(openModal(CONTRACTS_MODALS.VIEW_CONTRACT_INFO, { contract }))
        },
        show: (data) => {
          const contract = data as Contract

          return mobileScreen && !contract.isFolder
        },
      },
      {
        name: translations.DELETE,
        Icon: DeleteIcon,
        callback: (data) => {
          const { id, isFolder, status, owner, recipient } = data as Contract
          let description = translations.DELETE_DRAFT_CONTRACT_DESCRIPTION

          if (isFolder) {
            description = translations.DELETE_FOLDER_DESCRIPTION
          } else if (status !== CONTRACT_STATUS.DRAFT && owner) {
            description = translations.DELETE_OWN_CONTRACT_DESCRIPTION
          }

          dispatch(
            openModal(CONTRACTS_MODALS.DELETE_CONTRACT, {
              description,
              id,
              isFolder,
              recipient,
            })
          )
        },
        show: (data) => {
          const contract = data as Contract

          return (
            contract.isFolder || contract.owner || (contract.recipient && contract.status === CONTRACT_STATUS.COMPLETED)
          )
        },
      },
    ],
    [checkAccess]
  )
}
