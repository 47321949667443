import { FETCH_STATUSES } from '../../types/fetch'
import { createReducer } from '../createReducer'

import * as TYPES from './industries.types'

type Actions = TYPES.FetchIndustriesAction | TYPES.FetchIndustriesSuccessAction | TYPES.FetchIndustriesFailureAction

export const initialState: TYPES.IndustriesState = {
  fetchStatus: FETCH_STATUSES.IDLE,
  data: [],
}

export const industries = createReducer<TYPES.IndustriesState, Actions>(initialState, {
  [TYPES.IndustriesActions.FETCH_INDUSTRIES]: (state) => ({
    ...state,
    fetchStatus: FETCH_STATUSES.REQUEST,
  }),
  [TYPES.IndustriesActions.FETCH_INDUSTRIES_SUCCESS]: (state, action) => ({
    ...state,
    fetchStatus: FETCH_STATUSES.SUCCESS,
    data: (action as TYPES.FetchIndustriesSuccessAction).payload,
  }),
  [TYPES.IndustriesActions.FETCH_INDUSTRIES_FAILURE]: (state, action) => ({
    ...state,
    fetchStatus: FETCH_STATUSES.FAILURE,
    error: (action as TYPES.FetchIndustriesFailureAction).payload,
  }),
})
