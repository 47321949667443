import styled from 'styled-components'

import Box from '../../../../ui/Box'

export const StyledResendCodeWrapper = styled(Box).attrs({
  width: '100%',
})`
  position: relative;
`

export const StyledNotification = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
`
