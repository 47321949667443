import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

import { translationFixes } from './ar/fixes-ar'
import enTranslations from './en/translations'
import arTranslations from './ar/translations'

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources: {
      en: {
        translation: enTranslations,
      },
      ar: {
        translation: { ...arTranslations, ...translationFixes },
      },
    },
    fallbackLng: 'en',
    debug: false,
    detection: {
      order: ['queryString', 'cookie'],
      caches: ['cookie'],
    },
    interpolation: {
      escapeValue: false,
    },
  })

export default i18n
