import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useDispatchUnmount } from '../useDispatchUnmount'
import * as ACTIONS from '../../store/contracts/otpVerification/otpVerification.actions'
import * as SELECTORS from '../../store/contracts/otpVerification/otpVerification.selectors'
import * as VERIFICATION_ACTIONS from '../../store/contracts/verification/verification.actions'

export const useOTPVerificationFetch = (contractId: string) => {
  const dispatch = useDispatch()
  const isVerifyError = useSelector(SELECTORS.otpVerificationVerifyIsError)

  useEffect(() => {
    dispatch(ACTIONS.fetchSendCode(contractId))
    dispatch(ACTIONS.fetchAttempts(contractId))
  }, [contractId])
  useEffect(() => {
    if (isVerifyError) {
      dispatch(ACTIONS.fetchAttempts(contractId))
    }
  }, [contractId, isVerifyError])

  useDispatchUnmount(ACTIONS.cleanOTPVerification, VERIFICATION_ACTIONS.cleanVerification)
}
