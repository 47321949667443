import styled from 'styled-components'

import Box from '../../../../ui/Box'

export const StyledStores = styled(Box).attrs({
  display: 'flex',
  mt: 5,
})`
  ${({ theme }) => `@media ${theme.devices.mobile} {
    flex-direction: column;
  }`}
`
