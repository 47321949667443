import { Languages } from '../../store/language/language.types'

const I18NEXT_COOKIE_NAME = 'i18next'

function getCookie(cname: string) {
  const name = cname + '='
  const decodedCookie = decodeURIComponent(document.cookie)
  const ca = decodedCookie.split(';')
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i]
    while (c.charAt(0) == ' ') {
      c = c.substring(1)
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length)
    }
  }
  return ''
}

export const getDefaultLanguage = () => {
  const langCookie = getCookie(I18NEXT_COOKIE_NAME)

  const language = langCookie ? langCookie : 'en'
  return language as Languages
}
