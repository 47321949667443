import React from 'react'
import ContentLoader from 'react-content-loader'

import { blueExtraLight, glowColor } from '../../../themes/colors'

const FILL_COLOR = blueExtraLight
const GLOW_COLOR = glowColor
const SPEED = 1.5
const SVG_WIDTH = '100%'
const SVG_HEIGHT = 600
const RADIUS = 3
const CYCLE_HEIGHT = 48
const OFFSET = 16
const RECIPIENT_HEIGHT = 67

export const ContractProgressSkeleton: React.FC = () => (
  <ContentLoader
    display="block"
    width={SVG_WIDTH}
    height={SVG_HEIGHT}
    speed={SPEED}
    backgroundColor={FILL_COLOR}
    foregroundColor={GLOW_COLOR}
  >
    <rect width="80%" height={CYCLE_HEIGHT} x="0" y={0} rx={RADIUS} ry={RADIUS} />
    <rect width="100%" height={RECIPIENT_HEIGHT} x="0" y={CYCLE_HEIGHT + OFFSET} rx={RADIUS} ry={RADIUS} />
  </ContentLoader>
)
