import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useAccessFeature } from '../../../hooks/subscription/useAccessFeature'
import { SIGNING_TYPES } from '../../../types/signingOrder'
import { SubscriptionFeatures } from '../../../types/subscription'
import DropdownButton from '../../ui/DropdownButton'
import Typography from '../../ui/Typography'
import { TYPOGRAPHY_NAMES } from '../../ui/Typography/Typography.types'

import { ChangeSigningOrderProps } from './ChangeSigningOrderProps'
import { ITEMS } from './ChangeSigningOrder.constants'

export const ChangeSigningOrder: React.FC<ChangeSigningOrderProps> = ({ disabled, order, onChange }) => {
  const { checkAccess } = useAccessFeature()
  const { t } = useTranslation()
  const orderText = useMemo(() => ITEMS.find((item) => item.value === order), [order])?.name
  const transaltedItems = ITEMS.map((item) => {
    item.name = t(item.name)
    return item
  })

  const handleChange = (value: string) => {
    if (value !== SIGNING_TYPES.SEQUENTIAL || checkAccess(SubscriptionFeatures.FEATURE_CLM)) {
      onChange(value)
    }
  }

  return (
    <DropdownButton disabled={disabled} items={transaltedItems} onChange={handleChange}>
      <Typography isCapitalize name={TYPOGRAPHY_NAMES.body14}>
        {orderText && t(orderText)}
      </Typography>
    </DropdownButton>
  )
}
