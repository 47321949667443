import { combineEpics, Epic, ofType } from 'redux-observable'
import { catchError, map, mergeMap, takeUntil } from 'rxjs/operators'

import { catchFetchError } from '../../../utils/catchFetchError'
import { templateService } from '../../../api'

import * as ACTIONS from './saveAsTemplate.actions'

export const fetchSaveAsTemplate: Epic = (action$) =>
  action$.pipe(
    ofType(ACTIONS.fetchSaveAsTemplateTypes.request),
    mergeMap(({ payload }) =>
      templateService.fetchCreateFromContract(payload.body).pipe(
        map(({ response }) => ACTIONS.fetchSaveAsTemplateSuccess(response)),
        catchError(catchFetchError(ACTIONS.fetchSaveAsTemplateFailure)),
        takeUntil(
          action$.pipe(ofType(ACTIONS.fetchSaveAsTemplateTypes.success, ACTIONS.fetchSaveAsTemplateTypes.failure))
        )
      )
    )
  )

export const saveAsTemplateEpics = combineEpics(fetchSaveAsTemplate)
