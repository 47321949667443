import { Action } from 'redux'

import { FetchFailurePayload, FETCH_STATUSES } from '../../types/fetch'
import { CreateSignatureBody, Signature, ValidateSignatureBody } from '../../types/signatures'

export interface ValidateSignatureError {
  signatures?: any[]
}

export interface SignaturesState {
  validateSignature: {
    fetchStatus: FETCH_STATUSES
    error?: FetchFailurePayload<ValidateSignatureError>
  }
  createSignature: {
    fetchStatus: FETCH_STATUSES
    error?: FetchFailurePayload
  }
  list: {
    fetchStatus: FETCH_STATUSES
    error?: FetchFailurePayload
    data: Signature[]
  }
  deleteSignature: {
    fetchStatus: FETCH_STATUSES
    error?: FetchFailurePayload
  }
}

export enum SignaturesActions {
  'FETCH_VALIDATE_SIGNATURE' = 'FETCH_VALIDATE_SIGNATURE',
  'FETCH_CREATE_SIGNATURE' = 'FETCH_CREATE_SIGNATURE',
  'FETCH_SIGNATURES_LIST' = 'FETCH_SIGNATURES_LIST',
  'FETCH_DELETE_SIGNATURE' = 'FETCH_DELETE_SIGNATURE',
  'CLEAN_DELETE_SIGNATURE' = 'CLEAN_DELETE_SIGNATURE',
  'CLEAN_CREATE_SIGNATURE' = 'CLEAN_CREATE_SIGNATURE',
  'CLEAN_SIGNATURES_LIST' = 'CLEAN_SIGNATURES_LIST',
  'CLEAN_VALIDATE_SIGNATURE' = 'CLEAN_VALIDATE_SIGNATURE',
}

export interface FetchValidateSignatureAction extends Action {
  payload: { body: ValidateSignatureBody }
}
export type FetchValidateSignatureSuccessAction = Action
export type CleanValidateSignature = Action

export interface FetchCreateSignatureAction extends Action {
  payload: { body: CreateSignatureBody }
}
export interface FetchCreateSignatureSuccessAction extends Action {
  payload: { body: Signature }
}
export type CleanCreateSignature = Action

export type FetchSignaturesListAction = Action
export interface FetchSignaturesListSuccessAction extends Action {
  payload: { body: Signature[] }
}
export type CleanSignatureList = Action

export interface FetchDeleteSignatureAction extends Action {
  payload: { id: string }
}
export interface FetchDeleteSuccessAction extends Action {
  payload: { id: string }
}
export type CleanDeleteSignature = Action
