import { ContractsState } from './contracts.types'
import { initialState as briefListInitialState } from './briefList/briefList.reducer'
import { initialState as contractsListInitialState } from './contractsList/contractsList.reducer'
import { initialState as downloadInitialState } from './download/download.reducer'
import { initialState as foldersInitialState } from './folders/folders.reducer'
import { initialState as ownerViewInitialState } from './ownerView/ownerView.reducer'
import { initialState as placementInitialState } from './placement/placement.reducer'
import { initialState as previewInitialState } from './preview/preview.reducer'
import { initialState as recipientsInitialState } from './recipients/recipients.reducer'
import { initialState as reuploadInitialState } from './reupload/reupload.reducer'
import { initialState as saveAsTemplateInitialState } from './saveAsTemplate/saveAsTemplate.reducer'
import { initialState as signingInitialState } from './signing/signing.reducer'
import { initialState as uploadInitialState } from './upload/upload.reducer'
import { initialState as voidInitialState } from './void/void.reducer'
import { initialState as messageInitialState } from './message/message.reducer'
import { initialState as otpVerificationInitialState } from './otpVerification/otpVerification.reducer'
import { initialState as verificationInitialState } from './verification/verification.reducer'
import { initialState as noneVerificationInitialState } from './noneVerification/noneVerification.reducer'
import { initialState as strikesInitialState } from './strikes/strikes.reducer'
import { initialState as correctionsInitialState } from './corrections/corrections.reducer'
import { initialState as commentsInitialState } from './comments/comments.reducer'
import { initialState as historyInitialState } from './history/history.reducer'
import { initialState as viewOptionsInitialState } from './viewOptions/viewOptions.reducer'
import { initialState as clearAndContinueInitialState } from './clearAndContinue/clearAndContinue.reducer'

export const contractsInitialState: ContractsState = {
  briefList: briefListInitialState,
  contractsList: contractsListInitialState,
  download: downloadInitialState,
  folders: foldersInitialState,
  ownerView: ownerViewInitialState,
  placement: placementInitialState,
  preview: previewInitialState,
  recipients: recipientsInitialState,
  reupload: reuploadInitialState,
  saveAsTemplate: saveAsTemplateInitialState,
  signing: signingInitialState,
  upload: uploadInitialState,
  void: voidInitialState,
  message: messageInitialState,
  otpVerification: otpVerificationInitialState,
  verification: verificationInitialState,
  noneVerification: noneVerificationInitialState,
  strikes: strikesInitialState,
  corrections: correctionsInitialState,
  comments: commentsInitialState,
  history: historyInitialState,
  viewOptions: viewOptionsInitialState,
  clearAndContinue: clearAndContinueInitialState,
}
