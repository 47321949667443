import { FetchFailureAction, FetchFailurePayload } from '../../types/fetch'
import { Sorting } from '../../types/table'
import { Contact, ContactResponse } from '../../types/contacts'
import { AddContactsRequestBody } from '../../types/addContact'
import { UpdateContactsForm } from '../../types/updateContact'
import { createFetchActionTypes } from '../../utils/createFetchActionTypes'
import { Params } from '../../utils/prepareUrlParams/prepareUrlParams'
import { Notification } from '../../types/notifications'

import * as TYPES from './contacts.types'

export const fetchContactsTypes = createFetchActionTypes(TYPES.ContactsActions.FETCH_CONTACTS)
export const fetchCreateContactTypes = createFetchActionTypes(TYPES.ContactsActions.FETCH_CREATE_CONTACT)
export const fetchUpdateContactTypes = createFetchActionTypes(TYPES.ContactsActions.FETCH_UPDATE_CONTACT)
export const fetchDeleteContactTypes = createFetchActionTypes(TYPES.ContactsActions.FETCH_DELETE_CONTACT)

export const setSorting = (sorting: Sorting): TYPES.SetSorting => ({
  type: TYPES.ContactsActions.SET_SORTING,
  payload: sorting,
})
export const setSearchText = (searchText: string): TYPES.SetSearchText => ({
  type: TYPES.ContactsActions.SET_SEARCH_TEXT,
  payload: { searchText },
})
export const setOffset = (offset: number, loadMore = false): TYPES.SetOffset => ({
  type: TYPES.ContactsActions.SET_OFFSET,
  payload: { offset, loadMore },
})
export const clearFetchStatus = (): TYPES.ClearFetchStatusAction => ({
  type: TYPES.ContactsActions.CLEAR_FETCH_STATUS,
})
export const clearSuccessMessage = (): TYPES.ClearSuccessMessageAction => ({
  type: TYPES.ContactsActions.CLEAR_SUCCESS_MESSAGE,
})
export const clearErrorMessage = (): TYPES.ClearErrorAction => ({
  type: TYPES.ContactsActions.CLEAR_ERROR,
})
export const clearContacts = (): TYPES.ClearContactsAction => ({
  type: TYPES.ContactsActions.CLEAR_CONTACTS,
})

export const fetchContacts = (params: Params): TYPES.FetchContactsAction => ({
  type: fetchContactsTypes.request,
  payload: { params },
})
export const fetchContactsSuccess = (response: ContactResponse): TYPES.FetchContactsSuccessAction => ({
  type: fetchContactsTypes.success,
  payload: response,
})
export const fetchContactsFailure = (payload: FetchFailurePayload): FetchFailureAction => ({
  type: fetchContactsTypes.failure,
  payload,
})

export const fetchCreateContact = (body: AddContactsRequestBody): TYPES.FetchCreateContactAction => ({
  type: fetchCreateContactTypes.request,
  payload: { body },
})
export const fetchCreateContactSuccess = (contact: Contact): TYPES.FetchCreateContactSuccessAction => ({
  type: fetchCreateContactTypes.success,
  payload: { contact },
})
export const fetchCreateContactFailure = (payload: FetchFailurePayload): FetchFailureAction => ({
  type: fetchCreateContactTypes.failure,
  payload,
})

export const fetchUpdateContact = (id: string, body: UpdateContactsForm): TYPES.FetchUpdateContactAction => ({
  type: fetchUpdateContactTypes.request,
  payload: { id, body },
})
export const fetchUpdateContactSuccess = (contact: Contact): TYPES.FetchUpdateContactSuccessAction => ({
  type: fetchUpdateContactTypes.success,
  payload: { contact },
})
export const fetchUpdateContactFailure = (payload: FetchFailurePayload): FetchFailureAction => ({
  type: fetchUpdateContactTypes.failure,
  payload,
})

export const fetchDeleteContact = (id: string, isMobile = false): TYPES.FetchDeleteContactAction => ({
  type: fetchDeleteContactTypes.request,
  payload: { id, isMobile },
})
export const fetchDeleteContactSuccess = (id: string): TYPES.FetchDeleteContactSuccessAction => ({
  type: fetchDeleteContactTypes.success,
  payload: { id },
})
export const fetchDeleteContactFailure = (payload: FetchFailurePayload): FetchFailureAction => ({
  type: fetchDeleteContactTypes.failure,
  payload,
})

export const setContactNotification = (notification: Notification): TYPES.SetContactsNotificationAction => ({
  type: TYPES.ContactsActions.SET_CONTACTS_NOTIFICATION,
  payload: notification,
})
export const cleanContactsNotification = () => ({
  type: TYPES.ContactsActions.CLEAN_CONTACTS_NOTIFICATION,
})
