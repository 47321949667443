import React from 'react'
import { useDispatch } from 'react-redux'

import { closeProgress } from '../../../../../store/progress/progress.actions'
import { CloseIcon } from '../../../../assets/icons'

import * as Styled from './CloseButton.styles'

export const CloseButton = () => {
  const dispatch = useDispatch()

  return (
    <Styled.Wrapper onClick={() => dispatch(closeProgress())}>
      <CloseIcon color="grey" />
    </Styled.Wrapper>
  )
}
