import { FETCH_STATUSES } from '../../../types/fetch'
import { RootState } from '../../rootReducer.types'

export const reuploadFetchStatusSelector = (state: RootState) => state.contracts.reupload.fetchStatus

export const reuploadErrorSelector = (state: RootState) => state.contracts.reupload.error

export const reuploadIsRequestSelector = (state: RootState) =>
  state.contracts.reupload.fetchStatus === FETCH_STATUSES.REQUEST

export const reuploadIsSuccessSelector = (state: RootState) =>
  state.contracts.reupload.fetchStatus === FETCH_STATUSES.SUCCESS
