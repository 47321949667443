import { useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory, useParams } from 'react-router'

import translations from '../../../../translations/keys'
import { ContractRouteParams } from '../../../types/signing'
import { CONTRACT_PLACEMENT_MODALS } from '../../../constants/contractPlacement'
import { location } from '../../../../utils/location'
import { openModal } from '../../../../store/modals/modals.actions'
import { BUTTON_VARIANTS } from '../../../ui/Button/Button.types'
import { PlayIcon, SaveIcon } from '../../../assets/icons'

export const useButtons = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { contractId } = useParams<ContractRouteParams>()

  const buttons = useMemo(
    () => [
      {
        label: translations.CONTRACT_FINISH_LATER,
        onClick: () => {
          history.push(location.contractsAllUrl())
        },
        variant: BUTTON_VARIANTS.SECONDARY,
        MobileIcon: SaveIcon,
      },
      {
        label: translations.SEND_CONTRACT,
        onClick: () => {
          dispatch(openModal(CONTRACT_PLACEMENT_MODALS.CONTRACT_PLACEMENT_SEND, { contractId }))
        },
        MobileIcon: PlayIcon,
      },
    ],
    [contractId]
  )

  return buttons
}
