import { Action } from 'redux'

import { FetchFailurePayload, FETCH_STATUSES } from '../../../types/fetch'
import { SIGNING_TYPES } from '../../../types/signingOrder'
import * as TYPES from '../../../types/recipients'
import { EnabledFeatures } from '../../../types/subscription'
import { DocumentMessageForm } from '../../../types/documents'

export interface RecipientsState {
  contractId: string | null
  contractName: string | null
  sender: TYPES.Sender | null
  order: SIGNING_TYPES
  byId: TYPES.RecipientsById
  byOrder: TYPES.RecipientsByOrder
  sorted: TYPES.RecipientsSorted
  isNegativeStatus: boolean
  fetch: {
    status: FETCH_STATUSES
    error: FetchFailurePayload | null
  }
  add: {
    status: FETCH_STATUSES
    error: FetchFailurePayload | null
  }
  update: {
    status: FETCH_STATUSES
    error: FetchFailurePayload | null
  }
  delete: {
    status: FETCH_STATUSES
    error: FetchFailurePayload | null
  }
  resend: {
    status: FETCH_STATUSES
    error: FetchFailurePayload | null
  }
  messageInitialValues: DocumentMessageForm | null
}

export enum RecipientsActions {
  'SET_CONTRACT_ID' = 'SET_CONTRACT_ID',
  'SET_ORDER' = 'SET_ORDER',
  'SET_RECIPIENTS' = 'SET_RECIPIENTS',
  'CLEAR_RECIPIENTS' = 'CLEAR_RECIPIENTS',
  'FETCH_RECIPIENTS' = 'FETCH_RECIPIENTS',
  'FETCH_ADD_RECIPIENTS' = 'FETCH_ADD_RECIPIENTS',
  'FETCH_UPDATE_RECIPIENT' = 'FETCH_UPDATE_RECIPIENT',
  'FETCH_DELETE_RECIPIENT' = 'FETCH_DELETE_RECIPIENT',
  'FETCH_CONTRACT_INFO' = 'FETCH_CONTRACT_INFO',
  'FETCH_SENDER_INFO' = 'FETCH_SENDER_INFO',
  'FETCH_RESEND' = 'FETCH_RESEND',
}

export interface SetContractIdAction extends Action {
  payload: { id: string }
}
export interface SetOrderAction extends Action {
  payload: { order: SIGNING_TYPES }
}
export interface SetRecipientsAction extends Action {
  payload: { recipients: Required<TYPES.RecipientData>[] }
}
export type ClearRecipientsAction = Action

export interface FetchRecipientsAction extends Action {
  payload: { contractId: string }
}
export interface FetchRecipientsSuccessAction extends Action {
  payload: { recipients: Required<TYPES.RecipientData>[]; enabledFeatures: EnabledFeatures }
}

export interface FetchAddRecipientsAction extends Action {
  payload: { contractId: string; body: TYPES.AddRecipientsForm }
}
export interface FetchAddRecipientsSuccessAction extends Action {
  payload: { recipients: TYPES.RecipientData[] }
}

export interface FetchUpdateRecipientAction extends Action {
  payload: { id: string; body: Omit<TYPES.RecipientData, 'id'> }
}
export interface FetchUpdateRecipientSuccessAction extends Action {
  payload: { id: string; recipient: TYPES.RecipientData }
}

export interface FetchDeleteRecipientAction extends Action {
  payload: { id: string }
}
export interface FetchDeleteRecipientSuccessAction extends Action {
  payload: { id: string }
}

export interface FetchContractInfoAction extends Action {
  payload: { contractId: string }
}
export interface FetchContractInfoSuccessAction extends Action {
  payload: { name: string; messageInitialValues: DocumentMessageForm }
}

export type FetchSenderInfoAction = Action
export interface FetchSenderInfoSuccessAction extends Action {
  payload: TYPES.Sender
}

export interface FetchResendAction extends Action {
  payload: { contractId: string; body: TYPES.AddRecipientsForm }
}
export interface FetchResendSuccessAction extends Action {
  payload: { recipients: TYPES.RecipientData[] }
}
