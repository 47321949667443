import { combineEpics, Epic, ofType } from 'redux-observable'
import { catchError, mergeMap, takeUntil } from 'rxjs/operators'
import { of } from 'rxjs'

import { subscriptionService } from '../../../api'
import { catchFetchError } from '../../../utils/catchFetchError'
import { preparePlans } from '../../../utils/subscription/preparePlans'

import * as ACTIONS from './plans.actions'

const fetchPlans: Epic = (action$) =>
  action$.pipe(
    ofType(ACTIONS.fetchPlansTypes.request),
    mergeMap(() =>
      subscriptionService.fetchPlans().pipe(
        mergeMap((response) => of(ACTIONS.fetchPlansSuccess(preparePlans(response)))),
        catchError(catchFetchError(ACTIONS.fetchPlansFailure)),
        takeUntil(action$.pipe(ofType(ACTIONS.fetchPlansTypes.success, ACTIONS.fetchPlansTypes.failure)))
      )
    )
  )

const fetchPlan: Epic = (action$) =>
  action$.pipe(
    ofType(ACTIONS.fetchPlanTypes.request),
    mergeMap(({ payload }) =>
      subscriptionService.fetchPlans().pipe(
        mergeMap((response) =>
          of(ACTIONS.fetchPlanSuccess(preparePlans(response).find((plan) => plan.id === payload.subscriptionId)!))
        ),
        catchError(catchFetchError(ACTIONS.fetchPlanFailure)),
        takeUntil(action$.pipe(ofType(ACTIONS.fetchPlanTypes.success, ACTIONS.fetchPlanTypes.failure)))
      )
    )
  )

export const plansEpics = combineEpics(fetchPlans, fetchPlan)
