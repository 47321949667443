import React, { useState } from 'react'
import { FormikValues } from 'formik'

import omit from '../../../utils/omit'
import { isString } from '../../../utils/isString'
import { TableActionsKebab } from '../TableActions/TableActionsKebab'

import * as Styled from './Table.styles'
import { TableProps } from './Table.types'
import { TableRow } from './TableRow'

export const Table: React.FC<TableProps> = ({
  data,
  columns,
  validationSchema,
  sorting,
  highlightedText,
  customActions,
  onSort,
  onUpdate,
  onDelete,
  emptyResultsMessage,
}) => {
  const [editingId, setEditingId] = useState<string | null>(null)
  const flipIcon = sorting.direction === 'ASC'
  const withControls = Boolean(onUpdate || onDelete)

  const handleSorting = (field: string) => () => {
    onSort({ field, direction: sorting.direction === 'ASC' ? 'DESC' : 'ASC' })
  }

  const handleEdit = (id: string | null) => {
    setEditingId(id)
  }

  const handleSubmit = (id: string) => (values: FormikValues) => {
    const preparedValues = omit(['id'], values)

    onUpdate && onUpdate(id, preparedValues)
    setEditingId(null)
  }

  return (
    <Styled.Table data-testid="table">
      <Styled.TableRow data-testid="table-head-row">
        {columns.map((column) => {
          const renderArrows = () => {
            if (column.sortable) {
              return sorting.field === column.field ? (
                <Styled.SortingIcon data-testid="table-head-cell-button-icon" flip={flipIcon} />
              ) : (
                <Styled.NeutralIcon />
              )
            }

            return null
          }

          return (
            <Styled.TableHeadCell
              data-testid="table-head-cell"
              key={column.field}
              count={columns.length}
              widthPercent={column.widthPercent}
              widthPercentLaptop={column.widthPercentLaptop}
              noHeader={column.noHeader}
            >
              <Styled.HeadButton
                data-testid="table-head-cell-button"
                onClick={column.sortable ? handleSorting(column.field) : undefined}
              >
                {column.name}
                {renderArrows()}
              </Styled.HeadButton>
            </Styled.TableHeadCell>
          )
        })}
        {withControls && <Styled.TableHeadControlCell data-testid="table-head-control-cell" />}
        {customActions && <TableActionsKebab visible={false} />}
      </Styled.TableRow>
      {data.length > 0 ? (
        data.map((item) => (
          <TableRow
            key={item.id}
            columns={columns}
            data={item}
            validationSchema={validationSchema}
            editMode={editingId === item.id}
            withControls={withControls}
            highlightedText={highlightedText}
            customActions={customActions}
            onEdit={onUpdate && handleEdit}
            onDelete={onDelete}
            onSubmit={handleSubmit(item.id)}
          />
        ))
      ) : (
        <Styled.TableRow noBorder>
          {isString(emptyResultsMessage) ? (
            <Styled.TableCell widthPercent={100}>
              <Styled.TableCellEllipsis ellipsis={false}>{emptyResultsMessage}</Styled.TableCellEllipsis>
            </Styled.TableCell>
          ) : (
            emptyResultsMessage
          )}
        </Styled.TableRow>
      )}
    </Styled.Table>
  )
}
