import React from 'react'

import ErrorBoundary from '../../components/ErrorBoundary/ErrorBoundary'

import { TemplateFillFields as TemplateFillFieldsPage } from './TemplateFillFields'

export const TemplateFillFields = () => (
  <ErrorBoundary>
    <TemplateFillFieldsPage />
  </ErrorBoundary>
)
