import { createFetchActionTypes } from '../../../utils/createFetchActionTypes'
import { FetchFailureAction, FetchFailurePayload } from '../../../types/fetch'
import { EditableElem } from '../../../types/editableElem'
import {
  DocStrikesResponseBody,
  DocStrikesById,
  DocStrikesByPages,
  DocStrike,
  DocStrikesConfirmRequest,
  DocCancelStrikeConfirmation,
} from '../../../types/strikethrough'
import { ConfirmationStatus } from '../../../types/confirmationActions'

import * as TYPES from './strikes.types'

export const fetchStrikesTypes = createFetchActionTypes(TYPES.StrikesActions.FETCH_STRIKES)
export const fetchCreateStrikeTypes = createFetchActionTypes(TYPES.StrikesActions.FETCH_CREATE_STRIKE)
export const fetchConfirmStrikeTypes = createFetchActionTypes(TYPES.StrikesActions.FETCH_CONFIRM_STRIKE)
export const fetchCancelStrikeConfirmationTypes = createFetchActionTypes(
  TYPES.StrikesActions.FETCH_CANCEL_STRIKE_CONFIRMATION
)

export const addStrike = (strike: DocStrike, currentUserId: string): TYPES.AddStrikeAction => ({
  type: TYPES.StrikesActions.ADD_STRIKE,
  payload: { strike, currentUserId },
})
export const setStrike = (strike: EditableElem): TYPES.SetStrikeAction => ({
  type: TYPES.StrikesActions.SET_STRIKE,
  payload: { strike },
})
export const unsetStrike = (id: string, currentUserId: string): TYPES.UnsetStrikeAction => ({
  type: TYPES.StrikesActions.UNSET_STRIKE,
  payload: { id, currentUserId },
})
export const clearStrikes = (): TYPES.ClearStrikesAction => ({
  type: TYPES.StrikesActions.CLEAR_STRIKES,
})

export const fetchStrikes = (contractId: string): TYPES.FetchStrikesAction => ({
  type: fetchStrikesTypes.request,
  payload: { contractId },
})
export const fetchStrikesSuccess = (
  byId: DocStrikesById,
  byPages: DocStrikesByPages
): TYPES.FetchStrikesSuccessAction => ({
  type: fetchStrikesTypes.success,
  payload: { byId, byPages },
})
export const fetchStrikesFailure = (payload: FetchFailurePayload): FetchFailureAction => ({
  type: fetchStrikesTypes.failure,
  payload,
})

export const fetchCreateStrike = (contractId: string, strike: DocStrike): TYPES.FetchCreateStrikeAction => ({
  type: fetchCreateStrikeTypes.request,
  payload: { contractId, strike },
})
export const fetchCreateStrikeSuccess = (response: DocStrikesResponseBody): TYPES.FetchCreateStrikeSuccessAction => ({
  type: fetchCreateStrikeTypes.success,
  payload: response,
})
export const fetchCreateStrikeFailure = (payload: FetchFailurePayload): FetchFailureAction => ({
  type: fetchCreateStrikeTypes.failure,
  payload,
})

export const fetchConfirmStrike = (payload: DocStrikesConfirmRequest): TYPES.FetchConfirmStrikeAction => ({
  type: fetchConfirmStrikeTypes.request,
  payload,
})
export const fetchConfirmStrikeSuccess = (
  confirmStatus: ConfirmationStatus
): TYPES.FetchConfirmStrikeSuccessAction => ({
  type: fetchConfirmStrikeTypes.success,
  payload: { confirmStatus },
})
export const fetchConfirmStrikeFailure = (payload: FetchFailurePayload): FetchFailureAction => ({
  type: fetchConfirmStrikeTypes.failure,
  payload,
})

export const fetchCancelStrikeConfirmation = (
  payload: DocCancelStrikeConfirmation
): TYPES.FetchCancelStrikeConfirmationAction => ({
  type: fetchCancelStrikeConfirmationTypes.request,
  payload,
})
export const fetchCancelStrikeConfirmationSuccess = (
  confirmStatus: ConfirmationStatus
): TYPES.FetchCancelStrikeConfirmationSuccessAction => ({
  type: fetchCancelStrikeConfirmationTypes.success,
  payload: { confirmStatus },
})
export const fetchCancelStrikeConfirmationFailure = (payload: FetchFailurePayload): FetchFailureAction => ({
  type: fetchConfirmStrikeTypes.failure,
  payload,
})
