import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { location } from '../../../../utils/location'
import useAuth from '../../../../hooks/useAuth'
import {
  subscriptionNotExistSelector,
  subscriptionUsePlanIsFirstLoadSelector,
} from '../../../../store/subscription/userPlan/userPlan.selectors'
import { useAccessFeature } from '../../../../hooks/subscription/useAccessFeature'

import { PrivateRouteProps } from './PrivateRoute.types'

export const PrivateRoute: React.FC<PrivateRouteProps> = ({
  children,
  feature,
  loader = null,
  ignoreSubscriptionError = false,
  ...props
}) => {
  const { authenticated, loading } = useAuth()
  const isUserPlanFirstLoad = useSelector(subscriptionUsePlanIsFirstLoadSelector)
  const noSubscription = useSelector(subscriptionNotExistSelector)
  const { checkAccess } = useAccessFeature()

  if (loading) {
    return loader
  }

  const render = () => {
    if (!authenticated) {
      return <Redirect to={location.loginUrl()} />
    }

    if (!isUserPlanFirstLoad) {
      return loader
    }

    if (!ignoreSubscriptionError && noSubscription) {
      return <Redirect to={location.subscriptionSelectUrl()} />
    }

    if (checkAccess(feature, false)) {
      return children
    }

    return <Redirect to={location.error403Url()} />
  }

  return <Route {...props} render={render} />
}
