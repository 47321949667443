import { DocStrike, DocStrikesRequestBody, DocStrikesById, DocStrikesByPages } from '../../types/strikethrough'
import { EditableElem } from '../../types/editableElem'
import omit from '../omit'

type DocStrikesWithPages = { byId: DocStrikesById; byPages: DocStrikesByPages }

interface AddStrikeByIdArgs {
  strike: DocStrike
  strikesById: DocStrikesById
  currentUserId: string
}

export const addStrikeById = ({ strike, strikesById, currentUserId }: AddStrikeByIdArgs): DocStrikesWithPages => {
  const id = strike.id
  const nextStrikesById = id && !strikesById[id] ? { ...strikesById, [id]: strike } : strikesById
  const [byId, byPages] = prepareStrikesForStore(Object.values(nextStrikesById), currentUserId)

  return { byId, byPages }
}

export const updateStrikeById = (strike: EditableElem, strikesById: DocStrikesById): DocStrikesById => {
  const id = strike.id!
  const nextStrike = { ...strikesById[id], ...strike }
  return { ...omit([id], strikesById), [id]: nextStrike }
}

interface RemoveStrikeByIdArgs {
  id: string
  strikesById: DocStrikesById
  currentUserId: string
}

export const removeStrikeById = ({ id, strikesById, currentUserId }: RemoveStrikeByIdArgs): DocStrikesWithPages => {
  const nextStrikesById = {
    ...omit([id], strikesById),
  }
  const [byId, byPages] = prepareStrikesForStore(Object.values(nextStrikesById), currentUserId)

  return { byId, byPages }
}

export const prepareStrikesForStore = (
  strikes: DocStrike[],
  currentUserId?: string
): [DocStrikesById, DocStrikesByPages] => {
  const byId: DocStrikesById = {}
  const byPages: DocStrikesByPages = {}

  strikes.forEach((strike) => {
    const { id, page } = strike

    if (id) {
      byId[id] = {
        ...strike,
        canConfirm: !!currentUserId && currentUserId !== strike.strikeOrCorrectionOwner,
      }
      byPages[page] = (byPages[page] || []).concat(id)
    }
  })

  return [byId, byPages]
}

export const prepareStrikeForRequest = (strike: DocStrike): DocStrikesRequestBody => ({
  strikes: [
    omit(
      [
        'id',
        'isEditable',
        'canConfirm',
        'owner',
        'status',
        'strikeOrCorrectionOwner',
        'acceptOrRejectTimestamp',
        'confirmation',
        'type',
      ],
      strike
    ),
  ],
})
