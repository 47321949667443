import { SUBSCRIPTION_MONTH, SUBSCRIPTION_YEAR } from '../../constants/subscription'
import { SubscriptionPlan, SubscriptionPlanResponse } from '../../types/subscription'

export const preparePlans = (plans: SubscriptionPlanResponse[]): SubscriptionPlan[] =>
  plans.map(({ terms, ...plan }) => {
    let yearPrice = 0
    let monthPrice = 0

    terms.forEach((item) => {
      if (item.term === SUBSCRIPTION_YEAR) {
        yearPrice = item.price
      } else if (item.term === SUBSCRIPTION_MONTH) {
        monthPrice = item.price
      }
    })

    return {
      ...plan,
      yearPrice,
      monthPrice,
    }
  })
