import { Options } from '@popperjs/core'
import React, { useMemo, useState } from 'react'
import ReactDOM from 'react-dom'
import { usePopper } from 'react-popper'
import { useTranslation } from 'react-i18next'

import translations from '../../../translations/keys'
import { DocCommentsThread, Suggestion } from '../../../types/comment'
import { getFormattedDate } from '../../../utils/date'
import { CloseIcon } from '../../assets/icons'
import Box from '../Box'
import { BUTTON_VARIANTS } from '../Button/Button.types'
import ClickOutsideListener from '../ClickOutsideListener'
import ConfirmationActions from '../ConfirmationActions'
import ConfirmationLabel from '../ConfirmationLabel'
import { DOC_VIEWER_CONTENT_ID, DOC_VIEWER_WRAPPER_ID } from '../DocViewer/DocViewer.constants'
import Mentions from '../Mentions'
import Scrollbar from '../Scrollbar'
import { CommentThreadSkeleton } from '../Skeletons/CommentThreadSkeleton'
import Typography from '../Typography'
import { TYPOGRAPHY_NAMES } from '../Typography/Typography.types'
import { BoxSize } from '../ConfirmationLabel/ConfirmationLabel.types'

import * as Styled from './CommentPopover.styles'
import { CommentPopoverProps } from './CommentPopover.types'
const MAX_COMMENT_SIZE = 200

const options: Options = {
  strategy: 'absolute',
  modifiers: [
    {
      name: 'offset',
      options: {
        offset: [20, 0],
      },
    },
  ],
  placement: 'bottom-start',
}

export const CommentPopover: React.FC<CommentPopoverProps> = ({
  targetRef,
  comment,
  thread,
  recipients,
  currentUser,
  loading,
  onElemConfirm,
  onSubmit,
  onClose,
  onElemCancelConfirmation,
  isContractOwner,
}) => {
  const { status, name, timestamp } = comment.confirmation
  const [popperRef, setPopperRef] = useState<HTMLDivElement | null>(null)
  const { styles, attributes } = usePopper(targetRef, popperRef, options)
  const [message, setMessage] = useState('')
  const [mentions, setMentions] = useState<Suggestion[]>([])
  const suggestions = useMemo(
    () => Object.values(recipients).map((recipient) => ({ name: recipient.name, email: recipient.email })),
    [targetRef, comment, currentUser, message]
  )
  const preparedComment = useMemo(
    () => ({
      id: comment.id,
      x: comment.x,
      y: comment.y,
      page: comment.page,
      message,
      mentions,
      confirmation: comment.confirmation,
    }),
    [targetRef, comment, currentUser, message]
  )
  const docViewerContent = document.getElementById(DOC_VIEWER_CONTENT_ID)
  const docViewerWrapper = document.getElementById(DOC_VIEWER_WRAPPER_ID)
  const { t } = useTranslation()
  const isSubmitDisabled = !message.trim()
  const placeholder =
    loading || thread.length > 0 ? t(translations.COMMENT_REPLY) : t(translations.COMMENT_FIELD_PLACEHOLDER)

  if (!docViewerContent || !docViewerWrapper) {
    return null
  }

  const handleSubmit = () => {
    onSubmit(preparedComment)
  }

  const handleChange = (value: string) => {
    if (value.length < MAX_COMMENT_SIZE) {
      setMessage(value)
    }
  }

  const handleMentionsChange = (mentions: Suggestion[]) => {
    setMentions(mentions)
  }

  const handleClose = (event: MouseEvent | TouchEvent) => {
    onClose(preparedComment, event)
  }

  const isShowConfirmationActions = () => !status && thread.length > 0

  const getName = (comment: DocCommentsThread) => {
    const recipientName = comment.recipientId
      ? recipients[comment.recipientId]?.name
      : `${currentUser?.firstName} ${currentUser?.lastName}`.trim()

    const ownerName = comment.owner?.name

    return ownerName || recipientName
  }

  return ReactDOM.createPortal(
    <ClickOutsideListener test-id="click-outside-listener" onClickAway={handleClose}>
      <Styled.Wrapper
        data-ignore-scroll
        data-testid="comment-popover-wrapper"
        ref={setPopperRef}
        style={styles.popper}
        {...attributes.popper}
      >
        <Styled.ActionsWrapper>
          <Styled.ActionButton variant={BUTTON_VARIANTS.TEXT} onClick={handleClose}>
            <CloseIcon color="grey" size="medium" />
          </Styled.ActionButton>
        </Styled.ActionsWrapper>
        <Scrollbar>
          <Styled.Content>
            {loading ? (
              <CommentThreadSkeleton />
            ) : (
              thread.map((comment: DocCommentsThread) => (
                <Styled.ThreadItem key={comment.id}>
                  <Box mb={0.5}>
                    <Typography name={TYPOGRAPHY_NAMES.bold14}>{getName(comment)}</Typography>
                  </Box>
                  <Box mb={1}>
                    <Typography name={TYPOGRAPHY_NAMES.bodySmall12} color="grey">
                      {getFormattedDate(comment.date)}
                    </Typography>
                  </Box>
                  <Mentions
                    disabled
                    name={comment.id}
                    value={comment.message}
                    placeholder={t(translations.COMMENT_FIELD_PLACEHOLDER)}
                    suggestions={suggestions}
                    onChange={handleChange}
                    onMentionsChange={handleMentionsChange}
                  />
                </Styled.ThreadItem>
              ))
            )}
            {status && name && timestamp && comment?.id && (
              <ConfirmationLabel
                isContractOwner={isContractOwner}
                size={BoxSize.tall}
                iconSize="extraSmall"
                id={comment.id}
                status={status}
                date={timestamp}
                fullName={name}
                onCancel={onElemCancelConfirmation}
              />
            )}
            {!status && (
              <Mentions
                autoFocus={comment.counter === 0}
                name="comment"
                value={message}
                placeholder={placeholder}
                suggestions={suggestions}
                onChange={handleChange}
                onMentionsChange={handleMentionsChange}
                suggestionsPortalHost={docViewerWrapper}
              />
            )}
            <Styled.SubmitButton
              status={status}
              data-testid="comment-popover-submit-button"
              disabled={isSubmitDisabled}
              onClick={handleSubmit}
            >
              {t(translations.SAVE)}
            </Styled.SubmitButton>
          </Styled.Content>
        </Scrollbar>
        {isShowConfirmationActions() && comment.id && onElemConfirm && (
          <ConfirmationActions onConfirm={onElemConfirm} id={comment.id} />
        )}
      </Styled.Wrapper>
    </ClickOutsideListener>,
    docViewerContent
  )
}
