import styled from 'styled-components'

import Button from '../../../../ui/Button'
import Typography from '../../../../ui/Typography'

export const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 320px;
  margin-top: 24px;
`
export const StyledTitle = styled(Typography)`
  text-align: center;
  margin: 40px 0 24px;
`
export const StyledDescription = styled(Typography)`
  text-align: center;
  margin-bottom: 24px;
`
export const StyledButton = styled(Button)`
  margin-top: 16px;
  margin-bottom: 24px;
`
