const PIXELS_COVERAGE = 4
const TOP_POINT = 0
const LEFT_POINT = 0

function trimCanvas(canvas: HTMLCanvasElement) {
  const ctx = canvas.getContext('2d')
  const copyCanvas = document.createElement('canvas').getContext('2d')

  if (!ctx || !copyCanvas) {
    return canvas
  }

  const pixels = ctx.getImageData(0, 0, canvas.width, canvas.height)
  const l = pixels.data.length
  const bound: {
    top: number | null
    left: number | null
    right: number | null
    bottom: number | null
  } = {
    top: null,
    left: null,
    right: null,
    bottom: null,
  }
  let i, x, y

  for (i = 0; i < l; i += PIXELS_COVERAGE) {
    if (pixels.data[i + (PIXELS_COVERAGE - 1)] !== 0) {
      x = (i / PIXELS_COVERAGE) % canvas.width
      y = ~~(i / PIXELS_COVERAGE / canvas.width)

      if (bound.top === null) {
        bound.top = y
      }

      if (bound.left === null) {
        bound.left = x
      } else if (x < bound.left) {
        bound.left = x
      }

      if (bound.right === null) {
        bound.right = x
      } else if (bound.right < x) {
        bound.right = x
      }

      if (bound.bottom === null) {
        bound.bottom = y
      } else if (bound.bottom < y) {
        bound.bottom = y
      }
    }
  }

  const newBound = bound as { [key: string]: number }

  const trimHeight = newBound.bottom - newBound.top,
    trimWidth = newBound.right - newBound.left,
    trimmed = ctx.getImageData(newBound.left, newBound.top, trimWidth, trimHeight)

  copyCanvas.canvas.width = trimWidth
  copyCanvas.canvas.height = trimHeight
  copyCanvas.putImageData(trimmed, LEFT_POINT, TOP_POINT)

  return copyCanvas.canvas
}

export const getTrimmedCanvas = (canvas: HTMLCanvasElement) => {
  const copy = document.createElement('canvas')

  copy.width = canvas.width
  copy.height = canvas.height
  copy.getContext('2d')!.drawImage(canvas, LEFT_POINT, TOP_POINT)

  return trimCanvas(canvas)
}
