import React from 'react'
import { useField } from 'formik'
import { useTranslation } from 'react-i18next'

import Password from '../../Password'

import { FormPasswordProps } from './FormPassword.types'

export const FormPassword = (props: FormPasswordProps) => {
  const [field, meta] = useField(props)
  const { t } = useTranslation()

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    field.onChange(e)
    props.onChange && props.onChange(e)
  }

  return (
    <Password
      {...props}
      {...field}
      {...meta}
      error={meta.touched ? meta.error && t(meta.error) : ''}
      onChange={onChange}
    />
  )
}
