import React from 'react'

import MainTemplate from '../../components/Main/MainTemplate'

import { Error403 as Error403Page } from './Error403'

export const Error403 = () => (
  <MainTemplate>
    <Error403Page />
  </MainTemplate>
)
