import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'

import { location } from '../../../../../utils/location'
import { CONTRACT_SIGN_MODALS } from '../../../../constants/contractSign'
import { CONTRACT_MODALS } from '../../../../../constants/contract'
import { modalsVisibleSelector } from '../../../../../store/modals/modals.selectors'
import { closeModal } from '../../../../../store/modals/modals.actions'
import ChooseSignModal from '../../../../components/Modals/SignContractModals/ChooseSignModal'
import FinishLaterModal from '../../../../components/Modals/SignContractModals/FinishLaterModal'
import SendContractModal from '../../../../components/Modals/SignContractModals/SendContractModal'
import DeclineSigningModal from '../../../../components/Modals/SignContractModals/DeclineSigningModal'
import DownloadProgressModal from '../../../../components/Modals/ContractModals/DownloadProgressModal'
import SigningOTPVerificationModal from '../../../../components/Modals/SignContractModals/SigningOTPVerificationModal'
import ActionOTPVerificationModal from '../../../../components/Modals/SignContractModals/ActionOTPVerificationModal'
import SigningStubEKYCModal from '../../../../components/Modals/SignContractModals/SigningStubEKYCModal'
import SigningActionsStubEKYCModal from '../../../../components/Modals/SignContractModals/SigningActionsStubEKYCModal'
import SaveAnnotationPromptModal from '../../../../components/Modals/ContractModals/SaveAnnotationPromptModal'

export const ContractSignModals = () => {
  const history = useHistory()
  const dispatch = useDispatch()

  const onUpdatedSuccessfully = useCallback(() => {
    history.push(location.contractsAllUrl())
  }, [])

  const showChooseSign = useSelector(modalsVisibleSelector(CONTRACT_SIGN_MODALS.CONTRACT_VIEW_CHOOSE_SIGN))
  const onCloseChooseSign = () => {
    dispatch(closeModal(CONTRACT_SIGN_MODALS.CONTRACT_VIEW_CHOOSE_SIGN))
  }

  const showFinishSignLater = useSelector(modalsVisibleSelector(CONTRACT_SIGN_MODALS.FINISH_SIGN_LATER))
  const onCloseFinishSignLater = () => {
    dispatch(closeModal(CONTRACT_SIGN_MODALS.FINISH_SIGN_LATER))
  }

  const showSendContract = useSelector(modalsVisibleSelector(CONTRACT_SIGN_MODALS.SEND_CONTRACT))
  const onCloseSendContract = () => {
    dispatch(closeModal(CONTRACT_SIGN_MODALS.SEND_CONTRACT))
  }

  const showDeclineSigning = useSelector(modalsVisibleSelector(CONTRACT_SIGN_MODALS.DECLINE_SIGNING))
  const onCloseDeclineSigning = () => {
    dispatch(closeModal(CONTRACT_SIGN_MODALS.DECLINE_SIGNING))
  }

  const showDownloadProgress = useSelector(modalsVisibleSelector(CONTRACT_MODALS.CONTRACT_DOWNLOAD_PROGRESS))
  const onCloseDownloadProgress = () => {
    dispatch(closeModal(CONTRACT_MODALS.CONTRACT_DOWNLOAD_PROGRESS))
  }

  const showSigningOTPVerification = useSelector(modalsVisibleSelector(CONTRACT_SIGN_MODALS.SIGNING_OTP_VERIFICATION))
  const handleSigningOTPVerificationClose = () => {
    dispatch(closeModal(CONTRACT_SIGN_MODALS.SIGNING_OTP_VERIFICATION))
  }

  const showActionOTPVerification = useSelector(modalsVisibleSelector(CONTRACT_SIGN_MODALS.ACTION_OTP_VERIFICATION))
  const handleActionOTPVerificationClose = () => {
    dispatch(closeModal(CONTRACT_SIGN_MODALS.ACTION_OTP_VERIFICATION))
  }

  const showSigningEKYCVerification = useSelector(modalsVisibleSelector(CONTRACT_SIGN_MODALS.SIGNING_EKYC_VERIFICATION))
  const handleSigningEKYCVerificationClose = () => {
    dispatch(closeModal(CONTRACT_SIGN_MODALS.SIGNING_EKYC_VERIFICATION))
  }

  const showSigningActionsEKYCVerification = useSelector(
    modalsVisibleSelector(CONTRACT_SIGN_MODALS.SIGNING_ACTIONS_EKYC_VERIFICATION)
  )
  const handleSigningActionsEKYCVerificationClose = () => {
    dispatch(closeModal(CONTRACT_SIGN_MODALS.SIGNING_ACTIONS_EKYC_VERIFICATION))
  }

  const showSaveAnnotation = useSelector(modalsVisibleSelector(CONTRACT_MODALS.CONTRACT_SAVE_ANNOTATION_PROMPT))
  const onCloseSaveAnnotation = () => {
    dispatch(closeModal(CONTRACT_MODALS.CONTRACT_SAVE_ANNOTATION_PROMPT))
  }

  return (
    <>
      {showChooseSign && <ChooseSignModal onClose={onCloseChooseSign} />}
      {showFinishSignLater && <FinishLaterModal onClose={onCloseFinishSignLater} />}
      {showSendContract && <SendContractModal onClose={onCloseSendContract} onSuccess={onUpdatedSuccessfully} />}
      {showDeclineSigning && <DeclineSigningModal onClose={onCloseDeclineSigning} onSuccess={onUpdatedSuccessfully} />}
      {showDownloadProgress && <DownloadProgressModal onClose={onCloseDownloadProgress} />}
      {showSigningOTPVerification && <SigningOTPVerificationModal onClose={handleSigningOTPVerificationClose} />}
      {showActionOTPVerification && <ActionOTPVerificationModal onClose={handleActionOTPVerificationClose} />}
      {showSigningEKYCVerification && <SigningStubEKYCModal onClose={handleSigningEKYCVerificationClose} />}
      {showSigningActionsEKYCVerification && (
        <SigningActionsStubEKYCModal onClose={handleSigningActionsEKYCVerificationClose} />
      )}
      {showSaveAnnotation && <SaveAnnotationPromptModal onClose={onCloseSaveAnnotation} />}
    </>
  )
}
