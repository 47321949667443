import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import translations from '../../../translations/keys'
import Box from '../../ui/Box'
import { EKYCConfirmedSvg } from '../../assets/EKYCConfirmedSvg'
import Typography from '../../ui/Typography'
import { TYPOGRAPHY_NAMES } from '../../ui/Typography/Typography.types'
import { PhoneWithHandSvg } from '../../assets/PhoneWithHandSvg'
import { useAccessFeature } from '../../../hooks/subscription/useAccessFeature'
import { SubscriptionFeatures } from '../../../types/subscription'
import { SubscriptionSvg } from '../../assets/SubscriptionSvg'
import Button from '../../ui/Button'
import { ekycConfirmationLoading, ekycGetConfirmationStatus } from '../../../store/ekyc/ekyc.selectors'
import { clearConfirmation, clearEnroll, fetchEnrollStatus } from '../../../store/ekyc/ekyc.actions'
import { subscriptionUserPlanIsLoadedSelector } from '../../../store/subscription/userPlan/userPlan.selectors'
import { EKYCSkeleton } from '../../components/Skeletons/EKYCSkeleton'
import { useDispatchUnmount } from '../../../hooks/useDispatchUnmount'

import { Wrapper } from './EKYC.styles'

export const EKYC = () => {
  const dispatch = useDispatch()
  const { checkAccess } = useAccessFeature()
  const { t } = useTranslation()
  const isUserPlanLoaded = useSelector(subscriptionUserPlanIsLoadedSelector)
  const isEKYCConfirmed = useSelector(ekycGetConfirmationStatus)
  const isEKYCLoading = useSelector(ekycConfirmationLoading)
  const isEKYCAccessed = checkAccess(SubscriptionFeatures.FEATURE_EKYC, false)

  useEffect(() => {
    if (isEKYCAccessed) {
      dispatch(fetchEnrollStatus())
    }
  }, [isEKYCAccessed])
  useDispatchUnmount(clearEnroll, clearConfirmation)

  if (!isUserPlanLoaded || isEKYCLoading) {
    return (
      <Wrapper>
        <EKYCSkeleton />
      </Wrapper>
    )
  }

  if (!isEKYCAccessed) {
    return (
      <Wrapper>
        <SubscriptionSvg />
        <Box mt={5} mb={5}>
          {t(translations.UPGRADE_REQUIRED)}
        </Box>
        <Box width={208}>
          <Button fullWidth>{t(translations.UPGRADE)}</Button>
        </Box>
      </Wrapper>
    )
  }

  if (isEKYCConfirmed) {
    return (
      <Wrapper>
        <EKYCConfirmedSvg />
        <Box mt={5} mb={5} width={250} textAlign="center">
          <Typography name={TYPOGRAPHY_NAMES.H3}>{t(translations.VERIFICATION_CONFIRMED)}</Typography>
        </Box>
        {/* TODO UQD-1410 uncomment when this will work */}
        {/* <Box display="flex">
            <Box mr={3}>
              <AppStoreSvg />
            </Box>
            <GooglePlaySvg />
          </Box> */}
      </Wrapper>
    )
  }

  return (
    <Wrapper>
      <PhoneWithHandSvg />
      <Box mt={5} mb={5} width={206} textAlign="center">
        {t(translations.USE_MOBILE_EKYC)}
      </Box>
    </Wrapper>
  )
}
