import styled from 'styled-components'

import Box from '../../../../../../ui/Box'

export const StyledField = styled(Box).attrs({
  width: '50%',
})`
  &:nth-child(odd) {
    padding-right: 12px;
  }
  &:nth-child(even) {
    padding-left: 12px;
  }
`
