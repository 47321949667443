import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router'

import translations from '../../../../translations/keys'
import { TemplateRouteParams } from '../../../types/template'
import { FETCH_STATUSES } from '../../../../types/fetch'
import { location } from '../../../../utils/location'
import { fetchUpdatePlacement } from '../../../../store/templates/placement/placement.actions'
import {
  placementByIdSelector,
  placementFetchUpdateSelector,
  placementSortedSelector,
} from '../../../../store/templates/placement/placement.selectors'
import { fetchUpdateFields } from '../../../../store/templates/fields/fields.actions'
import { fieldsByIdSelector, fieldsFetchUpdateSelector } from '../../../../store/templates/fields/fields.selectors'
import { recipientsSortedSelector } from '../../../../store/templates/recipients/recipients.selectors'
import { BUTTON_VARIANTS } from '../../../ui/Button/Button.types'
import { SaveIcon } from '../../../assets/icons'

export const useButtons = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { templateId } = useParams<TemplateRouteParams>()
  const recipientsList = useSelector(recipientsSortedSelector)
  const placementById = useSelector(placementByIdSelector)
  const placementSorted = useSelector(placementSortedSelector)
  const fieldsById = useSelector(fieldsByIdSelector)
  const fetchPlacementUpdateRequest = useSelector(placementFetchUpdateSelector)
  const fetchFieldsUpdateRequest = useSelector(fieldsFetchUpdateSelector)
  const shouldUpdatePlacement = recipientsList.length > 0

  const buttons = useMemo(
    () => [
      {
        label: translations.SAVE_AND_CLOSE,
        onClick: () => {
          dispatch(fetchUpdateFields(templateId, fieldsById))

          if (shouldUpdatePlacement) {
            dispatch(fetchUpdatePlacement(placementById, placementSorted))
          }
        },
        variant: BUTTON_VARIANTS.SECONDARY,
        MobileIcon: SaveIcon,
      },
    ],
    [templateId, recipientsList, placementById, placementSorted, fieldsById]
  )

  useEffect(() => {
    if (
      (!shouldUpdatePlacement || fetchPlacementUpdateRequest.status === FETCH_STATUSES.SUCCESS) &&
      fetchFieldsUpdateRequest.status === FETCH_STATUSES.SUCCESS
    ) {
      history.push(location.templatesAllUrl())
    }
  }, [fetchPlacementUpdateRequest, fetchFieldsUpdateRequest])

  return buttons
}
