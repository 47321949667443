import React, { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

import { WarningIcon } from '../../../assets/WarningIcon'
import translations from '../../../../translations/keys'
import { fetchResendEmail } from '../../../../store/auth/auth.actions'
import Modal from '../../Modal'
import Typography from '../../../ui/Typography'
import { TYPOGRAPHY_NAMES } from '../../../ui/Typography/Typography.types'
import Button from '../../../ui/Button'
import { BUTTON_VARIANTS } from '../../../ui/Button/Button.types'
import Box from '../../../ui/Box'
import { authResendEmailErrorSelector, authResendEmailFetchStatusSelector } from '../../../../store/auth/auth.selectors'
import { FETCH_STATUSES } from '../../../../types/fetch'
import { FormError } from '../../FormError'

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 320px;
  text-align: center;
  padding-bottom: 5px;
  padding-top: 24px;
`

interface ResendEmailModalProps {
  email: string
  onClose: () => void
}

export const ResendEmailModal: FC<ResendEmailModalProps> = ({ email, onClose }) => {
  const dispatch = useDispatch()
  const fetchStatus = useSelector(authResendEmailFetchStatusSelector)
  const resendEmailError = useSelector(authResendEmailErrorSelector)
  const showResendButton = fetchStatus === FETCH_STATUSES.SUCCESS

  const handleResend = () => {
    dispatch(fetchResendEmail(email))
  }

  return (
    <Modal onClose={onClose}>
      <StyledContent>
        <Box mb={2}>
          <WarningIcon />
        </Box>
        <Box mb={2}>
          <Typography name={TYPOGRAPHY_NAMES.H1}>{translations.WARNING}</Typography>
        </Box>
        <Box mb={2}>
          <p>{translations.RESEND_EMAIL_DESCRIPTION}</p>
        </Box>
        <Box textAlign="center" mb={3}>
          {showResendButton ? (
            <p>{translations.RESEND_EMAIL_SUCCESS}</p>
          ) : (
            <Button variant={BUTTON_VARIANTS.LINK} onClick={handleResend}>
              {translations.RESEND_NOTIFICATION}
            </Button>
          )}
        </Box>
        <Button onClick={onClose} fullWidth>
          {translations.OK}
        </Button>
        <FormError error={resendEmailError} />
      </StyledContent>
    </Modal>
  )
}
