import React, { FC } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { EmailIcon } from '../../../assets/EmailIcon'
import translations from '../../../../translations/keys'
import Modal from '../../Modal'
import Typography from '../../../ui/Typography'
import { TYPOGRAPHY_NAMES } from '../../../ui/Typography/Typography.types'
import Button from '../../../ui/Button'

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 320px;
  text-align: center;
  padding-bottom: 21px;
  padding-top: 24px;
`
const StyledEmailIcon = styled(EmailIcon)`
  margin-bottom: 40px;
  margin-top: 14px;
`
const StyledTitle = styled(Typography)`
  margin-bottom: 24px;
`
const StyledDescription = styled.p`
  margin-bottom: 40px;
`

interface CheckEmailModalProps {
  onClose: () => void
  description: string
}

export const CheckEmailModal: FC<CheckEmailModalProps> = ({ onClose, description }) => {
  const { t } = useTranslation()

  return (
    <Modal onClose={onClose}>
      <StyledContent>
        <StyledEmailIcon />
        <StyledTitle name={TYPOGRAPHY_NAMES.H1}>{t(translations.CHECK_EMAIL_TITLE)}</StyledTitle>
        <StyledDescription>{description}</StyledDescription>
        <Button onClick={onClose} fullWidth>
          {t(translations.OK)}
        </Button>
      </StyledContent>
    </Modal>
  )
}
