import React, { useEffect, useRef, useState, useLayoutEffect } from 'react'
import { useSelector } from 'react-redux'

import translations from '../../../translations/keys'
import { DEFAULT_CORRECTION_FONT_SIZE } from '../../../types/documentField'
import Resizer from '../Resizer'
import { PointerModes } from '../DocViewer/DocViewer.types'
import ConfirmationActions from '../ConfirmationActions'
import ConfirmationLabel from '../ConfirmationLabel'
import { BoxSize } from '../ConfirmationLabel/ConfirmationLabel.types'
import { correctionsByIdSelector } from '../../../store/contracts/corrections/corrections.selectors'
import Tooltip from '../../components/Tooltip'
import Box from '../Box'

import { CorrectionProps } from './Correction.types'
import * as Styled from './Correction.styles'

const MIN_SIZE = 30

export const Correction: React.FC<CorrectionProps> = ({
  id,
  isSelected = false,
  isEditable = false,
  x,
  y,
  width,
  height,
  fontSize = DEFAULT_CORRECTION_FONT_SIZE,
  placeholder = translations.CORRECTION_INPUT_PLACEHOLDER,
  text,
  status,
  acceptOrRejectByName,
  correctionOwner,
  acceptOrRejectTimestamp,
  onChange,
  onSave,
  onBlur,
  onDelete,
  onConfirm,
  onCancelConfirmation,
  currentUserEmail,
  ...props
}) => {
  const correction = useSelector(correctionsByIdSelector)
  const [editMode, setEditMode] = useState(false)
  const [visible, setVisible] = useState(true)
  const [isHide, setIsHide] = useState(false)
  const [isFullName, setIsFullName] = useState(false)
  const [clientCoords, setCoords] = useState({ x: 0, y: 0 })
  const [expandLabel, setExpandLabel] = useState(false)
  const wrapperRef = React.useRef<HTMLDivElement>(null)
  const labelRef = React.useRef<HTMLDivElement>(null)
  const confirmationActionsRef = React.useRef<HTMLDivElement>(null)
  const topActionsRef = React.useRef<HTMLDivElement>(null)
  const textAreaRef = useRef<HTMLTextAreaElement | null>(null)
  const isSaveDisabled = !text?.trim().length

  const node = wrapperRef.current
  useEffect(() => {
    node?.addEventListener('mousemove', handleEvent)
    node?.addEventListener('mouseleave', hideTooltip)

    return () => {
      node?.removeEventListener('mousemove', handleEvent)
      node?.removeEventListener('mouseleave', hideTooltip)
    }
  }, [wrapperRef.current])

  useLayoutEffect(() => {
    if (!isFullName && isHide) {
      setIsFullName(true)
    }
  }, [clientCoords.x, clientCoords.y])

  useEffect(() => {
    const textArea = textAreaRef.current

    if (textArea) {
      textArea.focus()
    }
  }, [editMode])

  useEffect(() => {
    if (!isSelected) {
      setVisible(true)
    }
  }, [isSelected])

  useEffect(() => {
    const onClick = (event: MouseEvent) => {
      const isLabelClick = labelRef?.current?.contains(event.target as HTMLElement)
      if (!isLabelClick && !isSelected) {
        setExpandLabel(false)
      } else if (!isLabelClick && isSelected) {
        setExpandLabel(true)
      } else if (isLabelClick && !isSelected) {
        setExpandLabel(!expandLabel)
        return
      }
    }
    document.addEventListener('click', onClick)
    return () => document.removeEventListener('click', onClick)
  }, [expandLabel, isSelected])

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const isOnlySpaces = text === '' && !event.target.value.trim().length

    if (onChange && !isOnlySpaces) {
      onChange(event)
    }
  }

  const handleHide = () => {
    setVisible((prev) => !prev)
  }

  const handleSave = () => {
    onSave && onSave(id)
  }

  const handleDelete = () => {
    onDelete && onDelete(id)
  }

  const handleModeChange = () => {
    setEditMode(true)
  }

  const handleBlur = (event: React.FocusEvent<HTMLTextAreaElement>) => {
    setEditMode(false)
    onBlur && onBlur(event)
  }

  const isShowConfirmationActions = () => {
    const isCorrectionOrStrikeOwner = currentUserEmail !== correctionOwner
    return !status && isSelected && visible && isCorrectionOrStrikeOwner
  }

  let timeout: number
  const handleEvent = (event: MouseEvent): void => {
    const isLabelHover = labelRef?.current?.contains(event.target as HTMLElement)
    const isConfirmationActionsHover = confirmationActionsRef?.current?.contains(event.target as HTMLElement)
    const isEyeRefHover = topActionsRef?.current?.contains(event.target as HTMLElement)
    if (isLabelHover || isConfirmationActionsHover || isEyeRefHover) {
      return
    }
    const offSetX = 15
    const offSetY = 20
    const target = event.target as HTMLElement
    const bounds = target.getBoundingClientRect()
    if (!node) {
      return
    }
    if (timeout) {
      clearTimeout(timeout)
    }
    timeout = setTimeout(() => {
      const clientX = event.clientX - Math.round(bounds.left) + offSetX
      const clientY = event.clientY - Math.round(bounds.top) + offSetY
      setIsHide(true)
      setCoords({
        x: clientX,
        y: clientY,
      })
    }, 1000)
  }

  const hideTooltip = () => {
    setIsFullName(false)
    if (timeout) {
      clearTimeout(timeout)
    }
  }

  const showLabel = () => {
    setExpandLabel(!expandLabel)
  }

  return (
    <Styled.Wrapper
      ref={wrapperRef}
      data-testid="correction-wrapper"
      data-id={id}
      data-type={PointerModes.DRAG}
      data-readonly={!isEditable ? true : undefined}
      data-min-size={MIN_SIZE}
      data-save-disabled={isSaveDisabled}
      isSelected={isSelected}
      isEditMode={editMode}
      isVisible={visible}
      isEditable={isEditable}
      style={{ left: x, top: y, width, height }}
      onDoubleClick={handleModeChange}
      {...props}
    >
      {isFullName && correction[id]?.owner?.name && (
        <Tooltip top={clientCoords.y} left={clientCoords.x} title={correction[id]?.owner!.name} />
      )}
      {isSelected && isEditable && <Resizer isEditable={isEditable} />}
      {visible && (
        <Styled.TextArea
          data-testid="correction-textarea"
          autoComplete="off"
          ref={textAreaRef}
          name={id}
          value={text}
          disabled={!onChange || !editMode || !isEditable}
          fontSize={fontSize}
          placeholder={placeholder}
          tabIndex={-1}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      )}
      {isSelected && (
        <Styled.Actions ref={topActionsRef}>
          {!isEditable && (
            <Styled.Action>
              <Styled.ActionButton data-action icon={visible ? 'eyeClosed' : 'eyeOpened'} onClick={handleHide} />
            </Styled.Action>
          )}
          {isEditable && (
            <>
              <Styled.Action>
                <Styled.ActionButton data-action icon="checkCircle" disabled={isSaveDisabled} onClick={handleSave} />
              </Styled.Action>
              <Styled.Action>
                <Styled.ActionButton data-action icon="closeCircle" onClick={handleDelete} />
              </Styled.Action>
            </>
          )}
        </Styled.Actions>
      )}
      {isShowConfirmationActions() && onConfirm && (
        <Box ref={confirmationActionsRef}>
          <ConfirmationActions id={id} onConfirm={onConfirm} />
        </Box>
      )}
      {status && acceptOrRejectByName && acceptOrRejectTimestamp && visible && (
        <Box ref={labelRef}>
          <Styled.Confirmation>
            <ConfirmationLabel
              status={status}
              date={acceptOrRejectTimestamp}
              fullName={acceptOrRejectByName}
              size={expandLabel ? BoxSize.default : BoxSize.compact}
              iconSize="extraSmall"
              id={id}
              onCancel={onCancelConfirmation}
              expandLabel={expandLabel}
              showLabel={showLabel}
            />
          </Styled.Confirmation>
        </Box>
      )}
    </Styled.Wrapper>
  )
}
