import { FETCH_STATUSES } from '../../../types/fetch'
import { RootState } from '../../rootReducer.types'

export const recipientsContractIdSelector = (state: RootState) => state.contracts.recipients.contractId
export const recipientsContractNameSelector = (state: RootState) => state.contracts.recipients.contractName
export const recipientsSenderSelector = (state: RootState) => state.contracts.recipients.sender
export const recipientsOrderSelector = (state: RootState) => state.contracts.recipients.order
export const recipientsByIdSelector = (state: RootState) => state.contracts.recipients.byId
export const recipientsByOrderSelector = (state: RootState) => state.contracts.recipients.byOrder
export const recipientsSortedSelector = (state: RootState) => state.contracts.recipients.sorted

export const recipientsFetchAddSelector = (state: RootState) => state.contracts.recipients.add
export const recipientsFetchAddStatusSelector = (state: RootState) => state.contracts.recipients.add.status
export const recipientsFetchAddErrorSelector = (state: RootState) => state.contracts.recipients.add.error

export const recipientsFetchUpdateSelector = (state: RootState) => state.contracts.recipients.update
export const recipientsFetchUpdateStatusSelector = (state: RootState) => state.contracts.recipients.update.status
export const recipientsFetchUpdateErrorSelector = (state: RootState) => state.contracts.recipients.update.error

export const recipientsFetchSelector = (state: RootState) => state.contracts.recipients.fetch
export const recipientsIsLoadingSelector = (state: RootState) =>
  [FETCH_STATUSES.IDLE, FETCH_STATUSES.REQUEST].includes(state.contracts.recipients.fetch.status)

export const recipientsFetchResendStatusSelector = (state: RootState) => state.contracts.recipients.resend.status
export const recipientsFetchResendErrorSelector = (state: RootState) => state.contracts.recipients.resend.error
export const recipientsIsResentSelector = (state: RootState) =>
  state.contracts.recipients.resend.status === FETCH_STATUSES.SUCCESS

export const recipientsFetchDeleteSelector = (state: RootState) => state.contracts.recipients.delete
export const recipientsFetchDeleteErrorSelector = (state: RootState) => state.contracts.recipients.delete.error
export const recipientsIsDeleteSelector = (state: RootState) =>
  state.contracts.recipients.delete.status === FETCH_STATUSES.SUCCESS

export const recipientsMessageInitialValuesSelector = (state: RootState) =>
  state.contracts.recipients.messageInitialValues
export const recipientsIsNegativeStatus = (state: RootState) => state.contracts.recipients.isNegativeStatus
