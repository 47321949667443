import styled, { css } from 'styled-components'

import { StyledThemeScheme } from '../../../themes/light'
import { ConfirmationStatus } from '../../../types/confirmationActions'
import { FilledCommentIcon } from '../../assets/icons'

const selectedStyles = css`
  z-index: 100;
`

const getColorByStatus = (theme: StyledThemeScheme, status: ConfirmationStatus | null) => {
  const mapStatus = {
    [ConfirmationStatus.ACCEPTED]: theme.palette.green,
    [ConfirmationStatus.REJECTED]: theme.palette.errorColor,
  }
  return status ? mapStatus[status] : theme.palette.yellow
}

export const Wrapper = styled.div<{ isSelected?: boolean }>`
  position: absolute;
  cursor: pointer;
  transform: translate3d(-3px, -38px, 0);
  z-index: 200;
  opacity: 0.85;
  transition: opacity 300ms;
  ${({ isSelected }) => isSelected && selectedStyles}

  &:hover {
    opacity: 1;
  }
`
export const Content = styled.div`
  pointer-events: none;
  filter: drop-shadow(rgba(0, 0, 0, 0.1) 1px 1px 3px);
`
export const GreyIcon = styled(FilledCommentIcon).attrs({ color: 'grey', size: 'high' })`
  position: absolute;
  left: 5px;
  top: -5px;
  z-index: 10;
`
export const NormalIcon = styled(FilledCommentIcon).attrs({ size: 'high' })<{ status: ConfirmationStatus | null }>`
  color: ${({ theme, status }) => getColorByStatus(theme, status)};
  position: relative;
  z-index: 11;
`
