import { Action } from 'redux'

import { Breadcrumb } from '../../../types/breadcrumb'
import {
  ContactsListResponse,
  Contract,
  ContractsCategory,
  ContractSuccessMessages,
  CONTRACT_STATUS,
} from '../../../types/contracts'
import { ContractsFiltersForm } from '../../../types/contractsFilters'
import { DuplicateDocumentRequestBody } from '../../../types/duplicateDocument'
import { FetchFailurePayload, FETCH_STATUSES } from '../../../types/fetch'
import { Sorting } from '../../../types/table'
import { Params } from '../../../utils/prepareUrlParams/prepareUrlParams'
import { Notification } from '../../../types/notifications'

export interface ContractsListState {
  fetchStatus: FETCH_STATUSES
  error: FetchFailurePayload | null
  data: Contract[]
  sorting: Sorting
  loadMore: boolean
  offset: number
  total: number
  limit: number
  searchText: string
  filters: ContractsFiltersForm
  isFirstLoad: boolean
  isAppliedFilters: boolean
  delete: {
    fetchStatus: FETCH_STATUSES
    error: FetchFailurePayload | null
  }
  successMessage: ContractSuccessMessages
  forceUpdate: number
  rename: {
    fetchStatus: FETCH_STATUSES
    error: FetchFailurePayload | null
  }
  moveToFolder: {
    fetchStatus: FETCH_STATUSES
    error: FetchFailurePayload | null
  }
  duplicate: {
    fetchStatus: FETCH_STATUSES
    error: FetchFailurePayload | null
  }
  breadcrumbs: Breadcrumb[]
  folderId: string
  isEmptyFolder: boolean
  isEmptyContracts: boolean
  notification: Notification | null
}

export enum ContractsListActions {
  'FETCH_CONTRACTS_LIST_DATA' = 'FETCH_CONTRACTS_LIST_DATA',
  'CONTRACTS_LIST_SET_SORTING' = 'CONTRACTS_LIST_SET_SORTING',
  'CLEAR_CONTRACTS' = 'CLEAR_CONTRACTS',
  'CLEAR_CONTRACTS_DATA' = 'CLEAR_CONTRACTS_DATA',
  'CONTRACTS_LIST_SET_OFFSET' = 'CONTRACTS_LIST_SET_OFFSET',
  'CONTRACTS_LIST_SET_SEARCH_TEXT' = 'CONTRACTS_LIST_SET_SEARCH_TEXT',
  'CONTRACTS_LIST_SET_FILTERS' = 'CONTRACTS_LIST_SET_FILTERS',
  'DELETE_CONTRACT' = 'DELETE_CONTRACT',
  'CLEAR_DELETE_CONTRACT' = 'CLEAR_DELETE_CONTRACT',
  'CONTRACTS_LIST_CLEAR_SUCCESS_MESSAGE' = 'CONTRACTS_LIST_CLEAR_SUCCESS_MESSAGE',
  'CONTRACTS_LIST_FORCE_UPDATE' = 'CONTRACTS_LIST_FORCE_UPDATE',
  'RENAME_CONTRACT' = 'RENAME_CONTRACT',
  'CLEAR_RENAME_CONTRACT' = 'CLEAR_RENAME_CONTRACT',
  'MOVE_CONTRACT_TO_FOLDER' = 'MOVE_CONTRACT_TO_FOLDER',
  'CLEAR_MOVE_CONTRACT_TO_FOLDER' = 'CLEAR_MOVE_CONTRACT_TO_FOLDER',
  'DUPLICATE_CONTRACT' = 'DUPLICATE_CONTRACT',
  'CLEAR_DUPLICATE_CONTRACT' = 'CLEAR_DUPLICATE_CONTRACT',
  'CONTRACTS_LIST_GO_TO_FOLDER' = 'CONTRACTS_LIST_GO_TO_FOLDER',
  'UPLOAD_CONTRACTS_FOLDER' = 'UPLOAD_CONTRACTS_FOLDER',
  'CONTRACTS_LIST_SET_IS_FIRST_LOAD' = 'CONTRACTS_LIST_SET_IS_FIRST_LOAD',
  'SET_CONTRACTS_NOTIFICATION' = 'SET_CONTRACTS_NOTIFICATION',
  'CLEAN_CONTRACTS_NOTIFICATION' = 'CLEAN_CONTRACTS_NOTIFICATION',
  'SET_CONTRACT_STATUS' = 'SET_CONTRACT_STATUS',
}

export interface FetchContractsList extends Action {
  payload: { params: Params }
}

export interface FetchContractsListSuccess extends Action {
  payload: { body: ContactsListResponse }
}

export interface ContractsListSetSorting extends Action {
  payload: Sorting
}

export interface ContractsListSetOffset extends Action {
  payload: { offset: number; loadMore: boolean }
}

export interface ContractsListSetSearchText extends Action {
  payload: { searchText: string }
}

export interface ContractsListSetFilters extends Action {
  payload: { filters: ContractsFiltersForm }
}

export interface FetchDeleteContract extends Action {
  payload: { id: string; isFolder: boolean; category?: ContractsCategory; recipient: boolean; isMobile: boolean }
}

export interface FetchDeleteContractSuccess extends Action {
  payload: { isFolder: boolean }
}

export type ClearDeleteContract = Action

export type ContractsListForceUpdate = Action

export type ContractsListSetIsFirstLoad = Action

export interface FetchRenameContract extends Action {
  payload: { id: string; name: string; isFolder: boolean }
}

export interface FetchRenameContractSuccess extends Action {
  payload: { id: string; name: string }
}

export type ClearRenameContract = Action

export interface FetchMoveContractToFolder extends Action {
  payload: { id: string; folderId: string; recipient: boolean; category?: ContractsCategory; isMobile?: boolean }
}

export type FetchMoveContractToFolderSuccess = Action

export type ClearMoveContractToFolder = Action

export interface FetchDuplicateContract extends Action {
  payload: { id: string; body: DuplicateDocumentRequestBody; category?: ContractsCategory; isMobile?: boolean }
}

export type FetchDuplicateContractSuccess = Action

export type ClearDuplicateContract = Action

export interface ContractsListGoToFolder extends Action {
  payload: { breadcrumb: Breadcrumb }
}

export interface SetContractsNotificationAction extends Action {
  payload: Notification
}

export interface SetContractStatusAction extends Action {
  payload: { contractId: string; status: CONTRACT_STATUS }
}
