import { of } from 'rxjs'

import { signingService } from '../../../api'
import { SessionStorageKeys } from '../../../constants/sessionStorage'
import { CONTRACT_STATUS } from '../../../types/contracts'
import { RecipientActions } from '../../../types/recipients'
import { FetchSignBody, FetchUnregisteredSignBody, SigningContractDocumentData } from '../../../types/signingContract'
import range from '../../../utils/range'
import SessionStorage from '../../../utils/SessionStorage'
import { cutMIME } from '../../../utils/signatures/cutMIME'

import { FetchSignRequestArgs, FetchViewRequestArgs } from './signing.types'

export const prepareImages = (recipientId: string, total: number) =>
  total > 0 ? range(0, total - 1).map((index) => signingService.getContractPageSrc(recipientId, index)) : []

export const showViewPage = (data?: SigningContractDocumentData | null) =>
  data?.recipient.action === RecipientActions.VIEW ||
  data?.recipient.signed ||
  data?.contractStatus === CONTRACT_STATUS.VOIDED ||
  data?.contractStatus === CONTRACT_STATUS.DELETED ||
  data?.contractStatus === CONTRACT_STATUS.DECLINED ||
  data?.contractStatus === CONTRACT_STATUS.LEFT_CORRECTION

export const fetchSignRequest = ({ signingData, recipientToken, defaultSign, placementById }: FetchSignRequestArgs) => {
  const recipientId = signingData.recipient.id
  const unregisteredSign = !!recipientToken

  if (!signingData.signingPlaces) {
    return of()
  }

  if (unregisteredSign) {
    const isInitials = signingData.recipient.action === RecipientActions.INITIAL
    const body: FetchUnregisteredSignBody = {
      signs: signingData.signingPlaces.map((place) => {
        const placeById = placementById[place.id]

        return {
          placeId: place.id,
          timestamp: placeById.timestamp || '',
        }
      }),
    }

    if (isInitials) {
      body.initials = cutMIME(defaultSign.image)
    } else {
      body.signature = cutMIME(defaultSign.image)
    }

    return signingService.fetchUnregisteredSign({
      recipientId,
      body,
      queryParams: { verify: SessionStorage.get(SessionStorageKeys.VERIFICATION_TOKEN) },
      recipientToken,
    })
  }

  const body: FetchSignBody = {
    signs: signingData.signingPlaces.map((place) => {
      const placeById = placementById[place.id]

      return {
        placeId: place.id,
        signatureId: placeById.signatureId || '',
        timestamp: placeById.timestamp || '',
      }
    }),
  }

  return signingService.fetchSign({
    recipientId,
    body,
    queryParams: { verify: SessionStorage.get(SessionStorageKeys.VERIFICATION_TOKEN) },
  })
}

export const fetchViewRequest = ({ signingData, recipientToken }: FetchViewRequestArgs) => {
  const recipientId = signingData.recipient.id
  const unregisteredSign = !!recipientToken

  if (unregisteredSign) {
    return signingService.fetchUnregisteredSign({
      recipientId,
      body: { signs: [] },
      queryParams: { verify: SessionStorage.get(SessionStorageKeys.VERIFICATION_TOKEN) },
      recipientToken,
    })
  }

  return signingService.fetchSign({
    recipientId,
    body: { signs: [] },
    queryParams: { verify: SessionStorage.get(SessionStorageKeys.VERIFICATION_TOKEN) },
  })
}
