import { Action } from 'redux'

import { FetchFailurePayload, FETCH_STATUSES } from '../../../types/fetch'
import { EditableElem } from '../../../types/editableElem'
import { RecipientsById } from '../../../types/recipients'
import { PlacementBody, PlacementById, PlacementByPages, PlacementSorted } from '../../../types/placement'

export interface PlacementState {
  byId: PlacementById
  byPages: PlacementByPages
  sorted: PlacementSorted
  shouldRegenerate: boolean
  fetch: {
    status: FETCH_STATUSES
    error: FetchFailurePayload | null
  }
  update: {
    status: FETCH_STATUSES
    error: FetchFailurePayload | null
  }
  generate: {
    status: FETCH_STATUSES
    error: FetchFailurePayload | null
  }
  send: {
    fetchStatus: FETCH_STATUSES
    error: FetchFailurePayload | null
  },
  status: {
    fetchStatus: FETCH_STATUSES,
    status: string,
    error: FetchFailurePayload | null
  }
}

export enum PlacementActions {
  'CLEAR_PLACEMENT' = 'CLEAR_PLACEMENT',
  'CLEAR_PLACEMENT_STATUS' = 'CLEAR_PLACEMENT_STATUS',
  'FETCH_PLACEMENT' = 'FETCH_PLACEMENT',
  'FETCH_UPDATE_PLACEMENT' = 'FETCH_UPDATE_PLACEMENT',
  'FETCH_GENERATE_PLACEMENT' = 'FETCH_GENERATE_PLACEMENT',
  'FETCH_SEND_CONTRACT' = 'FETCH_SEND_CONTRACT',
  'CLEAR_SEND_CONTRACT' = 'CLEAR_SEND_CONTRACT',
  'SET_SHOULD_REGENERATE' = 'SET_SHOULD_REGENERATE',
  'FETCH_CONTRACT_STATUS' = 'FETCH_CONTRACT_STATUS'
}

export type ClearPlacementAction = Action
export type ClearPlacementStatusAction = Action

export interface FetchPlacementAction extends Action {
  payload: { contractId: string; recipientsById: RecipientsById }
}
export interface FetchPlacementSuccessAction extends Action {
  payload: { byId: PlacementById; byPages: PlacementByPages; sorted: PlacementSorted }
}

export interface FetchUpdatePlacementAction extends Action {
  payload: { place: EditableElem; byId: PlacementById; sorted: PlacementSorted }
}
export interface FetchUpdatePlacementSuccessAction extends Action {
  payload: PlacementBody
}

export interface FetchGeneratePlacementAction extends Action {
  payload: { contractId: string; isMobile?: boolean }
}
export type FetchGeneratePlacementSuccessAction = Action

export interface FetchSendContractAction extends Action {
  payload: { contractId: string }
}
export type FetchSendContractSuccessAction = Action

export interface FetchContractStatusAction extends Action {
  payload: { contractId: string }
}
export interface FetchContractStatusSuccessAction extends Action {
  payload: { status: string }
}

export interface SetShouldRegenerateAction extends Action {
  payload: { shouldRegenerate: boolean }
}
