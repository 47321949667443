import styled from 'styled-components'

import { HelpIcon } from '../../assets/HelpIcon'

export const StyledIcon = styled(HelpIcon)`
  width: 7px;
  height: 11px;
  fill: ${(props) => props.theme.palette.grey};
  cursor: pointer;
`
