import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { modalsVisibleSelector } from '../../../../../store/modals/modals.selectors'
import { closeModal } from '../../../../../store/modals/modals.actions'
import { ADD_RECIPIENTS_MODALS } from '../../../../constants/addRecipients'
import { ContactsModal } from '../../../Modals/ContractAddRecipientsModals/ContactsModal/ContactsModal'

import { ModalsProps } from './Modals.types'

export const Modals: React.FC<ModalsProps> = ({ onSelectContact }) => {
  const dispatch = useDispatch()

  const showContactsModal = useSelector(modalsVisibleSelector(ADD_RECIPIENTS_MODALS.ADD_RECIPIENTS_CONTACTS))
  const onCloseContactsModal = () => {
    dispatch(closeModal(ADD_RECIPIENTS_MODALS.ADD_RECIPIENTS_CONTACTS))
  }

  return <>{showContactsModal && <ContactsModal onClose={onCloseContactsModal} onSelect={onSelectContact} />}</>
}
