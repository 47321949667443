import React, { MutableRefObject, useRef, useState } from 'react'
import Calendar from 'react-calendar'

import 'react-calendar/dist/Calendar.css'

import ClickOutsideListener from '../ClickOutsideListener'
import { getFormattedDate } from '../../../utils/date'
import Popover from '../Popover'
import TextInput from '../TextInput'
import { PickerArrowIcon } from '../../assets/icons'
import { getLanguage } from '../../../utils/rtl/getLanguage'

import { StyledCalendarWrapper } from './DatePicker.styles'
import { DatePickerProps } from './DatePicker.types'

export const DatePicker: React.FC<DatePickerProps> = ({
  placeholder,
  value,
  onChange,
  label,
  minDate,
  maxDate,
  name,
  error,
  help,
  disabled,
}) => {
  const inputRef: MutableRefObject<null> = useRef(null)
  const [visible, setVisible] = useState(false)
  const inputValue = value ? getFormattedDate(value) : ''
  const isArabic = getLanguage() === 'ar'

  const handleOpen = () => {
    setVisible(true)
  }
  const handleClose = () => {
    setVisible(false)
  }
  const handleChange = (value: Date) => {
    onChange(value)
    setVisible(false)
  }

  return (
    <div test-id="date-picker-wrapper">
      <ClickOutsideListener onClickAway={handleClose} test-id="date-picker-click-outside-listener">
        <TextInput
          name={name}
          icon="calendar"
          placeholder={placeholder}
          value={inputValue}
          onClick={handleOpen}
          onClickIcon={handleOpen}
          test-id="date-picker-text-input"
          readOnly
          label={label}
          error={error}
          ref={inputRef}
          iconColor="grey"
          iconSize="medium"
          iconViewBox="0 0 20 20"
          help={help}
          disabled={disabled}
        />
        <Popover targetRef={inputRef} visible={visible} onClose={handleClose} padding="0" width="auto">
          <StyledCalendarWrapper test-id="date-picker-calendar">
            <Calendar
              value={value}
              onClickDay={handleChange}
              maxDetail="month"
              view="month"
              prevLabel={<PickerArrowIcon viewBox="0 0 16 16" size="extraSmall" color="blue" />}
              nextLabel={<PickerArrowIcon viewBox="0 0 16 16" size="extraSmall" color="blue" flip />}
              next2Label={null}
              prev2Label={null}
              locale={isArabic ? 'ar' : 'en-US'}
              minDate={minDate}
              maxDate={maxDate}
            />
          </StyledCalendarWrapper>
        </Popover>
      </ClickOutsideListener>
    </div>
  )
}
