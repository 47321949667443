import { Breadcrumb } from '../../../types/breadcrumb'
import { FetchFailureAction, FetchFailurePayload } from '../../../types/fetch'
import { Sorting } from '../../../types/table'
import { TemplatesList } from '../../../types/templates'
import { createFetchActionTypes } from '../../../utils/createFetchActionTypes'
import { Params } from '../../../utils/prepareUrlParams/prepareUrlParams'

import * as TYPES from './list.types'

export const fetchTemplatesListDataTypes = createFetchActionTypes(TYPES.ListActions.FETCH_TEMPLATES_LIST_DATA)

export const fetchTemplatesListData = (params: Params): TYPES.FetchList => ({
  type: fetchTemplatesListDataTypes.request,
  payload: { params },
})
export const fetchTemplatesListDataSuccess = (body: TemplatesList): TYPES.FetchListSuccess => ({
  type: fetchTemplatesListDataTypes.success,
  payload: { body },
})
export const fetchTemplatesListDataFailure = (payload: FetchFailurePayload): FetchFailureAction => ({
  type: fetchTemplatesListDataTypes.failure,
  payload,
})

export const templatesListSetSorting = (sorting: Sorting): TYPES.ListSetSorting => ({
  type: TYPES.ListActions.TEMPLATES_LIST_SET_SORTING,
  payload: sorting,
})

export const templatesListSetSearchText = (searchText: string): TYPES.ListSetSearchText => ({
  type: TYPES.ListActions.TEMPLATES_LIST_SET_SEARCH_TEXT,
  payload: { searchText },
})

export const templatesListSetOffset = (offset: number, loadMore = false): TYPES.ListSetOffset => ({
  type: TYPES.ListActions.TEMPLATES_LIST_SET_OFFSET,
  payload: { offset, loadMore },
})

export const templatesListCleanNotification = () => ({
  type: TYPES.ListActions.TEMPLATES_LIST_CLEAN_NOTIFICATION,
})

export const cleanTemplates = () => ({
  type: TYPES.ListActions.CLEAN_TEMPLATES,
})

export const templatesListForceUpdate = () => ({
  type: TYPES.ListActions.TEMPLATES_LIST_FORCE_UPDATE,
})

export const templateListGoToFolder = (breadcrumb: Breadcrumb): TYPES.TemplatesListGoToFolder => ({
  type: TYPES.ListActions.TEMPLATES_LIST_GO_TO_FOLDER,
  payload: { breadcrumb },
})
