import React from 'react'
import { useSelector } from 'react-redux'

import { CONTRACTS_MODALS } from '../../../../../constants/contracts'
import { SLASHES_DATE_FORMAT } from '../../../../../constants/date'
import { modalsPayloadSelector } from '../../../../../store/modals/modals.selectors'
import translations from '../../../../../translations/keys'
import { Contract, ContractDate } from '../../../../../types/contracts'
import { prepareStatus } from '../../../../../utils/contracts/prepareStatus'
import { getFormattedDate } from '../../../../../utils/date/date'
import Modal from '../../../Modal'
import Typography from '../../../../ui/Typography'
import { TYPOGRAPHY_NAMES } from '../../../../ui/Typography/Typography.types'
import Box from '../../../../ui/Box'

import { ViewContractInfoModalProps } from './ViewContractInfoModal.types'

export const ViewContractInfoModal: React.FC<ViewContractInfoModalProps> = ({ onClose }) => {
  const { contract }: { contract: Contract } = useSelector(modalsPayloadSelector(CONTRACTS_MODALS.VIEW_CONTRACT_INFO))

  const renderDate = (label: string, contractDate?: ContractDate) => {
    if (!contractDate) {
      return null
    }

    return (
      <Box mt={3}>
        <Typography name={TYPOGRAPHY_NAMES.tableColumn} color="grey">
          {label}
        </Typography>
        <Box mt={1} display="flex" justifyContent="space-between">
          {contractDate.userName}
          <Typography name={TYPOGRAPHY_NAMES.body14} color="grey">
            {getFormattedDate(new Date(contractDate.date), SLASHES_DATE_FORMAT)}
          </Typography>
        </Box>
      </Box>
    )
  }

  return (
    <Modal onClose={onClose}>
      <Box display="flex" flexDirection="column" width="100%" mt={3}>
        <Box textAlign="center">
          <Typography name={TYPOGRAPHY_NAMES.H1}>{translations.VIEW_INFO}</Typography>
        </Box>
        {renderDate(translations.CREATED_BY_SPACE, contract.createDate)}
        {renderDate(translations.LAST_MODIFIED_BY, contract.updateDate)}
        <Box mt={3} display="flex" justifyContent="space-between">
          <Box display="flex" flexDirection="column">
            <Typography name={TYPOGRAPHY_NAMES.tableColumn} color="grey">
              {translations.STATUS}
            </Typography>
            <Box mt={1}>{prepareStatus(contract)}</Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  )
}
