import { of } from 'rxjs'

import { isDev } from '../../api/getEnvs'
import translations from '../../translations'
import { FetchAxiosError, FetchFailureAction, FetchFailurePayload } from '../../types/fetch'
import { isString } from '../isString'

export const catchFetchError = <P>(
  action: (error: FetchFailurePayload, payload?: P) => FetchFailureAction,
  payload?: P
) => (error: FetchAxiosError) => {
  const response = isString(error.response) ? JSON.parse(error.response as string) : error.response

  const actionData: FetchFailurePayload = {
    errorMessage: translations.SOMETHING_WRONG,
    errorCodes: [],
    lastErrorCode: '',
  }

  if (response && response.message.length > 0) {
    const lastErrorCode = Array.isArray(response.details) ? response.details[0].error : response.message[0]
    const translationError = translations[lastErrorCode]

    actionData.errorCodes = response.message
    actionData.lastErrorCode = lastErrorCode

    if (translationError) {
      actionData.errorMessage = translationError
    }

    if (response.details) {
      actionData.details = response.details
    }
  }

  if (isDev()) {
    console.error(error)
  }

  return of(action(actionData as FetchFailurePayload, payload))
}
