import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { TEMPLATES_MODALS } from '../../../../constants/templates'
import { modalsVisibleSelector } from '../../../../store/modals/modals.selectors'
import { closeModal } from '../../../../store/modals/modals.actions'
import UploadTemplateToWithRecipientsModal from '../../../components/Modals/TemplatesModals/UploadTemplateToWithRecipientsModal'
import CreateFolderForTemplateModal from '../../../components/Modals/TemplatesModals/CreateFolderForTemplateModal'

export const Modals: React.FC = () => {
  const dispatch = useDispatch()

  const showUploadTemplateToWithRecipientsModal = useSelector(
    modalsVisibleSelector(TEMPLATES_MODALS.UPLOAD_TEMPLATE_TO_WITH_RECIPIENTS)
  )
  const onCloseUploadTemplateToWithRecipientsModal = () => {
    dispatch(closeModal(TEMPLATES_MODALS.UPLOAD_TEMPLATE_TO_WITH_RECIPIENTS))
  }

  const showCreateFolder = useSelector(modalsVisibleSelector(TEMPLATES_MODALS.CREATE_FOLDER_FOR_TEMPLATE))
  const handleTemplateCreateFolderClose = () => {
    dispatch(closeModal(TEMPLATES_MODALS.CREATE_FOLDER_FOR_TEMPLATE))
  }

  return (
    <>
      {showUploadTemplateToWithRecipientsModal && (
        <UploadTemplateToWithRecipientsModal onClose={onCloseUploadTemplateToWithRecipientsModal} />
      )}
      {showCreateFolder && <CreateFolderForTemplateModal onClose={handleTemplateCreateFolderClose} />}
    </>
  )
}
