import { Epic, combineEpics, ofType } from 'redux-observable'
import { from, zip } from 'rxjs'
import { catchError, map, mergeMap, switchMap, takeUntil } from 'rxjs/operators'

import { documentsService, templateService } from '../../../api'
import { catchFetchError } from '../../../utils/catchFetchError'
import { generatePlacement, preparePlacement, preparePlacementForRequest } from '../../../utils/placement'
import { loadImage } from '../../../utils/loadImage'

import * as ACTIONS from './placement.actions'

const fetchPlacement: Epic = (action$) =>
  action$.pipe(
    ofType(ACTIONS.fetchPlacementTypes.request),
    mergeMap(({ payload }) =>
      templateService.fetchPlacement(payload.templateId).pipe(
        map((response) => ACTIONS.fetchPlacementSuccess(...preparePlacement(response, payload.recipientsById))),
        catchError(catchFetchError(ACTIONS.fetchPlacementFailure)),
        takeUntil(action$.pipe(ofType(ACTIONS.fetchPlacementTypes.success, ACTIONS.fetchPlacementTypes.failure)))
      )
    )
  )

const fetchUpdatePlacement: Epic = (action$) =>
  action$.pipe(
    ofType(ACTIONS.fetchUpdatePlacementTypes.request),
    switchMap(({ payload }) =>
      templateService.fetchAddPlacement(preparePlacementForRequest(payload.byId, payload.sorted)).pipe(
        map(({ response }) => ACTIONS.fetchUpdatePlacementSuccess(response)),
        catchError(catchFetchError(ACTIONS.fetchUpdatePlacementFailure)),
        takeUntil(
          action$.pipe(ofType(ACTIONS.fetchUpdatePlacementTypes.success, ACTIONS.fetchUpdatePlacementTypes.failure))
        )
      )
    )
  )

const fetchGeneratePlacement: Epic = (action$, state$) =>
  action$.pipe(
    ofType(ACTIONS.fetchGeneratePlacementTypes.request),
    mergeMap(({ payload }) =>
      templateService.fetchTemplate(payload.templateId).pipe(
        map((response) => response.document.id),
        switchMap((documentId) =>
          zip(
            documentsService.fetchDocumentTotalPages(documentId).pipe(map((response) => response.totalPages)),
            from(loadImage(documentsService.getDocumentPreviewPageSrc(documentId, 0)))
          ).pipe(
            map(([totalPages, image]) =>
              generatePlacement(state$.value.templates, totalPages, image.width, image.height)
            ),
            switchMap((placement) =>
              templateService.fetchAddPlacement(placement).pipe(map(() => ACTIONS.fetchGeneratePlacementSuccess()))
            )
          )
        ),
        catchError(catchFetchError(ACTIONS.fetchGeneratePlacementFailure)),
        takeUntil(
          action$.pipe(ofType(ACTIONS.fetchGeneratePlacementTypes.success, ACTIONS.fetchGeneratePlacementTypes.failure))
        )
      )
    )
  )

export const placementEpics = combineEpics(fetchPlacement, fetchUpdatePlacement, fetchGeneratePlacement)
