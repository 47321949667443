import React from 'react'

export const FolderSvg: React.FC = (props) => (
  <svg width="156" height="96" viewBox="0 0 156 96" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M105.49 45H102V38.732C102 37.226 100.774 36 99.268 36H96V33.586L95.505 33.091L95.504 33.089L80.414 18H56V29H50.732C49.226 29 48 30.226 48 31.732V75.419H48.006C48.001 75.982 48.176 76.533 48.528 76.994C49.018 77.634 49.76 78 50.565 78H95.324C96.48 78 97.498 77.221 97.774 76.187L108 48.149V47.972C108 46.25 106.944 45 105.49 45ZM99.268 38C99.671 38 100 38.328 100 38.732V45H96V38H99.268ZM81 21.415L92.586 33H81V21.415ZM58 20H79V35H94V45H60.731C60.588 45 60.447 45.012 60.309 45.035C59.335 45.197 58.523 45.907 58.281 46.813L58 47.585V20ZM50 31.732C50 31.328 50.329 31 50.732 31H56V53.035L50 69.399V31.732ZM95.869 75.583C95.803 75.828 95.579 76 95.324 76H50.565C50.322 76 50.18 75.861 50.117 75.778C50.054 75.696 49.957 75.522 49.994 75.37L56 58.898V58.903L60.16 47.499L60.186 47.417C60.252 47.172 60.477 47 60.731 47H105.49C105.87 47 105.967 47.546 105.992 47.819L95.869 75.583Z"
      fill="#6085B3"
    />
    <path
      d="M64 35H74C74.552 35 75 34.553 75 34C75 33.447 74.552 33 74 33H64C63.448 33 63 33.447 63 34C63 34.553 63.448 35 64 35Z"
      fill="#E7EAED"
    />
    <path
      d="M64 28H74C74.552 28 75 27.553 75 27C75 26.447 74.552 26 74 26H64C63.448 26 63 26.447 63 27C63 27.553 63.448 28 64 28Z"
      fill="#E7EAED"
    />
    <path
      d="M64 42H88C88.552 42 89 41.553 89 41C89 40.447 88.552 40 88 40H64C63.448 40 63 40.447 63 41C63 41.553 63.448 42 64 42Z"
      fill="#E7EAED"
    />
  </svg>
)
