import React from 'react'
import { useHistory } from 'react-router'

import translations from '../../../../../translations/keys'
import AuthorizationBox from '../../../../components/Authorization/AuthorizationBox'
import Box from '../../../../ui/Box'
import Link from '../../../../ui/Link'
import { location } from '../../../../../utils/location'
import Button from '../../../../ui/Button'
import FormWrapper from '../../../../components/FormWrapper'

export const UpdatedSuccessfully = () => {
  const history = useHistory()
  const handleSubmit = () => {
    history.push(location.registrationUrl())
  }

  return (
    <AuthorizationBox title={<Box textAlign="center">{translations.CONTRACT_UPDATED_SUCCESSFULLY}</Box>}>
      <FormWrapper size="lg">
        <Box mt={4} textAlign="center">
          {translations.KEEP_IN_ON_PLACE}
        </Box>
        <Box mt={4} mb={4} textAlign="center">
          <Button onClick={handleSubmit} type="submit">
            {translations.SIGN_UP}
          </Button>
        </Box>
        <Box textAlign="center" mb={8}>
          {translations.REGISTRATION_HAVE_ACCOUNT} <Link to={location.loginUrl()}>{translations.LOG_IN}</Link>
        </Box>
      </FormWrapper>
    </AuthorizationBox>
  )
}
