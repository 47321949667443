export enum SubscriptionFeatures {
  FEATURE_CONTRACTS_COUNT = 'FEATURE_CONTRACTS_COUNT',
  FEATURE_CONTRACTS_UNLIMITED = 'FEATURE_CONTRACTS_UNLIMITED',
  FEATURE_USER_TEMPLATES = 'FEATURE_USER_TEMPLATES',
  FEATURE_UNLIMITED_BULK_SENDING = 'FEATURE_UNLIMITED_BULK_SENDING',
  FEATURE_DASHBOARD = 'FEATURE_DASHBOARD',
  FEATURE_CLM = 'FEATURE_CLM',
  FEATURE_EKYC = 'FEATURE_EKYC',
  FEATURE_SIGNATURE_MATCHING = 'FEATURE_SIGNATURE_MATCHING',
  FEATURE_API_INTEGRATION = 'FEATURE_API_INTEGRATION',
}

export interface SubscriptionFeature {
  name: SubscriptionFeatures
  limit?: number
}

interface TermsItem {
  price: number
  term: number
}

interface Plan {
  id: string
  name: string
  features: SubscriptionFeature[]
}

export interface SubscriptionPlanResponse extends Plan {
  terms: TermsItem[]
}

export interface SubscriptionPlan extends Plan {
  yearPrice: number
  monthPrice: number
}

export interface IUnsubscribe {
  success: boolean
}

export interface SubscriptionUserPlan extends SubscriptionPlan {
  expireData: string
  contractsCount: number
  price: number
  term: number
  system: boolean
  highest: boolean
}

export interface EnabledFeatures {
  [key: string]: boolean
}

export interface EnabledLimits {
  [key: string]: number
}
