import { FetchFailureAction, FetchFailurePayload } from '../../types/fetch'
import { VerificationPasswordBody } from '../../types/verificationPassword'
import { VerifyPasswordKeys } from '../../types/verifyPassword'
import { createFetchActionTypes } from '../../utils/createFetchActionTypes'

import * as TYPES from './verifyPassword.types'

export const fetchVerifyPasswordTypes = createFetchActionTypes(TYPES.VerifyPasswordActions.FETCH_VERIFY_PASSWORD)

export const fetchVerifyPassword = (
  key: VerifyPasswordKeys,
  body: VerificationPasswordBody
): TYPES.FetchVerifyPasswordAction => ({
  type: fetchVerifyPasswordTypes.request,
  payload: { body, key },
})

export const fetchVerifyPasswordSuccess = (
  key: VerifyPasswordKeys,
  token: string
): TYPES.FetchVerifyPasswordSuccessAction => ({
  type: fetchVerifyPasswordTypes.success,
  payload: { token, key },
})

export const fetchVerifyPasswordFailure = (
  payload: FetchFailurePayload,
  key?: VerifyPasswordKeys
): FetchFailureAction => ({
  type: fetchVerifyPasswordTypes.failure,
  payload: { ...payload, key },
})

export const cleanVerifyPassword = (key: VerifyPasswordKeys): TYPES.CleanVerifyPassword => ({
  type: TYPES.VerifyPasswordActions.CLEAN_VERIFY_PASSWORD,
  payload: { key },
})
