import React from 'react'
import { useSelector } from 'react-redux'

import { isBannerVisibleSelector } from '../../../store/banner/banner.selectors'
import { Modal as UiModal } from '../../ui/Modal/Modal'

import { ModalProps } from './Modal.types'

export const Modal: React.FC<ModalProps> = (props) => {
  const isBannerVisible = useSelector(isBannerVisibleSelector)

  return (
    <UiModal {...props} offsetTop={isBannerVisible}>
      {props.children}
    </UiModal>
  )
}
