import { Action } from 'redux'

import { ContractHistory } from '../../../types/contractHistory'
import { FetchFailurePayload, FETCH_STATUSES } from '../../../types/fetch'

export interface HistoryState {
  fetchStatus: FETCH_STATUSES
  error: FetchFailurePayload | null
  data: ContractHistory
}

export enum HistoryActions {
  'FETCH_CONTRACT_HISTORY' = 'FETCH_CONTRACT_HISTORY',
  'CLEAN_CONTRACT_HISTORY' = 'CLEAN_CONTRACT_HISTORY',
}

export interface FetchHistory extends Action {
  payload: { contractId: string }
}

export interface FetchHistorySuccess extends Action {
  payload: { history: ContractHistory }
}
