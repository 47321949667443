import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { openModal } from '../../../../store/modals/modals.actions'
import { location } from '../../../../utils/location'
import { EDIT_PROFILE_MODALS } from '../../../constants/editProfile'

export const useShowNotifications = () => {
  const dispatch = useDispatch()
  const search = useLocation().search
  const params = new URLSearchParams(search)
  const verificationFail = params.get(location.queryParams.VERIFICATION_FAIL)

  useEffect(() => {
    if (verificationFail === 'true') {
      dispatch(openModal(EDIT_PROFILE_MODALS.EDIT_PROFILE_EXPIRED_LINK))
    }
  }, [verificationFail])
}
