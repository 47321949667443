import { FetchFailureAction, FetchFailurePayload } from '../../../types/fetch'
import { createFetchActionTypes } from '../../../utils/createFetchActionTypes'

import * as TYPES from './moveToFolder.types'

export const fetchMoveTemplateToFolderTypes = createFetchActionTypes(TYPES.MoveToFolderActions.MOVE_TEMPLATE_TO_FOLDER)

export const fetchMoveTemplateToFolder = (id: string, folderId: string): TYPES.FetchMoveTemplateToFolder => ({
  type: fetchMoveTemplateToFolderTypes.request,
  payload: { id, folderId },
})

export const fetchMoveTemplateToFolderSuccess = (): TYPES.FetchMoveTemplateToFolderSuccess => ({
  type: fetchMoveTemplateToFolderTypes.success,
})

export const fetchMoveTemplateToFolderFailure = (payload: FetchFailurePayload): FetchFailureAction => ({
  type: fetchMoveTemplateToFolderTypes.failure,
  payload,
})

export const clearMoveTemplateToFolder = (): TYPES.ClearMoveTemplateToFolder => ({
  type: TYPES.MoveToFolderActions.CLEAR_MOVE_TEMPLATE_TO_FOLDER,
})
