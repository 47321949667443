import { FetchFailureAction, FetchFailurePayload } from '../../../types/fetch'
import {
  FetchActivateSubscriptionBody,
  FetchRegisterCardBody,
  FormTokenizationFields,
} from '../../../types/subscriptionPayment'
import { createFetchActionTypes } from '../../../utils/createFetchActionTypes'

import * as TYPES from './payment.types'

export const fetchPaymentFormFieldsTypes = createFetchActionTypes(
  TYPES.PaymentActions.FETCH_SUBSCRIPTION_PAYMENT_FORM_FIELDS
)
export const fetchRegisterCardTypes = createFetchActionTypes(TYPES.PaymentActions.FETCH_SUBSCRIPTION_REGISTER_CARD)
export const fetchActivateSubscriptionTypes = createFetchActionTypes(TYPES.PaymentActions.FETCH_ACTIVATE_SUBSCRIPTION)

export const fetchPaymentFormFields = (): TYPES.FetchFormFieldsAction => ({
  type: fetchPaymentFormFieldsTypes.request,
})
export const fetchPaymentFormFieldsSuccess = (fields: FormTokenizationFields): TYPES.FetchFormFieldsSuccessAction => ({
  type: fetchPaymentFormFieldsTypes.success,
  payload: { fields },
})
export const fetchPaymentFormFieldsFailure = (payload: FetchFailurePayload): FetchFailureAction => ({
  type: fetchPaymentFormFieldsTypes.failure,
  payload,
})

export const clearSubscriptionPayment = () => ({
  type: TYPES.PaymentActions.CLEAN_SUBSCRIPTION_PAYMENT,
})

export const fetchRegisterCard = (body: FetchRegisterCardBody): TYPES.FetchRegisterCardAction => ({
  type: fetchRegisterCardTypes.request,
  payload: { body },
})
export const fetchRegisterCardSuccess = (threeDSecureUrl: string): TYPES.FetchRegisterCardSuccessAction => ({
  type: fetchRegisterCardTypes.success,
  payload: { threeDSecureUrl },
})
export const fetchRegisterCardFailure = (payload: FetchFailurePayload): FetchFailureAction => ({
  type: fetchRegisterCardTypes.failure,
  payload,
})

export const setPaymentProcessError = (payload: FetchFailurePayload): FetchFailureAction => ({
  type: TYPES.PaymentActions.SET_SUBSCRIPTION_PAYMENT_ERROR,
  payload,
})

export const verifyPaymentCard = () => ({
  type: TYPES.PaymentActions.VERIFY_SUBSCRIPTION_PAYMENT_CARD,
})

export const cleanPaymentForm = () => ({
  type: TYPES.PaymentActions.CLEAN_SUBSCRIPTION_PAYMENT_FORM,
})

export const fetchActivateSubscription = (
  body: FetchActivateSubscriptionBody,
  trial: boolean
): TYPES.FetchActivateSubscriptionAction => ({
  type: fetchActivateSubscriptionTypes.request,
  payload: { body, trial },
})
export const fetchActivateSubscriptionSuccess = (
  threeDSecureUrl: string
): TYPES.FetchActivateSubscriptionSuccessAction => ({
  type: fetchActivateSubscriptionTypes.success,
  payload: { threeDSecureUrl },
})
export const fetchActivateSubscriptionFailure = (payload: FetchFailurePayload): FetchFailureAction => ({
  type: fetchActivateSubscriptionTypes.failure,
  payload,
})
