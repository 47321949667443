export enum EDIT_PROFILE_MODALS {
  EDIT_PROFILE_EDIT_PHONE_MODAL = 'EDIT_PROFILE_EDIT_PHONE_MODAL',
  EDIT_PROFILE_CONFIRM_PHONE_MODAL = 'EDIT_PROFILE_CONFIRM_PHONE_MODAL',
  EDIT_PROFILE_CHANGE_EMAIL_MODAL = 'EDIT_PROFILE_CHANGE_EMAIL_MODAL',
  EDIT_PROFILE_LEAVE_MODAL = 'EDIT_PROFILE_LEAVE_MODAL',
  EDIT_PROFILE_VERIFICATION_PASSWORD = 'EDIT_PROFILE_VERIFICATION_PASSWORD',
  EDIT_PROFILE_CHECK_EMAIL = 'EDIT_PROFILE_CHECK_EMAIL',
  EDIT_PROFILE_EXPIRED_LINK = 'EDIT_PROFILE_EXPIRED_LINK',
  EDIT_PROFILE_CANCEL_CHANGES = 'EDIT_PROFILE_CANCEL_CHANGES',
}
