import { UploadDocumentBody } from '../../types/uploadDocument'
import { convertToFormData } from '../convertToFormData/convertToFormData'
import { renameFile } from '../file/renameFile'

import { prepareFolderName } from './prepareFolderName'

export const prepareUploadDocumentBody = (body: UploadDocumentBody) => {
  const file = renameFile(body.file, body.fileName)
  const folderName = prepareFolderName(body.folderName)

  return convertToFormData({ folderName, file })
}
