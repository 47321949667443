import { Action } from 'redux'

import { CreateContractFromTemplateBody } from '../../../types/createContractFromTemplate'
import { FetchFailurePayload, FETCH_STATUSES } from '../../../types/fetch'
import { RecipientData } from '../../../types/recipients'
import { SIGNING_TYPES } from '../../../types/signingOrder'

export interface CreateContractState {
  recipients: {
    list: RecipientData[] | null
    order: SIGNING_TYPES | null
  }
  fetchStatus: FETCH_STATUSES
  error: FetchFailurePayload | null
  contractId: string | null
}

export enum CreateContractActions {
  'FETCH_CREATE_CONTRACT_FROM_TEMPLATE' = 'FETCH_CREATE_CONTRACT_FROM_TEMPLATE',
  'SET_CREATE_CONTRACT_RECIPIENTS_DATA' = 'SET_CREATE_CONTRACT_RECIPIENTS_DATA',
  'CLEAN_CREATE_CONTRACT_FROM_TEMPLATE' = 'CLEAN_CREATE_CONTRACT_FROM_TEMPLATE',
}

export interface FetchCreateContract extends Action {
  payload: { body: CreateContractFromTemplateBody }
}

export interface SetCreateContractRecipientData extends Action {
  payload: { recipients: RecipientData[]; order: SIGNING_TYPES }
}
export interface FetchCreateContractSuccess extends Action {
  payload: { contractId: string }
}
