import React from 'react'

import ErrorBoundary from '../../components/ErrorBoundary/ErrorBoundary'

import { TemplatePreview as TemplatePreviewPage } from './TemplatePreview'

export const TemplatePreview = () => (
  <ErrorBoundary>
    <TemplatePreviewPage />
  </ErrorBoundary>
)
