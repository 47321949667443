import { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import translations from '../../../../translations/keys'
import { PLACEMENT_MODALS } from '../../../constants/placement'
import { placementGenerateIsSuccess } from '../../../../store/contracts/placement/placement.selectors'
import { openModal } from '../../../../store/modals/modals.actions'
import { ToolbarControl } from '../../../ui/DocViewerToolbar/DocViewerToolbar.types'
import { ReloadIcon } from '../../../assets/icons'
import { viewOptionsIsSignatureNamesVisibleSelector } from '../../../../store/contracts/viewOptions/viewOptions.selectors'

export const useToolbarActions = () => {
  const dispatch = useDispatch()
  const isSignatureNamesVisible = useSelector(viewOptionsIsSignatureNamesVisibleSelector)
  const resetDisabled = useSelector(placementGenerateIsSuccess)

  const toolbarActions = useMemo(() => {
    const actions: ToolbarControl[] = [
      // TODO UQD-1410 uncomment when this will work
      // {
      //   name: translations.GRID,
      //   disabled: true,
      //   Icon: GridIcon,
      //   onClick: () => {
      //     console.log('Grid')
      //   },
      // },
      // {

      // {
      //   name: translations.GROUP_EDIT,
      //   disabled: true,
      //   Icon: ResizeIcon,
      //   onClick: () => {
      //     console.log('Group edit')
      //   },
      // },
      // {
      //   name: translations.APPLY_TO_ALL_PAGES,
      //   disabled: true,
      //   Icon: CheckCircleIcon,
      //   onClick: () => {
      //     console.log('Apply to all pages')
      //   },
      // },
      {
        name: translations.RESET,
        disabled: resetDisabled,
        Icon: ReloadIcon,
        onClick: () => {
          dispatch(openModal(PLACEMENT_MODALS.PLACEMENT_RESET))
        },
      },
      // {
      //   name: translations.UNDO,
      //   disabled: true,
      //   Icon: BackwardIcon,
      //   onClick: () => {
      //     console.log('Undo')
      //   },
      // },
      // {
      //   name: translations.REDO,
      //   disabled: true,
      //   Icon: ForwardIcon,
      //   onClick: () => {
      //     console.log('Redo')
      //   },
      // },
    ]

    return actions
  }, [resetDisabled, isSignatureNamesVisible])

  return toolbarActions
}
