import React from 'react'

import { NOTIFICATION_MODAL_TYPES } from '../../../../ui/Modals/NotificationModal/NotificationModal.constants'
import { NotificationModalView } from '../../../../ui/Modals/NotificationModal/NotificationModalView'
import translations from '../../../../../translations/keys'

import { CancelChangesModalProps } from './CancelChangesModal.types'

export const CancelChangesModal: React.FC<CancelChangesModalProps> = (props) => (
  <NotificationModalView
    type={NOTIFICATION_MODAL_TYPES.WARNING}
    onClose={props.onClose}
    description={translations.ASK_CANCEL_CHANGES}
    successButtonText={translations.OK}
    onSuccess={props.onSuccess}
  />
)
