import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { CONTRACTS_MODALS } from '../../../../constants/contracts'
import { closeModal } from '../../../../store/modals/modals.actions'
import { modalsVisibleSelector } from '../../../../store/modals/modals.selectors'
import { SigningOrderModal } from '../../../components/Modals/ContractPreviewModals/SigningOrderModal/SigningOrderModal'
import SaveAsTemplateModal from '../../../components/Modals/ContractsModals/SaveAsTemplateModal'
import CreateFolderForTemplateModal from '../../../components/Modals/TemplatesModals/CreateFolderForTemplateModal'
import { CONTRACT_PREVIEW_MODALS } from '../../../constants/contractPreview'

interface ContractPreviewModalsProps {
  contractId: string
}

export const ContractPreviewModals: React.FC<ContractPreviewModalsProps> = ({ contractId }) => {
  const dispatch = useDispatch()

  const showSigningOrderModal = useSelector(modalsVisibleSelector(CONTRACT_PREVIEW_MODALS.SIGNING_ORDER_MODAL))
  const handleShowSigningOrderClose = () => {
    dispatch(closeModal(CONTRACT_PREVIEW_MODALS.SIGNING_ORDER_MODAL))
  }

  const showSaveContractAsTemplate = useSelector(modalsVisibleSelector(CONTRACTS_MODALS.SAVE_CONTRACT_AS_TEMPLATE))
  const handleSaveContractAsTemplateClose = () => {
    dispatch(closeModal(CONTRACTS_MODALS.SAVE_CONTRACT_AS_TEMPLATE))
  }

  const showCreateFolderForTemplate = useSelector(modalsVisibleSelector(CONTRACTS_MODALS.CREATE_FOLDER_FOR_TEMPLATE))
  const handleCreateFolderForTemplateClose = () => {
    dispatch(closeModal(CONTRACTS_MODALS.CREATE_FOLDER_FOR_TEMPLATE))
  }

  return (
    <>
      {showSigningOrderModal && <SigningOrderModal contractId={contractId} onClose={handleShowSigningOrderClose} />}
      {showSaveContractAsTemplate && <SaveAsTemplateModal onClose={handleSaveContractAsTemplateClose} />}
      {showCreateFolderForTemplate && <CreateFolderForTemplateModal onClose={handleCreateFolderForTemplateClose} />}
    </>
  )
}
