import { ElementType } from 'react'

import { DrawableCompNames, DrawingStyle } from '../../../types/drawableElem'
import Comment from '../Comment'
import Strikethrough from '../Strikethrough'
import Correction from '../Correction'
import { DocTextField, DocDateField } from '../DocField'

export const drawingCompMap: { [drawingCompName: string]: { style: DrawingStyle; Component: ElementType } } = {
  [DrawableCompNames.COMMENT]: {
    style: 'one-step',
    Component: Comment,
  },
  [DrawableCompNames.CORRECTION]: {
    style: 'two-step',
    Component: Correction,
  },
  [DrawableCompNames.STRIKETHROUGH]: {
    style: 'two-step',
    Component: Strikethrough,
  },
  [DrawableCompNames.TEXT]: {
    style: 'two-step',
    Component: DocTextField,
  },
  [DrawableCompNames.DATE]: {
    style: 'two-step',
    Component: DocDateField,
  },
}

export const DOC_VIEWER_WRAPPER_ID = 'doc-viewer-wrapper'
export const DOC_VIEWER_CONTENT_ID = 'doc-viewer-content'
export const DOC_VIEWER_DRAWING_ID = 'doc-viewer-drawing'
