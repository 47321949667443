import { Action } from 'redux'

import { Breadcrumb } from '../../../types/breadcrumb'
import { FetchFailurePayload, FETCH_STATUSES } from '../../../types/fetch'
import { Sorting } from '../../../types/table'
import { Template, TemplatesList } from '../../../types/templates'
import { Params } from '../../../utils/prepareUrlParams/prepareUrlParams'
import { Notification } from '../../../types/notifications'

export interface ListState {
  data: Template[]
  sorting: Sorting
  searchText: string
  offset: number
  total: number
  limit: number
  isFirstLoad: boolean
  loading: boolean
  fetchStatus: FETCH_STATUSES
  error: FetchFailurePayload | null
  loadMore: boolean
  notification: Notification | null
  forceUpdate: number
  breadcrumbs: Breadcrumb[]
  folderId: string
  isEmptyFolder: boolean
  isEmptyContracts: boolean
}

export enum ListActions {
  FETCH_TEMPLATES_LIST_DATA = 'FETCH_TEMPLATES_LIST_DATA',
  TEMPLATES_LIST_SET_SORTING = 'TEMPLATES_SET_SORTING',
  TEMPLATES_LIST_SET_SEARCH_TEXT = 'TEMPLATES_SET_SEARCH_TEXT',
  TEMPLATES_LIST_SET_OFFSET = 'TEMPLATES_SET_OFFSET',
  TEMPLATES_LIST_CLEAN_NOTIFICATION = 'TEMPLATES_CLEAN_NOTIFICATION',
  CLEAN_TEMPLATES = 'CLEAN_TEMPLATES',
  TEMPLATES_LIST_FORCE_UPDATE = 'TEMPLATES_LIST_FORCE_UPDATE',
  TEMPLATES_LIST_GO_TO_FOLDER = 'TEMPLATES_LIST_GO_TO_FOLDER',
}

export interface FetchList extends Action {
  payload: { params: Params }
}
export interface FetchListSuccess extends Action {
  payload: { body: TemplatesList }
}

export interface ListSetSorting extends Action {
  payload: Sorting
}

export interface ListSetOffset extends Action {
  payload: { offset: number; loadMore: boolean }
}

export interface ListSetSearchText extends Action {
  payload: { searchText: string }
}

export interface TemplatesListGoToFolder extends Action {
  payload: { breadcrumb: Breadcrumb }
}
