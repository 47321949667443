import React from 'react'
import ContentLoader from 'react-content-loader'

import { blueExtraLight, glowColor } from '../../../themes/colors'

const FILL_COLOR = blueExtraLight
const GLOW_COLOR = glowColor
const SPEED = 1.5
const SVG_WIDTH = '100%'
const SVG_HEIGHT = 500
const ROW_HEIGHT = 56
const CELL_Y = 116
const CELL_H = 18
const CELL_BORDER_Y = 153
const RADIUS = 3
const ROWS = [
  ['25%', '18%', '13%', '14%'],
  ['20%', '14%', '12%', '11%'],
  ['22%', '16%', '11%', '14%'],
  ['18%', '12%', '10%', '16%'],
  ['24%', '16%', '13%', '10%'],
  ['25%', '18%', '11%', '14%'],
  ['20%', '15%', '13%', '13%'],
  ['23%', '14%', '10%', '15%'],
]
const columnsOffsets = ['0', '31%', '54%', '69%']

export const ContactsSkeleton: React.FC = () => (
  <ContentLoader
    display="block"
    width={SVG_WIDTH}
    height={SVG_HEIGHT}
    speed={SPEED}
    backgroundColor={FILL_COLOR}
    foregroundColor={GLOW_COLOR}
  >
    <rect width="384" height="40" x="0" y="0" rx={RADIUS} ry={RADIUS} />

    {columnsOffsets.map((offset, index) => (
      <rect key={index} width="5%" height="14" x={offset} y="72" rx={RADIUS} ry={RADIUS} />
    ))}
    <rect width="100%" height="1" x="0" y="97" rx={RADIUS} ry={RADIUS} />

    {ROWS.map((widths, i) => (
      <React.Fragment key={i}>
        {columnsOffsets.map((offset, index) => (
          <rect
            key={index}
            width={widths[index]}
            height={CELL_H}
            x={offset}
            y={CELL_Y + ROW_HEIGHT * i}
            rx={RADIUS}
            ry={RADIUS}
          />
        ))}
        <rect width="18" height={CELL_H} x="94%" y={CELL_Y + ROW_HEIGHT * i} rx={RADIUS} ry={RADIUS} />
        <rect width="18" height={CELL_H} x="97%" y={CELL_Y + ROW_HEIGHT * i} rx={RADIUS} ry={RADIUS} />
        <rect width="100%" height="1" x="0" y={CELL_BORDER_Y + ROW_HEIGHT * i} rx={RADIUS} ry={RADIUS} />
      </React.Fragment>
    ))}
  </ContentLoader>
)
