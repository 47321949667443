import { FETCH_STATUSES } from '../../types/fetch'
import { RootState } from '../rootReducer.types'

export const editProfilePhoneSelector = (state: RootState) => state.editProfile.phone

export const editProfilePhoneSuccessSelector = (state: RootState) =>
  state.editProfile.phone.fetchStatus === FETCH_STATUSES.SUCCESS

export const editProfilePhoneErrorSelector = (state: RootState) => state.editProfile.phone.error

export const editProfileConfirmPhoneSelector = (state: RootState) => state.editProfile.confirmPhone

export const editProfileConfirmPhoneSuccessSelector = (state: RootState) =>
  state.editProfile.confirmPhone.fetchStatus === FETCH_STATUSES.SUCCESS

export const editProfileConfirmPhoneErrorSelector = (state: RootState) => state.editProfile.confirmPhone.error

export const editProfileChangeEmailSelector = (state: RootState) => state.editProfile.email

export const editProfileChangeEmailErrorSelector = (state: RootState) => state.editProfile.email.error

export const editProfileDataSelector = (state: RootState) => state.editProfile.data

export const editProfileErrorSelector = (state: RootState) => state.editProfile.data.error

export const editProfileDataSuccessSelector = (state: RootState) =>
  state.editProfile.data.fetchStatus === FETCH_STATUSES.SUCCESS

export const editProfileResendVerificationSelector = (state: RootState) => state.editProfile.resendVerification

export const editProfileResendVerificationSuccessSelector = (state: RootState) =>
  state.editProfile.resendVerification.fetchStatus === FETCH_STATUSES.SUCCESS

export const editProfileUnconfirmedEmailSelector = (state: RootState) => state.editProfile.unconfirmedEmail

export const editProfileUnconfirmedEmailNewEmailSelector = (state: RootState) =>
  state.editProfile.unconfirmedEmail.newEmail

export const editProfileUnconfirmedEmailSuccessSelector = (state: RootState) =>
  state.editProfile.unconfirmedEmail.fetchStatus === FETCH_STATUSES.SUCCESS

export const editProfilePreventEmailUpdateSelector = (state: RootState) => state.editProfile.preventEmailUpdate

export const editProfilePreventEmailUpdateSuccessSelector = (state: RootState) =>
  state.editProfile.preventEmailUpdate.fetchStatus === FETCH_STATUSES.SUCCESS

export const editProfileInitialDataSelector = (state: RootState) => state.editProfile.initialData

export const editProfileInitialDataSuccessSelector = (state: RootState) =>
  state.editProfile.initialData.fetchStatus === FETCH_STATUSES.SUCCESS
