export interface RegistrationRequestBody {
  firstName: string
  lastName: string
  phone: string
  email: string
  password: string
  country: string
  industry: string
}

export interface RegistrationFormValues extends RegistrationRequestBody {
  confirmPassword: string
  accepted: boolean
}

export interface RegistrationResponse {
  userId: string
  linkId: string
  phoneValidate: string
}

export interface RegistrationResendCodeResponse {
  linkId: string
}

export enum RegistrationFields {
  firstName = 'firstName',
  lastName = 'lastName',
  phone = 'phone',
  email = 'email',
  password = 'password',
  country = 'country',
  industry = 'industry',
  confirmPassword = 'confirmPassword',
  accepted = 'accepted',
}
