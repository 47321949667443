import { FetchFailureAction, FETCH_STATUSES } from '../../../types/fetch'
import { createReducer } from '../../createReducer'
import { fetchVerifyOTPTypes } from '../otpVerification/otpVerification.actions'

import * as ACTIONS from './verification.actions'
import * as TYPES from './verification.types'

type Actions = FetchFailureAction | TYPES.FetchVerificationSuccess

export const initialState: TYPES.VerificationState = {
  fetchStatus: FETCH_STATUSES.IDLE,
  error: null,
  verified: null,
}

export const verification = createReducer<TYPES.VerificationState, Actions>(initialState, {
  [ACTIONS.fetchVerificationTypes.request]: (state) => ({
    ...state,
    fetchStatus: FETCH_STATUSES.REQUEST,
  }),
  [ACTIONS.fetchVerificationTypes.success]: (state, action) => ({
    ...state,
    fetchStatus: FETCH_STATUSES.SUCCESS,
    verified: (action as TYPES.FetchVerificationSuccess).payload.body.verified,
  }),
  [ACTIONS.fetchVerificationTypes.failure]: (state, action) => ({
    ...state,
    fetchStatus: FETCH_STATUSES.FAILURE,
    error: (action as FetchFailureAction).payload,
  }),
  [TYPES.VerificationActions.CLEAN_CONTRACT_VERIFICATION]: () => ({ ...initialState }),
  [fetchVerifyOTPTypes.success]: (state) => ({
    ...state,
    verified: true,
  }),
})
