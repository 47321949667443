import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { FormikProps } from 'formik'

import translations from '../../../../../translations/keys'
import { UploadDocumentFields, UploadDocumentForm } from '../../../../../types/uploadDocument'
import { TEMPLATES_MODALS } from '../../../../../constants/templates'
import { location } from '../../../../../utils/location'
import { prepareFolderName } from '../../../../../utils/documents/prepareFolderName'
import { useShowFetchError } from '../../../../hooks/useShowFetchError'
import { useTemplatesFoldersFetch } from '../../../../../hooks/templates/useTemplatesFoldersFetch'
import { useDispatchUnmount } from '../../../../../hooks/useDispatchUnmount'
import { openModal } from '../../../../../store/modals/modals.actions'
import { modalsPayloadSelector } from '../../../../../store/modals/modals.selectors'
import {
  clearRecipients,
  fetchAddClmOnlyRecipients,
} from '../../../../../store/templates/recipients/recipients.actions'
import * as FOLDERS_SELECTORS from '../../../../../store/templates/folders/folders.selectors'
import {
  recipientsFetchAddClmOnlySelector,
  recipientsTemplateIdSelector,
} from '../../../../../store/templates/recipients/recipients.selectors'
import UploadDocumentToModal from '../../DocumentModals/UploadDocumentToModal'
import { setSuccessMessage } from '../../../../../store/templates/preview/preview.actions'
import { SuccessMessages } from '../../../../../store/templates/preview/preview.types'

import { UploadTemplateToWithRecipientsModalProps } from './UploadTemplateToWithRecipientsModal.types'

export const UploadTemplateToWithRecipientsModal: React.FC<UploadTemplateToWithRecipientsModalProps> = ({
  onClose,
}) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const formRef: React.RefObject<FormikProps<UploadDocumentForm>> = useRef(null)
  const templateId = useSelector(recipientsTemplateIdSelector)
  const foldersIsLoading = useSelector(FOLDERS_SELECTORS.templatesFoldersIsLoadingSelector)
  const folders = useSelector(FOLDERS_SELECTORS.templatesFoldersDataSelector)
  const { recipients } = useSelector(modalsPayloadSelector(TEMPLATES_MODALS.UPLOAD_TEMPLATE_TO_WITH_RECIPIENTS))
  const fetchAddClmOnly = useSelector(recipientsFetchAddClmOnlySelector)
  const lastAddedFolder = useSelector(FOLDERS_SELECTORS.templatesLastAddedFolderSelector)

  useEffect(() => {
    const form = formRef.current

    if (lastAddedFolder && form) {
      form.setFieldValue(UploadDocumentFields.folderName, lastAddedFolder.value)
    }
  }, [lastAddedFolder])

  useEffect(() => {
    if (templateId) {
      dispatch(setSuccessMessage(SuccessMessages.TEMPLATES_SUCCESSFUL_CREATION))
      onClose()
      history.push(location.templatesRecipientsDiagramUrl(templateId))
    }
  }, [templateId])

  useTemplatesFoldersFetch()
  useDispatchUnmount(clearRecipients)
  useShowFetchError(fetchAddClmOnly.status, fetchAddClmOnly.error)

  const handleSubmit = (values: UploadDocumentForm) => {
    const folderName = folders.find((folder) => folder.value === values.folderName)?.name || values.folderName

    dispatch(fetchAddClmOnlyRecipients(values.fileName, recipients, prepareFolderName(folderName)))
  }

  const handleCreateFolder = () => {
    dispatch(openModal(TEMPLATES_MODALS.CREATE_FOLDER_FOR_TEMPLATE))
  }

  return (
    <UploadDocumentToModal
      initialTouchedDisabled
      ref={formRef}
      title={translations.TEMPLATES_CREATE_CLM_MODAL}
      foldersIsLoading={foldersIsLoading}
      folders={folders}
      onCreateFolder={handleCreateFolder}
      onSubmit={handleSubmit}
      onClose={onClose}
    />
  )
}
