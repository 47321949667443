import { combineEpics, Epic, ofType } from 'redux-observable'
import { catchError, map, mergeMap, takeUntil } from 'rxjs/operators'

import { catchFetchError } from '../../../utils/catchFetchError'
import { templateService } from '../../../api'

import * as ACTIONS from './reupload.actions'

export const fetchReupload: Epic = (action$) =>
  action$.pipe(
    ofType(ACTIONS.fetchReuploadTypes.request),
    mergeMap(({ payload }) =>
      templateService.fetchReuploadTemplate(payload.id, payload.file).pipe(
        map(() => ACTIONS.fetchReuploadSuccess()),
        catchError(catchFetchError(ACTIONS.fetchReuploadFailure)),
        takeUntil(action$.pipe(ofType(ACTIONS.fetchReuploadTypes.success, ACTIONS.fetchReuploadTypes.failure)))
      )
    )
  )

export const reuploadEpics = combineEpics(fetchReupload)
