import styled from 'styled-components'

export const StyledRow = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  ${({ theme }) => `@media ${theme.devices.mobile} {
    flex-wrap: wrap;
  }`}
`
export const StyledCell = styled.div`
  width: 50%;

  & ~ & {
    margin-left: 32px;
  }

  ${({ theme }) => `@media ${theme.devices.mobile} {
    width: 100%;
    & ~ & {
      margin-left: 0;
    }
  }`}
`
export const StyledButtonWrapper = styled.div`
  width: 148px;
  ${({ theme }) => `@media ${theme.devices.mobile} {
    width: 100%
  }`}
`
export const StyledConfirmation = styled.div`
  ${({ theme }) => `@media ${theme.devices.mobile} {
    margin-top: 24px;
  }`}
`
