import React, { useCallback, useEffect, useState } from 'react'

import Skeleton from '../Skeleton'
import { loadImage } from '../../../../../utils/loadImage'

import { PageImageProps } from './PageImage.types'
import { StyledImg } from './PageImage.styles'

export const PageImage: React.FC<PageImageProps> = ({ src, page, recipientToken, pageHeight }) => {
  const [imgSrc, setImgSrc] = useState<string | null>(null)

  const downloadImg = useCallback(async () => {
    try {
      const image = await loadImage(src, recipientToken)
      setImgSrc(image.src)
    } catch (error) {
      setImgSrc('')
    }
  }, [src, recipientToken])

  useEffect(() => {
    downloadImg()
  }, [downloadImg])

  if (!imgSrc) {
    return <Skeleton pageHeight={pageHeight} />
  }

  return <StyledImg src={imgSrc} alt={`Contract page #${page}`} />
}
