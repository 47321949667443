import styled from 'styled-components'

import { LogoIcon } from '../../assets/LogoIcon'
import Button from '../../ui/Button'
import { BUTTON_VARIANTS } from '../../ui/Button/Button.types'

export const StyledHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
`
export const StyledTitle = styled.div`
  width: 200px;
  margin-right: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`
export const StyledInactiveBtn = styled(Button).attrs({ variant: BUTTON_VARIANTS.INACTIVE })`
  & ~ & {
    margin-left: 40px;
  }
`
export const StyledBtn = styled(Button)`
  width: 208px;

  ${({ theme }) => `@media ${theme.devices.tablet} {
  width: auto;
}`}
`
export const StyledInactiveBtnsContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  padding: 9px 0;

  ${({ theme }) => `@media ${theme.devices.laptop} {
    width: 100%;
    order: 2;
    margin-left: 0;
    padding-top: 16px;
    padding-bottom: 1px;
  }`}
`
export const StyledBtnsContainer = styled.div`
  margin-left: 24px;

  ${StyledBtn} ~ ${StyledBtn} {
    margin-left: 40px;
  }

  ${({ theme }) => `@media ${theme.devices.tablet} {
  margin-left: 10px;

  ${StyledBtn} ~ ${StyledBtn} {
    margin-left: 16px;
  }
}`}
`

export const LogoWrapper = styled.div`
  width: 68px;
`
export const Logo = styled(LogoIcon)`
  ${({ theme }) => `@media ${theme.devices.tablet} {
    height: 48px;
  }`}
`
