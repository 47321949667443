import { useDispatch } from 'react-redux'

import { useButtons as useCommonButtons } from '../../../hooks/contractSign/useButtons'
import { BUTTON_VARIANTS } from '../../../ui/Button/Button.types'
import translations from '../../../../translations/keys'
import { CalendarIcon } from '../../../assets/icons'
import { openProgress } from '../../../../store/progress/progress.actions'

import { usePlacementById } from './usePlacementById'

export const useButtons = () => {
  const dispatch = useDispatch()
  const placementById = usePlacementById()

  const progress = {
    label: translations.HISTORY,
    onClick: () => {
      dispatch(openProgress())
    },
    variant: BUTTON_VARIANTS.SECONDARY,
    MobileIcon: CalendarIcon,
    isMobileOnly: true,
  }

  return useCommonButtons(placementById, { progress })
}
