import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { FORGOT_PASSWORD_NOTIFICATIONS } from '../../../types/forgotPassword'
import { CheckEmailModal } from '../../components/Modals/CheckEmailModal/CheckEmailModal'
import { modalsVisibleSelector } from '../../../store/modals/modals.selectors'
import { closeModal } from '../../../store/modals/modals.actions'
import { location } from '../../../utils/location'
import { VerifyEmailModal } from '../../components/Modals/ForgotPasswordModals/VerifyEmailModal'
import translations from '../../../translations/keys'

export const ForgotPasswordModals = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { t } = useTranslation()

  const showNotificationCheckEmail = useSelector(
    modalsVisibleSelector(FORGOT_PASSWORD_NOTIFICATIONS.NOTIFICATION_CHECK_EMAIL)
  )
  const onCloseNotificationCheckEmail = () => {
    dispatch(closeModal(FORGOT_PASSWORD_NOTIFICATIONS.NOTIFICATION_CHECK_EMAIL))
    history.push(location.loginUrl())
  }
  const showNotificationVerifyEmail = useSelector(
    modalsVisibleSelector(FORGOT_PASSWORD_NOTIFICATIONS.NOTIFICATION_VERIFY_EMAIL)
  )
  const onCloseNotificationVerifyEmail = () => {
    dispatch(closeModal(FORGOT_PASSWORD_NOTIFICATIONS.NOTIFICATION_VERIFY_EMAIL))
    history.push(location.loginUrl())
  }

  return (
    <>
      {showNotificationCheckEmail && (
        <CheckEmailModal
          onClose={onCloseNotificationCheckEmail}
          description={t(translations.CHECK_EMAIL_DESCRIPTION)}
        />
      )}
      {showNotificationVerifyEmail && <VerifyEmailModal onClose={onCloseNotificationVerifyEmail} />}
    </>
  )
}
