export interface OTPVerificationRequestBody {
  code: string
}

export enum OTPVerificationModalFields {
  code = 'code',
}

export interface OTPVerificationAttemptsResponse {
  attempts: number
}
