import React from 'react'

import MainTemplate from '../../components/Main/MainTemplate'

import { LegalNotice as LegalNoticePage } from './LegalNotice'

export const LegalNotice = () => (
  <MainTemplate simple>
    <LegalNoticePage />
  </MainTemplate>
)
