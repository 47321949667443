import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'

import { CONTRACTS_MODALS } from '../../../../../../constants/contracts'
import { fetchNoneVerification } from '../../../../../../store/contracts/noneVerification/noneVerification.actions'
import * as SIGNING_SELECTORS from '../../../../../../store/contracts/signing/signing.selectors'
import * as VERIFICATION_SELECTORS from '../../../../../../store/contracts/verification/verification.selectors'
import { openModal } from '../../../../../../store/modals/modals.actions'
import { RecipientAuthentication } from '../../../../../../types/recipients'
import { location } from '../../../../../../utils/location'

export const useOpenSigning = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const contract = useSelector(SIGNING_SELECTORS.signingContractDocumentDataSelector)
  const authentication = useSelector(SIGNING_SELECTORS.signingRecipientAuthentication)
  const verified = useSelector(VERIFICATION_SELECTORS.contractsVerificationVerified)
  const verifiedFetched = useSelector(VERIFICATION_SELECTORS.contractsVerificationFetched)

  const openContract = useCallback(() => {
    if (!contract || !verifiedFetched) {
      return null
    }

    if (verified) {
      return history.push(location.contractsSignUrl(contract.id))
    }

    if (authentication === RecipientAuthentication.NONE) {
      return dispatch(
        fetchNoneVerification(contract.id, () => {
          history.push(location.contractsSignUrl(contract.id))
        })
      )
    }

    if (authentication === RecipientAuthentication.OTP) {
      return dispatch(openModal(CONTRACTS_MODALS.OTP_VERIFICATION, { contractId: contract.id }))
    }

    if (authentication === RecipientAuthentication.EKYC || authentication === RecipientAuthentication.EKYC_ID) {
      return dispatch(openModal(CONTRACTS_MODALS.EKYC_VERIFICATION))
    }
  }, [authentication, contract, verified, verifiedFetched])

  useEffect(() => {
    openContract()
  }, [openContract])
}
