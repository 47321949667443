import { ApiIntegrationData } from '../../types/apiIntegration'
import { Ajax } from '../ajax'

class ApiIntegrationService extends Ajax {
  pathname = 'public-token'

  fetchPublicToken = () => this.getJSON<ApiIntegrationData>(`${this.pathname}`)

  fetchGeneratePublicToken = () => this.getJSON<ApiIntegrationData>(`${this.pathname}/generate`)
}

export const apiIntegrationService = new ApiIntegrationService()
