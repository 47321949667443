import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import useAuth from '../../hooks/useAuth'

export const useLogoutByParams = (params?: string[]) => {
  const { authenticated, logout } = useAuth()
  const search = useLocation().search
  const [checkingParams, setCheckingParams] = useState(Boolean(params))

  useEffect(() => {
    if (params && authenticated) {
      const queryParams = new URLSearchParams(search)
      const needLogout = params.some((queryParam) => queryParams.get(queryParam) !== null)

      needLogout && logout()
    }
    setCheckingParams(false)
  }, [authenticated, params, search, logout])

  return {
    checkingParams,
  }
}
