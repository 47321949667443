import React from 'react'

import { SvgIconProps } from '../../ui/SvgIcon/SvgIcon.types'
import SvgIcon from '../../ui/SvgIcon'

export const RenameIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <path d="M20.3404 3.659C19.4618 2.78033 18.0374 2.78033 17.1587 3.659L17.0677 3.74998H3.74999C3.33578 3.74998 3 4.08576 3 4.49997V20.25C3 20.6642 3.33578 21 3.74999 21H19.5C19.9142 21 20.25 20.6642 20.25 20.25V6.93103L20.3404 6.84061C21.219 5.96202 21.219 4.53766 20.3404 3.659ZM19.2797 4.71966C19.5726 5.01252 19.5726 5.48716 19.2797 5.78001L13.5285 11.5318L12.0907 12.9697C11.9054 13.155 11.705 13.3214 11.4928 13.4688C10.9956 13.814 10.4322 14.0526 9.83847 14.1687C10.0019 13.3125 10.419 12.52 11.0411 11.8979L18.2194 4.71966C18.5122 4.42681 18.9869 4.42681 19.2797 4.71966ZM18.75 8.43164V19.5H4.49998V5.25H15.5676L9.98019 10.8374C8.97071 11.8469 8.36366 13.1899 8.27254 14.6154L8.26442 14.7457L8.25113 14.9515C8.22842 15.303 8.45089 15.6105 8.76761 15.7134C8.84066 15.7371 8.91878 15.75 9.00003 15.75C10.2168 15.75 11.3941 15.3726 12.3761 14.6822C12.6511 14.4889 12.9108 14.2711 13.1517 14.0302L14.5935 12.5882L18.75 8.43164Z" />
  </SvgIcon>
)
