import { Card } from './cards'

export enum SubscriptionPaymentTerms {
  YEAR = 'year',
  MONTH = 'month',
}

export type FormTokenizationFields = {
  [key: string]: string
  access_code: string
  language: string
  merchant_extra: string
  merchant_identifier: string
  merchant_reference: string
  return_url: string
  service_command: string
  signature: string
}

export type FetchFormTokenizationResponse = FormTokenizationFields

export interface PaymentEventError {
  error?: string
}

export interface FetchRegisterCardBody {
  tokenName: string
  expiredDate: string
}

export interface TokenizationResponse extends PaymentEventError {
  token: string
  expiryDate: string
}

export type CardVerifiedResponse = Card & PaymentEventError

export interface FetchActivateSubscriptionBody extends FetchRegisterCardBody {
  subscriptionId: string
  term: number
}
