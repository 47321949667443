import React, { forwardRef } from 'react'
import { Formik, FormikProps } from 'formik'

import Modal from '../../../Modal'
import { ContractSvg } from '../../../../assets/ContractSvg'
import Box from '../../../../ui/Box'
import Typography from '../../../../ui/Typography'
import { TYPOGRAPHY_NAMES } from '../../../../ui/Typography/Typography.types'
import translations from '../../../../../translations/keys'
import Button from '../../../../ui/Button'
import FormDropdown from '../../../../ui/Forms/FormDropdown'
import { DuplicateDocumentFields, DuplicateDocumentForm } from '../../../../../types/duplicateDocument'
import FormTextInput from '../../../../ui/Forms/FormTextInput'
import { FILE_NAME_MAX_LENGTH } from '../../../../../validation/fileNameValidationSchema'
import { duplicateDocumentValidationSchema } from '../../../../../validation/documents/duplicateDocumentValidationSchema'
import { DuplicateDocumentSkeleton } from '../../../Skeletons/DuplicateDocumentSkeleton'
import { duplicateDocumentInitialValues } from '../../../../../initialValues/duplicateDocumentInitialValues'

import { StyledWrapper, StyledForm } from './DuplicateDocumentModal.styles'
import { DuplicateDocumentModalProps } from './DuplicateDocumentModal.types'

export const DuplicateDocumentModal = forwardRef<FormikProps<DuplicateDocumentForm>, DuplicateDocumentModalProps>(
  ({ onClose, folders, loading, onSubmit, oldName, description }, ref) => {
    const initialValues = {
      ...duplicateDocumentInitialValues,
      [DuplicateDocumentFields.name]: oldName,
    }

    const renderForm = () => (
      <StyledForm>
        <FormTextInput
          name={DuplicateDocumentFields.name}
          label={translations.NAME}
          placeholder={translations.DOCUMENT_NAME_PLACEHOLDER}
          maxLength={FILE_NAME_MAX_LENGTH}
        />
        <FormDropdown
          name={DuplicateDocumentFields.folderId}
          label={translations.CHOOSE_FOLDER}
          placeholder={translations.CHOOSE_FOLDER}
          options={folders}
          maxHeight={110}
        />
        <Box width="100%" mt={2}>
          <Button type="submit" fullWidth>
            {translations.DUPLICATE}
          </Button>
        </Box>
      </StyledForm>
    )

    return (
      <Modal onClose={onClose}>
        <StyledWrapper>
          <ContractSvg />
          <Box mt={3} mb={2}>
            <Typography name={TYPOGRAPHY_NAMES.H1}>{translations.DUPLICATE_FILE}</Typography>
          </Box>
          <Box mb={4}>{description}</Box>
          {loading ? (
            <DuplicateDocumentSkeleton />
          ) : (
            <Formik
              innerRef={ref}
              onSubmit={onSubmit}
              initialValues={initialValues}
              validationSchema={duplicateDocumentValidationSchema}
            >
              {renderForm}
            </Formik>
          )}
        </StyledWrapper>
      </Modal>
    )
  }
)
