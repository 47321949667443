import React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { clearSuccessMessage } from '../../../store/templates/preview/preview.actions'
import * as SELECTORS from '../../../store/templates/preview/preview.selectors'
import translations from '../../../translations/keys'
import { useTemplatePreviewFetch } from '../../../hooks/templatePreview/useTemplatePreviewFetch'
import { useShowFetchError } from '../../hooks/useShowFetchError'
import { useDispatchUnmount } from '../../../hooks/useDispatchUnmount'
import { removeExtension } from '../../../utils/file/removeExtension'
import { location } from '../../../utils/location'
import { clearRecipients } from '../../../store/templates/recipients/recipients.actions'
import { openModal } from '../../../store/modals/modals.actions'
import Box from '../../ui/Box'
import NotificationBadge from '../../ui/NotificationBadge'
import DocViewer from '../../ui/DocViewer'
import { PreviewHeader } from '../../components/PreviewHeader/PreviewHeader'
import FullScreenTemplate from '../../components/Main/FullScreenTemplate'
import { BUTTON_VARIANTS } from '../../ui/Button/Button.types'
import { PlayIcon, SaveIcon, TrashIcon } from '../../assets/icons'
import { TemplateRouteParams } from '../../types/template'
import { TEMPLATE_PREVIEW_MODALS } from '../../constants/templatePreview'
import { TEMPLATES_MODALS } from '../../../constants/templates'

import * as Styled from './TemplatePreview.styles'
import Modals from './components/Modals'

export const TemplatePreview = () => {
  const history = useHistory()
  const { templateId } = useParams<TemplateRouteParams>()
  const dispatch = useDispatch()
  const fetchStatus = useSelector(SELECTORS.previewFetchStatusSelector)
  const successMessage = useSelector(SELECTORS.previewSuccessMessageSelector)
  const error = useSelector(SELECTORS.previewErrorSelector)
  const data = useSelector(SELECTORS.previewDataSelector)

  const handleNotificationBadgeClose = () => {
    dispatch(clearSuccessMessage())
  }

  const handleAddCLM = () => {
    history.push(location.templatesAddRecipientsUrl(templateId))
  }

  const actions = [
    {
      buttonIcon: 'delete',
      label: translations.TEMPLATES_DELETE_AND_CLOSE,
      onClick: () => {
        dispatch(
          openModal(TEMPLATES_MODALS.DELETE_TEMPLATE, {
            description: translations.DELETE_TEMPLATE_DESCRIPTION,
            id: templateId,
            folderId: false,
          })
        )
      },
      MobileIcon: TrashIcon,
    },
  ]
  const buttons = [
    {
      label: translations.TEMPLATES_SAVE_AND_CLOSE,
      onClick: () => {
        dispatch(openModal(TEMPLATE_PREVIEW_MODALS.SAVE_TEMPLATE_WITHOUT_SLM))
      },
      variant: BUTTON_VARIANTS.SECONDARY,
      MobileIcon: SaveIcon,
    },
    {
      label: translations.TEMPLATES_ADD_CLM,
      onClick: handleAddCLM,
      MobileIcon: PlayIcon,
    },
  ]

  useTemplatePreviewFetch(templateId)
  useDispatchUnmount(clearRecipients)
  useShowFetchError(fetchStatus, error)

  return (
    <FullScreenTemplate
      headerContent={<PreviewHeader actions={actions} buttons={buttons} title={removeExtension(data?.name || '')} />}
    >
      <Styled.Container test-id="template-preview-container">
        {data && <DocViewer pages={data.pages} />}
        {successMessage && (
          <Styled.MessageContainer test-id="templates-preview-message-container">
            <Box ml={4} mt={4}>
              <NotificationBadge
                isShow
                isAutoClose
                message={translations[successMessage]}
                onClose={handleNotificationBadgeClose}
              />
            </Box>
          </Styled.MessageContainer>
        )}
      </Styled.Container>
      <Modals />
    </FullScreenTemplate>
  )
}
