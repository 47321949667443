import React from 'react'

import * as colors from '../../../themes/colors'

export enum BUTTON_VARIANTS {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  LINK = 'link',
  TEXT = 'text',
  INACTIVE = 'inactive',
  INHERIT = 'inherit',
}

export interface ButtonProps {
  variant?: BUTTON_VARIANTS
  disabled?: boolean
  compact?: boolean
  error?: string
  type?: 'submit' | 'button'
  fullWidth?: boolean
  icon?: string
  iconColor?: keyof typeof colors
  ref?: React.MutableRefObject<HTMLButtonElement>
  children?: React.ReactNode
  textColor?: keyof typeof colors
  'data-action'?: boolean
  // TODO: change any to React.MouseEvent when ApiIntegration handlesubmit is fixed
  onClick?: (event: any) => void
}
