import styled from 'styled-components'

import Box from '../../Box'

export const CycleNumber = styled(Box).attrs({
  height: 48,
  pl: 2,
  pr: 2,
})`
  line-height: 48px;
  background-color: ${({ theme }) => theme.palette.blueExtraLight};
  border: 1px solid ${({ theme }) => theme.palette.strokeGrey};
  border-radius: 4px;
`
export const Recipients = styled(Box).attrs({
  display: 'flex',
  flexWrap: 'wrap',
})``
