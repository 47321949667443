import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import Box from '../Box'
import Typography from '../Typography'
import { TYPOGRAPHY_NAMES } from '../Typography/Typography.types'
import Popover from '../Popover'

import { DocViewerToolbarProps } from './DocViewerToolbar.types'
import * as Styled from './DocViewerToolbar.styles'

// TODO: rework Toolbar to support options in a better way
export const DocViewerToolbar: React.FC<DocViewerToolbarProps> = ({ activeDrawingElem, controls, onChange }) => {
  const actionItemRef: React.MutableRefObject<null> = useRef(null)
  const [visible, setVisible] = useState(false)
  const { t } = useTranslation()
  const handleOpen = () => {
    if (!visible) {
      setVisible(true)
    }
  }
  const handleClose = () => {
    setVisible(false)
  }
  const handleChange = (name: string, value: string | number) => () => {
    onChange && onChange(name, value)
    setVisible(false)
  }

  return (
    <Styled.Wrapper>
      <Styled.Controls>
        {controls.map((control, idx) => {
          if (typeof control === 'string') {
            return <Styled.Separator key={idx} />
          }

          const { Icon, name, disabled, selected, options, value, pointerMode, drawingCompName, onClick } = control
          const active = selected || (activeDrawingElem && activeDrawingElem === drawingCompName)

          return (
            <Styled.Control
              data-testid="toolbar-control"
              ref={options ? actionItemRef : null}
              key={name}
              disabled={disabled}
              selected={active}
              data-type={pointerMode}
              data-comp-type={drawingCompName}
              noPointerEvents={Boolean(drawingCompName)}
              onClick={options ? handleOpen : onClick}
            >
              {Icon && <Icon color={active ? 'inherit' : 'grey'} />}
              {options && (
                <Typography name={TYPOGRAPHY_NAMES.body16} color="grey">
                  {value}
                </Typography>
              )}
              <Box textAlign="center" pt={1}>
                <Typography name={TYPOGRAPHY_NAMES.bodySmall12} color={disabled ? 'grey' : undefined}>
                  {t(name)}
                </Typography>
              </Box>
              {options && (
                <Popover
                  padding="5px 0"
                  width="auto"
                  offsetTop={-8}
                  targetRef={actionItemRef}
                  visible={visible}
                  onClose={handleClose}
                >
                  {options.map((option) => (
                    <Styled.Option key={option} onClick={handleChange(name, option)}>
                      {option}
                    </Styled.Option>
                  ))}
                </Popover>
              )}
            </Styled.Control>
          )
        })}
      </Styled.Controls>
    </Styled.Wrapper>
  )
}
