import { FetchFailureAction, FETCH_STATUSES } from '../../../types/fetch'
import { DEFAULT_FIELD_FONT_SIZE } from '../../../types/documentField'
import { createReducer } from '../../createReducer'
import {
  addFieldById,
  updateFieldById,
  updateFieldsFontSize,
  updateFieldText,
  removeFieldById,
  clearFieldsText,
  getFirstEmptyField,
} from '../../../utils/documentField'

import * as TYPES from './fields.types'
import * as ACTIONS from './fields.actions'

type Actions =
  | TYPES.AddFieldAction
  | TYPES.SetFieldAction
  | TYPES.UnsetFieldAction
  | TYPES.ClearFieldsAction
  | TYPES.FetchFieldsSuccessAction
  | TYPES.FetchUpdateFieldsSuccessAction
  | FetchFailureAction
  | TYPES.SetFontSizeAction
  | TYPES.SetFieldTextAction
  | TYPES.ClearFieldsTextAction
  | TYPES.ScrollToEmptyFieldSuccessAction

export const initialState: TYPES.FieldsState = {
  byId: {},
  byPages: {},
  fontSize: DEFAULT_FIELD_FONT_SIZE,
  fetch: {
    status: FETCH_STATUSES.IDLE,
    error: null,
  },
  update: {
    status: FETCH_STATUSES.IDLE,
    error: null,
  },
}

export const fields = createReducer<TYPES.FieldsState, Actions>(initialState, {
  [TYPES.FieldsActions.TEMPLATES_ADD_FIELD]: (state, action) => ({
    ...state,
    ...addFieldById((action as TYPES.AddFieldAction).payload.field, state.byId),
  }),
  [TYPES.FieldsActions.TEMPLATES_SET_FIELD]: (state, action) => ({
    ...state,
    byId: updateFieldById((action as TYPES.SetFieldAction).payload.field, state.byId),
  }),
  [TYPES.FieldsActions.TEMPLATES_UNSET_FIELD]: (state, action) => ({
    ...state,
    ...removeFieldById((action as TYPES.UnsetFieldAction).payload.id, state.byId),
  }),
  [TYPES.FieldsActions.TEMPLATES_CLEAR_FIELDS]: () => ({
    ...initialState,
  }),

  [ACTIONS.fetchFieldsTypes.request]: (state) => ({
    ...state,
    fetch: {
      ...state.fetch,
      status: FETCH_STATUSES.REQUEST,
      error: null,
    },
  }),
  [ACTIONS.fetchFieldsTypes.success]: (state, action) => ({
    ...state,
    ...(action as TYPES.FetchFieldsSuccessAction).payload,
    fetch: {
      ...state.fetch,
      status: FETCH_STATUSES.SUCCESS,
      error: null,
    },
  }),
  [ACTIONS.fetchFieldsTypes.failure]: (state, action) => ({
    ...state,
    fetch: {
      ...state.fetch,
      status: FETCH_STATUSES.FAILURE,
      error: (action as FetchFailureAction).payload,
    },
  }),

  [ACTIONS.fetchUpdateFieldsTypes.request]: (state) => ({
    ...state,
    update: {
      ...state.update,
      status: FETCH_STATUSES.REQUEST,
      error: null,
    },
  }),
  [ACTIONS.fetchUpdateFieldsTypes.success]: (state) => ({
    ...state,
    update: {
      ...state.update,
      status: FETCH_STATUSES.SUCCESS,
      error: null,
    },
  }),
  [ACTIONS.fetchUpdateFieldsTypes.failure]: (state, action) => ({
    ...state,
    update: {
      ...state.update,
      status: FETCH_STATUSES.FAILURE,
      error: (action as FetchFailureAction).payload,
    },
  }),

  [TYPES.FieldsActions.TEMPLATES_SET_FONT_SIZE]: (state, action) => {
    const fontSize = (action as TYPES.SetFontSizeAction).payload.fontSize

    return {
      ...state,
      fontSize,
      byId: updateFieldsFontSize(fontSize, state.byId),
    }
  },

  [TYPES.FieldsActions.TEMPLATES_SET_FIELD_TEXT]: (state, action) => {
    const { id, text } = (action as TYPES.SetFieldTextAction).payload

    return {
      ...state,
      byId: updateFieldText(text, id, state.byId),
    }
  },

  [TYPES.FieldsActions.TEMPLATES_CLEAR_FIELDS_TEXT]: (state) => ({
    ...state,
    byId: clearFieldsText(state.byId),
  }),

  [ACTIONS.scrollToEmptyFieldTypes.request]: (state) => ({
    ...state,
    scrollToField: undefined,
  }),
  [ACTIONS.scrollToEmptyFieldTypes.success]: (state) => ({
    ...state,
    scrollToField: getFirstEmptyField(state.byId, state.byPages),
  }),
})
