import React, { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { useSigningNotifications } from '../../../hooks/signing/useSigningNotifications'
import { useUnregisteredSigningFetch } from '../../../hooks/signing/useUnregisteredSigningFetch'
import { useDispatchUnmount } from '../../../hooks/useDispatchUnmount'
import * as PLACEMENT_SELECTORS from '../../../store/contracts/placement/placement.selectors'
import { clearSigningSuccessMessage } from '../../../store/contracts/signing/signing.actions'
import * as SIGNING_SELECTORS from '../../../store/contracts/signing/signing.selectors'
import { cleanViewOptions } from '../../../store/contracts/viewOptions/viewOptions.actions'
import { viewOptionsIsSignatureNamesVisibleSelector } from '../../../store/contracts/viewOptions/viewOptions.selectors'
import translations from '../../../translations/keys'
import { removeExtension } from '../../../utils/file/removeExtension'
import LogSidebar from '../../components/LogSidebar'
import FullScreenTemplate from '../../components/Main/FullScreenTemplate'
import { PreviewHeader } from '../../components/PreviewHeader/PreviewHeader'
import { useDownloadContract } from '../../hooks/contracts/useDownloadContract'
import { useActions } from '../../hooks/contractSign/useActions'
import { useToolbarActions } from '../../hooks/contractSign/useToolbarActions'
import { useVerification } from '../../hooks/contractSign/useVerification'
import { ContractRouteParams } from '../../types/signing'
import Box from '../../ui/Box'
import DocViewer from '../../ui/DocViewer'
import NotificationBadge from '../../ui/NotificationBadge'

import { useMobileActions } from './hooks/useMobileActions'
import Modals from './components/Modals'
import { UpdatedSuccessfully } from './components/UpdatedSuccessfully'
import * as Styled from './ContractUnregisteredSign.styles'
import { useButtons } from './hooks/useButtons'
import { useErrors } from './hooks/useErrors'
import { usePlacementById } from './hooks/usePlacementById'
import { useRecipientToken } from './hooks/useRecipientToken'

export const ContractUnregisteredSign = () => {
  const dispatch = useDispatch()
  const { contractId } = useParams<ContractRouteParams>()
  const [updatedSuccessfully, setUpdatedSuccessfully] = useState(false)
  const data = useSelector(SIGNING_SELECTORS.signingContractDocumentDataSelector)
  const placementByPages = useSelector(PLACEMENT_SELECTORS.placementByPagesSelector)
  const scrollToPlacementId = useSelector(SIGNING_SELECTORS.signingScrollToSignPlacementId)
  const notification = useSelector(SIGNING_SELECTORS.signingNotificationSelector)
  const recipientToken = useSelector(SIGNING_SELECTORS.signingRecipientTokenSelector)
  const isSignatureNamesVisible = useSelector(viewOptionsIsSignatureNamesVisibleSelector)
  const title = removeExtension(data?.contractName || '')
  const placementById = usePlacementById()
  const actions = useActions()
  const mobileActions = useMobileActions()
  const buttons = useButtons()
  const toolbarActions = useToolbarActions()

  const handleNotificationBadgeClose = () => {
    dispatch(clearSigningSuccessMessage())
  }
  const onUpdatedSuccessfully = useCallback(() => {
    setUpdatedSuccessfully(true)
  }, [])

  useRecipientToken()
  useUnregisteredSigningFetch(contractId)
  useSigningNotifications()
  useDownloadContract(contractId)
  useVerification(contractId)
  useErrors()
  useDispatchUnmount(cleanViewOptions)

  if (updatedSuccessfully) {
    return <UpdatedSuccessfully />
  }

  return (
    <FullScreenTemplate
      headerContent={<PreviewHeader actions={actions} buttons={buttons} title={title} />}
      sidebarContent={<LogSidebar />}
    >
      <Styled.Container>
        {data && (
          <DocViewer
            readonly
            pages={data.pages}
            placementById={placementById}
            placementByPages={placementByPages}
            scrollToElemId={scrollToPlacementId}
            actions={toolbarActions}
            recipientToken={recipientToken}
            isSignatureNamesVisible={isSignatureNamesVisible}
            mobileActions={mobileActions}
          />
        )}
        {notification && (
          <Styled.MessageContainer>
            <Box ml={4} mt={4}>
              <NotificationBadge
                isShow
                isAutoClose
                message={translations[notification.message]}
                onClose={handleNotificationBadgeClose}
                variant={notification.variant}
              />
            </Box>
          </Styled.MessageContainer>
        )}
      </Styled.Container>
      <Modals onUpdatedSuccessfully={onUpdatedSuccessfully} />
    </FullScreenTemplate>
  )
}
