import React from 'react'
import { useTheme } from 'styled-components'

import translations from '../../../../../translations/keys'
import { ContractSvg } from '../../../../assets/ContractSvg'
import Box from '../../../../ui/Box'
import { StyledThemeScheme } from '../../../../../themes/light'
import useMediaQuery from '../../../../hooks/useMediaQuery'
import Modal from '../../../Modal'
import Typography from '../../../../ui/Typography'
import { TYPOGRAPHY_NAMES } from '../../../../ui/Typography/Typography.types'
import { UploadFile } from '../../../../ui/UploadFile/UploadFile'

import { UploadIntoClmOnlyModalProps } from './UploadIntoClmOnlyModal.types'
import { StyledWrapper } from './UploadIntoClmOnlyModal.styles'

export const UploadIntoClmOnlyModal: React.FC<UploadIntoClmOnlyModalProps> = ({
  loading = false,
  onUpload,
  onClose,
}) => {
  const theme = useTheme() as StyledThemeScheme
  const mobileScreen = useMediaQuery(theme.devices.mobile)

  const validationInfo = mobileScreen ? (
    <p>
      {translations.UPLOAD_CONTACT_FORMATS} {translations.UPLOAD_CONTACT_SIZE}
    </p>
  ) : (
    <div>
      <p>{translations.UPLOAD_CONTACT_FORMATS}</p>
      <p>{translations.UPLOAD_CONTACT_SIZE}</p>
    </div>
  )

  const handleClose = () => {
    if (!loading) {
      onClose()
    }
  }

  const handleSuccess = (file: File) => {
    onUpload(file)
  }

  return (
    <Modal onClose={handleClose}>
      <StyledWrapper>
        <ContractSvg />
        <Box mt={5} mb={2}>
          <Typography name={TYPOGRAPHY_NAMES.H2}>{translations.TEMPLATES_UPLOAD_DOCUMENT_FOR_CLM_ONLY}</Typography>
        </Box>
        <Box mb={5}>
          <Typography name={TYPOGRAPHY_NAMES.body16}>
            {translations.TEMPLATES_CLM_ONLY_UPLOAD_DOCUMENT_DISCLAIMER}
          </Typography>
        </Box>
        <UploadFile validationInfo={validationInfo} loading={loading} onSuccess={handleSuccess} />
      </StyledWrapper>
    </Modal>
  )
}
