import styled from 'styled-components'

import { TextAreaProps } from './TextArea.types'

export const StyledWrapper = styled.div`
  display: inline-block;
  position: relative;
  padding-bottom: 24px;
  width: 100%;
`

export const StyledLabel = styled.span<{ disabled?: boolean }>`
  display: block;
  margin-bottom: 4px;
  ${(props) => props.disabled && `color: ${props.theme.palette.grey}`}
`

export const StyledTextArea = styled.textarea<TextAreaProps & { isError: boolean }>`
  background: ${(props) => props.theme.palette.white};
  box-sizing: border-box;
  padding: 8px 14px 9px;
  color: ${(props) => props.theme.palette.textColor};
  font-family: ${(props) => props.theme.textStyles.body14['font-family']};
  line-height: ${(props) => props.theme.textStyles.body14['line-height']};
  font-size: ${(props) => props.theme.textStyles.body14['font-size']};
  width: 100%;
  height: ${(props) => `${props.height}px`};
  resize: none;
  border: 1px solid ${(props) => props.theme.palette.grey};
  border-radius: 4px;

  &:active,
  &:focus {
    border-color: ${(props) => props.theme.palette.blueDark};
    outline: none;
  }
  &::placeholder {
    color: ${(props) => props.theme.palette.grey};
    font-weight: normal;
  }
  &:-webkit-autofill {
    box-shadow: ${({ theme }) => `0 0 0 1000px ${theme.palette.white} inset`};
  }

  &:disabled {
    color: ${(props) => props.theme.palette.grey};
  }

  ${({ isError, theme }) =>
    isError &&
    `
    border-color: ${theme.palette.errorColor};

    &:active,
    &:focus {
      border-color: ${theme.palette.errorColor};
    }
  `}
`

export const StyledError = styled.span`
  display: block;
  color: ${(props) => props.theme.palette.errorColor};
  position: absolute;
`
