import { Ajax } from '../ajax'
import { UploadDocumentBody } from '../../types/uploadDocument'
import { DocumentPreviewResponse } from '../../types/documentPreview'
import { prepareUploadDocumentBody } from '../../utils/documents/prepareUploadDocumentBody'
import { ContactsListResponse, ContractsListBriefResponse } from '../../types/contracts'
import { DuplicateDocumentRequestBody } from '../../types/duplicateDocument'
import prepareUrlParams from '../../utils/prepareUrlParams'
import { Params } from '../../utils/prepareUrlParams/prepareUrlParams'
import { convertToFormData } from '../../utils/convertToFormData/convertToFormData'
import { DocumentMessageBody, UpdateDocumentRequestBody } from '../../types/documents'
import { CreateContractFromTemplateBody } from '../../types/createContractFromTemplate'
import { prepareCreateContractFromTemplate } from '../../utils/documents/prepareCreateContractFromTemplate'

class ContractsService extends Ajax {
  pathname = 'contract'

  fetchContract = (contractId: string) => this.getJSON<DocumentPreviewResponse>(`${this.pathname}/${contractId}`)

  fetchUploadContract = (body: UploadDocumentBody) => this.post(this.pathname, prepareUploadDocumentBody(body), {})

  fetchReuploadContract = (id: string, file: File) =>
    this.put(`${this.pathname}/${id}`, convertToFormData({ file }), {})

  fetchContractsList = (params: Params) =>
    this.getJSON<ContactsListResponse>(`${this.pathname}?${prepareUrlParams(params)}`)

  fetchDeleteContract = (id: string) => this.delete(`${this.pathname}/${id}`)

  fetchUpdateContract = (id: string, body: UpdateDocumentRequestBody) => this.patch(`${this.pathname}/${id}`, body)

  fetchDuplicateContract = (id: string, body: DuplicateDocumentRequestBody) =>
    this.post(`${this.pathname}/duplicate/${id}`, body)

  fetchStartSigningContract = (id: string) => this.getJSON(`${this.pathname}/startsigning/${id}`)

  fetchContractStatus = (id: string) => this.getJSON(`${this.pathname}/status/${id}`)

  fetchVoidContract = (id: string) => this.delete(`${this.pathname}/void/${id}`)

  fetchDeleteRecipientContract = (id: string) => this.delete(`${this.pathname}/recipient/${id}`)

  fetchUpdateRecipientContract = (id: string, body: UpdateDocumentRequestBody) =>
    this.patch(`${this.pathname}/recipient/${id}`, body)

  fetchContractsListBrief = () => this.getJSON<ContractsListBriefResponse>(`${this.pathname}/brief`)

  fetchCreateFromTemplate = (body: CreateContractFromTemplateBody) =>
    this.post(`${this.pathname}/createfromtemplate`, prepareCreateContractFromTemplate(body))

  fetchSaveContractMessage = (id: string, body: DocumentMessageBody) =>
    this.post(`${this.pathname}/message/${id}`, body)
}

export const contractsService = new ContractsService()
