import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

import translations from '../../../../translations/keys'
import Typography from '../../../ui/Typography'
import { TYPOGRAPHY_NAMES } from '../../../ui/Typography/Typography.types'
import ErrorBoundary from '../../ErrorBoundary/ErrorBoundary'
import { location } from '../../../../utils/location'

import {
  StyledWrapper,
  AuthorizationGlobalStyle,
  StyledContent,
  StyledFooter,
  StyledLinks,
  StyledLink,
  StyledCopyright,
} from './AuthorizationTemplate.styles'

const LINKS = [
  {
    label: translations.TERMS_OF_USE,
    href: location.termsUrl(),
  },
  {
    label: translations.PRIVACY_POLICY,
    href: location.privacyUrl(),
  },
]

export const AuthorizationTemplate: FC = ({ children }) => {
  const { t } = useTranslation()
  return (
    <StyledWrapper data-testid="authorizationTemplate-wrapper">
      <AuthorizationGlobalStyle />
      <ErrorBoundary global invertedColor={true}>
        <StyledContent>{children}</StyledContent>
      </ErrorBoundary>
      <StyledFooter data-testid="authorizationTemplate-footer-wrapper">
        <StyledLinks>
          {LINKS.map((link) => (
            <StyledLink key={link.label} to={link.href}>
              <Typography name={TYPOGRAPHY_NAMES.body14} mobileName={TYPOGRAPHY_NAMES.bodySmall12}>
                {t(link.label)}
              </Typography>
            </StyledLink>
          ))}
        </StyledLinks>
        <StyledCopyright
          data-testid="authorizationTemplate-translations"
          name={TYPOGRAPHY_NAMES.body14}
          mobileName={TYPOGRAPHY_NAMES.bodySmall12}
        >
          {t(translations.COPYRIGHT)}
        </StyledCopyright>
      </StyledFooter>
    </StyledWrapper>
  )
}
