import { Action } from 'redux'

import { FetchFailurePayload, FETCH_STATUSES } from '../../../types/fetch'
import { EditableElem } from '../../../types/editableElem'
import { DocFieldsResponseBody, DocFieldsById, DocFieldsByPages, DocField } from '../../../types/documentField'

export interface FieldsState {
  byId: DocFieldsById
  byPages: DocFieldsByPages
  fontSize: number
  scrollToField?: string
  fetch: {
    status: FETCH_STATUSES
    error: FetchFailurePayload | null
  }
  update: {
    status: FETCH_STATUSES
    error: FetchFailurePayload | null
  }
}

export enum FieldsActions {
  'TEMPLATES_ADD_FIELD' = 'TEMPLATES_ADD_FIELD',
  'TEMPLATES_SET_FIELD' = 'TEMPLATES_SET_FIELD',
  'TEMPLATES_UNSET_FIELD' = 'TEMPLATES_UNSET_FIELD',
  'TEMPLATES_CLEAR_FIELDS' = 'TEMPLATES_CLEAR_FIELDS',
  'TEMPLATES_FETCH_FIELDS' = 'TEMPLATES_FETCH_FIELDS',
  'TEMPLATES_FETCH_UPDATE_FIELDS' = 'TEMPLATES_FETCH_UPDATE_FIELDS',
  'TEMPLATES_SET_FONT_SIZE' = 'TEMPLATES_SET_FONT_SIZE',
  'TEMPLATES_SET_FIELD_TEXT' = 'TEMPLATES_SET_FIELD_TEXT',
  'TEMPLATES_CLEAR_FIELDS_TEXT' = 'TEMPLATES_CLEAR_FIELDS_TEXT',
  'TEMPLATES_SCROLL_TO_EMPTY_FIELD' = 'TEMPLATES_SCROLL_TO_EMPTY_FIELD',
}

export interface AddFieldAction extends Action {
  payload: { field: DocField }
}
export interface SetFieldAction extends Action {
  payload: { field: EditableElem }
}
export interface UnsetFieldAction extends Action {
  payload: { id: string }
}
export type ClearFieldsAction = Action

export interface FetchFieldsAction extends Action {
  payload: { templateId: string }
}
export interface FetchFieldsSuccessAction extends Action {
  payload: { byId: DocFieldsById; byPages: DocFieldsByPages; fontSize: number }
}

export interface FetchUpdateFieldsAction extends Action {
  payload: { templateId: string; byId: DocFieldsById }
}
export interface FetchUpdateFieldsSuccessAction extends Action {
  payload: DocFieldsResponseBody
}

export interface SetFontSizeAction extends Action {
  payload: { fontSize: number }
}
export interface SetFieldTextAction extends Action {
  payload: { id: string; text: string }
}

export type ClearFieldsTextAction = Action

export type ScrollToEmptyFieldAction = Action
export interface ScrollToEmptyFieldSuccessAction extends Action {
  payload: { id: string }
}
