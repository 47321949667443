import { FetchFailureAction, FetchFailurePayload } from '../../../types/fetch'
import { UploadDocumentBody, UploadDocumentBodyNative, UploadDocumentResponse } from '../../../types/uploadDocument'
import { createFetchActionTypes } from '../../../utils/createFetchActionTypes'

import * as TYPES from './upload.types'

export const fetchUploadTemplateTypes = createFetchActionTypes(TYPES.UploadActions.FETCH_UPLOAD_TEMPLATE)

export const fetchUploadTemplate = (
  body: UploadDocumentBody | UploadDocumentBodyNative
): TYPES.FetchUploadTemplate => ({
  type: fetchUploadTemplateTypes.request,
  payload: { body },
})

export const fetchUploadTemplateSuccess = (response: UploadDocumentResponse): TYPES.FetchUploadTemplateSuccess => ({
  type: fetchUploadTemplateTypes.success,
  payload: { id: response.id },
})

export const fetchUploadTemplateFailure = (payload: FetchFailurePayload): FetchFailureAction => ({
  type: fetchUploadTemplateTypes.failure,
  payload,
})

export const cleanUploadTemplate = () => ({
  type: TYPES.UploadActions.CLEAN_UPLOAD_TEMPLATE,
})
