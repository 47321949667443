import { Action } from 'redux'

import { FetchFailurePayload, FETCH_STATUSES } from '../../../types/fetch'
import { Notification } from '../../../types/notifications'

export interface DeleteState {
  fetchStatus: FETCH_STATUSES
  error: FetchFailurePayload | null
}

export enum DeleteActions {
  'DELETE_TEMPLATE' = 'DELETE_TEMPLATE',
  'CLEAR_DELETE_TEMPLATE' = 'CLEAR_DELETE_TEMPLATE',
}

export interface FetchDeleteTemplate extends Action {
  payload: { id: string; isFolder: boolean }
}

export interface FetchDeleteTemplateSuccess extends Action {
  payload: { notification: Notification }
}
