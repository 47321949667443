import * as yup from 'yup'

import { DocumentMessageFields } from '../../types/documents'

export const TITLE_MAX = 100
export const MESSAGE_MAX = 500

export const documentMessageValidationSchema = yup.object().shape({
  [DocumentMessageFields.title]: yup.string().max(TITLE_MAX, 'DOCUMENT_TITLE_FIELD_MAX'),
  [DocumentMessageFields.message]: yup.string().max(MESSAGE_MAX, 'DOCUMENT_MESSAGE_FIELD_MAX'),
})
