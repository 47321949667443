import React from 'react'
import styled, { css } from 'styled-components'

import { FormWrapperProps } from './FormWrapper.types'

const lgSizeForm = css`
  width: 832px;
  padding: 0 256px;
  ${({ theme }) => `@media ${theme.devices.laptop} {
    width: 552px;
    padding: 0 116px;
  }`}
  ${({ theme }) => `@media ${theme.devices.mobile} {
    width: 100%;
    padding: 0 25px;
  }`}
`
const mdSizeForm = css`
  width: 480px;
  padding: 0 80px;
  ${({ theme }) => `@media ${theme.devices.mobile} {
    width: 100%;
    padding: 0 25px;
}`}
`

const StyledForm = styled.div<{ size: FormWrapperProps['size'] }>`
  ${({ size }) => (size === 'md' ? mdSizeForm : lgSizeForm)}
`

export const FormWrapper: React.FC<FormWrapperProps> = ({ size = 'md', children }) => (
  <StyledForm size={size}>{children}</StyledForm>
)
