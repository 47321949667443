import { Action } from 'redux'

import { FetchFailurePayload, FETCH_STATUSES } from '../../../types/fetch'
import { SigningContractDocumentData, DefaultSign } from '../../../types/signingContract'
import { DeclineSigningBody } from '../../../types/declineSigning'
import { Notification } from '../../../types/notifications'
import { PlacementById } from '../../../types/placement'
import { FileForFormData } from '../../../types/formData'

export enum SigningNotificationMessages {
  'NONE' = '',
  'CONTRACT_READY_TO_SEND' = 'CONTRACT_READY_TO_SEND',
  'SIGNATURE_CHANGED' = 'SIGNATURE_CHANGED',
  'INITIALS_CHANGED' = 'INITIALS_CHANGED',
  'REQUEST_TO_VIEW' = 'REQUEST_TO_VIEW',
  'SIGNING_STOPPED' = 'SIGNING_STOPPED',
  'SIGNING_FINISHED' = 'SIGNING_FINISHED',
  'DELETED_BY_OWNER' = 'DELETED_BY_OWNER',
  'CODE_IS_CORRECT' = 'CODE_IS_CORRECT',
  'EXPIRED_CONTRACT_NOTIFICATION' = 'EXPIRED_CONTRACT_NOTIFICATION',
  'ACCEPTED_SUCCESSFULLY' = 'ACCEPTED_SUCCESSFULLY',
  'REJECTED_SUCCESSFULLY' = 'REJECTED_SUCCESSFULLY',
  'CANCEL_ACCEPTATION_SUCCESSFULLY' = 'CANCEL_ACCEPTATION_SUCCESSFULLY',
  'CANCEL_REJECTION_SUCCESSFULLY' = 'CANCEL_REJECTION_SUCCESSFULLY',
}

export interface SigningState {
  contract: {
    data: SigningContractDocumentData | null
    fetchStatus: FETCH_STATUSES
    error: FetchFailurePayload | null
  }
  sign: {
    fetchStatus: FETCH_STATUSES
    error: FetchFailurePayload | null
  }
  defaultSign: {
    data: DefaultSign
    fetchStatus: FETCH_STATUSES
    error: FetchFailurePayload | null
  }
  scrollToSign: {
    placementId: string
    error: FetchFailurePayload | null
  }
  notification: Notification | null
  decline: {
    fetchStatus: FETCH_STATUSES
    error: FetchFailurePayload | null
  }
  viewed: {
    fetchStatus: FETCH_STATUSES
    error: FetchFailurePayload | null
  }
  totalPages: {
    fetchStatus: FETCH_STATUSES
    error: FetchFailurePayload | null
  }
  recipientToken: string
}

export enum SigningActions {
  'FETCH_SIGNING_CONTRACT_DOCUMENT' = 'FETCH_SIGNING_CONTRACT_DOCUMENT',
  'CLEAR_SIGNING' = 'CLEAR_SIGNING',
  'FETCH_SIGN_CONTRACT' = 'FETCH_SIGN_CONTRACT',
  'FETCH_DEFAULT_SIGN' = 'FETCH_DEFAULT_SIGN',
  'SCROLL_TO_NEAREST_SIGN' = 'SCROLL_TO_NEAREST_SIGN',
  'SET_SIGNING_SUCCESS_MESSAGE' = 'SET_SIGNING_SUCCESS_MESSAGE',
  'CLEAR_SIGNING_SUCCESS_MESSAGE' = 'CLEAR_SIGNING_SUCCESS_MESSAGE',
  'FETCH_DECLINE_SIGNING' = 'FETCH_DECLINE_SIGNING',
  'SET_SIGNATURE' = 'SET_SIGNATURE',
  'FETCH_VIEWED_CONTRACT' = 'FETCH_VIEWED_CONTRACT',
  'FETCH_TOTAL_PAGES' = 'FETCH_TOTAL_PAGES',
  'SET_RECIPIENT_TOKEN' = 'SET_RECIPIENT_TOKEN',
}

export interface FetchSigningContractDocumentAction extends Action {
  payload: { contractId: string }
}
export interface FetchSigningContractDocumentSuccessAction extends Action {
  payload: SigningContractDocumentData
}

export type FetchSignContract = Action

export interface FetchSignContractSuccess extends Action {
  payload: { contractId: string }
}

export type FetchDefaultSign = Action

export interface FetchDefaultSignSuccess extends Action {
  payload: DefaultSign
}

export interface ScrollToNearestSignSuccess extends Action {
  payload: string
}

export interface SetSigningSuccessMessageAction extends Action {
  payload: Notification
}

export interface FetchDeclineSigningAction extends Action {
  payload: { recipientId: string; body: DeclineSigningBody }
}

export type FetchDeclineSigningSuccessAction = Action

export interface SetSignaturePayload {
  image?: string
  placeId: string
  signatureId?: string
  timestamp?: string
  isChanged?: boolean
}

export interface SetSignaturePayloadSuccess extends SetSignaturePayload {
  placementById: PlacementById
}

export interface SetSignatureAction extends Action {
  payload: SetSignaturePayload
}

export interface SetSignatureSuccessAction extends Action {
  payload: SetSignaturePayloadSuccess
}

export type FetchViewedContract = Action

export type FetchViewedContractSuccess = Action

export type FetchTotalPages = Action

export interface FetchTotalPagesSuccess extends Action {
  payload: SigningContractDocumentData
}

export interface File {
  [key: string]: FileForFormData
}

export interface Headers {
  [key: string]: string
}

export interface SetRecipientToken extends Action {
  payload: { recipientToken: string }
}

export interface FetchSignRequestArgs {
  signingData: SigningContractDocumentData
  recipientToken: string
  defaultSign: DefaultSign
  placementById: PlacementById
}

export interface FetchViewRequestArgs {
  signingData: SigningContractDocumentData
  recipientToken: string
}
