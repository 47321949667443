import React from 'react'

import { SvgIconProps } from '../../ui/SvgIcon/SvgIcon.types'
import SvgIcon from '../../ui/SvgIcon'

export const EyeClosedIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <path d="M22.0822 11.396C21.2583 9.66021 20.2778 8.24458 19.1408 7.14911L17.9483 8.34161C18.9207 9.27091 19.7678 10.4866 20.5002 12.0007C18.5502 16.0366 15.7822 17.9538 11.9994 17.9538C10.8639 17.9538 9.81808 17.779 8.86182 17.4293L7.56972 18.7214C8.89784 19.3347 10.3744 19.6413 11.9994 19.6413C16.5041 19.6413 19.865 17.2952 22.0822 12.603C22.1714 12.4143 22.2176 12.2082 22.2176 11.9995C22.2176 11.7908 22.1714 11.5847 22.0822 11.396ZM20.5923 3.88099L19.5932 2.88067C19.5757 2.86324 19.5551 2.84941 19.5323 2.83998C19.5095 2.83054 19.4851 2.82568 19.4605 2.82568C19.4359 2.82568 19.4115 2.83054 19.3887 2.83998C19.3659 2.84941 19.3453 2.86324 19.3278 2.88067L16.7645 5.44286C15.3512 4.72099 13.7628 4.36005 11.9994 4.36005C7.49472 4.36005 4.13378 6.70614 1.91659 11.3983C1.82747 11.587 1.78125 11.7931 1.78125 12.0018C1.78125 12.2105 1.82747 12.4167 1.91659 12.6054C2.80237 14.471 3.86878 15.9664 5.11581 17.0915L2.63565 19.571C2.60052 19.6061 2.58078 19.6538 2.58078 19.7035C2.58078 19.7532 2.60052 19.8009 2.63565 19.8361L3.6362 20.8366C3.67136 20.8717 3.71903 20.8915 3.76874 20.8915C3.81845 20.8915 3.86612 20.8717 3.90128 20.8366L20.5923 4.1463C20.6097 4.12889 20.6236 4.10821 20.633 4.08544C20.6424 4.06268 20.6473 4.03828 20.6473 4.01364C20.6473 3.989 20.6424 3.9646 20.633 3.94184C20.6236 3.91908 20.6097 3.8984 20.5923 3.88099ZM3.49862 12.0007C5.45097 7.96474 8.21893 6.04755 11.9994 6.04755C13.2777 6.04755 14.4399 6.26693 15.4944 6.71294L13.8467 8.3606C13.0664 7.94426 12.173 7.78975 11.2982 7.91984C10.4234 8.04994 9.61352 8.45777 8.98813 9.08316C8.36275 9.70854 7.95492 10.5184 7.82482 11.3932C7.69472 12.268 7.84924 13.1615 8.26557 13.9418L6.31042 15.8969C5.22831 14.9418 4.2955 13.6479 3.49862 12.0007ZM9.28065 12.0007C9.28106 11.5874 9.37896 11.18 9.56638 10.8117C9.75381 10.4433 10.0255 10.1244 10.3593 9.88077C10.6932 9.63715 11.0798 9.47571 11.4877 9.40957C11.8957 9.34343 12.3135 9.37446 12.7072 9.50013L9.40511 12.8022C9.32234 12.5431 9.28035 12.2727 9.28065 12.0007Z" />
    <path d="M11.9079 14.6252C11.8268 14.6252 11.7469 14.6214 11.6677 14.6142L10.4297 15.8521C11.1743 16.1373 11.9855 16.2006 12.7653 16.0346C13.5452 15.8685 14.2602 15.4801 14.824 14.9163C15.3878 14.3525 15.7762 13.6375 15.9423 12.8576C16.1083 12.0778 16.045 11.2666 15.7598 10.522L14.5219 11.7599C14.5291 11.8392 14.5329 11.9191 14.5329 12.0002C14.5331 12.3449 14.4653 12.6864 14.3334 13.0049C14.2016 13.3235 14.0082 13.6129 13.7644 13.8567C13.5207 14.1005 13.2312 14.2939 12.9126 14.4257C12.5941 14.5576 12.2527 14.6253 11.9079 14.6252Z" />
  </SvgIcon>
)
