import React from 'react'
import ContentLoader from 'react-content-loader'

import { blueExtraLight, glowColor } from '../../../themes/colors'

const FILL_COLOR = blueExtraLight
const GLOW_COLOR = glowColor
const SPEED = 1.5
const SVG_WIDTH = '100%'
const SVG_HEIGHT = 600
const RADIUS = 3
const TEXT_HEIGHT = 21
const OFFSET = 2
const ELEMENTS_WIDTHS = [50, 40, 55]
const TEXT_WITH_OFFSET = TEXT_HEIGHT + OFFSET
const ELEMENT_HEIGHT = 131 + OFFSET * 2

export const ContractHistorySkeleton: React.FC = () => (
  <ContentLoader
    display="block"
    width={SVG_WIDTH}
    height={SVG_HEIGHT}
    speed={SPEED}
    backgroundColor={FILL_COLOR}
    foregroundColor={GLOW_COLOR}
  >
    {ELEMENTS_WIDTHS.map((width, index) => (
      <React.Fragment key={index}>
        <rect width={`${width + 15}%`} height={TEXT_HEIGHT} x="0" y={index * ELEMENT_HEIGHT} rx={RADIUS} ry={RADIUS} />
        <rect
          width={`${width}%`}
          height={TEXT_HEIGHT}
          x="0"
          y={index * ELEMENT_HEIGHT + TEXT_WITH_OFFSET}
          rx={RADIUS}
          ry={RADIUS}
        />
        <rect
          width={`${width + 5}%`}
          height={TEXT_HEIGHT}
          x="0"
          y={index * ELEMENT_HEIGHT + 2 * TEXT_WITH_OFFSET}
          rx={RADIUS}
          ry={RADIUS}
        />
        <rect
          width={`${width + 6}%`}
          height={TEXT_HEIGHT}
          x="0"
          y={index * ELEMENT_HEIGHT + 3 * TEXT_WITH_OFFSET}
          rx={RADIUS}
          ry={RADIUS}
        />
        <rect
          width={`${width + 20}%`}
          height={TEXT_HEIGHT}
          x="0"
          y={index * ELEMENT_HEIGHT + 4 * TEXT_WITH_OFFSET}
          rx={RADIUS}
          ry={RADIUS}
        />
      </React.Fragment>
    ))}
  </ContentLoader>
)
