import React from 'react'

import { TYPOGRAPHY_NAMES } from '../Typography/Typography.types'

import { RadioButtonProps } from './RadioButton.types'
import {
  StyledWrapper,
  HiddenRadioButton,
  StyledRadioButton,
  StyledLabel,
  StyledChecked,
  StyledCheckedWrapper,
} from './RadioButton.styles'

export const RadioButton: React.FC<RadioButtonProps> = ({
  label,
  checked = false,
  disabled,
  id,
  typographyName = TYPOGRAPHY_NAMES.body14,
  dotPosition = 'center',
  renderLabel,
  ...props
}) => (
  <StyledWrapper test-id="radio-wrapper" disabled={disabled} dotPosition={dotPosition}>
    <HiddenRadioButton test-id="radio-hidden" checked={checked} disabled={disabled} {...props} value={id} />
    <StyledRadioButton checked={checked}>
      <StyledCheckedWrapper>
        <StyledChecked />
      </StyledCheckedWrapper>
    </StyledRadioButton>
    {renderLabel ? (
      renderLabel()
    ) : (
      <StyledLabel test-id="radio-label" name={typographyName}>
        {label}
      </StyledLabel>
    )}
  </StyledWrapper>
)
