import { Action } from 'redux'

import { FetchFailurePayload, FETCH_STATUSES } from '../../../types/fetch'
import { Option } from '../../../types/options'

export interface BriefListState {
  fetchStatus: FETCH_STATUSES
  error: FetchFailurePayload | null
  data: Option[]
}

export enum BriefListActions {
  'FETCH_BRIEF_CONTRACTS' = 'FETCH_BRIEF_CONTRACTS',
  'CLEAN_BRIEF_CONTRACTS' = 'CLEAN_BRIEF_CONTRACTS',
}

export type FetchBriefList = Action

export interface FetchBriefListSuccess extends Action {
  payload: { data: Option[] }
}
