import { Action } from 'redux'

import { Cards, Card } from '../../../types/cards'
import { FetchFailurePayload, FETCH_STATUSES } from '../../../types/fetch'

export interface CardsState {
  fetchStatus: FETCH_STATUSES
  error: FetchFailurePayload | null
  list: Cards | null
  lastAddedCard: Card | null
  setDefault: {
    fetchStatus: FETCH_STATUSES
    error: FetchFailurePayload | null
  }
}

export enum CardsActions {
  'FETCH_SUBSCRIPTION_CARDS' = 'FETCH_SUBSCRIPTION_CARDS',
  'CLEAN_SUBSCRIPTION_CARDS' = 'CLEAN_SUBSCRIPTION_CARDS',
  'ADD_SUBSCRIPTION_CARD' = 'ADD_SUBSCRIPTION_CARD',
  'FETCH_SET_DEFAULT_CARD' = 'FETCH_SET_DEFAULT_CARD',
}

export type FetchCardsAction = Action

export interface FetchCardsSuccessAction extends Action {
  payload: { cards: Cards }
}

export interface AddCard extends Action {
  payload: { card: Card }
}

export interface FetchSetDefaultCardAction extends Action {
  payload: { cardId: string }
}

export type FetchSetDefaultCardSuccessAction = Action
