import React from 'react'
import { Form, Formik } from 'formik'

import { TrashIcon, EditIcon } from '../../assets/icons'
import { RoundCheckIcon } from '../../assets/icons/RoundCheckIcon'
import { RoundCrossIcon } from '../../assets/icons/RoundCrossIcon'
import ClickOutsideListener from '../ClickOutsideListener'
import highlightText from '../../../utils/highlightText'
import FormTextInput from '../Forms/FormTextInput'
import FormPhone from '../Forms/FormPhone'
import { isString } from '../../../utils/isString'
import TableActions from '../TableActions'

import * as Styled from './Table.styles'
import { TableRowProps, TABLE_COLUMN_TYPES } from './Table.types'

export const TableRow: React.FC<TableRowProps> = ({
  data,
  columns,
  validationSchema,
  editMode,
  withControls,
  highlightedText,
  customActions,
  onEdit,
  onSubmit,
  onDelete,
}) => {
  const handleEdit = (id: string | null) => () => {
    onEdit && onEdit(id)
  }

  const handleDelete = () => {
    onDelete && onDelete(data.id)
  }

  if (editMode) {
    return (
      <ClickOutsideListener onClickAway={handleEdit(null)} mouseEvent="mousedown">
        <Formik validationSchema={validationSchema} initialValues={data} onSubmit={onSubmit}>
          {({ dirty, isValid }) => (
            <Form data-testid="table-form">
              <Styled.TableRow data-testid="table-row">
                {columns.map((column) => (
                  <Styled.TableFormCell
                    data-testid="table-cell"
                    key={column.field}
                    count={columns.length}
                    widthPercent={column.widthPercent}
                  >
                    <Styled.FormField>
                      {column.type === TABLE_COLUMN_TYPES.PHONE ? (
                        <FormPhone data-testid="table-phone-input" name={column.field} maxLength={column.maxLength} />
                      ) : (
                        <FormTextInput
                          data-testid="table-text-input"
                          name={column.field}
                          maxLength={column.maxLength}
                          trim={column.type === TABLE_COLUMN_TYPES.EMAIL}
                        />
                      )}
                    </Styled.FormField>
                  </Styled.TableFormCell>
                ))}
                <Styled.TableControlCell data-testid="table-control-cell">
                  <Styled.ControlButton
                    data-testid="table-control-btn-apply"
                    type="submit"
                    disabled={!dirty || !isValid}
                  >
                    <RoundCheckIcon />
                  </Styled.ControlButton>
                  <Styled.ControlButton data-testid="table-control-btn-cancel" type="button" onClick={handleEdit(null)}>
                    <RoundCrossIcon />
                  </Styled.ControlButton>
                </Styled.TableControlCell>
              </Styled.TableRow>
            </Form>
          )}
        </Formik>
      </ClickOutsideListener>
    )
  }

  return (
    <Styled.TableRow data-testid="table-row" key={data.id}>
      {columns.map((column) => {
        const cell = () => {
          if (column.render) {
            return column.render(data, highlightedText)
          }

          return (
            <Styled.TableCellEllipsis ellipsis>
              {column.searchable && isString(data[column.field])
                ? highlightText(data[column.field] as string, highlightedText, Styled.HighlightedText, 'span')
                : data[column.field]}
            </Styled.TableCellEllipsis>
          )
        }
        return (
          <Styled.TableCell
            data-testid="table-cell"
            key={column.field}
            count={columns.length}
            widthPercent={column.widthPercent}
            widthPercentLaptop={column.widthPercentLaptop}
          >
            {cell()}
          </Styled.TableCell>
        )
      })}
      {withControls && (
        <Styled.TableControlCell data-testid="table-control-cell">
          {onEdit && (
            <Styled.ControlButton data-testid="table-control-btn-edit" type="button" onClick={handleEdit(data.id)}>
              <EditIcon />
            </Styled.ControlButton>
          )}
          {onDelete && (
            <Styled.ControlButton data-testid="table-control-btn-remove" type="button" onClick={handleDelete}>
              <TrashIcon />
            </Styled.ControlButton>
          )}
        </Styled.TableControlCell>
      )}
      {customActions && <TableActions data={data} actions={customActions} />}
    </Styled.TableRow>
  )
}
