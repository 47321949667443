import { useMemo } from 'react'
import { useHistory, useParams } from 'react-router'

import translations from '../../../../translations/keys'
import { TemplateRouteParams } from '../../../types/template'
import { location } from '../../../../utils/location'
import { BUTTON_VARIANTS } from '../../../ui/Button/Button.types'
import { SaveIcon } from '../../../assets/icons'

export const useButtons = () => {
  const history = useHistory()
  const { templateId } = useParams<TemplateRouteParams>()

  const buttons = useMemo(
    () => [
      {
        label: translations.EDIT,
        onClick: () => {
          history.push(location.templatesAddRecipientsUrl(templateId))
        },
        variant: BUTTON_VARIANTS.SECONDARY,
        MobileIcon: SaveIcon,
      },
      {
        label: translations.CONTRACT_START,
        onClick: () => {
          history.push(location.templatesFillRecipientsUrl(templateId))
        },
        variant: BUTTON_VARIANTS.PRIMARY,
        MobileIcon: SaveIcon,
      },
    ],
    [templateId]
  )

  return buttons
}
