import React from 'react'

import { SvgIconProps } from '../../ui/SvgIcon/SvgIcon.types'
import SvgIcon from '../../ui/SvgIcon'

export const DoubleArrowIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <path d="M10.8409 14.4232L7.38409 17.8426C7.17196 18.0525 6.82804 18.0525 6.61591 17.8426L3.1591 14.4232C2.94697 14.2134 2.94697 13.8732 3.1591 13.6633C3.37122 13.4535 3.71515 13.4535 3.92728 13.6633L6.45681 16.1655L6.45681 6.00005L7.54319 6.00005L7.54319 16.1655L10.0727 13.6633C10.2849 13.4535 10.6288 13.4535 10.8409 13.6633C11.053 13.8732 11.053 14.2134 10.8409 14.4232Z" />
    <path d="M13.1591 9.5768L16.6159 6.15737C16.828 5.94754 17.172 5.94754 17.3841 6.15737L20.8409 9.5768C21.053 9.78664 21.053 10.1268 20.8409 10.3367C20.6288 10.5465 20.2849 10.5465 20.0727 10.3367L17.5432 7.8345V18H16.4568V7.8345L13.9273 10.3367C13.7151 10.5465 13.3712 10.5465 13.1591 10.3367C12.947 10.1268 12.947 9.78664 13.1591 9.5768Z" />
  </SvgIcon>
)
