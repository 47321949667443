import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import * as VERIFICATION_SELECTORS from '../../../store/contracts/verification/verification.selectors'
import * as SIGNING_SELECTORS from '../../../store/contracts/signing/signing.selectors'
import { openModal } from '../../../store/modals/modals.actions'
import { CONTRACT_SIGN_MODALS } from '../../constants/contractSign'
import { RecipientAuthentication } from '../../../types/recipients'
import { fetchNoneVerification } from '../../../store/contracts/noneVerification/noneVerification.actions'
import { fetchSigningContractDocument } from '../../../store/contracts/signing/signing.actions'

export const useVerification = (contractId: string) => {
  const dispatch = useDispatch()
  const isVerified = useSelector(VERIFICATION_SELECTORS.contractsVerificationVerified)
  const verificationFetched = useSelector(VERIFICATION_SELECTORS.contractsVerificationFetched)
  const recipientAuthentication = useSelector(SIGNING_SELECTORS.signingRecipientAuthentication)

  const handleVerify = useCallback(() => {
    if (!verificationFetched || isVerified) {
      return null
    }

    if (
      recipientAuthentication === RecipientAuthentication.EKYC ||
      recipientAuthentication === RecipientAuthentication.EKYC_ID
    ) {
      return dispatch(openModal(CONTRACT_SIGN_MODALS.SIGNING_EKYC_VERIFICATION))
    }

    if (recipientAuthentication === RecipientAuthentication.OTP) {
      return dispatch(openModal(CONTRACT_SIGN_MODALS.SIGNING_OTP_VERIFICATION, { contractId }))
    }

    if (recipientAuthentication === RecipientAuthentication.NONE) {
      return dispatch(
        fetchNoneVerification(contractId, () => {
          dispatch(fetchSigningContractDocument(contractId))
        })
      )
    }
  }, [verificationFetched, isVerified, contractId, recipientAuthentication])

  useEffect(() => {
    handleVerify()
  }, [handleVerify])
}
