import { combineEpics, Epic, ofType } from 'redux-observable'
import { of } from 'rxjs'
import { catchError, mergeMap, takeUntil } from 'rxjs/operators'

import { templateService } from '../../../api'
import { catchFetchError } from '../../../utils/catchFetchError'
import { prepareFolderId, prepareTemplatesOffset } from '../list/list.utils'
import * as LIST_ACTIONS from '../list/list.actions'

import * as MOVE_TO_FOLDER_ACTIONS from './moveToFolder.actions'

const fetchMoveToFolder: Epic = (action$, state$) =>
  action$.pipe(
    ofType(MOVE_TO_FOLDER_ACTIONS.fetchMoveTemplateToFolderTypes.request),
    mergeMap(({ payload }) =>
      templateService
        .fetchUpdateTemplate(payload.id, {
          folderId: prepareFolderId(payload.folderId),
        })
        .pipe(
          mergeMap(() =>
            of(
              LIST_ACTIONS.templatesListSetOffset(prepareTemplatesOffset(state$.value.contracts.contractsList)),
              LIST_ACTIONS.templatesListForceUpdate(),
              MOVE_TO_FOLDER_ACTIONS.fetchMoveTemplateToFolderSuccess()
            )
          ),
          catchError(catchFetchError(MOVE_TO_FOLDER_ACTIONS.fetchMoveTemplateToFolderFailure)),
          takeUntil(
            action$.pipe(
              ofType(
                MOVE_TO_FOLDER_ACTIONS.fetchMoveTemplateToFolderTypes.success,
                MOVE_TO_FOLDER_ACTIONS.fetchMoveTemplateToFolderTypes.failure
              )
            )
          )
        )
    )
  )

export const moveToFolderEpics = combineEpics(fetchMoveToFolder)
