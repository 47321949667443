import React, { FC } from 'react'
import { useSelector } from 'react-redux'

import { modalsPayloadSelector } from '../../../../../store/modals/modals.selectors'
import translations from '../../../../../translations/keys'
import { RECIPIENTS_DIAGRAM_MODALS } from '../../../../constants/recipientsDiagram'
import { NOTIFICATION_MODAL_TYPES } from '../../../../ui/Modals/NotificationModal/NotificationModal.constants'
import { NotificationModalView } from '../../../../ui/Modals/NotificationModal/NotificationModalView'

import { DeleteRecipientModalProps } from './DeleteRecipientModal.types'

export const DeleteRecipientModal: FC<DeleteRecipientModalProps> = ({ onDelete, onClose }) => {
  const { id, description = translations.DELETE_RECIPIENT_DESCRIPTION } = useSelector(
    modalsPayloadSelector(RECIPIENTS_DIAGRAM_MODALS.DELETE_DIAGRAM_RECIPIENT)
  )

  const handleSuccess = () => {
    if (id) {
      onDelete(id)
    }
    onClose()
  }

  return (
    <NotificationModalView
      type={NOTIFICATION_MODAL_TYPES.WARNING}
      title={translations.DELETE_RECIPIENT}
      description={description}
      successButtonText={translations.DELETE}
      onSuccess={handleSuccess}
      onClose={onClose}
    />
  )
}
