import { ajax, AjaxRequest } from 'rxjs/ajax'

import CircuitBreaker from './CircuitBreaker'
import { getEnvs } from './getEnvs'

const { REACT_APP_API = '', REACT_APP_API_PREFIX = 'api' } = getEnvs()

export class Ajax {
  circuitBreaker = new CircuitBreaker()
  serverUrl = `${REACT_APP_API}/${REACT_APP_API_PREFIX}`
  defaultHeaders = {
    'Content-Type': 'application/json',
  }

  static mobileHeaders = {}

  getJSON = <P>(url: string, headers?: { [key: string]: string }) =>
    this.circuitBreaker.wrapRequest<P>((path) => ajax.getJSON(path, { ...headers, ...Ajax.mobileHeaders }), {
      path: `${this.serverUrl}/${url}`,
      method: 'GET',
    })

  post = (url: string, body?: any, headers: { [key: string]: string } = this.defaultHeaders) =>
    this.circuitBreaker.wrapRequest((path) => ajax.post(path, body, { ...headers, ...Ajax.mobileHeaders }), {
      path: `${this.serverUrl}/${url}`,
      method: 'POST',
    })

  put = (url: string, body?: any, headers: { [key: string]: string } = this.defaultHeaders) =>
    this.circuitBreaker.wrapRequest((path) => ajax.put(path, body, { ...headers, ...Ajax.mobileHeaders }), {
      path: `${this.serverUrl}/${url}`,
      method: 'PUT',
    })

  patch = (url: string, body?: any) =>
    this.circuitBreaker.wrapRequest(
      (path) => ajax.patch(path, body, { ...this.defaultHeaders, ...Ajax.mobileHeaders }),
      {
        path: `${this.serverUrl}/${url}`,
        method: 'PATCH',
      }
    )

  delete = (url: string) =>
    this.circuitBreaker.wrapRequest((path) => ajax.delete(path, Ajax.mobileHeaders), {
      path: `${this.serverUrl}/${url}`,
      method: 'DELETE',
    })

  request = (request: AjaxRequest) =>
    ajax({
      ...request,
      url: `${this.serverUrl}/${request.url}`,
    })
}
