import { Form } from 'formik'
import styled from 'styled-components'

import Box from '../../../../ui/Box'

export const StyledWrapper = styled(Box).attrs({
  mt: 3,
  mb: 3,
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
})`
  width: 320px;

  ${({ theme }) => `@media ${theme.devices.mobile} {
    width: 100%;
  }`}
`

export const StyledForm = styled(Form)`
  width: 100%;
`
