import React, { useRef, useState } from 'react'

import translations from '../../../../../../../../translations/keys'
import { SignatureScreen } from '../SignatureScreen/SignatureScreen'
import { MOBILE_CREATE_SIGNATURE_STEPS } from '../../ModalMobile.constants'
import { Preview } from '../Preview/Preview'
import { useMaxSize } from '../../hooks/useMaxSize'
import { SignatureMode } from '../../../../CreateSignatureModal.types'

import { StyledContent } from './Body.styles'
import { BodyProps } from './Body.types'

export const Body: React.FC<BodyProps> = ({ onCreateSignature, onChangeStep, step, mode }) => {
  const contentRef = useRef<HTMLDivElement>(null)
  const [signature, setSignature] = useState('')
  const [trimmedSignature, setTrimmedSignature] = useState('')
  const [initials, setInitials] = useState('')
  const [trimmedInitials, setTrimmedInitials] = useState('')
  const { width, height } = useMaxSize(contentRef)
  const onSubmit = () => {
    onCreateSignature({ initials: trimmedInitials, signature: trimmedSignature })
  }

  const renderContent = () => (
    <>
      {step === MOBILE_CREATE_SIGNATURE_STEPS.SIGNATURE && (
        <SignatureScreen
          placeholder={translations.DRAW_SIGNATURE_HERE}
          buttonText={mode === SignatureMode.signature ? translations.SUBMIT : translations.NEXT}
          setImg={setSignature}
          setTrimmedImg={setTrimmedSignature}
          imgUrl={signature}
          onButtonClick={onChangeStep(MOBILE_CREATE_SIGNATURE_STEPS.INITIALS)}
        />
      )}
      {step === MOBILE_CREATE_SIGNATURE_STEPS.INITIALS && (
        <SignatureScreen
          placeholder={translations.DRAW_INITIALS_HERE}
          buttonText={translations.SUBMIT}
          setImg={setInitials}
          setTrimmedImg={setTrimmedInitials}
          imgUrl={initials}
          onButtonClick={onChangeStep(MOBILE_CREATE_SIGNATURE_STEPS.PREVIEW)}
        />
      )}
      {step === MOBILE_CREATE_SIGNATURE_STEPS.PREVIEW && (
        <Preview signature={signature} initials={initials} onChangeStep={onChangeStep} onSubmit={onSubmit} />
      )}
    </>
  )

  return (
    <StyledContent style={{ width, height }} ref={contentRef}>
      {renderContent()}
    </StyledContent>
  )
}
