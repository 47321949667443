import {
  CreateTemplateFromContractBody,
  CreateTemplateFromContractResponse,
} from '../../../types/createTemplateFromContract'
import { FetchFailureAction, FetchFailurePayload } from '../../../types/fetch'
import { createFetchActionTypes } from '../../../utils/createFetchActionTypes'

import * as TYPES from './createFromContract.types'

export const fetchCreateTemplateFromContractTypes = createFetchActionTypes(
  TYPES.CreateFromContractActions.FETCH_CREATE_TEMPLATE_FROM_CONTRACT
)

export const fetchCreateTemplateFromContract = (
  body: CreateTemplateFromContractBody
): TYPES.FetchCreateTemplateFromContract => ({
  type: fetchCreateTemplateFromContractTypes.request,
  payload: { body },
})

export const fetchCreateTemplateFromContractSuccess = (
  response: CreateTemplateFromContractResponse
): TYPES.FetchCreateTemplateFromContractSuccess => ({
  type: fetchCreateTemplateFromContractTypes.success,
  payload: { id: response.id },
})

export const fetchCreateTemplateFromContractFailure = (payload: FetchFailurePayload): FetchFailureAction => ({
  type: fetchCreateTemplateFromContractTypes.failure,
  payload,
})

export const cleanCreateFromContract = () => ({
  type: TYPES.CreateFromContractActions.CLEAN_CREATE_TEMPLATE_FROM_CONTRACT,
})
