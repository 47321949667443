import React from 'react'
import ContentLoader from 'react-content-loader'

import { blueExtraLight, glowColor } from '../../../themes/colors'

const FILL_COLOR = blueExtraLight
const GLOW_COLOR = glowColor
const SPEED = 1.5
const SVG_WIDTH = '100%'
const SVG_HEIGHT = 271
const RADIUS = 3
const FIELD_HEIGHT = 56
const TEXT_HEIGHT = 21
const ROWS = [1, 2, 3, 4, 5]
const FIRST_FIELD_HEIGHT = FIELD_HEIGHT - 10

export const PreferencesNotificationsSkeleton: React.FC = () => {
  const renderRow = (y: number) => (
    <>
      <rect width="20%" height={TEXT_HEIGHT} x="0" y={y} rx={RADIUS} ry={RADIUS} />
      <rect width="7%" height={TEXT_HEIGHT} x="93%" y={y} rx={RADIUS} ry={RADIUS} />
      <rect width="7%" height={TEXT_HEIGHT} x="81%" y={y} rx={RADIUS} ry={RADIUS} />
      <rect width="7%" height={TEXT_HEIGHT} x="68%" y={y} rx={RADIUS} ry={RADIUS} />
    </>
  )
  return (
    <ContentLoader
      display="block"
      width={SVG_WIDTH}
      height={SVG_HEIGHT}
      speed={SPEED}
      backgroundColor={FILL_COLOR}
      foregroundColor={GLOW_COLOR}
    >
      {renderRow(0)}
      <rect width="100%" height="1" x="0" y={FIRST_FIELD_HEIGHT} rx={RADIUS} ry={RADIUS} />

      {ROWS.map((value) => (
        <React.Fragment key={value}>
          {renderRow(FIELD_HEIGHT * value + FIELD_HEIGHT / 2 - TEXT_HEIGHT)}
          <rect width="100%" height="1" x="0" y={FIRST_FIELD_HEIGHT + FIELD_HEIGHT * value} rx={RADIUS} ry={RADIUS} />
        </React.Fragment>
      ))}
    </ContentLoader>
  )
}
