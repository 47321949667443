import { FETCH_STATUSES } from '../../../types/fetch'
import { RootState } from '../../rootReducer.types'

export const placementByIdSelector = (state: RootState) => state.contracts.placement.byId
export const placementByPagesSelector = (state: RootState) => state.contracts.placement.byPages
export const placementSortedSelector = (state: RootState) => state.contracts.placement.sorted
export const placementFetchSelector = (state: RootState) => state.contracts.placement.fetch
export const placementFetchUpdateSelector = (state: RootState) => state.contracts.placement.update
export const placementShouldRegenerateSelector = (state: RootState) => state.contracts.placement.shouldRegenerate

export const placementFetchGenerateSelector = (state: RootState) => state.contracts.placement.generate
export const placementFetchGenerateError = (state: RootState) => state.contracts.placement.generate.error

export const placementGenerateIsSuccess = (state: RootState) =>
  state.contracts.placement.generate.status === FETCH_STATUSES.SUCCESS
export const placementUpdateIsSuccess = (state: RootState) =>
  state.contracts.placement.update.status === FETCH_STATUSES.SUCCESS

export const placementSendIsSuccess = (state: RootState) =>
  state.contracts.placement.send.fetchStatus === FETCH_STATUSES.SUCCESS
export const placementSendFetchStatus = (state: RootState) => state.contracts.placement.send.fetchStatus
export const placementSendError = (state: RootState) => state.contracts.placement.send.error

export const placementContractStatusIsSuccess = (state: RootState) =>
  state.contracts.placement.status.fetchStatus === FETCH_STATUSES.SUCCESS
export const placementContractStatus = (state: RootState) => state.contracts.placement.status
export const placementContractStatusError = (state: RootState) => state.contracts.placement.status.error
