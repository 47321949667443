import React from 'react'

import { SvgIconProps } from '../../ui/SvgIcon/SvgIcon.types'
import SvgIcon from '../../ui/SvgIcon'

export const SignIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <path d="M21.6239 9.07579H24V7.53984H21.9173L20.2891 6.89474L22.2721 4.91183C23.3996 3.78093 23.3996 1.95095 22.2721 0.820054C21.1272 -0.273351 19.3251 -0.273351 18.1803 0.820054L15.2697 3.73068L3.46587 5.30503L0.309494 14.1982C-0.11078 15.4368 0.494528 16.79 1.69799 17.3023L1.08976 17.9098L0 23.0921L5.1846 22.0016L6.18297 21.0071C6.58006 21.2366 7.02986 21.3594 7.48853 21.3634H14.9233L21.0671 19.0595H24V17.5235H20.7891L14.6453 19.8274H7.48853C6.85231 19.8274 6.33657 19.3117 6.33657 18.6755C6.33657 18.0393 6.85231 17.5235 7.48853 17.5235H13.5663L17.4254 13.6652L15.4048 11.7791L19.1057 8.07742L21.6239 9.07579ZM19.2662 1.90592C19.8034 1.39296 20.6489 1.39296 21.1861 1.90592C21.7154 2.4365 21.7154 3.29529 21.1861 3.82586L20.1271 4.8849L18.2072 2.96496L19.2662 1.90592ZM1.76097 14.6974H1.76174L4.60324 6.70275L13.4787 5.51853L10.6925 8.30781H7.66286L5.70529 9.46362L3.65863 15.2687C3.64078 15.3163 3.61894 15.3623 3.59336 15.4062L3.11107 15.8877C2.98488 15.9504 2.84645 15.9844 2.70558 15.9876C2.15844 15.9877 1.71475 15.5443 1.7146 14.9972C1.7146 14.8954 1.7302 14.7943 1.76097 14.6974ZM9.15657 9.84377L6.13382 12.8665L6.96784 10.5012L8.08217 9.84377H9.15657ZM12.9304 15.9876H11.1963L14.3181 12.8657L15.2151 13.7021L12.9304 15.9876ZM9.02448 15.9876H7.48853C6.00403 15.9876 4.80061 17.191 4.80061 18.6755C4.80191 19.1039 4.90732 19.5256 5.1078 19.9042L4.41663 20.5954L1.98522 21.1069L2.49669 18.6755L4.03264 17.1395C4.36714 16.9334 4.64856 16.6517 4.85437 16.317L17.1212 4.05093L19.0412 5.97086L9.02448 15.9876Z" />
  </SvgIcon>
)
