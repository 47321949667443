import styled from 'styled-components'

export const StyledWrapper = styled.div<{ noError?: boolean }>`
  display: inline-block;
  font-family: ${(props) => props.theme.palette.fontFamily};
  position: relative;
  padding-bottom: ${({ noError }) => (noError ? '16px' : '24px')};
  line-height: ${(props) => props.theme.palette.lineHeight};
  width: 100%;
  font-size: 0.875rem;
`
export const StyledLabel = styled.span<{ disabled?: boolean }>`
  display: flex;
  margin-bottom: 4px;
  ${(props) => props.disabled && `color: ${props.theme.palette.grey}`}
`
export const StyledInput = styled.input`
  background: ${(props) => props.theme.palette.white};
  box-sizing: border-box;
  padding: 8px 0 9px;
  font-size: 0.875rem;
  color: ${(props) => props.theme.palette.textColor};
  font-family: ${(props) => props.theme.palette.fontFamily};
  line-height: ${(props) => props.theme.palette.lineHeight};
  border: 0;
  width: 100%;

  &:active,
  &:focus {
    outline: none;
  }
  &::placeholder {
    color: ${(props) => props.theme.palette.grey};
    font-weight: normal;
  }
  &:-webkit-autofill {
    box-shadow: ${({ theme }) => `0 0 0 1000px ${theme.palette.white} inset`};
  }

  &:disabled {
    color: ${(props) => props.theme.palette.grey};
  }
`
export const StyledError = styled.span`
  display: block;
  color: ${(props) => props.theme.palette.errorColor};
  position: absolute;
`
export const StyledNote = styled.span`
  display: block;
  position: absolute;
  color: ${(props) => props.theme.palette.grey};
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const StyledIconWrapper = styled.div<{ flipIcon?: boolean; disabled?: boolean }>`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding-left: 12px;
  user-select: none;
  ${({ disabled }) => (disabled ? 'pointer-events: none;' : '')}

  svg {
    ${({ flipIcon }) =>
      flipIcon &&
      `
    transform: rotate(180deg);
  `}
  }
`
export const StyledInputWrapper = styled.div<{ isError: boolean; isFocus: boolean; disabled?: boolean }>`
  display: flex;
  border: 1px solid ${(props) => props.theme.palette.grey};
  border-radius: 4px;
  padding: 0 14px;

  ${({ isFocus, theme }) =>
    isFocus &&
    `
    border-color: ${theme.palette.blueDark};
  `}

  ${({ isError, theme }) =>
    isError &&
    `
    border-color: ${theme.palette.errorColor};
  `}
  ${(props) => props.disabled && `border-color: ${props.theme.palette.transparentGrey}`}
`
export const StyledPrefix = styled.div<{ disabled?: boolean }>`
  display: flex;
  align-items: center;
  margin-top: -1px;
  padding-right: 6px;

  ${(props) => props.disabled && `color: ${props.theme.palette.grey}`}
`
export const StyledSearchWrapper = styled.div<{ isError: boolean; isFocus: boolean; disabled?: boolean }>`
  display: flex;
  border-radius: 19px;
  padding: 0 14px;
  border: 1px solid ${({ theme }) => theme.palette.blueExtraLight};
  background-color: ${({ theme }) => theme.palette.blueExtraLight};

  input {
    background-color: ${({ theme }) => theme.palette.blueExtraLight};
  }

  ${({ isFocus, theme }) =>
    isFocus &&
    `
    border-color: ${theme.palette.blueDark};
  `}

  ${({ isError, theme }) =>
    isError &&
    `
    border-color: ${theme.palette.errorColor};
  `}
  ${(props) => props.disabled && `border-color: ${props.theme.palette.transparentGrey}`}
`
