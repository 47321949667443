import { Form, Formik } from 'formik'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { declineSigningInitialValues } from '../../../../../initialValues/declineSigningInitialValues'
import translations from '../../../../../translations/keys'
import Modal from '../../../Modal'
import Typography from '../../../../ui/Typography'
import { TYPOGRAPHY_NAMES } from '../../../../ui/Typography/Typography.types'
import Box from '../../../../ui/Box'
import FormTextArea from '../../../../ui/Forms/FormTextArea'
import { DeclineSigningFields, DeclineSigningForm } from '../../../../../types/declineSigning'
import Button from '../../../../ui/Button'
import { declineSigningValidationSchema } from '../../../../../validation/declineSigning/declineSigningValidationSchema'
import { fetchDeclineSigning } from '../../../../../store/contracts/signing/signing.actions'
import {
  signingDeclineIsSuccessSelector,
  signingDeclineFetchStatusSelector,
  signingDeclineErrorSelector,
  signingContractDocumentDataSelector,
} from '../../../../../store/contracts/signing/signing.selectors'
import { useShowFetchError } from '../../../../hooks/useShowFetchError'
import { useCheckAction } from '../../../../hooks/contractSign/useCheckAction'
import { fetchRecipients } from '../../../../../store/contracts/recipients/recipients.actions'

import { DeclineSigningModalProps } from './DeclineSigningModal.types'

export const DeclineSigningModal: React.FC<DeclineSigningModalProps> = ({ onClose, onSuccess }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const declineSuccessfully = useSelector(signingDeclineIsSuccessSelector)
  const fetchStatus = useSelector(signingDeclineFetchStatusSelector)
  const error = useSelector(signingDeclineErrorSelector)
  const data = useSelector(signingContractDocumentDataSelector)

  const closeAndFetchRecipients = () => {
    if (data) {
      dispatch(fetchRecipients(data.id))
    }
    onClose()
  }
  const { handleAction } = useCheckAction(onClose)

  useEffect(() => {
    if (declineSuccessfully) {
      onClose()
      onSuccess()
    }
  }, [declineSuccessfully, onSuccess])
  useShowFetchError(fetchStatus, error)

  const onSubmit = (values: DeclineSigningForm) => {
    handleAction(() => {
      dispatch(fetchDeclineSigning(data?.recipient.id || '', values))
    })
  }

  const renderForm = () => (
    <Form>
      <Box mt={5} mb={1}>
        <FormTextArea
          name={DeclineSigningFields.reason}
          label={t(translations.DECLINE_REASON_LABEL)}
          placeholder={t(translations.DECLINE_REASON_PLACEHOLDER)}
          height={152}
        />
      </Box>
      <Button type="submit" fullWidth>
        {t(translations.DECLINE)}
      </Button>
    </Form>
  )

  return (
    <Modal onClose={closeAndFetchRecipients}>
      <Box pt={3} pb={3} display="flex" justifyContent="center" flexDirection="column" textAlign="center" width={320}>
        <Typography name={TYPOGRAPHY_NAMES.H1}>{t(translations.DECLINE_SIGNING_TITLE)}</Typography>
        <Formik
          initialValues={declineSigningInitialValues}
          onSubmit={onSubmit}
          validationSchema={declineSigningValidationSchema}
        >
          {renderForm}
        </Formik>
      </Box>
    </Modal>
  )
}
