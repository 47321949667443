import styled from 'styled-components'

import Box from '../../../../ui/Box/index'

export const StyledItem = styled(Box).attrs({
  mbMobile: 3,
  width: '50%',
  mobileWidth: '100%',
})`
  & + & {
    margin-left: 24px;
  }

  ${({ theme }) => `@media ${theme.devices.mobile} {
    & + & {
      margin-left: 0;
    }
  }`}
`
export const StyledItemWrapper = styled(Box).attrs({
  display: 'flex',
  pt: 3,
  pb: 3,
  pl: 3,
  pr: 3,
  height: '100%',
})`
  border: 1px solid ${(props) => props.theme.palette.grey};
  border-radius: 4px;
`
export const StyledIconWrapper = styled(Box).attrs({
  mr: 2,
})``
