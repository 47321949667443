import { Formik } from 'formik'
import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import translations from '../../../translations/keys'
import { TYPOGRAPHY_COMPONENTS, TYPOGRAPHY_NAMES } from '../../ui/Typography/Typography.types'
import { ChangePasswordFields, ChangePasswordFormValues } from '../../../types/changePassword'
import { changePasswordInitialValues } from '../../../initialValues/changePasswordInitialValues'
import Button from '../../ui/Button'
import FormPassword from '../../ui/Forms/FormPassword'
import { changePasswordValidationSchema } from '../../../validation/changePassword/changePasswordValidationSchema'
import { fetchChangePassword, resetChangePassword } from '../../../store/changePassword/changePassword.actions'
import { location } from '../../../utils/location'
import {
  changePasswordIsFailureSelector,
  changePasswordSelector,
} from '../../../store/changePassword/changePassword.selectors'
import { useHistoryPushAfterSuccess } from '../../hooks/useHistoryPushAfterSuccess'
import { useShowFetchError } from '../../hooks/useShowFetchError'
import { openModal } from '../../../store/modals/modals.actions'
import { COMMON_MODALS_IDS } from '../../ui/Modals/CommonModals/CommonModals.constants'
import { NOTIFICATION_MODAL_TYPES } from '../../ui/Modals/NotificationModal/NotificationModal.constants'
import { useCleanFields } from '../../../hooks/useCleanFields'

import {
  StyledRequirementsInfo,
  StyledTitle,
  StyledItem,
  StyledAtLeastOne,
  StyledForm,
  StyledButtonWrapper,
} from './ChangePassword.styles'

const PASSWORD_CONTAIN_LIST = [translations.ONLY_LATIN_LETTERS, translations.EIGHT_OR_MORE_SYMBOLS]
const AT_LEAST_ONE_LIST = [
  translations.DIGIT,
  translations.SPECIAL_CHARACTER,
  translations.UPPERCASE_LETTER,
  translations.LOWERCASE_LETTER,
]

export const ChangePassword = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const formRef = useRef(null)
  const changePasswordData = useSelector(changePasswordSelector)
  const changePasswordFailure = useSelector(changePasswordIsFailureSelector)
  const onSubmit = ({ confirmNewPassword, ...values }: ChangePasswordFormValues) => {
    dispatch(fetchChangePassword(values))
  }
  const renderForm = () => (
    <StyledForm>
      <FormPassword
        name={ChangePasswordFields.currentPassword}
        label={t(translations.CURRENT_PASSWORD_LABEL)}
        placeholder={t(translations.CURRENT_PASSWORD_PLACEHOLDER)}
      />
      <FormPassword
        name={ChangePasswordFields.newPassword}
        label={t(translations.NEW_PASSWORD_LABEL)}
        placeholder={t(translations.NEW_PASSWORD_PLACEHOLDER)}
      />
      <FormPassword
        name={ChangePasswordFields.confirmNewPassword}
        label={t(translations.CONFIRM_NEW_PASSWORD)}
        placeholder={t(translations.CONFIRM_NEW_PASSWORD)}
      />
      <StyledButtonWrapper>
        <Button fullWidth type="submit">
          {t(translations.SAVE)}
        </Button>
      </StyledButtonWrapper>
    </StyledForm>
  )
  const showSuccessModal = () =>
    openModal(COMMON_MODALS_IDS.NOTIFICATION, {
      type: NOTIFICATION_MODAL_TYPES.SUCCESS,
      description: t(translations.PASSWORD_CHANGED_SUCCESSFULLY),
    })

  useHistoryPushAfterSuccess(location.editProfileUrl(), changePasswordData.fetchStatus, showSuccessModal)
  useShowFetchError(changePasswordData.fetchStatus, changePasswordData.error)
  useCleanFields(
    changePasswordFailure,
    [ChangePasswordFields.currentPassword, ChangePasswordFields.newPassword, ChangePasswordFields.confirmNewPassword],
    formRef
  )

  useEffect(
    () => () => {
      dispatch(resetChangePassword())
    },
    []
  )

  return (
    <>
      <StyledTitle name={TYPOGRAPHY_NAMES.H3} component={TYPOGRAPHY_COMPONENTS.h3}>
        {t(translations.PASSWORD_REQUIREMENTS)}
      </StyledTitle>
      <StyledRequirementsInfo name={TYPOGRAPHY_NAMES.body14} component={TYPOGRAPHY_COMPONENTS.p}>
        {t(translations.PASSWORD_CONTAIN)}:
      </StyledRequirementsInfo>
      <ul>
        {PASSWORD_CONTAIN_LIST.map((text, i) => (
          <StyledItem key={i} name={TYPOGRAPHY_NAMES.body14} component={TYPOGRAPHY_COMPONENTS.li}>
            {t(text)}
          </StyledItem>
        ))}
      </ul>
      <StyledAtLeastOne name={TYPOGRAPHY_NAMES.body14} component={TYPOGRAPHY_COMPONENTS.p}>
        {t(translations.AT_LEAST_ONE)}:
      </StyledAtLeastOne>
      <ul>
        {AT_LEAST_ONE_LIST.map((text, i) => (
          <StyledItem key={i} name={TYPOGRAPHY_NAMES.body14} component={TYPOGRAPHY_COMPONENTS.li}>
            {t(text)}
          </StyledItem>
        ))}
      </ul>
      <Formik
        innerRef={formRef}
        initialValues={changePasswordInitialValues}
        onSubmit={onSubmit}
        validationSchema={changePasswordValidationSchema}
      >
        {renderForm}
      </Formik>
    </>
  )
}
