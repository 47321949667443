import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useContractsFoldersFetch } from '../../../../../hooks/contracts/useContractsFoldersFetch'
import {
  foldersDataSelector,
  foldersDataErrorSelector,
  foldersDataFetchStatusSelector,
  foldersIsLoadingSelector,
} from '../../../../../store/contracts/folders/folders.selectors'
import { useShowFetchError } from '../../../../hooks/useShowFetchError'
import { DocumentMoveToFolderForm } from '../../../../../types/documentMoveToFolder'
import { modalsPayloadSelector } from '../../../../../store/modals/modals.selectors'
import { CONTRACTS_MODALS } from '../../../../../constants/contracts'
import {
  contractsListMoveToFolderFetchStatusSelector,
  contractsListMoveToFolderSuccessSelector,
  contractsListMoveToFolderErrorSelector,
} from '../../../../../store/contracts/contractsList/contractsList.selectors'
import {
  clearMoveContractToFolder,
  fetchMoveContractToFolder,
} from '../../../../../store/contracts/contractsList/contractsList.actions'
import { useDispatchUnmount } from '../../../../../hooks/useDispatchUnmount'
import MoveDocumentToFolderModal from '../../DocumentModals/MoveDocumentToFolderModal'

import { MoveToFolderModalProps } from './MoveToFolderModal.types'

export const MoveToFolderModal: React.FC<MoveToFolderModalProps> = ({ onClose }) => {
  const dispatch = useDispatch()
  const folders = useSelector(foldersDataSelector)
  const foldersError = useSelector(foldersDataErrorSelector)
  const foldersFetchStatus = useSelector(foldersDataFetchStatusSelector)
  const foldersIsLoading = useSelector(foldersIsLoadingSelector)
  const movedSuccessfully = useSelector(contractsListMoveToFolderSuccessSelector)
  const moveFetchStatus = useSelector(contractsListMoveToFolderFetchStatusSelector)
  const moveError = useSelector(contractsListMoveToFolderErrorSelector)
  const { id, recipient } = useSelector(modalsPayloadSelector(CONTRACTS_MODALS.CONTRACT_MOVE_TO_FOLDER))

  useEffect(() => {
    if (movedSuccessfully) {
      onClose()
    }
  }, [movedSuccessfully])
  useContractsFoldersFetch()
  useShowFetchError(foldersFetchStatus, foldersError)
  useShowFetchError(moveFetchStatus, moveError)
  useDispatchUnmount(clearMoveContractToFolder)

  const onSubmit = (values: DocumentMoveToFolderForm) => {
    dispatch(fetchMoveContractToFolder(id, values.folder, recipient))
  }

  return (
    <MoveDocumentToFolderModal onClose={onClose} folders={folders} loading={foldersIsLoading} onSubmit={onSubmit} />
  )
}
