import React from 'react'

import SvgIcon from '../../ui/SvgIcon'
import { SvgIconProps } from '../../ui/SvgIcon/SvgIcon.types'

export const CloseIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} viewBox="0 0 16 16">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.16272 3.16272C2.94576 3.37968 2.94576 3.73144 3.16272 3.94839L12.0516 12.8373C12.2686 13.0542 12.6203 13.0542 12.8373 12.8373C13.0542 12.6203 13.0542 12.2686 12.8373 12.0516L3.94839 3.16272C3.73143 2.94576 3.37968 2.94576 3.16272 3.16272Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.8373 3.16272C12.6203 2.94576 12.2686 2.94576 12.0516 3.16272L3.16272 12.0516C2.94576 12.2686 2.94576 12.6203 3.16272 12.8373C3.37968 13.0542 3.73143 13.0542 3.94839 12.8373L12.8373 3.94839C13.0542 3.73143 13.0542 3.37968 12.8373 3.16272Z"
    />
  </SvgIcon>
)
