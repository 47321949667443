import React from 'react'

import MainTemplate from '../../components/Main/MainTemplate'

import { CloudStorage as CloudStoragePage } from './CloudStorage'

export const CloudStorage = () => (
  <MainTemplate>
    <CloudStoragePage />
  </MainTemplate>
)
