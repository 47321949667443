import { combineEpics, Epic, ofType } from 'redux-observable'
import { of } from 'rxjs'
import { catchError, mergeMap, takeUntil } from 'rxjs/operators'

import { subscriptionService } from '../../../api'
import { catchFetchError } from '../../../utils/catchFetchError'

import * as ACTIONS from './userPlan.actions'

const fetchUserPlan: Epic = (action$) =>
  action$.pipe(
    ofType(ACTIONS.fetchUserPlanTypes.request),
    mergeMap(() =>
      subscriptionService.fetchUserPlan().pipe(
        mergeMap((response) => of(ACTIONS.fetchUserPlanSuccess(response))),
        catchError(catchFetchError(ACTIONS.fetchUserPlanFailure)),
        takeUntil(action$.pipe(ofType(ACTIONS.fetchUserPlanTypes.success, ACTIONS.fetchUserPlanTypes.failure)))
      )
    )
  )

export const userPlanEpics = combineEpics(fetchUserPlan)
