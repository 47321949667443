import styled from 'styled-components'

import Box from '../../Box'

export const StyledWrapper = styled(Box).attrs({
  pt: 3,
  pl: 4,
  plTablet: 6,
  pr: 8,
  prTablet: 2,
  mb: 2,
})`
  border-color: ${({ theme }) => theme.palette.greyLight};
  border-width: 1px;
  border-style: solid;
  border-radius: 4px;
  position: relative;
`
export const StyledCross = styled.div`
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;
`
export const StyledField = styled(Box).attrs({
  pr: 4,
})`
  width: 25%;

  ${({ theme }) => `@media ${theme.devices.laptop} {
    width: 50%;
  }`}
`
