import React, { useEffect, useRef } from 'react'

type MouseEvents = 'click' | 'mousedown' | 'mouseup'
type TouchEvents = 'touchstart' | 'touchend'

interface Props extends React.HTMLAttributes<HTMLElement> {
  onClickAway: (event: MouseEvent | TouchEvent) => void
  mouseEvent?: MouseEvents
  touchEvent?: TouchEvents
  as?: React.ElementType
  capture?: boolean
}

export const ClickOutsideListener: React.FC<Props> = ({
  as = 'div',
  onClickAway,
  mouseEvent = 'click',
  touchEvent = 'touchend',
  capture = false,
  ...props
}) => {
  const node = useRef<HTMLElement>(null)

  useEffect(() => {
    const handleEvents = (event: MouseEvent | TouchEvent): void => {
      if (node.current && node.current.contains(event.target as Node)) {
        return
      }

      onClickAway(event)
    }

    document.addEventListener(mouseEvent, handleEvents, capture)
    document.addEventListener(touchEvent, handleEvents, capture)

    return () => {
      document.removeEventListener(mouseEvent, handleEvents, capture)
      document.removeEventListener(touchEvent, handleEvents, capture)
    }
  }, [mouseEvent, onClickAway, touchEvent, capture])

  return React.createElement(as, { ref: node, ...props })
}
