import React from 'react'

import { SvgIconProps } from '../../ui/SvgIcon/SvgIcon.types'
import SvgIcon from '../../ui/SvgIcon'

export const RoundCrossIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <path d="M12 1.5C6.20156 1.5 1.5 6.20156 1.5 12C1.5 17.7984 6.20156 22.5 12 22.5C17.7984 22.5 22.5 17.7984 22.5 12C22.5 6.20156 17.7984 1.5 12 1.5ZM15.8766 15.9891L14.3297 15.982L12 13.2047L9.67266 15.9797L8.12344 15.9867C8.02031 15.9867 7.93594 15.9047 7.93594 15.7992C7.93594 15.7547 7.95234 15.7125 7.98047 15.6773L11.0297 12.0445L7.98047 8.41406C7.95215 8.37971 7.93643 8.3367 7.93594 8.29219C7.93594 8.18906 8.02031 8.10469 8.12344 8.10469L9.67266 8.11172L12 10.8891L14.3273 8.11406L15.8742 8.10703C15.9773 8.10703 16.0617 8.18906 16.0617 8.29453C16.0617 8.33906 16.0453 8.38125 16.0172 8.41641L12.9727 12.0469L16.0195 15.6797C16.0477 15.7148 16.0641 15.757 16.0641 15.8016C16.0641 15.9047 15.9797 15.9891 15.8766 15.9891Z" />
  </SvgIcon>
)
