import { getEnvs } from '../../api/getEnvs'
import { navigateToMobileApp } from '../utils/navigateToMobileApp'

const { REACT_APP_API } = getEnvs()

export const useNavigateToMobileApp = () => {
  const handleNavigate = () => {
    navigateToMobileApp()
    setTimeout(() => {
      const { pathname, search } = window.location
      window.location.replace(`${REACT_APP_API}${pathname}${search}`)
    }, 3000)
  }

  return { handleNavigate }
}
