import React from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import translations from '../../../../../translations/keys'
import { openModal } from '../../../../../store/modals/modals.actions'
import { CONTRACTS_MODALS } from '../../../../../constants/contracts'
import Box from '../../../../ui/Box'
import { ContractSvg } from '../../../../assets/ContractSvg'
import Button from '../../../../ui/Button'
import { BUTTON_VARIANTS } from '../../../../ui/Button/Button.types'
import UploadMenu from '../UploadMenu'
import { CONTRACT_STATUS } from '../../../../../types/contracts'

import * as Styled from './EmptyContracts.styles'
import { EmptyContractsProps } from './EmptyContracts.types'

export const EmptyContracts: React.FC<EmptyContractsProps> = ({ category }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const canAdd = !category || category === CONTRACT_STATUS.DRAFT

  const openAddFolderModal = () => {
    dispatch(openModal(CONTRACTS_MODALS.CONTRACTS_CREATE_FOLDER))
  }

  return (
    <Styled.Container data-testid="contracts-container">
      <Styled.Placeholder data-testid="contracts-placeholder">
        <Box textAlign="center" mb={4}>
          <ContractSvg />
        </Box>
        <Box mb={5} textAlign="center">
          {t(canAdd ? translations.CONTRACTS_PLACEHOLDER_TEXT : translations.NO_CONTRACTS)}
        </Box>
        {canAdd && (
          <>
            <Styled.PlaceholderBtn>
              <UploadMenu />
            </Styled.PlaceholderBtn>
            <Box mt={3} display="flex" justifyContent="center">
              <Button icon="folderAdd" onClick={openAddFolderModal} variant={BUTTON_VARIANTS.INACTIVE}>
                {t(translations.ADD_FOLDER)}
              </Button>
            </Box>
          </>
        )}
      </Styled.Placeholder>
    </Styled.Container>
  )
}
