import { useEffect, useRef, useState } from 'react'

// TODO: create fallback for IE11
const useIntersection = (root = null, rootMargin = '0px', threshold = 0) => {
  const [isIntersecting, updateIntersecting] = useState(false)
  const [target, setTarget] = useState<HTMLDivElement | null>(null)
  const observer = useRef<IntersectionObserver | null>(null)

  useEffect(() => {
    if (observer.current) {
      observer.current.disconnect()
    }

    observer.current = new IntersectionObserver(([entry]) => updateIntersecting(entry.isIntersecting), {
      root,
      rootMargin,
      threshold,
    })

    const { current: currentObserver } = observer

    if (target) {
      currentObserver.observe(target)
    }

    return () => currentObserver.disconnect()
  }, [target, root, rootMargin, threshold])

  return [isIntersecting, setTarget] as const
}

export default useIntersection
