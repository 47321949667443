import React from 'react'
import { Switch, Redirect } from 'react-router-dom'

import { SubscriptionFeatures } from '../../types/subscription'
import { location } from '../../utils/location'
import {
  EditProfile,
  ChangePassword,
  Signatures,
  Contacts,
  EKYC,
  SubscriptionPlans,
  SubscriptionView,
  ApiIntegration,
  SubscriptionPayment,
} from '../pages'

import { PrivateRoute } from './components/PrivateRoute'

const ProfileRoutes = () => (
  <Switch>
    <PrivateRoute exact path={location.editProfileUrl()}>
      <EditProfile />
    </PrivateRoute>
    <PrivateRoute exact path={location.changePasswordUrl()}>
      <ChangePassword />
    </PrivateRoute>
    <PrivateRoute exact path={location.signatureAndInitialsUrl()}>
      <Signatures />
    </PrivateRoute>
    <PrivateRoute exact path={location.contactsUrl()}>
      <Contacts />
    </PrivateRoute>
    <PrivateRoute exact path={location.eKYCUrl()}>
      <EKYC />
    </PrivateRoute>
    <PrivateRoute exact path={location.subscriptionPlansUrl()}>
      <SubscriptionPlans />
    </PrivateRoute>
    <PrivateRoute exact path={location.subscriptionViewUrl()}>
      <SubscriptionView />
    </PrivateRoute>
    <PrivateRoute exact path={location.apiIntegrationUrl()} feature={SubscriptionFeatures.FEATURE_API_INTEGRATION}>
      <ApiIntegration />
    </PrivateRoute>
    <PrivateRoute exact path={location.subscriptionPaymentUrl()}>
      <SubscriptionPayment />
    </PrivateRoute>
    <Redirect to={location.error404Url()} />
  </Switch>
)

export default ProfileRoutes
