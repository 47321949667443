import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router'

import { modalsPayloadSelector } from '../../../../../store/modals/modals.selectors'
import { CONTRACT_SIGN_MODALS } from '../../../../constants/contractSign'
import OTPVerificationModal from '../../VerificationsModals/OTPVerificationModal'
import { ContractRouteParams } from '../../../../types/signing'

import { ActionOTPVerificationModalProps } from './ActionOTPVerificationModal.types'

export const ActionOTPVerificationModal: React.FC<ActionOTPVerificationModalProps> = ({ onClose }) => {
  const { contractId } = useParams<ContractRouteParams>()
  const { handleAction } = useSelector(modalsPayloadSelector(CONTRACT_SIGN_MODALS.ACTION_OTP_VERIFICATION))

  useEffect(
    () => () => {
      onClose()
    },
    []
  )

  return <OTPVerificationModal onClose={onClose} onSuccess={handleAction} contractId={contractId} />
}
