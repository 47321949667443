export const omit = (keys: string[], obj: any) => {
  const result: any = {}
  const index: any = {}

  for (let i = 0, len = keys.length; i < len; i += 1) {
    index[keys[i]] = true
  }

  for (const prop in obj) {
    if (!index.hasOwnProperty(prop)) {
      result[prop] = obj[prop]
    }
  }

  return result
}
