import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import translations from '../../../translations/keys'
import { removeExtension } from '../../../utils/file/removeExtension'
import { cleanCreateContract } from '../../../store/templates/createContract/createContract.actions'
import * as PREVIEW_SELECTORS from '../../../store/templates/preview/preview.selectors'
import * as RECIPIENTS_SELECTORS from '../../../store/templates/recipients/recipients.selectors'
import {
  fetchSenderInfo,
  fetchRecipients,
  clearRecipients,
} from '../../../store/templates/recipients/recipients.actions'
import * as PLACEMENT_SELECTORS from '../../../store/templates/placement/placement.selectors'
import * as FIELDS_SELECTORS from '../../../store/templates/fields/fields.selectors'
import * as ACTIONS from '../../../store/templates/placement/placement.actions'
import { clearFields, setFieldText } from '../../../store/templates/fields/fields.actions'
import { useDispatchUnmount } from '../../../hooks/useDispatchUnmount'
import { useTemplatePreviewFetch } from '../../../hooks/templatePreview/useTemplatePreviewFetch'
import { useShowFetchError } from '../../hooks/useShowFetchError'
import { useTemplateFieldsFetch } from '../../../hooks/templates/useTemplateFieldsFetch'
import { clearSuccessMessage } from '../../../store/templates/preview/preview.actions'
import DocViewer from '../../ui/DocViewer'
import { PreviewHeader } from '../../components/PreviewHeader/PreviewHeader'
import FullScreenTemplate from '../../components/Main/FullScreenTemplate'
import { TemplateRouteParams } from '../../types/template'
import Box from '../../ui/Box'
import NotificationBadge from '../../ui/NotificationBadge'
import { viewOptionsIsSignatureNamesVisibleSelector } from '../../../store/templates/viewOptions/viewOptions.selectors'

import { useToolbarActions } from './hooks/useToolbarActions'
import { useButtons } from './hooks/useButtons'
import Modals from './components/Modals'
import * as Styled from './TemplateFillFields.styles'

export const TemplateFillFields = () => {
  const dispatch = useDispatch()
  const { templateId } = useParams<TemplateRouteParams>()
  const previewFetchStatus = useSelector(PREVIEW_SELECTORS.previewFetchStatusSelector)
  const error = useSelector(PREVIEW_SELECTORS.previewErrorSelector)
  const data = useSelector(PREVIEW_SELECTORS.previewDataSelector)
  const byId = useSelector(RECIPIENTS_SELECTORS.recipientsByIdSelector)
  const byOrder = useSelector(RECIPIENTS_SELECTORS.recipientsByOrderSelector)
  const sorted = useSelector(RECIPIENTS_SELECTORS.recipientsSortedSelector)
  const placementById = useSelector(PLACEMENT_SELECTORS.placementByIdSelector)
  const placementByPages = useSelector(PLACEMENT_SELECTORS.placementByPagesSelector)
  const fieldsById = useSelector(FIELDS_SELECTORS.fieldsByIdSelector)
  const fieldsByPages = useSelector(FIELDS_SELECTORS.fieldsByPagesSelector)
  const scrollToField = useSelector(FIELDS_SELECTORS.fieldsScrollToFieldSelector)
  const title = removeExtension(data?.name || '')
  const recipients = { byId, byOrder, sorted }
  const shouldRefetch = useSelector(PLACEMENT_SELECTORS.placementGenerateIsSuccess)
  const successMessage = useSelector(PREVIEW_SELECTORS.previewSuccessMessageSelector)
  const fetchFieldsRequest = useSelector(FIELDS_SELECTORS.fieldsFetchSelector)
  const isSignatureNamesVisible = useSelector(viewOptionsIsSignatureNamesVisibleSelector)
  const buttons = useButtons()
  const toolbarActions = useToolbarActions()

  const handleNotificationBadgeClose = () => {
    dispatch(clearSuccessMessage())
  }

  const handleFieldChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    dispatch(setFieldText(event.target.name, event.target.value))
  }

  useEffect(() => {
    dispatch(fetchSenderInfo())
    dispatch(fetchRecipients(templateId))
  }, [])

  useEffect(() => {
    if (recipients.sorted.length > 0) {
      dispatch(ACTIONS.fetchPlacement(templateId, byId))
    }
  }, [recipients.sorted, shouldRefetch])

  useTemplatePreviewFetch(templateId)
  useTemplateFieldsFetch(templateId)
  useShowFetchError(previewFetchStatus, error)
  useShowFetchError(fetchFieldsRequest.status, fetchFieldsRequest.error)
  useDispatchUnmount(clearRecipients, ACTIONS.clearPlacement, cleanCreateContract, clearFields)

  return (
    <FullScreenTemplate headerContent={<PreviewHeader buttons={buttons} title={title} />}>
      <Styled.Container>
        {data && (
          <DocViewer
            readonly
            pages={data.pages}
            placementById={placementById}
            placementByPages={placementByPages}
            fieldsById={fieldsById}
            fieldsByPages={fieldsByPages}
            actions={toolbarActions}
            scrollToElemId={scrollToField}
            isSignatureNamesVisible={isSignatureNamesVisible}
            onFieldChange={handleFieldChange}
          />
        )}
        {successMessage && (
          <Styled.MessageContainer>
            <Box ml={4} mt={4}>
              <NotificationBadge
                isShow
                isAutoClose
                message={translations[successMessage]}
                onClose={handleNotificationBadgeClose}
              />
            </Box>
          </Styled.MessageContainer>
        )}
      </Styled.Container>
      <Modals />
    </FullScreenTemplate>
  )
}
