import { EditProfileFields, EditProfileFormBody, EditProfileInitialDataResponse } from '../types/editProfile'

const editProfileInitialValues: EditProfileFormBody = {
  [EditProfileFields.firstName]: '',
  [EditProfileFields.lastName]: '',
  [EditProfileFields.address]: '',
  [EditProfileFields.city]: '',
  [EditProfileFields.company]: '',
  [EditProfileFields.industry]: '',
  [EditProfileFields.postalCode]: '',
  [EditProfileFields.region]: '',
  [EditProfileFields.job]: '',
  [EditProfileFields.country]: '',
}

export const prepareEditProfileInitialValues = (initialValues?: EditProfileInitialDataResponse): EditProfileFormBody =>
  initialValues
    ? Object.keys(editProfileInitialValues).reduce(
        (values, key) => ({ ...values, [key]: initialValues[key] }),
        editProfileInitialValues
      )
    : editProfileInitialValues
