import { useDispatch } from 'react-redux'
import { useEffect } from 'react'

import { ConfirmationStatus } from '../../types/confirmationActions'
import { Variant } from '../../types/notifications'
import * as ACTIONS from '../../store/contracts/signing/signing.actions'
import { SigningNotificationMessages } from '../../store/contracts/signing/signing.types'

export const useConfirmationNotification = (isConfirmed: boolean, confirmStatus: ConfirmationStatus | null) => {
  const dispatch = useDispatch()

  useEffect(() => {
    if (isConfirmed && confirmStatus) {
      dispatch(
        ACTIONS.setSigningNotification({
          variant: Variant.SUCCESS,
          message:
            confirmStatus === ConfirmationStatus.ACCEPTED
              ? SigningNotificationMessages.ACCEPTED_SUCCESSFULLY
              : SigningNotificationMessages.REJECTED_SUCCESSFULLY,
        })
      )
    }
  }, [isConfirmed, confirmStatus])
}
