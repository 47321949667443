import { FetchFailureAction, FetchFailurePayload } from '../../../types/fetch'
import { UpdateNotificationsBody, UserNotificationsValues } from '../../../types/preferencesNotifications'
import { createFetchActionTypes } from '../../../utils/createFetchActionTypes'

import * as TYPES from './notifications.types'

export const fetchNotificationsTypes = createFetchActionTypes(
  TYPES.NotificationsActions.FETCH_PREFERENCES_NOTIFICATIONS
)
export const updateNotificationsTypes = createFetchActionTypes(
  TYPES.NotificationsActions.UPDATE_PREFERENCES_NOTIFICATIONS
)

export const fetchNotifications = (): TYPES.FetchNotifications => ({
  type: fetchNotificationsTypes.request,
})

export const fetchNotificationsSuccess = (notifications: UserNotificationsValues): TYPES.FetchNotificationsSuccess => ({
  type: fetchNotificationsTypes.success,
  payload: { notifications },
})

export const fetchNotificationsFailure = (payload: FetchFailurePayload): FetchFailureAction => ({
  type: fetchNotificationsTypes.failure,
  payload,
})

export const cleanNotifications = () => ({
  type: TYPES.NotificationsActions.CLEAN_PREFERENCES_NOTIFICATIONS,
})

export const updateNotifications = (body: UpdateNotificationsBody): TYPES.UpdateNotifications => ({
  type: updateNotificationsTypes.request,
  payload: { body },
})

export const updateNotificationsSuccess = (
  notifications: UserNotificationsValues
): TYPES.UpdateNotificationsSuccess => ({
  type: updateNotificationsTypes.success,
  payload: { notifications },
})

export const updateNotificationsFailure = (payload: FetchFailurePayload): FetchFailureAction => ({
  type: updateNotificationsTypes.failure,
  payload,
})
