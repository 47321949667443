import styled, { css } from 'styled-components'

import Button from '../../ui/Button'
import { BUTTON_VARIANTS } from '../../ui/Button/Button.types'
import Link from '../../ui/Link'
import { TriangleDownIcon } from '../../assets/icons'

const activeStyles = css`
  color: ${({ theme }) => theme.palette.white};
  text-decoration: none;
  background-color: ${({ theme }) => theme.palette.blue};
`
const disabledStyles = css`
  pointer-events: none;
  opacity: 0.5;
`
export const Nav = styled.nav<{ open: boolean }>`
  display: ${({ open }) => (open ? 'block' : 'none')};
  width: 224px;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  background-color: ${({ theme }) => theme.palette.blueDark};
  z-index: 200;

  ${({ theme }) => `@media ${theme.devices.mobile} {
    width: 100%;
  }`}
`
export const Content = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  padding-top: 54px;
  color: ${({ theme }) => theme.palette.white};
`
export const List = styled.ul`
  width: 100%;
  margin-top: 20px;
  list-style: none;
  overflow: hidden;
`
export const ListItem = styled.li`
  ${List} {
    margin-top: 5px;
  }
`
export const IconWrapper = styled.span`
  display: inline-flex;
  margin-right: 16px;
`
export const MenuLink = styled(Link)<{ active?: boolean; disabled?: boolean; nested?: boolean }>`
  display: flex;
  align-items: center;
  margin: ${({ nested }) => (nested ? '0' : '20px 0')};
  padding: ${({ nested }) => (nested ? '6px 27px 6px 74px' : '6px 27px')};
  color: ${({ theme, active }) => (active ? theme.palette.white : theme.palette.grey)};
  font-size: 14px;
  transition: color 200ms, background-color 200ms;

  &:hover,
  &:focus {
    ${activeStyles}
  }

  ${({ active }) => active && activeStyles}
  ${({ disabled }) => disabled && disabledStyles}
`
export const MenuButton = styled(Button).attrs({ variant: BUTTON_VARIANTS.TEXT })<{ active?: boolean }>`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  margin: 20px 0 5px;
  padding: 6px 15px 6px 27px;
  color: ${({ theme, active }) => (active ? theme.palette.white : theme.palette.grey)};
  transition: color 200ms;
`
export const TriangleIcon = styled(TriangleDownIcon).attrs({ size: 'small' })<{ isFlipped?: boolean }>`
  margin-left: auto;
  transform: rotate(${({ isFlipped }) => (isFlipped ? '180deg' : '0deg')});
  transition: transform 300ms;
`
export const Footer = styled.div`
  width: 100%;
  margin-top: auto;
  margin-bottom: 20px;
`
export const CloseButton = styled(Button).attrs({ variant: BUTTON_VARIANTS.TEXT })`
  position: absolute;
  top: 23px;
  left: 16px;
`
