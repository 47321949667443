export enum CreateFolderFields {
  folderName = 'folderName',
}

export enum CREATE_FOLDER_ERRORS {
  FOLDER_EXISTS_ERROR = 'FOLDER_EXISTS_ERROR',
}

export interface CreateFolderBody {
  folderName: string
}
