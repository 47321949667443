import React from 'react'

export const SmallMastercardSvg = () => (
  <svg width="28" height="17" viewBox="0 0 28 17" fill="none">
    <path d="M17.5861 15.0788H10.0994V1.80469H17.5862L17.5861 15.0788Z" fill="#FF5F00" />
    <path
      d="M10.5754 8.44205C10.5754 5.74939 11.8534 3.35077 13.8433 1.80499C12.337 0.633189 10.474 -0.00278711 8.55573 9.18191e-06C3.83041 9.18191e-06 0 3.77958 0 8.44205C0 13.1045 3.83041 16.8841 8.55573 16.8841C10.474 16.8869 12.3371 16.2509 13.8434 15.0791C11.8536 13.5336 10.5754 11.1348 10.5754 8.44205Z"
      fill="#EB001B"
    />
    <path
      d="M27.6881 8.44205C27.6881 13.1045 23.8577 16.8841 19.1324 16.8841C17.2139 16.8869 15.3506 16.2509 13.844 15.0791C15.8345 13.5333 17.1124 11.1348 17.1124 8.44205C17.1124 5.74926 15.8345 3.35077 13.844 1.80499C15.3506 0.633224 17.2138 -0.0027381 19.1323 8.86164e-06C23.8576 8.86164e-06 27.688 3.77958 27.688 8.44205"
      fill="#F79E1B"
    />
  </svg>
)
