import React from 'react'
import ContentLoader from 'react-content-loader'

import { blueExtraLight, glowColor } from '../../../themes/colors'

const FILL_COLOR = blueExtraLight
const GLOW_COLOR = glowColor
const SPEED = 1.5
const SVG_WIDTH = '100%'
const SVG_HEIGHT = 271
const RADIUS = 3
const FIELD_HEIGHT = 40

export const SaveAsTemplateModalSkeleton: React.FC = () => (
  <ContentLoader
    display="block"
    width={SVG_WIDTH}
    height={SVG_HEIGHT}
    speed={SPEED}
    backgroundColor={FILL_COLOR}
    foregroundColor={GLOW_COLOR}
  >
    <rect width="20%" height="15" x="0" y="0" rx={RADIUS} ry={RADIUS} />
    <rect width="100%" height={FIELD_HEIGHT} x="0" y="25" rx={RADIUS} ry={RADIUS} />

    <rect width="20%" height="15" x="0" y="90" rx={RADIUS} ry={RADIUS} />
    <rect width="100%" height={FIELD_HEIGHT} x="0" y="115" rx={RADIUS} ry={RADIUS} />

    <rect width="30%" height="15" x="0" y="175" rx={RADIUS} ry={RADIUS} />

    <rect width="100%" height={FIELD_HEIGHT} x="0" y="225" rx={RADIUS} ry={RADIUS} />
  </ContentLoader>
)
