import * as yup from 'yup'

import { LoginFields } from '../../types/login'
import { emailValidationSchema } from '../emailValidationSchema'
import { singlePasswordValidationSchema } from '../passwordValidationSchema'

export const loginValidationSchema = yup.object().shape({
  [LoginFields.email]: emailValidationSchema,
  [LoginFields.password]: singlePasswordValidationSchema(true),
  [LoginFields.rememberMe]: yup.boolean(),
})
