import { FetchFailureAction, FETCH_STATUSES } from '../../../types/fetch'
import { createReducer } from '../../createReducer'

import * as ACTIONS from './createContract.actions'
import * as TYPES from './createContract.types'

type Actions = FetchFailureAction | TYPES.FetchCreateContractSuccess | TYPES.SetCreateContractRecipientData

export const initialState: TYPES.CreateContractState = {
  contractId: null,
  recipients: {
    list: null,
    order: null,
  },
  fetchStatus: FETCH_STATUSES.IDLE,
  error: null,
}

export const createContract = createReducer<TYPES.CreateContractState, Actions>(initialState, {
  [ACTIONS.fetchCreateContractTypes.request]: (state) => ({
    ...state,
    fetchStatus: FETCH_STATUSES.REQUEST,
  }),
  [ACTIONS.fetchCreateContractTypes.success]: (state, action) => ({
    ...state,
    contractId: (action as TYPES.FetchCreateContractSuccess).payload.contractId,
    fetchStatus: FETCH_STATUSES.SUCCESS,
  }),
  [ACTIONS.fetchCreateContractTypes.failure]: (state, action) => ({
    ...state,
    fetchStatus: FETCH_STATUSES.FAILURE,
    error: (action as FetchFailureAction).payload,
  }),
  [TYPES.CreateContractActions.SET_CREATE_CONTRACT_RECIPIENTS_DATA]: (state, action) => {
    const { recipients, order } = (action as TYPES.SetCreateContractRecipientData).payload

    return {
      ...state,
      recipients: {
        ...state.recipients,
        list: recipients,
        order,
      },
    }
  },
  [TYPES.CreateContractActions.CLEAN_CREATE_CONTRACT_FROM_TEMPLATE]: () => ({ ...initialState }),
})
