import { useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { FormikProps } from 'formik'

import translations from '../../../../translations/keys'
import { TEMPLATES_MODALS } from '../../../../constants/templates'
import { AddRecipientsForm } from '../../../../types/recipients'
import { TemplateRouteParams } from '../../../types/template'
import { location } from '../../../../utils/location'
import { validateActions, prepareAllRecipientsForSubmit } from '../../../../utils/recipients'
import { checkIfPlacementShouldRegen } from '../../../../utils/placement'
import { useDispatchUnmount } from '../../../../hooks/useDispatchUnmount'
import { openModal } from '../../../../store/modals/modals.actions'
import * as SELECTORS from '../../../../store/templates/recipients/recipients.selectors'
import { setShouldRegenerate } from '../../../../store/templates/placement/placement.actions'
import { clearRecipients, fetchAddRecipients } from '../../../../store/templates/recipients/recipients.actions'
import { useHistoryPushAfterSuccess } from '../../../hooks/useHistoryPushAfterSuccess'
import { COMMON_MODALS_IDS } from '../../../ui/Modals/CommonModals/CommonModals.constants'
import { NOTIFICATION_MODAL_TYPES } from '../../../ui/Modals/NotificationModal/NotificationModal.constants'
import { BUTTON_VARIANTS } from '../../../ui/Button/Button.types'
import { PlayIcon } from '../../../assets/icons'

export default (formRef: React.MutableRefObject<FormikProps<AddRecipientsForm> | null>) => {
  const dispatch = useDispatch()
  const { templateId } = useParams<TemplateRouteParams>()
  const [nextPage, setNextPage] = useState('')
  const order = useSelector(SELECTORS.recipientsOrderSelector)
  const byId = useSelector(SELECTORS.recipientsByIdSelector)
  const fetchAdd = useSelector(SELECTORS.recipientsFetchAddSelector)

  const handleSubmit = (values: AddRecipientsForm) => {
    const preparedBody = prepareAllRecipientsForSubmit(values, order, true)

    if (templateId) {
      // TODO: remove when API is ready for placements
      if (checkIfPlacementShouldRegen(values.recipients, byId)) {
        dispatch(setShouldRegenerate(true))
      }
      dispatch(fetchAddRecipients(templateId, preparedBody))
    } else {
      dispatch(openModal(TEMPLATES_MODALS.UPLOAD_TEMPLATE_TO_WITH_RECIPIENTS, preparedBody))
    }
  }

  const buttons = useMemo(
    () => [
      {
        label: translations.SAVE_AND_CLOSE,
        onClick: () => {
          setNextPage(location.templatesAllUrl())
          formRef.current?.handleSubmit()
        },
        MobileIcon: PlayIcon,
        variant: BUTTON_VARIANTS.SECONDARY,
      },
      {
        label: translations.NEXT,
        onClick: () => {
          const form = formRef.current
          if (form) {
            const errorCode = validateActions(form.values.recipients)
            if (errorCode) {
              dispatch(
                openModal(COMMON_MODALS_IDS.NOTIFICATION, {
                  type: NOTIFICATION_MODAL_TYPES.WARNING,
                  description: translations[errorCode],
                })
              )
            } else {
              setNextPage(location.templatesRecipientsDiagramUrl(templateId))
              form.handleSubmit()
            }
          }
        },
        MobileIcon: PlayIcon,
      },
    ],
    []
  )

  useHistoryPushAfterSuccess(nextPage, fetchAdd.status)
  useDispatchUnmount(clearRecipients)

  return { buttons, handleSubmit }
}
