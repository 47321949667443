import styled from 'styled-components'

import * as colors from '../../../themes/colors'

export const StyledMenu = styled.ul`
  list-style: none;
  padding: 8px 0;
  border-width: 1px;
  border-style: solid;
  border-color: ${({ theme }) => theme.palette.strokeGrey};
  box-shadow: ${({ theme }) => theme.shadow};
  border-radius: 4px;
`

export const StyledLabel = styled.span<{ color?: keyof typeof colors }>`
  ${({ theme, color }) => (color ? `color: ${theme.palette[color]};` : '')}
`
