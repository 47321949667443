import { DocumentPickerResponse } from 'react-native-document-picker'

import { FileForFormData } from '../../types/formData'

export const convertToFormData = (obj: {
  [key: string]: string | File | FileForFormData | DocumentPickerResponse | undefined
}) => {
  const formData = new FormData()

  Object.keys(obj).forEach((key) => {
    const value = obj[key] as FileForFormData

    if (value) {
      if (typeof value === 'object') {
        if (!value.fileCopyUri && !value.uri) {
          return
        }

        formData.append(key, value as File)
      } else {
        formData.append(key, value)
      }
    }
  })

  return formData
}
