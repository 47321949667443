import { combineReducers } from 'redux'

import { upload } from './upload/upload.reducer'
import { reupload } from './reupload/reupload.reducer'
import { preview } from './preview/preview.reducer'
import { folders } from './folders/folders.reducer'
import { recipients } from './recipients/recipients.reducer'
import { contractsList } from './contractsList/contractsList.reducer'
import { placement } from './placement/placement.reducer'
import { signing } from './signing/signing.reducer'
import { ContractsState } from './contracts.types'
import { download } from './download/download.reducer'
import { voidContract } from './void/void.reducer'
import { ownerView } from './ownerView/ownerView.reducer'
import { saveAsTemplate } from './saveAsTemplate/saveAsTemplate.reducer'
import { briefList } from './briefList/briefList.reducer'
import { message } from './message/message.reducer'
import { otpVerification } from './otpVerification/otpVerification.reducer'
import { verification } from './verification/verification.reducer'
import { noneVerification } from './noneVerification/noneVerification.reducer'
import { strikes } from './strikes/strikes.reducer'
import { corrections } from './corrections/corrections.reducer'
import { comments } from './comments/comments.reducer'
import { history } from './history/history.reducer'
import { viewOptions } from './viewOptions/viewOptions.reducer'
import { clearAndContinue } from './clearAndContinue/clearAndContinue.reducer'

export const contracts = combineReducers<ContractsState>({
  upload,
  reupload,
  preview,
  folders,
  recipients,
  contractsList,
  placement,
  signing,
  download,
  void: voidContract,
  ownerView,
  saveAsTemplate,
  briefList,
  message,
  otpVerification,
  verification,
  noneVerification,
  strikes,
  corrections,
  comments,
  history,
  viewOptions,
  clearAndContinue,
})
