import React from 'react'
import styled from 'styled-components'

import { languageMenu } from '../../routes/menus'
import DropdownButton from '../DropdownButton'

import { LangMenuProps } from './LangMenu.types'

const StyledText = styled.div`
  text-transform: uppercase;
  line-height: 1;
`

export const LangMenu: React.FC<LangMenuProps> = ({ lang }) => (
  <DropdownButton items={languageMenu}>
    <StyledText>{lang}</StyledText>
  </DropdownButton>
)
