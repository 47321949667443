import { combineReducers } from 'redux'

import { auth } from './auth/auth.reducer'
import { modals } from './modals/modals.reducer'
import { banner } from './banner/banner.reducer'
import { RootState } from './rootReducer.types'
import { countries } from './countries/countries.reducer'
import { phones } from './phones/phones.reducer'
import { industries } from './industries/industries.reducer'
import { registration } from './registration/registration.reducer'
import { forgotPassword } from './forgotPassword/forgotPassword.reducer'
import { restorePassword } from './restorePassword/restorePassword.reducer'
import { changePassword } from './changePassword/changePassword.reducer'
import { editProfile } from './editProfile/editProfile.reducer'
import { verifyPassword } from './verifyPassword/verifyPassword.reducer'
import { contacts } from './contacts/contacts.reducer'
import { signatures } from './signatures/signatures.reducer'
import { contracts } from './contracts/contracts.reducer'
import { templates } from './templates/templates.reducer'
import { ekyc } from './ekyc/ekyc.reducer'
import { subscription } from './subscription/subscription.reducer'
import { apiIntegration } from './apiIntegration/apiIntegration.reducer'
import { preferences } from './preferences/preferences.reducer'
import { userNotifications } from './userNotifications/userNotifications.reducer'
import { progress } from './progress/progress.reducer'
import { language } from './language/language.reducer'

export default combineReducers<RootState>({
  auth,
  modals,
  banner,
  progress,
  countries,
  phones,
  industries,
  registration,
  forgotPassword,
  restorePassword,
  changePassword,
  editProfile,
  verifyPassword,
  contacts,
  signatures,
  contracts,
  templates,
  ekyc,
  subscription,
  apiIntegration,
  preferences,
  userNotifications,
  language,
})
