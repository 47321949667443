import { useMemo } from 'react'
import { useHistory, useParams } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'

import translations from '../../../../translations/keys'
import { ContractRouteParams } from '../../../types/signing'
import { location } from '../../../../utils/location'
import { EyeIcon } from '../../../ui/InputIcons/EyeIcon'
import { FileIcon } from '../../../assets/icons'
import { openModal } from '../../../../store/modals/modals.actions'
import { CONTRACTS_MODALS } from '../../../../constants/contracts'
import * as SELECTORS from '../../../../store/contracts/recipients/recipients.selectors'
import { useAccessFeature } from '../../../../hooks/subscription/useAccessFeature'
import { SubscriptionFeatures } from '../../../../types/subscription'

export const useActions = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { contractId } = useParams<ContractRouteParams>()
  const contractName = useSelector(SELECTORS.recipientsContractNameSelector)
  const { checkAccess } = useAccessFeature()

  const actions = useMemo(
    () => [
      {
        buttonIcon: 'eye',
        label: translations.VIEW_DOCUMENT,
        onClick: () => {
          history.push(location.contractsPreviewUrl(contractId))
        },
        MobileIcon: EyeIcon,
      },
      {
        buttonIcon: 'file',
        label: translations.CONTRACT_SAVE_AS_TEMPLATE,
        onClick: () => {
          if (checkAccess(SubscriptionFeatures.FEATURE_USER_TEMPLATES)) {
            dispatch(openModal(CONTRACTS_MODALS.SAVE_CONTRACT_AS_TEMPLATE, { id: contractId, oldName: contractName }))
          }
        },
        MobileIcon: FileIcon,
      },
    ],
    [contractId, contractName]
  )

  return actions
}
