import React, { FC } from 'react'
import styled from 'styled-components'

import { LogoIcon } from '../../../assets/LogoIcon'
import Typography from '../../../ui/Typography'
import { TYPOGRAPHY_COMPONENTS, TYPOGRAPHY_NAMES } from '../../../ui/Typography/Typography.types'
import Box from '../../../ui/Box'

import { AuthorizationBoxProps } from './AuthorizationBox.types'

const StyledWrapper = styled(Box).attrs({
  mt: 3,
  mtMobile: 0,
  display: 'flex',
  alignItems: 'center',
})``
const StyledContent = styled(Box)`
  ${({ theme }) => `background-color: ${theme.palette.white};`}
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  ${({ theme }) => `@media ${theme.devices.mobile} {
    padding-top: 47px;
    border-radius: 0;
    box-shadow: none;
  }`}
`
const StyledLogoWrapper = styled.div`
  margin-bottom: 36px;
`

export const AuthorizationBox: FC<AuthorizationBoxProps> = ({ children, title, noLogo, pt = 8 }) => (
  <StyledWrapper data-testid="authorizationBox-wrapper">
    <StyledContent pt={pt}>
      {!noLogo && (
        <StyledLogoWrapper>
          <LogoIcon data-testid="authorizationBox-logoIcon" />
        </StyledLogoWrapper>
      )}
      {title && (
        <Typography
          data-testid="authorizationBox-wrapper-title"
          name={TYPOGRAPHY_NAMES.H1}
          component={TYPOGRAPHY_COMPONENTS.h1}
        >
          {title}
        </Typography>
      )}
      {children}
    </StyledContent>
  </StyledWrapper>
)
