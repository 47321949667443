import { FetchFailureAction, FETCH_STATUSES } from '../../../types/fetch'
import { createReducer } from '../../createReducer'

import * as TYPES from './favorite.types'
import * as ACTIONS from './favorite.actions'

type Actions = FetchFailureAction

export const initialState: TYPES.FavoriteState = {
  fetchStatus: FETCH_STATUSES.IDLE,
  error: null,
}

export const favorite = createReducer<TYPES.FavoriteState, Actions>(initialState, {
  [ACTIONS.fetchFavoriteTypes.request]: (state) => ({
    ...state,
    fetchStatus: FETCH_STATUSES.REQUEST,
  }),
  [ACTIONS.fetchFavoriteTypes.success]: (state) => ({
    ...state,
    fetchStatus: FETCH_STATUSES.SUCCESS,
  }),
  [ACTIONS.fetchFavoriteTypes.failure]: (state, action) => ({
    ...state,
    fetchStatus: FETCH_STATUSES.FAILURE,
    error: (action as FetchFailureAction).payload,
  }),
})
