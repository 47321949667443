import React, { useMemo } from 'react'
import { max } from 'date-fns'
import { useTranslation } from 'react-i18next'

import { CheckCircleIcon } from '../../../assets/icons'
import translations from '../../../../translations/keys'
import Box from '../../Box'
import Typography from '../../Typography'
import { TYPOGRAPHY_NAMES } from '../../Typography/Typography.types'
import { getDeadlineDays, getFormattedDate } from '../../../../utils/date'

import { TotalProps } from './Total.types'
import * as Styled from './Total.styles'

export const Total: React.FC<TotalProps> = ({ recipients }) => {
  const { t } = useTranslation()
  const finalSLA = useMemo(() => {
    const deadlines = Object.values(recipients)
      .map((recipient) => recipient.deadline)
      .filter(Boolean) as Date[]

    return deadlines.length ? max(deadlines) : null
  }, [recipients])
  const deadline = useMemo(() => finalSLA && getDeadlineDays(finalSLA), [finalSLA])

  return (
    <Box data-testid="total-div" display="flex" justifyContent="center">
      <Box width={240}>
        <Styled.Completed data-testid="total-completed">
          <CheckCircleIcon data-testid="total-completed-icon" color="green" />
          <Box ml={1}>
            <Typography data-testid="total-completed-typography" name={TYPOGRAPHY_NAMES.tableColumn}>
              {t(translations.COMPLETED)}
            </Typography>
          </Box>
        </Styled.Completed>
        {finalSLA && (
          <>
            <Box display="flex" justifyContent="space-between" mb={2}>
              <Typography data-testid="total-completed-deadline" name={TYPOGRAPHY_NAMES.H4}>
                {t(translations.CONTRACT_DEADLINE)}:{' '}
              </Typography>
              <Typography data-testid="total-completed-deadline-value" name={TYPOGRAPHY_NAMES.body16}>
                {deadline} {deadline === 1 ? translations.DAY : translations.DAYS}
              </Typography>
            </Box>
            <Box display="flex" justifyContent="space-between">
              <Typography data-testid="total-completed-sla" name={TYPOGRAPHY_NAMES.H4}>
                {t(translations.FINAL_SLA)}:{' '}
              </Typography>
              <Typography data-testid="total-completed-sla-value" name={TYPOGRAPHY_NAMES.body16}>
                {getFormattedDate(finalSLA)}
              </Typography>
            </Box>
          </>
        )}
      </Box>
    </Box>
  )
}
