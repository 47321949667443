import React from 'react'

import translations from '../../../../../translations/keys'
import { NOTIFICATION_MODAL_TYPES } from '../../../../ui/Modals/NotificationModal/NotificationModal.constants'
import { NotificationModalView } from '../../../../ui/Modals/NotificationModal/NotificationModalView'

import { SaveConfirmModalProps } from './SaveConfirmModal.types'

export const SaveConfirmModal: React.FC<SaveConfirmModalProps> = ({ onConfirmUpdate, onClose }) => {
  const handleSuccess = () => {
    onConfirmUpdate()
    onClose()
  }

  return (
    <NotificationModalView
      type={NOTIFICATION_MODAL_TYPES.INFO}
      title={translations.SAVE_RECIPIENT_FOR_RESEND_CONFIRMATION}
      onSuccess={handleSuccess}
      onClose={onClose}
    />
  )
}
