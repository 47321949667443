import React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { clearSuccessMessage } from '../../../store/contracts/preview/preview.actions'
import * as SELECTORS from '../../../store/contracts/preview/preview.selectors'
import { openModal } from '../../../store/modals/modals.actions'
import { CONTRACT_PREVIEW_MODALS } from '../../constants/contractPreview'
import translations from '../../../translations/keys'
import { useContractPreviewFetch } from '../../../hooks/contractPreview/useContractPreviewFetch'
import { useShowFetchError } from '../../hooks/useShowFetchError'
import { removeExtension } from '../../../utils/file/removeExtension'
import Box from '../../ui/Box'
import NotificationBadge from '../../ui/NotificationBadge'
import DocViewer from '../../ui/DocViewer'
import { PreviewHeader } from '../../components/PreviewHeader/PreviewHeader'
import FullScreenTemplate from '../../components/Main/FullScreenTemplate'
import { BUTTON_VARIANTS } from '../../ui/Button/Button.types'
import { FileIcon, PlayIcon, SaveIcon } from '../../assets/icons'
import { location } from '../../../utils/location'
import { ContractRouteParams } from '../../types/signing'
import { CONTRACTS_MODALS } from '../../../constants/contracts'
import { useAccessFeature } from '../../../hooks/subscription/useAccessFeature'
import { SubscriptionFeatures } from '../../../types/subscription'

import { ContractPreviewModals } from './components/ContractPreviewModals'
import * as Styled from './ContractPreview.styles'

export const ContractPreview = () => {
  const history = useHistory()
  const { t } = useTranslation()
  const { contractId } = useParams<ContractRouteParams>()
  const dispatch = useDispatch()
  const fetchStatus = useSelector(SELECTORS.previewFetchStatusSelector)
  const successMessage = useSelector(SELECTORS.previewSuccessMessageSelector)
  const error = useSelector(SELECTORS.previewErrorSelector)
  const data = useSelector(SELECTORS.previewDataSelector)
  const { checkAccess } = useAccessFeature()

  const handleNotificationBadgeClose = () => {
    dispatch(clearSuccessMessage())
  }

  const handleStartCycle = () => {
    dispatch(openModal(CONTRACT_PREVIEW_MODALS.SIGNING_ORDER_MODAL))
  }

  // TODO: implement real action 'onClick'
  const actions = [
    // TODO UQD-1410 uncomment when this will work
    // {
    //   buttonIcon: 'signature',
    //   label: translations.CONTRACT_ASSIGN_TO_SELF,
    //   onClick: () => {},
    //   MobileIcon: UserIcon,
    // },
    {
      buttonIcon: 'file',
      label: t(translations.CONTRACT_SAVE_AS_TEMPLATE),
      onClick: () => {
        if (checkAccess(SubscriptionFeatures.FEATURE_USER_TEMPLATES)) {
          dispatch(openModal(CONTRACTS_MODALS.SAVE_CONTRACT_AS_TEMPLATE, { id: contractId, oldName: data?.name }))
        }
      },
      MobileIcon: FileIcon,
    },
    // TODO UQD-1410 uncomment when this will work
    // {
    //   buttonIcon: 'delete',
    //   label: translations.CONTRACT_DELETE_AND_CLOSE,
    //   onClick: () => {},
    //   MobileIcon: TrashIcon,
    // },
  ]
  const buttons = [
    {
      label: t(translations.CONTRACT_SAVE_AND_CLOSE),
      onClick: () => {
        history.push(location.contractsAllUrl())
      },
      variant: BUTTON_VARIANTS.SECONDARY,
      MobileIcon: SaveIcon,
    },
    {
      label: t(translations.PROCEED_TO_CLM),
      onClick: handleStartCycle,
      MobileIcon: PlayIcon,
    },
  ]

  useContractPreviewFetch(contractId)
  useShowFetchError(fetchStatus, error)

  return (
    <FullScreenTemplate
      headerContent={<PreviewHeader actions={actions} buttons={buttons} title={removeExtension(data?.name || '')} />}
    >
      <Styled.Container test-id="contract-preview-container">
        {data && <DocViewer pages={data.pages} />}
        {successMessage && (
          <Styled.MessageContainer test-id="contracts-preview-message-container">
            <Box ml={4} mt={4}>
              <NotificationBadge
                isShow
                isAutoClose
                message={t(translations[successMessage])}
                onClose={handleNotificationBadgeClose}
              />
            </Box>
          </Styled.MessageContainer>
        )}
      </Styled.Container>
      <ContractPreviewModals contractId={contractId} />
    </FullScreenTemplate>
  )
}
