import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { fetchGeneratePublicToken } from '../../../store/apiIntegration/apiIntegration.actions'
import { closeModal } from '../../../store/modals/modals.actions'
import { modalsVisibleSelector } from '../../../store/modals/modals.selectors'
import ConfirmGenerateModal from '../../components/Modals/ApiIntegrationModals/ConfirmGenerateModal'
import { API_INTEGRATION_MODALS } from '../../constants/apiGenerate'

export const ApiIntegrationModals: React.FC = () => {
  const dispatch = useDispatch()

  const showConfirmGenerateModal = useSelector(modalsVisibleSelector(API_INTEGRATION_MODALS.CONFIRM_GENERATE))
  const onCloseCancelChanges = () => {
    dispatch(closeModal(API_INTEGRATION_MODALS.CONFIRM_GENERATE))
  }
  const onCancelChangesSuccess = () => {
    onCloseCancelChanges()
    dispatch(fetchGeneratePublicToken())
  }

  return (
    <>
      {showConfirmGenerateModal && (
        <ConfirmGenerateModal onClose={onCloseCancelChanges} onSuccess={onCancelChangesSuccess} />
      )}
    </>
  )
}
