import React from 'react'
import { useTranslation } from 'react-i18next'

import translations from '../../../translations/keys'
import { BulletList } from '../../ui/BulletList'
import Typography from '../../ui/Typography'
import { TYPOGRAPHY_NAMES } from '../../ui/Typography/Typography.types'
import Box from '../../ui/Box'

import { StyledParagraph } from './LegalNotice.styles'

//TODO UQD-1702 add translation when text is agreed
export const LegalNotice = () => {
  const { t } = useTranslation()
  return (
    <Box display="flex" justifyContent="center">
      <Box width={704}>
        <Box mb={3}>
          <Typography name={TYPOGRAPHY_NAMES.H1}>{t(translations.LEGAL_NOTICE)}</Typography>
        </Box>
        <StyledParagraph>
          All organizations and individuals are governed by laws. For this reason, when misunderstandings and disputes
          arise, it is up to the displeased party to resort to the intervention of courts of law.
        </StyledParagraph>
        <StyledParagraph>
          This requires the disgruntled party to file a petition at a court of law and issue a legal notice to the other
          party before the matter is finalized in court.
        </StyledParagraph>

        <Box mb={3} mt={4}>
          <Typography name={TYPOGRAPHY_NAMES.H3}>What Is A Legal Notice?</Typography>
        </Box>
        <StyledParagraph>
          Legal notice is simply the requirement that a party must be furnished with sufficient knowledge concerning the
          legal processes that affect his rights and duties or obligations. In other words, it is a way of notifying
          individuals or organisations about a matter by using a method required by the law courts.
        </StyledParagraph>
        <StyledParagraph>
          A legal notice, served to a defendant, must contain all the facts and complaints made in the petition. This is
          to inform the defendant about what he is being accused of, and he (the defendant) is given a reasonable period
          to answer; informing the court whether he agrees to or disagrees with the facts stated there in.
        </StyledParagraph>

        <Box mt={3} mb={1}>
          <Typography name={TYPOGRAPHY_NAMES.bold14}>Who Can Serve a Legal Notice?</Typography>
        </Box>
        <StyledParagraph>
          There are various types of legal notices and anybody, if he has gone through the right legal procedures, has
          the right to issue a legal notice.
        </StyledParagraph>
        <StyledParagraph>
          There are legal procedures to be adhered to when serving a legal notice and any complainant who fails to
          follow those procedures does so at his own peril.
        </StyledParagraph>
        <StyledParagraph>
          After a petition has been filed, the court issues an order to serve legal notice if the court finds the
          petition reasonable.
        </StyledParagraph>

        <Box mt={3} mb={1}>
          <Typography name={TYPOGRAPHY_NAMES.bold14}>When is a legal notice said to be properly served?</Typography>
        </Box>
        <StyledParagraph>
          After having gone through the necessary court procedures to obtain a legal notice permit, the notice you serve
          the defendant with must contain all the complaints, accusations or charges that have been filed at the court
          and this legal notice must be personally served to the accused.
        </StyledParagraph>
        <StyledParagraph>
          In other words, the legal notice must be directly handed over to the accused and not to another person (a
          friend, relative or middleman).
        </StyledParagraph>

        <Box mb={3} mt={4}>
          <Typography name={TYPOGRAPHY_NAMES.H3}>
            What If A Legal Notice Is Not Served Or Is Improperly Served?
          </Typography>
        </Box>
        <BulletList
          items={[
            'A delay in the case.',
            'An already passed court order in your favour may be overturned, revoked or declared invalid.',
            'Additional costs or court charges may be incurred.',
          ]}
          mbItem={2}
        />

        <Box mt={5}>
          <Typography name={TYPOGRAPHY_NAMES.body14} color="grey">
            Date of Last Revision: October 22, 2020
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}
