import React from 'react'
import { Formik } from 'formik'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import translations from '../../../../../translations/keys'
import { SubscriptionSvg } from '../../../../assets/SubscriptionSvg'
import Box from '../../../../ui/Box'
import Modal from '../../../../components/Modal'
import Typography from '../../../../ui/Typography'
import { TYPOGRAPHY_NAMES } from '../../../../ui/Typography/Typography.types'
import Button from '../../../../ui/Button'
import { subscriptionTermInitialValues } from '../../../../../initialValues/subscriptionTermInitialValues'
import FormRadioButton from '../../../../ui/Forms/FormRadioButton'
import { SubscriptionTermFields, SubscriptionTermForm } from '../../../../../types/subscriptionTerm'
import { SubscriptionPaymentTerms } from '../../../../../types/subscriptionPayment'
import { modalsPayloadSelector } from '../../../../../store/modals/modals.selectors'
import { SELECT_SUBSCRIPTION_MODALS } from '../../../../../constants/selectSubscription'
import { SubscriptionPlan } from '../../../../../types/subscription'
import { location } from '../../../../../utils/location'
import { CardSpecificationTypes } from '../../../../../types/cardSpecification'

import { SelectTermModalProps } from './SelectTermModal.types'
import { StyleForm } from './SelectTermModal.styles'

export const SelectTermModal: React.FC<SelectTermModalProps> = ({ onClose }) => {
  const history = useHistory()

  const { plan }: { plan: SubscriptionPlan } = useSelector(
    modalsPayloadSelector(SELECT_SUBSCRIPTION_MODALS.SELECT_SUBSCRIPTION_TERM_MODAL)
  )

  const renderLabel = (term: SubscriptionPaymentTerms) => () => {
    const isYear = term === SubscriptionPaymentTerms.YEAR
    const termText = isYear ? translations.YEARLY : translations.MONTHLY
    const priceTermText = isYear ? translations.YEAR : translations.MO
    const price = isYear ? plan.yearPrice : plan.monthPrice

    return (
      <Box ml={1}>
        {`${plan.name} ${translations.PLAN} ${termText} - `}
        <Typography name={TYPOGRAPHY_NAMES.bold14}>
          ${price}/{priceTermText}
        </Typography>
      </Box>
    )
  }

  const renderForm = () => (
    <StyleForm>
      <Box display="flex" flexDirection="column">
        <FormRadioButton
          name={SubscriptionTermFields.term}
          label=""
          renderLabel={renderLabel(SubscriptionPaymentTerms.MONTH)}
          id={SubscriptionPaymentTerms.MONTH}
        />
        <FormRadioButton
          name={SubscriptionTermFields.term}
          label=""
          renderLabel={renderLabel(SubscriptionPaymentTerms.YEAR)}
          id={SubscriptionPaymentTerms.YEAR}
        />
        <Button type="submit" fullWidth>
          {translations.SUBMIT}
        </Button>
      </Box>
    </StyleForm>
  )

  const onSubmit = (values: SubscriptionTermForm) => {
    history.push(
      location.cardSpecification(plan.id, {
        term: values.term,
        type: CardSpecificationTypes.PAYMENT,
      })
    )
    onClose()
  }

  return (
    <Modal onClose={onClose}>
      <Box display="flex" flexDirection="column" alignItems="center" width={320}>
        <SubscriptionSvg />
        <Box textAlign="center" mt={3} mb={2} width={240}>
          <Typography name={TYPOGRAPHY_NAMES.H1}>{translations.SELECT_SUBSCRIPTION_TERM}</Typography>
        </Box>
        <Formik initialValues={subscriptionTermInitialValues} onSubmit={onSubmit}>
          {renderForm}
        </Formik>
      </Box>
    </Modal>
  )
}
