import React, { forwardRef, MutableRefObject } from 'react'
import styled from 'styled-components'

import { KebabIcon } from '../../assets/icons'
import DropdownButton from '../DropdownButton'
import Box from '../Box'

import { TableActionsKebabProps } from './TableActions.types'

const StyledTrigger = styled.div`
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
`

export const TableActionsKebab = forwardRef<null, TableActionsKebabProps>(({ children, visible, onClick }, ref) => (
  <Box minWidth={24}>
    {onClick && (
      <StyledTrigger>
        <DropdownButton
          fullWidth
          hideTriangle
          targetRef={ref as MutableRefObject<null>}
          open={visible}
          onClick={onClick}
        >
          <KebabIcon color="grey" />
        </DropdownButton>
      </StyledTrigger>
    )}
    {children}
  </Box>
))
