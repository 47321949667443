export enum ContractHistoryAction {
  ACCEPTED = 'ACCEPTED',
  CANCELLED_ACCEPTATION = 'CANCELLED_ACCEPTATION',
  CANCELLED_REJECTION = 'CANCELLED_REJECTION',
  CLEARED_CORRECTIONS = 'CLEARED_CORRECTIONS',
  COMMENTED = 'COMMENTED',
  COMPLETED = 'COMPLETED',
  CROSSED_OVER = 'CROSSED_OVER',
  DECLINED = 'DECLINED',
  DELETED = 'DELETED',
  DOWNLOADED = 'DOWNLOADED',
  EXPIRED = 'EXPIRED',
  LEFT_CORRECTION = 'LEFT_CORRECTION',
  OPENED = 'OPENED',
  RECEIVED = 'RECEIVED',
  REJECTED = 'REJECTED',
  RENAMED = 'RENAMED',
  RESENT = 'RESENT',
  REUPLOADED = 'REUPLOADED',
  SENT = 'SENT',
  SIGNED = 'SIGNED',
  VERIFICATION_FAILED = 'VERIFICATION_FAILED',
  VOIDED = 'VOIDED',
}

export interface ContractHistoryItem {
  id: string
  createAt: string
  fullName: string
  email: string
  ip: string
  status: {
    action: ContractHistoryAction
    important: boolean
  }
}

export type ContractHistory = ContractHistoryItem[]
