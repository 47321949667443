import { useTheme } from 'styled-components'

import { getEnvs } from '../../../../../../../api/getEnvs'
import { StyledThemeScheme } from '../../../../../../../themes/light'

const { REACT_APP_SIGNATURE_PEN_WIDTH } = getEnvs()
const PEN_WIDTH = REACT_APP_SIGNATURE_PEN_WIDTH ? Number(REACT_APP_SIGNATURE_PEN_WIDTH) : 5

export const useOptions = () => {
  const theme = useTheme() as StyledThemeScheme

  return {
    minWidth: PEN_WIDTH,
    maxWidth: PEN_WIDTH,
    penColor: theme.palette.textColor,
    backgroundColor: 'transparent',
  }
}
