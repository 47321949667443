import React, { useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router'

import { SLASHES_DATE_FORMAT } from '../../../../../../constants/date'
import translations from '../../../../../../translations/keys'
import { Contract } from '../../../../../../types/contracts'
import { prepareStatus } from '../../../../../../utils/contracts/prepareStatus'
import { getFormattedDate } from '../../../../../../utils/date/date'
import highlightText from '../../../../../../utils/highlightText'
import { FolderIcon } from '../../../../../assets/icons'
import { MobileColumnOptions } from '../../../../../ui/MobileTable/MobileTable.types'
import { HighlightedText } from '../../../../../ui/Table/Table.styles'
import { TableData } from '../../../../../ui/Table/Table.types'
import Typography from '../../../../../ui/Typography'
import { TYPOGRAPHY_NAMES } from '../../../../../ui/Typography/Typography.types'
import Box from '../../../../../ui/Box'

import { openContract } from './useActions'

export const useMobileColumn = () => {
  const history = useHistory()
  const dispatch = useDispatch()

  return useMemo(
    () => (data: TableData, { highlightedText, showFullInfo }: MobileColumnOptions) => {
      const contract = data as Contract
      const onClick = () => {
        openContract(dispatch, history, contract)
      }

      if (contract.isFolder) {
        return (
          <Box display="flex" alignItems="center" onClick={onClick}>
            <Box mr={1} display="flex" alignItems="center">
              <FolderIcon color="grey" />
            </Box>
            {highlightText(contract.name, highlightedText, HighlightedText, 'span')}
          </Box>
        )
      }

      return (
        <Box display="flex" flexDirection="column">
          <span onClick={onClick}>{highlightText(contract.name, highlightedText, HighlightedText, 'span')}</span>
          <Box mt={showFullInfo ? 1 : 0} mb={showFullInfo ? 1 : 0}>
            {prepareStatus(contract)}
          </Box>
          {showFullInfo ? (
            <Box display="flex">
              <Box display="flex" flexDirection="column">
                <Typography name={TYPOGRAPHY_NAMES.bodySmall12} color="grey">
                  {getFormattedDate(new Date(contract.createDate.date), SLASHES_DATE_FORMAT)}
                </Typography>
                <Typography name={TYPOGRAPHY_NAMES.body14}>{contract.createDate.userName}</Typography>
              </Box>
              {contract.updateDate && (
                <Box display="flex" flexDirection="column">
                  <Typography name={TYPOGRAPHY_NAMES.bodySmall12} color="grey">
                    {getFormattedDate(new Date(contract.updateDate.date), SLASHES_DATE_FORMAT)}
                  </Typography>
                  <Typography name={TYPOGRAPHY_NAMES.body14}>{contract.updateDate.userName}</Typography>
                </Box>
              )}
            </Box>
          ) : (
            contract.updateDate && (
              <Typography name={TYPOGRAPHY_NAMES.bodySmall12} color="grey">
                {translations.MODIFIED} {getFormattedDate(new Date(contract.updateDate.date), SLASHES_DATE_FORMAT)}
              </Typography>
            )
          )}
        </Box>
      )
    },
    []
  )
}
