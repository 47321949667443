import React from 'react'

import MainTemplate from '../../components/Main/MainTemplate'

import { ApiIntegration as ApiIntegrationPage } from './ApiIntegration'

export const ApiIntegration = () => (
  <MainTemplate>
    <ApiIntegrationPage />
  </MainTemplate>
)
