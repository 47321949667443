import React from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import translations from '../../../translations/keys'
import { ContractRouteParams } from '../../types/signing'
import { removeExtension } from '../../../utils/file/removeExtension'
import { useDispatchUnmount } from '../../../hooks/useDispatchUnmount'
import { useContractAnnotations } from '../../../hooks/contracts/useContractAnnotations'
import { useDownloadContract } from '../../hooks/contracts/useDownloadContract'
import { authUserSelector } from '../../../store/auth/auth.selectors'
import { useSigningNotifications } from '../../../hooks/signing/useSigningNotifications'
import { useSigningFetch } from '../../../hooks/signing/useSigningFetch'
import { useVerification } from '../../hooks/contractSign/useVerification'
import { useActions } from '../../hooks/contractSign/useActions'
import { useToolbarActions } from '../../hooks/contractSign/useToolbarActions'
import { useCommentsFetch } from '../../../hooks/contracts/useCommentsFetch'
import { useStrikesFetch } from '../../../hooks/contracts/useStrikesFetch'
import { useCorrectionsFetch } from '../../../hooks/contracts/useCorrectionsFetch'
import * as SIGNING_SELECTORS from '../../../store/contracts/signing/signing.selectors'
import * as RECIPIENTS_SELECTORS from '../../../store/contracts/recipients/recipients.selectors'
import * as PLACEMENT_SELECTORS from '../../../store/contracts/placement/placement.selectors'
import * as STRIKES_SELECTORS from '../../../store/contracts/strikes/strikes.selectors'
import * as CORRECTIONS_SELECTORS from '../../../store/contracts/corrections/corrections.selectors'
import * as COMMENTS_SELECTORS from '../../../store/contracts/comments/comments.selectors'
import { clearSigningSuccessMessage } from '../../../store/contracts/signing/signing.actions'
import { viewOptionsIsSignatureNamesVisibleSelector } from '../../../store/contracts/viewOptions/viewOptions.selectors'
import { cleanViewOptions } from '../../../store/contracts/viewOptions/viewOptions.actions'
import Box from '../../ui/Box'
import DocViewer from '../../ui/DocViewer'
import NotificationBadge from '../../ui/NotificationBadge'
import LogSidebar from '../../components/LogSidebar'
import RecipientsProgressModals from '../../components/Modals/RecipientsModals/RecipientsProgressModals'
import { PreviewHeader } from '../../components/PreviewHeader/PreviewHeader'
import FullScreenTemplate from '../../components/Main/FullScreenTemplate'
import CommentPopUp from '../CommentPopUp'

import { useMobileActions } from './hooks/useMobileActions'
import * as Styled from './ContractSign.styles'
import ContractSignModals from './components/ContractSignModals'
import { usePlacementById } from './hooks/usePlacementById'
import { useButtons } from './hooks/useButtons'
import { useErrors } from './hooks/useErrors'

export const ContractSign = () => {
  const { contractId } = useParams<ContractRouteParams>()
  const dispatch = useDispatch()
  const data = useSelector(SIGNING_SELECTORS.signingContractDocumentDataSelector)
  const recipientsById = useSelector(RECIPIENTS_SELECTORS.recipientsByIdSelector)
  const placementByPages = useSelector(PLACEMENT_SELECTORS.placementByPagesSelector)
  const scrollToPlacementId = useSelector(SIGNING_SELECTORS.signingScrollToSignPlacementId)
  const strikesById = useSelector(STRIKES_SELECTORS.strikesByIdSelector)
  const strikesByPages = useSelector(STRIKES_SELECTORS.strikesByPagesSelector)
  const correctionsById = useSelector(CORRECTIONS_SELECTORS.correctionsByIdSelector)
  const correctionsByPages = useSelector(CORRECTIONS_SELECTORS.correctionsByPagesSelector)
  const commentsById = useSelector(COMMENTS_SELECTORS.commentsByIdSelector)
  const commentsByPages = useSelector(COMMENTS_SELECTORS.commentsByPagesSelector)
  const commentThreadLoading = useSelector(COMMENTS_SELECTORS.commentsFetchThreadRequestSelector)
  const commentThread = useSelector(COMMENTS_SELECTORS.commentsThreadSelector)
  const currentUser = useSelector(authUserSelector)
  const notification = useSelector(SIGNING_SELECTORS.signingNotificationSelector)
  const isSignatureNamesVisible = useSelector(viewOptionsIsSignatureNamesVisibleSelector)
  const title = removeExtension(data?.contractName || '')
  const placementById = usePlacementById()
  const actions = useActions()
  const buttons = useButtons()
  const toolbarActions = useToolbarActions()
  const mobileActions = useMobileActions()
  const {
    selectedComment,
    handleElemChange,
    handleElemCreate,
    handleElemClick,
    handleElemBlur,
    handleElemSave,
    handleElemDelete,
    handleFieldChange,
    handleCommentSubmit,
    handleCommentPopoverClose,
    handleElemConfirm,
    handleElemCancelConfirmation,
  } = useContractAnnotations(contractId)

  const handleNotificationBadgeClose = () => {
    dispatch(clearSigningSuccessMessage())
  }

  useSigningFetch(contractId)
  useSigningNotifications()
  useDownloadContract(contractId)
  useVerification(contractId)
  useCommentsFetch(contractId)
  useStrikesFetch(contractId)
  useCorrectionsFetch(contractId)
  useErrors()
  useDispatchUnmount(cleanViewOptions)

  return (
    <FullScreenTemplate
      sidebarContent={<LogSidebar />}
      headerContent={<PreviewHeader actions={actions} buttons={buttons} title={title} />}
    >
      <Styled.Container test-id="contract-signing-container">
        {data && (
          <DocViewer
            isSignatureEditable={false}
            pages={data.pages}
            placementById={placementById}
            placementByPages={placementByPages}
            strikesById={strikesById}
            strikesByPages={strikesByPages}
            correctionsById={correctionsById}
            correctionsByPages={correctionsByPages}
            commentsById={commentsById}
            commentsByPages={commentsByPages}
            scrollToElemId={scrollToPlacementId}
            actions={toolbarActions}
            isSignatureNamesVisible={isSignatureNamesVisible}
            onElemChange={handleElemChange}
            onElemCreate={handleElemCreate}
            onElemClick={handleElemClick}
            onElemBlur={handleElemBlur}
            onElemSave={handleElemSave}
            onElemDelete={handleElemDelete}
            onFieldChange={handleFieldChange}
            onElemConfirm={handleElemConfirm}
            mobileActions={mobileActions}
          />
        )}
        {notification && (
          <Styled.MessageContainer test-id="contracts-preview-message-container">
            <Box ml={4} mt={4}>
              <NotificationBadge
                isShow
                isAutoClose
                message={translations[notification.message]}
                onClose={handleNotificationBadgeClose}
                variant={notification.variant}
              />
            </Box>
          </Styled.MessageContainer>
        )}
      </Styled.Container>
      {selectedComment && (
        <CommentPopUp
          isContractOwner={false}
          targetRef={selectedComment.elem}
          comment={selectedComment.comment}
          thread={commentThread}
          recipients={recipientsById}
          currentUser={currentUser}
          loading={commentThreadLoading}
          onSubmit={handleCommentSubmit}
          onClose={handleCommentPopoverClose}
          onElemConfirm={handleElemConfirm}
          onElemCancelConfirmation={handleElemCancelConfirmation}
          onModalClose={handleCommentPopoverClose}
        />
      )}
      <ContractSignModals />
      <RecipientsProgressModals />
    </FullScreenTemplate>
  )
}
