import { DocumentMessageBody } from '../../../types/documents'
import { FetchFailureAction, FetchFailurePayload } from '../../../types/fetch'
import { createFetchActionTypes } from '../../../utils/createFetchActionTypes'

import * as TYPES from './message.types'

export const fetchSaveContractMessageTypes = createFetchActionTypes(TYPES.MessageActions.FETCH_SAVE_CONTRACT_MESSAGE)

export const fetchSaveContractMessage = (
  contractId: string,
  body: DocumentMessageBody
): TYPES.FetchSaveContractMessage => ({
  type: fetchSaveContractMessageTypes.request,
  payload: { contractId, body },
})
export const fetchSaveContractMessageSuccess = (): TYPES.FetchSaveContractMessageSuccess => ({
  type: fetchSaveContractMessageTypes.success,
})
export const fetchSaveContractMessageFailure = (payload: FetchFailurePayload): FetchFailureAction => ({
  type: fetchSaveContractMessageTypes.failure,
  payload,
})

export const clearContractMessage = (): TYPES.ClearContractMessageAction => ({
  type: TYPES.MessageActions.CLEAR_CONTRACT_MESSAGE,
})
