import { TemplatesList, TemplatesListResponse } from '../../types/templates'
import { User } from '../../types/user'

export const prepareTemplatesList = (response: TemplatesListResponse, user: User | null): TemplatesList => {
  const userData = {
    userEmail: user?.email || '',
    userName: `${user?.lastName} ${user?.firstName}`,
  }

  return {
    ...response,
    data: response.data.map((item) => ({
      ...item,
      createDate: item.createDate
        ? {
            ...userData,
            date: item.createDate,
          }
        : null,
      updateDate: item.updateDate
        ? {
            ...userData,
            date: item.updateDate,
          }
        : null,
    })),
  }
}
