import { FETCH_STATUSES } from '../../../types/fetch'
import { RootState } from '../../rootReducer.types'

export const createFromContractIsSuccessSelector = (state: RootState) =>
  state.templates.createFromContract.fetchStatus === FETCH_STATUSES.SUCCESS

export const createFromContractFetchStatusSelector = (state: RootState) =>
  state.templates.createFromContract.fetchStatus

export const createFromContractErrorSelector = (state: RootState) => state.templates.createFromContract.error

export const createFromContractIdSelector = (state: RootState) => state.templates.createFromContract.id
