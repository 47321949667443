import React from 'react'

export const PasswordIcon: React.FC = (props) => (
  <svg width="156" height="96" viewBox="0 0 156 96" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M115 72H41C40.4477 72 40 72.4477 40 73V91C40 91.5523 40.4477 92 41 92H115C115.552 92 116 91.5523 116 91V73C116 72.4477 115.552 72 115 72ZM41 70C39.3431 70 38 71.3431 38 73V91C38 92.6569 39.3431 94 41 94H115C116.657 94 118 92.6569 118 91V73C118 71.3431 116.657 70 115 70H41Z"
      fill="#DFE1E6"
    />
    <path
      d="M75.046 43.5213L74.2575 50.6123C74.2184 50.965 74.3319 51.3191 74.5687 51.584C74.8055 51.8489 75.1449 52.0003 75.5001 52.0003H80.5001C80.8553 52.0003 81.1947 51.8489 81.4316 51.584C81.6684 51.3192 81.7819 50.9652 81.7428 50.6123L80.9543 43.5213C82.2348 42.5899 83.0001 41.1165 83.0001 39.5002C83.0001 36.7427 80.7577 34.5002 78.0001 34.5002C75.2424 34.5002 73 36.7427 73 39.5002C73 41.1165 73.7655 42.5899 75.046 43.5213ZM78.0001 37.0003C79.3782 37.0003 80.5 38.1221 80.5 39.5002C80.5 40.4585 79.9556 41.3166 79.0791 41.7415C78.6018 41.9734 78.3223 42.4788 78.3809 43.0048L79.1036 49.5002H76.8966L77.6193 43.0048C77.6779 42.4787 77.3984 41.9734 76.9211 41.7415C76.0447 41.3166 75.5002 40.4585 75.5002 39.5002C75.5001 38.1221 76.6219 37.0003 78.0001 37.0003Z"
      fill="#6085B3"
    />
    <path
      d="M99.25 24.5H95.5V19.5C95.5 9.85039 87.6497 2 78 2C68.3503 2 60.5 9.85039 60.5 19.5V24.5H56.75C56.0591 24.5 55.5 25.0591 55.5 25.75V57.0001C55.5 59.7576 57.7424 62 60.5 62H95.5001C98.2576 62 100.5 59.7576 100.5 57V25.75C100.5 25.0591 99.9409 24.5 99.25 24.5ZM63 19.5C63 11.2285 69.7286 4.49996 78 4.49996C86.2714 4.49996 93 11.2285 93 19.5V24.5H90.5V19.5C90.5 12.6078 84.8921 6.99992 78 6.99992C71.1079 6.99992 65.5 12.6079 65.5 19.5V24.5H63V19.5ZM88 19.5V24.5H68V19.5C68 13.9861 72.4861 9.5 78 9.5C83.5139 9.5 88 13.9861 88 19.5ZM98 57C98 58.3781 96.8782 59.4999 95.5001 59.4999H60.5C59.1219 59.4999 58.0001 58.3781 58.0001 57V27H98V57Z"
      fill="#6085B3"
    />
    <path
      d="M59.1874 83.6619L58.3483 84.9002L55.8065 83.165L55.8961 86H54.2668L54.3157 83.2464L51.831 84.9083L51 83.6538L53.8024 82L51.0163 80.3299L51.831 79.0754L54.3157 80.7617L54.2668 78H55.888L55.8391 80.7617L58.3483 79.0835L59.1874 80.3381L56.3686 81.9919L59.1874 83.6619Z"
      fill="#6085B3"
    />
    <path
      d="M74.6986 83.6619L73.8595 84.9002L71.3177 83.165L71.4073 86H69.778L69.8269 83.2464L67.3422 84.9083L66.5112 83.6538L69.3136 82L66.5275 80.3299L67.3422 79.0754L69.8269 80.7617L69.778 78H71.3992L71.3503 80.7617L73.8595 79.0835L74.6986 80.3381L71.8798 81.9919L74.6986 83.6619Z"
      fill="#6085B3"
    />
    <path
      d="M90.2098 83.6619L89.3707 84.9002L86.8289 83.165L86.9185 86H85.2892L85.3381 83.2464L82.8534 84.9083L82.0224 83.6538L84.8248 82L82.0387 80.3299L82.8534 79.0754L85.3381 80.7617L85.2892 78H86.9104L86.8615 80.7617L89.3707 79.0835L90.2098 80.3381L87.391 81.9919L90.2098 83.6619Z"
      fill="#6085B3"
    />
    <path
      d="M105.721 83.6619L104.882 84.9002L102.34 83.165L102.43 86H100.8L100.849 83.2464L98.3646 84.9083L97.5336 83.6538L100.336 82L97.5499 80.3299L98.3646 79.0754L100.849 80.7617L100.8 78H102.422L102.373 80.7617L104.882 79.0835L105.721 80.3381L102.902 81.9919L105.721 83.6619Z"
      fill="#6085B3"
    />
  </svg>
)
