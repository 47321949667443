import { FetchFailureAction, FETCH_STATUSES } from '../../types/fetch'
import { AuthActions } from '../auth/auth.types'
import { createReducer } from '../createReducer'

import * as ACTIONS from './userNotifications.actions'
import * as TYPES from './userNotifications.types'

type Actions =
  | TYPES.FetchUserNotificationsSuccessAction
  | FetchFailureAction
  | TYPES.SetOffsetAction
  | TYPES.ReadNotificationsSuccessAction
  | TYPES.FetchUnreadCountSuccessAction
  | TYPES.AddUserNotificationAction

const DEFAULT_LIMIT = 5

export const initialState: TYPES.UserNotificationsState = {
  fetchStatus: FETCH_STATUSES.IDLE,
  error: null,
  data: [],
  limit: DEFAULT_LIMIT,
  offset: 0,
  total: 0,
  read: {
    fetchStatus: FETCH_STATUSES.IDLE,
    error: null,
  },
  unread: {
    count: 0,
    fetchStatus: FETCH_STATUSES.IDLE,
    error: null,
  },
}

export const userNotifications = createReducer<TYPES.UserNotificationsState, Actions>(initialState, {
  [ACTIONS.fetchUserNotificationsTypes.request]: (state) => ({
    ...state,
    fetchStatus: FETCH_STATUSES.REQUEST,
  }),
  [ACTIONS.fetchUserNotificationsTypes.success]: (state, action) => {
    const { notifications } = (action as TYPES.FetchUserNotificationsSuccessAction).payload

    return {
      ...state,
      fetchStatus: FETCH_STATUSES.SUCCESS,
      data: [...state.data, ...notifications.data],
      total: state.total || notifications.totalCount,
    }
  },
  [ACTIONS.fetchUserNotificationsTypes.failure]: (state, action) => ({
    ...state,
    fetchStatus: FETCH_STATUSES.FAILURE,
    error: (action as FetchFailureAction).payload,
  }),
  [TYPES.UserNotificationsActions.USER_NOTIFICATIONS_SET_OFFSET]: (state, action) => ({
    ...state,
    offset: (action as TYPES.SetOffsetAction).payload.offset,
  }),
  [AuthActions.CLEAN_AUTH]: () => ({ ...initialState }),
  [ACTIONS.fetchReadNotificationsTypes.request]: (state) => ({
    ...state,
    read: {
      ...state.read,
      fetchStatus: FETCH_STATUSES.REQUEST,
    },
  }),
  [ACTIONS.fetchReadNotificationsTypes.success]: (state, action) => {
    const {
      body: { notifications },
    } = (action as TYPES.ReadNotificationsSuccessAction).payload

    return {
      ...state,
      data: state.data.map((notification) =>
        notifications.includes(notification.id)
          ? {
              ...notification,
              read: true,
            }
          : notification
      ),
      read: {
        ...state.read,
        fetchStatus: FETCH_STATUSES.SUCCESS,
      },
      unread: {
        ...state.unread,
        count: state.unread.count - notifications.length,
      },
    }
  },
  [ACTIONS.fetchReadNotificationsTypes.failure]: (state, action) => ({
    ...state,
    read: {
      ...state.read,
      fetchStatus: FETCH_STATUSES.FAILURE,
      error: (action as FetchFailureAction).payload,
    },
  }),
  [ACTIONS.fetchUnreadCountTypes.request]: (state) => ({
    ...state,
    unread: {
      ...state.unread,
      fetchStatus: FETCH_STATUSES.REQUEST,
    },
  }),
  [ACTIONS.fetchUnreadCountTypes.success]: (state, action) => {
    const { unreadCount } = (action as TYPES.FetchUnreadCountSuccessAction).payload

    return {
      ...state,
      unread: {
        ...state.unread,
        fetchStatus: FETCH_STATUSES.SUCCESS,
        count: unreadCount,
      },
    }
  },
  [ACTIONS.fetchUnreadCountTypes.failure]: (state, action) => ({
    ...state,
    unread: {
      ...state.unread,
      fetchStatus: FETCH_STATUSES.FAILURE,
      error: (action as FetchFailureAction).payload,
    },
  }),
  [TYPES.UserNotificationsActions.ADD_USER_NOTIFICATION]: (state, action) => {
    const { notification } = (action as TYPES.AddUserNotificationAction).payload

    return {
      ...state,
      total: state.total + 1,
      data: [notification, ...state.data],
      unread: {
        ...state.unread,
        count: state.unread.count + 1,
      },
    }
  },
  [TYPES.UserNotificationsActions.CLEAR_NOTIFICATIONS]: (state) => ({
    ...state,
    data: initialState.data,
  }),
  [TYPES.UserNotificationsActions.CLEAR_NOTIFICATIONS_OFFSET]: (state) => ({
    ...state,
    offset: initialState.offset,
  }),
})
