import { EditableElem } from './editableElem'

export type PlacementById = { [id: string]: DocPlace }
export type PlacementByPages = { [id: string]: string[] }
export type PlacementSorted = string[]

// Percentage based on a single page of the design (968x1248)
export const PAGE_HOR_MARGIN = 0.02066
export const PAGE_VER_MARGIN = 0.01602
export const SIGNATURES_PER_PAGE = 4
export const SIGNATURES_BLOCK_WIDTH = 0.78512
export const SIGNATURES_BLOCK_LEFT_MARGIN = 0.0878
export const SIGNATURES_BLOCK_BOTTOM_MARGIN = 0.04807
export const SIGNATURE_RIGHT_MARGIN = 0.02368
export const SIGNATURE_WIDTH = SIGNATURES_BLOCK_WIDTH / SIGNATURES_PER_PAGE
export const SIGNATURE_HEIGHT_RATIO = 0.35955
export const INITIAL_WIDTH = 0.07438
export const INITIAL_HEIGHT_RATIO = 0.77775
export const INITIAL_MARGIN_BOTTOM = 0.01442

export type SignatureType = 'sign' | 'initial'

export interface Place extends EditableElem {
  recipientId: string
  page: number
  image?: string
  signatureId?: string
  timestamp?: string
}

export interface DocPlaceAction {
  name: string
  onClick: () => void
  icon: string
}

export interface DocPlace extends Place {
  type: SignatureType
  title: string
  subtitle: string
  caption?: string
  readonly?: boolean
  onSign?: () => void
  actions?: DocPlaceAction[]
}

export interface PlacementBody {
  displayNames: false
  recipients: {
    recipientId: string
    places: Place[]
  }[]
}
