import React, { useState } from 'react'

import Typography from '../Typography'
import { TYPOGRAPHY_COMPONENTS, TYPOGRAPHY_NAMES } from '../Typography/Typography.types'
import Box from '../Box'

import { StyledAccordion, StyledTitle, StyledTriangleDownIcon } from './Accordion.styles'
import { AccordionProps } from './Accordion.types'

export const Accordion: React.FC<AccordionProps> = (props) => {
  const { item } = props
  const [isShow, setIsShow] = useState(false)
  const handleToggle = () => {
    setIsShow(!isShow)
  }
  return (
    <StyledAccordion>
      <StyledTitle onClick={handleToggle}>
        <Typography name={TYPOGRAPHY_NAMES.H4} component={TYPOGRAPHY_COMPONENTS.h4}>
          {item.title}
        </Typography>
        <StyledTriangleDownIcon flip={isShow} color="grey" size="small" />
      </StyledTitle>
      {isShow && (
        <Box pr={2} pb={4} pl={2}>
          <Typography name={TYPOGRAPHY_NAMES.body14}>{item.description}</Typography>
        </Box>
      )}
    </StyledAccordion>
  )
}
