import { DuplicateDocumentRequestBody } from '../../../types/duplicateDocument'
import { FetchFailureAction, FetchFailurePayload } from '../../../types/fetch'
import { createFetchActionTypes } from '../../../utils/createFetchActionTypes'

import * as TYPES from './duplicate.types'

export const fetchDuplicateTemplateTypes = createFetchActionTypes(TYPES.DuplicateActions.DUPLICATE_TEMPLATE)

export const fetchDuplicateTemplate = (
  id: string,
  body: DuplicateDocumentRequestBody
): TYPES.FetchDuplicateTemplate => ({
  type: fetchDuplicateTemplateTypes.request,
  payload: { id, body },
})

export const fetchDuplicateTemplateSuccess = (): TYPES.FetchDuplicateTemplateSuccess => ({
  type: fetchDuplicateTemplateTypes.success,
})

export const fetchDuplicateTemplateFailure = (payload: FetchFailurePayload): FetchFailureAction => ({
  type: fetchDuplicateTemplateTypes.failure,
  payload,
})

export const clearDuplicateTemplate = (): TYPES.ClearDuplicateTemplate => ({
  type: TYPES.DuplicateActions.CLEAR_DUPLICATE_TEMPLATE,
})
