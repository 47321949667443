import { FetchFailureAction, FetchFailurePayload } from '../../types/fetch'
import { CreateSignatureBody, ValidateSignatureBody, Signature } from '../../types/signatures'
import { createFetchActionTypes } from '../../utils/createFetchActionTypes'

import * as TYPES from './signatures.types'

export const fetchValidateSignatureTypes = createFetchActionTypes(TYPES.SignaturesActions.FETCH_VALIDATE_SIGNATURE)
export const fetchCreateSignatureTypes = createFetchActionTypes(TYPES.SignaturesActions.FETCH_CREATE_SIGNATURE)
export const fetchSignaturesListTypes = createFetchActionTypes(TYPES.SignaturesActions.FETCH_SIGNATURES_LIST)
export const fetchDeleteSignatureTypes = createFetchActionTypes(TYPES.SignaturesActions.FETCH_DELETE_SIGNATURE)

export const fetchValidateSignature = (body: ValidateSignatureBody): TYPES.FetchValidateSignatureAction => ({
  type: fetchValidateSignatureTypes.request,
  payload: { body },
})
export const fetchValidateSignatureSuccess = (): TYPES.FetchValidateSignatureSuccessAction => ({
  type: fetchValidateSignatureTypes.success,
})
export const fetchValidateSignatureFailure = (payload: FetchFailurePayload): FetchFailureAction => ({
  type: fetchValidateSignatureTypes.failure,
  payload,
})
export const cleanValidateSignature = (): TYPES.CleanValidateSignature => ({
  type: TYPES.SignaturesActions.CLEAN_VALIDATE_SIGNATURE,
})

export const fetchCreateSignature = (body: CreateSignatureBody): TYPES.FetchCreateSignatureAction => ({
  type: fetchCreateSignatureTypes.request,
  payload: { body },
})
export const fetchCreateSignatureSuccess = (body: Signature): TYPES.FetchCreateSignatureSuccessAction => ({
  type: fetchCreateSignatureTypes.success,
  payload: { body },
})
export const fetchCreateSignatureFailure = (payload: FetchFailurePayload): FetchFailureAction => ({
  type: fetchCreateSignatureTypes.failure,
  payload,
})
export const cleanCreateSignature = (): TYPES.CleanCreateSignature => ({
  type: TYPES.SignaturesActions.CLEAN_CREATE_SIGNATURE,
})

export const fetchSignaturesList = (): TYPES.FetchSignaturesListAction => ({
  type: fetchSignaturesListTypes.request,
})
export const fetchSignaturesListSuccess = (body: Signature[]): TYPES.FetchSignaturesListSuccessAction => ({
  type: fetchSignaturesListTypes.success,
  payload: { body },
})
export const fetchSignaturesListFailure = (payload: FetchFailurePayload): FetchFailureAction => ({
  type: fetchSignaturesListTypes.failure,
  payload,
})
export const cleanSignaturesList = (): TYPES.CleanSignatureList => ({
  type: TYPES.SignaturesActions.CLEAN_SIGNATURES_LIST,
})

export const fetchDeleteSignature = (id: string): TYPES.FetchDeleteSignatureAction => ({
  type: fetchDeleteSignatureTypes.request,
  payload: { id },
})
export const fetchDeleteSignatureSuccess = (id: string): TYPES.FetchDeleteSuccessAction => ({
  type: fetchDeleteSignatureTypes.success,
  payload: { id },
})
export const fetchDeleteSignatureFailure = (payload: FetchFailurePayload): FetchFailureAction => ({
  type: fetchDeleteSignatureTypes.failure,
  payload,
})
export const cleanDeleteSignature = (): TYPES.CleanDeleteSignature => ({
  type: TYPES.SignaturesActions.CLEAN_DELETE_SIGNATURE,
})
