import { NEGATIVE_STATUSES } from '../../../constants/contracts'
import { CONTRACT_STATUS } from '../../../types/contracts'
import { FETCH_STATUSES } from '../../../types/fetch'
import { RecipientActions } from '../../../types/recipients'
import { RootState } from '../../rootReducer.types'

import { showViewPage } from './signing.utils'

export const signingContractDocumentDataSelector = (state: RootState) => state.contracts.signing.contract.data
export const signingContractFetchStatusSelector = (state: RootState) => state.contracts.signing.contract.fetchStatus
export const signingContractErrorSelector = (state: RootState) => state.contracts.signing.contract.error
export const signingContractSuccessSelector = (state: RootState) =>
  state.contracts.signing.contract.fetchStatus === FETCH_STATUSES.SUCCESS
export const signingContractFetchedFullySelector = (state: RootState) =>
  state.contracts.signing.contract.fetchStatus === FETCH_STATUSES.SUCCESS &&
  state.contracts.signing.totalPages.fetchStatus === FETCH_STATUSES.SUCCESS
export const signingIsFirstViewSelector = (state: RootState) =>
  state.contracts.signing.contract.data?.recipient.action === RecipientActions.VIEW &&
  !state.contracts.signing.contract.data?.recipient.signed
export const signingIsViewSelector = (state: RootState) =>
  state.contracts.signing.contract.data?.recipient.action === RecipientActions.VIEW
export const signingIsInitialsSelector = (state: RootState) =>
  state.contracts.signing.contract.data?.recipient.action === RecipientActions.INITIAL
export const signingIsViewPageSelector = (state: RootState) => showViewPage(state.contracts.signing.contract.data)
export const signingRecipientAuthentication = (state: RootState) =>
  state.contracts.signing.contract.data?.recipient.authentication

export const signingIsNegativeSelector = (state: RootState) => {
  const status = state.contracts.signing.contract.data?.contractStatus
  return status ? NEGATIVE_STATUSES.includes(status) : false
}
export const signingContractIsCompletedSelector = (state: RootState) =>
  state.contracts.signing.contract.data?.contractStatus === CONTRACT_STATUS.COMPLETED
export const signingContractIsDeletedSelector = (state: RootState) =>
  state.contracts.signing.contract.data?.contractStatus === CONTRACT_STATUS.DELETED
export const signingContractIsExpiredSelector = (state: RootState) =>
  state.contracts.signing.contract.data?.contractStatus === CONTRACT_STATUS.USER_TERM_EXPIRED

export const signingSignIsSuccessSelector = (state: RootState) =>
  state.contracts.signing.sign.fetchStatus === FETCH_STATUSES.SUCCESS
export const signingSignFetchStatusSelector = (state: RootState) => state.contracts.signing.sign.fetchStatus
export const signingSignErrorSelector = (state: RootState) => state.contracts.signing.sign.error

export const signingDefaultSignDataSelector = (state: RootState) => state.contracts.signing.defaultSign.data

export const signingScrollToSignPlacementId = (state: RootState) => state.contracts.signing.scrollToSign.placementId

export const signingNotificationSelector = (state: RootState) => state.contracts.signing.notification

export const signingDeclineIsSuccessSelector = (state: RootState) =>
  state.contracts.signing.decline.fetchStatus === FETCH_STATUSES.SUCCESS
export const signingDeclineFetchStatusSelector = (state: RootState) => state.contracts.signing.decline.fetchStatus
export const signingDeclineErrorSelector = (state: RootState) => state.contracts.signing.decline.error

export const signingRecipientTokenSelector = (state: RootState) => state.contracts.signing.recipientToken
