import React from 'react'
import { useSelector } from 'react-redux'
import { StyleSheetManager } from 'styled-components'
import rtlPlugin from 'stylis-plugin-rtl'

import { languagesSelector } from '../../store/language/language.selectors'

export const RTL: React.FC = ({ children }) => {
  const language = useSelector(languagesSelector).language
  const rtl = language === 'ar'

  return (
    <StyleSheetManager stylisPlugins={rtl ? [rtlPlugin] : []}>
      <div style={{ height: '100%' }} dir={rtl ? 'rtl' : 'ltr'}>
        {children}
      </div>
    </StyleSheetManager>
  )
}
