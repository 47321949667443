import React from 'react'

import Button from '../../../../ui/Button'
import { BUTTON_VARIANTS } from '../../../../ui/Button/Button.types'
import Typography from '../../../../ui/Typography'
import Box from '../../../../ui/Box/index'
import { TYPOGRAPHY_NAMES } from '../../../../ui/Typography/Typography.types'
import translations from '../../../../../translations/keys'

import { StyledItem, StyledItemWrapper, StyledIconWrapper } from './CloudStorageItem.styles'
import { CloudStorageItemProps } from './CloudStorageItem.types'

export const CloudStorageItem: React.FC<CloudStorageItemProps> = (props) => {
  const { item } = props
  return (
    <StyledItem>
      <StyledItemWrapper>
        <StyledIconWrapper>{item.icon}</StyledIconWrapper>
        <Box display="flex" flexDirection="column">
          <Box mb={1}>
            <Typography name={TYPOGRAPHY_NAMES.H4}>{item.title}</Typography>
          </Box>
          <Typography name={TYPOGRAPHY_NAMES.body14} color="grey">
            {item.statusText}
          </Typography>
          <Box width={148} mt={2}>
            <Button fullWidth variant={item.isConnected ? BUTTON_VARIANTS.PRIMARY : BUTTON_VARIANTS.SECONDARY}>
              {item.isConnected ? translations.CONNECT : translations.DISCONNECT}
            </Button>
          </Box>
        </Box>
      </StyledItemWrapper>
    </StyledItem>
  )
}
