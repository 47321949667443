import styled from 'styled-components'
import { motion } from 'framer-motion'

export const StyledLine = styled(motion.div)<{ animating: boolean }>`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 1px;
  background-color: ${({ theme }) => theme.palette.blue};
  opacity: ${({ animating }) => (animating ? 1 : 0)};
`
