import styled from 'styled-components'

import Box from '../../../../ui/Box'

export const StyledWrapper = styled(Box).attrs({
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
  pt: 4,
})`
  border: 1px solid ${({ theme }) => theme.palette.strokeGrey};
  border-radius: 10px;
`
