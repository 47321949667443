export enum SocketEvents {
  DOCUMENT_READY = 'DOCUMENT_READY',
  DOCUMENT_UPDATE = 'DOCUMENT_UPDATE',

  NOTIFICATION = 'NOTIFICATION',

  TOKENIZATION_COMPLETED = 'TOKENIZATION_COMPLETED',
  PURCHASE_COMPLETED = 'PURCHASE_COMPLETED',
  CARD_VERIFIED = 'CARD_VERIFIED',
  REFUND_COMPLETED = 'REFUND_COMPLETED',
}
