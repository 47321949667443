import { FETCH_STATUSES } from '../../types/fetch'
import { RootState } from '../rootReducer.types'

export const authUserSelector = (state: RootState) => state.auth.user

export const authLoginFetchStatusSelector = (state: RootState) => state.auth.loginFetchStatus

export const authLoginErrorSelector = (state: RootState) => state.auth.loginError

export const authLoginIsFailureSelector = (state: RootState) => state.auth.loginFetchStatus === FETCH_STATUSES.FAILURE

export const authResendEmailFetchStatusSelector = (state: RootState) => state.auth.resendEmailFetchStatus

export const authResendEmailErrorSelector = (state: RootState) => state.auth.resendEmailError

export const authUserEmailSelector = (state: RootState) => state.auth.user?.email

export const authUserIdSelector = (state: RootState) => state.auth.user?.id
