import React, { FC } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import translations from '../../../../translations/keys'
import { Modal } from '../../Modal/Modal'
import Typography from '../../Typography'
import { TYPOGRAPHY_NAMES } from '../../Typography/Typography.types'
import Button from '../../Button'
import { BUTTON_VARIANTS } from '../../Button/Button.types'
import { HandshakeIcon } from '../../../assets/HandshakeIcon'

import { NOTIFICATION_MODAL_TYPES } from './NotificationModal.constants'
import { WarningIcon } from './assets/WarningIcon'
import { NotificationModalViewProps } from './NotificationModalView.types'
import { InfoIcon } from './assets/InfoIcon'

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 320px;
  text-align: center;
  ${({ theme }) => `@media ${theme.devices.mobile} {
    width: 100%;
  }`}
`
const StyledTitle = styled(Typography)`
  margin: 24px 0 16px;
`
const StyledButton = styled(Button)`
  margin-top: 24px;
`

const TITLES: { [key: string]: string } = {
  [NOTIFICATION_MODAL_TYPES.WARNING]: translations.WARNING,
  [NOTIFICATION_MODAL_TYPES.SUCCESS]: translations.SUCCESS,
  [NOTIFICATION_MODAL_TYPES.INFO]: translations.INFO,
}
const ICONS: { [key: string]: FC } = {
  [NOTIFICATION_MODAL_TYPES.WARNING]: WarningIcon,
  [NOTIFICATION_MODAL_TYPES.SUCCESS]: HandshakeIcon,
  [NOTIFICATION_MODAL_TYPES.INFO]: InfoIcon,
}

export const NotificationModalView: FC<NotificationModalViewProps> = ({
  onClose,
  description,
  title,
  type,
  successButtonText,
  cancelButtonText,
  onSuccess,
  onCancel,
  onlySuccess = false,
}) => {
  const { t } = useTranslation()
  const Icon = ICONS[type]
  const onSuccessClick = () => {
    if (onSuccess) {
      onSuccess()
    } else {
      onClose()
    }
  }
  const onCancelClick = () => {
    if (onCancel) {
      onCancel()
    } else {
      onClose()
    }
  }

  return (
    <Modal onClose={onClose}>
      <StyledContent>
        <Icon />
        <StyledTitle name={TYPOGRAPHY_NAMES.H1}>{t(title || TITLES[type])}</StyledTitle>
        <p>{description && t(description)}</p>
        <StyledButton onClick={onSuccessClick} fullWidth>
          {t(successButtonText || translations.OK)}
        </StyledButton>
        {onSuccess && !onlySuccess && (
          <StyledButton onClick={onCancelClick} fullWidth variant={BUTTON_VARIANTS.SECONDARY}>
            {t(cancelButtonText || translations.CANCEL)}
          </StyledButton>
        )}
      </StyledContent>
    </Modal>
  )
}
