import React from 'react'

export const EditIcon: React.FC = (props) => (
  <svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M20 20H0V22H20V20Z" fill="#8B96A9" />
    <path
      d="M14.3402 0L2.00028 12.34V18H7.66028L20.0002 5.66L14.3402 0ZM6.83027 15.9999H4.00029V13.17L14.3402 2.82998L17.1702 5.65996L6.83027 15.9999Z"
      fill="#8B96A9"
    />
  </svg>
)
