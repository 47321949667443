import React from 'react'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { UPLOAD_TYPES } from '../../../../constants/contracts'
import translations from '../../../../../translations/keys'
import DropdownButton from '../../../../ui/DropdownButton'
import { BUTTON_VARIANTS } from '../../../../ui/Button/Button.types'
import { openModal } from '../../../../../store/modals/modals.actions'
import { CONTRACTS_MODALS } from '../../../../../constants/contracts'
import { Item } from '../../../../ui/DropdownButton/DropdownButton.types'
import { FileIcon, LaptopIcon } from '../../../../assets/icons'
import { useAccessFeature } from '../../../../../hooks/subscription/useAccessFeature'
import { SubscriptionFeatures } from '../../../../../types/subscription'

const StyledText = styled.div`
  text-transform: uppercase;
  line-height: 1;
`
export const UploadMenu: React.FC = () => {
  const dispatch = useDispatch()
  const { checkAccess } = useAccessFeature()
  const { t } = useTranslation()
  const items: Item[] = [
    {
      name: t(translations.UPLOAD_FROM_COMPUTER),
      icon: LaptopIcon,
      value: UPLOAD_TYPES.COMPUTER,
      onClick: () => {
        dispatch(openModal(CONTRACTS_MODALS.UPLOAD_CONTRACT))
      },
    },
    {
      name: t(translations.UPLOAD_FROM_TEMPLATE),
      icon: FileIcon,
      value: UPLOAD_TYPES.TEMPLATE,
      onClick: () => {
        if (checkAccess(SubscriptionFeatures.FEATURE_USER_TEMPLATES)) {
          dispatch(openModal(CONTRACTS_MODALS.CREATE_CONTRACT_FROM_TEMPLATE))
        }
      },
    },
    // TODO UQD-1410 uncomment when this will work
    // {
    //   name: translations.UPLOAD_FROM_GOOGLE,
    //   icon: GoogleDriveIcon,
    //   value: UPLOAD_TYPES.GOOGLE,
    //   disabled: true,
    // },
    // {
    //   name: translations.UPLOAD_FROM_ONE_DRIVE,
    //   icon: OneDriveIcon,
    //   value: UPLOAD_TYPES.ONE_DRIVE,
    //   disabled: true,
    // },
  ]

  return (
    <DropdownButton
      fullWidth
      triangleColor="white"
      iconColor="blue"
      variant={BUTTON_VARIANTS.PRIMARY}
      items={items}
      popoverStyles={{
        width: '208px',
        offsetTop: 5,
      }}
    >
      <StyledText>{t(translations.ADD_CONTRACT)}</StyledText>
    </DropdownButton>
  )
}
