import React, { useState } from 'react'
import { useTheme } from 'styled-components'

import Typography from '../../../../../../../ui/Typography'
import { TYPOGRAPHY_NAMES } from '../../../../../../../ui/Typography/Typography.types'
import translations from '../../../../../../../../translations/keys'
import Box from '../../../../../../../ui/Box'
import Button from '../../../../../../../ui/Button'
import useMediaQuery from '../../../../../../../hooks/useMediaQuery'
import { StyledThemeScheme } from '../../../../../../../../themes/light'
import { SignaturePad } from '../../../SignaturePad/SignaturePad'
import { SignatureMode } from '../../../../CreateSignatureModal.types'

import { ModalBodyProps } from './ModalBody.types'
import { DESCRIPTIONS, TITLES } from './ModalBody.constants'

export const ModalBody: React.FC<ModalBodyProps> = ({ onCreateSignature, mode }) => {
  const theme = useTheme() as StyledThemeScheme
  const tabletScreen = useMediaQuery(theme.devices.tablet)
  const [signature, setSignature] = useState('')
  const [trimmedSignature, setTrimmedSignature] = useState('')
  const [initials, setInitials] = useState('')
  const [trimmedInitials, setTrimmedInitials] = useState('')
  const isSignatureMode = mode === SignatureMode.signature
  const isInitialsMode = mode === SignatureMode.initials

  const disabled = () => {
    if (mode === SignatureMode.both) {
      return !(signature && initials)
    }
    if (isSignatureMode) {
      return !signature
    }
    if (isInitialsMode) {
      return !initials
    }
  }
  const onSubmit = () => {
    onCreateSignature({ initials: trimmedInitials, signature: trimmedSignature })
  }

  return (
    <Box display="flex" flexDirection="column" alignItems="center" mt={3} width="100%" height="100%">
      <Box mb={3}>
        <Typography name={TYPOGRAPHY_NAMES.H1}>{translations[TITLES[mode]]}</Typography>
      </Box>
      <Box mb={5} textAlign="center" width={626}>
        <Typography name={TYPOGRAPHY_NAMES.body14}>{translations[DESCRIPTIONS[mode]]}</Typography>
      </Box>
      <Box display="flex" flexDirection={tabletScreen ? 'column' : undefined} width="100%">
        {!isInitialsMode && (
          <Box width={tabletScreen || isSignatureMode ? '100%' : '60%'}>
            <SignaturePad
              height={tabletScreen ? 272 : 480}
              title={translations.SIGNATURE}
              placeholder={translations.DRAW_SIGNATURE_HERE}
              setImg={setSignature}
              setTrimmedImg={setTrimmedSignature}
              imgUrl={signature}
            />
          </Box>
        )}
        {!isSignatureMode && (
          <Box
            width={tabletScreen || isInitialsMode ? '100%' : '40%'}
            ml={tabletScreen || isInitialsMode ? 0 : 3}
            mt={tabletScreen && !isInitialsMode ? 4 : undefined}
          >
            <SignaturePad
              height={tabletScreen ? 228 : 480}
              title={translations.INITIALS}
              placeholder={translations.DRAW_INITIALS_HERE}
              setImg={setInitials}
              setTrimmedImg={setTrimmedInitials}
              imgUrl={initials}
            />
          </Box>
        )}
      </Box>
      <Box width={320} mt={5}>
        <Button fullWidth disabled={disabled()} onClick={onSubmit}>
          {translations.SUBMIT}
        </Button>
      </Box>
    </Box>
  )
}
