import { FetchFailureAction } from '../../../types/fetch'
import { createReducer } from '../../createReducer'

import * as TYPES from './viewOptions.types'

type Actions = FetchFailureAction

export const initialState: TYPES.ViewOptionsState = {
  isSignatureNamesVisible: false,
}

export const viewOptions = createReducer<TYPES.ViewOptionsState, Actions>(initialState, {
  [TYPES.ViewOptionsActions.TEMPLATES_TOGGLE_SIGNATURE_NAMES_VISIBLE]: (state) => ({
    ...state,
    isSignatureNamesVisible: !state.isSignatureNamesVisible,
  }),
  [TYPES.ViewOptionsActions.CLEAN_TEMPLATES_VIEW_OPTIONS]: () => ({ ...initialState }),
})
