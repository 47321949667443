import React, { useState } from 'react'
import { useTheme } from 'styled-components'

import { StyledThemeScheme } from '../../../../../themes/light'
import translations from '../../../../../translations/keys'
import { SIGNATURE_TYPE } from '../../../../../types/signatures'
import { AttentionIcon } from '../../../../assets/icons'
import Button from '../../../../ui/Button'
import Box from '../../../../ui/Box'
import useMediaQuery from '../../../../hooks/useMediaQuery'
import { SignaturesEmpty } from '../SignaturesEmpty/SignaturesEmpty'
import { SignaturesItem } from '../SignaturesItem/SignaturesItem'

import { StyledAddSignature } from './SignaturesList.styles'
import { SignaturesListProps, Visible } from './SignaturesList.types'

const INITIAL_VISIBLE = { type: SIGNATURE_TYPE.NO_TYPE, id: '' }

export const SignaturesList: React.FC<SignaturesListProps> = ({ signatures, onAddSignature }) => {
  const theme = useTheme() as StyledThemeScheme
  const mobileScreen = useMediaQuery(theme.devices.mobile)
  const [visible, setVisible] = useState<Visible>(INITIAL_VISIBLE)

  if (!signatures.length) {
    return <SignaturesEmpty onAddSignature={onAddSignature} />
  }

  const renderHeader = () => {
    const info =
      signatures.length === 1 ? (
        translations.ADD_ONE_MORE_SIGNATURE
      ) : (
        <Box display="flex">
          <AttentionIcon color="grey" /> <Box ml={1}>{translations.REACHED_MAX_NUMBER_OF_SIGNATURES}</Box>
        </Box>
      )

    return (
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
        {info}
        {!mobileScreen && (
          <StyledAddSignature>
            <Button fullWidth onClick={onAddSignature}>
              {translations.ADD_SIGNATURE}
            </Button>
          </StyledAddSignature>
        )}
      </Box>
    )
  }
  const onShow = (signature: Visible) => {
    setVisible(signature)
  }

  return (
    <div>
      {renderHeader()}
      {signatures.map((signature, index) => (
        <SignaturesItem
          {...signature}
          key={signature.id}
          visible={visible}
          onShow={onShow}
          name={`${translations.SIGNATURE} ${index + 1}`}
        />
      ))}
    </div>
  )
}
