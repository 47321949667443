import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'

import { openModal } from '../../../../store/modals/modals.actions'
import translations from '../../../../translations/keys'
import { location } from '../../../../utils/location'
import { BUTTON_VARIANTS } from '../../../ui/Button/Button.types'
import { CONTRACT_SIGN_MODALS } from '../../../constants/contractSign'
import { useButtons as useCommonButtons } from '../../../hooks/contractSign/useButtons'
import { CalendarIcon, CloseCircleIcon } from '../../../assets/icons'
import { closeProgress, openProgress } from '../../../../store/progress/progress.actions'
import { isProgressVisibleSelector } from '../../../../store/progress/progress.selectors'

import { usePlacementById } from './usePlacementById'

export const useButtons = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const placementById = usePlacementById()
  const isProgressOpen = useSelector(isProgressVisibleSelector)

  const cancelButton = {
    label: translations.CANCEL,
    onClick: () => {
      history.push(location.contractsAllUrl())
    },
    variant: BUTTON_VARIANTS.SECONDARY,
    MobileIcon: CloseCircleIcon,
  }
  const finishLater = {
    label: translations.CONTRACT_FINISH_LATER,
    onClick: () => {
      dispatch(openModal(CONTRACT_SIGN_MODALS.FINISH_SIGN_LATER))
    },
    variant: BUTTON_VARIANTS.SECONDARY,
    MobileIcon: CloseCircleIcon,
  }

  const progress = {
    label: translations.HISTORY,
    onClick: () => {
      dispatch(isProgressOpen ? closeProgress() : openProgress())
    },
    variant: BUTTON_VARIANTS.SECONDARY,
    MobileIcon: CalendarIcon,
    isMobileOnly: true,
  }

  return useCommonButtons(placementById, { cancelButton, finishLater, progress })
}
