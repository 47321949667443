import { Action } from 'redux'

import { FetchFailurePayload, FETCH_STATUSES } from '../../../types/fetch'
import {
  FetchActivateSubscriptionBody,
  FetchRegisterCardBody,
  FormTokenizationFields,
} from '../../../types/subscriptionPayment'

export interface PaymentState {
  form: {
    fetchStatus: FETCH_STATUSES
    error: FetchFailurePayload | null
    fields: FormTokenizationFields | null
    refetch: boolean
  }
  registerCard: {
    fetchStatus: FETCH_STATUSES
    error: FetchFailurePayload | null
  }
  process: {
    processing: boolean
    cardVerified: boolean
    error: FetchFailurePayload | null
  }
  activate: {
    fetchStatus: FETCH_STATUSES
    error: FetchFailurePayload | null
    trial: boolean
  }
  threeDSecureUrl: string | null
}

export enum PaymentActions {
  'FETCH_SUBSCRIPTION_PAYMENT_FORM_FIELDS' = 'FETCH_SUBSCRIPTION_PAYMENT_FORM_FIELDS',
  'CLEAN_SUBSCRIPTION_PAYMENT' = 'CLEAN_SUBSCRIPTION_PAYMENT',
  'FETCH_SUBSCRIPTION_REGISTER_CARD' = 'FETCH_SUBSCRIPTION_REGISTER_CARD',
  'SET_SUBSCRIPTION_PAYMENT_ERROR' = 'SET_SUBSCRIPTION_PAYMENT_ERROR',
  'VERIFY_SUBSCRIPTION_PAYMENT_CARD' = 'VERIFY_SUBSCRIPTION_PAYMENT_CARD',
  'CLEAN_SUBSCRIPTION_PAYMENT_FORM' = 'CLEAN_SUBSCRIPTION_PAYMENT_FORM',
  'FETCH_ACTIVATE_SUBSCRIPTION' = 'FETCH_ACTIVATE_SUBSCRIPTION',
}

export type FetchFormFieldsAction = Action
export interface FetchFormFieldsSuccessAction extends Action {
  payload: { fields: FormTokenizationFields }
}

export interface FetchRegisterCardAction extends Action {
  payload: { body: FetchRegisterCardBody }
}
export interface FetchRegisterCardSuccessAction extends Action {
  payload: { threeDSecureUrl: string }
}

export interface FetchActivateSubscriptionAction extends Action {
  payload: { body: FetchActivateSubscriptionBody; trial: boolean }
}
export interface FetchActivateSubscriptionSuccessAction extends Action {
  payload: { threeDSecureUrl: string }
}
