import translations from '../translations'
import { AddContactFields } from '../types/addContact'
import { DEPARTMENT_MAX_LENGTH } from '../validation/departmentValidationSchema'
import { EMAIL_MAX_LENGTH } from '../validation/emailValidationSchema'
import { NAME_MAX_LENGTH } from '../validation/nameValidationSchema'
import { PHONE_MAX_LENGTH } from '../validation/phoneValidationSchema'
import { TABLE_COLUMN_TYPES } from '../web/ui/Table/Table.types'

export enum CONTACT_FIELD_ERRORS {
  INVALID_PHONE = 'INVALID_PHONE',
  CONTACT_EMAIL_EXIST = 'CONTACT_EMAIL_EXIST',
}

export const mobileColumns = [
  {
    field: AddContactFields.fullName,
    name: 'Name',
    maxLength: NAME_MAX_LENGTH,
    widthPercent: 34,
    sortable: true,
    searchable: true,
  },
  {
    field: AddContactFields.email,
    name: 'Email',
    maxLength: EMAIL_MAX_LENGTH,
    widthPercent: 25,
    sortable: true,
    searchable: true,
    type: TABLE_COLUMN_TYPES.EMAIL,
  },
]

export const columns = [
  ...mobileColumns,
  {
    field: AddContactFields.phone,
    name: translations.PHONE_NUMBER,
    maxLength: PHONE_MAX_LENGTH,
    type: TABLE_COLUMN_TYPES.PHONE,
    widthPercent: 17,
  },
  {
    field: AddContactFields.department,
    name: translations.DEPARTMENT_LABEL,
    maxLength: DEPARTMENT_MAX_LENGTH,
    widthPercent: 24,
  },
]
