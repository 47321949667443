export enum CreateTemplateFromContractFields {
  folderName = 'folderName',
  name = 'name',
  contractId = 'contractId',
}

export interface CreateTemplateFromContractBody {
  folderName: string
  name: string
  contractId: string
}

export interface CreateTemplateFromContractResponse {
  id: string
}
