import { Action } from 'redux'

import { FetchFailurePayload, FETCH_STATUSES } from '../../../types/fetch'
import { EditableElem } from '../../../types/editableElem'
import {
  DocCorrectionsResponseBody,
  DocCorrectionsById,
  DocCorrectionsByPages,
  DocCorrection,
  DocCorrectionsConfirmRequest,
  DocCancelCorrectionConfirmation,
} from '../../../types/correction'
import { ConfirmationStatus } from '../../../types/confirmationActions'

export interface CorrectionsState {
  byId: DocCorrectionsById
  byPages: DocCorrectionsByPages
  fetch: {
    status: FETCH_STATUSES
    error: FetchFailurePayload | null
  }
  fetchCreate: {
    status: FETCH_STATUSES
    error: FetchFailurePayload | null
  }
  confirm: {
    status: FETCH_STATUSES
    error: FetchFailurePayload | null
    confirmStatus: ConfirmationStatus | null
  }
  cancelConfirmation: {
    status: FETCH_STATUSES
    error: FetchFailurePayload | null
    confirmStatus: ConfirmationStatus | null
  }
}

export enum CorrectionsActions {
  'ADD_CORRECTION' = 'ADD_CORRECTION',
  'SET_CORRECTION' = 'SET_CORRECTION',
  'SET_CORRECTION_TEXT' = 'SET_CORRECTION_TEXT',
  'UNSET_CORRECTION' = 'UNSET_CORRECTION',
  'CLEAR_CORRECTIONS' = 'CLEAR_CORRECTIONS',
  'FETCH_CORRECTIONS' = 'FETCH_CORRECTIONS',
  'FETCH_CREATE_CORRECTION' = 'FETCH_CREATE_CORRECTION',
  'FETCH_CONFIRM_CORRECTION' = 'FETCH_CONFIRM_CORRECTION',
  'FETCH_CANCEL_CORRECTION_CONFIRMATION' = 'FETCH_CANCEL_CORRECTION_CONFIRMATION',
}

export interface AddCorrectionAction extends Action {
  payload: { correction: DocCorrection; currentUserId: string }
}
export interface SetCorrectionAction extends Action {
  payload: { correction: EditableElem }
}
export interface SetCorrectionTextAction extends Action {
  payload: { id: string; text: string }
}
export interface UnsetCorrectionAction extends Action {
  payload: { id: string; currentUserId: string }
}
export type ClearCorrectionsAction = Action

export interface FetchCorrectionsAction extends Action {
  payload: { contractId: string }
}
export interface FetchCorrectionsSuccessAction extends Action {
  payload: { byId: DocCorrectionsById; byPages: DocCorrectionsByPages }
}

export interface FetchCreateCorrectionAction extends Action {
  payload: { contractId: string; correction: DocCorrection }
}
export interface FetchCreateCorrectionSuccessAction extends Action {
  payload: DocCorrectionsResponseBody
}

export interface FetchConfirmCorrectionAction extends Action {
  payload: DocCorrectionsConfirmRequest
}
export interface FetchConfirmCorrectionSuccessAction extends Action {
  payload: { confirmStatus: ConfirmationStatus }
}

export interface FetchCancelCorrectionConfirmationAction extends Action {
  payload: DocCancelCorrectionConfirmation
}
export interface FetchCancelCorrectionConfirmationSuccessAction extends Action {
  payload: { confirmStatus: ConfirmationStatus }
}
