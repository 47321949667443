import React from 'react'

import { SvgIconProps } from '../../ui/SvgIcon/SvgIcon.types'
import SvgIcon from '../../ui/SvgIcon'

export const ForwardIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <path d="M22.7989 11.077L15.924 4.20073C15.7274 4.00411 15.4345 3.94636 15.1747 4.05224C14.9175 4.15811 14.7498 4.40973 14.7498 4.68748V8.12492H12.6873C6.24279 8.12492 1 13.3677 1 19.8122C1 20.1189 1.2035 20.3884 1.49774 20.4722C1.56099 20.4915 1.62424 20.4997 1.68749 20.4997C1.91986 20.4997 2.14261 20.3815 2.27048 20.1766L2.47673 19.8466C4.34257 16.8574 7.69065 14.9998 11.2161 14.9998H14.7498V18.4373C14.7498 18.715 14.9175 18.9666 15.1747 19.0725C15.4332 19.177 15.7274 19.1193 15.924 18.924L22.7989 12.0491C23.067 11.781 23.067 11.3451 22.7989 11.077ZM16.1248 16.7777V14.3123C16.1248 13.9328 15.8168 13.6248 15.4373 13.6248H11.2161C7.97252 13.6248 4.85956 15.0122 2.67335 17.3469C3.78021 12.848 7.85014 9.4999 12.6873 9.4999H15.4373C15.8168 9.4999 16.1248 9.19191 16.1248 8.81241V6.34707L21.3401 11.5624L16.1248 16.7777Z" />
    <path d="M15.0803 3.82071C15.4319 3.67748 15.8319 3.75511 16.1008 4.02396L22.9757 10.9002C23.3414 11.266 23.3414 11.8601 22.9757 12.2259L16.1008 19.1008C15.8321 19.3676 15.4308 19.4457 15.081 19.3043L15.0795 19.3037C14.7283 19.1591 14.4998 18.8159 14.4998 18.4373V15.2498H11.2161C7.7769 15.2498 4.50898 17.0629 2.6888 19.979L2.48256 20.309C2.30795 20.5886 2.00405 20.7497 1.68749 20.7497C1.6028 20.7497 1.51552 20.7387 1.4271 20.712C1.02651 20.5968 0.75 20.2297 0.75 19.8122C0.75 13.2296 6.10472 7.87492 12.6873 7.87492H14.4998V4.68748C14.4998 4.30883 14.7292 3.96531 15.0803 3.82071ZM15.2694 4.28359C15.1066 4.35084 14.9998 4.51078 14.9998 4.68748V8.37492H12.6873C6.38086 8.37492 1.25 13.5058 1.25 19.8122C1.25 20.0073 1.37952 20.1786 1.56627 20.2318L1.57054 20.233C1.60789 20.2444 1.64637 20.2497 1.68749 20.2497C1.83562 20.2497 1.97725 20.1742 2.0584 20.0442L2.26465 19.7142C4.17618 16.6519 7.60444 14.7498 11.2161 14.7498H14.9998V18.4373C14.9998 18.6139 15.1065 18.7737 15.2691 18.841C15.4359 18.9081 15.6231 18.8702 15.7475 18.747L22.6221 11.8723C22.7926 11.7018 22.7926 11.4243 22.6221 11.2538L15.7472 4.37751C15.6229 4.25322 15.4375 4.21531 15.2694 4.28359ZM15.8748 5.74352L21.6936 11.5624L15.8748 17.3812V14.3123C15.8748 14.0709 15.6787 13.8748 15.4373 13.8748H11.2161C8.04312 13.8748 4.99578 15.2325 2.85583 17.5178L2.20209 18.2159L2.43059 17.2872C3.56424 12.6793 7.7322 9.2499 12.6873 9.2499H15.4373C15.6787 9.2499 15.8748 9.05383 15.8748 8.81241V5.74352ZM16.3748 6.95063V8.81241C16.3748 9.32998 15.9548 9.7499 15.4373 9.7499H12.6873C8.30311 9.7499 4.56388 12.5693 3.18909 16.4894C5.36121 14.5223 8.23374 13.3748 11.2161 13.3748H15.4373C15.9548 13.3748 16.3748 13.7948 16.3748 14.3123V16.1741L20.9865 11.5624L16.3748 6.95063Z" />
  </SvgIcon>
)
