import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { fetchCountries } from '../../store/countries/countries.actions'
import { fetchPhones } from '../../store/phones/phones.actions'
import { fetchIndustries } from '../../store/industries/industries.actions'

export const useRegistrationFetch = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchCountries())
    dispatch(fetchPhones())
    dispatch(fetchIndustries())
  }, [])
}
