import React, { useEffect } from 'react'
import { Form, Formik, FormikHandlers, FormikHelpers, FormikState } from 'formik'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { location } from '../../../utils/location'
import translations from '../../../translations/keys'
import Box from '../../ui/Box'
import FormWrapper from '../../components/FormWrapper'
import AuthorizationBox from '../../components/Authorization/AuthorizationBox'
import FormTextInput from '../../ui/Forms/FormTextInput'
import Button from '../../ui/Button'
import Link from '../../ui/Link'
import { fetchForgotPassword, resetForgotPassword } from '../../../store/forgotPassword/forgotPassword.actions'
import { forgotPasswordSelector } from '../../../store/forgotPassword/forgotPassword.selectors'
import { FETCH_STATUSES } from '../../../types/fetch'
import { openModal } from '../../../store/modals/modals.actions'
import { ForgotPasswordFields, FORGOT_PASSWORD_NOTIFICATIONS } from '../../../types/forgotPassword'
import { useShowFetchError } from '../../hooks/useShowFetchError'
import { EMAIL_MAX_LENGTH } from '../../../validation/emailValidationSchema'
import { forgotPasswordValidationSchema } from '../../../validation/forgotPassword/forgotPasswordValidationSchema'

import { ForgotPasswordModals } from './ForgotPasswordModals'
import { ForgotPasswordValues } from './ForgotPassword.types'

const StyledDescription = styled.p`
  text-align: center;
  margin-bottom: 24px;
`
const StyledButtonWrapper = styled.div`
  margin-bottom: 201px;
  ${({ theme }) => `@media ${theme.devices.mobile} {
    margin-bottom: 121px;
  }`}
`
const StyledTitle = styled.span`
  display: inline-block;
  margin-bottom: 24px;
`

export const ForgotPassword = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { forgotPasswordFetchStatus, forgotPasswordError, forgotPasswordMessage } = useSelector(forgotPasswordSelector)
  const initialValues: ForgotPasswordValues = {
    [ForgotPasswordFields.email]: '',
  }
  const onSubmit = (values: ForgotPasswordValues) => {
    dispatch(fetchForgotPassword(values.email))
  }

  const renderForm = ({
    handleSubmit,
  }: FormikHelpers<ForgotPasswordValues> & FormikHandlers & FormikState<ForgotPasswordValues>) => (
    <Form>
      <FormTextInput
        name={ForgotPasswordFields.email}
        label={t(translations.EMAIL_LABEL)}
        placeholder={t(translations.FORGOT_PASSWORD_EMAIL_PLACEHOLDER)}
        maxLength={EMAIL_MAX_LENGTH}
        trim
      />
      <StyledButtonWrapper>
        <Button onClick={handleSubmit} fullWidth type="submit">
          {t(translations.RESET_PASSWORD)}
        </Button>
      </StyledButtonWrapper>
    </Form>
  )

  useEffect(() => {
    if (forgotPasswordFetchStatus === FETCH_STATUSES.SUCCESS) {
      dispatch(
        forgotPasswordMessage === 'VERIFY_EMAIL_FIRST'
          ? openModal(FORGOT_PASSWORD_NOTIFICATIONS.NOTIFICATION_VERIFY_EMAIL)
          : openModal(FORGOT_PASSWORD_NOTIFICATIONS.NOTIFICATION_CHECK_EMAIL)
      )
    }
  }, [forgotPasswordFetchStatus])

  useShowFetchError(forgotPasswordFetchStatus, forgotPasswordError)

  useEffect(
    () => () => {
      dispatch(resetForgotPassword())
    },
    []
  )

  return (
    <>
      <AuthorizationBox title={<StyledTitle>{t(translations.FORGOT_PASSWORD)}</StyledTitle>}>
        <FormWrapper>
          <StyledDescription>{t(translations.FORGOT_PASSWORD_DESCRIPTION)}</StyledDescription>
          <Formik validationSchema={forgotPasswordValidationSchema} initialValues={initialValues} onSubmit={onSubmit}>
            {renderForm}
          </Formik>
          <Box textAlign="center" mb={5}>
            <Link to={location.loginUrl()}>{t(translations.BACK_TO_LOG_IN)}</Link>
          </Box>
        </FormWrapper>
      </AuthorizationBox>
      <ForgotPasswordModals />
    </>
  )
}
