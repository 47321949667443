import { ContractHistory } from '../../types/contractHistory'
import { getRecipientHeaders } from '../../utils/signing/getRecipientHeaders'
import { Ajax } from '../ajax'

class HistoryService extends Ajax {
  pathname = 'history'

  fetchHistory = (contractId: string, recipientToken: string) =>
    this.getJSON<ContractHistory>(`${this.pathname}/${contractId}`, getRecipientHeaders(recipientToken))
}

export const historyService = new HistoryService()
