import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { Form, Formik, FormikHandlers, FormikHelpers, FormikState } from 'formik'
import { useLocation, Redirect } from 'react-router-dom'

import translations from '../../../translations/keys'
import { openModal } from '../../../store/modals/modals.actions'
import Box from '../../ui/Box'
import FormWrapper from '../../components/FormWrapper'
import AuthorizationBox from '../../components/Authorization/AuthorizationBox'
import Button from '../../ui/Button'
import Typography from '../../ui/Typography'
import { TYPOGRAPHY_COMPONENTS, TYPOGRAPHY_NAMES } from '../../ui/Typography/Typography.types'
import FormPassword from '../../ui/Forms/FormPassword'
import { RESTORE_PASSWORD_NOTIFICATIONS } from '../../../constants/restorePassword'
import { RestorePasswordValues, RestorePasswordFields } from '../../../types/restorePassword'
import { resetRestorePassword, fetchRestorePassword } from '../../../store/restorePassword/restorePassword.actions'
import { restorePasswordSelector } from '../../../store/restorePassword/restorePassword.selectors'
import { FETCH_STATUSES } from '../../../types/fetch'
import { useShowFetchError } from '../../hooks/useShowFetchError'
import { restorePasswordValidationSchema } from '../../../validation/restorePassword/restorePasswordValidationSchema'
import { location } from '../../../utils/location'

import { RestorePasswordModals } from './RestorePasswordModals'

const StyledTitle = styled.span`
  display: inline-block;
  margin-bottom: 24px;
`
const StyledForm = styled(Form)`
  width: 100%;
`
const StyledButtonWrapper = styled.div`
  margin-top: 24px;
`
const StyledValidation = styled(Typography)`
  color: ${({ theme }) => theme.palette.grey};
  text-align: center;
`

export const RestorePassword = () => {
  const dispatch = useDispatch()
  const search = useLocation().search
  const params = new URLSearchParams(search)
  const linkId = params.get(location.queryParams.ID)
  const code = params.get(location.queryParams.CODE)
  const restorePasswordData = useSelector(restorePasswordSelector)

  useEffect(() => {
    if (restorePasswordData.fetchStatus === FETCH_STATUSES.SUCCESS) {
      dispatch(openModal(RESTORE_PASSWORD_NOTIFICATIONS.NOTIFICATION_SUCCESS_CHANGED))
    }
  }, [restorePasswordData.fetchStatus])

  useShowFetchError(restorePasswordData.fetchStatus, restorePasswordData.error)

  useEffect(
    () => () => {
      dispatch(resetRestorePassword())
    },
    []
  )

  if (!linkId || !code) {
    return <Redirect to={location.loginUrl()} />
  }

  const initialValues: RestorePasswordValues = {
    [RestorePasswordFields.password]: '',
    [RestorePasswordFields.confirmPassword]: '',
  }

  const renderForm = ({
    handleSubmit,
  }: FormikHelpers<RestorePasswordValues> & FormikHandlers & FormikState<RestorePasswordValues>) => (
    <StyledForm>
      <FormPassword
        name={RestorePasswordFields.password}
        label={translations.PASSWORD_LABEL}
        placeholder={translations.RESTORE_PASSWORD_PLACEHOLDER}
      />
      <FormPassword
        name={RestorePasswordFields.confirmPassword}
        label={translations.CONFIRM_PASSWORD_LABEL}
        placeholder={translations.RESTORE_CONFIRM_PASSWORD_PLACEHOLDER}
      />
      <StyledValidation name={TYPOGRAPHY_NAMES.bodySmall12} component={TYPOGRAPHY_COMPONENTS.p}>
        {translations.PASSWORD_VALIDATION_INFO}
      </StyledValidation>
      <StyledButtonWrapper>
        <Button onClick={handleSubmit} fullWidth>
          {translations.RESTORE_PASSWORD_TITLE}
        </Button>
      </StyledButtonWrapper>
    </StyledForm>
  )

  const onSubmit = (values: RestorePasswordValues) => {
    dispatch(fetchRestorePassword(linkId, code, values.password))
  }

  return (
    <>
      <AuthorizationBox title={<StyledTitle>{translations.RESTORE_PASSWORD_TITLE}</StyledTitle>}>
        <FormWrapper>
          <Box mb={15}>
            <Formik
              initialValues={initialValues}
              onSubmit={onSubmit}
              validationSchema={restorePasswordValidationSchema}
            >
              {renderForm}
            </Formik>
          </Box>
        </FormWrapper>
      </AuthorizationBox>
      <RestorePasswordModals />
    </>
  )
}
