import React from 'react'
import { useTheme } from 'styled-components'

import { StyledThemeScheme } from '../../../../../themes/light'
import { getFeature } from '../../../../../utils/subscription/getFeature'
import useMediaQuery from '../../../../hooks/useMediaQuery'
import Box from '../../../../ui/Box'
import { BulletList } from '../../../../ui/BulletList'
import Typography from '../../../../ui/Typography'
import { TYPOGRAPHY_NAMES } from '../../../../ui/Typography/Typography.types'

import { StyledPlan } from './Plan.styles'
import { PlanProps } from './Plan.types'

export const Plan: React.FC<PlanProps> = ({ data: { name, features } }) => {
  const featuresLabels = features.map((feature) => getFeature(feature, true))
  const theme = useTheme() as StyledThemeScheme
  const mobileScreen = useMediaQuery(theme.devices.mobile)

  return (
    <StyledPlan>
      <Box mb={3}>
        <Typography name={TYPOGRAPHY_NAMES.H1}>{name}</Typography>
      </Box>
      <BulletList items={featuresLabels} mbItem={2} columnsCount={mobileScreen ? 1 : 2} />
    </StyledPlan>
  )
}
