import React, { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { modalsPayloadSelector } from '../../../../../store/modals/modals.selectors'
import translations from '../../../../../translations/keys'
import { SIGNATURES_MODALS } from '../../../../constants/signatures'
import { NOTIFICATION_MODAL_TYPES } from '../../../../ui/Modals/NotificationModal/NotificationModal.constants'
import { NotificationModalView } from '../../../../ui/Modals/NotificationModal/NotificationModalView'
import { closeModal, openModal } from '../../../../../store/modals/modals.actions'

import { DeleteSignatureModalProps } from './DeleteSignatureModal.types'

export const DeleteSignatureModal: FC<DeleteSignatureModalProps> = ({ onClose }) => {
  const dispatch = useDispatch()
  const { id } = useSelector(modalsPayloadSelector(SIGNATURES_MODALS.DELETE_SIGNATURE))

  const onSuccess = () => {
    dispatch(openModal(SIGNATURES_MODALS.DELETE_SIGNATURE_VERIFICATION, { id }))
    dispatch(closeModal(SIGNATURES_MODALS.DELETE_SIGNATURE))
  }

  return (
    <NotificationModalView
      type={NOTIFICATION_MODAL_TYPES.WARNING}
      onClose={onClose}
      description={translations.SIGNATURE_DELETE_CONFIRMATION}
      successButtonText={translations.DELETE}
      onSuccess={onSuccess}
    />
  )
}
