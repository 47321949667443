import React from 'react'

import translations from '../../../translations/keys'

import { StyledTextArea, StyledWrapper, StyledLabel, StyledError } from './TextArea.styles'
import { TextAreaProps } from './TextArea.types'

export const TextArea: React.FC<TextAreaProps> = ({ label, disabled, height = 40, error, ...props }) => (
  <StyledWrapper>
    {label && (
      <StyledLabel disabled={disabled} test-id="textarea-label">
        {label}
      </StyledLabel>
    )}
    <StyledTextArea height={height} isError={!!error} {...props} test-id="textarea-field" />
    {!!error && <StyledError test-id="textarea-error">{translations[error] || error}</StyledError>}
  </StyledWrapper>
)
