import * as yup from 'yup'

import { AddContactFields } from '../../types/addContact'
import { departmentValidationSchema } from '../departmentValidationSchema'
import { emailValidationSchema } from '../emailValidationSchema'
import { nameValidationSchema } from '../nameValidationSchema'
import { phoneValidationSchema } from '../phoneValidationSchema'

export const addContactValidationSchema = yup.object().shape({
  [AddContactFields.fullName]: nameValidationSchema,
  [AddContactFields.email]: emailValidationSchema,
  ...phoneValidationSchema(AddContactFields.phone, false).fields,
  [AddContactFields.department]: departmentValidationSchema,
})
