import styled from 'styled-components'

import Box from '../../Box'

export const Wrapper = styled(Box).attrs({
  width: 200,
  mb: 3,
})`
  background-color: ${({ theme }) => theme.palette.blueExtraLight};
  border-radius: 4px;
`
export const Label = styled(Box).attrs({
  height: 32,
  mb: 1,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
})`
  color: ${({ theme }) => theme.palette.white};
  background-color: ${({ theme }) => theme.palette.blue};
  border-radius: 4px 4px 0 0;
`
export const Department = styled(Box).attrs({
  pb: 1,
  textAlign: 'center',
})`
  color: ${({ theme }) => theme.palette.grey};
`
export const FullName = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`
