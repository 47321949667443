import { Action } from 'redux'

export enum BannerActions {
  'OPEN_BANNER' = 'OPEN_BANNER',
  'CLOSE_BANNER' = 'CLOSE_BANNER',
}

export interface BannerState {
  visible: boolean
}

export type OpenBannerAction = Action

export type CloseBannerAction = Action
