import React from 'react'
import { Switch, BrowserRouter, Redirect } from 'react-router-dom'

import {
  Registration,
  ConfirmEmail,
  Login,
  ForgotPassword,
  RestorePassword,
  ConfirmPhone,
  Error403,
  Error404,
  Faq,
  LegalNotice,
  Terms,
  ContractUnregisteredSign,
  CardSpecification,
  SelectSubscription,
  PrivacyPolicy,
} from '../pages'
import { location } from '../../utils/location'
import ProvideAuth from '../../providers/AuthProvider'
import CommonModals from '../ui/Modals/CommonModals'
import { AuthorizationGlobalStyle } from '../components/Authorization/AuthorizationTemplate/AuthorizationTemplate.styles'
import OpenInAppBanner from '../components/OpenInAppBanner'

import { UnauthorizedRoute } from './components/UnauthorizedRoute'
import { PrivateRoute } from './components/PrivateRoute'
import ProfileRoutes from './ProfileRoutes'
import ContractsRoutes from './ContractsRoutes'
import TemplatesRoutes from './TemplatesRoutes'
import PreferencesRoutes from './PreferencesRoutes'
import PublicRoute from './components/PublicRoute'
import PaymentRoutes from './PaymentRoutes'

export const Router = () => (
  <BrowserRouter>
    <ProvideAuth>
      <OpenInAppBanner>
        <Switch>
          <UnauthorizedRoute exact path={location.loginUrl()} logoutQueryParams={['emailChanged']}>
            <Login />
          </UnauthorizedRoute>
          <UnauthorizedRoute exact path={location.registrationUrl()}>
            <Registration />
          </UnauthorizedRoute>
          <UnauthorizedRoute exact path={location.confirmEmailUrl()}>
            <ConfirmEmail />
          </UnauthorizedRoute>
          <UnauthorizedRoute exact path={location.forgotPasswordUrl()}>
            <ForgotPassword />
          </UnauthorizedRoute>
          <UnauthorizedRoute exact path={location.restorePasswordUrl()}>
            <RestorePassword />
          </UnauthorizedRoute>
          <UnauthorizedRoute exact path={location.confirmPhone()}>
            <ConfirmPhone />
          </UnauthorizedRoute>
          <PrivateRoute
            exact
            path={location.cardSpecification()}
            loader={<AuthorizationGlobalStyle />}
            ignoreSubscriptionError
          >
            <CardSpecification />
          </PrivateRoute>
          <PrivateRoute exact path={location.subscriptionSelectUrl()} ignoreSubscriptionError>
            <SelectSubscription />
          </PrivateRoute>
          <PrivateRoute path={location.profileUrl()}>
            <ProfileRoutes />
          </PrivateRoute>
          <PrivateRoute path={location.contractsUrl()}>
            <ContractsRoutes />
          </PrivateRoute>
          <PrivateRoute path={location.templatesUrl()}>
            <TemplatesRoutes />
          </PrivateRoute>
          <PrivateRoute path={location.preferencesUrl()}>
            <PreferencesRoutes />
          </PrivateRoute>
          <PrivateRoute exact path={location.error403Url()}>
            <Error403 />
          </PrivateRoute>
          <PrivateRoute exact path={location.error404Url()}>
            <Error404 />
          </PrivateRoute>
          <PrivateRoute exact path={location.faqUrl()}>
            <Faq />
          </PrivateRoute>
          <PublicRoute exact path={location.legalNoticeUrl()}>
            <LegalNotice />
          </PublicRoute>
          <PublicRoute exact path={location.termsUrl()}>
            <Terms />
          </PublicRoute>
          <PublicRoute exact path={location.contractsUnregisteredSignUrl()}>
            <ContractUnregisteredSign />
          </PublicRoute>
          <PublicRoute path={location.paymentUrl()}>
            <PaymentRoutes />
          </PublicRoute>
          <PublicRoute path={location.privacyUrl()}>
            <PrivacyPolicy />
          </PublicRoute>
          <Redirect to={location.loginUrl()} />
        </Switch>
      </OpenInAppBanner>
    </ProvideAuth>
    <CommonModals />
  </BrowserRouter>
)
