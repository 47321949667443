import { Form as FormikForm } from 'formik'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { addYears, startOfTomorrow } from 'date-fns'
import { useTranslation } from 'react-i18next'

import { openModal } from '../../../../../../../store/modals/modals.actions'
import translations from '../../../../../../../translations/keys'
import { Contact } from '../../../../../../../types/contacts'
import {
  AddRecipientsFields,
  RecipientActions,
  RecipientAuthentication,
  RecipientAuthOptions,
  RecipientSignatureTypes,
} from '../../../../../../../types/recipients'
import { importContactIntoRecipient } from '../../../../../../../utils/recipients'
import { DEPARTMENT_MAX_LENGTH } from '../../../../../../../validation/departmentValidationSchema'
import { EMAIL_MAX_LENGTH } from '../../../../../../../validation/emailValidationSchema'
import { NAME_MAX_LENGTH } from '../../../../../../../validation/nameValidationSchema'
import { ADD_RECIPIENTS_MODALS } from '../../../../../../constants/addRecipients'
import { getRecipientsActionsOptions } from '../../../../../../utils/recipients'
import Button from '../../../../../../ui/Button'
import FormCheckbox from '../../../../../../ui/Forms/FormCheckbox'
import FormDatePicker from '../../../../../../ui/Forms/FormDatePicker'
import FormDropdown from '../../../../../../ui/Forms/FormDropdown'
import FormNumber from '../../../../../../ui/Forms/FormNumber'
import FormPhone from '../../../../../../ui/Forms/FormPhone'
import FormRadioButton from '../../../../../../ui/Forms/FormRadioButton'
import FormTextInput from '../../../../../../ui/Forms/FormTextInput'
import Typography from '../../../../../../ui/Typography'
import { TYPOGRAPHY_NAMES } from '../../../../../../ui/Typography/Typography.types'
import Box from '../../../../../../ui/Box'
import { Modals } from '../../Modals'
import { SIGNING_TYPES } from '../../../../../../../types/signingOrder'

import { FormProps } from './Form.types'
import { StyledField } from './Form.styles'

const TOTAL_FIELDS_COUNT = 9

export const Form: React.FC<FormProps> = ({
  values,
  templatesMode,
  order,
  hiddenFields = {},
  disabledFields = {},
  setValues,
  setFieldValue,
  onConfirmUpdate,
  onCloseRecipientModal,
}) => {
  const dispatch = useDispatch()
  const isPhoneVisible = values[AddRecipientsFields.recipientAuthentication] === RecipientAuthentication.OTP
  const nameAndEmailDisabled = templatesMode && values[AddRecipientsFields.recipientNoEmailAndName]
  const maxDeadline = addYears(startOfTomorrow(), 1)
  const orderDisabled = order === SIGNING_TYPES.SEPARATE
  const deadline = values[AddRecipientsFields.recipientDeadline]
  const isAllFieldsDisabled = Object.keys(disabledFields).length == TOTAL_FIELDS_COUNT
  const isDeadlineEnabled =
    values[AddRecipientsFields.recipientAction] !== RecipientActions.VIEW &&
    !disabledFields[AddRecipientsFields.recipientDeadline]
  const { t } = useTranslation()
  const openContacts = () => {
    dispatch(openModal(ADD_RECIPIENTS_MODALS.ADD_RECIPIENTS_CONTACTS))
  }
  const handleSelectContact = (contact: Contact) => {
    const newValues = importContactIntoRecipient(values, contact)
    setValues(newValues)
  }

  useEffect(() => {
    if (templatesMode) {
      return
    } else if (isDeadlineEnabled && !deadline) {
      setFieldValue(AddRecipientsFields.recipientDeadline, startOfTomorrow())
    } else if (!isDeadlineEnabled && deadline) {
      setFieldValue(AddRecipientsFields.recipientDeadline, null)
    }
  }, [isDeadlineEnabled, deadline, setFieldValue, templatesMode])

  return (
    <>
      <FormikForm>
        <Box display="flex" flexDirection="column" alignItems="center">
          <Box display="flex" width="100%">
            {!hiddenFields[AddRecipientsFields.recipientName] && (
              <Box width="50%" mr={3}>
                <FormTextInput
                  disabled={disabledFields[AddRecipientsFields.recipientName] || nameAndEmailDisabled}
                  name={AddRecipientsFields.recipientName}
                  label={t(translations.RECIPIENT_NAME_LABEL)}
                  placeholder={t(translations.RECIPIENT_NAME_PLACEHOLDER)}
                  icon="contact"
                  iconColor="grey"
                  maxLength={NAME_MAX_LENGTH}
                  onClickIcon={openContacts}
                />
              </Box>
            )}
            {!hiddenFields[AddRecipientsFields.recipientEmail] && (
              <Box width="50%">
                <FormTextInput
                  disabled={disabledFields[AddRecipientsFields.recipientEmail] || nameAndEmailDisabled}
                  name={AddRecipientsFields.recipientEmail}
                  label={t(translations.RECIPIENT_EMAIL_LABEL)}
                  placeholder={t(translations.RECIPIENT_EMAIL_PLACEHOLDER)}
                  icon="contact"
                  iconColor="grey"
                  maxLength={EMAIL_MAX_LENGTH}
                  onClickIcon={openContacts}
                  trim
                />
              </Box>
            )}
          </Box>
          {templatesMode && (
            <Box mt={0} mb={3} width="100%">
              <FormCheckbox
                name={AddRecipientsFields.recipientNoEmailAndName}
                label={t(translations.RECIPIENTS_WITHOUT_EMAIL_AND_NAME)}
              />
            </Box>
          )}
          <Box display="flex" width="100%">
            {!hiddenFields[AddRecipientsFields.recipientAction] && (
              <Box width="50%" mr={3}>
                <FormDropdown
                  disabled={disabledFields[AddRecipientsFields.recipientAction]}
                  name={AddRecipientsFields.recipientAction}
                  label={t(translations.CHOOSE_ACTION)}
                  options={getRecipientsActionsOptions}
                  autoFill={false}
                />
              </Box>
            )}
            <Box display="flex" justifyContent="space-between" width="50%">
              {!hiddenFields[AddRecipientsFields.recipientCycleOrder] && (
                <Box width={templatesMode ? '100%' : '50%'} mr={templatesMode ? 0 : 4}>
                  <FormNumber
                    disabled={disabledFields[AddRecipientsFields.recipientCycleOrder] || orderDisabled}
                    name={AddRecipientsFields.recipientCycleOrder}
                    label={t(translations.CYCLE_ORDER)}
                    help={
                      <Typography name={TYPOGRAPHY_NAMES.bodySmall12} color="textColor">
                        {t(translations.CYCLE_ORDER_HELP)}
                      </Typography>
                    }
                  />
                </Box>
              )}
              {!hiddenFields[AddRecipientsFields.recipientDeadline] && !templatesMode && (
                <Box width="50%">
                  <FormDatePicker
                    disabled={!isDeadlineEnabled}
                    minDate={startOfTomorrow()}
                    maxDate={maxDeadline}
                    name={AddRecipientsFields.recipientDeadline}
                    label={t(translations.DEADLINE_SLA_LABEL)}
                    placeholder={isDeadlineEnabled ? t(translations.DEADLINE_SLA_PLACEHOLDER) : undefined}
                    help={
                      <Typography name={TYPOGRAPHY_NAMES.bodySmall12} color="textColor">
                        {t(translations.DEADLINE_SLA_HELP)}
                      </Typography>
                    }
                  />
                </Box>
              )}
            </Box>
          </Box>
          <Box display="flex" width="100%" flexWrap="wrap">
            {!hiddenFields[AddRecipientsFields.recipientSignatureType] && values.action !== RecipientActions.VIEW && (
              <StyledField display="flex" alignItems="center" flexDirection="column" width="50%">
                <Box width="100%" mb={1.5}>
                  <Typography
                    name={TYPOGRAPHY_NAMES.body14}
                    color={disabledFields[AddRecipientsFields.recipientSignatureType] ? 'grey' : 'black'}
                  >
                    {values.action === RecipientActions.SIGN
                      ? t(translations.SIGNATURE_FIELD)
                      : t(translations.INITIALS_FIELD)}
                  </Typography>
                </Box>
                <Box width="100%" display="flex">
                  <Box mr={4}>
                    <FormRadioButton
                      disabled={disabledFields[AddRecipientsFields.recipientSignatureType]}
                      name={AddRecipientsFields.recipientSignatureType}
                      label={t(translations.LAST_PAGE)}
                      id={RecipientSignatureTypes.LAST_PAGE}
                    />
                  </Box>
                  <Box>
                    <FormRadioButton
                      disabled={disabledFields[AddRecipientsFields.recipientSignatureType]}
                      name={AddRecipientsFields.recipientSignatureType}
                      label={t(translations.EVERY_PAGE)}
                      id={RecipientSignatureTypes.EVERY_PAGE}
                    />
                  </Box>
                </Box>
              </StyledField>
            )}
            {!hiddenFields[AddRecipientsFields.recipientDepartment] && (
              <StyledField>
                <FormTextInput
                  disabled={disabledFields[AddRecipientsFields.recipientDepartment]}
                  name={AddRecipientsFields.recipientDepartment}
                  label={`${t(translations.DEPARTMENT_LABEL)} (${t(translations.OPTIONAL)})`}
                  placeholder={t(translations.DEPARTMENT_PLACEHOLDER)}
                  maxLength={DEPARTMENT_MAX_LENGTH}
                />
              </StyledField>
            )}
            {!hiddenFields[AddRecipientsFields.recipientAuthentication] && (
              <StyledField>
                <FormDropdown
                  disabled={disabledFields[AddRecipientsFields.recipientAuthentication]}
                  autoFill={false}
                  name={AddRecipientsFields.recipientAuthentication}
                  label={t(translations.AUTHENTICATION)}
                  options={RecipientAuthOptions}
                />
              </StyledField>
            )}
            {!hiddenFields[AddRecipientsFields.recipientPhone] && (
              <StyledField>
                {isPhoneVisible && (
                  <FormPhone
                    disabled={disabledFields[AddRecipientsFields.recipientPhone]}
                    name={AddRecipientsFields.recipientPhone}
                    label={t(translations.PHONE_LABEL)}
                  />
                )}
              </StyledField>
            )}
          </Box>
          {!isAllFieldsDisabled && (
            <Box width={320} mt={2}>
              <Button type="submit" fullWidth>
                {translations.SAVE}
              </Button>
            </Box>
          )}
        </Box>
      </FormikForm>
      <Modals
        onSelectContact={handleSelectContact}
        onConfirmUpdate={onConfirmUpdate}
        onCloseRecipientModal={onCloseRecipientModal}
      />
    </>
  )
}
