import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { useDispatchUnmount } from '../useDispatchUnmount'
import { clearComments, fetchComments } from '../../store/contracts/comments/comments.actions'

export const useCommentsFetch = (id: string) => {
  const dispatch = useDispatch()

  useEffect(() => {
    if (id) {
      dispatch(fetchComments(id))
    }
  }, [id])

  useDispatchUnmount(clearComments)
}
