import React from 'react'

import { SvgIconProps } from '../../ui/SvgIcon/SvgIcon.types'
import SvgIcon from '../../ui/SvgIcon'

export const PlayIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <path d="M19 10.2546C20.3333 11.0303 20.3333 12.9697 19 13.7454L7 20.727C5.66667 21.5027 4 20.533 4 18.9816L4 5.01842C4 3.46695 5.66667 2.49729 7 3.27302L19 10.2546ZM6 18.9816L18 12L6 5.01842L6 18.9816Z" />
  </SvgIcon>
)
