import * as yup from 'yup'

import { emailValidationSchema } from '../../validation/emailValidationSchema'
import { passwordValidationSchema } from '../../validation/passwordValidationSchema'
import { phoneValidationSchema } from '../../validation/phoneValidationSchema'
import { nameValidationSchema } from '../../validation/nameValidationSchema'
import { countryValidationSchema } from '../../validation/countryValidationSchema'
import { industryValidationSchema } from '../../validation/industryValidationSchema'
import { RegistrationFields } from '../../types/registration'

export const registrationValidationSchema = yup.object().shape({
  [RegistrationFields.email]: emailValidationSchema,
  [RegistrationFields.firstName]: nameValidationSchema,
  [RegistrationFields.lastName]: nameValidationSchema,
  [RegistrationFields.country]: countryValidationSchema,
  [RegistrationFields.industry]: industryValidationSchema,
  [RegistrationFields.accepted]: yup.boolean().required('PLEASE_ACCEPT_TERMS').oneOf([true], 'PLEASE_ACCEPT_TERMS'),
  ...passwordValidationSchema(RegistrationFields.password, RegistrationFields.confirmPassword).fields,
  ...phoneValidationSchema(RegistrationFields.phone).fields,
})
