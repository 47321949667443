export interface ForgotPasswordBody {
  email: string
}

export enum FORGOT_PASSWORD_NOTIFICATIONS {
  NOTIFICATION_CHECK_EMAIL = 'NOTIFICATION_CHECK_EMAIL',
  NOTIFICATION_VERIFY_EMAIL = 'NOTIFICATION_VERIFY_EMAIL',
}

export enum ForgotPasswordFields {
  email = 'email',
}
