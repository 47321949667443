import styled from 'styled-components'

import Box from '../Box'
import { ConfirmationStatus } from '../../../types/confirmationActions'

import { ConfirmationLabelProps } from './ConfirmationLabel.types'

export const Wrapper = styled(Box).attrs({
  pl: 1,
  pr: 1,
  display: 'inline-flex',
  alignItems: 'center',
})<Pick<ConfirmationLabelProps, 'status' | 'size'> & { isContractOwner: boolean }>`
  background-color: ${({ theme, status }) =>
    status === ConfirmationStatus.ACCEPTED ? theme.palette.green : theme.palette.errorColor};
  height: ${({ size, isContractOwner }) => (size === 'tall' && isContractOwner ? 'auto' : '28px')};
  width: ${({ size }) => (size === 'tall' ? '100%' : 'auto')};
  border-radius: 4px;
  cursor: default;
`

export const User = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${({ theme }) => theme.palette.white};
`

export const StyledDate = styled.span<Pick<ConfirmationLabelProps, 'size'> & { isContractOwner: boolean }>`
  color: ${({ theme }) => theme.palette.greyLight};
  display: ${({ size, isContractOwner }) => (size === 'tall' && isContractOwner ? 'block' : 'initial')};
  margin-left: ${({ size }) => (size === 'tall' ? 'auto' : '10px')};
`

export const Cancel = styled.div<Pick<ConfirmationLabelProps, 'size'>>`
  ${({ size }) =>
    size === 'tall'
      ? `
        display: block;
        position: absolute;
        right: 24px;`
      : `
        display: initial;
        position: initial;
        right: 0;
  `};
`
