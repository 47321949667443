import { FETCH_STATUSES } from '../../../types/fetch'
import { RootState } from '../../rootReducer.types'

export const saveAsTemplateIsSuccessSelector = (state: RootState) =>
  state.contracts.saveAsTemplate.fetchStatus === FETCH_STATUSES.SUCCESS

export const saveAsTemplateFetchStatusSelector = (state: RootState) => state.contracts.saveAsTemplate.fetchStatus

export const saveAsTemplateErrorSelector = (state: RootState) => state.contracts.saveAsTemplate.error

export const saveAsTemplateIdSelector = (state: RootState) => state.contracts.saveAsTemplate.id
