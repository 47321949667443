import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { cleanVerifyPassword, fetchVerifyPassword } from '../../../store/verifyPassword/verifyPassword.actions'
import translations from '../../../translations/keys'
import AccessGuard from '../../components/AccessGuard'
import { AccessGuardFormSubmit } from '../../components/AccessGuardForm/AccessGuardForm.types'
import { VerifyPasswordKeys } from '../../../types/verifyPassword'
import {
  verifyPasswordIsFailureSelector,
  verifyPasswordSuccessSelector,
} from '../../../store/verifyPassword/verifyPassword.selectors'
import { openModal } from '../../../store/modals/modals.actions'
import { SIGNATURES_MODALS } from '../../constants/signatures'
import { COMMON_MODALS_IDS } from '../../ui/Modals/CommonModals/CommonModals.constants'
import { NOTIFICATION_MODAL_TYPES } from '../../ui/Modals/NotificationModal/NotificationModal.constants'
import { signaturesListDataSelector } from '../../../store/signatures/signatures.selectors'
import { useDispatchUnmount } from '../../../hooks/useDispatchUnmount'
import { cleanSignaturesList } from '../../../store/signatures/signatures.actions'
import { useAccessFeature } from '../../../hooks/subscription/useAccessFeature'
import { SubscriptionFeatures } from '../../../types/subscription'

import { StyledLoadingWrapper } from './Signatures.styles'
import { SignaturesModals } from './components/SignaturesModals/SignaturesModals'
import { SignaturesContent } from './components/SignaturesContent/SignaturesContent'

const LoadingWrapper: React.FC = ({ children }) => <StyledLoadingWrapper>{children}</StyledLoadingWrapper>

export const Signatures = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const access = useSelector(verifyPasswordSuccessSelector(VerifyPasswordKeys.SIGNATURES_PAGE))
  const isError = useSelector(verifyPasswordIsFailureSelector(VerifyPasswordKeys.SIGNATURES_PAGE))
  const signaturesListData = useSelector(signaturesListDataSelector)
  const { checkAccess } = useAccessFeature()

  const onSubmit: AccessGuardFormSubmit = (values) => {
    dispatch(fetchVerifyPassword(VerifyPasswordKeys.SIGNATURES_PAGE, values))
  }
  const onAddSignature = () => {
    if (checkAccess(SubscriptionFeatures.FEATURE_SIGNATURE_MATCHING, false)) {
      dispatch(openModal(SIGNATURES_MODALS.SMART_SIGNATURE))
    } else if (signaturesListData.length > 1) {
      dispatch(
        openModal(COMMON_MODALS_IDS.NOTIFICATION, {
          type: NOTIFICATION_MODAL_TYPES.WARNING,
          description: t(translations.REACHED_MAX_NUMBER_OF_SIGNATURES),
        })
      )
    } else {
      dispatch(openModal(SIGNATURES_MODALS.CREATE_SIGNATURE))
    }
  }

  const onCleanVerifyPassword = useCallback(() => cleanVerifyPassword(VerifyPasswordKeys.SIGNATURES_PAGE), [])

  useDispatchUnmount(onCleanVerifyPassword, cleanSignaturesList)

  return (
    <>
      <AccessGuard
        access={access}
        onSubmit={onSubmit}
        title={t(translations.PLEASE_ENTER_PASSWORD)}
        description={t(translations.ACCESS_TO_SIGNATURE_DESCRIPTION)}
        submitText={t(translations.CONTINUE)}
        isError={isError}
        Wrapper={LoadingWrapper}
      >
        <SignaturesContent onAddSignature={onAddSignature} />
      </AccessGuard>
      <SignaturesModals />
    </>
  )
}
