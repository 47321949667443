import { FETCH_STATUSES } from '../../../types/fetch'
import { RootState } from '../../rootReducer.types'

export const subscriptionPlansDataSelector = (state: RootState) => state.subscription.plans.list.data
export const subscriptionPlansFetchStatusSelector = (state: RootState) => state.subscription.plans.list.fetchStatus
export const subscriptionPlansErrorSelector = (state: RootState) => state.subscription.plans.list.error
export const subscriptionPlansIsLoadingSelector = (state: RootState) =>
  state.subscription.plans.list.fetchStatus === FETCH_STATUSES.IDLE ||
  state.subscription.plans.list.fetchStatus === FETCH_STATUSES.REQUEST

export const subscriptionPlanDataSelector = (state: RootState) => state.subscription.plans.plan.data
