import { RootState } from '../../rootReducer.types'

export const recipientsTemplateIdSelector = (state: RootState) => state.templates.recipients.templateId
export const recipientsTemplateNameSelector = (state: RootState) => state.templates.recipients.templateName
export const recipientsSenderSelector = (state: RootState) => state.templates.recipients.sender
export const recipientsOrderSelector = (state: RootState) => state.templates.recipients.order
export const recipientsByIdSelector = (state: RootState) => state.templates.recipients.byId
export const recipientsByOrderSelector = (state: RootState) => state.templates.recipients.byOrder
export const recipientsSortedSelector = (state: RootState) => state.templates.recipients.sorted
export const recipientsFetchInfoSelector = (state: RootState) => state.templates.recipients.fetchTemplateInfo
export const recipientsFetchSelector = (state: RootState) => state.templates.recipients.fetch
export const recipientsFetchAddSelector = (state: RootState) => state.templates.recipients.add
export const recipientsFetchAddClmOnlySelector = (state: RootState) => state.templates.recipients.addClmOnly
export const recipientsIsDocumentExistsSelector = (state: RootState) => state.templates.recipients.isDocumentExists
export const recipientsMessageInitialValuesSelector = (state: RootState) =>
  state.templates.recipients.messageInitialValues
