import { useDispatch } from 'react-redux'
import { useEffect } from 'react'

import { SigningNotificationMessages } from '../../store/contracts/signing/signing.types'
import { ConfirmationStatus } from '../../types/confirmationActions'
import { Variant } from '../../types/notifications'
import * as SIGNING_ACTIONS from '../../store/contracts/signing/signing.actions'

export const useCancelConfirmationNotification = (isCanceled: boolean, cancelStatus: ConfirmationStatus | null) => {
  const dispatch = useDispatch()

  useEffect(() => {
    if (isCanceled && cancelStatus) {
      dispatch(
        SIGNING_ACTIONS.setSigningNotification({
          variant: Variant.SUCCESS,
          message:
            cancelStatus === ConfirmationStatus.ACCEPTED
              ? SigningNotificationMessages.CANCEL_ACCEPTATION_SUCCESSFULLY
              : SigningNotificationMessages.CANCEL_REJECTION_SUCCESSFULLY,
        })
      )
    }
  }, [isCanceled, cancelStatus])
}
