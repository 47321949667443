import React from 'react'
import { useTheme } from 'styled-components'

import { StyledThemeScheme } from '../../../themes/light'
import { mainMenu } from '../../routes/menus'
import ClickOutsideListener from '../../ui/ClickOutsideListener'
import useMediaQuery from '../../hooks/useMediaQuery'
import useNavControls from '../../hooks/useNavControls'
import { CrossIcon } from '../../assets/icons'

import * as Styled from './MobileMenu.styles'
import { MobileMenuLink } from './MobileMenuLink'

export const MobileMenu: React.FC = () => {
  const { mainmenuOpen, setMainmenuOpen } = useNavControls()
  const theme = useTheme() as StyledThemeScheme
  const tabletScreen = useMediaQuery(theme.devices.tablet)

  const handleMenuClose = () => {
    if (mainmenuOpen) {
      setMainmenuOpen(false)
    }
  }

  return (
    <ClickOutsideListener onClickAway={handleMenuClose}>
      <Styled.Nav data-testid="menu-wrapper" open={mainmenuOpen}>
        <Styled.Content data-testid="menu-content">
          {tabletScreen && (
            <Styled.CloseButton onClick={handleMenuClose}>
              <CrossIcon color="grey" />
            </Styled.CloseButton>
          )}
          <Styled.List data-testid="menu-navigation">
            {mainMenu.map((item) => (
              <MobileMenuLink key={item.name} {...item} />
            ))}
          </Styled.List>
          <Styled.Footer data-testid="menu-footer">
            {/* TODO UQD-1410 uncomment when this will work */}
            {/* <Styled.MenuLink disabled to={location.rootUrl()} onClick={() => {}}>
              <Styled.IconWrapper>
                <SupportIcon />
              </Styled.IconWrapper>
              {translations.SUPPORT}
            </Styled.MenuLink> */}
          </Styled.Footer>
        </Styled.Content>
      </Styled.Nav>
    </ClickOutsideListener>
  )
}
