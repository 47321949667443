import React from 'react'

export const ContactsIcon: React.FC = (props) => (
  <svg width="156" height="96" viewBox="0 0 156 96" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M96.4293 76C95.2476 76 94.2565 75.042 94.2565 73.8159V69.3328C93.761 68.2983 89.9491 65.7693 83.0114 63.3171C79.2758 62.0143 77.4079 61.9377 73.7485 62.9722C66.3534 65.0796 62.3128 67.3403 61.7029 68.3366V73.7776C61.7029 74.9654 60.7499 75.9617 59.5301 75.9617C58.3484 75.9617 57.3573 75.0037 57.3573 73.7776V68.1833C57.3192 66.9572 57.2811 63.1255 72.5287 58.7574C76.9886 57.4929 79.8857 57.5695 84.3837 59.1788C90.8639 61.4395 98.6021 65.0413 98.5259 69.2179V73.7393C98.6021 75.0037 97.611 76 96.4293 76Z"
      fill="#6085B3"
    />
    <path
      d="M78.3526 33.1136C73.7922 33.1136 70.0365 37.5975 69.9216 42.5494C69.8449 47.4232 73.294 52.375 78.3526 52.375C82.913 52.375 86.6687 47.8911 86.7836 42.9393C86.8986 38.0654 83.4112 33.1136 78.3526 33.1136ZM78.3526 56.2351C70.8413 56.1571 65.4378 49.6846 65.5911 42.3544C65.7444 35.0242 71.4545 29.0586 78.3526 29.0586C85.8639 29.1366 91.2674 35.609 91.1141 42.9003C90.9992 50.2695 85.2507 56.2351 78.3526 56.2351Z"
      fill="#6085B3"
    />
    <path
      d="M33.1769 66.1176C31.995 66.1176 31.0038 65.1815 31.0038 63.9832V58.0668C30.9657 56.8685 30.9276 53.124 46.1773 48.8551C50.6378 47.6194 53.5353 47.6943 58.0339 49.267C58.9871 49.6041 59.9783 49.9411 60.9314 50.3155C62.037 50.7649 62.5707 52.0006 62.1514 53.0865C61.6939 54.1724 60.4358 54.6967 59.3302 54.2848C58.4152 53.9478 57.5002 53.6107 56.6233 53.2737C52.9253 52.0006 51.0191 51.9257 47.3591 52.9367C39.963 54.9962 35.9218 57.2055 35.3119 58.1791V64.0207C35.35 65.1815 34.3969 66.1176 33.1769 66.1176Z"
      fill="#DFE1E6"
    />
    <path
      d="M51.1385 23.9321C46.5781 23.9321 42.8224 28.2802 42.7075 33.0819C42.6308 37.8081 46.0799 42.6098 51.1385 42.6098C55.6989 42.6098 59.4546 38.2618 59.5695 33.46C59.6845 28.7339 56.2354 23.9321 51.1385 23.9321ZM51.1768 46.3529C43.6655 46.2773 38.262 40.001 38.4153 32.8929C38.5686 25.7848 44.2787 20 51.1768 20C58.6881 20.0756 64.0916 26.3519 63.9383 33.4222C63.785 40.5682 58.0749 46.3529 51.1768 46.3529Z"
      fill="#DFE1E6"
    />
    <path
      d="M123.568 66.1176C122.387 66.1176 121.396 65.1808 121.396 63.9817V58.1736C120.787 57.1993 116.747 54.9885 109.355 52.9275C105.697 51.9158 103.792 51.9907 100.096 53.2648C99.2192 53.5645 98.3047 53.9018 97.3902 54.2765C96.2852 54.7262 95.0277 54.1641 94.5705 53.0774C94.1132 51.9907 94.6848 50.7541 95.7898 50.3045C96.7424 49.9297 97.7332 49.555 98.6858 49.2553C103.182 47.7189 106.078 47.6065 110.536 48.8431C125.816 53.0774 125.74 56.8621 125.702 58.0986V64.0192C125.702 65.1808 124.75 66.1176 123.568 66.1176Z"
      fill="#DFE1E6"
    />
    <path
      d="M105.53 23.9321C100.97 23.9321 97.2143 28.2802 97.0993 33.0819C97.0226 37.8081 100.472 42.6098 105.53 42.6098C110.091 42.6098 113.846 38.2618 113.961 33.46C114.038 28.7339 110.589 23.9321 105.53 23.9321ZM105.53 46.3529C98.019 46.2773 92.6155 40.001 92.7688 32.8929C92.9221 25.7848 98.6322 20 105.53 20C113.042 20.0756 118.445 26.3519 118.292 33.4222C118.139 40.5682 112.428 46.3529 105.53 46.3529Z"
      fill="#DFE1E6"
    />
  </svg>
)
