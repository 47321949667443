import React, { FC, useMemo } from 'react'

import { PHONE_MAX_LENGTH } from '../../../validation/phoneValidationSchema'
import Number from '../Number'

import { PhoneProps } from './Phone.types'

const PLUS = '+'

export const Phone: FC<PhoneProps> = ({ value, name, onChange, ...rest }) => {
  const preparedValue = useMemo(() => (value && value.length && value[0] === PLUS ? value.slice(1) : value), [value])

  const onChangeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = String(e.target.value)

    Object.defineProperty(e, 'target', {
      writable: false,
      value: { value, name },
    })

    onChange && onChange(e)
  }

  return (
    <Number
      {...rest}
      prefix={PLUS}
      name={name}
      placeholder="00000000000"
      value={preparedValue}
      onChange={onChangeValue}
    />
  )
}

Phone.defaultProps = {
  maxLength: PHONE_MAX_LENGTH,
}
