import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { FetchResponses } from '../../../constants/fetchResponses'
import { location } from '../../../../utils/location'
import { useShowFetchError } from '../../../hooks/useShowFetchError'
import * as RECIPIENTS_SELECTORS from '../../../../store/contracts/recipients/recipients.selectors'
import * as OWNER_VIEW_SELECTORS from '../../../../store/contracts/ownerView/ownerView.selectors'
import * as STRIKES_SELECTORS from '../../../../store/contracts/strikes/strikes.selectors'
import * as CORRECTIONS_SELECTORS from '../../../../store/contracts/corrections/corrections.selectors'

export const useErrors = () => {
  const history = useHistory()
  const error = useSelector(OWNER_VIEW_SELECTORS.ownerViewContractErrorSelector)
  const resendFetchStatus = useSelector(RECIPIENTS_SELECTORS.recipientsFetchResendStatusSelector)
  const resendError = useSelector(RECIPIENTS_SELECTORS.recipientsFetchResendErrorSelector)
  const strikeConfirmFetchStatus = useSelector(STRIKES_SELECTORS.strikesConfirmFetchStatusSelector)
  const strikeConfirmError = useSelector(STRIKES_SELECTORS.strikesConfirmErrorSelector)
  const strikeCancelFetchStatus = useSelector(STRIKES_SELECTORS.strikesCancelConfirmationFetchStatusSelector)
  const strikeCancelError = useSelector(STRIKES_SELECTORS.strikesCancelConfirmationErrorSelector)
  const correctionsConfirmFetchStatus = useSelector(CORRECTIONS_SELECTORS.correctionsConfirmFetchStatusSelector)
  const correctionsConfirmError = useSelector(CORRECTIONS_SELECTORS.correctionsConfirmErrorSelector)
  const correctionsCancelFetchStatus = useSelector(
    CORRECTIONS_SELECTORS.correctionsCancelConfirmationFetchStatusSelector
  )
  const correctionsCancelError = useSelector(CORRECTIONS_SELECTORS.correctionsCancelConfirmationErrorSelector)

  useEffect(() => {
    if (
      error?.lastErrorCode === FetchResponses.LAST_ERROR_CODE ||
      error?.lastErrorCode === FetchResponses.FORBIDDEN_RESOURCE
    ) {
      history.push(location.error403Url())
    }
  }, [error])

  useShowFetchError(resendFetchStatus, resendError)
  useShowFetchError(strikeConfirmFetchStatus, strikeConfirmError)
  useShowFetchError(strikeCancelFetchStatus, strikeCancelError)
  useShowFetchError(correctionsConfirmFetchStatus, correctionsConfirmError)
  useShowFetchError(correctionsCancelFetchStatus, correctionsCancelError)
}
