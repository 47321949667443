import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import translations from '../../../../../translations/keys'
import { SubscriptionPaymentTerms } from '../../../../../types/subscriptionPayment'
import Box from '../../../../ui/Box'
import Typography from '../../../../ui/Typography'
import { TYPOGRAPHY_COMPONENTS, TYPOGRAPHY_NAMES } from '../../../../ui/Typography/Typography.types'
import Cards from '../Cards'
import Logos from '../Logos'
import * as CARDS_SELECTORS from '../../../../../store/subscription/cards/cards.selectors'

import { PaymentProps } from './Payment.types'

export const Payment: React.FC<PaymentProps> = ({ term, price }) => {
  const cards = useSelector(CARDS_SELECTORS.subscriptionCardsListSelector)
  const { t } = useTranslation()

  return (
    <div>
      <Typography name={TYPOGRAPHY_NAMES.H3} isCapitalize>
        {t(translations.PAYMENT)}
      </Typography>
      <Box mt={2} mb={2} display="flex" alignItems="center">
        <Typography name={TYPOGRAPHY_NAMES.body14} component={TYPOGRAPHY_COMPONENTS.span} color="grey">
          {t(translations.AMOUNT)}
        </Typography>
        <Box ml={1.5}>
          <Typography name={TYPOGRAPHY_NAMES.regularH1} component={TYPOGRAPHY_COMPONENTS.span}>
            ${price}/{term === SubscriptionPaymentTerms.YEAR ? t(translations.YEAR) : t(translations.MONTH)}
          </Typography>
        </Box>
      </Box>
      <Logos />
      {cards && <Cards list={cards} />}
    </div>
  )
}
