import io from 'socket.io-client'

import { getEnvs } from './getEnvs'

const { REACT_APP_API = '' } = getEnvs()

class SocketService {
  serverUrl = REACT_APP_API

  io = () => io(this.serverUrl)
}

export const socketService = new SocketService()
