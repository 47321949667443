import React from 'react'
import { useField } from 'formik'
import { useTranslation } from 'react-i18next'

import Phone from '../../Phone'

import { FormPhoneProps } from './FormPhone.types'

export const FormPhone = (props: FormPhoneProps) => {
  const { t } = useTranslation()
  const [field, meta] = useField(props)
  return <Phone {...props} {...field} {...meta} error={meta.touched ? meta.error && t(meta.error) : ''} />
}
