import React, { forwardRef } from 'react'
import styled from 'styled-components'

import { isNumber } from '../../../utils/isNumber'

import { BoxProps } from './Box.types'

const StyledBox = styled.div<any>`
  width: ${({ width }) => (isNumber(width) ? `${width}px` : width)};
  min-width: ${({ minWidth }) => (isNumber(minWidth) ? `${minWidth}px` : minWidth)};
  height: ${({ height }) => (isNumber(height) ? `${height}px` : height)};
  text-align: ${({ textAlign }) => textAlign};
  margin-bottom: ${({ mb }) => `${mb * 8}px`};
  margin-top: ${({ mt }) => `${mt * 8}px`};
  margin-left: ${({ ml }) => `${ml * 8}px`};
  margin-right: ${({ mr }) => `${mr * 8}px`};
  padding-bottom: ${({ pb }) => `${pb * 8}px`};
  padding-top: ${({ pt }) => `${pt * 8}px`};
  padding-left: ${({ pl }) => `${pl * 8}px`};
  padding-right: ${({ pr }) => `${pr * 8}px`};
  ${({ padding }) => (padding ? `padding: ${padding * 8}px` : '')};
  display: ${({ display }) => display};
  ${({ justifyContent }) => (justifyContent ? `justify-content: ${justifyContent};` : '')}
  ${({ alignItems }) => (alignItems ? `align-items: ${alignItems};` : '')}
  ${({ flexDirection }) => (flexDirection ? `flex-direction: ${flexDirection};` : '')}
  ${({ flexWrap }) => (flexWrap ? `flex-wrap: ${flexWrap};` : '')}
  ${({ verticalAlign }) => (verticalAlign ? `vertical-align: ${verticalAlign};` : '')}
  ${({ theme, mbMobile, mtMobile, mlMobile, mobileWidth }) => `@media ${theme.devices.mobile} {
    ${typeof mbMobile !== 'undefined' ? `margin-bottom: ${mbMobile * 8}px;` : ''}
    ${typeof mtMobile !== 'undefined' ? `margin-top: ${mtMobile * 8}px;` : ''}
    ${typeof mlMobile !== 'undefined' ? `margin-left: ${mlMobile * 8}px;` : ''}
    ${typeof mobileWidth !== 'undefined' ? `width: ${mobileWidth};` : ''}
  }`}
  ${({ theme, mbTablet, mtTablet, mlTablet, mrTablet, plTablet, prTablet }) => `@media ${theme.devices.tablet} {
    ${typeof mbTablet !== 'undefined' ? `margin-bottom: ${mbTablet * 8}px;` : ''}
    ${typeof mtTablet !== 'undefined' ? `margin-top: ${mtTablet * 8}px;` : ''}
    ${typeof mlTablet !== 'undefined' ? `margin-left: ${mlTablet * 8}px;` : ''}
    ${typeof mrTablet !== 'undefined' ? `margin-right: ${mrTablet * 8}px;` : ''}
    ${typeof plTablet !== 'undefined' ? `padding-left: ${plTablet * 8}px;` : ''}
    ${typeof prTablet !== 'undefined' ? `padding-right: ${prTablet * 8}px;` : ''}
  }`}
  ${({ theme, mbLaptop, mtLaptop, mlLaptop, mrLaptop, plLaptop, prLaptop }) => `@media ${theme.devices.laptop} {
    ${typeof mbLaptop !== 'undefined' ? `margin-bottom: ${mbLaptop * 8}px;` : ''}
    ${typeof mtLaptop !== 'undefined' ? `margin-top: ${mtLaptop * 8}px;` : ''}
    ${typeof mlLaptop !== 'undefined' ? `margin-left: ${mlLaptop * 8}px;` : ''}
    ${typeof mrLaptop !== 'undefined' ? `margin-right: ${mrLaptop * 8}px;` : ''}
    ${typeof plLaptop !== 'undefined' ? `padding-left: ${plLaptop * 8}px;` : ''}
    ${typeof prLaptop !== 'undefined' ? `padding-right: ${prLaptop * 8}px;` : ''}
  }`}
  ${({
    theme,
    mbMobile,
    mtMobile,
    mlMobile,
    mrMobile,
    mobileWidth,
    plMobile,
    prMobile,
    ptMobile,
    pbMobile,
  }) => `@media ${theme.devices.mobile} {
    ${typeof mbMobile !== 'undefined' ? `margin-bottom: ${mbMobile * 8}px;` : ''}
    ${typeof mtMobile !== 'undefined' ? `margin-top: ${mtMobile * 8}px;` : ''}
    ${typeof mlMobile !== 'undefined' ? `margin-left: ${mlMobile * 8}px;` : ''}
    ${typeof mrMobile !== 'undefined' ? `margin-right: ${mrMobile * 8}px;` : ''}
    ${typeof mobileWidth !== 'undefined' ? `width: ${mobileWidth};` : ''}
    ${typeof plMobile !== 'undefined' ? `padding-left: ${plMobile * 8}px;` : ''}
    ${typeof prMobile !== 'undefined' ? `padding-right: ${prMobile * 8}px;` : ''}
    ${typeof ptMobile !== 'undefined' ? `padding-top: ${ptMobile * 8}px;` : ''}
    ${typeof pbMobile !== 'undefined' ? `padding-bottom: ${pbMobile * 8}px;` : ''}
  }`}
`

export const Box = forwardRef<HTMLDivElement, BoxProps>(
  (
    {
      mb = 0,
      mt = 0,
      ml = 0,
      mr = 0,
      pb = 0,
      pt = 0,
      pl = 0,
      pr = 0,
      minWidth = 'auto',
      width = 'auto',
      height = 'auto',
      textAlign = 'left',
      display = 'block',
      component = 'div',
      ...props
    },
    ref
  ) => (
    <StyledBox
      as={component}
      mb={mb}
      mt={mt}
      ml={ml}
      mr={mr}
      pb={pb}
      pt={pt}
      pr={pr}
      pl={pl}
      ref={ref}
      width={width}
      height={height}
      textAlign={textAlign}
      display={display}
      minWidth={minWidth}
      {...props}
    />
  )
)
