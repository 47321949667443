import { Action } from 'redux'

import { FetchFailurePayload, FETCH_STATUSES } from '../../../types/fetch'
import { UpdateNotificationsBody, UserNotificationsValues } from '../../../types/preferencesNotifications'

export interface NotificationsState {
  fetchStatus: FETCH_STATUSES
  error: FetchFailurePayload | null
  data: UserNotificationsValues | null
  update: {
    fetchStatus: FETCH_STATUSES
    error: FetchFailurePayload | null
  }
}

export enum NotificationsActions {
  'FETCH_PREFERENCES_NOTIFICATIONS' = 'FETCH_PREFERENCES_NOTIFICATIONS',
  'CLEAN_PREFERENCES_NOTIFICATIONS' = 'CLEAN_PREFERENCES_NOTIFICATIONS',
  'UPDATE_PREFERENCES_NOTIFICATIONS' = 'UPDATE_PREFERENCES_NOTIFICATIONS',
}

export type FetchNotifications = Action

export interface FetchNotificationsSuccess extends Action {
  payload: { notifications: UserNotificationsValues }
}

export interface UpdateNotifications extends Action {
  payload: { body: UpdateNotificationsBody }
}

export interface UpdateNotificationsSuccess extends Action {
  payload: { notifications: UserNotificationsValues }
}
