import React from 'react'
import { Form } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { EDIT_PROFILE_MODALS } from '../../../../constants/editProfile'
import { FormikRenderForm } from '../../../../../types/formik'
import { EditProfileFields, EditProfileFormBody } from '../../../../../types/editProfile'
import translations from '../../../../../translations/keys'
import { EditProfileBox } from '../EditProfileBox/EditProfileBox'
import { BUTTON_VARIANTS } from '../../../../ui/Button/Button.types'
import { openModal } from '../../../../../store/modals/modals.actions'
import { countriesSelector } from '../../../../../store/countries/countries.selectors'
import { industriesSelector } from '../../../../../store/industries/industries.selectors'
import {
  ADDRESS_TITLE_MAX_LENGTH,
  JOB_DATA_TITLE_MAX_LENGTH,
} from '../../../../../validation/editProfile/editProfileValidationSchema'
import { NAME_MAX_LENGTH } from '../../../../../validation/nameValidationSchema'
import { EditProfileModals } from '../EditProfileModals/EditProfileModals'
import TextInput from '../../../../ui/TextInput'
import Button from '../../../../ui/Button'
import Phone from '../../../../ui/Phone'
import FormTextInput from '../../../../ui/Forms/FormTextInput'
import FormDropdown from '../../../../ui/Forms/FormDropdown'
import Typography from '../../../../ui/Typography'
import { TYPOGRAPHY_NAMES } from '../../../../ui/Typography/Typography.types'
import Box from '../../../../ui/Box'
import { fetchEditProfileResendVerification } from '../../../../../store/editProfile/editProfile.actions'
import {
  editProfileInitialDataSelector,
  editProfileUnconfirmedEmailNewEmailSelector,
  editProfileUnconfirmedEmailSuccessSelector,
} from '../../../../../store/editProfile/editProfile.selectors'

import { StyledButtonWrapper, StyledCell, StyledConfirmation, StyledRow } from './EditProfileRenderForm.styles'

export const EditProfileRenderForm = ({
  handleSubmit,
  dirty,
  handleReset,
  touched,
}: FormikRenderForm<EditProfileFormBody>) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const countries = useSelector(countriesSelector)
  const industries = useSelector(industriesSelector)
  const newEmail = useSelector(editProfileUnconfirmedEmailNewEmailSelector)
  const unconfirmedEmailSuccess = useSelector(editProfileUnconfirmedEmailSuccessSelector)
  const initialData = useSelector(editProfileInitialDataSelector)
  const email = initialData.values?.email || ''
  const phone = initialData.values?.phone || ''
  const nameTouched = touched[EditProfileFields.firstName] || touched[EditProfileFields.lastName]
  const isWaitingConfirmation = Boolean(newEmail)

  const showEditEmailModal = () => {
    dispatch(openModal(EDIT_PROFILE_MODALS.EDIT_PROFILE_CHANGE_EMAIL_MODAL))
  }
  const showEditPhoneModal = () => {
    dispatch(openModal(EDIT_PROFILE_MODALS.EDIT_PROFILE_EDIT_PHONE_MODAL))
  }
  const onResend = () => {
    dispatch(fetchEditProfileResendVerification())
  }
  const onCancel = () => {
    dispatch(openModal(EDIT_PROFILE_MODALS.EDIT_PROFILE_CANCEL_CHANGES))
  }
  const renderWaitingConfirmation = () => (
    <StyledConfirmation>
      <StyledRow>
        <Typography name={TYPOGRAPHY_NAMES.body14}>
          {t(translations.CHANGE_EMAIL_LABEL)} {newEmail} {t(translations.WAITING_FOR_CONFIRMATION)}
        </Typography>
      </StyledRow>
      <StyledRow>
        <Box mt={2} display="flex">
          <Button variant={BUTTON_VARIANTS.INACTIVE} icon="reload" type="button" onClick={onResend}>
            {t(translations.RESEND_VERIFICATION)}
          </Button>
          <Box ml={4}>
            <Button variant={BUTTON_VARIANTS.INACTIVE} icon="closeCircle" type="button" onClick={onCancel}>
              {t(translations.CANCEL_CHANGES)}
            </Button>
          </Box>
        </Box>
      </StyledRow>
    </StyledConfirmation>
  )

  return (
    <>
      <Form>
        <EditProfileBox title={t(translations.EMAIL_LABEL)}>
          <StyledRow>
            <StyledCell>
              <TextInput label={`${t(translations.EMAIL_LABEL)}*`} value={email} disabled />
            </StyledCell>
            {unconfirmedEmailSuccess && !isWaitingConfirmation && (
              <StyledCell>
                <Button variant={BUTTON_VARIANTS.INACTIVE} icon="edit" type="button" onClick={showEditEmailModal}>
                  {t(translations.EDIT_EMAIL)}
                </Button>
              </StyledCell>
            )}
          </StyledRow>
          {isWaitingConfirmation && renderWaitingConfirmation()}
        </EditProfileBox>
        <EditProfileBox title={t(translations.PHONE)}>
          <StyledRow>
            <StyledCell>
              <Phone label={`${t(translations.PHONE_LABEL)}*`} value={phone} disabled />
            </StyledCell>
            <StyledCell>
              <Button variant={BUTTON_VARIANTS.INACTIVE} icon="edit" onClick={showEditPhoneModal} type="button">
                {t(translations.EDIT_PROFILE_PHONE_EDIT)}
              </Button>
            </StyledCell>
          </StyledRow>
        </EditProfileBox>
        <EditProfileBox title={t(translations.PERSONAL_DATA)}>
          <StyledRow>
            <StyledCell>
              <FormTextInput
                name={EditProfileFields.firstName}
                label={`${t(translations.FIRST_NAME_LABEL)}*`}
                placeholder={t(translations.FIRST_NAME_PLACEHOLDER)}
                maxLength={NAME_MAX_LENGTH}
              />
            </StyledCell>
            <StyledCell>
              <FormTextInput
                name={EditProfileFields.lastName}
                label={`${t(translations.LAST_NAME_LABEL)}*`}
                placeholder={t(translations.LAST_NAME_PLACEHOLDER)}
                maxLength={NAME_MAX_LENGTH}
              />
            </StyledCell>
          </StyledRow>
          {nameTouched && (
            <StyledRow>
              <Box mb={3}>
                <Typography name={TYPOGRAPHY_NAMES.body14}>{t(translations.NAME_NOTE)}</Typography>
              </Box>
            </StyledRow>
          )}
        </EditProfileBox>
        <EditProfileBox title={t(translations.JOB_DATA)}>
          <StyledRow>
            <StyledCell>
              <FormDropdown
                name={EditProfileFields.industry}
                label={`${t(translations.INDUSTRY_LABEL)}*`}
                placeholder={t(translations.INDUSTRY_PLACEHOLDER)}
                options={industries.data}
              />
            </StyledCell>
            <StyledCell>
              <FormTextInput
                name={EditProfileFields.company}
                label={t(translations.COMPANY_LABEL)}
                placeholder={t(translations.COMPANY_PLACEHOLDER)}
                maxLength={JOB_DATA_TITLE_MAX_LENGTH}
              />
            </StyledCell>
          </StyledRow>
          <StyledRow>
            <StyledCell>
              <FormTextInput
                name={EditProfileFields.job}
                label={t(translations.JOB_TITLE_LABEL)}
                placeholder={t(translations.JOB_TITLE_PLACEHOLDER)}
                maxLength={JOB_DATA_TITLE_MAX_LENGTH}
              />
            </StyledCell>
            <StyledCell />
          </StyledRow>
        </EditProfileBox>
        <EditProfileBox title={t(translations.ADDRESS)}>
          <StyledRow>
            <StyledCell>
              <FormDropdown
                name={EditProfileFields.country}
                label={`${t(translations.COUNTRY_LABEL)}*`}
                placeholder={t(translations.COUNTRY_PLACEHOLDER)}
                options={countries.data}
              />
            </StyledCell>
            <StyledCell>
              <FormTextInput
                name={EditProfileFields.region}
                label={t(translations.REGION_LABEL)}
                placeholder={t(translations.REGION_PLACEHOLDER)}
                maxLength={ADDRESS_TITLE_MAX_LENGTH}
              />
            </StyledCell>
          </StyledRow>
          <StyledRow>
            <StyledCell>
              <FormTextInput
                name={EditProfileFields.city}
                label={t(translations.CITY_LABEL)}
                placeholder={t(translations.CITY_PLACEHOLDER)}
                maxLength={ADDRESS_TITLE_MAX_LENGTH}
              />
            </StyledCell>
            <StyledCell>
              <FormTextInput
                name={EditProfileFields.postalCode}
                label={t(translations.POSTAL_CODE_LABEL)}
                placeholder={t(translations.POSTAL_CODE_PLACEHOLDER)}
                maxLength={ADDRESS_TITLE_MAX_LENGTH}
              />
            </StyledCell>
          </StyledRow>
          <StyledRow>
            <FormTextInput
              name={EditProfileFields.address}
              label={t(translations.ADDRESS)}
              placeholder={t(translations.ADDRESS_PLACEHOLDER)}
              maxLength={ADDRESS_TITLE_MAX_LENGTH}
            />
          </StyledRow>
        </EditProfileBox>
        <StyledButtonWrapper>
          <Button disabled={!dirty} type="submit" fullWidth>
            {t(translations.UPDATE)}
          </Button>
        </StyledButtonWrapper>
      </Form>
      <EditProfileModals
        onLeaveSuccess={handleSubmit}
        onLeaveCancel={handleReset}
        canLeave={!dirty}
        onExpiredLinkSuccess={onResend}
      />
    </>
  )
}
