import styled from 'styled-components'

import Box from '../../Box'

export const StyledSenderWrapper = styled(Box).attrs({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
})`
  position: relative;
`
