import React, { useEffect } from 'react'
import { Formik, FormikHelpers } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import translations from '../../../translations/keys'
import { editProfileValidationSchema } from '../../../validation/editProfile/editProfileValidationSchema'
import { EditProfileFormBody } from '../../../types/editProfile'
import { closeModal, openModal } from '../../../store/modals/modals.actions'
import { prepareEditProfileInitialValues } from '../../../initialValues/editProfileInitialValues'
import { fetchEditProfile } from '../../../store/editProfile/editProfile.actions'
import { useShowFetchError } from '../../hooks/useShowFetchError'
import {
  editProfileDataSelector,
  editProfileDataSuccessSelector,
  editProfileResendVerificationSelector,
  editProfileResendVerificationSuccessSelector,
  editProfileUnconfirmedEmailSelector,
  editProfilePreventEmailUpdateSelector,
  editProfileInitialDataSuccessSelector,
  editProfileInitialDataSelector,
  editProfilePreventEmailUpdateSuccessSelector,
} from '../../../store/editProfile/editProfile.selectors'
import { COMMON_MODALS_IDS } from '../../ui/Modals/CommonModals/CommonModals.constants'
import { NOTIFICATION_MODAL_TYPES } from '../../ui/Modals/NotificationModal/NotificationModal.constants'
import { EDIT_PROFILE_MODALS } from '../../constants/editProfile'
import { useEditProfileFetch } from '../../../hooks/editProfile/useEditProfileFetch'

import { EditProfileRenderForm } from './components/EditProfileRenderForm/EditProfileRenderForm'
import { useShowNotifications } from './hooks/useShowNotifications'

export const EditProfile = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const editProfileData = useSelector(editProfileDataSelector)
  const profileDataSuccess = useSelector(editProfileDataSuccessSelector)
  const resendVerification = useSelector(editProfileResendVerificationSelector)
  const resendVerificationSuccess = useSelector(editProfileResendVerificationSuccessSelector)
  const unconfirmedEmail = useSelector(editProfileUnconfirmedEmailSelector)
  const preventEmailUpdate = useSelector(editProfilePreventEmailUpdateSelector)
  const preventEmailUpdateSuccess = useSelector(editProfilePreventEmailUpdateSuccessSelector)
  const initialData = useSelector(editProfileInitialDataSelector)
  const initialDataSuccess = useSelector(editProfileInitialDataSuccessSelector)

  useEffect(() => {
    if (profileDataSuccess) {
      dispatch(
        openModal(COMMON_MODALS_IDS.NOTIFICATION, {
          type: NOTIFICATION_MODAL_TYPES.SUCCESS,
          description: t(translations.CHANGES_SAVED_SUCCESSFULLY),
        })
      )
    }
  }, [profileDataSuccess])
  useEffect(() => {
    if (resendVerificationSuccess) {
      dispatch(openModal(EDIT_PROFILE_MODALS.EDIT_PROFILE_CHECK_EMAIL))
      dispatch(closeModal(EDIT_PROFILE_MODALS.EDIT_PROFILE_EXPIRED_LINK))
    }
  }, [resendVerificationSuccess])
  useEffect(() => {
    if (preventEmailUpdateSuccess) {
      dispatch(
        openModal(COMMON_MODALS_IDS.NOTIFICATION, {
          type: NOTIFICATION_MODAL_TYPES.WARNING,
          title: t(translations.CHANGING_EMAIL_CANCELED),
        })
      )
    }
  }, [preventEmailUpdateSuccess])
  useEditProfileFetch()
  useShowNotifications()
  useShowFetchError(editProfileData.fetchStatus, editProfileData.error)
  useShowFetchError(resendVerification.fetchStatus, resendVerification.error)
  useShowFetchError(unconfirmedEmail.fetchStatus, unconfirmedEmail.error)
  useShowFetchError(preventEmailUpdate.fetchStatus, preventEmailUpdate.error)
  useShowFetchError(initialData.fetchStatus, initialData.error)

  const onSubmit = (values: EditProfileFormBody, helpers: FormikHelpers<EditProfileFormBody>) => {
    dispatch(fetchEditProfile(values))
    helpers.resetForm({ values })
  }

  return (
    <>
      {initialDataSuccess && (
        <Formik
          initialValues={prepareEditProfileInitialValues(initialData.values)}
          onSubmit={onSubmit}
          validationSchema={editProfileValidationSchema}
        >
          {EditProfileRenderForm}
        </Formik>
      )}
    </>
  )
}
