import React from 'react'

import { SvgIconProps } from '../../ui/SvgIcon/SvgIcon.types'
import SvgIcon from '../../ui/SvgIcon'

export const TextIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <path d="M13 6.5V21.5H11V6.5H5V4.5H19V6.5H13Z" />
  </SvgIcon>
)
