import { combineEpics, Epic, ofType } from 'redux-observable'
import { of } from 'rxjs'
import { catchError, mergeMap, takeUntil } from 'rxjs/operators'

import { paymentService } from '../../../api'
import { catchFetchError } from '../../../utils/catchFetchError'

import * as ACTIONS from './cards.actions'

const fetchCards: Epic = (action$) =>
  action$.pipe(
    ofType(ACTIONS.fetchCardsTypes.request),
    mergeMap(() =>
      paymentService.fetchCards().pipe(
        mergeMap((response) => of(ACTIONS.fetchCardsSuccess(response))),
        catchError(catchFetchError(ACTIONS.fetchCardsFailure)),
        takeUntil(action$.pipe(ofType(ACTIONS.fetchCardsTypes.success, ACTIONS.fetchCardsTypes.failure)))
      )
    )
  )

const fetchSetDefaultCard: Epic = (action$) =>
  action$.pipe(
    ofType(ACTIONS.fetchSetDefaultCardTypes.request),
    mergeMap(({ payload }) =>
      paymentService.fetchSetDefaultCard(payload.cardId).pipe(
        mergeMap(() => of(ACTIONS.fetchSetDefaultCardSuccess())),
        catchError(catchFetchError(ACTIONS.fetchSetDefaultCardFailure)),
        takeUntil(
          action$.pipe(ofType(ACTIONS.fetchSetDefaultCardTypes.success, ACTIONS.fetchSetDefaultCardTypes.failure))
        )
      )
    )
  )

export const cardsEpics = combineEpics(fetchCards, fetchSetDefaultCard)
