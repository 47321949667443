import React from 'react'
import { useTranslation } from 'react-i18next'

import translations from '../../../../../../translations/keys'
import { ConfirmationStatus } from '../../../../../../types/confirmationActions'
import Button from '../../../../Button'
import { BUTTON_VARIANTS } from '../../../../Button/Button.types'

import * as Styled from './ConfirmationActions.styles'
import { ConfirmationActionsProps } from './ConfirmationActions.types'

export const ConfirmationActions: React.FC<ConfirmationActionsProps> = ({ onConfirm, id }) => {
  const { t } = useTranslation()
  const handleAccept = () => {
    onConfirm(id, ConfirmationStatus.ACCEPTED)
  }

  const handleReject = () => {
    onConfirm(id, ConfirmationStatus.REJECTED)
  }

  return (
    <Styled.Wrapper data-testid="confirmation-actions">
      <Styled.ButtonWrapper>
        <Button
          data-testid="confirmation-actions-accept"
          data-action
          variant={BUTTON_VARIANTS.INACTIVE}
          icon="checkCircle"
          iconColor="green"
          textColor="white"
          onClick={handleAccept}
          fullWidth
        >
          {t(translations.ACCEPT)}
        </Button>
      </Styled.ButtonWrapper>
      <Styled.ButtonWrapper>
        <Button
          data-testid="confirmation-actions-reject"
          data-action
          variant={BUTTON_VARIANTS.INACTIVE}
          icon="closeCircle"
          iconColor="errorColor"
          textColor="white"
          onClick={handleReject}
          fullWidth
        >
          {t(translations.REJECT)}
        </Button>
      </Styled.ButtonWrapper>
    </Styled.Wrapper>
  )
}
