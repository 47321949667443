import { RegistrationRequestBody, RegistrationResponse, RegistrationFormValues } from '../../types/registration'
import { FetchFailurePayload, FetchFailureAction } from '../../types/fetch'
import { createFetchActionTypes } from '../../utils/createFetchActionTypes'

import * as TYPES from './registration.types'

export const fetchSignUpTypes = createFetchActionTypes(TYPES.RegistrationActions.FETCH_REGISTRATION)
export const fetchPhoneConfirmTypes = createFetchActionTypes(TYPES.RegistrationActions.FETCH_REGISTRATION_PHONE_CONFIRM)
export const fetchResendCodeTypes = createFetchActionTypes(TYPES.RegistrationActions.FETCH_REGISTRATION_RESEND_CODE)

export const fetchSignUp = ({
  body,
  registrationForm,
}: {
  body: RegistrationRequestBody
  registrationForm: RegistrationFormValues
}): TYPES.FetchRegistrationAction => ({
  type: fetchSignUpTypes.request,
  payload: { body, registrationForm },
})

export const fetchSignUpSuccess = (
  response: RegistrationResponse,
  registrationForm: RegistrationFormValues
): TYPES.FetchRegistrationSuccessAction => ({
  type: fetchSignUpTypes.success,
  payload: {
    response,
    registrationForm,
  },
})

export const fetchSignUpFailure = (payload: FetchFailurePayload): FetchFailureAction => ({
  type: fetchSignUpTypes.failure,
  payload,
})

export const cleanSignUpFetchStatus = () => ({
  type: TYPES.RegistrationActions.FETCH_REGISTRATION_CLEAN_FETCH_STATUS,
})

export const fetchPhoneConfirm = ({ code }: { code: string }): TYPES.FetchRegistrationPhoneConfirmAction => ({
  type: fetchPhoneConfirmTypes.request,
  payload: { code },
})

export const fetchPhoneConfirmSuccess = (): TYPES.FetchRegistrationPhoneConfirmSuccessAction => ({
  type: fetchPhoneConfirmTypes.success,
})

export const fetchPhoneConfirmFailure = (payload: FetchFailurePayload): FetchFailureAction => ({
  type: fetchPhoneConfirmTypes.failure,
  payload,
})

export const fetchResendCode = (userId: string): TYPES.FetchRegistrationResendCodeAction => ({
  type: fetchResendCodeTypes.request,
  payload: { userId },
})

export const fetchResendCodeSuccess = (linkId: string): TYPES.FetchRegistrationResendCodeSuccessAction => ({
  type: fetchResendCodeTypes.success,
  payload: {
    linkId,
  },
})

export const fetchResendCodeFailure = (payload: FetchFailurePayload): FetchFailureAction => ({
  type: fetchResendCodeTypes.failure,
  payload,
})

export const resetRegistration = () => ({
  type: TYPES.RegistrationActions.RESET_REGISTRATION,
})
