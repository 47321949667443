import React from 'react'
import { useTranslation } from 'react-i18next'

import translations from '../../translations'
import keys from '../../translations/keys'
import { SubscriptionFeature } from '../../types/subscription'

export const getFeature = (feature: SubscriptionFeature, useKeys?: boolean) => {
  const { t } = useTranslation()
  return (
    <>
      {feature.limit ? `${feature.limit} ` : null}
      {useKeys ? t(keys[feature.name]) : translations[feature.name]}
    </>
  )
}
