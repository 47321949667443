import { FETCH_STATUSES } from '../../../types/fetch'
import { RootState } from '../../rootReducer.types'

export const placementByIdSelector = (state: RootState) => state.templates.placement.byId
export const placementByPagesSelector = (state: RootState) => state.templates.placement.byPages
export const placementSortedSelector = (state: RootState) => state.templates.placement.sorted
export const placementFetchSelector = (state: RootState) => state.templates.placement.fetch
export const placementFetchUpdateSelector = (state: RootState) => state.templates.placement.update
export const placementFetchGenerateSelector = (state: RootState) => state.templates.placement.generate
export const placementShouldRegenerateSelector = (state: RootState) => state.templates.placement.shouldRegenerate

export const placementGenerateIsSuccess = (state: RootState) =>
  state.templates.placement.generate.status === FETCH_STATUSES.SUCCESS
export const placementUpdateIsSuccess = (state: RootState) =>
  state.templates.placement.update.status === FETCH_STATUSES.SUCCESS
