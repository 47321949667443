import { Epic, combineEpics, ofType } from 'redux-observable'
import { catchError, map, mergeMap } from 'rxjs/operators'
import { of } from 'rxjs'

import { localeService } from '../../api'

import * as ACTIONS from './phones.actions'
import * as TYPES from './phones.types'

const fetchPhones: Epic = (action$) =>
  action$.pipe(
    ofType(TYPES.PhonesActions.FETCH_PHONES),
    mergeMap(() =>
      localeService.fetchPhones().pipe(
        map((response) => ACTIONS.fetchPhonesSuccess(response)),
        catchError((error) => of(ACTIONS.fetchPhonesFailure(error.response)))
      )
    )
  )

export const phonesEpics = combineEpics(fetchPhones)
