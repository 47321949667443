import React from 'react'
import { useTranslation } from 'react-i18next'

import useNavControls from '../../hooks/useNavControls'
import { ChevronLeftIcon } from '../../assets/icons/ChevronLeftIcon'
import Button from '../Button'
import { BUTTON_VARIANTS } from '../Button/Button.types'
import Typography from '../../ui/Typography'
import { TYPOGRAPHY_NAMES } from '../../ui/Typography/Typography.types'
import translations from '../../../translations/keys'

export const SidebarToggle = () => {
  const { setSidebarOpen } = useNavControls()
  const { t } = useTranslation()
  const handleToggleSidebar = () => {
    setSidebarOpen((prevValue) => !prevValue)
  }

  return (
    <Button variant={BUTTON_VARIANTS.LINK} onClick={handleToggleSidebar}>
      <ChevronLeftIcon color="grey" />
      <Typography color="grey" name={TYPOGRAPHY_NAMES.body16}>
        {t(translations.BACK)}
      </Typography>
    </Button>
  )
}
