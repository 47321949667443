import styled from 'styled-components'

import Box from '../../ui/Box'

export const StyledPopup = styled(Box).attrs({
  pt: 3,
  pb: 3,
  pl: 2,
  pr: 2,
})`
  position: fixed;
  top: ${({ theme }) => theme.sizes.layout.headerHeightMobile};
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${({ theme }) => theme.palette.white};
  z-index: 10;
`

export const StyledTitle = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
`
