import { combineEpics, Epic, ofType } from 'redux-observable'
import { catchError, map, mergeMap, takeUntil } from 'rxjs/operators'

import { catchFetchError } from '../../../utils/catchFetchError'
import { templateService } from '../../../api'

import * as ACTIONS from './upload.actions'

export const fetchUpload: Epic = (action$) =>
  action$.pipe(
    ofType(ACTIONS.fetchUploadTemplateTypes.request),
    mergeMap(({ payload }) =>
      templateService.fetchUploadDocument(payload.body).pipe(
        map(({ response }) => ACTIONS.fetchUploadTemplateSuccess(response)),
        catchError(catchFetchError(ACTIONS.fetchUploadTemplateFailure)),
        takeUntil(
          action$.pipe(ofType(ACTIONS.fetchUploadTemplateTypes.success, ACTIONS.fetchUploadTemplateTypes.failure))
        )
      )
    )
  )

export const uploadEpics = combineEpics(fetchUpload)
