import { Epic, combineEpics, ofType } from 'redux-observable'
import { of } from 'rxjs'
import { catchError, map, mergeMap, takeUntil } from 'rxjs/operators'

import { signingService, socketService } from '../../../api'
import { SOCKET_CONNECT_ERROR } from '../../../constants/errors'
import { SocketEvents } from '../../../constants/sockets'
import translations from '../../../translations'
import { catchFetchError } from '../../../utils/catchFetchError'
import { signingRecipientTokenSelector } from '../signing/signing.selectors'

import * as TYPES from './download.types'
import * as ACTIONS from './download.actions'

const fetchDownloadContract: Epic = (action$, state$) =>
  action$.pipe(
    ofType(ACTIONS.fetchDownloadContractTypes.request),
    mergeMap(({ payload }) =>
      signingService.fetchDownloadContract(payload.contractId, signingRecipientTokenSelector(state$.value)).pipe(
        map(({ response }) => ACTIONS.fetchDownloadContractSuccess(response)),
        catchError(catchFetchError(ACTIONS.fetchDownloadContractFailure)),
        takeUntil(
          action$.pipe(ofType(ACTIONS.fetchDownloadContractTypes.success, ACTIONS.fetchDownloadContractTypes.failure))
        )
      )
    )
  )

const subscribeOnDownloadProgress: Epic = (action$) =>
  action$.pipe(
    ofType(ACTIONS.subscribeOnDownloadProgressTypes.request),
    mergeMap(({ payload }) => {
      const socket = socketService.io()
      const downloadContract = () => {
        of(ACTIONS.fetchDownloadContract(payload.contractId), ACTIONS.subscribeOnDownloadProgressSuccess())
        socket.close()
      }

      socket.on(`${SocketEvents.DOCUMENT_READY}.${payload.contractId}`, downloadContract)
      socket.on(`${SocketEvents.DOCUMENT_UPDATE}.${payload.contractId}`, downloadContract)
      socket.on('connect_error', (error: Error) => {
        console.error(error)
        of(
          ACTIONS.subscribeOnDownloadProgressFailure({
            lastErrorCode: SOCKET_CONNECT_ERROR,
            errorCodes: [SOCKET_CONNECT_ERROR],
            errorMessage: translations[SOCKET_CONNECT_ERROR],
          })
        )
      })

      return action$.pipe(
        ofType(TYPES.DownloadActions.UNSUBSCRIBE_ON_DOWNLOAD_PROGRESS),
        mergeMap(() => {
          socket.close()
          return of()
        })
      )
    })
  )

export const downloadEpics = combineEpics(fetchDownloadContract, subscribeOnDownloadProgress)
