import { FetchFailureAction, FETCH_STATUSES } from '../../../types/fetch'
import { SIGNING_TYPES } from '../../../types/signingOrder'
import { createReducer } from '../../createReducer'
import { getOrderBasedOnRecipients, mapRecipients, mapRecipientsFromForm } from '../../../utils/recipients'

import * as TYPES from './recipients.types'
import * as ACTIONS from './recipients.actions'

type Actions =
  | TYPES.SetOrderAction
  | TYPES.SetRecipientsAction
  | TYPES.ClearRecipientsAction
  | TYPES.FetchRecipientsSuccessAction
  | TYPES.FetchAddRecipientsSuccessAction
  | TYPES.FetchAddClmOnlyRecipientsSuccessAction
  | TYPES.FetchTemplateInfoSuccessAction
  | TYPES.FetchSenderInfoSuccessAction
  | FetchFailureAction

export const initialState: TYPES.RecipientsState = {
  templateId: null,
  templateName: null,
  sender: null,
  order: SIGNING_TYPES.SEQUENTIAL,
  byId: {},
  byOrder: [],
  sorted: [],
  fetch: {
    status: FETCH_STATUSES.IDLE,
    error: null,
  },
  add: {
    status: FETCH_STATUSES.IDLE,
    error: null,
  },
  addClmOnly: {
    status: FETCH_STATUSES.IDLE,
    error: null,
  },
  fetchTemplateInfo: {
    status: FETCH_STATUSES.IDLE,
    error: null,
  },
  isDocumentExists: false,
  messageInitialValues: null,
}

export const recipients = createReducer<TYPES.RecipientsState, Actions>(initialState, {
  [TYPES.RecipientsActions.TEMPLATES_SET_ORDER]: (state, action) => ({
    ...state,
    order: (action as TYPES.SetOrderAction).payload.order,
  }),
  [TYPES.RecipientsActions.TEMPLATES_SET_RECIPIENTS]: (state, action) => ({
    ...state,
    ...mapRecipientsFromForm((action as TYPES.SetRecipientsAction).payload.recipients),
  }),
  [TYPES.RecipientsActions.TEMPLATES_CLEAR_RECIPIENTS]: () => ({
    ...initialState,
  }),

  [ACTIONS.fetchRecipientsTypes.request]: (state) => ({
    ...state,
    fetch: {
      status: FETCH_STATUSES.REQUEST,
      error: null,
    },
  }),
  [ACTIONS.fetchRecipientsTypes.success]: (state, action) => {
    const { recipients, enabledFeatures } = (action as TYPES.FetchRecipientsSuccessAction).payload

    return {
      ...state,
      ...mapRecipients(recipients),
      order: getOrderBasedOnRecipients({ recipients, enabledFeatures, order: state.order }),
      fetch: {
        status: FETCH_STATUSES.SUCCESS,
        error: null,
      },
    }
  },
  [ACTIONS.fetchRecipientsTypes.failure]: (state, action) => ({
    ...state,
    fetch: {
      status: FETCH_STATUSES.FAILURE,
      error: (action as FetchFailureAction).payload,
    },
  }),

  [ACTIONS.fetchAddRecipientsTypes.request]: (state) => ({
    ...state,
    add: {
      status: FETCH_STATUSES.REQUEST,
      error: null,
    },
  }),
  [ACTIONS.fetchAddRecipientsTypes.success]: (state) => ({
    ...state,
    add: {
      status: FETCH_STATUSES.SUCCESS,
      error: null,
    },
  }),
  [ACTIONS.fetchAddRecipientsTypes.failure]: (state, action) => ({
    ...state,
    add: {
      status: FETCH_STATUSES.FAILURE,
      error: (action as FetchFailureAction).payload,
    },
  }),

  [ACTIONS.fetchAddClmOnlyRecipientsTypes.request]: (state) => ({
    ...state,
    addClmOnly: {
      status: FETCH_STATUSES.REQUEST,
      error: null,
    },
  }),
  [ACTIONS.fetchAddClmOnlyRecipientsTypes.success]: (state, action) => ({
    ...state,
    templateId: (action as TYPES.FetchAddClmOnlyRecipientsSuccessAction).payload.templateId,
    addClmOnly: {
      status: FETCH_STATUSES.SUCCESS,
      error: null,
    },
  }),
  [ACTIONS.fetchAddClmOnlyRecipientsTypes.failure]: (state, action) => ({
    ...state,
    addClmOnly: {
      status: FETCH_STATUSES.FAILURE,
      error: (action as FetchFailureAction).payload,
    },
  }),

  [ACTIONS.fetchTemplateInfoTypes.request]: (state) => ({
    ...state,
    fetchTemplateInfo: {
      status: FETCH_STATUSES.REQUEST,
      error: null,
    },
  }),
  [ACTIONS.fetchTemplateInfoTypes.success]: (state, action) => {
    const payload = (action as TYPES.FetchTemplateInfoSuccessAction).payload

    return {
      ...state,
      templateName: payload.name,
      isDocumentExists: payload.isDocumentExists,
      messageInitialValues: payload.messageInitialValues,
      fetchTemplateInfo: {
        status: FETCH_STATUSES.SUCCESS,
        error: null,
      },
    }
  },
  [ACTIONS.fetchTemplateInfoTypes.failure]: (state, action) => ({
    ...state,
    fetchTemplateInfo: {
      status: FETCH_STATUSES.FAILURE,
      error: (action as FetchFailureAction).payload,
    },
  }),

  [ACTIONS.fetchSenderInfoTypes.request]: (state) => ({
    ...state,
    fetchSender: {
      status: FETCH_STATUSES.REQUEST,
      error: null,
    },
  }),
  [ACTIONS.fetchSenderInfoTypes.success]: (state, action) => ({
    ...state,
    sender: (action as TYPES.FetchSenderInfoSuccessAction).payload,
    fetchSender: {
      status: FETCH_STATUSES.SUCCESS,
      error: null,
    },
  }),
  [ACTIONS.fetchSenderInfoTypes.failure]: (state, action) => ({
    ...state,
    fetchSender: {
      status: FETCH_STATUSES.FAILURE,
      error: (action as FetchFailureAction).payload,
    },
  }),
})
