import translations from '../../../translations/keys'
import { SIGNING_TYPES } from '../../../types/signingOrder'

export const ITEMS = [
  {
    value: SIGNING_TYPES.SEQUENTIAL,
    name: translations.SEQUENTIAL_SIGNING,
  },
  {
    value: SIGNING_TYPES.SEPARATE,
    name: translations.SEPARATELY_SIGNING,
  },
]
