import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { location } from '../../../../utils/location'
import { useShowFetchError } from '../../../hooks/useShowFetchError'
import * as SIGNING_SELECTORS from '../../../../store/contracts/signing/signing.selectors'
import * as STRIKES_SELECTORS from '../../../../store/contracts/strikes/strikes.selectors'
import * as CORRECTIONS_SELECTORS from '../../../../store/contracts/corrections/corrections.selectors'
import { FetchResponses } from '../../../constants/fetchResponses'

export const useErrors = () => {
  const history = useHistory()
  const error = useSelector(SIGNING_SELECTORS.signingContractErrorSelector)
  const strikeConfirmFetchStatus = useSelector(STRIKES_SELECTORS.strikesConfirmFetchStatusSelector)
  const strikeConfirmError = useSelector(STRIKES_SELECTORS.strikesConfirmErrorSelector)
  const correctionsConfirmFetchStatus = useSelector(CORRECTIONS_SELECTORS.correctionsConfirmFetchStatusSelector)
  const correctionsConfirmError = useSelector(CORRECTIONS_SELECTORS.correctionsConfirmErrorSelector)

  useEffect(() => {
    if (
      error?.lastErrorCode === FetchResponses.LAST_ERROR_CODE ||
      error?.lastErrorCode === FetchResponses.FORBIDDEN_RESOURCE
    ) {
      history.push(location.error403Url())
    }
  }, [error])

  useShowFetchError(strikeConfirmFetchStatus, strikeConfirmError)
  useShowFetchError(correctionsConfirmFetchStatus, correctionsConfirmError)
}
