import { FETCH_STATUSES } from '../../types/fetch'
import { RootState } from '../rootReducer.types'

export const ekycGetEnrolledData = (state: RootState) => state.ekyc.ekycEnroll.data
export const ekycGetEnrolledError = (state: RootState) => state.ekyc.ekycEnroll.error

export const ekycGetConfirmationStatus = (state: RootState) => state.ekyc.ekycConfirmation.isConfirmed
export const ekycGetConfirmationStatusError = (state: RootState) => state.ekyc.ekycConfirmation.error
export const ekycConfirmationLoading = (state: RootState) => {
  const { fetchStatus } = state.ekyc.ekycConfirmation
  return fetchStatus === FETCH_STATUSES.IDLE || fetchStatus === FETCH_STATUSES.REQUEST
}

export const ekycGetVerificationSelector = (state: RootState) => state.ekyc.ekycVerification.isVerified
export const ekycGetVerificationErrorSelector = (state: RootState) => state.ekyc.ekycVerification.error
export const ekycVerification = (state: RootState) => state.ekyc.ekycVerification.isVerified
export const ekycVerificationErrorSelector = (state: RootState) => state.ekyc.ekycVerification.error

export const ekycAttemptsSelector = (state: RootState) => state.ekyc.eKYCAttempts
