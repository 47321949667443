import { ResizeControls } from '../../../types/editableElem'
import { PAGE_HOR_MARGIN, PAGE_VER_MARGIN } from '../../../types/placement'
import { roundNumber } from '../../../utils/numbers'
import { getNumberLimitedByRange } from '../../../utils/numbers/numbers'

import { Bounds, ElemBounds, Coords, PointerModes, Size } from './DocViewer.types'

const HUNDRED = 100
const MIN_SCROLLBAR_HEIGHT = 2
const MINIMUM_PLACE_SIZE = 50

export const getPositionFromPointer = (event: PointerEvent, startCoords: Coords = { x: 0, y: 0 }): Coords => ({
  x: event.clientX - startCoords.x,
  y: event.clientY - startCoords.y,
})

export const getInitialScale = (canvas: HTMLDivElement, content: HTMLDivElement): number => {
  const canvasWidth = canvas.offsetWidth
  const contentWidth = content.offsetWidth
  const scale = roundNumber(canvasWidth / contentWidth, 2)

  return scale
}

export const getBounds = (canvas: HTMLDivElement, content: HTMLDivElement, scale: number): Bounds => {
  const canvasWidth = canvas.offsetWidth
  const canvasHeight = canvas.offsetHeight
  const contentWidth = content.offsetWidth * scale
  const contentHeight = content.offsetHeight * scale

  const diffWidth = canvasWidth - contentWidth
  const diffHeight = canvasHeight - contentHeight

  const scaleWidthFactor = canvasWidth > contentWidth ? diffWidth * 0.5 : 0
  const scaleHeightFactor = canvasHeight > contentHeight ? diffHeight * 0.5 : 0
  const nextBounds = {
    minX: canvasWidth - contentWidth - scaleWidthFactor,
    maxX: scaleWidthFactor,
    minY: canvasHeight - contentHeight - scaleHeightFactor,
    maxY: scaleHeightFactor,
  }

  return nextBounds
}

export const getElemBounds = (page: HTMLDivElement, elem: HTMLDivElement, scale = 1): ElemBounds => {
  const minSize = elem.dataset.minSize ? parseInt(elem.dataset.minSize) : MINIMUM_PLACE_SIZE

  const pageWidth = page.offsetWidth
  const pageHeight = page.offsetHeight
  const elemWidth = elem.offsetWidth
  const elemHeight = elem.offsetHeight

  const diffWidth = pageWidth - elemWidth
  const diffHeight = pageHeight - elemHeight

  const horMargin = pageWidth * PAGE_HOR_MARGIN
  const verMargin = pageHeight * PAGE_VER_MARGIN

  const nextBounds = {
    minX: horMargin * scale,
    maxX: (diffWidth - horMargin) * scale,
    minY: verMargin * scale,
    maxY: (diffHeight - verMargin) * scale,
    minWidth: minSize,
    maxWidth: pageWidth - elem.offsetLeft - horMargin,
    minHeight: minSize,
    maxHeight: pageHeight - elem.offsetTop - verMargin,
  }

  return nextBounds
}

export const getElemConstraints = (elem: HTMLDivElement) => (elem.dataset.constraints || '').split(' ')

export const resizeElem = (
  prevPosition: Coords,
  prevSize: Size,
  pointerPosition: Coords,
  scale: number,
  elemBounds: ElemBounds,
  resizeDirection: ResizeControls,
  constraints: string[] = []
): Coords & Size => {
  const isHeightConstrained = constraints.includes('height')
  const maxX = prevPosition.x + prevSize.width - elemBounds.minWidth
  const maxY = prevPosition.y + prevSize.height - elemBounds.minHeight
  let maxWidth = prevPosition.x - elemBounds.minX + prevSize.width
  let maxHeight = prevPosition.y - elemBounds.minY + prevSize.height

  let { x: nextX, y: nextY } = prevPosition
  let { width: nextWidth, height: nextHeight } = prevSize

  if (resizeDirection === ResizeControls.TOP_LEFT) {
    nextX = prevPosition.x + pointerPosition.x / scale
    nextWidth = prevSize.width - pointerPosition.x / scale
    if (!isHeightConstrained) {
      nextY = prevPosition.y + pointerPosition.y / scale
      nextHeight = prevSize.height - pointerPosition.y / scale
    }
  } else if (resizeDirection === ResizeControls.TOP_RIGHT) {
    maxWidth = elemBounds.maxWidth
    nextWidth = prevSize.width + pointerPosition.x / scale
    if (!isHeightConstrained) {
      nextY = prevPosition.y + pointerPosition.y / scale
      nextHeight = prevSize.height - pointerPosition.y / scale
    }
  } else if (resizeDirection === ResizeControls.BOTTOM_RIGHT) {
    maxWidth = elemBounds.maxWidth
    maxHeight = elemBounds.maxHeight
    nextWidth = prevSize.width + pointerPosition.x / scale
    if (!isHeightConstrained) {
      nextHeight = prevSize.height + pointerPosition.y / scale
    }
  } else if (resizeDirection === ResizeControls.BOTTOM_LEFT) {
    maxHeight = elemBounds.maxHeight
    nextX = prevPosition.x + pointerPosition.x / scale
    nextWidth = prevSize.width - pointerPosition.x / scale
    if (!isHeightConstrained) {
      nextHeight = prevSize.height + pointerPosition.y / scale
    }
  }

  const x = getNumberLimitedByRange(nextX, elemBounds.minX, maxX)
  const y = getNumberLimitedByRange(nextY, elemBounds.minY, maxY)
  const width = getNumberLimitedByRange(nextWidth, elemBounds.minWidth, maxWidth)
  const height = getNumberLimitedByRange(nextHeight, elemBounds.minHeight, maxHeight)

  return { x, y, width, height }
}

export const limitPositionToBounds = (x: number, y: number, bounds: Bounds): Coords => {
  const { minX, minY, maxX, maxY } = bounds

  const correctedX = getNumberLimitedByRange(x, minX, maxX)
  const correctedY = getNumberLimitedByRange(y, minY, maxY)

  return { x: correctedX, y: correctedY }
}

export const getPositionFromOffset = (
  position: Coords,
  offset: Coords,
  scale: number,
  nextScale: number,
  bounds: Bounds
) => {
  const scaleDifference = nextScale - scale
  const calculatedX = position.x - offset.x * scaleDifference
  const calculatedY = position.y - offset.y * scaleDifference

  return limitPositionToBounds(calculatedX, calculatedY, bounds)
}

export const getPointersDistance = (events: PointerEvent[]) =>
  Math.sqrt(Math.pow(events[0].pageX - events[1].pageX, 2) + Math.pow(events[0].pageY - events[1].pageY, 2))

export const getCanvasMiddlePosition = (canvas: HTMLDivElement, x: number, y: number, scale: number): Coords => {
  const canvasWidth = canvas.offsetWidth
  const canvasHeight = canvas.offsetHeight
  const middleX = (canvasWidth / 2 - x) / scale
  const middleY = (canvasHeight / 2 - y) / scale

  return {
    x: middleX,
    y: middleY,
  }
}

export const getPointersMiddlePosition = (events: PointerEvent[], scale: number, content: HTMLDivElement): Coords => {
  const contentRect = content.getBoundingClientRect()
  const firstX = roundNumber(events[0].clientX - contentRect.left, 5)
  const firstY = roundNumber(events[0].clientY - contentRect.top, 5)
  const secondX = roundNumber(events[1].clientX - contentRect.left, 5)
  const secondY = roundNumber(events[1].clientY - contentRect.top, 5)

  return {
    x: (firstX + secondX) / 2 / scale,
    y: (firstY + secondY) / 2 / scale,
  }
}

export const getScaleFromPinch = (events: PointerEvent[], pinchStartDistance: number, pinchStartScale: number) => {
  const currentDistance = getPointersDistance(events)

  if (currentDistance > 0) {
    const touchProportion = currentDistance / pinchStartDistance
    const scaleDifference = touchProportion * pinchStartScale

    return scaleDifference
  }

  return null
}

export const getScrollRatio = (canvas: HTMLDivElement | null, content: HTMLDivElement | null, scale: number) => {
  if (canvas && content) {
    return canvas.offsetHeight / (content.offsetHeight * scale)
  }

  return 1
}

export const getScrollbarProperties = (
  canvas: HTMLDivElement | null,
  content: HTMLDivElement | null,
  y: number,
  scale: number
) => {
  if (canvas && content) {
    const calculatedHeight = getScrollRatio(canvas, content, scale) * HUNDRED
    const height = calculatedHeight >= MIN_SCROLLBAR_HEIGHT ? calculatedHeight : MIN_SCROLLBAR_HEIGHT
    const top = ((y * -1) / (content.offsetHeight * scale)) * HUNDRED
    const limitedTop = getNumberLimitedByRange(top, 0, HUNDRED - height)

    return { height: `${height < HUNDRED ? height : 0}%`, top: `${limitedTop}%` }
  }

  return { height: '0%', top: '0%' }
}

export const checkElementsCollision = (target: HTMLDivElement) => {
  const page = target.parentElement!
  const elements = page.querySelectorAll<HTMLDivElement>(`[data-type="${PointerModes.DRAG}"]`)
  const distanceX = Math.round((page!.offsetHeight * PAGE_VER_MARGIN) / 2)
  const distanceY = Math.round((page!.offsetWidth * PAGE_HOR_MARGIN) / 2)
  const maxX = target.offsetLeft + target.offsetWidth + distanceX
  const maxY = target.offsetTop + target.offsetHeight + distanceY
  const isCollisionFree = target.dataset?.ignoreCollision

  if (isCollisionFree) {
    return false
  }

  for (const key in elements) {
    const elem = elements[key]
    const isElemCollisionFree = elem.dataset?.ignoreCollision

    if (target !== elem) {
      if (
        elem.offsetLeft < maxX &&
        elem.offsetLeft + elem.offsetWidth + distanceX > target.offsetLeft &&
        elem.offsetTop < maxY &&
        elem.offsetTop + elem.offsetHeight + distanceY > target.offsetTop &&
        !isElemCollisionFree
      ) {
        return true
      }
    }
  }

  return false
}

export const checkIfElementChanged = (target: HTMLDivElement, { x, y }: Coords, { width, height }: Size) => {
  const { offsetLeft, offsetTop, offsetWidth, offsetHeight } = target

  if (offsetLeft !== x || offsetTop !== y || offsetWidth !== width || offsetHeight !== height) {
    return true
  }

  return false
}

export const checkIfElementWithinBounds = (target: HTMLDivElement, bounds: ElemBounds) => {
  const minX = target.offsetLeft
  const maxY = target.offsetTop
  const maxX = minX + target.offsetWidth

  if (minX >= bounds.minX && maxX <= bounds.maxX && maxY >= bounds.minY && maxY <= bounds.maxY) {
    return true
  }

  return false
}
