import { FetchFailureAction, FetchFailurePayload } from '../../../types/fetch'
import { Notification } from '../../../types/notifications'
import { OwnerViewDocumentData } from '../../../types/ownerViewContract'
import { createFetchActionTypes } from '../../../utils/createFetchActionTypes'

import * as TYPES from './ownerView.types'

export const fetchOwnerContractDocumentTypes = createFetchActionTypes(
  TYPES.OwnerViewActions.FETCH_OWNER_CONTRACT_DOCUMENT
)

export const fetchOwnerContractDocument = (contractId: string): TYPES.FetchOwnerContractDocumentAction => ({
  type: fetchOwnerContractDocumentTypes.request,
  payload: { contractId },
})
export const fetchOwnerContractDocumentSuccess = (
  data: OwnerViewDocumentData
): TYPES.FetchOwnerContractDocumentSuccessAction => ({
  type: fetchOwnerContractDocumentTypes.success,
  payload: data,
})
export const fetchOwnerContractDocumentFailure = (payload: FetchFailurePayload): FetchFailureAction => ({
  type: fetchOwnerContractDocumentTypes.failure,
  payload,
})

export const cleanOwnerView = () => ({
  type: TYPES.OwnerViewActions.CLEAN_OWNER_VIEW,
})

export const setOwnerViewNotification = (notification: Notification): TYPES.SetOwnerViewNotificationAction => ({
  type: TYPES.OwnerViewActions.SET_OWNER_NOTIFICATION,
  payload: notification,
})
export const cleanOwnerViewNotification = () => ({
  type: TYPES.OwnerViewActions.CLEAN_OWNER_NOTIFICATION,
})
