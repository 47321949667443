import { ContractVerificationResponse } from '../../../types/contractVerificaiton'
import { FetchFailureAction, FetchFailurePayload } from '../../../types/fetch'
import { createFetchActionTypes } from '../../../utils/createFetchActionTypes'

import * as TYPES from './verification.types'

export const fetchVerificationTypes = createFetchActionTypes(TYPES.VerificationActions.FETCH_CONTRACT_VERIFICATION)

export const fetchVerification = (contractId: string): TYPES.FetchVerification => ({
  type: fetchVerificationTypes.request,
  payload: { contractId },
})

export const fetchVerificationSuccess = (body: ContractVerificationResponse): TYPES.FetchVerificationSuccess => ({
  type: fetchVerificationTypes.success,
  payload: { body },
})

export const fetchVerificationFailure = (payload: FetchFailurePayload): FetchFailureAction => ({
  type: fetchVerificationTypes.failure,
  payload,
})

export const cleanVerification = () => ({
  type: TYPES.VerificationActions.CLEAN_CONTRACT_VERIFICATION,
})
