import * as yup from 'yup'

import { CONTACT_FIELD_ERRORS } from '../constants/contacts'

export const PHONE_MIN_LENGTH = 8
export const PHONE_MAX_LENGTH = 15

export const phoneValidationSchema = (phoneField: string, required = true) => {
  const field = required ? yup.string().required('PHONE_REQUIRED') : yup.string().nullable()

  return yup.object().shape({
    [phoneField]: field
      .min(PHONE_MIN_LENGTH, CONTACT_FIELD_ERRORS.INVALID_PHONE)
      .max(PHONE_MAX_LENGTH, CONTACT_FIELD_ERRORS.INVALID_PHONE),
  })
}
