import React from 'react'

import MainTemplate from '../../components/Main/MainTemplate'

import { PreferencesNotifications as PreferencesNotificationsPage } from './PreferencesNotifications'

export const PreferencesNotifications = () => (
  <MainTemplate>
    <PreferencesNotificationsPage />
  </MainTemplate>
)
