import { combineEpics, Epic, ofType } from 'redux-observable'
import { catchError, map, mergeMap, takeUntil } from 'rxjs/operators'

import { historyService } from '../../../api'
import { catchFetchError } from '../../../utils/catchFetchError'
import { signingRecipientTokenSelector } from '../signing/signing.selectors'

import * as ACTIONS from './history.actions'

export const fetchHistory: Epic = (action$, state$) =>
  action$.pipe(
    ofType(ACTIONS.fetchHistoryTypes.request),
    mergeMap(({ payload }) =>
      historyService.fetchHistory(payload.contractId, signingRecipientTokenSelector(state$.value)).pipe(
        map((response) => ACTIONS.fetchHistorySuccess(response)),
        catchError(catchFetchError(ACTIONS.fetchHistoryFailure)),
        takeUntil(action$.pipe(ofType(ACTIONS.fetchHistoryTypes.success, ACTIONS.fetchHistoryTypes.failure)))
      )
    )
  )

export const historyEpics = combineEpics(fetchHistory)
