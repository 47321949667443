import { FETCH_STATUSES } from '../../types/fetch'
import { createReducer } from '../createReducer'

import * as TYPES from './phones.types'

type Actions = TYPES.FetchPhonesAction | TYPES.FetchPhonesSuccessAction | TYPES.FetchPhonesFailureAction

export const initialState: TYPES.PhonesState = {
  fetchStatus: FETCH_STATUSES.IDLE,
  data: [],
}

export const phones = createReducer<TYPES.PhonesState, Actions>(initialState, {
  [TYPES.PhonesActions.FETCH_PHONES]: (state) => ({
    ...state,
    fetchStatus: FETCH_STATUSES.REQUEST,
  }),
  [TYPES.PhonesActions.FETCH_PHONES_SUCCESS]: (state, action) => ({
    ...state,
    fetchStatus: FETCH_STATUSES.SUCCESS,
    data: (action as TYPES.FetchPhonesSuccessAction).payload,
  }),
  [TYPES.PhonesActions.FETCH_PHONES_FAILURE]: (state, action) => ({
    ...state,
    fetchStatus: FETCH_STATUSES.FAILURE,
    error: (action as TYPES.FetchPhonesFailureAction).payload,
  }),
})
