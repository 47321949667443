import React from 'react'

import { SvgIconProps } from '../../ui/SvgIcon/SvgIcon.types'
import SvgIcon from '../../ui/SvgIcon'

export const FilledCommentIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon viewBox="0 0 30 28" {...props}>
    <path d="M0.332031 4.33317C0.332031 2.30813 1.97365 0.666504 3.9987 0.666504H25.9987C28.0237 0.666504 29.6654 2.30813 29.6654 4.33317V18.9998C29.6654 21.0249 28.0237 22.6665 25.9987 22.6665H9.25765L2.23222 27.3501C1.85717 27.6001 1.37495 27.6235 0.977538 27.4108C0.580124 27.1981 0.332031 26.7839 0.332031 26.3332V4.33317Z" />
  </SvgIcon>
)
