import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { SELECT_SUBSCRIPTION_MODALS } from '../../../constants/selectSubscription'
import { usePlansFetch } from '../../../hooks/subscription/usePlansFetch'
import { openModal } from '../../../store/modals/modals.actions'
import translations from '../../../translations/keys'
import ContactUs from '../../components/ContactUs'
import SubscriptionPlan from '../../components/SubscriptionPlan'
import Typography from '../../ui/Typography'
import { TYPOGRAPHY_COMPONENTS, TYPOGRAPHY_NAMES } from '../../ui/Typography/Typography.types'
import * as PLANS_SELECTORS from '../../../store/subscription/plans/plans.selectors'
import { SubscriptionPlansSkeleton } from '../../components/Skeletons/SubscriptionPlansSkeleton'
import Box from '../../ui/Box'
import { getFeaturesList } from '../../../utils/subscription/getFeaturesList'
import { BUTTON_VARIANTS } from '../../ui/Button/Button.types'
import { location } from '../../../utils/location'
import { CardSpecificationTypes } from '../../../types/cardSpecification'

import { StyledPlans } from './SelectSubscription.styles'
import { useErrors } from './hooks/useErrors'
import Modals from './components/Modals'

export const SelectSubscription = () => {
  const history = useHistory()
  const dispatch = useDispatch()

  const plans = useSelector(PLANS_SELECTORS.subscriptionPlansDataSelector)
  const isLoading = useSelector(PLANS_SELECTORS.subscriptionPlansIsLoadingSelector)

  usePlansFetch()
  useErrors()

  const onContactUs = () => {
    dispatch(openModal(SELECT_SUBSCRIPTION_MODALS.SELECT_SUBSCRIPTION_CONTACT_US_MODAL))
  }

  return (
    <div>
      <Box mb={4}>
        <Typography name={TYPOGRAPHY_NAMES.H3} component={TYPOGRAPHY_COMPONENTS.h3}>
          {translations.SELECT_SUBSCRIPTION}
        </Typography>
      </Box>
      {isLoading ? (
        <SubscriptionPlansSkeleton />
      ) : (
        <StyledPlans>
          {plans?.map((plan) => {
            const featuresList = getFeaturesList(plan.features, null, null)
            const buttons = [
              {
                label: translations.SUBSCRIBE_NOW,
                onClick: () => {
                  dispatch(openModal(SELECT_SUBSCRIPTION_MODALS.SELECT_SUBSCRIPTION_TERM_MODAL, { plan }))
                },
                variant: BUTTON_VARIANTS.PRIMARY,
              },
              {
                label: translations.START_FREE_TRIAL_BUTTON,
                onClick: () => {
                  history.push(
                    location.cardSpecification(plan.id, {
                      type: CardSpecificationTypes.TRIAL,
                    })
                  )
                },
                variant: BUTTON_VARIANTS.SECONDARY,
              },
            ]

            return (
              <SubscriptionPlan
                key={plan.id}
                data={plan}
                isActive={false}
                buttons={buttons}
                featuresList={featuresList}
              />
            )
          })}
        </StyledPlans>
      )}
      <ContactUs onClick={onContactUs} />
      <Modals />
    </div>
  )
}
