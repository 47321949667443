export interface ChangeEmailFormValues {
  email: string
}

export type ChangeEmailBody = ChangeEmailFormValues

export enum ChangeEmailFields {
  email = 'email',
}

export interface ChangeEmailQueryParams {
  verify: string
}
