import React from 'react'

import ErrorBoundary from '../../components/ErrorBoundary/ErrorBoundary'

import { ContractOwnerView as ContractOwnerViewPage } from './ContractOwnerView'

export const ContractOwnerView = () => (
  <ErrorBoundary>
    <ContractOwnerViewPage />
  </ErrorBoundary>
)
