import { combineEpics, Epic, ofType } from 'redux-observable'
import { catchError, map, mergeMap, takeUntil } from 'rxjs/operators'

import { apiIntegrationService } from '../../api'
import { catchFetchError } from '../../utils/catchFetchError'

import * as ACTIONS from './apiIntegration.actions'

const fetchPublicToken: Epic = (action$) =>
  action$.pipe(
    ofType(ACTIONS.fetchPublicTokenTypes.request),
    mergeMap(() =>
      apiIntegrationService.fetchPublicToken().pipe(
        map((response) => ACTIONS.fetchPublicTokenSuccess(response.publicToken)),
        catchError(catchFetchError(ACTIONS.fetchPublicTokenFailure)),
        takeUntil(action$.pipe(ofType(ACTIONS.fetchPublicTokenTypes.success, ACTIONS.fetchPublicTokenTypes.failure)))
      )
    )
  )

const fetchGeneratePublicToken: Epic = (action$) =>
  action$.pipe(
    ofType(ACTIONS.fetchGeneratePublicTokenTypes.request),
    mergeMap(() =>
      apiIntegrationService.fetchGeneratePublicToken().pipe(
        map((response) => ACTIONS.fetchGeneratePublicTokenSuccess(response.publicToken)),
        catchError(catchFetchError(ACTIONS.fetchPublicTokenFailure)),
        takeUntil(
          action$.pipe(
            ofType(ACTIONS.fetchGeneratePublicTokenTypes.success, ACTIONS.fetchGeneratePublicTokenTypes.failure)
          )
        )
      )
    )
  )

export const apiIntegrationEpics = combineEpics(fetchPublicToken, fetchGeneratePublicToken)
