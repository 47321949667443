import React from 'react'

import MainTemplate from '../../components/Main/MainTemplate'

import { Error404 as Error404Page } from './Error404'

export const Error404 = () => (
  <MainTemplate>
    <Error404Page />
  </MainTemplate>
)
