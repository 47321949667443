import styled, { css } from 'styled-components'

import { SignatureType } from '../../../types/placement'
import fancyBorder from '../../utils/fancyBorder'

import { SignatureBoxProps } from './SignatureBox.types'

export const Tooltip = styled.div`
  display: flex;
  position: absolute;
  bottom: -10px;
  min-width: 150px;
  min-height: 38px;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  color: ${({ theme }) => theme.palette.white};
  text-align: center;
  background-color: ${({ theme }) => theme.palette.black};
  border-radius: 4px;
  visibility: hidden;
  opacity: 0;
  transition: opacity 200ms, visibility 200ms;
  transform: translateY(100%);
  ${({ theme }) => theme.textStyles.bodySmall12}

  &:hover {
    display: none;
  }
  &::after {
    display: block;
    content: '';
    position: absolute;
    top: -9px;
    left: 50%;
    width: 0;
    height: 0;
    margin-left: -10px;
    border-left: solid transparent 10px;
    border-right: solid transparent 10px;
    border-bottom: ${({ theme }) => `10px solid ${theme.palette.black}`};
  }
`
export const BoxContent = styled.div<Pick<SignatureBoxProps, 'signSrc'>>`
  width: 100%;
  ${({ signSrc }) => (signSrc ? 'height: 100%;' : '')}
  text-align: center;
  pointer-events: none;
`
export const BoxText = styled.div<{ type: SignatureType }>`
  overflow: hidden;
  position: relative;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: ${({ theme }) => theme.palette.grey};
  ${({ type, theme }) => (type === 'sign' ? theme.textStyles.bold14 : theme.textStyles.bodySmall12)}
`
const selectedStyles = css`
  background-color: ${({ theme }) => theme.palette.strokeGrey};
  background-image: ${({ theme }) => fancyBorder(theme.palette.blueOcean, 4)};
  z-index: 100;

  ${BoxText} {
    color: ${({ theme }) => theme.palette.black};
  }
`
const signableStyles = css`
  background-color: ${({ theme }) => theme.palette.yellow};

  ${BoxText} {
    font-family: Tahoma-Bold;
    color: ${({ theme }) => theme.palette.black};
  }
`
const readonlyStyles = css<Pick<SignatureBoxProps, 'signSrc'>>`
  cursor: default;
  ${({ signSrc }) => (signSrc ? 'background-image: none;' : '')}
`
export const Box = styled.div<
  Pick<SignatureBoxProps, 'signSrc' | 'isSelected' | 'readonly' | 'isMoving' | 'isResizing'> & { isSignable: boolean }
>`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 10px;
  background-color: transparent;
  background-image: ${({ theme }) => fancyBorder(theme.palette.grey, 2)};
  border-radius: 7px;
  z-index: 10;
  cursor: pointer;

  ${({ isMoving, isResizing }) =>
    !isMoving &&
    !isResizing &&
    css`
      &:hover {
        z-index: 105;

        ${Tooltip} {
          opacity: 1;
          visibility: visible;
        }
      }
    `}

  ${({ isSignable, signSrc }) => (isSignable && !signSrc ? signableStyles : '')}
  ${({ isSelected }) => isSelected && selectedStyles}
  ${({ readonly }) => readonly && readonlyStyles}
`
export const SignatureImg = styled.img`
  max-width: 100%;
  max-height: 100%;
`
export const FullName = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
`
export const Actions = styled.div`
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.palette.blueDark};
  color: ${({ theme }) => theme.palette.white};
  white-space: nowrap;
  cursor: default;
`
export const Action = styled.div`
  display: flex;

  & ~ & {
    margin-left: 16px;
  }
`

export const SignatureImgWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`
