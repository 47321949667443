import { Action } from 'redux'

import { FetchFailurePayload, FETCH_STATUSES } from '../../../types/fetch'

export interface VoidState {
  fetchStatus: FETCH_STATUSES
  error: FetchFailurePayload | null
}

export enum VoidActions {
  'FETCH_VOID_CONTRACT' = 'FETCH_VOID_CONTRACT',
  'CLEAN_VOID_CONTRACT' = 'CLEAN_VOID_CONTRACT',
}

export interface FetchVoidContract extends Action {
  payload: { contractId: string }
}

export interface FetchVoidContractSuccess extends Action {
  payload: { contractId: string }
}
