export interface RestorePasswordBody {
  password: string
  linkId: string
  code: string
}

export interface RestorePasswordValues {
  password: string
  confirmPassword: string
}

export enum RestorePasswordFields {
  password = 'password',
  confirmPassword = 'confirmPassword',
}
