import React from 'react'
import { useTheme } from 'styled-components'

import { StyledThemeScheme } from '../../themes/light'

export const PaymentSvg = () => {
  const theme = useTheme() as StyledThemeScheme

  return (
    <svg width="81" height="80" viewBox="0 0 81 80" fill="none">
      <path
        d="M78.9863 27.6848L52.4906 1.18506C51.7382 0.425485 50.713 -0.00133591 49.6438 3.14121e-06H49.6317C48.5695 0.000505284 47.5522 0.427494 46.8077 1.18506L18.7011 29.2876H4.01714C1.79851 29.2876 0 31.0861 0 33.3048V70.7981C0 73.0167 1.79851 74.8152 4.01714 74.8152H23.8564L27.8522 78.8149C28.6045 79.5745 29.6297 80.0013 30.699 80H30.7097C31.7722 79.9997 32.7902 79.5727 33.5351 78.8149L37.5388 74.8152H68.2913C70.51 74.8152 72.3085 73.0167 72.3085 70.7981V40.0455L78.9796 33.3744C79.741 32.6291 80.1709 31.609 80.1727 30.5437C80.1767 29.4701 79.7492 28.44 78.9863 27.6848ZM61.9523 14.4376L47.1023 29.2876H35.7311L56.2667 8.75201L61.9523 14.4376ZM48.7038 3.07311C49.2131 2.55139 50.049 2.54135 50.5709 3.05085C50.5784 3.05822 50.586 3.06558 50.5932 3.07311L54.3733 6.8586L31.9429 29.2876H22.4879L48.7038 3.07311ZM31.639 76.9269C31.3941 77.1826 31.0557 77.3276 30.7016 77.3286C30.3428 77.3278 29.9993 77.1831 29.7482 76.9269L27.6446 74.8152H33.752L31.639 76.9269ZM69.6304 70.7981C69.6304 71.5376 69.0308 72.1371 68.2913 72.1371H4.01714C3.27765 72.1371 2.67809 71.5376 2.67809 70.7981V52.0514H69.6304V70.7981ZM69.6304 49.3733H2.67809V41.339H69.6304V49.3733ZM69.6304 38.661H2.67809V33.3048C2.67809 32.5653 3.27765 31.9657 4.01714 31.9657H68.2913C69.0308 31.9657 69.6304 32.5653 69.6304 33.3048V38.661ZM77.0929 31.4743L72.3085 36.2587V33.3048C72.3085 31.0861 70.51 29.2876 68.2913 29.2876H50.8837L63.8404 16.331L77.0875 29.5782C77.3448 29.8303 77.4896 30.1754 77.4892 30.5356C77.4889 30.8891 77.346 31.2276 77.0929 31.4743Z"
        fill={theme.palette.blue}
      />
    </svg>
  )
}
