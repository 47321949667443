import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import translations from '../../../../../translations/keys'
import { RecipientActions } from '../../../../../types/recipients'
import { RECIPIENTS_DIAGRAM_MODALS } from '../../../../constants/recipientsDiagram'
import { getFormattedDate } from '../../../../../utils/date'
import { modalsPayloadSelector } from '../../../../../store/modals/modals.selectors'
import Box from '../../../../ui/Box'
import Modal from '../../../Modal'
import Typography from '../../../../ui/Typography'
import { TYPOGRAPHY_NAMES } from '../../../../ui/Typography/Typography.types'

import { ViewRecipientModalProps } from './ViewRecipientModal.types'
import * as Styled from './ViewRecipientModal.styles'
export const ViewRecipientModal: React.FC<ViewRecipientModalProps> = ({ isOwner, onClose }) => {
  const { t } = useTranslation()
  const { recipient } = useSelector(modalsPayloadSelector(RECIPIENTS_DIAGRAM_MODALS.VIEW_DIAGRAM_RECIPIENT))
  const deadline = recipient.deadline ? getFormattedDate(recipient.deadline) : null
  const action = t(translations[recipient.action])
  const signatureType = recipient.action !== RecipientActions.VIEW ? t(translations[recipient.signatureType]) : null

  const handleClose = () => {
    onClose()
  }

  return (
    <Modal onClose={handleClose}>
      <Box width={380} display="flex" justifyContent="center" alignItems="center" flexDirection="column" pt={3} pb={3}>
        <Typography name={TYPOGRAPHY_NAMES.H1}>{t(translations.RECIPIENT_INFO)}</Typography>
        <Box mt={3} width="100%">
          <Box mb={3}>
            <Styled.Label>{t(translations.NAME)}</Styled.Label>
            <Styled.Text>{recipient.name}</Styled.Text>
          </Box>
          <Box mb={3}>
            <Styled.Label>{t(translations.EMAIL_LABEL)}</Styled.Label>
            <Styled.Text>{recipient.email}</Styled.Text>
          </Box>
          <Box display="flex" flexWrap="wrap">
            <Styled.Field>
              <Styled.Label>{t(translations.ACTION)}</Styled.Label>
              <Styled.Text>{action}</Styled.Text>
            </Styled.Field>
            {signatureType && (
              <Styled.Field>
                <Styled.Label>{t(translations.SIGNATURE_FIELD)}</Styled.Label>
                <Styled.Text>{signatureType}</Styled.Text>
              </Styled.Field>
            )}
            {deadline && (
              <Styled.Field>
                <Styled.Label>{t(translations.DEADLINE_SLA_LABEL)}</Styled.Label>
                <Styled.Text>{deadline}</Styled.Text>
              </Styled.Field>
            )}
            <Styled.Field>
              <Styled.Label>{t(translations.CYCLE_ORDER)}</Styled.Label>
              <Styled.Text>{recipient.cycleOrder}</Styled.Text>
            </Styled.Field>
            {isOwner && (
              <Styled.Field>
                <Styled.Label>{t(translations.AUTHENTICATION)}</Styled.Label>
                <Styled.Text>{recipient.authentication}</Styled.Text>
              </Styled.Field>
            )}
            {isOwner && recipient.phone && (
              <Styled.Field>
                <Styled.Label>{t(translations.PHONE_NUMBER)}</Styled.Label>
                <Styled.Text>{recipient.phone}</Styled.Text>
              </Styled.Field>
            )}
            {recipient.department && (
              <Styled.Field>
                <Styled.Label>{t(translations.DEPARTMENT_LABEL)}</Styled.Label>
                <Styled.Text>{recipient.department}</Styled.Text>
              </Styled.Field>
            )}
          </Box>
        </Box>
      </Box>
    </Modal>
  )
}
