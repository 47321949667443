import React, { useCallback, useMemo } from 'react'
import { DataFunc, Mention, MentionsInput, OnChangeHandlerFunc, SuggestionDataItem } from 'react-mentions'
import { useTheme } from 'styled-components'

import { StyledThemeScheme } from '../../../themes/light'
import Typography from '../Typography'
import { TYPOGRAPHY_NAMES } from '../Typography/Typography.types'
import { MENTION_TRIGGER } from '../../../constants/mentions'

import { useMentionsChange } from './hooks/useMentionsChange'
import { StyledSuggestion, getMentionsInputStyles, StyledWrapper, getMentionStyle } from './Mentions.styles'
import { MentionsProps } from './Mentions.types'
import { prepareSuggestionData } from './utils/prepareData'

export const Mentions: React.FC<MentionsProps> = ({
  disabled,
  value,
  name,
  suggestions,
  placeholder,
  autoFocus = false,
  suggestionsPortalHost,
  onMentionsChange,
  onChange,
}) => {
  const theme = useTheme() as StyledThemeScheme
  const suggestionData = useMemo(() => prepareSuggestionData(suggestions), [suggestions])
  const { updateMentions } = useMentionsChange({ onMentionsChange })

  const renderSuggestion = useCallback(
    (
      suggestion: SuggestionDataItem,
      search: string,
      highlightedDisplay: React.ReactNode,
      index: number,
      focused: boolean
    ) => (
      <StyledSuggestion focused={focused}>
        <Typography name={TYPOGRAPHY_NAMES.body14} color="textColor">
          {suggestion.display}
        </Typography>
        <Typography name={TYPOGRAPHY_NAMES.body14} color="grey">
          {suggestion.id}
        </Typography>
      </StyledSuggestion>
    ),
    []
  )
  const getData: DataFunc = useCallback(
    (query) => {
      const queryInLowerCase = query.toLocaleLowerCase()
      return suggestionData.filter(
        ({ id, display }) =>
          `${id}`.toLocaleLowerCase().includes(queryInLowerCase) ||
          display?.toLocaleLowerCase().includes(queryInLowerCase)
      )
    },
    [suggestionData]
  )
  const handleChange: OnChangeHandlerFunc = useCallback(
    (event, newValue, newPlainTextValue, mentions) => {
      updateMentions(mentions)
      onChange(event.target.value)
    },
    [updateMentions, onChange]
  )

  return (
    <StyledWrapper>
      <MentionsInput
        disabled={disabled}
        data-testid="mentions-input"
        name={name}
        value={value}
        placeholder={placeholder}
        autoFocus={autoFocus}
        style={getMentionsInputStyles(theme)}
        onChange={handleChange}
        suggestionsPortalHost={suggestionsPortalHost}
      >
        <Mention
          trigger={MENTION_TRIGGER}
          data={getData}
          renderSuggestion={renderSuggestion}
          displayTransform={(id, display) => `${MENTION_TRIGGER}${display}`}
          appendSpaceOnAdd
          style={getMentionStyle(theme)}
          data-testid="mentions-mention"
        />
      </MentionsInput>
    </StyledWrapper>
  )
}
