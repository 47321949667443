export interface Signature {
  id: string
  signature: string
  initials: string
  image?: string
}

export interface CreateSignatureBody {
  signature: string
  initials: string
  dynamicSignatures?: Array<string>
}

export interface ValidateSignatureBody {
  dynamicSignatures: Array<string>
}

export enum SIGNATURE_TYPE {
  NO_TYPE = '',
  SIGNATURE = 'SIGNATURE',
  INITIALS = 'INITIALS',
}
