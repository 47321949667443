import React from 'react'

import { SvgIconProps } from '../../ui/SvgIcon/SvgIcon.types'
import SvgIcon from '../../ui/SvgIcon'

export const SignatureIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <path d="M16.125 7.17188V6.04688C16.125 5.94375 16.0406 5.85938 15.9375 5.85938H6.9375C6.83437 5.85938 6.75 5.94375 6.75 6.04688V7.17188C6.75 7.275 6.83437 7.35938 6.9375 7.35938H15.9375C16.0406 7.35938 16.125 7.275 16.125 7.17188ZM6.9375 9.23438C6.83437 9.23438 6.75 9.31875 6.75 9.42188V10.5469C6.75 10.65 6.83437 10.7344 6.9375 10.7344H11.25C11.3531 10.7344 11.4375 10.65 11.4375 10.5469V9.42188C11.4375 9.31875 11.3531 9.23438 11.25 9.23438H6.9375ZM11.25 19.9688H4.875V3.46875H18V10.4062C18 10.5094 18.0844 10.5938 18.1875 10.5938H19.5C19.6031 10.5938 19.6875 10.5094 19.6875 10.4062V2.53125C19.6875 2.11641 19.3523 1.78125 18.9375 1.78125H3.9375C3.52266 1.78125 3.1875 2.11641 3.1875 2.53125V20.9062C3.1875 21.3211 3.52266 21.6562 3.9375 21.6562H11.25C11.3531 21.6562 11.4375 21.5719 11.4375 21.4688V20.1562C11.4375 20.0531 11.3531 19.9688 11.25 19.9688Z" />
    <path d="M9.98332 14.8354C9.48052 15.841 9.25 17.0981 9.25 18H7.75C7.75 16.9019 8.01948 15.409 8.64168 14.1646C9.25877 12.9304 10.3338 11.75 12 11.75C12.9008 11.75 13.763 12.2719 14.4436 12.8769C14.721 13.1235 14.987 13.4004 15.2348 13.6957C15.7492 13.4194 16.3416 13.25 17 13.25C18.3577 13.25 19.3124 14.1079 19.8887 15.0444C20.4627 15.977 20.75 17.1135 20.75 18H19.25C19.25 17.3865 19.0373 16.523 18.6113 15.8306C18.1876 15.1421 17.6423 14.75 17 14.75C16.6836 14.75 16.3838 14.8195 16.1086 14.9405C16.1476 15.0079 16.1854 15.0753 16.222 15.1428C16.6518 15.9364 16.9714 16.8485 16.8949 17.6351C16.8545 18.0505 16.6966 18.4831 16.3303 18.8053C15.9669 19.1249 15.496 19.25 15 19.25C14.5747 19.25 14.1774 19.1536 13.841 18.9385C13.5012 18.7211 13.2705 18.4122 13.1369 18.0687C12.88 17.4081 12.9815 16.639 13.2385 15.9782C13.4167 15.5198 13.6958 15.0508 14.0638 14.6338C13.8678 14.4026 13.6601 14.1874 13.447 13.9981C12.862 13.4781 12.3492 13.25 12 13.25C11.1662 13.25 10.4912 13.8196 9.98332 14.8354ZM14.945 15.936C14.8191 16.1219 14.7151 16.3198 14.6365 16.5218C14.456 16.986 14.4637 17.3419 14.5349 17.525C14.5654 17.6034 14.6043 17.6461 14.6492 17.6748C14.6976 17.7058 14.8003 17.75 15 17.75C15.254 17.75 15.3299 17.6876 15.3396 17.679C15.3464 17.673 15.3877 17.6368 15.402 17.4899C15.4336 17.1642 15.2966 16.6072 14.945 15.936Z" />
  </SvgIcon>
)
