import { createReducer } from '../createReducer'
import i18n from '../../translations/i18n'
import { getDefaultLanguage } from '../../utils/rtl/getDefaultLanguage'

import * as TYPES from './language.types'

type Actions = TYPES.ChangeLanguageAction

const initialState: TYPES.LanguageState = {
  language: getDefaultLanguage(),
}

export const language = createReducer<TYPES.LanguageState, Actions>(initialState, {
  [TYPES.LanguagesActions.CHANGE_LANGUAGE]: (state, action) => {
    i18n.changeLanguage(action.payload.language)

    return {
      ...state,
      language: action.payload.language,
    }
  },
})
