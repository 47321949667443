export enum CardPaymentOption {
  VISA = 'VISA',
  MASTERCARD = 'MASTERCARD',
  AMEX = 'AMEX',
}

export interface Card {
  expireDate: string
  holderName: string | null
  id: string
  lastDigits: string
  paymentOption: CardPaymentOption
  token: string
  default: boolean
}

export type Cards = Card[]
