import { Epic, combineEpics, ofType } from 'redux-observable'
import { catchError, map, mergeMap, switchMap, takeUntil } from 'rxjs/operators'

import { contractsService, documentsService } from '../../../api'
import { catchFetchError } from '../../../utils/catchFetchError'
import prepareForPreview from '../../../utils/documents/prepareForPreview'

import * as ACTIONS from './preview.actions'

const fetchPreview: Epic = (action$) =>
  action$.pipe(
    ofType(ACTIONS.fetchPreviewTypes.request),
    mergeMap(({ payload }) =>
      contractsService.fetchContract(payload.contractId).pipe(
        map((response) => ({ documentId: response.document.id, name: response.name })),
        switchMap(({ documentId, name }) =>
          documentsService.fetchDocumentTotalPages(documentId).pipe(
            map(({ totalPages }) =>
              ACTIONS.fetchPreviewSuccess({
                documentId,
                name,
                total: totalPages,
                pages: prepareForPreview(documentId, totalPages),
              })
            )
          )
        ),
        catchError(catchFetchError(ACTIONS.fetchPreviewFailure)),
        takeUntil(action$.pipe(ofType(ACTIONS.fetchPreviewTypes.success, ACTIONS.fetchPreviewTypes.failure)))
      )
    )
  )

export const previewEpics = combineEpics(fetchPreview)
