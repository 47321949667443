import React from 'react'
import { useSelector } from 'react-redux'
import { useTheme } from 'styled-components'

import { useContractsListFetch } from '../../../../../hooks/contracts/useContractsListFetch'
import {
  contractsListDataSelector,
  contractsListLoadingSelector,
  contractsListIsFirstLoadSelector,
  contractsListSearchTextSelector,
  contractsListIsAppliedFiltersSelector,
  contractsListFetchStatusSelector,
  contractsListErrorSelector,
  contractsListFolderId,
} from '../../../../../store/contracts/contractsList/contractsList.selectors'
import { ContractsListSkeleton } from '../../../../components/Skeletons/ContractsListSkeleton'
import { useShowFetchError } from '../../../../hooks/useShowFetchError'
import ContractsList from '../ContractsList'
import { ContractsModals } from '../ContractsModals'
import EmptyContracts from '../EmptyContracts'
import { ContractsProps } from '../../Contracts.types'
import { StyledThemeScheme } from '../../../../../themes/light'
import useMediaQuery from '../../../../hooks/useMediaQuery'
import { MobileContractsListSkeleton } from '../../../../components/Skeletons/MobileContractsListSkeleton'

export const ContractsContainer: React.FC<ContractsProps> = ({ category }) => {
  const theme = useTheme() as StyledThemeScheme
  const mobileScreen = useMediaQuery(theme.devices.mobile)
  const contracts = useSelector(contractsListDataSelector)
  const loading = useSelector(contractsListLoadingSelector)
  const isFirstLoad = useSelector(contractsListIsFirstLoadSelector)
  const searchText = useSelector(contractsListSearchTextSelector)
  const isFiltersApplied = useSelector(contractsListIsAppliedFiltersSelector)
  const fetchStatus = useSelector(contractsListFetchStatusSelector)
  const error = useSelector(contractsListErrorSelector)
  const folderId = useSelector(contractsListFolderId)
  const isEmptyContracts = !contracts.length
  const isFirstLoading = isEmptyContracts && loading && isFirstLoad
  const showPlaceholder = isEmptyContracts && !searchText && !loading && !isFiltersApplied && !folderId

  useContractsListFetch(category)
  useShowFetchError(fetchStatus, error)

  const renderContent = () => {
    if (isFirstLoading) {
      return mobileScreen ? <MobileContractsListSkeleton /> : <ContractsListSkeleton />
    } else if (showPlaceholder) {
      return <EmptyContracts category={category} />
    }
    return <ContractsList />
  }

  return (
    <>
      {renderContent()}
      <ContractsModals />
    </>
  )
}
