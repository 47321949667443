import styled from 'styled-components'

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`
export const Placeholder = styled.div`
  align-self: center;
  margin-top: 208px;
  text-align: center;
  max-width: 208px;

  ${({ theme }) => `@media ${theme.devices.tablet} {
    margin-top: 50px;
  }`};
`
export const PlaceholderBtn = styled.div`
  margin: 0 auto;
`
