import { FETCH_STATUSES } from '../../../types/fetch'
import { RootState } from '../../rootReducer.types'

export const foldersDataSelector = (state: RootState) => state.contracts.folders.data

export const foldersIsLoadingSelector = (state: RootState) =>
  state.contracts.folders.fetchStatus === FETCH_STATUSES.REQUEST ||
  state.contracts.folders.fetchStatus === FETCH_STATUSES.IDLE

export const foldersIsSuccessSelector = (state: RootState) =>
  state.contracts.folders.fetchStatus === FETCH_STATUSES.SUCCESS

export const lastAddedFolderSelector = (state: RootState) => state.contracts.folders.lastAddedFolder

export const foldersDataErrorSelector = (state: RootState) => state.contracts.folders.error

export const foldersDataFetchStatusSelector = (state: RootState) => state.contracts.folders.fetchStatus

export const uploadFolderIsSuccessSelector = (state: RootState) =>
  state.contracts.folders.upload.fetchStatus === FETCH_STATUSES.SUCCESS

export const uploadFolderFetchStatusSelector = (state: RootState) => state.contracts.folders.upload.fetchStatus

export const uploadFolderDataErrorSelector = (state: RootState) => state.contracts.folders.upload.error
