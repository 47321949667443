import styled, { css } from 'styled-components'

import Link from '../../ui/Link'

const activeStyles = css`
  color: ${({ theme }) => theme.palette.white};
  text-decoration: none;
  background-color: ${({ theme }) => theme.palette.blue};
`
const disabledStyles = css`
  pointer-events: none;
  opacity: 0.5;
`

export const Nav = styled.nav`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: ${({ theme }) => theme.sizes.layout.menuWidth};
  background-color: ${({ theme }) => theme.palette.blueDark};
  z-index: 99;
`
export const Content = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: ${({ theme }) => theme.sizes.layout.headerHeight};
  color: ${({ theme }) => theme.palette.white};
`
export const List = styled.ul`
  width: 100%;
  margin-top: 20px;
  list-style: none;
`
export const ListItem = styled.li`
  margin: 10px 0;
`
export const IconWrapper = styled.span`
  display: inline-flex;
  margin-bottom: 3px;
`
export const MenuLink = styled(Link)<{ active?: boolean; disabled?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 6px 32px;
  color: ${({ theme, active }) => (active ? theme.palette.white : theme.palette.grey)};
  font-size: 12px;
  transition: color 200ms, background-color 200ms;

  &:hover,
  &:focus {
    ${activeStyles}
  }

  ${({ active }) => active && activeStyles}
  ${({ disabled }) => disabled && disabledStyles}
`
export const Footer = styled.div`
  width: 100%;
  margin-top: auto;
  margin-bottom: 20px;
`
