import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import Button from '../../../../ui/Button'
import { BUTTON_VARIANTS } from '../../../../ui/Button/Button.types'
import { openModal } from '../../../../../store/modals/modals.actions'
import { SIGNATURES_MODALS } from '../../../../constants/signatures'
import { COMMON_MODALS_IDS } from '../../../../ui/Modals/CommonModals/CommonModals.constants'
import { NOTIFICATION_MODAL_TYPES } from '../../../../ui/Modals/NotificationModal/NotificationModal.constants'
import { signaturesListDataSelector } from '../../../../../store/signatures/signatures.selectors'
import translations from '../../../../../translations/keys'
import { useAccessFeature } from '../../../../../hooks/subscription/useAccessFeature'
import { SubscriptionFeatures } from '../../../../../types/subscription'

export const AddSignature = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const signaturesListData = useSelector(signaturesListDataSelector)
  const { checkAccess } = useAccessFeature()

  const onAddSignature = () => {
    if (checkAccess(SubscriptionFeatures.FEATURE_SIGNATURE_MATCHING, false)) {
      dispatch(openModal(SIGNATURES_MODALS.SMART_SIGNATURE))
    } else if (signaturesListData.length > 1) {
      dispatch(
        openModal(COMMON_MODALS_IDS.NOTIFICATION, {
          type: NOTIFICATION_MODAL_TYPES.WARNING,
          description: translations.REACHED_MAX_NUMBER_OF_SIGNATURES,
        })
      )
    } else {
      dispatch(openModal(SIGNATURES_MODALS.CREATE_SIGNATURE))
    }
  }

  return signaturesListData.length !== 0 ? (
    <Button icon="add" variant={BUTTON_VARIANTS.INACTIVE} onClick={onAddSignature}>
      {t(translations.ADD_SIGNATURE)}
    </Button>
  ) : null
}
