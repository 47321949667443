import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { useDispatchUnmount } from '../useDispatchUnmount'
import { clearRecipients, fetchRecipients } from '../../store/contracts/recipients/recipients.actions'

export const useRecipientsFetch = (id: string) => {
  const dispatch = useDispatch()

  useEffect(() => {
    if (id) {
      dispatch(fetchRecipients(id))
    }
  }, [id])

  useDispatchUnmount(clearRecipients)
}
