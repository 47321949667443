import { Ajax } from '../ajax'
import {
  DocCorrectionsConfirmRequest,
  DocCorrectionsRequestBody,
  DocCorrectionsResponseBody,
} from '../../types/correction'

class CorrectionService extends Ajax {
  pathname = 'correction'

  fetchCorrections = (contractId: string) => this.getJSON<DocCorrectionsResponseBody>(`${this.pathname}/${contractId}`)

  fetchCreateCorrection = (contractId: string, body: DocCorrectionsRequestBody) =>
    this.post(`${this.pathname}/${contractId}`, body)

  fetchConfirmCorrection = ({ contractId, correctionId, ...body }: DocCorrectionsConfirmRequest) =>
    this.patch(`${this.pathname}/${contractId}/${correctionId}`, body)

  fetchCancelCorrectionConfirmation = (contractId: string, correctionId: string) =>
    this.patch(`${this.pathname}/${contractId}/${correctionId}`, {
      status: null,
    })
}

export const correctionService = new CorrectionService()
