import { Epic, combineEpics, ofType } from 'redux-observable'
import { catchError, map, mergeMap } from 'rxjs/operators'

import { authService } from '../../api'
import { catchFetchError } from '../../utils/catchFetchError'

import * as ACTIONS from './registration.actions'
import { registrationLinkIdSelector } from './registration.selectors'

const fetchSignUp: Epic = (action$) =>
  action$.pipe(
    ofType(ACTIONS.fetchSignUpTypes.request),
    mergeMap(({ payload }) =>
      authService.fetchSignUp(payload.body).pipe(
        map((action) => ACTIONS.fetchSignUpSuccess(action.response, payload.registrationForm)),
        catchError(catchFetchError(ACTIONS.fetchSignUpFailure))
      )
    )
  )

const fetchPhoneConfirm: Epic = (action$, state$) =>
  action$.pipe(
    ofType(ACTIONS.fetchPhoneConfirmTypes.request),
    mergeMap(({ payload }) =>
      authService.fetchPhoneConfirm(registrationLinkIdSelector(state$.value), payload.code).pipe(
        map(() => ACTIONS.fetchPhoneConfirmSuccess()),
        catchError(catchFetchError(ACTIONS.fetchPhoneConfirmFailure))
      )
    )
  )

const fetchResendCode: Epic = (action$) =>
  action$.pipe(
    ofType(ACTIONS.fetchResendCodeTypes.request),
    mergeMap(({ payload }) =>
      authService.fetchNewPhoneCode(payload.userId).pipe(
        map((action) => ACTIONS.fetchResendCodeSuccess(action.linkId)),
        catchError(catchFetchError(ACTIONS.fetchResendCodeFailure))
      )
    )
  )

export const registrationEpics = combineEpics(fetchSignUp, fetchPhoneConfirm, fetchResendCode)
