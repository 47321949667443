import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import translations from '../../../../../translations/keys'
import { SIGNING_TYPES } from '../../../../../types/signingOrder'
import { FETCH_STATUSES } from '../../../../../types/fetch'
import { location } from '../../../../../utils/location'
import { recipientsFetchSelector } from '../../../../../store/templates/recipients/recipients.selectors'
import { BackButton } from '../../../../ui/BackButton/BackButton'
import { RecipientsDiagram } from '../../../../ui/RecipientsDiagram'
import Typography from '../../../../ui/Typography'
import { TYPOGRAPHY_NAMES } from '../../../../ui/Typography/Typography.types'
import Box from '../../../../ui/Box'

import { PlacementSidebarProps } from './PlacementSidebar.types'

export const PlacementSidebar: React.FC<PlacementSidebarProps> = ({ sender, recipients }) => {
  const recipientsFetch = useSelector(recipientsFetchSelector)
  const { t } = useTranslation()
  const isReady = recipientsFetch.status === FETCH_STATUSES.SUCCESS
  const recipientsLength = recipients.sorted.length
  const title = recipientsLength > 0 ? t(translations.RECIPIENTS) : t(translations.TEMPLATES_CLM_NOT_SPECIFIED)

  return (
    <>
      <BackButton compact path={location.templatesAllUrl()} label={t(translations.BACK)} />
      {isReady && (
        <>
          <Box mb={3}>
            <Typography name={TYPOGRAPHY_NAMES.H4}>{title}</Typography>
          </Box>
          <RecipientsDiagram compact sender={sender} recipients={recipients} order={SIGNING_TYPES.SEPARATE} />
        </>
      )}
    </>
  )
}
