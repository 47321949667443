import React from 'react'

import { DownloadIcon, MoneyIcon, SignIcon } from '../../../../assets/icons'
import { Card } from '../Card/Card'

import { StyledFaqCategory } from './FaqCategory.styles'

export const FaqCategory = () => (
  <StyledFaqCategory>
    <Card icon={<MoneyIcon color="blue" />} isActive={false} text="Subscription" />
    <Card icon={<SignIcon color="blue" />} isActive={false} text="Subscription" />
    <Card icon={<DownloadIcon color="white" />} isActive={true} text="Subscription" />
    <Card icon={<MoneyIcon color="blue" />} isActive={false} text="Subscription" />
  </StyledFaqCategory>
)
