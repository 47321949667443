import { Observable, throwError } from 'rxjs'
import { tap } from 'rxjs/operators'

import { NETWORK_IS_BUSY } from '../../constants/errors'
import { FetchAxiosError, FetchError } from '../../types/fetch'
import { getEnvs } from '../getEnvs'

import { BreakerState, Method, Options } from './CircuitBreaker.types'

const {
  REACT_APP_CIRCUIT_BREAKER_MAX_ATTEMPTS = '30',
  REACT_APP_CIRCUIT_BREAKER_DENIED_MILLISECONDS = 30000,
} = getEnvs()

export class CircuitBreaker {
  endpoints: {
    [path: string]: {
      [method: string]: {
        attempts: number
        timer: number
        breakerState: BreakerState
      }
    }
  } = {}

  wrapRequest = <P>(request: (path: string) => Observable<P>, { path, method }: Options): Observable<P> => {
    const endpoint = this.getEndpoint(path, method)

    if (endpoint.breakerState === BreakerState.open) {
      return throwError({
        response: {
          statusCode: 500,
          message: [NETWORK_IS_BUSY],
        },
      })
    }

    return request(path).pipe(
      tap(
        () => {
          this.resetAttempts(path, method)
        },
        (error: FetchAxiosError) => {
          const response = error.response as FetchError

          if (response?.statusCode === 401) {
            return null
          }

          this.increaseAttempts(path, method)

          if (endpoint.attempts >= Number(REACT_APP_CIRCUIT_BREAKER_MAX_ATTEMPTS)) {
            this.denyRequest(path, method)
          }
        }
      )
    )
  }

  increaseAttempts = (path: string, method: Method) => {
    this.getEndpoint(path, method).attempts += 1
  }

  resetAttempts = (path: string, method: Method) => {
    this.getEndpoint(path, method).attempts = 0
  }

  denyRequest = (path: string, method: Method) => {
    const endpoint = this.getEndpoint(path, method)

    endpoint.breakerState = BreakerState.open
    // @ts-ignore
    endpoint.timer = setTimeout(() => {
      endpoint.breakerState = BreakerState.close
      this.resetAttempts(path, method)
    }, +REACT_APP_CIRCUIT_BREAKER_DENIED_MILLISECONDS)
  }

  getEndpoint = (path: string, method: Method) => {
    if (!this.endpoints[path] || !this.endpoints[path][method]) {
      this.endpoints = {
        ...this.endpoints,
        [path]: {
          ...(this.endpoints[path] || {}),
          [method]: {
            attempts: 0,
            timer: 0,
            breakerState: BreakerState.close,
          },
        },
      }
    }

    return this.endpoints[path][method]
  }
}
