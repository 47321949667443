import { Epic, combineEpics, ofType } from 'redux-observable'
import { catchError, map, mergeMap } from 'rxjs/operators'
import { of } from 'rxjs'

import { localeService } from '../../api'
import { CountryPayload } from '../../types/countries'

import * as TYPES from './countries.types'
import * as ACTIONS from './countries.actions'

const fetchCountries: Epic = (action$) => {
  const prepareCountries = (countries: CountryPayload[]) => countries.map(({ name, code }) => ({ name, value: code }))

  return action$.pipe(
    ofType(TYPES.CountriesActions.FETCH_COUNTRIES),
    mergeMap(() =>
      localeService.fetchCountries().pipe(
        map((response) =>  ACTIONS.fetchCountriesSuccess(prepareCountries(response))),
        catchError((error) => of(ACTIONS.fetchCountriesFailure(error.response)))
      )
    )
  )
}

export const countriesEpics = combineEpics(fetchCountries)
