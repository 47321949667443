import { Form, Formik } from 'formik'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { useDispatchUnmount } from '../../../../../hooks/useDispatchUnmount'
import { contactUsInitialValues } from '../../../../../initialValues/contactUsInitialValues'
import { openModal } from '../../../../../store/modals/modals.actions'
import { clearContactUs, fetchContactUs } from '../../../../../store/subscription/contactUs/contactUs.actions'
import * as SELECTORS from '../../../../../store/subscription/contactUs/contactUs.selectors'
import translations from '../../../../../translations/keys'
import { ContactUsFields, ContactUsForm } from '../../../../../types/contactUs'
import {
  contactUsValidationSchema,
  CONTACT_US_MESSAGE_MAX_LENGTH,
} from '../../../../../validation/contactUs/contactUsValidationSchema'
import { useShowFetchError } from '../../../../hooks/useShowFetchError'
import Button from '../../../Button'
import FormTextArea from '../../../Forms/FormTextArea'
import { Modal } from '../../../Modal/Modal'
import Typography from '../../../Typography'
import { TYPOGRAPHY_NAMES } from '../../../Typography/Typography.types'
import Box from '../../../Box'
import { COMMON_MODALS_IDS } from '../../CommonModals/CommonModals.constants'
import { NOTIFICATION_MODAL_TYPES } from '../../NotificationModal/NotificationModal.constants'

import { ContactUsModalProps } from './ContactUsModal.types'

export const ContactUsModal: React.FC<ContactUsModalProps> = ({ onClose }) => {
  const dispatch = useDispatch()
  const isSuccess = useSelector(SELECTORS.subscriptionContactUsIsSuccessSelector)
  const fetchStatus = useSelector(SELECTORS.subscriptionContactUsFetchStatusSelector)
  const error = useSelector(SELECTORS.subscriptionContactUsErrorSelector)
  const { t } = useTranslation()
  useEffect(() => {
    if (isSuccess) {
      onClose()
      dispatch(
        openModal(COMMON_MODALS_IDS.NOTIFICATION, {
          type: NOTIFICATION_MODAL_TYPES.SUCCESS,
          title: t(translations.CONTACT_US_SUCCESS),
        })
      )
    }
  }, [isSuccess])
  useShowFetchError(fetchStatus, error)
  useDispatchUnmount(clearContactUs)

  const onSubmit = (values: ContactUsForm) => {
    dispatch(fetchContactUs(values.message))
  }

  const renderForm = () => (
    <Form>
      <Box mb={1}>
        <FormTextArea
          name={ContactUsFields.message}
          label={t(translations.MESSAGE)}
          height={152}
          maxLength={CONTACT_US_MESSAGE_MAX_LENGTH}
        />
      </Box>
      <Button type="submit" fullWidth>
        {t(translations.SEND)}
      </Button>
    </Form>
  )

  return (
    <Modal onClose={onClose}>
      <Box pt={3} pb={3} display="flex" flexDirection="column" width={320} textAlign="center">
        <Typography name={TYPOGRAPHY_NAMES.H1}>{t(translations.CONTACT_US)}</Typography>
        <Box mt={3} mb={3} textAlign="center">
          {t(translations.CONTACT_US_MESSAGE)}
        </Box>
        <Formik initialValues={contactUsInitialValues} onSubmit={onSubmit} validationSchema={contactUsValidationSchema}>
          {renderForm}
        </Formik>
      </Box>
    </Modal>
  )
}
