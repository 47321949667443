import { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router'

import translations from '../../../../translations/keys'
import { TemplateRouteParams } from '../../../types/template'
import { TEMPLATES_FILL_RECIPIENTS_MODALS } from '../../../../constants/templatesFillRecipients'
import { location } from '../../../../utils/location'
import { validateAllFields } from '../../../../utils/documentField'
import { closeModal, openModal } from '../../../../store/modals/modals.actions'
import * as CREATE_CONTRACT_SELECTORS from '../../../../store/templates/createContract/createContract.selectors'
import * as PREVIEW_SELECTORS from '../../../../store/templates/preview/preview.selectors'
import * as FIELDS_SELECTORS from '../../../../store/templates/fields/fields.selectors'
import { BUTTON_VARIANTS } from '../../../ui/Button/Button.types'
import { COMMON_MODALS_IDS } from '../../../ui/Modals/CommonModals/CommonModals.constants'
import { NOTIFICATION_MODAL_TYPES } from '../../../ui/Modals/NotificationModal/NotificationModal.constants'
import { CloseIcon, SaveIcon } from '../../../assets/icons'

export const useButtons = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { templateId } = useParams<TemplateRouteParams>()
  const templateData = useSelector(PREVIEW_SELECTORS.previewDataSelector)
  const recipients = useSelector(CREATE_CONTRACT_SELECTORS.createContractRecipientsSelector)
  const order = useSelector(CREATE_CONTRACT_SELECTORS.createContractOrderSelector)
  const fieldsById = useSelector(FIELDS_SELECTORS.fieldsByIdSelector)
  const fieldsCount = useSelector(FIELDS_SELECTORS.fieldsCountSelector)

  const createContract = useCallback(() => {
    dispatch(
      openModal(TEMPLATES_FILL_RECIPIENTS_MODALS.CREATE_CONTRACT_FROM_TEMPLATE, {
        fileName: templateData?.name,
        recipients,
        order,
      })
    )
  }, [templateData, recipients, order])

  const buttons = useMemo(
    () => [
      {
        label: translations.DELETE_AND_CLOSE,
        onClick: () => {
          dispatch(openModal(TEMPLATES_FILL_RECIPIENTS_MODALS.ABORT_CREATING_CONTRACT))
        },
        MobileIcon: CloseIcon,
        variant: BUTTON_VARIANTS.SECONDARY,
      },
      {
        label: translations.CREATE,
        onClick: () => {
          const error = validateAllFields(fieldsById)
          const allDataExist = templateData && recipients && order

          if (error) {
            dispatch(
              openModal(COMMON_MODALS_IDS.NOTIFICATION, {
                type: NOTIFICATION_MODAL_TYPES.WARNING,
                description: translations.TEMPLATES_INCORRECT_FIELDS,
              })
            )
          } else if (allDataExist && fieldsCount > 0) {
            dispatch(
              openModal(COMMON_MODALS_IDS.NOTIFICATION, {
                type: NOTIFICATION_MODAL_TYPES.WARNING,
                description: translations.SAVE_CONTRACT_WITH_FIELDS,
                onSuccess: () => {
                  createContract()
                  dispatch(closeModal(COMMON_MODALS_IDS.NOTIFICATION))
                },
              })
            )
          } else if (allDataExist) {
            createContract()
          } else {
            history.push(location.templatesFillRecipientsUrl(templateId))
          }
        },
        variant: BUTTON_VARIANTS.PRIMARY,
        MobileIcon: SaveIcon,
      },
    ],
    [templateId, templateData, recipients, order, fieldsById, createContract]
  )

  return buttons
}
