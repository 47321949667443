import React from 'react'
import { useDispatch } from 'react-redux'
import { useHistory, useParams } from 'react-router'

import { setSuccessMessage } from '../../../../../store/templates/preview/preview.actions'
import { SuccessMessages } from '../../../../../store/templates/preview/preview.types'
import translations from '../../../../../translations/keys'
import { location } from '../../../../../utils/location'
import { TemplateRouteParams } from '../../../../types/template'
import AskModal from '../../ContractModals/AskModal'

import { AbortCreatingContractModalProps } from './AbortCreatingContractModal.types'

export const AbortCreatingContractModal: React.FC<AbortCreatingContractModalProps> = ({ onClose }) => {
  const history = useHistory()
  const { templateId } = useParams<TemplateRouteParams>()
  const dispatch = useDispatch()

  const onSuccess = () => {
    onClose()
    dispatch(setSuccessMessage(SuccessMessages.CREATING_CONTRACT_ABORTED))
    history.push(location.templatesViewUrl(templateId))
  }

  return (
    <AskModal
      onClose={onClose}
      onSuccess={onSuccess}
      title={translations.CANCEL_CREATING_CONTRACT}
      onCancel={onClose}
    />
  )
}
