import React from 'react'

import { SvgIconProps } from '../../ui/SvgIcon/SvgIcon.types'
import SvgIcon from '../../ui/SvgIcon'

export const CrossoverIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <path d="M14.5938 14.7188H9.125L8.16667 16.9479C7.93056 17.4965 7.8125 17.9062 7.8125 18.1771C7.8125 18.3924 7.91319 18.5833 8.11458 18.75C8.32292 18.9097 8.76736 19.0139 9.44792 19.0625V19.4479H5V19.0625C5.59028 18.9583 5.97222 18.8229 6.14583 18.6563C6.5 18.3229 6.89236 17.6458 7.32292 16.625L12.2917 5H12.6562L17.5729 16.75C17.9688 17.6944 18.3264 18.309 18.6458 18.5938C18.9722 18.8715 19.4236 19.0278 20 19.0625V19.4479H14.4271V19.0625C14.9896 19.0347 15.3681 18.941 15.5625 18.7813C15.7639 18.6215 15.8646 18.4271 15.8646 18.1979C15.8646 17.8924 15.7257 17.4097 15.4479 16.75L14.5938 14.7188ZM14.3021 13.9479L11.9063 8.23958L9.44792 13.9479H14.3021Z" />
    <rect x="3.95703" y="15.5669" width="18" height="1" transform="rotate(-30 3.95703 15.5669)" />
    <rect x="4.45703" y="6.56689" width="18" height="1" transform="rotate(30 4.45703 6.56689)" />
  </SvgIcon>
)
