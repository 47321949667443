import { Ajax } from '../ajax'
import { DocumentPreviewTotalPagesResponse } from '../../types/documentPreview'

class DocumentsService extends Ajax {
  pathname = 'document'

  getDocumentPreviewPageSrc = (id: string, index: number) => `${this.serverUrl}/${this.pathname}/preview/${id}/${index}`

  fetchDocumentTotalPages = (id: string) =>
    this.getJSON<DocumentPreviewTotalPagesResponse>(`${this.pathname}/pages/${id}`)
}

export const documentsService = new DocumentsService()
