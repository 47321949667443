import React from 'react'

import { SvgIconProps } from '../../ui/SvgIcon/SvgIcon.types'
import SvgIcon from '../../ui/SvgIcon'

export const CloseCircleIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <path d="M16.0636 8.31494C16.0636 8.21182 15.9793 8.12744 15.8761 8.12744L14.3293 8.13447L11.9996 10.9118L9.67226 8.13682L8.12305 8.12979C8.01992 8.12979 7.93555 8.21182 7.93555 8.31729C7.93555 8.36182 7.95195 8.404 7.98008 8.43916L11.0293 12.072L7.98008 15.7024C7.95176 15.7368 7.93604 15.7798 7.93555 15.8243C7.93555 15.9274 8.01992 16.0118 8.12305 16.0118L9.67226 16.0048L11.9996 13.2274L14.3269 16.0024L15.8738 16.0095C15.9769 16.0095 16.0613 15.9274 16.0613 15.822C16.0613 15.7774 16.0449 15.7353 16.0168 15.7001L12.9722 12.0696L16.0215 8.43682C16.0496 8.404 16.0636 8.35947 16.0636 8.31494Z" />
    <path d="M12 1.52344C6.20156 1.52344 1.5 6.225 1.5 12.0234C1.5 17.8219 6.20156 22.5234 12 22.5234C17.7984 22.5234 22.5 17.8219 22.5 12.0234C22.5 6.225 17.7984 1.52344 12 1.52344ZM12 20.7422C7.18594 20.7422 3.28125 16.8375 3.28125 12.0234C3.28125 7.20938 7.18594 3.30469 12 3.30469C16.8141 3.30469 20.7188 7.20938 20.7188 12.0234C20.7188 16.8375 16.8141 20.7422 12 20.7422Z" />
  </SvgIcon>
)
