import { FETCH_STATUSES } from '../../../types/fetch'
import { RootState } from '../../rootReducer.types'

export const correctionsByIdSelector = (state: RootState) => state.contracts.corrections.byId
export const correctionsByPagesSelector = (state: RootState) => state.contracts.corrections.byPages
export const correctionsCountSelector = (state: RootState) => Object.keys(state.contracts.corrections.byId).length
export const correctionsFetchSelector = (state: RootState) => state.contracts.corrections.fetch

export const correctionsIsConfirmedSelector = (state: RootState) =>
  state.contracts.corrections.confirm.status === FETCH_STATUSES.SUCCESS
export const correctionsConfirmStatusSelector = (state: RootState) => state.contracts.corrections.confirm.confirmStatus
export const correctionsConfirmFetchStatusSelector = (state: RootState) => state.contracts.corrections.confirm.status
export const correctionsConfirmErrorSelector = (state: RootState) => state.contracts.corrections.confirm.error

export const correctionsCancelConfirmationSuccessSelector = (state: RootState) =>
  state.contracts.corrections.cancelConfirmation.status === FETCH_STATUSES.SUCCESS
export const correctionsCancelConfirmationStatusSelector = (state: RootState) =>
  state.contracts.corrections.cancelConfirmation.confirmStatus
export const correctionsCancelConfirmationFetchStatusSelector = (state: RootState) =>
  state.contracts.corrections.cancelConfirmation.status
export const correctionsCancelConfirmationErrorSelector = (state: RootState) =>
  state.contracts.corrections.cancelConfirmation.error
