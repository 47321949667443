import React from 'react'

import SvgIcon from '../../ui/SvgIcon'
import { SvgIconProps } from '../../ui/SvgIcon/SvgIcon.types'

export const TriangleRightIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon viewBox="0 0 16 16" {...props}>
    <path d="M10.4281 8.42934L7.03653 11.8209C6.65402 12.2034 6 11.9325 6 11.3916L6 4.6084C6 4.06746 6.65402 3.79655 7.03653 4.17906L10.4281 7.57066C10.6652 7.80778 10.6652 8.19222 10.4281 8.42934Z" />
  </SvgIcon>
)
