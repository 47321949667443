import { Epic, combineEpics, ofType } from 'redux-observable'
import { concat, of } from 'rxjs'
import { catchError, map, mergeMap, switchMap, takeUntil } from 'rxjs/operators'

import { correctionService } from '../../../api'
import { catchFetchError } from '../../../utils/catchFetchError'
import { prepareCorrectionsForStore, prepareCorrectionForRequest } from '../../../utils/correction'
import { authUserIdSelector } from '../../auth/auth.selectors'

import * as ACTIONS from './corrections.actions'

const fetchCorrections: Epic = (action$, state$) =>
  action$.pipe(
    ofType(ACTIONS.fetchCorrectionsTypes.request),
    mergeMap(({ payload }) =>
      correctionService.fetchCorrections(payload.contractId).pipe(
        map((response) =>
          ACTIONS.fetchCorrectionsSuccess(
            ...prepareCorrectionsForStore(response.corrections, authUserIdSelector(state$.value))
          )
        ),
        catchError(catchFetchError(ACTIONS.fetchCorrectionsFailure)),
        takeUntil(action$.pipe(ofType(ACTIONS.fetchCorrectionsTypes.success, ACTIONS.fetchCorrectionsTypes.failure)))
      )
    )
  )

const fetchCreateCorrection: Epic = (action$) =>
  action$.pipe(
    ofType(ACTIONS.fetchCreateCorrectionTypes.request),
    switchMap(({ payload }) =>
      concat(
        correctionService
          .fetchCreateCorrection(payload.contractId, prepareCorrectionForRequest(payload.correction))
          .pipe(
            map(({ response }) => ACTIONS.fetchCreateCorrectionSuccess(response)),
            catchError(catchFetchError(ACTIONS.fetchCreateCorrectionFailure)),
            takeUntil(
              action$.pipe(
                ofType(ACTIONS.fetchCreateCorrectionTypes.success, ACTIONS.fetchCreateCorrectionTypes.failure)
              )
            )
          ),
        of(ACTIONS.fetchCorrections(payload.contractId))
      )
    )
  )

const fetchConfirmCorrection: Epic = (action$) =>
  action$.pipe(
    ofType(ACTIONS.fetchConfirmCorrectionTypes.request),
    switchMap(({ payload }) =>
      correctionService.fetchConfirmCorrection(payload).pipe(
        mergeMap(() =>
          of(ACTIONS.fetchCorrections(payload.contractId), ACTIONS.fetchConfirmCorrectionSuccess(payload.status))
        ),
        catchError(catchFetchError(ACTIONS.fetchConfirmCorrectionFailure)),
        takeUntil(
          action$.pipe(ofType(ACTIONS.fetchConfirmCorrectionTypes.success, ACTIONS.fetchConfirmCorrectionTypes.failure))
        )
      )
    )
  )

const fetchCancelCorrectionConfirmation: Epic = (action$) =>
  action$.pipe(
    ofType(ACTIONS.fetchCancelCorrectionConfirmationTypes.request),
    switchMap(({ payload }) =>
      correctionService.fetchCancelCorrectionConfirmation(payload.contractId, payload.correctionId).pipe(
        mergeMap(() =>
          of(
            ACTIONS.fetchCorrections(payload.contractId),
            ACTIONS.fetchCancelCorrectionConfirmationSuccess(payload.status)
          )
        ),
        catchError(catchFetchError(ACTIONS.fetchCancelCorrectionConfirmationFailure)),
        takeUntil(
          action$.pipe(
            ofType(
              ACTIONS.fetchCancelCorrectionConfirmationTypes.success,
              ACTIONS.fetchCancelCorrectionConfirmationTypes.failure
            )
          )
        )
      )
    )
  )

export const correctionsEpics = combineEpics(
  fetchCorrections,
  fetchCreateCorrection,
  fetchConfirmCorrection,
  fetchCancelCorrectionConfirmation
)
