import { FetchFailureAction, FetchFailurePayload } from '../../../types/fetch'
import { createFetchActionTypes } from '../../../utils/createFetchActionTypes'

import * as TYPES from './rename.types'

export const fetchRenameTemplateTypes = createFetchActionTypes(TYPES.RenameActions.RENAME_TEMPLATE)

export const fetchRenameTemplate = (id: string, name: string, isFolder = false): TYPES.FetchRenameTemplate => ({
  type: fetchRenameTemplateTypes.request,
  payload: { id, name, isFolder },
})

export const fetchRenameTemplateSuccess = (id: string, name: string): TYPES.FetchRenameTemplateSuccess => ({
  type: fetchRenameTemplateTypes.success,
  payload: { id, name },
})

export const fetchRenameTemplateFailure = (payload: FetchFailurePayload): FetchFailureAction => ({
  type: fetchRenameTemplateTypes.failure,
  payload,
})

export const clearRenameTemplate = () => ({
  type: TYPES.RenameActions.CLEAR_RENAME_TEMPLATE,
})
