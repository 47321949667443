import {
  DocCancelCommentsConfirmation,
  DocComment,
  DocCommentsById,
  DocCommentsByPages,
  DocCommentsConfirmRequest,
  DocCommentsResponseBody,
  DocCommentsThread,
  DocCommentsThreadRequestBody,
} from '../../../types/comment'
import { ConfirmationStatus } from '../../../types/confirmationActions'
import { EditableElem } from '../../../types/editableElem'
import { FetchFailureAction, FetchFailurePayload } from '../../../types/fetch'
import { createFetchActionTypes } from '../../../utils/createFetchActionTypes'

import * as TYPES from './comments.types'

export const fetchCommentsTypes = createFetchActionTypes(TYPES.CommentsActions.FETCH_COMMENTS)
export const fetchCommentsThreadTypes = createFetchActionTypes(TYPES.CommentsActions.FETCH_COMMENTS_THREAD)
export const fetchCreateCommentTypes = createFetchActionTypes(TYPES.CommentsActions.FETCH_CREATE_COMMENT)
export const fetchUpdateCommentsTypes = createFetchActionTypes(TYPES.CommentsActions.FETCH_UPDATE_COMMENTS)
export const fetchConfirmCommentsTypes = createFetchActionTypes(TYPES.CommentsActions.FETCH_CONFIRM_COMMENTS)
export const fetchCancelCommentsConfirmationTypes = createFetchActionTypes(
  TYPES.CommentsActions.FETCH_CANCEL_COMMENTS_CONFIRMATION
)

export const addComment = (comment: DocComment): TYPES.AddCommentAction => ({
  type: TYPES.CommentsActions.ADD_COMMENT,
  payload: { comment },
})
export const setComment = (comment: EditableElem): TYPES.SetCommentAction => ({
  type: TYPES.CommentsActions.SET_COMMENT,
  payload: { comment },
})
export const unsetComment = (id: string): TYPES.UnsetCommentAction => ({
  type: TYPES.CommentsActions.UNSET_COMMENT,
  payload: { id },
})
export const clearComments = (): TYPES.ClearCommentsAction => ({
  type: TYPES.CommentsActions.CLEAR_COMMENTS,
})
export const clearThread = (): TYPES.ClearThreadAction => ({
  type: TYPES.CommentsActions.CLEAR_THREAD,
})

export const fetchComments = (contractId: string): TYPES.FetchCommentsAction => ({
  type: fetchCommentsTypes.request,
  payload: { contractId },
})
export const fetchCommentsSuccess = (
  byId: DocCommentsById,
  byPages: DocCommentsByPages
): TYPES.FetchCommentsSuccessAction => ({
  type: fetchCommentsTypes.success,
  payload: { byId, byPages },
})
export const fetchCommentsFailure = (payload: FetchFailurePayload): FetchFailureAction => ({
  type: fetchCommentsTypes.failure,
  payload,
})

export const fetchCommentsThread = (body: DocCommentsThreadRequestBody): TYPES.FetchCommentsThreadAction => ({
  type: fetchCommentsThreadTypes.request,
  payload: body,
})
export const fetchCommentsThreadSuccess = (comments: DocCommentsThread[]): TYPES.FetchCommentsThreadSuccessAction => ({
  type: fetchCommentsThreadTypes.success,
  payload: { comments },
})
export const fetchCommentsThreadFailure = (payload: FetchFailurePayload): FetchFailureAction => ({
  type: fetchCommentsThreadTypes.failure,
  payload,
})

export const fetchCreateComment = (contractId: string, comment: DocComment): TYPES.FetchCreateCommentAction => ({
  type: fetchCreateCommentTypes.request,
  payload: { contractId, comment },
})
export const fetchCreateCommentSuccess = (
  response: DocCommentsResponseBody
): TYPES.FetchCreateCommentSuccessAction => ({
  type: fetchCreateCommentTypes.success,
  payload: response,
})
export const fetchCreateCommentFailure = (payload: FetchFailurePayload): FetchFailureAction => ({
  type: fetchCreateCommentTypes.failure,
  payload,
})

export const fetchUpdateComments = (contractId: string, byId: DocCommentsById): TYPES.FetchUpdateCommentsAction => ({
  type: fetchUpdateCommentsTypes.request,
  payload: { contractId, byId },
})
export const fetchUpdateCommentsSuccess = (
  response: DocCommentsResponseBody
): TYPES.FetchUpdateCommentsSuccessAction => ({
  type: fetchUpdateCommentsTypes.success,
  payload: response,
})
export const fetchUpdateCommentsFailure = (payload: FetchFailurePayload): FetchFailureAction => ({
  type: fetchUpdateCommentsTypes.failure,
  payload,
})

export const fetchConfirmComments = (payload: DocCommentsConfirmRequest): TYPES.FetchConfirmCommentsAction => ({
  type: fetchConfirmCommentsTypes.request,
  payload,
})
export const fetchConfirmCommentsSuccess = (
  confirmStatus: ConfirmationStatus
): TYPES.FetchConfirmCommentsSuccessAction => ({
  type: fetchConfirmCommentsTypes.success,
  payload: { confirmStatus },
})
export const fetchConfirmCommentsFailure = (payload: FetchFailurePayload): FetchFailureAction => ({
  type: fetchConfirmCommentsTypes.failure,
  payload,
})

export const fetchCancelCommentsConfirmation = (
  payload: DocCancelCommentsConfirmation
): TYPES.FetchCancelCommentsConfirmationAction => ({
  type: fetchCancelCommentsConfirmationTypes.request,
  payload,
})
export const fetchCancelCommentsConfirmationSuccess = (
  confirmStatus: ConfirmationStatus
): TYPES.FetchCancelCommentsConfirmationSuccessAction => ({
  type: fetchCancelCommentsConfirmationTypes.success,
  payload: { confirmStatus },
})
export const fetchCancelCommentsConfirmationFailure = (payload: FetchFailurePayload): FetchFailureAction => ({
  type: fetchCancelCommentsConfirmationTypes.failure,
  payload,
})
