import React from 'react'

import ErrorBoundary from '../../components/ErrorBoundary/ErrorBoundary'

import { TemplateAddRecipients as TemplateAddRecipientsView } from './TemplateAddRecipients'

export const TemplateAddRecipients = () => (
  <ErrorBoundary>
    <TemplateAddRecipientsView />
  </ErrorBoundary>
)
