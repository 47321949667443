export enum CONTRACT_SIGN_MODALS {
  CONTRACT_VIEW_CHOOSE_SIGN = 'CONTRACT_VIEW_CHOOSE_SIGN',
  FINISH_SIGN_LATER = 'FINISH_SIGN_LATER',
  SEND_CONTRACT = 'SEND_CONTRACT',
  DECLINE_SIGNING = 'DECLINE_SIGNING',
  SIGNING_OTP_VERIFICATION = 'SIGNING_OTP_VERIFICATION',
  ACTION_OTP_VERIFICATION = 'ACTION_OTP_VERIFICATION',
  SIGNING_EKYC_VERIFICATION = 'SIGNING_EKYC_VERIFICATION',
  SIGNING_ACTIONS_EKYC_VERIFICATION = 'SIGNING_ACTIONS_EKYC_VERIFICATION',
}
