import { combineEpics, Epic, ofType } from 'redux-observable'
import { catchError, map, mergeMap, takeUntil } from 'rxjs/operators'

import { signatureService } from '../../api/signatureService'
import { VerifyPasswordKeys } from '../../types/verifyPassword'
import { catchFetchError } from '../../utils/catchFetchError'
import { prepareCreateSignatureBody } from '../../utils/signatures/prepareCreateSignatureBody'
import { prepareSignaturesItem, prepareSignaturesList } from '../../utils/signatures/prepareSignaturesList'
import { verifyPasswordPasswordTokenSelector } from '../verifyPassword/verifyPassword.selectors'

import * as ACTIONS from './signatures.actions'

const fetchValidateSignature: Epic = (action$) =>
  action$.pipe(
    ofType(ACTIONS.fetchValidateSignatureTypes.request),
    mergeMap(({ payload }) =>
      signatureService
        .fetchValidateSignature(payload.body)
        .pipe(
          map(ACTIONS.fetchValidateSignatureSuccess),
          catchError(catchFetchError(ACTIONS.fetchValidateSignatureFailure)),
          takeUntil(
            action$.pipe(
              ofType(ACTIONS.fetchValidateSignatureTypes.success, ACTIONS.fetchValidateSignatureTypes.failure)
            )
          )
        )
    )
  )

const fetchCreateSignature: Epic = (action$, state$) =>
  action$.pipe(
    ofType(ACTIONS.fetchCreateSignatureTypes.request),
    mergeMap(({ payload }) =>
      signatureService
        .fetchCreateSignature(prepareCreateSignatureBody(payload.body), {
          verify: verifyPasswordPasswordTokenSelector(VerifyPasswordKeys.CREATE_SIGNATURE)(state$.value),
        })
        .pipe(
          map((action) => ACTIONS.fetchCreateSignatureSuccess(prepareSignaturesItem(action.response))),
          catchError(catchFetchError(ACTIONS.fetchCreateSignatureFailure)),
          takeUntil(
            action$.pipe(ofType(ACTIONS.fetchCreateSignatureTypes.success, ACTIONS.fetchCreateSignatureTypes.failure))
          )
        )
    )
  )

const fetchSignaturesList: Epic = (action$, state$) =>
  action$.pipe(
    ofType(ACTIONS.fetchSignaturesListTypes.request),
    mergeMap(() =>
      signatureService
        .fetchSignaturesList({
          verify: verifyPasswordPasswordTokenSelector(VerifyPasswordKeys.SIGNATURES_PAGE)(state$.value),
        })
        .pipe(
          map((response) => ACTIONS.fetchSignaturesListSuccess(prepareSignaturesList(response))),
          catchError(catchFetchError(ACTIONS.fetchSignaturesListFailure)),
          takeUntil(
            action$.pipe(ofType(ACTIONS.fetchSignaturesListTypes.success, ACTIONS.fetchSignaturesListTypes.failure))
          )
        )
    )
  )

const fetchDeleteSignature: Epic = (action$, state$) =>
  action$.pipe(
    ofType(ACTIONS.fetchDeleteSignatureTypes.request),
    mergeMap(({ payload }) =>
      signatureService
        .fetchDeleteSignature(payload.id, {
          verify: verifyPasswordPasswordTokenSelector(VerifyPasswordKeys.DELETE_SIGNATURE)(state$.value),
        })
        .pipe(
          map(() => ACTIONS.fetchDeleteSignatureSuccess(payload.id)),
          catchError(catchFetchError(ACTIONS.fetchDeleteSignatureFailure)),
          takeUntil(
            action$.pipe(ofType(ACTIONS.fetchDeleteSignatureTypes.success, ACTIONS.fetchDeleteSignatureTypes.failure))
          )
        )
    )
  )

export const createSignatureEpics = combineEpics(
  fetchValidateSignature,
  fetchCreateSignature,
  fetchSignaturesList,
  fetchDeleteSignature
)
