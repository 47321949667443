import React from 'react'

import AuthorizationTemplate from '../../components/Authorization/AuthorizationTemplate'

import { ForgotPassword as ForgotPasswordPage } from './ForgotPassword'

export const ForgotPassword = () => (
  <AuthorizationTemplate>
    <ForgotPasswordPage />
  </AuthorizationTemplate>
)
