import styled from 'styled-components'

import Box from '../../../../ui/Box'

export const StyledWrapper = styled(Box).attrs({
  mt: 26,
  mtMobile: 0,
})`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  // 48px toggle sidebar height + offset
  ${({ theme }) => `@media ${theme.devices.mobile} {
    height: calc(100% - 48px)
  }`}
`

export const StyledAddSignature = styled.div`
  min-width: 208px;

  ${({ theme }) => `@media ${theme.devices.mobile} {
    min-width: 0;
    width: 100%;
  }`}
`
