import { FetchFailureAction, FetchFailurePayload } from '../../../types/fetch'
import { IUnsubscribe, SubscriptionPlan } from '../../../types/subscription'
import { createFetchActionTypes } from '../../../utils/createFetchActionTypes'

import * as TYPES from './plans.types'

export const fetchPlansTypes = createFetchActionTypes(TYPES.PlansActions.FETCH_SUBSCRIPTION_PLANS)
export const fetchPlanTypes = createFetchActionTypes(TYPES.PlansActions.FETCH_SUBSCRIPTION_PLAN)

export const fetchPlans = (): TYPES.FetchPlans => ({
  type: fetchPlansTypes.request,
})
export const fetchPlansSuccess = (data: SubscriptionPlan[]): TYPES.FetchPlansSuccess => ({
  type: fetchPlansTypes.success,
  payload: { data },
})
export const fetchPlansFailure = (payload: FetchFailurePayload): FetchFailureAction => ({
  type: fetchPlansTypes.failure,
  payload,
})

export const clearPlans = () => ({
  type: TYPES.PlansActions.CLEAR_SUBSCRIPTION_PLANS,
})

export const fetchPlan = (subscriptionId: string): TYPES.FetchPlan => ({
  type: fetchPlanTypes.request,
  payload: { subscriptionId },
})
export const fetchPlanSuccess = (data: SubscriptionPlan): TYPES.FetchPlanSuccess => ({
  type: fetchPlanTypes.success,
  payload: { data },
})
export const fetchPlanFailure = (payload: FetchFailurePayload): FetchFailureAction => ({
  type: fetchPlanTypes.failure,
  payload,
})

export const unsubscribe = (subscriptionId: string): TYPES.Unsubscribe => ({
  type: fetchPlanTypes.request,
  payload: { subscriptionId },
})
export const unsubscribeSuccess = (data: IUnsubscribe): TYPES.UnsubscribeSuccess => ({
  type: fetchPlanTypes.success,
  payload: { data },
})
export const unsubscribeFailure = (data: IUnsubscribe): TYPES.UnsubscribeFailure => ({
  type: fetchPlanTypes.failure,
  payload: { data }
})
