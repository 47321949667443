import { Form, Formik } from 'formik'
import React, { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { confirmPhoneInitialValues } from '../../../../../initialValues/confirmPhoneModalInitialValues'
import {
  fetchEditProfileChangePhone,
  fetchEditProfileConfirmPhone,
  resetEditProfilePhoneData,
} from '../../../../../store/editProfile/editProfile.actions'
import {
  editProfilePhoneSelector,
  editProfileConfirmPhoneSuccessSelector,
  editProfileConfirmPhoneSelector,
} from '../../../../../store/editProfile/editProfile.selectors'
import { closeModal, openModal } from '../../../../../store/modals/modals.actions'
import translations from '../../../../../translations/keys'
import { ConfirmPhoneModalFields, ConfirmPhoneModalRequestBody } from '../../../../../types/confirmPhoneModal'
import { confirmPhoneModalValidationSchema } from '../../../../../validation/confirmPhoneModal/confirmPhoneModalValidationSchema'
import { PhoneIcon } from '../../../../assets/PhoneIcon'
import { EDIT_PROFILE_MODALS } from '../../../../constants/editProfile'
import { useShowFetchError } from '../../../../hooks/useShowFetchError'
import Button from '../../../../ui/Button'
import { BUTTON_VARIANTS } from '../../../../ui/Button/Button.types'
import CountDown from '../../../../ui/CountDown'
import FormCodeInput from '../../../../ui/Forms/FormCodeInput'
import Modal from '../../../Modal'
import Typography from '../../../../ui/Typography'
import { TYPOGRAPHY_COMPONENTS, TYPOGRAPHY_NAMES } from '../../../../ui/Typography/Typography.types'
import Box from '../../../../ui/Box'
import { COMMON_MODALS_IDS } from '../../../../ui//Modals/CommonModals/CommonModals.constants'
import { NOTIFICATION_MODAL_TYPES } from '../../../../ui//Modals/NotificationModal/NotificationModal.constants'
import { StyledTitle } from '../EditPhoneModal/EditPhoneModal.styles'

import { StyledContent } from './ConfirmPhoneModal.styles'
import { ConfirmPhoneModalProps } from './ConfirmPhoneModal.types'

export const ConfirmPhoneModal: FC<ConfirmPhoneModalProps> = ({ onClose }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [resendCodeDisabled, setResendCodeDisabled] = useState(true)
  const [countDownKey, setCountDownKey] = useState(new Date().getTime())
  const phoneData = useSelector(editProfilePhoneSelector)
  const confirmPhoneSuccess = useSelector(editProfileConfirmPhoneSuccessSelector)
  const confirmPhone = useSelector(editProfileConfirmPhoneSelector)
  const onSubmit = (values: ConfirmPhoneModalRequestBody) => {
    dispatch(fetchEditProfileConfirmPhone(values.verificationCode))
  }
  const handleCountDownComplete = () => {
    setResendCodeDisabled(false)
  }
  const onResendCode = () => {
    setResendCodeDisabled(true)
    setCountDownKey(new Date().getTime())
    dispatch(fetchEditProfileChangePhone(phoneData.phoneNumber))
  }
  const renderForm = () => (
    <>
      <Form>
        <Box textAlign="center" mb={2.5}>
          {t(translations.CONFIRM_PHONE_DESCRIPTION)}
        </Box>
        <Box textAlign="center" mb={4}>
          <Typography name={TYPOGRAPHY_NAMES.H3}>+{phoneData.phoneNumber}</Typography>
        </Box>
        <Box textAlign="center" mb={2.5}>
          {t(translations.CONFIRM_PHONE_CODE_INPUT_LABEL)}
        </Box>
        <Box mb={2}>
          <FormCodeInput name={ConfirmPhoneModalFields.verificationCode} length={6} />
        </Box>
        <Box mb={2}>
          <Button fullWidth type="submit">
            {t(translations.CONTINUE)}
          </Button>
        </Box>
      </Form>
      <Box mb={3}>
        <CountDown key={countDownKey} onComplete={handleCountDownComplete} />
      </Box>
      <Box textAlign="center" mb={2}>
        <Button variant={BUTTON_VARIANTS.LINK} onClick={onResendCode} disabled={resendCodeDisabled}>
          {t(translations.RESEND_CODE)}
        </Button>
      </Box>
    </>
  )

  useEffect(() => {
    if (confirmPhoneSuccess) {
      dispatch(closeModal(EDIT_PROFILE_MODALS.EDIT_PROFILE_CONFIRM_PHONE_MODAL))
      dispatch(
        openModal(COMMON_MODALS_IDS.NOTIFICATION, {
          type: NOTIFICATION_MODAL_TYPES.SUCCESS,
          description: t(translations.CHANGES_SAVES_SUCCESSFULLY),
        })
      )
    }
  }, [confirmPhoneSuccess])

  useShowFetchError(confirmPhone.fetchStatus, confirmPhone.error)
  useShowFetchError(phoneData.fetchStatus, phoneData.error)

  useEffect(
    () => () => {
      dispatch(resetEditProfilePhoneData())
    },
    []
  )

  return (
    <Modal onClose={onClose}>
      <StyledContent>
        <PhoneIcon />
        <StyledTitle name={TYPOGRAPHY_NAMES.H1} component={TYPOGRAPHY_COMPONENTS.h1}>
          {t(translations.CONFIRM_PHONE_TITLE)}
        </StyledTitle>
        <Formik
          initialValues={confirmPhoneInitialValues}
          onSubmit={onSubmit}
          validationSchema={confirmPhoneModalValidationSchema}
        >
          {renderForm}
        </Formik>
      </StyledContent>
    </Modal>
  )
}
