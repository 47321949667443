export interface ConfirmPhoneFormValues {
  phone: string
  verificationCode: string
}

export interface ConfirmPhoneRequestBody {
  phone: string
  code: string
}

export enum ConfirmPhoneFields {
  phone = 'phone',
  verificationCode = 'verificationCode',
}
