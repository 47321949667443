import { location } from '../../utils/location'
import { SubscriptionFeatures } from '../../types/subscription'
import { MenuItem } from '../types/menu'
import { FlagUKSvg } from '../assets/FlagUKSvg'
import { FlagUAESvg } from '../assets/FlagUAESvg'
import * as icons from '../assets/icons'
import store from '../../store'
import { changeLanguage } from '../../store/language/language.actions'

export const profileLinks = [
  { name: 'SIDEBAR_EDIT_PROFILE_LINK', route: location.editProfileUrl(), Icon: icons.UserIcon },
  { name: 'SIDEBAR_CHANGE_PASSWORD_LINK', route: location.changePasswordUrl(), Icon: icons.KeyIcon },
  { name: 'SIDEBAR_SIGNATURE_INITIALS_LINK', route: location.signatureAndInitialsUrl(), Icon: icons.SignatureIcon },
  { name: 'SIDEBAR_CONTACTS_LINK', route: location.contactsUrl(), Icon: icons.ContactIcon },
  {
    name: 'SIDEBAR_EKYC_LINK',
    route: location.eKYCUrl(),
    feature: SubscriptionFeatures.FEATURE_EKYC,
    Icon: icons.IdIcon,
  },
  // TODO UQD-1410 uncomment when this will work
  // { name: 'SIDEBAR_PAYMENT_LINK', Icon: icons.PaymentIcon, route: '' },
  {
    name: 'SIDEBAR_SUBSCRIPTION_LINK',
    route: location.subscriptionViewUrl(),
    activeUrl: location.subscriptionUrl(),
    Icon: icons.MoneyIcon,
  },
  {
    name: 'SIDEBAR_API_INTEGRATION_LINK',
    route: location.apiIntegrationUrl(),
    feature: SubscriptionFeatures.FEATURE_API_INTEGRATION,
    Icon: icons.ConnectorIcon,
  },
]

export const contractsLinks = [
  { name: 'SIDEBAR_CONTRACTS_ALL', route: location.contractsAllUrl(), Icon: icons.MultipleFilesIcon },
  { name: 'SIDEBAR_CONTRACTS_RECEIVED', route: location.contractsReceivedUrl(), Icon: icons.InboxIcon },
  { name: 'SIDEBAR_CONTRACTS_SENT', route: location.contractsSentUrl(), Icon: icons.EnvelopeIcon },
  { name: 'SIDEBAR_CONTRACTS_DRAFTS', route: location.contractsDraftsUrl(), Icon: icons.EmptyFileIcon },
  { name: 'SIDEBAR_CONTRACTS_DELETED', route: location.contractsDeletedUrl(), Icon: icons.TrashIcon },
]

export const templatesLinks = [
  { name: 'SIDEBAR_TEMPLATES_ALL', route: location.templatesAllUrl(), Icon: icons.MultipleFilesIcon },
  { name: 'SIDEBAR_TEMPLATES_STARRED', route: location.templatesStarredUrl(), Icon: icons.StarIcon },
  { name: 'SIDEBAR_TEMPLATES_DOCUMENTS', route: location.templatesDocumentsUrl(), Icon: icons.ClipboardLineIcon },
  { name: 'SIDEBAR_TEMPLATES_PROCEDURES', route: location.templatesProceduresUrl(), Icon: icons.FileForbidLineIcon },
]

export const preferencesLinks = [
  { name: 'SIDEBAR_PREFERENCES_NOTIFICATIONS', route: location.preferencesNotificationsUrl(), Icon: icons.BellIcon },
  // TODO UQD-1410 uncomment when this will work
  // {
  //   name: 'SIDEBAR_PREFERENCES_REGIONAL_SETTINGS',
  //   route: location.preferencesRegionalSettingsUrl(),
  //   Icon: icons.FlagIcon,
  // },
  // {
  //   name: 'SIDEBAR_PREFERENCES_CLOUD_STORAGE',
  //   route: '',
  //   Icon: icons.CloudIcon,
  // },
]

export const mainMenu: MenuItem[] = [
  { name: 'MAIN_MENU_DASHBOARD_LINK', route: '', Icon: icons.DashboardIcon },
  {
    name: 'MAIN_MENU_CONTRACTS_LINK',
    route: location.contractsAllUrl(),
    children: contractsLinks,
    Icon: icons.DocApprovedIcon,
  },
  {
    name: 'MAIN_MENU_TEMPLATES_LINK',
    route: location.templatesAllUrl(),
    feature: SubscriptionFeatures.FEATURE_USER_TEMPLATES,
    children: templatesLinks,
    Icon: icons.FileIcon,
  },
]

export const sidebarMenu: { [key: string]: MenuItem[] } = {
  profile: profileLinks,
  contracts: contractsLinks,
  templates: templatesLinks,
  preferences: preferencesLinks,
}

export const userMenu = [
  { name: 'USER_MENU_MANAGE_PROFILE_LINK', route: location.editProfileUrl() },
  { name: 'USER_MENU_PREFERENCES_LINK', route: location.preferencesNotificationsUrl() },
  // TODO UQD-1410 uncomment when this will work
  // { name: 'USER_MENU_ANALYTICS_LINK', route: '' },
  { name: 'LOGOUT', route: location.logoutUrl() },
]

export const languageMenu = [
  { name: 'English', alias: 'en', icon: FlagUKSvg, onClick: () => store.dispatch(changeLanguage('en')) },
  { name: 'Arabic', alias: 'ar', icon: FlagUAESvg, onClick: () => store.dispatch(changeLanguage('ar')) },
]

export const sidebarFooterMenu: MenuItem[] = [
  { name: 'TERMS_AND_CONDITIONS', route: location.termsUrl(), Icon: icons.FileIcon },
  { name: 'SIDEBAR_PRIVACY_LINK', route: location.privacyUrl(), Icon: icons.InfoIcon },
]
