import React from 'react'

import TextInput from '../TextInput'
import Typography from '../Typography'
import { TYPOGRAPHY_NAMES } from '../Typography/Typography.types'
import Box from '../Box'

import { SearchProps } from './Search.types'

export const Search: React.FC<SearchProps> = ({ value, placeholder, onChange, error, onClickIcon, noError, note }) => {
  const icon = value.length ? 'closeCircle' : undefined

  return (
    <TextInput
      value={value}
      placeholder={placeholder}
      onChange={onChange}
      error={error}
      icon={icon}
      searchable
      onClickIcon={onClickIcon}
      noError={noError}
      note={
        note && (
          <Box ml={2}>
            <Typography name={TYPOGRAPHY_NAMES.body14}>{note}</Typography>
          </Box>
        )
      }
    />
  )
}
