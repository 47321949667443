import React from 'react'

import Box from '../../Box'
import { Total } from '../Total/Total'
import { Cycle } from '../Cycle/Cycle'
import { Delimiter } from '../Delimiter/Delimiter'
import { Indicators } from '../Indicators/Indicators'
import SenderCard from '../SenderCard'

import { DiagramProps } from './Diagram.types'
import { StyledSenderWrapper } from './Diagram.styles'

export const Diagram: React.FC<DiagramProps> = ({
  sender,
  recipients,
  compact,
  displayStatus,
  editCondition,
  deleteCondition,
  onClick,
  onEdit,
  onDelete,
}) => {
  const cycles = recipients.byOrder
  const cyclesLength = recipients.byOrder.length
  const displayTotal = recipients.sorted.length > 0
  const horMargin = compact ? 0 : 5

  return (
    <Box
      data-testid="diagram-div"
      display="flex"
      justifyContent="center"
      flexDirection="column"
      ml={horMargin}
      mr={horMargin}
      mlTablet={0}
      mrTablet={0}
    >
      {!compact && (
        <>
          <StyledSenderWrapper data-testid="diagram-wrapper">
            {sender && <SenderCard name={sender.name} jobTitle={sender.jobTitle} />}
            <Indicators />
          </StyledSenderWrapper>
          <Delimiter />
        </>
      )}
      {cycles.map(([order, recipientIds], index) => (
        <React.Fragment key={index}>
          <Cycle
            order={order}
            recipientsById={recipients.byId}
            recipientsSorted={recipientIds}
            compact={compact}
            displayStatus={displayStatus}
            editCondition={editCondition}
            deleteCondition={deleteCondition}
            onClick={onClick}
            onEdit={onEdit}
            onDelete={onDelete}
          />
          {!compact && <Delimiter hideArrow={cyclesLength - 1 === index} />}
        </React.Fragment>
      ))}
      {!compact && displayTotal && <Total recipients={recipients.byId} />}
    </Box>
  )
}
