import { Action } from 'redux'

import { FetchFailurePayload, FETCH_STATUSES } from '../../types/fetch'

export interface ForgotPasswordState {
  forgotPasswordFetchStatus: FETCH_STATUSES
  forgotPasswordError?: FetchFailurePayload
  resendVerificationFetchStatus: FETCH_STATUSES
  resendVerificationError?: FetchFailurePayload
  email: string
  forgotPasswordMessage?: string
}

export enum ForgotPasswordActions {
  'FETCH_FORGOT_PASSWORD' = 'FETCH_FORGOT_PASSWORD',
  'RESET_FORGOT_PASSWORD' = 'RESET_FORGOT_PASSWORD',
  'FETCH_RESEND_NOTIFICATION' = 'FETCH_RESEND_NOTIFICATION',
}

export interface FetchForgotPasswordAction extends Action {
  payload: {
    email: string
  }
}

export interface FetchForgotPasswordSuccessAction extends Action {
  payload: { email: string; message?: string }
}

export type FetchResendVerificationAction = Action

export type FetchResendVerificationSuccessAction = Action
