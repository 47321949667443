import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'

import { modalsPayloadSelector } from '../../../../../store/modals/modals.selectors'
import translations from '../../../../../translations/keys'
import { location } from '../../../../../utils/location'
import { COMMON_MODALS_IDS } from '../../CommonModals/CommonModals.constants'
import { NOTIFICATION_MODAL_TYPES } from '../../NotificationModal/NotificationModal.constants'
import { NotificationModalView } from '../../NotificationModal/NotificationModalView'

import { SubscriptionWarningModalProps } from './SubscriptionWarningModal.types'

export const SubscriptionWarningModal: React.FC<SubscriptionWarningModalProps> = ({ onClose }) => {
  const history = useHistory()
  const { descriptionKey } = useSelector(modalsPayloadSelector(COMMON_MODALS_IDS.SUBSCRIPTION_WARNING))
  const { t } = useTranslation()
  const handleSuccess = () => {
    history.push(location.subscriptionPlansUrl())
    onClose()
  }

  return (
    <NotificationModalView
      type={NOTIFICATION_MODAL_TYPES.WARNING}
      description={t(translations[descriptionKey])}
      onSuccess={handleSuccess}
      onClose={onClose}
      successButtonText={t(translations.UPGRADE)}
      onlySuccess
    />
  )
}
