import { Contact, RawContact } from '../../types/contacts'
import { prepareFormRequestBody } from '../../utils/formik/prepareFormRequestBody'

import { ContactsState } from './contacts.types'

export const prepareContact = (rawContact: RawContact): Contact => {
  if (!rawContact?.id) {
    throw new Error('SOMETHING_WRONG')
  }

  return prepareFormRequestBody({
    id: rawContact.id,
    fullName: rawContact.fullName,
    email: rawContact.email,
    department: rawContact.department,
    phone: rawContact.phone,
  })
}

export const addContactToList = (contact: Contact, list: Contact[]) => [contact, ...list]

export const updateContactInList = (contact: Contact, list: Contact[]) =>
  list.map((item) => (item.id === contact.id ? contact : item))

export const prepareFetchContactsRequest = (state: ContactsState) => {
  const { sorted, offset, limit, sorting } = state

  // If there's only one contact left on a page then go to a previus one if it exists
  const correctedOffset = offset > 0 && sorted.length === 1 ? offset - limit : offset

  return { offset: correctedOffset, limit, orderByField: sorting.field, order: sorting.direction }
}

export const prepareMobileFetchContactsRequest = ({ sorted, sorting }: ContactsState) => ({
  offset: 0,
  limit: sorted.length,
  orderByField: sorting.field,
  order: sorting.direction,
})
