import { SUBSCRIPTION_NOT_EXIST } from '../../../constants/errors'
import { FETCH_STATUSES } from '../../../types/fetch'
import { RootState } from '../../rootReducer.types'

export const subscriptionUserPlanDataSelector = (state: RootState) => state.subscription.userPlan.data
export const subscriptionUserPlanFetchStatusSelector = (state: RootState) => state.subscription.userPlan.fetchStatus
export const subscriptionUserPlanErrorSelector = (state: RootState) => state.subscription.userPlan.error
export const subscriptionUserPlanIsIdleSelector = (state: RootState) =>
  state.subscription.userPlan.fetchStatus === FETCH_STATUSES.IDLE
export const subscriptionUserPlanEnabledFeaturesSelector = (state: RootState) =>
  state.subscription.userPlan.enabledFeatures
export const subscriptionUserPlanIsLoadedSelector = (state: RootState) =>
  state.subscription.userPlan.fetchStatus === FETCH_STATUSES.SUCCESS ||
  state.subscription.userPlan.fetchStatus === FETCH_STATUSES.FAILURE
export const subscriptionUserPlanEnabledLimitsSelector = (state: RootState) => state.subscription.userPlan.enabledLimits
export const subscriptionNotExistSelector = (state: RootState) =>
  state.subscription.userPlan.error?.lastErrorCode === SUBSCRIPTION_NOT_EXIST
export const subscriptionUsePlanIsFirstLoadSelector = (state: RootState) => state.subscription.userPlan.isFirstLoad
export const subscriptionPlanslistSelector = (state: RootState) => state.subscription.plans.list.data
