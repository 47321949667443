import React, { useEffect, useMemo, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { FormikProps } from 'formik'
import { useTranslation } from 'react-i18next'

import translations from '../../../translations/keys'
import * as SELECTORS from '../../../store/templates/recipients/recipients.selectors'
import * as ACTIONS from '../../../store/templates/recipients/recipients.actions'
import { useShowFetchError } from '../../hooks/useShowFetchError'
import { AddRecipientsForm } from '../../../types/recipients'
import { FETCH_STATUSES } from '../../../types/fetch'
import { SIGNING_TYPES } from '../../../types/signingOrder'
import { TemplateRouteParams } from '../../types/template'
import { removeExtension } from '../../../utils/file/removeExtension'
import Typography from '../../ui/Typography'
import { TYPOGRAPHY_NAMES } from '../../ui/Typography/Typography.types'
import Box from '../../ui/Box'
import FullScreenTemplate from '../../components/Main/FullScreenTemplate'
import { PreviewHeader } from '../../components/PreviewHeader/PreviewHeader'
import { FormRecipients } from '../../ui/Forms/FormRecipients/FormRecipients'
import { ChangeSigningOrder } from '../../components/ChangeSigningOrder/ChangeSigningOrder'

import useActions from './hooks/useActions'
import useButtons from './hooks/useButtons'
import { Modals } from './components/Modals'

export const TemplateAddRecipients = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { templateId } = useParams<TemplateRouteParams>()
  const templateName = useSelector(SELECTORS.recipientsTemplateNameSelector)
  const byId = useSelector(SELECTORS.recipientsByIdSelector)
  const sorted = useSelector(SELECTORS.recipientsSortedSelector)
  const order = useSelector(SELECTORS.recipientsOrderSelector)
  const fetch = useSelector(SELECTORS.recipientsFetchSelector)
  const fetchAdd = useSelector(SELECTORS.recipientsFetchAddSelector)
  const formRef: React.Ref<FormikProps<AddRecipientsForm>> = useRef(null)
  const recipientsList = useMemo(() => Object.values(byId), [sorted])
  const actions = useActions()
  const { buttons, handleSubmit } = useButtons(formRef)

  const loading = sorted.length === 0 && fetch.status === FETCH_STATUSES.REQUEST

  const handleOrderChange = (order: string) => {
    dispatch(ACTIONS.setOrder(order as SIGNING_TYPES))
  }

  useShowFetchError(fetchAdd.status, fetchAdd.error)

  useEffect(() => {
    if (templateId) {
      dispatch(ACTIONS.fetchTemplateInfo(templateId))
      dispatch(ACTIONS.fetchRecipients(templateId))
    }
  }, [templateId])

  if (loading) {
    return null
  }

  return (
    <FullScreenTemplate
      headerContent={<PreviewHeader actions={actions} buttons={buttons} title={removeExtension(templateName || '')} />}
    >
      <Box padding={4}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography name={TYPOGRAPHY_NAMES.H1}>{t(translations.ADD_RECIPIENTS_TITLE)}</Typography>
          <ChangeSigningOrder order={order as string} onChange={handleOrderChange} />
        </Box>
        <Box mt={3} mb={3}>
          <Typography name={TYPOGRAPHY_NAMES.H3}>{t(translations.RECIPIENTS)}</Typography>
        </Box>
        <FormRecipients
          templatesMode
          ref={formRef}
          recipients={recipientsList}
          disableOrder={order === SIGNING_TYPES.SEPARATE}
          onSubmit={handleSubmit}
        />
      </Box>
      <Modals />
    </FullScreenTemplate>
  )
}
