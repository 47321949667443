import React from 'react'

import Button from '../../../../../../../ui/Button'
import Box from '../../../../../../../ui/Box'
import { SignaturePad } from '../../../SignaturePad/SignaturePad'

import { SignatureScreenProps } from './SignatureScreen.types'

const BUTTON_PLACE_HEIGHT = 48

export const SignatureScreen: React.FC<SignatureScreenProps> = ({
  placeholder,
  buttonText,
  setImg,
  imgUrl,
  setTrimmedImg,
  onButtonClick,
}) => (
  <Box width="100%" height="100%" display="flex" flexDirection="column" alignItems="center">
    <Box
      width="100%"
      height={`calc(100% - ${BUTTON_PLACE_HEIGHT}px)`}
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <SignaturePad placeholder={placeholder} setImg={setImg} setTrimmedImg={setTrimmedImg} imgUrl={imgUrl} isMobile />
    </Box>
    <Box mt={1} width={343}>
      <Button fullWidth disabled={!imgUrl} onClick={onButtonClick}>
        {buttonText}
      </Button>
    </Box>
  </Box>
)
