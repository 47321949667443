import React from 'react'

import { SvgIconProps } from '../../ui/SvgIcon/SvgIcon.types'
import SvgIcon from '../../ui/SvgIcon'

export const BellIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon viewBox="0 0 24 25" {...props}>
    <path d="M19.125 18.5H18.5625V10.5312C18.5625 7.22422 16.118 4.49141 12.9375 4.03672V3.125C12.9375 2.60703 12.518 2.1875 12 2.1875C11.482 2.1875 11.0625 2.60703 11.0625 3.125V4.03672C7.88203 4.49141 5.4375 7.22422 5.4375 10.5312V18.5H4.875C4.46016 18.5 4.125 18.8352 4.125 19.25V20C4.125 20.1031 4.20937 20.1875 4.3125 20.1875H9.375C9.375 21.6359 10.5516 22.8125 12 22.8125C13.4484 22.8125 14.625 21.6359 14.625 20.1875H19.6875C19.7906 20.1875 19.875 20.1031 19.875 20V19.25C19.875 18.8352 19.5398 18.5 19.125 18.5ZM12 21.3125C11.3789 21.3125 10.875 20.8086 10.875 20.1875H13.125C13.125 20.8086 12.6211 21.3125 12 21.3125ZM7.125 18.5V10.5312C7.125 9.22813 7.63125 8.00469 8.55234 7.08359C9.47344 6.1625 10.6969 5.65625 12 5.65625C13.3031 5.65625 14.5266 6.1625 15.4477 7.08359C16.3687 8.00469 16.875 9.22813 16.875 10.5312V18.5H7.125Z" />
  </SvgIcon>
)
