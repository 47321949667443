import React from 'react'

export const TriangleIcon = () => (
  <svg width="8" height="5" viewBox="0 0 8 5" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.57066 4.42813L0.179057 1.03653C-0.203447 0.654023 0.067458 0 0.608401 0H7.3916C7.93254 0 8.20345 0.654022 7.82094 1.03653L4.42934 4.42813C4.19222 4.66525 3.80778 4.66525 3.57066 4.42813Z"
      fill="#8B96A9"
    />
  </svg>
)
