import { Form, Formik } from 'formik'
import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'

import { useCleanFields } from '../../../hooks/useCleanFields'
import { verificationPasswordInitialValues } from '../../../initialValues/verificationPasswordInitialValues'
import translations from '../../../translations/keys'
import { VerificationPasswordFields } from '../../../types/verificationPassword'
import { verificationPasswordValidationSchema } from '../../../validation/verificationPassord/verificationPasswordValidationSchema'
import { PasswordIcon } from '../../assets/PasswordIcon'
import Button from '../../ui/Button'
import FormPassword from '../../ui/Forms/FormPassword'
import Typography from '../../ui/Typography'
import { TYPOGRAPHY_NAMES } from '../../ui/Typography/Typography.types'
import Box from '../../ui/Box'

import { StyledContent } from './AccessGuardForm.styles'
import { AccessGuardFormProps, AccessGuardFormSubmit } from './AccessGuardForm.types'

export const AccessGuardForm: React.FC<AccessGuardFormProps> = ({
  onSubmit,
  description,
  title,
  submitText = translations.ENTER,
  isError,
}) => {
  const { t } = useTranslation()
  const formRef = useRef(null)
  const renderForm = () => (
    <Form>
      <Box mb={1}>
        <FormPassword
          name={VerificationPasswordFields.password}
          label={t(translations.PASSWORD_LABEL)}
          placeholder={t(translations.PASSWORD_PLACEHOLDER)}
          autoComplete="new-password"
        />
      </Box>
      <Button type="submit" fullWidth data-testid="access-guard-form-submit">
        {t(submitText)}
      </Button>
    </Form>
  )

  useCleanFields(isError, [VerificationPasswordFields.password], formRef, { errorCode: 'PASSWORD_INVALID_TRY_AGAIN' })

  const handleSubmit: AccessGuardFormSubmit = (values) => onSubmit(values)

  return (
    <StyledContent data-testid="access-guard-form">
      <Box textAlign="center" mb={4} data-testid="access-guard-form-password-icon">
        <PasswordIcon />
      </Box>
      <Box textAlign="center" mb={3}>
        <Typography data-testid="access-guard-form-title" name={TYPOGRAPHY_NAMES.H1}>
          {title}
        </Typography>
      </Box>
      {description && (
        <Box textAlign="center" mb={3}>
          <Typography data-testid="access-guard-form-description" name={TYPOGRAPHY_NAMES.body14}>
            {description}
          </Typography>
        </Box>
      )}
      <Formik
        innerRef={formRef}
        initialValues={verificationPasswordInitialValues}
        onSubmit={handleSubmit}
        validationSchema={verificationPasswordValidationSchema}
      >
        {renderForm}
      </Formik>
    </StyledContent>
  )
}
