import React from 'react'
import styled, { css } from 'styled-components'

import { RecipientActions, RecipientData } from '../../types/recipients'
import translations from '../../translations/keys'
import { prepareRecipientForForm } from '../../utils/recipients/recipients'

export const RecipientActionBackground = css<{
  action: RecipientActions
}>`
  background-color: ${({ action, theme }) => {
    if (action === RecipientActions.SIGN) {
      return theme.palette.green
    } else if (action === RecipientActions.INITIAL) {
      return '#F7BD00'
    }

    return theme.palette.blueExtraLight
  }};
`

export const StyledIndicator = styled.div<{
  action: RecipientActions
}>`
  display: inline-block;
  width: 16px;
  height: 16px;
  border-radius: 4px;
  ${RecipientActionBackground}
`

export const getRecipientsActionsOptions = [
  {
    value: RecipientActions.VIEW,
    name: translations.VIEW,
    Icon: () => <StyledIndicator action={RecipientActions.VIEW} />,
  },
  {
    value: RecipientActions.INITIAL,
    name: translations.INITIAL,
    Icon: () => <StyledIndicator action={RecipientActions.INITIAL} />,
  },
  {
    value: RecipientActions.SIGN,
    name: translations.SIGN,
    Icon: () => <StyledIndicator action={RecipientActions.SIGN} />,
  },
]

export const prepareAllRecipientsForForm = (recipients: RecipientData[], templatesMode = false) =>
  recipients.map((recipient) => prepareRecipientForForm(recipient, templatesMode))
