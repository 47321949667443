import React from 'react'

import ErrorBoundary from '../../components/ErrorBoundary/ErrorBoundary'
import Header from '../../components/Header'

import { Faq as FaqPage } from './Faq'

export const Faq = () => (
  <ErrorBoundary isHeader>
    <Header />
    <FaqPage />
  </ErrorBoundary>
)
