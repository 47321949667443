import React from 'react'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { usePaymentFetch } from '../../../hooks/subscription/usePaymentFetch'
import translations from '../../../translations/keys'
import { SubscriptionPaymentTerms } from '../../../types/subscriptionPayment'
import { location } from '../../../utils/location'
import { SubscriptionPaymentSkeleton } from '../../components/Skeletons/SubscriptionPaymentSkeleton'
import { SubscriptionPaymentRouteParams } from '../../types/subscriptionPayment'
import { BackButton } from '../../ui/BackButton/BackButton'
import Typography from '../../ui/Typography'
import { TYPOGRAPHY_NAMES } from '../../ui/Typography/Typography.types'
import * as PLANS_SELECTORS from '../../../store/subscription/plans/plans.selectors'

import Payment from './components/Payment'
import Plan from './components/Plan'
import { useErrors } from './hooks/useErrors'
import Modals from './components/Modals'

export const SubscriptionPayment = () => {
  const { subscriptionId, term } = useParams<SubscriptionPaymentRouteParams>()
  const selectedPlan = useSelector(PLANS_SELECTORS.subscriptionPlanDataSelector)
  const { t } = useTranslation()
  usePaymentFetch(subscriptionId)
  useErrors()

  const renderPlan = () => {
    if (!selectedPlan) {
      return <SubscriptionPaymentSkeleton />
    }

    return (
      <>
        <Typography name={TYPOGRAPHY_NAMES.H3}>
          {t(translations.SIGN_UP_FOR)} {selectedPlan.name}
        </Typography>
        <Plan data={selectedPlan} />
      </>
    )
  }

  const renderPayment = () => {
    if (!selectedPlan) {
      return
    }

    return (
      <Payment
        price={term === SubscriptionPaymentTerms.YEAR ? selectedPlan.yearPrice : selectedPlan.monthPrice}
        term={term}
      />
    )
  }

  return (
    <div>
      <BackButton path={location.subscriptionPlansUrl()} compact />
      {renderPlan()}
      {renderPayment()}
      <Modals />
    </div>
  )
}
