import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'

import * as SIGNING_SELECTORS from '../../store/contracts/signing/signing.selectors'
import * as ACTIONS from '../../store/contracts/signing/signing.actions'
import { SigningNotificationMessages } from '../../store/contracts/signing/signing.types'
import { Variant } from '../../types/notifications'
import * as STRIKES_SELECTORS from '../../store/contracts/strikes/strikes.selectors'
import * as CORRECTIONS_SELECTORS from '../../store/contracts/corrections/corrections.selectors'
import * as COMMENTS_SELECTORS from '../../store/contracts/comments/comments.selectors'

import { useConfirmationNotification } from './useConfirmationNotification'
import { useCancelConfirmationNotification } from './useCancelConfirmation'

export const useSigningNotifications = () => {
  const dispatch = useDispatch()
  const isNegative = useSelector(SIGNING_SELECTORS.signingIsNegativeSelector)
  const isFirstView = useSelector(SIGNING_SELECTORS.signingIsFirstViewSelector)
  const isCompleted = useSelector(SIGNING_SELECTORS.signingContractIsCompletedSelector)
  const isDeleted = useSelector(SIGNING_SELECTORS.signingContractIsDeletedSelector)
  const isExpired = useSelector(SIGNING_SELECTORS.signingContractIsExpiredSelector)
  const isStrikeConfirmed = useSelector(STRIKES_SELECTORS.strikesIsConfirmedSelector)
  const strikeConfirmStatus = useSelector(STRIKES_SELECTORS.strikesConfirmStatusSelector)
  const isStrikeCanceled = useSelector(STRIKES_SELECTORS.strikesCancelConfirmationSuccessSelector)
  const strikeCancelStatus = useSelector(STRIKES_SELECTORS.strikesCancelConfirmationStatusSelector)
  const isCorrectionConfirmed = useSelector(CORRECTIONS_SELECTORS.correctionsIsConfirmedSelector)
  const correctionConfirmStatus = useSelector(CORRECTIONS_SELECTORS.correctionsConfirmStatusSelector)
  const isCorrectionCanceled = useSelector(CORRECTIONS_SELECTORS.correctionsCancelConfirmationSuccessSelector)
  const correctionCancelStatus = useSelector(CORRECTIONS_SELECTORS.correctionsCancelConfirmationStatusSelector)
  const isCommentConfirmed = useSelector(COMMENTS_SELECTORS.commentsIsConfirmedSelector)
  const commentConfirmStatus = useSelector(COMMENTS_SELECTORS.commentsConfirmStatusSelector)
  const isCommentCanceled = useSelector(COMMENTS_SELECTORS.commentsCancelConfirmationSuccessSelector)
  const commentCancelStatus = useSelector(COMMENTS_SELECTORS.commentsCancelConfirmationStatusSelector)

  useEffect(() => {
    if (isFirstView) {
      dispatch(
        ACTIONS.setSigningNotification({
          message: SigningNotificationMessages.REQUEST_TO_VIEW,
          variant: Variant.SUCCESS,
        })
      )
    }
  }, [isFirstView])
  useEffect(() => {
    if (isNegative) {
      dispatch(
        ACTIONS.setSigningNotification({
          message: SigningNotificationMessages.SIGNING_STOPPED,
          variant: Variant.WARNING,
        })
      )
    }
  }, [isNegative])
  useEffect(() => {
    if (isCompleted) {
      dispatch(
        ACTIONS.setSigningNotification({
          message: SigningNotificationMessages.SIGNING_FINISHED,
          variant: Variant.SUCCESS,
        })
      )
    }
  }, [isCompleted])
  useEffect(() => {
    if (isDeleted) {
      dispatch(
        ACTIONS.setSigningNotification({
          message: SigningNotificationMessages.DELETED_BY_OWNER,
          variant: Variant.WARNING,
        })
      )
    }
  }, [isDeleted])
  useEffect(() => {
    if (isExpired) {
      dispatch(
        ACTIONS.setSigningNotification({
          message: SigningNotificationMessages.EXPIRED_CONTRACT_NOTIFICATION,
          variant: Variant.WARNING,
        })
      )
    }
  }, [isExpired])
  useConfirmationNotification(isCommentConfirmed, commentConfirmStatus)
  useConfirmationNotification(isCorrectionConfirmed, strikeConfirmStatus)
  useConfirmationNotification(isStrikeConfirmed, correctionConfirmStatus)
  useCancelConfirmationNotification(isCommentCanceled, commentCancelStatus)
  useCancelConfirmationNotification(isStrikeCanceled, strikeCancelStatus)
  useCancelConfirmationNotification(isCorrectionCanceled, correctionCancelStatus)
}
