import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { modalsVisibleSelector } from '../../../../../store/modals/modals.selectors'
import { closeModal } from '../../../../../store/modals/modals.actions'
import ContactUsModal from '../../../../ui/Modals/SubscriptionModals/ContactUsModal'
import { SELECT_SUBSCRIPTION_MODALS } from '../../../../../constants/selectSubscription'
import SelectTermModal from '../../../../components/Modals/SelectSubscriptionModals/SelectTermModal'

export const Modals = () => {
  const dispatch = useDispatch()

  const showContactUs = useSelector(
    modalsVisibleSelector(SELECT_SUBSCRIPTION_MODALS.SELECT_SUBSCRIPTION_CONTACT_US_MODAL)
  )
  const handleContactUsClose = () => {
    dispatch(closeModal(SELECT_SUBSCRIPTION_MODALS.SELECT_SUBSCRIPTION_CONTACT_US_MODAL))
  }

  const showSelectTerm = useSelector(modalsVisibleSelector(SELECT_SUBSCRIPTION_MODALS.SELECT_SUBSCRIPTION_TERM_MODAL))
  const handleSelectTermClose = () => {
    dispatch(closeModal(SELECT_SUBSCRIPTION_MODALS.SELECT_SUBSCRIPTION_TERM_MODAL))
  }

  return (
    <>
      {showContactUs && <ContactUsModal onClose={handleContactUsClose} />}
      {showSelectTerm && <SelectTermModal onClose={handleSelectTermClose} />}
    </>
  )
}
