import { FetchFailureAction, FETCH_STATUSES } from '../../../types/fetch'
import { createReducer } from '../../createReducer'

import * as TYPES from './preview.types'
import * as ACTIONS from './preview.actions'

type Actions =
  | TYPES.SetSuccessMessageAction
  | TYPES.ClearSuccessMessageAction
  | TYPES.ClearPreviewAction
  | TYPES.FetchPreviewSuccessAction
  | FetchFailureAction

export const initialState: TYPES.PreviewState = {
  data: null,
  fetchStatus: FETCH_STATUSES.IDLE,
  successMessage: TYPES.SuccessMessages.NONE,
  error: null,
}

export const preview = createReducer<TYPES.PreviewState, Actions>(initialState, {
  [TYPES.PreviewActions.SET_SUCCESS_MESSAGE]: (state, action) => ({
    ...state,
    successMessage: (action as TYPES.SetSuccessMessageAction).payload.message,
  }),
  [TYPES.PreviewActions.CLEAR_SUCCESS_MESSAGE]: (state) => ({
    ...state,
    successMessage: TYPES.SuccessMessages.NONE,
  }),
  [TYPES.PreviewActions.CLEAR_PREVIEW]: () => ({
    ...initialState,
  }),

  [ACTIONS.fetchPreviewTypes.request]: (state) => ({
    ...state,
    fetchStatus: FETCH_STATUSES.REQUEST,
  }),
  [ACTIONS.fetchPreviewTypes.success]: (state, action) => ({
    ...state,
    data: (action as TYPES.FetchPreviewSuccessAction).payload,
    fetchStatus: FETCH_STATUSES.SUCCESS,
  }),
  [ACTIONS.fetchPreviewTypes.failure]: (state, action) => ({
    ...state,
    fetchStatus: FETCH_STATUSES.FAILURE,
    error: (action as FetchFailureAction).payload,
  }),
})
