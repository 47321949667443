import * as yup from 'yup'

const PASSWORD_MIN_LENGTH = 8
export const PASSWORD_MAX_LENGTH = 100
const specialChars = '!@#$%^&*()\\-_=+\\\\|\\[\\]{};:/?.><'
const passwordAllowedCharsRegExp = new RegExp(`^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\\d${specialChars}]*)$`)
const passwordRulesRegExp = new RegExp(`(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[${specialChars}])`)

export const singlePasswordValidationSchema = (checkOnlyRequired = false) => {
  const requiredField = yup.string().required('REQUIRED_FIELD')

  if (checkOnlyRequired) {
    return requiredField
  }

  return requiredField
    .matches(passwordAllowedCharsRegExp, 'PASSWORD_INVALID')
    .matches(passwordRulesRegExp, 'PASSWORD_INVALID')
    .min(PASSWORD_MIN_LENGTH, 'PASSWORD_INVALID')
    .max(PASSWORD_MAX_LENGTH, 'PASSWORD_INVALID')
}

export const passwordValidationSchema = (passwordField: string, confirmPasswordField: string) =>
  yup.object().shape({
    [passwordField]: singlePasswordValidationSchema(),
    [confirmPasswordField]: singlePasswordValidationSchema().oneOf([yup.ref(passwordField)], 'PASSWORDS_DO_NOT_MATCH'),
  })
