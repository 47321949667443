import React, { useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'

import Button from '../Button'
import { BUTTON_VARIANTS } from '../Button/Button.types'
import translations from '../../../translations/keys'
import Box from '../Box'
import { Spinner } from '../Spinner/Spinner'
import TableActions from '../TableActions'

import {
  StyledTable,
  StyledTableHead,
  StyledHeadButton,
  StyledTableHeadCell,
  StyledSortingIcon,
  StyledNeutralIcon,
  StyledRow,
  StyledColumn,
  StyledTableHeadColumns,
  StyledTableHeadFullInfo,
  ScrollableWrapper,
} from './MobileTable.styles'
import { MobileTableProps } from './MobileTable.types'

export const MobileTable: React.FC<MobileTableProps> = ({
  columns,
  sorting,
  onSort,
  data,
  renderColumn,
  hasMore,
  loadMore,
  actions,
  highlightedText,
  emptyResultsMessage,
}) => {
  const [showFullInfo, setShowFullInfo] = useState(false)
  const flipIcon = sorting.direction === 'ASC'

  const handleSorting = (field: string) => () => {
    onSort({ field, direction: sorting.direction === 'ASC' ? 'DESC' : 'ASC' })
  }

  const onFullInfoClick = () => {
    setShowFullInfo((prevShowFullInfo) => !prevShowFullInfo)
  }

  return (
    <StyledTable>
      <StyledTableHead>
        <StyledTableHeadColumns>
          {columns.map((column) => (
            <StyledTableHeadCell key={column.field} test-id="mobile-table-head-cell">
              <StyledHeadButton onClick={handleSorting(column.field)}>
                {column.name}
                {sorting.field === column.field ? <StyledSortingIcon flip={flipIcon} /> : <StyledNeutralIcon />}
              </StyledHeadButton>
            </StyledTableHeadCell>
          ))}
        </StyledTableHeadColumns>
        <StyledTableHeadFullInfo>
          <Button
            variant={BUTTON_VARIANTS.TEXT}
            textColor="grey"
            onClick={onFullInfoClick}
            test-id="mobile-table-full-info-button"
          >
            {showFullInfo ? translations.HIDE_INFO : translations.SHOW_INFO}
          </Button>
        </StyledTableHeadFullInfo>
      </StyledTableHead>

      {data.length ? (
        <ScrollableWrapper id="scrollableTarget" test-id="mobile-scrollable-table">
          <InfiniteScroll
            dataLength={data.length}
            next={loadMore}
            hasMore={hasMore}
            loader={
              <Box mt={1}>
                <Spinner show />
              </Box>
            }
            scrollableTarget="scrollableTarget"
          >
            {data.map((item) => (
              <StyledRow key={item.id} test-id="mobile-table-row">
                <StyledColumn>{renderColumn(item, { showFullInfo, highlightedText })}</StyledColumn>
                <TableActions data={item} actions={actions} />
              </StyledRow>
            ))}
          </InfiniteScroll>
        </ScrollableWrapper>
      ) : (
        <Box mt={2}>{emptyResultsMessage}</Box>
      )}
    </StyledTable>
  )
}
