import React, { FC, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { useDispatchUnmount } from '../../../../../hooks/useDispatchUnmount'
import { cleanVerifyPassword, fetchVerifyPassword } from '../../../../../store/verifyPassword/verifyPassword.actions'
import {
  verifyPasswordIsFailureSelector,
  verifyPasswordSuccessSelector,
} from '../../../../../store/verifyPassword/verifyPassword.selectors'
import translations from '../../../../../translations/keys'
import { VerifyPasswordKeys } from '../../../../../types/verifyPassword'
import AccessGuard from '../../../AccessGuard'
import { AccessGuardFormSubmit } from '../../../AccessGuardForm/AccessGuardForm.types'
import Modal from '../../../Modal'
import Box from '../../../../ui/Box'

import { ChooseSignModalProps } from './ChooseSignModal.types'
import SignsForm from './components/SignsForm'

export const ChooseSignModal: FC<ChooseSignModalProps> = ({ onClose }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const access = useSelector(verifyPasswordSuccessSelector(VerifyPasswordKeys.SIGNATURES_PAGE))
  const isError = useSelector(verifyPasswordIsFailureSelector(VerifyPasswordKeys.SIGNATURES_PAGE))

  const onSubmit: AccessGuardFormSubmit = (values) => {
    dispatch(fetchVerifyPassword(VerifyPasswordKeys.SIGNATURES_PAGE, values))
  }
  const onCleanVerifyPassword = useCallback(() => cleanVerifyPassword(VerifyPasswordKeys.SIGNATURES_PAGE), [])

  useDispatchUnmount(onCleanVerifyPassword)

  return (
    <Modal onClose={onClose}>
      <Box mt={3} mb={3}>
        <AccessGuard
          access={access}
          onSubmit={onSubmit}
          title={t(translations.ENTER_YOUR_PASSWORD_TO_CONTINUE)}
          isError={isError}
        >
          <SignsForm onClose={onClose} />
        </AccessGuard>
      </Box>
    </Modal>
  )
}
