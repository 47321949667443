import React from 'react'

import { SvgIconProps } from '../../ui/SvgIcon/SvgIcon.types'
import SvgIcon from '../../ui/SvgIcon'

export const FileIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <path d="M20.0297 6.76406L14.9859 1.72031C14.8453 1.57969 14.6555 1.5 14.4562 1.5H4.5C4.08516 1.5 3.75 1.83516 3.75 2.25V21.75C3.75 22.1648 4.08516 22.5 4.5 22.5H19.5C19.9148 22.5 20.25 22.1648 20.25 21.75V7.29609C20.25 7.09687 20.1703 6.90469 20.0297 6.76406ZM18.5203 7.64062H14.1094V3.22969L18.5203 7.64062ZM18.5625 20.8125H5.4375V3.1875H12.5156V8.25C12.5156 8.51107 12.6193 8.76145 12.8039 8.94606C12.9885 9.13066 13.2389 9.23438 13.5 9.23438H18.5625V20.8125ZM11.8125 14.4844H7.5C7.39687 14.4844 7.3125 14.5688 7.3125 14.6719V15.7969C7.3125 15.9 7.39687 15.9844 7.5 15.9844H11.8125C11.9156 15.9844 12 15.9 12 15.7969V14.6719C12 14.5688 11.9156 14.4844 11.8125 14.4844ZM7.3125 11.4844V12.6094C7.3125 12.7125 7.39687 12.7969 7.5 12.7969H16.5C16.6031 12.7969 16.6875 12.7125 16.6875 12.6094V11.4844C16.6875 11.3813 16.6031 11.2969 16.5 11.2969H7.5C7.39687 11.2969 7.3125 11.3813 7.3125 11.4844Z" />
  </SvgIcon>
)
