import { FetchFailureAction, FETCH_STATUSES } from '../../../types/fetch'
import { createReducer } from '../../createReducer'

import * as TYPES from './plans.types'
import * as ACTIONS from './plans.actions'

type Actions = FetchFailureAction | TYPES.FetchPlansSuccess | TYPES.FetchPlanSuccess

export const initialState: TYPES.PlansState = {
  list: {
    fetchStatus: FETCH_STATUSES.IDLE,
    error: null,
    data: null,
  },
  plan: {
    fetchStatus: FETCH_STATUSES.IDLE,
    error: null,
    data: null,
  },
}

export const plans = createReducer<TYPES.PlansState, Actions>(initialState, {
  [ACTIONS.fetchPlansTypes.request]: (state) => ({
    ...state,
    list: {
      ...state.list,
      fetchStatus: FETCH_STATUSES.REQUEST,
    },
  }),
  [ACTIONS.fetchPlansTypes.success]: (state, action) => ({
    ...state,
    list: {
      ...state.list,
      fetchStatus: FETCH_STATUSES.SUCCESS,
      data: (action as TYPES.FetchPlansSuccess).payload.data,
    },
  }),
  [ACTIONS.fetchPlansTypes.failure]: (state, action) => ({
    ...state,
    list: {
      ...state.list,
      fetchStatus: FETCH_STATUSES.FAILURE,
      error: (action as FetchFailureAction).payload,
    },
  }),
  [TYPES.PlansActions.CLEAR_SUBSCRIPTION_PLANS]: () => ({ ...initialState }),
  [ACTIONS.fetchPlanTypes.request]: (state) => ({
    ...state,
    plan: {
      ...state.plan,
      fetchStatus: FETCH_STATUSES.REQUEST,
    },
  }),
  [ACTIONS.fetchPlanTypes.success]: (state, action) => ({
    ...state,
    plan: {
      ...state.plan,
      fetchStatus: FETCH_STATUSES.SUCCESS,
      data: (action as TYPES.FetchPlanSuccess).payload.data,
    },
  }),
  [ACTIONS.fetchPlanTypes.failure]: (state, action) => ({
    ...state,
    plan: {
      ...state.plan,
      fetchStatus: FETCH_STATUSES.FAILURE,
      error: (action as FetchFailureAction).payload,
    },
  }),
})
