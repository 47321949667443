import { FETCH_STATUSES } from '../../../types/fetch'
import { RootState } from '../../rootReducer.types'

export const createContractIdSelector = (state: RootState) => state.templates.createContract.contractId
export const createContractRecipientsSelector = (state: RootState) => state.templates.createContract.recipients.list
export const createContractOrderSelector = (state: RootState) => state.templates.createContract.recipients.order
export const createContractIsRequestSelector = (state: RootState) =>
  state.templates.createContract.fetchStatus === FETCH_STATUSES.REQUEST
export const createContractIsSuccessSelector = (state: RootState) =>
  state.templates.createContract.fetchStatus === FETCH_STATUSES.SUCCESS
export const createContractFetchStatusSelector = (state: RootState) => state.templates.createContract.fetchStatus
export const createContractErrorSelector = (state: RootState) => state.templates.createContract.error
