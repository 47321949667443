import { Action } from 'redux'

import { FetchFailurePayload, FETCH_STATUSES } from '../../types/fetch'

export interface ApiIntegrationState {
  publicToken: string
  fetchPublicToken: {
    status: FETCH_STATUSES
    error?: FetchFailurePayload | null
  }
  generatePublicToken: {
    status: FETCH_STATUSES
    error?: FetchFailurePayload | null
  }
}

export enum ApiIntegrationActions {
  'FETCH_PUBLIC_TOKEN' = 'FETCH_PUBLIC_TOKEN',
  'GENERATE_PUBLIC_TOKEN' = 'GENERATE_PUBLIC_TOKEN',
  'RESET' = 'RESET',
}

export type FetchPublicTokenAction = Action

export type ResetAction = Action

export interface FetchPublicTokenSuccessAction extends Action {
  payload: { publicToken: string }
}

export interface FetchGeneratePublicTokenSuccessAction extends Action {
  payload: { publicToken: string }
}
