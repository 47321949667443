import { Action } from 'redux'

import { DocumentMessageBody } from '../../../types/documents'
import { FetchFailurePayload, FETCH_STATUSES } from '../../../types/fetch'

export interface MessageState {
  fetchStatus: FETCH_STATUSES
  error: FetchFailurePayload | null
}

export enum MessageActions {
  'FETCH_SAVE_TEMPLATE_MESSAGE' = 'FETCH_SAVE_TEMPLATE_MESSAGE',
  'CLEAR_TEMPLATE_MESSAGE' = 'CLEAR_TEMPLATE_MESSAGE',
}

export interface FetchSaveTemplateMessage extends Action {
  payload: { templateId: string; body: DocumentMessageBody }
}
export type FetchSaveTemplateMessageSuccess = Action

export type ClearTemplateMessageAction = Action
