import { Epic, combineEpics, ofType } from 'redux-observable'
import { concat, of } from 'rxjs'
import { catchError, map, mergeMap, switchMap, takeUntil } from 'rxjs/operators'

import { strikeService } from '../../../api'
import { catchFetchError } from '../../../utils/catchFetchError'
import { prepareStrikesForStore, prepareStrikeForRequest } from '../../../utils/strikethrough'
import { authUserIdSelector } from '../../auth/auth.selectors'

import * as ACTIONS from './strikes.actions'

const fetchStrikes: Epic = (action$, state$) =>
  action$.pipe(
    ofType(ACTIONS.fetchStrikesTypes.request),
    mergeMap(({ payload }) =>
      strikeService.fetchStrikes(payload.contractId).pipe(
        map((response) =>
          ACTIONS.fetchStrikesSuccess(...prepareStrikesForStore(response.strikes, authUserIdSelector(state$.value)))
        ),
        catchError(catchFetchError(ACTIONS.fetchStrikesFailure)),
        takeUntil(action$.pipe(ofType(ACTIONS.fetchStrikesTypes.success, ACTIONS.fetchStrikesTypes.failure)))
      )
    )
  )

const fetchCreateStrike: Epic = (action$) =>
  action$.pipe(
    ofType(ACTIONS.fetchCreateStrikeTypes.request),
    switchMap(({ payload }) =>
      concat(
        strikeService.fetchCreateStrike(payload.contractId, prepareStrikeForRequest(payload.strike)).pipe(
          map(({ response }) => ACTIONS.fetchCreateStrikeSuccess(response)),
          catchError(catchFetchError(ACTIONS.fetchCreateStrikeFailure)),
          takeUntil(
            action$.pipe(ofType(ACTIONS.fetchCreateStrikeTypes.success, ACTIONS.fetchCreateStrikeTypes.failure))
          )
        ),
        of(ACTIONS.fetchStrikes(payload.contractId))
      )
    )
  )

const fetchConfirmStrike: Epic = (action$) =>
  action$.pipe(
    ofType(ACTIONS.fetchConfirmStrikeTypes.request),
    switchMap(({ payload }) =>
      strikeService.fetchConfirmStrike(payload).pipe(
        mergeMap(() => of(ACTIONS.fetchStrikes(payload.contractId), ACTIONS.fetchConfirmStrikeSuccess(payload.status))),
        catchError(catchFetchError(ACTIONS.fetchConfirmStrikeFailure)),
        takeUntil(
          action$.pipe(ofType(ACTIONS.fetchConfirmStrikeTypes.success, ACTIONS.fetchConfirmStrikeTypes.failure))
        )
      )
    )
  )

const fetchCancelStrikeConfirmation: Epic = (action$) =>
  action$.pipe(
    ofType(ACTIONS.fetchCancelStrikeConfirmationTypes.request),
    switchMap(({ payload }) =>
      strikeService.fetchCancelStrikeConfirmation(payload.contractId, payload.strikeId).pipe(
        mergeMap(() =>
          of(ACTIONS.fetchStrikes(payload.contractId), ACTIONS.fetchCancelStrikeConfirmationSuccess(payload.status))
        ),
        catchError(catchFetchError(ACTIONS.fetchCancelStrikeConfirmationFailure)),
        takeUntil(
          action$.pipe(
            ofType(
              ACTIONS.fetchCancelStrikeConfirmationTypes.success,
              ACTIONS.fetchCancelStrikeConfirmationTypes.failure
            )
          )
        )
      )
    )
  )

export const strikesEpics = combineEpics(
  fetchStrikes,
  fetchCreateStrike,
  fetchConfirmStrike,
  fetchCancelStrikeConfirmation
)
