import { FetchFailureAction, FetchFailurePayload } from '../../types/fetch'
import { createFetchActionTypes } from '../../utils/createFetchActionTypes'

import * as TYPES from './restorePassword.types'

export const fetchRestorePasswordTypes = createFetchActionTypes(TYPES.RestorePasswordActions.FETCH_RESTORE_PASSWORD)

export const fetchRestorePassword = (
  linkId: string,
  code: string,
  password: string
): TYPES.FetchRestorePasswordAction => ({
  type: fetchRestorePasswordTypes.request,
  payload: { linkId, code, password },
})

export const fetchRestorePasswordSuccess = (): TYPES.FetchRestorePasswordSuccessAction => ({
  type: fetchRestorePasswordTypes.success,
})

export const fetchRestorePasswordFailure = (payload: FetchFailurePayload): FetchFailureAction => ({
  type: fetchRestorePasswordTypes.failure,
  payload,
})

export const resetRestorePassword = () => ({
  type: TYPES.RestorePasswordActions.RESET_RESTORE_PASSWORD,
})
