import React from 'react'

interface LogoIconProps {
  withText?: boolean
  viewBox?: string
}

export const LogoIcon: React.FC<LogoIconProps> = ({ withText = true, viewBox, ...props }) => {
  const width = withText ? 99 : 34
  const calcVieBox = () => {
    if (viewBox) {
      return viewBox
    }
    return withText ? '0 0 99 56' : '0 0 34 56'
  }

  return (
    <svg width={width} height="56" viewBox={calcVieBox()} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0)">
        <path
          d="M0 4.4873L11.7389 8.35703V43.8407L8.4996 45.2207C4.47704 46.9578 0 44.0048 0 39.6429V4.4873Z"
          fill="#6085B3"
        />
        <path d="M20.1901 0L34.7526 7.7491V44.5549L20.1901 56V0Z" fill="#324C7E" />
        {withText && (
          <>
            <path
              d="M55.9581 24.2123H54.2272V23.0736C53.6954 23.5368 53.1829 23.8939 52.7091 24.1351C52.2256 24.3764 51.6937 24.5018 51.1039 24.5018C50.6591 24.5018 50.2336 24.4246 49.8372 24.2799C49.4407 24.1351 49.0926 23.9035 48.8025 23.5851C48.5124 23.2666 48.2804 22.8613 48.116 22.3788C47.9516 21.8963 47.8742 21.3076 47.8742 20.6128V13.9542H49.6051V19.8022C49.6051 20.3522 49.6244 20.7962 49.6631 21.1532C49.7018 21.5103 49.7888 21.8287 49.9339 22.0989C50.0789 22.3595 50.2626 22.5525 50.5141 22.6779C50.7558 22.8034 51.1039 22.8613 51.5584 22.8613C51.9838 22.8613 52.4286 22.7455 52.9121 22.5042C53.3956 22.263 53.8307 21.9735 54.2272 21.6261V13.9638H55.9581V24.2123Z"
              fill="#6085B3"
            />
            <path
              d="M66.382 28.0048H64.6511V23.1026C64.1483 23.5658 63.6552 23.8939 63.1814 24.1062C62.6979 24.3185 62.1757 24.4246 61.5859 24.4246C60.4158 24.4246 59.4876 23.9614 58.7913 23.0254C58.0951 22.0893 57.747 20.7865 57.747 19.1267C57.747 18.2485 57.8727 17.4669 58.1145 16.7913C58.3562 16.1158 58.6753 15.5465 59.0718 15.0833C59.4489 14.6394 59.9034 14.2919 60.4352 14.041C60.967 13.7998 61.5085 13.6743 62.079 13.6743C62.6108 13.6743 63.0653 13.7322 63.4521 13.8577C63.8389 13.9831 64.2353 14.1568 64.6415 14.3981L64.7478 13.9542H66.3723V28.0048H66.382ZM64.6511 21.6454V15.7974C64.216 15.5851 63.8292 15.4307 63.4811 15.3438C63.133 15.257 62.7656 15.2184 62.3788 15.2184C61.4698 15.2184 60.7736 15.5465 60.2805 16.2027C59.7873 16.8589 59.5456 17.8046 59.5456 19.0302C59.5456 20.2268 59.739 21.1532 60.1354 21.7998C60.5319 22.4463 61.1411 22.7648 61.963 22.7648C62.4465 22.7648 62.9203 22.6586 63.3844 22.456C63.8389 22.2533 64.2643 21.9831 64.6511 21.6454Z"
              fill="#6085B3"
            />
            <path
              d="M77.3474 19.0881C77.3474 20.8155 76.9316 22.1472 76.1 23.0929C75.2684 24.029 74.1564 24.5018 72.764 24.5018C71.3329 24.5018 70.2112 24.0193 69.3893 23.0446C68.5674 22.07 68.1613 20.7576 68.1613 19.0881C68.1613 17.3703 68.5867 16.0386 69.428 15.0929C70.2692 14.1472 71.3812 13.6647 72.764 13.6647C74.1468 13.6647 75.2588 14.1375 76.1 15.0832C76.9316 16.0386 77.3474 17.3703 77.3474 19.0881ZM75.5585 19.0881C75.5585 17.7563 75.3168 16.772 74.8333 16.1255C74.3498 15.4789 73.6536 15.1604 72.764 15.1604C71.8647 15.1604 71.1685 15.4789 70.6754 16.1255C70.1919 16.772 69.9405 17.7563 69.9405 19.0881C69.9405 20.3715 70.1822 21.3462 70.6754 22.0121C71.1588 22.6779 71.8551 23.006 72.764 23.006C73.6536 23.006 74.3402 22.6779 74.8333 22.0314C75.3168 21.3848 75.5585 20.4005 75.5585 19.0881Z"
              fill="#6085B3"
            />
            <path
              d="M87.133 24.2123H85.4021V23.0736C84.8703 23.5368 84.3578 23.8939 83.884 24.1351C83.4005 24.3764 82.8687 24.5018 82.2788 24.5018C81.834 24.5018 81.4085 24.4246 81.0121 24.2799C80.6156 24.1351 80.2675 23.9035 79.9774 23.5851C79.6874 23.2666 79.4553 22.8613 79.2909 22.3788C79.1265 21.8963 79.0492 21.3076 79.0492 20.6128V13.9542H80.78V19.8022C80.78 20.3522 80.7994 20.7962 80.838 21.1532C80.8767 21.5103 80.9637 21.8287 81.1088 22.0989C81.2538 22.3595 81.4376 22.5525 81.689 22.6779C81.9307 22.8034 82.2788 22.8613 82.7333 22.8613C83.1587 22.8613 83.6035 22.7455 84.087 22.5042C84.5705 22.263 85.0056 21.9735 85.4021 21.6261V13.9638H87.133V24.2123Z"
              fill="#6085B3"
            />
            <path
              d="M95.8259 24.2123V23.1605C95.5552 23.3921 95.3231 23.5754 95.1297 23.7202C94.9363 23.8649 94.7042 24 94.4432 24.1255C94.2014 24.2413 93.9403 24.3281 93.6793 24.3957C93.4182 24.4632 93.1087 24.4922 92.7606 24.4922C92.1998 24.4922 91.6873 24.3764 91.2135 24.1448C90.7397 23.9132 90.3336 23.5754 90.0048 23.1219C89.6664 22.6683 89.4053 22.1086 89.2216 21.4427C89.0378 20.7769 88.9411 20.0145 88.9411 19.146C88.9411 18.2485 89.0572 17.4669 89.2893 16.8106C89.5213 16.1544 89.8404 15.5754 90.2562 15.0833C90.6333 14.6394 91.0878 14.2823 91.61 14.0314C92.1418 13.7805 92.7026 13.655 93.2828 13.655C93.7953 13.655 94.2498 13.7129 94.6269 13.8287C95.004 13.9445 95.4101 14.1182 95.8356 14.3595V9.91077H97.5664V24.2123H95.8259ZM95.8259 21.7129V15.7974C95.4005 15.5851 95.0137 15.45 94.6752 15.3631C94.3368 15.2859 93.9693 15.2473 93.5632 15.2473C92.6736 15.2473 91.9774 15.5754 91.4746 16.2413C90.9718 16.9071 90.7204 17.8625 90.7204 19.1267C90.7204 20.333 90.9138 21.2594 91.3005 21.8963C91.6873 22.5332 92.3062 22.8517 93.1571 22.8517C93.6116 22.8517 94.066 22.7455 94.5302 22.5429C94.9847 22.3402 95.4198 22.07 95.8259 21.7129Z"
              fill="#6085B3"
            />
            <path
              d="M49.6921 30.7743C50.1273 30.7068 50.5817 30.6682 51.0749 30.6682C52.4093 30.6682 53.4826 31.0735 54.2949 31.8745C55.0298 32.6175 55.3972 33.5536 55.3972 34.6923V37.4426C55.3972 37.9058 55.2425 38.3015 54.9427 38.6296C54.6333 38.977 54.2465 39.1507 53.7921 39.1507H47.8742V37.8769H53.2022C53.647 37.8769 53.8694 37.6453 53.8694 37.1917V34.9143C53.8694 34.0651 53.6083 33.3606 53.0958 32.7912C52.5447 32.2122 51.6937 31.9227 50.5431 31.9131C50.2046 31.9131 49.9242 31.9324 49.7018 31.9806V30.7743H49.6921Z"
              fill="#6085B3"
            />
            <path
              d="M62.9493 39.1412C61.5859 39.1412 60.5319 38.6876 59.797 37.7805C59.1878 37.0278 58.8784 36.0531 58.8784 34.8661C58.8784 33.5151 59.2845 32.4632 60.1065 31.7009C60.8413 31.0061 61.8083 30.6586 63.0073 30.6586C63.9453 30.6586 64.7189 30.8902 65.3474 31.3631C66.1016 31.9518 66.4787 32.8106 66.4787 33.9301V37.8673H67.0589V39.1412H66.4787C66.4304 40.3957 66.0533 41.3897 65.3474 42.1038C64.8059 42.6538 64.1097 43.0302 63.2394 43.2329C62.7463 43.3487 62.2338 43.4162 61.6923 43.4162C61.4022 43.4162 61.1314 43.4066 60.8607 43.3969L60.2708 42.6635V42.0845C60.6286 42.1327 61.0057 42.152 61.4022 42.152C63.6939 42.152 64.8736 41.1484 64.9316 39.1315H62.9493V39.1412ZM64.9316 33.9301C64.9316 33.3221 64.7479 32.8299 64.3901 32.4729C64.042 32.1255 63.5778 31.9518 63.0073 31.9518C62.2048 31.9518 61.5666 32.222 61.1121 32.7624C60.648 33.3028 60.4159 34.0459 60.4159 35.0109C60.4159 35.8601 60.648 36.5453 61.1218 37.076C61.5859 37.6068 62.1951 37.8673 62.9397 37.8673H64.9413V33.9301H64.9316Z"
              fill="#6085B3"
            />
            <path d="M68.0645 37.8673H64.7381V39.1411H68.0645V37.8673Z" fill="#6085B3" />
            <path d="M71.265 37.8673H67.9387V39.1411H71.265V37.8673Z" fill="#6085B3" />
            <path
              d="M75.0459 37.8673C74.2917 37.0374 73.9146 36.0241 73.9146 34.8178C73.9146 33.6598 74.2337 32.7045 74.8719 31.9614C75.6068 31.1025 76.5737 30.6683 77.7921 30.6683C78.7591 30.6683 79.5907 30.9578 80.2772 31.5271C81.2152 32.3088 81.6793 33.3993 81.6793 34.8178C81.6793 36.0145 81.3022 37.0374 80.548 37.8673H82.1144V39.1411H79.3296C78.6237 39.1411 78.1209 39.0929 77.8405 38.9964H77.7728C77.4827 39.0929 76.9895 39.1411 76.274 39.1411H71.1684V37.8673H75.0459ZM77.7824 31.9517C77.0476 31.9517 76.477 32.2123 76.0612 32.7238C75.6455 33.2352 75.4424 33.9493 75.4424 34.8565C75.4424 35.6864 75.6648 36.3812 76.1193 36.9602C76.5737 37.5392 77.1249 37.8384 77.7824 37.8577C78.44 37.8384 79.0008 37.5392 79.4456 36.9602C79.9001 36.3812 80.1225 35.6864 80.1225 34.8565C80.1225 33.9493 79.9194 33.2352 79.5036 32.7238C79.0878 32.2123 78.5173 31.9517 77.7824 31.9517ZM76.7961 26.924C77.0379 26.924 77.2409 27.0109 77.415 27.1846C77.5891 27.3583 77.6761 27.5609 77.6761 27.8022C77.6761 28.0531 77.5891 28.2654 77.415 28.4391C77.2409 28.6128 77.0379 28.6996 76.7961 28.6996C76.5544 28.6996 76.3513 28.6128 76.1773 28.4391C76.0032 28.2654 75.9162 28.0531 75.9162 27.8022C75.9162 27.5609 76.0032 27.3583 76.1773 27.1846C76.3513 27.0109 76.5544 26.924 76.7961 26.924ZM78.9718 26.924C79.2232 26.924 79.4359 27.0109 79.61 27.1846C79.7841 27.3583 79.8711 27.5609 79.8711 27.8022C79.8711 28.0531 79.7841 28.2654 79.61 28.4391C79.4359 28.6128 79.2232 28.6996 78.9718 28.6996C78.7301 28.6996 78.527 28.6128 78.353 28.4391C78.1789 28.2654 78.0919 28.0531 78.0919 27.8022C78.0919 27.5609 78.1789 27.3583 78.353 27.1846C78.527 27.0109 78.7301 26.924 78.9718 26.924Z"
              fill="#6085B3"
            />
            <path d="M85.3054 37.8673H81.979V39.1411H85.3054V37.8673Z" fill="#6085B3" />
            <path d="M88.5061 37.8673H85.1798V39.1411H88.5061V37.8673Z" fill="#6085B3" />
            <path
              d="M92.3255 37.8674C91.5423 37.0664 91.1555 36.0338 91.1555 34.7793C91.1555 33.4379 91.6003 32.386 92.4899 31.614C93.2345 30.9771 94.1918 30.6586 95.3714 30.6586C96.0096 30.6586 96.6768 30.7648 97.3731 30.9675V32.1737C96.6672 31.9904 96.0483 31.9035 95.5165 31.9035C94.6269 31.9035 93.9403 32.1737 93.4375 32.7238C92.9444 33.2642 92.693 34.0169 92.693 34.9819C92.693 35.7636 92.9541 36.4488 93.4859 37.0471C93.979 37.5971 94.5495 37.877 95.1974 37.877H98.8332V38.3788L98.2046 39.1508H88.3803V37.877H92.3255V37.8674Z"
              fill="#6085B3"
            />
          </>
        )}
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="98.8235" height="56" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
