import React from 'react'
import { useField } from 'formik'

import Number from '../../Number'

import { FormNumberProps } from './FormNumber.types'

export const FormNumber = (props: FormNumberProps) => {
  const [field, meta] = useField(props)
  return <Number {...props} {...field} {...meta} error={meta.touched ? meta.error : ''} />
}
