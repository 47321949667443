import styled from 'styled-components'

import Box from '../../ui/Box'

const BUTTON_WIDTH = 208

export const StyledWrapper = styled(Box).attrs({
  mt: 2,
  pt: 2.5,
  pb: 2.5,
  pl: 3,
  pr: 3,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
})`
  border: 1px solid ${({ theme }) => theme.palette.grey};
  border-radius: 4px;

  ${({ theme }) => `@media ${theme.devices.mobile} {
    flex-direction: column;
  }`}
`

export const StyledButtonWrapper = styled(Box).attrs({
  width: BUTTON_WIDTH,
})``

export const StyledDescription = styled.div`
  ${({ theme }) => `@media ${theme.devices.mobile} {
    margin-bottom: 16px;
    text-align: center;
  }`}
`
