import React, { useEffect, useRef } from 'react'
import { FormikProps } from 'formik'
import { useDispatch, useSelector } from 'react-redux'

import { RenameDocumentFields, RenameDocumentBody } from '../../../../../types/renameDocument'
import { CONTRACTS_MODALS, CONTRACT_EXISTS } from '../../../../../constants/contracts'
import { DOCUMENT_WITH_NAME_EXIST } from '../../../../../constants/documents'
import { modalsPayloadSelector } from '../../../../../store/modals/modals.selectors'
import { useShowFetchError } from '../../../../hooks/useShowFetchError'
import { useDispatchUnmount } from '../../../../../hooks/useDispatchUnmount'
import RenameDocumentModal from '../../DocumentModals/RenameDocumentModal'
import {
  contractsListRenameErrorSelector,
  contractsListRenameFetchStatusSelector,
  contractsListRenameSuccessSelector,
} from '../../../../../store/contracts/contractsList/contractsList.selectors'
import {
  clearRenameContract,
  fetchRenameContract,
} from '../../../../../store/contracts/contractsList/contractsList.actions'
import { FOLDER_ALREADY_EXIST } from '../../../../../constants/folders'

import { RenameContractModalProps } from './RenameContractModal.types'

const EXCLUDE_CODES = [DOCUMENT_WITH_NAME_EXIST, FOLDER_ALREADY_EXIST]

export const RenameContractModal: React.FC<RenameContractModalProps> = ({ onClose }) => {
  const formRef: React.RefObject<FormikProps<RenameDocumentBody>> = useRef(null)
  const dispatch = useDispatch()
  const { title, id, oldName, isFolder } = useSelector(modalsPayloadSelector(CONTRACTS_MODALS.RENAME_CONTRACT))
  const renamedSuccessfully = useSelector(contractsListRenameSuccessSelector)
  const fetchStatus = useSelector(contractsListRenameFetchStatusSelector)
  const error = useSelector(contractsListRenameErrorSelector)

  useEffect(() => {
    if (renamedSuccessfully) {
      onClose()
    }
  }, [renamedSuccessfully])
  useEffect(() => {
    const form = formRef.current

    if (form) {
      if (error?.lastErrorCode === DOCUMENT_WITH_NAME_EXIST) {
        form.setFieldError(RenameDocumentFields.name, CONTRACT_EXISTS)
      } else if (error?.lastErrorCode === FOLDER_ALREADY_EXIST) {
        form.setFieldError(RenameDocumentFields.name, FOLDER_ALREADY_EXIST)
      }
    }
  }, [error])
  useShowFetchError(fetchStatus, error, EXCLUDE_CODES)
  useDispatchUnmount(clearRenameContract)

  const onSubmit = (values: RenameDocumentBody) => {
    dispatch(fetchRenameContract(id, values.name, isFolder))
  }

  return <RenameDocumentModal onClose={onClose} title={title} oldName={oldName} onSubmit={onSubmit} ref={formRef} />
}
