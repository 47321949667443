import React, { useCallback, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FormikProps } from 'formik'

import { location } from '../../../../../utils/location'
import { UploadDocumentFields, UploadDocumentForm } from '../../../../../types/uploadDocument'
import { TEMPLATES_MODALS } from '../../../../../constants/templates'
import { openModal } from '../../../../../store/modals/modals.actions'
import { fetchUploadTemplate, cleanUploadTemplate } from '../../../../../store/templates/upload/upload.actions'
import * as SELECTORS from '../../../../../store/templates/upload/upload.selectors'
import { modalsPayloadSelector } from '../../../../../store/modals/modals.selectors'
import { useShowFetchError } from '../../../../hooks/useShowFetchError'
import { useTemplatesFoldersFetch } from '../../../../../hooks/templates/useTemplatesFoldersFetch'
import { useHistoryPushAfterSuccess } from '../../../../hooks/useHistoryPushAfterSuccess'
import { useDispatchUnmount } from '../../../../../hooks/useDispatchUnmount'
import * as FOLDERS_SELECTORS from '../../../../../store/templates/folders/folders.selectors'
import UploadDocumentToModal from '../../DocumentModals/UploadDocumentToModal'
import { setSuccessMessage } from '../../../../../store/templates/preview/preview.actions'
import { SuccessMessages } from '../../../../../store/templates/preview/preview.types'

import { UploadTemplateToModalProps } from './UploadTemplateToModal.types'

export const UploadTemplateToModal: React.FC<UploadTemplateToModalProps> = ({ onClose }) => {
  const dispatch = useDispatch()
  const formRef: React.RefObject<FormikProps<UploadDocumentForm>> = useRef(null)
  const templateId = useSelector(SELECTORS.uploadIdSelector)
  const foldersIsLoading = useSelector(FOLDERS_SELECTORS.templatesFoldersIsLoadingSelector)
  const folders = useSelector(FOLDERS_SELECTORS.templatesFoldersDataSelector)
  const modalPayload = useSelector(modalsPayloadSelector(TEMPLATES_MODALS.UPLOAD_TEMPLATE_TO))
  const uploadFetchStatus = useSelector(SELECTORS.uploadFetchStatusSelector)
  const uploadError = useSelector(SELECTORS.uploadErrorSelector)
  const lastAddedFolder = useSelector(FOLDERS_SELECTORS.templatesLastAddedFolderSelector)
  const uploadIsRequest = useSelector(SELECTORS.uploadIsRequestSelector)
  const uploadIsSuccess = useSelector(SELECTORS.uploadIsSuccessSelector)

  const showSuccessMessage = useCallback(() => setSuccessMessage(SuccessMessages.DOCUMENT_UPLOADED_SUCCESS), [])

  useEffect(() => {
    const form = formRef.current

    if (lastAddedFolder && form) {
      form.setFieldValue(UploadDocumentFields.folderName, lastAddedFolder.value)
    }
  }, [lastAddedFolder])
  useEffect(() => {
    if (uploadIsSuccess) {
      onClose()
    }
  }, [uploadIsSuccess])

  useHistoryPushAfterSuccess(location.templatesPreviewUrl(templateId), uploadFetchStatus, showSuccessMessage)
  useTemplatesFoldersFetch()
  useDispatchUnmount(cleanUploadTemplate)
  useShowFetchError(uploadFetchStatus, uploadError)

  const onSubmit = (values: UploadDocumentForm) => {
    const folderName = folders.find((folder) => folder.value === values.folderName)?.name || values.folderName

    dispatch(
      fetchUploadTemplate({
        ...values,
        file: modalPayload.file,
        folderName,
      })
    )
  }
  const onCreateFolder = () => {
    dispatch(openModal(TEMPLATES_MODALS.CREATE_FOLDER_FOR_TEMPLATE))
  }

  return (
    <UploadDocumentToModal
      onClose={onClose}
      fileName={modalPayload.fileName}
      folders={folders}
      foldersIsLoading={foldersIsLoading}
      onCreateFolder={onCreateFolder}
      onSubmit={onSubmit}
      uploading={uploadIsRequest}
      ref={formRef}
    />
  )
}
