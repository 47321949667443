import { EditProfileFormBody, EditProfileInitialDataResponse } from '../../types/editProfile'
import { FetchFailureAction, FetchFailurePayload } from '../../types/fetch'
import { createFetchActionTypes } from '../../utils/createFetchActionTypes'

import * as TYPES from './editProfile.types'

export const fetchEditProfileChangePhoneTypes = createFetchActionTypes(
  TYPES.EditProfileActions.FETCH_EDIT_PROFILE_CHANGE_PHONE
)
export const fetchEditProfileConfirmPhoneTypes = createFetchActionTypes(
  TYPES.EditProfileActions.FETCH_EDIT_PROFILE_CONFIRM_PHONE
)
export const fetchEditProfileChangeEmailTypes = createFetchActionTypes(
  TYPES.EditProfileActions.FETCH_EDIT_PROFILE_CHANGE_EMAIL
)
export const fetchEditProfileTypes = createFetchActionTypes(TYPES.EditProfileActions.FETCH_EDIT_PROFILE)
export const fetchEditProfileResendVerificationTypes = createFetchActionTypes(
  TYPES.EditProfileActions.FETCH_EDIT_PROFILE_RESEND_EMAIL_RESET
)
export const fetchEditProfileUnconfirmedEmailTypes = createFetchActionTypes(
  TYPES.EditProfileActions.FETCH_EDIT_PROFILE_UNCONFIRMED_EMAIL
)
export const fetchEditProfilePreventEmailUpdateTypes = createFetchActionTypes(
  TYPES.EditProfileActions.FETCH_EDIT_PROFILE_PREVENT_EMAIL_UPDATE
)
export const fetchEditProfileInitialDataTypes = createFetchActionTypes(
  TYPES.EditProfileActions.FETCH_EDIT_PROFILE_INITIAL_DATA
)

export const fetchEditProfileChangePhone = (phone: string): TYPES.EditProfileChangePhoneAction => ({
  type: fetchEditProfileChangePhoneTypes.request,
  payload: { phone },
})

export const fetchEditProfileChangePhoneSuccess = (phone: string): TYPES.EditProfileChangePhoneSuccessAction => ({
  type: fetchEditProfileChangePhoneTypes.success,
  payload: { phone },
})

export const fetchEditProfileChangePhoneFailure = (payload: FetchFailurePayload): FetchFailureAction => ({
  type: fetchEditProfileChangePhoneTypes.failure,
  payload,
})

export const resetEditProfileChangePhone = () => ({
  type: TYPES.EditProfileActions.RESET_EDIT_PROFILE_PHONE_FETCH_STATUS,
})

export const fetchEditProfileConfirmPhone = (verificationCode: string): TYPES.EditProfileConfirmPhoneAction => ({
  type: fetchEditProfileConfirmPhoneTypes.request,
  payload: { verificationCode },
})

export const fetchEditProfileConfirmPhoneSuccess = (phone: string): TYPES.EditProfileConfirmPhoneSuccessAction => ({
  type: fetchEditProfileConfirmPhoneTypes.success,
  payload: { phone },
})

export const fetchEditProfileConfirmPhoneFailure = (payload: FetchFailurePayload): FetchFailureAction => ({
  type: fetchEditProfileConfirmPhoneTypes.failure,
  payload,
})

export const resetEditProfilePhoneData = () => ({
  type: TYPES.EditProfileActions.RESET_EDIT_PROFILE_PHONE_DATA,
})

export const fetchEditProfileChangeEmail = (email: string): TYPES.EditProfileChangeEmailAction => ({
  type: fetchEditProfileChangeEmailTypes.request,
  payload: { email },
})

export const fetchEditProfileChangeEmailSuccess = (newEmail: string): TYPES.EditProfileChangeEmailSuccessAction => ({
  type: fetchEditProfileChangeEmailTypes.success,
  payload: { newEmail },
})

export const fetchEditProfileChangeEmailFailure = (payload: FetchFailurePayload): FetchFailureAction => ({
  type: fetchEditProfileChangeEmailTypes.failure,
  payload,
})

export const resetEditProfileEmailData = () => ({
  type: TYPES.EditProfileActions.RESET_EDIT_PROFILE_EMAIL_DATA,
})

export const fetchEditProfile = (body: EditProfileFormBody): TYPES.EditProfileAction => ({
  type: fetchEditProfileTypes.request,
  payload: { body },
})

export const fetchEditProfileSuccess = (): TYPES.EditProfileSuccessAction => ({
  type: fetchEditProfileTypes.success,
})

export const fetchEditProfileFailure = (payload: FetchFailurePayload): FetchFailureAction => ({
  type: fetchEditProfileTypes.failure,
  payload,
})

export const resetEditProfile = () => ({
  type: TYPES.EditProfileActions.RESET_EDIT_PROFILE,
})

export const fetchEditProfileResendVerification = (): TYPES.EditProfileResendVerificationAction => ({
  type: fetchEditProfileResendVerificationTypes.request,
})

export const fetchEditProfileResendVerificationSuccess = (): TYPES.EditProfileResendVerificationSuccessAction => ({
  type: fetchEditProfileResendVerificationTypes.success,
})

export const fetchEditProfileResendVerificationFailure = (payload: FetchFailurePayload): FetchFailureAction => ({
  type: fetchEditProfileResendVerificationTypes.failure,
  payload,
})

export const fetchEditProfileUnconfirmedEmail = (): TYPES.EditProfileUnconfirmedEmailAction => ({
  type: fetchEditProfileUnconfirmedEmailTypes.request,
})

export const fetchEditProfileUnconfirmedEmailSuccess = (
  newEmail?: string
): TYPES.EditProfileUnconfirmedEmailSuccessAction => ({
  type: fetchEditProfileUnconfirmedEmailTypes.success,
  payload: { newEmail },
})

export const fetchEditProfileUnconfirmedEmailFailure = (payload: FetchFailurePayload): FetchFailureAction => ({
  type: fetchEditProfileUnconfirmedEmailTypes.failure,
  payload,
})

export const fetchEditProfilePreventEmailUpdate = (): TYPES.EditProfilePreventEmailUpdateAction => ({
  type: fetchEditProfilePreventEmailUpdateTypes.request,
})

export const fetchEditProfilePreventEmailUpdateSuccess = (): TYPES.EditProfilePreventEmailUpdateSuccessAction => ({
  type: fetchEditProfilePreventEmailUpdateTypes.success,
})

export const fetchEditProfilePreventEmailUpdateFailure = (payload: FetchFailurePayload): FetchFailureAction => ({
  type: fetchEditProfilePreventEmailUpdateTypes.failure,
  payload,
})

export const fetchEditProfileInitialData = (): TYPES.EditProfileInitialDataAction => ({
  type: fetchEditProfileInitialDataTypes.request,
})

export const fetchEditProfileInitialDataSuccess = (
  body: EditProfileInitialDataResponse
): TYPES.EditProfileInitialDataActionSuccessAction => ({
  type: fetchEditProfileInitialDataTypes.success,
  payload: { body },
})

export const fetchEditProfileInitialDataFailure = (payload: FetchFailurePayload): FetchFailureAction => ({
  type: fetchEditProfileInitialDataTypes.failure,
  payload,
})
