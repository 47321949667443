import { CountryPayload } from '../../types/countries'
import { Phones } from '../../types/phones'
import { Ajax } from '../ajax'

class LocaleService extends Ajax {
  pathname = 'locale'

  fetchCountries = () => this.getJSON<CountryPayload[]>(`${this.pathname}/countries`)

  fetchPhones = () => this.getJSON<Phones>(`${this.pathname}/phonecode`)

  fetchIndustries = () => this.getJSON<string[]>(`${this.pathname}/industries`)
}

export const localeService = new LocaleService()
