import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

export const useDispatchUnmount = (...actions: Array<() => void>) => {
  const dispatch = useDispatch()

  useEffect(
    () => () => {
      actions.forEach((action) => {
        dispatch(action())
      })
    },
    []
  )
}
