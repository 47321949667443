import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router'

import { setRecipientToken } from '../../../../store/contracts/signing/signing.actions'
import { location } from '../../../../utils/location'

export const useRecipientToken = () => {
  const dispatch = useDispatch()
  const search = useLocation().search
  const params = new URLSearchParams(search)
  const recipientToken = params.get(location.queryParams.RECIPIENT_TOKEN)

  useEffect(() => {
    dispatch(setRecipientToken(recipientToken || ''))
  }, [recipientToken])
}
