import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { FormErrorProps } from './FormError.types'

const StyledError = styled.div`
  height: 21px;
  margin: 5px 0;
  color: ${(props) => props.theme.palette.errorColor};
`

export const FormError: React.FC<FormErrorProps> = ({ error }) => {
  const errorMessage = error ? error.errorMessage : ''
  const { t } = useTranslation()
  return <StyledError>{t(errorMessage)}</StyledError>
}
