import { FileForFormData } from '../../types/formData'
import { convertToFormData } from '../../utils/convertToFormData/convertToFormData.native'
import { Ajax } from '../ajax'

class EKYCService extends Ajax {
  pathname = 'kyc'

  fetchEnroll = (fileForFormData: { [key: string]: FileForFormData }, headers: { [key: string]: string }) =>
    this.post(`${this.pathname}/enroll_matched`, convertToFormData(fileForFormData), headers)

  fetchVerification = (fileForFormData: { [key: string]: FileForFormData }, headers: { [key: string]: string }) =>
    this.post(`${this.pathname}/verify`, convertToFormData(fileForFormData), headers)

  fetchEnrollStatus = () => this.getJSON(`${this.pathname}`)
}

export const ekycService = new EKYCService()
