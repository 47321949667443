import React from 'react'
import ContentLoader from 'react-content-loader'

import { blueExtraLight, glowColor } from '../../../../../themes/colors'

import { SkeletonProps } from './Skeleton.types'

const FILL_COLOR = blueExtraLight
const GLOW_COLOR = glowColor
const SPEED = 1.5
const RADIUS = 3

const FULL_PAGE = 100
const PAGE_OFFSET = 6

const INITIALS_HEIGHT = 4
const INITIALS_BOTTOM_OFFSET = 2
const INITIALS_PAGE_LIMIT = 80

const TEXT_HEIGHT = 2
const TEXT_RADIUS = 15
const TEXT_OFFSET = 1
const TEXT_PARAGRAPH_OFFSET = 2
const TEXT_PAGE_LIMIT = 70
const TEXT_WITH_OFFSET = TEXT_HEIGHT + TEXT_OFFSET
const TEXT_LINES_WIDTHS = [70, 65, 65, 60]

const SIGNATURE_RIGHT_OFFSET = 2
const SIGNATURES_COUNT = 4

export const Skeleton: React.FC<SkeletonProps> = ({ pageHeight }) => {
  const renderInitials = () => {
    const yPositions: number[] = []
    let generation = true

    const getY = (length: number) => length * INITIALS_HEIGHT + length * INITIALS_BOTTOM_OFFSET + PAGE_OFFSET

    while (generation) {
      yPositions.push(getY(yPositions.length))

      if (getY(yPositions.length) >= INITIALS_PAGE_LIMIT) {
        generation = false
      }
    }

    return yPositions.map((y) => (
      <rect key={y} width="8%" height={`${INITIALS_HEIGHT}%`} x="90%" y={`${y}%`} rx={RADIUS} ry={RADIUS} />
    ))
  }

  const renderSignatures = () => {
    let count = SIGNATURES_COUNT
    const xPositions: number[] = []
    const drawArea = FULL_PAGE - count * SIGNATURE_RIGHT_OFFSET - PAGE_OFFSET
    const signatureWidth = drawArea / count - SIGNATURE_RIGHT_OFFSET

    while (count) {
      const offset = SIGNATURE_RIGHT_OFFSET + PAGE_OFFSET

      xPositions.push(offset + (count - 1) * (signatureWidth + SIGNATURE_RIGHT_OFFSET))

      --count
    }

    return xPositions.map((x) => (
      <rect key={x} width={`${signatureWidth}%`} height="4%" x={`${x}%`} y="90%" rx={RADIUS} ry={RADIUS} />
    ))
  }

  const renderContent = () => {
    const paragraphHeight = TEXT_LINES_WIDTHS.length * TEXT_WITH_OFFSET
    const yPositions: number[] = []
    let generation = true

    const getY = (positionsLength: number) =>
      positionsLength * paragraphHeight + positionsLength * TEXT_PARAGRAPH_OFFSET + PAGE_OFFSET

    while (generation) {
      yPositions.push(getY(yPositions.length))

      if (getY(yPositions.length) >= TEXT_PAGE_LIMIT) {
        generation = false
      }
    }

    return yPositions.map((y) => (
      <React.Fragment key={y}>
        {TEXT_LINES_WIDTHS.map((width, index) => (
          <rect
            key={index}
            width={`${width}%`}
            height={`${TEXT_HEIGHT}%`}
            x="8%"
            y={`${y + index * TEXT_WITH_OFFSET}%`}
            rx={TEXT_RADIUS}
            ry={TEXT_RADIUS}
          />
        ))}
      </React.Fragment>
    ))
  }

  return (
    <ContentLoader
      display="block"
      width={`${FULL_PAGE}%`}
      height={pageHeight}
      speed={SPEED}
      backgroundColor={FILL_COLOR}
      foregroundColor={GLOW_COLOR}
    >
      {renderInitials()}
      {renderContent()}
      {renderSignatures()}
    </ContentLoader>
  )
}
