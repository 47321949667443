import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FormikValues } from 'formik'
import { useTranslation } from 'react-i18next'

import {
  clearSuccessMessage,
  fetchDeleteContact,
  fetchUpdateContact,
  setOffset,
  setSearchText,
  setSorting,
} from '../../../store/contacts/contacts.actions'
import * as SELECTORS from '../../../store/contacts/contacts.selectors'
import { openModal } from '../../../store/modals/modals.actions'
import translations from '../../../translations/keys'
import { updateContactValidationSchema } from '../../../validation/updateContact/updateContactValidaitonSchema'
import { Sorting } from '../../../types/table'
import { UpdateContactsForm } from '../../../types/updateContact'
import { FETCH_STATUSES } from '../../../types/fetch'
import { columns } from '../../../constants/contacts'
import { CONTACTS_MODALS } from '../../constants/contacts'
import { useShowFetchError } from '../../hooks/useShowFetchError'
import Box from '../../ui/Box'
import Table from '../../ui/Table'
import Pagination from '../../ui/Pagination'
import Search from '../../ui/Search'
import Button from '../../ui/Button'
import NotificationBadge from '../../ui/NotificationBadge'
import { ContactsSkeleton } from '../../components/Skeletons/ContactsSkeleton'
import { ContactsIcon } from '../../assets/ContactsIcon'
import { useContactsFetch } from '../../../hooks/contacts/useContactsFetch'

import { ContactsModals } from './components/ContactsModals/ContactsModals'
import * as Styled from './Contacts.styles'

export const Contacts = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [showNotificationBadge, setShowNotificationBadge] = useState(false)
  const [contactToDelete, setContactToDelete] = useState<string | null>(null)
  const contacts = useSelector(SELECTORS.contactsSortedSelector)
  const sorting = useSelector(SELECTORS.contactsSortingSelector)
  const searchText = useSelector(SELECTORS.contactsSearchTextSelector)
  const offset = useSelector(SELECTORS.contactsOffsetSelector)
  const total = useSelector(SELECTORS.contactsTotalSelector)
  const limit = useSelector(SELECTORS.contactsLimitSelector)
  const isFirstLoad = useSelector(SELECTORS.contactsIsFirstLoadSelector)
  const loading = useSelector(SELECTORS.contactsLoadingSelector)
  const fetchStatus = useSelector(SELECTORS.contactsFetchStatusSelector)
  const successMessage = useSelector(SELECTORS.contactsSuccessMessageSelector)
  const error = useSelector(SELECTORS.contactsErrorSelector)
  const isEmpty = contacts.length === 0
  const showSkeleton = isEmpty && loading && isFirstLoad
  const showPlaceholder = isEmpty && !searchText && fetchStatus !== FETCH_STATUSES.REQUEST
  const showTable = !showSkeleton && !showPlaceholder

  const handleNotificationBadgeClose = () => {
    setShowNotificationBadge(false)
    dispatch(clearSuccessMessage())
  }

  const handleSearchTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setSearchText(e.target.value))
  }

  const handleSearchTextClear = () => {
    dispatch(setSearchText(''))
  }

  const handleCreateContact = () => {
    dispatch(openModal(CONTACTS_MODALS.CONTACTS_ADD_CONTACT))
  }

  const handleDeleteContact = (id: string) => {
    setContactToDelete(id)
    dispatch(openModal(CONTACTS_MODALS.CONTACTS_DELETE_CONTACT))
  }

  const handleConfirmDelete = () => {
    if (contactToDelete) {
      dispatch(fetchDeleteContact(contactToDelete))
      setContactToDelete(null)
    }
  }

  const handlePageChange = (page: number) => {
    dispatch(setOffset(page))
  }

  const handleSorting = (sorting: Sorting) => {
    dispatch(setSorting(sorting))
  }

  const handleUpdateContact = (id: string, values: FormikValues) => {
    dispatch(fetchUpdateContact(id, values as UpdateContactsForm))
  }

  useEffect(() => {
    if (successMessage) {
      setShowNotificationBadge(true)
    }
  }, [successMessage])

  useContactsFetch()
  useShowFetchError(fetchStatus, error)

  return (
    <>
      <Styled.Container data-testid="contacts-container">
        {showSkeleton && <ContactsSkeleton />}
        {showPlaceholder && (
          <Styled.Placeholder data-testid="contacts-placeholder">
            <Box textAlign="center" mb={5}>
              <ContactsIcon />
            </Box>
            <Box mb={4}>{t(translations.CONTACTS_PLACEHOLDER_TEXT)}</Box>
            <Styled.PlaceholderBtn>
              <Button fullWidth onClick={handleCreateContact}>
                {t(translations.ADD_CONTACT)}
              </Button>
            </Styled.PlaceholderBtn>
          </Styled.Placeholder>
        )}
        {showTable && (
          <>
            <Styled.Header data-testid="contacts-header">
              <Styled.HeaderSearchWrapper data-testid="contacts-search-wrapper">
                <Search
                  data-testid="contacts-search"
                  placeholder={t(translations.SEARCH_CONTACT)}
                  value={searchText}
                  onChange={handleSearchTextChange}
                  onClickIcon={handleSearchTextClear}
                />
              </Styled.HeaderSearchWrapper>
              <Styled.HeaderBtnWrapper>
                <Button fullWidth onClick={handleCreateContact}>
                  {t(translations.ADD_CONTACT)}
                </Button>
              </Styled.HeaderBtnWrapper>
            </Styled.Header>
            <Table
              data-testid="contacts-table"
              columns={columns}
              data={contacts}
              validationSchema={updateContactValidationSchema}
              sorting={sorting}
              highlightedText={searchText}
              onSort={handleSorting}
              onUpdate={handleUpdateContact}
              onDelete={handleDeleteContact}
              emptyResultsMessage={t(translations.CONTACTS_EMPTY_RESULTS)}
            />
            <Styled.PaginationContainer>
              <Pagination
                data-testid="contacts-pagination"
                total={total}
                offset={offset}
                limit={limit}
                onChange={handlePageChange}
              />
            </Styled.PaginationContainer>
          </>
        )}
        {showNotificationBadge && (
          <Styled.MessageContainer data-testid="contacts-message-container">
            <NotificationBadge
              isShow
              isAutoClose
              message={t(translations[successMessage])}
              onClose={handleNotificationBadgeClose}
            />
          </Styled.MessageContainer>
        )}
        <ContactsModals onConfirmDelete={handleConfirmDelete} />
      </Styled.Container>
    </>
  )
}
