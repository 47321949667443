import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import * as ACTIONS from '../../store/contracts/signing/signing.actions'
import * as VERIFICATION_SELECTORS from '../../store/contracts/verification/verification.selectors'
import SessionStorage from '../../utils/SessionStorage'
import { SessionStorageKeys } from '../../constants/sessionStorage'

import { useCommonSigningFetch } from './useCommonSigningFetch'

export const useSigningFetch = (id: string) => {
  const dispatch = useDispatch()
  const isVerified = useSelector(VERIFICATION_SELECTORS.contractsVerificationVerified)

  useEffect(() => {
    if (isVerified) {
      dispatch(ACTIONS.fetchDefaultSign())
    }
  }, [isVerified])
  useEffect(
    () => () => {
      SessionStorage.remove(SessionStorageKeys.VERIFICATION_TOKEN)
    },
    []
  )
  useCommonSigningFetch(id)
}
