import React, { useEffect, useRef } from 'react'
import { FormikProps } from 'formik'
import { useDispatch, useSelector } from 'react-redux'

import { RenameDocumentFields, RenameDocumentBody } from '../../../../../types/renameDocument'
import { TEMPLATE_EXISTS, TEMPLATES_MODALS } from '../../../../../constants/templates'
import { DOCUMENT_WITH_NAME_EXIST } from '../../../../../constants/documents'
import { modalsPayloadSelector } from '../../../../../store/modals/modals.selectors'
import { useShowFetchError } from '../../../../hooks/useShowFetchError'
import { useDispatchUnmount } from '../../../../../hooks/useDispatchUnmount'
import RenameDocumentModal from '../../DocumentModals/RenameDocumentModal'
import {
  templatesRenameErrorSelector,
  templatesRenameFetchStatusSelector,
  templatesRenameSuccessSelector,
} from '../../../../../store/templates/rename/rename.selectors'
import { clearRenameTemplate, fetchRenameTemplate } from '../../../../../store/templates/rename/rename.actions'
import { FOLDER_ALREADY_EXIST } from '../../../../../constants/folders'

import { RenameTemplateModalProps } from './RenameTemplateModal.types'

const EXCLUDE_CODES = [DOCUMENT_WITH_NAME_EXIST, FOLDER_ALREADY_EXIST]

export const RenameTemplateModal: React.FC<RenameTemplateModalProps> = ({ onClose }) => {
  const formRef: React.RefObject<FormikProps<RenameDocumentBody>> = useRef(null)
  const dispatch = useDispatch()
  const { title, id, oldName, isFolder } = useSelector(modalsPayloadSelector(TEMPLATES_MODALS.RENAME_TEMPLATE))
  const renamedSuccessfully = useSelector(templatesRenameSuccessSelector)
  const fetchStatus = useSelector(templatesRenameFetchStatusSelector)
  const error = useSelector(templatesRenameErrorSelector)

  useEffect(() => {
    if (renamedSuccessfully) {
      onClose()
    }
  }, [renamedSuccessfully])
  useEffect(() => {
    const form = formRef.current

    if (form) {
      if (error?.lastErrorCode === DOCUMENT_WITH_NAME_EXIST) {
        form.setFieldError(RenameDocumentFields.name, TEMPLATE_EXISTS)
      } else if (error?.lastErrorCode === FOLDER_ALREADY_EXIST) {
        form.setFieldError(RenameDocumentFields.name, FOLDER_ALREADY_EXIST)
      }
    }
  }, [error])
  useShowFetchError(fetchStatus, error, EXCLUDE_CODES)
  useDispatchUnmount(clearRenameTemplate)

  const onSubmit = (values: RenameDocumentBody) => {
    dispatch(fetchRenameTemplate(id, values.name, isFolder))
  }

  return <RenameDocumentModal onClose={onClose} title={title} oldName={oldName} onSubmit={onSubmit} ref={formRef} />
}
