import * as yup from 'yup'

const NAME_MIN_LENGTH = 1
export const NAME_MAX_LENGTH = 100
const nameRegExp = new RegExp(
  `^[a-zA-Z-ʼ\u0600-\u065F\u066A-\u06EF\u06FA-\u06FF\` \s]{${NAME_MIN_LENGTH},${NAME_MAX_LENGTH}}$`
)

export const nameValidationSchema = yup
  .string()
  .trim()
  .required('REQUIRED_FIELD')
  .matches(nameRegExp, 'INCORRECT_DATA_ENTERED')
