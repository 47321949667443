import { FetchFailureAction, FetchFailurePayload } from '../../types/fetch'
import { createFetchActionTypes } from '../../utils/createFetchActionTypes'

import * as TYPES from './forgotPassword.types'

export const fetchForgotPasswordTypes = createFetchActionTypes(TYPES.ForgotPasswordActions.FETCH_FORGOT_PASSWORD)
export const fetchResendVerificationTypes = createFetchActionTypes(
  TYPES.ForgotPasswordActions.FETCH_RESEND_NOTIFICATION
)

export const fetchForgotPassword = (email: string): TYPES.FetchForgotPasswordAction => ({
  type: fetchForgotPasswordTypes.request,
  payload: { email },
})

export const fetchForgotPasswordSuccess = (
  email: string,
  message?: string
): TYPES.FetchForgotPasswordSuccessAction => ({
  type: fetchForgotPasswordTypes.success,
  payload: { email, message },
})

export const fetchForgotPasswordFailure = (payload: FetchFailurePayload): FetchFailureAction => ({
  type: fetchForgotPasswordTypes.failure,
  payload,
})

export const fetchResendVerification = (): TYPES.FetchResendVerificationAction => ({
  type: fetchResendVerificationTypes.request,
})

export const fetchResendVerificationSuccess = (): TYPES.FetchResendVerificationSuccessAction => ({
  type: fetchResendVerificationTypes.success,
})

export const fetchResendVerificationFailure = (payload: FetchFailurePayload): FetchFailureAction => ({
  type: fetchResendVerificationTypes.failure,
  payload,
})

export const resetForgotPassword = () => ({
  type: TYPES.ForgotPasswordActions.RESET_FORGOT_PASSWORD,
})
