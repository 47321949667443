import React from 'react'

import AuthorizationTemplate from '../../components/Authorization/AuthorizationTemplate'

import { ConfirmEmail as ConfirmEmailPage } from './ConfirmEmail'

export const ConfirmEmail = () => (
  <AuthorizationTemplate>
    <ConfirmEmailPage />
  </AuthorizationTemplate>
)
