import React from 'react'
import { useTranslation } from 'react-i18next'

import translations from '../../../translations/keys'
import { CrossIcon } from '../../assets/icons'
import Box from '../Box'
import Button from '../Button'
import Typography from '../Typography'
import { TYPOGRAPHY_COMPONENTS, TYPOGRAPHY_NAMES } from '../Typography/Typography.types'

import { CloseButton, Logo, LogoWrapper, StyledTextWrapper, StyledWrapper } from './OpenInAppBanner.styles'
import { OpenInAppBannerProps } from './OpenInAppBanner.types'

export const OpenInAppBanner: React.FC<OpenInAppBannerProps> = ({
  children,
  isBannerVisible,
  isMobileScreen,
  handleMenuClose,
  handleNavigate,
}) => {
  const { t } = useTranslation()
  return (
    <>
      {isBannerVisible && isMobileScreen && (
        <StyledWrapper>
          <CloseButton onClick={handleMenuClose}>
            <CrossIcon color="grey" size={'extraSmall'} />
          </CloseButton>
          <LogoWrapper data-testid="header-logo-wrapper">
            <Logo withText={false} viewBox="0 0 50 56" />
          </LogoWrapper>
          <StyledTextWrapper>
            <Typography name={TYPOGRAPHY_NAMES.bodySmall6} component={TYPOGRAPHY_COMPONENTS.span}>
              {t(translations.UQOUD)}
            </Typography>
            <Typography name={TYPOGRAPHY_NAMES.bodySmall6} component={TYPOGRAPHY_COMPONENTS.span}>
              {t(translations.OPEN_IN_UQOUD_APP)}
            </Typography>
          </StyledTextWrapper>
          <Box display="flex" justifyContent="flex-end" width="100%">
            <Button onClick={handleNavigate} compact>
              {t(translations.OPEN_IN_UQOUD_APP_BUTTON)}
            </Button>
          </Box>
        </StyledWrapper>
      )}
      {children}
    </>
  )
}
