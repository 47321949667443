import isAfter from 'date-fns/isAfter'
import isBefore from 'date-fns/isBefore'

import translations from '../../translations'
import omit from '../omit'
import { MAX_SIGNATURES_PER_PAGE, MAX_INITIALS_PER_PAGE } from '../../constants/recipients'
import { Contact } from '../../types/contacts'
import { SIGNING_TYPES } from '../../types/signingOrder'
import { EditProfileInitialDataResponse } from '../../types/editProfile'
import * as TYPES from '../../types/recipients'
import { SubscriptionFeatures } from '../../types/subscription'
import { accessFeature } from '../subscription/accessFeature'
import { getFromToISODate, parseISOwithTZ } from '../date/date'
import { DocumentPreviewResponse } from '../../types/documentPreview'
import { documentMessageInitialValues } from '../../initialValues/documentMessageInitialValues'
import { DocumentMessageForm } from '../../types/documents'
import { AddRecipientsFields, AddRecipientsForm, RecipientAuthentication, RecipientData } from '../../types/recipients'
import { addPhonePlus } from '../phone/addPhonePlus'

const validateDeadline = (recipients: TYPES.RecipientData[]) => {
  const deadlines: { [order: string]: { min: number | Date; max: number | Date } } = {}
  const cyclesWithDeadline = new Set<string>()

  for (let i = 0; i < recipients.length; i += 1) {
    const recipient = recipients[i]

    const deadline = recipient.deadline as Date
    const prevMin = deadlines[recipient.cycleOrder]?.min || deadline
    const prevMax = deadlines[recipient.cycleOrder]?.max || deadline

    if (deadline && prevMin && prevMax) {
      deadlines[recipient.cycleOrder] = {
        min: isBefore(deadline, prevMin) ? deadline : prevMin,
        max: isAfter(deadline, prevMax) ? deadline : prevMax,
      }
    }

    if (deadline) {
      cyclesWithDeadline.add(recipient.cycleOrder.toString())
    }
  }

  const sortedCycles = Array.from(cyclesWithDeadline).sort()
  const cyclesLength = cyclesWithDeadline.size

  if (cyclesLength <= 1) {
    return null
  }

  for (let index = 1; index < cyclesLength; index++) {
    const cycle = sortedCycles[index]
    const prevCycle = sortedCycles[index - 1]
    const prevMax = deadlines[prevCycle].max
    const currentMin = deadlines[cycle].min

    if (isAfter(prevMax, currentMin)) {
      return 'RECIPIENTS_WRONG_DEADLINES'
    }
  }

  return null
}

export const isDeadlineExpired = (recipientsById: TYPES.RecipientsById, recipientsSorted: TYPES.RecipientsSorted) => {
  const today = new Date()

  return recipientsSorted.some((id) => {
    const recipient = recipientsById[id]
    const deadline = recipientsById[id].deadline as Date

    return recipient.action !== TYPES.RecipientActions.VIEW && isBefore(deadline, today)
  })
}

export const validateRecipients = (recipients: TYPES.RecipientData[]) => {
  const byCycle: { [order: string]: { [id: string]: boolean } } = {}
  let totalSignaturesPerPage = 0
  let totalInitialsPerPage = 0

  for (let i = 0; i < recipients.length; i += 1) {
    const recipient = recipients[i]

    if (recipient.action === TYPES.RecipientActions.SIGN) {
      totalSignaturesPerPage += 1
    }
    if (recipient.action === TYPES.RecipientActions.INITIAL) {
      totalInitialsPerPage += 1
    }

    if (byCycle[recipient.cycleOrder] && recipient.email && byCycle[recipient.cycleOrder][recipient.email]) {
      return 'RECIPIENTS_EMAIL_NOT_UNIQUE'
    } else {
      byCycle[recipient.cycleOrder] = {
        ...(byCycle[recipient.cycleOrder] ? byCycle[recipient.cycleOrder] : {}),
        [recipient.email]: true,
      }
    }
  }

  if (totalSignaturesPerPage > MAX_SIGNATURES_PER_PAGE) {
    return 'RECIPIENTS_MAX_SIGNATURES_EXCEEDED'
  }
  if (totalInitialsPerPage > MAX_INITIALS_PER_PAGE) {
    return 'RECIPIENTS_MAX_INITIALS_EXCEEDED'
  }

  return validateDeadline(recipients)
}

export const validateCycleOrderForStartedContract = (
  recipient: TYPES.RecipientData,
  prevRecipient: TYPES.RecipientData
) => {
  if (recipient.cycleOrder < prevRecipient.cycleOrder) {
    return 'RECIPIENTS_CYCLE_ORDER_LESS_THAN_ORIGINAL'
  }
}

export const validateActions = (recipients: TYPES.RecipientData[]) => {
  const valid = recipients.some(
    (recipient) =>
      recipient.action === TYPES.RecipientActions.INITIAL || recipient.action === TYPES.RecipientActions.SIGN
  )

  return valid ? null : 'EMPTY_RECIPIENTS'
}

export const importContactIntoRecipient = (recipient: TYPES.RecipientData, contact: Contact) => ({
  ...recipient,
  [TYPES.AddRecipientsFields.recipientName]: contact.fullName,
  [TYPES.AddRecipientsFields.recipientEmail]: contact.email,
  [TYPES.AddRecipientsFields.recipientPhone]: contact.phone || '',
  [TYPES.AddRecipientsFields.recipientDepartment]: contact.department || '',
})

export const mapRecipients = (recipients: Required<TYPES.RecipientData>[]): TYPES.RecipientsStores => {
  const sorted: TYPES.RecipientsSorted = []
  const byOrder: { [id: string]: string[] } = {}

  const byId: TYPES.RecipientsById = recipients.reduce((acc, recipient, idx) => {
    const { id, cycleOrder, email, name } = recipient
    const noEmailAndName = !email && !name
    const preparedName = name || `${translations.NO_NAME} ${idx + 1}`
    const preparedDeadline = recipient.deadline && parseISOwithTZ(recipient.deadline as string)

    sorted.push(id)
    byOrder[cycleOrder] = [...(byOrder[cycleOrder] || []), id]

    return {
      ...acc,
      [id]: {
        ...omit(['contract', 'signed'], recipient),
        name: preparedName,
        noEmailAndName,
        deadline: preparedDeadline,
      },
    }
  }, {})

  return { byId, byOrder: Object.entries(byOrder).map(([order, ids]) => [Number(order), ids]), sorted }
}

export const mapRecipientsFromForm = (recipients: Required<TYPES.RecipientData>[]): TYPES.RecipientsStores => {
  const sorted: TYPES.RecipientsSorted = []
  const byOrder: { [id: string]: string[] } = {}

  const byId: TYPES.RecipientsById = recipients.reduce((acc, recipient, idx) => {
    const { id, cycleOrder, name, noEmailAndName } = recipient
    const preparedName = name && !noEmailAndName ? name : `${translations.NO_NAME} ${idx + 1}`
    const preparedDeadline = recipient.deadline && new Date(recipient.deadline)

    sorted.push(id)
    byOrder[cycleOrder] = [...(byOrder[cycleOrder] || []), id]

    return { ...acc, [id]: { ...recipient, name: preparedName, noEmailAndName, deadline: preparedDeadline } }
  }, {})

  return { byId, byOrder: Object.entries(byOrder).map(([order, ids]) => [Number(order), ids]), sorted }
}

export const getOrderBasedOnRecipients = ({
  recipients,
  enabledFeatures,
  order,
}: TYPES.GetOrderBasedOnRecipientsProps) => {
  if (recipients.length === 0) {
    const initialOrder = accessFeature(enabledFeatures, SubscriptionFeatures.FEATURE_CLM)
      ? order
      : SIGNING_TYPES.SEPARATE

    return initialOrder
  }

  const isSequential = recipients.some((recipient) => recipient.cycleOrder !== 1)
  return isSequential ? SIGNING_TYPES.SEQUENTIAL : SIGNING_TYPES.SEPARATE
}

export const prepareSenderInfo = (user: EditProfileInitialDataResponse) => ({
  name: `${user.firstName} ${user.lastName}`.trim(),
  jobTitle: user.job,
})

export const checkEKYCOption = (recipients: TYPES.RecipientData[]) =>
  recipients.some(
    (recipient) =>
      recipient.authentication === TYPES.RecipientAuthentication.EKYC ||
      recipient.authentication === TYPES.RecipientAuthentication.EKYC_ID
  )

export const prepareMessageInitialValues = (document: DocumentPreviewResponse): DocumentMessageForm =>
  document.messageTitle && document.messageBody
    ? { title: document.messageTitle, message: document.messageBody }
    : documentMessageInitialValues

export const prepareRecipientForForm = ({ status, ...recipient }: RecipientData, templatesMode: boolean) => {
  const name = recipient[AddRecipientsFields.recipientName]
  const email = recipient[AddRecipientsFields.recipientEmail]
  const phoneValue = recipient[AddRecipientsFields.recipientPhone]
  const departmentValue = recipient[AddRecipientsFields.recipientDepartment]
  const noEmailAndName = recipient[AddRecipientsFields.recipientNoEmailAndName]

  return {
    ...recipient,
    [AddRecipientsFields.recipientName]: name && !noEmailAndName ? name : '',
    [AddRecipientsFields.recipientEmail]: email && !noEmailAndName ? email : '',
    [AddRecipientsFields.recipientDepartment]: departmentValue || '',
    [AddRecipientsFields.recipientPhone]: phoneValue || '',
    [AddRecipientsFields.recipientNoEmailAndName]: templatesMode ? noEmailAndName : false,
  }
}

export const prepareAllRecipientsForSubmit = (
  { recipients }: AddRecipientsForm,
  order: SIGNING_TYPES,
  templatesMode = false
): AddRecipientsForm => ({
  recipients: recipients.map(prepareRecipientForSubmit(order, templatesMode)),
})

export const prepareRecipientForSubmit = (order: SIGNING_TYPES, templatesMode = false) => (
  recipient: RecipientData
): RecipientData => {
  const name = recipient[AddRecipientsFields.recipientName]
  const email = recipient[AddRecipientsFields.recipientEmail]
  const orderValue = recipient[AddRecipientsFields.recipientCycleOrder]
  const deadlineValue = recipient[AddRecipientsFields.recipientDeadline] as Date
  const departmentValue = recipient[AddRecipientsFields.recipientDepartment]
  const authValue = recipient[AddRecipientsFields.recipientAuthentication]
  const phoneValue = recipient[AddRecipientsFields.recipientPhone]
  const noEmailAndName = recipient[AddRecipientsFields.recipientNoEmailAndName]

  return {
    ...omit([AddRecipientsFields.recipientNoEmailAndName, 'status'], recipient),
    [AddRecipientsFields.recipientName]: noEmailAndName ? null : name,
    [AddRecipientsFields.recipientEmail]: noEmailAndName ? null : email,
    [AddRecipientsFields.recipientCycleOrder]: order === SIGNING_TYPES.SEPARATE ? 1 : orderValue,
    [AddRecipientsFields.recipientDeadline]:
      templatesMode || !deadlineValue ? undefined : getFromToISODate(deadlineValue.toISOString(), true),
    [AddRecipientsFields.recipientDepartment]: departmentValue || undefined,
    [AddRecipientsFields.recipientPhone]:
      phoneValue && authValue === RecipientAuthentication.OTP ? addPhonePlus(phoneValue) : undefined,
  }
}

export const deleteRecipientById = (
  deletedId: string,
  { byId, byOrder, sorted }: TYPES.RecipientsStores
): TYPES.RecipientsStores => {
  const { cycleOrder } = byId[deletedId]
  const newById = omit([deletedId], byId)
  const newSorted = sorted.filter((recipientId) => recipientId !== deletedId)
  const newByOrder = byOrder
    .map<[number, string[]]>(([order, recipientsIds]) =>
      order === cycleOrder
        ? [order, recipientsIds.filter((recipientId) => recipientId !== deletedId)]
        : [order, recipientsIds]
    )
    .filter(([_order, recipientsIds]) => recipientsIds.length)

  return {
    byId: newById,
    sorted: newSorted,
    byOrder: newByOrder,
  }
}
