import { FetchFailureAction, FetchFailurePayload } from '../../../types/fetch'
import { createFetchActionTypes } from '../../../utils/createFetchActionTypes'

import * as TYPES from './favorite.types'

export const fetchFavoriteTypes = createFetchActionTypes(TYPES.FavoriteActions.TOGGLE_FAVORITE_TEMPLATE)

export const fetchFavoriteTemplate = (id: string, favorite: boolean): TYPES.FetchFavoriteTemplate => ({
  type: fetchFavoriteTypes.request,
  payload: { id, favorite },
})

export const fetchFavoriteTemplateSuccess = (): TYPES.FetchFavoriteTemplateSuccess => ({
  type: fetchFavoriteTypes.success,
})

export const fetchFavoriteTemplateFailure = (payload: FetchFailurePayload): FetchFailureAction => ({
  type: fetchFavoriteTypes.failure,
  payload,
})
