import React, { useMemo } from 'react'
import { useTheme } from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { useTranslation } from 'react-i18next'

import translations from '../../../../../../translations/keys'
import { Contract } from '../../../../../../types/contracts'
import { TableData } from '../../../../../ui/Table/Table.types'
import { TableCellEllipsis, HighlightedText } from '../../../../../ui/Table/Table.styles'
import highlightText from '../../../../../../utils/highlightText'
import { FolderIcon } from '../../../../../assets/icons'
import Box from '../../../../../ui/Box'
import useMediaQuery from '../../../../../hooks/useMediaQuery'
import { StyledThemeScheme } from '../../../../../../themes/light'
import { renderDate } from '../../../../../utils/table'
import { authUserEmailSelector } from '../../../../../../store/auth/auth.selectors'
import { prepareStatus } from '../../../../../../utils/contracts/prepareStatus'

import { openContract } from './useActions'

const titleData = {
  field: 'name',
  name: translations.TITLE,
}

export const useColumns = () => {
  const history = useHistory()
  const { t } = useTranslation()
  const theme = useTheme() as StyledThemeScheme
  const laptopScreen = useMediaQuery(theme.devices.laptop)
  const dispatch = useDispatch()
  const userEmail = useSelector(authUserEmailSelector)
  const calcProgress = (contract: Contract) => {
    if (contract.isFolder) {
      return ''
    }

    if (contract.progress) {
      return `${contract.progress.completed}/${contract.progress.involved}`
    }

    return ''
  }

  return useMemo(
    () => [
      {
        ...titleData,
        name: t(titleData.name),
        widthPercent: 32,
        widthPercentLaptop: 32,
        sortable: true,
        searchable: true,
        render: (data: TableData, highlightedText?: string) => {
          const contract = data as Contract
          const onClick = () => {
            openContract(dispatch, history, contract)
          }

          return (
            <TableCellEllipsis ellipsis onClick={onClick}>
              {contract.isFolder && (
                <Box display="inline-block" mr={1} verticalAlign="middle">
                  <FolderIcon color="grey" />
                </Box>
              )}
              <Box display="inline">{highlightText(contract.name, highlightedText, HighlightedText, 'span')}</Box>
            </TableCellEllipsis>
          )
        },
      },
      {
        field: 'status',
        name: t(translations.STATUS),
        widthPercent: 16,
        widthPercentLaptop: 19,
        render: (data: TableData) => {
          const contract = data as Contract

          return contract.isFolder ? '' : prepareStatus(contract)
        },
      },
      {
        field: 'progress',
        name: t(translations.PROGRESS),
        widthPercent: 12,
        widthPercentLaptop: 6,
        sortable: true,
        noHeader: 'laptop' as const,
        render: (data: TableData) => {
          const contract = data as Contract
          return calcProgress(contract)
        },
      },
      {
        field: 'createDate',
        name: laptopScreen ? t(translations.CREATED) : t(translations.CREATED_BY),
        widthPercent: 20,
        widthPercentLaptop: 18,
        sortable: true,
        render: renderDate('createDate', userEmail),
      },
      {
        field: 'updateDate',
        name: laptopScreen ? t(translations.UPDATED) : t(translations.UPDATED_BY),
        widthPercent: 20,
        widthPercentLaptop: 22,
        sortable: true,
        render: renderDate('updateDate', userEmail),
      },
    ],
    [userEmail]
  )
}

export const mobileColumns = [titleData]
