import { Form, Formik } from 'formik'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTheme } from 'styled-components'
import { useTranslation } from 'react-i18next'

import { CONTRACTS_FILTERS } from '../../../../../constants/contracts'
import { contractsFiltersInitialValues } from '../../../../../initialValues/contractsFiltersInitialValues'
import { contractsListSetFilters } from '../../../../../store/contracts/contractsList/contractsList.actions'
import {
  contractsListFiltersSelector,
  contractsListIsAppliedFiltersSelector,
} from '../../../../../store/contracts/contractsList/contractsList.selectors'
import { StyledThemeScheme } from '../../../../../themes/light'
import translations from '../../../../../translations/keys'
import { ContractsFiltersFields, ContractsFiltersForm } from '../../../../../types/contractsFilters'
import { FormikRenderForm } from '../../../../../types/formik'
import Button from '../../../../ui/Button'
import { BUTTON_VARIANTS } from '../../../../ui/Button/Button.types'
import DropdownButton from '../../../../ui/DropdownButton'
import FormCheckboxes from '../../../../ui/Forms/FormCheckboxes'
import FormDatePicker from '../../../../ui/Forms/FormDatePicker'
import MobilePopupButton from '../../../../components/MobilePopupButton'
import Typography from '../../../../ui/Typography'
import { TYPOGRAPHY_NAMES } from '../../../../ui/Typography/Typography.types'
import Box from '../../../../ui/Box'
import useMediaQuery from '../../../../hooks/useMediaQuery'

import { StyledDateCreated } from './Filters.styles'

export const Filters = () => {
  const theme = useTheme() as StyledThemeScheme
  const laptopScreen = useMediaQuery(theme.devices.laptop)
  const mobileScreen = useMediaQuery(theme.devices.mobile)
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const filters = useSelector(contractsListFiltersSelector)
  const isAppliedFilters = useSelector(contractsListIsAppliedFiltersSelector)

  const onApplyFilters = (values: ContractsFiltersForm) => {
    dispatch(contractsListSetFilters(values))
  }

  const renderForm = (handleClose: () => void) => ({
    values,
    handleSubmit,
    resetForm,
  }: FormikRenderForm<ContractsFiltersForm>) => {
    const onSubmit = () => {
      handleClose()
      handleSubmit()
    }
    const onReset = () => {
      onApplyFilters(contractsFiltersInitialValues)
      resetForm({ values: contractsFiltersInitialValues })
    }
    const statusesOptions = CONTRACTS_FILTERS.map((status) => ({
      value: status,
      name: t(translations[status]),
    }))

    return (
      <Form>
        <Box
          display="flex"
          flexDirection="column"
          width={368}
          mobileWidth="100%"
          pt={2}
          pb={2}
          pl={3}
          pr={3}
          ptMobile={0}
          pbMobile={0}
          plMobile={0}
          prMobile={0}
        >
          <Typography name={TYPOGRAPHY_NAMES.H3} mobileName={TYPOGRAPHY_NAMES.H4}>
            {t(translations.STATUS)}
          </Typography>
          <Box display="flex" flexWrap="wrap" mt={2} mb={1}>
            <FormCheckboxes
              name={ContractsFiltersFields.statuses}
              options={statusesOptions}
              values={values[ContractsFiltersFields.statuses]}
              itemStyle={{ width: '50%', pb: 2 }}
            />
          </Box>
          <Typography name={TYPOGRAPHY_NAMES.H3} mobileName={TYPOGRAPHY_NAMES.H4}>
            {t(translations.DATE_CREATED)}
          </Typography>
          <StyledDateCreated>
            <Box mr={3} mrMobile={0} mobileWidth="100%">
              <FormDatePicker
                name={ContractsFiltersFields.createDateFrom}
                label={t(translations.FROM)}
                maxDate={values.createDateTo ? new Date(values.createDateTo) : new Date()}
                placeholder={t(translations.CHOOSE)}
              />
            </Box>
            <Box mobileWidth="100%">
              <FormDatePicker
                name={ContractsFiltersFields.createDateTo}
                label={t(translations.TO)}
                minDate={values.createDateFrom ? new Date(values.createDateFrom) : undefined}
                maxDate={new Date()}
                placeholder={t(translations.CHOOSE)}
              />
            </Box>
          </StyledDateCreated>
          <Box display="flex" mt={2}>
            <Box mr={3} width="50%">
              <Button fullWidth onClick={onSubmit} type="button">
                {t(translations.APPLY)}
              </Button>
            </Box>
            <Box width="50%">
              <Button variant={BUTTON_VARIANTS.SECONDARY} fullWidth onClick={onReset} type="button">
                {t(translations.RESET)}
              </Button>
            </Box>
          </Box>
        </Box>
      </Form>
    )
  }

  const popoverContent = ({ handleClose }: { handleClose: () => void }) => (
    <Formik onSubmit={onApplyFilters} initialValues={filters}>
      {renderForm(handleClose)}
    </Formik>
  )

  return (
    <Box mr={5} mrLaptop={2} mrMobile={2}>
      {mobileScreen ? (
        <MobilePopupButton
          popupContent={popoverContent}
          buttonIcon="filter"
          buttonIconColor={isAppliedFilters ? 'blue' : undefined}
          popupTitle={t(translations.FILTERS)}
        />
      ) : (
        <DropdownButton
          hideTriangle
          popoverContent={popoverContent}
          capture
          buttonIcon="filter"
          buttonIconColor={isAppliedFilters ? 'blue' : undefined}
          openStyles={{
            buttonIcon: { color: 'blue' },
          }}
        >
          {({ visible }) =>
            !laptopScreen && (
              <Typography name={TYPOGRAPHY_NAMES.body14} color={isAppliedFilters || visible ? 'textColor' : 'grey'}>
                {t(translations.FILTERS)}
              </Typography>
            )
          }
        </DropdownButton>
      )}
    </Box>
  )
}
