import React from 'react'

import AuthorizationTemplate from '../../../../components/Authorization/AuthorizationTemplate'

import { UpdatedSuccessfully as UpdatedSuccessfullyPage } from './UpdatedSuccessfully'

export const UpdatedSuccessfully = () => (
  <AuthorizationTemplate>
    <UpdatedSuccessfullyPage />
  </AuthorizationTemplate>
)
