import React from 'react'

export const TemplateSvg = () => (
  <svg width="156" height="96" viewBox="0 0 156 96" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M76.5943 73.1634C76.4208 74.8501 77.6698 76.3303 79.3699 76.5025C81.1047 76.6746 82.5618 75.4353 82.7353 73.7486C82.9088 72.0618 81.6598 70.5816 79.9597 70.4095C78.2597 70.2718 76.7678 71.4766 76.5943 73.1634ZM79.0576 79.5317C77.427 79.3596 75.9351 78.6023 74.8942 77.3286C73.8534 76.055 73.3676 74.4715 73.5064 72.8536C73.8534 69.5145 76.8025 67.036 80.2373 67.3803C83.6027 67.7245 86.1007 70.6849 85.7538 74.0584C85.4415 77.4319 82.4231 79.8415 79.0576 79.5317Z"
      fill="#DFE1E6"
    />
    <path d="M85.3376 38.6711H57.5469V35.6074H85.3376V38.6711Z" fill="#DFE1E6" />
    <path d="M85.3376 59.8071H57.5469V56.7434H85.3376V59.8071Z" fill="#DFE1E6" />
    <path d="M85.3376 48.8949H57.5469V45.8312H85.3376V48.8949Z" fill="#DFE1E6" />
    <path
      d="M50.0532 84.9711H93.1444V22.8713H50.0532V84.9711ZM94.671 88.0004H48.5266C47.6592 88.0004 47 87.3119 47 86.4858V21.3223C47 20.4617 47.6939 19.8076 48.5266 19.8076H94.7057C95.5731 19.8076 96.2323 20.4961 96.2323 21.3223V86.4858C96.2323 87.3119 95.5384 88.0004 94.671 88.0004Z"
      fill="#6085B3"
    />
    <path
      d="M101.297 79.9108C100.464 79.9108 99.7354 79.2223 99.7354 78.3962V17.2602H55.4992C54.6666 17.2602 53.9727 16.5717 53.9727 15.7112C53.9727 14.8506 54.6666 14.1621 55.4992 14.1621H101.158C101.991 14.1621 102.823 14.9194 102.823 15.78V78.3962C102.823 79.2223 102.129 79.9108 101.297 79.9108Z"
      fill="#6085B3"
    />
    <path
      d="M107.474 73.7831C106.641 73.7831 105.913 73.0946 105.913 72.2685V11.0981H62.6828C61.8155 11.0981 61.1562 10.4096 61.1562 9.54905C61.1562 8.68847 61.8502 8 62.6828 8H107.301C108.168 8 109.001 8.79172 109.001 9.61788V72.2341C109.001 73.1291 108.307 73.7831 107.474 73.7831Z"
      fill="#6085B3"
    />
  </svg>
)
