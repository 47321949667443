import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'

import { SessionStorageKeys } from '../../../constants/sessionStorage'
import { fetchNoneVerification } from '../../../store/contracts/noneVerification/noneVerification.actions'
import { signingRecipientAuthentication } from '../../../store/contracts/signing/signing.selectors'
import { openModal } from '../../../store/modals/modals.actions'
import { RecipientAuthentication } from '../../../types/recipients'
import SessionStorage from '../../../utils/SessionStorage'
import { CONTRACT_SIGN_MODALS } from '../../constants/contractSign'
import { ContractRouteParams } from '../../types/signing'

export const useCheckAction = (onClose: () => void) => {
  const { contractId } = useParams<ContractRouteParams>()
  const dispatch = useDispatch()
  const recipientAuthentication = useSelector(signingRecipientAuthentication)

  const handleAction = useCallback(
    (action: () => void) => {
      if (SessionStorage.get(SessionStorageKeys.VERIFICATION_TOKEN)) {
        action()
      } else if (recipientAuthentication === RecipientAuthentication.NONE) {
        dispatch(fetchNoneVerification(contractId, action))
      } else if (recipientAuthentication === RecipientAuthentication.OTP) {
        dispatch(
          openModal(CONTRACT_SIGN_MODALS.ACTION_OTP_VERIFICATION, {
            handleAction: action,
          })
        )
      } else if (
        recipientAuthentication === RecipientAuthentication.EKYC ||
        recipientAuthentication === RecipientAuthentication.EKYC_ID
      ) {
        onClose()
        dispatch(openModal(CONTRACT_SIGN_MODALS.SIGNING_EKYC_VERIFICATION))
      }
    },
    [onClose, recipientAuthentication]
  )

  return { handleAction }
}
