import React, { forwardRef, useMemo } from 'react'
import { Formik, FormikProps } from 'formik'
import { useTranslation } from 'react-i18next'

import Modal from '../../../Modal'
import { ContractSvg } from '../../../../assets/ContractSvg'
import Box from '../../../../ui/Box'
import Typography from '../../../../ui/Typography'
import { TYPOGRAPHY_NAMES } from '../../../../ui/Typography/Typography.types'
import translations from '../../../../../translations/keys'
import Button from '../../../../ui/Button'
import { BUTTON_VARIANTS } from '../../../../ui/Button/Button.types'
import FormTextInput from '../../../../ui/Forms/FormTextInput'
import { UploadDocumentFields, UploadDocumentForm } from '../../../../../types/uploadDocument'
import FormDropdown from '../../../../ui/Forms/FormDropdown'
import { uploadDocumentToValidationSchema } from '../../../../../validation/documents/uploadDocumentToValidationSchema'
import { FILE_NAME_MAX_LENGTH } from '../../../../../validation/fileNameValidationSchema'
import { uploadDocumentToInitialValues } from '../../../../../initialValues/uploadDocumentToInitialValues'
import { Spinner } from '../../../../ui/Spinner/Spinner'

import { StyledForm, StyledWrapper } from './UploadDocumentToModal.styles'
import { UploadDocumentToModalProps } from './UploadDocumentToModal.types'

export const UploadDocumentToModal = forwardRef<FormikProps<UploadDocumentForm>, UploadDocumentToModalProps>(
  (
    {
      onClose,
      fileName = '',
      foldersIsLoading,
      folders,
      uploading = false,
      initialTouchedDisabled = false,
      onCreateFolder,
      onSubmit,
      title = translations.UPLOAD_TO,
      submitText = translations.UPLOAD,
    },
    ref
  ) => {
    const { t } = useTranslation()
    const initialsValues = useMemo(() => uploadDocumentToInitialValues(fileName), [fileName])
    const initialTouched = initialTouchedDisabled
      ? undefined
      : {
          [UploadDocumentFields.fileName]: true,
        }

    if (foldersIsLoading) {
      return null
    }

    const renderForm = () => (
      <StyledForm>
        <FormTextInput
          name={UploadDocumentFields.fileName}
          label={t(translations.NAME)}
          maxLength={FILE_NAME_MAX_LENGTH}
        />
        <FormDropdown
          name={UploadDocumentFields.folderName}
          label={t(translations.FOLDER_LABEL)}
          placeholder=""
          options={folders}
          noError
          maxHeight={150}
        />
        <Box>
          <Button icon="folderAdd" variant={BUTTON_VARIANTS.INACTIVE} type="button" onClick={onCreateFolder}>
            {t(translations.CREATE_NEW_FOLDER)}
          </Button>
        </Box>
        <Box mt={2} mb={2}>
          <Spinner show={uploading} />
        </Box>
        <Button type="submit" fullWidth>
          {t(submitText)}
        </Button>
      </StyledForm>
    )

    return (
      <Modal onClose={onClose}>
        <StyledWrapper>
          <ContractSvg />
          <Box mt={5} mb={3}>
            <Typography name={TYPOGRAPHY_NAMES.H1}>{t(title)}</Typography>
          </Box>
          <Formik
            innerRef={ref}
            onSubmit={onSubmit}
            initialValues={initialsValues}
            validationSchema={uploadDocumentToValidationSchema}
            validateOnMount
            initialTouched={initialTouched}
          >
            {renderForm}
          </Formik>
        </StyledWrapper>
      </Modal>
    )
  }
)
