import React, { useEffect, useRef } from 'react'
import { FormikProps } from 'formik'
import { useDispatch, useSelector } from 'react-redux'

import { useTemplatesFoldersFetch } from '../../../../../hooks/templates/useTemplatesFoldersFetch'
import * as FOLDERS_SELECTORS from '../../../../../store/templates/folders/folders.selectors'
import { useShowFetchError } from '../../../../hooks/useShowFetchError'
import { DuplicateDocumentFields, DuplicateDocumentForm } from '../../../../../types/duplicateDocument'
import { modalsPayloadSelector } from '../../../../../store/modals/modals.selectors'
import { TEMPLATE_EXISTS, TEMPLATES_MODALS } from '../../../../../constants/templates'
import { DOCUMENT_WITH_NAME_EXIST } from '../../../../../constants/documents'
import * as DUPLICATE_SELECTORS from '../../../../../store/templates/duplicate/duplicate.selectors'
import { useDispatchUnmount } from '../../../../../hooks/useDispatchUnmount'
import {
  clearDuplicateTemplate,
  fetchDuplicateTemplate,
} from '../../../../../store/templates/duplicate/duplicate.actions'
import DuplicateDocumentModal from '../../DocumentModals/DuplicateDocumentModal'
import translations from '../../../../../translations/keys'

import { DuplicateTemplateModalProps } from './DuplicateTemplateModal.types'

const EXCLUDE_CODES = [DOCUMENT_WITH_NAME_EXIST]

export const DuplicateTemplateModal: React.FC<DuplicateTemplateModalProps> = ({ onClose }) => {
  const dispatch = useDispatch()
  const formRef: React.RefObject<FormikProps<DuplicateDocumentForm>> = useRef(null)
  const { id, oldName } = useSelector(modalsPayloadSelector(TEMPLATES_MODALS.DUPLICATE_TEMPLATE))
  const folders = useSelector(FOLDERS_SELECTORS.templatesFoldersDataSelector)
  const foldersError = useSelector(FOLDERS_SELECTORS.templatesFoldersDataErrorSelector)
  const foldersFetchStatus = useSelector(FOLDERS_SELECTORS.templatesFoldersDataFetchStatusSelector)
  const foldersIsLoading = useSelector(FOLDERS_SELECTORS.templatesFoldersIsLoadingSelector)
  const duplicatedSuccessfully = useSelector(DUPLICATE_SELECTORS.templatesDuplicateSuccessSelector)
  const duplicateFetchStatus = useSelector(DUPLICATE_SELECTORS.templatesDuplicateFetchStatusSelector)
  const duplicateError = useSelector(DUPLICATE_SELECTORS.templatesDuplicateErrorSelector)

  useEffect(() => {
    if (duplicatedSuccessfully) {
      onClose()
    }
  }, [duplicatedSuccessfully])
  useEffect(() => {
    if (duplicateError?.lastErrorCode === DOCUMENT_WITH_NAME_EXIST) {
      const form = formRef.current

      if (form) {
        form.setFieldError(DuplicateDocumentFields.name, TEMPLATE_EXISTS)
      }
    }
  }, [duplicateError])
  useTemplatesFoldersFetch()
  useShowFetchError(foldersFetchStatus, foldersError)
  useShowFetchError(duplicateFetchStatus, duplicateError, EXCLUDE_CODES)
  useDispatchUnmount(clearDuplicateTemplate)

  const onSubmit = (values: DuplicateDocumentForm) => {
    dispatch(fetchDuplicateTemplate(id, values))
  }

  return (
    <DuplicateDocumentModal
      loading={foldersIsLoading}
      folders={folders}
      oldName={oldName}
      onClose={onClose}
      onSubmit={onSubmit}
      ref={formRef}
      description={translations.TEMPLATE_DUPLICATE_FILE_DESCRIPTION}
    />
  )
}
