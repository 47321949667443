import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { useDispatchUnmount } from '../useDispatchUnmount'
import { fetchTemplatesFolders, cleanTemplatesFolders } from '../../store/templates/folders/folders.actions'

export const useTemplatesFoldersFetch = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchTemplatesFolders())
  }, [])

  useDispatchUnmount(cleanTemplatesFolders)
}
