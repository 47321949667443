import { FETCH_STATUSES } from '../../../types/fetch'
import { RootState } from '../../rootReducer.types'

export const contractsListDataSelector = (state: RootState) => state.contracts.contractsList.data
export const contractsListSortingSelector = (state: RootState) => state.contracts.contractsList.sorting
export const contractsListLimitSelector = (state: RootState) => state.contracts.contractsList.limit
export const contractsListOffsetSelector = (state: RootState) => state.contracts.contractsList.offset
export const contractsListSearchTextSelector = (state: RootState) => state.contracts.contractsList.searchText
export const contractsListTotalSelector = (state: RootState) => state.contracts.contractsList.total
export const contractsListFiltersSelector = (state: RootState) => state.contracts.contractsList.filters
export const contractsListLoadingSelector = (state: RootState) =>
  state.contracts.contractsList.fetchStatus === FETCH_STATUSES.REQUEST
export const contractsListIsFirstLoadSelector = (state: RootState) => state.contracts.contractsList.isFirstLoad
export const contractsListIsAppliedFiltersSelector = (state: RootState) =>
  state.contracts.contractsList.isAppliedFilters
export const contractsListErrorSelector = (state: RootState) => state.contracts.contractsList.error
export const contractsListFetchStatusSelector = (state: RootState) => state.contracts.contractsList.fetchStatus
export const contractsListSuccessMessageSelector = (state: RootState) => state.contracts.contractsList.successMessage
export const contractsListForceUpdateSelector = (state: RootState) => state.contracts.contractsList.forceUpdate
export const contractsListBreadcrumbs = (state: RootState) => state.contracts.contractsList.breadcrumbs
export const contractsListFolderId = (state: RootState) => state.contracts.contractsList.folderId
export const contractsListIsEmptyContracts = (state: RootState) => state.contracts.contractsList.isEmptyContracts
export const contractsListIsEmptyFolder = (state: RootState) => state.contracts.contractsList.isEmptyFolder

export const contractsListDeleteSuccessSelector = (state: RootState) =>
  state.contracts.contractsList.delete.fetchStatus === FETCH_STATUSES.SUCCESS
export const contractsListDeleteFetchStatusSelector = (state: RootState) =>
  state.contracts.contractsList.delete.fetchStatus
export const contractsListDeleteErrorSelector = (state: RootState) => state.contracts.contractsList.delete.error

export const contractsListRenameSuccessSelector = (state: RootState) =>
  state.contracts.contractsList.rename.fetchStatus === FETCH_STATUSES.SUCCESS
export const contractsListRenameFetchStatusSelector = (state: RootState) =>
  state.contracts.contractsList.rename.fetchStatus
export const contractsListRenameErrorSelector = (state: RootState) => state.contracts.contractsList.rename.error

export const contractsListMoveToFolderSuccessSelector = (state: RootState) =>
  state.contracts.contractsList.moveToFolder.fetchStatus === FETCH_STATUSES.SUCCESS
export const contractsListMoveToFolderFetchStatusSelector = (state: RootState) =>
  state.contracts.contractsList.moveToFolder.fetchStatus
export const contractsListMoveToFolderErrorSelector = (state: RootState) =>
  state.contracts.contractsList.moveToFolder.error

export const contractsListDuplicateSuccessSelector = (state: RootState) =>
  state.contracts.contractsList.duplicate.fetchStatus === FETCH_STATUSES.SUCCESS
export const contractsListDuplicateFetchStatusSelector = (state: RootState) =>
  state.contracts.contractsList.duplicate.fetchStatus
export const contractsListDuplicateErrorSelector = (state: RootState) => state.contracts.contractsList.duplicate.error

export const contractsNotificationSelector = (state: RootState) => state.contracts.contractsList.notification
