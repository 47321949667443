import * as TYPES from '../../../types/placement'
import { SigningRecipient } from '../../../types/signingContract'
import { MetadataSigning, SigningPlace } from '../../../types/documentPreview'
import { addMIME } from '../../../utils/signatures/addMIME'
import { preparePlaces } from '../../../utils/placement'

export const prepareSigningPlacement = (
  otherRecipients: MetadataSigning[],
  recipientPlaces: SigningPlace[] = [],
  recipient?: SigningRecipient,
  onlyView = false
): [TYPES.PlacementById, TYPES.PlacementByPages, TYPES.PlacementSorted] => {
  let sorted: TYPES.PlacementSorted = []
  let byId: TYPES.PlacementById = {}
  let byPages: TYPES.PlacementByPages = {}

  otherRecipients.forEach((otherRecipient) => {
    if (!otherRecipient.places) {
      return
    }

    const [newById, newByPages, newSorted] = preparePlaces(
      { sorted, byId, byPages },
      otherRecipient.places.map((place) => ({
        ...place,
        recipientId: place.recipient.id,
        readonly: true,
        image: otherRecipient.image && addMIME(otherRecipient.image),
      })),
      {
        name: otherRecipient.recipientName,
        action: otherRecipient.action,
      }
    )

    sorted = newSorted
    byId = newById
    byPages = newByPages
  })

  const [newById, newByPages, newSorted] = preparePlaces(
    { sorted, byId, byPages },
    recipientPlaces.map((place) => ({ ...place, recipientId: place.recipient.id, readonly: onlyView })),
    recipient
  )
  sorted = newSorted
  byId = newById
  byPages = newByPages

  return [byId, byPages, sorted]
}
