import styled from 'styled-components'

import { RecipientActions } from '../../../../types/recipients'
import { RecipientActionBackground } from '../../../utils/recipients'
import Box from '../../Box'

export const Indicators = styled(Box).attrs({
  display: 'flex',
})`
  position: absolute;
  bottom: 26px;
  right: 0;

  ${({ theme }) => `@media ${theme.devices.tablet} {
    flex-direction: column;
  }`}
`
export const Indicator = styled(Box).attrs({
  mr: 1,
})<{
  action: RecipientActions
}>`
  display: inline-block;
  width: 24px;
  height: 24px;
  border-radius: 4px;
  ${RecipientActionBackground}
`
