import React from 'react'
import { useTranslation } from 'react-i18next'

import { NOTIFICATION_MODAL_TYPES } from '../../../../ui/Modals/NotificationModal/NotificationModal.constants'
import { NotificationModalView } from '../../../../ui/Modals/NotificationModal/NotificationModalView'
import translations from '../../../../../translations/keys'

import { ConfirmGenerateModalProps } from './ConfirmGenerateModal.types'

export const ConfirmGenerateModal: React.FC<ConfirmGenerateModalProps> = (props) => {
  const { t } = useTranslation()
  return (
    <NotificationModalView
      type={NOTIFICATION_MODAL_TYPES.INFO}
      title={t(translations.ARE_YOU_SURE)}
      onClose={props.onClose}
      description={t(translations.CONFIRM_GENERATE_MODAL_DESCRIPTION)}
      successButtonText={t(translations.GENERATE)}
      onSuccess={props.onSuccess}
    />
  )
}
