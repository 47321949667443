import React from 'react'
import { useHistory, useParams } from 'react-router'

import translations from '../../../../../translations/keys'
import { location } from '../../../../../utils/location'
import { TemplateRouteParams } from '../../../../types/template'
import AskModal from '../../ContractModals/AskModal'

import { SaveWithoutSLMModalProps } from './SaveWithoutSLMModal.types'

export const SaveWithoutSLMModal: React.FC<SaveWithoutSLMModalProps> = ({ onClose }) => {
  const { templateId } = useParams<TemplateRouteParams>()
  const history = useHistory()
  const onSuccess = () => {
    onClose()
    history.push(location.templatesViewUrl(templateId))
  }

  return <AskModal onClose={onClose} onSuccess={onSuccess} title={translations.SAVE_WITHOUT_SLM} onCancel={onClose} />
}
