import styled, { css } from 'styled-components'

import Box from '../../../../ui/Box'

import { CardProps } from './Card.types'

const activeCardStyled = css`
  ${({ theme }) => `background-color: ${theme.palette.blue};`}
  ${({ theme }) => `color: ${theme.palette.white};`}
`
const inactiveCardStyled = css`
  ${({ theme }) => `background-color: ${theme.palette.white};`}
  ${({ theme }) => `color: ${theme.palette.black};`}
  &:hover {
    ${({ theme }) => `background-color: ${theme.palette.blueExtraLight};`}
  }
`

export const StyledCard = styled(Box).attrs({
  ml: 1.5,
  mr: 1.5,
  mb: 4,
})<Pick<CardProps, 'isActive'>>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 84px;
  box-shadow: ${({ theme }) => theme.shadow};
  border-radius: 4px;
  cursor: pointer;
  ${({ isActive }) => (isActive ? activeCardStyled : inactiveCardStyled)}
`
