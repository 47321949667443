import { combineEpics, Epic, ofType } from 'redux-observable'
import { catchError, map, mergeMap, takeUntil } from 'rxjs/operators'

import { notificationService } from '../../../api'
import { catchFetchError } from '../../../utils/catchFetchError'
import { prepareNotifications, prepareUpdatingNotifications } from '../../../utils/preferences/notifications'

import * as ACTIONS from './notifications.actions'
import { notificationsDataSelector } from './notifications.selectors'

const fetchNotifications: Epic = (action$) =>
  action$.pipe(
    ofType(ACTIONS.fetchNotificationsTypes.request),
    mergeMap(() =>
      notificationService.fetchBlockedNotifications().pipe(
        map((response) => ACTIONS.fetchNotificationsSuccess(prepareNotifications(response))),
        catchError(catchFetchError(ACTIONS.fetchNotificationsFailure)),
        takeUntil(
          action$.pipe(ofType(ACTIONS.fetchNotificationsTypes.success, ACTIONS.fetchNotificationsTypes.failure))
        )
      )
    )
  )

const updateNotifications: Epic = (action$, state$) =>
  action$.pipe(
    ofType(ACTIONS.updateNotificationsTypes.request),
    mergeMap(({ payload }) =>
      notificationService.fetchUpdateBlockedNotifications(payload.body).pipe(
        map(() =>
          ACTIONS.updateNotificationsSuccess(
            prepareUpdatingNotifications(notificationsDataSelector(state$.value)!, payload.body)
          )
        ),
        catchError(catchFetchError(ACTIONS.updateNotificationsFailure)),
        takeUntil(
          action$.pipe(ofType(ACTIONS.updateNotificationsTypes.success, ACTIONS.updateNotificationsTypes.failure))
        )
      )
    )
  )

export const notificationsEpics = combineEpics(fetchNotifications, updateNotifications)
