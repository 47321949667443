import { Action } from 'redux'

import { FetchFailurePayload, FETCH_STATUSES } from '../../../types/fetch'

export interface ReuploadState {
  fetchStatus: FETCH_STATUSES
  error: FetchFailurePayload | null
}

export enum ReuploadActions {
  'FETCH_REUPLOAD' = 'FETCH_REUPLOAD',
  'CLEAR_REUPLOAD' = 'CLEAR_REUPLOAD',
}

export interface FetchReupload extends Action {
  payload: { id: string; file: File }
}
export type FetchReuploadSuccess = Action
