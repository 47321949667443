import { createFetchActionTypes } from '../../../utils/createFetchActionTypes'
import { FetchFailureAction, FetchFailurePayload } from '../../../types/fetch'
import { EditableElem } from '../../../types/editableElem'
import { DocFieldsResponseBody, DocFieldsById, DocFieldsByPages, DocField } from '../../../types/documentField'

import * as TYPES from './fields.types'

export const fetchFieldsTypes = createFetchActionTypes(TYPES.FieldsActions.TEMPLATES_FETCH_FIELDS)
export const fetchUpdateFieldsTypes = createFetchActionTypes(TYPES.FieldsActions.TEMPLATES_FETCH_UPDATE_FIELDS)
export const scrollToEmptyFieldTypes = createFetchActionTypes(TYPES.FieldsActions.TEMPLATES_SCROLL_TO_EMPTY_FIELD)

export const addField = (field: DocField): TYPES.AddFieldAction => ({
  type: TYPES.FieldsActions.TEMPLATES_ADD_FIELD,
  payload: { field },
})
export const setField = (field: EditableElem): TYPES.SetFieldAction => ({
  type: TYPES.FieldsActions.TEMPLATES_SET_FIELD,
  payload: { field },
})
export const unsetField = (id: string): TYPES.UnsetFieldAction => ({
  type: TYPES.FieldsActions.TEMPLATES_UNSET_FIELD,
  payload: { id },
})
export const clearFields = (): TYPES.ClearFieldsAction => ({
  type: TYPES.FieldsActions.TEMPLATES_CLEAR_FIELDS,
})

export const fetchFields = (templateId: string): TYPES.FetchFieldsAction => ({
  type: fetchFieldsTypes.request,
  payload: { templateId },
})
export const fetchFieldsSuccess = (
  byId: DocFieldsById,
  byPages: DocFieldsByPages,
  fontSize: number
): TYPES.FetchFieldsSuccessAction => ({
  type: fetchFieldsTypes.success,
  payload: { byId, byPages, fontSize },
})
export const fetchFieldsFailure = (payload: FetchFailurePayload): FetchFailureAction => ({
  type: fetchFieldsTypes.failure,
  payload,
})

export const fetchUpdateFields = (templateId: string, byId: DocFieldsById): TYPES.FetchUpdateFieldsAction => ({
  type: fetchUpdateFieldsTypes.request,
  payload: { templateId, byId },
})
export const fetchUpdateFieldsSuccess = (response: DocFieldsResponseBody): TYPES.FetchUpdateFieldsSuccessAction => ({
  type: fetchUpdateFieldsTypes.success,
  payload: response,
})
export const fetchUpdateFieldsFailure = (payload: FetchFailurePayload): FetchFailureAction => ({
  type: fetchUpdateFieldsTypes.failure,
  payload,
})

export const setFontSize = (fontSize: number): TYPES.SetFontSizeAction => ({
  type: TYPES.FieldsActions.TEMPLATES_SET_FONT_SIZE,
  payload: { fontSize },
})

export const setFieldText = (id: string, text: string): TYPES.SetFieldTextAction => ({
  type: TYPES.FieldsActions.TEMPLATES_SET_FIELD_TEXT,
  payload: { id, text },
})

export const clearFieldsText = (): TYPES.ClearFieldsTextAction => ({
  type: TYPES.FieldsActions.TEMPLATES_CLEAR_FIELDS_TEXT,
})

export const scrollToEmptyField = (): TYPES.ScrollToEmptyFieldAction => ({
  type: scrollToEmptyFieldTypes.request,
})
export const scrollToEmptyFieldSuccess = (id: string): TYPES.ScrollToEmptyFieldSuccessAction => ({
  type: scrollToEmptyFieldTypes.success,
  payload: { id },
})
