import React, { useRef } from 'react'
import { FormikProps } from 'formik'
import { useDispatch, useSelector } from 'react-redux'

import { CreateFolderBody, CreateFolderFields, CREATE_FOLDER_ERRORS } from '../../../../../types/createFolder'
import { createFolder } from '../../../../../store/contracts/folders/folders.actions'
import { foldersDataSelector } from '../../../../../store/contracts/folders/folders.selectors'
import UploadDocumentCreateFolderModal from '../../DocumentModals/UploadDocumentCreateFolderModal'

import { UploadContractCreateFolderModalProps } from './UploadContractCreateFolderModal.types'

export const UploadContractCreateFolderModal: React.FC<UploadContractCreateFolderModalProps> = ({ onClose }) => {
  const dispatch = useDispatch()
  const formRef: React.RefObject<FormikProps<CreateFolderBody>> = useRef(null)
  const folders = useSelector(foldersDataSelector)

  const onSubmit = (values: CreateFolderBody) => {
    const form = formRef.current
    const folderName = values.folderName.toLocaleLowerCase()
    const alreadyExist = folders.some((folder) => folder.name.toLocaleLowerCase() === folderName)

    if (form && alreadyExist) {
      form.setFieldError(CreateFolderFields.folderName, CREATE_FOLDER_ERRORS.FOLDER_EXISTS_ERROR)
      return
    }

    dispatch(
      createFolder({
        name: values.folderName,
        value: values.folderName,
      })
    )
    onClose()
  }

  return <UploadDocumentCreateFolderModal onClose={onClose} onSubmit={onSubmit} ref={formRef} />
}
