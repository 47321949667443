import React from 'react'
import { useTheme } from 'styled-components'

import { StyledThemeScheme } from '../../themes/light'

export const SmallArrowDownIcon: React.FC = (props) => {
  const theme = useTheme() as StyledThemeScheme

  return (
    <svg width="10" height="6" viewBox="0 0 10 6" fill="none" {...props}>
      <path
        d="M5.00081 5.99977L0.757812 1.75677L2.17281 0.342773L5.00081 3.17177L7.82881 0.342773L9.24381 1.75677L5.00081 5.99977Z"
        fill={theme.palette.grey}
      />
    </svg>
  )
}
