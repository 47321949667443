import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { DEFAULT_DATE_WITH_TIME_FORMAT } from '../../../../../constants/date'
import { historyActionsWithoutDocument } from '../../../../../constants/history'
import { useHistoryFetch } from '../../../../../hooks/contracts/useHistoryFetch'
import * as HISTORY_SELECTORS from '../../../../../store/contracts/history/history.selectors'
import translations from '../../../../../translations/keys'
import { ContractHistoryAction } from '../../../../../types/contractHistory'
import { getFormattedDate } from '../../../../../utils/date'
import { useShowFetchError } from '../../../../hooks/useShowFetchError'
import { ContractRouteParams } from '../../../../types/signing'
import Box from '../../../../ui/Box'
import Typography from '../../../../ui/Typography'
import { TYPOGRAPHY_NAMES } from '../../../../ui/Typography/Typography.types'
import { ContractHistorySkeleton } from '../../../Skeletons/ContractHistorySkeleton'

import { StyledItem } from './History.styles'

export const History = () => {
  const { t } = useTranslation()
  const { contractId } = useParams<ContractRouteParams>()
  const data = useSelector(HISTORY_SELECTORS.historyDataSelector)
  const fetchStatus = useSelector(HISTORY_SELECTORS.historyFetchStatusSelector)
  const error = useSelector(HISTORY_SELECTORS.historyErrorSelector)
  const isLoading = useSelector(HISTORY_SELECTORS.historyIsLoadingSelector)

  useHistoryFetch(contractId)
  useShowFetchError(fetchStatus, error)

  if (isLoading) {
    return <ContractHistorySkeleton />
  }

  const showDocumentByAction = (action: ContractHistoryAction) =>
    isHistoryActionWithoutDocument(action) ? null : `${t(translations.DOCUMENT)} `

  const isHistoryActionWithoutDocument = (action: ContractHistoryAction) =>
    historyActionsWithoutDocument.includes(action)

  return (
    <div>
      {data.map(({ createAt, fullName, ip, status: { important, action }, email, id }) => {
        const importantName = important ? TYPOGRAPHY_NAMES.bold14 : TYPOGRAPHY_NAMES.body14

        return (
          <StyledItem key={id}>
            <Typography name={TYPOGRAPHY_NAMES.body14} color="grey">
              {getFormattedDate(new Date(createAt), DEFAULT_DATE_WITH_TIME_FORMAT)}
            </Typography>
            <Box mt={0.25} mb={0.25}>
              <Typography name={TYPOGRAPHY_NAMES.bold14}>{fullName}</Typography>
            </Box>
            <span>{email}</span>
            <Box mb={0.25}>
              {t(translations.IP)} {ip}
            </Box>
            <Typography name={importantName} color={important ? 'green' : undefined}>
              {showDocumentByAction(action)}
              <Typography
                name={importantName}
                {...(isHistoryActionWithoutDocument(action) ? { isCapitalize: true } : { textCase: 'lowercase' })}
              >
                {t(translations[action])}
              </Typography>
            </Typography>
          </StyledItem>
        )
      })}
    </div>
  )
}
