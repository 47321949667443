import { Action } from 'redux'

import { FetchFailurePayload, FETCH_STATUSES } from '../../../types/fetch'
import { SIGNING_TYPES } from '../../../types/signingOrder'
import * as TYPES from '../../../types/recipients'
import { EnabledFeatures } from '../../../types/subscription'
import { DocumentMessageForm } from '../../../types/documents'

export interface RecipientsState {
  templateId: string | null
  templateName: string | null
  sender: TYPES.Sender | null
  order: SIGNING_TYPES
  byId: TYPES.RecipientsById
  byOrder: TYPES.RecipientsByOrder
  sorted: TYPES.RecipientsSorted
  fetch: {
    status: FETCH_STATUSES
    error: FetchFailurePayload | null
  }
  add: {
    status: FETCH_STATUSES
    error: FetchFailurePayload | null
  }
  addClmOnly: {
    status: FETCH_STATUSES
    error: FetchFailurePayload | null
  }
  fetchTemplateInfo: {
    status: FETCH_STATUSES
    error: FetchFailurePayload | null
  }
  isDocumentExists: boolean
  messageInitialValues: null | DocumentMessageForm
}

export enum RecipientsActions {
  'TEMPLATES_SET_ORDER' = 'TEMPLATES_SET_ORDER',
  'TEMPLATES_SET_RECIPIENTS' = 'TEMPLATES_SET_RECIPIENTS',
  'TEMPLATES_CLEAR_RECIPIENTS' = 'TEMPLATES_CLEAR_RECIPIENTS',
  'TEMPLATES_FETCH_RECIPIENTS' = 'TEMPLATES_FETCH_RECIPIENTS',
  'TEMPLATES_FETCH_ADD_RECIPIENTS' = 'TEMPLATES_FETCH_ADD_RECIPIENTS',
  'TEMPLATES_FETCH_ADD_CLM_ONLY_RECIPIENTS' = 'TEMPLATES_FETCH_ADD_CLM_ONLY_RECIPIENTS',
  'TEMPLATES_FETCH_TEMPLATE_INFO' = 'TEMPLATES_FETCH_TEMPLATE_INFO',
  'TEMPLATES_FETCH_SENDER_INFO' = 'TEMPLATES_FETCH_SENDER_INFO',
}

export interface SetOrderAction extends Action {
  payload: { order: SIGNING_TYPES }
}
export type ClearRecipientsAction = Action

export interface SetRecipientsAction extends Action {
  payload: { recipients: Required<TYPES.RecipientData>[] }
}

export interface FetchRecipientsAction extends Action {
  payload: { templateId: string }
}
export interface FetchRecipientsSuccessAction extends Action {
  payload: { recipients: Required<TYPES.RecipientData>[]; enabledFeatures: EnabledFeatures }
}

export interface FetchAddRecipientsAction extends Action {
  payload: { templateId: string; body: TYPES.AddRecipientsForm }
}
export interface FetchAddRecipientsSuccessAction extends Action {
  payload: { recipients: TYPES.RecipientData[] }
}

export interface FetchAddClmOnlyRecipientsAction extends Action {
  payload: { name: string; recipients: TYPES.AddRecipientsForm; folderName?: string }
}
export interface FetchAddClmOnlyRecipientsSuccessAction extends Action {
  payload: { templateId: string }
}

export interface FetchTemplateInfoAction extends Action {
  payload: { templateId: string }
}
export interface FetchTemplateInfoSuccessAction extends Action {
  payload: { name: string; isDocumentExists: boolean; messageInitialValues: DocumentMessageForm }
}
export interface FetchTemplateInfoSuccessArgs {
  name: string
  isDocumentExists: boolean
  messageInitialValues: DocumentMessageForm
}

export type FetchSenderInfoAction = Action
export interface FetchSenderInfoSuccessAction extends Action {
  payload: TYPES.Sender
}
