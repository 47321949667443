import { combineEpics, Epic, ofType } from 'redux-observable'
import { catchError, map, mergeMap, takeUntil } from 'rxjs/operators'

import { catchFetchError } from '../../../utils/catchFetchError'
import { templateService } from '../../../api'

import * as ACTIONS from './message.actions'

export const fetchSaveTemplateMessage: Epic = (action$) =>
  action$.pipe(
    ofType(ACTIONS.fetchSaveTemplateMessageTypes.request),
    mergeMap(({ payload }) =>
      templateService.fetchSaveTemplateMessage(payload.templateId, payload.body).pipe(
        map(() => ACTIONS.fetchSaveTemplateMessageSuccess()),
        catchError(catchFetchError(ACTIONS.fetchSaveTemplateMessageFailure)),
        takeUntil(
          action$.pipe(
            ofType(ACTIONS.fetchSaveTemplateMessageTypes.success, ACTIONS.fetchSaveTemplateMessageTypes.failure)
          )
        )
      )
    )
  )

export const messageEpics = combineEpics(fetchSaveTemplateMessage)
