import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { useTranslation } from 'react-i18next'

import translations from '../../../../../translations/keys'
import { FileSvg } from '../../../../assets/FileSvg'
import Button from '../../../../ui/Button'
import { BUTTON_VARIANTS } from '../../../../ui/Button/Button.types'
import Modal from '../../..//Modal'
import Typography from '../../../../ui/Typography'
import { TYPOGRAPHY_NAMES } from '../../../../ui/Typography/Typography.types'
import Box from '../../../../ui/Box'
import { Spinner } from '../../../../ui/Spinner/Spinner'
import {
  subscribeOnDownloadProgress,
  unsubscribeOnDownloadProgress,
} from '../../../../../store/contracts/download/download.actions'
import { downloadProgressIsSuccessSelector } from '../../../../../store/contracts/download/download.selectors'
import { ContractRouteParams } from '../../../../types/signing'

import { DownloadProgressModalProps } from './DownloadProgressModal.types'

export const DownloadProgressModal: React.FC<DownloadProgressModalProps> = ({ onClose }) => {
  const { contractId } = useParams<ContractRouteParams>()
  const dispatch = useDispatch()
  const isSuccess = useSelector(downloadProgressIsSuccessSelector)
  const { t } = useTranslation()
  const handleClose = useCallback(() => {
    dispatch(unsubscribeOnDownloadProgress())
    onClose()
  }, [])

  useEffect(() => {
    dispatch(subscribeOnDownloadProgress(contractId))
  }, [contractId])
  useEffect(() => {
    if (isSuccess) {
      handleClose()
    }
  }, [isSuccess, handleClose])

  return (
    <Modal onClose={handleClose}>
      <Box display="flex" flexDirection="column" alignItems="center" width={320} textAlign="center">
        <Box mb={2}>
          <FileSvg />
        </Box>
        <Box width={220} textAlign="center">
          <Typography name={TYPOGRAPHY_NAMES.H1}>{t(translations.PREPARING_FILE)}</Typography>
        </Box>
        <Box mt={2} mb={3}>
          {t(translations.WAIT_PREPARING_FILE)}
        </Box>
        <Box mb={3}>
          <Spinner show />
        </Box>
        <Button onClick={handleClose} fullWidth variant={BUTTON_VARIANTS.SECONDARY}>
          {t(translations.CANCEL)}
        </Button>
      </Box>
    </Modal>
  )
}
