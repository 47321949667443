import React, { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useTranslation } from 'react-i18next'

import translations from '../../../../../translations/keys'
import { Modal } from '../../../Modal/Modal'
import Search from '../../../Search'
import Typography from '../../../Typography'
import { TYPOGRAPHY_NAMES } from '../../../Typography/Typography.types'
import Box from '../../../Box'
import { useContactsFetch } from '../../../../../hooks/contacts/useContactsFetch'
import * as SELECTORS from '../../../../../store/contacts/contacts.selectors'
import { Spinner } from '../../../Spinner/Spinner'
import { setOffset, setSearchText } from '../../../../../store/contacts/contacts.actions'
import { modalsPayloadSelector } from '../../../../../store/modals/modals.selectors'
import { ADD_RECIPIENTS_MODALS } from '../../../../constants/addRecipients'
import { Contact as ContactData } from '../../../../../types/contacts'
import { ContactsModalSkeleton } from '../../../Skeletons/ContactsModalSkeleton'

import { ContactsModalProps } from './ContactsModal.types'
import { Contact } from './components/Contact/Contact'
import { StyledContacts } from './ContactsModal.styles'
import { CONTACTS_CONTENT_HEIGHT, CONTACTS_LIST_HEIGHT, CONTACTS_LIST_OFFSET } from './ContactsModal.constants'

export const ContactsModal: React.FC<ContactsModalProps> = ({ onClose, onSelect }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const contacts = useSelector(SELECTORS.contactsSortedSelector)
  const total = useSelector(SELECTORS.contactsTotalSelector)
  const offset = useSelector(SELECTORS.contactsOffsetSelector)
  const loading = useSelector(SELECTORS.contactsLoadingSelector)
  const isFirstLoad = useSelector(SELECTORS.contactsIsFirstLoadSelector)
  const searchText = useSelector(SELECTORS.contactsSearchTextSelector)
  const limit = useSelector(SELECTORS.contactsLimitSelector)
  const modalPayload = useSelector(modalsPayloadSelector(ADD_RECIPIENTS_MODALS.ADD_RECIPIENTS_CONTACTS))

  const isEmptyContacts = !contacts.length
  const hasMore = contacts.length < total
  const isLoading = isEmptyContacts && loading && isFirstLoad
  const showPlaceholder = isEmptyContacts && !searchText && !loading

  useContactsFetch()

  const next = () => {
    dispatch(setOffset(offset + limit, true))
  }
  const onChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setSearchText(e.target.value))
  }
  const onClearSearch = () => {
    dispatch(setSearchText(''))
  }
  const onSelectContact = (contact: ContactData) => {
    onSelect(contact, modalPayload?.recipientIndex)
    onClose()
  }
  const contactsList = useMemo(
    () =>
      contacts.length > 0
        ? contacts.map((contact) => (
            <Contact key={contact.id} data={contact} onSelect={onSelectContact} searchText={searchText} />
          ))
        : t(translations.CONTACTS_EMPTY_RESULTS),
    [contacts, searchText]
  )
  const renderContent = () => {
    if (isLoading) {
      return <ContactsModalSkeleton />
    } else if (showPlaceholder) {
      return (
        <Box pt={3} height={CONTACTS_CONTENT_HEIGHT}>
          {t(translations.CONTACTS_EMPTY_RESULTS)}
        </Box>
      )
    }

    return (
      <>
        <Box mt={5} mb={-1}>
          <Search
            placeholder={t(translations.SEARCH)}
            value={searchText}
            onChange={onChangeSearch}
            onClickIcon={onClearSearch}
            noError
          />
        </Box>
        <StyledContacts>
          <InfiniteScroll
            dataLength={contacts.length}
            next={next}
            hasMore={hasMore}
            loader={
              <Box mt={1}>
                <Spinner show />
              </Box>
            }
            height={CONTACTS_LIST_HEIGHT + CONTACTS_LIST_OFFSET}
          >
            {contactsList}
          </InfiniteScroll>
        </StyledContacts>
      </>
    )
  }

  return (
    <Modal onClose={onClose}>
      <Box mt={3} mb={3} width={640}>
        <Box textAlign="center">
          <Typography name={TYPOGRAPHY_NAMES.H1}>{t(translations.CHOOSE_CONTACT)}</Typography>
        </Box>
        {renderContent()}
      </Box>
    </Modal>
  )
}
