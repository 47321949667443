import { EnabledFeatures, EnabledLimits, SubscriptionFeature } from '../../../types/subscription'

export const splitLimitsAndFeatures = (features: SubscriptionFeature[]) =>
  features.reduce<{
    enabledFeatures: EnabledFeatures
    enabledLimits: EnabledLimits
  }>(
    (res, feature) => {
      if (feature.limit) {
        res.enabledLimits[feature.name] = feature.limit
      }

      res.enabledFeatures[feature.name] = true

      return res
    },
    { enabledFeatures: {}, enabledLimits: {} }
  )
