import { Epic, combineEpics, ofType } from 'redux-observable'
import { catchError, map, mergeMap } from 'rxjs/operators'
import { of } from 'rxjs'

import { localeService } from '../../api'

import * as TYPES from './industries.types'
import * as ACTIONS from './industries.actions'

const fetchIndustries: Epic = (action$) => {
  const prepareIndustries = (industries: string[]) =>
    industries.map((industry) => ({ name: industry, value: industry }))

  return action$.pipe(
    ofType(TYPES.IndustriesActions.FETCH_INDUSTRIES),
    mergeMap(() =>
      localeService.fetchIndustries().pipe(
        map((response) => ACTIONS.fetchIndustriesSuccess(prepareIndustries(response))),
        catchError((error) => of(ACTIONS.fetchIndustriesFailure(error.response)))
      )
    )
  )
}

export const industriesEpics = combineEpics(fetchIndustries)
