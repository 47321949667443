import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Form, Formik } from 'formik'
import { useTranslation } from 'react-i18next'

import {
  fetchEditProfileChangeEmail,
  resetEditProfileEmailData,
} from '../../../../../../../store/editProfile/editProfile.actions'
import { editProfileChangeEmailSelector } from '../../../../../../../store/editProfile/editProfile.selectors'
import { ChangeEmailFields, ChangeEmailFormValues } from '../../../../../../../types/changeEmail'
import { FETCH_STATUSES } from '../../../../../../../types/fetch'
import { useShowFetchError } from '../../../../../../hooks/useShowFetchError'
import { useDispatchUnmount } from '../../../../../../../hooks/useDispatchUnmount'
import Box from '../../../../../../ui/Box'
import { EmailIcon } from '../../../../../../assets/EmailIcon'
import Typography from '../../../../../../ui/Typography'
import { TYPOGRAPHY_NAMES } from '../../../../../../ui/Typography/Typography.types'
import translations from '../../../../../../../translations/keys'
import Button from '../../../../../../ui/Button'
import { changeEmailInitialValues } from '../../../../../../../initialValues/changeEmailInitialValues'
import { changeEmailValidationSchema } from '../../../../../../../validation/changeEmail/changeEmailValidationSchema'
import FormTextInput from '../../../../../../ui/Forms/FormTextInput'
import { EMAIL_MAX_LENGTH } from '../../../../../../../validation/emailValidationSchema'

import { ChangeEmailFormProps } from './ChangeEmailForm.types'

export const ChangeEmailForm: React.FC<ChangeEmailFormProps> = ({ onClose }) => {
  const dispatch = useDispatch()
  const emailData = useSelector(editProfileChangeEmailSelector)
  const changeEmailSuccess = emailData.fetchStatus === FETCH_STATUSES.SUCCESS
  const { t } = useTranslation()
  const handleSubmit = (values: ChangeEmailFormValues) => {
    dispatch(fetchEditProfileChangeEmail(values.email))
  }

  useDispatchUnmount(resetEditProfileEmailData)
  useShowFetchError(emailData.fetchStatus, emailData.error)

  return (
    <>
      <Box textAlign="center" mb={4}>
        <EmailIcon />
      </Box>
      <Box textAlign="center" mb={2}>
        <Typography name={TYPOGRAPHY_NAMES.H1}>{t(translations.CHANGE_EMAIL_TITLE)}</Typography>
      </Box>
      <Box textAlign="center" mb={3}>
        <p>{changeEmailSuccess ? t(translations.CHANGE_EMAIL_SUCCESS) : t(translations.CHANGE_EMAIL_DESCRIPTION)}</p>
      </Box>
      {changeEmailSuccess ? (
        <Button onClick={onClose} fullWidth>
          {t(translations.OK)}
        </Button>
      ) : (
        <Formik
          initialValues={changeEmailInitialValues}
          onSubmit={handleSubmit}
          validationSchema={changeEmailValidationSchema}
        >
          <Form>
            <Box mb={1}>
              <FormTextInput
                name={ChangeEmailFields.email}
                label={t(translations.CHANGE_EMAIL_LABEL)}
                placeholder={t(translations.CHANGE_EMAIL_PLACEHOLDER)}
                maxLength={EMAIL_MAX_LENGTH}
                trim
              />
            </Box>
            <Button fullWidth type="submit">
              {t(translations.CHANGE_EMAIL_BUTTON)}
            </Button>
          </Form>
        </Formik>
      )}
    </>
  )
}
