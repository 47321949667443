import { ConfirmationStatus } from '../../../types/confirmationActions'
import { Size } from '../SvgIcon/SvgIcon.types'

export type OnCancelConfirmation = (id: string, status: ConfirmationStatus) => void

export interface ConfirmationLabelProps {
  fullName: string
  date: string
  status: ConfirmationStatus
  id: string
  isContractOwner?: boolean
  size?: BoxSize
  iconSize?: Extract<Size, 'default' | 'extraSmall'>
  onCancel?: OnCancelConfirmation
  expandLabel?: boolean
  showLabel?: () => void
}

export enum BoxSize {
  compact = 'compact',
  tall = 'tall',
  default = 'default',
}
