import React from 'react'

export const EKYCConfirmedSvg: React.FC = (props) => (
  <svg width="156" height="96" viewBox="0 0 156 96" fill="none" {...props}>
    <path
      d="M85.9062 81.9688H71.375C70.5 81.9688 69.8125 81.2812 69.8125 80.4062C69.8125 79.5312 70.5 78.8438 71.375 78.8438H85.9062C86.7812 78.8438 87.4688 79.5312 87.4688 80.4062C87.4688 81.2812 86.75 81.9688 85.9062 81.9688Z"
      fill="#6085B3"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M56.125 18.7812H100.781V13.5938C100.781 12.2188 99.625 11.0938 98.1875 11.0938H58.7188C57.2813 11.0938 56.125 12.2188 56.125 13.5938V18.7812ZM56.125 74H100.812V69.9993C100.875 69.9998 100.937 70 101 70C101.984 70 102.954 69.9382 103.906 69.8181V82.4062C103.906 85.5 101.344 88 98.1875 88H58.7188C55.5625 88 53 85.5 53 82.4062V13.5938C53 10.5 55.5625 8 58.7188 8H98.2187C101.344 8 103.906 10.5 103.906 13.625V24.1819C102.954 24.0618 101.984 24 101 24C100.937 24 100.875 24.0002 100.812 24.0007V21.9062H56.125V74ZM98.2187 84.9062C99.6562 84.9062 100.812 83.7812 100.812 82.4062V77.0937H56.125V82.4062C56.125 83.7812 57.2813 84.9062 58.7188 84.9062H98.2187Z"
      fill="#6085B3"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M101 63C109.837 63 117 55.8366 117 47C117 38.1634 109.837 31 101 31C92.1634 31 85 38.1634 85 47C85 55.8366 92.1634 63 101 63ZM101 67C112.046 67 121 58.0457 121 47C121 35.9543 112.046 27 101 27C89.9543 27 81 35.9543 81 47C81 58.0457 89.9543 67 101 67Z"
      fill="#DFE1E6"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M111.914 39.5858C112.695 40.3668 112.695 41.6332 111.914 42.4142L99.9142 54.4142C99.1332 55.1953 97.8668 55.1953 97.0858 54.4142L89.5858 46.9142C88.8047 46.1332 88.8047 44.8668 89.5858 44.0858C90.3668 43.3047 91.6332 43.3047 92.4142 44.0858L98.5 50.1716L109.086 39.5858C109.867 38.8047 111.133 38.8047 111.914 39.5858Z"
      fill="#6085B3"
    />
  </svg>
)
