import * as TYPES from './viewOptions.types'

export const toggleSignatureNamesVisible = () => ({
  type: TYPES.ViewOptionsActions.CONTRACTS_TOGGLE_SIGNATURE_NAMES_VISIBLE,
})

export const setConfirmationLabel = (payload: TYPES.ConfirmationLabelPayload): TYPES.SetConfirmationLabelAction => ({
  type: TYPES.ViewOptionsActions.SET_CONFIRMATION_LABEL,
  payload,
})

export const unsetConfirmationLabel = () => ({
  type: TYPES.ViewOptionsActions.UNSET_CONFIRMATION_LABEL,
})

export const cleanViewOptions = () => ({
  type: TYPES.ViewOptionsActions.CLEAN_CONTRACTS_VIEW_OPTIONS,
})
