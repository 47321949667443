import { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router'

import translations from '../../../../translations/keys'
import { fetchDownloadContract } from '../../../../store/contracts/download/download.actions'
import { ContractRouteParams } from '../../../types/signing'
import { DownloadIcon, StopIcon, UsersIcon, ContourCrossIcon } from '../../../assets/icons'
import { openModal } from '../../../../store/modals/modals.actions'
import { CONTRACT_OWNER_VIEW_MODALS } from '../../../constants/contractOwnerView'
import {
  ownerViewContractSuccessSelector,
  ownerViewContractIsNegativeSelector,
  ownerViewContractIsCompletedSelector,
  ownerViewContractIsDeletedSelector,
  ownerViewContractIsLeftCorrectionSelector,
} from '../../../../store/contracts/ownerView/ownerView.selectors'
import { location } from '../../../../utils/location'

export const useActions = () => {
  const { contractId } = useParams<ContractRouteParams>()
  const history = useHistory()
  const dispatch = useDispatch()
  const isSuccess = useSelector(ownerViewContractSuccessSelector)
  const isNegative = useSelector(ownerViewContractIsNegativeSelector)
  const isCompleted = useSelector(ownerViewContractIsCompletedSelector)
  const isDeleted = useSelector(ownerViewContractIsDeletedSelector)
  const isLeftCorrectionStatus = useSelector(ownerViewContractIsLeftCorrectionSelector)

  console.log(isNegative, isLeftCorrectionStatus, "both conditions for expired");

  const actions = useMemo(() => {
    if (!isSuccess) {
      return []
    }

    const clearAndContinueContract = {
      buttonIcon: 'closeCircle',
      label: translations.CLEAR_AND_CONTINUE,
      onClick: () => {
        dispatch(openModal(CONTRACT_OWNER_VIEW_MODALS.OWNER_CLEAR_AND_CONTINUE_CONTRACT))
      },
      MobileIcon: ContourCrossIcon,
    }
    const voidContract = {
      buttonIcon: 'stop',
      label: translations.VOID,
      onClick: () => {
        dispatch(openModal(CONTRACT_OWNER_VIEW_MODALS.OWNER_VOID_CONTRACT))
      },
      MobileIcon: StopIcon,
    }
    const downloadContract = {
      buttonIcon: 'download',
      label: translations.DOWNLOAD,
      onClick: () => {
        dispatch(fetchDownloadContract(contractId))
      },
      MobileIcon: DownloadIcon,
    }
    const editContract = {
      buttonIcon: 'users',
      label: translations.EDIT_FLOW,
      onClick: () => {
        history.push(location.contractsAddRecipientsUrl(contractId))
      },
      MobileIcon: UsersIcon,
    }

    if(!isNegative && !isLeftCorrectionStatus){
      return [editContract, clearAndContinueContract, voidContract, downloadContract];
    }

    if (isNegative) {
      return isLeftCorrectionStatus
        ? [editContract, downloadContract, clearAndContinueContract]
        : [editContract, downloadContract]
    }

    if (isCompleted || isDeleted) {
      return [downloadContract]
    }

    return isLeftCorrectionStatus
      ? [editContract, clearAndContinueContract, voidContract, downloadContract]
      : [editContract, voidContract, downloadContract]
  }, [isSuccess, isNegative, contractId, isCompleted, isDeleted])

  return actions
}
