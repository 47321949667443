import { FETCH_STATUSES } from '../../../types/fetch'
import { RootState } from '../../rootReducer.types'

export const commentsByIdSelector = (state: RootState) => state.contracts.comments.byId
export const commentsByPagesSelector = (state: RootState) => state.contracts.comments.byPages
export const commentsCountSelector = (state: RootState) => Object.keys(state.contracts.comments.byId).length
export const commentsThreadSelector = (state: RootState) => state.contracts.comments.thread
export const commentsFetchStatusSelector = (state: RootState) => state.contracts.comments.fetch.status
export const commentsFetchErrorSelector = (state: RootState) => state.contracts.comments.fetch.error
export const commentsFetchThreadStatusSelector = (state: RootState) => state.contracts.comments.fetchThread.status
export const commentsFetchThreadErrorSelector = (state: RootState) => state.contracts.comments.fetchThread.error
export const commentsFetchCreateStatusSelector = (state: RootState) => state.contracts.comments.update.status
export const commentsFetchCreateErrorSelector = (state: RootState) => state.contracts.comments.update.error
export const commentsFetchUpdateSelector = (state: RootState) => state.contracts.comments.update

export const commentsFetchThreadRequestSelector = (state: RootState) =>
  state.contracts.comments.fetchThread.status === FETCH_STATUSES.REQUEST

export const commentsUpdateIsSuccess = (state: RootState) =>
  state.contracts.comments.update.status === FETCH_STATUSES.SUCCESS

export const commentsIsConfirmedSelector = (state: RootState) =>
  state.contracts.comments.confirm.status === FETCH_STATUSES.SUCCESS
export const commentsConfirmStatusSelector = (state: RootState) => state.contracts.comments.confirm.confirmStatus
export const commentsConfirmFetchStatusSelector = (state: RootState) => state.contracts.comments.confirm.status
export const commentsConfirmErrorSelector = (state: RootState) => state.contracts.comments.confirm.error

export const commentsCancelConfirmationSuccessSelector = (state: RootState) =>
  state.contracts.comments.cancelConfirmation.status === FETCH_STATUSES.SUCCESS
export const commentsCancelConfirmationStatusSelector = (state: RootState) =>
  state.contracts.comments.cancelConfirmation.confirmStatus
export const commentsCancelConfirmationFetchStatusSelector = (state: RootState) =>
  state.contracts.comments.cancelConfirmation.status
export const commentsCancelConfirmationErrorSelector = (state: RootState) =>
  state.contracts.comments.cancelConfirmation.error
