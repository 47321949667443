import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import translations from '../../../translations/keys'
import Typography from '../../ui/Typography'
import { TYPOGRAPHY_COMPONENTS, TYPOGRAPHY_NAMES } from '../../ui/Typography/Typography.types'
import * as USER_PLAN_SELECTORS from '../../../store/subscription/userPlan/userPlan.selectors'
import { useShowFetchError } from '../../hooks/useShowFetchError'
import { SUBSCRIPTION_IS_EXPIRED } from '../../../constants/subscription'

import Plan from './components/Plan'
import { StyledTitle } from './SubscriptionView.styles'

const EXCLUDE_CODES = [SUBSCRIPTION_IS_EXPIRED]

export const SubscriptionView = () => {
  const { t } = useTranslation()
  const userPlan = useSelector(USER_PLAN_SELECTORS.subscriptionUserPlanDataSelector)
  const userPlanFetchStatus = useSelector(USER_PLAN_SELECTORS.subscriptionUserPlanFetchStatusSelector)
  const userPlanError = useSelector(USER_PLAN_SELECTORS.subscriptionUserPlanErrorSelector)

  useShowFetchError(userPlanFetchStatus, userPlanError, EXCLUDE_CODES)

  return (
    <div>
      <StyledTitle>
        <Typography name={TYPOGRAPHY_NAMES.H3} component={TYPOGRAPHY_COMPONENTS.h3}>
          {t(translations.YOUR_PLAN)}
        </Typography>
      </StyledTitle>
      <Plan data={userPlan} expired={userPlanError?.lastErrorCode === SUBSCRIPTION_IS_EXPIRED} />
    </div>
  )
}
