import translations from '../../translations'
import { Contract, CONTRACT_STATUS } from '../../types/contracts'

export const RETURNED_WITH_CORRECTIONS = 'RETURNED_WITH_CORRECTIONS'

export const getStatuses = (contract: Contract): string[] => {
  const isPendingRecipient = contract.status === CONTRACT_STATUS.PENDING_RECIPIENTS

  if (!contract.owner && isPendingRecipient) {
    return contract.signed
      ? [CONTRACT_STATUS.SIGNED, CONTRACT_STATUS.PENDING_RECIPIENTS]
      : [CONTRACT_STATUS.READY_TO_SIGN]
  }

  if (contract.status === CONTRACT_STATUS.LEFT_CORRECTION) {
    return [RETURNED_WITH_CORRECTIONS]
  }

  return [CONTRACT_STATUS[contract.status]]
}

export const prepareStatus = (contract: Contract) =>
  getStatuses(contract)
    .map((status) => translations[status])
    .join(', ')
