import styled from 'styled-components'

export const Tooltip = styled.div<{ top: number; left: number }>`
  display: flex;
  position: absolute;
  top: ${({ top }) => top + 'px'};
  left: ${({ left }) => left + 'px'};
  min-width: 150px;
  min-height: 38px;
  align-items: center;
  justify-content: center;
  padding: 11px 16px;
  color: ${({ theme }) => theme.palette.white};
  text-align: center;
  background-color: ${({ theme }) => theme.palette.black};
  border-radius: 4px;
  ${({ theme }) => theme.textStyles.bodySmall12}
  z-index: 100;

  &:hover {
    opacity: 0;
  }
`
