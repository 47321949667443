import { Action } from 'redux'

import { FetchFailurePayload, FETCH_STATUSES } from '../../../types/fetch'

export interface ContactUsState {
  fetchStatus: FETCH_STATUSES
  error: FetchFailurePayload | null
}

export enum ContactUsActions {
  'FETCH_SUBSCRIPTION_CONTACT_US' = 'FETCH_SUBSCRIPTION_CONTACT_US',
  'CLEAR_SUBSCRIPTION_CONTACT_US' = 'CLEAR_SUBSCRIPTION_CONTACT_US',
}

export interface FetchContactUs extends Action {
  payload: { message: string }
}

export type FetchContactUsSuccess = Action
