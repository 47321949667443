import styled from 'styled-components'

import Box from '../../ui/Box'

export const StyledPlans = styled(Box).attrs({
  display: 'flex',
  alignItems: 'flex-start',
})`
  ${({ theme }) => `@media ${theme.devices.laptop} {
    align-items: initial;
    flex-wrap: wrap;
  }`}
`

export const StyledTitle = styled(Box).attrs({
  mb: 4,
})`
  ${({ theme }) => `@media ${theme.devices.mobile} {
    display: none;
  }`}
`
