import React from 'react'

import { SvgIconProps } from '../../ui/SvgIcon/SvgIcon.types'
import SvgIcon from '../../ui/SvgIcon'

export const FolderAddIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <path d="M11.3438 10.3852V12.375H9.36328C9.26719 12.375 9.1875 12.4477 9.1875 12.5391V13.5234C9.1875 13.6125 9.26719 13.6875 9.36328 13.6875H11.3438V15.6773C11.3438 15.7688 11.4187 15.8438 11.5078 15.8438H12.4922C12.5836 15.8438 12.6562 15.7688 12.6562 15.6773V13.6875H14.6367C14.7328 13.6875 14.8125 13.6125 14.8125 13.5234V12.5391C14.8125 12.4477 14.7328 12.375 14.6367 12.375H12.6562V10.3852C12.6562 10.2938 12.5836 10.2188 12.4922 10.2188H11.5078C11.4187 10.2188 11.3438 10.2938 11.3438 10.3852ZM20.625 6.99375H12.2109L9.46172 4.36406C9.42674 4.33132 9.38073 4.31291 9.33281 4.3125H3.375C2.96016 4.3125 2.625 4.64766 2.625 5.0625V18.9375C2.625 19.3523 2.96016 19.6875 3.375 19.6875H20.625C21.0398 19.6875 21.375 19.3523 21.375 18.9375V7.74375C21.375 7.32891 21.0398 6.99375 20.625 6.99375ZM19.6875 18H4.3125V6H8.73047L11.5336 8.68125H19.6875V18Z" />
  </SvgIcon>
)
