import React from 'react'

import { SvgIconProps } from '../../ui/SvgIcon/SvgIcon.types'
import SvgIcon from '../../ui/SvgIcon'

export const MoneyIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <path d="M12 1.5C6.20156 1.5 1.5 6.20156 1.5 12C1.5 17.7984 6.20156 22.5 12 22.5C17.7984 22.5 22.5 17.7984 22.5 12C22.5 6.20156 17.7984 1.5 12 1.5ZM12 20.7188C7.18594 20.7188 3.28125 16.8141 3.28125 12C3.28125 7.18594 7.18594 3.28125 12 3.28125C16.8141 3.28125 20.7188 7.18594 20.7188 12C20.7188 16.8141 16.8141 20.7188 12 20.7188ZM13.118 11.4562L12.5227 11.318V8.17031C13.4133 8.29219 13.9641 8.85 14.0578 9.53437C14.0695 9.62812 14.1492 9.69609 14.243 9.69609H15.2953C15.4055 9.69609 15.4922 9.6 15.4828 9.48984C15.3398 8.02969 14.1375 7.09219 12.532 6.93047V6.16406C12.532 6.06094 12.4477 5.97656 12.3445 5.97656H11.6859C11.5828 5.97656 11.4984 6.06094 11.4984 6.16406V6.9375C9.83906 7.09922 8.54062 8.01562 8.54062 9.72656C8.54062 11.3109 9.70781 12.075 10.9336 12.368L11.5125 12.5156V15.8602C10.4766 15.7219 9.89531 15.1688 9.77578 14.4234C9.76172 14.3344 9.68203 14.2687 9.59063 14.2687H8.50781C8.39766 14.2687 8.31094 14.3625 8.32031 14.4727C8.42578 15.7617 9.40313 16.9477 11.4891 17.1V17.8359C11.4891 17.9391 11.5734 18.0234 11.6766 18.0234H12.3422C12.4453 18.0234 12.5297 17.9391 12.5297 17.8336L12.525 17.0906C14.3602 16.9289 15.6727 15.9469 15.6727 14.1844C15.6703 12.5578 14.6367 11.8312 13.118 11.4562ZM11.5102 11.0766C11.3789 11.0391 11.2688 11.0039 11.1586 10.9594C10.3664 10.6734 9.99844 10.2117 9.99844 9.61641C9.99844 8.76562 10.643 8.28047 11.5102 8.17031V11.0766ZM12.5227 15.8672V12.7336C12.5953 12.7547 12.6609 12.7711 12.7289 12.7852C13.8375 13.1227 14.2102 13.5914 14.2102 14.3109C14.2102 15.2273 13.5211 15.7781 12.5227 15.8672Z" />
  </SvgIcon>
)
