import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

import translations from '../../../../translations/keys'
import { NOTIFICATION_MODAL_TYPES } from '../../../ui/Modals/NotificationModal/NotificationModal.constants'
import { NotificationModalView } from '../../../ui/Modals/NotificationModal/NotificationModalView'

interface DeleteContactModalProps {
  onConfirmDelete: () => void
  onClose: () => void
}

export const DeleteContactModal: FC<DeleteContactModalProps> = ({ onConfirmDelete, onClose }) => {
  const { t } = useTranslation()
  return (
    <NotificationModalView
      type={NOTIFICATION_MODAL_TYPES.WARNING}
      title={t(translations.DELETE_CONTACT)}
      description={t(translations.DELETE_CONTACT_DESCRIPTION)}
      successButtonText={t(translations.DELETE_CONTACT)}
      onClose={onClose}
      onSuccess={onConfirmDelete}
    />
  )
}
