import React, { forwardRef } from 'react'
import styled, { css } from 'styled-components'

import * as colors from '../../../themes/colors'
import { inputIcons } from '../InputIcons'
import translations from '../../../translations/keys'

import { ButtonProps, BUTTON_VARIANTS } from './Button.types'

const primaryBtn = css<{ compact?: boolean; textColor?: keyof typeof colors }>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: ${({ compact }) => (compact ? '32px' : '40px')};
  min-width: ${({ compact }) => (compact ? 'auto' : '120px')};
  line-height: 1;
  padding: 0 14px;
  color: ${(props) => props.theme.palette[props.textColor || 'white']};
  font-weight: bold;
  text-transform: uppercase;
  background: ${(props) => props.theme.palette.blueDark};
  border-radius: 4px;

  &:disabled {
    background-color: ${(props) => props.theme.palette.grey};
    pointer-events: none;
    cursor: default;
  }
  &:active {
    background: ${(props) => props.theme.palette.blueExtraDark};
  }
`

const linkBtn = css<{ textColor?: keyof typeof colors }>`
  color: ${(props) => props.theme.palette[props.textColor || 'blue']};
  background: transparent;
  align-items: center;

  &:hover {
    text-decoration: underline;
  }
  &:active {
    color: ${(props) => props.theme.palette.blueExtraDark};
  }

  &:disabled {
    cursor: default;
    color: ${(props) => props.theme.palette.grey};
    pointer-events: none;
  }
`
const textBtn = css<{ textColor?: keyof typeof colors }>`
  align-items: center;
  text-align: left;
  background: transparent;
  ${(props) => (props.textColor ? `color: ${props.theme.palette[props.textColor]};` : '')};

  &:disabled {
    color: ${(props) => props.theme.palette.grey};
    opacity: 0.75;
    pointer-events: none;
    cursor: default;
  }
`
const inactiveBtn = css<{ textColor?: keyof typeof colors }>`
  color: ${(props) => props.theme.palette[props.textColor || 'grey']};
  align-items: center;
  background: transparent;
  &:hover {
    text-decoration: underline;
  }

  &:disabled {
    text-decoration: none;
    cursor: default;
  }
`
const secondaryBtn = css`
  ${primaryBtn}
  background: ${(props) => props.theme.palette.white};
  color: ${(props) => props.theme.palette.blueDark};
  border-width: 1px;
  border-style: solid;
  border-color: ${(props) => props.theme.palette.blueDark};
  &:hover,
  &:active {
    background: ${(props) => props.theme.palette.blue};
    color: ${(props) => props.theme.palette.white};
  }
  &:disabled {
    background: ${(props) => props.theme.palette.white};
    border-color: ${(props) => props.theme.palette.greyLight};
    color: ${(props) => props.theme.palette.greyLight};
  }
`

const inheritBtn = css`
  border-width: 1px;
  border-style: solid;
  border-color: ${(props) => props.theme.palette.grey};
  border-radius: 4px;
  color: inherit;
  background-color: inherit;
  height: 40px;
  align-items: center;
  padding: 0 14px;
  font-weight: bold;
  text-transform: uppercase;
`

const StyledButton = styled.button<Pick<ButtonProps, 'fullWidth' | 'variant' | 'compact' | 'textColor'>>`
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  cursor: pointer;
  appearance: none;
  box-sizing: border-box;
  display: inline-flex;
  justify-content: center;
  ${({ theme }) => theme.textStyles.body14};
  ${({ fullWidth }) => fullWidth && `width: 100%;`}

  ${({ variant }) => {
    if (variant === BUTTON_VARIANTS.LINK) {
      return linkBtn
    }
    if (variant === BUTTON_VARIANTS.TEXT) {
      return textBtn
    }
    if (variant === BUTTON_VARIANTS.INACTIVE) {
      return inactiveBtn
    }
    if (variant === BUTTON_VARIANTS.SECONDARY) {
      return secondaryBtn
    }
    if (variant === BUTTON_VARIANTS.INHERIT) {
      return inheritBtn
    }

    return primaryBtn
  }}

  &::-moz-focus-inner {
    border-style: none;
  }
`
const StyledError = styled.span`
  display: block;
  color: ${(props) => props.theme.palette.errorColor};
  position: absolute;
`
const StyledIconWrapper = styled.span<{ noOffset: boolean }>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: ${({ noOffset }) => (noOffset ? '0px' : '8px')};
`

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      children,
      variant = BUTTON_VARIANTS.PRIMARY,
      error,
      fullWidth,
      icon,
      compact,
      textColor,
      iconColor = 'grey',
      'data-action': dataAction,
      ...props
    },
    ref
  ) => {
    const Icon = icon && inputIcons[icon]

    return (
      <>
        <StyledButton
          ref={ref}
          {...props}
          variant={variant}
          fullWidth={fullWidth}
          compact={compact}
          textColor={textColor}
          data-action={dataAction}
        >
          {Icon && (
            <StyledIconWrapper noOffset={!children} data-action={dataAction}>
              <Icon color={iconColor} data-action={dataAction} />
            </StyledIconWrapper>
          )}
          {children}
        </StyledButton>
        {!!error && <StyledError test-id="text-input-error">{translations[error] || error}</StyledError>}
      </>
    )
  }
)

Button.displayName = 'Button'

Button.defaultProps = {
  disabled: false,
  compact: false,
}
