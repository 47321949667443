import React from 'react'

import { SvgIconProps } from '../../ui/SvgIcon/SvgIcon.types'
import SvgIcon from '../../ui/SvgIcon'

export const CloudUploadIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} viewBox="0 0 48 48">
    <path d="M24.6045 21.5172C24.569 21.4724 24.5236 21.4361 24.4718 21.4112C24.4199 21.3863 24.3631 21.3733 24.3054 21.3733C24.2478 21.3733 24.1909 21.3863 24.1391 21.4112C24.0873 21.4361 24.0419 21.4724 24.0064 21.5172L18.6899 28.1594C18.6461 28.2147 18.6189 28.281 18.6115 28.3508C18.604 28.4206 18.6166 28.4911 18.6478 28.5542C18.679 28.6173 18.7275 28.6704 18.7879 28.7075C18.8482 28.7446 18.9179 28.7643 18.989 28.7641H22.4969V40.1266C22.4969 40.3329 22.6678 40.5016 22.8766 40.5016H25.7247C25.9336 40.5016 26.1045 40.3329 26.1045 40.1266V28.7688H29.6219C29.9399 28.7688 30.1155 28.4079 29.9209 28.1641L24.6045 21.5172Z" />
    <path d="M38.5169 17.1891C36.3429 11.5266 30.8033 7.5 24.3144 7.5C17.8255 7.5 12.2859 11.5219 10.1118 17.1844C6.04381 18.2391 3.03906 21.9 3.03906 26.25C3.03906 31.4297 7.28748 35.625 12.528 35.625H14.4315C14.6403 35.625 14.8112 35.4562 14.8112 35.25V32.4375C14.8112 32.2313 14.6403 32.0625 14.4315 32.0625H12.528C10.9283 32.0625 9.42356 31.4344 8.3033 30.2953C7.1878 29.1609 6.59444 27.6328 6.64666 26.0484C6.68938 24.8109 7.11659 23.6484 7.89033 22.6688C8.68305 21.6703 9.79381 20.9437 11.028 20.6203L12.827 20.1562L13.4868 18.4406C13.8951 17.3719 14.4647 16.3734 15.1815 15.4688C15.8891 14.5721 16.7273 13.7838 17.6688 13.1297C19.6198 11.775 21.9172 11.0578 24.3144 11.0578C26.7115 11.0578 29.009 11.775 30.9599 13.1297C31.9046 13.7859 32.74 14.5734 33.4473 15.4688C34.1641 16.3734 34.7337 17.3766 35.1419 18.4406L35.797 20.1516L37.5913 20.6203C40.1641 21.3047 41.9631 23.6156 41.9631 26.25C41.9631 27.8016 41.3508 29.2641 40.24 30.3609C39.6953 30.902 39.0473 31.331 38.3335 31.6231C37.6197 31.9151 36.8544 32.0645 36.0818 32.0625H34.1783C33.9694 32.0625 33.7986 32.2313 33.7986 32.4375V35.25C33.7986 35.4562 33.9694 35.625 34.1783 35.625H36.0818C41.3223 35.625 45.5707 31.4297 45.5707 26.25C45.5707 21.9047 42.5755 18.2484 38.5169 17.1891Z" />
  </SvgIcon>
)
