import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { roundNumber } from '../../../utils/numbers'
import useImage from '../../hooks/useImage'
import DocViewerToolbar from '../DocViewerToolbar'
import { MinusIcon, PlusIcon } from '../../assets/icons'
import { signingIsViewSelector } from '../../../store/contracts/signing/signing.selectors'
import DocViewerMobileToolbar from '../../ui/DocViewerMobileToolbar'

import useViewer from './useViewer'
import { DocViewerProps, Zoom } from './DocViewer.types'
import * as Styled from './DocViewer.styles'
import { DocPage } from './DocPage'
import { DOC_VIEWER_WRAPPER_ID } from './DocViewer.constants'

const HUNDRED = 100
const PAGE_INDICATOR_TIME = 5000

export const DocViewer: React.FC<DocViewerProps> = ({
  pages = [],
  placementById,
  placementByPages,
  fieldsById,
  fieldsByPages,
  strikesById,
  strikesByPages,
  correctionsById,
  correctionsByPages,
  commentsById,
  commentsByPages,
  fieldsFontSize,
  readonly = false,
  isSignatureEditable = true,
  scrollToElemId,
  actions,
  mobileActions,
  onElemChange,
  onElemCreate,
  onElemClick,
  onElemBlur,
  onElemSave,
  onElemDelete,
  onFieldChange,
  onToolbarChange,
  onElemConfirm,
  onElemCancelConfirmation,
  recipientToken,
  isSignatureNamesVisible,
}) => {
  const isView = useSelector(signingIsViewSelector)
  const [canvasRef, setCanvasRef] = useState<HTMLDivElement | null>(null)
  const [contentRef, setContentRef] = useState<HTMLDivElement | null>(null)
  const [scrollbarRef, setScrollbarRef] = useState<HTMLDivElement | null>(null)
  const [currentPage, setCurrentPage] = useState(1)
  const [timer, setTimer] = useState<ReturnType<typeof setTimeout>>()
  const [showPageIndicator, setShowPageIndicator] = useState(false)
  const firstPageImg = useImage(pages[0], recipientToken)
  const {
    drawingComp,
    zoomIndicator,
    selectedElem,
    movingElem,
    resizingElem,
    scrollToElem,
    onInit,
    onZoom,
  } = useViewer(canvasRef, contentRef, scrollbarRef, readonly, onElemChange, onElemCreate, onElemBlur)

  const zoom = roundNumber(zoomIndicator * HUNDRED)
  const total = pages.length
  const pageIndicatorText = `Page ${currentPage} of ${total}`
  const displayPages = Boolean(firstPageImg)

  const handleCurrentPageChange = (page: number) => {
    if (currentPage !== page) {
      setCurrentPage(page)
    }
  }

  const handleZoom = (direction: Zoom) => () => {
    onZoom(direction)
  }

  const handlePageIndicator = (isVisible: boolean) => () => {
    clearTimeout(timer as ReturnType<typeof setTimeout>)

    if (isVisible) {
      setShowPageIndicator(true)
    } else {
      setTimer(setTimeout(() => setShowPageIndicator(false), PAGE_INDICATOR_TIME))
    }
  }

  useEffect(() => {
    if (firstPageImg) {
      onInit()
    }
  }, [firstPageImg])

  useEffect(() => {
    if (scrollToElemId) {
      scrollToElem(scrollToElemId)
    }
  }, [scrollToElemId])
  return (
    <Styled.Wrapper id={DOC_VIEWER_WRAPPER_ID} dir="ltr">
      <Styled.Canvas ref={setCanvasRef} isVisible={displayPages}>
        <Styled.Content ref={setContentRef}>
          {displayPages &&
            canvasRef &&
            pages.map((src, idx) => (
              <DocPage
                key={src}
                src={src}
                page={idx}
                drawingComp={drawingComp}
                width={firstPageImg!.width}
                height={firstPageImg!.height}
                selectedElem={selectedElem}
                movingElem={movingElem}
                resizingElem={resizingElem}
                placesById={placementById}
                placesSorted={placementByPages && placementByPages[idx]}
                fieldsById={fieldsById}
                fieldsSorted={fieldsByPages && fieldsByPages[idx]}
                fieldsFontSize={fieldsFontSize}
                strikesById={strikesById}
                strikesSorted={strikesByPages && strikesByPages[idx]}
                correctionsById={correctionsById}
                correctionsSorted={correctionsByPages && correctionsByPages[idx]}
                commentsById={commentsById}
                commentsSorted={commentsByPages && commentsByPages[idx]}
                readonly={readonly}
                isSignatureEditable={isSignatureEditable}
                onCurrentPageChange={handleCurrentPageChange}
                onElemDelete={onElemDelete}
                onFieldChange={onFieldChange}
                onElemClick={onElemClick}
                onElemSave={onElemSave}
                onElemConfirm={onElemConfirm}
                onElemCancelConfirmation={onElemCancelConfirmation}
                recipientToken={recipientToken}
                isSignatureNamesVisible={isSignatureNamesVisible}
              />
            ))}
        </Styled.Content>
      </Styled.Canvas>
      {displayPages && (
        <Styled.ZoomControlsWrapper>
          <Styled.ZoomBtn onClick={handleZoom(-1)}>
            <MinusIcon color="white" />
          </Styled.ZoomBtn>
          <Styled.ZoomIndicator>{zoom}%</Styled.ZoomIndicator>
          <Styled.ZoomBtn onClick={handleZoom(1)}>
            <PlusIcon color="white" />
          </Styled.ZoomBtn>
        </Styled.ZoomControlsWrapper>
      )}
      <Styled.PageIndicator show={showPageIndicator}>{pageIndicatorText}</Styled.PageIndicator>
      <Styled.Scrollbar
        ref={setScrollbarRef}
        onPointerEnter={handlePageIndicator(true)}
        onPointerLeave={handlePageIndicator(false)}
      />
      {actions && !isView && (
        <DocViewerToolbar activeDrawingElem={drawingComp?.name} controls={actions} onChange={onToolbarChange} />
      )}
      {mobileActions && !isView && (
        <DocViewerMobileToolbar actions={mobileActions} activeDrawingElem={drawingComp?.name} />
      )}
    </Styled.Wrapper>
  )
}
