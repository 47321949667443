import { useState, useEffect } from 'react'
import { useTheme } from 'styled-components'

import { StyledThemeScheme } from '../../themes/light'

import useMediaQuery from './useMediaQuery'

const useProvideNavControls = () => {
  const theme = useTheme() as StyledThemeScheme
  const tabletScreen = useMediaQuery(theme.devices.tablet)
  const laptopScreen = useMediaQuery(theme.devices.laptop)
  const [mainmenuOpen, setMainmenuOpen] = useState(false)
  const [sidebarOpen, setSidebarOpen] = useState(!tabletScreen)

  useEffect(() => {
    if (tabletScreen) {
      setSidebarOpen(false)
      setMainmenuOpen(false)
    } else if (laptopScreen) {
      setSidebarOpen(true)
    }
  }, [tabletScreen, laptopScreen])

  return { sidebarOpen, setSidebarOpen, mainmenuOpen, setMainmenuOpen }
}

export default useProvideNavControls
