export enum Variant {
  SUCCESS = 'success',
  WARNING = 'warning',
}

export type NotificationVariant = Variant.SUCCESS | Variant.WARNING

export interface Notification {
  message: string
  variant: NotificationVariant
}
