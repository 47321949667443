import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { modalsPayloadSelector } from '../../../../../store/modals/modals.selectors'
import translations from '../../../../../translations/keys'
import { NOTIFICATION_MODAL_TYPES } from '../../../../ui/Modals/NotificationModal/NotificationModal.constants'
import { NotificationModalView } from '../../../../ui/Modals/NotificationModal/NotificationModalView'
import { clearDeleteTemplate, fetchDeleteTemplate } from '../../../../../store/templates/delete/delete.actions'
import {
  templatesDeleteSuccessSelector,
  templatesDeleteFetchStatusSelector,
  templatesDeleteErrorSelector,
} from '../../../../../store/templates/delete/delete.selectors'
import { useShowFetchError } from '../../../../hooks/useShowFetchError'
import { useDispatchUnmount } from '../../../../../hooks/useDispatchUnmount'
import { TEMPLATES_MODALS } from '../../../../../constants/templates'

import { DeleteTemplateModalProps } from './DeleteTemplateModal.types'

export const DeleteTemplateModal: React.FC<DeleteTemplateModalProps> = ({ onClose, onDeleted }) => {
  const dispatch = useDispatch()
  const { description, id, isFolder } = useSelector(modalsPayloadSelector(TEMPLATES_MODALS.DELETE_TEMPLATE))
  const deletedSuccessfully = useSelector(templatesDeleteSuccessSelector)
  const fetchStatus = useSelector(templatesDeleteFetchStatusSelector)
  const error = useSelector(templatesDeleteErrorSelector)

  useEffect(() => {
    if (deletedSuccessfully) {
      onClose()
      onDeleted && onDeleted()
    }
  }, [deletedSuccessfully, onDeleted])
  useShowFetchError(fetchStatus, error)
  useDispatchUnmount(clearDeleteTemplate)

  const handleSuccess = () => {
    dispatch(fetchDeleteTemplate(id, isFolder))
  }

  return (
    <NotificationModalView
      type={NOTIFICATION_MODAL_TYPES.WARNING}
      onClose={onClose}
      description={description}
      successButtonText={translations.DELETE}
      onSuccess={handleSuccess}
    />
  )
}
