import React from 'react'

export const WarningIcon: React.FC = (props) => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M7.53902 31.0938C7.53902 31.7852 8.09762 32.3438 8.78902 32.3438H31.2109C31.9023 32.3438 32.4609 31.7852 32.4609 31.0938V21.9922C32.4609 15.1094 26.8828 9.53125 20 9.53125C13.1171 9.53125 7.53902 15.1094 7.53902 21.9922V31.0938ZM10.3515 21.9922C10.3515 16.6641 14.6718 12.3438 20 12.3438C25.3281 12.3438 29.6484 16.6641 29.6484 21.9922V29.5312H15.7812V22.8516C15.7812 22.6367 15.6054 22.4609 15.3906 22.4609H13.6718C13.457 22.4609 13.2812 22.6367 13.2812 22.8516V29.5312H10.3515V21.9922ZM8.47262 12.1289L10.0195 10.582C10.1406 10.4609 10.1406 10.2617 10.0195 10.1406L7.36715 7.48828C7.30842 7.43012 7.2291 7.3975 7.14645 7.3975C7.06379 7.3975 6.98448 7.43012 6.92574 7.48828L5.37887 9.03516C5.32071 9.09389 5.28809 9.1732 5.28809 9.25586C5.28809 9.33851 5.32071 9.41783 5.37887 9.47656L8.03121 12.1289C8.15231 12.25 8.34762 12.25 8.47262 12.1289ZM34.6289 9.03516L33.082 7.48828C33.0233 7.43012 32.9439 7.3975 32.8613 7.3975C32.7786 7.3975 32.6993 7.43012 32.6406 7.48828L29.9882 10.1406C29.9301 10.1994 29.8975 10.2787 29.8975 10.3613C29.8975 10.444 29.9301 10.5233 29.9882 10.582L31.5351 12.1289C31.6562 12.25 31.8554 12.25 31.9765 12.1289L34.6289 9.47656C34.75 9.35156 34.75 9.15625 34.6289 9.03516ZM32.5 34.8438H7.49996C6.80856 34.8438 6.24996 35.4023 6.24996 36.0938V37.0312C6.24996 37.2031 6.39059 37.3438 6.56246 37.3438H33.4375C33.6093 37.3438 33.75 37.2031 33.75 37.0312V36.0938C33.75 35.4023 33.1914 34.8438 32.5 34.8438ZM18.9062 7.03125H21.0937C21.2656 7.03125 21.4062 6.89062 21.4062 6.71875V2.96875C21.4062 2.79688 21.2656 2.65625 21.0937 2.65625H18.9062C18.7343 2.65625 18.5937 2.79688 18.5937 2.96875V6.71875C18.5937 6.89062 18.7343 7.03125 18.9062 7.03125Z"
      fill="#EB3434"
    />
  </svg>
)
