import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { RECIPIENTS_DIAGRAM_MODALS } from '../../../../constants/recipientsDiagram'
import { CONTRACT_SIGN_MODALS } from '../../../../constants/contractSign'
import { modalsVisibleSelector } from '../../../../../store/modals/modals.selectors'
import { closeModal } from '../../../../../store/modals/modals.actions'
import { DeleteRecipientModal } from '../../../../components/Modals/RecipientsModals/DeleteRecipientModal/DeleteRecipientModal'
import { EditRecipientModal } from '../../../../components/Modals/RecipientsModals/EditRecipientModal/EditRecipientModal'
import ViewRecipientModal from '../../../../components/Modals/RecipientsModals/ViewRecipientModal'
import FinishLaterModal from '../../SignContractModals/FinishLaterModal'

import { RecipientsProgressModalsProps } from './RecipientsProgressModals.types'

export const RecipientsProgressModals: React.FC<RecipientsProgressModalsProps> = ({
  isOwner,
  onUpdate,
  onConfirmDelete,
}) => {
  const dispatch = useDispatch()

  const showViewRecipientModal = useSelector(modalsVisibleSelector(RECIPIENTS_DIAGRAM_MODALS.VIEW_DIAGRAM_RECIPIENT))
  const handleCloseViewRecipientModal = () => {
    dispatch(closeModal(RECIPIENTS_DIAGRAM_MODALS.VIEW_DIAGRAM_RECIPIENT))
  }

  const showDeleteRecipientModal = useSelector(
    modalsVisibleSelector(RECIPIENTS_DIAGRAM_MODALS.DELETE_DIAGRAM_RECIPIENT)
  )
  const handleCloseDeleteRecipientModal = () => {
    dispatch(closeModal(RECIPIENTS_DIAGRAM_MODALS.DELETE_DIAGRAM_RECIPIENT))
  }

  const showEditRecipientModal = useSelector(modalsVisibleSelector(RECIPIENTS_DIAGRAM_MODALS.EDIT_DIAGRAM_RECIPIENT))
  const handleCloseEditRecipientModal = () => {
    dispatch(closeModal(RECIPIENTS_DIAGRAM_MODALS.EDIT_DIAGRAM_RECIPIENT))
  }

  const showFinishSignLater = useSelector(modalsVisibleSelector(CONTRACT_SIGN_MODALS.FINISH_SIGN_LATER))
  const onCloseFinishSignLater = () => {
    dispatch(closeModal(CONTRACT_SIGN_MODALS.FINISH_SIGN_LATER))
  }

  return (
    <>
      {showViewRecipientModal && <ViewRecipientModal isOwner={isOwner} onClose={handleCloseViewRecipientModal} />}
      {showDeleteRecipientModal && onConfirmDelete && (
        <DeleteRecipientModal onDelete={onConfirmDelete} onClose={handleCloseDeleteRecipientModal} />
      )}
      {showEditRecipientModal && (
        <EditRecipientModal shouldConfirmUpdate onUpdate={onUpdate} onClose={handleCloseEditRecipientModal} />
      )}
      {showFinishSignLater && <FinishLaterModal onClose={onCloseFinishSignLater} />}
    </>
  )
}
