export enum SIGNING_TYPES {
  SEQUENTIAL = 'sequential',
  SEPARATE = 'separate',
}

export enum SigningOrderFields {
  order = 'order',
}

export interface SigningOrderForm {
  order: SIGNING_TYPES
}
