import { DocCommentsById, DocCommentsByPages } from '../../../types/comment'
import { DocCorrectionsById, DocCorrectionsByPages } from '../../../types/correction'
import { DocFieldsById, DocFieldsByPages } from '../../../types/documentField'
import { DrawableCompNames, DrawableElem } from '../../../types/drawableElem'
import { EditableElem, ResizeControls } from '../../../types/editableElem'
import { Place, PlacementById, PlacementByPages } from '../../../types/placement'
import { DocStrikesById, DocStrikesByPages } from '../../../types/strikethrough'
import { OnConfirm } from '../ConfirmationActions/ConfirmationActions.types'
import { OnCancelConfirmation } from '../ConfirmationLabel/ConfirmationLabel.types'
import { MobileActions } from '../DocViewerMobileToolbar/DocViewerMobileToolbar.types'
import { ToolbarControls } from '../DocViewerToolbar/DocViewerToolbar.types'

export type Bounds = {
  minX: number
  maxX: number
  minY: number
  maxY: number
}

export type ElemBounds = Bounds & { minWidth: number; maxWidth: number; minHeight: number; maxHeight: number }

export type Coords = { x: number; y: number }

export type Zoom = -1 | 1

export interface DrawingComp {
  name: DrawableCompNames
  page?: number
}

export type PlacesPage = {
  [idx: string]: {
    signatures: Place[]
    initials: Place[]
  }
}

export interface DocViewerProps {
  pages: string[]
  placementById?: PlacementById
  placementByPages?: PlacementByPages
  fieldsById?: DocFieldsById
  fieldsByPages?: DocFieldsByPages
  strikesById?: DocStrikesById
  strikesByPages?: DocStrikesByPages
  correctionsById?: DocCorrectionsById
  correctionsByPages?: DocCorrectionsByPages
  commentsById?: DocCommentsById
  commentsByPages?: DocCommentsByPages
  fieldsFontSize?: number
  readonly?: boolean
  isSignatureEditable?: boolean
  scrollToElemId?: string
  actions?: ToolbarControls
  mobileActions?: MobileActions
  recipientToken?: string
  isSignatureNamesVisible?: boolean
  onElemChange?: (place: EditableElem) => void
  onElemCreate?: (place: DrawableElem) => void
  onElemClick?: (id: string, place: HTMLDivElement) => void
  onElemBlur?: (id: string, isSaveDisabled: boolean) => void
  onElemSave?: (id: string) => void
  onElemDelete?: (id: string) => void
  onElemConfirm?: OnConfirm
  onElemCancelConfirmation?: OnCancelConfirmation
  onFieldChange?: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  onToolbarChange?: (name: string, value: string | number) => void
}

export interface DocPageProps {
  src: string
  page: number
  drawingComp?: DrawingComp | null
  width: number
  height: number
  selectedElem: string | null
  movingElem?: string | null
  resizingElem?: string | null
  placesById?: PlacementById
  placesSorted?: string[]
  fieldsById?: DocFieldsById
  fieldsSorted?: string[]
  fieldsFontSize?: number
  strikesById?: DocStrikesById
  strikesSorted?: string[]
  correctionsById?: DocCorrectionsById
  correctionsSorted?: string[]
  commentsById?: DocCommentsById
  commentsSorted?: string[]
  readonly?: boolean
  isSignatureEditable?: boolean
  recipientToken?: string
  isSignatureNamesVisible?: boolean
  onCurrentPageChange: (page: number) => void
  onFieldChange?: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  onElemClick?: (id: string, place: HTMLDivElement) => void
  onElemSave?: (id: string) => void
  onElemDelete?: (id: string) => void
  onElemConfirm?: OnConfirm
  onElemCancelConfirmation?: OnCancelConfirmation
}

export enum PointerModes {
  PAN = 'pan',
  SCROLL = 'scroll',
  DRAG = 'drag',
  RESIZE = 'resize',
  DRAW = 'draw',
}

export interface Size {
  width: number
  height: number
}

export interface Pointer {
  mode: PointerModes | null
  events: PointerEvent[]
  elem: HTMLDivElement | null
  bounds: Bounds | null
  elemBounds: ElemBounds | null
  startCoords: Coords
  scale: number
  contentPosition: Coords
  prevPosition: Coords
  prevSize: Size
  pinchStartDistance: number | null
  pinchStartScale: number | null
  resizeDirection: ResizeControls
  // drawingComp.page in state is not updating correctly between pointerDown
  // and pointerUp handlers that's why it has to be repeated in this ref
  currentPage: number | null
}
