import React from 'react'

import { SvgIconProps } from '../../ui/SvgIcon/SvgIcon.types'
import SvgIcon from '../../ui/SvgIcon'

export const ChevronLeftIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <path d="m 7.3664278,12.590776 10.5656302,8.25233 c 0.02761,0.02174 0.06079,0.03524 0.09573,0.03897 0.03494,0.0037 0.07022,-0.0025 0.10179,-0.01789 0.03158,-0.01542 0.05816,-0.03943 0.0767,-0.06928 0.01854,-0.02985 0.02829,-0.06432 0.02812,-0.09946 v -1.81172 c 0,-0.11484 -0.0539,-0.22499 -0.14297,-0.29531 L 9.6539278,12.000176 18.091428,5.4118758 c 0.09141,-0.0703 0.14297,-0.1805 0.14297,-0.2953 v -1.8118 c 0,-0.157 -0.18047,-0.2437 -0.30234,-0.1476 L 7.3664278,11.409476 c -0.0898,0.0701 -0.1624,0.1597 -0.2124,0.262 -0.0499,0.1024 -0.0759,0.2148 -0.0759,0.3287 0,0.1139 0.026,0.2262 0.0759,0.3286 0.05,0.1023 0.1226,0.1919 0.2124,0.262 z" />
  </SvgIcon>
)
