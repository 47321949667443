import styled from 'styled-components'

import { MainTemplateProps } from './MainTemplate.types'

export const StyledWrapper = styled.main<Pick<MainTemplateProps, 'simple'>>`
  display: flex;
  height: 100%;
  padding-top: ${({ theme }) => theme.sizes.layout.headerHeight};
  padding-left: ${({ theme, simple }) => (simple ? 0 : theme.sizes.layout.menuWidth)};

  ${({ theme }) => `@media ${theme.devices.tablet} {
    padding-top: ${theme.sizes.layout.headerHeightMobile};
    padding-left: 0;
  }`}
`
export const StyledContent = styled.div<{ isBannerVisible?: boolean }>`
  overflow-y: auto;
  width: 100%;
  padding: 32px 32px 0;
  position: relative;
  margin-top: ${({ isBannerVisible }) => (isBannerVisible ? '50px' : 0)};

  // Fixes bottom padding bug in Firefox and IE11
  &:after {
    content: '';
    display: block;
    padding-bottom: 32px;
  }

  ${({ theme }) => `@media ${theme.devices.mobile} {
    padding-top: ${theme.spacing8x[3]};
    padding-left: ${theme.spacing8x[2]};
    padding-right: ${theme.spacing8x[2]};
  }`}
`
