import React from 'react'

import * as Styled from './Delimiter.styles'
import { DelimiterProps } from './Delimiter.types'

export const Delimiter: React.FC<DelimiterProps> = ({ hideArrow }) => (
  <Styled.Wrapper data-testid="delimiter-wrapper">
    <Styled.Line data-testid="delimiter-line" />
    {!hideArrow && <Styled.Arrow data-testid="delimiter-arrow" />}
  </Styled.Wrapper>
)
