import { Option } from '../../types/options'

interface Keys {
  nameKey: string
  valueKey: string
}

export const prepareOptions = <D extends { [key: string]: string }>(data: D[], keys: Keys): Option[] =>
  data.map((item) => ({
    name: item[keys.nameKey],
    value: item[keys.valueKey],
  }))
