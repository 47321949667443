import {
  DocComment,
  DocCommentRequestBody,
  DocCommentsById,
  DocCommentsByPages,
  DocCommentsRequestBody,
  DocCommentsThread,
  IComment,
} from '../../types/comment'
import { EditableElem } from '../../types/editableElem'
import omit from '../omit'
import uid from '../uid'

type DocCommentsWithPages = { byId: DocCommentsById; byPages: DocCommentsByPages }

export const addCommentById = (comment: DocComment, commentsById: DocCommentsById): DocCommentsWithPages => {
  const id = comment.id
  const newCommentsById = id && !commentsById[id] ? { ...commentsById, [id]: comment } : commentsById

  const [byId, byPages] = prepareCommentsForStore(Object.values(newCommentsById))

  return { byId, byPages }
}

export const updateCommentById = (comment: EditableElem, commentsById: DocCommentsById): DocCommentsById => {
  const id = comment.id!
  const newComment = { ...commentsById[id], ...comment }
  return { ...commentsById, [id]: newComment }
}

export const removeCommentById = (id: string, commentsById: DocCommentsById): DocCommentsWithPages => {
  const newCommentsById = {
    ...omit([id], commentsById),
  }
  const [byId, byPages] = prepareCommentsForStore(Object.values(newCommentsById))

  return { byId, byPages }
}

export const prepareCommentsForStore = (comments: DocComment[]): [DocCommentsById, DocCommentsByPages] => {
  const byId: DocCommentsById = {}
  const byPages: DocCommentsByPages = {}

  comments.forEach((comment) => {
    const { page } = comment
    const id = comment.id || uid()

    byId[id] = { id, ...comment }
    byPages[page] = (byPages[page] || []).concat(id)
  })

  return [byId, byPages]
}

export const prepareCommentsThreadForStore = (comments: IComment[]): DocCommentsThread[] =>
  comments.map((comment) => ({
    id: comment.id,
    recipientId: comment.recipient.id,
    date: new Date(comment.date),
    message: comment.message,
    page: comment.page,
    x: comment.x,
    y: comment.y,
    owner: comment.owner,
  }))

export const prepareCommentForRequest = ({
  x,
  y,
  page,
  mentions,
  message = '',
}: DocComment): DocCommentRequestBody => ({
  x,
  y,
  page,
  mentions,
  message: message.trim(),
})

export const prepareCommentsForRequest = (byId: DocCommentsById): DocCommentsRequestBody => {
  const comments = Object.values(byId).map((comment) => omit(['id'], comment))

  return {
    comments,
  }
}
