import React from 'react'

import { SvgIconProps } from '../../ui/SvgIcon/SvgIcon.types'
import SvgIcon from '../../ui/SvgIcon'

export const FilterIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <path d="M20.626 3.60938H3.37128C2.79707 3.60938 2.43847 4.23516 2.72675 4.73438L8.17832 14.0016V19.6406C8.17832 20.0555 8.51113 20.3906 8.92363 20.3906H15.0736C15.4861 20.3906 15.8189 20.0555 15.8189 19.6406V14.0016L21.2728 4.73438C21.5588 4.23516 21.2002 3.60938 20.626 3.60938ZM14.1408 18.7031H9.85644V15.0469H14.1432V18.7031H14.1408ZM14.3658 13.1578L14.1432 13.5469H9.8541L9.63144 13.1578L4.98378 5.29688H19.0135L14.3658 13.1578Z" />
  </SvgIcon>
)
