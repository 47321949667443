import translations from '../translations'
import { TEMPLATE_TYPES } from '../types/templates'

import { DRAFTS_VALUE } from './documents'

export const TEMPLATES_TYPES_INFO: { [key: string]: { name: string; value: TEMPLATE_TYPES } } = {
  [TEMPLATE_TYPES.CLM]: {
    name: translations.CLM,
    value: TEMPLATE_TYPES.CLM,
  },
  [TEMPLATE_TYPES.DOCUMENT]: {
    name: translations.DOCUMENT,
    value: TEMPLATE_TYPES.DOCUMENT,
  },
  [TEMPLATE_TYPES.DOCUMENT_CLM]: {
    name: translations.DOCUMENT_CLM,
    value: TEMPLATE_TYPES.DOCUMENT_CLM,
  },
}

export enum TEMPLATES_MODALS {
  RENAME_TEMPLATE = 'RENAME_TEMPLATE',
  DELETE_TEMPLATE = 'DELETE_TEMPLATE',
  MOVE_TEMPLATE_TO_FOLDER = 'MOVE_TEMPLATE_TO_FOLDER',
  DUPLICATE_TEMPLATE = 'DUPLICATE_TEMPLATE',
  UPLOAD_TEMPLATE = 'UPLOAD_TEMPLATE',
  UPLOAD_TEMPLATE_TO = 'UPLOAD_TEMPLATE_TO',
  UPLOAD_TEMPLATE_TO_WITH_RECIPIENTS = 'UPLOAD_TEMPLATE_TO_WITH_RECIPIENTS',
  UPLOAD_TO_CLM_ONLY = 'UPLOAD_TO_CLM_ONLY',
  CREATE_FOLDER_FOR_TEMPLATE = 'CREATE_FOLDER_FOR_TEMPLATE',
  TEMPLATES_CREATE_FOLDER = 'TEMPLATES_CREATE_FOLDER',
  CREATE_TEMPLATE_FROM_CONTRACT = 'CREATE_TEMPLATE_FROM_CONTRACT',
  CHOOSE_SIGNING_ORDER = 'CHOOSE_SIGNING_ORDER',
  CREATE_CONTRACT_FROM_TEMPLATE = 'CREATE_CONTRACT_FROM_TEMPLATE',
  UPLOAD_FILE_FOR_CONTRACT = 'UPLOAD_FILE_FOR_CONTRACT',
  DISCARD_CONFIRMATION = 'DISCARD_CONFIRMATION',
}

export const TEMPLATE_EXISTS = 'TEMPLATE_EXISTS'

export const ALL_TEMPLATES_FOLDER = {
  name: 'All templates',
  value: DRAFTS_VALUE,
}
