import React, { useEffect, useState } from 'react'
import { Form, Formik, FormikHandlers, FormikHelpers, FormikState } from 'formik'
import { Redirect } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import translations from '../../../translations/keys'
import { location } from '../../../utils/location'
import AuthorizationBox from '../../components/Authorization/AuthorizationBox'
import Link from '../../ui/Link'
import Typography from '../../ui/Typography'
import { TYPOGRAPHY_COMPONENTS, TYPOGRAPHY_NAMES } from '../../ui/Typography/Typography.types'
import Box from '../../ui/Box'
import FormWrapper from '../../components/FormWrapper'
import FormPhone from '../../ui/Forms/FormPhone'
import FormCodeInput from '../../ui/Forms/FormCodeInput'
import Button from '../../ui/Button'
import CountDown from '../../ui/CountDown'
import { BUTTON_VARIANTS } from '../../ui/Button/Button.types'
import {
  registrationUserIdSelector,
  registrationFormSelector,
  registrationConfirmPhoneFetchStatusSelector,
  registrationConfirmPhoneErrorSelector,
  registrationResendCodeFetchStatusSelector,
  registrationResendCodeErrorSelector,
  registrationSignUpFetchStatusSelector,
} from '../../../store/registration/registration.selectors'
import {
  cleanSignUpFetchStatus,
  fetchPhoneConfirm,
  fetchResendCode,
  fetchSignUp,
} from '../../../store/registration/registration.actions'
import { prepareRegistrationBody } from '../../../utils/registration/prepareRegistrationBody'
import { FETCH_STATUSES } from '../../../types/fetch'
import { useHistoryPushAfterSuccess } from '../../hooks/useHistoryPushAfterSuccess'
import { useShowFetchError } from '../../hooks/useShowFetchError'
import { confirmPasswordValidationSchema } from '../../../validation/confirmPhone/confirmPasswordValidationSchema'
import { ConfirmPhoneFields, ConfirmPhoneFormValues } from '../../../types/confirmPhone'
import { PhoneIcon } from '../../assets/PhoneIcon'

export const ConfirmPhone = () => {
  const [resendCodeDisabled, setResendCodeDisabled] = useState(true)
  const [countDownKey, setCountDownKey] = useState(new Date().getTime())
  const dispatch = useDispatch()
  const userId = useSelector(registrationUserIdSelector)
  const registrationForm = useSelector(registrationFormSelector)
  const confirmPhoneFetchStatus = useSelector(registrationConfirmPhoneFetchStatusSelector)
  const confirmPhoneError = useSelector(registrationConfirmPhoneErrorSelector)
  const resendCodeFetchStatus = useSelector(registrationResendCodeFetchStatusSelector)
  const resendCodeError = useSelector(registrationResendCodeErrorSelector)
  const signUpFetchStatus = useSelector(registrationSignUpFetchStatusSelector)

  if (!registrationForm || !userId) {
    return <Redirect to={location.registrationUrl()} />
  }

  const [prevPhone, setPrevPhone] = useState(registrationForm.phone)

  const initialValues: ConfirmPhoneFormValues = {
    [ConfirmPhoneFields.phone]: registrationForm.phone,
    [ConfirmPhoneFields.verificationCode]: '',
  }

  const handleSubmit = (values: ConfirmPhoneFormValues) => {
    if (confirmPhoneFetchStatus === FETCH_STATUSES.REQUEST) {
      return
    }

    dispatch(fetchPhoneConfirm({ code: values.verificationCode }))
  }

  const handleCountDownComplete = () => {
    setResendCodeDisabled(false)
  }

  const renderForm = ({
    values,
  }: FormikHelpers<ConfirmPhoneFormValues> & FormikHandlers & FormikState<ConfirmPhoneFormValues>) => {
    const onResendCode = () => {
      if (values.phone === prevPhone) {
        dispatch(fetchResendCode(userId))
      } else {
        setPrevPhone(values.phone)
        const newRegistrationForm = { ...registrationForm, phone: values.phone }
        const body = prepareRegistrationBody(newRegistrationForm)
        dispatch(fetchSignUp({ body, registrationForm: newRegistrationForm }))
      }
      setResendCodeDisabled(true)
      setCountDownKey(new Date().getTime())
    }

    return (
      <>
        <Form>
          <Box textAlign="center" mb={2.5}>
            {translations.CONFIRM_PHONE_DESCRIPTION}
          </Box>
          <FormPhone name={ConfirmPhoneFields.phone} />
          <Box textAlign="center" mb={2.5}>
            {translations.CONFIRM_PHONE_CODE_INPUT_LABEL}
          </Box>
          <Box mb={2}>
            <FormCodeInput name={ConfirmPhoneFields.verificationCode} length={6} />
          </Box>
          <Box mb={2}>
            <Button fullWidth type="submit">
              {translations.CONTINUE}
            </Button>
          </Box>
        </Form>
        <Box mb={3}>
          <CountDown key={countDownKey} onComplete={handleCountDownComplete} />
        </Box>
        <Box textAlign="center" mb={2}>
          <Button variant={BUTTON_VARIANTS.LINK} onClick={onResendCode} disabled={resendCodeDisabled}>
            {translations.RESEND_CODE}
          </Button>
        </Box>
      </>
    )
  }

  useHistoryPushAfterSuccess(location.confirmEmailUrl(), confirmPhoneFetchStatus)
  useShowFetchError(confirmPhoneFetchStatus, confirmPhoneError)
  useShowFetchError(resendCodeFetchStatus, resendCodeError)
  useEffect(() => {
    if (signUpFetchStatus === FETCH_STATUSES.SUCCESS) {
      dispatch(cleanSignUpFetchStatus())
    }
  }, [signUpFetchStatus])

  return (
    <AuthorizationBox noLogo>
      <FormWrapper>
        <Box textAlign="center" mb={4}>
          <PhoneIcon />
        </Box>
        <Box textAlign="center" mb={3}>
          <Typography name={TYPOGRAPHY_NAMES.H1} component={TYPOGRAPHY_COMPONENTS.h1}>
            {translations.CONFIRM_PHONE_TITLE}
          </Typography>
        </Box>
        <Formik
          validationSchema={confirmPasswordValidationSchema}
          initialValues={initialValues}
          onSubmit={handleSubmit}
        >
          {renderForm}
        </Formik>
        <Box textAlign="center" mb={6}>
          <Link to={location.registrationUrl()}>{translations.BACK_TO_SIGNUP}</Link>
        </Box>
      </FormWrapper>
    </AuthorizationBox>
  )
}
