import { FetchFailureAction, FetchFailurePayload } from '../../../types/fetch'
import { SIGNING_TYPES } from '../../../types/signingOrder'
import { AddRecipientsForm, RecipientData, Sender } from '../../../types/recipients'
import { createFetchActionTypes } from '../../../utils/createFetchActionTypes'
import { EnabledFeatures } from '../../../types/subscription'

import * as TYPES from './recipients.types'

export const fetchRecipientsTypes = createFetchActionTypes(TYPES.RecipientsActions.TEMPLATES_FETCH_RECIPIENTS)
export const fetchAddRecipientsTypes = createFetchActionTypes(TYPES.RecipientsActions.TEMPLATES_FETCH_ADD_RECIPIENTS)
export const fetchAddClmOnlyRecipientsTypes = createFetchActionTypes(
  TYPES.RecipientsActions.TEMPLATES_FETCH_ADD_CLM_ONLY_RECIPIENTS
)
export const fetchTemplateInfoTypes = createFetchActionTypes(TYPES.RecipientsActions.TEMPLATES_FETCH_TEMPLATE_INFO)
export const fetchSenderInfoTypes = createFetchActionTypes(TYPES.RecipientsActions.TEMPLATES_FETCH_SENDER_INFO)

export const setOrder = (order: SIGNING_TYPES): TYPES.SetOrderAction => ({
  type: TYPES.RecipientsActions.TEMPLATES_SET_ORDER,
  payload: { order },
})
export const setRecipients = (recipients: Required<RecipientData>[]): TYPES.SetRecipientsAction => ({
  type: TYPES.RecipientsActions.TEMPLATES_SET_RECIPIENTS,
  payload: { recipients },
})
export const clearRecipients = (): TYPES.ClearRecipientsAction => ({
  type: TYPES.RecipientsActions.TEMPLATES_CLEAR_RECIPIENTS,
})

export const fetchRecipients = (templateId: string): TYPES.FetchRecipientsAction => ({
  type: fetchRecipientsTypes.request,
  payload: { templateId },
})
export const fetchRecipientsSuccess = (
  recipients: Required<RecipientData>[],
  enabledFeatures: EnabledFeatures
): TYPES.FetchRecipientsSuccessAction => ({
  type: fetchRecipientsTypes.success,
  payload: { recipients, enabledFeatures },
})
export const fetchRecipientsFailure = (payload: FetchFailurePayload): FetchFailureAction => ({
  type: fetchRecipientsTypes.failure,
  payload,
})

export const fetchAddRecipients = (templateId: string, body: AddRecipientsForm): TYPES.FetchAddRecipientsAction => ({
  type: fetchAddRecipientsTypes.request,
  payload: { templateId, body },
})
export const fetchAddRecipientsSuccess = (recipients: RecipientData[]): TYPES.FetchAddRecipientsSuccessAction => ({
  type: fetchAddRecipientsTypes.success,
  payload: { recipients },
})
export const fetchAddRecipientsFailure = (payload: FetchFailurePayload): FetchFailureAction => ({
  type: fetchAddRecipientsTypes.failure,
  payload,
})

export const fetchAddClmOnlyRecipients = (
  name: string,
  recipients: AddRecipientsForm,
  folderName?: string
): TYPES.FetchAddClmOnlyRecipientsAction => ({
  type: fetchAddClmOnlyRecipientsTypes.request,
  payload: { name, folderName, recipients },
})
export const fetchAddClmOnlyRecipientsSuccess = (templateId: string): TYPES.FetchAddClmOnlyRecipientsSuccessAction => ({
  type: fetchAddClmOnlyRecipientsTypes.success,
  payload: { templateId },
})
export const fetchAddClmOnlyRecipientsFailure = (payload: FetchFailurePayload): FetchFailureAction => ({
  type: fetchAddClmOnlyRecipientsTypes.failure,
  payload,
})

export const fetchTemplateInfo = (templateId: string): TYPES.FetchTemplateInfoAction => ({
  type: fetchTemplateInfoTypes.request,
  payload: { templateId },
})
export const fetchTemplateInfoSuccess = ({
  name,
  isDocumentExists,
  messageInitialValues,
}: TYPES.FetchTemplateInfoSuccessArgs): TYPES.FetchTemplateInfoSuccessAction => ({
  type: fetchTemplateInfoTypes.success,
  payload: { name, isDocumentExists, messageInitialValues },
})
export const fetchTemplateInfoFailure = (payload: FetchFailurePayload): FetchFailureAction => ({
  type: fetchTemplateInfoTypes.failure,
  payload,
})

export const fetchSenderInfo = (): TYPES.FetchSenderInfoAction => ({
  type: fetchSenderInfoTypes.request,
})
export const fetchSenderInfoSuccess = (sender: Sender): TYPES.FetchSenderInfoSuccessAction => ({
  type: fetchSenderInfoTypes.success,
  payload: sender,
})
export const fetchSenderInfoFailure = (payload: FetchFailurePayload): FetchFailureAction => ({
  type: fetchSenderInfoTypes.failure,
  payload,
})
