import React, { useState } from 'react'
import { useTheme } from 'styled-components'

import { useDidUpdate } from '../../../hooks/useDidUpdate'
import { StyledThemeScheme } from '../../../themes/light'
import useMediaQuery from '../../hooks/useMediaQuery'

import ContractsContainer from './components/ContractsContainer'
import { ContractsProps } from './Contracts.types'

export const Contracts: React.FC<ContractsProps> = (props) => {
  const theme = useTheme() as StyledThemeScheme
  const mobileScreen = useMediaQuery(theme.devices.mobile)
  const [updateContractsKey, setUpdateContractsKey] = useState(1)

  useDidUpdate(() => {
    setUpdateContractsKey((prevKey) => prevKey + 1)
  }, [mobileScreen])

  return <ContractsContainer key={updateContractsKey} {...props} />
}
