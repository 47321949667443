import styled, { css } from 'styled-components'

import Button from '../Button'
import { BUTTON_VARIANTS } from '../Button/Button.types'

const selectedStyles = css`
  z-index: 100;
`

export const Wrapper = styled.div<{ isSelected?: boolean }>`
  position: absolute;
  transform: translateZ(0);
  box-shadow: ${({ theme }) => `inset 0 0 0 2px ${theme.palette.blueOcean}`};
  cursor: pointer;
  ${({ isSelected }) => isSelected && selectedStyles}

  &: hover {
    z-index: 100;
  }
`
export const Svg = styled.svg`
  position: absolute;
  pointer-events: none;
`
export const Line = styled.line`
  stroke: ${({ theme }) => theme.palette.blueOcean};
  stroke-width: 2;
`
export const TopActions = styled.div`
  display: flex;
  position: absolute;
  right: 0;
  top: -44px;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.palette.white};
  background-color: ${({ theme }) => theme.palette.blueDark};
  border-radius: 4px;
`
export const Action = styled.div`
  display: flex;
`
export const ActionButton = styled(Button).attrs({ variant: BUTTON_VARIANTS.INACTIVE })`
  width: 40px;
  height: 40px;

  * {
    pointer-events: none;
  }
`

export const Confirmation = styled.div`
  position: absolute;
  top: 100%;
  margin-top: 4px;
`
