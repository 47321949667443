import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import translations from '../../translations'
import { EditableElem } from '../../types/editableElem'
import { DrawableCompNames, DrawableElem } from '../../types/drawableElem'
import { DocComment, DocCommentsThread } from '../../types/comment'
import { DEFAULT_CORRECTION_FONT_SIZE } from '../../types/documentField'
import { CONTRACT_MODALS } from '../../constants/contract'
import * as COMMENTS_ACTIONS from '../../store/contracts/comments/comments.actions'
import * as COMMENTS_SELECTORS from '../../store/contracts/comments/comments.selectors'
import * as STRIKES_ACTIONS from '../../store/contracts/strikes/strikes.actions'
import * as STRIKES_SELECTORS from '../../store/contracts/strikes/strikes.selectors'
import * as CORRECTIONS_ACTIONS from '../../store/contracts/corrections/corrections.actions'
import * as CORRECTIONS_SELECTORS from '../../store/contracts/corrections/corrections.selectors'
import { closeModal, openModal } from '../../store/modals/modals.actions'
import { OnConfirm } from '../../web/ui/ConfirmationActions/ConfirmationActions.types'
import * as AUTH_SELECTORS from '../../store/auth/auth.selectors'
import { COMMON_MODALS_IDS } from '../../web/ui/Modals/CommonModals/CommonModals.constants'
import { NOTIFICATION_MODAL_TYPES } from '../../web/ui/Modals/NotificationModal/NotificationModal.constants'
import { OnCancelConfirmation } from '../../web/ui/ConfirmationLabel/ConfirmationLabel.types'
import { ConfirmationStatus } from '../../types/confirmationActions'
import { fetchOwnerContractDocument } from '../../store/contracts/ownerView/ownerView.actions'

export const useContractAnnotations = (contractId: string) => {
  const dispatch = useDispatch()
  const [selectedComment, setSelectedComment] = useState<{ comment: DocComment; elem: HTMLDivElement } | null>(null)
  const commentsById = useSelector(COMMENTS_SELECTORS.commentsByIdSelector)
  const correctionsById = useSelector(CORRECTIONS_SELECTORS.correctionsByIdSelector)
  const strikesById = useSelector(STRIKES_SELECTORS.strikesByIdSelector)
  const currentUserId = useSelector(AUTH_SELECTORS.authUserIdSelector)
  const commentThread = useSelector(COMMENTS_SELECTORS.commentsThreadSelector)

  const handleElemChange = (place: EditableElem) => {
    if (strikesById[place.id!]) {
      dispatch(STRIKES_ACTIONS.setStrike(place))
    } else if (correctionsById[place.id!]) {
      dispatch(CORRECTIONS_ACTIONS.setCorrection(place))
    } else if (commentsById[place.id!]) {
      dispatch(COMMENTS_ACTIONS.setComment(place))
    }
  }

  const handleElemCreate = (elem: DrawableElem) => {
    if (!currentUserId) {
      return
    }
    const confirmationInitialValue = {
      acceptOrRejectTimestamp: null,
      confirmation: null,
      owner: null,
      status: null,
      strikeOrCorrectionOwner: currentUserId,
    }
    if (elem.type === DrawableCompNames.STRIKETHROUGH) {
      dispatch(
        STRIKES_ACTIONS.addStrike(
          {
            ...elem,
            ...confirmationInitialValue,
            isEditable: true,
          },
          currentUserId
        )
      )
    } else if (elem.type === DrawableCompNames.CORRECTION) {
      dispatch(
        CORRECTIONS_ACTIONS.addCorrection(
          {
            ...elem,
            ...confirmationInitialValue,
            text: '',
            isEditable: true,
            fontSize: DEFAULT_CORRECTION_FONT_SIZE,
          },
          currentUserId
        )
      )
    } else if (elem.type === DrawableCompNames.COMMENT) {
      dispatch(
        COMMENTS_ACTIONS.addComment({
          ...elem,
          confirmation: {
            email: null,
            name: null,
            recipientId: null,
            status: null,
            timestamp: null,
          },
          counter: 0,
        })
      )
    }
  }

  const handleElemClick = (id: string, elem: HTMLDivElement) => {
    if (commentsById[id]) {
      const comment = commentsById[id]

      if (comment.counter && comment.counter > 0) {
        dispatch(COMMENTS_ACTIONS.fetchCommentsThread({ contractId, x: comment.x, y: comment.y, page: comment.page }))
      }
      setSelectedComment((prevState) => (!prevState ? { comment, elem } : null))
    }
  }

  const handleElemBlur = (id: string, isSaveDisabled: boolean) => {
    if (isSaveDisabled) {
      return
    }

    let item
    let title

    if (correctionsById[id]?.isEditable) {
      item = correctionsById[id]
      title = translations.CORRECTION_UNSAVED_WARNING
    } else if (strikesById[id]?.isEditable) {
      item = strikesById[id]
      title = translations.STRIKE_UNSAVED_WARNING
    }

    if (item && title) {
      dispatch(openModal(CONTRACT_MODALS.CONTRACT_SAVE_ANNOTATION_PROMPT, { item, title }))
    }
  }

  const handleElemSave = (id: string) => {
    if (strikesById[id]) {
      dispatch(STRIKES_ACTIONS.fetchCreateStrike(contractId, strikesById[id]))
    } else if (correctionsById[id]) {
      dispatch(CORRECTIONS_ACTIONS.fetchCreateCorrection(contractId, correctionsById[id]))
    }
    dispatch(fetchOwnerContractDocument(contractId))
  }

  const handleElemDelete = (id: string) => {
    if (!currentUserId) {
      return
    }

    if (strikesById[id]) {
      dispatch(STRIKES_ACTIONS.unsetStrike(id, currentUserId))
    } else if (correctionsById[id]) {
      dispatch(CORRECTIONS_ACTIONS.unsetCorrection(id, currentUserId))
    } else if (commentsById[id]) {
      dispatch(COMMENTS_ACTIONS.unsetComment(id))
    }
  }

  const handleFieldChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    dispatch(CORRECTIONS_ACTIONS.setCorrectionText(event.target.name, event.target.value))
  }

  const handleCommentSubmit = (comment: DocComment) => {
    setSelectedComment(null)
    dispatch(COMMENTS_ACTIONS.fetchCreateComment(contractId, comment))
    dispatch(COMMENTS_ACTIONS.clearThread())
  }

  const handleCommentPopoverClose = (comment: DocComment) => {
    setSelectedComment(null)
    dispatch(COMMENTS_ACTIONS.clearThread())

    if (comment.message?.trim()) {
      dispatch(
        openModal(CONTRACT_MODALS.CONTRACT_SAVE_ANNOTATION_PROMPT, {
          item: comment,
          title: translations.COMMENT_UNSAVED_WARNING,
        })
      )
    }
  }

  const handleCommentModalClose = (comment: DocComment) => {
    setSelectedComment(null)
    dispatch(COMMENTS_ACTIONS.clearThread())
    dispatch(closeModal(CONTRACT_MODALS.COMMENTS_MODEL))

    if (comment.message?.trim()) {
      dispatch(
        openModal(CONTRACT_MODALS.CONTRACT_SAVE_ANNOTATION_PROMPT, {
          item: comment,
          title: translations.COMMENT_UNSAVED_WARNING,
        })
      )
    }
  }

  const handleElemConfirm: OnConfirm = (id, status) => {
    const openAttentionModal = (onSuccess: () => void) => {
      dispatch(
        openModal(COMMON_MODALS_IDS.NOTIFICATION, {
          type: NOTIFICATION_MODAL_TYPES.WARNING,
          description: translations.CONFIRMATION_ATTENTION,
          cancelButtonText: translations.CANCEL,
          onSuccess: () => {
            onSuccess()
            dispatch(closeModal(COMMON_MODALS_IDS.NOTIFICATION))
          },
        })
      )
    }

    if (strikesById[id]) {
      openAttentionModal(() => dispatch(STRIKES_ACTIONS.fetchConfirmStrike({ contractId, strikeId: id, status })))
    }

    if (correctionsById[id]) {
      openAttentionModal(() =>
        dispatch(CORRECTIONS_ACTIONS.fetchConfirmCorrection({ contractId, correctionId: id, status }))
      )
    }

    if (commentsById[id]) {
      const { x, y, page } = commentsById[id]
      const comment = commentThread.find((el) => el.x === x && el.y === y && el.page === page) as DocCommentsThread
      openAttentionModal(() =>
        dispatch(
          COMMENTS_ACTIONS.fetchConfirmComments({
            status,
            contractId,
            x: comment.x,
            y: comment.y,
            page: comment.page,
          })
        )
      )
    }
  }

  const handleElemCancelConfirmation: OnCancelConfirmation = (id, status) => {
    const openAttentionModal = (onSuccess: () => void) => {
      dispatch(
        openModal(COMMON_MODALS_IDS.NOTIFICATION, {
          type: NOTIFICATION_MODAL_TYPES.WARNING,
          description:
            status === ConfirmationStatus.ACCEPTED
              ? translations.CANCEL_ACCEPTATION_ATTENTION
              : translations.CANCEL_REJECTION_ATTENTION,
          onSuccess: () => {
            onSuccess()
            dispatch(closeModal(COMMON_MODALS_IDS.NOTIFICATION))
          },
        })
      )
    }

    if (strikesById[id]) {
      openAttentionModal(() =>
        dispatch(STRIKES_ACTIONS.fetchCancelStrikeConfirmation({ contractId, strikeId: id, status }))
      )
    }
    if (correctionsById[id]) {
      openAttentionModal(() =>
        dispatch(CORRECTIONS_ACTIONS.fetchCancelCorrectionConfirmation({ contractId, correctionId: id, status }))
      )
    }
    if (commentsById[id]) {
      const { x, y, page } = commentsById[id]
      const comment = commentThread.find((el) => el.x === x && el.y === y && el.page === page) as DocCommentsThread
      openAttentionModal(() =>
        dispatch(
          COMMENTS_ACTIONS.fetchCancelCommentsConfirmation({
            status,
            contractId,
            x: comment.x,
            y: comment.y,
            page: comment.page,
          })
        )
      )
    }
  }

  return {
    selectedComment,
    handleElemChange,
    handleElemCreate,
    handleElemClick,
    handleElemBlur,
    handleElemSave,
    handleElemDelete,
    handleFieldChange,
    handleCommentSubmit,
    handleCommentPopoverClose,
    handleElemConfirm,
    handleElemCancelConfirmation,
    handleCommentModalClose,
  }
}
