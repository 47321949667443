import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { EditableElem } from '../../../types/editableElem'
import { DrawableCompNames, DrawableElem } from '../../../types/drawableElem'
import { PLACEMENT_MODALS } from '../../constants/placement'
import { isNumber } from '../../../utils/isNumber'
import { removeExtension } from '../../../utils/file/removeExtension'
import { useDispatchUnmount } from '../../../hooks/useDispatchUnmount'
import { useTemplatePreviewFetch } from '../../../hooks/templatePreview/useTemplatePreviewFetch'
import { useTemplateFieldsFetch } from '../../../hooks/templates/useTemplateFieldsFetch'
import { useShowFetchError } from '../../hooks/useShowFetchError'
import * as PREVIEW_SELECTORS from '../../../store/templates/preview/preview.selectors'
import * as RECIPIENTS_SELECTORS from '../../../store/templates/recipients/recipients.selectors'
import * as REUPLOAD_SELECTORS from '../../../store/templates/reupload/reupload.selectors'
import {
  fetchSenderInfo,
  fetchRecipients,
  clearRecipients,
} from '../../../store/templates/recipients/recipients.actions'
import * as PLACEMENT_SELECTORS from '../../../store/templates/placement/placement.selectors'
import * as FIELDS_SELECTORS from '../../../store/templates/fields/fields.selectors'
import { openModal } from '../../../store/modals/modals.actions'
import * as ACTIONS from '../../../store/templates/placement/placement.actions'
import { fetchReupload } from '../../../store/templates/reupload/reupload.actions'
import {
  addField,
  clearFields,
  fetchFields,
  setField,
  setFontSize,
  unsetField,
} from '../../../store/templates/fields/fields.actions'
import DocViewer from '../../ui/DocViewer'
import { PreviewHeader } from '../../components/PreviewHeader/PreviewHeader'
import FullScreenTemplate from '../../components/Main/FullScreenTemplate'
import { TemplateRouteParams } from '../../types/template'
import { viewOptionsIsSignatureNamesVisibleSelector } from '../../../store/templates/viewOptions/viewOptions.selectors'
import { cleanViewOptions } from '../../../store/templates/viewOptions/viewOptions.actions'

import { useToolbarActions } from './hooks/useToolbarActions'
import { useActions } from './hooks/useActions'
import { useButtons } from './hooks/useButtons'
import PlacementSidebar from './components/PlacementSidebar'
import PlacementModals from './components/PlacementModals'
import * as Styled from './TemplatePlacement.styles'

export const TemplatePlacement = () => {
  const dispatch = useDispatch()
  const { templateId } = useParams<TemplateRouteParams>()
  const previewFetchStatus = useSelector(PREVIEW_SELECTORS.previewFetchStatusSelector)
  const error = useSelector(PREVIEW_SELECTORS.previewErrorSelector)
  const data = useSelector(PREVIEW_SELECTORS.previewDataSelector)
  const sender = useSelector(RECIPIENTS_SELECTORS.recipientsSenderSelector)
  const byId = useSelector(RECIPIENTS_SELECTORS.recipientsByIdSelector)
  const byOrder = useSelector(RECIPIENTS_SELECTORS.recipientsByOrderSelector)
  const sorted = useSelector(RECIPIENTS_SELECTORS.recipientsSortedSelector)
  const placementById = useSelector(PLACEMENT_SELECTORS.placementByIdSelector)
  const placementByPages = useSelector(PLACEMENT_SELECTORS.placementByPagesSelector)
  const fieldsById = useSelector(FIELDS_SELECTORS.fieldsByIdSelector)
  const fieldsByPages = useSelector(FIELDS_SELECTORS.fieldsByPagesSelector)
  const fontSize = useSelector(FIELDS_SELECTORS.fieldsFontSizeSelector)
  const title = removeExtension(data?.name || '')
  const recipients = { byId, byOrder, sorted }
  const shouldRefetch = useSelector(PLACEMENT_SELECTORS.placementGenerateIsSuccess)
  const fetchGenerateStatus = useSelector(PLACEMENT_SELECTORS.placementFetchGenerateSelector)
  const reuploadStatus = useSelector(REUPLOAD_SELECTORS.reuploadFetchStatusSelector)
  const reuploadError = useSelector(REUPLOAD_SELECTORS.reuploadErrorSelector)
  const isReuploadSuccess = useSelector(REUPLOAD_SELECTORS.reuploadIsSuccessSelector)
  const isGeneratePlacementSuccess = useSelector(PLACEMENT_SELECTORS.placementGenerateIsSuccess)
  const fetchFieldsRequest = useSelector(FIELDS_SELECTORS.fieldsFetchSelector)
  const reuploading = useSelector(REUPLOAD_SELECTORS.reuploadIsRequestSelector)
  const isSignatureNamesVisible = useSelector(viewOptionsIsSignatureNamesVisibleSelector)
  const actions = useActions()
  const buttons = useButtons()
  const toolbarActions = useToolbarActions()

  const handleElemChange = (place: EditableElem) => {
    if (fieldsById[place.id!]) {
      dispatch(setField(place))
    } else {
      dispatch(ACTIONS.setPlacement(place))
    }
  }

  const handleElemCreate = (elem: DrawableElem) => {
    if (elem.type === DrawableCompNames.TEXT || elem.type === DrawableCompNames.DATE) {
      dispatch(addField({ ...elem, type: elem.type, fontSize, text: '' }))
    }
  }

  const handleElemDelete = (id: string) => {
    if (fieldsById[id]) {
      dispatch(unsetField(id))
    }
  }

  const handleToolbarChange = (name: string, value: string | number) => {
    // TODO: use enum when DocViewerToolbar is refactored
    if (name === 'Size' && isNumber(value)) {
      dispatch(setFontSize(value as number))
    }
  }

  const handleReset = () => {
    dispatch(ACTIONS.fetchGeneratePlacement(templateId))
  }

  const handleReuploadConfirm = () => {
    dispatch(ACTIONS.clearPlacementStatus())
    dispatch(openModal(PLACEMENT_MODALS.REUPLOAD))
  }

  const handleReupload = (file: File) => {
    dispatch(fetchReupload(templateId, file))
  }

  const handleDiscard = () => {
    dispatch(fetchFields(templateId))
    dispatch(ACTIONS.fetchPlacement(templateId, byId))
  }

  useEffect(() => {
    dispatch(fetchSenderInfo())
    dispatch(fetchRecipients(templateId))
  }, [])

  useEffect(() => {
    if (recipients.sorted.length > 0) {
      dispatch(ACTIONS.fetchPlacement(templateId, byId))
    }
  }, [recipients.sorted, shouldRefetch])

  useEffect(() => {
    if (isReuploadSuccess && !isGeneratePlacementSuccess) {
      dispatch(ACTIONS.fetchGeneratePlacement(templateId))
    } else if (isReuploadSuccess && isGeneratePlacementSuccess) {
      history.go(0)
    }
  }, [isReuploadSuccess, isGeneratePlacementSuccess])

  useTemplatePreviewFetch(templateId)
  useTemplateFieldsFetch(templateId)
  useShowFetchError(previewFetchStatus, error)
  useShowFetchError(fetchGenerateStatus.status, fetchGenerateStatus.error)
  useShowFetchError(reuploadStatus, reuploadError)
  useShowFetchError(fetchFieldsRequest.status, fetchFieldsRequest.error)
  useDispatchUnmount(clearRecipients, ACTIONS.clearPlacement, clearFields, cleanViewOptions)

  return (
    <FullScreenTemplate
      headerContent={<PreviewHeader actions={actions} buttons={buttons} title={title} />}
      sidebarContent={<PlacementSidebar templateId={templateId} sender={sender} recipients={recipients} />}
    >
      <Styled.Container test-id="template-preview-container">
        {data && (
          <DocViewer
            pages={data.pages}
            placementById={placementById}
            placementByPages={placementByPages}
            fieldsById={fieldsById}
            fieldsByPages={fieldsByPages}
            fieldsFontSize={fontSize}
            actions={toolbarActions}
            isSignatureNamesVisible={isSignatureNamesVisible}
            onElemChange={handleElemChange}
            onElemCreate={handleElemCreate}
            onElemDelete={handleElemDelete}
            onToolbarChange={handleToolbarChange}
          />
        )}
      </Styled.Container>
      <PlacementModals
        reuploading={reuploading}
        onReset={handleReset}
        onDiscard={handleDiscard}
        onReuploadConfirm={handleReuploadConfirm}
        onReupload={handleReupload}
      />
    </FullScreenTemplate>
  )
}
