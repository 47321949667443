import { RegistrationFormValues } from '../../types/registration'
import { addPhonePlus } from '../phone/addPhonePlus'

export const prepareRegistrationBody = (formValues: RegistrationFormValues) => {
  const { phone, confirmPassword, accepted, ...values } = formValues
  return {
    ...values,
    phone: addPhonePlus(phone),
  }
}
