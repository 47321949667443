import React, { FC } from 'react'
import styled, { css, ThemedStyledProps } from 'styled-components'

import { StyledThemeScheme } from '../../../themes/light'
import * as colors from '../../../themes/colors'

import { TextCase, TypographyProps, TYPOGRAPHY_COMPONENTS, TYPOGRAPHY_NAMES } from './Typography.types'

const renderTextStyles = (styles: { [key: string]: string }, textCase?: TextCase) => `
  font-family: ${styles['font-family']};
  font-size: ${styles['font-size']};
  line-height: ${styles['line-height']};
  font-weight: ${styles['font-weight'] || 'normal'};
  text-transform: ${styles['text-transform'] || textCase || 'none'};
`

const StyledText = styled.span`
  ${({
    theme,
    typographyName,
    typographyMobileName,
    typographyLaptopName,
    textCase,
    isCapitalize,
    color,
  }: ThemedStyledProps<
    {
      typographyName: TYPOGRAPHY_NAMES
      typographyMobileName: TYPOGRAPHY_NAMES
      typographyLaptopName: TYPOGRAPHY_NAMES
      textCase: TextCase
      isCapitalize: boolean
      color?: keyof typeof colors
    },
    StyledThemeScheme
  >) => {
    const laptopName = typographyLaptopName || typographyName
    const mobileName = typographyMobileName || laptopName
    const textStyles = (name: TYPOGRAPHY_NAMES) => `
      ${name === TYPOGRAPHY_NAMES.H1 ? renderTextStyles(theme.textStyles.H1, textCase) : ''}
      ${name === TYPOGRAPHY_NAMES.H2 ? renderTextStyles(theme.textStyles.H2, textCase) : ''}
      ${name === TYPOGRAPHY_NAMES.H3 ? renderTextStyles(theme.textStyles.H3, textCase) : ''}
      ${name === TYPOGRAPHY_NAMES.H4 ? renderTextStyles(theme.textStyles.H4, textCase) : ''}
      ${name === TYPOGRAPHY_NAMES.body14 ? renderTextStyles(theme.textStyles.body14, textCase) : ''}
      ${name === TYPOGRAPHY_NAMES.body16 ? renderTextStyles(theme.textStyles.body16, textCase) : ''}
      ${name === TYPOGRAPHY_NAMES.bodySmall12 ? renderTextStyles(theme.textStyles.bodySmall12, textCase) : ''}
      ${name === TYPOGRAPHY_NAMES.bold14 ? renderTextStyles(theme.textStyles.bold14, textCase) : ''}
      ${name === TYPOGRAPHY_NAMES.bold16 ? renderTextStyles(theme.textStyles.bold16, textCase) : ''}
      ${name === TYPOGRAPHY_NAMES.tableColumn ? renderTextStyles(theme.textStyles.tableColumn, textCase) : ''}
      ${name === TYPOGRAPHY_NAMES.buttons_14 ? renderTextStyles(theme.textStyles.buttons_14, textCase) : ''}
      ${name === TYPOGRAPHY_NAMES.regularH1 ? renderTextStyles(theme.textStyles.regularH1, textCase) : ''}
      ${name === TYPOGRAPHY_NAMES.errorTitle32 ? renderTextStyles(theme.textStyles.errorTitle32, textCase) : ''}
      ${name === TYPOGRAPHY_NAMES.errorTitle28 ? renderTextStyles(theme.textStyles.errorTitle28, textCase) : ''}
    `
    const textColor = color ? `color: ${theme.palette[color]};` : ''
    const firstLetterCapital = isCapitalize
      ? css`
          &::first-letter {
            text-transform: uppercase;
          }
        `
      : ''

    return `
      ${textColor}
      ${firstLetterCapital}
      ${textStyles(typographyName)}
      ${`@media ${theme.devices.laptop} {
        ${textStyles(laptopName)}
      }`}
      ${`@media ${theme.devices.mobile} {
        ${textStyles(mobileName)}
      }`}
    `
  }}
`

export const Typography: FC<TypographyProps> = ({
  children,
  component = TYPOGRAPHY_COMPONENTS.span,
  name,
  mobileName,
  textCase,
  isCapitalize = false,
  color,
  laptopName,
  ...rest
}) => (
  <StyledText
    as={component}
    typographyName={name}
    typographyMobileName={mobileName}
    typographyLaptopName={laptopName}
    textCase={textCase}
    isCapitalize={isCapitalize}
    color={color}
    {...rest}
  >
    {children}
  </StyledText>
)
