import React from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import translations from '../../../../../translations/keys'
import Box from '../../../../ui/Box'
import Button from '../../../../ui/Button'
import { BUTTON_VARIANTS } from '../../../../ui/Button/Button.types'
import UploadMenu from '../UploadMenu'
import { TemplateSvg } from '../../../../assets/TemplateSvg'
import { openModal } from '../../../../../store/modals/modals.actions'
import { TEMPLATES_MODALS } from '../../../../../constants/templates'

import { StyledContainer, StyledPlaceholder, StyledPlaceholderBtn } from './EmptyTemplates.styles'

export const EmptyTemplates = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const openAddFolderModal = () => {
    dispatch(openModal(TEMPLATES_MODALS.TEMPLATES_CREATE_FOLDER))
  }

  return (
    <StyledContainer>
      <StyledPlaceholder>
        <Box textAlign="center" mb={4}>
          <TemplateSvg />
        </Box>
        <Box mb={5} textAlign="center">
          {t(translations.NO_TEMPLATES)}
        </Box>
        <StyledPlaceholderBtn>
          <UploadMenu />
        </StyledPlaceholderBtn>
        <Box mt={3} display="flex" justifyContent="center">
          <Button icon="folderAdd" onClick={openAddFolderModal} variant={BUTTON_VARIANTS.INACTIVE}>
            {t(translations.ADD_FOLDER)}
          </Button>
        </Box>
      </StyledPlaceholder>
    </StyledContainer>
  )
}
