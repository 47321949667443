import { FetchFailureAction, FetchFailurePayload } from '../../../types/fetch'
import { createFetchActionTypes } from '../../../utils/createFetchActionTypes'
import { DocumentPreviewDocumentData } from '../../../types/documentPreview'

import * as TYPES from './preview.types'

export const fetchPreviewTypes = createFetchActionTypes(TYPES.PreviewActions.FETCH_PREVIEW)

export const setSuccessMessage = (message: string): TYPES.SetSuccessMessageAction => ({
  type: TYPES.PreviewActions.SET_SUCCESS_MESSAGE,
  payload: { message },
})
export const clearSuccessMessage = (): TYPES.ClearSuccessMessageAction => ({
  type: TYPES.PreviewActions.CLEAR_SUCCESS_MESSAGE,
})
export const clearPreview = (): TYPES.ClearPreviewAction => ({
  type: TYPES.PreviewActions.CLEAR_PREVIEW,
})

export const fetchPreview = (contractId: string): TYPES.FetchPreviewAction => ({
  type: fetchPreviewTypes.request,
  payload: { contractId },
})
export const fetchPreviewSuccess = (data: DocumentPreviewDocumentData): TYPES.FetchPreviewSuccessAction => ({
  type: fetchPreviewTypes.success,
  payload: data,
})
export const fetchPreviewFailure = (payload: FetchFailurePayload): FetchFailureAction => ({
  type: fetchPreviewTypes.failure,
  payload,
})
