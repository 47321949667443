import React from 'react'

import MainTemplate from '../../components/Main/MainTemplate'

import { Signatures as SignaturesPage } from './Signatures'
import { AddSignature } from './components/AddSignature/AddSignature'

export const Signatures = () => (
  <MainTemplate additionalMobileNode={<AddSignature />}>
    <SignaturesPage />
  </MainTemplate>
)
