import React from 'react'
import { useTranslation } from 'react-i18next'

import { BulletList } from '../../ui/BulletList'
import Typography from '../../ui/Typography'
import translations from '../../../translations/keys'
import { TYPOGRAPHY_NAMES } from '../../ui/Typography/Typography.types'
import Box from '../../ui/Box'
import { location } from '../../../utils/location'
import Link from '../../ui/Link'

import { StyledParagraph } from './Terms.styles'
import { TitledParagraph } from './components/TitledParagraph'

export const Terms = () => {
  const { t } = useTranslation()

  return (
    <Box display="flex" justifyContent="center">
      <Box width={704}>
        <Box mb={3}>
          <Typography name={TYPOGRAPHY_NAMES.H1}>{t(translations.TERMS_AND_CONDITIONS)}</Typography>
        </Box>
        <TitledParagraph title="About our Terms">
          <StyledParagraph>
            These terms and conditions of use (the “Terms”) explain how you may use “Uqoud”, an application interface
            that provides users with contract lifecycle management, e-signature, and electronic Know-Your-Client
            (“EKYC”) solutions and any of its content or the services and/or functionalities offered thereon (the
            “Services”). These Terms apply between:
          </StyledParagraph>
          <StyledParagraph>
            Kryptos DMCC (“Company”, “Kryptos”, “we”, “us” or “our”), a company duly incorporated and registered in the
            Dubai Multi Commodities Centre (the “DMCC”), whose registered office is at Unit No: OneJLT-06-94 One JLT,
            Plot No: DMCC-EZ1-1AB Jumeirah Lakes Towers Dubai, United Arab Emirates, and whose registration number is
            DMCC771756, and
          </StyledParagraph>
          <StyledParagraph>you, the person accessing or using Uqoud (“you” or “your”).</StyledParagraph>
          <StyledParagraph>
            You should read these Terms carefully before using Uqoud. By using Uqoud or any of the Services or otherwise
            indicating your consent to such use, you agree to be bound by these Terms. If you do not agree with any of
            these Terms, you should stop using Uqoud immediately.
          </StyledParagraph>
          <StyledParagraph>
            These Terms apply to any parts of or Services provided through Uqoud, whether or not such Services is
            provided in exchange for payment or any other form of consideration.
          </StyledParagraph>
          <StyledParagraph>
            If you are a client or paid subscriber of Uqoud (a “Client”), then separate terms and conditions will apply
            in addition to and in conjunction with these Terms.
          </StyledParagraph>
        </TitledParagraph>

        <TitledParagraph title="About Us">
          <StyledParagraph>
            We are Kryptos DMCC (“Company”, “Kryptos”, “we”, “us” or “our”), a company duly incorporated and registered
            in the Dubai Multi Commodities Centre (the “DMCC”), whose registered office is at Unit No: OneJLT-06-94 One
            JLT, Plot No: DMCC-EZ1-1AB Jumeirah Lakes Towers Dubai, United Arab Emirates, and whose registration number
            is DMCC771756, a company that operates in the software industry. Our products and services include Uqoud, an
            application interface that provides you with contract lifecycle management, e-signature, and electronic
            Know-Your-Client (“EKYC”), and AI archiving and reporting solutions.
          </StyledParagraph>
          <StyledParagraph>If you have any questions about Uqoud please contact us by: admin@uqoud.com</StyledParagraph>
          <StyledParagraph>sending an email to [privacy@uqoud.com];</StyledParagraph>
        </TitledParagraph>

        <TitledParagraph title="Using Uqoud">
          <StyledParagraph>
            You agree that you are solely responsible for all costs and expenses you may incur in relation to your use
            of Uqoud.
          </StyledParagraph>
          <StyledParagraph>
            We make no promise that Uqoud is appropriate or available for use in locations outside of [MENA, GCC and
            Europe] (the “Service Countries”). If you choose to use the Services from locations outside the Service
            Countries, you acknowledge that you do so at your own initiative and are responsible for compliance with
            local laws in any country where the Services are so used, to the extent of their applicability.
          </StyledParagraph>
          <StyledParagraph>
            We try to make Uqoud as accessible as possible. If you have any difficulties using Uqoud, please contact us
            by using the contact details, or otherwise as instructed, at the top of this page.
          </StyledParagraph>
          <StyledParagraph>As a condition for your use of Uqoud, you agree not to:</StyledParagraph>
          <StyledParagraph>
            misuse or attack Uqoud by knowingly introducing viruses, trojans, worms, logic bombs or any other material
            which is malicious or technologically harmful (such as by way of a denial-of-service attack); or
          </StyledParagraph>
          <StyledParagraph>
            attempt to gain unauthorised access to Uqoud, the server on which Uqoud is stored or any server, computer or
            database connected to Uqoud.
          </StyledParagraph>
          <StyledParagraph>
            We may prevent or suspend your access to Uqoud if you do not comply with these Terms, any other applicable
            policies which you are subject to in relation to your receipt of the Services, or any applicable law.
          </StyledParagraph>
        </TitledParagraph>
        <TitledParagraph title="Registration and Password Security">
          <StyledParagraph>
            Use of Uqoud may require registration, particularly in order to access certain Services provided thereon
            including, but not limited to:
          </StyledParagraph>
          <BulletList
            mbItem={1}
            items={[
              `Contract lifecycle management Services, including contract editing, comments, cross-over, workflow and cycles management, sending and receiving contracts and notifications and reminders.`,
              `E-Signature Services, including recording initials and capturing and placing signatures in a static and dynamic manner.`,
              `EKYC Services, including verifying your identity and your customers’ identities via facial recognition / biometrics as relevant in connection with our provision of the Services.`,
              `Storing and managing contract execution and contract lifecycle management Services. `,
            ]}
          />
          <StyledParagraph>
            We are not obliged to permit anyone to register with Uqoud and we may refuse, terminate or suspend
            registration to anyone at any time.
          </StyledParagraph>
          <StyledParagraph>
            You are responsible for making sure that your password and any other account details are kept secure and
            confidential.
          </StyledParagraph>
          <StyledParagraph>
            If we have reason to believe there is likely to be a breach of security or misuse of Uqoud through your
            account or the use of your password, we may notify you and require you to change your password, or we may
            suspend or terminate your account.
          </StyledParagraph>
          <StyledParagraph>
            Any personal information you provide to us as part of the registration process will be processed in
            accordance with our <Link to={location.privacyUrl()}>Privacy Policy</Link>.
          </StyledParagraph>
        </TitledParagraph>
        <TitledParagraph title="Infringing Content">
          <StyledParagraph> We will use reasonable efforts to:</StyledParagraph>
          <StyledParagraph>
            delete accounts which are being used in an inappropriate manner or in breach of these Terms; and
          </StyledParagraph>
          <StyledParagraph>
            identify and remove any content that is inappropriate, defamatory, infringes intellectual property rights or
            is otherwise in breach of these Terms and/or our <Link to={location.privacyUrl()}>Privacy Policy</Link>
          </StyledParagraph>
          <StyledParagraph>
            when we are notified, but we cannot be responsible if you have failed to provide us with the relevant
            information.
          </StyledParagraph>
          <StyledParagraph>
            If you believe that any content which is distributed or published by Uqoud is inappropriate, defamatory or
            infringing on intellectual property rights, you should contact us immediately using the contact details at
            the top of this page.
          </StyledParagraph>
        </TitledParagraph>
        <TitledParagraph title="Your Privacy and Personal Information">
          <StyledParagraph>
            Your privacy and personal information are important to us. Any personal information that you provide to us
            will be dealt with in line with our <Link to={location.privacyUrl()}>Privacy Policy</Link>, which explains
            what personal information we collect from you, how and why we collect, store, use and share such
            information, your rights in relation to your personal information and how to contact us and supervisory
            authorities in the event you have a query or complaint about the use of your personal information.
          </StyledParagraph>
        </TitledParagraph>
        <TitledParagraph title="Ownership, Use and Intellectual Property Rights">
          <StyledParagraph>
            The intellectual property rights in Uqoud and in any text, images, video, audio or other multimedia content,
            software or other information or material submitted to or accessible from Uqoud (the “Content”) are owned by
            us and our licensors.
          </StyledParagraph>
          <StyledParagraph>
            We and our licensors reserve all our intellectual property rights (including, but not limited to, all
            copyright, trademarks, domain names, design rights, database rights, patents and all other intellectual
            property rights of any kind) whether registered or unregistered anywhere in the world. This means, for
            example, that we remain owners of them and are free to use them as we see fit.
          </StyledParagraph>
          <StyledParagraph>
            Nothing in these Terms grants you any legal rights in Uqoud or the Content other than as necessary for you
            to access it. You agree not to adjust, try to circumvent or delete any notices contained on Uqoud or the
            Content (including any intellectual property notices) and in particular, in any digital rights or other
            security technology embedded or contained within Uqoud or the Content.
          </StyledParagraph>
          <StyledParagraph>
            Trademarks: Uqoud is a trademark of Kryptos in process of registration in Jordan and the UAE, and
            “GRADIANT”, a trademark registered in Spain under the registration number (016202269), is a trademark of
            Fundación Centro Tecnolóxico de Telecomunicacións de Galicia, a company duly registered in Spain, VAT number
            (G36997229), whose registered address is at Carretera do Vilar, 56-58 36314 Vigo, Pontevedra, Spain. Other
            trademarks and trade names may also be used Uqoud or in the Content. Use by you of any trademarks on Uqoud
            or in the Content is strictly prohibited unless you have our prior written permission
          </StyledParagraph>
          <StyledParagraph>
            When we transfer your information to other countries, we will use, share and safeguard that information as
            described in this Policy. To conduct the services, we may transfer the personal information we collect to
            countries which do not provide the same level of data protection as the country in which you reside and are
            not recognized by the European Commission as providing an adequate level of data protection. We only
            transfer personal information to these countries, with your consent, when it is necessary for the services
            we provide you, or subject to appropriate safeguards that assure the protection of your personal
            information, such as standard contractual clauses.
          </StyledParagraph>
        </TitledParagraph>
        <TitledParagraph title="Accuracy of Information and Availability of Uqoud">
          <StyledParagraph>
            We try to make sure that Uqoud is accurate, up-to-date and free from bugs, but we cannot promise that it
            will be. Furthermore, we cannot promise that Uqoud will be fit or suitable for any purpose. Any reliance
            that you may place on the information found on Uqoud is so placed at your own risk.
          </StyledParagraph>
          <StyledParagraph>
            We may suspend or terminate access or operation of Uqoud at any time as we see fit.
          </StyledParagraph>
          <StyledParagraph>
            Unless otherwise agreed in a Services Contract executed between us and you as a Client, any Content on Uqoud
            is provided for your general information purposes only and to inform you about us and our products and news,
            features, services and other websites that may be of interest, but has not been tailored to your specific
            requirements or circumstances. It does not constitute technical, financial or legal advice or any other type
            of advice and should not be relied on for any purposes. In any case, you should always use your own
            independent judgment when using Uqoud and its Content.
          </StyledParagraph>
          <StyledParagraph>
            While we try to make sure that Uqoud is available for your use, we do not promise that Uqoud will be
            available at all times or that your use of Uqoud will be uninterrupted.
          </StyledParagraph>
        </TitledParagraph>
        <TitledParagraph title="Limitation on our Liability">
          <StyledParagraph>
            Except for any legal responsibility that we cannot exclude in law (such as for death or personal injury) or
            arising under applicable laws relating to the protection of your personal information, we are not legally
            responsible for any:
          </StyledParagraph>
          <StyledParagraph>
            losses that were not foreseeable to you and us when these Terms were formed;
          </StyledParagraph>
          <StyledParagraph>losses that were not caused by any breach on our part;</StyledParagraph>
          <StyledParagraph>business losses;</StyledParagraph>
          <StyledParagraph>losses to non-consumers; or</StyledParagraph>
          <StyledParagraph>
            otherwise any indirect, incidental, economic, punitive, special or consequential damage or losses, howsoever
            caused and whether arising under contract, tort arising out of these Terms or out of the use of or inability
            to use Uqoud, unless such loss or damage is caused by the fraud, negligence or willful misconduct of
            Kryptos.
          </StyledParagraph>
        </TitledParagraph>
        <TitledParagraph title="Events Beyond our Control">
          <StyledParagraph>
            We are not liable to you if we fail to comply with these Terms because of circumstances beyond our
            reasonable control, including, but not limited to, strikes, lock-outs or other industrial disputes;
            breakdown of systems or network access, including power outages and internet connection interruptions;
            flood, fire, explosion or accident; epidemics or pandemics; war, hostilities (whether war be declared or
            not), invasion, act of foreign enemies, mobilisation, requisition or embargo, acts or threats of terrorism,
            or regional or global cybersecurity attacks or any other event beyond the control and security levels
            provided by common security tools.{' '}
          </StyledParagraph>
        </TitledParagraph>
        <TitledParagraph title="Rights of Third Parties">
          <StyledParagraph>
            No one other than a party to these Terms has any right to enforce any of these Terms.
          </StyledParagraph>
        </TitledParagraph>
        <TitledParagraph title="Variation">
          <StyledParagraph>
            No changes to these Terms are valid or have any effect unless agreed by us in writing or made in accordance
            with this clause.
          </StyledParagraph>
          <StyledParagraph>
            We reserve the right to vary these Terms from time to time. Our updated Terms will be displayed on Uqoud and
            by continuing to use and access Uqoud following such changes, you agree to be bound by any variation made by
            us. It is your responsibility to check these Terms from time to time to verify such variations.
          </StyledParagraph>
        </TitledParagraph>
        <TitledParagraph title="Disputes">
          <StyledParagraph>
            We will try to resolve any disputes with you quickly and efficiently. If you are unhappy with us, please
            contact us as soon as possible using the contact details set out at the top of this page.
          </StyledParagraph>
          <StyledParagraph>
            These Terms shall be governed by the laws of the Hashemite Kingdom of Jordan. If you want to take court
            proceedings, the relevant courts of the Hashemite Kingdom of Jordan will have non-exclusive jurisdiction in
            relation to these Terms.
          </StyledParagraph>
        </TitledParagraph>
      </Box>
    </Box>
  )
}
