import React from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { NOTIFICATION_MODAL_TYPES } from '../../../../ui/Modals/NotificationModal/NotificationModal.constants'
import { NotificationModalView } from '../../../../ui/Modals/NotificationModal/NotificationModalView'
import translations from '../../../../../translations/keys'
import { modalsPayloadSelector } from '../../../../../store/modals/modals.selectors'
import { SUBSCRIPTION_MODALS } from '../../../../../constants/subscription'
import { location } from '../../../../../utils/location'

import { ChangePlanModalProps, ChangePlanModalPayload } from './ChangePlanModal.types'

export const ChangePlanModal: React.FC<ChangePlanModalProps> = ({ onClose }) => {
  const history = useHistory()

  const { id, term, description }: ChangePlanModalPayload = useSelector(
    modalsPayloadSelector(SUBSCRIPTION_MODALS.CHANGE_PLAN_MODAL)
  )

  const onSuccess = () => {
    onClose()
    history.push(location.subscriptionPaymentUrl(id, term))
  }

  return (
    <NotificationModalView
      type={NOTIFICATION_MODAL_TYPES.WARNING}
      onClose={onClose}
      description={description}
      successButtonText={translations.OK}
      onSuccess={onSuccess}
    />
  )
}
