import React, { useState } from 'react'

import Button from '../../ui/Button'
import { BUTTON_VARIANTS } from '../../ui/Button/Button.types'
import { MobilePopup } from '../MobilePopup/MobilePopup'

import { MobilePopupButtonProps } from './MobilePopupButton.types'

export const MobilePopupButton: React.FC<MobilePopupButtonProps> = ({
  variant = BUTTON_VARIANTS.TEXT,
  buttonIcon,
  buttonIconColor,
  children,
  popupContent,
  popupTitle,
}) => {
  const [visible, setVisible] = useState(false)

  const handleDropdownOpen = () => {
    setVisible((prevValue) => !prevValue)
  }
  const handleClose = () => {
    setVisible(false)
  }

  return (
    <div>
      <Button variant={variant} onClick={handleDropdownOpen} icon={buttonIcon} iconColor={buttonIconColor}>
        {children}
      </Button>
      {visible && <MobilePopup popupContent={popupContent} popupTitle={popupTitle} handleClose={handleClose} />}
    </div>
  )
}
