import React from 'react'

import { SvgIconProps } from '../../ui/SvgIcon/SvgIcon.types'
import SvgIcon from '../../ui/SvgIcon'

export const ArrowDownIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <path d="M15.8409 14.5152L12.3841 17.9721C12.172 18.1842 11.828 18.1842 11.6159 17.9721L8.15909 14.5152C7.94697 14.3031 7.94697 13.9592 8.15909 13.7471C8.37122 13.5349 8.71515 13.5349 8.92728 13.7471L11.4568 16.2766L11.4568 6L12.5432 6L12.5432 16.2766L15.0727 13.7471C15.2849 13.5349 15.6288 13.5349 15.8409 13.7471C16.053 13.9592 16.053 14.3031 15.8409 14.5152Z" />
  </SvgIcon>
)
