import { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FormikProps } from 'formik'

import translations from '../../../../translations/keys'
import { AddRecipientsForm } from '../../../../types/recipients'
import { FETCH_STATUSES } from '../../../../types/fetch'
import { TEMPLATES_FILL_RECIPIENTS_MODALS } from '../../../../constants/templatesFillRecipients'
import { validateActions } from '../../../../utils/recipients'
import { openModal } from '../../../../store/modals/modals.actions'
import { fieldsFetchSelector } from '../../../../store/templates/fields/fields.selectors'
import { BUTTON_VARIANTS } from '../../../ui/Button/Button.types'
import { NOTIFICATION_MODAL_TYPES } from '../../../ui/Modals/NotificationModal/NotificationModal.constants'
import { COMMON_MODALS_IDS } from '../../../ui/Modals/CommonModals/CommonModals.constants'
import { CloseIcon } from '../../../assets/CloseIcon'
import { PlayIcon } from '../../../assets/icons'
import { useAccessFeature } from '../../../../hooks/subscription/useAccessFeature'
import { SubscriptionFeatures } from '../../../../types/subscription'

const SINGLE_RECIPIENT = 1

export const useButtons = (formRef: React.MutableRefObject<FormikProps<AddRecipientsForm> | null>) => {
  const dispatch = useDispatch()
  const fetchFieldsRequest = useSelector(fieldsFetchSelector)
  const { checkAccess } = useAccessFeature()
  const isCreateBtnActive = fetchFieldsRequest.status === FETCH_STATUSES.SUCCESS

  return useMemo(
    () => [
      {
        label: translations.DELETE_AND_CLOSE,
        onClick: () => {
          dispatch(openModal(TEMPLATES_FILL_RECIPIENTS_MODALS.ABORT_CREATING_CONTRACT))
        },
        MobileIcon: CloseIcon,
        variant: BUTTON_VARIANTS.SECONDARY,
      },
      {
        disabled: !isCreateBtnActive,
        label: translations.CREATE,
        onClick: () => {
          const form = formRef.current

          if (!form) {
            return
          }

          const recipientsLength = form.values.recipients.length

          if (
            recipientsLength === SINGLE_RECIPIENT ||
            (recipientsLength > SINGLE_RECIPIENT && checkAccess(SubscriptionFeatures.FEATURE_UNLIMITED_BULK_SENDING))
          ) {
            const error = validateActions(form.values.recipients)

            if (error) {
              dispatch(
                openModal(COMMON_MODALS_IDS.NOTIFICATION, {
                  type: NOTIFICATION_MODAL_TYPES.WARNING,
                  description: translations[error],
                })
              )
            } else {
              form.handleSubmit()
            }
          }
        },
        MobileIcon: PlayIcon,
      },
    ],
    [fetchFieldsRequest, isCreateBtnActive]
  )
}
