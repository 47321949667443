import { Epic, combineEpics, ofType } from 'redux-observable'
import { from, zip } from 'rxjs'
import { catchError, map, mergeMap, switchMap, takeUntil } from 'rxjs/operators'

import { documentsService, contractsService, recipientsService } from '../../../api'
import { catchFetchError } from '../../../utils/catchFetchError'
import {
  generatePlacement,
  preparePlacement,
  preparePlacementForRequest,
  updatePlacementById,
} from '../../../utils/placement'
import { signingRecipientTokenSelector } from '../signing/signing.selectors'
import { loadImage } from '../../../utils/loadImage'

import * as ACTIONS from './placement.actions'
import { placementByIdSelector } from './placement.selectors'

const fetchPlacement: Epic = (action$) =>
  action$.pipe(
    ofType(ACTIONS.fetchPlacementTypes.request),
    mergeMap(({ payload }) =>
      recipientsService.fetchPlacement(payload.contractId).pipe(
        map((response) => ACTIONS.fetchPlacementSuccess(...preparePlacement(response, payload.recipientsById))),
        catchError(catchFetchError(ACTIONS.fetchPlacementFailure)),
        takeUntil(action$.pipe(ofType(ACTIONS.fetchPlacementTypes.success, ACTIONS.fetchPlacementTypes.failure)))
      )
    )
  )

const fetchUpdatePlacement: Epic = (action$, state$) =>
  action$.pipe(
    ofType(ACTIONS.fetchUpdatePlacementTypes.request),
    switchMap(({ payload }) =>
      recipientsService
        .fetchAddPlacement(
          preparePlacementForRequest(
            updatePlacementById(payload.place, placementByIdSelector(state$.value)),
            payload.sorted
          )
        )
        .pipe(
          map(({ response }) => ACTIONS.fetchUpdatePlacementSuccess(response)),
          catchError(catchFetchError(ACTIONS.fetchUpdatePlacementFailure)),
          takeUntil(
            action$.pipe(ofType(ACTIONS.fetchUpdatePlacementTypes.success, ACTIONS.fetchUpdatePlacementTypes.failure))
          )
        )
    )
  )

const fetchGeneratePlacement: Epic = (action$, state$) =>
  action$.pipe(
    ofType(ACTIONS.fetchGeneratePlacementTypes.request),
    mergeMap(({ payload }) =>
      contractsService.fetchContract(payload.contractId).pipe(
        map((response) => response.document.id),
        switchMap((documentId) =>
          zip(
            documentsService.fetchDocumentTotalPages(documentId).pipe(map((response) => response.totalPages)),
            from(
              loadImage(
                documentsService.getDocumentPreviewPageSrc(documentId, 0),
                signingRecipientTokenSelector(state$.value)
              )
            )
          ).pipe(
            map(([totalPages, image]) =>
              generatePlacement(state$.value.contracts, totalPages, image.width, image.height)
            ),
            switchMap((placement) =>
              recipientsService.fetchAddPlacement(placement).pipe(map(() => ACTIONS.fetchGeneratePlacementSuccess()))
            )
          )
        ),
        catchError(catchFetchError(ACTIONS.fetchGeneratePlacementFailure)),
        takeUntil(
          action$.pipe(ofType(ACTIONS.fetchGeneratePlacementTypes.success, ACTIONS.fetchGeneratePlacementTypes.failure))
        )
      )
    )
  )

const fetchSendContract: Epic = (action$) =>
  action$.pipe(
    ofType(ACTIONS.fetchSendContractTypes.request),
    mergeMap(({ payload }) =>
      contractsService.fetchStartSigningContract(payload.contractId).pipe(
        map(() => ACTIONS.fetchSendContractSuccess()),
        catchError(catchFetchError(ACTIONS.fetchSendContractFailure)),
        takeUntil(action$.pipe(ofType(ACTIONS.fetchSendContractTypes.success, ACTIONS.fetchSendContractTypes.failure)))
      )
    )
  )

  const fetchContractStatus: Epic = (action$) =>
  action$.pipe(
    ofType(ACTIONS.fetchContractStatusTypes.request),
    mergeMap(({ payload }) =>
      contractsService.fetchContractStatus(payload.contractId).pipe(
        map((response) => ACTIONS.fetchContractStatusSuccess(<{status:string}>response)),
        catchError(catchFetchError(ACTIONS.fetchContractStatusFailure)),
        takeUntil(action$.pipe(ofType(ACTIONS.fetchContractStatusTypes.success, ACTIONS.fetchContractStatusTypes.failure)))
      )
    )
  )

export const placementEpics = combineEpics(
  fetchPlacement,
  fetchUpdatePlacement,
  fetchGeneratePlacement,
  fetchSendContract,
  fetchContractStatus
)
