import { FETCH_STATUSES } from '../../../types/fetch'
import { RootState } from '../../rootReducer.types'

export const fieldsByIdSelector = (state: RootState) => state.templates.fields.byId
export const fieldsByPagesSelector = (state: RootState) => state.templates.fields.byPages
export const fieldsFontSizeSelector = (state: RootState) => state.templates.fields.fontSize
export const fieldsScrollToFieldSelector = (state: RootState) => state.templates.fields.scrollToField
export const fieldsCountSelector = (state: RootState) => Object.keys(state.templates.fields.byId).length
export const fieldsFetchSelector = (state: RootState) => state.templates.fields.fetch
export const fieldsFetchUpdateSelector = (state: RootState) => state.templates.fields.update

export const fieldsUpdateIsSuccess = (state: RootState) =>
  state.templates.fields.update.status === FETCH_STATUSES.SUCCESS
