import { FetchFailureAction, FETCH_STATUSES } from '../../types/fetch'
import { createReducer } from '../createReducer'

import * as ACTIONS from './changePassword.actions'
import * as TYPES from './changePassword.types'

type Actions = FetchFailureAction

const initialState: TYPES.ChangePasswordState = {
  fetchStatus: FETCH_STATUSES.IDLE,
}

export const changePassword = createReducer<TYPES.ChangePasswordState, Actions>(initialState, {
  [TYPES.ChangePasswordActions.RESET_CHANGE_PASSWORD]: () => ({ ...initialState }),
  [ACTIONS.fetchChangePasswordTypes.request]: (state) => ({
    ...state,
    fetchStatus: FETCH_STATUSES.REQUEST,
  }),
  [ACTIONS.fetchChangePasswordTypes.success]: (state) => ({
    ...state,
    fetchStatus: FETCH_STATUSES.SUCCESS,
  }),
  [ACTIONS.fetchChangePasswordTypes.failure]: (state, action) => ({
    ...state,
    fetchStatus: FETCH_STATUSES.FAILURE,
    error: (action as FetchFailureAction).payload,
  }),
})
