import { FetchFailureAction, FETCH_STATUSES } from '../../../types/fetch'
import { createReducer } from '../../createReducer'

import * as TYPES from './userPlan.types'
import * as ACTIONS from './userPlan.actions'
import { splitLimitsAndFeatures } from './userPlan.utils'

type Actions = FetchFailureAction | TYPES.FetchUserPlanSuccess

export const initialState: TYPES.UserPlanState = {
  fetchStatus: FETCH_STATUSES.IDLE,
  error: null,
  data: null,
  enabledFeatures: null,
  enabledLimits: null,
  isFirstLoad: false,
}

export const userPlan = createReducer<TYPES.UserPlanState, Actions>(initialState, {
  [ACTIONS.fetchUserPlanTypes.request]: (state) => ({
    ...state,
    error: null,
    fetchStatus: FETCH_STATUSES.REQUEST,
  }),
  [ACTIONS.fetchUserPlanTypes.success]: (state, action) => {
    const data = (action as TYPES.FetchUserPlanSuccess).payload.data
    const { enabledFeatures, enabledLimits } = splitLimitsAndFeatures(data.features)

    return {
      ...state,
      fetchStatus: FETCH_STATUSES.SUCCESS,
      data,
      enabledFeatures,
      enabledLimits,
      isFirstLoad: true,
    }
  },
  [ACTIONS.fetchUserPlanTypes.failure]: (state, action) => ({
    ...state,
    fetchStatus: FETCH_STATUSES.FAILURE,
    error: (action as FetchFailureAction).payload,
    isFirstLoad: true,
  }),
  [TYPES.UserPlanActions.CLEAR_SUBSCRIPTION_USER_PLAN]: () => ({ ...initialState }),
})
