import React from 'react'
import { useTranslation } from 'react-i18next'

import translations from '../../../../translations/keys'
import Box from '../../Box'
import Typography from '../../Typography'
import { TYPOGRAPHY_NAMES } from '../../Typography/Typography.types'
import RecipientCard from '../RecipientCard'

import { CycleProps } from './Cycle.types'
import * as Styled from './Cycle.styles'

export const Cycle: React.FC<CycleProps> = ({
  order,
  recipientsById,
  recipientsSorted,
  compact,
  displayStatus,
  editCondition,
  deleteCondition,
  onClick,
  onEdit,
  onDelete,
}) => {
  const alignItems = compact ? 'flex-start' : 'center'
  const recipientsTopMargin = compact ? 2 : 3
  const recipientCardHorMargin = compact ? 0 : 1
  const recipientCardBottomMargin = compact ? 2 : 3
  const { t } = useTranslation()
  return (
    <Box data-testid="cycle-div" display="flex" flexDirection="column" alignItems={alignItems}>
      <Styled.CycleNumber data-testid="cycle-number" justifyContent={alignItems}>
        <Typography data-testid="cycle-typography" name={TYPOGRAPHY_NAMES.tableColumn}>
          {t(translations.APPROVAL_CYCLE)} {order}
        </Typography>
      </Styled.CycleNumber>
      <Styled.Recipients
        data-testid="cycle-recipients-div"
        flexDirection={compact ? 'column' : 'row'}
        mt={recipientsTopMargin}
      >
        {recipientsSorted.map((id) => (
          <Box
            data-testid="cycle-recipient-div"
            key={id}
            mr={recipientCardHorMargin}
            ml={recipientCardHorMargin}
            mb={recipientCardBottomMargin}
          >
            <RecipientCard
              displayStatus={displayStatus}
              data={recipientsById[id]}
              editCondition={editCondition}
              deleteCondition={deleteCondition}
              onClick={onClick}
              onEdit={onEdit}
              onDelete={onDelete}
            />
          </Box>
        ))}
      </Styled.Recipients>
    </Box>
  )
}
