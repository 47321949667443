import React from 'react'
import { useTheme } from 'styled-components'
import { useSelector } from 'react-redux'

import { StyledThemeScheme } from '../../../../themes/light'
import useMediaQuery from '../../../hooks/useMediaQuery'
import Box from '../../../ui/Box'
import Header from '../../Header'
import Menu from '../../Menu'
import MobileMenu from '../../MobileMenu'
import Sidebar from '../../../ui/Sidebar'
import { SidebarToggle } from '../../../ui/Sidebar/SidebarToggle'
import ErrorBoundary from '../../ErrorBoundary/ErrorBoundary'
import { isBannerVisibleSelector } from '../../../../store/banner/banner.selectors'

import { StyledWrapper, StyledContent } from './MainTemplate.styles'
import { MainTemplateProps } from './MainTemplate.types'

export const MainTemplate: React.FC<MainTemplateProps> = ({ children, additionalMobileNode, simple = false }) => {
  const isBannerVisible = useSelector(isBannerVisibleSelector)
  const theme = useTheme() as StyledThemeScheme
  const mobileScreen = useMediaQuery(theme.devices.mobile)
  const tabletScreen = useMediaQuery(theme.devices.tablet)

  const renderMenu = () => {
    if (simple) {
      return null
    }

    if (mobileScreen || tabletScreen) {
      return <MobileMenu />
    }

    return <Menu />
  }

  return (
    <>
      <ErrorBoundary isHeader>
        <Header simple={simple} />
      </ErrorBoundary>
      {renderMenu()}
      <StyledWrapper simple={simple}>
        {!simple && <Sidebar />}
        <StyledContent isBannerVisible={isBannerVisible}>
          {!simple && mobileScreen && (
            <Box mb={3} display="flex" justifyContent="space-between">
              <SidebarToggle />
              {additionalMobileNode}
            </Box>
          )}
          <ErrorBoundary>{children}</ErrorBoundary>
        </StyledContent>
      </StyledWrapper>
    </>
  )
}
