import React, { useCallback, useState } from 'react'
import { useDropzone, FileRejection } from 'react-dropzone'
import { useTranslation } from 'react-i18next'

import { CloudUploadIcon } from '../../assets/icons/CloudUploadIcon'
import translations from '../../../translations/keys'
import Box from '../Box'
import Typography from '../Typography'
import { TYPOGRAPHY_NAMES } from '../Typography/Typography.types'
import { Spinner } from '../Spinner/Spinner'
import Button from '../Button'
import { BUTTON_VARIANTS } from '../Button/Button.types'

import { options, errorsMessages } from './UploadFile.constants'
import { StyledWrapper, StyledValidationInfo, StyledError, StyledDropzone } from './UploadFile.styles'
import { UploadFileProps } from './UploadFile.types'

export const UploadFile: React.FC<UploadFileProps> = ({ validationInfo, loading = false, onSuccess }) => {
  const { t } = useTranslation()
  const [errorMessage, setErrorMessage] = useState('')
  const onDrop = useCallback((acceptedFiles: File[], fileRejections: FileRejection[]) => {
    if (loading) {
      return
    }

    const error = fileRejections.length ? fileRejections[0].errors[0].code : undefined

    if (error) {
      setErrorMessage(errorsMessages[error] || translations.SOME_ERROR_OCCURRED)
      return
    }

    onSuccess(acceptedFiles[0])
  }, [])
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ ...options, onDrop })

  return (
    <Box width="100%" data-testid="upload-file-div">
      <StyledDropzone {...getRootProps()} disabled={loading} data-testid="upload-file-dropzone">
        <StyledWrapper isDragActive={isDragActive} data-testid="upload-file-wrapper">
          <input {...getInputProps()} data-testid="upload-file-input" />
          <Box display="flex" flexDirection="column" alignItems="center" width={320}>
            <Box>
              <CloudUploadIcon size="large" color="grey" data-testid="upload-file-cloudIcon" />
            </Box>
            <Typography name={TYPOGRAPHY_NAMES.body16}>{t(translations.DROP_FILE_HERE)}</Typography>
            <Box mb={2}>
              <Typography name={TYPOGRAPHY_NAMES.body16} textCase="uppercase">
                {t(translations.OR)}
              </Typography>
            </Box>
            {loading ? (
              <Spinner show />
            ) : (
              <Button variant={BUTTON_VARIANTS.INHERIT} fullWidth data-testid="upload-file-button">
                {t(translations.BROWSE_FILES)}
              </Button>
            )}
          </Box>
        </StyledWrapper>
      </StyledDropzone>
      {errorMessage ? (
        <StyledError data-testid="upload-file-error">{t(errorMessage)}</StyledError>
      ) : (
        <StyledValidationInfo data-testid="upload-file-validationInfo">
          <Typography data-testid="upload-file-typography" name={TYPOGRAPHY_NAMES.body16}>
            {validationInfo}
          </Typography>
        </StyledValidationInfo>
      )}
    </Box>
  )
}
