import React from 'react'

export const SubscriptionSvg: React.FC = (props) => (
  <svg width="156" height="96" viewBox="0 0 156 96" fill="none" {...props}>
    <path
      d="M124.158 13.7083C124.109 12.7554 123.323 12 122.36 12H33.8C32.837 12 32.0506 12.7554 32.0022 13.7083C32 13.7392 32 13.7696 32 13.8006V82.1989C32 83.1934 32.8066 84 33.8 84H122.36C123.353 84 124.16 83.1934 124.16 82.1989V13.8006C124.16 13.7696 124.16 13.7392 124.158 13.7083ZM35.6 15.5792H120.56V23.8834H35.6V15.5792ZM120.56 80.3983H35.6V27.4845H120.56V80.3983Z"
      fill="#6085B3"
    />
    <path
      d="M62.7801 21.6562H48.3801C47.3856 21.6562 46.5801 20.8502 46.5801 19.8562C46.5801 18.8612 47.3856 18.0557 48.3801 18.0557H62.7801C63.7746 18.0557 64.5801 18.8612 64.5801 19.8562C64.5801 20.8502 63.7746 21.6562 62.7801 21.6562Z"
      fill="#DFE1E6"
    />
    <path
      d="M41.1834 21.6562C40.1894 21.6562 39.3828 20.8502 39.3828 19.8562C39.3828 18.8612 40.1877 18.0557 41.1822 18.0557H41.1834C42.1779 18.0557 42.9834 18.8612 42.9834 19.8562C42.9834 20.8502 42.1779 21.6562 41.1834 21.6562Z"
      fill="#DFE1E6"
    />
    <path
      d="M59.6583 58.815H44.5383C43.5438 58.815 42.7383 58.0095 42.7383 57.0144C42.7383 56.0199 43.5438 55.2139 44.5383 55.2139H59.6583C60.6528 55.2139 61.4583 56.0199 61.4583 57.0144C61.4583 58.0095 60.6528 58.815 59.6583 58.815Z"
      fill="#DFE1E6"
    />
    <path
      d="M59.6583 66.051H44.5383C43.5438 66.051 42.7383 65.2443 42.7383 64.2504C42.7383 63.2559 43.5438 62.4492 44.5383 62.4492H59.6583C60.6528 62.4492 61.4583 63.2559 61.4583 64.2504C61.4583 65.2443 60.6528 66.051 59.6583 66.051Z"
      fill="#DFE1E6"
    />
    <path
      d="M59.6583 73.2862H44.5383C43.5438 73.2862 42.7383 72.4807 42.7383 71.4861C42.7383 70.4916 43.5438 69.6855 44.5383 69.6855H59.6583C60.6528 69.6855 61.4583 70.4916 61.4583 71.4861C61.4583 72.4807 60.6528 73.2862 59.6583 73.2862Z"
      fill="#DFE1E6"
    />
    <path
      d="M52.098 51.8909C47.3336 51.8909 43.458 48.0148 43.458 43.2493C43.458 38.4832 47.3336 34.6064 52.098 34.6064C56.8624 34.6064 60.738 38.4832 60.738 43.2493C60.738 48.0148 56.8624 51.8909 52.098 51.8909ZM52.098 38.2076C49.3193 38.2076 47.058 40.4688 47.058 43.2493C47.058 46.0286 49.3193 48.2904 52.098 48.2904C54.8768 48.2904 57.138 46.0286 57.138 43.2493C57.138 40.4688 54.8773 38.2076 52.098 38.2076Z"
      fill="#6085B3"
    />
    <path
      d="M85.6397 58.815H70.5197C69.5252 58.815 68.7197 58.0095 68.7197 57.0144C68.7197 56.0199 69.5252 55.2139 70.5197 55.2139H85.6397C86.6342 55.2139 87.4397 56.0199 87.4397 57.0144C87.4397 58.0095 86.6342 58.815 85.6397 58.815Z"
      fill="#DFE1E6"
    />
    <path
      d="M85.6397 66.051H70.5197C69.5252 66.051 68.7197 65.2443 68.7197 64.2504C68.7197 63.2559 69.5252 62.4492 70.5197 62.4492H85.6397C86.6342 62.4492 87.4397 63.2559 87.4397 64.2504C87.4397 65.2443 86.6342 66.051 85.6397 66.051Z"
      fill="#DFE1E6"
    />
    <path
      d="M85.6397 73.2862H70.5197C69.5252 73.2862 68.7197 72.4807 68.7197 71.4861C68.7197 70.4916 69.5252 69.6855 70.5197 69.6855H85.6397C86.6342 69.6855 87.4397 70.4916 87.4397 71.4861C87.4397 72.4807 86.6342 73.2862 85.6397 73.2862Z"
      fill="#DFE1E6"
    />
    <path
      d="M78.0804 51.8909C73.3161 51.8909 69.4404 48.0148 69.4404 43.2493C69.4404 38.4832 73.3161 34.6064 78.0804 34.6064C82.8448 34.6064 86.7204 38.4832 86.7204 43.2493C86.7204 48.0148 82.8448 51.8909 78.0804 51.8909ZM78.0804 38.2076C75.3017 38.2076 73.0404 40.4688 73.0404 43.2493C73.0404 46.0286 75.3017 48.2904 78.0804 48.2904C80.8592 48.2904 83.1204 46.0286 83.1204 43.2493C83.1204 40.4688 80.8592 38.2076 78.0804 38.2076Z"
      fill="#6085B3"
    />
    <path
      d="M111.638 58.815H96.5178C95.5233 58.815 94.7178 58.0095 94.7178 57.0144C94.7178 56.0199 95.5233 55.2139 96.5178 55.2139H111.638C112.632 55.2139 113.438 56.0199 113.438 57.0144C113.438 58.0095 112.632 58.815 111.638 58.815Z"
      fill="#DFE1E6"
    />
    <path
      d="M111.638 66.051H96.5178C95.5233 66.051 94.7178 65.2443 94.7178 64.2504C94.7178 63.2559 95.5233 62.4492 96.5178 62.4492H111.638C112.632 62.4492 113.438 63.2559 113.438 64.2504C113.438 65.2443 112.632 66.051 111.638 66.051Z"
      fill="#DFE1E6"
    />
    <path
      d="M111.638 73.2862H96.5178C95.5233 73.2862 94.7178 72.4807 94.7178 71.4861C94.7178 70.4916 95.5233 69.6855 96.5178 69.6855H111.638C112.632 69.6855 113.438 70.4916 113.438 71.4861C113.438 72.4807 112.632 73.2862 111.638 73.2862Z"
      fill="#DFE1E6"
    />
    <path
      d="M104.077 51.8909C99.3131 51.8909 95.4375 48.0148 95.4375 43.2493C95.4375 38.4832 99.3131 34.6064 104.077 34.6064C108.842 34.6064 112.717 38.4832 112.717 43.2493C112.717 48.0148 108.842 51.8909 104.077 51.8909ZM104.077 38.2076C101.299 38.2076 99.0375 40.4688 99.0375 43.2493C99.0375 46.0286 101.299 48.2904 104.077 48.2904C106.856 48.2904 109.117 46.0286 109.117 43.2493C109.117 40.4688 106.856 38.2076 104.077 38.2076Z"
      fill="#6085B3"
    />
  </svg>
)
