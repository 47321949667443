import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { clearContracts, fetchContractsListData } from '../../store/contracts/contractsList/contractsList.actions'
import * as SELECTORS from '../../store/contracts/contractsList/contractsList.selectors'
import { ContractsCategory } from '../../types/contracts'
import { getFromToISODate } from '../../utils/date'
import { useDispatchUnmount } from '../useDispatchUnmount'
import { prepareContractsFetchParams } from '../../utils/contracts/prepareContractsFetchParams'

export const useContractsListFetch = (category?: ContractsCategory) => {
  const dispatch = useDispatch()
  const sorting = useSelector(SELECTORS.contractsListSortingSelector)
  const offset = useSelector(SELECTORS.contractsListOffsetSelector)
  const limit = useSelector(SELECTORS.contractsListLimitSelector)
  const searchText = useSelector(SELECTORS.contractsListSearchTextSelector)
  const filters = useSelector(SELECTORS.contractsListFiltersSelector)
  const forceUpdate = useSelector(SELECTORS.contractsListForceUpdateSelector)
  const folderId = useSelector(SELECTORS.contractsListFolderId)
  const filtersIsApplied = useSelector(SELECTORS.contractsListIsAppliedFiltersSelector)

  useEffect(() => {
    const methods = prepareContractsFetchParams(searchText, folderId, filtersIsApplied, filters, category)

    dispatch(
      fetchContractsListData({
        orderByField: sorting.field,
        order: sorting.direction,
        offset,
        limit,
        name: searchText,
        status: methods.getStatus(),
        createDateFrom: getFromToISODate(filters.createDateFrom),
        createDateTo: getFromToISODate(filters.createDateTo, true),
        received: methods.getReceived(),
        folderId: methods.getFolderId(),
        owner: methods.getOwner(),
        signed: methods.getSigned(),
      })
    )
  }, [sorting, searchText, offset, limit, filters, category, forceUpdate, folderId, filtersIsApplied])

  useDispatchUnmount(clearContracts)
}
