import { EKYCAttemptsResponse, EKYCEnrollResponse } from '../../types/ekyc'
import { FetchFailureAction, FetchFailurePayload } from '../../types/fetch'
import { createFetchActionTypes } from '../../utils/createFetchActionTypes'

import * as TYPES from './ekyc.types'

export const fetchEKYCTypes = createFetchActionTypes(TYPES.EKYCActions.FETCH_ENROLL)
export const fetchEKYCStatusTypes = createFetchActionTypes(TYPES.EKYCActions.FETCH_ENROLL_STATUS)
export const fetchEKYCVerificationTypes = createFetchActionTypes(TYPES.EKYCActions.FETCH_VERIFICATION)
export const fetchVerifyEKYCTypes = createFetchActionTypes(TYPES.EKYCActions.FETCH_EKYC_VERIFY)
export const fetchEKYCAttemptsTypes = createFetchActionTypes(TYPES.EKYCActions.FETCH_EKYC_ATTEMPTS)

export const fetchEnroll = (fileForFormData: TYPES.File, headers: TYPES.Headers) => ({
  type: fetchEKYCTypes.request,
  payload: { fileForFormData, headers },
})

export const fetchEnrollSuccess = (payload: EKYCEnrollResponse): TYPES.FetchEnrollSuccessAction => ({
  type: fetchEKYCTypes.success,
  payload,
})

export const fetchEnrollFailure = (payload: FetchFailurePayload): FetchFailureAction => ({
  type: fetchEKYCTypes.failure,
  payload,
})

export const fetchVerification = (fileForFormData: TYPES.File, headers: TYPES.Headers) => ({
  type: fetchEKYCVerificationTypes.request,
  payload: { fileForFormData, headers },
})

export const fetchVerificationSuccess = (payload: EKYCEnrollResponse): TYPES.FetchEnrollSuccessAction => ({
  type: fetchEKYCVerificationTypes.success,
  payload,
})

export const fetchVerificationFailure = (payload: FetchFailurePayload): FetchFailureAction => ({
  type: fetchEKYCVerificationTypes.failure,
  payload,
})

export const fetchEnrollStatus = () => ({ type: fetchEKYCStatusTypes.request })

export const fetchEnrollStatusSuccess = () => ({ type: fetchEKYCStatusTypes.success })

export const fetchEnrollStatusFailure = (payload: FetchFailurePayload): FetchFailureAction => ({
  type: fetchEKYCStatusTypes.failure,
  payload,
})

export const clearEnroll = () => ({
  type: TYPES.EKYCActions.CLEAR_ENROLL,
})

export const clearConfirmation = () => ({
  type: TYPES.EKYCActions.CLEAR_CONFIRMATION,
})

export const clearVerification = () => ({
  type: TYPES.EKYCActions.CLEAR_VERIFICATION,
})

export const clearEKYC = () => ({
  type: TYPES.EKYCActions.CLEAR_EKYC,
})

export const clearAttempts = () => ({
  type: TYPES.EKYCActions.CLEAR_ATTEMPTS,
})

export const fetchVerifyEKYC = (fileForFormData: TYPES.File, headers: TYPES.Headers, isEKYC_ID?: boolean) => ({
  type: fetchVerifyEKYCTypes.request,
  payload: { fileForFormData, headers, isEKYC_ID },
})

export const fetchVerifyEKYCSuccess = () => ({
  type: fetchVerifyEKYCTypes.success,
})

export const fetchVerifyEKYCFailure = (payload: FetchFailurePayload): FetchFailureAction => ({
  type: fetchVerifyEKYCTypes.failure,
  payload,
})

export const fetchEKYCAttempts = (contractId: string) => ({
  type: fetchEKYCAttemptsTypes.request,
  payload: { contractId },
})

export const fetchEKYCAttemptsSuccess = (payload: EKYCAttemptsResponse) => ({
  type: fetchEKYCAttemptsTypes.success,
  payload,
})

export const fetchEKYCAttemptsFailure = (payload: FetchFailurePayload): FetchFailureAction => ({
  type: fetchEKYCAttemptsTypes.failure,
  payload,
})
