import React from 'react'

import { SvgIconProps } from '../../ui/SvgIcon/SvgIcon.types'
import SvgIcon from '../../ui/SvgIcon'

export const HamburgerIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon viewBox="0 0 24 20" {...props}>
    <rect width="24" height="4" />
    <rect y="8" width="24" height="4" />
    <rect y="16" width="24" height="4" />
  </SvgIcon>
)
