import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { BUNCHED_NOTIFICATIONS } from '../../../../../constants/userNotifications'
import { useDisabledFields } from '../../../../../hooks/notifications/useDisabledFields'
import { updateNotifications } from '../../../../../store/preferences/notifications/notifications.actions'
import translations from '../../../../../translations/keys'
import {
  UpdateNotification,
  UserNotificationsAllTypes,
  PreferencesNotifications,
  PreferencesNotificationsBunches,
  PreferencesNotificationsTypes,
} from '../../../../../types/preferencesNotifications'
import Box from '../../../../ui/Box'
import NotificationsBox from '../NotificationsBox'
import { OnChange, OnToggleAllType } from '../NotificationsBox/NotificationsBox.types'

import { NotificationsProps } from './Notifications.types'

export const Notifications: React.FC<NotificationsProps> = ({ data }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const allTypes: UserNotificationsAllTypes = useMemo(
    () => ({
      [PreferencesNotificationsTypes.SOCKET]: Object.values(data).every(
        (types) => types[PreferencesNotificationsTypes.SOCKET]
      ),
      [PreferencesNotificationsTypes.EMAIL]: Object.values(data).every(
        (types) => types[PreferencesNotificationsTypes.EMAIL]
      ),
      [PreferencesNotificationsTypes.PUSH]: Object.values(data).every(
        (types) => types[PreferencesNotificationsTypes.PUSH]
      ),
    }),
    [data]
  )
  const disabledFields = useDisabledFields()

  const onToggleContractChangesRequest: OnChange = ({ type }, checked) => {
    dispatch(
      updateNotifications({
        notifications: [
          {
            notification: PreferencesNotifications.CORRECTION_ADDED,
            way: type,
            blocked: !checked,
          },
          {
            notification: PreferencesNotifications.STRIKE_ADDED,
            way: type,
            blocked: !checked,
          },
        ],
      })
    )
  }

  const onChange: OnChange = ({ type, notification }, checked) => {
    dispatch(
      updateNotifications({
        notifications: [
          {
            notification,
            way: type,
            blocked: !checked,
          },
        ],
      })
    )

    if (notification === PreferencesNotifications.NOTIFICATION_CONTRACT_CHANGED) {
      onToggleContractChangesRequest({ type, notification }, checked)
    }
  }

  const onToggleAllType: OnToggleAllType = (type) => {
    dispatch(
      updateNotifications({
        notifications: (Object.keys(data) as PreferencesNotifications[]).reduce<UpdateNotification[]>(
          (newState, notification) => [...newState, { notification, way: type, blocked: allTypes[type] }],
          []
        ),
      })
    )
  }

  return (
    <Box>
      <NotificationsBox
        title={t(translations.CONTRACT_NOTIFICATIONS)}
        list={BUNCHED_NOTIFICATIONS[PreferencesNotificationsBunches.contract]}
        values={data}
        allTypes={allTypes}
        disabledFields={disabledFields}
        onChange={onChange}
        onToggleAllType={onToggleAllType}
      />
      <NotificationsBox
        title={t(translations.REMINDERS)}
        list={BUNCHED_NOTIFICATIONS[PreferencesNotificationsBunches.reminder]}
        values={data}
        disabledFields={disabledFields}
        onChange={onChange}
      />
      <NotificationsBox
        title={t(translations.CHASERS)}
        list={BUNCHED_NOTIFICATIONS[PreferencesNotificationsBunches.chasers]}
        values={data}
        disabledFields={disabledFields}
        onChange={onChange}
      />
    </Box>
  )
}
