import React from 'react'
import { useHistory } from 'react-router'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import translations from '../../../../../translations/keys'
import { getFeature } from '../../../../../utils/subscription/getFeature'
import Typography from '../../../../ui/Typography'
import { TYPOGRAPHY_NAMES } from '../../../../ui/Typography/Typography.types'
import Box from '../../../../ui/Box'
import { getFormattedDate } from '../../../../../utils/date'
import { DEFAULT_DATE_FORMAT } from '../../../../../constants/date'
import Button from '../../../../ui/Button'
import { BUTTON_VARIANTS } from '../../../../ui/Button/Button.types'
import { location } from '../../../../../utils/location'
import { preparePrice } from '../../../../../utils/subscription/preparePrice'

import { StyledPlan, StyledMark, StyledFeatures, StyledButtons, StyledUnsubscribe } from './Plan.styles'
import { PlanProps } from './Plan.types'

export const Plan: React.FC<PlanProps> = ({ data, expired }) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const onChangePlan = () => {
    history.push(location.subscriptionPlansUrl())
  }

  const onUnsubscribe = () => {
    console.log('unsubscribe')
  }

  if (expired) {
    return (
      <StyledPlan expired={expired}>
        <Typography name={TYPOGRAPHY_NAMES.H1}>{t(translations.SUBSCRIPTION_IS_EXPIRED)}</Typography>
        <Box width={208} mt={3}>
          <Button fullWidth onClick={onChangePlan}>
            {t(translations.CHANGE_PLAN)}
          </Button>
        </Box>
      </StyledPlan>
    )
  }

  if (!data) {
    return null
  }

  const contractsLimit = data.features.find((feature) => feature.limit)?.limit

  return (
    <StyledPlan>
      <Typography name={TYPOGRAPHY_NAMES.H1}>{data.name}</Typography>
      <Box mt={1}>
        <Typography name={TYPOGRAPHY_NAMES.regularH1}>{preparePrice(data.term, data.price, true)}</Typography>
      </Box>
      <StyledFeatures>
        {data.features.map((feature) => (
          <Box key={feature.name} width="50%" mobileWidth="100%" display="flex" alignItems="center" mb={2}>
            <StyledMark />
            {getFeature(feature, true)}
          </Box>
        ))}
      </StyledFeatures>
      <Box display="flex" justifyContent="space-between" mt={3}>
        <Box width="50%" display="flex" flexDirection="column">
          <Typography name={TYPOGRAPHY_NAMES.H4}>{t(translations.EXPIRATION_DATE)}</Typography>
          <Box mt={1}>{getFormattedDate(new Date(data.expireData), DEFAULT_DATE_FORMAT)}</Box>
        </Box>
        {contractsLimit && (
          <Box width="50%" display="flex" flexDirection="column">
            <Typography name={TYPOGRAPHY_NAMES.H4}>{t(translations.CONTRACTS)}</Typography>
            <Box mt={1}>
              {data.contractsCount || 0}/
              <Typography name={TYPOGRAPHY_NAMES.body14} color="grey">
                {contractsLimit}
              </Typography>
            </Box>
          </Box>
        )}
      </Box>
      <StyledButtons>
        <Box width={208} mobileWidth="100%">
          <Button fullWidth onClick={onChangePlan}>
            {t(translations.CHANGE_PLAN)}
          </Button>
        </Box>
        <StyledUnsubscribe>
          <Button fullWidth variant={BUTTON_VARIANTS.SECONDARY}>
            {t(translations.UNSUBSCRIBE)}
          </Button>
        </StyledUnsubscribe>
      </StyledButtons>
    </StyledPlan>
  )
}
