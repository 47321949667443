import React, { useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Form, Formik, FormikHandlers, FormikHelpers, FormikState } from 'formik'
import { useTranslation } from 'react-i18next'

import { countriesSelector } from '../../../store/countries/countries.selectors'
import { phonesSelector } from '../../../store/phones/phones.selectors'
import { industriesSelector } from '../../../store/industries/industries.selectors'
import translations from '../../../translations/keys'
import { cleanSignUpFetchStatus, fetchSignUp } from '../../../store/registration/registration.actions'
import AuthorizationBox from '../../components/Authorization/AuthorizationBox'
import FormTextInput from '../../ui/Forms/FormTextInput'
import FormWrapper from '../../components/FormWrapper'
import FormPassword from '../../ui/Forms/FormPassword'
import FormPhone from '../../ui/Forms/FormPhone'
import { TYPOGRAPHY_NAMES } from '../../ui/Typography/Typography.types'
import Link from '../../ui/Link'
import Button from '../../ui/Button'
import FormDropdown from '../../ui/Forms/FormDropdown'
import { registrationValidationSchema } from '../../../validation/registration/registrationValidationSchema'
import { EMAIL_MAX_LENGTH } from '../../../validation/emailValidationSchema'
import { location } from '../../../utils/location'
import {
  registrationFormSelector,
  registrationSignUpErrorSelector,
  registrationSignUpIsFailureSelector,
  registrationSignUpFetchStatusSelector,
} from '../../../store/registration/registration.selectors'
import { RegistrationFields, RegistrationFormValues } from '../../../types/registration'
import { prepareRegistrationBody } from '../../../utils/registration/prepareRegistrationBody'
import { FETCH_STATUSES } from '../../../types/fetch'
import { useHistoryPushAfterSuccess } from '../../hooks/useHistoryPushAfterSuccess'
import { useRegistrationFetch } from '../../../hooks/registration/useRegistrationFetch'
import { useShowFetchError } from '../../hooks/useShowFetchError'
import { NAME_MAX_LENGTH } from '../../../validation/nameValidationSchema'
import { useCleanFields } from '../../../hooks/useCleanFields'
import { registrationInitialValues } from '../../../initialValues/registrationInitialValues'

import {
  StyledButtonWrapper,
  StyledCheckbox,
  StyledLogIn,
  StyledTerms,
  StyledTitle,
  StyledValidation,
} from './Registration.styles'
import { selectPhoneCode } from './Registration.utils'

export const Registration = () => {
  const dispatch = useDispatch()
  const formRef = useRef(null)
  const countries = useSelector(countriesSelector)
  const phones = useSelector(phonesSelector)
  const industries = useSelector(industriesSelector)
  const registrationForm = useSelector(registrationFormSelector)
  const signUpFetchStatus = useSelector(registrationSignUpFetchStatusSelector)
  const signUpError = useSelector(registrationSignUpErrorSelector)
  const signUpFailure = useSelector(registrationSignUpIsFailureSelector)
  const { t } = useTranslation()

  const onSubmit = (formValues: RegistrationFormValues) => {
    if (signUpFetchStatus === FETCH_STATUSES.REQUEST) {
      return
    }

    const body = prepareRegistrationBody(formValues)

    dispatch(fetchSignUp({ body, registrationForm: formValues }))
  }
  const renderForm = ({
    handleSubmit,
    setFieldValue,
    values,
    touched,
    errors,
  }: FormikHelpers<RegistrationFormValues> & FormikHandlers & FormikState<RegistrationFormValues>) => {
    const onChangeCountry = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (!values[RegistrationFields.phone] || !touched[RegistrationFields.phone]) {
        setFieldValue(RegistrationFields.phone, selectPhoneCode(phones.data, e.target.value))
      }
    }
    const acceptedError = errors.accepted

    return (
      <Form>
        <FormTextInput
          name={RegistrationFields.firstName}
          label={t(translations.FIRST_NAME_LABEL)}
          placeholder={t(translations.FIRST_NAME_PLACEHOLDER)}
          maxLength={NAME_MAX_LENGTH}
        />
        <FormTextInput
          name={RegistrationFields.lastName}
          label={t(translations.LAST_NAME_LABEL)}
          placeholder={t(translations.LAST_NAME_PLACEHOLDER)}
          maxLength={NAME_MAX_LENGTH}
        />
        <FormDropdown
          name={RegistrationFields.country}
          label={t(translations.COUNTRY_LABEL)}
          placeholder={t(translations.COUNTRY_PLACEHOLDER)}
          options={countries.data}
          onChange={onChangeCountry}
        />
        <FormDropdown
          name={RegistrationFields.industry}
          label={t(translations.INDUSTRY_LABEL)}
          placeholder={t(translations.INDUSTRY_PLACEHOLDER)}
          options={industries.data}
        />
        <FormTextInput
          name={RegistrationFields.email}
          label={t(translations.EMAIL_LABEL)}
          placeholder={t(translations.EMAIL_YOUR_PLACEHOLDER)}
          maxLength={EMAIL_MAX_LENGTH}
          trim
        />
        <FormPhone name={RegistrationFields.phone} label={t(translations.PHONE_LABEL)} />
        <FormPassword
          name={RegistrationFields.password}
          label={t(translations.PASSWORD_LABEL)}
          placeholder={t(translations.REGISTRATION_PASSWORD_PLACEHOLDER)}
          autoComplete="new-password"
        />
        <FormPassword
          name={RegistrationFields.confirmPassword}
          label={t(translations.CONFIRM_PASSWORD_LABEL)}
          placeholder={t(translations.REGISTRATION_CONFIRM_PASSWORD_PLACEHOLDER)}
        />
        <StyledValidation name={TYPOGRAPHY_NAMES.bodySmall12}>
          {t(translations.PASSWORD_VALIDATION_INFO)}
        </StyledValidation>
        <StyledTerms>
          <StyledCheckbox name={RegistrationFields.accepted} label="" />
          {t(translations.REGISTRATION_ACCEPT)} <Link to={location.termsUrl()}>{t(translations.TERMS_OF_USE)}</Link>{' '}
          {t(translations.AND)} <Link to="/">{t(translations.PRIVACY_POLICY)}</Link>
        </StyledTerms>
        <StyledButtonWrapper>
          <Button onClick={handleSubmit} fullWidth error={acceptedError && t(acceptedError)} type="submit">
            {t(translations.SIGN_UP)}
          </Button>
        </StyledButtonWrapper>
        <StyledLogIn>
          {t(translations.REGISTRATION_HAVE_ACCOUNT)} <Link to={location.loginUrl()}>{t(translations.LOG_IN)}</Link>
        </StyledLogIn>
      </Form>
    )
  }

  useRegistrationFetch()

  useHistoryPushAfterSuccess(location.confirmPhone(), signUpFetchStatus, cleanSignUpFetchStatus)
  useShowFetchError(signUpFetchStatus, signUpError)
  useCleanFields(signUpFailure, [RegistrationFields.password, RegistrationFields.confirmPassword], formRef)

  return (
    <AuthorizationBox title={<StyledTitle>{t(translations.REGISTRATION_TITLE)}</StyledTitle>}>
      <FormWrapper size="lg">
        <Formik
          innerRef={formRef}
          validationSchema={registrationValidationSchema}
          initialValues={
            registrationForm
              ? { ...registrationForm, [RegistrationFields.confirmPassword]: '' }
              : registrationInitialValues
          }
          onSubmit={onSubmit}
        >
          {renderForm}
        </Formik>
      </FormWrapper>
    </AuthorizationBox>
  )
}
