import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { useTranslation } from 'react-i18next'

import translations from '../../../../../translations/keys'
import { ContractRouteParams } from '../../../../types/signing'
import * as COMMENTS_ACTIONS from '../../../../../store/contracts/comments/comments.actions'
import * as COMMENTS_SELECTORS from '../../../../../store/contracts/comments/comments.selectors'
import * as STRIKES_ACTIONS from '../../../../../store/contracts/strikes/strikes.actions'
import * as STRIKES_SELECTORS from '../../../../../store/contracts/strikes/strikes.selectors'
import * as CORRECTIONS_ACTIONS from '../../../../../store/contracts/corrections/corrections.actions'
import * as CORRECTIONS_SELECTORS from '../../../../../store/contracts/corrections/corrections.selectors'
import { modalsPayloadSelector } from '../../../../../store/modals/modals.selectors'
import { CONTRACT_MODALS } from '../../../../../constants/contract'
import { NOTIFICATION_MODAL_TYPES } from '../../../../ui/Modals/NotificationModal/NotificationModal.constants'
import { NotificationModalView } from '../../../../ui/Modals/NotificationModal/NotificationModalView'
import { authUserEmailSelector } from '../../../../../store/auth/auth.selectors'

import { SaveAnnotationPromptModalProps } from './SaveAnnotationPromptModal.types'

export const SaveAnnotationPromptModal: React.FC<SaveAnnotationPromptModalProps> = ({ onClose }) => {
  const { contractId } = useParams<ContractRouteParams>()
  const dispatch = useDispatch()
  const commentsById = useSelector(COMMENTS_SELECTORS.commentsByIdSelector)
  const correctionsById = useSelector(CORRECTIONS_SELECTORS.correctionsByIdSelector)
  const strikesById = useSelector(STRIKES_SELECTORS.strikesByIdSelector)
  const currentUserEmail = useSelector(authUserEmailSelector)
  const { item, title } = useSelector(modalsPayloadSelector(CONTRACT_MODALS.CONTRACT_SAVE_ANNOTATION_PROMPT))
  const { t } = useTranslation()

  const handleSave = () => {
    if (commentsById[item.id]) {
      dispatch(COMMENTS_ACTIONS.fetchCreateComment(contractId, item))
    } else if (correctionsById[item.id]) {
      dispatch(CORRECTIONS_ACTIONS.fetchCreateCorrection(contractId, item))
    } else if (strikesById[item.id]) {
      dispatch(STRIKES_ACTIONS.fetchCreateStrike(contractId, item))
    }

    onClose()
  }

  const handleCancel = () => {
    if (!currentUserEmail) {
      return
    }

    if (commentsById[item.id]) {
      dispatch(COMMENTS_ACTIONS.unsetComment(item.id))
    }

    onClose()
  }

  return (
    <NotificationModalView
      type={NOTIFICATION_MODAL_TYPES.INFO}
      description={title}
      successButtonText={t(translations.SAVE)}
      cancelButtonText={t(translations.CANCEL)}
      onSuccess={handleSave}
      onCancel={handleCancel}
      onClose={onClose}
    />
  )
}
