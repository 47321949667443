import styled from 'styled-components'

import FormCheckbox from '../../ui/Forms/FormCheckbox'
import Typography from '../../ui/Typography'

export const StyledValidation = styled(Typography)`
  color: ${({ theme }) => theme.palette.grey};
`
export const StyledTerms = styled.div`
  margin-top: 40px;
  text-align: center;
`
export const StyledButtonWrapper = styled.div`
  margin: 24px 0 41px;
`
export const StyledLogIn = styled.div`
  text-align: center;
  margin-bottom: 64px;
`
export const StyledCheckbox = styled(FormCheckbox)`
  margin-right: 8px;
`
export const StyledTitle = styled.span`
  display: inline-block;
  margin-bottom: 40px;
`
