import { combineEpics, Epic, ofType } from 'redux-observable'
import { of } from 'rxjs'
import { catchError, mergeMap, takeUntil } from 'rxjs/operators'

import { templateService } from '../../../api'
import { catchFetchError } from '../../../utils/catchFetchError'
import { templatesListForceUpdate } from '../list/list.actions'

import * as ACTIONS from './favorite.actions'

const fetchFavoriteTemplate: Epic = (action$) =>
  action$.pipe(
    ofType(ACTIONS.fetchFavoriteTypes.request),
    mergeMap(({ payload }) =>
      templateService
        .fetchUpdateTemplate(payload.id, {
          favorite: payload.favorite,
        })
        .pipe(
          mergeMap(() => of(templatesListForceUpdate(), ACTIONS.fetchFavoriteTemplateSuccess())),
          catchError(catchFetchError(ACTIONS.fetchFavoriteTemplateFailure)),
          takeUntil(action$.pipe(ofType(ACTIONS.fetchFavoriteTypes.success, ACTIONS.fetchFavoriteTypes.failure)))
        )
    )
  )

export const favoriteEpics = combineEpics(fetchFavoriteTemplate)
