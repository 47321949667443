export const blueDark = '#324C7E'
export const blueExtraDark = '#122240'
export const blue = '#6085B3'
export const blueExtraLight = '#F0F3F7'
export const blueOcean = '#1761EF'
export const blueMidnight = '#112448'
export const white = '#FFFFFF'
export const grey = '#8B96A9'
export const greyLight = '#DFE1E6'
export const greyExtraLight = '#EDEDED'
export const grey3 = '#F5F5F5'
export const strokeGrey = '#E7EAED'
export const transparentGrey = 'rgba(139, 150, 169, 0.5)'
export const errorColor = '#EB3434'
export const textColor = '#0F1522'
export const transparentDarkGrey = 'rgba(15, 21, 34, 0.8)'
export const green = '#61A60D'
export const black = '#000000'
export const glowColor = '#dee5ed'
export const yellow = '#F7BD00'
export const cyan = '#00B2FF'
export const pattensBlue = '#d0d9e1'
