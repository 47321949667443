import { Ajax } from '../ajax'
import { UploadDocumentFolder, UploadDocumentFoldersResponse } from '../../types/uploadDocument'
import { UploadFolderBody } from '../../types/uploadFolder'

class TemplateFolderService extends Ajax {
  pathname = 'template/folder'

  fetchFolders = () => this.getJSON<UploadDocumentFoldersResponse>(`${this.pathname}s`)

  fetchUpdateFolder = (id: string, body: UploadDocumentFolder) => this.put(`${this.pathname}/${id}`, body)

  fetchDeleteFolder = (id: string) => this.delete(`${this.pathname}/${id}`)

  fetchUploadFolder = (body: UploadFolderBody) => this.post(this.pathname, body)
}

export const templateFolderService = new TemplateFolderService()
