import { useEffect, useState } from 'react'

const useMediaQuery = (query: string, onlyMount = false) => {
  const mediaQueryList = window.matchMedia(`screen and ${query}`)
  const [value, setValue] = useState(mediaQueryList.matches)

  useEffect(() => {
    const handler = (event: MediaQueryListEvent) => {
      setValue(event.matches)
    }

    if (!onlyMount) {
      mediaQueryList.addListener(handler)
    }

    return () => {
      !onlyMount && mediaQueryList.removeListener(handler)
    }
  }, [onlyMount])

  return value
}

export default useMediaQuery
