import { Action } from 'redux'

import { FetchFailurePayload, FETCH_STATUSES } from '../../types/fetch'
import { ChangePasswordRequestBody } from '../../types/changePassword'

export interface ChangePasswordState {
  fetchStatus: FETCH_STATUSES
  error?: FetchFailurePayload
}

export enum ChangePasswordActions {
  'FETCH_CHANGE_PASSWORD' = 'FETCH_CHANGE_PASSWORD',
  'RESET_CHANGE_PASSWORD' = 'RESET_CHANGE_PASSWORD',
}

export interface FetchChangePasswordAction extends Action {
  payload: { body: ChangePasswordRequestBody }
}

export type FetchChangePasswordSuccessAction = Action
