import { FETCH_STATUSES } from '../../../types/fetch'
import { RootState } from '../../rootReducer.types'

export const subscriptionPaymentFormFieldsSelector = (state: RootState) => state.subscription.payment.form.fields
export const subscriptionPaymentFormFetchStatusSelector = (state: RootState) =>
  state.subscription.payment.form.fetchStatus
export const subscriptionPaymentFormErrorSelector = (state: RootState) => state.subscription.payment.form.error
export const subscriptionPaymentFormIsLoadingSelector = (state: RootState) =>
  [FETCH_STATUSES.IDLE, FETCH_STATUSES.REQUEST].includes(state.subscription.payment.form.fetchStatus)
export const subscriptionPaymentFormRefetchSelector = (state: RootState) => state.subscription.payment.form.refetch

export const subscriptionPaymentThreeDSecureUrlSelector = (state: RootState) =>
  state.subscription.payment.threeDSecureUrl

export const subscriptionPaymentProcessingSelector = (state: RootState) => state.subscription.payment.process.processing
export const subscriptionPaymentCardVerifiedSelector = (state: RootState) =>
  state.subscription.payment.process.cardVerified
export const subscriptionPaymentProcessErrorSelector = (state: RootState) => state.subscription.payment.process.error
