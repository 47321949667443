import React from 'react'

import AuthorizationTemplate from '../../components/Authorization/AuthorizationTemplate'

import { ConfirmPhone as ConfirmPhonePage } from './ConfirmPhone'

export const ConfirmPhone = () => (
  <AuthorizationTemplate>
    <ConfirmPhonePage />
  </AuthorizationTemplate>
)
