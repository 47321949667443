export enum DuplicateDocumentFields {
  name = 'name',
  folderId = 'folderId',
}

export interface DuplicateDocumentForm {
  name: string
  folderId: string
}

export interface DuplicateDocumentRequestBody {
  name: string
  folderId: string
}
