import React from 'react'
import { useTranslation } from 'react-i18next'

import { DocViewerMobileToolbarProps } from './DocViewerMobileToolbar.types'
import * as Styled from './DocViewerMobileToolbar.styles'

export const DocViewerMobileToolbar = ({ actions, activeDrawingElem }: DocViewerMobileToolbarProps) => {
  const { t } = useTranslation()
  return (
    <Styled.Nav>
      <Styled.List>
        {actions.map((item) => {
          const { Icon, name, disabled, selected, pointerMode, drawingCompName, onClick } = item
          const active = selected || (activeDrawingElem && activeDrawingElem === drawingCompName)
          return (
            <Styled.ListItem key={name}>
              <Styled.Button
                disabled={disabled}
                selected={active}
                onClick={onClick}
                data-type={pointerMode}
                data-comp-type={drawingCompName}
                noPointerEvents={Boolean(drawingCompName)}
              >
                <Styled.IconWrapper>
                  <Icon color="white" />
                </Styled.IconWrapper>
                {t(name)}
              </Styled.Button>
            </Styled.ListItem>
          )
        })}
      </Styled.List>
    </Styled.Nav>
  )
}
