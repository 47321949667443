import React, { useEffect, useRef } from 'react'
import { FormikProps } from 'formik'
import { useDispatch, useSelector } from 'react-redux'

import { CreateFolderBody, CreateFolderFields } from '../../../../../types/createFolder'
import {
  fetchTemplatesUploadFolder,
  cleanTemplatesFolders,
} from '../../../../../store/templates/folders/folders.actions'
import * as TEMPLATES_SELECTORS from '../../../../../store/templates/folders/folders.selectors'
import { useDispatchUnmount } from '../../../../../hooks/useDispatchUnmount'
import { useShowFetchError } from '../../../../hooks/useShowFetchError'
import { FOLDER_ALREADY_EXIST } from '../../../../../constants/folders'
import { DocumentsCreateFolderModalModal } from '../../DocumentModals/DocumentsCreateFolderModal/DocumentsCreateFolderModal'

import { TemplatesCreateFolderModalProps } from './TemplatesCreateFolderModal.types'

const EXCLUDE_CODES = [FOLDER_ALREADY_EXIST]

export const TemplatesCreateFolderModal: React.FC<TemplatesCreateFolderModalProps> = ({ onClose }) => {
  const dispatch = useDispatch()
  const formRef: React.RefObject<FormikProps<CreateFolderBody>> = useRef(null)
  const loadSuccessfully = useSelector(TEMPLATES_SELECTORS.templatesUploadFolderIsSuccessSelector)
  const fetchStatus = useSelector(TEMPLATES_SELECTORS.templatesUploadFolderFetchStatusSelector)
  const error = useSelector(TEMPLATES_SELECTORS.templatesUploadFolderDataErrorSelector)

  useEffect(() => {
    if (loadSuccessfully) {
      onClose()
    }
  }, [loadSuccessfully])
  useEffect(() => {
    if (error?.lastErrorCode === FOLDER_ALREADY_EXIST) {
      const form = formRef.current

      if (form) {
        form.setFieldError(CreateFolderFields.folderName, FOLDER_ALREADY_EXIST)
      }
    }
  }, [error])
  useShowFetchError(fetchStatus, error, EXCLUDE_CODES)
  useDispatchUnmount(cleanTemplatesFolders)

  const onSubmit = (values: CreateFolderBody) => {
    dispatch(fetchTemplatesUploadFolder({ name: values.folderName }))
  }

  return <DocumentsCreateFolderModalModal ref={formRef} onClose={onClose} onSubmit={onSubmit} />
}
