import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import * as SELECTORS from '../../store/contracts/otpVerification/otpVerification.selectors'
import * as ACTIONS from '../../store/contracts/otpVerification/otpVerification.actions'
import { Variant } from '../../types/notifications'
import { OTPVerificationNotificationMessages } from '../../store/contracts/otpVerification/otpVerification.types'
import { setContractStatus } from '../../store/contracts/contractsList/contractsList.actions'
import { CONTRACT_STATUS } from '../../types/contracts'

const LAST_ATTEMPT = 1

export const useOTPVerificationNotification = (contractId: string) => {
  const dispatch = useDispatch()
  const attempts = useSelector(SELECTORS.otpVerificationAttemptsCount)
  const isAttemptsFinished = useSelector(SELECTORS.otpVerificationAttemptsFinished)

  useEffect(() => {
    if (isAttemptsFinished) {
      dispatch(
        ACTIONS.setOTPVerificationNotification({
          message: OTPVerificationNotificationMessages.RECIPIENT_ATTEMPTS_FINISHED,
          variant: Variant.WARNING,
        })
      )
      dispatch(setContractStatus(contractId, CONTRACT_STATUS.VERIFICATION_FAILED))
    }
  }, [isAttemptsFinished, contractId])

  useEffect(() => {
    if (attempts === LAST_ATTEMPT) {
      dispatch(
        ACTIONS.setOTPVerificationNotification({
          message: OTPVerificationNotificationMessages.LAST_ATTEMPT,
          variant: Variant.WARNING,
        })
      )
    }
  }, [attempts])
}
