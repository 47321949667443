import { SubscriptionPaymentTerms } from './subscriptionPayment'

export enum CardSpecificationTypes {
  TRIAL = 'trial',
  PAYMENT = 'payment',
}

export interface CardSpecificationRouteParams {
  subscriptionId: string
}

export interface CardSpecificationRouteQueryParams {
  type: CardSpecificationTypes
  term?: SubscriptionPaymentTerms
}
