import { Formik, FormikProps } from 'formik'
import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { createTemplateFromContractInitialValues } from '../../../../../initialValues/createTemplateFromContractInitialValues'
import translations from '../../../../../translations/keys'
import {
  CreateTemplateFromContractBody,
  CreateTemplateFromContractFields,
} from '../../../../../types/createTemplateFromContract'
import { ContractSvg } from '../../../../assets/ContractSvg'
import Modal from '../../../Modal'
import Typography from '../../../../ui/Typography'
import { TYPOGRAPHY_NAMES } from '../../../../ui/Typography/Typography.types'
import Box from '../../../../ui/Box'
import FormDropdown from '../../../../ui/Forms/FormDropdown'
import FormTextInput from '../../../../ui/Forms/FormTextInput'
import { FILE_NAME_MAX_LENGTH } from '../../../../../validation/fileNameValidationSchema'
import { useTemplatesFoldersFetch } from '../../../../../hooks/templates/useTemplatesFoldersFetch'
import * as FOLDERS_SELECTORS from '../../../../../store/templates/folders/folders.selectors'
import Button from '../../../../ui/Button'
import { BUTTON_VARIANTS } from '../../../../ui/Button/Button.types'
import { openModal } from '../../../../../store/modals/modals.actions'
import { TEMPLATES_MODALS } from '../../../../../constants/templates'
import { createTemplateFromContractValidationSchema } from '../../../../../validation/templates/createTemplateFromContractValidationSchema'
import { CreateTemplateFromContractModalSkeleton } from '../../../Skeletons/CreateTemplateFromContractModalSkeleton'
import * as BRIEF_CONTRACTS_SELECTORS from '../../../../../store/contracts/briefList/briefList.selectors'
import { useContractsBriefListFetch } from '../../../../../hooks/contracts/useContractsBriefListFetch'
import {
  cleanCreateFromContract,
  fetchCreateTemplateFromContract,
} from '../../../../../store/templates/createFromContract/createFromContract.actions'
import { useDispatchUnmount } from '../../../../../hooks/useDispatchUnmount'
import * as CREATE_FROM_CONTRACT_SELECTORS from '../../../../../store/templates/createFromContract/createFromContract.selectors'
import { useShowFetchError } from '../../../../hooks/useShowFetchError'
import { useHistoryPushAfterSuccess } from '../../../../hooks/useHistoryPushAfterSuccess'
import { location } from '../../../../../utils/location'

import { CreateTemplateFromContractModalProps } from './CreateTemplateFromContractModal.types'
import { StyledForm } from './CreateTemplateFromContractModal.styles'

const DROPDOWN_HEIGHT = 150

export const CreateTemplateFromContractModal: React.FC<CreateTemplateFromContractModalProps> = ({ onClose }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const formRef: React.RefObject<FormikProps<CreateTemplateFromContractBody>> = useRef(null)
  const foldersIsLoading = useSelector(FOLDERS_SELECTORS.templatesFoldersIsLoadingSelector)
  const folders = useSelector(FOLDERS_SELECTORS.templatesFoldersDataSelector)
  const lastAddedFolder = useSelector(FOLDERS_SELECTORS.templatesLastAddedFolderSelector)
  const contracts = useSelector(BRIEF_CONTRACTS_SELECTORS.briefListDataSelector)
  const contractsIsLoading = useSelector(BRIEF_CONTRACTS_SELECTORS.briefListIsLoadingSelector)
  const templateId = useSelector(CREATE_FROM_CONTRACT_SELECTORS.createFromContractIdSelector)
  const createError = useSelector(CREATE_FROM_CONTRACT_SELECTORS.createFromContractErrorSelector)
  const createFetchStatus = useSelector(CREATE_FROM_CONTRACT_SELECTORS.createFromContractFetchStatusSelector)
  const createdSuccessfully = useSelector(CREATE_FROM_CONTRACT_SELECTORS.createFromContractIsSuccessSelector)

  useEffect(() => {
    const form = formRef.current

    if (lastAddedFolder && form) {
      form.setFieldValue(CreateTemplateFromContractFields.folderName, lastAddedFolder.value)
    }
  }, [lastAddedFolder])
  useEffect(() => {
    if (createdSuccessfully) {
      onClose()
    }
  }, [createdSuccessfully])
  useTemplatesFoldersFetch()
  useContractsBriefListFetch()
  useDispatchUnmount(cleanCreateFromContract)
  useShowFetchError(createFetchStatus, createError)
  useHistoryPushAfterSuccess(location.templatesViewUrl(templateId), createFetchStatus)

  const onSubmit = (values: CreateTemplateFromContractBody) => {
    const folderName = folders.find((folder) => folder.value === values.folderName)?.name || values.folderName

    dispatch(
      fetchCreateTemplateFromContract({
        ...values,
        folderName,
      })
    )
  }
  const onCreateFolder = () => {
    dispatch(openModal(TEMPLATES_MODALS.CREATE_FOLDER_FOR_TEMPLATE))
  }

  const renderForm = () => (
    <StyledForm>
      <FormDropdown
        name={CreateTemplateFromContractFields.contractId}
        label={t(translations.SELECT_CONTRACT)}
        options={contracts}
        maxHeight={DROPDOWN_HEIGHT}
      />
      <FormTextInput
        name={CreateTemplateFromContractFields.name}
        label={t(translations.TEMPLATE_NAME)}
        maxLength={FILE_NAME_MAX_LENGTH}
      />
      <FormDropdown
        name={CreateTemplateFromContractFields.folderName}
        label={t(translations.FOLDER_LABEL)}
        options={folders}
        maxHeight={DROPDOWN_HEIGHT}
        noError
      />
      <Box mb={4}>
        <Button icon="folderAdd" variant={BUTTON_VARIANTS.INACTIVE} type="button" onClick={onCreateFolder}>
          {t(translations.CREATE_NEW_FOLDER)}
        </Button>
      </Box>
      <Button type="submit" fullWidth>
        {t(translations.CREATE)}
      </Button>
    </StyledForm>
  )

  return (
    <Modal onClose={onClose}>
      <Box mt={3} mb={3} display="flex" flexDirection="column" width={320} alignItems="center">
        <ContractSvg />
        <Box mt={5} mb={3} textAlign="center">
          <Typography name={TYPOGRAPHY_NAMES.H1}>{t(translations.CREATE_TEMPLATE_FROM_CONTRACT)}</Typography>
        </Box>
        {foldersIsLoading || contractsIsLoading ? (
          <CreateTemplateFromContractModalSkeleton />
        ) : (
          <Formik
            innerRef={formRef}
            onSubmit={onSubmit}
            initialValues={createTemplateFromContractInitialValues}
            validationSchema={createTemplateFromContractValidationSchema}
          >
            {renderForm}
          </Formik>
        )}
      </Box>
    </Modal>
  )
}
