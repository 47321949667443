import { formatDistanceToNowStrict, differenceInCalendarDays, format, startOfDay, endOfDay } from 'date-fns'
import isValid from 'date-fns/isValid'
import locale from 'date-fns/locale/en-US'

import { DEFAULT_DATE_FORMAT } from '../../constants/date'

const SECONDS = 60
const MILLISECONDS = 1000

export const getDeadlineDays = (date: Date | null) => (date ? differenceInCalendarDays(date, new Date()) : null)

export const getFromToISODate = (dateISO: string | null, to = false) => {
  if (!dateISO) {
    return ''
  }

  const date = to ? endOfDay(new Date(dateISO)) : startOfDay(new Date(dateISO))

  return new Date(date.getTime() - date.getTimezoneOffset() * SECONDS * MILLISECONDS).toISOString()
}

export const getFormattedDate = (date: Date, dateFormat: string = DEFAULT_DATE_FORMAT) => format(date, dateFormat)

export const getISODate = (date: Date) => new Date(date.getTime()).toISOString()

export const parseISOwithTZ = (date: string) => {
  const newDate = new Date(date)

  if (!isValid(newDate)) {
    return null
  }

  if (date.endsWith('Z')) {
    return new Date(newDate.getTime() + newDate.getTimezoneOffset() * SECONDS * MILLISECONDS)
  }

  return newDate
}

export const getAgoFormat = (date: Date) => {
  const formatDistanceLocale: { [key: string]: string } = {
    lessThanXSeconds: '{{count}}s',
    xSeconds: '{{count}}s',
    halfAMinute: '30s',
    lessThanXMinutes: '{{count}}m',
    xMinutes: '{{count}}m',
    aboutXHours: '{{count}}h',
    xHours: '{{count}}h',
  }

  const formatDistance = (token: string, count: string) => {
    const distanceLocale = formatDistanceLocale[token]

    if (!distanceLocale) {
      return getFormattedDate(date)
    }

    const result = distanceLocale.replace('{{count}}', count)

    return `${result} ago`
  }

  return formatDistanceToNowStrict(new Date(date), {
    addSuffix: true,
    locale: {
      ...locale,
      formatDistance,
    },
  })
}
