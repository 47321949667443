import { useState, useEffect } from 'react'

import { loadImage } from '../../utils/loadImage'

export enum IMAGE_STATUS {
  LOADING = 'loading',
  LOADED = 'loaded',
  FAILED = 'failed',
}

const useImage = (src: string | null, recipientToken?: string) => {
  const [status, setStatus] = useState<IMAGE_STATUS | null>(null)
  const [image, setImage] = useState<HTMLImageElement | null>(null)

  useEffect(() => {
    if (!src || status === IMAGE_STATUS.LOADED) {
      return
    }

    loadImage(src, recipientToken)
      .then((image) => {
        setImage(image as HTMLImageElement)
        setStatus(IMAGE_STATUS.LOADED)
      })
      .catch(() => {
        setStatus(IMAGE_STATUS.FAILED)
      })
  }, [src, recipientToken])

  return image
}

export default useImage
