import { RegistrationRequestBody, RegistrationResendCodeResponse } from '../../types/registration'
import { LoginRequestBody } from '../../types/auth'
import { RestorePasswordBody } from '../../types/restorePassword'
import { Ajax } from '../ajax'
import { VerificationPasswordBody } from '../../types/verificationPassword'

class AuthService extends Ajax {
  pathname = 'auth'

  fetchReshresh = (headers: { [key: string]: string }) => this.getJSON(`${this.pathname}/refresh`, headers)

  fetchLogin = (body?: LoginRequestBody) => this.post(`${this.pathname}/login`, body)

  fetchLogout = () => this.post(`${this.pathname}/logout`)

  fetchSignUp = (body?: RegistrationRequestBody) => this.post(`${this.pathname}/signup`, body)

  fetchPhoneConfirm = (linkId: string, code: string) => this.getJSON(`${this.pathname}/phoneconfirm/${linkId}/${code}`)

  fetchNewPhoneCode = (userId: string) =>
    this.getJSON<RegistrationResendCodeResponse>(`${this.pathname}/newphonecode/${userId}`)

  fetchForgotPassword = (email: string) => this.post(`${this.pathname}/forgotpassword`, { email })

  fetchResendEmail = (email: string) => this.getJSON(`${this.pathname}/newemailcode/${email}`)

  fetchRestorePassword = (body?: RestorePasswordBody) => this.post(`${this.pathname}/resetpassword`, body)

  fetchPasswordVerify = (body: VerificationPasswordBody) => this.post(`${this.pathname}/passwordverify`, body)
}

export const authService = new AuthService()
