import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { useDispatchUnmount } from '../useDispatchUnmount'
import { cleanBriefList, fetchBriefList } from '../../store/templates/briefList/briefList.actions'

export const useTemplatesBriefListFetch = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchBriefList())
  }, [])

  useDispatchUnmount(cleanBriefList)
}
