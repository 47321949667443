import { FetchFailureAction, FETCH_STATUSES } from '../../../types/fetch'
import { createReducer } from '../../createReducer'

import * as ACTIONS from './reupload.actions'
import * as TYPES from './reupload.types'

type Actions = FetchFailureAction | TYPES.FetchReuploadSuccess

export const initialState: TYPES.ReuploadState = {
  fetchStatus: FETCH_STATUSES.IDLE,
  error: null,
}

export const reupload = createReducer<TYPES.ReuploadState, Actions>(initialState, {
  [ACTIONS.fetchReuploadTypes.request]: (state) => ({
    ...state,
    fetchStatus: FETCH_STATUSES.REQUEST,
  }),
  [ACTIONS.fetchReuploadTypes.success]: (state) => ({
    ...state,
    fetchStatus: FETCH_STATUSES.SUCCESS,
  }),
  [ACTIONS.fetchReuploadTypes.failure]: (state, action) => ({
    ...state,
    fetchStatus: FETCH_STATUSES.FAILURE,
    error: (action as FetchFailureAction).payload,
  }),
  [TYPES.ReuploadActions.TEMPLATES_CLEAR_REUPLOAD]: () => ({ ...initialState }),
})
