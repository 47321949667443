import React from 'react'
import { useTranslation } from 'react-i18next'

import translations from '../../../../../translations/keys'
import AskModal from '../../ContractModals/AskModal'

import { ResetModalProps } from './ResetModal.types'

export const ResetModal: React.FC<ResetModalProps> = ({ onReset, onClose }) => {
  const { t } = useTranslation()
  const handleSuccess = () => {
    onClose()
    onReset()
  }

  return (
    <AskModal
      title={t(translations.PLACEMENT_RESET_CONFIRMATION)}
      onSuccess={handleSuccess}
      onClose={onClose}
      onCancel={onClose}
    />
  )
}
