import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { fetchSignContract } from '../../../../../store/contracts/signing/signing.actions'
import AskModal from '../../ContractModals/AskModal'
import * as SIGNING_SELECTORS from '../../../../../store/contracts/signing/signing.selectors'
import { useShowFetchError } from '../../../../hooks/useShowFetchError'
import translations from '../../../../../translations/keys'
import { useCheckAction } from '../../../../hooks/contractSign/useCheckAction'
import { fetchRecipients } from '../../../../../store/contracts/recipients/recipients.actions'

import { SendContractModalProps } from './SendContractModal.types'

export const SendContractModal: React.FC<SendContractModalProps> = ({ onClose, onSuccess }) => {
  const dispatch = useDispatch()
  const fetchStatus = useSelector(SIGNING_SELECTORS.signingSignFetchStatusSelector)
  const error = useSelector(SIGNING_SELECTORS.signingSignErrorSelector)
  const isSuccess = useSelector(SIGNING_SELECTORS.signingSignIsSuccessSelector)
  const data = useSelector(SIGNING_SELECTORS.signingContractDocumentDataSelector)

  const closeAndFetchRecipients = () => {
    if (data) {
      dispatch(fetchRecipients(data.id))
    }
    onClose()
  }
  const { handleAction } = useCheckAction(onClose)

  const handleSuccess = () => {
    handleAction(() => {
      dispatch(fetchSignContract())
    })
  }

  useEffect(() => {
    if (isSuccess) {
      onClose()
      onSuccess()
    }
  }, [isSuccess, onSuccess])

  useShowFetchError(fetchStatus, error)

  return (
    <AskModal
      onSuccess={handleSuccess}
      onClose={closeAndFetchRecipients}
      title={translations.SEND_CONTRACT_TO_RECIPIENTS}
      successButtonText={translations.SEND}
    />
  )
}
