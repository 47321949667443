import React from 'react'

import { TYPOGRAPHY_COMPONENTS, TYPOGRAPHY_NAMES } from '../../../../ui/Typography/Typography.types'

import { StyledTitle, StyledWrapper } from './EditProfileBox.styles'
import { EditProfileBoxProps } from './EditProfileBox.types'

export const EditProfileBox: React.FC<EditProfileBoxProps> = ({ children, title }) => (
  <StyledWrapper>
    <StyledTitle name={TYPOGRAPHY_NAMES.H3} component={TYPOGRAPHY_COMPONENTS.h3}>
      {title}
    </StyledTitle>
    {children}
  </StyledWrapper>
)
