import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'

import translations from '../../../../../translations/keys'
import { location } from '../../../../../utils/location'
import StubEKYCModal from '../../VerificationsModals/StubEKYCModal'

import { SigningStubEKYCModalProps } from './SigningStubEKYCModal.types'

export const SigningStubEKYCModal: React.FC<SigningStubEKYCModalProps> = ({ onClose }) => {
  const history = useHistory()
  const { t } = useTranslation()
  const handleClose = () => {
    onClose()
    history.push(location.contractsAllUrl())
  }

  return (
    <StubEKYCModal
      onClose={handleClose}
      description={t(translations.EKYC_STUB_DESCRIPTION)}
      title={t(translations.EKYC_STUB_TITLE)}
    />
  )
}
