import { MutableRefObject } from 'react'

const LAST_STEP = 1

export const easeOutAnimation = (progress: number) => -Math.cos(progress * Math.PI) / 2 + 0.5

export const animate = (
  duration: number,
  animationRef: MutableRefObject<number | null>,
  callback: (step: number) => void
) => {
  const startTime = new Date().getTime()

  const animationStep = () => {
    if (!animationRef.current) {
      return
    }

    const deltaTime = new Date().getTime() - startTime
    const progress = deltaTime / duration

    const step = easeOutAnimation(progress)

    if (deltaTime >= duration) {
      callback(LAST_STEP)
      animationRef.current = null
    } else {
      callback(step)
      animationRef.current = requestAnimationFrame(animationStep)
    }
  }

  animationRef.current = requestAnimationFrame(animationStep)
}
