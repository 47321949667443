import React, { useMemo, useState, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { FormikHandlers } from 'formik'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { MONTH_YEAR_FORMAT } from '../../../../../constants/date'
import { generateCardMask } from '../../../../../utils/cards/generateCardMask'
import { getFormattedDate } from '../../../../../utils/date'
import Box from '../../../../ui/Box'
import RadioButton from '../../../../ui/RadioButton'
import Typography from '../../../../ui/Typography'
import { TYPOGRAPHY_NAMES } from '../../../../ui/Typography/Typography.types'
import TextInput from '../../../../ui/TextInput'
import Button from '../../../../ui/Button'
import translations from '../../../../../translations/keys'
import { openModal } from '../../../../../store/modals/modals.actions'
import { COMMON_MODALS_IDS } from '../../../../ui/Modals/CommonModals/CommonModals.constants'
import { NOTIFICATION_MODAL_TYPES } from '../../../../ui/Modals/NotificationModal/NotificationModal.constants'
import { SubscriptionPaymentRouteParams } from '../../../../types/subscriptionPayment'
import { PaymentCard } from '../../../../components/PaymentCard/PaymentCard'
import PaymentProcessing from '../../../../components/PaymentProcessing'
import { SUBSCRIPTION_PAYMENT_MODALS } from '../../../../constants/subscriptionPayment'
import { HandleTokenizationCompleted } from '../../../../../hooks/sockets/useSubscribeOnPayment'
import { fetchActivateSubscription } from '../../../../../store/subscription/payment/payment.actions'
import { SubscriptionPaymentTerms } from '../../../../../types/subscriptionPayment'
import { SUBSCRIPTION_MONTH, SUBSCRIPTION_YEAR } from '../../../../../constants/subscription'

import { CardsProps } from './Cards.types'
import { StyledCard, StyledCVV } from './Cards.styles'
import { LOGOS, CARDS_TYPES, CARD_OPTION_NAME, ANOTHER_OPTION, MAX_CARDS_COUNT } from './Cards.constants'

export const Cards: React.FC<CardsProps> = ({ list }) => {
  const { subscriptionId, term } = useParams<SubscriptionPaymentRouteParams>()
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const initialOption = useMemo(() => list.find((card) => card.default)?.id || '', [list])
  const [option, setOption] = useState<string>(initialOption)
  const [cvv, setCvv] = useState('')

  const isAnotherOption = option === ANOTHER_OPTION

  const handleChangeOption: FormikHandlers['handleChange'] = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newOption = e.target.value

    if (newOption === ANOTHER_OPTION && list.length > MAX_CARDS_COUNT) {
      dispatch(
        openModal(COMMON_MODALS_IDS.NOTIFICATION, {
          type: NOTIFICATION_MODAL_TYPES.WARNING,
          title: t(translations.FAILED_TO_ADD_CARD),
          description: t(translations.MANY_CARDS_ERROR),
        })
      )
      return
    }

    setCvv('')
    setOption(newOption)
  }

  const handleCvvChange: FormikHandlers['handleChange'] = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCvv(e.target.value)
  }

  const handleVerifyCard = useCallback(() => {
    dispatch(openModal(SUBSCRIPTION_PAYMENT_MODALS.SUBSCRIPTION_UPDATED))
  }, [])

  const handleTokenizationCompleted: HandleTokenizationCompleted = useCallback(
    (data) => {
      dispatch(
        fetchActivateSubscription(
          {
            tokenName: data.token,
            expiredDate: data.expiryDate,
            subscriptionId,
            term: term === SubscriptionPaymentTerms.YEAR ? SUBSCRIPTION_YEAR : SUBSCRIPTION_MONTH,
          },
          false
        )
      )
    },
    [term, subscriptionId]
  )

  return (
    <div>
      {list.map((card) => {
        const checked = option === card.id

        return (
          <div key={card.id}>
            <RadioButton
              id={card.id}
              name={CARD_OPTION_NAME}
              onChange={handleChangeOption}
              label=""
              checked={checked}
              dotPosition="top"
              renderLabel={() => {
                const date = getFormattedDate(new Date(card.expireDate), MONTH_YEAR_FORMAT)

                return (
                  <>
                    <StyledCard cardType={card.paymentOption}>
                      <span>{generateCardMask(card.lastDigits)}</span>
                      <Box display="flex" justifyContent="space-between" alignItems="center">
                        {date} {LOGOS[card.paymentOption]}
                      </Box>
                    </StyledCard>
                    <Box display="flex" flexDirection="column" ml={4}>
                      <Typography name={TYPOGRAPHY_NAMES.bold14}>
                        {CARDS_TYPES[card.paymentOption]} ({card.lastDigits})
                      </Typography>
                      <Box mt={1} mb={1}>
                        Exp: {date}
                      </Box>
                      <span>{card.holderName}</span>
                    </Box>
                  </>
                )
              }}
            />
            {checked && (
              <StyledCVV>
                <TextInput value={cvv} label="CVV" placeholder="000" onChange={handleCvvChange} />
              </StyledCVV>
            )}
          </div>
        )
      })}
      <RadioButton
        id={ANOTHER_OPTION}
        name={CARD_OPTION_NAME}
        onChange={handleChangeOption}
        label={t(translations.USE_ANOTHER_CARD)}
        checked={isAnotherOption}
      />
      {isAnotherOption && (
        <PaymentCard
          Loader={PaymentProcessing}
          handleVerifyCard={handleVerifyCard}
          handleTokenizationCompleted={handleTokenizationCompleted}
        />
      )}
      {!isAnotherOption && (
        <Box width={208} mt={2}>
          <Button fullWidth>{t(translations.PAY)}</Button>
        </Box>
      )}
    </div>
  )
}
