import React from 'react'

export const PhoneIcon: React.FC = (props) => (
  <svg width="156" height="96" viewBox="0 0 156 96" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M99.7843 74.0731V81.0985C99.7843 82.0213 99.4467 83.5989 98.188 83.5989H59.3238C57.881 83.5989 56.0698 82.1999 56.0698 81.0985V74.0731H99.7843ZM56.0698 14.2987C56.0698 13.2271 57.267 11.9471 58.6485 11.9471H98.188C98.7099 11.9471 99.7843 12.215 99.7843 14.2987V19.1807H56.0698V14.2987ZM101.196 22.485C102.056 22.485 103.161 21.5919 103.161 20.7584V14.2987C103.161 11.1433 100.982 9 98.1573 9H58.6178C55.6401 9 53 11.2922 53 14.2987V81.1282C53 84.1348 56.4382 86.5758 59.2932 86.5758H98.188C100.982 86.5758 103.192 84.4325 103.192 81.1282V59.4272C103.192 58.5937 102.363 57.9091 101.503 57.9091C100.644 57.9091 99.815 58.5937 99.815 59.4272V71.0963H56.1005V22.4552H101.196V22.485Z"
      fill="#6085B3"
    />
    <path d="M85.6018 80.5031H70.3447V77.5262H85.6018V80.5031Z" fill="#6085B3" />
    <path
      d="M86.001 52.2232L81.4269 54.813L83.5758 50.0501C84.1284 48.8594 82.5628 47.5198 82.5628 46.597V32.7846C82.5628 29.8971 85.4484 28.7659 87.2903 28.7659H118.48C119.094 28.7659 122.225 28.3491 122.225 32.7846V46.597C122.225 49.425 120.107 52.253 118.48 52.253H86.001V52.2232ZM118.48 25.4319H87.2903C83.6065 25.4319 79.5236 28.2896 79.5236 32.7548V46.5673C79.6157 48.7701 80.4752 49.3059 80.5059 49.3654L76.6686 57.7898C76.3924 58.3852 76.5459 59.0997 77.0371 59.5164C77.6204 60.0225 78.5106 59.9629 78.8483 59.7545L86.6764 55.5275H118.449C121.826 55.5275 125.264 51.8362 125.264 46.5375V32.7251C125.295 25.9975 120.199 25.4319 118.48 25.4319Z"
      fill="#DFE1E6"
    />
    <path
      d="M105.771 40.549C105.771 42.2756 104.328 43.7044 102.517 43.7044C100.736 43.7044 99.2625 42.3053 99.2625 40.549C99.2625 38.8225 100.705 37.3936 102.517 37.3936C104.328 37.3936 105.771 38.8225 105.771 40.549Z"
      fill="#6085B3"
    />
    <path
      d="M116.791 40.549C116.791 42.2756 115.348 43.7044 113.537 43.7044C111.757 43.7044 110.283 42.3053 110.283 40.549C110.283 38.8225 111.726 37.3936 113.537 37.3936C115.348 37.3936 116.791 38.8225 116.791 40.549Z"
      fill="#6085B3"
    />
    <path
      d="M94.7805 40.549C94.7805 42.2756 93.3377 43.7044 91.5265 43.7044C89.746 43.7044 88.2725 42.3053 88.2725 40.549C88.2725 38.8225 89.7153 37.3936 91.5265 37.3936C93.307 37.3936 94.7805 38.8225 94.7805 40.549Z"
      fill="#6085B3"
    />
  </svg>
)
