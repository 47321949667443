import { Form, Formik, FormikProps } from 'formik'
import React, { FC, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { otpVerificationInitialValues } from '../../../../../initialValues/otpVerificationInitialValues'
import translations from '../../../../../translations/keys'
import { OTPVerificationModalFields, OTPVerificationRequestBody } from '../../../../../types/otpVerification'
import { otpVerificationValidationSchema } from '../../../../../validation/contracts/OTPVerificationValidationSchema'
import { PasswordIcon } from '../../../../assets/PasswordIcon'
import { useShowFetchError } from '../../../../hooks/useShowFetchError'
import Button from '../../../../ui/Button'
import { BUTTON_VARIANTS } from '../../../../ui/Button/Button.types'
import CountDown from '../../../../ui/CountDown'
import FormCodeInput from '../../../../ui/Forms/FormCodeInput'
import Modal from '../../../../components/Modal'
import Typography from '../../../../ui/Typography'
import { TYPOGRAPHY_COMPONENTS, TYPOGRAPHY_NAMES } from '../../../../ui/Typography/Typography.types'
import Box from '../../../../ui/Box'
import { useOTPVerificationFetch } from '../../../../../hooks/contracts/useOTPVerificationFetch'
import * as SELECTORS from '../../../../../store/contracts/otpVerification/otpVerification.selectors'
import * as ACTIONS from '../../../../../store/contracts/otpVerification/otpVerification.actions'
import { setSigningNotification } from '../../../../../store/contracts/signing/signing.actions'
import { SigningNotificationMessages } from '../../../../../store/contracts/signing/signing.types'
import { Variant } from '../../../../../types/notifications'
import NotificationBadge from '../../../../ui/NotificationBadge'
import { useOTPVerificationNotification } from '../../../../../hooks/contracts/useOTPVerificationNotification'
import { INVALID_OTP, RECIPIENT_ATTEMPTS_FINISHED } from '../../../../../constants/contractOTPVerification'

import { OTPVerificationModalProps } from './OTPVerificationModal.types'
import { StyledNotification, StyledResendCodeWrapper } from './OTPVerificationModal.styles'

const EXCLUDE_VERIFY_ERRORS = [INVALID_OTP, RECIPIENT_ATTEMPTS_FINISHED]

export const OTPVerificationModal: FC<OTPVerificationModalProps> = ({ onClose, onSuccess, contractId }) => {
  const dispatch = useDispatch()
  const [resendCodeDisabled, setResendCodeDisabled] = useState(true)
  const [countDownKey, setCountDownKey] = useState(new Date().getTime())
  const phoneNumber = useSelector(SELECTORS.otpVerificationPhoneNumber)
  const verifyFetchStatus = useSelector(SELECTORS.otpVerificationVerifyFetchStatus)
  const verifyError = useSelector(SELECTORS.otpVerificationVerifyError)
  const verifySuccess = useSelector(SELECTORS.otpVerificationVerifyIsSuccess)
  const notification = useSelector(SELECTORS.otpVerificationNotification)
  const isAttemptsFinished = useSelector(SELECTORS.otpVerificationAttemptsFinished)
  const formRef: React.Ref<FormikProps<OTPVerificationRequestBody>> = useRef(null)

  useEffect(() => {
    if (verifySuccess) {
      dispatch(
        setSigningNotification({
          variant: Variant.SUCCESS,
          message: SigningNotificationMessages.CODE_IS_CORRECT,
        })
      )
      onSuccess(contractId)
    }
  }, [verifySuccess, onSuccess, contractId])
  useEffect(() => {
    const form = formRef.current
    if (form && verifyError?.lastErrorCode === INVALID_OTP) {
      form.setFieldError(OTPVerificationModalFields.code, verifyError.lastErrorCode)
      form.setFieldValue(OTPVerificationModalFields.code, '', false)
    }
  }, [verifyError])
  useOTPVerificationFetch(contractId)
  useOTPVerificationNotification(contractId)
  useShowFetchError(verifyFetchStatus, verifyError, EXCLUDE_VERIFY_ERRORS)

  const onSubmit = (values: OTPVerificationRequestBody) => {
    dispatch(ACTIONS.fetchVerifyOTP(values))
  }
  const handleCountDownComplete = () => {
    setResendCodeDisabled(false)
  }
  const onResendCode = () => {
    setResendCodeDisabled(true)
    setCountDownKey(new Date().getTime())
    dispatch(ACTIONS.fetchResendCode())
  }
  const handleNotificationBadgeClose = () => {
    dispatch(ACTIONS.cleanOTPVerificationNotification())
  }
  const renderForm = () => (
    <>
      <Form>
        <Box textAlign="center" mb={2.5}>
          {translations.OTP_VERIFICATION_DESCRIPTION}
        </Box>
        <Box textAlign="center" mb={4}>
          <Typography name={TYPOGRAPHY_NAMES.H3}>{phoneNumber || '************'}</Typography>
        </Box>
        <Box textAlign="center" mb={2.5}>
          {translations.OTP_VERIFICATION_CONTACT_OWNER}
        </Box>
        <Box mb={2}>
          <FormCodeInput name={OTPVerificationModalFields.code} length={6} disabled={isAttemptsFinished} />
        </Box>
        <Box mb={2}>
          <Button fullWidth type="submit" disabled={isAttemptsFinished}>
            {translations.CONTINUE}
          </Button>
        </Box>
      </Form>
      <StyledResendCodeWrapper>
        <Box mb={3}>
          {isAttemptsFinished ? (
            <CountDown onComplete={() => {}} seconds={0} />
          ) : (
            <CountDown key={countDownKey} onComplete={handleCountDownComplete} />
          )}
        </Box>
        <Box textAlign="center" mb={2}>
          <Button
            variant={BUTTON_VARIANTS.LINK}
            onClick={onResendCode}
            disabled={resendCodeDisabled || isAttemptsFinished}
          >
            {translations.RESEND_CODE}
          </Button>
        </Box>
        {notification && (
          <StyledNotification>
            <NotificationBadge
              isShow
              isAutoClose
              message={translations[notification.message]}
              onClose={handleNotificationBadgeClose}
              variant={notification.variant}
            />
          </StyledNotification>
        )}
      </StyledResendCodeWrapper>
    </>
  )

  return (
    <Modal onClose={onClose}>
      <Box
        width={320}
        height="100%"
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        mt={3}
      >
        <Box>
          <PasswordIcon />
        </Box>
        <Box mt={5} mb={3} textAlign="center">
          <Typography name={TYPOGRAPHY_NAMES.H1} component={TYPOGRAPHY_COMPONENTS.h1}>
            {translations.OTP_VERIFICATION_TITLE}
          </Typography>
        </Box>
        <Formik
          innerRef={formRef}
          initialValues={otpVerificationInitialValues}
          onSubmit={onSubmit}
          validationSchema={otpVerificationValidationSchema}
        >
          {renderForm}
        </Formik>
      </Box>
    </Modal>
  )
}
