import { getRecipientHeaders } from './signing/getRecipientHeaders'

export const loadImage = (url: string, recipientToken?: string): Promise<HTMLImageElement> => {
  const image = new Image()

  return new Promise((resolve, reject) => {
    fetch(url, { headers: getRecipientHeaders(recipientToken) })
      .then((response) => response.blob())
      .then((blob) => {
        image.src = URL.createObjectURL(blob)
        image.onload = () => {
          resolve(image)
        }
        image.onerror = reject
        image.onabort = reject
      })
      .catch(reject)
  })
}
