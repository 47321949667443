import React from 'react'

import Modal from '../../../../Modal'
import { useForceUpdate } from '../../hooks/useForceUpdate'

import { ModalBody } from './components/ModalBody/ModalBody'
import { ModalWebProps } from './ModalWeb.types'

export const ModalWeb: React.FC<ModalWebProps> = ({ onClose, onCreateSignature, mode }) => {
  const forceUpdateKey = useForceUpdate()

  return (
    <Modal onClose={onClose} key={forceUpdateKey} fillWidth>
      <ModalBody onCreateSignature={onCreateSignature} mode={mode} />
    </Modal>
  )
}
