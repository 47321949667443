import { Action } from 'redux'

import { ContractVerificationResponse } from '../../../types/contractVerificaiton'
import { FetchFailurePayload, FETCH_STATUSES } from '../../../types/fetch'

export interface VerificationState {
  fetchStatus: FETCH_STATUSES
  error: FetchFailurePayload | null
  verified: boolean | null
}

export enum VerificationActions {
  'FETCH_CONTRACT_VERIFICATION' = 'FETCH_CONTRACT_VERIFICATION',
  'CLEAN_CONTRACT_VERIFICATION' = 'CLEAN_CONTRACT_VERIFICATION',
}

export interface FetchVerification extends Action {
  payload: { contractId: string }
}

export interface FetchVerificationSuccess extends Action {
  payload: { body: ContractVerificationResponse }
}
