import React from 'react'

export const InfoIcon: React.FC = (props) => (
  <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.8335 32.867C25.9521 32.867 32.5335 26.2856 32.5335 18.167C32.5335 10.0484 25.9521 3.46699 17.8335 3.46699C9.71491 3.46699 3.1335 10.0484 3.1335 18.167C3.1335 26.2856 9.71491 32.867 17.8335 32.867ZM17.8335 35.667C27.4985 35.667 35.3335 27.832 35.3335 18.167C35.3335 8.50201 27.4985 0.666992 17.8335 0.666992C8.16851 0.666992 0.333496 8.50201 0.333496 18.167C0.333496 27.832 8.16851 35.667 17.8335 35.667Z"
      fill="#6085B3"
    />
    <path
      d="M19.9335 10.2168L19.5008 21.4027H16.1662L15.7335 10.2168H19.9335ZM19.9124 25.9299H15.7546V23.1439H19.9124V25.9299Z"
      fill="#6085B3"
    />
  </svg>
)
