import { startOfTomorrow } from 'date-fns'

import {
  AddRecipientsFields,
  AddRecipientsForm,
  AddRecipientsSignatureSequentialFields,
  RecipientActions,
  RecipientAuthentication,
  RecipientRoleSelectTypes,
  RecipientSignatureSequenceTypes,
  RecipientSignatureTypes,
  RoleSelectFields,
} from '../types/recipients'

export const emptyRecipient = {
  [AddRecipientsFields.recipientName]: '',
  [AddRecipientsFields.recipientEmail]: '',
  [AddRecipientsFields.recipientAction]: RecipientActions.VIEW,
  [AddRecipientsFields.recipientCycleOrder]: 1,
  [AddRecipientsFields.recipientDeadline]: startOfTomorrow(),
  [AddRecipientsFields.recipientSignatureType]: RecipientSignatureTypes.LAST_PAGE,
  [AddRecipientsFields.recipientDepartment]: '',
  [AddRecipientsFields.recipientAuthentication]: RecipientAuthentication.NONE,
  [AddRecipientsFields.recipientPhone]: '',
  [AddRecipientsFields.recipientNoEmailAndName]: false,
}

export const addRecipientsInitialValues: AddRecipientsForm = {
  [AddRecipientsFields.recipients]: [emptyRecipient, emptyRecipient, emptyRecipient],
}

export const addRecipientSequentialSelectInitialValues = {
  [AddRecipientsSignatureSequentialFields.signatureSequence]: RecipientSignatureSequenceTypes.SEQUENTIAL_SIGNING,
}

export const roleSelectInitialValues = {
  [RoleSelectFields.roleSelect]: RecipientRoleSelectTypes.RECIPIENT,
}
