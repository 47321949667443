import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { FetchFailurePayload, FETCH_STATUSES } from '../../types/fetch'
import { onFetchError } from '../utils/fetch'

export const useShowFetchError = (
  fetchStatus: FETCH_STATUSES,
  error: FetchFailurePayload | null = null,
  excludeCodes?: string[]
) => {
  const dispatch = useDispatch()

  useEffect(() => {
    if (fetchStatus === FETCH_STATUSES.FAILURE && error && !excludeCodes?.includes(error.lastErrorCode)) {
      dispatch(onFetchError(error.errorMessage))
    }
  }, [fetchStatus, error, excludeCodes])
}
