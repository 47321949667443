import { Action } from 'redux'

import { EditProfileFormBody, EditProfileInitialDataResponse } from '../../types/editProfile'
import { FetchFailurePayload, FETCH_STATUSES } from '../../types/fetch'
import { VerificationPasswordBody } from '../../types/verificationPassword'

export interface EditProfileState {
  phone: {
    fetchStatus: FETCH_STATUSES
    error?: FetchFailurePayload
    phoneNumber: string
  }
  confirmPhone: {
    fetchStatus: FETCH_STATUSES
    error?: FetchFailurePayload
  }
  email: {
    fetchStatus: FETCH_STATUSES
    error?: FetchFailurePayload
  }
  data: {
    fetchStatus: FETCH_STATUSES
    error?: FetchFailurePayload
  }
  resendVerification: {
    fetchStatus: FETCH_STATUSES
    error?: FetchFailurePayload
  }
  unconfirmedEmail: {
    fetchStatus: FETCH_STATUSES
    error?: FetchFailurePayload
    newEmail?: string
  }
  preventEmailUpdate: {
    fetchStatus: FETCH_STATUSES
    error?: FetchFailurePayload
  }
  initialData: {
    fetchStatus: FETCH_STATUSES
    error?: FetchFailurePayload
    values?: EditProfileInitialDataResponse
  }
}

export enum EditProfileActions {
  'FETCH_EDIT_PROFILE_CHANGE_PHONE' = 'FETCH_EDIT_PROFILE_CHANGE_PHONE',
  'RESET_EDIT_PROFILE_PHONE_FETCH_STATUS' = 'RESET_EDIT_PROFILE_PHONE_FETCH_STATUS',
  'FETCH_EDIT_PROFILE_CONFIRM_PHONE' = 'FETCH_EDIT_PROFILE_CONFIRM_PHONE',
  'FETCH_EDIT_PROFILE_CHANGE_EMAIL' = 'FETCH_EDIT_PROFILE_CHANGE_EMAIL',
  'RESET_EDIT_PROFILE_EMAIL_DATA' = 'RESET_EDIT_PROFILE_EMAIL_DATA',
  'RESET_EDIT_PROFILE_PHONE_DATA' = 'RESET_EDIT_PROFILE_PHONE_DATA',
  'FETCH_EDIT_PROFILE' = 'FETCH_EDIT_PROFILE',
  'RESET_EDIT_PROFILE' = 'RESET_EDIT_PROFILE',
  'FETCH_EDIT_PROFILE_RESEND_EMAIL_RESET' = 'FETCH_EDIT_PROFILE_RESEND_EMAIL_RESET',
  'FETCH_EDIT_PROFILE_UNCONFIRMED_EMAIL' = 'FETCH_EDIT_PROFILE_UNCONFIRMED_EMAIL',
  'FETCH_EDIT_PROFILE_PREVENT_EMAIL_UPDATE' = 'FETCH_EDIT_PROFILE_PREVENT_EMAIL_UPDATE',
  'FETCH_EDIT_PROFILE_INITIAL_DATA' = 'FETCH_EDIT_PROFILE_INITIAL_DATA',
}

export interface EditProfileChangePhoneAction extends Action {
  payload: { phone: string }
}
export interface EditProfileChangePhoneSuccessAction extends Action {
  payload: { phone: string }
}
export interface EditProfileConfirmPhoneAction extends Action {
  payload: { verificationCode: string }
}
export interface EditProfileConfirmPhoneSuccessAction extends Action {
  payload: { phone: string }
}

export interface EditProfileChangeEmailAction extends Action {
  payload: { email: string }
}
export interface EditProfileChangeEmailSuccessAction extends Action {
  payload: { newEmail: string }
}
export interface EditProfileAction extends Action {
  payload: { body: EditProfileFormBody }
}
export type EditProfileSuccessAction = Action

export interface EditProfileVerificationPasswordAction extends Action {
  payload: { body: VerificationPasswordBody }
}

export type EditProfileResendVerificationAction = Action

export type EditProfileResendVerificationSuccessAction = Action

export type EditProfileUnconfirmedEmailAction = Action

export interface EditProfileUnconfirmedEmailSuccessAction extends Action {
  payload: { newEmail?: string }
}

export type EditProfilePreventEmailUpdateAction = Action

export type EditProfilePreventEmailUpdateSuccessAction = Action

export type EditProfileInitialDataAction = Action

export interface EditProfileInitialDataActionSuccessAction extends Action {
  payload: { body: EditProfileInitialDataResponse }
}
