import { combineEpics, Epic, ofType } from 'redux-observable'
import { catchError, map, mergeMap, takeUntil } from 'rxjs/operators'

import { authService } from '../../api'
import { catchFetchError } from '../../utils/catchFetchError'

import * as ACTIONS from './restorePassword.actions'

const fetchRestorePassword: Epic = (action$) =>
  action$.pipe(
    ofType(ACTIONS.fetchRestorePasswordTypes.request),
    mergeMap(({ payload }) =>
      authService.fetchRestorePassword(payload).pipe(
        map(() => ACTIONS.fetchRestorePasswordSuccess()),
        catchError(catchFetchError(ACTIONS.fetchRestorePasswordFailure)),
        takeUntil(
          action$.pipe(ofType(ACTIONS.fetchRestorePasswordTypes.success, ACTIONS.fetchRestorePasswordTypes.failure))
        )
      )
    )
  )

export const restorePasswordEpics = combineEpics(fetchRestorePassword)
