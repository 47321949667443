import React, { useEffect, useRef } from 'react'
import { FormikProps } from 'formik'
import { useDispatch, useSelector } from 'react-redux'

import { CreateFolderBody, CreateFolderFields } from '../../../../../types/createFolder'
import { fetchUploadFolder, cleanFolders } from '../../../../../store/contracts/folders/folders.actions'
import {
  uploadFolderDataErrorSelector,
  uploadFolderFetchStatusSelector,
  uploadFolderIsSuccessSelector,
} from '../../../../../store/contracts/folders/folders.selectors'
import { useDispatchUnmount } from '../../../../../hooks/useDispatchUnmount'
import { useShowFetchError } from '../../../../hooks/useShowFetchError'
import { FOLDER_ALREADY_EXIST } from '../../../../../constants/folders'
import { DocumentsCreateFolderModalModal } from '../../DocumentModals/DocumentsCreateFolderModal/DocumentsCreateFolderModal'

import { ContractsCreateFolderModalProps } from './ContractsCreateFolderModal.types'

const EXCLUDE_CODES = [FOLDER_ALREADY_EXIST]

export const ContractsCreateFolderModal: React.FC<ContractsCreateFolderModalProps> = ({ onClose }) => {
  const dispatch = useDispatch()
  const formRef: React.RefObject<FormikProps<CreateFolderBody>> = useRef(null)
  const loadSuccessfully = useSelector(uploadFolderIsSuccessSelector)
  const fetchStatus = useSelector(uploadFolderFetchStatusSelector)
  const error = useSelector(uploadFolderDataErrorSelector)

  useEffect(() => {
    if (loadSuccessfully) {
      onClose()
    }
  }, [loadSuccessfully])
  useEffect(() => {
    if (error?.lastErrorCode === FOLDER_ALREADY_EXIST) {
      const form = formRef.current

      if (form) {
        form.setFieldError(CreateFolderFields.folderName, FOLDER_ALREADY_EXIST)
      }
    }
  }, [error])
  useShowFetchError(fetchStatus, error, EXCLUDE_CODES)
  useDispatchUnmount(cleanFolders)

  const onSubmit = (values: CreateFolderBody) => {
    dispatch(fetchUploadFolder({ name: values.folderName }))
  }

  return <DocumentsCreateFolderModalModal ref={formRef} onClose={onClose} onSubmit={onSubmit} />
}
