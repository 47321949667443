import React from 'react'

import { SvgIconProps } from '../../ui/SvgIcon/SvgIcon.types'
import SvgIcon from '../../ui/SvgIcon'

export const AttentionIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.5 20.32C17.3712 20.32 21.32 16.3712 21.32 11.5C21.32 6.62885 17.3712 2.68 12.5 2.68C7.62885 2.68 3.68 6.62885 3.68 11.5C3.68 16.3712 7.62885 20.32 12.5 20.32ZM12.5 22C18.299 22 23 17.299 23 11.5C23 5.70101 18.299 1 12.5 1C6.70101 1 2 5.70101 2 11.5C2 17.299 6.70101 22 12.5 22Z"
    />
    <path d="M13.76 6.72988L13.5004 13.4414H11.4996L11.24 6.72988H13.76ZM13.7473 16.1577H11.2527V14.4862H13.7473V16.1577Z" />
  </SvgIcon>
)
