import { combineEpics, Epic, ofType } from 'redux-observable'
import { catchError, map, mergeMap, takeUntil } from 'rxjs/operators'
import { of } from 'rxjs'

import { catchFetchError } from '../../../utils/catchFetchError'
import { templateFolderService } from '../../../api'
import { prepareOptions } from '../../../utils/dropdown/prepareOptions'
import { ALL_TEMPLATES_FOLDER } from '../../../constants/templates'
import { templatesListForceUpdate } from '../list/list.actions'

import * as ACTIONS from './folders.actions'

export const fetchFolders: Epic = (action$) =>
  action$.pipe(
    ofType(ACTIONS.fetchTemplatesFoldersTypes.request),
    mergeMap(() =>
      templateFolderService.fetchFolders().pipe(
        map((action) =>
          ACTIONS.fetchTemplatesFoldersSuccess([
            ALL_TEMPLATES_FOLDER,
            ...prepareOptions(action.data, { nameKey: 'name', valueKey: 'id' }),
          ])
        ),
        catchError(catchFetchError(ACTIONS.fetchTemplatesFoldersFailure)),
        takeUntil(
          action$.pipe(ofType(ACTIONS.fetchTemplatesFoldersTypes.success, ACTIONS.fetchTemplatesFoldersTypes.failure))
        )
      )
    )
  )

export const fetchUploadFolder: Epic = (action$) =>
  action$.pipe(
    ofType(ACTIONS.fetchTemplatesUploadFolderTypes.request),
    mergeMap(({ payload }) =>
      templateFolderService.fetchUploadFolder(payload.folder).pipe(
        mergeMap(() => of(templatesListForceUpdate(), ACTIONS.fetchTemplatesUploadFolderSuccess())),
        catchError(catchFetchError(ACTIONS.fetchTemplatesUploadFolderFailure)),
        takeUntil(
          action$.pipe(
            ofType(ACTIONS.fetchTemplatesUploadFolderTypes.success, ACTIONS.fetchTemplatesUploadFolderTypes.failure)
          )
        )
      )
    )
  )

export const foldersEpics = combineEpics(fetchFolders, fetchUploadFolder)
