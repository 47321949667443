import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { fetchUnreadCount, fetchUserNotifications } from '../../store/userNotifications/userNotifications.actions'
import * as SELECTORS from '../../store/userNotifications/userNotifications.selectors'
import { useDidUpdate } from '../useDidUpdate'

export const useNotificationsFetch = () => {
  const dispatch = useDispatch()
  const offset = useSelector(SELECTORS.userNotificationsOffsetSelector)
  const limit = useSelector(SELECTORS.userNotificationsLimitSelector)
  const isIdle = useSelector(SELECTORS.userNotificationsIsIdleSelector)

  useEffect(() => {
    if (isIdle) {
      dispatch(fetchUserNotifications({ limit, offset }))
      dispatch(fetchUnreadCount())
    }
  }, [isIdle, limit, offset])
  useDidUpdate(() => {
    dispatch(fetchUserNotifications({ limit, offset }))
  }, [limit, offset])
}
