export type UserNotificationsAllTypes = {
  [key in keyof typeof PreferencesNotificationsTypes]: boolean
}

export type UserNotificationsValues = {
  [key in keyof typeof PreferencesNotifications]: UserNotificationsAllTypes
}

export type FetchBlockedNotificationsResponse = {
  [key in keyof typeof PreferencesNotificationsTypes]: PreferencesNotifications[]
}

export interface UpdateNotification {
  notification: PreferencesNotifications
  way: PreferencesNotificationsTypes
  blocked: boolean
}

export interface UpdateNotificationsBody {
  notifications: UpdateNotification[]
}
export enum PreferencesNotifications {
  NOTIFICATION_CONTRACT_PENDING = 'NOTIFICATION_CONTRACT_PENDING',
  NOTIFICATION_CONTRACT_SIGNED = 'NOTIFICATION_CONTRACT_SIGNED',
  NOTIFICATION_CONTRACT_DECLINED = 'NOTIFICATION_CONTRACT_DECLINED',
  NOTIFICATION_CONTRACT_CHANGED = 'NOTIFICATION_CONTRACT_CHANGED',
  NOTIFICATION_CONTRACT_COMMENTED = 'NOTIFICATION_CONTRACT_COMMENTED',
  NOTIFICATION_CONTRACT_MENTIONED = 'NOTIFICATION_CONTRACT_MENTIONED',
  NOTIFICATION_CONTRACT_SENT = 'NOTIFICATION_CONTRACT_SENT',
  NOTIFICATION_CONTRACT_UPDATED = 'NOTIFICATION_CONTRACT_UPDATED',
  NOTIFICATION_CONTRACT_DELETED = 'NOTIFICATION_CONTRACT_DELETED',
  NOTIFICATION_CONTRACT_COMPLETED = 'NOTIFICATION_CONTRACT_COMPLETED',
  NOTIFICATION_CONTRACT_RECIPIENTS_UPDATED = 'NOTIFICATION_CONTRACT_RECIPIENTS_UPDATED',
  NOTIFICATION_CONTRACT_VOIDED = 'NOTIFICATION_CONTRACT_VOIDED',
  NOTIFICATION_CONTRACT_CORRECTIONS_REMOVED = 'NOTIFICATION_CONTRACT_CORRECTIONS_REMOVED',
  CORRECTION_ADDED = 'CORRECTION_ADDED',
  STRIKE_ADDED = 'STRIKE_ADDED',

  NOTIFICATION_REMINDER_BEFORE_AND_ON_DAY = 'NOTIFICATION_REMINDER_BEFORE_AND_ON_DAY',

  NOTIFICATION_CHASERS_DEADLINE_MISSED = 'NOTIFICATION_CHASERS_DEADLINE_MISSED',
}

export enum PreferencesNotificationsBunches {
  contract = 'contract',
  reminder = 'reminder',
  chasers = 'chasers',
}

export enum PreferencesNotificationsTypes {
  SOCKET = 'SOCKET',
  EMAIL = 'EMAIL',
  PUSH = 'PUSH',
}
