import React from 'react'

import ErrorBoundary from '../../components/ErrorBoundary/ErrorBoundary'

import { ContractRecipientsDiagram as ContractRecipientsDiagramView } from './ContractRecipientsDiagram'

export const ContractRecipientsDiagram = () => (
  <ErrorBoundary>
    <ContractRecipientsDiagramView />
  </ErrorBoundary>
)
