import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { SUBSCRIPTION_FEATURES_ERRORS } from '../../../../../constants/subscription'
import { useDispatchUnmount } from '../../../../../hooks/useDispatchUnmount'
import {
  clearSendContract,
  fetchSendContract,
  fetchContractStatus,
} from '../../../../../store/contracts/placement/placement.actions'
import {
  placementSendIsSuccess,
  placementSendFetchStatus,
  placementSendError,
  placementContractStatusIsSuccess,
  placementContractStatus,
} from '../../../../../store/contracts/placement/placement.selectors'
import { openModal } from '../../../../../store/modals/modals.actions'
import { modalsPayloadSelector } from '../../../../../store/modals/modals.selectors'
import translations from '../../../../../translations/keys'
import { SubscriptionFeatures } from '../../../../../types/subscription'
import { location } from '../../../../../utils/location'
import { FileSvg } from '../../../../assets/FileSvg'
import { CONTRACT_PLACEMENT_MODALS } from '../../../../constants/contractPlacement'
import { useShowFetchError } from '../../../../hooks/useShowFetchError'
import Button from '../../../../ui/Button'
import Modal from '../../../Modal'
import Typography from '../../../../ui/Typography'
import { TYPOGRAPHY_NAMES } from '../../../../ui/Typography/Typography.types'
import Box from '../../../../ui/Box'
import { COMMON_MODALS_IDS } from '../../../../ui/Modals/CommonModals/CommonModals.constants'
import { CLM_IS_NOT_ALLOWED } from '../../../../../constants/contracts'

import { SendContractModalProps } from './SendContractModal.types'

const EXCLUDE_CODES = [CLM_IS_NOT_ALLOWED]

export const SendContractModal: React.FC<SendContractModalProps> = ({ onClose }) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { contractId } = useSelector(modalsPayloadSelector(CONTRACT_PLACEMENT_MODALS.CONTRACT_PLACEMENT_SEND))
  const sendSuccessfully = useSelector(placementSendIsSuccess)
  const fetchStatus = useSelector(placementSendFetchStatus)
  const error = useSelector(placementSendError)
  const fetchStatusSuccessfully = useSelector(placementContractStatusIsSuccess)
  const { status: contractStatus } = useSelector(placementContractStatus)
  const { t } = useTranslation()

  useEffect(() => {
    if (sendSuccessfully) {
      history.push(location.contractsAllUrl())
    }
  }, [sendSuccessfully])

  useEffect(() => {
    if (fetchStatusSuccessfully && contractStatus === 'SENT') {
      console.log(fetchStatusSuccessfully, contractStatus)
      history.push(location.contractsAllUrl())
    } else if (fetchStatusSuccessfully) {
      dispatch(fetchSendContract(contractId))
    }
  }, [fetchStatusSuccessfully])

  useEffect(
    () => () => {
      onClose()
    },
    []
  )
  useEffect(() => {
    if (error?.lastErrorCode === CLM_IS_NOT_ALLOWED) {
      dispatch(
        openModal(COMMON_MODALS_IDS.SUBSCRIPTION_WARNING, {
          descriptionKey: SUBSCRIPTION_FEATURES_ERRORS[SubscriptionFeatures.FEATURE_CLM],
        })
      )
    }
  }, [error])
  useShowFetchError(fetchStatus, error, EXCLUDE_CODES)
  useDispatchUnmount(clearSendContract)

  const onSend = () => {
    dispatch(fetchContractStatus(contractId))
    console.log(fetchStatusSuccessfully, contractId, contractStatus)
  }

  return (
    <Modal onClose={onClose}>
      <Box display="flex" flexDirection="column" alignItems="center" width={320}>
        <Box mb={3}>
          <FileSvg />
        </Box>
        <Typography name={TYPOGRAPHY_NAMES.H1}>{t(translations.SEND_CONTRACT_TITLE)}</Typography>
        <Box mt={2} mb={3} textAlign="center">
          {t(translations.SEND_CONTRACT_DESCRIPTION)}
        </Box>
        <Button onClick={onSend} fullWidth>
          {t(translations.SUBMIT)}
        </Button>
      </Box>
    </Modal>
  )
}
