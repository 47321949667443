import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import translations from '../../../translations/keys'
import { ContractStatusTypes } from '../../../types/contracts'
import * as RECIPIENTS_SELECTORS from '../../../store/contracts/recipients/recipients.selectors'
import Tabs from '../../ui/Tabs'
import { useRecipientsFetch } from '../../../hooks/recipients/useRecipientsFetch'
import { ContractRouteParams } from '../../types/signing'

import CloseButton from './components/CloseButton'
import { LogSidebarProps, LogSidebarTabs } from './LogSidebar.types'
import History from './components/History'
import Progress from './components/Progress'
import * as Styled from './LogSidebar.styles'

export const LogSidebar: React.FC<LogSidebarProps> = ({ onEdit, onDelete }) => {
  const { t } = useTranslation()
  const { contractId } = useParams<ContractRouteParams>()
  const [activeId, setActiveId] = useState(LogSidebarTabs.PROGRESS)
  const recipientsById = useSelector(RECIPIENTS_SELECTORS.recipientsByIdSelector)
  const recipientsSorted = useSelector(RECIPIENTS_SELECTORS.recipientsSortedSelector)
  const negativeStatusCount = recipientsSorted.filter(
    (id) => recipientsById[id].status?.type === ContractStatusTypes.NEGATIVE
  ).length

  const items = [
    {
      id: LogSidebarTabs.PROGRESS,
      name: t(translations.PROGRESS),
      errorsCount: negativeStatusCount,
      render: () => <Progress onEdit={onEdit} onDelete={onDelete} />,
    },
    {
      id: LogSidebarTabs.HISTORY,
      name: t(translations.HISTORY),
      render: () => <History />,
    },
  ]

  useRecipientsFetch(contractId)

  const handleTabChange = (id: string) => {
    setActiveId(id as LogSidebarTabs)
  }

  return (
    <Styled.Container>
      <CloseButton />
      <Tabs items={items} activeId={activeId} onChange={handleTabChange} />
    </Styled.Container>
  )
}
