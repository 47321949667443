import { FetchFailureAction, FETCH_STATUSES } from '../../../types/fetch'
import { createReducer } from '../../createReducer'
import { updatePlacementById } from '../../../utils/placement'

import * as TYPES from './placement.types'
import * as ACTIONS from './placement.actions'

type Actions =
  | TYPES.SetPlacementAction
  | TYPES.ClearPlacementAction
  | TYPES.ClearPlacementStatusAction
  | TYPES.FetchPlacementSuccessAction
  | TYPES.FetchUpdatePlacementSuccessAction
  | TYPES.FetchGeneratePlacementSuccessAction
  | TYPES.SetShouldRegenerateAction
  | FetchFailureAction

export const initialState: TYPES.PlacementState = {
  byId: {},
  byPages: {},
  sorted: [],
  shouldRegenerate: false,
  fetch: {
    status: FETCH_STATUSES.IDLE,
    error: null,
  },
  update: {
    status: FETCH_STATUSES.IDLE,
    error: null,
  },
  generate: {
    status: FETCH_STATUSES.IDLE,
    error: null,
  },
}

export const placement = createReducer<TYPES.PlacementState, Actions>(initialState, {
  [TYPES.PlacementActions.TEMPLATES_SET_PLACEMENT]: (state, action) => ({
    ...state,
    byId: updatePlacementById((action as TYPES.SetPlacementAction).payload.place, state.byId),
  }),
  [TYPES.PlacementActions.TEMPLATES_CLEAR_PLACEMENT]: () => ({
    ...initialState,
  }),
  [TYPES.PlacementActions.TEMPLATES_CLEAR_PLACEMENT_STATUS]: (state) => ({
    ...state,
    generate: {
      ...initialState.generate,
    },
  }),

  [ACTIONS.fetchPlacementTypes.request]: (state) => ({
    ...state,
    fetch: {
      ...state.fetch,
      status: FETCH_STATUSES.REQUEST,
      error: null,
    },
  }),
  [ACTIONS.fetchPlacementTypes.success]: (state, action) => ({
    ...state,
    ...(action as TYPES.FetchPlacementSuccessAction).payload,
    fetch: {
      ...state.fetch,
      status: FETCH_STATUSES.SUCCESS,
      error: null,
    },
  }),
  [ACTIONS.fetchPlacementTypes.failure]: (state, action) => ({
    ...state,
    fetch: {
      ...state.fetch,
      status: FETCH_STATUSES.FAILURE,
      error: (action as FetchFailureAction).payload,
    },
  }),

  [ACTIONS.fetchUpdatePlacementTypes.request]: (state) => ({
    ...state,
    update: {
      ...state.update,
      status: FETCH_STATUSES.REQUEST,
      error: null,
    },
  }),
  [ACTIONS.fetchUpdatePlacementTypes.success]: (state) => ({
    ...state,
    update: {
      ...state.update,
      status: FETCH_STATUSES.SUCCESS,
      error: null,
    },
    generate: {
      ...state.generate,
      status: FETCH_STATUSES.IDLE,
    },
  }),
  [ACTIONS.fetchUpdatePlacementTypes.failure]: (state, action) => ({
    ...state,
    update: {
      ...state.update,
      status: FETCH_STATUSES.FAILURE,
      error: (action as FetchFailureAction).payload,
    },
  }),

  [ACTIONS.fetchGeneratePlacementTypes.request]: (state) => ({
    ...state,
    generate: {
      ...state.generate,
      status: FETCH_STATUSES.REQUEST,
      error: null,
    },
  }),
  [ACTIONS.fetchGeneratePlacementTypes.success]: (state) => ({
    ...state,
    generate: {
      ...state.generate,
      status: FETCH_STATUSES.SUCCESS,
      error: null,
    },
  }),
  [ACTIONS.fetchGeneratePlacementTypes.failure]: (state, action) => ({
    ...state,
    generate: {
      ...state.generate,
      status: FETCH_STATUSES.FAILURE,
      error: (action as FetchFailureAction).payload,
    },
  }),

  [TYPES.PlacementActions.TEMPLATES_SET_SHOULD_REGENERATE]: (state, action) => ({
    ...state,
    shouldRegenerate: (action as TYPES.SetShouldRegenerateAction).payload.shouldRegenerate,
  }),
})
