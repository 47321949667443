import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { TEMPLATES_MODALS } from '../../../../../constants/templates'
import { TEMPLATES_FILL_RECIPIENTS_MODALS } from '../../../../../constants/templatesFillRecipients'
import { openModal } from '../../../../../store/modals/modals.actions'
import { modalsPayloadSelector } from '../../../../../store/modals/modals.selectors'
import translations from '../../../../../translations/keys'
import UploadDocumentModal from '../../DocumentModals/UploadDocumentModal'

import { UploadFileModalProps } from './UploadFileModal.types'

export const UploadFileModal: React.FC<UploadFileModalProps> = ({ onClose }) => {
  const dispatch = useDispatch()
  const { recipients, order } = useSelector(modalsPayloadSelector(TEMPLATES_MODALS.UPLOAD_FILE_FOR_CONTRACT))

  const handleUploadContractSuccess = (fileName: string, file: File) => {
    dispatch(
      openModal(TEMPLATES_FILL_RECIPIENTS_MODALS.CREATE_CONTRACT_FROM_TEMPLATE, { fileName, file, recipients, order })
    )
  }

  return (
    <UploadDocumentModal
      onClose={onClose}
      onSuccess={handleUploadContractSuccess}
      title={translations.NO_DOCUMENT_NOTIFICATION}
    />
  )
}
