import { FetchFailureAction, FETCH_STATUSES } from '../../../types/fetch'
import { createReducer } from '../../createReducer'

import * as ACTIONS from './createFromContract.actions'
import * as TYPES from './createFromContract.types'

type Actions = FetchFailureAction | TYPES.FetchCreateTemplateFromContractSuccess

export const initialState: TYPES.CreateFromContractState = {
  id: null,
  fetchStatus: FETCH_STATUSES.IDLE,
  error: null,
}

export const createFromContract = createReducer<TYPES.CreateFromContractState, Actions>(initialState, {
  [ACTIONS.fetchCreateTemplateFromContractTypes.request]: (state) => ({
    ...state,
    fetchStatus: FETCH_STATUSES.REQUEST,
  }),
  [ACTIONS.fetchCreateTemplateFromContractTypes.success]: (state, action) => ({
    ...state,
    id: (action as TYPES.FetchCreateTemplateFromContractSuccess).payload.id,
    fetchStatus: FETCH_STATUSES.SUCCESS,
  }),
  [ACTIONS.fetchCreateTemplateFromContractTypes.failure]: (state, action) => ({
    ...state,
    fetchStatus: FETCH_STATUSES.FAILURE,
    error: (action as FetchFailureAction).payload,
  }),
  [TYPES.CreateFromContractActions.CLEAN_CREATE_TEMPLATE_FROM_CONTRACT]: () => ({ ...initialState }),
})
