import { Action } from 'redux'

import { FetchFailurePayload, FETCH_STATUSES } from '../../types/fetch'
import { RestorePasswordBody } from '../../types/restorePassword'

export interface RestorePasswordState {
  fetchStatus: FETCH_STATUSES
  error?: FetchFailurePayload
}

export enum RestorePasswordActions {
  'FETCH_RESTORE_PASSWORD' = 'FETCH_RESTORE_PASSWORD',
  'RESET_RESTORE_PASSWORD' = 'RESET_RESTORE_PASSWORD',
}

export interface FetchRestorePasswordAction extends Action {
  payload: RestorePasswordBody
}

export type FetchRestorePasswordSuccessAction = Action
