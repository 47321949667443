import { FetchFailureAction, FETCH_STATUSES } from '../../../types/fetch'
import { createReducer } from '../../createReducer'

import * as ACTIONS from './clearAndContinue.actions'
import * as TYPES from './clearAndContinue.types'

type Actions = FetchFailureAction

export const initialState: TYPES.ClearAndContinueState = {
  fetchStatus: FETCH_STATUSES.IDLE,
  error: null,
}

export const clearAndContinue = createReducer<TYPES.ClearAndContinueState, Actions>(initialState, {
  [ACTIONS.fetchClearAndContinueContractTypes.request]: (state) => ({
    ...state,
    fetchStatus: FETCH_STATUSES.REQUEST,
  }),
  [ACTIONS.fetchClearAndContinueContractTypes.success]: (state) => ({
    ...state,
    fetchStatus: FETCH_STATUSES.SUCCESS,
  }),
  [ACTIONS.fetchClearAndContinueContractTypes.failure]: (state, action) => ({
    ...state,
    fetchStatus: FETCH_STATUSES.FAILURE,
    error: (action as FetchFailureAction).payload,
  }),
})
