import React, { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import translations from '../../../../translations/keys'
import { DocCommentsThread, Suggestion } from '../../../../types/comment'
import Modal from '../../Modal'
import { isBannerVisibleSelector } from '../../../../store/banner/banner.selectors'
import { CommentThreadSkeleton } from '../../Skeletons/CommentThreadSkeleton'
import Box from '../../Box'
import Typography from '../../Typography'
import { TYPOGRAPHY_NAMES } from '../../Typography/Typography.types'
import Mentions from '../../Mentions'
import { getFormattedDate } from '../../../../utils/date'
import ConfirmationLabel from '../../ConfirmationLabel'
import { BoxSize } from '../../ConfirmationLabel/ConfirmationLabel.types'

import { CommentModalProps } from './CommentModal.types'
import * as Styled from './CommentModal.styles'
import ConfirmationActions from './components/ConfirmationActions'

const MAX_COMMENT_SIZE = 200

export const CommentModal = ({
  comment,
  currentUser,
  onSubmit,
  onModalClose,
  onElemConfirm,
  thread,
  loading,
  recipients,
  isContractOwner,
  onElemCancelConfirmation,
}: CommentModalProps) => {
  const isBannerVisible = useSelector(isBannerVisibleSelector)
  const { status, name, timestamp } = comment.confirmation
  const [mentions, setMentions] = useState<Suggestion[]>([])
  const [message, setMessage] = useState('')
  const { t } = useTranslation()
  const isSubmitDisabled = !message.trim()
  const placeholder =
    loading || thread.length > 0 ? t(translations.COMMENT_REPLY) : t(translations.COMMENT_FIELD_PLACEHOLDER)

  const suggestions = useMemo(
    () => Object.values(recipients).map((recipient) => ({ name: recipient.name, email: recipient.email })),
    [comment, currentUser, message]
  )
  const preparedComment = useMemo(
    () => ({
      id: comment.id,
      x: comment.x,
      y: comment.y,
      page: comment.page,
      message,
      mentions,
      confirmation: comment.confirmation,
    }),
    [comment, currentUser, message]
  )
  const handleSubmit = () => {
    onSubmit(preparedComment)
  }

  const handleClose = () => {
    onModalClose(preparedComment)
  }

  const isShowConfirmationActions = () => !status && thread.length > 0

  const handleChange = (value: string) => {
    if (value.length < MAX_COMMENT_SIZE) {
      setMessage(value)
    }
  }

  const handleMentionsChange = (mentions: Suggestion[]) => {
    setMentions(mentions)
  }

  const getName = (comment: DocCommentsThread) => {
    const recipientName = comment.recipientId
      ? recipients[comment.recipientId]?.name
      : `${currentUser?.firstName} ${currentUser?.lastName}`.trim()

    const ownerName = comment.owner?.name

    return ownerName || recipientName
  }
  return (
    <Modal title={t(translations.COMMENTS)} onClose={handleClose} fullSize offsetTop={isBannerVisible}>
      <Styled.Wrapper>
        {loading ? (
          <CommentThreadSkeleton />
        ) : (
          thread.map((comment: DocCommentsThread) => (
            <Styled.ThreadItem key={comment.id}>
              <Box mb={0.5}>
                <Typography name={TYPOGRAPHY_NAMES.bold14}>{getName(comment)}</Typography>
              </Box>
              <Box mb={1}>
                <Typography name={TYPOGRAPHY_NAMES.bodySmall12} color="grey">
                  {getFormattedDate(comment.date)}
                </Typography>
              </Box>
              <Mentions
                disabled
                name={comment.id}
                value={comment.message}
                placeholder={t(translations.COMMENT_FIELD_PLACEHOLDER)}
                suggestions={suggestions}
                onChange={handleChange}
                onMentionsChange={handleMentionsChange}
              />
            </Styled.ThreadItem>
          ))
        )}
        {status && name && timestamp && comment?.id && (
          <ConfirmationLabel
            isContractOwner={isContractOwner}
            size={BoxSize.tall}
            iconSize="extraSmall"
            id={comment.id}
            status={status}
            date={timestamp}
            fullName={name}
            onCancel={onElemCancelConfirmation}
          />
        )}
        {!status && (
          <Mentions
            autoFocus={comment.counter === 0}
            name="comment"
            value={message}
            placeholder={placeholder}
            suggestions={suggestions}
            onChange={handleChange}
            onMentionsChange={handleMentionsChange}
          />
        )}
        <Styled.SubmitButton
          status={status}
          data-testid="comment-popover-submit-button"
          disabled={isSubmitDisabled}
          onClick={handleSubmit}
        >
          {t(translations.SAVE)}
        </Styled.SubmitButton>

        {isShowConfirmationActions() && comment.id && onElemConfirm && (
          <ConfirmationActions onConfirm={onElemConfirm} id={comment.id} />
        )}
      </Styled.Wrapper>
    </Modal>
  )
}
