import { Action } from 'redux'

import { FetchFailurePayload, FETCH_STATUSES } from '../../../types/fetch'
import { Option } from '../../../types/options'

export interface FoldersState {
  upload: {
    fetchStatus: FETCH_STATUSES
    error: FetchFailurePayload | null
  }
  error: FetchFailurePayload | null
  data: Option[]
  lastAddedFolder: Option | null
  fetchStatus: FETCH_STATUSES
}

export enum FoldersActions {
  'FETCH_FOLDERS' = 'FETCH_FOLDERS',
  'CREATE_FOLDER' = 'CREATE_FOLDER',
  'UPLOAD_FOLDER' = 'UPLOAD_FOLDER',
  'CLEAN_FOLDER' = 'CLEAN_FOLDER',
}

export type FetchFoldersAction = Action

export interface FetchFoldersSuccessAction extends Action {
  payload: {
    data: Option[]
  }
}

export interface CreateFolder extends Action {
  payload: {
    folder: Option
  }
}
