import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import * as PLANS_ACTIONS from '../../store/subscription/plans/plans.actions'
import * as PAYMENT_ACTIONS from '../../store/subscription/payment/payment.actions'
import { useDispatchUnmount } from '../useDispatchUnmount'

export const usePlanFetch = (id: string) => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(PLANS_ACTIONS.fetchPlan(id))
  }, [id])

  useDispatchUnmount(PLANS_ACTIONS.clearPlans, PAYMENT_ACTIONS.clearSubscriptionPayment)
}
