export enum ChangePasswordFields {
  currentPassword = 'currentPassword',
  newPassword = 'newPassword',
  confirmNewPassword = 'confirmNewPassword',
}

export interface ChangePasswordRequestBody {
  currentPassword: string
  newPassword: string
}

export interface ChangePasswordFormValues extends ChangePasswordRequestBody {
  confirmNewPassword: string
}
