import { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Location } from 'history'

import { openModal } from '../../store/modals/modals.actions'

export const useHistoryLeave = (modalId: string, canLeave: boolean) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const block = useCallback(history.block, [])
  const [unBlockHandler, setUnBlockHandler] = useState<() => void>()

  useEffect(() => {
    if (!canLeave) {
      const unBlock = block((location: Location) => {
        if (!canLeave) {
          dispatch(openModal(modalId, { pathname: location.pathname }))
          return false
        }
      })

      setUnBlockHandler(() => unBlock)

      return () => {
        unBlock()
        setUnBlockHandler(undefined)
      }
    }
  }, [canLeave, block])

  return {
    unBlock: unBlockHandler,
  }
}
