import { FetchResponseWithPagination } from './fetch'

export enum CONTRACT_STATUS {
  DRAFT = 'DRAFT',
  SENT = 'SENT',
  SIGNED = 'SIGNED',
  COMMENTED = 'COMMENTED',
  VOIDED = 'VOIDED',
  COMPLETED = 'COMPLETED',
  DELETED = 'DELETED',
  EXPIRED = 'EXPIRED',

  PENDING_RECIPIENTS = 'PENDING_RECIPIENTS',
  READY_TO_SIGN = 'READY_TO_SIGN',
  DECLINED = 'DECLINED',
  VERIFICATION_FAILED = 'VERIFICATION_FAILED',
  USER_TERM_EXPIRED = 'USER_TERM_EXPIRED',
  LEFT_CORRECTION = 'LEFT_CORRECTION',
}

export enum ContractStatusTypes {
  POSITIVE = 'positive',
  NEGATIVE = 'negative',
}

export interface ContractDate {
  [key: string]: string
  date: string
  userEmail: string
  userName: string
}

export interface ContractProgress {
  involved: number
  completed: number
}

export interface Contract {
  [key: string]: string | ContractDate | CONTRACT_STATUS | boolean | ContractProgress
  id: string
  name: string
  status: CONTRACT_STATUS
  createDate: ContractDate
  updateDate: ContractDate
  owner: boolean
  isFolder: boolean
  recipient: boolean
  progress: ContractProgress
  signed: boolean
}

export interface ContactsListResponse extends FetchResponseWithPagination {
  data: Contract[]
}

export type ContractsCategory = CONTRACT_STATUS.DELETED | CONTRACT_STATUS.SENT | CONTRACT_STATUS.DRAFT | 'received'

export enum ContractSuccessMessages {
  'NONE' = '',
  'CONTRACT_DELETED' = 'CONTRACT_DELETED',
  'NAME_CHANGED' = 'NAME_CHANGED',
  'CONTRACT_MOVED_TO_FOLDER' = 'CONTRACT_MOVED_TO_FOLDER',
  'CONTRACT_DUPLICATED' = 'CONTRACT_DUPLICATED',
  'FOLDER_UPLOADED' = 'FOLDER_UPLOADED',
  'CONTRACT_SENT_SUCCESSFULLY' = 'CONTRACT_SENT_SUCCESSFULLY',
  'CONTRACT_DECLINED' = 'CONTRACT_DECLINED',
  'CONTRACT_FOLDER_DELETED' = 'CONTRACT_FOLDER_DELETED',
}

export type ContractsListBriefResponse = Array<{ id: string; name: string }>
