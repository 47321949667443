import { createReducer } from '../createReducer'

import * as TYPES from './progress.types'

type Actions = TYPES.CloseProgressAction | TYPES.OpenProgressAction

export const initialState: TYPES.ProgressState = {
  visible: false,
}

export const progress = createReducer<TYPES.ProgressState, Actions>(initialState, {
  [TYPES.ProgressActions.OPEN_PROGRESS]: () => ({
    visible: true,
  }),
  [TYPES.ProgressActions.CLOSE_PROGRESS]: () => ({
    visible: false,
  }),
})
