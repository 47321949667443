import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { modalsPayloadSelector } from '../../../../../store/modals/modals.selectors'
import { CreateSignatureBody } from '../../../../../types/signatures'
import { CreateSignatureModal as CommonCreateSignatureModal } from '../../CreateSignatureModal/CreateSignatureModal'
import { CreateSignatureModalProps, SignatureMode } from '../../CreateSignatureModal/CreateSignatureModal.types'
import { setSignature } from '../../../../../store/contracts/signing/signing.actions'
import { getISODate } from '../../../../../utils/date/date'
import { UNREGISTERED_CONTRACT_SIGN_MODALS } from '../../../../constants/unregisteredContractSign'

export const CreateSignatureModal: React.FC<Pick<CreateSignatureModalProps, 'onClose'>> = ({ onClose }) => {
  const dispatch = useDispatch()
  const { mode, placeId, isChanged } = useSelector(
    modalsPayloadSelector(UNREGISTERED_CONTRACT_SIGN_MODALS.UNREGISTERED_CREATE_SIGNATURE)
  )

  const onCreateSignature = (body: CreateSignatureBody) => {
    onClose()
    const image = mode === SignatureMode.signature ? body.signature : body.initials
    dispatch(setSignature({ placeId, image, signatureId: 'id', timestamp: getISODate(new Date()), isChanged }))
  }

  return <CommonCreateSignatureModal onClose={onClose} mode={mode} onCreateSignature={onCreateSignature} />
}
