import { Action } from 'redux'

import { FetchFailurePayload, FETCH_STATUSES } from '../../../types/fetch'

export interface ClearAndContinueState {
  fetchStatus: FETCH_STATUSES
  error: FetchFailurePayload | null
}

export enum ClearAndContinueActions {
  'FETCH_CLEAR_AND_CONTINUE_CONTRACT' = 'FETCH_CLEAR_AND_CONTINUE_CONTRACT',
  'CLEAN_CLEAR_AND_CONTINUE_CONTRACT' = 'CLEAN_CLEAR_AND_CONTINUE_CONTRACT',
}

export interface FetchClearAndContinueContract extends Action {
  payload: { contractId: string }
}

export interface FetchClearAndContinueContractSuccess extends Action {
  payload: { contractId: string }
}
