import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import * as PAYMENT_ACTIONS from '../../store/subscription/payment/payment.actions'
import * as PAYMENT_SELECTORS from '../../store/subscription/payment/payment.selectors'
import { useDispatchUnmount } from '../useDispatchUnmount'

export const usePaymentFieldsFetch = () => {
  const dispatch = useDispatch()

  const refetchFormFields = useSelector(PAYMENT_SELECTORS.subscriptionPaymentFormRefetchSelector)

  useEffect(() => {
    if (refetchFormFields) {
      dispatch(PAYMENT_ACTIONS.fetchPaymentFormFields())
    }
  }, [refetchFormFields])

  useDispatchUnmount(PAYMENT_ACTIONS.cleanPaymentForm)
}
