import styled from 'styled-components'

import Box from '../../../../../Box'

export const StyledWrapper = styled(Box).attrs({
  pl: 1,
  pr: 1,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  height: 56,
})`
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: ${({ theme }) => theme.palette.strokeGrey};
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.palette.blueExtraLight};
  }
`
