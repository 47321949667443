import { Action } from 'redux'

type ReducerMap<I, A> = {
  [key: string]: (state: I, action: A) => I
}

export const createReducer = <I, A extends Action>(initialState: I, reducerMap: ReducerMap<I, A>) => (
  state = initialState,
  action: A
) => {
  const reducer = reducerMap[action.type]

  return reducer ? reducer(state, action) : state
}
