import React from 'react'
import { Redirect, Switch } from 'react-router-dom'

import { location } from '../../utils/location'
import { CloudStorage } from '../pages'
import { PreferencesNotifications } from '../pages/PreferencesNotifications'

import { PrivateRoute } from './components/PrivateRoute'

const PreferencesRoutes = () => (
  <Switch>
    {/* TODO UQD-1410 uncomment when this will work */}
    <PrivateRoute exact path={location.preferencesNotificationsUrl()}>
      <PreferencesNotifications />
    </PrivateRoute>
    {/* <PrivateRoute exact path={location.preferencesRegionalSettingsUrl()}>
      <RegionalSettings />
    </PrivateRoute>
 */}
    <PrivateRoute exact path={location.cloudStorageUrl()}>
      <CloudStorage />
    </PrivateRoute>
    <Redirect to={location.error404Url()} />
  </Switch>
)

export default PreferencesRoutes
