import { useCallback } from 'react'
import { useDispatch } from 'react-redux'

import { openModal } from '../../../../store/modals/modals.actions'
import { CONTRACT_SIGN_MODALS } from '../../../constants/contractSign'
import { useShowNoSignature } from '../../../hooks/signatures/useShowNoSignature'
import { PlacementById } from '../../../../types/placement'
import { usePlacementById as useCommonPlacementById } from '../../../hooks/contractSign/usePlacementById'

export const usePlacementById = (): PlacementById => {
  const dispatch = useDispatch()
  const { showNoSignatureModal } = useShowNoSignature()

  const handleChangeSignature = useCallback((placeId: string, isInitials: boolean) => {
    dispatch(
      openModal(CONTRACT_SIGN_MODALS.CONTRACT_VIEW_CHOOSE_SIGN, {
        isInitials,
        placeId,
      })
    )
  }, [])
  const handleEmptySignature = useCallback(() => {
    showNoSignatureModal()
  }, [showNoSignatureModal])

  return useCommonPlacementById({ handleChangeSignature, handleEmptySignature })
}
