import { FetchFailureAction, FETCH_STATUSES } from '../../../types/fetch'
import { createReducer } from '../../createReducer'

import * as TYPES from './download.types'
import * as ACTIONS from './download.actions'

type Actions = TYPES.FetchDownloadContractSuccessAction | FetchFailureAction

export const initialState: TYPES.DownloadState = {
  fetchStatus: FETCH_STATUSES.IDLE,
  error: null,
  link: '',
  progress: {
    fetchStatus: FETCH_STATUSES.IDLE,
    error: null,
  },
}

export const download = createReducer<TYPES.DownloadState, Actions>(initialState, {
  [ACTIONS.fetchDownloadContractTypes.request]: (state) => ({
    ...state,
    fetchStatus: FETCH_STATUSES.REQUEST,
    link: initialState.link,
  }),
  [ACTIONS.fetchDownloadContractTypes.success]: (state, action) => ({
    ...state,
    fetchStatus: FETCH_STATUSES.SUCCESS,
    link: (action as TYPES.FetchDownloadContractSuccessAction).payload.link,
  }),
  [ACTIONS.fetchDownloadContractTypes.failure]: (state, action) => ({
    ...state,
    fetchStatus: FETCH_STATUSES.FAILURE,
    error: (action as FetchFailureAction).payload,
  }),
  [ACTIONS.subscribeOnDownloadProgressTypes.request]: (state) => ({
    ...state,
    progress: {
      ...state.progress,
      fetchStatus: FETCH_STATUSES.REQUEST,
    },
  }),
  [ACTIONS.subscribeOnDownloadProgressTypes.success]: (state) => ({
    ...state,
    progress: {
      ...state.progress,
      fetchStatus: FETCH_STATUSES.SUCCESS,
    },
  }),
  [ACTIONS.subscribeOnDownloadProgressTypes.failure]: (state, action) => ({
    ...state,
    progress: {
      ...state.progress,
      fetchStatus: FETCH_STATUSES.FAILURE,
      error: (action as FetchFailureAction).payload,
    },
  }),
  [TYPES.DownloadActions.UNSUBSCRIBE_ON_DOWNLOAD_PROGRESS]: (state) => ({
    ...state,
    progress: initialState.progress,
  }),
  [TYPES.DownloadActions.CLEAN_DOWNLOAD]: () => ({ ...initialState }),
})
