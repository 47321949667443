import { SignatureMode } from '../../../../CreateSignatureModal.types'

export const DESCRIPTIONS: { [key: string]: string } = {
  [SignatureMode.both]: 'CREATE_SIGNATURE_DESCRIPTION',
  [SignatureMode.signature]: 'CREATE_ONLY_SIGNATURE_DESCRIPTION',
  [SignatureMode.initials]: 'CREATE_ONLY_INITIALS_DESCRIPTION',
}

export const TITLES: { [key: string]: string } = {
  [SignatureMode.both]: 'CREATE_SIGNATURE',
  [SignatureMode.signature]: 'CREATE_NEW_SIGNATURE',
  [SignatureMode.initials]: 'CREATE_NEW_INITIALS',
}
