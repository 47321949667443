export const roundNumber = (num: number, decimal = 0) => Number(num.toFixed(decimal))

export const getNumberLimitedByRange = (value: number, min: number, max: number) => {
  if (value < min) {
    return roundNumber(min, 2)
  }
  if (value > max) {
    return roundNumber(max, 2)
  }
  return roundNumber(value, 2)
}
