import { Action } from 'redux'

import { FetchFailurePayload, FETCH_STATUSES } from '../../../types/fetch'

export interface NoneVerificationState {
  fetchStatus: FETCH_STATUSES
  error: FetchFailurePayload | null
}

export enum NoneVerificationActions {
  'FETCH_NONE_VERIFICATION' = 'FETCH_NONE_VERIFICATION',
  'CLEAN_NONE_VERIFICATION' = 'CLEAN_NONE_VERIFICATION',
}

export interface FetchNoneVerification extends Action {
  payload: { contractId: string; onSuccess?: () => void }
}
export type FetchNoneVerificationSuccess = Action
