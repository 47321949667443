import { FetchFailureAction, FETCH_STATUSES } from '../../../types/fetch'
import { createReducer } from '../../createReducer'

import * as TYPES from './duplicate.types'
import * as ACTIONS from './duplicate.actions'

type Actions = FetchFailureAction

export const initialState: TYPES.DuplicateState = {
  fetchStatus: FETCH_STATUSES.IDLE,
  error: null,
}

export const duplicate = createReducer<TYPES.DuplicateState, Actions>(initialState, {
  [ACTIONS.fetchDuplicateTemplateTypes.request]: (state) => ({
    ...state,
    fetchStatus: FETCH_STATUSES.REQUEST,
  }),
  [ACTIONS.fetchDuplicateTemplateTypes.success]: (state) => ({
    ...state,
    fetchStatus: FETCH_STATUSES.SUCCESS,
    error: null,
  }),
  [ACTIONS.fetchDuplicateTemplateTypes.failure]: (state, action) => ({
    ...state,
    fetchStatus: FETCH_STATUSES.FAILURE,
    error: (action as FetchFailureAction).payload,
  }),
  [TYPES.DuplicateActions.CLEAR_DUPLICATE_TEMPLATE]: () => ({ ...initialState }),
})
