import { FormikHandlers } from 'formik'
import { ReactNode } from 'react'

import * as colors from '../../../themes/colors'
import { Size } from '../SvgIcon/SvgIcon.types'

export enum TEXT_INPUT_TYPES {
  TEXT = 'text',
  PASSWORD = 'password',
  TEXTAREA = 'textarea',
}

export interface TextInputCommonProps {
  name?: string
  value: string
  placeholder?: string
  label?: string
  error?: string
  onChange?: FormikHandlers['handleChange']
  disabled?: boolean
  maxLength?: number
  searchable?: boolean
  noError?: boolean
  readOnly?: boolean
  prefix?: string | JSX.Element
  note?: ReactNode
  help?: ReactNode
  trim?: boolean
}

export interface TextInputProps extends TextInputCommonProps {
  icon?: string
  iconColor?: keyof typeof colors
  iconSize?: Size
  iconViewBox?: string
  flipIcon?: boolean
  onClickIcon?: () => void
  type?: TEXT_INPUT_TYPES
  autoComplete?: 'on' | 'off' | 'new-password'
  onClick?: () => void
  onFocus?: () => void
  ref?: React.MutableRefObject<HTMLInputElement | null>
}
