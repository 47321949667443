export interface EditProfileFormBody {
  [key: string]: string
  firstName: string
  lastName: string
  industry: string
  company: string
  job: string
  address: string
  city: string
  region: string
  postalCode: string
  country: string
}

export enum EditProfileFields {
  firstName = 'firstName',
  lastName = 'lastName',
  industry = 'industry',
  company = 'company',
  job = 'job',
  address = 'address',
  city = 'city',
  region = 'region',
  postalCode = 'postalCode',
  country = 'country',
  email = 'email',
  phone = 'phone',
}

export interface EditProfileUnconfirmedEmailResponse {
  email?: string
}

export interface EditProfileInitialDataResponse extends EditProfileFormBody {
  email: string
  phone: string
}
