import { FetchFailureAction, FETCH_STATUSES } from '../../../types/fetch'
import { createReducer } from '../../createReducer'

import * as TYPES from './payment.types'
import * as ACTIONS from './payment.actions'

type Actions = FetchFailureAction | TYPES.FetchFormFieldsSuccessAction | TYPES.FetchRegisterCardSuccessAction

export const initialState: TYPES.PaymentState = {
  form: {
    fetchStatus: FETCH_STATUSES.IDLE,
    error: null,
    fields: null,
    refetch: true,
  },
  registerCard: {
    fetchStatus: FETCH_STATUSES.IDLE,
    error: null,
  },
  process: {
    processing: false,
    cardVerified: false,
    error: null,
  },
  activate: {
    fetchStatus: FETCH_STATUSES.IDLE,
    error: null,
    trial: false,
  },
  threeDSecureUrl: null,
}

export const payment = createReducer<TYPES.PaymentState, Actions>(initialState, {
  [ACTIONS.fetchPaymentFormFieldsTypes.request]: (state) => ({
    ...state,
    form: {
      ...state.form,
      fetchStatus: FETCH_STATUSES.REQUEST,
    },
  }),
  [ACTIONS.fetchPaymentFormFieldsTypes.success]: (state, action) => ({
    ...state,
    form: {
      ...state.form,
      refetch: false,
      fetchStatus: FETCH_STATUSES.SUCCESS,
      fields: (action as TYPES.FetchFormFieldsSuccessAction).payload.fields,
    },
  }),
  [ACTIONS.fetchPaymentFormFieldsTypes.failure]: (state, action) => ({
    ...state,
    form: {
      ...state.form,
      fetchStatus: FETCH_STATUSES.FAILURE,
      error: (action as FetchFailureAction).payload,
    },
  }),
  [ACTIONS.fetchRegisterCardTypes.request]: (state) => ({
    ...state,
    registerCard: {
      ...state.registerCard,
      fetchStatus: FETCH_STATUSES.REQUEST,
    },
    process: {
      ...state.process,
      processing: true,
      error: null,
    },
  }),
  [ACTIONS.fetchRegisterCardTypes.success]: (state, action) => ({
    ...state,
    registerCard: {
      ...state.registerCard,
      fetchStatus: FETCH_STATUSES.SUCCESS,
    },
    threeDSecureUrl: (action as TYPES.FetchRegisterCardSuccessAction).payload.threeDSecureUrl,
  }),
  [ACTIONS.fetchRegisterCardTypes.failure]: (state, action) => ({
    ...state,
    registerCard: {
      ...state.registerCard,
      fetchStatus: FETCH_STATUSES.FAILURE,
      error: (action as FetchFailureAction).payload,
    },
  }),
  [TYPES.PaymentActions.CLEAN_SUBSCRIPTION_PAYMENT]: () => ({ ...initialState }),
  [TYPES.PaymentActions.SET_SUBSCRIPTION_PAYMENT_ERROR]: (state, action) => ({
    ...state,
    form: initialState.form,
    registerCard: initialState.registerCard,
    process: {
      ...initialState.process,
      error: (action as FetchFailureAction).payload,
    },
  }),
  [TYPES.PaymentActions.VERIFY_SUBSCRIPTION_PAYMENT_CARD]: (state) => ({
    ...state,
    process: {
      ...state.process,
      cardVerified: true,
    },
  }),
  [TYPES.PaymentActions.CLEAN_SUBSCRIPTION_PAYMENT_FORM]: (state) => ({
    ...state,
    form: initialState.form,
  }),
  [ACTIONS.fetchActivateSubscriptionTypes.request]: (state) => ({
    ...state,
    activate: {
      ...state.activate,
      fetchStatus: FETCH_STATUSES.REQUEST,
    },
    process: {
      ...state.process,
      processing: true,
      error: null,
    },
  }),
  [ACTIONS.fetchActivateSubscriptionTypes.success]: (state, action) => ({
    ...state,
    activate: {
      ...state.activate,
      fetchStatus: FETCH_STATUSES.SUCCESS,
    },
    threeDSecureUrl: (action as TYPES.FetchActivateSubscriptionSuccessAction).payload.threeDSecureUrl,
  }),
  [ACTIONS.fetchActivateSubscriptionTypes.failure]: (state, action) => ({
    ...state,
    activate: {
      ...state.activate,
      fetchStatus: FETCH_STATUSES.FAILURE,
      error: (action as FetchFailureAction).payload,
    },
  }),
})
