import { createFetchActionTypes } from '../../../utils/createFetchActionTypes'
import { FetchFailureAction, FetchFailurePayload } from '../../../types/fetch'
import { RecipientsById } from '../../../types/recipients'
import { EditableElem } from '../../../types/editableElem'
import { PlacementBody, PlacementById, PlacementByPages, PlacementSorted } from '../../../types/placement'

import * as TYPES from './placement.types'

export const fetchPlacementTypes = createFetchActionTypes(TYPES.PlacementActions.FETCH_PLACEMENT)
export const fetchUpdatePlacementTypes = createFetchActionTypes(TYPES.PlacementActions.FETCH_UPDATE_PLACEMENT)
export const fetchGeneratePlacementTypes = createFetchActionTypes(TYPES.PlacementActions.FETCH_GENERATE_PLACEMENT)
export const fetchSendContractTypes = createFetchActionTypes(TYPES.PlacementActions.FETCH_SEND_CONTRACT)
export const fetchContractStatusTypes = createFetchActionTypes(TYPES.PlacementActions.FETCH_CONTRACT_STATUS)

export const clearPlacement = (): TYPES.ClearPlacementAction => ({
  type: TYPES.PlacementActions.CLEAR_PLACEMENT,
})
export const clearPlacementStatus = (): TYPES.ClearPlacementStatusAction => ({
  type: TYPES.PlacementActions.CLEAR_PLACEMENT_STATUS,
})

export const fetchPlacement = (contractId: string, recipientsById: RecipientsById): TYPES.FetchPlacementAction => ({
  type: fetchPlacementTypes.request,
  payload: { contractId, recipientsById },
})
export const fetchPlacementSuccess = (
  byId: PlacementById,
  byPages: PlacementByPages,
  sorted: PlacementSorted
): TYPES.FetchPlacementSuccessAction => ({
  type: fetchPlacementTypes.success,
  payload: { byId, byPages, sorted },
})
export const fetchPlacementFailure = (payload: FetchFailurePayload): FetchFailureAction => ({
  type: fetchPlacementTypes.failure,
  payload,
})

export const fetchUpdatePlacement = (
  place: EditableElem,
  byId: PlacementById,
  sorted: PlacementSorted
): TYPES.FetchUpdatePlacementAction => ({
  type: fetchUpdatePlacementTypes.request,
  payload: { place, byId, sorted },
})
export const fetchUpdatePlacementSuccess = (response: PlacementBody): TYPES.FetchUpdatePlacementSuccessAction => ({
  type: fetchUpdatePlacementTypes.success,
  payload: response,
})
export const fetchUpdatePlacementFailure = (payload: FetchFailurePayload): FetchFailureAction => ({
  type: fetchUpdatePlacementTypes.failure,
  payload,
})

export const fetchGeneratePlacement = (contractId: string, isMobile?: boolean): TYPES.FetchGeneratePlacementAction => ({
  type: fetchGeneratePlacementTypes.request,
  payload: { contractId, isMobile },
})
export const fetchGeneratePlacementSuccess = (): TYPES.FetchGeneratePlacementSuccessAction => ({
  type: fetchGeneratePlacementTypes.success,
})
export const fetchGeneratePlacementFailure = (payload: FetchFailurePayload): FetchFailureAction => ({
  type: fetchGeneratePlacementTypes.failure,
  payload,
})

export const fetchSendContract = (contractId: string): TYPES.FetchSendContractAction => ({
  type: fetchSendContractTypes.request,
  payload: { contractId },
})
export const fetchSendContractSuccess = (): TYPES.FetchSendContractSuccessAction => ({
  type: fetchSendContractTypes.success,
})
export const fetchSendContractFailure = (payload: FetchFailurePayload): FetchFailureAction => ({
  type: fetchSendContractTypes.failure,
  payload,
})

export const fetchContractStatus = (contractId: string): TYPES.FetchContractStatusAction => ({
  type: fetchContractStatusTypes.request,
  payload: { contractId },
})
export const fetchContractStatusSuccess = (response : {status: string}): TYPES.FetchContractStatusSuccessAction => ({
  type: fetchContractStatusTypes.success,
  payload: response
})

export const fetchContractStatusFailure = (payload: FetchFailurePayload): FetchFailureAction => ({
  type: fetchContractStatusTypes.failure,
  payload,
})


export const clearSendContract = () => ({
  type: TYPES.PlacementActions.CLEAR_SEND_CONTRACT,
})

export const setShouldRegenerate = (shouldRegenerate: boolean): TYPES.SetShouldRegenerateAction => ({
  type: TYPES.PlacementActions.SET_SHOULD_REGENERATE,
  payload: { shouldRegenerate },
})
