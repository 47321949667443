import { ChangePasswordRequestBody } from '../../types/changePassword'
import { FetchFailureAction, FetchFailurePayload } from '../../types/fetch'
import { createFetchActionTypes } from '../../utils/createFetchActionTypes'

import * as TYPES from './changePassword.types'

export const fetchChangePasswordTypes = createFetchActionTypes(TYPES.ChangePasswordActions.FETCH_CHANGE_PASSWORD)

export const resetChangePassword = () => ({
  type: TYPES.ChangePasswordActions.RESET_CHANGE_PASSWORD,
})

export const fetchChangePassword = (body: ChangePasswordRequestBody): TYPES.FetchChangePasswordAction => ({
  type: fetchChangePasswordTypes.request,
  payload: { body },
})

export const fetchChangePasswordSuccess = (): TYPES.FetchChangePasswordSuccessAction => ({
  type: fetchChangePasswordTypes.success,
})

export const fetchChangePasswordFailure = (payload: FetchFailurePayload): FetchFailureAction => ({
  type: fetchChangePasswordTypes.failure,
  payload,
})
