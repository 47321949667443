import { combineEpics, Epic, ofType } from 'redux-observable'
import { catchError, map, mergeMap, takeUntil } from 'rxjs/operators'

import { catchFetchError } from '../../../utils/catchFetchError'
import { templateService } from '../../../api'

import * as ACTIONS from './briefList.actions'

export const fetchBriefList: Epic = (action$) =>
  action$.pipe(
    ofType(ACTIONS.fetchBriefListTypes.request),
    mergeMap(() =>
      templateService.fetchTemplatesListBrief().pipe(
        map((response) => ACTIONS.fetchBriefListSuccess(response.map(({ id, name }) => ({ name, value: id })))),
        catchError(catchFetchError(ACTIONS.fetchBriefListFailure)),
        takeUntil(action$.pipe(ofType(ACTIONS.fetchBriefListTypes.success, ACTIONS.fetchBriefListTypes.failure)))
      )
    )
  )

export const briefListEpics = combineEpics(fetchBriefList)
