import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useSubscribeOnPayment } from '../../../hooks/sockets/useSubscribeOnPayment'
import { usePaymentFieldsFetch } from '../../../hooks/subscription/usePaymentFieldsFetch'
import * as PAYMENT_SELECTORS from '../../../store/subscription/payment/payment.selectors'
import * as USER_PLAN_ACTIONS from '../../../store/subscription/userPlan/userPlan.actions'
import { onFetchError } from '../../utils/fetch'

import CardIFrame from './components/CardIFrame'
import { PaymentCardProps } from './PaymentCard.types'

export const PaymentCard: React.FC<PaymentCardProps> = ({ handleTokenizationCompleted, Loader, handleVerifyCard }) => {
  const dispatch = useDispatch()
  const openWindowRef = useRef<Window | null>(null)

  const cardVerified = useSelector(PAYMENT_SELECTORS.subscriptionPaymentCardVerifiedSelector)
  const processError = useSelector(PAYMENT_SELECTORS.subscriptionPaymentProcessErrorSelector)
  const paymentProcessing = useSelector(PAYMENT_SELECTORS.subscriptionPaymentProcessingSelector)
  const formIsLoading = useSelector(PAYMENT_SELECTORS.subscriptionPaymentFormIsLoadingSelector)
  const threeDSecureUrl = useSelector(PAYMENT_SELECTORS.subscriptionPaymentThreeDSecureUrlSelector)

  useEffect(() => {
    if (threeDSecureUrl) {
      openWindowRef.current = window.open(threeDSecureUrl, '_blank')
    }
  }, [threeDSecureUrl])

  useEffect(() => {
    if (cardVerified) {
      openWindowRef.current?.close()
    }
  }, [cardVerified])

  useEffect(() => {
    if (cardVerified) {
      handleVerifyCard()
      dispatch(USER_PLAN_ACTIONS.fetchUserPlan())
    }
  }, [cardVerified, handleVerifyCard])
  useEffect(() => {
    if (processError) {
      dispatch(onFetchError(processError.errorMessage))
    }
  }, [processError])
  usePaymentFieldsFetch()
  useSubscribeOnPayment({ handleTokenizationCompleted })

  if (formIsLoading || cardVerified) {
    return null
  }

  if (paymentProcessing) {
    return <Loader />
  }

  return <CardIFrame />
}
