import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { FormikProps } from 'formik'
import { useTranslation } from 'react-i18next'

import translations from '../../../translations/keys'
import * as SELECTORS from '../../../store/contracts/recipients/recipients.selectors'
import * as ACTIONS from '../../../store/contracts/recipients/recipients.actions'
import { setShouldRegenerate } from '../../../store/contracts/placement/placement.actions'
import { openModal } from '../../../store/modals/modals.actions'
import { useShowFetchError } from '../../hooks/useShowFetchError'
import { useDispatchUnmount } from '../../../hooks/useDispatchUnmount'
import { useHistoryPushAfterSuccess } from '../../hooks/useHistoryPushAfterSuccess'
import { AddRecipientsForm } from '../../../types/recipients'
import { FETCH_STATUSES } from '../../../types/fetch'
import { SIGNING_TYPES } from '../../../types/signingOrder'
import { ContractRouteParams } from '../../types/signing'
import { location } from '../../../utils/location'
import { removeExtension } from '../../../utils/file/removeExtension'
import { prepareAllRecipientsForSubmit, validateActions } from '../../../utils/recipients'
import { checkIfPlacementShouldRegen } from '../../../utils/placement'
import { COMMON_MODALS_IDS } from '../../ui/Modals/CommonModals/CommonModals.constants'
import { NOTIFICATION_MODAL_TYPES } from '../../ui/Modals/NotificationModal/NotificationModal.constants'
import Typography from '../../ui/Typography'
import { TYPOGRAPHY_NAMES } from '../../ui/Typography/Typography.types'
import Box from '../../ui/Box'
import { BUTTON_VARIANTS } from '../../ui/Button/Button.types'
import FullScreenTemplate from '../../components/Main/FullScreenTemplate'
import { PreviewHeader } from '../../components/PreviewHeader/PreviewHeader'
import { EyeIcon } from '../../ui/InputIcons/EyeIcon'
import { FormRecipients } from '../../ui/Forms/FormRecipients/FormRecipients'
import { ChangeSigningOrder } from '../../components/ChangeSigningOrder/ChangeSigningOrder'
import { FileIcon, PlayIcon } from '../../assets/icons'
import { CONTRACTS_MODALS } from '../../../constants/contracts'
import { useAccessFeature } from '../../../hooks/subscription/useAccessFeature'
import { SubscriptionFeatures } from '../../../types/subscription'

import Modals from './components/Modals'

const SINGLE_RECIPIENT = 1

export const ContractAddRecipients = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const history = useHistory()
  const { contractId } = useParams<ContractRouteParams>()
  const [nextPage, setNextPage] = useState('')
  const contractName = useSelector(SELECTORS.recipientsContractNameSelector)
  const byId = useSelector(SELECTORS.recipientsByIdSelector)
  const sorted = useSelector(SELECTORS.recipientsSortedSelector)
  const order = useSelector(SELECTORS.recipientsOrderSelector)
  const fetch = useSelector(SELECTORS.recipientsFetchSelector)
  const fetchAdd = useSelector(SELECTORS.recipientsFetchAddSelector)
  const formRef: React.Ref<FormikProps<AddRecipientsForm>> = useRef(null)
  const recipientsList = useMemo(() => Object.values(byId), [sorted])
  const { checkAccess } = useAccessFeature()

  const loading = sorted.length === 0 && fetch.status === FETCH_STATUSES.REQUEST

  const handleSubmit = (values: AddRecipientsForm) => {
    // TODO: remove when API is ready
    if (checkIfPlacementShouldRegen(values.recipients, byId)) {
      dispatch(setShouldRegenerate(true))
    }
    dispatch(ACTIONS.fetchAddRecipients(contractId, prepareAllRecipientsForSubmit(values, order)))
  }

  const actions = [
    {
      buttonIcon: 'eye',
      label: translations.VIEW_DOCUMENT,
      MobileIcon: EyeIcon,
      onClick: () => {
        history.push(location.contractsPreviewUrl(contractId))
      },
    },
    {
      buttonIcon: 'file',
      label: translations.CONTRACT_SAVE_AS_TEMPLATE,
      MobileIcon: FileIcon,
      onClick: () => {
        if (checkAccess(SubscriptionFeatures.FEATURE_USER_TEMPLATES)) {
          dispatch(openModal(CONTRACTS_MODALS.SAVE_CONTRACT_AS_TEMPLATE, { id: contractId, oldName: contractName }))
        }
      },
    },
  ]

  const buttons = [
    {
      label: translations.SAVE_AND_CLOSE,
      onClick: () => {
        setNextPage(location.contractsAllUrl())
        formRef.current?.handleSubmit()
      },
      //TODO uqd-555 change icon
      MobileIcon: PlayIcon,
      variant: BUTTON_VARIANTS.SECONDARY,
    },
    {
      label: translations.NEXT,
      onClick: () => {
        const form = formRef.current

        if (!form) {
          return null
        }

        const recipientsLength = form.values.recipients.length

        if (
          recipientsLength === SINGLE_RECIPIENT ||
          (recipientsLength > SINGLE_RECIPIENT && checkAccess(SubscriptionFeatures.FEATURE_UNLIMITED_BULK_SENDING))
        ) {
          const errorCode = validateActions(form.values.recipients)

          if (errorCode) {
            dispatch(
              openModal(COMMON_MODALS_IDS.NOTIFICATION, {
                type: NOTIFICATION_MODAL_TYPES.WARNING,
                description: t(translations[errorCode]),
              })
            )
          } else {
            setNextPage(location.contractsRecipientsDiagramUrl(contractId))
            form.handleSubmit()
          }
        }
      },
      //TODO uqd-555 change icon
      MobileIcon: PlayIcon,
    },
  ]

  const handleOrderChange = (order: string) => {
    dispatch(ACTIONS.setOrder(order as SIGNING_TYPES))
  }

  useShowFetchError(fetchAdd.status, fetchAdd.error)
  useHistoryPushAfterSuccess(nextPage, fetchAdd.status)

  useEffect(() => {
    dispatch(ACTIONS.fetchContractInfo(contractId))
    dispatch(ACTIONS.fetchRecipients(contractId))
  }, [])

  useDispatchUnmount(ACTIONS.clearRecipients)

  if (loading) {
    return null
  }

  return (
    <FullScreenTemplate
      headerContent={<PreviewHeader actions={actions} buttons={buttons} title={removeExtension(contractName || '')} />}
    >
      <Box padding={4}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography name={TYPOGRAPHY_NAMES.H1}>{t(translations.ADD_RECIPIENTS_TITLE)}</Typography>
          <ChangeSigningOrder order={order as string} onChange={handleOrderChange} />
        </Box>
        <Box mt={3} mb={3}>
          <Typography name={TYPOGRAPHY_NAMES.H3}>{t(translations.RECIPIENTS)}</Typography>
        </Box>
        <FormRecipients
          ref={formRef}
          recipients={recipientsList}
          disableOrder={order === SIGNING_TYPES.SEPARATE}
          onSubmit={handleSubmit}
        />
      </Box>
      <Modals />
    </FullScreenTemplate>
  )
}
