import { DRAFTS_VALUE } from '../../../constants/documents'
import { ContractsCategory } from '../../../types/contracts'
import { prepareContractsFetchParams } from '../../../utils/contracts/prepareContractsFetchParams'
import { contractsInitialState } from '../contracts.initialState'

import { ContractsListState } from './contractsList.types'

export const prepareContractsOffset = (state: ContractsListState) => {
  const { data, offset, limit } = state

  // If there's only one contact left on a page then go to a previus one if it exists
  const correctedOffset = offset > 0 && data.length === 1 ? offset - limit : offset

  return correctedOffset
}

export const prepareContractsListFetch = (
  { sorting, data, folderId, searchText, filters, isAppliedFilters }: ContractsListState,
  category?: ContractsCategory
) => {
  const methods = prepareContractsFetchParams(searchText, folderId, isAppliedFilters, filters, category)
  const {
    contractsList: { limit },
  } = contractsInitialState

  return {
    orderByField: sorting.field,
    order: sorting.direction,
    offset: 0,
    limit: data.length < limit ? limit : data.length,
    folderId: methods.getFolderId(),
    status: methods.getStatus(),
    received: methods.getReceived(),
    owner: methods.getOwner(),
    signed: methods.getSigned(),
  }
}
export const prepareFolderId = (folderId: string) => (folderId === DRAFTS_VALUE ? null : folderId)
