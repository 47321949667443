import { FetchFailureAction, FETCH_STATUSES } from '../../../types/fetch'
import { createReducer } from '../../createReducer'

import * as ACTIONS from './upload.actions'
import * as TYPES from './upload.types'

type Actions = FetchFailureAction | TYPES.FetchUploadTemplateSuccess

export const initialState: TYPES.UploadState = {
  id: null,
  fetchStatus: FETCH_STATUSES.IDLE,
  error: null,
}

export const upload = createReducer<TYPES.UploadState, Actions>(initialState, {
  [ACTIONS.fetchUploadTemplateTypes.request]: (state) => ({
    ...state,
    fetchStatus: FETCH_STATUSES.REQUEST,
  }),
  [ACTIONS.fetchUploadTemplateTypes.success]: (state, action) => ({
    ...state,
    id: (action as TYPES.FetchUploadTemplateSuccess).payload.id,
    fetchStatus: FETCH_STATUSES.SUCCESS,
  }),
  [ACTIONS.fetchUploadTemplateTypes.failure]: (state, action) => ({
    ...state,
    id: null,
    fetchStatus: FETCH_STATUSES.FAILURE,
    error: (action as FetchFailureAction).payload,
  }),
  [TYPES.UploadActions.CLEAN_UPLOAD_TEMPLATE]: () => ({ ...initialState }),
})
