import { useLocation } from 'react-router-dom'

const useLocationInfo = () => {
  const { pathname } = useLocation()

  const [, section = null] = pathname.split('/')

  return { section, pathname }
}

export default useLocationInfo
