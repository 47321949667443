import { Epic, combineEpics, ofType } from 'redux-observable'
import { concat, of } from 'rxjs'
import { catchError, debounceTime, map, mergeMap, takeUntil } from 'rxjs/operators'

import { contactsService } from '../../api'
import { catchFetchError } from '../../utils/catchFetchError'
import { addPhonePlus } from '../../utils/phone/addPhonePlus'
import { prepareFormRequestBody } from '../../utils/formik/prepareFormRequestBody'

import * as ACTIONS from './contacts.actions'
import { prepareContact, prepareFetchContactsRequest, prepareMobileFetchContactsRequest } from './contacts.utils'

const FETCH_DEBOUNCE_TIME = 500

const fetchContacts: Epic = (action$) =>
  action$.pipe(
    ofType(ACTIONS.fetchContactsTypes.request),
    debounceTime(FETCH_DEBOUNCE_TIME),
    mergeMap(({ payload }) =>
      contactsService.fetchContacts(payload.params).pipe(
        map((response) => ACTIONS.fetchContactsSuccess(response)),
        catchError(catchFetchError(ACTIONS.fetchContactsFailure)),
        takeUntil(action$.pipe(ofType(ACTIONS.fetchContactsTypes.success, ACTIONS.fetchContactsTypes.failure)))
      )
    )
  )

const fetchCreateContact: Epic = (action$) =>
  action$.pipe(
    ofType(ACTIONS.fetchCreateContactTypes.request),
    mergeMap(({ payload }) =>
      contactsService
        .fetchCreateContact(
          prepareFormRequestBody({
            ...payload.body,
            phone: payload.body.phone && addPhonePlus(payload.body.phone),
          })
        )
        .pipe(
          map((ajaxResponse) => ACTIONS.fetchCreateContactSuccess(prepareContact(ajaxResponse.response))),
          catchError(catchFetchError(ACTIONS.fetchCreateContactFailure)),
          takeUntil(
            action$.pipe(ofType(ACTIONS.fetchCreateContactTypes.success, ACTIONS.fetchCreateContactTypes.failure))
          )
        )
    )
  )

const fetchUpdateContact: Epic = (action$) =>
  action$.pipe(
    ofType(ACTIONS.fetchUpdateContactTypes.request),
    mergeMap(({ payload }) =>
      contactsService
        .fetchUpdateContact(
          payload.id,
          prepareFormRequestBody({
            ...payload.body,
            phone: payload.body.phone && addPhonePlus(payload.body.phone),
          })
        )
        .pipe(
          map((ajaxResponse) => ACTIONS.fetchUpdateContactSuccess(prepareContact(ajaxResponse.response))),
          catchError(catchFetchError(ACTIONS.fetchUpdateContactFailure)),
          takeUntil(
            action$.pipe(ofType(ACTIONS.fetchUpdateContactTypes.success, ACTIONS.fetchUpdateContactTypes.failure))
          )
        )
    )
  )

const fetchDeleteContact: Epic = (action$, state$) =>
  action$.pipe(
    ofType(ACTIONS.fetchDeleteContactTypes.request),
    mergeMap(({ payload }) =>
      concat(
        contactsService.fetchDeleteContact(payload.id).pipe(
          map(() => ACTIONS.fetchDeleteContactSuccess(payload.id)),
          catchError(catchFetchError(ACTIONS.fetchDeleteContactFailure)),
          takeUntil(
            action$.pipe(ofType(ACTIONS.fetchDeleteContactTypes.success, ACTIONS.fetchDeleteContactTypes.failure))
          )
        ),
        payload.isMobile
          ? of(
              ACTIONS.setOffset(state$.value.contacts.offset),
              ACTIONS.fetchContacts(prepareMobileFetchContactsRequest(state$.value.contacts))
            )
          : of(ACTIONS.fetchContacts(prepareFetchContactsRequest(state$.value.contacts)))
      )
    )
  )

export const contactsEpics = combineEpics(fetchContacts, fetchCreateContact, fetchUpdateContact, fetchDeleteContact)
