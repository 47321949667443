import { FETCH_STATUSES } from '../../../types/fetch'
import { RootState } from '../../rootReducer.types'

export const templatesFoldersDataSelector = (state: RootState) => state.templates.folders.data
export const templatesFoldersIsLoadingSelector = (state: RootState) =>
  state.templates.folders.fetchStatus === FETCH_STATUSES.REQUEST ||
  state.templates.folders.fetchStatus === FETCH_STATUSES.IDLE
export const templatesFoldersIsSuccessSelector = (state: RootState) =>
  state.templates.folders.fetchStatus === FETCH_STATUSES.SUCCESS
export const templatesLastAddedFolderSelector = (state: RootState) => state.templates.folders.lastAddedFolder
export const templatesFoldersDataErrorSelector = (state: RootState) => state.templates.folders.error
export const templatesFoldersDataFetchStatusSelector = (state: RootState) => state.templates.folders.fetchStatus

export const templatesUploadFolderIsSuccessSelector = (state: RootState) =>
  state.templates.folders.upload.fetchStatus === FETCH_STATUSES.SUCCESS
export const templatesUploadFolderFetchStatusSelector = (state: RootState) => state.templates.folders.upload.fetchStatus
export const templatesUploadFolderDataErrorSelector = (state: RootState) => state.templates.folders.upload.error
