import React from 'react'
import { useTranslation } from 'react-i18next'

import translations from '../../../../../translations/keys'
import { SignatureSvg } from '../../../../assets/SignatureSvg'
import Button from '../../../../ui/Button'
import Box from '../../../../ui/Box'

import { StyledAddSignature, StyledWrapper } from './SignaturesEmpty.styles'
import { SignaturesEmptyProps } from './SignaturesEmpty.types'

export const SignaturesEmpty: React.FC<SignaturesEmptyProps> = ({ onAddSignature }) => {
  const { t } = useTranslation()
  return (
    <StyledWrapper>
      <SignatureSvg />
      <Box mt={5} mb={5} textAlign="center">
        <p>{t(translations.NO_SIGNATURES)}</p>
        <p>{t(translations.LIMIT_SIGNATURES)}</p>
      </Box>
      <StyledAddSignature>
        <Button fullWidth onClick={onAddSignature}>
          {t(translations.ADD_SIGNATURE)}
        </Button>
      </StyledAddSignature>
    </StyledWrapper>
  )
}
