const translationKeys: { [key: string]: string } = {
  WARNING: 'WARNING',
  SUCCESS: 'SUCCESS',
  INFO: 'INFO',
  ARE_YOU_SURE: 'ARE_YOU_SURE',
  OK: 'OK',
  GENERATE: 'GENERATE',
  TERMS_OF_USE: 'TERMS_OF_USE',
  AND: 'AND',
  PRIVACY_POLICY: 'PRIVACY_POLICY',
  SIGN_UP: 'SIGN_UP',
  LOG_IN: 'LOG_IN',
  LOGOUT: 'LOGOUT',
  UQOUD: 'UQOUD',
  OPEN_IN_UQOUD_APP_BUTTON: 'OPEN_IN_UQOUD_APP_BUTTON',
  OPEN_IN_UQOUD_APP: 'OPEN_IN_UQOUD_APP',
  LEGAL_NOTICE: 'LEGAL_NOTICE',
  LANGUAGE: 'LANGUAGE',
  COPYRIGHT: 'COPYRIGHT',
  PASSWORD_LABEL: 'PASSWORD_LABEL',
  PASSWORD_PLACEHOLDER: 'PASSWORD_PLACEHOLDER',
  LOGIN_REMEMBER_ME: 'LOGIN_REMEMBER_ME',
  LOGIN_FORGOT_PASSWORD: 'LOGIN_FORGOT_PASSWORD',
  LOGIN_NO_ACCOUNT: 'LOGIN_NO_ACCOUNT',
  LOGIN_SIGN_UP_NOW: 'LOGIN_SIGN_UP_NOW',
  REGISTRATION_TITLE: 'REGISTRATION_TITLE',
  FIRST_NAME_LABEL: 'FIRST_NAME_LABEL',
  FIRST_NAME_PLACEHOLDER: 'FIRST_NAME_PLACEHOLDER',
  LAST_NAME_LABEL: 'LAST_NAME_LABEL',
  TOKEN_HAS_BEEN_EXPIRED: 'TOKEN_HAS_BEEN_EXPIRED',
  LAST_NAME_PLACEHOLDER: 'LAST_NAME_PLACEHOLDER',
  COUNTRY_LABEL: 'COUNTRY_LABEL',
  COUNTRY_PLACEHOLDER: 'COUNTRY_PLACEHOLDER',
  INDUSTRY_LABEL: 'INDUSTRY_LABEL',
  INDUSTRY_PLACEHOLDER: 'INDUSTRY_PLACEHOLDER',
  EMAIL_LABEL: 'EMAIL_LABEL',
  EMAIL_YOUR_PLACEHOLDER: 'EMAIL_YOUR_PLACEHOLDER',
  PHONE_LABEL: 'PHONE_LABEL',
  REGISTRATION_PASSWORD_PLACEHOLDER: 'REGISTRATION_PASSWORD_PLACEHOLDER',
  CONFIRM_PASSWORD_LABEL: 'CONFIRM_PASSWORD_LABEL',
  API_INTEGRATION: 'API_INTEGRATION',
  PUBLIC_TOKEN_LABEL: 'PUBLIC_TOKEN_LABEL',
  API_INTEGRATION_DESCRIPTION: 'API_INTEGRATION_DESCRIPTION',
  GO_TO_API_DESCRIPTION: 'GO_TO_API_DESCRIPTION',
  CONFIRM_GENERATE_MODAL_DESCRIPTION: 'CONFIRM_GENERATE_MODAL_DESCRIPTION',
  GENERATE_NEW: 'GENERATE_NEW',
  CLEAR_AND_CONTINUE: 'CLEAR_AND_CONTINUE',
  TOKEN_COPIED: 'TOKEN_COPIED',
  REGISTRATION_CONFIRM_PASSWORD_PLACEHOLDER: 'REGISTRATION_CONFIRM_PASSWORD_PLACEHOLDER',
  PASSWORD_VALIDATION_INFO: 'PASSWORD_VALIDATION_INFO',
  REGISTRATION_ACCEPT: 'REGISTRATION_ACCEPT',
  REGISTRATION_HAVE_ACCOUNT: 'REGISTRATION_HAVE_ACCOUNT',
  SOME_ERROR_OCCURRED: 'SOME_ERROR_OCCURRED',
  INVALID_EMAIL: 'INVALID_EMAIL',
  EMAIL_REQUIRED: 'EMAIL_REQUIRED',
  EMAIL_EXIST: 'EMAIL_EXIST',
  CONTACT_EMAIL_EXIST: 'CONTACT_EMAIL_EXIST',
  CONTACT_EXIST: 'CONTACT_EXIST',
  LONG_EMAIL: 'LONG_EMAIL',
  LONG_NAME: 'LONG_NAME',
  LONG_PASSWORD: 'LONG_PASSWORD',
  PASSWORD_INVALID: 'PASSWORD_INVALID',
  PASSWORD_REQUIRED: 'PASSWORD_REQUIRED',
  PASSWORDS_DO_NOT_MATCH: 'PASSWORDS_DO_NOT_MATCH',
  INVALID_REQUEST_BODY: 'INVALID_REQUEST_BODY',
  INCORRECT_DATA_ENTERED: 'INCORRECT_DATA_ENTERED',
  INVALID_PHONE: 'INVALID_PHONE',
  SELECT_COUNTRY: 'SELECT_COUNTRY',
  SELECT_INDUSTRY: 'SELECT_INDUSTRY',
  SMTP_IS_NOT_ACTIVE: 'SMTP_IS_NOT_ACTIVE',
  SOMETHING_WRONG: 'SOMETHING_WRONG',
  PLEASE_ACCEPT_TERMS: 'PLEASE_ACCEPT_TERMS',
  CONFIRM_EMAIL_TITLE: 'CONFIRM_EMAIL_TITLE',
  CONFIRM_EMAIL_DESCRIPTION: 'CONFIRM_EMAIL_DESCRIPTION',
  CONFIRM_EDIT_EMAIL_DESCRIPTION: 'CONFIRM_EDIT_EMAIL_DESCRIPTION',
  CONFIRM_PHONE_TITLE: 'CONFIRM_PHONE_TITLE',
  CONFIRM_PHONE_DESCRIPTION: 'CONFIRM_PHONE_DESCRIPTION',
  CONFIRM_PHONE_CODE_INPUT_LABEL: 'CONFIRM_PHONE_CODE_INPUT_LABEL',
  CONFIRM_PHONE_RESEND_BUTTON: 'CONFIRM_PHONE_RESEND_BUTTON',
  INCORRECT_CODE: 'INCORRECT_CODE',
  PHONE_REQUIRED: 'PHONE_REQUIRED',
  BACK_TO_LOG_IN: 'BACK_TO_LOG_IN',
  BACK_TO_PROFILE: 'BACK_TO_PROFILE',
  FORGOT_PASSWORD: 'FORGOT_PASSWORD',
  FORGOT_PASSWORD_DESCRIPTION: 'FORGOT_PASSWORD_DESCRIPTION',
  FORGOT_PASSWORD_EMAIL_PLACEHOLDER: 'FORGOT_PASSWORD_EMAIL_PLACEHOLDER',
  RESET_PASSWORD: 'RESET_PASSWORD',
  CONTINUE: 'CONTINUE',
  CHECK_EMAIL_TITLE: 'CHECK_EMAIL_TITLE',
  CHECK_EMAIL_DESCRIPTION: 'CHECK_EMAIL_DESCRIPTION',
  RESEND_NOTIFICATION: 'RESEND_NOTIFICATION',
  RESTORE_PASSWORD_TITLE: 'RESTORE_PASSWORD_TITLE',
  RESTORE_CONFIRM_PASSWORD_PLACEHOLDER: 'RESTORE_CONFIRM_PASSWORD_PLACEHOLDER',
  RESTORE_PASSWORD_PLACEHOLDER: 'RESTORE_PASSWORD_PLACEHOLDER',
  RESTORE_PASSWORD_SUCCESS: 'RESTORE_PASSWORD_SUCCESS',
  EXPIRED_LINK_MODAL_TITLE: 'EXPIRED_LINK_MODAL_TITLE',
  EXPIRED_LINK_MODAL_DESCRIPTION: 'EXPIRED_LINK_MODAL_DESCRIPTION',
  BACK_TO_SIGNUP: 'BACK_TO_SIGNUP',
  REQUIRED_FIELD: 'REQUIRED_FIELD',
  RESEND_CODE: 'RESEND_CODE',
  BASE_URL_NOT_EXIST: 'BASE_URL_NOT_EXIST',
  EDIT_EMAIL: 'EDIT_EMAIL',
  PHONE: 'PHONE',
  EDIT_PROFILE_PHONE_EDIT: 'EDIT_PROFILE_PHONE_EDIT',
  PERSONAL_DATA: 'PERSONAL_DATA',
  JOB_DATA: 'JOB_DATA',
  COMPANY_LABEL: 'COMPANY_LABEL',
  COMPANY_PLACEHOLDER: 'COMPANY_PLACEHOLDER',
  JOB_TITLE_LABEL: 'JOB_TITLE_LABEL',
  JOB_TITLE_PLACEHOLDER: 'JOB_TITLE_PLACEHOLDER',
  ADDRESS: 'ADDRESS',
  ADDRESS_PLACEHOLDER: 'ADDRESS_PLACEHOLDER',
  CITY_LABEL: 'CITY_LABEL',
  CITY_PLACEHOLDER: 'CITY_PLACEHOLDER',
  REGION_LABEL: 'REGION_LABEL',
  REGION_PLACEHOLDER: 'REGION_PLACEHOLDER',
  POSTAL_CODE_LABEL: 'POSTAL_CODE_LABEL',
  POSTAL_CODE_PLACEHOLDER: 'POSTAL_CODE_PLACEHOLDER',
  UPDATE: 'UPDATE',
  USER_NOT_EXISTS: 'USER_NOT_EXISTS',
  WRONG_CREDENTIALS: 'WRONG_CREDENTIALS',
  VERIFY_EMAIL_FIRST: 'VERIFY_EMAIL_FIRST',
  RESEND_EMAIL_DESCRIPTION: 'RESEND_EMAIL_DESCRIPTION',
  RESEND_EMAIL_SUCCESS: 'RESEND_EMAIL_SUCCESS',
  CONFIRMED_EMAIL: 'CONFIRMED_EMAIL',
  CONFIRMED_NEW_EMAIL: 'CONFIRMED_NEW_EMAIL',
  PASSWORD_REQUIREMENTS: 'PASSWORD_REQUIREMENTS',
  PASSWORD_CONTAIN: 'PASSWORD_CONTAIN',
  ONLY_LATIN_LETTERS: 'ONLY_LATIN_LETTERS',
  EIGHT_OR_MORE_SYMBOLS: 'EIGHT_OR_MORE_SYMBOLS',
  AT_LEAST_ONE: 'AT_LEAST_ONE',
  DIGIT: 'DIGIT',
  SPECIAL_CHARACTER: 'SPECIAL_CHARACTER',
  UPPERCASE_LETTER: 'UPPERCASE_LETTER',
  LOWERCASE_LETTER: 'LOWERCASE_LETTER',
  CURRENT_PASSWORD_LABEL: 'CURRENT_PASSWORD_LABEL',
  CURRENT_PASSWORD_PLACEHOLDER: 'CURRENT_PASSWORD_PLACEHOLDER',
  NEW_PASSWORD_LABEL: 'NEW_PASSWORD_LABEL',
  NEW_PASSWORD_PLACEHOLDER: 'NEW_PASSWORD_PLACEHOLDER',
  CONFIRM_NEW_PASSWORD: 'CONFIRM_NEW_PASSWORD',
  SAVE: 'SAVE',
  INCORRECT_CURRENT_PASSWORD: 'INCORRECT_CURRENT_PASSWORD',
  PASSWORD_CHANGED_SUCCESSFULLY: 'PASSWORD_CHANGED_SUCCESSFULLY',
  PHONE_CHANGED_SUCCESSFULLY: 'PHONE_CHANGED_SUCCESSFULLY',
  TOKEN_GENERATED_SUCCESSFULLY: 'TOKEN_GENERATED_SUCCESSFULLY',
  CHANGE_PHONE_NUMBER_TITLE: 'CHANGE_PHONE_NUMBER_TITLE',
  CHANGE_PHONE_NUMBER_DESCRIPTION: 'CHANGE_PHONE_NUMBER_DESCRIPTION',
  SEND_CODE: 'SEND_CODE',
  CHANGE_EMAIL_TITLE: 'CHANGE_EMAIL_TITLE',
  CHANGE_EMAIL_DESCRIPTION: 'CHANGE_EMAIL_DESCRIPTION',
  CHANGE_EMAIL_SUCCESS: 'CHANGE_EMAIL_SUCCESS',
  CHANGE_EMAIL_LABEL: 'CHANGE_EMAIL_LABEL',
  CHANGE_EMAIL_PLACEHOLDER: 'CHANGE_EMAIL_PLACEHOLDER',
  CHANGE_EMAIL_BUTTON: 'CHANGE_EMAIL_BUTTON',
  VERIFY_ACCOUNT_EMAIL_FIRST: 'VERIFY_ACCOUNT_EMAIL_FIRST',
  FILLED_INVALID_EMAIL: 'FILLED_INVALID_EMAIL',
  WAITING_FOR_CONFIRMATION: 'WAITING_FOR_CONFIRMATION',
  RESEND_VERIFICATION: 'RESEND_VERIFICATION',
  CANCEL_CHANGES: 'CANCEL_CHANGES',
  DELETE_CONTACT: 'DELETE_CONTACT',
  DELETE_CONTACT_DESCRIPTION: 'DELETE_CONTACT_DESCRIPTION',
  CANCEL: 'CANCEL',
  ADD_NEW_CONTACT: 'ADD_NEW_CONTACT',
  ADD_ONE_MORE: 'ADD_ONE_MORE',
  ADD_CONTACT: 'ADD_CONTACT',
  SEARCH_CONTACT: 'SEARCH_CONTACT',
  CONTACT_SAVED: 'CONTACT_SAVED',
  CONTACT_CREATED: 'CONTACT_CREATED',
  CONTACT_DELETED: 'CONTACT_DELETED',
  CONTACTS_PLACEHOLDER_TEXT: 'CONTACTS_PLACEHOLDER_TEXT',
  CONTACTS_EMPTY_RESULTS: 'CONTACTS_EMPTY_RESULTS',
  CONTACTS_OPEN: 'CONTACTS_OPEN',
  CONTACTS_RENAME: 'CONTACTS_RENAME',
  CONTACTS_VIEW_HISTORY: 'CONTACTS_VIEW_HISTORY',
  CONTACTS_MOVE_TO: 'CONTACTS_MOVE_TO',
  CONTACTS_DELETE: 'CONTACTS_DELETE',
  FULL_NAME_LABEL: 'FULL_NAME_LABEL',
  FULL_NAME_PLACEHOLDER: 'FULL_NAME_PLACEHOLDER',
  EMAIL_PLACEHOLDER: 'EMAIL_PLACEHOLDER',
  HIDE_BUTTON: 'HIDE_BUTTON',
  MAIN_MENU_DASHBOARD_LINK: 'MAIN_MENU_DASHBOARD_LINK',
  MAIN_MENU_CONTRACTS_LINK: 'MAIN_MENU_CONTRACTS_LINK',
  SIDEBAR_EKYC_LINK: 'SIDEBAR_EKYC_LINK',
  MAIN_MENU_TEMPLATES_LINK: 'MAIN_MENU_TEMPLATES_LINK',
  SIDEBAR_EDIT_PROFILE_LINK: 'SIDEBAR_EDIT_PROFILE_LINK',
  SIDEBAR_CHANGE_PASSWORD_LINK: 'SIDEBAR_CHANGE_PASSWORD_LINK',
  SIDEBAR_SIGNATURE_INITIALS_LINK: 'SIDEBAR_SIGNATURE_INITIALS_LINK',
  SIDEBAR_CONTACTS_LINK: 'SIDEBAR_CONTACTS_LINK',
  SIDEBAR_DOCUMENT_BRANDING_LINK: 'SIDEBAR_DOCUMENT_BRANDING_LINK',
  SIDEBAR_PAYMENT_LINK: 'SIDEBAR_PAYMENT_LINK',
  SIDEBAR_SUBSCRIPTION_LINK: 'SIDEBAR_SUBSCRIPTION_LINK',
  TERMS_AND_CONDITIONS: 'TERMS_AND_CONDITIONS',
  SIDEBAR_PRIVACY_LINK: 'SIDEBAR_PRIVACY_LINK',
  SIDEBAR_CONTRACTS_ALL: 'SIDEBAR_CONTRACTS_ALL',
  SIDEBAR_CONTRACTS_RECEIVED: 'SIDEBAR_CONTRACTS_RECEIVED',
  SIDEBAR_CONTRACTS_SENT: 'SIDEBAR_CONTRACTS_SENT',
  SIDEBAR_CONTRACTS_DRAFTS: 'SIDEBAR_CONTRACTS_DRAFTS',
  SIDEBAR_CONTRACTS_DELETED: 'SIDEBAR_CONTRACTS_DELETED',
  SIDEBAR_API_INTEGRATION_LINK: 'SIDEBAR_API_INTEGRATION_LINK',
  USER_MENU_MANAGE_PROFILE_LINK: 'USER_MENU_MANAGE_PROFILE_LINK',
  USER_MENU_PREFERENCES_LINK: 'USER_MENU_PREFERENCES_LINK',
  USER_MENU_ANALYTICS_LINK: 'USER_MENU_ANALYTICS_LINK',
  CHANGES_SAVED_SUCCESSFULLY: 'CHANGES_SAVED_SUCCESSFULLY',
  SAVE_CHANGES_BEFORE_LEAVING: 'SAVE_CHANGES_BEFORE_LEAVING',
  YES: 'YES',
  NO: 'NO',
  NAME_NOTE: 'NAME_NOTE',
  UPGRADE: 'UPGRADE',
  SUPPORT: 'SUPPORT',
  ENTER_YOUR_PASSWORD_TITLE: 'ENTER_YOUR_PASSWORD_TITLE',
  ENTER_YOUR_PASSWORD_DESCRIPTION: 'ENTER_YOUR_PASSWORD_DESCRIPTION',
  ENTER: 'ENTER',
  SENT_NEW_VERIFICATION_TO_EMAIL: 'SENT_NEW_VERIFICATION_TO_EMAIL',
  NEW_EMAIL_VERIFIED_SUCCESSFULLY: 'NEW_EMAIL_VERIFIED_SUCCESSFULLY',
  EXPIRED_NEW_EMAIL_VERIFICATION_LINK: 'EXPIRED_NEW_EMAIL_VERIFICATION_LINK',
  ERROR: 'ERROR',
  RESEND_EMAIL: 'RESEND_EMAIL',
  CANCEL_CHANGES_DESCRIPTION: 'CANCEL_CHANGES_DESCRIPTION',
  CHANGING_EMAIL_CANCELED: 'CHANGING_EMAIL_CANCELED',
  NO_CONTACTS: 'NO_CONTACTS',
  PLEASE_ENTER_PASSWORD: 'PLEASE_ENTER_PASSWORD',
  ACCESS_TO_SIGNATURE_DESCRIPTION: 'ACCESS_TO_SIGNATURE_DESCRIPTION',
  PASSWORD_INVALID_TRY_AGAIN: 'PASSWORD_INVALID_TRY_AGAIN',
  ENTER_PASSWORD: 'ENTER_PASSWORD',
  CONFIRM_ACCOUNT: 'CONFIRM_ACCOUNT',
  INVALID_CODE_OR_NUMBER: 'INVALID_CODE_OR_NUMBER',
  REACHED_MAX_NUMBER_OF_SIGNATURES: 'REACHED_MAX_NUMBER_OF_SIGNATURES',
  ADD_SIGNATURE: 'ADD_SIGNATURE',
  ADD_ONE_MORE_SIGNATURE: 'ADD_ONE_MORE_SIGNATURE',
  CLICK_TO_SEE_SIGNATURE: 'CLICK_TO_SEE_SIGNATURE',
  CLICK_TO_SEE_INITIALS: 'CLICK_TO_SEE_INITIALS',
  SHOW_SIGNATURE: 'SHOW_SIGNATURE',
  SHOW_INITIALS: 'SHOW_INITIALS',
  CLICK_UNBLUR: 'CLICK_UNBLUR',
  NO_SIGNATURES: 'NO_SIGNATURES',
  LIMIT_SIGNATURES: 'LIMIT_SIGNATURES',
  EDIT_PROFILE: 'EDIT_PROFILE',
  CHANGE_PASSWORD: 'CHANGE_PASSWORD',
  CONTACTS: 'CONTACTS',
  SIGNATURE_AND_INITIALS: 'SIGNATURE_AND_INITIALS',
  CREATE_SIGNATURE: 'CREATE_SIGNATURE',
  APPLY: 'APPLY',
  CLEAR: 'CLEAR',
  PREVIOUS: 'PREVIOUS',
  NEXT: 'NEXT',
  CLEAR_ALL: 'CLEAR_ALL',
  MORE: 'MORE',
  NOTIFICATIONS: 'NOTIFICATIONS',
  NOTIFICATIONS_SETTINGS: 'NOTIFICATIONS_SETTINGS',
  EDIT: 'EDIT',
  DELETE: 'DELETE',
  CREATE_SIGNATURE_DESCRIPTION: 'CREATE_SIGNATURE_DESCRIPTION',
  CREATE_DYNAMIC_SIGNATURE_DESCRIPTION_1: 'CREATE_DYNAMIC_SIGNATURE_DESCRIPTION_1',
  CREATE_DYNAMIC_SIGNATURE_DESCRIPTION_2: 'CREATE_DYNAMIC_SIGNATURE_DESCRIPTION_2',
  CREATE_STATIC_SIGNATURE_DESCRIPTION: 'CREATE_STATIC_SIGNATURE_DESCRIPTION',
  SIGNATURE: 'SIGNATURE',
  INITIALS: 'INITIALS',
  DRAW_SIGNATURE: 'DRAW_SIGNATURE',
  DRAW_SIGNATURE_MANY_TIMES: 'DRAW_SIGNATURE_MANY_TIMES',
  DRAW_SIGNATURE_HERE: 'DRAW_SIGNATURE_HERE',
  DRAW_INITIALS_HERE: 'DRAW_INITIALS_HERE',
  DRAW_INITIALS: 'DRAW_INITIALS',
  DRAW_INITIALS_ONLY_ONES: 'DRAW_INITIALS_ONLY_ONES',
  SUBMIT: 'SUBMIT',
  CHANGE: 'CHANGE',
  ADD_INITIALS: 'ADD_INITIALS',
  PREVIEW: 'PREVIEW',
  ENTER_YOUR_PASSWORD_TO_CONTINUE: 'ENTER_YOUR_PASSWORD_TO_CONTINUE',
  SIGNATURE_ADDED: 'SIGNATURE_ADDED',
  SIGNATURE_DELETE_CONFIRMATION: 'SIGNATURE_DELETE_CONFIRMATION',
  SIGNATURE_DELETED: 'SIGNATURE_DELETED',
  ADD_NEW_SIGNATURE: 'ADD_NEW_SIGNATURE',
  ADD_NEW_SIGNATURE_DESCRIPTION: 'ADD_NEW_SIGNATURE_DESCRIPTION',
  PASSWORD_INCORRECT_AGAIN: 'PASSWORD_INCORRECT_AGAIN',
  EXPIRED_VERIFICATION_LINK: 'EXPIRED_VERIFICATION_LINK',
  NAME: 'NAME',
  EDIT_CONTACT: 'EDIT_CONTACT',
  UPLOAD_FILE: 'UPLOAD_FILE',
  DROP_FILE_HERE: 'DROP_FILE_HERE',
  OR: 'OR',
  BROWSE_FILES: 'BROWSE_FILES',
  UPLOAD_CONTACT_FORMATS: 'UPLOAD_CONTACT_FORMATS',
  UPLOAD_CONTACT_SIZE: 'UPLOAD_CONTACT_SIZE',
  FILE_MAX_SIZE: 'FILE_MAX_SIZE',
  FILE_WRONG_FORMAT: 'FILE_WRONG_FORMAT',
  UPLOAD_TO: 'UPLOAD_TO',
  FOLDER_LABEL: 'FOLDER_LABEL',
  CREATE_NEW_FOLDER: 'CREATE_NEW_FOLDER',
  UPLOAD: 'UPLOAD',
  CREATE_FOLDER: 'CREATE_FOLDER',
  CREATE: 'CREATE',
  FOLDER_NAME_LABEL: 'FOLDER_NAME_LABEL',
  FOLDER_NAME_PLACEHOLDER: 'FOLDER_NAME_PLACEHOLDER',
  INCORRECT_FILE_NAME: 'INCORRECT_FILE_NAME',
  INCORRECT_FOLDER_NAME: 'INCORRECT_FOLDER_NAME',
  ADD_NEW_CONTRACT: 'ADD_NEW_CONTRACT',
  ADD_FOLDER: 'ADD_FOLDER',
  CONTRACTS_PLACEHOLDER_TEXT: 'CONTRACTS_PLACEHOLDER_TEXT',
  CONTRACTS_EMPTY_RESULTS: 'CONTRACTS_EMPTY_RESULTS',
  ADD_CONTRACT: 'ADD_CONTRACT',
  UPLOAD_FROM_COMPUTER: 'UPLOAD_FROM_COMPUTER',
  UPLOAD_FROM_TEMPLATE: 'UPLOAD_FROM_TEMPLATE',
  UPLOAD_FROM_GOOGLE: 'UPLOAD_FROM_GOOGLE',
  UPLOAD_FROM_ONE_DRIVE: 'UPLOAD_FROM_ONE_DRIVE',
  MORE_TIMES: 'MORE_TIMES',
  MORE_DYNAMIC_SIGNATURES: 'MORE_DYNAMIC_SIGNATURES',
  DYNAMIC_SIGNATURES_INVALID: 'DYNAMIC_SIGNATURES_INVALID',
  FOLDER_EXISTS_ERROR: 'FOLDER_EXISTS_ERROR',
  DOCUMENT_UPLOADED_SUCCESS: 'DOCUMENT_UPLOADED_SUCCESS',
  CONTRACT_ASSIGN_TO_SELF: 'CONTRACT_ASSIGN_TO_SELF',
  CONTRACT_SAVE_AS_TEMPLATE: 'CONTRACT_SAVE_AS_TEMPLATE',
  CONTRACT_DELETE_AND_CLOSE: 'CONTRACT_DELETE_AND_CLOSE',
  CONTRACT_SAVE_AND_CLOSE: 'CONTRACT_SAVE_AND_CLOSE',
  PROCEED_TO_CLM: 'PROCEED_TO_CLM',
  CONTRACT_START: 'CONTRACT_START',
  SELECT_SIGNATURE_SEQUENCE: 'SELECT_SIGNATURE_SEQUENCE',
  SEQUENTIAL_SIGNING: 'SEQUENTIAL_SIGNING',
  SEPARATELY_SIGNING: 'SEPARATELY_SIGNING',
  ADD_RECIPIENTS_TITLE: 'ADD_RECIPIENTS_TITLE',
  RECIPIENTS: 'RECIPIENTS',
  VIEW_AND_SEND: 'VIEW_AND_SEND',
  SAVE_AND_CLOSE: 'SAVE_AND_CLOSE',
  VIEW_DOCUMENT: 'VIEW_DOCUMENT',
  RECIPIENT_NAME_LABEL: 'RECIPIENT_NAME_LABEL',
  RECIPIENT_NAME_PLACEHOLDER: 'RECIPIENT_NAME_PLACEHOLDER',
  RECIPIENT_EMAIL_LABEL: 'RECIPIENT_EMAIL_LABEL',
  RECIPIENT_EMAIL_PLACEHOLDER: 'RECIPIENT_EMAIL_PLACEHOLDER',
  ADD_RECIPIENT: 'ADD_RECIPIENT',
  ADD_RECIPIENTS: 'ADD_RECIPIENTS',
  DOCUMENT_WITH_NAME_EXIST: 'DOCUMENT_WITH_NAME_EXIST',
  DOCUMENT_NAME_INVALID: 'DOCUMENT_NAME_INVALID',
  SIGNATURE_MODAL_FINISHED: 'SIGNATURE_MODAL_FINISHED',
  SIGNATURE_INITIALS_MODAL_FINISHED: 'SIGNATURE_INITIALS_MODAL_FINISHED',
  SIGNATURE_MODAL_YES: 'SIGNATURE_MODAL_YES',
  SIGNATURE_MODAL_NO: 'SIGNATURE_MODAL_NO',
  CHOOSE_CONTACT: 'CHOOSE_CONTACT',
  SEARCH: 'SEARCH',
  SEND_CONTRACT: 'SEND_CONTRACT',
  SENDER: 'SENDER',
  HEAD_OF_PRODUCT: 'HEAD_OF_PRODUCT',
  VIEW: 'VIEW',
  INITIAL: 'INITIAL',
  SIGN: 'SIGN',
  APPROVAL_CYCLE: 'APPROVAL_CYCLE',
  APPROVAL_CYCLES: 'APPROVAL_CYCLES',
  COMPLETED: 'COMPLETED',
  CONTRACT_DEADLINE: 'CONTRACT_DEADLINE',
  FINAL_SLA: 'FINAL_SLA',
  MESSAGE_TO_RECIPIENTS: 'MESSAGE_TO_RECIPIENTS',
  TITLE: 'TITLE',
  MESSAGE: 'MESSAGE',
  CUSTOM_SUBSCRIPTION_REQUEST_MESSAGE_SENT: 'CUSTOM_SUBSCRIPTION_REQUEST_MESSAGE_SENT',
  CONTACT_US_TITLE: 'CONTACT_US_TITLE',
  BACK_TO_ADD_RECIPIENTS: 'BACK_TO_ADD_RECIPIENTS',
  DELETE_RECIPIENT: 'DELETE_RECIPIENT',
  ARE_SURE: 'ARE_SURE',
  SHOULD_BE_DELETED: 'SHOULD_BE_DELETED',
  DELETE_RECIPIENT_DESCRIPTION: 'DELETE_RECIPIENT_DESCRIPTION',
  DELETE_CONTRACT_DESCRIPTION: 'DELETE_CONTRACT_DESCRIPTION',
  SHOW_INFO: 'SHOW_INFO',
  HIDE_INFO: 'HIDE_INFO',
  DELETE_OWN_CONTRACT_DESCRIPTION: 'DELETE_OWN_CONTRACT_DESCRIPTION',
  DELETE_DRAFT_CONTRACT_DESCRIPTION: 'DELETE_DRAFT_CONTRACT_DESCRIPTION',
  DELETE_FOLDER_DESCRIPTION: 'DELETE_FOLDER_DESCRIPTION',
  RENAME_FILE: 'RENAME_FILE',
  RENAME_FOLDER: 'RENAME_FOLDER',
  RENAME: 'RENAME',
  DOCUMENT_NAME_PLACEHOLDER: 'DOCUMENT_NAME_PLACEHOLDER',
  MOVE_TO_FOLDER: 'MOVE_TO_FOLDER',
  MOVE: 'MOVE',
  SELECT_FOLDER_LABEL: 'SELECT_FOLDER_LABEL',
  DUPLICATE_FILE: 'DUPLICATE_FILE',
  CONTRACT_DUPLICATE_FILE_DESCRIPTION: 'CONTRACT_DUPLICATE_FILE_DESCRIPTION',
  DUPLICATE: 'DUPLICATE',
  SELECT_FOLDER_ERROR: 'SELECT_FOLDER_ERROR',
  CHOOSE_FOLDER: 'CHOOSE_FOLDER',
  CONTRACT_EXISTS: 'CONTRACT_EXISTS',
  DEPARTMENT_LABEL: 'DEPARTMENT_LABEL',
  DEPARTMENT_PLACEHOLDER: 'DEPARTMENT_PLACEHOLDER',
  OPTIONAL: 'OPTIONAL',
  PHONE_NUMBER: 'PHONE_NUMBER',
  ADD_ANOTHER: 'ADD_ANOTHER',
  CHOOSE_ACTION: 'CHOOSE_ACTION',
  CYCLE_ORDER: 'CYCLE_ORDER',
  CHANGE_ORDER: 'CHANGE_ORDER',
  DEADLINE_SLA_LABEL: 'DEADLINE_SLA_LABEL',
  DEADLINE_SLA_PLACEHOLDER: 'DEADLINE_SLA_PLACEHOLDER',
  SIGNATURE_FIELD: 'SIGNATURE_FIELD',
  INITIALS_FIELD: 'INITIALS_FIELD',
  PAGE: 'PAGE',
  LAST_PAGE: 'LAST_PAGE',
  EVERY_PAGE: 'EVERY_PAGE',
  AUTHENTICATION: 'AUTHENTICATION',
  EDIT_RECIPIENT: 'EDIT_RECIPIENT',
  DOCUMENT_EXTENSIONS_INVALID: 'DOCUMENT_EXTENSIONS_INVALID',
  RECIPIENTS_EMAIL_FROM_CONTACT: 'RECIPIENTS_EMAIL_FROM_CONTACT',
  RECIPIENTS_DEPARTMENT_FROM_CONTACT: 'RECIPIENTS_DEPARTMENT_FROM_CONTACT',
  RECIPIENTS_PHONE_FROM_CONTACT: 'RECIPIENTS_PHONE_FROM_CONTACT',
  RECIPIENTS_MAXIMUM_REACHED: 'RECIPIENTS_MAXIMUM_REACHED',
  RECIPIENTS_EMAIL_CONTACT: 'RECIPIENTS_EMAIL_CONTACT',
  RECIPIENTS_MAX_SIGNATURES_EXCEEDED: 'RECIPIENTS_MAX_SIGNATURES_EXCEEDED',
  RECIPIENTS_MAX_INITIALS_EXCEEDED: 'RECIPIENTS_MAX_INITIALS_EXCEEDED',
  RECIPIENTS_EMAIL_NOT_UNIQUE: 'RECIPIENTS_EMAIL_NOT_UNIQUE',
  INVALID_CYCLE_ORDER: 'INVALID_CYCLE_ORDER',
  INCORRECT_CYCLE_ORDER: 'INCORRECT_CYCLE_ORDER',
  RECIPIENT_EMAIL_EXIST: 'RECIPIENT_EMAIL_EXIST',
  CYCLE_ORDER_MIN: 'CYCLE_ORDER_MIN',
  CYCLE_ORDER_MAX: 'CYCLE_ORDER_MAX',
  CYCLE_ORDER_REQUIRED: 'CYCLE_ORDER_REQUIRED',
  OPEN: 'OPEN',
  VIEW_HISTORY: 'VIEW_HISTORY',
  HISTORY: 'HISTORY',
  IP: 'IP',
  RESEND: 'RESEND',
  VIEW_INFO: 'VIEW_INFO',
  VOID: 'VOID',
  FILTERS: 'FILTERS',
  RESET_ALL: 'RESET_ALL',
  STATUS: 'STATUS',
  PENDING_SIGN: 'PENDING_SIGN',
  EXPIRING_SOON: 'EXPIRING_SOON',
  SIGNED: 'SIGNED',
  WAITING_FOR_SIGN: 'WAITING_FOR_SIGN',
  REQUIRED_TO_VIEW: 'REQUIRED_TO_VIEW',
  DATE_ADDED: 'DATE_ADDED',
  FROM: 'FROM',
  TO: 'TO',
  CHOOSE: 'CHOOSE',
  PROGRESS: 'PROGRESS',
  CREATED_BY: 'CREATED_BY',
  UPDATED_BY: 'UPDATED_BY',
  SENT: 'SENT',
  COMMENTED: 'COMMENTED',
  VOIDED: 'VOIDED',
  DELETED: 'DELETED',
  EXPIRED: 'EXPIRED',
  DATE_CREATED: 'DATE_CREATED',
  RESET: 'RESET',
  DRAFT: 'DRAFT',
  CREATED_BY_SPACE: 'CREATED_BY_SPACE',
  CONTRACTS_SEARCH_INFO: 'CONTRACTS_SEARCH_INFO',
  LAST_MODIFIED_BY: 'LAST_MODIFIED_BY',
  CREATED: 'CREATED',
  UPDATED: 'UPDATED',
  CONTRACT_DELETED: 'CONTRACT_DELETED',
  FOLDER_DELETED: 'FOLDER_DELETED',
  NAME_CHANGED: 'NAME_CHANGED',
  CONTRACT_REUPLOAD: 'CONTRACT_REUPLOAD',
  DOCUMENT_REUPLOAD: 'DOCUMENT_REUPLOAD',
  CONTRACT_FINISH_LATER: 'CONTRACT_FINISH_LATER',
  SIGNATURE_BOX_TEXT: 'SIGNATURE_BOX_TEXT',
  ME: 'ME',
  CONTRACT_MOVED_TO_FOLDER: 'CONTRACT_MOVED_TO_FOLDER',
  NO_CONTRACTS: 'NO_CONTRACTS',
  CONTRACT_DUPLICATED: 'CONTRACT_DUPLICATED',
  SIGNERS: 'SIGNERS',
  BACK: 'BACK',
  MODIFIED: 'MODIFIED',
  EMPTY_FOLDER: 'EMPTY_FOLDER',
  FOLDER_ALREADY_EXIST: 'FOLDER_ALREADY_EXIST',
  CHOOSE_INITIALS: 'CHOOSE_INITIALS',
  CHOOSE_SIGNATURE: 'CHOOSE_SIGNATURE',
  SELECT_DOCUMENT_INITIALS: 'SELECT_DOCUMENT_INITIALS',
  SELECT_DOCUMENT_SIGNATURE: 'SELECT_DOCUMENT_SIGNATURE',
  SELECTION_APPLIED_NOTE: 'SELECTION_APPLIED_NOTE',
  MANAGE_SIGNATURE: 'MANAGE_SIGNATURE',
  FOLDER_UPLOADED: 'FOLDER_UPLOADED',
  COMMENT: 'COMMENT',
  COMMENTS: 'COMMENTS',
  CROSSOVER: 'CROSSOVER',
  NO_SIGNATURE_IN_ACCOUNT: 'NO_SIGNATURE_IN_ACCOUNT',
  NEW_TO_CREATE_SIGNATURE: 'NEW_TO_CREATE_SIGNATURE',
  ADD_SIGNATURES: 'ADD_SIGNATURES',
  SEND_CONTRACT_TITLE: 'SEND_CONTRACT_TITLE',
  SEND_CONTRACT_DESCRIPTION: 'SEND_CONTRACT_DESCRIPTION',
  SEND_CONTRACT_DESCRIPTION_MOBILE: 'SEND_CONTRACT_DESCRIPTION_MOBILE',
  SEND: 'SEND',
  CONTRACT_SENT_SUCCESSFULLY: 'CONTRACT_SENT_SUCCESSFULLY',
  DECLINE_TO_SIGN: 'DECLINE_TO_SIGN',
  DOWNLOAD: 'DOWNLOAD',
  FINISH_CONTRACT_LATER: 'FINISH_CONTRACT_LATER',
  FINISH_CONTRACT_ACTIONS_LATER: 'FINISH_CONTRACT_ACTIONS_LATER',
  CHANGES_WILL_NOT_BE_SAVED: 'CHANGES_WILL_NOT_BE_SAVED',
  NOT_SIGNED_YET: 'NOT_SIGNED_YET',
  NOT_INITIALED_YET: 'NOT_INITIALED_YET',
  SEND_CONTRACT_TO_RECIPIENTS: 'SEND_CONTRACT_TO_RECIPIENTS',
  ADD_SIGNATURE_HERE: 'ADD_SIGNATURE_HERE',
  NO_DATA: 'NO_DATA',
  PENDING_RECIPIENTS: 'PENDING_RECIPIENTS',
  READY_TO_SIGN: 'READY_TO_SIGN',
  VERIFICATION_FAILED: 'VERIFICATION_FAILED',
  CONTRACT_READY_TO_SEND: 'CONTRACT_READY_TO_SEND',
  DECLINE_SIGNING_TITLE: 'DECLINE_SIGNING_TITLE',
  DECLINE_REASON_LABEL: 'DECLINE_REASON_LABEL',
  DECLINE_REASON_PLACEHOLDER: 'DECLINE_REASON_PLACEHOLDER',
  DECLINE: 'DECLINE',
  EXPLAIN_DECLINE: 'EXPLAIN_DECLINE',
  CONTRACT_DECLINED: 'CONTRACT_DECLINED',
  DECLINED: 'DECLINED',
  SIGNATURE_CHANGED: 'SIGNATURE_CHANGED',
  INITIALS_CHANGED: 'INITIALS_CHANGED',
  CYCLE_ORDER_HELP: 'CYCLE_ORDER_HELP',
  DEADLINE_SLA_HELP: 'DEADLINE_SLA_HELP',
  EMPTY_RECIPIENTS: 'EMPTY_RECIPIENTS',
  GRID: 'GRID',
  DISPLAY_NAMES: 'DISPLAY_NAMES',
  GROUP_EDIT: 'GROUP_EDIT',
  APPLY_TO_ALL_PAGES: 'APPLY_TO_ALL_PAGES',
  UNDO: 'UNDO',
  REDO: 'REDO',
  PLACEMENT_RESET_CONFIRMATION: 'PLACEMENT_RESET_CONFIRMATION',
  HELP: 'HELP',
  DOCUMENT_NOT_READY_FOR_DOWNLOAD: 'DOCUMENT_NOT_READY_FOR_DOWNLOAD',
  SOCKET_CONNECT_ERROR: 'SOCKET_CONNECT_ERROR',
  PREPARING_FILE: 'PREPARING_FILE',
  WAIT_PREPARING_FILE: 'WAIT_PREPARING_FILE',
  STOP_SIGNING: 'STOP_SIGNING',
  DELETE_CORRECTIONS: 'DELETE_CORRECTIONS',
  SIGNING_STOPPED: 'SIGNING_STOPPED',
  EDIT_FLOW: 'EDIT_FLOW',
  REQUEST_TO_VIEW: 'REQUEST_TO_VIEW',
  SIGNING_FINISHED: 'SIGNING_FINISHED',
  SIDEBAR_TEMPLATES_ALL: 'SIDEBAR_TEMPLATES_ALL',
  SIDEBAR_TEMPLATES_STARRED: 'SIDEBAR_TEMPLATES_STARRED',
  SIDEBAR_TEMPLATES_DOCUMENTS: 'SIDEBAR_TEMPLATES_DOCUMENTS',
  SIDEBAR_TEMPLATES_PROCEDURES: 'SIDEBAR_TEMPLATES_PROCEDURES',
  SIDEBAR_TEMPLATES_DELETED: 'SIDEBAR_TEMPLATES_DELETED',
  REUPLOAD: 'REUPLOAD',
  REUPLOAD_DOCUMENT: 'REUPLOAD_DOCUMENT',
  REUPLOAD_DOCUMENT_CONFIRMATION: 'REUPLOAD_DOCUMENT_CONFIRMATION',
  TEMPLATES_EMPTY_RESULTS: 'TEMPLATES_EMPTY_RESULTS',
  CLM: 'CLM',
  DOCUMENT: 'DOCUMENT',
  DOCUMENT_CLM: 'DOCUMENT_CLM',
  CREATE_TEMPLATE: 'CREATE_TEMPLATE',
  TYPE: 'TYPE',
  UPLOAD_DOCUMENT: 'UPLOAD_DOCUMENT',
  CLM_WITHOUT_DOCUMENT: 'CLM_WITHOUT_DOCUMENT',
  LOAD_EXISTING_CONTRACT: 'LOAD_EXISTING_CONTRACT',
  MARK_AS_STARRED: 'MARK_AS_STARRED',
  UNMARK_AS_STARRED: 'UNMARK_AS_STARRED',
  NO_TEMPLATES: 'NO_TEMPLATES',
  TEMPLATE_EXISTS: 'TEMPLATE_EXISTS',
  TEMPLATE_DELETED: 'TEMPLATE_DELETED',
  TEMPLATE_FOLDER_DELETED: 'TEMPLATE_FOLDER_DELETED',
  DELETE_TEMPLATE_DESCRIPTION: 'DELETE_TEMPLATE_DESCRIPTION',
  DELETE_TEMPLATE_FOLDER_DESCRIPTION: 'DELETE_TEMPLATE_FOLDER_DESCRIPTION',
  TEMPLATE_MOVED_TO_FOLDER: 'TEMPLATE_MOVED_TO_FOLDER',
  TEMPLATE_DUPLICATE_FILE_DESCRIPTION: 'TEMPLATE_DUPLICATE_FILE_DESCRIPTION',
  TEMPLATE_DUPLICATED: 'TEMPLATE_DUPLICATED',
  UPLOAD_TEMPLATE: 'UPLOAD_TEMPLATE',
  DELETED_BY_OWNER: 'DELETED_BY_OWNER',
  START_EKYC: 'START_EKYC',
  EKYC_WARNING_TEXT: 'EKYC_WARNING_TEXT',
  DETECTING_FACE: 'DETECTING_FACE',
  PLACE_YOUR_FACE: 'PLACE_YOUR_FACE',
  SUCCESSFUL_REGISTRATION_COMPLETED: 'SUCCESSFUL_REGISTRATION_COMPLETED',
  REGISTRATION_IS_FAILED: 'REGISTRATION_IS_FAILED',
  SUCCESSFUL_VERIFICATION: 'SUCCESSFUL_VERIFICATION',
  VERIFICATION_IS_FAILED: 'VERIFICATION_IS_FAILED',
  ACCEPT_LEGAL_NOTICE: 'ACCEPT_LEGAL_NOTICE',
  TAP_TO_SEE_SIGNATURE: 'TAP_TO_SEE_SIGNATURE',
  TAP_TO_SEE_INITIALS: 'TAP_TO_SEE_INITIALS',
  RECIPIENTS_WITHOUT_EMAIL_AND_NAME: 'RECIPIENTS_WITHOUT_EMAIL_AND_NAME',
  TEMPLATES_CLM_NOT_SPECIFIED: 'TEMPLATES_CLM_NOT_SPECIFIED',
  TEMPLATES_ADD_CLM: 'TEMPLATES_ADD_CLM',
  TEMPLATES_SAVE_AND_CLOSE: 'TEMPLATES_SAVE_AND_CLOSE',
  TEMPLATES_DELETE_AND_CLOSE: 'TEMPLATES_DELETE_AND_CLOSE',
  TEMPLATES_DISCARD_CONFIRMATION: 'TEMPLATES_DISCARD_CONFIRMATION',
  TEMPLATES_UPLOAD_DOCUMENT_FOR_CLM_ONLY: 'TEMPLATES_UPLOAD_DOCUMENT_FOR_CLM_ONLY',
  TEMPLATES_CLM_ONLY_UPLOAD_DOCUMENT_DISCLAIMER: 'TEMPLATES_CLM_ONLY_UPLOAD_DOCUMENT_DISCLAIMER',
  TEMPLATES_CREATE_CLM_MODAL: 'TEMPLATES_CREATE_CLM_MODAL',
  TEMPLATES_SUCCESSFUL_CREATION: 'TEMPLATES_SUCCESSFUL_CREATION',
  CREATE_TEMPLATE_FROM_CONTRACT: 'CREATE_TEMPLATE_FROM_CONTRACT',
  SELECT_CONTRACT: 'SELECT_CONTRACT',
  TEMPLATE_NAME: 'TEMPLATE_NAME',
  SELECT_CONTRACT_FOR_TEMPLATE: 'SELECT_CONTRACT_FOR_TEMPLATE',
  DISCARD: 'DISCARD',
  NO_NAME: 'NO_NAME',
  SAVE_WITHOUT_SLM: 'SAVE_WITHOUT_SLM',
  SIDEBAR_PREFERENCES_NOTIFICATIONS: 'SIDEBAR_PREFERENCES_NOTIFICATIONS',
  SIDEBAR_PREFERENCES_REGIONAL_SETTINGS: 'SIDEBAR_PREFERENCES_REGIONAL_SETTINGS',
  SIDEBAR_PREFERENCES_CLOUD_STORAGE: 'SIDEBAR_PREFERENCES_CLOUD_STORAGE',
  REGIONAL_SETTINGS_TITLE: 'REGIONAL_SETTINGS_TITLE',
  DATA_AND_TIME_FORMAT: 'DATA_AND_TIME_FORMAT',
  CONTRACT_NOTIFICATIONS: 'CONTRACT_NOTIFICATIONS',
  REMINDERS: 'REMINDERS',
  CHASERS: 'CHASERS',
  UPGRADE_REQUIRED: 'UPGRADE_REQUIRED',
  RECIPIENTS_WRONG_DEADLINES: 'RECIPIENTS_WRONG_DEADLINES',
  CONTRACT_FOLDER_DELETED: 'CONTRACT_FOLDER_DELETED',
  ANDROID_PERMISSION_CAMERA_TITLE: 'ANDROID_PERMISSION_CAMERA_TITLE',
  ANDROID_PERMISSION_CAMERA_MESSAGE: 'ANDROID_PERMISSION_CAMERA_MESSAGE',
  ID_CHECK: 'ID_CHECK',
  SELFIE: 'SELFIE',
  EKYC: 'EKYC',
  PLACE_YOUR_ID: 'PLACE_YOUR_ID',
  DETECTING_ID: 'DETECTING_ID',
  SENDING_IMAGE_TO_SERVER: 'SENDING_IMAGE_TO_SERVER',
  TRY_AGAIN: 'TRY_AGAIN',
  PLEASE_TRY_AGAIN: 'PLEASE_TRY_AGAIN',
  PLEASE_TRY_VERIFICATION_AGAIN: 'PLEASE_TRY_VERIFICATION_AGAIN',
  EKYC_VERIFICATION_CONFIRMED: 'EKYC_VERIFICATION_CONFIRMED',
  EKYC_REGISTRATION_FAILED: 'EKYC_REGISTRATION_FAILED',
  EKYC_CONTACT_SUPPORT: 'EKYC_CONTACT_SUPPORT',
  CONTACT_SUPPORT: 'CONTACT_SUPPORT',
  DELETE_AND_CLOSE: 'DELETE_AND_CLOSE',
  CANCEL_CREATING_CONTRACT: 'CANCEL_CREATING_CONTRACT',
  CREATE_NEW_CONTRACT: 'CREATE_NEW_CONTRACT',
  SELECT_TEMPLATE_FOR_CONTRACT: 'SELECT_TEMPLATE_FOR_CONTRACT',
  CREATE_CONTRACT_FROM_TEMPLATE: 'CREATE_CONTRACT_FROM_TEMPLATE',
  SELECT_TEMPLATE: 'SELECT_TEMPLATE',
  CREATING_CONTRACT_ABORTED: 'CREATING_CONTRACT_ABORTED',
  NO_DOCUMENT_NOTIFICATION: 'NO_DOCUMENT_NOTIFICATION',
  ASK_CANCEL_CHANGES: 'ASK_CANCEL_CHANGES',
  FOLDER_CONTAINS_RECEIVED_CONTRACT: 'FOLDER_CONTAINS_RECEIVED_CONTRACT',
  EKYC_INSTRUCTIONS_FACE_TITLE: 'EKYC_INSTRUCTIONS_FACE_TITLE',
  EKYC_INSTRUCTIONS_FACE_SUBTITLE: 'EKYC_INSTRUCTIONS_FACE_SUBTITLE',
  EKYC_INSTRUCTIONS_FACE_TEXT1: 'EKYC_INSTRUCTIONS_FACE_TEXT1',
  EKYC_INSTRUCTIONS_FACE_TEXT2: 'EKYC_INSTRUCTIONS_FACE_TEXT2',
  EKYC_INSTRUCTIONS_FACE_TEXT3: 'EKYC_INSTRUCTIONS_FACE_TEXT3',
  EKYC_INSTRUCTIONS_FACE_TEXT4: 'EKYC_INSTRUCTIONS_FACE_TEXT4',
  EKYC_INSTRUCTIONS_ID_TITLE: 'EKYC_INSTRUCTIONS_ID_TITLE',
  EKYC_INSTRUCTIONS_ID_SUBTITLE: 'EKYC_INSTRUCTIONS_ID_SUBTITLE',
  EKYC_INSTRUCTIONS_ID_TEXT1: 'EKYC_INSTRUCTIONS_ID_TEXT1',
  EKYC_INSTRUCTIONS_ID_TEXT2: 'EKYC_INSTRUCTIONS_ID_TEXT2',
  EKYC_INSTRUCTIONS_ID_TEXT3: 'EKYC_INSTRUCTIONS_ID_TEXT3',
  SUBSCRIPTION_PLANS: 'SUBSCRIPTION_PLANS',
  YEAR: 'YEAR',
  MO: 'MO',
  FEATURE_CONTRACTS_COUNT: 'FEATURE_CONTRACTS_COUNT',
  FEATURE_CONTRACTS_UNLIMITED: 'FEATURE_CONTRACTS_UNLIMITED',
  FEATURE_USER_TEMPLATES: 'FEATURE_USER_TEMPLATES',
  FEATURE_UNLIMITED_BULK_SENDING: 'FEATURE_UNLIMITED_BULK_SENDING',
  FEATURE_DASHBOARD: 'FEATURE_DASHBOARD',
  FEATURE_CLM: 'FEATURE_CLM',
  FEATURE_EKYC: 'FEATURE_EKYC',
  FEATURE_SIGNATURE_MATCHING: 'FEATURE_SIGNATURE_MATCHING',
  FEATURE_API_INTEGRATION: 'FEATURE_API_INTEGRATION',
  DO_NOT_LIKE_PLAN: 'DO_NOT_LIKE_PLAN',
  CONTACT_US: 'CONTACT_US',
  YOUR_PLAN: 'YOUR_PLAN',
  EXPIRATION_DATE: 'EXPIRATION_DATE',
  CONTRACTS: 'CONTRACTS',
  CHANGE_PLAN: 'CHANGE_PLAN',
  UNSUBSCRIBE: 'UNSUBSCRIBE',
  SUBSCRIPTION_IS_EXPIRED: 'SUBSCRIPTION_IS_EXPIRED',
  VERIFICATION_CONFIRMED: 'VERIFICATION_CONFIRMED',
  GO_TO_SETTINGS: 'GO_TO_SETTINGS',
  TEXT_FIELD: 'TEXT_FIELD',
  TEXT_FIELD_PLACEHOLDER: 'TEXT_FIELD_PLACEHOLDER',
  DATE_FIELD: 'DATE_FIELD',
  DATE_FIELD_PLACEHOLDER: 'DATE_FIELD_PLACEHOLDER',
  TEMPLATES_NO_ACCESS: 'TEMPLATES_NO_ACCESS',
  API_INTEGRATION_NO_ACCESS: 'API_INTEGRATION_NO_ACCESS',
  UPGRADE_YOUR_PLAN: 'UPGRADE_YOUR_PLAN',
  EKYC_NO_ACCESS: 'EKYC_NO_ACCESS',
  USE_MOBILE_EKYC: 'USE_MOBILE_EKYC',
  CONTACT_US_MESSAGE: 'CONTACT_US_MESSAGE',
  INVALID_MESSAGE: 'INVALID_MESSAGE',
  SIZE: 'SIZE',
  SEQUENTIAL_SIGNING_NO_ACCESS: 'SEQUENTIAL_SIGNING_NO_ACCESS',
  CONTACT_US_SUCCESS: 'CONTACT_US_SUCCESS',
  TEMPLATES_CUSTOM_FIELDS_PROMPT: 'TEMPLATES_CUSTOM_FIELDS_PROMPT',
  TEMPLATES_DATE_FIELD_INCORRECT: 'TEMPLATES_DATE_FIELD_INCORRECT',
  TEMPLATES_INCORRECT_FIELDS: 'TEMPLATES_INCORRECT_FIELDS',
  TEMPLATES_FIELDS_FILLED: 'TEMPLATES_FIELDS_FILLED',
  FLIP_THE_PHONE: 'FLIP_THE_PHONE',
  HOME: 'HOME',
  PAGE_NOT_FOUND: 'PAGE_NOT_FOUND',
  CONNECT: 'CONNECT',
  DISCONNECT: 'DISCONNECT',
  CONNECT_CLOUD_STORAGE: 'CONNECT_CLOUD_STORAGE',
  FAQ_HEADER_TITLE: 'FAQ_HEADER_TITLE',
  FAQ_HEADER_SUB_TITLE: 'FAQ_HEADER_SUB_TITLE',
  DOCUMENT_TITLE_FIELD_MAX: 'DOCUMENT_TITLE_FIELD_MAX',
  DOCUMENT_MESSAGE_FIELD_MAX: 'DOCUMENT_MESSAGE_FIELD_MAX',
  OTP_VERIFICATION_TITLE: 'OTP_VERIFICATION_TITLE',
  OTP_VERIFICATION_DESCRIPTION: 'OTP_VERIFICATION_DESCRIPTION',
  OTP_VERIFICATION_CONTACT_OWNER: 'OTP_VERIFICATION_CONTACT_OWNER',
  CODE_IS_CORRECT: 'CODE_IS_CORRECT',
  RECIPIENT_ATTEMPTS_FINISHED: 'RECIPIENT_ATTEMPTS_FINISHED',
  INVALID_OTP: 'INVALID_OTP',
  LAST_ATTEMPT: 'LAST_ATTEMPT',
  NEW_CODE_IS_SENT: 'NEW_CODE_IS_SENT',
  PROCESSING_DOCUMENTS: 'PROCESSING_DOCUMENTS',
  MULTIPLE_PERSONS_NO_ACCESS: 'MULTIPLE_PERSONS_NO_ACCESS',
  LOADING: 'LOADING',
  YOU_HAVE: 'YOU_HAVE',
  ATTEMPTS: 'ATTEMPTS',
  NETWORK_IS_BUSY: 'NETWORK_IS_BUSY',
  EXPIRED_CONTRACT_NOTIFICATION: 'EXPIRED_CONTRACT_NOTIFICATION',
  USER_TERM_EXPIRED: 'USER_TERM_EXPIRED',
  EKYC_ACTIONS_STUB_DESCRIPTION: 'EKYC_ACTIONS_STUB_DESCRIPTION',
  EKYC_STUB_TITLE: 'EKYC_STUB_TITLE',
  EKYC_STUB_DESCRIPTION: 'EKYC_STUB_DESCRIPTION',
  CONTRACT_UPDATED_SUCCESSFULLY: 'CONTRACT_UPDATED_SUCCESSFULLY',
  KEEP_IN_ON_PLACE: 'KEEP_IN_ON_PLACE',
  CREATE_ONLY_SIGNATURE_DESCRIPTION: 'CREATE_ONLY_SIGNATURE_DESCRIPTION',
  CREATE_ONLY_INITIALS_DESCRIPTION: 'CREATE_ONLY_INITIALS_DESCRIPTION',
  CREATE_NEW_SIGNATURE: 'CREATE_NEW_SIGNATURE',
  CREATE_NEW_INITIALS: 'CREATE_NEW_INITIALS',
  SEE_PROGRESS: 'SEE_PROGRESS',
  RECIPIENT_INFO: 'RECIPIENT_INFO',
  ACTION: 'ACTION',
  CANT_START_CYCLE: 'CANT_START_CYCLE',
  EXIT_APP: 'EXIT_APP',
  DO_YOU_WANNA_EXIT: 'DO_YOU_WANNA_EXIT',
  CONTRACT_VERIFY_FAILED: 'CONTRACT_VERIFY_FAILED',
  CONTACT_SENDER: 'CONTACT_SENDER',
  SAVE_RECIPIENT_FOR_RESEND_CONFIRMATION: 'SAVE_RECIPIENT_FOR_RESEND_CONFIRMATION',
  RECIPIENTS_CYCLE_ORDER_LESS_THAN_ORIGINAL: 'RECIPIENTS_CYCLE_ORDER_LESS_THAN_ORIGINAL',
  RECIPIENT_DELETE_CONFIRMATION_CONTRACT_RESEND: 'RECIPIENT_DELETE_CONFIRMATION_CONTRACT_RESEND',
  NOT_SENT: 'NOT_SENT',
  RECEIVED: 'RECEIVED',
  OPENED: 'OPENED',
  COMPLETE: 'COMPLETE',
  RESEND_CONTRACT: 'RESEND_CONTRACT',
  RESEND_CONTRACT_MODAL_HEADER_TEXT: 'RESEND_CONTRACT_MODAL_HEADER_TEXT',
  RESEND_CONTRACT_MODAL_FOOTER_TEXT: 'RESEND_CONTRACT_MODAL_FOOTER_TEXT',
  RECIPIENTS_WITH_EXPIRED_DEADLINE: 'RECIPIENTS_WITH_EXPIRED_DEADLINE',
  CONTRACT_RESENT: 'CONTRACT_RESENT',
  CORRECTION_INPUT_PLACEHOLDER: 'CORRECTION_INPUT_PLACEHOLDER',
  CONTRACT_UPDATED_SUCCESSFULLY_UNREGISTERED: 'CONTRACT_UPDATED_SUCCESSFULLY_UNREGISTERED',
  COMMENT_FIELD_PLACEHOLDER: 'COMMENT_FIELD_PLACEHOLDER',
  NOTIFICATION_CONTRACT_PENDING: 'NOTIFICATION_CONTRACT_PENDING',
  NOTIFICATION_CONTRACT_SIGNED: 'NOTIFICATION_CONTRACT_SIGNED',
  NOTIFICATION_CONTRACT_DECLINED: 'NOTIFICATION_CONTRACT_DECLINED',
  NOTIFICATION_CONTRACT_CHANGED: 'NOTIFICATION_CONTRACT_CHANGED',
  NOTIFICATION_CONTRACT_COMMENTED: 'NOTIFICATION_CONTRACT_COMMENTED',
  NOTIFICATION_CONTRACT_MENTIONED: 'NOTIFICATION_CONTRACT_MENTIONED',
  NOTIFICATION_CONTRACT_SENT: 'NOTIFICATION_CONTRACT_SENT',
  NOTIFICATION_CONTRACT_UPDATED: 'NOTIFICATION_CONTRACT_UPDATED',
  NOTIFICATION_CONTRACT_DELETED: 'NOTIFICATION_CONTRACT_DELETED',
  NOTIFICATION_CONTRACT_COMPLETED: 'NOTIFICATION_CONTRACT_COMPLETED',
  NOTIFICATION_CONTRACT_RECIPIENTS_UPDATED: 'NOTIFICATION_CONTRACT_RECIPIENTS_UPDATED',
  NOTIFICATION_CONTRACT_VOIDED: 'NOTIFICATION_CONTRACT_VOIDED',
  NOTIFICATION_CONTRACT_CORRECTIONS_REMOVED: 'NOTIFICATION_CONTRACT_CORRECTIONS_REMOVED',
  NOTIFICATION_REMINDER_BEFORE_AND_ON_DAY: 'NOTIFICATION_REMINDER_BEFORE_AND_ON_DAY',
  NOTIFICATION_CHASERS_DEADLINE_MISSED: 'NOTIFICATION_CHASERS_DEADLINE_MISSED',
  IN_APP: 'IN_APP',
  EMAIL: 'EMAIL',
  PUSH: 'PUSH',
  COMMENT_UNSAVED_WARNING: 'COMMENT_UNSAVED_WARNING',
  CONTRACT_ANNOTATION_SAVED: 'CONTRACT_ANNOTATION_SAVED',
  COMMENT_REPLY: 'COMMENT_REPLY',
  CLEAR_ALL_NOTIFICATIONS: 'CLEAR_ALL_NOTIFICATIONS',
  DEADLINE_IS_EXPIRED: 'DEADLINE_IS_EXPIRED',
  STRIKE_UNSAVED_WARNING: 'STRIKE_UNSAVED_WARNING',
  CORRECTION_UNSAVED_WARNING: 'CORRECTION_UNSAVED_WARNING',
  DAY: 'DAY',
  DAYS: 'DAYS',
  WE_SUGGEST_SUPPORT: 'WE_SUGGEST_SUPPORT',
  SAVE_CONTRACT_WITH_FIELDS: 'SAVE_CONTRACT_WITH_FIELDS',
  RENAMED: 'RENAMED',
  REUPLOADED: 'REUPLOADED',
  CROSSED_OVER: 'CROSSED_OVER',
  LEFT_CORRECTION: 'LEFT_CORRECTION',
  ACCEPTED: 'ACCEPTED',
  REJECTED: 'REJECTED',
  CANCELLED_ACCEPTATION: 'CANCELLED_ACCEPTATION',
  CANCELLED_REJECTION: 'CANCELLED_REJECTION',
  CLEARED_CORRECTIONS: 'CLEARED_CORRECTIONS',
  DOWNLOADED: 'DOWNLOADED',
  RESENT: 'RESENT',
  ACCEPT: 'ACCEPT',
  REJECT: 'REJECT',
  ACCEPTED_BY: 'ACCEPTED_BY',
  REJECTED_BY: 'REJECTED_BY',
  CONFIRMATION_ATTENTION: 'CONFIRMATION_ATTENTION',
  ACCEPTED_SUCCESSFULLY: 'ACCEPTED_SUCCESSFULLY',
  REJECTED_SUCCESSFULLY: 'REJECTED_SUCCESSFULLY',
  CANCEL_ACCEPTATION_ATTENTION: 'CANCEL_ACCEPTATION_ATTENTION',
  CANCEL_REJECTION_ATTENTION: 'CANCEL_REJECTION_ATTENTION',
  CANCEL_ACCEPTATION_SUCCESSFULLY: 'CANCEL_ACCEPTATION_SUCCESSFULLY',
  CANCEL_REJECTION_SUCCESSFULLY: 'CANCEL_REJECTION_SUCCESSFULLY',
  UPLOAD_FROM: 'UPLOAD_FROM',
  DEVICE: 'DEVICE',
  TEMPLATE: 'TEMPLATE',
  RECIPIENT: 'RECIPIENT',
  ADD_ONE_MORE_RECIPIENT: 'ADD_ONE_MORE_RECIPIENT',
  RETURNED_WITH_CORRECTIONS: 'RETURNED_WITH_CORRECTIONS',
  OWNER: 'OWNER',
  OPEN_THE_CONTRACT_AS: 'OPEN_THE_CONTRACT_AS',
  ENTER_PASSWORD_TO_OPEN_DOCUMENT: 'ENTER_PASSWORD_TO_OPEN_DOCUMENT',
  SIGN_UP_FOR: 'SIGN_UP_FOR',
  PAYMENT: 'PAYMENT',
  AMOUNT: 'AMOUNT',
  ANNUAL_SUBSCRIPTION: 'ANNUAL_SUBSCRIPTION',
  MONTHLY_SUBSCRIPTION: 'MONTHLY_SUBSCRIPTION',
  SELECT_OPTION: 'SELECT_OPTION',
  WAITING_PAYMENT: 'WAITING_PAYMENT',
  SELECT_ANOTHER_PLAN: 'SELECT_ANOTHER_PLAN',
  SAVE_CARD: 'SAVE_CARD',
  CARD: 'CARD',
  HAS_BEEN_SUCCESSFULLY_SAVED: 'HAS_BEEN_SUCCESSFULLY_SAVED',
  SUBSCRIPTION_UPDATED_SUCCESSFULLY: 'SUBSCRIPTION_UPDATED_SUCCESSFULLY',
  PAY: 'PAY',
  USE_ANOTHER_CARD: 'USE_ANOTHER_CARD',
  MONTH: 'MONTH',
  MANY_CARDS_ERROR: 'MANY_CARDS_ERROR',
  FAILED_TO_ADD_CARD: 'FAILED_TO_ADD_CARD',
  SPECIFY_CARD: 'SPECIFY_CARD',
  FILL_CARD_DETAILS: 'FILL_CARD_DETAILS',
  SUCCESSFUL_PAYMENT: 'SUCCESSFUL_PAYMENT',
  SHOW_ALL: 'SHOW_ALL',
  CARD_SUCCESSFULLY_ADDED: 'CARD_SUCCESSFULLY_ADDED',
  START_FREE_TRIAL: 'START_FREE_TRIAL',
  FREE_TRIAL: 'FREE_TRIAL',
  WANT_TO_LOG_OUT: 'WANT_TO_LOG_OUT',
  SELECT_SUBSCRIPTION: 'SELECT_SUBSCRIPTION',
  START_FREE_TRIAL_BUTTON: 'START_FREE_TRIAL_BUTTON',
  SUBSCRIBE_NOW: 'SUBSCRIBE_NOW',
  SELECT_SUBSCRIPTION_TERM: 'SELECT_SUBSCRIPTION_TERM',
  MONTHLY: 'MONTHLY',
  YEARLY: 'YEARLY',
  PLAN: 'PLAN',
  FREE_TRIAL_CHARGE: 'FREE_TRIAL_CHARGE',
  PLAN_IS_CONNECTED: 'PLAN_IS_CONNECTED',
  ERROR_CHECK_URL: 'ERROR_CHECK_URL',
  ERROR_FORBIDDEN: 'ERROR_FORBIDDEN',
  UPGRADED_SUBSCRIPTION: 'UPGRADED_SUBSCRIPTION',
  DOWNGRADED_SUBSCRIPTION: 'DOWNGRADED_SUBSCRIPTION',
}

export type TranslationKeys = typeof translationKeys

export default translationKeys
