import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { TEMPLATES_MODALS } from '../../../../../constants/templates'
import { openModal } from '../../../../../store/modals/modals.actions'
import translations from '../../../../../translations/keys'
import UploadDocumentModal from '../../DocumentModals/UploadDocumentModal'

import { UploadTemplateModalProps } from './UploadTemplateModal.types'

export const UploadTemplateModal: React.FC<UploadTemplateModalProps> = ({ onClose }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const handleUploadContractSuccess = (fileName: string, file: File) => {
    dispatch(openModal(TEMPLATES_MODALS.UPLOAD_TEMPLATE_TO, { fileName, file }))
  }

  return (
    <UploadDocumentModal
      onClose={onClose}
      onSuccess={handleUploadContractSuccess}
      title={t(translations.UPLOAD_TEMPLATE)}
    />
  )
}
