import React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import translations from '../../../translations/keys'
import { ContractRouteParams } from '../../types/signing'
import { RecipientData } from '../../../types/recipients'
import { RECIPIENTS_DIAGRAM_MODALS } from '../../constants/recipientsDiagram'
import { CONTRACT_MODALS } from '../../../constants/contract'
import omit from '../../../utils/omit'
import { removeExtension } from '../../../utils/file/removeExtension'
import { location } from '../../../utils/location'
import {
  validateRecipients,
  validateCycleOrderForStartedContract,
  prepareAllRecipientsForSubmit,
} from '../../../utils/recipients'
import { useDispatchUnmount } from '../../../hooks/useDispatchUnmount'
import { useDownloadContract } from '../../hooks/contracts/useDownloadContract'
import { useOwnerViewFetch } from '../../../hooks/ownerView/useOwnerViewFetch'
import { useOwnerViewNotifications } from '../../../hooks/ownerView/useOwnerViewNotifications'
import { useCommentsFetch } from '../../../hooks/contracts/useCommentsFetch'
import { useStrikesFetch } from '../../../hooks/contracts/useStrikesFetch'
import { useCorrectionsFetch } from '../../../hooks/contracts/useCorrectionsFetch'
import { useContractAnnotations } from '../../../hooks/contracts/useContractAnnotations'
import { fetchResend, setRecipients } from '../../../store/contracts/recipients/recipients.actions'
import * as RECIPIENTS_SELECTORS from '../../../store/contracts/recipients/recipients.selectors'
import * as OWNER_VIEW_SELECTORS from '../../../store/contracts/ownerView/ownerView.selectors'
import * as PLACEMENT_SELECTORS from '../../../store/contracts/placement/placement.selectors'
import * as STRIKES_SELECTORS from '../../../store/contracts/strikes/strikes.selectors'
import * as CORRECTIONS_SELECTORS from '../../../store/contracts/corrections/corrections.selectors'
import * as COMMENTS_SELECTORS from '../../../store/contracts/comments/comments.selectors'
import { viewOptionsIsSignatureNamesVisibleSelector } from '../../../store/contracts/viewOptions/viewOptions.selectors'
import { cleanViewOptions } from '../../../store/contracts/viewOptions/viewOptions.actions'
import { authUserSelector } from '../../../store/auth/auth.selectors'
import { cleanOwnerViewNotification } from '../../../store/contracts/ownerView/ownerView.actions'
import { closeModal, openModal } from '../../../store/modals/modals.actions'
import DocViewer from '../../ui/DocViewer'
import Box from '../../ui/Box'
import NotificationBadge from '../../ui/NotificationBadge'
import FullScreenTemplate from '../../components/Main/FullScreenTemplate'
import { PreviewHeader } from '../../components/PreviewHeader/PreviewHeader'
import LogSidebar from '../../components/LogSidebar'
import RecipientsProgressModals from '../../components/Modals/RecipientsModals/RecipientsProgressModals'
import { NOTIFICATION_MODAL_TYPES } from '../../ui/Modals/NotificationModal/NotificationModal.constants'
import { COMMON_MODALS_IDS } from '../../ui/Modals/CommonModals/CommonModals.constants'
import { EDIT_RECIPIENT_DISABLED_FIELDS } from '../../../constants/recipients'
import CommentPopUp from '../CommentPopUp'


import Modals from './components/Modals'
import * as Styled from './ContractOwnerView.styles'
import { useButtons } from './hooks/useButtons'
import { useActions } from './hooks/useActions'
import { useToolbarActions } from './hooks/useToolbarActions'
import { useErrors } from './hooks/useErrors'

export const ContractOwnerView = () => {
  const { contractId } = useParams<ContractRouteParams>()
  const dispatch = useDispatch()
  const history = useHistory()
  const data = useSelector(OWNER_VIEW_SELECTORS.ownerViewContractDocumentDataSelector)
  const order = useSelector(RECIPIENTS_SELECTORS.recipientsOrderSelector)
  const recipientsById = useSelector(RECIPIENTS_SELECTORS.recipientsByIdSelector)
  const placementById = useSelector(PLACEMENT_SELECTORS.placementByIdSelector)
  const placementByPages = useSelector(PLACEMENT_SELECTORS.placementByPagesSelector)
  const strikesById = useSelector(STRIKES_SELECTORS.strikesByIdSelector)
  const strikesByPages = useSelector(STRIKES_SELECTORS.strikesByPagesSelector)
  const correctionsById = useSelector(CORRECTIONS_SELECTORS.correctionsByIdSelector)
  const correctionsByPages = useSelector(CORRECTIONS_SELECTORS.correctionsByPagesSelector)
  const commentsById = useSelector(COMMENTS_SELECTORS.commentsByIdSelector)
  const commentsByPages = useSelector(COMMENTS_SELECTORS.commentsByPagesSelector)
  const commentThreadLoading = useSelector(COMMENTS_SELECTORS.commentsFetchThreadRequestSelector)
  const commentThread = useSelector(COMMENTS_SELECTORS.commentsThreadSelector)
  const currentUser = useSelector(authUserSelector)
  const notification = useSelector(OWNER_VIEW_SELECTORS.ownerViewNotificationSelector)
  const resendStatus = useSelector(RECIPIENTS_SELECTORS.recipientsFetchResendStatusSelector)
  const isSignatureNamesVisible = useSelector(viewOptionsIsSignatureNamesVisibleSelector)
  const title = removeExtension(data?.contractName || '')
  const actions = useActions()
  const buttons = useButtons()
  const toolbarActions = useToolbarActions()

  const {
    selectedComment,
    handleElemChange,
    handleElemCreate,
    handleElemClick,
    handleElemBlur,
    handleElemSave,
    handleElemDelete,
    handleFieldChange,
    handleCommentSubmit,
    handleCommentPopoverClose,
    handleElemConfirm,
    handleElemCancelConfirmation,
  } = useContractAnnotations(contractId)

  console.log(resendStatus);

  if(resendStatus === 'SUCCESS') history.push(location.contractsAllUrl())


  const handleNotificationBadgeClose = () => {
    dispatch(cleanOwnerViewNotification())
  }

  const handleEdit = (id: string) => {
    const recipient = recipientsById[id]
    dispatch(
      openModal(RECIPIENTS_DIAGRAM_MODALS.EDIT_DIAGRAM_RECIPIENT, {
        recipient,
        order,
        disabledFields: EDIT_RECIPIENT_DISABLED_FIELDS,
      })
    )
  }

  const handleRecipientUpdate = (values: RecipientData) => {
    const { id } = values

    if (id) {
      const recipient = recipientsById[id]
      const nextRecipients = {
        ...omit([id], recipientsById),
        [id]: { ...values, status: recipient.status },
      }
      const errorCode =
        validateRecipients(Object.values(nextRecipients)) || validateCycleOrderForStartedContract(values, recipient)

      if (errorCode) {
        dispatch(
          openModal(COMMON_MODALS_IDS.NOTIFICATION, {
            type: NOTIFICATION_MODAL_TYPES.WARNING,
            description: translations[errorCode],
          })
        )
      } else {
        dispatch(setRecipients(Object.values(nextRecipients)))
      }
    }
  }

  const handleConfirmDelete = (id: string) => {
    const nextRecipients = { ...omit([id], recipientsById) }
    dispatch(setRecipients(Object.values(nextRecipients)))
  }

  const handleDelete = (id: string) => {
    dispatch(
      openModal(RECIPIENTS_DIAGRAM_MODALS.DELETE_DIAGRAM_RECIPIENT, {
        id,
        description: translations.RECIPIENT_DELETE_CONFIRMATION_CONTRACT_RESEND,
      })
    )
  }

  const handleResend = () => {

    const recipientsBody = prepareAllRecipientsForSubmit({ recipients: Object.values(recipientsById) }, order)
    
    dispatch(fetchResend(contractId, recipientsBody))
    dispatch(closeModal(CONTRACT_MODALS.CONTRACT_RESEND))
    // history.push(location.contractsAllUrl())
  }

  useOwnerViewFetch(contractId)
  useDownloadContract(contractId)
  useOwnerViewNotifications()
  useCommentsFetch(contractId)
  useStrikesFetch(contractId)
  useCorrectionsFetch(contractId)
  useErrors()
  useDispatchUnmount(cleanViewOptions)

  return (
    <FullScreenTemplate
      sidebarContent={<LogSidebar onEdit={handleEdit} onDelete={handleDelete} />}
      headerContent={<PreviewHeader actions={actions} buttons={buttons} title={title} />}
    >
      <Styled.Container test-id="contract-signing-container">
        {data && (
          <DocViewer
            isSignatureEditable={false}
            pages={data.pages}
            placementById={placementById}
            placementByPages={placementByPages}
            strikesById={strikesById}
            strikesByPages={strikesByPages}
            correctionsById={correctionsById}
            correctionsByPages={correctionsByPages}
            commentsById={commentsById}
            commentsByPages={commentsByPages}
            actions={toolbarActions}
            isSignatureNamesVisible={isSignatureNamesVisible}
            onElemChange={handleElemChange}
            onElemCreate={handleElemCreate}
            onElemClick={handleElemClick}
            onElemBlur={handleElemBlur}
            onElemSave={handleElemSave}
            onElemDelete={handleElemDelete}
            onFieldChange={handleFieldChange}
            onElemConfirm={handleElemConfirm}
            onElemCancelConfirmation={handleElemCancelConfirmation}
          />
        )}
        {notification && (
          <Styled.MessageContainer test-id="contracts-preview-message-container">
            <Box ml={4} mt={4}>
              <NotificationBadge
                isShow
                isAutoClose
                message={translations[notification.message]}
                onClose={handleNotificationBadgeClose}
                variant={notification.variant}
              />
            </Box>
          </Styled.MessageContainer>
        )}
      </Styled.Container>
      {selectedComment && (
        <CommentPopUp
          isContractOwner={true}
          targetRef={selectedComment.elem}
          comment={selectedComment.comment}
          thread={commentThread}
          recipients={recipientsById}
          currentUser={currentUser}
          loading={commentThreadLoading}
          onSubmit={handleCommentSubmit}
          onClose={handleCommentPopoverClose}
          onElemConfirm={handleElemConfirm}
          onElemCancelConfirmation={handleElemCancelConfirmation}
          onModalClose={handleCommentPopoverClose}
        />
      )}
      <Modals onResend={handleResend}  />
      {/* <Modals onResend={handleResend}  contractId={contractId}/> */}
      <RecipientsProgressModals isOwner onUpdate={handleRecipientUpdate} onConfirmDelete={handleConfirmDelete} />
    </FullScreenTemplate>
  )
}
