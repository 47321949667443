import { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import translations from '../../../../translations/keys'
import { clearFieldsText, scrollToEmptyField } from '../../../../store/templates/fields/fields.actions'
import { ToolbarControls } from '../../../ui/DocViewerToolbar/DocViewerToolbar.types'
import { ArrowRightIcon, ContourCrossIcon } from '../../../assets/icons'
import { viewOptionsIsSignatureNamesVisibleSelector } from '../../../../store/templates/viewOptions/viewOptions.selectors'

export const useToolbarActions = () => {
  const dispatch = useDispatch()
  const isSignatureNamesVisible = useSelector(viewOptionsIsSignatureNamesVisibleSelector)

  const toolbarActions = useMemo(() => {
    const actions: ToolbarControls = [
      {
        name: translations.NEXT,
        Icon: ArrowRightIcon,
        onClick: () => {
          dispatch(scrollToEmptyField())
        },
      },
      {
        name: translations.CLEAR_ALL,
        Icon: ContourCrossIcon,
        onClick: () => {
          dispatch(clearFieldsText())
        },
      },
    ]

    return actions
  }, [isSignatureNamesVisible])

  return toolbarActions
}
