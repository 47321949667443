import { FetchFailureAction, FetchFailurePayload } from '../../../types/fetch'
import { SaveAsTemplateResponse, SaveAsTemplateBody } from '../../../types/saveAsTemplate'
import { createFetchActionTypes } from '../../../utils/createFetchActionTypes'

import * as TYPES from './saveAsTemplate.types'

export const fetchSaveAsTemplateTypes = createFetchActionTypes(
  TYPES.SaveAsTemplateActions.FETCH_SAVE_CONTRACT_AS_TEMPLATE
)

export const fetchSaveAsTemplate = (body: SaveAsTemplateBody): TYPES.FetchSaveAsTemplate => ({
  type: fetchSaveAsTemplateTypes.request,
  payload: { body },
})

export const fetchSaveAsTemplateSuccess = (response: SaveAsTemplateResponse): TYPES.FetchSaveAsTemplateSuccess => ({
  type: fetchSaveAsTemplateTypes.success,
  payload: { id: response.id },
})

export const fetchSaveAsTemplateFailure = (payload: FetchFailurePayload): FetchFailureAction => ({
  type: fetchSaveAsTemplateTypes.failure,
  payload,
})

export const cleanSaveAsTemplate = () => ({
  type: TYPES.SaveAsTemplateActions.CLEAN_SAVE_CONTRACT_AS_TEMPLATE,
})
