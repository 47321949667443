import styled from 'styled-components'

import Box from '../../../../ui/Box'

export const StyledPlan = styled(Box).attrs({
  pt: 4,
  pb: 4,
  pr: 4,
  pl: 4,
  mt: 2,
  mb: 3,
})`
  box-shadow: ${({ theme }) => theme.shadow};
  border: 1px solid ${({ theme }) => theme.palette.strokeGrey};
  border-radius: 4px;
`
