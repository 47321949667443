import React from 'react'

export const VisaSvg = () => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none">
    <path
      d="M21.3139 30.3342H18.0177L20.0778 17.6735H23.3744L21.3139 30.3342ZM15.2446 17.6735L12.1022 26.3816L11.7303 24.5064L11.7306 24.5071L10.6215 18.8134C10.6215 18.8134 10.4874 17.6735 9.05787 17.6735H3.86272L3.80176 17.8879C3.80176 17.8879 5.39045 18.2184 7.24973 19.335L10.1135 30.3346H13.5479L18.7922 17.6735H15.2446ZM41.1713 30.3342H44.198L41.5591 17.6732H38.9094C37.6858 17.6732 37.3877 18.6167 37.3877 18.6167L32.4716 30.3342H35.9078L36.5949 28.4536H40.7852L41.1713 30.3342ZM37.5442 25.8557L39.2761 21.1177L40.2505 25.8557H37.5442ZM32.7294 20.7181L33.1998 17.9993C33.1998 17.9993 31.7482 17.4473 30.2351 17.4473C28.5993 17.4473 24.7148 18.1622 24.7148 21.6386C24.7148 24.9095 29.2739 24.9501 29.2739 26.6682C29.2739 28.3862 25.1845 28.0784 23.8349 26.995L23.3449 29.8378C23.3449 29.8378 24.8167 30.5527 27.0655 30.5527C29.3149 30.5527 32.7084 29.388 32.7084 26.2181C32.7084 22.9262 28.1082 22.6197 28.1082 21.1885C28.1086 19.757 31.3188 19.9409 32.7294 20.7181Z"
      fill="#2566AF"
    />
    <path
      d="M11.7306 24.5074L10.6215 18.8138C10.6215 18.8138 10.4874 17.6738 9.05786 17.6738H3.86272L3.80176 17.8882C3.80176 17.8882 6.29875 18.4057 8.69379 20.3445C10.9839 22.1977 11.7306 24.5074 11.7306 24.5074Z"
      fill="#E6A540"
    />
  </svg>
)
