import React from 'react'

import { DEFAULT_DATE_WITH_TIME_FORMAT } from '../../../constants/date'
import translations from '../../../translations/keys'
import { getFormattedDate } from '../../../utils/date'
import { CheckCircleIcon, CloseCircleIcon } from '../../assets/icons'
import Box from '../Box'
import Button from '../Button'
import { BUTTON_VARIANTS } from '../Button/Button.types'
import { ConfirmationStatus } from '../../../types/confirmationActions'

import * as Styled from './ConfirmationLabel.styles'
import { StyledDate } from './ConfirmationLabel.styles'
import { ConfirmationLabelProps, BoxSize } from './ConfirmationLabel.types'

export const ConfirmationLabel: React.FC<ConfirmationLabelProps> = ({
  id,
  status,
  date,
  onCancel,
  fullName,
  size = BoxSize.default,
  iconSize = 'default',
  isContractOwner = true,
  expandLabel,
  showLabel,
}) => {
  const isAccepted = status === ConfirmationStatus.ACCEPTED

  const handleCancel = () => {
    if (onCancel) {
      onCancel(id, status)
    }
  }

  return (
    <Styled.Wrapper onClick={showLabel} status={status} size={size} isContractOwner={isContractOwner}>
      {isAccepted ? (
        <CheckCircleIcon color="white" size={iconSize} />
      ) : (
        <CloseCircleIcon color="white" size={iconSize} />
      )}
      {expandLabel && (
        <>
          <Box minWidth={0} ml={1}>
            <Styled.User>
              {isAccepted ? translations.ACCEPTED_BY : translations.REJECTED_BY} {fullName}{' '}
              <StyledDate isContractOwner={isContractOwner} size={size}>
                {getFormattedDate(new Date(date), DEFAULT_DATE_WITH_TIME_FORMAT)}
              </StyledDate>
            </Styled.User>
          </Box>
          {onCancel && isContractOwner && (
            <Styled.Cancel size={size}>
              <Box ml={2}>
                <Button data-action variant={BUTTON_VARIANTS.TEXT} textColor="greyLight" onClick={handleCancel}>
                  {translations.DISCARD}
                </Button>
              </Box>
            </Styled.Cancel>
          )}
        </>
      )}
    </Styled.Wrapper>
  )
}
