import styled from 'styled-components'

import Box from '../../../../../../ui/Box'

export const StyledCell = styled(Box).attrs({
  pt: 2.5,
  pb: 2,
  pl: 4,
  pr: 4,
  width: 308,
})`
  border-width: 1px;
  border-style: solid;
  border-color: ${({ theme }) => theme.palette.strokeGrey};

  & ~ & {
    margin-left: 24px;
  }
`

export const StyledImgWrapper = styled(Box).attrs({
  mb: 1.5,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
})<{ blurred: boolean }>`
  width: 128px;
  height: 128px;
  ${({ blurred }) =>
    blurred &&
    `
  filter: blur(16px);
  filter:progid:DXImageTransform.Microsoft.Blur(PixelRadius='16');
  `}

  img {
    max-width: 100%;
    max-height: 100%;
  }
`

export const StyledSign = styled(Box).attrs({
  mt: -1.5,
})`
  cursor: pointer;
`
