import { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import translations from '../../../translations/keys'
import { CloseCircleIcon, DownloadIcon } from '../../assets/icons'
import * as SIGNING_SELECTORS from '../../../store/contracts/signing/signing.selectors'
import { openModal } from '../../../store/modals/modals.actions'
import { CONTRACT_SIGN_MODALS } from '../../constants/contractSign'
import { fetchDownloadContract } from '../../../store/contracts/download/download.actions'
import { ContractRouteParams } from '../../types/signing'
import { RecipientAuthentication } from '../../../types/recipients'
import { setSigningNotification } from '../../../store/contracts/signing/signing.actions'
import { SigningNotificationMessages } from '../../../store/contracts/signing/signing.types'
import { Variant } from '../../../types/notifications'

export const useActions = () => {
  const { contractId } = useParams<ContractRouteParams>()
  const dispatch = useDispatch()
  const isView = useSelector(SIGNING_SELECTORS.signingIsViewPageSelector)
  const isSuccess = useSelector(SIGNING_SELECTORS.signingContractFetchedFullySelector)
  const recipientAuthentication = useSelector(SIGNING_SELECTORS.signingRecipientAuthentication)
  const isExpired = useSelector(SIGNING_SELECTORS.signingContractIsExpiredSelector)

  const actions = useMemo(() => {
    if (!isSuccess) {
      return []
    }

    const download = {
      buttonIcon: 'download',
      label: translations.DOWNLOAD,
      onClick: () => {
        dispatch(fetchDownloadContract(contractId))
      },
      MobileIcon: DownloadIcon,
    }

    return isView
      ? [download]
      : [
          {
            buttonIcon: 'closeCircle',
            label: translations.DECLINE_TO_SIGN,
            onClick: () => {
              if (
                recipientAuthentication === RecipientAuthentication.EKYC ||
                recipientAuthentication === RecipientAuthentication.EKYC_ID
              ) {
                dispatch(openModal(CONTRACT_SIGN_MODALS.SIGNING_ACTIONS_EKYC_VERIFICATION))
              } else if (isExpired) {
                dispatch(
                  setSigningNotification({
                    message: SigningNotificationMessages.EXPIRED_CONTRACT_NOTIFICATION,
                    variant: Variant.WARNING,
                  })
                )
              } else {
                dispatch(openModal(CONTRACT_SIGN_MODALS.DECLINE_SIGNING))
              }
            },
            MobileIcon: CloseCircleIcon,
          },
          download,
        ]
  }, [isView, isSuccess, recipientAuthentication, isExpired])

  return actions
}
