import { FetchFailureAction, FETCH_STATUSES } from '../../types/fetch'
import { createReducer } from '../createReducer'

import * as ACTIONS from './signatures.actions'
import * as TYPES from './signatures.types'

type Actions =
  | FetchFailureAction
  | TYPES.FetchSignaturesListSuccessAction
  | TYPES.FetchCreateSignatureSuccessAction
  | TYPES.FetchDeleteSuccessAction

const initialState: TYPES.SignaturesState = {
  validateSignature: {
    fetchStatus: FETCH_STATUSES.IDLE,
  },
  createSignature: {
    fetchStatus: FETCH_STATUSES.IDLE,
  },
  list: {
    fetchStatus: FETCH_STATUSES.IDLE,
    data: [],
  },
  deleteSignature: {
    fetchStatus: FETCH_STATUSES.IDLE,
  },
}

export const signatures = createReducer<TYPES.SignaturesState, Actions>(initialState, {
  [ACTIONS.fetchValidateSignatureTypes.request]: (state) => ({
    ...state,
    validateSignature: {
      ...state.validateSignature,
      fetchStatus: FETCH_STATUSES.REQUEST,
    },
  }),
  [ACTIONS.fetchValidateSignatureTypes.success]: (state) => ({
    ...state,
    validateSignature: {
      ...state.validateSignature,
      fetchStatus: FETCH_STATUSES.SUCCESS,
    },
  }),
  [ACTIONS.fetchValidateSignatureTypes.failure]: (state, action) => ({
    ...state,
    validateSignature: {
      ...state.validateSignature,
      fetchStatus: FETCH_STATUSES.FAILURE,
      error: (action as FetchFailureAction<TYPES.ValidateSignatureError>).payload,
    },
  }),
  [TYPES.SignaturesActions.CLEAN_VALIDATE_SIGNATURE]: (state) => ({
    ...state,
    validateSignature: initialState.validateSignature,
  }),
  [ACTIONS.fetchCreateSignatureTypes.request]: (state) => ({
    ...state,
    createSignature: {
      ...state.createSignature,
      fetchStatus: FETCH_STATUSES.REQUEST,
    },
  }),
  [ACTIONS.fetchCreateSignatureTypes.success]: (state, action) => {
    const act = action as TYPES.FetchCreateSignatureSuccessAction
    return {
      ...state,
      createSignature: {
        ...state.createSignature,
        fetchStatus: FETCH_STATUSES.SUCCESS,
      },
      list: {
        ...state.list,
        data: [...state.list.data, act.payload.body],
      },
    }
  },
  [ACTIONS.fetchCreateSignatureTypes.failure]: (state, action) => ({
    ...state,
    createSignature: {
      ...state.createSignature,
      fetchStatus: FETCH_STATUSES.FAILURE,
      error: (action as FetchFailureAction).payload,
    },
  }),
  [TYPES.SignaturesActions.CLEAN_CREATE_SIGNATURE]: (state) => ({
    ...state,
    createSignature: initialState.createSignature,
  }),
  [ACTIONS.fetchSignaturesListTypes.request]: (state) => ({
    ...state,
    list: {
      ...state.list,
      fetchStatus: FETCH_STATUSES.REQUEST,
    },
  }),
  [ACTIONS.fetchSignaturesListTypes.success]: (state, action) => ({
    ...state,
    list: {
      ...state.list,
      fetchStatus: FETCH_STATUSES.SUCCESS,
      data: (action as TYPES.FetchSignaturesListSuccessAction).payload.body,
    },
  }),
  [ACTIONS.fetchSignaturesListTypes.failure]: (state, action) => ({
    ...state,
    list: {
      ...state.list,
      fetchStatus: FETCH_STATUSES.FAILURE,
      error: (action as FetchFailureAction).payload,
    },
  }),
  [TYPES.SignaturesActions.CLEAN_SIGNATURES_LIST]: (state) => ({
    ...state,
    list: initialState.list,
  }),
  [ACTIONS.fetchDeleteSignatureTypes.request]: (state) => ({
    ...state,
    deleteSignature: {
      ...state.deleteSignature,
      fetchStatus: FETCH_STATUSES.REQUEST,
    },
  }),
  [ACTIONS.fetchDeleteSignatureTypes.success]: (state, action) => {
    const act = action as TYPES.FetchDeleteSuccessAction
    return {
      ...state,
      deleteSignature: {
        ...state.deleteSignature,
        fetchStatus: FETCH_STATUSES.SUCCESS,
      },
      list: {
        ...state.list,
        data: state.list.data.filter(({ id }) => id !== act.payload.id),
      },
    }
  },
  [ACTIONS.fetchDeleteSignatureTypes.failure]: (state, action) => ({
    ...state,
    deleteSignature: {
      ...state.deleteSignature,
      fetchStatus: FETCH_STATUSES.FAILURE,
      error: (action as FetchFailureAction).payload,
    },
  }),
  [TYPES.SignaturesActions.CLEAN_DELETE_SIGNATURE]: (state) => ({
    ...state,
    deleteSignature: initialState.deleteSignature,
  }),
})
