import React, { useEffect, useRef } from 'react'

import { SuccessIcon, WarningIcon, CloseIcon } from '../../assets/icons'

import { StyledClose, StyledMessage, StyledWrapper } from './NotificationBadge.styles'
import { NotificationBadgeProps } from './NotificationBadge.types'

const AUTO_CLOSE_TIME = 10000

export const NotificationBadge: React.FC<NotificationBadgeProps> = ({
  message,
  isShow,
  isAutoClose = false,
  onClose,
  variant = 'success',
}) => {
  const timer = useRef<ReturnType<typeof setTimeout> | null>(null)

  useEffect(() => {
    if (isAutoClose) {
      clearTimeout(timer.current as ReturnType<typeof setTimeout>)
      timer.current = setTimeout(() => onClose(), AUTO_CLOSE_TIME)
    }

    return () => clearTimeout(timer.current as ReturnType<typeof setTimeout>)
  }, [message])

  return (
    <StyledWrapper isShow={isShow}>
      {variant === 'success' ? (
        <SuccessIcon size="small" color="green" />
      ) : (
        <WarningIcon size="extraSmall" color="errorColor" />
      )}
      <StyledMessage>{message}</StyledMessage>
      <StyledClose onClick={onClose}>
        <CloseIcon size="extraSmall" />
      </StyledClose>
    </StyledWrapper>
  )
}
