import React, { MutableRefObject, useEffect, useRef, useState } from 'react'

import Guides from '../Guides'
import Popover from '../Popover'
import Button from '../Button'
import { BUTTON_VARIANTS } from '../Button/Button.types'
import Typography from '../Typography'
import { TYPOGRAPHY_NAMES } from '../Typography/Typography.types'
import { PointerModes } from '../DocViewer/DocViewer.types'
import Resizer from '../Resizer'

import { SignatureBoxProps } from './SignatureBox.types'
import * as Styled from './SignatureBox.styles'

export const SignatureBox: React.FC<SignatureBoxProps> = ({
  id,
  type,
  x,
  y,
  width,
  height,
  title,
  subtitle,
  caption,
  isSelected,
  isEditable,
  isMoving,
  isResizing,
  signSrc,
  actions,
  readonly,
  fullName,
  onSign,
}) => {
  const [visibleActions, setVisibleActions] = useState(false)
  const [ellipsisIsActive, setEllipsActive] = useState(false)
  const [titleRef, setTitleRef] = useState<HTMLDivElement | null>(null)
  const boxRef: MutableRefObject<null> = useRef(null)

  const showActions = Boolean(signSrc && actions)
  const showTooltip = (Boolean(caption) || ellipsisIsActive) && !visibleActions && !fullName
  const tooltipText = ellipsisIsActive ? title : caption

  const handlePopoverToggle = () => {
    setVisibleActions((prevValue) => !prevValue)
  }
  const handlePopoverClose = () => {
    setVisibleActions(false)
  }
  const handleClick = () => {
    if (showActions) {
      handlePopoverToggle()
    } else if (onSign) {
      onSign()
    }
  }

  const renderContent = () => {
    if (signSrc) {
      return (
        <Styled.SignatureImgWrapper>
          <Styled.SignatureImg src={signSrc} alt={title} />
        </Styled.SignatureImgWrapper>
      )
    }

    return (
      <>
        <Styled.BoxText ref={setTitleRef} type={type}>
          {title}
        </Styled.BoxText>
        {subtitle && <Styled.BoxText type={type}>{subtitle}</Styled.BoxText>}
      </>
    )
  }

  useEffect(() => {
    if (titleRef && type === 'sign') {
      setEllipsActive(titleRef.offsetWidth < titleRef.scrollWidth)
    }
  }, [titleRef, width])

  return (
    <Styled.Box
      ref={boxRef}
      data-type={PointerModes.DRAG}
      data-readonly={!isEditable ? true : undefined}
      data-id={id}
      style={{ left: x, top: y, width, height }}
      isSignable={!!onSign}
      isSelected={isSelected}
      isMoving={isMoving}
      isResizing={isResizing}
      onClick={handleClick}
      readonly={readonly}
      signSrc={signSrc}
    >
      <Styled.BoxContent signSrc={signSrc}>{renderContent()}</Styled.BoxContent>
      {isMoving && <Guides asymmetric={type === 'sign'} />}
      {!readonly && (signSrc || isSelected) && <Resizer isEditable={isEditable} />}
      {showTooltip && <Styled.Tooltip>{tooltipText}</Styled.Tooltip>}
      {fullName && (
        <Styled.FullName>
          <Typography name={TYPOGRAPHY_NAMES.body14} color={readonly ? 'grey' : undefined}>
            {fullName}
          </Typography>
        </Styled.FullName>
      )}
      {!readonly && showActions && (
        <Popover
          targetRef={boxRef}
          visible={visibleActions}
          placement="top-end"
          padding="8px"
          width="auto"
          offsetTop={4}
          backgroundColor="blueDark"
          onClose={handlePopoverClose}
        >
          <Styled.Actions>
            {actions &&
              actions.map((action) => (
                <Styled.Action key={action.name}>
                  <Button
                    variant={BUTTON_VARIANTS.INACTIVE}
                    textColor="white"
                    icon={action.icon}
                    onClick={action.onClick}
                  >
                    {action.name}
                  </Button>
                </Styled.Action>
              ))}
          </Styled.Actions>
        </Popover>
      )}
    </Styled.Box>
  )
}
