import React from 'react'
import { useTranslation } from 'react-i18next'

import translations from '../../../../translations/keys'
import { RecipientActions } from '../../../../types/recipients'
import Box from '../../Box'

import * as Styled from './Indicators.styles'

export const Indicators: React.FC = () => {
  const { t } = useTranslation()
  return (
    <Styled.Indicators data-testid="indicators-div">
      <Box display="flex" mr={2} mbTablet={2}>
        <Styled.Indicator data-testid="indicator-view" action={RecipientActions.VIEW} />
        {t(translations.VIEW)}
      </Box>
      <Box display="flex" mr={2} mbTablet={2}>
        <Styled.Indicator data-testid="indicator-initial" action={RecipientActions.INITIAL} />
        {t(translations.INITIAL)}
      </Box>
      <Box display="flex">
        <Styled.Indicator data-testid="indicator-sign" action={RecipientActions.SIGN} />
        {t(translations.SIGN)}
      </Box>
    </Styled.Indicators>
  )
}
