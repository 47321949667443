import React from 'react'

import Typography from '../../../Typography'
import { TYPOGRAPHY_NAMES } from '../../../Typography/Typography.types'
import Box from '../../../Box'

import { StyledItem } from './DropdownItem.styles'
import { DropdownItemProps } from './DropdownItem.types'

export const DropdownItem: React.FC<DropdownItemProps> = ({ label, Icon, iconColor = 'grey', disabled }) => (
  <StyledItem disabled={disabled}>
    {Icon && (
      <Box mr={1} display="flex" alignItems="center" justifyContent="center">
        <Icon color={iconColor} />
      </Box>
    )}
    <Typography name={TYPOGRAPHY_NAMES.body14}>{label}</Typography>
  </StyledItem>
)
