import { FETCH_STATUSES } from '../../../types/fetch'
import { RootState } from '../../rootReducer.types'

export const briefListIsLoadingSelector = (state: RootState) =>
  state.templates.briefList.fetchStatus === FETCH_STATUSES.IDLE ||
  state.templates.briefList.fetchStatus === FETCH_STATUSES.REQUEST

export const briefListFetchStatusSelector = (state: RootState) => state.templates.briefList.fetchStatus

export const briefListErrorSelector = (state: RootState) => state.templates.briefList.error

export const briefListDataSelector = (state: RootState) => state.templates.briefList.data
