import { useRef, useEffect } from 'react'

export const useDidUpdate = (cb: () => void, deps: Array<any>) => {
  const hasMount = useRef(false)
  useEffect(() => {
    if (hasMount.current) {
      cb()
    } else {
      hasMount.current = true
    }
  }, deps)
}
