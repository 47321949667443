import React from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { Form, Formik, FormikHelpers } from 'formik'
import { useTranslation } from 'react-i18next'

import omit from '../../../../utils/omit'
import { addContactInitialValues } from '../../../../initialValues/addContactInitialValues'
import translations from '../../../../translations/keys'
import { AddContactFields, AddContactsForm } from '../../../../types/addContact'
import { addContactValidationSchema } from '../../../../validation/addContact/addContactValidaitonSchema'
import { EMAIL_MAX_LENGTH } from '../../../../validation/emailValidationSchema'
import { NAME_MAX_LENGTH } from '../../../../validation/nameValidationSchema'
import { fetchCreateContact } from '../../../../store/contacts/contacts.actions'
import { ContactsIcon } from '../../../assets/ContactsIcon'
import Box from '../../../ui/Box'
import FormCheckbox from '../../../ui/Forms/FormCheckbox'
import FormTextInput from '../../../ui/Forms/FormTextInput'
import Button from '../../../ui/Button'
import Modal from '../..//Modal'
import Typography from '../../../ui/Typography'
import { TYPOGRAPHY_NAMES } from '../../../ui/Typography/Typography.types'
import FormPhone from '../../../ui/Forms/FormPhone'
import { DEPARTMENT_MAX_LENGTH } from '../../../../validation/departmentValidationSchema'

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 320px;
  padding-top: 24px;
`
const StyledTitle = styled(Typography)`
  margin-top: 40px;
  margin-bottom: 24px;
`

interface AddContactModalProps {
  onClose: () => void
}

export const AddContactModal: React.FC<AddContactModalProps> = ({ onClose }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const handleSubmit = (values: AddContactsForm, { resetForm }: FormikHelpers<AddContactsForm>) => {
    const preparedValues = omit([AddContactFields.addMore], values)

    dispatch(fetchCreateContact(preparedValues))

    if (values.addMore) {
      resetForm()
    } else {
      onClose()
    }
  }

  return (
    <Modal onClose={onClose}>
      <StyledContent>
        <ContactsIcon />
        <StyledTitle name={TYPOGRAPHY_NAMES.H1}>{t(translations.ADD_NEW_CONTACT)}</StyledTitle>
        <Formik
          validationSchema={addContactValidationSchema}
          initialValues={addContactInitialValues}
          onSubmit={handleSubmit}
        >
          {({ dirty }) => (
            <Form>
              <FormTextInput
                name={AddContactFields.fullName}
                label={t(translations.FULL_NAME_LABEL)}
                placeholder={t(translations.FULL_NAME_PLACEHOLDER)}
                maxLength={NAME_MAX_LENGTH}
              />
              <FormTextInput
                name={AddContactFields.email}
                label={t(translations.EMAIL_LABEL)}
                placeholder={t(translations.EMAIL_PLACEHOLDER)}
                maxLength={EMAIL_MAX_LENGTH}
                trim
              />
              <FormPhone
                name={AddContactFields.phone}
                label={`${t(translations.PHONE_LABEL)} (${t(translations.OPTIONAL)})`}
              />
              <FormTextInput
                name={AddContactFields.department}
                label={`${t(translations.DEPARTMENT_LABEL)} (${t(translations.OPTIONAL)})`}
                placeholder={t(translations.DEPARTMENT_PLACEHOLDER)}
                maxLength={DEPARTMENT_MAX_LENGTH}
              />
              <Box textAlign="center" mt={2} mb={3}>
                <FormCheckbox name={AddContactFields.addMore} label={t(translations.ADD_ANOTHER)} />
              </Box>
              <Box mb={3}>
                <Button fullWidth type="submit" disabled={!dirty}>
                  {t(translations.ADD_CONTACT)}
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      </StyledContent>
    </Modal>
  )
}
