import React from 'react'

import MainTemplate from '../../components/Main/MainTemplate'

import { SubscriptionPlans as SubscriptionPlansView } from './SubscriptionPlans'

export const SubscriptionPlans = () => (
  <MainTemplate>
    <SubscriptionPlansView />
  </MainTemplate>
)
