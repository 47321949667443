import React from 'react'

export const OneDriveSvg: React.FC = (props) => (
  <svg width="72" height="72" viewBox="0 0 72 72" fill="none" {...props}>
    <path
      d="M28.402 26.3878V26.3858L41.838 34.4338L49.8441 31.0638C51.4713 30.3609 53.2255 29.9994 54.9981 30.0018C55.2941 30.0018 55.5861 30.0158 55.8761 30.0338C54.9139 26.2803 52.8821 22.8871 50.0276 20.2667C47.1731 17.6463 43.6189 15.9115 39.797 15.2732C35.975 14.6349 32.05 15.1206 28.4989 16.6712C24.9478 18.2219 21.9236 20.7706 19.7939 24.0078L19.9979 24.0018C22.967 23.9979 25.8779 24.8244 28.402 26.3878Z"
      fill="#0364B8"
    />
    <path
      d="M28.404 26.386C25.8792 24.8229 22.9675 23.9971 19.998 24.002L19.794 24.008C16.888 24.0443 14.0468 24.8718 11.5757 26.4014C9.10464 27.9311 7.09705 30.1052 5.76863 32.69C4.44022 35.2749 3.84121 38.1728 4.03595 41.0725C4.2307 43.9722 5.21184 46.764 6.87395 49.148L18.718 44.162L23.986 41.946L35.712 37.01L41.836 34.434L28.404 26.386Z"
      fill="#0078D4"
    />
    <path
      d="M55.8768 30.033C55.5846 30.0125 55.2918 30.0019 54.9988 30.001C53.2268 29.9995 51.4733 30.3617 49.8469 31.065L41.8389 34.433L44.1609 35.823L51.7708 40.381L55.0908 42.369L66.4448 49.169C67.4765 47.254 68.0111 45.1107 67.9998 42.9356C67.9884 40.7604 67.4314 38.6228 66.3799 36.7187C65.3283 34.8146 63.8158 33.2048 61.9808 32.0367C60.1458 30.8686 58.0471 30.1797 55.8768 30.033Z"
      fill="#1490DF"
    />
    <path
      d="M55.0912 42.3696L51.7712 40.3816L44.1612 35.8216L41.8412 34.4336L35.7152 37.0096L23.9891 41.9456L18.7191 44.1616L6.87109 49.1476C8.34375 51.2646 10.307 52.9938 12.5931 54.1874C14.8792 55.3809 17.4202 56.0034 19.9991 56.0015H54.9992C57.3462 56.0024 59.6496 55.3677 61.6649 54.1648C63.6801 52.9619 65.3321 51.2357 66.4452 49.1696L55.0912 42.3696Z"
      fill="#28A8EA"
    />
  </svg>
)
