import { FC } from 'react'

import {
  AddIcon,
  ChevronLeftIcon,
  ContactIcon,
  DeleteIcon,
  FileIcon,
  RoundCheckIcon,
  RoundCrossIcon,
  SignatureIcon,
  CalendarIcon,
  FilterIcon,
  FilledCloseCircleIcon,
  ReuploadIcon,
  InteractionIcon,
  DownloadIcon,
  StopIcon,
  UsersIcon,
  DiscardIcon,
  EyeClosedIcon,
  EyeOpenedIcon,
  CheckCircleIcon,
  CloseCircleIcon,
} from '../../assets/icons'
import { SvgIconProps } from '../SvgIcon/SvgIcon.types'
import { FolderAddIcon } from '../../assets/icons/FolderAddIcon'
import { HelpIcon } from '../../assets/HelpIcon'

import { EyeIcon } from './EyeIcon'
import { TriangleIcon } from './TriangleIcon'
import { CheckIcon } from './CheckIcon'
import { InvisibleEyeIcon } from './InvisibleEye'
import { EditIcon } from './EditIcon'
import { SearchIcon } from './SearchIcon'
import { ReloadIcon } from './ReloadIcon'

export const inputIcons: {
  [key: string]: FC<SvgIconProps>
} = {
  eye: EyeIcon,
  invisibleEye: InvisibleEyeIcon,
  triangle: TriangleIcon,
  check: CheckIcon,
  checkCircle: CheckCircleIcon,
  edit: EditIcon,
  search: SearchIcon,
  reload: ReloadIcon,
  closeCircle: CloseCircleIcon,
  roundCross: RoundCrossIcon,
  add: AddIcon,
  roundCheck: RoundCheckIcon,
  delete: DeleteIcon,
  folderAdd: FolderAddIcon,
  signature: SignatureIcon,
  file: FileIcon,
  contact: ContactIcon,
  chevronLeft: ChevronLeftIcon,
  calendar: CalendarIcon,
  filter: FilterIcon,
  filledCloseCircle: FilledCloseCircleIcon,
  reupload: ReuploadIcon,
  help: HelpIcon,
  interaction: InteractionIcon,
  download: DownloadIcon,
  stop: StopIcon,
  users: UsersIcon,
  discard: DiscardIcon,
  eyeOpened: EyeOpenedIcon,
  eyeClosed: EyeClosedIcon,
}
