import { combineEpics, Epic, ofType } from 'redux-observable'
import { catchError, map, mergeMap, takeUntil } from 'rxjs/operators'

import { profileService } from '../../api'
import { catchFetchError } from '../../utils/catchFetchError'
import { prepareFormRequestBody } from '../../utils/formik/prepareFormRequestBody'
import { prepareFormInitialValues } from '../../utils/formik/prepareFormInitialValues'
import { addPhonePlus } from '../../utils/phone/addPhonePlus'
import { verifyPasswordPasswordTokenSelector } from '../verifyPassword/verifyPassword.selectors'
import { VerifyPasswordKeys } from '../../types/verifyPassword'

import * as ACTIONS from './editProfile.actions'
import { editProfilePhoneSelector } from './editProfile.selectors'

const fetchChangePhone: Epic = (action$) =>
  action$.pipe(
    ofType(ACTIONS.fetchEditProfileChangePhoneTypes.request),
    mergeMap(({ payload }) =>
      profileService.fetchResetPhone({ phone: addPhonePlus(payload.phone) }).pipe(
        map(() => ACTIONS.fetchEditProfileChangePhoneSuccess(payload.phone)),
        catchError(catchFetchError(ACTIONS.fetchEditProfileChangePhoneFailure)),
        takeUntil(
          action$.pipe(
            ofType(ACTIONS.fetchEditProfileChangePhoneTypes.success, ACTIONS.fetchEditProfileChangePhoneTypes.failure)
          )
        )
      )
    )
  )
const fetchConfirmPhone: Epic = (action$, state$) =>
  action$.pipe(
    ofType(ACTIONS.fetchEditProfileConfirmPhoneTypes.request),
    mergeMap(({ payload }) =>
      profileService
        .fetchVerifyPhone({
          code: payload.verificationCode,
          phone: addPhonePlus(editProfilePhoneSelector(state$.value).phoneNumber),
        })
        .pipe(
          map(() => ACTIONS.fetchEditProfileConfirmPhoneSuccess(editProfilePhoneSelector(state$.value).phoneNumber)),
          catchError(catchFetchError(ACTIONS.fetchEditProfileConfirmPhoneFailure)),
          takeUntil(
            action$.pipe(
              ofType(
                ACTIONS.fetchEditProfileConfirmPhoneTypes.success,
                ACTIONS.fetchEditProfileConfirmPhoneTypes.failure
              )
            )
          )
        )
    )
  )
const fetchChangeEmail: Epic = (action$, state$) =>
  action$.pipe(
    ofType(ACTIONS.fetchEditProfileChangeEmailTypes.request),
    mergeMap(({ payload }) =>
      profileService
        .fetchResetEmail(
          {
            email: payload.email,
          },
          {
            verify: verifyPasswordPasswordTokenSelector(VerifyPasswordKeys.EDIT_EMAIL)(state$.value),
          }
        )
        .pipe(
          map(() => ACTIONS.fetchEditProfileChangeEmailSuccess(payload.email)),
          catchError(catchFetchError(ACTIONS.fetchEditProfileChangeEmailFailure)),
          takeUntil(
            action$.pipe(
              ofType(ACTIONS.fetchEditProfileChangeEmailTypes.success, ACTIONS.fetchEditProfileChangeEmailTypes.failure)
            )
          )
        )
    )
  )
const fetchEditProfile: Epic = (action$) =>
  action$.pipe(
    ofType(ACTIONS.fetchEditProfileTypes.request),
    mergeMap(({ payload }) =>
      profileService.fetchEditProfile(prepareFormRequestBody(payload.body)).pipe(
        map(() => ACTIONS.fetchEditProfileSuccess()),
        catchError(catchFetchError(ACTIONS.fetchEditProfileFailure)),
        takeUntil(action$.pipe(ofType(ACTIONS.fetchEditProfileTypes.success, ACTIONS.fetchEditProfileTypes.failure)))
      )
    )
  )
const fetchResendVerification: Epic = (action$) =>
  action$.pipe(
    ofType(ACTIONS.fetchEditProfileResendVerificationTypes.request),
    mergeMap(() =>
      profileService.fetchResendEmailReset().pipe(
        map(() => ACTIONS.fetchEditProfileResendVerificationSuccess()),
        catchError(catchFetchError(ACTIONS.fetchEditProfileResendVerificationFailure)),
        takeUntil(
          action$.pipe(
            ofType(
              ACTIONS.fetchEditProfileResendVerificationTypes.success,
              ACTIONS.fetchEditProfileResendVerificationTypes.failure
            )
          )
        )
      )
    )
  )
const fetchUnconfirmedEmail: Epic = (action$) =>
  action$.pipe(
    ofType(ACTIONS.fetchEditProfileUnconfirmedEmailTypes.request),
    mergeMap(() =>
      profileService.fetchUnconfirmedEmail().pipe(
        map((action) => ACTIONS.fetchEditProfileUnconfirmedEmailSuccess(action?.email)),
        catchError(catchFetchError(ACTIONS.fetchEditProfileUnconfirmedEmailFailure)),
        takeUntil(
          action$.pipe(
            ofType(
              ACTIONS.fetchEditProfileUnconfirmedEmailTypes.success,
              ACTIONS.fetchEditProfileUnconfirmedEmailTypes.failure
            )
          )
        )
      )
    )
  )
const fetchPreventEmailUpdate: Epic = (action$) =>
  action$.pipe(
    ofType(ACTIONS.fetchEditProfilePreventEmailUpdateTypes.request),
    mergeMap(() =>
      profileService.fetchPreventEmailUpdate().pipe(
        map(() => ACTIONS.fetchEditProfilePreventEmailUpdateSuccess()),
        catchError(catchFetchError(ACTIONS.fetchEditProfilePreventEmailUpdateFailure)),
        takeUntil(
          action$.pipe(
            ofType(
              ACTIONS.fetchEditProfilePreventEmailUpdateTypes.success,
              ACTIONS.fetchEditProfilePreventEmailUpdateTypes.failure
            )
          )
        )
      )
    )
  )
const fetchInitialData: Epic = (action$) =>
  action$.pipe(
    ofType(ACTIONS.fetchEditProfileInitialDataTypes.request),
    mergeMap(() =>
      profileService.fetchEditProfileInitialData().pipe(
        map((action) => ACTIONS.fetchEditProfileInitialDataSuccess(prepareFormInitialValues(action))),
        catchError(catchFetchError(ACTIONS.fetchEditProfileInitialDataFailure)),
        takeUntil(
          action$.pipe(
            ofType(ACTIONS.fetchEditProfileInitialDataTypes.success, ACTIONS.fetchEditProfileInitialDataTypes.failure)
          )
        )
      )
    )
  )

export const editProfileEpics = combineEpics(
  fetchChangePhone,
  fetchConfirmPhone,
  fetchChangeEmail,
  fetchEditProfile,
  fetchResendVerification,
  fetchUnconfirmedEmail,
  fetchPreventEmailUpdate,
  fetchInitialData
)
