import React from 'react'
import { useTranslation } from 'react-i18next'

import translations from '../../../translations/keys'
import Box from '../Box'
import Button from '../Button'
import { BUTTON_VARIANTS } from '../Button/Button.types'
import { ConfirmationStatus } from '../../../types/confirmationActions'

import { Wrapper } from './ConfirmationActions.styles'
import { ConfirmationActionsProps } from './ConfirmationActions.types'

export const ConfirmationActions: React.FC<ConfirmationActionsProps> = ({ onConfirm, id }) => {
  const { t } = useTranslation()
  const handleAccept = () => {
    onConfirm(id, ConfirmationStatus.ACCEPTED)
  }

  const handleReject = () => {
    onConfirm(id, ConfirmationStatus.REJECTED)
  }

  return (
    <Wrapper data-testid="confirmation-actions">
      <Button
        data-testid="confirmation-actions-accept"
        data-action
        variant={BUTTON_VARIANTS.INACTIVE}
        icon="checkCircle"
        iconColor="green"
        textColor="white"
        onClick={handleAccept}
      >
        {t(translations.ACCEPT)}
      </Button>
      <Box ml={2} display="flex" alignItems="center">
        <Button
          data-testid="confirmation-actions-reject"
          data-action
          variant={BUTTON_VARIANTS.INACTIVE}
          icon="closeCircle"
          iconColor="errorColor"
          textColor="white"
          onClick={handleReject}
        >
          {t(translations.REJECT)}
        </Button>
      </Box>
    </Wrapper>
  )
}
