import { FetchFailureAction, FetchFailurePayload } from '../../../types/fetch'
import { Option } from '../../../types/options'
import { UploadFolderBody } from '../../../types/uploadFolder'
import { createFetchActionTypes } from '../../../utils/createFetchActionTypes'

import * as TYPES from './folders.types'

export const fetchTemplatesFoldersTypes = createFetchActionTypes(TYPES.FoldersActions.FETCH_TEMPLATES_FOLDERS)
export const fetchTemplatesUploadFolderTypes = createFetchActionTypes(TYPES.FoldersActions.UPLOAD_TEMPLATES_FOLDER)

export const createTemplatesFolder = (folder: Option): TYPES.CreateFolder => ({
  type: TYPES.FoldersActions.CREATE_TEMPLATES_FOLDER,
  payload: { folder },
})

export const cleanTemplatesFolders = () => ({
  type: TYPES.FoldersActions.CLEAN_TEMPLATES_FOLDER,
})

export const fetchTemplatesFolders = (): TYPES.FetchFoldersAction => ({
  type: fetchTemplatesFoldersTypes.request,
})
export const fetchTemplatesFoldersSuccess = (data: Option[]): TYPES.FetchFoldersSuccessAction => ({
  type: fetchTemplatesFoldersTypes.success,
  payload: { data },
})
export const fetchTemplatesFoldersFailure = (payload: FetchFailurePayload): FetchFailureAction => ({
  type: fetchTemplatesFoldersTypes.failure,
  payload,
})

export const fetchTemplatesUploadFolder = (folder: UploadFolderBody) => ({
  type: fetchTemplatesUploadFolderTypes.request,
  payload: { folder },
})
export const fetchTemplatesUploadFolderSuccess = () => ({
  type: fetchTemplatesUploadFolderTypes.success,
})
export const fetchTemplatesUploadFolderFailure = (payload: FetchFailurePayload): FetchFailureAction => ({
  type: fetchTemplatesUploadFolderTypes.failure,
  payload,
})
