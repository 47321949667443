import { Action } from 'redux'

import { FetchFailurePayload, FETCH_STATUSES } from '../../types/fetch'
import { RegistrationFormValues, RegistrationRequestBody, RegistrationResponse } from '../../types/registration'

export interface RegistrationState {
  signUpFetchStatus: FETCH_STATUSES
  signUpError?: FetchFailurePayload
  confirmPhoneFetchStatus: FETCH_STATUSES
  confirmPhoneError?: FetchFailurePayload
  resendCodeFetchStatus: FETCH_STATUSES
  resendCodeError?: FetchFailurePayload
  userId: string
  registrationForm?: RegistrationFormValues
  linkId: string
}

export enum RegistrationActions {
  'FETCH_REGISTRATION' = 'FETCH_REGISTRATION',
  'FETCH_REGISTRATION_CLEAN_FETCH_STATUS' = 'FETCH_REGISTRATION_CLEAN_FETCH_STATUS',
  'FETCH_REGISTRATION_PHONE_CONFIRM' = 'FETCH_REGISTRATION_PHONE_CONFIRM',
  'FETCH_REGISTRATION_RESEND_CODE' = 'FETCH_REGISTRATION_RESEND_CODE',
  'RESET_REGISTRATION' = 'RESET_REGISTRATION',
}

export interface FetchRegistrationAction extends Action {
  payload: {
    body: RegistrationRequestBody
    registrationForm: RegistrationFormValues
  }
}

export interface FetchRegistrationSuccessAction extends Action {
  payload: {
    response: RegistrationResponse
    registrationForm: RegistrationFormValues
  }
}

export interface FetchRegistrationPhoneConfirmAction extends Action {
  payload: {
    code: string
  }
}

export type FetchRegistrationPhoneConfirmSuccessAction = Action

export interface FetchRegistrationResendCodeAction extends Action {
  payload: { userId: string }
}

export interface FetchRegistrationResendCodeSuccessAction extends Action {
  payload: { linkId: string }
}
