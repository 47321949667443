export type Method = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE'

export enum BreakerState {
  open = 'open',
  close = 'close',
}

export interface Options {
  path: string
  method: Method
}
