import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { CONTRACT_MODALS } from '../../../../../constants/contract'
import { CONTRACT_OWNER_VIEW_MODALS } from '../../../../constants/contractOwnerView'
import { modalsVisibleSelector } from '../../../../../store/modals/modals.selectors'
import { closeModal } from '../../../../../store/modals/modals.actions'
import DownloadProgressModal from '../../../../components/Modals/ContractModals/DownloadProgressModal'
import VoidContractModal from '../../../../components/Modals/ContractOwnerView/VoidContractModal'
import ResendContractModal from '../../../../components/Modals/ContractModals/ResendContractModal'
import SaveAnnotationPromptModal from '../../../../components/Modals/ContractModals/SaveAnnotationPromptModal'
import ClearAndContinueContractModal from '../../../../components/Modals/ContractOwnerView/ClearAndContinueContractModal'
import { CONTRACT_PREVIEW_MODALS } from '../../../../constants/contractPreview'
import { SigningOrderModal } from '../../../../components/Modals/ContractPreviewModals/SigningOrderModal/SigningOrderModal'


export interface ModalsProps {
  onResend: () => void
}

export const Modals: React.FC<ModalsProps> = ({ onResend }) => {
  const dispatch = useDispatch()

  const showDownloadProgress = useSelector(modalsVisibleSelector(CONTRACT_MODALS.CONTRACT_DOWNLOAD_PROGRESS))
  const onCloseDownloadProgress = () => {
    dispatch(closeModal(CONTRACT_MODALS.CONTRACT_DOWNLOAD_PROGRESS))
  }

  // const showSigningOrderModal = useSelector(modalsVisibleSelector(CONTRACT_PREVIEW_MODALS.SIGNING_ORDER_MODAL))
  // const handleShowSigningOrderClose = () => {
  //   dispatch(closeModal(CONTRACT_PREVIEW_MODALS.SIGNING_ORDER_MODAL))
  // }


  const showClearAndContinueContract = useSelector(
    modalsVisibleSelector(CONTRACT_OWNER_VIEW_MODALS.OWNER_CLEAR_AND_CONTINUE_CONTRACT)
  )
  const onCloseClearAndContinueContract = () => {
    dispatch(closeModal(CONTRACT_OWNER_VIEW_MODALS.OWNER_CLEAR_AND_CONTINUE_CONTRACT))
  }

  const showVoidContract = useSelector(modalsVisibleSelector(CONTRACT_OWNER_VIEW_MODALS.OWNER_VOID_CONTRACT))
  const onCloseVoidContract = () => {
    dispatch(closeModal(CONTRACT_OWNER_VIEW_MODALS.OWNER_VOID_CONTRACT))
  }

  const showResendContract = useSelector(modalsVisibleSelector(CONTRACT_MODALS.CONTRACT_RESEND))
  const onCloseResendContract = () => {
    dispatch(closeModal(CONTRACT_MODALS.CONTRACT_RESEND))
  }

  const showSaveAnnotation = useSelector(modalsVisibleSelector(CONTRACT_MODALS.CONTRACT_SAVE_ANNOTATION_PROMPT))
  const onCloseSaveAnnotation = () => {
    dispatch(closeModal(CONTRACT_MODALS.CONTRACT_SAVE_ANNOTATION_PROMPT))
  }

  return (
    <>
      {showDownloadProgress && <DownloadProgressModal onClose={onCloseDownloadProgress} />}
      {showVoidContract && <VoidContractModal onClose={onCloseVoidContract} />}
      {showResendContract && <ResendContractModal onResend={onResend} onClose={onCloseResendContract} />}
      {showSaveAnnotation && <SaveAnnotationPromptModal onClose={onCloseSaveAnnotation} />}
      {showClearAndContinueContract && <ClearAndContinueContractModal onClose={onCloseClearAndContinueContract} />}
      {/* {showSigningOrderModal && <SigningOrderModal contractId={contractId} onClose={handleShowSigningOrderClose} />} */}
    </>
  )
}
