import { FetchFailureAction, FetchFailurePayload } from '../../types/fetch'
import { createFetchActionTypes } from '../../utils/createFetchActionTypes'

import * as TYPES from './apiIntegration.types'

export const fetchPublicTokenTypes = createFetchActionTypes(TYPES.ApiIntegrationActions.FETCH_PUBLIC_TOKEN)
export const fetchGeneratePublicTokenTypes = createFetchActionTypes(TYPES.ApiIntegrationActions.GENERATE_PUBLIC_TOKEN)

export const fetchPublicToken = (): TYPES.FetchPublicTokenAction => ({
  type: fetchPublicTokenTypes.request,
})

export const fetchPublicTokenSuccess = (publicToken: string): TYPES.FetchPublicTokenSuccessAction => ({
  type: fetchPublicTokenTypes.success,
  payload: { publicToken },
})

export const fetchPublicTokenFailure = (payload: FetchFailurePayload): FetchFailureAction => ({
  type: fetchPublicTokenTypes.failure,
  payload,
})

export const fetchGeneratePublicToken = (): TYPES.FetchPublicTokenAction => ({
  type: fetchGeneratePublicTokenTypes.request,
})

export const fetchGeneratePublicTokenSuccess = (publicToken: string): TYPES.FetchGeneratePublicTokenSuccessAction => ({
  type: fetchGeneratePublicTokenTypes.success,
  payload: { publicToken },
})

export const reset = (): TYPES.ResetAction => ({
  type: TYPES.ApiIntegrationActions.RESET,
})
