import { FetchFailureAction, FETCH_STATUSES } from '../../../types/fetch'
import { createReducer } from '../../createReducer'

import * as TYPES from './rename.types'
import * as ACTIONS from './rename.actions'

type Actions = FetchFailureAction

export const initialState: TYPES.RenameState = {
  fetchStatus: FETCH_STATUSES.IDLE,
  error: null,
}

export const rename = createReducer<TYPES.RenameState, Actions>(initialState, {
  [ACTIONS.fetchRenameTemplateTypes.request]: (state) => ({
    ...state,
    fetchStatus: FETCH_STATUSES.REQUEST,
  }),
  [ACTIONS.fetchRenameTemplateTypes.success]: (state) => ({
    ...state,
    fetchStatus: FETCH_STATUSES.SUCCESS,
    error: null,
  }),
  [ACTIONS.fetchRenameTemplateTypes.failure]: (state, action) => ({
    ...state,
    fetchStatus: FETCH_STATUSES.FAILURE,
    error: (action as FetchFailureAction).payload,
  }),
  [TYPES.RenameActions.CLEAR_RENAME_TEMPLATE]: () => ({ ...initialState }),
})
