import { FetchFailureAction, FETCH_STATUSES } from '../../../types/fetch'
import { createReducer } from '../../createReducer'
import {
  addCorrectionById,
  updateCorrectionById,
  updateCorrectionText,
  removeCorrectionById,
} from '../../../utils/correction'

import * as TYPES from './corrections.types'
import * as ACTIONS from './corrections.actions'

type Actions =
  | TYPES.AddCorrectionAction
  | TYPES.SetCorrectionAction
  | TYPES.UnsetCorrectionAction
  | TYPES.ClearCorrectionsAction
  | TYPES.FetchCorrectionsSuccessAction
  | TYPES.FetchCreateCorrectionSuccessAction
  | FetchFailureAction
  | TYPES.SetCorrectionTextAction
  | TYPES.FetchConfirmCorrectionSuccessAction

export const initialState: TYPES.CorrectionsState = {
  byId: {},
  byPages: {},
  fetch: {
    status: FETCH_STATUSES.IDLE,
    error: null,
  },
  fetchCreate: {
    status: FETCH_STATUSES.IDLE,
    error: null,
  },
  confirm: {
    status: FETCH_STATUSES.IDLE,
    error: null,
    confirmStatus: null,
  },
  cancelConfirmation: {
    status: FETCH_STATUSES.IDLE,
    error: null,
    confirmStatus: null,
  },
}

export const corrections = createReducer<TYPES.CorrectionsState, Actions>(initialState, {
  [TYPES.CorrectionsActions.ADD_CORRECTION]: (state, action) => {
    const { correction, currentUserId } = (action as TYPES.AddCorrectionAction).payload
    return {
      ...state,
      ...addCorrectionById({ correction, correctionsById: state.byId, currentUserId }),
    }
  },
  [TYPES.CorrectionsActions.SET_CORRECTION]: (state, action) => ({
    ...state,
    byId: updateCorrectionById((action as TYPES.SetCorrectionAction).payload.correction, state.byId),
  }),
  [TYPES.CorrectionsActions.SET_CORRECTION_TEXT]: (state, action) => {
    const { id, text } = (action as TYPES.SetCorrectionTextAction).payload

    return {
      ...state,
      byId: updateCorrectionText(text, id, state.byId),
    }
  },
  [TYPES.CorrectionsActions.UNSET_CORRECTION]: (state, action) => {
    const { id, currentUserId } = (action as TYPES.UnsetCorrectionAction).payload
    return {
      ...state,
      ...removeCorrectionById({ id, correctionsById: state.byId, currentUserId }),
    }
  },
  [TYPES.CorrectionsActions.CLEAR_CORRECTIONS]: () => ({
    ...initialState,
  }),

  [ACTIONS.fetchCorrectionsTypes.request]: (state) => ({
    ...state,
    fetch: {
      ...state.fetch,
      status: FETCH_STATUSES.REQUEST,
      error: null,
    },
  }),
  [ACTIONS.fetchCorrectionsTypes.success]: (state, action) => ({
    ...state,
    ...(action as TYPES.FetchCorrectionsSuccessAction).payload,
    fetch: {
      ...state.fetch,
      status: FETCH_STATUSES.SUCCESS,
      error: null,
    },
  }),
  [ACTIONS.fetchCorrectionsTypes.failure]: (state, action) => ({
    ...state,
    fetch: {
      ...state.fetch,
      status: FETCH_STATUSES.FAILURE,
      error: (action as FetchFailureAction).payload,
    },
  }),

  [ACTIONS.fetchCreateCorrectionTypes.request]: (state) => ({
    ...state,
    fetchCreate: {
      ...state.fetchCreate,
      status: FETCH_STATUSES.REQUEST,
      error: null,
    },
  }),
  [ACTIONS.fetchCreateCorrectionTypes.success]: (state) => ({
    ...state,
    fetchCreate: {
      ...state.fetchCreate,
      status: FETCH_STATUSES.SUCCESS,
      error: null,
    },
  }),
  [ACTIONS.fetchCreateCorrectionTypes.failure]: (state, action) => ({
    ...state,
    fetchCreate: {
      ...state.fetchCreate,
      status: FETCH_STATUSES.FAILURE,
      error: (action as FetchFailureAction).payload,
    },
  }),
  [ACTIONS.fetchConfirmCorrectionTypes.request]: (state) => ({
    ...state,
    confirm: {
      ...state.confirm,
      status: FETCH_STATUSES.REQUEST,
    },
  }),
  [ACTIONS.fetchConfirmCorrectionTypes.success]: (state, action) => ({
    ...state,
    confirm: {
      ...state.confirm,
      status: FETCH_STATUSES.SUCCESS,
      confirmStatus: (action as TYPES.FetchConfirmCorrectionSuccessAction).payload.confirmStatus,
    },
  }),
  [ACTIONS.fetchConfirmCorrectionTypes.failure]: (state, action) => ({
    ...state,
    confirm: {
      ...state.confirm,
      status: FETCH_STATUSES.FAILURE,
      error: (action as FetchFailureAction).payload,
    },
  }),
  [ACTIONS.fetchCancelCorrectionConfirmationTypes.request]: (state) => ({
    ...state,
    cancelConfirmation: {
      ...state.cancelConfirmation,
      status: FETCH_STATUSES.REQUEST,
    },
  }),
  [ACTIONS.fetchCancelCorrectionConfirmationTypes.success]: (state, action) => ({
    ...state,
    cancelConfirmation: {
      ...state.cancelConfirmation,
      status: FETCH_STATUSES.SUCCESS,
      confirmStatus: (action as TYPES.FetchCancelCorrectionConfirmationSuccessAction).payload.confirmStatus,
    },
  }),
  [ACTIONS.fetchCancelCorrectionConfirmationTypes.failure]: (state, action) => ({
    ...state,
    cancelConfirmation: {
      ...state.cancelConfirmation,
      status: FETCH_STATUSES.FAILURE,
      error: (action as FetchFailureAction).payload,
    },
  }),
})
