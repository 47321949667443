import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { modalsVisibleSelector } from '../../../../../store/modals/modals.selectors'
import { closeModal } from '../../../../../store/modals/modals.actions'
import ContactUsModal from '../../../../ui/Modals/SubscriptionModals/ContactUsModal'
import { SUBSCRIPTION_MODALS } from '../../../../../constants/subscription'
import SelectTermModal from '../../../../components/Modals/SubscriptionModals/SelectTermModal'
import ChangePlanModal from '../../../../components/Modals/SubscriptionModals/ChangePlanModal'

export const Modals = () => {
  const dispatch = useDispatch()

  const showContactUs = useSelector(modalsVisibleSelector(SUBSCRIPTION_MODALS.CONTACT_US_MODAL))
  const handleContactUsClose = () => {
    dispatch(closeModal(SUBSCRIPTION_MODALS.CONTACT_US_MODAL))
  }

  const showSelectTerm = useSelector(modalsVisibleSelector(SUBSCRIPTION_MODALS.SELECT_TERM_MODAL))
  const handleSelectTermClose = () => {
    dispatch(closeModal(SUBSCRIPTION_MODALS.SELECT_TERM_MODAL))
  }

  const showChangePlan = useSelector(modalsVisibleSelector(SUBSCRIPTION_MODALS.CHANGE_PLAN_MODAL))
  const handleChangePlanClose = () => {
    dispatch(closeModal(SUBSCRIPTION_MODALS.CHANGE_PLAN_MODAL))
  }

  return (
    <>
      {showContactUs && <ContactUsModal onClose={handleContactUsClose} />}
      {showSelectTerm && <SelectTermModal onClose={handleSelectTermClose} />}
      {showChangePlan && <ChangePlanModal onClose={handleChangePlanClose} />}
    </>
  )
}
