import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { modalsVisibleSelector } from '../../../../../store/modals/modals.selectors'
import { closeModal } from '../../../../../store/modals/modals.actions'
import { CONTRACT_PLACEMENT_MODALS } from '../../../../constants/contractPlacement'
import { CONTRACTS_MODALS } from '../../../../../constants/contracts'
import { PLACEMENT_MODALS } from '../../../../constants/placement'
import SendContractModal from '../../../../components/Modals/ContractPlacementModals/SendContractModal'
import ResetModal from '../../../../components/Modals/PlacementModals/ResetModal'
import ReuploadConfirmModal from '../../../../components/Modals/PlacementModals/ReuploadConfirmModal'
import ReuploadModal from '../../../../components/Modals/PlacementModals/ReuploadModal'
import SaveAsTemplateModal from '../../../../components/Modals/ContractsModals/SaveAsTemplateModal'
import CreateFolderForTemplateModal from '../../../../components/Modals/TemplatesModals/CreateFolderForTemplateModal'

import { PlacementModalsProps } from './PlacementModals.types'

export const PlacementModals: React.FC<PlacementModalsProps> = ({
  reuploading,
  onReset,
  onReuploadConfirm,
  onReupload,
}) => {
  const dispatch = useDispatch()

  const showSendContract = useSelector(modalsVisibleSelector(CONTRACT_PLACEMENT_MODALS.CONTRACT_PLACEMENT_SEND))
  const onCloseSendContract = () => {
    dispatch(closeModal(CONTRACT_PLACEMENT_MODALS.CONTRACT_PLACEMENT_SEND))
  }

  const showSaveContractAsTemplate = useSelector(modalsVisibleSelector(CONTRACTS_MODALS.SAVE_CONTRACT_AS_TEMPLATE))
  const handleSaveContractAsTemplateClose = () => {
    dispatch(closeModal(CONTRACTS_MODALS.SAVE_CONTRACT_AS_TEMPLATE))
  }

  const showCreateFolderForTemplate = useSelector(modalsVisibleSelector(CONTRACTS_MODALS.CREATE_FOLDER_FOR_TEMPLATE))
  const handleCreateFolderForTemplateClose = () => {
    dispatch(closeModal(CONTRACTS_MODALS.CREATE_FOLDER_FOR_TEMPLATE))
  }

  const showResetPlacement = useSelector(modalsVisibleSelector(PLACEMENT_MODALS.PLACEMENT_RESET))
  const onCloseResetPlacement = () => {
    dispatch(closeModal(PLACEMENT_MODALS.PLACEMENT_RESET))
  }

  const showReuploadConfirm = useSelector(modalsVisibleSelector(PLACEMENT_MODALS.REUPLOAD_CONFIRMATION))
  const onCloseReuploadConfirmation = () => {
    dispatch(closeModal(PLACEMENT_MODALS.REUPLOAD_CONFIRMATION))
  }

  const showReupload = useSelector(modalsVisibleSelector(PLACEMENT_MODALS.REUPLOAD))
  const onCloseReupload = () => {
    dispatch(closeModal(PLACEMENT_MODALS.REUPLOAD))
  }

  return (
    <>
      {showSendContract && <SendContractModal onClose={onCloseSendContract} />}
      {showSaveContractAsTemplate && <SaveAsTemplateModal onClose={handleSaveContractAsTemplateClose} />}
      {showCreateFolderForTemplate && <CreateFolderForTemplateModal onClose={handleCreateFolderForTemplateClose} />}
      {showResetPlacement && <ResetModal onReset={onReset} onClose={onCloseResetPlacement} />}
      {showReuploadConfirm && (
        <ReuploadConfirmModal onReuploadConfirm={onReuploadConfirm} onClose={onCloseReuploadConfirmation} />
      )}
      {showReupload && <ReuploadModal loading={reuploading} onReupload={onReupload} onClose={onCloseReupload} />}
    </>
  )
}
