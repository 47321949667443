// TODO: REMOVE WHEN KEYS DEPENS ON translations/keys only.
export const translationFixes = {
  Warning: 'تحذير',
  'Success!': 'نجاح!',
  Info: 'معلومات',
  'Are you sure?': 'هل أنت متأكد؟',
  ok: 'موافق',
  Generate: 'إنشاء',
  'Terms of Use': 'شروط الاستخدام',
  and: 'و',
  'Privacy Policy': 'سياسة الخصوصية',
  'Sign Up': 'التسجيل',
  'Log In': 'تسجيل الدخول',
  Logout: 'تسجيل الخروج',
  Uqoud: 'Uqoud',
  OPEN: 'فتح في زر تطبيق عقود',
  'Open in Uqoud app': 'فتح في تطبيق عقود',
  'Legal Notice!': 'إشعار قانوني!',
  Language: 'اللغة',
  'Copyright © 2020 Uqoud. All rights reserved': 'حقوق الطبع والنشر ¬© 2020 عقود. جميع الحقوق محفوظة',
  Password: 'كلمة المرور',
  'Enter your password': 'أدخل كلمة المرور',
  'Remember me': 'تثبيت تسجيل الدخول',
  'Forgot your password?': 'هل نسيت كلمة المرور؟',
  'No account?': 'أليس لديك حساب؟',
  'Sign Up now': 'سجّل الآن',
  'Create account': 'إنشاء حساب',
  'First name': 'الاسم الأول',
  'Enter your first name': 'أدخل الاسم الأول هنا',
  'Last name': 'اسم العائلة',
  'Token has been expired': 'انتهت صلاحية الرمز',
  'Enter your last name': 'أدخل اسم العائلة',
  Country: 'البلد',
  'Select your country': 'حدد بلدك هنا',
  Industry: 'مجال العمل',
  'Select industry': 'حدد مجال العمل هنا',
  Email: 'البريد الإلكتروني',
  'Enter your email': 'أدخل بريدك الإلكتروني',
  'Mobile phone number': 'رقم الجوال',
  'Create password': 'أنشئ كلمة المرور هنا',
  'Confirm password': 'تأكيد كلمة المرور',
  'API Integration': 'تكامل واجهة برمجة التطبيقات',
  'Public token': 'الرمز المميز',
  'You can get access to our API if you follow the instructions on API description page.\nUse the token below to perform available operations':
    'يمكنك الوصول إلى واجهة برمجة التطبيقات إذا اتبعت التعليمات الواردة في صفحة وصف واجهة برمجة التطبيقات. nاستخدم الرمز المبين أدناه لأداء العمليات المتاحة',
  'Go to API description': 'الانتقال إلى وصف واجهة برمجة التطبيقات',
  'Generating a new token will automatically expire the current one':
    'إنشاء رمز جديد سينهي صلاحية الرمز الحالي تلقائيًا',
  'Generate New': 'إنشاء رمز جديد',
  'Clear and continue': 'مسح ومتابعة',
  'Token copied to clipboard': 'نُسِخ الرمز للحافظة',
  'Password must contain 8 or more symbols, 1 digit, 1 special character, 1 uppercase letter, and 1 lowercase letter':
    'يجب أن تحتوي كلمة المرور على 8 رموز أو أكثر, ورقم واحد, وحرف خاص واحد, وحرف كبير واحد, وحرف صغير واحد',
  'I accept': 'أوافق',
  'Already have account?': 'هل تملك حسابًا بالفعل؟',
  'Some error occurred': 'حدث خطأ ما',
  'Invalid email address': 'عنوان بريد إلكتروني غير صالح',
  'Email is required': 'البريد الإلكتروني مطلوب',
  'Specified email is already registered': 'البريد الإلكتروني المحدد مُسجّل بالفعل',
  'Contact with this email already exists': 'توجد جهة اتصال بهذا البريد الإلكتروني',
  'This contact already exists': 'جهة الاتصال موجودة للتو',
  'Email is too long': 'البريد الإلكتروني طويل',
  'Name is too long': 'الاسم طويل',
  'Your password is too long': 'كلمة المرور طويلة',
  'Invalid password': 'كلمة المرور غير صالحة',
  'Password is required': 'كلمة المرور مطلوبة',
  "Passwords don't match": 'كلمتا المرور غير متطابقتين',
  'Invalid request body': 'نص الطلب غير صالح',
  'Incorrect data entered': 'تم إدخال بيانات غير صحيحة',
  'Invalid phone number': 'رقم هاتف غير صالح',
  'Please, select your country': 'يرجى تحديد بلدك',
  'Please, select your industry': 'يرجى تحديد مجال عملك',
  'SMTP service is not active': 'خدمة SMTP غير نشطة',
  'Something wrong has happened': 'حدث خطأٌ ما',
  'Please accept Terms of Use and Privacy Policy': 'يرجى الموافقة على شروط الاستخدام وسياسة الخصوصية',
  'Confirm your email': 'تأكيد البريد الإلكتروني',
  'The verification link has been sent to your email address. Please check your email to verify your account':
    'تم بعث رابط التحقق إلى عنوان بريدك الإلكتروني. يرجى تفحص بريدك الإلكتروني للتحقق من حسابك',
  'The verification link has been sent to the new email address. Please, check your email':
    'تم إرسال رابط التحقق إلى عنوان بريدك الإلكتروني الجديد. يرجى تفحص بريدك الإلكتروني',
  'Confirm phone number': 'تأكيد رقم الهاتف',
  'The verification code was sent to the specified phone number': 'تم إرسال رمز التحقق إلى رقم الهاتف المحدد',
  'Please enter the code': 'يرجى إدخال الرمز',
  'Resend code': 'إعادة إرسال الرمز',
  'Invalid code': 'رمز غير صالح',
  'Phone number is required': 'رقم الهاتف مطلوب',
  'Back to Log In': 'العودة لتسجيل الدخول',
  'Back to Profile': 'العودة إلى ملف التعريف',
  'Forgot password': 'نسيت كلمة المرور',
  'Enter your email to reset password': 'أدخل بريدك الإلكتروني لإعادة تعيين كلمة المرور',
  'Reset Password': 'إعادة تعيين كلمة المرور',
  Continue: 'متابعة',
  'Check your email': 'تحقق من بريدك الإلكتروني',
  'Your password reset instructions were sent to your email. If you are unable to find them, please be sure to check your spam folder':
    'تم إرسال تعليمات إعادة تعيين كلمة المرور إلى بريدك الإلكتروني. إذا لم تجدها ابحث في مجلد البريد العشوائي',
  'Resend verification email': 'إعادة إرسال البريد الإلكتروني للتحقق من الصحة',
  'Set password': 'تعيين كلمة المرور',
  'Confirm your password': 'تأكيد كلمة المرور',
  'Create new password': 'إنشاء كلمة مرور جديدة',
  'Your password has been changed': 'تم تغيير كلمة المرور',
  'Your link has expired': 'انتهت صلاحية الرابط',
  'Please, try to log in to request another verification link': 'يُرجى محاولة تسجيل الدخول لطلب رابط تحقق آخر',
  'Back to Sign Up': 'العودة إلى التسجيل',
  "The field can't be empty": 'لا يمكن أن يكون الحقل فارغًا',
  'Not found': 'لم يتم العثور عليه',
  'Edit email': 'تحرير البريد الإلكتروني',
  Phone: 'الهاتف',
  'Edit mobile phone number': 'تحرير رقم الهاتف الجوال',
  'Personal Data': 'البيانات الشخصية',
  'Job Data': 'بيانات الوظيفة',
  Company: 'الشركة',
  'Enter your company name': 'أدخل اسم شركتك',
  'Job title': 'المسمى الوظيفي',
  'Enter your job title': 'أدخل مسماك الوظيفي',
  Address: 'العنوان',
  'Enter your address': 'أدخل عنوانك',
  City: 'المدينة',
  'Enter you city': 'أدخل مدينتك',
  'Region/Province': 'المنطقة/المحافظة',
  'Enter your region': 'أدخل منطقتك',
  'Postal code': 'الرمز البريدي',
  'Enter postal code': 'أدخل الرمز البريدي',
  Update: 'تحديث',
  'You have filled invalid Email': 'لقد أدخلت بريدًا إلكترونيًا غير صالح',
  'You have filled invalid Email or Password': 'لقد أدخلت بريدًا إلكترونيًا أو كلمة مرور غير صالحة',
  'Please verify your account before logging in': 'يرجى التحقق من حسابك قبل تسجيل الدخول',
  'The email has been resent. Please, check your inbox':
    'تمت إعادة إرسال البريد الإلكتروني. يرجى التحقق من صندوق الوارد',
  'Your email has successfully been confirmed': 'تم تأكيد بريدك الإلكتروني بنجاح',
  'New email is successfully confirmed': 'تم تأكيد بريد إلكتروني جديد بنجاح',
  'Password Requirements': 'متطلبات كلمة المرور',
  'Password must contain': 'يجب أن تحتوي كلمة المرور على',
  'Only Latin letters': 'حروف لاتينية فقط',
  '8 or more symbols': '8 رموز أو أكثر',
  'At least one': 'واحد على الأقل',
  Digit: 'رقم',
  'Special character': 'حرف خاص',
  'Uppercase letter': 'حرف كبير',
  'Lowercase letter': 'حرف صغير',
  'Current password': 'كلمة المرور الحالية',
  'Enter current password': 'أدخل كلمة المرور الحالية',
  'New password': 'كلمة المرور الجديدة',
  'Enter new password': 'أدخل كلمة المرور الجديدة',
  'Confirm new password': 'تأكيد كلمة المرور الجديدة',
  Save: 'حفظ',
  'You have entered incorrect current password': 'لقد أدخلت كلمة مرور حالية غير صحيحة',
  'Your password was changed': 'تم تغيير كلمة المرور',
  'Your phone was changed': 'تم تغيير الهاتف',
  'New token generated successfully': 'تم إنشاء رمز جديد بنجاح',
  'Change your mobile phone number': 'تغير رقم الهاتف الجوال',
  'Enter your new mobile phone number. We will send your new number a verification code via SMS to confirm':
    'أدخل رقم هاتف جوال جديد. سنرسل رمز تحقق إلى رقمك الجديد من خلال رسالة نصية قصيرة للتأكيد',
  'Send code': 'إرسال رمز',
  'Change your email': 'تغيير البريد الإلكتروني',
  'We will send a verification email to your new email address to confirm this change':
    'سنرسل رسالة للتحقق إلى عنوان البريد الإلكتروني الجديد لتأكيد هذا التغيير',
  'The verification email was sent to your new email': 'تم إرسال بريد إلكتروني للتحقق إلى البريد الإلكتروني الجديد',
  'New email': 'بريد إلكتروني جديد',
  'Enter new email': 'أدخل البريد الإلكتروني الجديد',
  'Send verification email': 'إرسال البريد الإلكتروني للتحقق من الصحة',
  'Please verify your account email first': 'يرجى التحقق من صحة البريد الإلكتروني للحساب أولًا',
  'waiting for confirmation': 'انتظار التأكيد',
  'Resend verification': 'إعادة إرسال بريد إلكتروني للتحقق من الصحة',
  'Cancel changes': 'إلغاء التغييرات',
  'Delete contact': 'حذف جهة اتصال',
  'Are you sure you want to delete this contact?': 'هل تريد بالتأكيد حذف جهة الاتصال هذه؟',
  Cancel: 'إلغاء',
  'Add new contact': 'إضافة جهة اتصال جديدة',
  'Add one more': 'إضافة جهة اتصال إضافية',
  'Add contact': 'إضافة جهة اتصال',
  'Search contact': 'البحث عن جهة اتصال',
  'Changes saved successfully': 'تم حفظ التغييرات بنجاح',
  'Contact is successfully added': 'تمت إضافة جهة اتصال بنجاح',
  'Contact deleted successfully': 'تم حذف جهة الاتصال بنجاح',
  'You have no contacts yet. Please add new contact': 'لا تمتلك جهات اتصال بعد. يرجى إضافة جهة اتصال جديدة',
  'Contacts are not found. Please try to change your request': 'لم يتم العثور على جهات اتصال. يرجى محاولة تغيير طلبك',
  Open: 'فتح',
  Rename: 'إعادة التسمية',
  'View history': 'عرض السجل',
  'Move to': 'الانتقال إلى',
  Delete: 'حذف',
  'Full name': 'الاسم الكامل',
  'Enter name': 'أدخل الاسم',
  'Enter email': 'أدخل البريد الإلكتروني',
  Hide: 'إخفاء',
  Dashboard: 'لوحة المعلومات',
  Contracts: 'العقود',
  eKYC: 'eKYC',
  Templates: 'القوالب',
  'Edit profile': 'تحرير ملف التعريف',
  'Change password': 'تغيير كلمة المرور',
  'Signature and initials': 'التوقيع والحروف الأولى',
  Contacts: 'جهات الاتصال',
  'Document branding': 'إدراج علامة تجارية للمستندات',
  Payment: 'السداد',
  Subscription: 'الاشتراك',
  'Terms and Conditions': 'الشروط والأحكام',
  'All contracts': 'جميع العقود',
  Received: 'تم الاستلام',
  Sent: 'تم الإرسال',
  Drafts: 'المسودات',
  Deleted: 'تم الحذف',
  'Manage profile': 'إدارة ملف التعريف',
  Preferences: 'التفضيلات',
  Analytics: 'التحليلات',
  'All changes saved successfully': 'تم حفظ جميع التغييرات بنجاح',
  'Do you want to save changes before leaving?': 'هل تريد حفظ التغييرات قبل المغادرة؟',
  yes: 'نعم',
  no: 'لا',
  'Note: your name must be the same as in your official documents (passport, etc.) to pass identification':
    'ملاحظة: يجب أن يكون اسمك هو نفسه الوارد في مستنداتك الرسمية (جواز السفر وغيره) لإتمام تحديد الهوية',
  Upgrade: 'ترقية',
  Support: 'دعم',
  'Enter your password to change your email address': 'أدخل كلمة المرور لتغيير عنوان البريد الإلكتروني',
  Enter: 'إدخال',
  'New verification email has been sent': 'تم إرسال بريد إلكتروني جديد للتحقق من الصحة',
  'Your new email has been verified successfully. Please, use it to log in again':
    'تم التحقق من صحة البريد الإلكتروني الجديد بنجاح. يُرجى استخدامه لتسجيل الدخول مرة أخرى',
  'Link is invalid or expired. Please, click on Resend Email button to request another verification link':
    'الرابط غير صالح أو منتهي الصلاحية. يرجى النقر فوق زر إعادة إرسال البريد الإلكتروني لطلب رابط تحقق من صحة آخر',
  Error: 'خطأ',
  'Resend email': 'إعادة إرسال البريد الإلكتروني',
  'Do you want to cancel your changes and return to your current email address?':
    'هل تريد إلغاء التغييرات والعودة إلى عنوان البريد الإلكتروني الحالي؟',
  'Changing email has been canceled': 'تم إلغاء تغيير البريد الإلكتروني',
  'Please enter your password': 'يرجى إدخال كلمة المرور',
  'To access your signatures, please enter your password below': 'للوصول إلى توقيعاتك, يرجى إدخال كلمة المرور أدناه',
  'Incorrect password. Please try again': 'كلمة المرور غير صحيحة. يُرجى المحاولة مرة أخرى',
  'Enter password': 'إدخال كلمة المرور',
  'Please enter your password\nfirst to confirm account': 'يرجى إدخال كلمة المرور\nأولًا لتأكيد الحساب',
  'You have reached the maximum number of signatures. To create a new one, please delete one of the existing signatures':
    'لقد وصلت إلى العدد الأقصى من التوقيعات. لإنشاء توقيع جديد, يرجى حذف أحد التوقيعات القائمة',
  'Add signature': 'إضافة توقيع',
  'You can add one more signature': 'يمكنك إضافة توقيع آخر',
  'Click to see Signature': 'انقر لرؤية التوقيع',
  'Click to see Initials': 'انقر لرؤية الحروف الأولية',
  'Show Signature': 'عرض التوقيع',
  'Show Initials': 'عرض الحروف الأولية',
  'Click to unblur the signature': 'انقر لإلغاء تمويه التوقيع',
  'You have no signatures yet. Please add a signature below.': 'لا تملك توقيعات بعد. يرجى إضافة توقيع أدناه.',
  'You can add up to two signatures': 'يمكنك إضافة حتى توقيعين',
  'Create signature': 'إنشاء توقيع',
  Apply: 'تقديم',
  Clear: 'مسح',
  Previous: 'السابق',
  Next: 'التالي',
  'Clear All': 'مسح الكل',
  More: 'المزيد',
  Notifications: 'الإشعارات',
  'Notifications settings': 'إعدادات الإشعارات',
  Edit: 'تحرير',
  'Draw your new signature on the indicated areas. Once finished, tap the Accept button to save your signatures. After saving, tap the Submit button to create another signature':
    'ارسم توقيعك الجديد في المناطق المشار إليها. بمجرد الانتهاء, اضغط على زر قبول لحفظ توقيعاتك. بعد الحفظ, اضغط على زر "إرسال" لإنشاء توقيع آخر',
  'Draw your new signature below. You need to enter it ': 'ارسم توقيعك الجديد أدناه. وتحتاج إلى إدخاله',
  ' times so we can learn the way you draw it': '  مرة/مرات حتى يمكننا معرفة طريقة رسمك له',
  'Now draw your initials below. It would be enough if you enter it only once':
    'ارسم الآن الحروف الأولى من اسمك أدناه. ويكفي أن تدخله مرة واحدة',
  Signature: 'التوقيع',
  Initials: 'الحروف الأولى',
  'Draw signature here': 'ارسم التوقيع هنا',
  'Draw your new signature. You need to enter it 5 times so we can learn the way you draw it':
    'ارسم توقيعك الجديد. وتحتاج إلى إدخاله 5 مرات حتى يمكننا معرفة طريقة رسمك له',
  'Draw a signature here': 'ارسم توقيعًا هنا',
  'Draw initials here': 'ارسم الحروف الأولى هنا',
  'Now draw your initials': 'ارسم الآن الحروف الأولى من اسمك',
  'Now draw your initials. It would be enough if you enter it only once':
    'ارسم الآن الحروف الأولى من اسمك. ويكفي أن تدخله مرة واحدة',
  Submit: 'إرسال',
  Change: 'تغيير',
  'Add initials': 'إضافة الحروف الأولى',
  Preview: 'معاينة',
  'Enter your password to continue': 'أدخل كلمة المرور للمتابعة',
  'Signature added successfully': 'تمت إضافة التوقيع بنجاح',
  'Are you sure you want to delete this signature? This action cannot be undone':
    'هلي تريد بالتأكيد حذف هذا التوقيع؟ لا يمكن التراجع عن هذا الإجراء',
  'Signature deleted successfully': 'تم حذف التوقيع بنجاح',
  'Add new signature': 'إضافة توقيع جديد',
  'Smart signatures can be created through the Uqoud mobile app. Download the app using the links below':
    'يمكن إنشاء التوقيعات الذكية من خلال تطبيق "عقود" على الهاتف الجوال. نزّل التطبيق باستخدام الروابط الواردة أدناه',
  'Invalid password. Try again': 'كلمة المرور غير صالحة. حاول مرة أخرى',
  'Link is invalid or expired': 'الرابط غير صالح أو منتهي الصلاحية',
  Name: 'الاسم',
  'Edit contact': 'تحرير جهة الاتصال',
  'Upload file': 'تحميل ملف',
  'Drag and drop file here': 'اسحب الملف وأفلته هنا',
  Or: 'أو',
  'Browse files': 'استعراض الملفات',
  'Files for uploading can be of the following formats only: word, pdf, jpg, png':
    'قد تكون الملفات المتاحة للتحميل من أحد التنسيقات التالية: word, pdf, jpg, png',
  'and must not exceed 100Mb': 'ويجب ألا تتجاوز مساحة الملفات 100 ميغابايت',
  'Files for uploading must not exceed 100Mb': 'يجب ألا تتجاوز مساحة الملفات 100 ميغابايت',
  'Upload to': 'تحميل إلى',
  'Select folder': 'تحديد مجلد',
  'Create new folder': 'إنشاء مجلد جديد',
  Upload: 'تحميل',
  'Create folder': 'إنشاء مجلد',
  Create: 'إنشاء',
  'Folder name': 'اسم المجلد',
  'Enter folder name': 'إدخال اسم المجلد',
  'Incorrect file name': 'اسم الملف غير صحيح',
  'Incorrect folder name': 'اسم المجلد غير صحيح',
  'Add new contract': 'إضافة عقد جديد',
  'Add folder': 'إضافة مجلد',
  'You have no contracts yet. Please add a new contract below': 'لا تملك عقودًا بعد. يرجى إضافة عقد جديد أدناه',
  'Contract not found. Please try again': 'لم يتم العثور على العقد. يُرجى المحاولة مرة أخرى',
  'Add contract': 'إضافة عقد',
  'Load from template': 'تحميل من القالب',
  'Google Drive': 'Google Drive',
  'One Drive': 'One Drive',
  'more times': 'مرات أكثر',
  'Some of the signatures you entered has been classified as invalid. Please, draw it again':
    'تم تصنيف بعض التوقيعات التي أدخلتها باعتبارها غير صالحة. يرجى رسم توقيعك مرة أخرى',
  'Insufficient coherence between signatures': 'اتساق غير كافٍ ما بين التوقيعات',
  'This folder already exists': 'هذا المجلد موجود بالفعل',
  'New document uploaded successfully!': 'تم تحميل مستند جديد بنجاح!',
  'Assign to myself': 'تعيين لنفسي',
  'Save as template': 'الحفظ كقالب',
  'Delete and close': 'حذف وإغلاق',
  'Save and close': 'حفظ وإغلاق',
  'Proceed to CLM': 'المتابعة إلى CLM',
  'Start contract': 'بدء العقد',
  'Please select a signature sequence': 'يرجى تحديد تسلسل التوقيع',
  'Sequential signing': 'توقيع متسلسل',
  'Separately signing': 'توقيع منفصل',
  'Contract Lifecycle Management': 'إدارة دورة العقد',
  Recipients: 'المستلمون',
  'To view and send': 'للعرض والإرسال',
  'View document': 'عرض المستند',
  'Recipient name': 'اسم المستلم',
  'Recipient email': 'البريد الإلكتروني للمستلم',
  'Add recipient': 'إضافة مستلم',
  'Add recipients': 'إضافة مستلمين',
  'Document with this name already exists': 'يوجد مستند بهذا الاسم بالفعل',
  'Incorrect document name': 'اسم المستند غير صحيح',
  'Signing finished. Is the signature ok?': 'انتهى التوقيع. هل هذا التوقيع صحيح؟',
  'Signing finished. Is the initials ok?': 'انتهى التوقيع. هل الحروف الأولى صحيحة؟',
  'Yes, It’s OK': 'نعم, إنه صحيح',
  'No, repeat': 'لا, كرر التوقيع',
  'Choose contact': 'اختيار جهة اتصال',
  Search: 'بحث',
  'Send contract': 'إرسال عقد',
  Sender: 'المرسل',
  'Head of Product': 'رئيس الإنتاج',
  View: 'عرض',
  Initial: 'التوقيع بالحرف الأول',
  Sign: 'توقيع',
  'APPROVAL CYCLE': 'دورة الموافقة',
  'Approval Cycles': 'دورات الموافقة',
  Completed: 'اكتمل',
  'Contract deadline': 'الموعد النهائي للعقد',
  'Final SLA': 'اتفاقية مستوى الخدمة النهائية',
  'Message to recipients': 'رسالة إلى المستلمين',
  Title: 'العنوان',
  Message: 'الرسالة',
  'Thank you! Our manager will answer you shortly on the email, specified in your account':
    'شكرًا لك! سيرد عليك مديرنا في غضون فترة قصيرة عبر البريد الإلكتروني المحدد في حسابك',
  'Tell us about your requests. Our manager will contact you via email':
    'أخبرنا بطلباتك. وسيتصل بك مديرنا عبر البريد الإلكتروني',
  Back: 'رجوع',
  'Delete recipient': 'حذف مستلم',
  'Are you sure that': 'هل أنت متأكد من أن',
  'should be deleted from the Contract Recipient list?': 'يجب حذفه من قائمة مستلم العقد؟',
  'Are you sure that you want to delete recipient?': 'هل تريد بالتأكيد حذف المستلم؟',
  'Are you sure that you want to delete this contract?': 'هل تريد بالتأكيد حذف جهة الاتصال هذه؟',
  'Show full info': 'عرض المعلومات الكاملة',
  'Hide full info': 'إخفاء المعلومات الكاملة',
  'Are you sure you want to delete this contract? Signing flow will be stopped for all users':
    'هل تريد بالتأكيد حذف هذا العقد؟, سيتم إيقاف تدفق التوقيع لجميع المستخدمين',
  'Are you sure you want to delete this contract?': 'هل تريد بالتأكيد حذف هذا العقد؟',
  'Are you sure you want to delete this folder?': 'هل تريد بالتأكيد حذف هذا المجلد؟',
  'Rename file': 'إعادة تسمية الملف',
  'Rename folder': 'إعادة تسمية المجلد',
  'Move to folder': 'الانتقال إلى المجلد',
  Move: 'نقل',
  'Duplicate file': 'ملف مكرر',
  'Please rename this contract before duplicating': 'يرجى إعادة تسمية هذا العقد قبل نسخه',
  Duplicate: 'نسخ',
  'Please select a folder': 'يرجى تحديد مجلد',
  'Choose folder': 'اختيار مجلد',
  'Contract with this name already exists': 'يوجد عقد بهذا الاسم بالفعل',
  Department: 'القسم',
  'Enter department': 'إدخال قسم',
  optional: 'اختياري',
  'Phone number': 'رقم الهاتف',
  'Add another': 'إضافة آخر',
  'Choose action': 'اختيار إجراء',
  'Cycle order': 'طلب الدورة',
  'Change order': 'طلب التغيير',
  'Deadline SLA': 'الموعد النهائي لاتفاقية مستوى الخدمة',
  'Select date': 'تحديد التاريخ',
  'Signature field': 'حقل التوقيع',
  'Initials field': 'حقل الحروف الأولى',
  Page: 'صفحة',
  'Last page': 'الصفحة الأخيرة',
  'Every page': 'كل صفحة',
  Authentication: 'مصادقة',
  'Edit recipient': 'تحرير المستلم',
  'You can change the email on Contacts page': 'يمكنك تغيير البريد الإلكتروني في صفحة جهات الاتصال',
  'You can change the department on Contacts page': 'يمكنك تغيير القسم في صفحة جهات الاتصال',
  'You can change the phone number on Contacts page': 'يمكنك تغيير رقم الهاتف في صفحة جهات الاتصال',
  'You have added the maximum number of recipients': 'لقد أضفت العدد الأقصى من المستلمين',
  'Maximum 4 recipients can sign the document': 'يمكن لأربعة مستلمين كحدٍ أقصى التوقيع على المستند',
  'Maximum 10 recipients can initial the document': 'يمكن لعشرة مستلمين كحدٍ أقصى التوقيع بالحروف الأولى على المستند',
  "Error. You can't use the same email few times in one cycle order":
    'هناك خطأ. فلا يمكنك استخدام البريد الإلكتروني ذاته عدة مرات في أمر دورة واحدة',
  'Please make sure the cycle order entered is correct': 'يرجى التأكد من صحة طلب الدورة المُدخل',
  'Recipient with this email already exists': 'يوجد مستلم بهذا البريد الإلكتروني للتو',
  'Minimum is 1': 'الحد الأدنى هو 1',
  'Maximum is 50': 'الحد الأقصى هو 50',
  'Field is required': 'تعبئة الحقل مطلوبة',
  History: 'السجل',
  IP: 'عنوان IP',
  Resend: 'إعادة إرسال',
  'View info': 'عرض معلومات',
  Void: 'ملغي',
  Filters: 'عوامل التصفية',
  'Reset all': 'إعادة تعيين الكل',
  Status: 'الحالة',
  'Pending signature': 'توقيع معلق',
  'Expiring Soon': 'تنتهي الصلاحية قريبًا',
  Signed: 'موقّع',
  'Waiting for sign': 'بانتظار التوقيع',
  'Required to View': 'مطلوب للعرض',
  'Date added': 'تاريخ الإضافة',
  From: 'من',
  To: 'إلى',
  Choose: 'اختيار',
  Progress: 'التقدم',
  'Created / by': 'إنشاء / بواسطة',
  'Updated / by': 'تحديث / بواسطة',
  Commented: 'تم التعليق',
  Voided: 'ملغي',
  Expired: 'انتهت مدة الصلاحية',
  'Date created': 'تاريخ الإنشاء',
  Reset: 'إعادة التعيين',
  Draft: 'مسودة',
  'Created By': 'إنشاء بواسطة',
  'You search among all your documents': 'تبحث بين جميع المستندات',
  'Last Modified By': 'آخر تعديل بواسطة',
  Created: 'تم الإنشاء',
  Updated: 'تم التحديث',
  'Contract deleted successfully': 'تم حذف العقد بنجاح',
  'Folder with all contracts deleted successfully': 'تم حذف المجلد بجميع العقود بنجاح',
  'Name changed successfully': 'تم تغيير الاسم بنجاح',
  'Reupload document': 'إعادة تحميل المستند',
  'Finish later': 'الإنهاء لاحقًا',
  'Signature here': 'التوقيع هنا',
  Me: 'أنا',
  'Contract moved to another folder successfully': 'تم نقل العقد إلى مجلد آخر بنجاح',
  'You have no contracts yet': 'لا تملك عقودًا بعد',
  'Contract duplicated successfully': 'تم نسخ العقد بنجاح',
  Signers: 'الموقّعون',
  Modified: 'تم التعديل',
  'The folder is empty': 'المجلد فارغ',
  'Choose your initials': 'اختر حروفك الأولى',
  'Choose your signature': 'اختر توقيعك',
  'Select your initials to be used in this document.': 'حدد حروفك الأولى المقرر استخدامها في هذا المستند.',
  'Select your signature to be used in this document.': 'حدد توقيعك المقرر استخدامه في هذا المستند.',
  'Please note that these initials will be applied to all documents':
    'يرجى العلم أن الحروف الأولى هذه ستنطبق على جميع المستندات',
  'You can manage your signatures in the "Manage profile" section': 'يمكنك إدارة توقيعاتك في قسم "إدارة ملف التعريف"',
  'New folder added successfully': 'تمت إضافة مجلد جديد بنجاح',
  COMMENTS: 'التعليقات',
  Comment: 'تعليق',
  Crossover: 'تحويل',
  'You have no signatures in your account': 'لا تملك أي توقيعات في حسابك',
  'You need to create one to continue': 'تحتاج إلى إنشاء توقيع للمتابعة',
  'Add signatures': 'إضافة توقيعات',
  'Send the contract?': 'هل سترسل العقد؟',
  'Please ensure that all signatures and initials are correctly placed on all pages':
    'يرجى التأكد من وضع جميع التوقيعات والحروف الأولى بصورة صحيحة في جميع الصفحات',
  'Please ensure that all the necessary actions are performed before sending the contract':
    'يرجى التأكد من اتحاذ جميع الإجراءات اللازمة قبل إرسال العقد',
  Send: 'إرسال',
  'Contract has been successfully sent to the recipients': 'تم إرسال العقد بنجاح إلى المستلمين',
  'Decline to sign': 'رفض التوقيع',
  Download: 'تنزيل',
  'Finish contract actions later? Changes will not be saved':
    'هل ستنهي إجراءات العقد في وقت لاحق؟ لن يتم حفظ التغييرات',
  'Finish contract actions later?': 'هل ستنهي إجراءات العقد في وقت لاحق؟',
  'Changes will not be saved': 'لن يتم حفظ التغييرات',
  'Contract not signed yet, please complete requested action to be able to send':
    'لم يتم توقيع العقد بعد, يرجى إكمال الإجراء المطلوب حتى تتمكن من الإرسال',
  'Contract not initialed yet, please complete requested action to be able to send':
    'لم يتم توقيع العقد بالحروف الأولى بعد, يرجى إكمال الإجراء المطلوب حتى تتمكن من الإرسال',
  'Send contract to other recipients?': 'هل سترسل عقدًا إلى مستلمين آخرين؟',
  'Add your signature here': 'أضف توقيعك هنا',
  'No Data': 'لا توجد بيانات',
  'Pending recipients': 'مستلمون معلقون',
  'Ready to sign': 'جاهز للتوقيع',
  'Verification failed': 'فشل التحقق',
  'All actions complete! Your contract is ready to be sent': 'اكتملت كل الإجراءات! عقدك جاهز للإرسال',
  'Are you sure you want to decline this contract?': 'هل تريد بالتأكيد رفض هذا العقد؟',
  'Reason for declining': 'سبب الرفض',
  'Add comment': 'إضافة تعليق',
  Decline: 'رفض',
  'Please, explain why you wish to decline': 'يرجى توضيح السبب وراء رغبتك في الرفض',
  'Contract declined successfully': 'تم رفض العقد بنجاح',
  Declined: 'تم الرفض',
  'Signature changed successfully': 'تم تغيير التوقيع بنجاح',
  'Initials changed successfully': 'تم تغيير الحروف الأولى بنجاح',
  'The order of receiving the contract for this recipient from the overall cycle':
    'طلب إتاحة العقد لهذا المستلم من الدورة بكليتها',
  'The deadline date for the recipient to take action on the contract':
    'تاريخ الموعد النهائي المحدد لاتخاذ المستلم إجراء فيما يتعلق بالعقد',
  'Contract cannot be with only "view" actions': 'لا يمكن أن يكون العقد لغرض "المشاهدة" فقط',
  'Show grid': 'عرض الشبكة',
  'Display names': 'عرض الأسماء',
  'Group edit': 'تحرير المجموعة',
  'Apply to all pages': 'تنطبق على جميع الصفحات',
  Undo: 'تراجع',
  Redo: 'إعادة',
  'Are you sure you want to reset signatures and initials placement inside current contract?':
    'هل تريد بالتأكيد إعادة تعيين التوقيعات ووضع الحروف الأولى داخل العقد الحالي؟',
  Help: 'تعليمات',
  'We are preparing a file for you. It can take some time, please wait':
    'نجهز ملفًا لك. وقد يستغرق الأمر بعض الوقت, لذلك يرجى الانتظار',
  'Socket connect error': 'خطأ في توصيل المقبس',
  'We are preparing a file for you': 'نجهز ملفًا لك',
  'It can take some time, please wait': 'قد يستغرق الأمر بعض الوقت, لذلك يرجى الانتظار',
  'Do you want to stop signing of your contract?': 'هل ترغب في إيقاف توقيع عقدك؟',
  'Do you want to delete all corrections from the document and continue signing its initial version':
    'هل ترغب في حذف جميع التصحيحات من المستند ومتابعة توقيع الإصدار الأولي',
  'The signature of this contract has been stopped temporarily': 'تم إيقاف توقيع هذا العقد مؤقتًا',
  'Edit flow': 'تحرير التدفق',
  'It has been requested that you view this contract': 'لقد تم طلب مشاهدة هذا العقد',
  'Contract signing has been successfully finished': 'اكتمل توقيع العقد بنجاح',
  'All templates': 'جميع القوالب',
  Starred: 'معلّمة بنجمة',
  'With documents': 'مع مستندات',
  Procedures: 'الإجراءات',
  'Re-upload': 'إعادة تحميل',
  'Re-upload file': 'إعادة تحميل ملف',
  'This action may cause changes in current placement of boxes in your contract. Note that replacing document cannot be undone':
    'قد يتسبب هذا الإجراء في تغييرات في الوضع الحالي للخانات في عقدك. يرجى العلم أن استبدال المستند لا يمكن التراجع عنه',
  'Template not found. Please try again': 'لم يتم العثور على القالب. يُرجى المحاولة مرة أخرى',
  CLM: 'إدارة دورة العقد',
  Document: 'مستند',
  'Document+CLM': 'المستند+إدارة دورة العقد',
  'Create template': 'إنشاء قالب',
  Type: 'النوع',
  'Upload document': 'تحميل مستند',
  'CLM without document': 'إدارة دورة العقد بدون مستند',
  'Load from existing contract': 'التحميل من عقد قائم',
  'Mark as starred': 'إضافة علامة النجمة',
  'Unmark as starred': 'إلغاء علامة النجمة',
  'You have no templates yet': 'لا تملك قوالب بعد',
  'Template with this name already exists': 'يوجد قالب بهذا الاسم بالفعل',
  'Template deleted successfully': 'تم حذف القالب بنجاح',
  'Folder with all templates deleted successfully': 'تم حذف المجلد بجميع القوالب بنجاح',
  'Are you sure you want to delete this template? This action cannot be undone':
    'هل تريد بالتأكيد حذف هذا القالب؟, لا يمكن التراجع عن هذا الإجراء',
  'Are you sure you want to delete this folder? This will delete all templates inside it with no undo':
    'هل تريد بالتأكيد حذف هذا المجلد؟ سيؤدي هذا إلى حذف جميع القوالب بداخله بدون تراجع',
  'Moved to another folder successfully': 'تم النقل إلى مجلد آخر بنجاح',
  'Please rename this template before duplicating': 'يرجى إعادة تسمية هذا القالب قبل نسخه',
  'Template duplicated successfully': 'تم نسخ القالب بنجاح',
  'Upload a document for the template': 'تحميل مستند للقالب',
  'Contract has been deleted by the owner': 'حذف المالك العقد',
  'Start eKYC': 'ابدأ بتعبئة معلومات عن عميلك',
  'Please make sure you allow access to your camera': 'يرجى التأكد من السماح بالوصول إلى الكاميرا الخاصة بك',
  'Detecting face': 'كشف الوجه',
  'Please keep your face within the oval\nuntil the next step of results':
    'يرجى إبقاء وجهك داخل الشكل البيضاوي/nحتى الخطوة التالية من النتائج',
  'Successful registration\ncompleted!': 'اكتمل التسجيل/nبنجاح!',
  'Registration is failed!': 'فشل التسجيل!',
  'Successful eKYC verification completed!': 'اكتمل التحقق من صحة "eمعلومات العميل" بنجاح!',
  'EKYC Verification is failed': 'فشل التحقق من صحة معلومات العميل',
  'Do you accept the Legal notice? ': 'هل تقبل الإشعار القانوني؟ ',
  'Tap to see Signature': 'اضغط لرؤية التوقيع',
  'Tap to see Initials': 'اضغط لرؤية الحروف الأولى',
  'Continue without name and email': 'المتابعة بدون اسم وبريد إلكتروني',
  'Recipients and signing order are not specified': 'المستلمون وأمر التوقيع غير محددين',
  'Add CLM': 'إضافة إدارة دورة العقود',
  'Do you want to discard changes in the template?': 'هل تريد تجاهل التغييرات في القالب؟',
  'Note, that you could not delete a document from template, but you could re-upload it.':
    'يرجى العلم أنه لا يمكنك حذف مستند من القالب, لكن يمكنك إعادة تحميله.',
  'Create CLM template': 'إنشاء قالب إدارة دورة العقود',
  'Template created successfully': 'تم إنشاء القالب بنجاح',
  'Create template from existing contract': 'إنشاء قالب من عقد قائم',
  'Select contract': 'تحديد عقد',
  'Template name': 'اسم القالب',
  'Please select contract to create a template': 'يرجى اختيار عقد لإنشاء قالب',
  Discard: 'تجاهل',
  'No name': 'بدون اسم',
  'Do you want to save this template without a signing flow?': 'هل تريد حفظ هذا القالب بدون تدفق التوقيع؟',
  'Regional settings': 'الإعدادات الإقليمية',
  'Cloud storage': 'التخزين السحابي',
  'Date, Time, and Language Settings': 'إعدادات التاريخ والوقت واللغة',
  'Date and time format': 'تنسيق الوقت والتاريخ',
  'Contract Notifications': 'إشعارات العقد',
  Reminders: 'رسائل التذكير',
  Chasers: 'مطاردون',
  'Subscription upgrade required': 'مطلوب ترقية الاشتراك',
  'Deadline SLA for recipient cannot be earlier, then Deadlines SLA in cycle order before him':
    'لا يمكن أن يكون الموعد النهائي لاتفاقية مستوى الخدمة للمستلم قبل المواعيد النهائية لاتفاقية مستوى الخدمة في الدورة السابقة',
  'Permission to use camera': 'إذن لاستخدام الكاميرا',
  'We need your permission to use your camera': 'نحتاج إلى إذنك لاستخدام الكاميرا',
  'ID check': 'التحقق من المعرّف',
  Selfie: 'سيلفي',
  'Please keep the ID within the box until the next step of face capture and box changes to oval shape':
    'يرجى الاحتفاظ بالمعرّف داخل الخانة حتى إتمام الخطوة التالية لالتقاط صورة الوجه وتغيير الخانة إلى الشكل البيضاوي',
  'Detecting document ID': 'الكشف عن معرّف المستند',
  'Sending image to server': 'إرسال صورة إلى الخادم',
  'Try Again': 'حاول مرة أخرى',
  'Please try again. Please try to improve the light and correct position of your document and face on the photos':
    'يرجى المحاولة مرة أخرى. يرجى محاولة تحسين الإضاءة وتصحيح وضع المستند ووجهك على الصور',
  'Please try again. Please try to improve the light and correct position of your face on the photo':
    'يرجى المحاولة مرة أخرى. يرجى محاول تحسين الإضاءة وتصحيح وضع المستند ووجهك على الصورة',
  'Verification is successfully\nconfirmed': 'تم تأكيد التحقق من الصحة\nبنجاح',
  'eKYC registration is failed!': 'فشل تسجيل eمعلومات العميل!',
  'You expired all your attempts.\nWe suggest you to contact Support or Contract sender':
    'لقد انتهت صلاحية كل محاولاتك.\nنقترح الاتصال بالدعم أو مرسل العقد',
  'Contact support': 'الاتصال بالدعم',
  'Cancel creating a contract from a template?': 'هل تريد إلغاء إنشاء عقد من قالب؟',
  'Create new contract': 'إنشاء عقد جديد',
  'Please select template to create a contract': 'يرجى تحديد قالب لإنشاء عقد',
  'Create contract from template': 'إنشاء عقد من قالب',
  'Select template': 'تحديد قالب',
  'Creating contract aborted successfully': 'تم إحباط إنشاء عقد بنجاح',
  'Selected template contains no document. You need to upload it to create a contract':
    'لا يحتوي القالب المحدد على أي مستند. ويجب عليك تحميله لإنشاء عقد',
  'Do you really want to cancel changes?': 'هل بالفعل ترغب في إلغاء التغييرات؟',
  'This folder contains some items that cannot be deleted right now. Please open a folder to manage them':
    'يحتوي هذا المجلد على بعض العناصر التي يتعذر حذفها الآن. يرجى فتح مجلد لإدارتها',
  'Instructions for Face detecting': 'تعليمات كشف الوجه',
  'You are going to take a picture of your face. Here are some tips to take it in the best conditions.':
    'ستلتقط صورة لوجهك. فيما يلي بعض النصائح التي يجب اتباعها في أفضل الظروف.',
  '1. Hold your phone still while taking the picture': '1. امسك هاتفك بثبات أثناء التقاط الصورة',
  '2. Remove your hair from the face and take your glasses off': '2. أبعد شعرك عن وجهك وانزع نظارتك',
  '3. Check lighting conditions (medium brightness)': '3. تحقق من ظروف الإضاءة (درجة سطوع متوسطة)',
  '4. Your face should cover the whole oval space': '4. يجب أن يغطي وجهك المساحة البيضاوية بالكامل',
  'Instructions for ID card detecting': 'تعليمات كشف بطاقة المعرّف',
  'You are going to take a picture of your ID card. Here are some tips to take it in the best conditions.':
    'ستلتقط صورة لبطاقة المعرّف. فيما يلي بعض النصائح التي يجب اتباعها في أفضل الظروف.',
  '2. Check lighting conditions (medium brightness)': '2. تحقق من ظروف الإضاءة (درجة سطوع متوسطة)',
  '3. Your ID card should cover the whole rectangular space': '3. يجب أن تغطي بطاقة المعرّف المساحة البيضاوية بالكامل',
  'Subscription Plans': 'خطط الاشتراك',
  year: 'سنة',
  mo: 'شهر',
  contracts: 'عقود',
  'Unlimited contracts': 'عقود غير محدودة',
  'User templates': 'قوالب المستخدم',
  'Unlimited bulk sending': 'إرسال جماعي غير محدود',
  'Signature matching': 'مطابقة التوقيع',
  "Don't like any of offered plans? Contact us to discuss some custom options":
    'ألا تعجبك أي من الخطط المعروضة؟ اتصل بنا لمناقشة بعض الخيارات المخصصة',
  'Contact us': 'اتصل بنا',
  'Your Plan': 'خطتك',
  'Expiration date': 'تاريخ انتهاء الصلاحية',
  'Change plan': 'تغيير الخطة',
  Unsubscribe: 'إلغاء الاشتراك',
  'Your subscription is expired': 'انتهت صلاحية اشتراكك',
  'Verification is successfully confirmed': 'تم تأكيد التحقق من الصحة بنجاح',
  'Go to settings': 'الانتقال إلى الإعدادات',
  'Text field': 'حقل نصي',
  'Adjust this field width to be long enough for the text. You will enter your text here when you will be creating a contract from this template':
    'اضبط عرض هذا الحقل بحيث يكون طويلًا بما يكفي لاستيعاب النص. وستدخل نصك هنا عند إنشاء عقد من هذا القالب',
  'Date field': 'حقل التاريخ',
  'dd.mm.yyyy': 'يوم. شهر. سنة',
  'You have no access to User templates features. Upgrade your subscription now to get access to it':
    'لا تملك حق الوصول إلى ميزات قوالب المستخدم. قم بترقية اشتراكك الآن للوصول إليها',
  'You have no access to API Integration features. Upgrade your subscription now to get access to it':
    'لا تملك حق الوصول إلى ميزات تكامل واجهة برمجة التطبيقات. قم بترقية اشتراكك الآن للوصول إليها',
  'Upgrade your subscription plan': 'قم بترقية خطة الاشتراك',
  "You don't have access to using eKYC-features. Upgrade your subscription now to get it":
    'لا تملك حق الوصول لاستخدام ميزات (eمعلومات العميل). قم بترقية اشتراكك الآن للحصول عليها',
  'Please use our Mobile Application to be verified with eKYC':
    'يرجى استخدام تطبيق الجوال المراد التحقق منه مع (eمعلومات العميل)',
  'Invalid message': 'رسالة غير صالحة',
  Size: 'الحجم',
  'You cannot use sequential signing of a contract. Upgrade your subscription now to get access to it':
    'لا يمكنك استخدام التوقيع المتسلسل للعقد. قم بترقية اشتراكك الآن للوصول إليه',
  'Please fill all the input fields in a document with appropriate data to create a contract':
    'يرجى تعبئة جميع الحقول في المستند ببيانات مناسبة لإنشاء عقد',
  'Incorrect date': 'تاريخ غير صحيح',
  'Please fill in all the required fields correctly to create a contract':
    'يرجى تعبئة جميع الحقول المطلوبة بصورة صحيحة لإنشاء عقد',
  'You have filled all additional fields. Now we are ready to create a contract':
    'لقد عبأت جميع الحقول الإضافية. والآن نحن جاهزون لإنشاء عقد',
  'Flip the phone horizontally to sign. Check that\nthe landscape mode is unlocked for your phone':
    'ضع الهاتف في وضع أفقي للتوقيع. تأكد من أن الوضع الأفقي مفتوح لهاتفك',
  HOME: 'الرئيسية',
  'Page not found': 'تعذر إيجاد الصفحة',
  CONNECT: 'اتصال',
  DISCONNECT: 'قطع الاتصال',
  'Connect Cloud Storage': 'توصيل التخزين السحابي',
  'Hello! How can we help you?': 'مرحبًا! كيف يمكننا مساعدتك؟',
  'or choose the category': 'أو اختر الفئة',
  'Maximum is 100': 'الحد الأقصى هو 100',
  'Maximum is 500': 'الحد الأقصى هو 500',
  'Passcode OTP verification': 'التحقق من كلمة المرور لمرة واحدة',
  'The verification code has been sent to the phone number, specified by Contract Owner':
    'تم إرسال رمز التحقق إلى رقم الهاتف, الذي حدده مالك العقد',
  "If you don't receive a code in SMS due to wrong phone number specified - please, contact Contract Owner to fix it":
    'إذا لم تستلم رمزًا في رسالة نصية قصيرة بسبب تحديد رقم هاتف خاطئ - يرجى الاتصال بمالك العقد لتصحيحه',
  'Code is correct': 'الرمز صحيح',
  'You expired all your attempts. We suggest you to contact Support or Contract sender':
    'لقد انتهيت من جميع محاولاتك. نقترح عليك الاتصال بالدعم أو بمرسل العقد',
  'Incorrect code': 'رمز غير صحيح',
  'You have the last attempt': 'أمامك محاولة أخيرة',
  'New code is sent': 'تم إرسال رمز جديد',
  'Processing provided documents…': 'تجهيز المستندات المقدمة‚Ä¶',
  'You can send your document only to 1 person. Upgrade your subscription now to include multiple recipients':
    'لا يمكنك إرسال مستندك إلا إلى شخص واحد. قم بترقية اشتراكك الآن لضم مستلمين متعددين',
  'Loading...': 'جارِ التحميل...',
  'You have': 'أمامك',
  attempts: 'محاولات',
  'The network is busy, please try again later': 'الشبكة مشغولة, يرجى المحاولة مرة أخرى لاحقًا',
  'Your deadline on the contract has passed. Please, contact contract owner for further actions':
    'لقد مر الموعد النهائي للعقد. يرجى الاتصال بمالك العقد لمزيدٍ من الإجراءات',
  'Your actions on this contract must be confirmed using mobile app. Thus you will not be able to continue from web-version. Please, switch to the mobile application and continue acting on this contract using it.':
    'يجب تأكيد إجراءاتك في هذا العقد باستخدام تطبيق الجوال. وبالتالي لن تكون قادرًا على المواصلة من إصدار الويب. يرجى التحويل إلى تطبيق الجوال ومواصلة إجراءاتك في هذا العقد من خلاله.',
  'You need to pass verification using mobile application': 'يجب عليك اجتياز عملية التحقق باستخدام تطبيق الجوال',
  'Please, install the application on your smartphone and then open document again from email on your smartphone':
    'يرجى تثبيت التطبيق على هاتفك الذكي وافتح المستند مرة أخرى من البريد الإلكتروني على هاتفك الذكي',
  'Contract has been updated successfully': 'تم تحديث العقد بنجاح',
  'Want to keep all your signed contracts in one place? Sign up and join Uqoud platform':
    'هل تريد الاحتفاظ بعقودك الموقعة في مكان واحد؟ بادر بالتسجيل والانضمام إلى منصة عقود',
  'Draw your new initials on the indicated areas. Once finished, tap the Accept button to save your initials. After saving, tap the Submit button to create another initials':
    'ارسم حروفك الأولى الجديدة في المناطق المشار إليها. بمجرد الانتهاء, اضغط على زر قبول لحفظ حروفك الأولى. بعد الحفظ, اضغط على زر إرسال لإنشاء حروف أولى أخرى',
  'Create new signature': 'إنشاء توقيع جديد',
  'Create new initials': 'إنشاء حروف أولى جديدة',
  'See progress': 'عاين التقدم',
  'Recipient info': 'معلومات المستلم',
  Action: 'الإجراء',
  "You can't start cycle without recipients. Please add recipients":
    'لا يمكنك بدء دورة بدون مستلمين. يرجى إضافة مستلمين',
  'Exit App': 'الخروج من التطبيق',
  'Do you want to exit?': 'هل تريد الخروج من التطبيق؟',
  'You have failed contract verification': 'فشلتَ أثناء التحقق من العقد',
  'Please, contact Support or the sender of the contract': 'يرجى الاتصال بالدعم أو مرسل العقد',
  'Save recipients data? Note, that it will not be sent yet':
    'هل تريد حفظ بيانات المستلمين؟ يرجى العلم أنها لم تُرسل بعد',
  'Cycle order cannot be less than the current one': 'لا يمكن أن يكون حجم طلبات المُتلقين أقل من الحالي',
  'Delete this recipient from a contract? Note, that it will effect contract flow':
    'أترغب في حذف هذا المستلم من العقد؟ يرجى العلم أن هذا من شأنه التأثير على تدفق العقد',
  'Not sent': 'لم يتم الإرسال',
  Opened: 'مفتوح',
  Complete: 'إكمال',
  'Resend contract': 'إعادة إرسال العقد',
  'The contract recipients will have the following updates. Check their data before sending. Further editing will not be available without stopping the flow':
    'سيحصل مستلمو العقد على التحديثات التالية. تحقق من بياناتهم قبل الإرسال. لن يكون أي تحرير إضافي متاحًا دون إيقاف التدفق',
  'Apply changes to the recipients? Note, that current signing progress will not be lost':
    'هل ترغب في تطبيق التغييرات على المستلمين؟ يرجى العلم أن التقدم المحرز في التوقيع الحالي لن يضيع',
  'Error! You have a user with expired deadline. Set him a new deadline to continue':
    'خطأ! لديك مستخدم بموعد نهائي منتهي الصلاحية. عيّن له موعدًا نهائيًا جديدًا للمتابعة',
  'Contract successfully resent to recipients': 'تمت إعادة إرسال العقد بنجاح إلى المستلمين',
  'Add text here': 'إضافة نص هنا',
  'Add a comment': 'إضافة تعليق',
  'Contract pending action': 'إجراء العقد ملعق',
  'Contract was signed/ viewed/ initialed': 'تم توقيع/ مراجعة/ التوقيع بالحروف الأولى على العقد',
  'Contract was declined': 'تم رفض العقد',
  'Changes for contract requested': 'تم طلب إدخال تغييرات على العقد',
  'Contract was commented': 'تم التعليق على العقد',
  'You were mentioned in comments': 'ورد ذكر اسمك في التعليقات',
  'Contract was sent': 'تم إرسال العقد',
  'Contract was updated': 'تم تحديث العقد',
  'Contract was deleted': 'تم حذف العقد',
  'Contract signing was completed': 'اكتمل توقيع العقد',
  "Contract recipients' list has been updated": 'تم تحديث قائمة مستلمي العقد',
  'Contract has been voided': 'تم إبطال العقد',
  'Owner removed all corrections and continued signing flow': 'حذف المالك جميع التصحيحات وواصل تدفق التوقيع',
  'One day before and on the day of contract deadline': 'قبل يوم واحد وفي الموعد النهائي للعقد',
  'Three days after contract deadline missed': 'بعد ثلاثة أيام من تفويت الموعد النهائي للعقد',
  'In-app': 'داخل التطبيق',
  Push: 'دفع',
  'You have left unsaved comment. To continue delete it or save it (to send it to other recipients)':
    'لقد تركت تعليقًا غير محفوظ. للمتابعة احذفه أو احفظه (لإرساله إلى مستلمين آخرين)',
  Reply: 'رد',
  'Are you sure you want to clear all notifications?': 'هل ترغب بالتأكيد في مسح جميع الإشعارات؟',
  'Deadline is expired': 'انتهت صلاحية الموعد النهائي',
  'You have left unsaved crossover. To continue delete it or save it (to send it to other recipients)':
    'لقد تركت تحويلًا غير محفوظ. للمتابعة احذفه أو احفظه (لإرساله إلى مستلمين آخرين)',
  'You have left unsaved correction. To continue delete it or save it (to send it to other recipients)':
    'لقد تركت تصحيحًا غير محفوظ. للمتابعة احذفه أو احفظه (لإرساله إلى مستلمين آخرين)',
  day: 'يوم',
  days: 'أيام',
  'We suggest you to contact Support and Contract sender': 'نقترح عليك الاتصال بالدعم وبمرسل العقد',
  'Save entered data in a contract? Make sure you filled all fields correctly, because you could not change it later in a contract':
    'هل ترغب في حفظ البيانات المدخلة في العقد؟ تأكد من تعبئة جميع الحقول بصورة صحيحة, حيث لا يمكنك تغييرها لاحقًا في العقد',
  Renamed: 'تمت إعادة التسمية',
  Reuploaded: 'تمت إعادة التحميل',
  'Crossed over': 'تم التحويل',
  'Left correction': 'ترك التصحيح',
  Accepted: 'مقبول',
  Rejected: 'مرفوض',
  'Cancelled acceptation': 'تم إلغاء القبول',
  'Cancelled rejection': 'تم إلغاء الرفض',
  'Cleared corrections': 'تم مسح التصحيحات',
  Downloaded: 'تم التنزيل',
  Resent: 'تمت إعادة الإرسال',
  Accept: 'قبول',
  Reject: 'رفض',
  'Accepted by': 'مقبول بواسطة',
  'Rejected by': 'مرفوض بواسطة',
  'Make sure decision on this item is your responsibility. You could not cancel the action after confirmation. Continue?':
    'تأكد من أنه يقع على عاتقك اتخاذ قرار بشأن هذا البند. ولا يمكنك إلغاء الإجراء بعد التأكيد. متابعة؟',
  'Accepted successfully': 'تم القبول بنجاح',
  'Rejected successfully': 'تم الرفض بنجاح',
  'Cancel acceptation? It will be cleared and other recipients will be able to make a decision again':
    'هل ترغب في إلغاء القبول؟ سيتم مسحه وسيتمكن مستلمون آخرون من اتخاذ قرار مجددًا',
  'Cancel rejection? It will be cleared and other recipients will be able to make a decision again':
    'هل ترغب في إلغاء الرفض؟ سيتم مسحه وسيتمكن مستلمون آخرون من اتخاذ قرار مجددًا',
  'Acceptation cleared successfully': 'تم مسح القبول بنجاح',
  'Rejection cleared successfully': 'تم مسح الرفض بنجاح',
  'Upload From': 'تحميل من',
  Device: 'الجهاز',
  Template: 'القالب',
  Recipient: 'المستلم',
  'Add one more recipient': 'إضافة مستلم آخر',
  'Returned with corrections': 'تمت الإعادة مع تصحيحات',
  Owner: 'المالك',
  'You want to open the contract as:': 'أنت ترغب في فتح العقد باسم:',
  'Enter your password to open the document': 'أدخل كلمة المرور لفتح المستند',
  'Sign Up For': 'التسجيل في',
  payment: 'السداد',
  Amount: 'المبلغ',
  'Annual subscription': 'الاشتراك السنوي',
  'Monthly subscription': 'الاشتراك الشهري',
  'Select option': 'تحديد خيار',
  'Waiting for the payment to be processed': 'انتظار اكتمال الدفعة',
  'This is your current plan. Please select another one.': 'هذه خطتك الحالية. يرجى تحديد خطة أخرى.',
  'Save card': 'حفظ البطاقة',
  Card: 'البطاقة',
  'has been successfully saved': 'تم حفظها بنجاح',
  'Subscription updated successfully': 'تم تحديث الاشتراك بنجاح',
  Pay: 'دفع',
  'Use another card': 'استخدم بطاقة أخرى',
  month: 'شهر',
  'You have to many cards now. Please delete some of them and add a card again on Payment section.':
    'لديك العديد من البطاقات الآن. يرجى حذف بعض منها وإضافة بطاقة مرة أخرى في قسم السداد.',
  'Failed to add card': 'فشلت إضافة بطاقة',
  'Specify card': 'تحديد بطاقة',
  'Please fill your card details to continue': 'يرجى تعبئة تفاصيل البطاقة للمتابعة',
  'Successful payment': 'سداد ناجح',
  'Show all': 'إظهار الكل',
  'Card has been successfully added': 'تمت إضافة البطاقة بنجاح',
  'You can start your free trial': 'يمكنك بدء تجربتك المجانية',
  'Free trial': 'تجربة مجانية',
  'Do you want to log out?': 'هل ترغب في تسجيل الخروج؟',
  'Select subscription': 'تحديد الاشتراك',
  'Start free trial': 'بدء التجربة المجانية',
  'Subscribe now': 'اشترك الآن',
  'Please select subscription term': 'يرجى تحديد مدة الاشتراك',
  Monthly: 'شهري',
  Yearly: 'سنوي',
  plan: 'الخطة',
  'To confirm your card, we will charge you a minimal fee and return it back.':
    'لتأكيد بطاقتك, سنخصم من حسابك رسومًا قليلة ثم سنعيدها إلى حسابك.',
  'plan is connected': 'تم توصيل الخطة',
  'Error, please check your URL and try again': 'خطأ, يرجى التحقق من عنوان الرابط والمحاولة مجددًا',
  'Please check your URL and try again': 'يرجى التحقق من عنوان الرابط والمحاولة مجددًا',
  'Your subscription will be upgraded right now, but you will not be able to downgrade until the next payment. Continue?':
    'سيتم ترقية اشتراكك الآن ، لكن لن تتمكن من الرجوع إلى إصدار سابق حتى الدفعة التالية. موافق؟',
  'Your subscription will be downgraded after the current one will expire. Continue?':
    'سيتم تخفيض اشتراكك بعد انتهاء صلاحية الاشتراك الحالي. موافق؟',
}
