import { FETCH_STATUSES } from '../../../types/fetch'
import { RootState } from '../../rootReducer.types'

export const subscriptionCardsListSelector = (state: RootState) => state.subscription.cards.list
export const subscriptionCardsLastAddedCardSelector = (state: RootState) => state.subscription.cards.lastAddedCard
export const subscriptionCardsFetchStatusSelector = (state: RootState) => state.subscription.cards.fetchStatus
export const subscriptionCardsErrorSelector = (state: RootState) => state.subscription.cards.error

export const subscriptionCardsSetDefaultFetchStatusSelector = (state: RootState) =>
  state.subscription.cards.setDefault.fetchStatus
export const subscriptionCardsSetDefaultErrorSelector = (state: RootState) => state.subscription.cards.setDefault.error
export const subscriptionCardsSetDefaultSuccessSelector = (state: RootState) =>
  state.subscription.cards.setDefault.fetchStatus === FETCH_STATUSES.SUCCESS
