import React from 'react'
import { useTheme } from 'styled-components'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import translations from '../../../translations/keys'
import { StyledThemeScheme } from '../../../themes/light'
import { sidebarFooterMenu, sidebarMenu } from '../../routes/menus'
import useAuth from '../../../hooks/useAuth'
import useNavControls from '../../hooks/useNavControls'
import useMediaQuery from '../../hooks/useMediaQuery'
import useLocationInfo from '../../hooks/useLocationInfo'
import { LogoutIcon, ChevronRightIcon, ChevronLeftIcon } from '../../assets/icons'
import MenuLink from '../MenuLink'
import { useAccessFeature } from '../../../hooks/subscription/useAccessFeature'
import { isBannerVisibleSelector } from '../../../store/banner/banner.selectors'

import * as Styled from './Sidebar.styles'

export const Sidebar: React.FC = () => {
  const { logout } = useAuth()
  const { section, pathname } = useLocationInfo()
  const theme = useTheme() as StyledThemeScheme
  const tabletScreen = useMediaQuery(theme.devices.tablet)
  const mobileScreen = useMediaQuery(theme.devices.mobile)
  const { sidebarOpen, setSidebarOpen } = useNavControls()
  const { checkAccess } = useAccessFeature()
  const isBannerVisible = useSelector(isBannerVisibleSelector)
  const { t } = useTranslation()
  const showHideButton = tabletScreen && !mobileScreen
  const showFooterMenu = !tabletScreen || sidebarOpen

  const handleSidebarOpen = () => {
    setSidebarOpen((prevValue) => !prevValue)
  }

  const handleLogout = () => {
    logout()
  }

  if (!section || !sidebarMenu[section]) {
    return null
  }

  return (
    <Styled.SidebarWrapper
      test-id="sidebar-nav"
      open={sidebarOpen}
      mobile={mobileScreen}
      isBannerVisible={isBannerVisible}
    >
      <Styled.SidebarList test-id="sidebar-ul">
        {showHideButton && (
          <Styled.SidebarListItem test-id="sidebar-li">
            <Styled.MenuLink
              test-id="sidebar-link"
              to="#"
              smallPadding={!sidebarOpen}
              Icon={!sidebarOpen ? ChevronRightIcon : ChevronLeftIcon}
              onClick={handleSidebarOpen}
            >
              {t(translations.HIDE_BUTTON)}
            </Styled.MenuLink>
          </Styled.SidebarListItem>
        )}
        {sidebarMenu[section].map((item) => {
          const onClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
            if (mobileScreen) {
              setSidebarOpen(false)
            }

            if (!checkAccess(item.feature)) {
              event.preventDefault()
            }
          }
          const active = () => {
            if (mobileScreen) {
              return false
            }

            if (item.activeUrl) {
              return pathname.includes(item.activeUrl)
            }

            return item.route === pathname
          }
          const isActive = active()

          return (
            <Styled.SidebarListItem
              test-id="sidebar-menu-li"
              data-testactive={isActive ? item.name : ''}
              key={item.name}
            >
              <MenuLink
                to={item.route}
                active={isActive}
                disabled={!item.route}
                smallPadding={!sidebarOpen}
                showTriangle={mobileScreen}
                Icon={item.Icon}
                onClick={onClick}
              >
                {t(translations[item.name])}
              </MenuLink>
            </Styled.SidebarListItem>
          )
        })}
        {mobileScreen && (
          <Styled.LogoutListItem test-id="sidebar-logout-li">
            <MenuLink
              test-id="sidebar-logout-link"
              to="#"
              smallPadding={!sidebarOpen}
              Icon={LogoutIcon}
              onClick={handleLogout}
            >
              {t(translations.LOGOUT)}
            </MenuLink>
          </Styled.LogoutListItem>
        )}
      </Styled.SidebarList>
      {showFooterMenu && (
        <Styled.FooterListWrapper test-id="sidebar-footer-div">
          <Styled.FooterList test-id="sidebar-footer-ul">
            {sidebarFooterMenu.map((item) => (
              <Styled.SidebarFooterListItem test-id="sidebar-footer-li" key={item.name}>
                <Styled.FooterLink test-id="sidebar-footer-link" compact disabled={!item.route} to={item.route}>
                  {t(translations[item.name])}
                </Styled.FooterLink>
              </Styled.SidebarFooterListItem>
            ))}
          </Styled.FooterList>
        </Styled.FooterListWrapper>
      )}
    </Styled.SidebarWrapper>
  )
}
