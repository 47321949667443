import React from 'react'
import { useSelector } from 'react-redux'
import { useTheme } from 'styled-components'

import { isBannerVisibleSelector } from '../../../../store/banner/banner.selectors'
import { isProgressVisibleSelector } from '../../../../store/progress/progress.selectors'
import { StyledThemeScheme } from '../../../../themes/light'
import useMediaQuery from '../../../hooks/useMediaQuery'
import { BackButton } from '../../../ui/BackButton/BackButton'
import ErrorBoundary from '../../ErrorBoundary/ErrorBoundary'

import * as Styled from './FullScreenTemplate.styles'
import { FullScreenTemplateProps } from './FullScreenTemplate.types'

export const FullScreenTemplate: React.FC<FullScreenTemplateProps> = ({
  headerContent,
  sidebarContent,
  children,
  backPath,
  backButtonText,
}) => {
  const isBannerVisible = useSelector(isBannerVisibleSelector)
  const theme = useTheme() as StyledThemeScheme
  const tabletScreen = useMediaQuery(theme.devices.tablet)
  const isProgressVisible = useSelector(isProgressVisibleSelector)
  const isSidebarOpen = tabletScreen ? isProgressVisible : true

  return (
    <>
      <Styled.Header isBannerVisible={isBannerVisible}>{headerContent}</Styled.Header>
      <Styled.Main isBannerVisible={isBannerVisible}>
        {sidebarContent && <Styled.Sidebar isSidebarOpen={isSidebarOpen}>{sidebarContent}</Styled.Sidebar>}

        <Styled.Content withSidebar={Boolean(sidebarContent)} isSidebarOpen={isSidebarOpen}>
          {backPath && <BackButton path={backPath} label={backButtonText} />}
          <ErrorBoundary>{children}</ErrorBoundary>
        </Styled.Content>
      </Styled.Main>
    </>
  )
}
