import { FetchFailureAction, FETCH_STATUSES } from '../../types/fetch'
import { createReducer } from '../createReducer'

import * as ACTIONS from './ekyc.actions'
import * as TYPES from './ekyc.types'

type Actions =
  | TYPES.FetchEnrollAction
  | FetchFailureAction
  | TYPES.FetchEnrollSuccessAction
  | TYPES.FetchEKYCAttemptsSuccessAction

export const initialState: TYPES.EKYCState = {
  ekycEnroll: {
    fetchStatus: FETCH_STATUSES.IDLE,
    data: null,
    error: null,
  },
  ekycConfirmation: {
    fetchStatus: FETCH_STATUSES.IDLE,
    isConfirmed: false,
    error: null,
  },
  ekycVerification: {
    fetchStatus: FETCH_STATUSES.IDLE,
    error: null,
    isVerified: false,
  },
  eKYCAttempts: {
    fetchStatus: FETCH_STATUSES.IDLE,
    error: null,
    value: null,
  },
}

export const ekyc = createReducer<TYPES.EKYCState, Actions>(initialState, {
  [ACTIONS.fetchEKYCTypes.request]: (state) => ({
    ...state,
    ekycEnroll: {
      ...state.ekycEnroll,
      fetchStatus: FETCH_STATUSES.REQUEST,
    },
  }),
  [ACTIONS.fetchEKYCTypes.success]: (state, action) => ({
    ...state,
    ekycEnroll: {
      data: (action as TYPES.FetchEnrollSuccessAction).payload,
      fetchStatus: FETCH_STATUSES.SUCCESS,
      error: null,
    },
  }),
  [ACTIONS.fetchEKYCTypes.failure]: (state, action) => ({
    ...state,
    ekycEnroll: {
      ...state.ekycEnroll,
      fetchStatus: FETCH_STATUSES.FAILURE,
      error: (action as FetchFailureAction).payload,
    },
  }),
  [ACTIONS.fetchEKYCStatusTypes.request]: (state) => ({
    ...state,
    ekycConfirmation: {
      ...state.ekycConfirmation,
      fetchStatus: FETCH_STATUSES.REQUEST,
    },
  }),
  [ACTIONS.fetchEKYCStatusTypes.success]: (state) => ({
    ...state,
    ekycConfirmation: {
      ...state.ekycConfirmation,
      fetchStatus: FETCH_STATUSES.SUCCESS,
      isConfirmed: true,
    },
  }),
  [ACTIONS.fetchEKYCStatusTypes.failure]: (state, action) => ({
    ...state,
    ekycConfirmation: {
      ...state.ekycConfirmation,
      fetchStatus: FETCH_STATUSES.FAILURE,
      error: (action as FetchFailureAction).payload,
    },
  }),
  [ACTIONS.fetchVerifyEKYCTypes.request]: (state) => ({
    ...state,
    ekycVerification: {
      ...state.ekycVerification,
      fetchStatus: FETCH_STATUSES.REQUEST,
    },
  }),
  [ACTIONS.fetchVerifyEKYCTypes.success]: (state) => ({
    ...state,
    ekycVerification: {
      ...state.ekycVerification,
      fetchStatus: FETCH_STATUSES.SUCCESS,
      isVerified: true,
    },
  }),
  [ACTIONS.fetchVerifyEKYCTypes.failure]: (state, action) => ({
    ...state,
    ekycVerification: {
      ...state.ekycVerification,
      fetchStatus: FETCH_STATUSES.FAILURE,
      error: (action as FetchFailureAction).payload,
    },
  }),
  [ACTIONS.fetchEKYCAttemptsTypes.request]: (state) => ({
    ...state,
    eKYCAttempts: {
      ...state.eKYCAttempts,
      fetchStatus: FETCH_STATUSES.REQUEST,
    },
  }),
  [ACTIONS.fetchEKYCAttemptsTypes.success]: (state, action) => ({
    ...state,
    eKYCAttempts: {
      ...state.eKYCAttempts,
      value: (action as TYPES.FetchEKYCAttemptsSuccessAction).payload.attempts,
    },
  }),
  [ACTIONS.fetchEKYCAttemptsTypes.failure]: (state, action) => ({
    ...state,
    eKYCAttempts: {
      ...state.eKYCAttempts,
      fetchStatus: FETCH_STATUSES.FAILURE,
      error: (action as FetchFailureAction).payload,
    },
  }),
  [TYPES.EKYCActions.CLEAR_ENROLL]: (state) => ({
    ...state,
    ekycEnroll: { ...initialState.ekycEnroll },
  }),
  [TYPES.EKYCActions.CLEAR_CONFIRMATION]: (state) => ({
    ...state,
    ekycConfirmation: { ...initialState.ekycConfirmation },
  }),
  [TYPES.EKYCActions.CLEAR_VERIFICATION]: (state) => ({
    ...state,
    ekycVerification: { ...initialState.ekycVerification },
  }),
  [TYPES.EKYCActions.CLEAR_ATTEMPTS]: (state) => ({
    ...state,
    eKYCAttempts: { ...initialState.eKYCAttempts },
  }),
  [TYPES.EKYCActions.CLEAR_EKYC]: () => ({ ...initialState }),
})
