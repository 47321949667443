import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTheme } from 'styled-components'
import { useTranslation } from 'react-i18next'

import * as SELECTORS from '../../../../../store/contracts/contractsList/contractsList.selectors'
import * as ACTIONS from '../../../../../store/contracts/contractsList/contractsList.actions'
import Table from '../../../../ui/Table'
import { Sorting } from '../../../../../types/table'
import Pagination from '../../../../ui/Pagination'
import Search from '../../../../ui/Search'
import translations from '../../../../../translations/keys'
import Box from '../../../../ui/Box'
import Filters from '../Filters'
import Button from '../../../../ui/Button'
import { BUTTON_VARIANTS } from '../../../../ui/Button/Button.types'
import UploadMenu from '../UploadMenu'
import AppliedFilters from '../AppliedFilters'
import Typography from '../../../../ui/Typography'
import { TYPOGRAPHY_NAMES } from '../../../../ui/Typography/Typography.types'
import { FolderSvg } from '../../../../assets/FolderSvg'
import { StyledThemeScheme } from '../../../../../themes/light'
import useMediaQuery from '../../../../hooks/useMediaQuery'
import NotificationBadge from '../../../../ui/NotificationBadge'
import { MobileTable } from '../../../../ui/MobileTable'
import { CONTRACTS_MODALS } from '../../../../../constants/contracts'
import { openModal } from '../../../../../store/modals/modals.actions'
import { mobileColumns } from '../../../../../constants/contacts'
import Breadcrumbs from '../../../../components/Breadcrumbs'
import { Breadcrumb } from '../../../../../types/breadcrumb'
import { useContractsNotification } from '../../../../../hooks/contracts/useContractsNotification'

import {
  StyledUploadMenuWrapper,
  StyledSearchWrapper,
  StyledMessageWrapper,
  StyledContractsWrapper,
} from './ContractsList.styles'
import { useActions } from './hooks/useActions'
import { useColumns } from './hooks/useColumns'
import { useMobileColumn } from './hooks/useMobileColumn'
import { useOpenSigning } from './hooks/useOpenSigning'

export const ContractsList: React.FC = () => {
  const theme = useTheme() as StyledThemeScheme
  const laptopScreen = useMediaQuery(theme.devices.laptop)
  const mobileScreen = useMediaQuery(theme.devices.mobile)
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const contracts = useSelector(SELECTORS.contractsListDataSelector)
  const sorting = useSelector(SELECTORS.contractsListSortingSelector)
  const total = useSelector(SELECTORS.contractsListTotalSelector)
  const offset = useSelector(SELECTORS.contractsListOffsetSelector)
  const limit = useSelector(SELECTORS.contractsListLimitSelector)
  const searchText = useSelector(SELECTORS.contractsListSearchTextSelector)
  const notification = useSelector(SELECTORS.contractsNotificationSelector)
  const breadcrumbs = useSelector(SELECTORS.contractsListBreadcrumbs)
  const isEmptyFolder = useSelector(SELECTORS.contractsListIsEmptyFolder)
  const isEmptyContracts = useSelector(SELECTORS.contractsListIsEmptyContracts)
  const actions = useActions()
  const columns = useColumns()
  const mobileColumn = useMobileColumn()

  useContractsNotification()
  useOpenSigning()

  const handleSorting = (sorting: Sorting) => {
    dispatch(ACTIONS.contractsListSetSorting(sorting))
  }
  const handlePageChange = (page: number) => {
    dispatch(ACTIONS.contractsListSetOffset(page))
  }
  const handleSearchTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(ACTIONS.contractsListSetSearchText(e.target.value))
  }
  const handleSearchTextClear = () => {
    dispatch(ACTIONS.contractsListSetSearchText(''))
  }
  const handleNotificationBadgeClose = () => {
    dispatch(ACTIONS.cleanContractsNotification())
  }
  const getEmptyResultsMessage = () => {
    if (isEmptyContracts) {
      return t(translations.CONTRACTS_EMPTY_RESULTS)
    }
    if (isEmptyFolder) {
      return (
        <Box display="flex" flexDirection="column" alignItems="center" width="100%" mt={8} mtMobile={6}>
          <FolderSvg />
          <Box mt={5} mtMobile={3} textAlign="center">
            {t(translations.EMPTY_FOLDER)}
          </Box>
        </Box>
      )
    }

    return ''
  }
  const onUploadContract = () => {
    dispatch(openModal(CONTRACTS_MODALS.UPLOAD_CONTRACT))
  }
  const loadMore = () => {
    dispatch(ACTIONS.contractsListSetOffset(offset + limit, true))
  }
  const handleAddFolder = () => {
    dispatch(openModal(CONTRACTS_MODALS.CONTRACTS_CREATE_FOLDER))
  }
  const onClickBreadcrumb = useCallback((breadcrumb: Breadcrumb) => {
    dispatch(ACTIONS.contractsListGoToFolder(breadcrumb))
  }, [])

  return (
    <StyledContractsWrapper>
      <Box display="flex" mb={1}>
        <StyledSearchWrapper>
          <Search
            placeholder={t(translations.SEARCH)}
            value={searchText}
            onChange={handleSearchTextChange}
            onClickIcon={handleSearchTextClear}
            note={t(searchText && translations.CONTRACTS_SEARCH_INFO)}
          />
        </StyledSearchWrapper>
        <Box height={45} ml={5} mlLaptop={3} mlMobile={2} display="flex" alignItems="center">
          <Filters />
          {breadcrumbs.length <= 1 && (
            <Box ml={5} mr={5} mlLaptop={2} mrLaptop={2}>
              <Button icon="folderAdd" variant={BUTTON_VARIANTS.TEXT} onClick={handleAddFolder}>
                {!laptopScreen && (
                  <Typography name={TYPOGRAPHY_NAMES.body14} color="grey">
                    {t(translations.ADD_FOLDER)}
                  </Typography>
                )}
              </Button>
            </Box>
          )}
          {mobileScreen && (
            <Box>
              <Button icon="add" variant={BUTTON_VARIANTS.INACTIVE} onClick={onUploadContract} />
            </Box>
          )}
        </Box>
        {!mobileScreen && (
          <StyledUploadMenuWrapper>
            <UploadMenu />
          </StyledUploadMenuWrapper>
        )}
      </Box>
      <AppliedFilters />
      {breadcrumbs.length > 1 && (
        <Box mb={3} mt={-1}>
          <Breadcrumbs items={breadcrumbs} onClick={onClickBreadcrumb} />
        </Box>
      )}
      {mobileScreen ? (
        <MobileTable
          columns={mobileColumns}
          data={contracts}
          sorting={sorting}
          onSort={handleSorting}
          highlightedText={searchText}
          renderColumn={mobileColumn}
          hasMore={total > offset}
          loadMore={loadMore}
          actions={actions}
          emptyResultsMessage={getEmptyResultsMessage()}
        />
      ) : (
        <>
          <Table
            columns={columns}
            data={contracts}
            sorting={sorting}
            onSort={handleSorting}
            emptyResultsMessage={getEmptyResultsMessage()}
            highlightedText={searchText}
            customActions={actions}
          />
          <Pagination total={total} offset={offset} limit={limit} onChange={handlePageChange} />
        </>
      )}
      {notification && (
        <StyledMessageWrapper>
          <NotificationBadge
            isShow
            isAutoClose
            message={t(translations[notification.message])}
            onClose={handleNotificationBadgeClose}
          />
        </StyledMessageWrapper>
      )}
    </StyledContractsWrapper>
  )
}
