import { FetchFailureAction, FetchFailurePayload } from '../../../types/fetch'
import { createFetchActionTypes } from '../../../utils/createFetchActionTypes'

import * as TYPES from './contactUs.types'

export const fetchContactUsTypes = createFetchActionTypes(TYPES.ContactUsActions.FETCH_SUBSCRIPTION_CONTACT_US)

export const fetchContactUs = (message: string): TYPES.FetchContactUs => ({
  type: fetchContactUsTypes.request,
  payload: { message },
})

export const fetchContactUsSuccess = (): TYPES.FetchContactUsSuccess => ({
  type: fetchContactUsTypes.success,
})

export const fetchContactUsFailure = (payload: FetchFailurePayload): FetchFailureAction => ({
  type: fetchContactUsTypes.failure,
  payload,
})

export const clearContactUs = () => ({
  type: TYPES.ContactUsActions.CLEAR_SUBSCRIPTION_CONTACT_US,
})
