import { Epic, combineEpics, ofType } from 'redux-observable'
import { concat, of } from 'rxjs'
import { catchError, map, mergeMap, switchMap, takeUntil } from 'rxjs/operators'

import { signingService } from '../../../api'
import { catchFetchError } from '../../../utils/catchFetchError'
import {
  prepareCommentsForStore,
  prepareCommentsThreadForStore,
  prepareCommentForRequest,
} from '../../../utils/comment'

import * as ACTIONS from './comments.actions'

const fetchComments: Epic = (action$) =>
  action$.pipe(
    ofType(ACTIONS.fetchCommentsTypes.request),
    mergeMap(({ payload }) =>
      signingService.fetchComments(payload.contractId).pipe(
        map((response) => ACTIONS.fetchCommentsSuccess(...prepareCommentsForStore(response))),
        catchError(catchFetchError(ACTIONS.fetchCommentsFailure)),
        takeUntil(action$.pipe(ofType(ACTIONS.fetchCommentsTypes.success, ACTIONS.fetchCommentsTypes.failure)))
      )
    )
  )

const fetchCommentsThread: Epic = (action$) =>
  action$.pipe(
    ofType(ACTIONS.fetchCommentsThreadTypes.request),
    mergeMap(({ payload }) =>
      signingService.fetchCommentsThread(payload).pipe(
        map((response) => ACTIONS.fetchCommentsThreadSuccess(prepareCommentsThreadForStore(response.comments))),
        catchError(catchFetchError(ACTIONS.fetchCommentsThreadFailure)),
        takeUntil(
          action$.pipe(ofType(ACTIONS.fetchCommentsThreadTypes.success, ACTIONS.fetchCommentsThreadTypes.failure))
        )
      )
    )
  )

const fetchCreateComment: Epic = (action$) =>
  action$.pipe(
    ofType(ACTIONS.fetchCreateCommentTypes.request),
    switchMap(({ payload }) =>
      concat(
        signingService.fetchCreateComment(payload.contractId, prepareCommentForRequest(payload.comment)).pipe(
          map(({ response }) => ACTIONS.fetchCreateCommentSuccess(response)),
          catchError(catchFetchError(ACTIONS.fetchCreateCommentFailure)),
          takeUntil(
            action$.pipe(ofType(ACTIONS.fetchCreateCommentTypes.success, ACTIONS.fetchCreateCommentTypes.failure))
          )
        ),
        of(ACTIONS.fetchComments(payload.contractId))
      )
    )
  )

const fetchConfirmComments: Epic = (action$) =>
  action$.pipe(
    ofType(ACTIONS.fetchConfirmCommentsTypes.request),
    switchMap(({ payload }) =>
      signingService.fetchConfirmComments(payload).pipe(
        mergeMap(() =>
          of(ACTIONS.fetchComments(payload.contractId), ACTIONS.fetchConfirmCommentsSuccess(payload.status))
        ),
        catchError(catchFetchError(ACTIONS.fetchConfirmCommentsFailure)),
        takeUntil(
          action$.pipe(ofType(ACTIONS.fetchConfirmCommentsTypes.success, ACTIONS.fetchConfirmCommentsTypes.failure))
        )
      )
    )
  )

const fetchCancelCommentsConfirmation: Epic = (action$) =>
  action$.pipe(
    ofType(ACTIONS.fetchCancelCommentsConfirmationTypes.request),
    switchMap(({ payload }) =>
      signingService.fetchCancelCommentsConfirmation(payload).pipe(
        mergeMap(() =>
          of(ACTIONS.fetchComments(payload.contractId), ACTIONS.fetchCancelCommentsConfirmationSuccess(payload.status))
        ),
        catchError(catchFetchError(ACTIONS.fetchCancelCommentsConfirmationFailure)),
        takeUntil(
          action$.pipe(
            ofType(
              ACTIONS.fetchCancelCommentsConfirmationTypes.success,
              ACTIONS.fetchCancelCommentsConfirmationTypes.failure
            )
          )
        )
      )
    )
  )

export const commentsEpics = combineEpics(
  fetchComments,
  fetchCommentsThread,
  fetchCreateComment,
  fetchConfirmComments,
  fetchCancelCommentsConfirmation
)
