import { combineEpics, Epic, ofType } from 'redux-observable'
import { catchError, map, mergeMap, takeUntil } from 'rxjs/operators'

import { authService } from '../../api'
import { catchFetchError } from '../../utils/catchFetchError'

import * as ACTIONS from './forgotPassword.actions'
import { forgotPasswordEmailSelector } from './forgotPassword.selectors'

const fetchForgotPassword: Epic = (action$) =>
  action$.pipe(
    ofType(ACTIONS.fetchForgotPasswordTypes.request),
    mergeMap(({ payload }) =>
      authService.fetchForgotPassword(payload.email).pipe(
        map((action) =>
          ACTIONS.fetchForgotPasswordSuccess(
            payload.email,
            action.response?.message?.length > 0 ? action.response.message[0] : undefined
          )
        ),
        catchError(catchFetchError(ACTIONS.fetchForgotPasswordFailure)),
        takeUntil(
          action$.pipe(ofType(ACTIONS.fetchForgotPasswordTypes.success, ACTIONS.fetchForgotPasswordTypes.failure))
        )
      )
    )
  )

const fetchResendVerification: Epic = (action$, state$) =>
  action$.pipe(
    ofType(ACTIONS.fetchResendVerificationTypes.request),
    mergeMap(() =>
      authService.fetchResendEmail(forgotPasswordEmailSelector(state$.value)).pipe(
        map(() => ACTIONS.fetchResendVerificationSuccess()),
        catchError(catchFetchError(ACTIONS.fetchResendVerificationFailure)),
        takeUntil(
          action$.pipe(
            ofType(ACTIONS.fetchResendVerificationTypes.success, ACTIONS.fetchResendVerificationTypes.failure)
          )
        )
      )
    )
  )

export const forgotPasswordEpics = combineEpics(fetchForgotPassword, fetchResendVerification)
