import React from 'react'
import { useTranslation } from 'react-i18next'

import translations from '../../../../../translations/keys'
import { SIGNING_TYPES } from '../../../../../types/signingOrder'
import { location } from '../../../../../utils/location'
import { BackButton } from '../../../../ui/BackButton/BackButton'
import { RecipientsDiagram } from '../../../../ui/RecipientsDiagram'
import Typography from '../../../../ui/Typography'
import { TYPOGRAPHY_NAMES } from '../../../../ui/Typography/Typography.types'
import Box from '../../../../ui/Box'

import { PlacementSidebarProps } from './PlacementSidebar.types'

export const PlacementSidebar: React.FC<PlacementSidebarProps> = ({ contractId, sender, recipients }) => {
  const { t } = useTranslation()
  return (
    <>
      <BackButton compact path={location.contractsRecipientsDiagramUrl(contractId)} label={t(translations.BACK)} />
      <Box mb={3}>
        <Typography name={TYPOGRAPHY_NAMES.H4}>{t(translations.SIGNERS)}</Typography>
      </Box>
      <RecipientsDiagram compact sender={sender} recipients={recipients} order={SIGNING_TYPES.SEPARATE} />
    </>
  )
}
