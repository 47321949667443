import React from 'react'

import { SvgIconProps } from '../../ui/SvgIcon/SvgIcon.types'
import SvgIcon from '../../ui/SvgIcon'

export const ReloadIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <path d="M21.307 4.90547L19.9852 5.93906C18.1828 3.63516 15.3797 2.15625 12.232 2.15625C6.79688 2.15625 2.39766 6.55078 2.39063 11.9883C2.3836 17.4305 6.7922 21.8438 12.232 21.8438C16.4813 21.8438 20.1024 19.1484 21.4805 15.3727C21.5156 15.2742 21.4641 15.1641 21.3656 15.1312L20.0367 14.6742C19.9904 14.6583 19.9397 14.6612 19.8954 14.6822C19.8512 14.7033 19.817 14.7408 19.8 14.7867C19.7578 14.9039 19.7109 15.0211 19.6617 15.1359C19.2563 16.0969 18.675 16.9594 17.9344 17.7C17.1997 18.436 16.3299 19.0233 15.3727 19.4297C14.3813 19.8492 13.3242 20.0625 12.2367 20.0625C11.1469 20.0625 10.0922 19.8492 9.10079 19.4297C8.14261 19.0251 7.27248 18.4375 6.53907 17.7C5.80243 16.9654 5.21575 16.0946 4.81173 15.1359C4.3922 14.1422 4.17891 13.0875 4.17891 11.9977C4.17891 10.9078 4.3922 9.85313 4.81173 8.85938C5.2172 7.89844 5.79845 7.03594 6.53907 6.29531C7.2797 5.55469 8.1422 4.97344 9.10079 4.56563C10.0922 4.14609 11.1492 3.93281 12.2367 3.93281C13.3266 3.93281 14.3813 4.14609 15.3727 4.56563C16.3308 4.97026 17.201 5.55778 17.9344 6.29531C18.1664 6.52734 18.3844 6.77344 18.5859 7.03125L17.175 8.13281C17.1471 8.15441 17.1258 8.18344 17.1137 8.21658C17.1015 8.24972 17.099 8.28562 17.1063 8.32014C17.1137 8.35466 17.1306 8.38641 17.1552 8.41173C17.1798 8.43705 17.211 8.45492 17.2453 8.46328L21.3609 9.47109C21.4781 9.49922 21.593 9.41016 21.593 9.29062L21.6117 5.05078C21.6094 4.89609 21.4289 4.80938 21.307 4.90547Z" />
  </SvgIcon>
)
