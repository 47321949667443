import { FetchFailureAction, FetchFailurePayload } from '../../../types/fetch'
import { Option } from '../../../types/options'
import { UploadFolderBody } from '../../../types/uploadFolder'
import { createFetchActionTypes } from '../../../utils/createFetchActionTypes'

import * as TYPES from './folders.types'

export const fetchFoldersTypes = createFetchActionTypes(TYPES.FoldersActions.FETCH_FOLDERS)
export const fetchUploadFolderTypes = createFetchActionTypes(TYPES.FoldersActions.UPLOAD_FOLDER)

export const createFolder = (folder: Option): TYPES.CreateFolder => ({
  type: TYPES.FoldersActions.CREATE_FOLDER,
  payload: { folder },
})

export const cleanFolders = () => ({
  type: TYPES.FoldersActions.CLEAN_FOLDER,
})

export const fetchFolders = (): TYPES.FetchFoldersAction => ({
  type: fetchFoldersTypes.request,
})
export const fetchFoldersSuccess = (data: Option[]): TYPES.FetchFoldersSuccessAction => ({
  type: fetchFoldersTypes.success,
  payload: { data },
})
export const fetchFoldersFailure = (payload: FetchFailurePayload): FetchFailureAction => ({
  type: fetchFoldersTypes.failure,
  payload,
})

export const fetchUploadFolder = (folder: UploadFolderBody, isMobile?: boolean) => ({
  type: fetchUploadFolderTypes.request,
  payload: { folder, isMobile },
})
export const fetchUploadFolderSuccess = () => ({
  type: fetchUploadFolderTypes.success,
})
export const fetchUploadFolderFailure = (payload: FetchFailurePayload): FetchFailureAction => ({
  type: fetchUploadFolderTypes.failure,
  payload,
})
