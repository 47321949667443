import { combineEpics, Epic, ofType } from 'redux-observable'
import { catchError, map, mergeMap, takeUntil } from 'rxjs/operators'

import { catchFetchError } from '../../../utils/catchFetchError'
import { templateService } from '../../../api'

import * as ACTIONS from './createFromContract.actions'

export const fetchCreateFromContract: Epic = (action$) =>
  action$.pipe(
    ofType(ACTIONS.fetchCreateTemplateFromContractTypes.request),
    mergeMap(({ payload }) =>
      templateService.fetchCreateFromContract(payload.body).pipe(
        map(({ response }) => ACTIONS.fetchCreateTemplateFromContractSuccess(response)),
        catchError(catchFetchError(ACTIONS.fetchCreateTemplateFromContractFailure)),
        takeUntil(
          action$.pipe(
            ofType(
              ACTIONS.fetchCreateTemplateFromContractTypes.success,
              ACTIONS.fetchCreateTemplateFromContractTypes.failure
            )
          )
        )
      )
    )
  )

export const createFromContractEpics = combineEpics(fetchCreateFromContract)
