import translations from '../../translations'
import * as TYPES from '../../types/placement'
import { RecipientActions, RecipientData, RecipientsById, RecipientSignatureTypes } from '../../types/recipients'
import getInitials from '../../utils/getInitials'
import omit from '../../utils/omit'
import range from '../../utils/range'
import { ContractsState } from '../../store/contracts/contracts.types'
import { TemplatesState } from '../../store/templates/templates.types'

// TODO: remove all these functions when API will generate default placements

type RecipientsWithPlaces = { [id: string]: { recipientId: string; places: TYPES.Place[] } }

export const calculateSignatureAttrs = (
  signatures: { recipientId: string; page: number }[] = [],
  pageWidth: number,
  pageHeight: number
) => {
  const blockWidth = Math.round(pageWidth * TYPES.SIGNATURES_BLOCK_WIDTH)
  const blockBottomMargin = Math.round(pageHeight * TYPES.SIGNATURES_BLOCK_BOTTOM_MARGIN)
  const marginRight = Math.round(blockWidth * TYPES.SIGNATURE_RIGHT_MARGIN)
  const startX = Math.round(pageWidth * (TYPES.PAGE_HOR_MARGIN + TYPES.SIGNATURES_BLOCK_LEFT_MARGIN))
  const width = Math.round(
    pageWidth * TYPES.SIGNATURE_WIDTH - marginRight * ((TYPES.SIGNATURES_PER_PAGE - 1) / TYPES.SIGNATURES_PER_PAGE)
  )
  const height = Math.round(width * TYPES.SIGNATURE_HEIGHT_RATIO)

  return signatures.map((sign, idx) => {
    const x = (width + marginRight) * idx + startX
    const y = pageHeight - blockBottomMargin - height

    return { ...sign, x, y, width, height }
  })
}

export const calculateInitialAttrs = (
  initials: { recipientId: string; page: number }[] = [],
  pageWidth: number,
  pageHeight: number
) => {
  const width = Math.round(pageWidth * TYPES.INITIAL_WIDTH)
  const height = Math.round(width * TYPES.INITIAL_HEIGHT_RATIO)
  const marginBottom = Math.round(pageHeight * TYPES.INITIAL_MARGIN_BOTTOM)
  const blockRightMargin = Math.round(pageWidth * TYPES.PAGE_HOR_MARGIN)
  const blockHeight = pageHeight - (height + marginBottom) * initials.length
  const startY = blockHeight / 2

  return initials.map((sign, idx) => {
    const x = pageWidth - width - blockRightMargin
    const y = startY + (height + marginBottom) * idx

    return { ...sign, x, y, width, height }
  })
}

export const generatePlacement = (
  docs: ContractsState | TemplatesState,
  totalPages: number,
  width: number,
  height: number
) => {
  const { byId: recipientsById, sorted: recipientsSorted } = docs.recipients
  const { byId: placementById, byPages: placementByPages } = docs.placement
  const pagesSorted = range(0, totalPages - 1)
  const pagesByIdx: any = pagesSorted.reduce((acc, cur) => ({ ...acc, [cur]: { signatures: [], initials: [] } }), {})
  let places: TYPES.Place[] = []

  const getAction = (action: RecipientActions) => {
    if (action === RecipientActions.SIGN) {
      return 'signatures'
    }
    if (action === RecipientActions.INITIAL) {
      return 'initials'
    }
    return null
  }
  const getPlaceId = (page: number, recipientId?: string) => {
    const places = placementByPages[page]
    const placeId = places && places.find((placeId) => placementById[placeId].recipientId === recipientId)
    return placeId
  }

  recipientsSorted.forEach((id) => {
    const recipient = recipientsById[id]
    const action = getAction(recipient.action)

    if (!action) {
      return
    }

    if (recipient.signatureType === RecipientSignatureTypes.EVERY_PAGE) {
      pagesSorted.forEach((page) => {
        const placeId = getPlaceId(page, recipient.id)
        pagesByIdx[page][action].push({ id: placeId, recipientId: recipient.id, page })
      })
    } else {
      const page = pagesSorted.length - 1
      const placeId = getPlaceId(page, recipient.id)
      pagesByIdx[page][action].push({ id: placeId, recipientId: recipient.id, page })
    }
  })

  Object.keys(pagesByIdx).forEach((idx) => {
    places = places.concat(
      calculateSignatureAttrs(pagesByIdx[idx].signatures, width, height),
      calculateInitialAttrs(pagesByIdx[idx].initials, width, height)
    )
  })

  const recipientsWithPlaces = places.reduce<RecipientsWithPlaces>((acc, cur) => {
    const { recipientId } = cur
    const cleanedPlace = omit(['recipientId'], cur)
    const recipientPlaces = acc[recipientId] ? acc[recipientId].places : []
    return {
      ...acc,
      [recipientId]: {
        recipientId,
        places: recipientPlaces.concat(cleanedPlace),
      },
    }
  }, {})

  const requestBody: TYPES.PlacementBody = {
    displayNames: false,
    recipients: Object.values(recipientsWithPlaces),
  }

  return requestBody
}

export const checkIfPlacementShouldRegen = (recipients: RecipientData[], prevRecipients: RecipientsById) => {
  const shouldRegenerate = recipients.some((recipient) => {
    if (!recipient.id) {
      return true
    }

    const prevRecipient = prevRecipients[recipient.id]

    if (recipient.action !== prevRecipient.action || recipient.signatureType !== prevRecipient.signatureType) {
      return true
    }

    return false
  })

  return shouldRegenerate
}

export const preparePlaces = (
  oldData: { sorted: TYPES.PlacementSorted; byId: TYPES.PlacementById; byPages: TYPES.PlacementByPages },
  places: TYPES.Place[],
  recipient?: Pick<RecipientData, 'id' | 'name' | 'action'>
): [TYPES.PlacementById, TYPES.PlacementByPages, TYPES.PlacementSorted] => {
  const sorted: TYPES.PlacementSorted = [...oldData.sorted]
  const byId: TYPES.PlacementById = {
    ...oldData.byId,
  }
  const byPages: TYPES.PlacementByPages = { ...oldData.byPages }

  if (recipient) {
    places.forEach((place) => {
      const type = recipient.action
      const name = recipient.name

      if (type === RecipientActions.VIEW) {
        return
      }

      const title = type === RecipientActions.SIGN ? name : getInitials(name)
      const subtitle = type === RecipientActions.SIGN ? translations.SIGNATURE_BOX_TEXT : translations.INITIALS
      const caption = type === RecipientActions.SIGN ? undefined : name
      const nextPlace = {
        ...place,
        recipientId: recipient.id,
        type: type.toLowerCase() as TYPES.SignatureType,
        title,
        subtitle,
        caption,
      }

      sorted.push(place.id!)
      byId[place.id!] = { ...omit(['createAt'], nextPlace) }
      byPages[place.page] = (byPages[place.page] || []).concat(nextPlace.id!)
    })
  }

  return [byId, byPages, sorted]
}

export const preparePlacement = (
  placement: TYPES.PlacementBody,
  recipientsById: RecipientsById
): [TYPES.PlacementById, TYPES.PlacementByPages, TYPES.PlacementSorted] => {
  let sorted: TYPES.PlacementSorted = []
  let byId: TYPES.PlacementById = {}
  let byPages: TYPES.PlacementByPages = {}

  placement.recipients.forEach((recipient, ind) => {
    const id: string = Object.values(recipientsById)[ind].id!
    const [newById, newByPages, newSorted] = preparePlaces({ sorted, byId, byPages }, recipient.places, {
      id: id,
      name: recipientsById[id].name,
      action: recipientsById[id].action,
    })
    sorted = newSorted
    byId = newById
    byPages = newByPages
  })

  return [byId, byPages, sorted]
}

export const updatePlacementById = (
  place: Omit<TYPES.Place, 'recipientId' | 'page'>,
  placementById: TYPES.PlacementById
): TYPES.PlacementById => {
  const id = place.id!
  const nextPlace = { ...placementById[id], ...place }
  return { ...omit([id], placementById), [id]: nextPlace }
}

export const preparePlacementForRequest = (
  byId: TYPES.PlacementById,
  sorted: TYPES.PlacementSorted
): TYPES.PlacementBody => {
  const recipients = sorted.reduce<RecipientsWithPlaces>((acc, placeId) => {
    const place = byId[placeId]
    const { recipientId } = place
    const recipientPlaces = acc[recipientId] ? acc[recipientId].places : []

    return {
      ...acc,
      [recipientId]: {
        recipientId: recipientId,
        places: recipientPlaces.concat(omit(['recipientId', 'type', 'title', 'subtitle', 'caption'], place)),
      },
    }
  }, {})

  const requestBody: TYPES.PlacementBody = {
    displayNames: false,
    recipients: Object.values(recipients),
  }

  return requestBody
}
