import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { CONTRACTS_MODALS } from '../../../../../constants/contracts'
import { closeModal } from '../../../../../store/modals/modals.actions'
import { modalsVisibleSelector } from '../../../../../store/modals/modals.selectors'
import SaveAsTemplateModal from '../../../../components/Modals/ContractsModals/SaveAsTemplateModal'
import CreateFolderForTemplateModal from '../../../../components/Modals/TemplatesModals/CreateFolderForTemplateModal'

export const Modals = () => {
  const dispatch = useDispatch()

  const showSaveContractAsTemplate = useSelector(modalsVisibleSelector(CONTRACTS_MODALS.SAVE_CONTRACT_AS_TEMPLATE))
  const handleSaveContractAsTemplateClose = () => {
    dispatch(closeModal(CONTRACTS_MODALS.SAVE_CONTRACT_AS_TEMPLATE))
  }

  const showCreateFolderForTemplate = useSelector(modalsVisibleSelector(CONTRACTS_MODALS.CREATE_FOLDER_FOR_TEMPLATE))
  const handleCreateFolderForTemplateClose = () => {
    dispatch(closeModal(CONTRACTS_MODALS.CREATE_FOLDER_FOR_TEMPLATE))
  }

  return (
    <>
      {showSaveContractAsTemplate && <SaveAsTemplateModal onClose={handleSaveContractAsTemplateClose} />}
      {showCreateFolderForTemplate && <CreateFolderForTemplateModal onClose={handleCreateFolderForTemplateClose} />}
    </>
  )
}
