import styled from 'styled-components'

import { Error404Svg } from '../../assets/Error404Svg'

export const StyledError404Svg = styled(Error404Svg)`
  ${({ theme }) => `@media ${theme.devices.mobile} {
    width: 208px;
    height: 133px;
  }`}
`
