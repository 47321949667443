import { EnabledFeatures, EnabledLimits, SubscriptionFeature, SubscriptionFeatures } from '../../types/subscription'

export interface FeatureItem extends SubscriptionFeature {
  enabled: boolean
  highlighted: boolean
}

export const getFeaturesList = (
  features: SubscriptionFeature[],
  userEnabledFeatures: EnabledFeatures | null,
  userEnabledLimits: EnabledLimits | null
): FeatureItem[] => {
  const allFeatures: { [key: string]: string } = { ...SubscriptionFeatures }
  const contractsFeatures: string[] = [
    SubscriptionFeatures.FEATURE_CONTRACTS_UNLIMITED,
    SubscriptionFeatures.FEATURE_CONTRACTS_COUNT,
  ]

  const enabledFeatures = features.map((feature) => {
    if (contractsFeatures.includes(feature.name)) {
      contractsFeatures.forEach((contractFeature) => {
        delete allFeatures[contractFeature]
      })
    } else {
      delete allFeatures[feature.name]
    }

    const isMoreLimit = Boolean(
      userEnabledLimits &&
        feature.limit &&
        userEnabledLimits[feature.name] &&
        userEnabledLimits[feature.name] < feature.limit
    )

    let isNewFeature = false
    if (feature.name === SubscriptionFeatures.FEATURE_CONTRACTS_COUNT) {
      isNewFeature = contractsFeatures.every(
        (contractFeature) => userEnabledFeatures && !userEnabledFeatures[contractFeature]
      )
    } else {
      isNewFeature = !!userEnabledFeatures && !userEnabledFeatures[feature.name]
    }

    return {
      ...feature,
      enabled: true,
      highlighted: isNewFeature || isMoreLimit,
    }
  })

  const disabledFeatures = Object.keys(allFeatures).map((feature) => ({
    name: feature as SubscriptionFeatures,
    enabled: false,
    highlighted: false,
  }))

  return [...enabledFeatures, ...disabledFeatures]
}
