import styled from 'styled-components'

export const StyledContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`
export const StyledPlaceholder = styled.div`
  align-self: center;
  margin-top: 208px;
  text-align: center;
  max-width: 208px;
  width: 208px;
`
export const StyledPlaceholderBtn = styled.div`
  margin: 0 auto;
`
