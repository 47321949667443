import React, { FC, useEffect } from 'react'

import { CloseIcon } from '../../assets/CloseIcon'

import {
  StyledWrapper,
  StyledOverlay,
  StyledModalWrapper,
  StyledClose,
  StyledTitle,
  StyledContent,
  StyledHeader,
} from './Modal.styles'
import { ModalProps } from './Modal.types'

export const Modal: FC<ModalProps> = ({
  children,
  onClose,
  title,
  fullSize,
  fillWidth,
  headerSize = 'default',
  offsetTop,
}) => {
  useEffect(() => {
    document.body.style.overflow = 'hidden'
    return () => {
      document.body.style.overflow = 'auto'
    }
  }, [])

  return (
    <StyledWrapper offsetTop={offsetTop}>
      <StyledOverlay onClick={onClose} test-id="modal-overlay" />
      <StyledModalWrapper fullSize={fullSize} fillWidth={fillWidth}>
        <StyledHeader title={title} fullSize={fullSize} headerSize={headerSize}>
          {title && <StyledTitle>{title}</StyledTitle>}
          <StyledClose onClick={onClose} test-id="modal-close">
            <CloseIcon />
          </StyledClose>
        </StyledHeader>
        <StyledContent fullSize={fullSize}>{children}</StyledContent>
      </StyledModalWrapper>
    </StyledWrapper>
  )
}
