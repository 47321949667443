import { EditableElem } from './editableElem'

export type DocFieldsById = { [id: string]: DocField }
export type DocFieldsByPages = { [id: string]: string[] }

export type DocFieldType = 'TEXT' | 'DATE'

export interface DocField extends EditableElem {
  page: number
  type: DocFieldType
  fontSize: number
  text?: string
}

export type DocFieldWithText = Pick<DocField, 'id' | 'text'>

export interface DocFieldsRequestBody {
  fields: Omit<DocField, 'id'>[]
}

export type DocFieldsResponseBody = DocField[]

export const DEFAULT_FIELD_FONT_SIZE = 12

export const DEFAULT_CORRECTION_FONT_SIZE = 16

export const fontSizeOptions = [8, 10, 11, 12, 14, 16]
