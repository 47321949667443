import React from 'react'
import { useHistory } from 'react-router-dom'

import Button from '../Button'
import { BUTTON_VARIANTS } from '../Button/Button.types'
import Typography from '../Typography'
import { TYPOGRAPHY_NAMES } from '../Typography/Typography.types'
import Box from '../Box'

import { BackButtonProps } from './BackButton.types'

export const BackButton: React.FC<BackButtonProps> = ({ label, path, compact = false }) => {
  const history = useHistory()
  const padding = compact ? 0 : 4

  const onClick = () => {
    history.push(path)
  }

  return (
    <Box pb={3} pt={padding} pl={padding}>
      <Box height={24}>
        <Button variant={BUTTON_VARIANTS.LINK} onClick={onClick} icon="chevronLeft">
          <Typography name={TYPOGRAPHY_NAMES.body14} color="grey">
            {label}
          </Typography>
        </Button>
      </Box>
    </Box>
  )
}
