import React from 'react'

import { SvgIconProps } from '../../ui/SvgIcon/SvgIcon.types'
import SvgIcon from '../../ui/SvgIcon'

interface PickerArrowIconProps extends SvgIconProps {
  flip?: boolean
}

export const PickerArrowIcon: React.FC<PickerArrowIconProps> = ({ flip, ...props }) => (
  <SvgIcon {...props} style={flip ? { transform: 'rotate(-180deg)' } : undefined}>
    <path d="M10.5428 0.542908L11.957 1.95712L6.16414 7.75001L11.957 13.5429L10.5428 14.9571L3.33571 7.75001L10.5428 0.542908Z" />
  </SvgIcon>
)
