import styled, { css } from 'styled-components'

import DefaultMenuLink from '../MenuLink'

import { SidebarProps } from './Sidebar.types'

const sidebarDefaultStyles = css<SidebarProps>`
  position: relative;
  width: ${({ open }) => (open ? '256px' : '56px')};
  flex-shrink: 0;
  flex-basis: ${({ open }) => (open ? '256px' : '56px')};
  background-color: ${({ theme }) => theme.palette.blueExtraLight};
`
const sidebarMobileStyles = css<SidebarProps>`
  overflow-y: auto;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  transform: ${({ open }) => (open ? 'translateX(0)' : 'translateX(-100%)')};
  padding-top: ${({ theme }) => theme.sizes.layout.headerHeight};
  background-color: ${({ theme }) => theme.palette.white};

  ${({ theme }) => `@media ${theme.devices.tablet} {
    padding-top: ${theme.sizes.layout.headerHeightMobile};
  }`}
`

export const SidebarWrapper = styled.nav<SidebarProps>`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  margin-top: ${({ isBannerVisible }) => (isBannerVisible ? '50px' : 0)};
  height: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
  border-right: ${({ theme }) => `1px solid ${theme.palette.greyLight}`};
  transition: width 300ms, flex-basis 300ms, transform 300ms ease-in-out;
  z-index: 90;

  ${({ mobile }) => (mobile ? sidebarMobileStyles : sidebarDefaultStyles)}
`
export const SidebarList = styled.ul`
  margin: 0;
  padding: 8px 0 0 0;
  list-style: none;
`
export const SidebarListItem = styled.li`
  position: relative;
  margin-top: 4px;
  margin-bottom: 4px;
  white-space: nowrap;

  ${({ theme }) => `@media ${theme.devices.mobile} {
    &::after {
      display: block;
      content: '';
      height: 1px;
      width: calc(100% - 36px);
      margin: 0 18px;
      flex-basis: 100%;
      background-color: ${theme.palette.greyLight};
    }
  }`}
`
export const LogoutListItem = styled.li`
  position: relative;
  white-space: nowrap;
  margin-top: 20px;
`
export const FooterListWrapper = styled.div`
  margin-top: auto;
`
export const SidebarFooterListItem = styled.li`
  white-space: nowrap;
  margin: 0 10px;
`
export const MenuLink = styled(DefaultMenuLink)`
  padding-top: 0;
`
export const FooterLink = styled(DefaultMenuLink)`
  justify-content: center;
  font-size: 0.6rem;
  padding: 0;
`

export const FooterList = styled.ul`
  margin: 0;
  padding: 0 0 0 0;
  list-style: none;
  display: flex;
  justify-content: center;
`
