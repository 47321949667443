import React from 'react'

import { SvgIconProps } from '../../ui/SvgIcon/SvgIcon.types'
import SvgIcon from '../../ui/SvgIcon'

export const DashboardIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <path d="M10.875 3.375H3.75C3.54375 3.375 3.375 3.54375 3.375 3.75V10.875C3.375 11.0813 3.54375 11.25 3.75 11.25H10.875C11.0813 11.25 11.25 11.0813 11.25 10.875V3.75C11.25 3.54375 11.0813 3.375 10.875 3.375ZM9.65625 9.65625H4.96875V4.96875H9.65625V9.65625ZM20.25 3.375H13.125C12.9187 3.375 12.75 3.54375 12.75 3.75V10.875C12.75 11.0813 12.9187 11.25 13.125 11.25H20.25C20.4562 11.25 20.625 11.0813 20.625 10.875V3.75C20.625 3.54375 20.4562 3.375 20.25 3.375ZM19.0312 9.65625H14.3438V4.96875H19.0312V9.65625ZM10.875 12.75H3.75C3.54375 12.75 3.375 12.9187 3.375 13.125V20.25C3.375 20.4562 3.54375 20.625 3.75 20.625H10.875C11.0813 20.625 11.25 20.4562 11.25 20.25V13.125C11.25 12.9187 11.0813 12.75 10.875 12.75ZM9.65625 19.0312H4.96875V14.3438H9.65625V19.0312ZM20.25 12.75H13.125C12.9187 12.75 12.75 12.9187 12.75 13.125V20.25C12.75 20.4562 12.9187 20.625 13.125 20.625H20.25C20.4562 20.625 20.625 20.4562 20.625 20.25V13.125C20.625 12.9187 20.4562 12.75 20.25 12.75ZM19.0312 19.0312H14.3438V14.3438H19.0312V19.0312Z" />
  </SvgIcon>
)
