import { Action } from 'redux'

import { FetchFailurePayload, FETCH_STATUSES } from '../../../types/fetch'

export interface DownloadState {
  fetchStatus: FETCH_STATUSES
  error: FetchFailurePayload | null
  link: string
  progress: {
    fetchStatus: FETCH_STATUSES
    error: FetchFailurePayload | null
  }
}

export enum DownloadActions {
  'FETCH_DOWNLOAD_CONTRACT' = 'FETCH_DOWNLOAD_CONTRACT',
  'SUBSCRIBE_ON_DOWNLOAD_PROGRESS' = 'SUBSCRIBE_ON_DOWNLOAD_PROGRESS',
  'UNSUBSCRIBE_ON_DOWNLOAD_PROGRESS' = 'UNSUBSCRIBE_ON_DOWNLOAD_PROGRESS',
  'CLEAN_DOWNLOAD' = 'CLEAN_DOWNLOAD',
}

export interface FetchDownloadContractAction extends Action {
  payload: { contractId: string }
}
export interface FetchDownloadContractSuccessAction extends Action {
  payload: { link: string }
}

export interface SubscribeOnDownloadProgressAction extends Action {
  payload: { contractId: string }
}
export type SubscribeOnDownloadProgressSuccessAction = Action
