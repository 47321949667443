import React from 'react'

import ErrorBoundary from '../../components/ErrorBoundary/ErrorBoundary'

import { TemplatesFillRecipients as TemplatesFillRecipientsView } from './TemplatesFillRecipients'

export const TemplatesFillRecipients = () => (
  <ErrorBoundary>
    <TemplatesFillRecipientsView />
  </ErrorBoundary>
)
