import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import * as SELECTORS from '../../../../../store/templates/list/list.selectors'
import * as ACTIONS from '../../../../../store/templates/list/list.actions'
import Box from '../../../../ui/Box'
import { FolderSvg } from '../../../../assets/FolderSvg'
import Search from '../../../../ui/Search'
import Button from '../../../../ui/Button'
import { BUTTON_VARIANTS } from '../../../../ui/Button/Button.types'
import Typography from '../../../../ui/Typography'
import { TYPOGRAPHY_NAMES } from '../../../../ui/Typography/Typography.types'
import UploadMenu from '../UploadMenu'
import Table from '../../../../ui/Table'
import NotificationBadge from '../../../../ui/NotificationBadge'
import translations from '../../../../../translations/keys'
import Pagination from '../../../../ui/Pagination'
import Breadcrumbs from '../../../../components/Breadcrumbs'
import { Breadcrumb } from '../../../../../types/breadcrumb'
import { Sorting } from '../../../../../types/table'
import { openModal } from '../../../../../store/modals/modals.actions'
import { TEMPLATES_MODALS } from '../../../../../constants/templates'

import { useActions } from './hooks/useActions'
import { useColumns } from './hooks/useColumns'
import { StyledSearchWrapper, StyledListWrapper, StyledMessageWrapper, StyledUploadMenuWrapper } from './List.styles'

export const List = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const templates = useSelector(SELECTORS.templatesListDataSelector)
  const sorting = useSelector(SELECTORS.templatesListSortingSelector)
  const total = useSelector(SELECTORS.templatesListTotalSelector)
  const offset = useSelector(SELECTORS.templatesListOffsetSelector)
  const limit = useSelector(SELECTORS.templatesListLimitSelector)
  const searchText = useSelector(SELECTORS.templatesListSearchTextSelector)
  const notification = useSelector(SELECTORS.templatesListNotificationSelector)
  const breadcrumbs = useSelector(SELECTORS.templatesListBreadcrumbs)
  const isEmptyFolder = useSelector(SELECTORS.templatesListIsEmptyFolder)
  const isEmptyContracts = useSelector(SELECTORS.templatesListIsEmptyContracts)
  const actions = useActions()
  const columns = useColumns()

  const handleSorting = (sorting: Sorting) => {
    dispatch(ACTIONS.templatesListSetSorting(sorting))
  }
  const handlePageChange = (page: number) => {
    dispatch(ACTIONS.templatesListSetOffset(page))
  }
  const handleSearchTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(ACTIONS.templatesListSetSearchText(e.target.value))
  }
  const handleSearchTextClear = () => {
    dispatch(ACTIONS.templatesListSetSearchText(''))
  }
  const handleNotificationBadgeClose = () => {
    dispatch(ACTIONS.templatesListCleanNotification())
  }
  const onClickBreadcrumb = useCallback((breadcrumb: Breadcrumb) => {
    dispatch(ACTIONS.templateListGoToFolder(breadcrumb))
  }, [])
  const getEmptyResultsMessage = () => {
    if (isEmptyContracts) {
      return t(translations.TEMPLATES_EMPTY_RESULTS)
    }
    if (isEmptyFolder) {
      return (
        <Box display="flex" flexDirection="column" alignItems="center" width="100%" mt={8} mtMobile={6}>
          <FolderSvg />
          <Box mt={5} mtMobile={3} textAlign="center">
            {t(translations.EMPTY_FOLDER)}
          </Box>
        </Box>
      )
    }

    return ''
  }

  const handleAddFolder = () => {
    dispatch(openModal(TEMPLATES_MODALS.TEMPLATES_CREATE_FOLDER))
  }

  return (
    <StyledListWrapper>
      <Box display="flex" mb={1}>
        <StyledSearchWrapper>
          <Search
            placeholder={t(translations.SEARCH)}
            value={searchText}
            onChange={handleSearchTextChange}
            onClickIcon={handleSearchTextClear}
            note={searchText && t(translations.CONTRACTS_SEARCH_INFO)}
          />
        </StyledSearchWrapper>
        <Box height={45} ml={5} mr={5} display="flex" alignItems="center">
          {breadcrumbs.length <= 1 && (
            <Box>
              <Button icon="folderAdd" variant={BUTTON_VARIANTS.TEXT} onClick={handleAddFolder}>
                <Typography name={TYPOGRAPHY_NAMES.body14} color="grey">
                  {t(translations.ADD_FOLDER)}
                </Typography>
              </Button>
            </Box>
          )}
        </Box>
        <StyledUploadMenuWrapper>
          <UploadMenu />
        </StyledUploadMenuWrapper>
      </Box>
      <Box mb={3}>
        <Breadcrumbs items={breadcrumbs} onClick={onClickBreadcrumb} />
      </Box>
      <Table
        columns={columns}
        data={templates}
        sorting={sorting}
        onSort={handleSorting}
        emptyResultsMessage={getEmptyResultsMessage()}
        highlightedText={searchText}
        customActions={actions}
      />
      <Pagination total={total} offset={offset} limit={limit} onChange={handlePageChange} />
      {notification && (
        <StyledMessageWrapper>
          <NotificationBadge
            isShow
            isAutoClose
            message={t(translations[notification.message])}
            onClose={handleNotificationBadgeClose}
            variant={notification.variant}
          />
        </StyledMessageWrapper>
      )}
    </StyledListWrapper>
  )
}
