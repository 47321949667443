import styled from 'styled-components'

import Box from '../../../../ui/Box'

export const StyledBadge = styled(Box).attrs({
  pt: 0.5,
  pb: 0.5,
  pl: 1,
  pr: 1,
  mr: 2,
  mb: 1,
  display: 'flex',
  alignItems: 'center',
})`
  color: ${({ theme }) => theme.palette.white};
  background-color: ${({ theme }) => theme.palette.grey};
  border-radius: 4px;
`

export const StyledDateTo = styled.span<{ lowerCase: boolean }>`
  ${({ lowerCase }) => lowerCase && 'text-transform: lowercase;'}
`
