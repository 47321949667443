import { useEffect, useState } from 'react'

export const useMaxSize = (contentRef: React.RefObject<HTMLDivElement>) => {
  const [width, setWidth] = useState<number | undefined>(undefined)
  const [height, setHeight] = useState<number | undefined>(undefined)

  useEffect(() => {
    const div = contentRef.current

    if (div) {
      setWidth(div.offsetWidth)
      setHeight(div.offsetHeight)
    }
  }, [])

  return {
    width,
    height,
  }
}
