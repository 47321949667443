import React from 'react'

import MainTemplate from '../../components/Main/MainTemplate'

import { Contracts as ContractsPage } from './Contracts'
import { ContractsProps } from './Contracts.types'

export const Contracts: React.FC<ContractsProps> = (props) => (
  <MainTemplate>
    <ContractsPage {...props} />
  </MainTemplate>
)
