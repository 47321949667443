import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  signaturesListDataSelector,
  signaturesListFetchStatusSelector,
} from '../../../../../store/signatures/signatures.selectors'
import { LoadHandler } from '../../../../components/LoadHandler/LoadHandler'
import { SignaturesList } from '../SignaturesList/SignaturesList'
import { fetchSignaturesList } from '../../../../../store/signatures/signatures.actions'

import { SignaturesContentProps } from './SignaturesContent.types'

export const SignaturesContent: React.FC<SignaturesContentProps> = ({ onAddSignature }) => {
  const dispatch = useDispatch()
  const signaturesListFetchStatus = useSelector(signaturesListFetchStatusSelector)
  const signaturesListData = useSelector(signaturesListDataSelector)

  useEffect(() => {
    dispatch(fetchSignaturesList())
  }, [])

  return (
    <LoadHandler fetchStatus={signaturesListFetchStatus}>
      <SignaturesList signatures={signaturesListData} onAddSignature={onAddSignature} />
    </LoadHandler>
  )
}
