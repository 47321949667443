import { SubscriptionPaymentTerms } from '../../types/subscriptionPayment'
import { CardSpecificationRouteQueryParams } from '../../types/cardSpecification'

import { QUERY_PARAMS } from './queryParams'

class Location {
  pathname = ''
  queryParams = QUERY_PARAMS

  constructor(pathname = '/') {
    this.pathname = pathname
  }

  rootUrl = () => this.pathname

  registrationUrl = () => `${this.pathname}registration`

  loginUrl = () => `${this.pathname}login`

  logoutUrl = () => `${this.pathname}logout`

  confirmEmailUrl = () => `${this.pathname}confirm-email`

  forgotPasswordUrl = () => `${this.pathname}forgot-password`

  restorePasswordUrl = () => `${this.pathname}restore-password`

  confirmPhone = () => `${this.pathname}confirm-phone`

  cardSpecification = (subscriptionId?: string, queryParams?: CardSpecificationRouteQueryParams) => {
    let params = ''

    if (queryParams) {
      const { term, type } = queryParams
      const termParam = term ? `&term=${term}` : ''

      params = `?type=${type}${termParam}`
    }

    return `${this.pathname}card-specification/${subscriptionId || ':subscriptionId'}${params}`
  }

  subscriptionSelectUrl = () => `${this.pathname}subscription/select`

  error403Url = () => `${this.pathname}error/403`
  error404Url = () => `${this.pathname}error/404`

  // TODO: update dashboard route when its ready
  dashboardUrl = () => `${this.pathname}`

  profileUrl = () => `${this.pathname}profile`
  editProfileUrl = () => `${this.pathname}profile/edit-profile`
  changePasswordUrl = () => `${this.pathname}profile/change-password`
  signatureAndInitialsUrl = () => `${this.pathname}profile/signature-and-initials`
  contactsUrl = () => `${this.pathname}profile/contacts`
  eKYCUrl = () => `${this.pathname}profile/ekyc`
  subscriptionUrl = () => `${this.pathname}profile/subscription`
  subscriptionPlansUrl = () => `${this.pathname}profile/subscription/plans`
  subscriptionViewUrl = () => `${this.pathname}profile/subscription/view`
  subscriptionPaymentUrl = (id: string | null = null, term: SubscriptionPaymentTerms | null = null) =>
    `${this.pathname}profile/subscription/${id || ':subscriptionId'}/payment/${term || ':term'}`
  apiIntegrationUrl = () => `${this.pathname}profile/api-integration`

  contractsUrl = () => `${this.pathname}contracts`
  contractsAllUrl = () => `${this.pathname}contracts/all`
  contractsReceivedUrl = () => `${this.pathname}contracts/received`
  contractsSentUrl = () => `${this.pathname}contracts/sent`
  contractsDraftsUrl = () => `${this.pathname}contracts/drafts`
  contractsDeletedUrl = () => `${this.pathname}contracts/deleted`
  contractsPreviewUrl = (id: string | null = null) => `${this.pathname}contracts/preview/${id || ':contractId'}`
  contractsAddRecipientsUrl = (id: string | null = null) =>
    `${this.pathname}contracts/add-recipients/${id || ':contractId'}`
  contractsRecipientsDiagramUrl = (id: string | null = null) =>
    `${this.pathname}contracts/recipients-diagram/${id || ':contractId'}`
  contractsPlacementUrl = (id: string | null = null) => `${this.pathname}contracts/placement/${id || ':contractId'}`
  contractsSignUrl = (id: string | null = null) => `${this.pathname}contracts/signing/${id || ':contractId'}`
  contractsOwnerViewUrl = (id: string | null = null) => `${this.pathname}contracts/owner/view/${id || ':contractId'}`
  contractsUnregisteredSignUrl = (id: string | null = null) =>
    `${this.pathname}unregistered/signing/${id || ':contractId'}`

  templatesUrl = () => `${this.pathname}templates`
  templatesAllUrl = () => `${this.pathname}templates/all`
  templatesStarredUrl = () => `${this.pathname}templates/starred`
  templatesDocumentsUrl = () => `${this.pathname}templates/documents`
  templatesProceduresUrl = () => `${this.pathname}templates/procedures`
  templatesDeletedUrl = () => `${this.pathname}templates/deleted`
  templatesPreviewUrl = (id: string | null = null) => `${this.pathname}templates/preview/${id || ':templateId'}`
  templatesViewUrl = (id: string | null = null) => `${this.pathname}templates/view/${id || ':templateId'}`
  templatesClmOnlyAddRecipientsUrl = () => `${this.pathname}templates/add-recipients`
  templatesAddRecipientsUrl = (id: string | null = null) =>
    `${this.pathname}templates/add-recipients/${id || ':templateId'}`
  templatesRecipientsDiagramUrl = (id: string | null = null) =>
    `${this.pathname}templates/recipients-diagram/${id || ':templateId'}`
  templatesPlacementUrl = (id: string | null = null) => `${this.pathname}templates/placement/${id || ':templateId'}`
  templatesFillRecipientsUrl = (id: string | null = null) =>
    `${this.pathname}templates/fill-recipients/${id || ':templateId'}`
  templatesFillFieldsUrl = (id: string | null = null) => `${this.pathname}templates/fill-fields/${id || ':templateId'}`

  preferencesUrl = () => `${this.pathname}preferences`
  preferencesNotificationsUrl = () => `${this.pathname}preferences/notifications`
  preferencesRegionalSettingsUrl = () => `${this.pathname}preferences/regional-settings`
  cloudStorageUrl = () => `${this.pathname}preferences/cloud-storage`

  faqUrl = () => `${this.pathname}faq`

  legalNoticeUrl = () => `${this.pathname}legal-notice`

  termsUrl = () => `${this.pathname}terms`
  privacyUrl = () => `${this.pathname}privacy-policy`

  paymentUrl = () => `${this.pathname}payment`
  paymentRegistrationCardUrl = () => `${this.pathname}api/payment/registration/card/callback`
  paymentPurchaseUrl = () => `${this.pathname}api/payment/purchase/callback`
}

export const location = new Location()
