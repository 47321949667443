import styled from 'styled-components'

import Typography from '../Typography'

import { RadioButtonProps } from './RadioButton.types'

export const StyledCheckedWrapper = styled.div``
export const HiddenRadioButton = styled.input.attrs({ type: 'radio' })`
  overflow: hidden;
  width: 1px;
  height: 1px;
  position: absolute;
  clip: rect(0 0 0 0);
  margin: -1px;
  padding: 0;
  white-space: nowrap;
  border: 0;
`
export const StyledChecked = styled.div`
  display: block;
  background: ${(props) => props.theme.palette.blue};
  width: 10px;
  height: 10px;
  border-radius: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`
export const StyledRadioButton = styled.div<{ checked?: boolean }>`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  border-width: 1px;
  border-style: solid;
  border-color: ${(props) => props.theme.palette.grey};
  border-radius: 20px;
  transition: all 100ms;
  position: relative;

  ${HiddenRadioButton}:checked ~ & {
    border-color: ${(props) => props.theme.palette.blue};
  }
  ${HiddenRadioButton}:disabled ~ & {
    border-color: ${(props) => props.theme.palette.grey};
    background-color: ${(props) => props.theme.palette.grey3};

    ${StyledChecked} {
      background: ${(props) => props.theme.palette.grey};
    }
  }
  ${StyledCheckedWrapper} {
    visibility: ${(props) => (props.checked ? 'visible' : 'hidden')};
  }
`
export const StyledLabel = styled(Typography)`
  margin-left: 8px;

  ${HiddenRadioButton}:disabled ~ & {
    color: ${(props) => props.theme.palette.grey};
  }
`
export const StyledWrapper = styled.label<Pick<RadioButtonProps, 'disabled' | 'renderLabel' | 'dotPosition'>>`
  display: inline-flex;
  margin-bottom: 16px;
  ${({ dotPosition }) => {
    if (dotPosition === 'center') {
      return 'align-items: center;'
    }

    if (dotPosition === 'top') {
      return 'align-items: flex-start;'
    }

    return ''
  }}
  ${({ disabled }) => (disabled ? '' : 'cursor: pointer;')}

  &:hover {
    ${HiddenRadioButton}:not([disabled]) ~ ${StyledRadioButton} {
      border-color: ${(props) => props.theme.palette.blue};
    }
  }
`
