import styled from 'styled-components'

import { HEADER_HEIGHT, HEADER_TABLET_HEIGHT } from '../../components/Main/FullScreenTemplate/FullScreenTemplate.styles'
import { MOBILE_ACTIONS_HIEGHT } from '../../ui/DocViewerMobileToolbar/DocViewerMobileToolbar.styles'
export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: calc(100vh - ${HEADER_HEIGHT});

  ${({ theme }) => `@media ${theme.devices.laptop} {
    height: calc(100vh - ${HEADER_TABLET_HEIGHT} - ${MOBILE_ACTIONS_HIEGHT});
   
  }`}
  ${({ theme }) => `@media ${theme.devices.mobile} {
    height: calc(100vh - ${HEADER_HEIGHT} - ${MOBILE_ACTIONS_HIEGHT});
  }`}
`
export const MessageContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 615px;
  max-width: 100%;
`
