import styled from 'styled-components'

import Typography from '../Typography'
import { TYPOGRAPHY_NAMES } from '../Typography/Typography.types'

import { ModalProps } from './Modal.types'

export const StyledWrapper = styled.div<{ offsetTop?: boolean }>`
  position: fixed;
  top: ${({ offsetTop }) => (offsetTop ? '50px' : 0)};
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
`
export const StyledOverlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  ${({ theme }) => `background-color: ${theme.palette.textColor};`}
  opacity: 0.6;
`
export const StyledModalWrapper = styled.div<Pick<ModalProps, 'fullSize' | 'fillWidth'>>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  ${({ theme }) => `background-color: ${theme.palette.white};`}
  border-radius: 8px;
  min-width: 400px;
  min-height: 200px;
  max-height: 100%;
  max-width: 100%;
  box-sizing: border-box;
  overflow: auto;
  display: flex;
  flex-direction: column;
  ${({ fillWidth }) => (fillWidth ? 'width: calc(100% - 32px);' : '')}
  ${({ theme, fullSize }) => `@media ${theme.devices.mobile} {
  min-width: 0;
  min-height: 0;
  width: ${fullSize ? '100%' : 'calc(100% - 32px)'};
  height: ${fullSize ? '100%' : 'auto'};
  ${fullSize ? `border-radius: 0;` : ''}
}`}
`
export const StyledHeader = styled.div<Pick<ModalProps, 'title' | 'fullSize' | 'headerSize'>>`
  box-sizing: border-box;
  padding: ${({ headerSize }) => (headerSize === 'small' ? '8px' : '19px 19px 8px')};
  ${({ title }) => (title ? 'min-height: 40px;' : 'height: 40px;')}
  display: flex;
  justify-content: flex-end;
  position: relative;
`
export const StyledClose = styled.div`
  cursor: pointer;
  position: relative;
`
export const StyledContent = styled.div<Pick<ModalProps, 'fullSize'>>`
  overflow: auto;
  padding: 0 40px 40px;
  max-height: 100%;
  max-width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  ${({ theme, fullSize }) => `@media ${theme.devices.mobile} {
  padding: ${fullSize ? '0 16px 16px' : '0 24px 40px'};
  height: ${fullSize ? '100%' : 'auto'};
}`}
`
export const StyledTitle = styled(Typography).attrs({
  name: TYPOGRAPHY_NAMES.H3,
})`
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
`
