import styled from 'styled-components'

import { ConfirmationStatus } from '../../../../types/confirmationActions'
import Button from '../../Button'

export const SubmitButton = styled(Button).attrs({ compact: true })<{ status: ConfirmationStatus | null }>`
  display: ${({ status }) => (!!status ? 'none' : 'initial')};
  width: 100%;
  margin-top: 30px;
`
export const Wrapper = styled.div`
  padding: 10px 0;
  width: 100%;
  height: 100%;
`

export const ThreadItem = styled.div`
  padding-bottom: 16px;
  margin-bottom: 16px;
  border-bottom: ${({ theme }) => `1px solid ${theme.palette.pattensBlue}`};
`
