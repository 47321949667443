import styled from 'styled-components'

import Typography from '../../../../ui/Typography'
import { TYPOGRAPHY_COMPONENTS, TYPOGRAPHY_NAMES } from '../../../../ui/Typography/Typography.types'

export const Field = styled.div`
  width: 50%;
  margin-bottom: 24px;

  &:nth-child(odd) {
    padding-right: 20px;
  }
  &:nth-child(even) {
    padding-left: 20px;
  }
`

export const Label = styled(Typography).attrs({
  name: TYPOGRAPHY_NAMES.body14,
  component: TYPOGRAPHY_COMPONENTS.div,
  isUppercase: true,
  color: 'grey',
})`
  margin-bottom: 8px;
`

export const Text = styled(Typography).attrs({ name: TYPOGRAPHY_NAMES.body14, component: TYPOGRAPHY_COMPONENTS.div })``
