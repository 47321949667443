import styled from 'styled-components'

export const Wrapper = styled.div`
  display: none;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  position: absolute;
  top: -5px;
  right: -35px;
  ${({ theme }) => `@media ${theme.devices.tablet} {
      display: block;
    }`}
`
