import { FetchFailureAction, FETCH_STATUSES } from '../../../types/fetch'
import { createReducer } from '../../createReducer'

import * as ACTIONS from './void.actions'
import * as TYPES from './void.types'

type Actions = FetchFailureAction

export const initialState: TYPES.VoidState = {
  fetchStatus: FETCH_STATUSES.IDLE,
  error: null,
}

export const voidContract = createReducer<TYPES.VoidState, Actions>(initialState, {
  [ACTIONS.fetchVoidContractTypes.request]: (state) => ({
    ...state,
    fetchStatus: FETCH_STATUSES.REQUEST,
  }),
  [ACTIONS.fetchVoidContractTypes.success]: (state) => ({
    ...state,
    fetchStatus: FETCH_STATUSES.SUCCESS,
  }),
  [ACTIONS.fetchVoidContractTypes.failure]: (state, action) => ({
    ...state,
    fetchStatus: FETCH_STATUSES.FAILURE,
    error: (action as FetchFailureAction).payload,
  }),
  [TYPES.VoidActions.CLEAN_VOID_CONTRACT]: () => ({ ...initialState }),
})
