import styled from 'styled-components'

import Box from '../../ui/Box'

export const StyledTitle = styled(Box).attrs({
  mb: 2,
})`
  ${({ theme }) => `@media ${theme.devices.mobile} {
    display: none;
  }`}
`
