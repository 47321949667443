import React, { useCallback, useEffect, useRef, useState } from 'react'
import ReactSignaturePad from 'react-signature-pad-wrapper'
import { useTheme } from 'styled-components'

import { StyledThemeScheme } from '../../../../../../themes/light'
import Box from '../../../../../ui/Box'
import { SignaturePadActions } from '../SignaturePadActions/SignaturePadActions'

import { useOptions } from './hooks/useOptions'
import { StyledSignaturePadWrapper } from './SignaturePad.styles'
import { PadRef, SignaturePadProps } from './SignaturePad.types'
import { addImgToCanvas } from './utils/addImgToCanvas'
import { getTrimmedCanvas } from './utils/trimCanvas'

const BORDERS_COUNT = 2
const MOBILE_PLACEHOLDER_FINGER_OFFSET = 20
const MOBILE_PLACEHOLDER_TEXT_OFFSET = MOBILE_PLACEHOLDER_FINGER_OFFSET + 12
const IMAGE_TYPE = 'image/png'

export const SignaturePad: React.FC<SignaturePadProps> = ({
  width,
  height,
  title,
  placeholder,
  setImg,
  setTrimmedImg,
  imgUrl,
  isMobile,
}) => {
  const padRef = useRef<PadRef>()
  const wrapperRef = useRef<HTMLDivElement>(null)
  const theme = useTheme() as StyledThemeScheme
  const [dirty, setDirty] = useState(Boolean(imgUrl))

  const options = useOptions()

  const addPlaceholder = useCallback(() => {
    const canvas = padRef.current?._canvas

    if (canvas && wrapperRef.current) {
      const ctx = canvas.getContext('2d') as CanvasRenderingContext2D
      const placeholderStyles = theme.textStyles.body14
      const halfCanvasWidth = wrapperRef.current.offsetWidth / 2
      const halfCanvasHeight = wrapperRef.current.offsetHeight / 2
      const offsetText = isMobile ? MOBILE_PLACEHOLDER_TEXT_OFFSET : 0

      ctx.textAlign = 'center'
      ctx.font = `${placeholderStyles['font-size']} ${placeholderStyles['font-family']}`
      ctx.fillStyle = theme.palette.grey
      ctx.fillText(placeholder, halfCanvasWidth, halfCanvasHeight + offsetText)
      isMobile && addImgToCanvas(ctx, { halfCanvasWidth, halfCanvasHeight, offset: MOBILE_PLACEHOLDER_FINGER_OFFSET })
    }
  }, [placeholder, theme, isMobile])
  const removePlaceholder = useCallback(() => {
    const canvas = padRef.current?._canvas

    if (padRef.current?.isEmpty() && canvas) {
      const ctx = canvas.getContext('2d') as CanvasRenderingContext2D
      const clean = () => {
        ctx.clearRect(0, 0, canvas.width, canvas.height)
      }
      const revertPenColor = () => {
        ctx.fillStyle = options.backgroundColor
        ctx.fillRect(0, 0, canvas.width, canvas.height)
        ctx.fillStyle = options.penColor
      }

      clean()
      revertPenColor()
    }
  }, [options.backgroundColor, options.penColor])

  const onBegin = () => {
    removePlaceholder()
    setDirty(true)
  }

  const onClear = useCallback(() => {
    padRef.current?.clear()
    addPlaceholder()
    setDirty(false)
    setImg('')
    setTrimmedImg('')
  }, [addPlaceholder])
  const onApply = useCallback(() => {
    const canvas = padRef.current?._canvas
    if (!canvas) {
      return
    }
    const url = canvas.toDataURL(IMAGE_TYPE)
    const trimmedUrl = getTrimmedCanvas(canvas).toDataURL(IMAGE_TYPE)
    setImg(url)
    setTrimmedImg(trimmedUrl)
    padRef.current?.off()
  }, [])
  const onChange = useCallback(() => {
    setImg('')
    setTrimmedImg('')
    padRef.current?.on()
  }, [])

  useEffect(() => {
    if (imgUrl) {
      padRef.current?.fromDataURL(imgUrl)
      padRef.current?.off()
    } else {
      addPlaceholder()
    }
  }, [])

  return (
    <>
      {title && <Box mb={1}>{title}</Box>}
      <StyledSignaturePadWrapper width={width || '100%'} height={height || '100%'} ref={wrapperRef}>
        <ReactSignaturePad
          ref={padRef}
          options={{
            ...options,
            onBegin,
          }}
          width={width && width - BORDERS_COUNT}
          height={height && height - BORDERS_COUNT}
          debounceInterval={0}
        />
        <SignaturePadActions
          onClear={onClear}
          dirty={dirty}
          onApply={onApply}
          applied={Boolean(imgUrl)}
          onChange={onChange}
        />
      </StyledSignaturePadWrapper>
    </>
  )
}
