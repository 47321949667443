import React from 'react'
import styled, { css } from 'styled-components'

import { TriangleRightIcon } from '../../assets/icons/TriangleRightIcon'
import Link from '../Link'

import { MenuLinkProps } from './MenuLink.types'

const disabledStyles = css`
  color: ${({ theme }) => theme.palette.grey};
  pointer-events: none;
`
const activeStyles = css`
  &&& {
    color: ${({ theme }) => theme.palette.white};
    background-color: ${({ theme }) => theme.palette.blue};

    &:focus {
      color: ${({ theme }) => theme.palette.white};
    }
  }
`
const compactStyles = css`
  padding-top: 6px;
  padding-bottom: 6px;
  font-size: ${({ theme }) => theme.textStyles.body14};
`

const StyledMenuLink = styled(Link)<MenuLinkProps>`
  display: flex;
  flex-direction: ${({ direction }) => direction};
  align-items: center;
  padding-top: 13px;
  padding-bottom: 13px;
  padding-right: ${({ smallPadding }) => (smallPadding ? '17px' : '32px')};
  padding-left: ${({ smallPadding }) => (smallPadding ? '17px' : '32px')};
  color: ${({ theme }) => theme.palette.textColor};
  font-size: 16px;
  transition: padding 300ms 200ms, background-color 200ms;

  ${({ active }) => active && activeStyles}
  ${({ disabled }) => disabled && disabledStyles}
  ${({ compact }) => compact && compactStyles}

  &:hover {
    background-color: ${({ theme }) => theme.palette.blueExtraLight};
    text-decoration: none;
  }
`
const StyledIconWrapper = styled.span<Omit<MenuLinkProps, 'to'>>`
  display: inline-flex;
  margin-right: ${({ direction }) => (direction === 'row' ? '16px' : '0px')};
  margin-bottom: ${({ direction }) => (direction === 'row' ? '0px' : '3px')};
  color: ${({ active, theme }) => (active ? theme.palette.white : theme.palette.grey)};
  ${({ disabled }) => disabled && 'opacity: 0.5'}
`
const StyledTriangleRightIcon = styled(TriangleRightIcon)`
  margin-left: auto;
`

export const MenuLink: React.FC<MenuLinkProps> = ({
  children,
  Icon,
  active,
  disabled = false,
  smallPadding = false,
  showTriangle = false,
  direction = 'row',
  ...props
}) => (
  <StyledMenuLink direction={direction} disabled={disabled} smallPadding={smallPadding} active={active} {...props}>
    {Icon && (
      <StyledIconWrapper direction={direction} disabled={disabled} active={active}>
        <Icon color="inherit" />
      </StyledIconWrapper>
    )}
    {children}
    {showTriangle && <StyledTriangleRightIcon color="grey" size="small" />}
  </StyledMenuLink>
)
