import React from 'react'
import styled, { css } from 'styled-components'

import { ResizeControls } from '../../../types/editableElem'
import { PointerModes } from '../DocViewer/DocViewer.types'

import { ResizerProps } from './Resizer.types'

const topLeft = css`
  top: -10px;
  left: -10px;
  cursor: nwse-resize;
`
const topRight = css`
  top: -10px;
  right: -10px;
  cursor: nesw-resize;
`
const bottomRight = css`
  right: -10px;
  bottom: -10px;
  cursor: nwse-resize;
`
const bottomLeft = css`
  bottom: -10px;
  left: -10px;
  cursor: nesw-resize;
`

export const StyledResizeControl = styled.div<{
  isEditable?: boolean
  direction: ResizeControls
}>`
  position: absolute;
  padding: 8px;
  z-index: 105;
  pointer-events: ${({ isEditable }) => (isEditable ? 'all' : 'none')};

  &::after {
    display: block;
    content: '';
    width: 6px;
    height: 6px;
    background-color: ${({ theme }) => theme.palette.white};
    border: ${({ theme }) => `1px solid ${theme.palette.blueOcean}`};
  }

  ${({ direction }) => {
    switch (direction) {
      case ResizeControls.TOP_LEFT:
        return topLeft
      case ResizeControls.TOP_RIGHT:
        return topRight
      case ResizeControls.BOTTOM_RIGHT:
        return bottomRight
      default:
        return bottomLeft
    }
  }}
`

export const Resizer: React.FC<ResizerProps> = ({ isEditable }) => (
  <>
    <StyledResizeControl
      data-testid="resizer-rect"
      data-type={isEditable && PointerModes.RESIZE}
      data-dir={ResizeControls.TOP_LEFT}
      direction={ResizeControls.TOP_LEFT}
      isEditable={isEditable}
    />
    <StyledResizeControl
      data-testid="resizer-rect"
      data-type={isEditable && PointerModes.RESIZE}
      data-dir={ResizeControls.TOP_RIGHT}
      direction={ResizeControls.TOP_RIGHT}
      isEditable={isEditable}
    />
    <StyledResizeControl
      data-testid="resizer-rect"
      data-type={isEditable && PointerModes.RESIZE}
      data-dir={ResizeControls.BOTTOM_RIGHT}
      direction={ResizeControls.BOTTOM_RIGHT}
      isEditable={isEditable}
    />
    <StyledResizeControl
      data-testid="resizer-rect"
      data-type={isEditable && PointerModes.RESIZE}
      data-dir={ResizeControls.BOTTOM_LEFT}
      direction={ResizeControls.BOTTOM_LEFT}
      isEditable={isEditable}
    />
  </>
)
