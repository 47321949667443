import { LoginRequestBody, LoginResponse } from '../../types/auth'
import { FetchFailurePayload, FetchFailureAction } from '../../types/fetch'
import { User } from '../../types/user'
import { createFetchActionTypes } from '../../utils/createFetchActionTypes'

import * as TYPES from './auth.types'

export const fetchLoginTypes = createFetchActionTypes(TYPES.AuthActions.FETCH_LOGIN)
export const fetchResendEmailTypes = createFetchActionTypes(TYPES.AuthActions.FETCH_RESEND_EMAIL)

export const setUserInfo = (user: User) => ({
  type: TYPES.AuthActions.SET_USER_INFO,
  payload: { user },
})
export const fetchLogin = (body: LoginRequestBody, isMobile?: boolean): TYPES.FetchLoginAction => ({
  type: fetchLoginTypes.request,
  payload: { body, isMobile },
})
export const fetchLoginSuccess = (body: LoginResponse): TYPES.FetchLoginSuccessAction => ({
  type: fetchLoginTypes.success,
  payload: { body },
})
export const fetchLoginFailure = (payload: FetchFailurePayload): FetchFailureAction => ({
  type: fetchLoginTypes.failure,
  payload,
})
export const cleanLoginFetchStatus = () => ({
  type: TYPES.AuthActions.FETCH_LOGIN_CLEAN_FETCH_STATUS,
})

export const fetchResendEmail = (email: string): TYPES.FetchResendEmailAction => ({
  type: fetchResendEmailTypes.request,
  payload: { email },
})
export const fetchResendEmailSuccess = (): TYPES.FetchResendEmailSuccessAction => ({
  type: fetchResendEmailTypes.success,
})
export const fetchResendEmailFailure = (payload: FetchFailurePayload): FetchFailureAction => ({
  type: fetchResendEmailTypes.failure,
  payload,
})

export const cleanAuth = () => ({
  type: TYPES.AuthActions.CLEAN_AUTH,
})

export const cleanLoginError = () => ({
  type: TYPES.AuthActions.CLEAN_LOGIN_ERROR,
})
