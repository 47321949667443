import styled, { css } from 'styled-components'

import { DropdownItemProps } from './DropdownItem.types'

const disabledStyles = css`
  color: ${({ theme }) => theme.palette.grey};
  pointer-events: none;
`

export const StyledItem = styled.div<Omit<DropdownItemProps, 'label'>>`
  display: flex;
  align-items: center;
  padding: 8px 16px;
  cursor: pointer;
  ${({ disabled }) => (disabled ? disabledStyles : '')}

  &:hover {
    background-color: ${({ theme }) => theme.palette.blueExtraLight};
  }
`
