import { Action } from 'redux'

import { FetchFailurePayload, FETCH_STATUSES } from '../../../types/fetch'
import { RecipientsById } from '../../../types/recipients'
import { EditableElem } from '../../../types/editableElem'
import { PlacementBody, PlacementById, PlacementByPages, PlacementSorted } from '../../../types/placement'

export interface PlacementState {
  byId: PlacementById
  byPages: PlacementByPages
  sorted: PlacementSorted
  shouldRegenerate: boolean
  fetch: {
    status: FETCH_STATUSES
    error: FetchFailurePayload | null
  }
  update: {
    status: FETCH_STATUSES
    error: FetchFailurePayload | null
  }
  generate: {
    status: FETCH_STATUSES
    error: FetchFailurePayload | null
  }
}

export enum PlacementActions {
  'TEMPLATES_SET_PLACEMENT' = 'TEMPLATES_SET_PLACEMENT',
  'TEMPLATES_CLEAR_PLACEMENT' = 'TEMPLATES_CLEAR_PLACEMENT',
  'TEMPLATES_CLEAR_PLACEMENT_STATUS' = 'TEMPLATES_CLEAR_PLACEMENT_STATUS',
  'TEMPLATES_FETCH_PLACEMENT' = 'TEMPLATES_FETCH_PLACEMENT',
  'TEMPLATES_FETCH_UPDATE_PLACEMENT' = 'TEMPLATES_FETCH_UPDATE_PLACEMENT',
  'TEMPLATES_FETCH_GENERATE_PLACEMENT' = 'TEMPLATES_FETCH_GENERATE_PLACEMENT',
  'TEMPLATES_SET_SHOULD_REGENERATE' = 'TEMPLATES_SET_SHOULD_REGENERATE',
  'TEMPLATES_TOGGLE_SIGNATURE_NAMES_VISIBLE' = 'TEMPLATES_TOGGLE_SIGNATURE_NAMES_VISIBLE',
}

export interface SetPlacementAction extends Action {
  payload: { place: EditableElem }
}
export type ClearPlacementAction = Action
export type ClearPlacementStatusAction = Action

export interface FetchPlacementAction extends Action {
  payload: { templateId: string; recipientsById: RecipientsById }
}
export interface FetchPlacementSuccessAction extends Action {
  payload: { byId: PlacementById; byPages: PlacementByPages; sorted: PlacementSorted }
}

export interface FetchUpdatePlacementAction extends Action {
  payload: { byId: PlacementById; sorted: PlacementSorted }
}
export interface FetchUpdatePlacementSuccessAction extends Action {
  payload: PlacementBody
}

export interface FetchGeneratePlacementAction extends Action {
  payload: { templateId: string }
}
export type FetchGeneratePlacementSuccessAction = Action

export interface SetShouldRegenerateAction extends Action {
  payload: { shouldRegenerate: boolean }
}
