import { combineEpics, Epic, ofType } from 'redux-observable'
import { catchError, map, mergeMap, takeUntil } from 'rxjs/operators'

import { templateFolderService, templateService } from '../../../api'
import { catchFetchError } from '../../../utils/catchFetchError'

import * as ACTIONS from './rename.actions'

export const fetchRenameTemplate: Epic = (action$) =>
  action$.pipe(
    ofType(ACTIONS.fetchRenameTemplateTypes.request),
    mergeMap(({ payload }) => {
      const update = payload.isFolder ? templateFolderService.fetchUpdateFolder : templateService.fetchUpdateTemplate

      return update(payload.id, { name: payload.name }).pipe(
        map(() => ACTIONS.fetchRenameTemplateSuccess(payload.id, payload.name)),
        catchError(catchFetchError(ACTIONS.fetchRenameTemplateFailure)),
        takeUntil(
          action$.pipe(ofType(ACTIONS.fetchRenameTemplateTypes.success, ACTIONS.fetchRenameTemplateTypes.failure))
        )
      )
    })
  )

export const renameEpics = combineEpics(fetchRenameTemplate)
