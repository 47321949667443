import { Action } from 'redux'

import { FetchError, FETCH_STATUSES } from '../../types/fetch'
import { Option } from '../../types/options'

export type Industries = Option[]

export interface IndustriesState {
  fetchStatus: FETCH_STATUSES
  data: Industries
  error?: FetchError
}

export enum IndustriesActions {
  'FETCH_INDUSTRIES' = 'FETCH_INDUSTRIES',
  'FETCH_INDUSTRIES_SUCCESS' = 'FETCH_INDUSTRIES_SUCCESS',
  'FETCH_INDUSTRIES_FAILURE' = 'FETCH_INDUSTRIES_FAILURE',
}

export type FetchIndustriesAction = Action

export interface FetchIndustriesSuccessAction extends Action {
  payload: Industries
}

export interface FetchIndustriesFailureAction extends Action {
  payload: FetchError
}
