import { combineEpics, Epic, ofType } from 'redux-observable'
import { catchError, map, mergeMap, takeUntil } from 'rxjs/operators'

import { catchFetchError } from '../../../utils/catchFetchError'
import { contractsService } from '../../../api'

import * as ACTIONS from './void.actions'

export const fetchVoidContract: Epic = (action$) =>
  action$.pipe(
    ofType(ACTIONS.fetchVoidContractTypes.request),
    mergeMap(({ payload }) =>
      contractsService.fetchVoidContract(payload.contractId).pipe(
        map(() => ACTIONS.fetchVoidContractSuccess(payload.contractId)),
        catchError(catchFetchError(ACTIONS.fetchVoidContractFailure)),
        takeUntil(action$.pipe(ofType(ACTIONS.fetchVoidContractTypes.success, ACTIONS.fetchVoidContractTypes.failure)))
      )
    )
  )

export const voidEpics = combineEpics(fetchVoidContract)
