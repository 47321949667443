import { Ajax } from '../ajax'
import { ContactUsForm } from '../../types/contactUs'

class SupportService extends Ajax {
  pathname = 'support'

  fetchContactUs = (body: ContactUsForm) => this.post(`${this.pathname}/contactus`, body)
}

export const supportService = new SupportService()
