import { useCallback } from 'react'
import { useDispatch } from 'react-redux'

import { openModal } from '../../../../store/modals/modals.actions'
import { PlacementById } from '../../../../types/placement'
import { usePlacementById as useCommonPlacementById } from '../../../hooks/contractSign/usePlacementById'
import { UNREGISTERED_CONTRACT_SIGN_MODALS } from '../../../constants/unregisteredContractSign'
import { SignatureMode } from '../../../components/Modals/CreateSignatureModal/CreateSignatureModal.types'

export const usePlacementById = (): PlacementById => {
  const dispatch = useDispatch()

  const openCreateSignature = useCallback(
    (isChanged: boolean) => (placeId: string, isInitials: boolean) => {
      dispatch(
        openModal(UNREGISTERED_CONTRACT_SIGN_MODALS.UNREGISTERED_CREATE_SIGNATURE, {
          mode: isInitials ? SignatureMode.initials : SignatureMode.signature,
          placeId,
          isChanged,
        })
      )
    },
    []
  )
  const handleChangeSignature = useCallback(openCreateSignature(true), [])
  const handleEmptySignature = useCallback(openCreateSignature(false), [])

  return useCommonPlacementById({ handleChangeSignature, handleEmptySignature })
}
