import { FetchFailureAction, FetchFailurePayload } from '../../../types/fetch'
import { createFetchActionTypes } from '../../../utils/createFetchActionTypes'

import * as TYPES from './noneVerification.types'

export const fetchNoneVerificationTypes = createFetchActionTypes(TYPES.NoneVerificationActions.FETCH_NONE_VERIFICATION)

export const fetchNoneVerification = (contractId: string, onSuccess?: () => void): TYPES.FetchNoneVerification => ({
  type: fetchNoneVerificationTypes.request,
  payload: { contractId, onSuccess },
})

export const fetchNoneVerificationSuccess = (): TYPES.FetchNoneVerificationSuccess => ({
  type: fetchNoneVerificationTypes.success,
})

export const fetchNoneVerificationFailure = (payload: FetchFailurePayload): FetchFailureAction => ({
  type: fetchNoneVerificationTypes.failure,
  payload,
})

export const cleanNoneVerification = () => ({
  type: TYPES.NoneVerificationActions.CLEAN_NONE_VERIFICATION,
})
