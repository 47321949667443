import { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router'

import translations from '../../../../translations/keys'
import { TemplateRouteParams } from '../../../types/template'
import { TEMPLATE_MODALS } from '../../../constants/template'
import { location } from '../../../../utils/location'
import { openModal } from '../../../../store/modals/modals.actions'
import { recipientsIsDocumentExistsSelector } from '../../../../store/templates/recipients/recipients.selectors'
import { EyeIcon } from '../../../ui/InputIcons/EyeIcon'
import { DiscardIcon } from '../../../assets/icons'

export const useActions = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { templateId } = useParams<TemplateRouteParams>()
  const isDocumentExists = useSelector(recipientsIsDocumentExistsSelector)

  const actions = useMemo(() => {
    const view = {
      buttonIcon: 'eye',
      label: translations.VIEW_DOCUMENT,
      onClick: () => {
        history.push(location.templatesPreviewUrl(templateId))
      },
      MobileIcon: EyeIcon,
    }
    const discard = {
      buttonIcon: 'discard',
      label: translations.DISCARD,
      onClick: () => {
        dispatch(openModal(TEMPLATE_MODALS.DISCARD_CONFIRMATION))
      },
      MobileIcon: DiscardIcon,
    }

    if (isDocumentExists) {
      return [view, discard]
    }

    return [discard]
  }, [templateId, isDocumentExists])

  return actions
}
