import styled from 'styled-components'

import Box from '../../../../ui/Box'

export const StyledHeader = styled(Box).attrs({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  pt: 8,
  pl: 4,
  pb: 7.5,
  pr: 4,
  plMobile: 2,
  prMobile: 2,
})`
  flex-direction: column;
  background: ${({ theme }) =>
    `linear-gradient(127.83deg, #415f85 -0.97%, #0c2557 92.99%), ${theme.palette.blueDark};`};
`
export const StyledSearchWrapper = styled.div`
  width: 100%;
  max-width: 384px;
`
