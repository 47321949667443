export enum ConfirmationStatus {
  ACCEPTED = 'accepted',
  REJECTED = 'rejected',
}

export interface IConfirmation {
  email: string
  name: string
  recipientId: string
  status: ConfirmationStatus
  timestamp: string
}

interface IStrikeOrCorrectionOwner {
  email: string
  name: string
  recipientId: string | null
}

export interface ConfirmationElem {
  canConfirm?: boolean
  confirmation: IConfirmation | null
  owner: IStrikeOrCorrectionOwner | null
  strikeOrCorrectionOwner: string
  status: ConfirmationStatus | null
  acceptOrRejectTimestamp: string | null
}
