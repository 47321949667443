import React from 'react'
import { useHistory } from 'react-router'

import translations from '../../../translations/keys'
import { location } from '../../../utils/location'
import Button from '../../ui/Button'
import { HEADER_TABLET_HEIGHT } from '../../components/Main/FullScreenTemplate/FullScreenTemplate.styles'
import Typography from '../../ui/Typography'
import { TYPOGRAPHY_NAMES } from '../../ui/Typography/Typography.types'
import Box from '../../ui/Box'

import { StyledError404Svg } from './Error404.styles'

export const Error404 = () => {
  const history = useHistory()

  const handleRedirectHomePage = () => {
    history.push(location.dashboardUrl())
  }

  return (
    <Box display="flex" justifyContent="center" alignItems="center" height={`calc(100% - ${HEADER_TABLET_HEIGHT})`}>
      <Box display="flex" flexDirection="column" alignItems="center">
        <StyledError404Svg />
        <Box mt={4} mb={4} textAlign="center" mobileWidth="300px">
          <Typography name={TYPOGRAPHY_NAMES.errorTitle32} mobileName={TYPOGRAPHY_NAMES.errorTitle28}>
            {translations.PAGE_NOT_FOUND}
          </Typography>
        </Box>
        <Box width={208}>
          <Button fullWidth onClick={handleRedirectHomePage}>
            {translations.HOME}
          </Button>
        </Box>
      </Box>
    </Box>
  )
}
