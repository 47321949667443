import React from 'react'
import { useTheme } from 'styled-components'
import { useTranslation } from 'react-i18next'

import translations from '../../../../../translations/keys'
import { BUTTON_VARIANTS } from '../../../../ui/Button/Button.types'
import Button from '../../../../ui/Button'
import { getAgoFormat } from '../../../../../utils/date/date'
import { Spinner } from '../../../../ui/Spinner/Spinner'
import Scrollbar from '../../../../ui/Scrollbar'
import { StyledThemeScheme } from '../../../../../themes/light'
import useMediaQuery from '../../../../hooks/useMediaQuery'

import * as Styled from './List.styles'
import { ListProps } from './List.types'

export const List: React.FC<ListProps> = React.memo(
  ({ onItemClick, onMore, items, loading, hasMore, title, autoHeight }) => {
    const theme = useTheme() as StyledThemeScheme
    const tabletScreen = useMediaQuery(theme.devices.tablet)
    const { t } = useTranslation()

    const renderDate = (date: string) => <Styled.DateField>{getAgoFormat(new Date(date))}</Styled.DateField>

    const renderItems = () => (
      <div>
        {items.map((item) => {
          const onClick = () => {
            onItemClick(item)
          }

          return (
            <Styled.Item key={item.id} data-testid="notifications-item" onClick={onClick} clickable={!!item.link}>
              {!item.read && <Styled.ReadStatus />}
              <Styled.Text>{item.message}</Styled.Text>
              {renderDate(item.createAt)}
            </Styled.Item>
          )
        })}
      </div>
    )

    const renderFooter = () => {
      if (!hasMore) {
        return null
      }

      return (
        <Styled.Footer>
          {loading ? (
            <Spinner show />
          ) : (
            <Button data-testid="notifications-more" onClick={onMore} variant={BUTTON_VARIANTS.INACTIVE}>
              {t(translations.MORE)}
            </Button>
          )}
        </Styled.Footer>
      )
    }

    return (
      <Scrollbar maxHeight={tabletScreen ? 397 : 376} autoHeight={autoHeight}>
        <Styled.Notifications data-testid="notifications-items">
          {title && (
            <Styled.Header>
              <Styled.Title data-testid="notifications-title">{title}</Styled.Title>
            </Styled.Header>
          )}
          {renderItems()}
          {renderFooter()}
        </Styled.Notifications>
      </Scrollbar>
    )
  }
)
