import { RootState } from '../rootReducer.types'
import { FETCH_STATUSES } from '../../types/fetch'

export const changePasswordSelector = (state: RootState) => state.changePassword

export const changePasswordSuccessSelector = (state: RootState) =>
  state.changePassword.fetchStatus === FETCH_STATUSES.SUCCESS

export const changePasswordErrorSelector = (state: RootState) => state.changePassword.error

export const changePasswordIsFailureSelector = (state: RootState) =>
  state.changePassword.fetchStatus === FETCH_STATUSES.FAILURE
