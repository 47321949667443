import { CONTRACT_STATUS } from './contracts'

export enum ContractsFiltersFields {
  statuses = 'statuses',
  createDateFrom = 'createDateFrom',
  createDateTo = 'createDateTo',
}

export interface ContractsFiltersForm {
  statuses: CONTRACT_STATUS[]
  createDateFrom: string | null
  createDateTo: string | null
}
