import React, { useEffect, useMemo, useState } from 'react'

import translations from '../../../../../../translations/keys'
import { useMediaMode } from '../../../../../hooks/useMediaMode'
import Modal from '../../../../Modal'
import LockPortraitModal from '../LockPortraitModal'
import { useForceUpdate } from '../../hooks/useForceUpdate'
import { SignatureMode } from '../../CreateSignatureModal.types'

import Body from './components/Body'
import { MOBILE_CREATE_SIGNATURE_STEPS } from './ModalMobile.constants'
import { ModalMobileProps } from './ModalMobile.types'

const DRAWING_STEPS = [MOBILE_CREATE_SIGNATURE_STEPS.SIGNATURE, MOBILE_CREATE_SIGNATURE_STEPS.INITIALS]

export const ModalMobile: React.FC<ModalMobileProps> = ({ onClose, onCreateSignature, mode }) => {
  const isPortrait = useMediaMode('portrait')
  const initialStep = useMemo(
    () =>
      mode === SignatureMode.initials
        ? MOBILE_CREATE_SIGNATURE_STEPS.INITIALS
        : MOBILE_CREATE_SIGNATURE_STEPS.SIGNATURE,
    [mode]
  )

  const [step, setStep] = useState(initialStep)
  const forceUpdateKey = useForceUpdate()

  const onChangeStep = (nextStep: MOBILE_CREATE_SIGNATURE_STEPS) => () => {
    setStep(nextStep)
  }
  const getTitle = () => {
    if (step === MOBILE_CREATE_SIGNATURE_STEPS.PREVIEW) {
      return translations.PREVIEW
    }

    if (isPortrait) {
      return translations.ADD_SIGNATURE
    }

    return ''
  }

  useEffect(() => {
    setStep(initialStep)
  }, [isPortrait, initialStep])

  return (
    <Modal
      onClose={onClose}
      title={getTitle()}
      fullSize
      headerSize={!isPortrait && DRAWING_STEPS.includes(step) ? 'small' : undefined}
    >
      {isPortrait ? (
        <LockPortraitModal onClose={onClose} />
      ) : (
        <Body
          onCreateSignature={onCreateSignature}
          onChangeStep={onChangeStep}
          step={step}
          key={forceUpdateKey}
          mode={mode}
        />
      )}
    </Modal>
  )
}
