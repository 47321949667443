import { Action } from 'redux'

import { FetchFailurePayload, FETCH_STATUSES } from '../../../types/fetch'
import { UploadDocumentBody, UploadDocumentBodyNative } from '../../../types/uploadDocument'

export interface UploadState {
  id: string | null
  fetchStatus: FETCH_STATUSES
  error: FetchFailurePayload | null
}

export enum UploadActions {
  'FETCH_UPLOAD_TEMPLATE' = 'FETCH_UPLOAD_TEMPLATE',
  'CLEAN_UPLOAD_TEMPLATE' = 'CLEAN_UPLOAD_TEMPLATE',
}

export interface FetchUploadTemplate extends Action {
  payload: { body: UploadDocumentBody | UploadDocumentBodyNative }
}

export interface FetchUploadTemplateSuccess extends Action {
  payload: { id: string }
}
