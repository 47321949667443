import React from 'react'
import { useTheme } from 'styled-components'
import { useTranslation } from 'react-i18next'

import translations from '../../../../../translations/keys'
import { ContractSvg } from '../../../../assets/ContractSvg'
import Modal from '../../../Modal'
import Typography from '../../../../ui/Typography'
import { TYPOGRAPHY_NAMES } from '../../../../ui/Typography/Typography.types'
import { UploadFile } from '../../../../ui/UploadFile/UploadFile'
import Box from '../../../../ui/Box'
import { StyledThemeScheme } from '../../../../../themes/light'
import useMediaQuery from '../../../../hooks/useMediaQuery'
import { removeExtension } from '../../../../../utils/file/removeExtension'

import { UploadDocumentModalProps } from './UploadDocumentModal.types'
import { StyledWrapper } from './UploadDocumentModal.styles'

export const UploadDocumentModal: React.FC<UploadDocumentModalProps> = ({ onClose, onSuccess, title }) => {
  const theme = useTheme() as StyledThemeScheme
  const mobileScreen = useMediaQuery(theme.devices.mobile)
  const { t } = useTranslation()
  const validationInfo = mobileScreen ? (
    <p>
      {t(translations.UPLOAD_CONTACT_FORMATS)} {t(translations.UPLOAD_CONTACT_SIZE)}
    </p>
  ) : (
    <div>
      <p>{t(translations.UPLOAD_CONTACT_FORMATS)}</p>
      <p>{t(translations.UPLOAD_CONTACT_SIZE)}</p>
    </div>
  )
  const handleSuccess = (file: File) => {
    const fileName = removeExtension(file)

    onClose()
    onSuccess(fileName, file)
  }

  return (
    <Modal onClose={onClose}>
      <StyledWrapper>
        <ContractSvg />
        <Box mt={5} mb={5} textAlign="center">
          <Typography name={TYPOGRAPHY_NAMES.H1}>{title}</Typography>
        </Box>
        <UploadFile validationInfo={validationInfo} onSuccess={handleSuccess} />
      </StyledWrapper>
    </Modal>
  )
}
