import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import * as LIST_ACTIONS from '../../store/templates/list/list.actions'
import * as LIST_SELECTORS from '../../store/templates/list/list.selectors'
import { TEMPLATES_CATEGORIES, TEMPLATE_TYPES } from '../../types/templates'
import { useDispatchUnmount } from '../useDispatchUnmount'

export const useTemplatesListFetch = (category?: TEMPLATES_CATEGORIES) => {
  const dispatch = useDispatch()
  const sorting = useSelector(LIST_SELECTORS.templatesListSortingSelector)
  const offset = useSelector(LIST_SELECTORS.templatesListOffsetSelector)
  const limit = useSelector(LIST_SELECTORS.templatesListLimitSelector)
  const searchText = useSelector(LIST_SELECTORS.templatesListSearchTextSelector)
  const forceUpdate = useSelector(LIST_SELECTORS.templatesListForceUpdateSelector)
  const folderId = useSelector(LIST_SELECTORS.templatesListFolderId)

  useEffect(() => {
    const getFolderId = () => {
      if (searchText || category) {
        return ''
      }

      if (folderId) {
        return folderId
      }

      return 'null'
    }
    const getType = () => {
      if (category === TEMPLATES_CATEGORIES.WITH_DOCUMENTS) {
        return [TEMPLATE_TYPES.DOCUMENT, TEMPLATE_TYPES.DOCUMENT_CLM]
      }

      if (category === TEMPLATES_CATEGORIES.PROCEDURES) {
        return TEMPLATE_TYPES.CLM
      }

      return ''
    }

    dispatch(
      LIST_ACTIONS.fetchTemplatesListData({
        orderByField: sorting.field,
        order: sorting.direction,
        offset,
        limit,
        name: searchText,
        folderId: getFolderId(),
        type: getType(),
        favorite: category === TEMPLATES_CATEGORIES.STARRED ? 'true' : '',
      })
    )
  }, [sorting, searchText, offset, limit, forceUpdate, folderId, category])

  useDispatchUnmount(LIST_ACTIONS.cleanTemplates)
}
