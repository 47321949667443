import { DropzoneOptions } from 'react-dropzone'

import translations from '../../../translations/keys'

const MAX_SIZE_MEGABYTES = 100
const MAX_SIZE_KILOBYTES = MAX_SIZE_MEGABYTES * 1024
const MAX_SIZE_BYTES = MAX_SIZE_KILOBYTES * 1024

export enum ACCEPT_FORMATS_MIMES {
  doc = 'application/msword',
  docxDotDotx = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  docm = 'application/vnd.ms-word.document.macroenabled.12',
  html = 'text/html',
  mht = 'message/rfc822',
  mhtml1 = 'application/x-mimearchive',
  mhtml2 = 'multipart/related',
  odt = 'application/vnd.oasis.opendocument.text',
  rtf = 'application/rtf',
  txt = 'text/plain',
  xml1 = 'application/xml',
  xml2 = 'text/xml',
  xps = 'application/oxps',
  pdf = 'application/pdf',
  jpg = 'image/jpeg',
  png = 'image/png',
}

export const options: DropzoneOptions = {
  multiple: false,
  maxSize: MAX_SIZE_BYTES,
  accept: [
    ACCEPT_FORMATS_MIMES.doc,
    ACCEPT_FORMATS_MIMES.docxDotDotx,
    ACCEPT_FORMATS_MIMES.docm,
    ACCEPT_FORMATS_MIMES.html,
    ACCEPT_FORMATS_MIMES.mht,
    ACCEPT_FORMATS_MIMES.mhtml1,
    ACCEPT_FORMATS_MIMES.mhtml2,
    ACCEPT_FORMATS_MIMES.odt,
    ACCEPT_FORMATS_MIMES.rtf,
    ACCEPT_FORMATS_MIMES.txt,
    ACCEPT_FORMATS_MIMES.xml1,
    ACCEPT_FORMATS_MIMES.xml2,
    ACCEPT_FORMATS_MIMES.xps,
    ACCEPT_FORMATS_MIMES.pdf,
    ACCEPT_FORMATS_MIMES.jpg,
    ACCEPT_FORMATS_MIMES.png,
  ],
}

export enum UPLOAD_ERRORS {
  FILE_TOO_LARGE = 'file-too-large',
  FILE_INVALID_TYPE = 'file-invalid-type',
}

export const errorsMessages: { [key: string]: string } = {
  [UPLOAD_ERRORS.FILE_TOO_LARGE]: translations.FILE_MAX_SIZE,
  [UPLOAD_ERRORS.FILE_INVALID_TYPE]: translations.FILE_WRONG_FORMAT,
}
