import React, { forwardRef } from 'react'
import { Formik, FormikProps } from 'formik'
import { useTranslation } from 'react-i18next'

import Modal from '../../..//Modal'
import { FolderSvg } from '../../../../assets/FolderSvg'
import Box from '../../../../ui/Box'
import Typography from '../../../../ui/Typography'
import { TYPOGRAPHY_NAMES } from '../../../../ui/Typography/Typography.types'
import translations from '../../../../../translations/keys'
import Button from '../../../../ui/Button'
import FormTextInput from '../../../../ui/Forms/FormTextInput'
import { CreateFolderBody, CreateFolderFields } from '../../../../../types/createFolder'
import { createFolderInitialValues } from '../../../../../initialValues/createFolderInitialValues'
import { createFolderValidationSchema } from '../../../../../validation/documents/createFolderValidationSchema'
import { FILE_NAME_MAX_LENGTH } from '../../../../../validation/fileNameValidationSchema'

import { StyledWrapper, StyledForm } from './DocumentsCreateFolderModal.styles'
import { DocumentsCreateFolderModalProps } from './DocumentsCreateFolderModal.types'

export const DocumentsCreateFolderModalModal = forwardRef<
  FormikProps<CreateFolderBody>,
  DocumentsCreateFolderModalProps
>(({ onClose, onSubmit }, ref) => {
  const { t } = useTranslation()
  const renderForm = () => (
    <StyledForm>
      <FormTextInput
        name={CreateFolderFields.folderName}
        label={t(translations.NAME)}
        placeholder={t(translations.FOLDER_NAME_PLACEHOLDER)}
        maxLength={FILE_NAME_MAX_LENGTH}
      />
      <Box width="100%" mt={2}>
        <Button type="submit" fullWidth>
          {t(translations.CREATE)}
        </Button>
      </Box>
    </StyledForm>
  )

  return (
    <Modal onClose={onClose}>
      <StyledWrapper>
        <FolderSvg />
        <Box mt={3} mb={4}>
          <Typography name={TYPOGRAPHY_NAMES.H1}>{t(translations.CREATE_FOLDER)}</Typography>
        </Box>
        <Formik
          innerRef={ref}
          onSubmit={onSubmit}
          initialValues={createFolderInitialValues}
          validationSchema={createFolderValidationSchema}
        >
          {renderForm}
        </Formik>
      </StyledWrapper>
    </Modal>
  )
})
