import React, { useEffect } from 'react'
import { Formik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'

import translations from '../../../../translations/keys'
import { ContractSvg } from '../../../assets/ContractSvg'
import Modal from '../../../components/Modal'
import Typography from '../../../ui/Typography'
import { TYPOGRAPHY_NAMES } from '../../../ui/Typography/Typography.types'
import Box from '../../../ui/Box'
import FormRadioButton from '../../../ui/Forms/FormRadioButton'
import Button from '../../../ui/Button'
import { OPEN_CONTRACT_AS_TYPES, OpenContractAsFields, OpenContractAsForm } from '../../../../types/contractAsTypes'
import { openContractAsInitialValues as initialValues } from '../../../../initialValues/openContractAsInitialValues'
import { modalsPayloadSelector } from '../../../../store/modals/modals.selectors'
import { CONTRACTS_MODALS } from '../../../../constants/contracts'
import { fetchSigningContractDocument } from '../../../../store/contracts/signing/signing.actions'
import { location } from '../../../../utils/location'

import { OpenContractAsModalProps as OpenContractAsModalProps } from './OpenContractAsModals.types'
import { StyledForm } from './OpenContractAsModals.styles'

export const OpenContractAsModal: React.FC<OpenContractAsModalProps> = ({ onClose }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const contract = useSelector(modalsPayloadSelector(CONTRACTS_MODALS.OPEN_CONTRACT_AS))

  const onSubmit = ({ order }: OpenContractAsForm) => {
    if (order === OPEN_CONTRACT_AS_TYPES.RECIPIENT) {
      dispatch(fetchSigningContractDocument(contract.id))
    } else if (order === OPEN_CONTRACT_AS_TYPES.OWNER) {
      history.push(location.contractsOwnerViewUrl(contract.id))
    }
    onClose()
  }

  useEffect(() => () => onClose(), [])

  const renderForm = () => (
    <StyledForm>
      <Box height={90} mt={2} display="flex" flexDirection="column">
        <FormRadioButton
          name={OpenContractAsFields.order}
          label={translations.RECIPIENT}
          id={OPEN_CONTRACT_AS_TYPES.RECIPIENT}
        />
        <FormRadioButton
          name={OpenContractAsFields.order}
          label={translations.OWNER}
          id={OPEN_CONTRACT_AS_TYPES.OWNER}
        />
      </Box>
      <Button type="submit" fullWidth>
        {translations.SUBMIT}
      </Button>
    </StyledForm>
  )

  return (
    <Modal onClose={onClose}>
      <Box display="flex" alignItems="center" flexDirection="column" textAlign="center" width={320}>
        <ContractSvg />
        <Box mr={3} ml={3} mt={2} textAlign="center">
          <Typography name={TYPOGRAPHY_NAMES.H1}>{translations.OPEN_THE_CONTRACT_AS}</Typography>
        </Box>
        <Formik initialValues={initialValues} onSubmit={onSubmit}>
          {renderForm}
        </Formik>
      </Box>
    </Modal>
  )
}
