import { Ajax } from '../ajax'
import { UploadDocumentBody } from '../../types/uploadDocument'
import { DocumentPreviewResponse } from '../../types/documentPreview'
import { AddClmOnlyRecipientsBody, AddRecipientsForm, RecipientData } from '../../types/recipients'
import { TemplatesListBriefResponse, TemplatesListResponse } from '../../types/templates'
import { DocumentMessageBody, UpdateDocumentRequestBody } from '../../types/documents'
import { CreateTemplateFromContractBody } from '../../types/createTemplateFromContract'
import { PlacementBody } from '../../types/placement'
import { Params, prepareUrlParams } from '../../utils/prepareUrlParams/prepareUrlParams'
import { prepareUploadDocumentBody } from '../../utils/documents/prepareUploadDocumentBody'
import { prepareCreateTemplateFromContract } from '../../utils/documents/prepareCreateTemplateFromContract'
import { convertToFormData } from '../../utils/convertToFormData/convertToFormData'
import { DuplicateDocumentRequestBody } from '../../types/duplicateDocument'

class TemplateService extends Ajax {
  pathname = 'template'

  fetchTemplate = (templateId: string) => this.getJSON<DocumentPreviewResponse>(`${this.pathname}/${templateId}`)

  fetchUploadDocument = (body: UploadDocumentBody) =>
    this.post(`${this.pathname}/createdocument`, prepareUploadDocumentBody(body), {})

  fetchReuploadTemplate = (id: string, file: File) =>
    this.put(`${this.pathname}/updatedocument/${id}`, convertToFormData({ file }), {})

  fetchTemplatesList = (params: Params) =>
    this.getJSON<TemplatesListResponse>(`${this.pathname}?${prepareUrlParams(params)}`)

  fetchUpdateTemplate = (id: string, body: UpdateDocumentRequestBody) => this.patch(`${this.pathname}/${id}`, body)

  fetchCreateFromContract = (body: CreateTemplateFromContractBody) =>
    this.post(`${this.pathname}/createfromcontract`, prepareCreateTemplateFromContract(body))

  fetchRecipients = (templateId: string) =>
    this.getJSON<Required<RecipientData>[]>(`${this.pathname}/recipients/${templateId}`)

  fetchAddRecipients = (templateId: string, body: AddRecipientsForm) =>
    this.post(`${this.pathname}/recipients/${templateId}`, body)

  fetchAddClmOnlyRecipients = (body: AddClmOnlyRecipientsBody) => this.post(`${this.pathname}/createprocedure`, body)

  fetchPlacement = (templateId: string) => this.getJSON<PlacementBody>(`${this.pathname}/placement/${templateId}`)

  fetchAddPlacement = (body: PlacementBody) => this.post(`${this.pathname}/placement`, body)

  fetchDeleteTemplate = (id: string) => this.delete(`${this.pathname}/${id}`)

  fetchDuplicateTemplate = (id: string, body: DuplicateDocumentRequestBody) =>
    this.post(`${this.pathname}/duplicate/${id}`, body)

  fetchTemplatesListBrief = () => this.getJSON<TemplatesListBriefResponse>(`${this.pathname}/brief`)

  fetchSaveTemplateMessage = (id: string, body: DocumentMessageBody) =>
    this.post(`${this.pathname}/message/${id}`, body)
}

export const templateService = new TemplateService()
