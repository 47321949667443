import { Action } from 'redux'

import { FetchFailurePayload, FETCH_STATUSES } from '../../types/fetch'
import { LoginRequestBody, LoginResponse } from '../../types/auth'
import { User } from '../../types/user'

export interface AuthState {
  user: User | null
  loginFetchStatus: FETCH_STATUSES
  loginError?: FetchFailurePayload
  resendEmailFetchStatus: FETCH_STATUSES
  resendEmailError?: FetchFailurePayload
}

export enum AuthActions {
  SET_USER_INFO = 'SET_USER_INFO',
  FETCH_LOGIN = 'FETCH_LOGIN',
  FETCH_LOGIN_CLEAN_FETCH_STATUS = 'FETCH_LOGIN_CLEAN_FETCH_STATUS',
  FETCH_RESEND_EMAIL = 'FETCH_RESEND_EMAIL',
  CLEAN_AUTH = 'CLEAN_AUTH',
  CLEAN_LOGIN_ERROR = 'CLEAN_LOGIN_ERROR',
}

export interface SetUserInfoAction extends Action {
  payload: {
    user: User
  }
}

export interface FetchLoginAction extends Action {
  payload: {
    body: LoginRequestBody
    isMobile?: boolean
  }
}

export interface FetchLoginSuccessAction extends Action {
  payload: {
    body: LoginResponse
  }
}

export interface FetchResendEmailAction extends Action {
  payload: {
    email: string
  }
}

export type FetchResendEmailSuccessAction = Action
