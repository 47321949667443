import React from 'react'

export const InvisibleEyeIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M22.0827 11.3957C21.2588 9.65996 20.2783 8.24434 19.1413 7.14887L17.9488 8.34137C18.9212 9.27067 19.7683 10.4864 20.5007 12.0004C18.5507 16.0364 15.7827 17.9536 11.9999 17.9536C10.8644 17.9536 9.81856 17.7787 8.86231 17.429L7.5702 18.7211C8.89833 19.3344 10.3749 19.6411 11.9999 19.6411C16.5046 19.6411 19.8655 17.295 22.0827 12.6028C22.1718 12.4141 22.2181 12.208 22.2181 11.9993C22.2181 11.7906 22.1718 11.5845 22.0827 11.3957ZM20.5928 3.88074L19.5936 2.88043C19.5762 2.863 19.5555 2.84917 19.5328 2.83973C19.51 2.8303 19.4856 2.82544 19.461 2.82544C19.4363 2.82544 19.4119 2.8303 19.3892 2.83973C19.3664 2.84917 19.3457 2.863 19.3283 2.88043L16.765 5.44262C15.3517 4.72074 13.7633 4.35981 11.9999 4.35981C7.4952 4.35981 4.13427 6.7059 1.91708 11.3981C1.82796 11.5868 1.78174 11.7929 1.78174 12.0016C1.78174 12.2103 1.82796 12.4164 1.91708 12.6051C2.80286 14.4707 3.86927 15.9661 5.1163 17.0913L2.63614 19.5707C2.601 19.6059 2.58127 19.6536 2.58127 19.7033C2.58127 19.753 2.601 19.8007 2.63614 19.8358L3.63669 20.8364C3.67185 20.8715 3.71952 20.8912 3.76923 20.8912C3.81893 20.8912 3.86661 20.8715 3.90177 20.8364L20.5928 4.14606C20.6102 4.12864 20.624 4.10796 20.6335 4.0852C20.6429 4.06244 20.6478 4.03804 20.6478 4.0134C20.6478 3.98876 20.6429 3.96436 20.6335 3.9416C20.624 3.91884 20.6102 3.89816 20.5928 3.88074ZM3.49911 12.0004C5.45145 7.96449 8.21942 6.04731 11.9999 6.04731C13.2782 6.04731 14.4404 6.26668 15.4949 6.7127L13.8472 8.36035C13.0669 7.94402 12.1735 7.7895 11.2987 7.9196C10.4238 8.0497 9.61401 8.45752 8.98862 9.08291C8.36323 9.7083 7.95541 10.5181 7.82531 11.3929C7.69521 12.2678 7.84973 13.1612 8.26606 13.9415L6.31091 15.8967C5.2288 14.9416 4.29598 13.6476 3.49911 12.0004ZM9.28114 12.0004C9.28155 11.5871 9.37944 11.1798 9.56687 10.8114C9.75429 10.4431 10.026 10.1242 10.3598 9.88053C10.6937 9.63691 11.0803 9.47547 11.4882 9.40933C11.8962 9.34319 12.314 9.37421 12.7077 9.49988L9.40559 12.802C9.32283 12.5429 9.28084 12.2725 9.28114 12.0004Z"
      fill="#8B96A9"
    />
    <path
      d="M11.9059 14.6253C11.8248 14.6253 11.7449 14.6215 11.6657 14.6143L10.4277 15.8522C11.1723 16.1374 11.9836 16.2008 12.7634 16.0347C13.5432 15.8686 14.2583 15.4802 14.822 14.9164C15.3858 14.3526 15.7742 13.6376 15.9403 12.8578C16.1064 12.0779 16.043 11.2667 15.7579 10.5221L14.5199 11.7601C14.5272 11.8393 14.5309 11.9192 14.5309 12.0003C14.5311 12.3451 14.4633 12.6865 14.3315 13.0051C14.1996 13.3236 14.0063 13.6131 13.7625 13.8569C13.5187 14.1006 13.2293 14.294 12.9107 14.4258C12.5921 14.5577 12.2507 14.6255 11.9059 14.6253Z"
      fill="#8B96A9"
    />
  </svg>
)
