import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'

import { fetchSigningContractDocument } from '../../../../../store/contracts/signing/signing.actions'
import { modalsPayloadSelector } from '../../../../../store/modals/modals.selectors'
import { location } from '../../../../../utils/location'
import { CONTRACT_SIGN_MODALS } from '../../../../constants/contractSign'
import OTPVerificationModal from '../../VerificationsModals/OTPVerificationModal'

import { SigningOTPVerificationModalProps } from './SigningOTPVerificationModal.types'

export const SigningOTPVerificationModal: React.FC<SigningOTPVerificationModalProps> = ({ onClose }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { contractId } = useSelector(modalsPayloadSelector(CONTRACT_SIGN_MODALS.SIGNING_OTP_VERIFICATION))
  const onSuccess = useCallback(() => {
    onClose()
    dispatch(fetchSigningContractDocument(contractId))
  }, [contractId])
  const handleClose = () => {
    onClose()
    history.push(location.contractsAllUrl())
  }

  return <OTPVerificationModal onClose={handleClose} onSuccess={onSuccess} contractId={contractId} />
}
