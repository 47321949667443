import { combineEpics, Epic, ofType } from 'redux-observable'
import { catchError, map, mergeMap, takeUntil } from 'rxjs/operators'

import { catchFetchError } from '../../../utils/catchFetchError'
import { contractsService } from '../../../api'

import * as ACTIONS from './createContract.actions'

export const fetchCreateContract: Epic = (action$) =>
  action$.pipe(
    ofType(ACTIONS.fetchCreateContractTypes.request),
    mergeMap(({ payload }) =>
      contractsService.fetchCreateFromTemplate(payload.body).pipe(
        map(({ response }) => ACTIONS.fetchCreateContractSuccess(response.id)),
        catchError(catchFetchError(ACTIONS.fetchCreateContractFailure)),
        takeUntil(
          action$.pipe(ofType(ACTIONS.fetchCreateContractTypes.success, ACTIONS.fetchCreateContractTypes.failure))
        )
      )
    )
  )

export const createContractEpics = combineEpics(fetchCreateContract)
