import { FETCH_STATUSES } from '../../types/fetch'
import { RootState } from '../rootReducer.types'

export const publicTokenSelector = (state: RootState) => state.apiIntegration.publicToken

export const publicTokenSuccessSelector = (state: RootState) =>
  state.apiIntegration.fetchPublicToken.status === FETCH_STATUSES.SUCCESS

export const publicTokenErrorSelector = (state: RootState) => state.apiIntegration.fetchPublicToken.error

export const publicTokenGenerateSuccessSelector = (state: RootState) =>
  state.apiIntegration.generatePublicToken.status === FETCH_STATUSES.SUCCESS
