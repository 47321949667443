import React, { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'

import { CONTRACTS_MODALS } from '../../../../../constants/contracts'
import { modalsPayloadSelector } from '../../../../../store/modals/modals.selectors'
import { location } from '../../../../../utils/location'
import OTPVerificationModal from '../../VerificationsModals/OTPVerificationModal'

import { ContractOTPVerificationModalProps } from './ContractOTPVerificationModal.types'

export const ContractOTPVerificationModal: React.FC<ContractOTPVerificationModalProps> = ({ onClose }) => {
  const history = useHistory()
  const { contractId } = useSelector(modalsPayloadSelector(CONTRACTS_MODALS.OTP_VERIFICATION))
  const onSuccess = useCallback(() => {
    onClose()
    history.push(location.contractsSignUrl(contractId))
  }, [contractId])

  return <OTPVerificationModal onClose={onClose} onSuccess={onSuccess} contractId={contractId} />
}
