import React from 'react'
import ContentLoader from 'react-content-loader'

import { blueExtraLight, glowColor } from '../../../themes/colors'

const FILL_COLOR = blueExtraLight
const GLOW_COLOR = glowColor
const SPEED = 1.5
const SVG_WIDTH = 360
const SVG_HEIGHT = 490
const RADIUS = 3

export const ChooseSignModalSkeleton: React.FC = () => (
  <ContentLoader
    display="block"
    width={SVG_WIDTH}
    height={SVG_HEIGHT}
    speed={SPEED}
    backgroundColor={FILL_COLOR}
    foregroundColor={GLOW_COLOR}
  >
    <rect width="60%" height="29" x="20%" y="0" rx={RADIUS} ry={RADIUS} />
    <rect width="70%" height="42" x="15%" y="45" rx={RADIUS} ry={RADIUS} />
    <rect width="70%" height="252" x="15%" y="123" rx={RADIUS} ry={RADIUS} />
    <rect width="80%" height="21" x="10%" y="391" rx={RADIUS} ry={RADIUS} />
    <rect width="80%" height="40" x="10%" y="452" rx={RADIUS} ry={RADIUS} />
  </ContentLoader>
)
