import styled from 'styled-components'

import { LogoIcon } from '../../assets/LogoIcon'

export const Header = styled.header<{ isBannerVisible?: boolean }>`
  position: fixed;
  top: ${({ isBannerVisible }) => (isBannerVisible ? '50px' : 0)};
  right: 0;
  left: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: ${({ theme }) => theme.sizes.layout.headerHeight};
  padding: 12px 23px 10px;
  background-color: ${({ theme }) => theme.palette.white};
  box-shadow: ${({ theme }) => theme.shadow};
  z-index: 100;

  ${({ theme }) => `@media ${theme.devices.tablet} {
    height: ${theme.sizes.layout.headerHeightMobile};
  }`}
`
export const LogoWrapper = styled.div`
  width: 68px;
`
export const Logo = styled(LogoIcon)`
  ${({ theme }) => `@media ${theme.devices.tablet} {
    height: 48px;
  }`}
`
export const Nav = styled.nav`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-end;
  margin-left: auto;
`
export const Box = styled.div`
  margin-left: 50px;

  ${({ theme }) => `@media ${theme.devices.tablet} {
    margin-left: 25px
  }`}
  ${({ theme }) => `@media ${theme.devices.mobile} {
    margin-left: 20px
  }`}
`
