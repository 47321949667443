import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { TEMPLATES_MODALS } from '../../../../../constants/templates'
import { TEMPLATES_FILL_RECIPIENTS_MODALS } from '../../../../../constants/templatesFillRecipients'
import { closeModal } from '../../../../../store/modals/modals.actions'
import { modalsVisibleSelector } from '../../../../../store/modals/modals.selectors'
import { UploadContractCreateFolderModal } from '../../../../components/Modals/ContractsModals/UploadContractCreateFolderModal/UploadContractCreateFolderModal'
import AbortCreatingContractModal from '../../../../components/Modals/TemplatesModals/AbortCreatingContractModal'
import CreateContractModal from '../../../../components/Modals/TemplatesModals/CreateContractModal'
import { UploadFileModal } from '../../../../components/Modals/TemplatesModals/UploadFileModal'

export const Modals = () => {
  const dispatch = useDispatch()

  const showAbortCreatingContract = useSelector(
    modalsVisibleSelector(TEMPLATES_FILL_RECIPIENTS_MODALS.ABORT_CREATING_CONTRACT)
  )
  const handleAbortCreatingContractClose = () => {
    dispatch(closeModal(TEMPLATES_FILL_RECIPIENTS_MODALS.ABORT_CREATING_CONTRACT))
  }

  const showCreateContractFromTemplate = useSelector(
    modalsVisibleSelector(TEMPLATES_FILL_RECIPIENTS_MODALS.CREATE_CONTRACT_FROM_TEMPLATE)
  )
  const handleCreateContractFromTemplateClose = () => {
    dispatch(closeModal(TEMPLATES_FILL_RECIPIENTS_MODALS.CREATE_CONTRACT_FROM_TEMPLATE))
  }

  const showContractsCreateFolder = useSelector(
    modalsVisibleSelector(TEMPLATES_FILL_RECIPIENTS_MODALS.CREATE_CONTRACTS_FOLDER)
  )
  const handleContractsCreateFolderClose = () => {
    dispatch(closeModal(TEMPLATES_FILL_RECIPIENTS_MODALS.CREATE_CONTRACTS_FOLDER))
  }

  const showUploadFileForContract = useSelector(modalsVisibleSelector(TEMPLATES_MODALS.UPLOAD_FILE_FOR_CONTRACT))
  const handleUploadFileForContractClose = () => {
    dispatch(closeModal(TEMPLATES_MODALS.UPLOAD_FILE_FOR_CONTRACT))
  }

  return (
    <>
      {showAbortCreatingContract && <AbortCreatingContractModal onClose={handleAbortCreatingContractClose} />}
      {showCreateContractFromTemplate && <CreateContractModal onClose={handleCreateContractFromTemplateClose} />}
      {showContractsCreateFolder && <UploadContractCreateFolderModal onClose={handleContractsCreateFolderClose} />}
      {showUploadFileForContract && <UploadFileModal onClose={handleUploadFileForContractClose} />}
    </>
  )
}
