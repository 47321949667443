import styled from 'styled-components'

import { SpinnerProps } from './Spinner.types'

export const StyledWrapper = styled.div<Omit<SpinnerProps, 'show'>>`
  width: 40px;
  height: 40px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  line-height: 12px;
`
export const StyledSVG = styled.svg`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`
export const StyledCircle = styled.circle`
  @keyframes move {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  stroke: ${(props) => props.theme.palette.blue};
  stroke-width: 1px;
  fill: none;
  transform-origin: center;
  stroke-dasharray: 70 100;
  animation: move 1s infinite linear;
`
export const StyledCircleBg = styled.circle`
  stroke: ${(props) => props.theme.palette.greyExtraLight};
  stroke-width: 1px;
  fill: none;
`
