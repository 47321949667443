import React from 'react'
import ContentLoader from 'react-content-loader'

import { blueExtraLight, glowColor } from '../../../themes/colors'
import { CONTACTS_CONTENT_HEIGHT } from '../Modals/ContractAddRecipientsModals/ContactsModal/ContactsModal.constants'

const FILL_COLOR = blueExtraLight
const GLOW_COLOR = glowColor
const SPEED = 1.5
const SVG_WIDTH = '100%'
const SVG_HEIGHT = CONTACTS_CONTENT_HEIGHT
const ROW_HEIGHT = 56
const CELL_Y = 110
const CELL_H = 18
const CELL_BORDER_Y = CELL_Y + CELL_H + 20
const RADIUS = 3
const ROWS = [
  ['30%', '15%'],
  ['20%', '15%'],
  ['38%', '15%'],
  ['26%', '15%'],
  ['15%', '15%'],
]

export const ContactsModalSkeleton: React.FC = () => (
  <ContentLoader
    display="block"
    width={SVG_WIDTH}
    height={SVG_HEIGHT}
    speed={SPEED}
    backgroundColor={FILL_COLOR}
    foregroundColor={GLOW_COLOR}
  >
    <rect width="100%" height="40" x="0" y="40" rx={RADIUS} ry={RADIUS} />

    {ROWS.map(([width1, width2], i) => (
      <React.Fragment key={i}>
        <rect width={width1} height={CELL_H} x="0" y={CELL_Y + ROW_HEIGHT * i} rx={RADIUS} ry={RADIUS} />
        <rect width={width2} height={CELL_H} x="85%" y={CELL_Y + ROW_HEIGHT * i} rx={RADIUS} ry={RADIUS} />
        <rect width="100%" height="1" x="0" y={CELL_BORDER_Y + ROW_HEIGHT * i} rx={RADIUS} ry={RADIUS} />
      </React.Fragment>
    ))}
  </ContentLoader>
)
