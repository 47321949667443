import React from 'react'

import { SvgIconProps } from '../../ui/SvgIcon/SvgIcon.types'
import SvgIcon from '../../ui/SvgIcon'

export const SaveIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <path d="M20.9367 6.87422L17.1258 3.06328C16.95 2.8875 16.7344 2.75859 16.5 2.68828V2.625H3.375C2.96016 2.625 2.625 2.96016 2.625 3.375V20.625C2.625 21.0398 2.96016 21.375 3.375 21.375H20.625C21.0398 21.375 21.375 21.0398 21.375 20.625V7.93359C21.375 7.53516 21.218 7.15547 20.9367 6.87422ZM9 4.3125H15V6.75H9V4.3125ZM19.6875 19.6875H4.3125V4.3125H7.5V7.5C7.5 7.91484 7.83516 8.25 8.25 8.25H15.75C16.1648 8.25 16.5 7.91484 16.5 7.5V4.82344L19.6875 8.01094V19.6875ZM12 10.3594C10.1367 10.3594 8.625 11.8711 8.625 13.7344C8.625 15.5977 10.1367 17.1094 12 17.1094C13.8633 17.1094 15.375 15.5977 15.375 13.7344C15.375 11.8711 13.8633 10.3594 12 10.3594ZM12 15.6094C10.9641 15.6094 10.125 14.7703 10.125 13.7344C10.125 12.6984 10.9641 11.8594 12 11.8594C13.0359 11.8594 13.875 12.6984 13.875 13.7344C13.875 14.7703 13.0359 15.6094 12 15.6094Z" />
  </SvgIcon>
)
