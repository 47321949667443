import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { modalsPayloadSelector } from '../../../../../store/modals/modals.selectors'
import { SIGNATURES_MODALS } from '../../../../constants/signatures'
import { VerificationPasswordModal } from '../../VerificationPasswordModal/VerificationPasswordModal'
import { cleanCreateSignature, fetchCreateSignature } from '../../../../../store/signatures/signatures.actions'
import {
  verifyPasswordIsFailureSelector,
  verifyPasswordSuccessSelector,
} from '../../../../../store/verifyPassword/verifyPassword.selectors'
import { VerifyPasswordKeys } from '../../../../../types/verifyPassword'
import { AccessGuardFormSubmit } from '../../../AccessGuardForm/AccessGuardForm.types'
import { cleanVerifyPassword, fetchVerifyPassword } from '../../../../../store/verifyPassword/verifyPassword.actions'
import { closeModal, openModal } from '../../../../../store/modals/modals.actions'
import translations from '../../../../../translations/keys'
import { COMMON_MODALS_IDS } from '../../../../ui/Modals/CommonModals/CommonModals.constants'
import { NOTIFICATION_MODAL_TYPES } from '../../../../ui/Modals/NotificationModal/NotificationModal.constants'
import {
  createSignatureIsSuccessSelector,
  createSignatureSelector,
  createSignatureIsFailureSelector,
} from '../../../../../store/signatures/signatures.selectors'
import { useShowFetchError } from '../../../../hooks/useShowFetchError'
import { useDispatchUnmount } from '../../../../../hooks/useDispatchUnmount'

import { CreateSignatureVerificationModalProps } from './CreateSignatureVerificationModal.types'

export const CreateSignatureVerificationModal: React.FC<CreateSignatureVerificationModalProps> = ({ onClose }) => {
  const dispatch = useDispatch()
  const access = useSelector(verifyPasswordSuccessSelector(VerifyPasswordKeys.CREATE_SIGNATURE))
  const isError = useSelector(verifyPasswordIsFailureSelector(VerifyPasswordKeys.CREATE_SIGNATURE))
  const { body } = useSelector(modalsPayloadSelector(SIGNATURES_MODALS.CREATE_SIGNATURE_VERIFICATION))
  const createSignatureIsSuccess = useSelector(createSignatureIsSuccessSelector)
  const createSignatureIsFailure = useSelector(createSignatureIsFailureSelector)
  const createSignature = useSelector(createSignatureSelector)

  const onAccessed = useCallback(() => {
    dispatch(fetchCreateSignature(body))
  }, [body])
  const onSubmit: AccessGuardFormSubmit = (values) => {
    dispatch(fetchVerifyPassword(VerifyPasswordKeys.CREATE_SIGNATURE, values))
  }
  const onCleanVerifyPassword = useCallback(() => cleanVerifyPassword(VerifyPasswordKeys.CREATE_SIGNATURE), [])

  useEffect(() => {
    if (createSignatureIsSuccess) {
      dispatch(closeModal(SIGNATURES_MODALS.CREATE_SIGNATURE_VERIFICATION))
      dispatch(closeModal(SIGNATURES_MODALS.CREATE_SIGNATURE))
      dispatch(
        openModal(COMMON_MODALS_IDS.NOTIFICATION, {
          type: NOTIFICATION_MODAL_TYPES.SUCCESS,
          title: translations.SIGNATURE_ADDED,
        })
      )
    }
  }, [createSignatureIsSuccess])
  useShowFetchError(createSignature.fetchStatus, createSignature.error)
  useDispatchUnmount(onCleanVerifyPassword, cleanCreateSignature)

  return (
    <VerificationPasswordModal
      onClose={onClose}
      onSubmit={onSubmit}
      onAccessed={onAccessed}
      access={access}
      isError={isError || createSignatureIsFailure}
      submitText={translations.CONTINUE}
    />
  )
}
