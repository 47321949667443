import { Action } from 'redux'

import { Sorting } from './table'

export enum FETCH_STATUSES {
  IDLE = 'IDLE',
  REQUEST = 'REQUEST',
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE',
}

export interface FailureDetail {
  error: string
  index: number
}
export interface FetchError {
  statusCode?: number
  message: string[]
  error?: string
  details?: FailureDetail[]
}

export interface FetchFailureAction<T = Array<FailureDetail>> extends Action {
  payload: { key?: string } & FetchFailurePayload<T>
}

export interface FetchFailurePayload<T = Array<FailureDetail>> {
  errorMessage: string
  errorCodes: string[]
  lastErrorCode: string
  details?: T
}

export interface FetchAxiosError {
  response: FetchError | string
}

export interface FetchResponseWithPagination {
  offset: number
  limit: number
  order: Sorting['direction']
  orderByField: Sorting['field']
  page: number
  totalCount: number
}
