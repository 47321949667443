import React from 'react'

import Box from '../Box'

import { StyledBullet } from './BulletList.styles'
import { BulletListProps } from './BulletList.types'

const FULL_WIDTH = 100

export const BulletList: React.FC<BulletListProps> = ({ mbItem, items, columnsCount = 1 }) => (
  <Box component="ul" display="flex" flexWrap="wrap">
    {items.map((item, index) => (
      <StyledBullet
        key={index}
        mb={mbItem}
        data-testid="bullet-item"
        component="li"
        width={`${FULL_WIDTH / columnsCount}%`}
      >
        {item}
      </StyledBullet>
    ))}
  </Box>
)
