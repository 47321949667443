import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { clearPreview, fetchPreview } from '../../store/templates/preview/preview.actions'
import { useDispatchUnmount } from '../useDispatchUnmount'

export const useTemplatePreviewFetch = (id: string) => {
  const dispatch = useDispatch()

  useEffect(() => {
    if (id) {
      dispatch(fetchPreview(id))
    }
  }, [id])

  useDispatchUnmount(clearPreview)
}
