import { FetchFailureAction, FETCH_STATUSES } from '../../../types/fetch'
import { createReducer } from '../../createReducer'

import * as ACTIONS from './noneVerification.actions'
import * as TYPES from './noneVerification.types'

type Actions = FetchFailureAction

export const initialState: TYPES.NoneVerificationState = {
  fetchStatus: FETCH_STATUSES.IDLE,
  error: null,
}

export const noneVerification = createReducer<TYPES.NoneVerificationState, Actions>(initialState, {
  [ACTIONS.fetchNoneVerificationTypes.request]: (state) => ({
    ...state,
    fetchStatus: FETCH_STATUSES.REQUEST,
  }),
  [ACTIONS.fetchNoneVerificationTypes.success]: (state) => ({
    ...state,
    fetchStatus: FETCH_STATUSES.SUCCESS,
  }),
  [ACTIONS.fetchNoneVerificationTypes.failure]: (state, action) => ({
    ...state,
    fetchStatus: FETCH_STATUSES.FAILURE,
    error: (action as FetchFailureAction).payload,
  }),
  [TYPES.NoneVerificationActions.CLEAN_NONE_VERIFICATION]: () => ({ ...initialState }),
})
