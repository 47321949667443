import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'

import * as CONTRACTS_SELECTORS from '../../store/contracts/contractsList/contractsList.selectors'
import * as CONTRACTS_ACTIONS from '../../store/contracts/contractsList/contractsList.actions'
import { Variant } from '../../types/notifications'

export const useContractsNotification = () => {
  const dispatch = useDispatch()
  const error = useSelector(CONTRACTS_SELECTORS.contractsListErrorSelector)
  const message = useSelector(CONTRACTS_SELECTORS.contractsListSuccessMessageSelector)
  const isSuccess = useSelector(CONTRACTS_SELECTORS.contractsListSuccessMessageSelector)

  useEffect(() => {
    if (error) {
      dispatch(
        CONTRACTS_ACTIONS.setContractsNotification({
          message: error.lastErrorCode,
          variant: Variant.WARNING,
        })
      )
    }
  }, [error])

  useEffect(() => {
    if (isSuccess && message) {
      dispatch(
        CONTRACTS_ACTIONS.setContractsNotification({
          message: message,
          variant: Variant.SUCCESS,
        })
      )
    }
  }, [isSuccess, message])
}
