import { combineEpics, Epic, ofType } from 'redux-observable'
import { catchError, map, mergeMap, takeUntil } from 'rxjs/operators'

import { authService } from '../../api'
import { VerifyPasswordKeys } from '../../types/verifyPassword'
import { catchFetchError } from '../../utils/catchFetchError'

import * as ACTIONS from './verifyPassword.actions'

const fetchVerifyPassword: Epic = (action$) =>
  action$.pipe(
    ofType(ACTIONS.fetchVerifyPasswordTypes.request),
    mergeMap(({ payload }) =>
      authService.fetchPasswordVerify(payload.body).pipe(
        map((action) => ACTIONS.fetchVerifyPasswordSuccess(payload.key, action.response.token)),
        catchError(catchFetchError<VerifyPasswordKeys>(ACTIONS.fetchVerifyPasswordFailure, payload.key)),
        takeUntil(
          action$.pipe(ofType(ACTIONS.fetchVerifyPasswordTypes.success, ACTIONS.fetchVerifyPasswordTypes.failure))
        )
      )
    )
  )

export const verifyPasswordEpics = combineEpics(fetchVerifyPassword)
