import { FetchFailureAction, FetchFailurePayload } from '../../../types/fetch'
import { createFetchActionTypes } from '../../../utils/createFetchActionTypes'

import * as TYPES from './download.types'

export const fetchDownloadContractTypes = createFetchActionTypes(TYPES.DownloadActions.FETCH_DOWNLOAD_CONTRACT)
export const subscribeOnDownloadProgressTypes = createFetchActionTypes(
  TYPES.DownloadActions.SUBSCRIBE_ON_DOWNLOAD_PROGRESS
)

export const fetchDownloadContract = (contractId: string): TYPES.FetchDownloadContractAction => ({
  type: fetchDownloadContractTypes.request,
  payload: { contractId },
})
export const fetchDownloadContractSuccess = (link: string): TYPES.FetchDownloadContractSuccessAction => ({
  type: fetchDownloadContractTypes.success,
  payload: { link },
})
export const fetchDownloadContractFailure = (payload: FetchFailurePayload): FetchFailureAction => ({
  type: fetchDownloadContractTypes.failure,
  payload,
})

export const subscribeOnDownloadProgress = (contractId: string): TYPES.SubscribeOnDownloadProgressAction => ({
  type: subscribeOnDownloadProgressTypes.request,
  payload: { contractId },
})
export const subscribeOnDownloadProgressSuccess = (): TYPES.SubscribeOnDownloadProgressSuccessAction => ({
  type: subscribeOnDownloadProgressTypes.success,
})
export const subscribeOnDownloadProgressFailure = (payload: FetchFailurePayload): FetchFailureAction => ({
  type: subscribeOnDownloadProgressTypes.failure,
  payload,
})
export const unsubscribeOnDownloadProgress = () => ({
  type: TYPES.DownloadActions.UNSUBSCRIBE_ON_DOWNLOAD_PROGRESS,
})

export const cleanDownload = () => ({
  type: TYPES.DownloadActions.CLEAN_DOWNLOAD,
})
