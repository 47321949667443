import React, { createRef, RefObject, useState } from 'react'

import Box from '../Box'

import Tab from './components/Tab'
import Underline from './components/Underline'
import { TabsProps } from './Tabs.types'
import { StyledTabs } from './Tabs.styles'
import TabPanel from './components/TabPanel'

export const Tabs: React.FC<TabsProps> = ({ items, activeId, onChange }) => {
  const [animating, setAnimating] = useState(false)
  const tabRefs = items.reduce<{ [key: string]: RefObject<HTMLDivElement> }>(
    (acc, item) => ({ ...acc, [item.id]: createRef() }),
    {}
  )

  const finishAnimating = () => {
    setAnimating(false)
  }

  return (
    <div>
      <StyledTabs>
        <Box display="flex" justifyContent="center" mb={2}>
          {items.map(({ name, id, errorsCount }) => {
            const handleChange = () => {
              if (tabRefs[id]) {
                onChange(id)
                setAnimating(true)
              }
            }

            return (
              <Tab
                key={id}
                ref={tabRefs[id]}
                isActive={id === activeId}
                onClick={handleChange}
                name={name}
                animating={animating}
                errorsCount={errorsCount}
              />
            )
          })}
        </Box>
        <Underline refs={tabRefs} activeId={activeId} animating={animating} finishAnimating={finishAnimating} />
      </StyledTabs>
      <div>
        {items.map(({ render, id }) => (
          <TabPanel key={id} isActive={id === activeId}>
            {render()}
          </TabPanel>
        ))}
      </div>
    </div>
  )
}
