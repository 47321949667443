import React from 'react'

import SvgIcon from '../../ui/SvgIcon'
import { SvgIconProps } from '../../ui/SvgIcon/SvgIcon.types'

export const SuccessIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} viewBox="0 0 14 14">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 1C3.68666 1 1 3.68666 1 7C1 10.3133 3.68666 13 7 13C10.3133 13 13 10.3133 13 7C13 3.68666 10.3133 1 7 1ZM0 7C0 3.13438 3.13438 0 7 0C10.8656 0 14 3.13438 14 7C14 10.8656 10.8656 14 7 14C3.13438 14 0 10.8656 0 7Z"
    />
    <path d="M5.54916 6.2526L6.66166 7.79635L9.11791 4.3901C9.18422 4.29837 9.27868 4.23243 9.38525 4.2017C9.42934 4.18899 9.4755 4.18229 9.52259 4.18229H10.2554C10.357 4.18229 10.4163 4.29792 10.357 4.38073L7.06634 8.94323C7.02035 9.00742 6.95972 9.05973 6.88948 9.09581C6.81924 9.1319 6.74141 9.15072 6.66244 9.15072C6.58347 9.15072 6.50564 9.1319 6.4354 9.09581C6.36515 9.05973 6.30452 9.00742 6.25853 8.94323L4.31009 6.24323C4.25072 6.16042 4.31009 6.04479 4.41166 6.04479H5.14447C5.19135 6.04479 5.23755 6.05142 5.2818 6.06411C5.388 6.09457 5.48298 6.15996 5.54916 6.2526Z" />
  </SvgIcon>
)
