export enum QUERY_PARAMS {
  EMAIL_VERIFIED = 'emailverified',
  EXPIRED_PASSWORD = 'expiredPassword',
  EMAIL_CHANGED = 'emailChanged',
  ID = 'id',
  CODE = 'code',
  VERIFICATION_FAIL = 'verificationFail',

  RECIPIENT_TOKEN = 'token',

  TYPE = 'type',
  TERM = 'term',
}
