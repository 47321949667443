import { Action } from 'redux'

import { FetchFailurePayload, FETCH_STATUSES } from '../../types/fetch'
import {
  ReadNotificationsBody,
  UserNotification,
  UserNotifications,
  UserNotificationsResponse,
} from '../../types/userNotifications'
import { Params } from '../../utils/prepareUrlParams/prepareUrlParams'

export interface UserNotificationsState {
  fetchStatus: FETCH_STATUSES
  data: UserNotifications
  error: FetchFailurePayload | null
  offset: number
  total: number
  limit: number
  read: {
    fetchStatus: FETCH_STATUSES
    error: FetchFailurePayload | null
  }
  unread: {
    count: number
    fetchStatus: FETCH_STATUSES
    error: FetchFailurePayload | null
  }
}

export enum UserNotificationsActions {
  'FETCH_USER_NOTIFICATIONS' = 'FETCH_USER_NOTIFICATIONS',
  'USER_NOTIFICATIONS_SET_OFFSET' = 'USER_NOTIFICATIONS_SET_OFFSET',
  'USER_NOTIFICATIONS_READ' = 'USER_NOTIFICATIONS_READ',
  'FETCH_USER_NOTIFICATIONS_UNREAD_COUNT' = 'FETCH_USER_NOTIFICATIONS_UNREAD_COUNT',
  'ADD_USER_NOTIFICATION' = 'ADD_USER_NOTIFICATION',
  'CLEAR_NOTIFICATIONS' = 'CLEAR_NOTIFICATIONS',
  'CLEAR_NOTIFICATIONS_OFFSET' = 'CLEAR_NOTIFICATIONS_OFFSET',
}

export interface FetchUserNotificationsAction extends Action {
  payload: { params: Params }
}
export interface FetchUserNotificationsSuccessAction extends Action {
  payload: { notifications: UserNotificationsResponse }
}

export interface SetOffsetAction extends Action {
  payload: { offset: number }
}

export interface ReadNotificationsAction extends Action {
  payload: { body: ReadNotificationsBody }
}
export interface ReadNotificationsSuccessAction extends Action {
  payload: { body: ReadNotificationsBody }
}

export type FetchUnreadCountAction = Action

export interface FetchUnreadCountSuccessAction extends Action {
  payload: { unreadCount: number }
}

export interface AddUserNotificationAction extends Action {
  payload: { notification: UserNotification }
}
