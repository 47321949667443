import { combineReducers } from 'redux'

import { SubscriptionState } from './subscription.types'
import { userPlan } from './userPlan/userPlan.reducer'
import { contactUs } from './contactUs/contactUs.reducer'
import { plans } from './plans/plans.reducer'
import { payment } from './payment/payment.reducer'
import { cards } from './cards/cards.reducer'

export const subscription = combineReducers<SubscriptionState>({
  userPlan,
  contactUs,
  plans,
  payment,
  cards,
})
