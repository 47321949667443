import React from 'react'
import { Formik } from 'formik'

import Modal from '../../../Modal'
import { FolderSvg } from '../../../../assets/FolderSvg'
import Box from '../../../../ui/Box'
import Typography from '../../../../ui/Typography'
import { TYPOGRAPHY_NAMES } from '../../../../ui/Typography/Typography.types'
import translations from '../../../../../translations/keys'
import Button from '../../../../ui/Button'
import FormDropdown from '../../../../ui/Forms/FormDropdown'
import { DocumentMoveToFolderFields, DocumentMoveToFolderForm } from '../../../../../types/documentMoveToFolder'
import { documentMoveToFolderInitialValues } from '../../../../../initialValues/documentMoveToFolderInitialValues'
import { DocumentMoveToFolderSkeleton } from '../../../Skeletons/DocumentMoveToFolderSkeleton'
import { FormikRenderForm } from '../../../../../types/formik'

import { StyledWrapper, StyledForm } from './MoveDocumentToFolderModal.styles'
import { MoveDocumentToFolderModalProps } from './MoveDocumentToFolderModal.types'

export const MoveDocumentToFolderModal: React.FC<MoveDocumentToFolderModalProps> = ({
  onClose,
  loading,
  onSubmit,
  folders,
}) => {
  const renderForm = ({ values }: FormikRenderForm<DocumentMoveToFolderForm>) => (
    <StyledForm>
      <FormDropdown
        name={DocumentMoveToFolderFields.folder}
        label={translations.SELECT_FOLDER_LABEL}
        placeholder=""
        options={folders}
        maxHeight={110}
      />
      <Box width="100%" mt={2}>
        <Button type="submit" fullWidth disabled={!values.folder}>
          {translations.SAVE}
        </Button>
      </Box>
    </StyledForm>
  )

  return (
    <Modal onClose={onClose}>
      <StyledWrapper>
        <FolderSvg />
        <Box mt={3} mb={4}>
          <Typography name={TYPOGRAPHY_NAMES.H1}>{translations.MOVE_TO_FOLDER}</Typography>
        </Box>
        {loading ? (
          <DocumentMoveToFolderSkeleton />
        ) : (
          <Formik onSubmit={onSubmit} initialValues={documentMoveToFolderInitialValues}>
            {renderForm}
          </Formik>
        )}
      </StyledWrapper>
    </Modal>
  )
}
