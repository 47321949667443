import { TranslationKeys } from '../keys'

const translations: TranslationKeys = {
  WARNING: 'تحذير',
  SUCCESS: 'نجاح!',
  INFO: 'معلومات',
  ARE_YOU_SURE: 'هل أنت متأكد؟',
  OK: 'موافق',
  GENERATE: 'إنشاء',
  TERMS_OF_USE: 'شروط الاستخدام',
  AND: 'و',
  PRIVACY_POLICY: 'سياسة الخصوصية',
  SIGN_UP: 'التسجيل',
  LOG_IN: 'تسجيل الدخول',
  LOGOUT: 'تسجيل الخروج',
  UQOUD: 'Uqoud',
  OPEN_IN_UQOUD_APP_BUTTON: 'فتح في زر تطبيق عقود',
  OPEN_IN_UQOUD_APP: 'فتح في تطبيق عقود',
  LEGAL_NOTICE: 'إشعار قانوني!',
  LANGUAGE: 'اللغة',
  COPYRIGHT: 'حقوق الطبع والنشر ¬© 2020 عقود. جميع الحقوق محفوظة',
  PASSWORD_LABEL: 'كلمة المرور',
  PASSWORD_PLACEHOLDER: 'أدخل كلمة المرور هنا',
  LOGIN_REMEMBER_ME: 'تثبيت تسجيل الدخول',
  LOGIN_FORGOT_PASSWORD: 'هل نسيت كلمة المرور؟',
  LOGIN_NO_ACCOUNT: 'أليس لديك حساب؟',
  LOGIN_SIGN_UP_NOW: 'سجّل الآن',
  REGISTRATION_TITLE: 'إنشاء حساب',
  FIRST_NAME_LABEL: 'الاسم الأول',
  FIRST_NAME_PLACEHOLDER: 'أدخل الاسم الأول هنا',
  LAST_NAME_LABEL: 'اسم العائلة',
  TOKEN_HAS_BEEN_EXPIRED: 'انتهت صلاحية الرمز',
  LAST_NAME_PLACEHOLDER: 'أدخل اسم العائلة',
  COUNTRY_LABEL: 'البلد',
  COUNTRY_PLACEHOLDER: 'حدد بلدك هنا',
  INDUSTRY_LABEL: 'مجال العمل',
  INDUSTRY_PLACEHOLDER: 'حدد مجال العمل هنا',
  EMAIL_LABEL: 'البريد الإلكتروني',
  EMAIL_YOUR_PLACEHOLDER: 'أدخل بريدك الإلكتروني هنا',
  PHONE_LABEL: 'رقم الجوال',
  REGISTRATION_PASSWORD_PLACEHOLDER: 'أنشئ كلمة المرور هنا',
  CONFIRM_PASSWORD_LABEL: 'تأكيد كلمة المرور',
  API_INTEGRATION: 'تكامل واجهة برمجة التطبيقات',
  PUBLIC_TOKEN_LABEL: 'الرمز المميز',
  API_INTEGRATION_DESCRIPTION:
    'يمكنك الوصول إلى واجهة برمجة التطبيقات إذا اتبعت التعليمات الواردة في صفحة وصف واجهة برمجة التطبيقات. nاستخدم الرمز المبين أدناه لأداء العمليات المتاحة',
  GO_TO_API_DESCRIPTION: 'الانتقال إلى وصف واجهة برمجة التطبيقات',
  CONFIRM_GENERATE_MODAL_DESCRIPTION: 'إنشاء رمز جديد سينهي صلاحية الرمز الحالي تلقائيًا',
  GENERATE_NEW: 'إنشاء رمز جديد',
  CLEAR_AND_CONTINUE: 'مسح ومتابعة',
  TOKEN_COPIED: 'نُسِخ الرمز للحافظة',
  REGISTRATION_CONFIRM_PASSWORD_PLACEHOLDER: 'تأكيد كلمة المرور',
  PASSWORD_VALIDATION_INFO:
    'يجب أن تحتوي كلمة المرور على 8 رموز أو أكثر, ورقم واحد, وحرف خاص واحد, وحرف كبير واحد, وحرف صغير واحد',
  REGISTRATION_ACCEPT: 'أوافق',
  REGISTRATION_HAVE_ACCOUNT: 'هل تملك حسابًا بالفعل؟',
  SOME_ERROR_OCCURRED: 'حدث خطأ ما',
  INVALID_EMAIL: 'عنوان بريد إلكتروني غير صالح',
  EMAIL_REQUIRED: 'البريد الإلكتروني مطلوب',
  EMAIL_EXIST: 'البريد الإلكتروني المحدد مُسجّل بالفعل',
  CONTACT_EMAIL_EXIST: 'توجد جهة اتصال بهذا البريد الإلكتروني',
  CONTACT_EXIST: 'جهة الاتصال موجودة للتو',
  LONG_EMAIL: 'البريد الإلكتروني طويل',
  LONG_NAME: 'الاسم طويل',
  LONG_PASSWORD: 'كلمة المرور طويلة',
  PASSWORD_INVALID: 'كلمة المرور غير صالحة',
  PASSWORD_REQUIRED: 'كلمة المرور مطلوبة',
  PASSWORDS_DO_NOT_MATCH: 'كلمتا المرور غير متطابقتين',
  INVALID_REQUEST_BODY: 'نص الطلب غير صالح',
  INCORRECT_DATA_ENTERED: 'تم إدخال بيانات غير صحيحة',
  INVALID_PHONE: 'رقم هاتف غير صالح',
  SELECT_COUNTRY: 'يرجى تحديد بلدك',
  SELECT_INDUSTRY: 'يرجى تحديد مجال عملك',
  SMTP_IS_NOT_ACTIVE: 'خدمة SMTP غير نشطة',
  SOMETHING_WRONG: 'حدث خطأٌ ما',
  PLEASE_ACCEPT_TERMS: 'يرجى الموافقة على شروط الاستخدام وسياسة الخصوصية',
  CONFIRM_EMAIL_TITLE: 'تأكيد البريد الإلكتروني',
  CONFIRM_EMAIL_DESCRIPTION:
    'تم بعث رابط التحقق إلى عنوان بريدك الإلكتروني. يرجى تفحص بريدك الإلكتروني للتحقق من حسابك',
  CONFIRM_EDIT_EMAIL_DESCRIPTION: 'تم إرسال رابط التحقق إلى عنوان بريدك الإلكتروني الجديد. يرجى تفحص بريدك الإلكتروني',
  CONFIRM_PHONE_TITLE: 'تأكيد رقم الهاتف',
  CONFIRM_PHONE_DESCRIPTION: 'تم إرسال رمز التحقق إلى رقم الهاتف المحدد',
  CONFIRM_PHONE_CODE_INPUT_LABEL: 'يرجى إدخال الرمز',
  CONFIRM_PHONE_RESEND_BUTTON: 'إعادة إرسال الرمز',
  INCORRECT_CODE: 'رمز غير صالح',
  PHONE_REQUIRED: 'رقم الهاتف مطلوب',
  BACK_TO_LOG_IN: 'العودة لتسجيل الدخول',
  BACK_TO_PROFILE: 'العودة إلى ملف التعريف',
  FORGOT_PASSWORD: 'نسيت كلمة المرور',
  FORGOT_PASSWORD_DESCRIPTION: 'أدخل بريدك الإلكتروني لإعادة تعيين كلمة المرور',
  FORGOT_PASSWORD_EMAIL_PLACEHOLDER: 'أدخل بريدك الإلكتروني',
  RESET_PASSWORD: 'إعادة تعيين كلمة المرور',
  CONTINUE: 'متابعة',
  CHECK_EMAIL_TITLE: 'تحقق من بريدك الإلكتروني',
  CHECK_EMAIL_DESCRIPTION:
    'تم إرسال تعليمات إعادة تعيين كلمة المرور إلى بريدك الإلكتروني. إذا لم تجدها ابحث في مجلد البريد العشوائي',
  RESEND_NOTIFICATION: 'إعادة إرسال البريد الإلكتروني للتحقق من الصحة',
  RESTORE_PASSWORD_TITLE: 'تعيين كلمة المرور',
  RESTORE_CONFIRM_PASSWORD_PLACEHOLDER: 'تأكيد كلمة المرور',
  RESTORE_PASSWORD_PLACEHOLDER: 'إنشاء كلمة مرور جديدة',
  RESTORE_PASSWORD_SUCCESS: 'تم تغيير كلمة المرور',
  EXPIRED_LINK_MODAL_TITLE: 'انتهت صلاحية الرابط',
  EXPIRED_LINK_MODAL_DESCRIPTION: 'يُرجى محاولة تسجيل الدخول لطلب رابط تحقق آخر',
  BACK_TO_SIGNUP: 'العودة إلى التسجيل',
  REQUIRED_FIELD: 'لا يمكن أن يكون الحقل فارغًا',
  RESEND_CODE: 'إعادة إرسال الرمز',
  BASE_URL_NOT_EXIST: 'لم يتم العثور عليه',
  EDIT_EMAIL: 'تحرير البريد الإلكتروني',
  PHONE: 'الهاتف',
  EDIT_PROFILE_PHONE_EDIT: 'تحرير رقم الهاتف الجوال',
  PERSONAL_DATA: 'البيانات الشخصية',
  JOB_DATA: 'بيانات الوظيفة',
  COMPANY_LABEL: 'الشركة',
  COMPANY_PLACEHOLDER: 'أدخل اسم شركتك',
  JOB_TITLE_LABEL: 'المسمى الوظيفي',
  JOB_TITLE_PLACEHOLDER: 'أدخل مسماك الوظيفي',
  ADDRESS: 'العنوان',
  ADDRESS_PLACEHOLDER: 'أدخل عنوانك',
  CITY_LABEL: 'المدينة',
  CITY_PLACEHOLDER: 'أدخل مدينتك',
  REGION_LABEL: 'المنطقة/المحافظة',
  REGION_PLACEHOLDER: 'أدخل منطقتك',
  POSTAL_CODE_LABEL: 'الرمز البريدي',
  POSTAL_CODE_PLACEHOLDER: 'أدخل الرمز البريدي',
  UPDATE: 'تحديث',
  USER_NOT_EXISTS: 'لقد أدخلت بريدًا إلكترونيًا غير صالح',
  WRONG_CREDENTIALS: 'لقد أدخلت بريدًا إلكترونيًا أو كلمة مرور غير صالحة',
  VERIFY_EMAIL_FIRST: 'يرجى التحقق من حسابك قبل تسجيل الدخول',
  RESEND_EMAIL_DESCRIPTION: 'يرجى التحقق من حسابك قبل تسجيل الدخول',
  RESEND_EMAIL_SUCCESS: 'تمت إعادة إرسال البريد الإلكتروني. يرجى التحقق من صندوق الوارد',
  CONFIRMED_EMAIL: 'تم تأكيد بريدك الإلكتروني بنجاح',
  CONFIRMED_NEW_EMAIL: 'تم تأكيد بريد إلكتروني جديد بنجاح',
  PASSWORD_REQUIREMENTS: 'متطلبات كلمة المرور',
  PASSWORD_CONTAIN: 'يجب أن تحتوي كلمة المرور على',
  ONLY_LATIN_LETTERS: 'حروف لاتينية فقط',
  EIGHT_OR_MORE_SYMBOLS: '8 رموز أو أكثر',
  AT_LEAST_ONE: 'واحد على الأقل',
  DIGIT: 'رقم',
  SPECIAL_CHARACTER: 'حرف خاص',
  UPPERCASE_LETTER: 'حرف كبير',
  LOWERCASE_LETTER: 'حرف صغير',
  CURRENT_PASSWORD_LABEL: 'كلمة المرور الحالية',
  CURRENT_PASSWORD_PLACEHOLDER: 'أدخل كلمة المرور الحالية',
  NEW_PASSWORD_LABEL: 'كلمة المرور الجديدة',
  NEW_PASSWORD_PLACEHOLDER: 'أدخل كلمة المرور الجديدة',
  CONFIRM_NEW_PASSWORD: 'تأكيد كلمة المرور الجديدة',
  SAVE: 'حفظ',
  INCORRECT_CURRENT_PASSWORD: 'لقد أدخلت كلمة مرور حالية غير صحيحة',
  PASSWORD_CHANGED_SUCCESSFULLY: 'تم تغيير كلمة المرور',
  PHONE_CHANGED_SUCCESSFULLY: 'تم تغيير الهاتف',
  TOKEN_GENERATED_SUCCESSFULLY: 'تم إنشاء رمز جديد بنجاح',
  CHANGE_PHONE_NUMBER_TITLE: 'تغير رقم الهاتف الجوال',
  CHANGE_PHONE_NUMBER_DESCRIPTION:
    'أدخل رقم هاتف جوال جديد. سنرسل رمز تحقق إلى رقمك الجديد من خلال رسالة نصية قصيرة للتأكيد',
  SEND_CODE: 'إرسال رمز',
  CHANGE_EMAIL_TITLE: 'تغيير البريد الإلكتروني',
  CHANGE_EMAIL_DESCRIPTION: 'سنرسل رسالة للتحقق إلى عنوان البريد الإلكتروني الجديد لتأكيد هذا التغيير',
  CHANGE_EMAIL_SUCCESS: 'تم إرسال بريد إلكتروني للتحقق إلى البريد الإلكتروني الجديد',
  CHANGE_EMAIL_LABEL: 'بريد إلكتروني جديد',
  CHANGE_EMAIL_PLACEHOLDER: 'أدخل البريد الإلكتروني الجديد',
  CHANGE_EMAIL_BUTTON: 'إرسال البريد الإلكتروني للتحقق من الصحة',
  VERIFY_ACCOUNT_EMAIL_FIRST: 'يرجى التحقق من صحة البريد الإلكتروني للحساب أولًا',
  FILLED_INVALID_EMAIL: 'لقد أدخلت بريدًا إلكترونيًا غير صالح',
  WAITING_FOR_CONFIRMATION: 'انتظار التأكيد',
  RESEND_VERIFICATION: 'إعادة إرسال بريد إلكتروني للتحقق من الصحة',
  CANCEL_CHANGES: 'إلغاء التغييرات',
  DELETE_CONTACT: 'حذف جهة اتصال',
  DELETE_CONTACT_DESCRIPTION: 'هل تريد بالتأكيد حذف جهة الاتصال هذه؟',
  CANCEL: 'إلغاء',
  ADD_NEW_CONTACT: 'إضافة جهة اتصال جديدة',
  ADD_ONE_MORE: 'إضافة جهة اتصال إضافية',
  ADD_CONTACT: 'إضافة جهة اتصال',
  SEARCH_CONTACT: 'البحث عن جهة اتصال',
  CONTACT_SAVED: 'تم حفظ التغييرات بنجاح',
  CONTACT_CREATED: 'تمت إضافة جهة اتصال بنجاح',
  CONTACT_DELETED: 'تم حذف جهة الاتصال بنجاح',
  CONTACTS_PLACEHOLDER_TEXT: 'لا تملك جهات اتصال بعد. يرجى إضافة جهة اتصال جديدة',
  CONTACTS_EMPTY_RESULTS: 'لم يتم العثور على جهات اتصال. يرجى محاولة تغيير طلبك',
  CONTACTS_OPEN: 'فتح',
  CONTACTS_RENAME: 'إعادة التسمية',
  CONTACTS_VIEW_HISTORY: 'عرض السجل',
  CONTACTS_MOVE_TO: 'الانتقال إلى',
  CONTACTS_DELETE: 'حذف',
  FULL_NAME_LABEL: 'الاسم الكامل',
  FULL_NAME_PLACEHOLDER: 'أدخل الاسم',
  EMAIL_PLACEHOLDER: 'أدخل البريد الإلكتروني',
  HIDE_BUTTON: 'إخفاء',
  MAIN_MENU_DASHBOARD_LINK: 'لوحة المعلومات',
  MAIN_MENU_CONTRACTS_LINK: 'العقود',
  SIDEBAR_EKYC_LINK: 'eKYC',
  MAIN_MENU_TEMPLATES_LINK: 'القوالب',
  SIDEBAR_EDIT_PROFILE_LINK: 'تحرير ملف التعريف',
  SIDEBAR_CHANGE_PASSWORD_LINK: 'تغيير كلمة المرور',
  SIDEBAR_SIGNATURE_INITIALS_LINK: 'التوقيع والحروف الأولى',
  SIDEBAR_CONTACTS_LINK: 'جهات الاتصال',
  SIDEBAR_DOCUMENT_BRANDING_LINK: 'إدراج علامة تجارية للمستندات',
  SIDEBAR_PAYMENT_LINK: 'السداد',
  SIDEBAR_SUBSCRIPTION_LINK: 'الاشتراك',
  TERMS_AND_CONDITIONS: 'الشروط والأحكام',
  SIDEBAR_PRIVACY_LINK: 'سياسة الخصوصية',
  SIDEBAR_CONTRACTS_ALL: 'جميع العقود',
  SIDEBAR_CONTRACTS_RECEIVED: 'تم الاستلام',
  SIDEBAR_CONTRACTS_SENT: 'تم الإرسال',
  SIDEBAR_CONTRACTS_DRAFTS: 'المسودات',
  SIDEBAR_CONTRACTS_DELETED: 'تم الحذف',
  SIDEBAR_API_INTEGRATION_LINK: 'تكامل واجهة برمجة التطبيقات',
  USER_MENU_MANAGE_PROFILE_LINK: 'إدارة ملف التعريف',
  USER_MENU_PREFERENCES_LINK: 'التفضيلات',
  USER_MENU_ANALYTICS_LINK: 'التحليلات',
  CHANGES_SAVED_SUCCESSFULLY: 'تم حفظ جميع التغييرات بنجاح',
  SAVE_CHANGES_BEFORE_LEAVING: 'هل تريد حفظ التغييرات قبل المغادرة؟',
  YES: 'نعم',
  NO: 'لا',
  NAME_NOTE: 'ملاحظة: يجب أن يكون اسمك هو نفسه الوارد في مستنداتك الرسمية (جواز السفر وغيره) لإتمام تحديد الهوية',
  UPGRADE: 'ترقية',
  SUPPORT: 'دعم',
  ENTER_YOUR_PASSWORD_TITLE: 'أدخل كلمة المرور',
  ENTER_YOUR_PASSWORD_DESCRIPTION: 'أدخل كلمة المرور لتغيير عنوان البريد الإلكتروني',
  ENTER: 'إدخال',
  SENT_NEW_VERIFICATION_TO_EMAIL: 'تم إرسال بريد إلكتروني جديد للتحقق من الصحة',
  NEW_EMAIL_VERIFIED_SUCCESSFULLY:
    'تم التحقق من صحة البريد الإلكتروني الجديد بنجاح. يُرجى استخدامه لتسجيل الدخول مرة أخرى',
  EXPIRED_NEW_EMAIL_VERIFICATION_LINK:
    'الرابط غير صالح أو منتهي الصلاحية. يرجى النقر فوق زر إعادة إرسال البريد الإلكتروني لطلب رابط تحقق من صحة آخر',
  ERROR: 'خطأ',
  RESEND_EMAIL: 'إعادة إرسال البريد الإلكتروني',
  CANCEL_CHANGES_DESCRIPTION: 'هل تريد إلغاء التغييرات والعودة إلى عنوان البريد الإلكتروني الحالي؟',
  CHANGING_EMAIL_CANCELED: 'تم إلغاء تغيير البريد الإلكتروني',
  NO_CONTACTS: 'لا تمتلك جهات اتصال بعد. يرجى إضافة جهة اتصال جديدة',
  PLEASE_ENTER_PASSWORD: 'يرجى إدخال كلمة المرور',
  ACCESS_TO_SIGNATURE_DESCRIPTION: 'للوصول إلى توقيعاتك, يرجى إدخال كلمة المرور أدناه',
  PASSWORD_INVALID_TRY_AGAIN: 'كلمة المرور غير صحيحة. يُرجى المحاولة مرة أخرى',
  ENTER_PASSWORD: 'إدخال كلمة المرور',
  CONFIRM_ACCOUNT: 'يرجى إدخال كلمة المرور\nأولًا لتأكيد الحساب',
  INVALID_CODE_OR_NUMBER: 'رمز غير صالح',
  REACHED_MAX_NUMBER_OF_SIGNATURES:
    'لقد وصلت إلى العدد الأقصى من التوقيعات. لإنشاء توقيع جديد, يرجى حذف أحد التوقيعات القائمة',
  ADD_SIGNATURE: 'إضافة توقيع',
  ADD_ONE_MORE_SIGNATURE: 'يمكنك إضافة توقيع آخر',
  CLICK_TO_SEE_SIGNATURE: 'انقر لرؤية التوقيع',
  CLICK_TO_SEE_INITIALS: 'انقر لرؤية الحروف الأولية',
  SHOW_SIGNATURE: 'عرض التوقيع',
  SHOW_INITIALS: 'عرض الحروف الأولية',
  CLICK_UNBLUR: 'انقر لإلغاء تمويه التوقيع',
  NO_SIGNATURES: 'لا تملك توقيعات بعد. يرجى إضافة توقيع أدناه.',
  LIMIT_SIGNATURES: 'يمكنك إضافة حتى توقيعين',
  EDIT_PROFILE: 'تحرير ملف التعريف',
  CHANGE_PASSWORD: 'تغيير كلمة المرور',
  CONTACTS: 'جهات الاتصال',
  SIGNATURE_AND_INITIALS: 'التوقيع والحروف الأولى',
  CREATE_SIGNATURE: 'إنشاء توقيع',
  APPLY: 'تقديم',
  CLEAR: 'مسح',
  PREVIOUS: 'السابق',
  NEXT: 'التالي',
  CLEAR_ALL: 'مسح الكل',
  MORE: 'المزيد',
  NOTIFICATIONS: 'الإشعارات',
  NOTIFICATIONS_SETTINGS: 'إعدادات الإشعارات',
  EDIT: 'تحرير',
  DELETE: 'حذف',
  CREATE_SIGNATURE_DESCRIPTION:
    'ارسم توقيعك الجديد في المناطق المشار إليها. بمجرد الانتهاء, اضغط على زر قبول لحفظ توقيعاتك. بعد الحفظ, اضغط على زر إرسال لإنشاء توقيع آخر',
  CREATE_DYNAMIC_SIGNATURE_DESCRIPTION_1: 'ارسم توقيعك الجديد أدناه. وتحتاج إلى إدخاله',
  CREATE_DYNAMIC_SIGNATURE_DESCRIPTION_2: '  مرة/مرات حتى يمكننا معرفة طريقة رسمك له',
  CREATE_STATIC_SIGNATURE_DESCRIPTION: 'ارسم الآن الحروف الأولى من اسمك أدناه. ويكفي أن تدخله مرة واحدة',
  SIGNATURE: 'التوقيع',
  INITIALS: 'الحروف الأولى',
  DRAW_SIGNATURE: 'ارسم التوقيع هنا',
  DRAW_SIGNATURE_MANY_TIMES: 'ارسم توقيعك الجديد. وتحتاج إلى إدخاله 5 مرات حتى يمكننا معرفة طريقة رسمك له',
  DRAW_SIGNATURE_HERE: 'ارسم توقيعًا هنا',
  DRAW_INITIALS_HERE: 'ارسم الحروف الأولى هنا',
  DRAW_INITIALS: 'ارسم الآن الحروف الأولى من اسمك',
  DRAW_INITIALS_ONLY_ONES: 'ارسم الآن الحروف الأولى من اسمك. ويكفي أن تدخله مرة واحدة',
  SUBMIT: 'إرسال',
  CHANGE: 'تغيير',
  ADD_INITIALS: 'إضافة الحروف الأولى',
  PREVIEW: 'معاينة',
  ENTER_YOUR_PASSWORD_TO_CONTINUE: 'أدخل كلمة المرور للمتابعة',
  SIGNATURE_ADDED: 'تمت إضافة التوقيع بنجاح',
  SIGNATURE_DELETE_CONFIRMATION: 'هلي تريد بالتأكيد حذف هذا التوقيع؟ لا يمكن التراجع عن هذا الإجراء',
  SIGNATURE_DELETED: 'تم حذف التوقيع بنجاح',
  ADD_NEW_SIGNATURE: 'إضافة توقيع جديد',
  ADD_NEW_SIGNATURE_DESCRIPTION:
    'يمكن إنشاء التوقيعات الذكية من خلال تطبيق "عقود" على الهاتف الجوال. نزّل التطبيق باستخدام الروابط الواردة أدناه',
  PASSWORD_INCORRECT_AGAIN: 'كلمة المرور غير صالحة. حاول مرة أخرى',
  EXPIRED_VERIFICATION_LINK: 'الرابط غير صالح أو منتهي الصلاحية',
  NAME: 'الاسم',
  EDIT_CONTACT: 'تحرير جهة الاتصال',
  UPLOAD_FILE: 'تحميل ملف',
  DROP_FILE_HERE: 'اسحب الملف وأفلته هنا',
  OR: 'أو',
  BROWSE_FILES: 'استعراض الملفات',
  UPLOAD_CONTACT_FORMATS: 'قد تكون الملفات المتاحة للتحميل من أحد التنسيقات التالية: word, pdf, jpg, png',
  UPLOAD_CONTACT_SIZE: 'ويجب ألا تتجاوز مساحة الملفات 100 ميغابايت',
  FILE_MAX_SIZE: 'يجب ألا تتجاوز مساحة الملفات 100 ميغابايت',
  FILE_WRONG_FORMAT: 'قد تكون الملفات المتاحة للتحميل من أحد التنسيقات التالية: word, pdf, jpg, png',
  UPLOAD_TO: 'تحميل إلى',
  FOLDER_LABEL: 'تحديد مجلد',
  CREATE_NEW_FOLDER: 'إنشاء مجلد جديد',
  UPLOAD: 'تحميل',
  CREATE_FOLDER: 'إنشاء مجلد',
  CREATE: 'إنشاء',
  FOLDER_NAME_LABEL: 'اسم المجلد',
  FOLDER_NAME_PLACEHOLDER: 'إدخال اسم المجلد',
  INCORRECT_FILE_NAME: 'اسم الملف غير صحيح',
  INCORRECT_FOLDER_NAME: 'اسم المجلد غير صحيح',
  ADD_NEW_CONTRACT: 'إضافة عقد جديد',
  ADD_FOLDER: 'إضافة مجلد',
  CONTRACTS_PLACEHOLDER_TEXT: 'لا تملك عقودًا بعد. يرجى إضافة عقد جديد أدناه',
  CONTRACTS_EMPTY_RESULTS: 'لم يتم العثور على العقد. يُرجى المحاولة مرة أخرى',
  ADD_CONTRACT: 'إضافة عقد',
  UPLOAD_FROM_COMPUTER: 'تحميل',
  UPLOAD_FROM_TEMPLATE: 'تحميل من القالب',
  UPLOAD_FROM_GOOGLE: 'Google Drive',
  UPLOAD_FROM_ONE_DRIVE: 'One Drive',
  MORE_TIMES: 'مرات أكثر',
  MORE_DYNAMIC_SIGNATURES: 'تم تصنيف بعض التوقيعات التي أدخلتها باعتبارها غير صالحة. يرجى رسم توقيعك مرة أخرى',
  DYNAMIC_SIGNATURES_INVALID: 'اتساق غير كافٍ ما بين التوقيعات',
  FOLDER_EXISTS_ERROR: 'هذا المجلد موجود بالفعل',
  DOCUMENT_UPLOADED_SUCCESS: 'تم تحميل مستند جديد بنجاح!',
  CONTRACT_ASSIGN_TO_SELF: 'تعيين لنفسي',
  CONTRACT_SAVE_AS_TEMPLATE: 'الحفظ كقالب',
  CONTRACT_DELETE_AND_CLOSE: 'حذف وإغلاق',
  CONTRACT_SAVE_AND_CLOSE: 'حفظ وإغلاق',
  PROCEED_TO_CLM: 'المتابعة إلى CLM',
  CONTRACT_START: 'بدء العقد',
  SELECT_SIGNATURE_SEQUENCE: 'يرجى تحديد تسلسل التوقيع',
  SEQUENTIAL_SIGNING: 'توقيع متسلسل',
  SEPARATELY_SIGNING: 'توقيع منفصل',
  ADD_RECIPIENTS_TITLE: 'إدارة دورة العقد',
  RECIPIENTS: 'المستلمون',
  VIEW_AND_SEND: 'للعرض والإرسال',
  SAVE_AND_CLOSE: 'حفظ وإغلاق',
  VIEW_DOCUMENT: 'عرض المستند',
  RECIPIENT_NAME_LABEL: 'اسم المستلم',
  RECIPIENT_NAME_PLACEHOLDER: 'أدخل الاسم',
  RECIPIENT_EMAIL_LABEL: 'البريد الإلكتروني للمستلم',
  RECIPIENT_EMAIL_PLACEHOLDER: 'أدخل البريد الإلكتروني',
  ADD_RECIPIENT: 'إضافة مستلم',
  ADD_RECIPIENTS: 'إضافة مستلمين',
  DOCUMENT_WITH_NAME_EXIST: 'يوجد مستند بهذا الاسم بالفعل',
  DOCUMENT_NAME_INVALID: 'اسم المستند غير صحيح',
  SIGNATURE_MODAL_FINISHED: 'انتهى التوقيع. هل هذا التوقيع صحيح؟',
  SIGNATURE_INITIALS_MODAL_FINISHED: 'انتهى التوقيع. هل الحروف الأولى صحيحة؟',
  SIGNATURE_MODAL_YES: 'نعم, إنه صحيح',
  SIGNATURE_MODAL_NO: 'لا, كرر التوقيع',
  CHOOSE_CONTACT: 'اختيار جهة اتصال',
  SEARCH: 'بحث',
  SEND_CONTRACT: 'إرسال عقد',
  SENDER: 'المرسل',
  HEAD_OF_PRODUCT: 'رئيس الإنتاج',
  VIEW: 'عرض',
  INITIAL: 'التوقيع بالحرف الأول',
  SIGN: 'توقيع',
  APPROVAL_CYCLE: 'دورة الموافقة',
  APPROVAL_CYCLES: 'دورات الموافقة',
  COMPLETED: 'اكتمل',
  CONTRACT_DEADLINE: 'الموعد النهائي للعقد',
  FINAL_SLA: 'اتفاقية مستوى الخدمة النهائية',
  MESSAGE_TO_RECIPIENTS: 'رسالة إلى المستلمين',
  TITLE: 'العنوان',
  MESSAGE: 'الرسالة',
  CUSTOM_SUBSCRIPTION_REQUEST_MESSAGE_SENT:
    'شكرًا لك! سيرد عليك مديرنا في فترة قصيرة عبر البريد الإلكتروني المحدد في حسابك',
  CONTACT_US_TITLE: 'أخبرنا بطلباتك. وسيتصل بك مديرنا عبر البريد الإلكتروني',
  BACK_TO_ADD_RECIPIENTS: 'رجوع',
  DELETE_RECIPIENT: 'حذف مستلم',
  ARE_SURE: 'هل أنت متأكد من أن',
  SHOULD_BE_DELETED: 'يجب حذفه من قائمة مستلم العقد؟',
  DELETE_RECIPIENT_DESCRIPTION: 'هل تريد بالتأكيد حذف المستلم؟',
  DELETE_CONTRACT_DESCRIPTION: 'هل تريد بالتأكيد حذف جهة الاتصال هذه؟',
  SHOW_INFO: 'عرض المعلومات الكاملة',
  HIDE_INFO: 'إخفاء المعلومات الكاملة',
  DELETE_OWN_CONTRACT_DESCRIPTION: 'هل تريد بالتأكيد حذف هذا العقد؟, سيتم إيقاف تدفق التوقيع لجميع المستخدمين',
  DELETE_DRAFT_CONTRACT_DESCRIPTION: 'هل تريد بالتأكيد حذف هذا العقد؟',
  DELETE_FOLDER_DESCRIPTION: 'هل تريد بالتأكيد حذف هذا المجلد؟',
  RENAME_FILE: 'إعادة تسمية الملف',
  RENAME_FOLDER: 'إعادة تسمية المجلد',
  RENAME: 'إعادة التسمية',
  DOCUMENT_NAME_PLACEHOLDER: 'أدخل الاسم',
  MOVE_TO_FOLDER: 'الانتقال إلى المجلد',
  MOVE: 'نقل',
  SELECT_FOLDER_LABEL: 'تحديد مجلد',
  DUPLICATE_FILE: 'ملف مكرر',
  CONTRACT_DUPLICATE_FILE_DESCRIPTION: 'يرجى إعادة تسمية هذا العقد قبل نسخه',
  DUPLICATE: 'نسخ',
  SELECT_FOLDER_ERROR: 'يرجى تحديد مجلد',
  CHOOSE_FOLDER: 'اختيار مجلد',
  CONTRACT_EXISTS: 'يوجد عقد بهذا الاسم بالفعل',
  DEPARTMENT_LABEL: 'القسم',
  DEPARTMENT_PLACEHOLDER: 'إدخال قسم',
  OPTIONAL: 'اختياري',
  PHONE_NUMBER: 'رقم الهاتف',
  ADD_ANOTHER: 'إضافة آخر',
  CHOOSE_ACTION: 'اختيار إجراء',
  CYCLE_ORDER: 'طلب الدورة',
  CHANGE_ORDER: 'طلب التغيير',
  DEADLINE_SLA_LABEL: 'الموعد النهائي لاتفاقية مستوى الخدمة',
  DEADLINE_SLA_PLACEHOLDER: 'تحديد التاريخ',
  SIGNATURE_FIELD: 'حقل التوقيع',
  INITIALS_FIELD: 'حقل الحروف الأولى',
  PAGE: 'صفحة',
  LAST_PAGE: 'الصفحة الأخيرة',
  EVERY_PAGE: 'كل صفحة',
  AUTHENTICATION: 'مصادقة',
  EDIT_RECIPIENT: 'تحرير المستلم',
  DOCUMENT_EXTENSIONS_INVALID: 'قد تكون الملفات المتاحة للتحميل من أحد التنسيقات التالية: word, pdf, jpg, png',
  RECIPIENTS_EMAIL_FROM_CONTACT: 'يمكنك تغيير البريد الإلكتروني في صفحة جهات الاتصال',
  RECIPIENTS_DEPARTMENT_FROM_CONTACT: 'يمكنك تغيير القسم في صفحة جهات الاتصال',
  RECIPIENTS_PHONE_FROM_CONTACT: 'يمكنك تغيير رقم الهاتف في صفحة جهات الاتصال',
  RECIPIENTS_MAXIMUM_REACHED: 'لقد أضفت العدد الأقصى من المستلمين',
  RECIPIENTS_EMAIL_CONTACT: 'يمكنك تغيير البريد الإلكتروني في صفحة جهات الاتصال',
  RECIPIENTS_MAX_SIGNATURES_EXCEEDED: 'يمكن لأربعة مستلمين كحدٍ أقصى التوقيع على المستند',
  RECIPIENTS_MAX_INITIALS_EXCEEDED: 'يمكن لعشرة مستلمين كحدٍ أقصى التوقيع بالحروف الأولى على المستند',
  RECIPIENTS_EMAIL_NOT_UNIQUE: 'هناك خطأ. فلا يمكنك استخدام البريد الإلكتروني ذاته عدة مرات في أمر دورة واحدة',
  INVALID_CYCLE_ORDER: 'يرجى التأكد من صحة طلب الدورة المُدخل',
  INCORRECT_CYCLE_ORDER: 'يرجى التأكد من صحة طلب الدورة المُدخل',
  RECIPIENT_EMAIL_EXIST: 'يوجد مستلم بهذا البريد الإلكتروني للتو',
  CYCLE_ORDER_MIN: 'الحد الأدنى هو 1',
  CYCLE_ORDER_MAX: 'الحد الأقصى هو 50',
  CYCLE_ORDER_REQUIRED: 'تعبئة الحقل مطلوبة',
  OPEN: 'فتح',
  VIEW_HISTORY: 'عرض السجل',
  HISTORY: 'السجل',
  IP: 'عنوان IP',
  RESEND: 'إعادة إرسال',
  VIEW_INFO: 'عرض معلومات',
  VOID: 'ملغي',
  FILTERS: 'عوامل التصفية',
  RESET_ALL: 'إعادة تعيين الكل',
  STATUS: 'الحالة',
  PENDING_SIGN: 'توقيع معلق',
  EXPIRING_SOON: 'تنتهي الصلاحية قريبًا',
  SIGNED: 'موقّع',
  WAITING_FOR_SIGN: 'بانتظار التوقيع',
  REQUIRED_TO_VIEW: 'مطلوب للعرض',
  DATE_ADDED: 'تاريخ الإضافة',
  FROM: 'من',
  TO: 'إلى',
  CHOOSE: 'اختيار',
  PROGRESS: 'التقدم',
  CREATED_BY: 'إنشاء / بواسطة',
  UPDATED_BY: 'تحديث / بواسطة',
  SENT: 'تم الإرسال',
  COMMENTED: 'تم التعليق',
  VOIDED: 'ملغي',
  DELETED: 'تم الحذف',
  EXPIRED: 'انتهت مدة الصلاحية',
  DATE_CREATED: 'تاريخ الإنشاء',
  RESET: 'إعادة التعيين',
  DRAFT: 'مسودة',
  CREATED_BY_SPACE: 'إنشاء بواسطة',
  CONTRACTS_SEARCH_INFO: 'تبحث بين جميع المستندات',
  LAST_MODIFIED_BY: 'آخر تعديل بواسطة',
  CREATED: 'تم الإنشاء',
  UPDATED: 'تم التحديث',
  CONTRACT_DELETED: 'تم حذف العقد بنجاح',
  FOLDER_DELETED: 'تم حذف المجلد بجميع العقود بنجاح',
  NAME_CHANGED: 'تم تغيير الاسم بنجاح',
  CONTRACT_REUPLOAD: 'إعادة تحميل المستند',
  DOCUMENT_REUPLOAD: 'إعادة تحميل المستند',
  CONTRACT_FINISH_LATER: 'الإنهاء لاحقًا',
  SIGNATURE_BOX_TEXT: 'التوقيع هنا',
  ME: 'أنا',
  CONTRACT_MOVED_TO_FOLDER: 'تم نقل العقد إلى مجلد آخر بنجاح',
  NO_CONTRACTS: 'لا تملك عقودًا بعد',
  CONTRACT_DUPLICATED: 'تم نسخ العقد بنجاح',
  SIGNERS: 'الموقّعون',
  BACK: 'رجوع',
  MODIFIED: 'تم التعديل',
  EMPTY_FOLDER: 'المجلد فارغ',
  FOLDER_ALREADY_EXIST: 'هذا المجلد موجود بالفعل',
  CHOOSE_INITIALS: 'اختر حروفك الأولى',
  CHOOSE_SIGNATURE: 'اختر توقيعك',
  SELECT_DOCUMENT_INITIALS: 'حدد حروفك الأولى المقرر استخدامها في هذا المستند.',
  SELECT_DOCUMENT_SIGNATURE: 'حدد توقيعك المقرر استخدامه في هذا المستند.',
  SELECTION_APPLIED_NOTE: 'يرجى العلم أن الحروف الأولى هذه ستنطبق على جميع المستندات',
  MANAGE_SIGNATURE: 'يمكنك إدارة توقيعاتك في قسم "إدارة ملف التعريف"',
  FOLDER_UPLOADED: 'تمت إضافة مجلد جديد بنجاح',
  COMMENT: 'تعليق',
  COMMENTS: 'التعليقات',
  CROSSOVER: 'تحويل',
  NO_SIGNATURE_IN_ACCOUNT: 'لا تملك أي توقيعات في حسابك',
  NEW_TO_CREATE_SIGNATURE: 'تحتاج إلى إنشاء توقيع للمتابعة',
  ADD_SIGNATURES: 'إضافة توقيعات',
  SEND_CONTRACT_TITLE: 'هل سترسل العقد؟',
  SEND_CONTRACT_DESCRIPTION: 'يرجى التأكد من وضع جميع التوقيعات والحروف الأولى بصورة صحيحة في جميع الصفحات',
  SEND_CONTRACT_DESCRIPTION_MOBILE: 'يرجى التأكد من اتحاذ جميع الإجراءات اللازمة قبل إرسال العقد',
  SEND: 'إرسال',
  CONTRACT_SENT_SUCCESSFULLY: 'تم إرسال العقد بنجاح إلى المستلمين',
  DECLINE_TO_SIGN: 'رفض التوقيع',
  DOWNLOAD: 'تنزيل',
  FINISH_CONTRACT_LATER: 'هل ستنهي إجراءات العقد في وقت لاحق؟ لن يتم حفظ التغييرات',
  FINISH_CONTRACT_ACTIONS_LATER: 'هل ستنهي إجراءات العقد في وقت لاحق؟',
  CHANGES_WILL_NOT_BE_SAVED: 'لن يتم حفظ التغييرات',
  NOT_SIGNED_YET: 'لم يتم توقيع العقد بعد, يرجى إكمال الإجراء المطلوب حتى تتمكن من الإرسال',
  NOT_INITIALED_YET: 'لم يتم توقيع العقد بالحروف الأولى بعد, يرجى إكمال الإجراء المطلوب حتى تتمكن من الإرسال',
  SEND_CONTRACT_TO_RECIPIENTS: 'هل سترسل عقدًا إلى مستلمين آخرين؟',
  ADD_SIGNATURE_HERE: 'أضف توقيعك هنا',
  NO_DATA: 'لا توجد بيانات',
  PENDING_RECIPIENTS: 'مستلمون معلقون',
  READY_TO_SIGN: 'جاهز للتوقيع',
  VERIFICATION_FAILED: 'فشل التحقق',
  CONTRACT_READY_TO_SEND: 'اكتملت كل الإجراءات! عقدك جاهز للإرسال',
  DECLINE_SIGNING_TITLE: 'هل تريد بالتأكيد رفض هذا العقد؟',
  DECLINE_REASON_LABEL: 'سبب الرفض',
  DECLINE_REASON_PLACEHOLDER: 'إضافة تعليق',
  DECLINE: 'رفض',
  EXPLAIN_DECLINE: 'يرجى توضيح السبب وراء رغبتك في الرفض',
  CONTRACT_DECLINED: 'تم رفض العقد بنجاح',
  DECLINED: 'تم الرفض',
  SIGNATURE_CHANGED: 'تم تغيير التوقيع بنجاح',
  INITIALS_CHANGED: 'تم تغيير الحروف الأولى بنجاح',
  CYCLE_ORDER_HELP: 'طلب إتاحة العقد لهذا المستلم من الدورة بكليتها',
  DEADLINE_SLA_HELP: 'تاريخ الموعد النهائي المحدد لاتخاذ المستلم إجراء فيما يتعلق بالعقد',
  EMPTY_RECIPIENTS: 'لا يمكن أن يكون العقد لغرض "المشاهدة" فقط',
  GRID: 'عرض الشبكة',
  DISPLAY_NAMES: 'عرض الأسماء',
  GROUP_EDIT: 'تحرير المجموعة',
  APPLY_TO_ALL_PAGES: 'تنطبق على جميع الصفحات',
  UNDO: 'تراجع',
  REDO: 'إعادة',
  PLACEMENT_RESET_CONFIRMATION: 'هل تريد بالتأكيد إعادة تعيين التوقيعات ووضع الحروف الأولى داخل العقد الحالي؟',
  HELP: 'تعليمات',
  DOCUMENT_NOT_READY_FOR_DOWNLOAD: 'نجهز ملفًا لك. وقد يستغرق الأمر بعض الوقت, لذلك يرجى الانتظار',
  SOCKET_CONNECT_ERROR: 'خطأ في توصيل المقبس',
  PREPARING_FILE: 'نجهز ملفًا لك',
  WAIT_PREPARING_FILE: 'قد يستغرق الأمر بعض الوقت, لذلك يرجى الانتظار',
  STOP_SIGNING: 'هل ترغب في إيقاف توقيع عقدك؟',
  DELETE_CORRECTIONS: 'هل ترغب في حذف جميع التصحيحات من المستند ومتابعة توقيع الإصدار الأولي',
  SIGNING_STOPPED: 'تم إيقاف توقيع هذا العقد مؤقتًا',
  EDIT_FLOW: 'تحرير التدفق',
  REQUEST_TO_VIEW: 'لقد تم طلب مشاهدة هذا العقد',
  SIGNING_FINISHED: 'اكتمل توقيع العقد بنجاح',
  SIDEBAR_TEMPLATES_ALL: 'جميع القوالب',
  SIDEBAR_TEMPLATES_STARRED: 'معلّمة بنجمة',
  SIDEBAR_TEMPLATES_DOCUMENTS: 'مع مستندات',
  SIDEBAR_TEMPLATES_PROCEDURES: 'الإجراءات',
  SIDEBAR_TEMPLATES_DELETED: 'تم الحذف',
  REUPLOAD: 'إعادة تحميل',
  REUPLOAD_DOCUMENT: 'إعادة تحميل ملف',
  REUPLOAD_DOCUMENT_CONFIRMATION:
    'قد يتسبب هذا الإجراء في تغييرات في الوضع الحالي للخانات في عقدك. يرجى العلم أن استبدال المستند لا يمكن التراجع عنه',
  TEMPLATES_EMPTY_RESULTS: 'لم يتم العثور على القالب. يُرجى المحاولة مرة أخرى',
  CLM: 'إدارة دورة العقد',
  DOCUMENT: 'مستند',
  DOCUMENT_CLM: 'المستند+إدارة دورة العقد',
  CREATE_TEMPLATE: 'إنشاء قالب',
  TYPE: 'النوع',
  UPLOAD_DOCUMENT: 'تحميل مستند',
  CLM_WITHOUT_DOCUMENT: 'إدارة دورة العقد بدون مستند',
  LOAD_EXISTING_CONTRACT: 'التحميل من عقد قائم',
  MARK_AS_STARRED: 'إضافة علامة النجمة',
  UNMARK_AS_STARRED: 'إلغاء علامة النجمة',
  NO_TEMPLATES: 'لا تملك قوالب بعد',
  TEMPLATE_EXISTS: 'يوجد قالب بهذا الاسم بالفعل',
  TEMPLATE_DELETED: 'تم حذف القالب بنجاح',
  TEMPLATE_FOLDER_DELETED: 'تم حذف المجلد بجميع القوالب بنجاح',
  DELETE_TEMPLATE_DESCRIPTION: 'هل تريد بالتأكيد حذف هذا القالب؟, لا يمكن التراجع عن هذا الإجراء',
  DELETE_TEMPLATE_FOLDER_DESCRIPTION:
    'هل تريد بالتأكيد حذف هذا المجلد؟ سيؤدي هذا إلى حذف جميع القوالب بداخله بدون تراجع',
  TEMPLATE_MOVED_TO_FOLDER: 'تم النقل إلى مجلد آخر بنجاح',
  TEMPLATE_DUPLICATE_FILE_DESCRIPTION: 'يرجى إعادة تسمية هذا القالب قبل نسخه',
  TEMPLATE_DUPLICATED: 'تم نسخ القالب بنجاح',
  UPLOAD_TEMPLATE: 'تحميل مستند للقالب',
  DELETED_BY_OWNER: 'حذف المالك العقد',
  START_EKYC: 'ابدأ بتعبئة معلومات عن عميلك',
  EKYC_WARNING_TEXT: 'يرجى التأكد من السماح بالوصول إلى الكاميرا الخاصة بك',
  DETECTING_FACE: 'كشف الوجه',
  PLACE_YOUR_FACE: 'يرجى إبقاء وجهك داخل الشكل البيضاوي/nحتى الخطوة التالية من النتائج',
  SUCCESSFUL_REGISTRATION_COMPLETED: 'اكتمل التسجيل/nبنجاح!',
  REGISTRATION_IS_FAILED: 'فشل التسجيل!',
  SUCCESSFUL_VERIFICATION: 'اكتمل التحقق من صحة "eمعلومات العميل" بنجاح!',
  VERIFICATION_IS_FAILED: 'فشل التحقق من صحة معلومات العميل',
  ACCEPT_LEGAL_NOTICE: 'هل تقبل الإشعار القانوني؟ ',
  TAP_TO_SEE_SIGNATURE: 'اضغط لرؤية التوقيع',
  TAP_TO_SEE_INITIALS: 'اضغط لرؤية الحروف الأولى',
  RECIPIENTS_WITHOUT_EMAIL_AND_NAME: 'المتابعة بدون اسم وبريد إلكتروني',
  TEMPLATES_CLM_NOT_SPECIFIED: 'المستلمون وأمر التوقيع غير محددين',
  TEMPLATES_ADD_CLM: 'إضافة إدارة دورة العقود',
  TEMPLATES_SAVE_AND_CLOSE: 'حفظ وإغلاق',
  TEMPLATES_DELETE_AND_CLOSE: 'حذف وإغلاق',
  TEMPLATES_DISCARD_CONFIRMATION: 'هل تريد تجاهل التغييرات في القالب؟',
  TEMPLATES_UPLOAD_DOCUMENT_FOR_CLM_ONLY: 'تحميل مستند للقالب',
  TEMPLATES_CLM_ONLY_UPLOAD_DOCUMENT_DISCLAIMER: 'يرجى العلم أنه لا يمكنك حذف مستند من القالب, لكن يمكنك إعادة تحميله.',
  TEMPLATES_CREATE_CLM_MODAL: 'إنشاء قالب إدارة دورة العقود',
  TEMPLATES_SUCCESSFUL_CREATION: 'تم إنشاء القالب بنجاح',
  CREATE_TEMPLATE_FROM_CONTRACT: 'إنشاء قالب من عقد قائم',
  SELECT_CONTRACT: 'تحديد عقد',
  TEMPLATE_NAME: 'اسم القالب',
  SELECT_CONTRACT_FOR_TEMPLATE: 'يرجى اختيار عقد لإنشاء قالب',
  DISCARD: 'تجاهل',
  NO_NAME: 'بدون اسم',
  SAVE_WITHOUT_SLM: 'هل تريد حفظ هذا القالب بدون تدفق التوقيع؟',
  SIDEBAR_PREFERENCES_NOTIFICATIONS: 'الإشعارات',
  SIDEBAR_PREFERENCES_REGIONAL_SETTINGS: 'الإعدادات الإقليمية',
  SIDEBAR_PREFERENCES_CLOUD_STORAGE: 'التخزين السحابي',
  REGIONAL_SETTINGS_TITLE: 'إعدادات التاريخ والوقت واللغة',
  DATA_AND_TIME_FORMAT: 'تنسيق الوقت والتاريخ',
  CONTRACT_NOTIFICATIONS: 'إشعارات العقد',
  REMINDERS: 'رسائل التذكير',
  CHASERS: 'مطاردون',
  UPGRADE_REQUIRED: 'مطلوب ترقية الاشتراك',
  RECIPIENTS_WRONG_DEADLINES:
    'لا يمكن أن يكون الموعد النهائي لاتفاقية مستوى الخدمة للمستلم قبل المواعيد النهائية لاتفاقية مستوى الخدمة في الدورة السابقة',
  CONTRACT_FOLDER_DELETED: 'تم حذف المجلد بجميع العقود بنجاح',
  ANDROID_PERMISSION_CAMERA_TITLE: 'إذن لاستخدام الكاميرا',
  ANDROID_PERMISSION_CAMERA_MESSAGE: 'نحتاج إلى إذنك لاستخدام الكاميرا',
  ID_CHECK: 'التحقق من المعرّف',
  SELFIE: 'سيلفي',
  EKYC: 'eKYC',
  PLACE_YOUR_ID:
    'يرجى الاحتفاظ بالمعرّف داخل الخانة حتى إتمام الخطوة التالية لالتقاط صورة الوجه وتغيير الخانة إلى الشكل البيضاوي',
  DETECTING_ID: 'الكشف عن معرّف المستند',
  SENDING_IMAGE_TO_SERVER: 'إرسال صورة إلى الخادم',
  TRY_AGAIN: 'حاول مرة أخرى',
  PLEASE_TRY_AGAIN: 'يرجى المحاولة مرة أخرى. يرجى محاولة تحسين الإضاءة وتصحيح وضع المستند ووجهك على الصور',
  PLEASE_TRY_VERIFICATION_AGAIN: 'يرجى المحاولة مرة أخرى. يرجى محاول تحسين الإضاءة وتصحيح وضع المستند ووجهك على الصورة',
  EKYC_VERIFICATION_CONFIRMED: 'تم تأكيد التحقق من الصحة\nبنجاح',
  EKYC_REGISTRATION_FAILED: 'فشل تسجيل eمعلومات العميل!',
  EKYC_CONTACT_SUPPORT: 'لقد انتهت صلاحية كل محاولاتك.\nنقترح الاتصال بالدعم أو مرسل العقد',
  CONTACT_SUPPORT: 'الاتصال بالدعم',
  DELETE_AND_CLOSE: 'حذف وإغلاق',
  CANCEL_CREATING_CONTRACT: 'هل تريد إلغاء إنشاء عقد من قالب؟',
  CREATE_NEW_CONTRACT: 'إنشاء عقد جديد',
  SELECT_TEMPLATE_FOR_CONTRACT: 'يرجى تحديد قالب لإنشاء عقد',
  CREATE_CONTRACT_FROM_TEMPLATE: 'إنشاء عقد من قالب',
  SELECT_TEMPLATE: 'تحديد قالب',
  CREATING_CONTRACT_ABORTED: 'تم إحباط إنشاء عقد بنجاح',
  NO_DOCUMENT_NOTIFICATION: 'لا يحتوي القالب المحدد على أي مستند. ويجب عليك تحميله لإنشاء عقد',
  ASK_CANCEL_CHANGES: 'هل بالفعل ترغب في إلغاء التغييرات؟',
  FOLDER_CONTAINS_RECEIVED_CONTRACT: 'يحتوي هذا المجلد على بعض العناصر التي يتعذر حذفها الآن. يرجى فتح مجلد لإدارتها',
  EKYC_INSTRUCTIONS_FACE_TITLE: 'تعليمات كشف الوجه',
  EKYC_INSTRUCTIONS_FACE_SUBTITLE: 'ستلتقط صورة لوجهك. فيما يلي بعض النصائح التي يجب اتباعها في أفضل الظروف.',
  EKYC_INSTRUCTIONS_FACE_TEXT1: '1. امسك هاتفك بثبات أثناء التقاط الصورة',
  EKYC_INSTRUCTIONS_FACE_TEXT2: '2. أبعد شعرك عن وجهك وانزع نظارتك',
  EKYC_INSTRUCTIONS_FACE_TEXT3: '3. تحقق من ظروف الإضاءة (درجة سطوع متوسطة)',
  EKYC_INSTRUCTIONS_FACE_TEXT4: '4. يجب أن يغطي وجهك المساحة البيضاوية بالكامل',
  EKYC_INSTRUCTIONS_ID_TITLE: 'تعليمات كشف بطاقة المعرّف',
  EKYC_INSTRUCTIONS_ID_SUBTITLE: 'ستلتقط صورة لبطاقة المعرّف. فيما يلي بعض النصائح التي يجب اتباعها في أفضل الظروف.',
  EKYC_INSTRUCTIONS_ID_TEXT1: '1. امسك هاتفك بثبات أثناء التقاط الصورة',
  EKYC_INSTRUCTIONS_ID_TEXT2: '2. تحقق من ظروف الإضاءة (درجة سطوع متوسطة)',
  EKYC_INSTRUCTIONS_ID_TEXT3: '3. يجب أن تغطي بطاقة المعرّف المساحة البيضاوية بالكامل',
  SUBSCRIPTION_PLANS: 'خطط الاشتراك',
  YEAR: 'سنة',
  MO: 'شهر',
  FEATURE_CONTRACTS_COUNT: 'عقود',
  FEATURE_CONTRACTS_UNLIMITED: 'عقود غير محدودة',
  FEATURE_USER_TEMPLATES: 'قوالب المستخدم',
  FEATURE_UNLIMITED_BULK_SENDING: 'إرسال جماعي غير محدود',
  FEATURE_DASHBOARD: 'لوحة المعلومات',
  FEATURE_CLM: 'إدارة دورة العقد',
  FEATURE_EKYC: 'eKYC',
  FEATURE_SIGNATURE_MATCHING: 'مطابقة التوقيع',
  FEATURE_API_INTEGRATION: 'تكامل واجهة برمجة التطبيقات',
  DO_NOT_LIKE_PLAN: 'ألا تعجبك أي من الخطط المعروضة؟ اتصل بنا لمناقشة بعض الخيارات المخصصة',
  CONTACT_US: 'اتصل بنا',
  YOUR_PLAN: 'خطتك',
  EXPIRATION_DATE: 'تاريخ انتهاء الصلاحية',
  CONTRACTS: 'العقود',
  CHANGE_PLAN: 'تغيير الخطة',
  UNSUBSCRIBE: 'إلغاء الاشتراك',
  SUBSCRIPTION_IS_EXPIRED: 'انتهت صلاحية اشتراكك',
  VERIFICATION_CONFIRMED: 'تم تأكيد التحقق من الصحة بنجاح',
  GO_TO_SETTINGS: 'الانتقال إلى الإعدادات',
  TEXT_FIELD: 'حقل نصي',
  TEXT_FIELD_PLACEHOLDER:
    'اضبط عرض هذا الحقل بحيث يكون طويلًا بما يكفي لاستيعاب النص. وستدخل نصك هنا عند إنشاء عقد من هذا القالب',
  DATE_FIELD: 'حقل التاريخ',
  DATE_FIELD_PLACEHOLDER: 'يوم. شهر. سنة',
  TEMPLATES_NO_ACCESS: 'لا تملك حق الوصول إلى ميزات قوالب المستخدم. قم بترقية اشتراكك الآن للوصول إليها',
  API_INTEGRATION_NO_ACCESS:
    'لا تملك حق الوصول إلى ميزات تكامل واجهة برمجة التطبيقات. قم بترقية اشتراكك الآن للوصول إليها',
  UPGRADE_YOUR_PLAN: 'قم بترقية خطة الاشتراك',
  EKYC_NO_ACCESS: 'لا تملك حق الوصول لاستخدام ميزات (eمعلومات العميل). قم بترقية اشتراكك الآن للحصول عليها',
  USE_MOBILE_EKYC: 'يرجى استخدام تطبيق الجوال المراد التحقق منه مع (eمعلومات العميل)',
  CONTACT_US_MESSAGE: 'أخبرنا بطلباتك. وسيتصل بك مديرنا عبر البريد الإلكتروني',
  INVALID_MESSAGE: 'رسالة غير صالحة',
  SIZE: 'الحجم',
  SEQUENTIAL_SIGNING_NO_ACCESS: 'لا يمكنك استخدام التوقيع المتسلسل للعقد. قم بترقية اشتراكك الآن للوصول إليه',
  CONTACT_US_SUCCESS: 'شكرًا لك! سيرد عليك مديرنا في غضون فترة قصيرة عبر البريد الإلكتروني المحدد في حسابك',
  TEMPLATES_CUSTOM_FIELDS_PROMPT: 'يرجى تعبئة جميع الحقول في المستند ببيانات مناسبة لإنشاء عقد',
  TEMPLATES_DATE_FIELD_INCORRECT: 'تاريخ غير صحيح',
  TEMPLATES_INCORRECT_FIELDS: 'يرجى تعبئة جميع الحقول المطلوبة بصورة صحيحة لإنشاء عقد',
  TEMPLATES_FIELDS_FILLED: 'لقد عبأت جميع الحقول الإضافية. والآن نحن جاهزون لإنشاء عقد',
  FLIP_THE_PHONE: 'ضع الهاتف في وضع أفقي للتوقيع. تأكد من أن الوضع الأفقي مفتوح لهاتفك',
  HOME: 'الرئيسية',
  PAGE_NOT_FOUND: 'تعذر إيجاد الصفحة',
  CONNECT: 'اتصال',
  DISCONNECT: 'قطع الاتصال',
  CONNECT_CLOUD_STORAGE: 'توصيل التخزين السحابي',
  FAQ_HEADER_TITLE: 'مرحبًا! كيف يمكننا مساعدتك؟',
  FAQ_HEADER_SUB_TITLE: 'أو اختر الفئة',
  DOCUMENT_TITLE_FIELD_MAX: 'الحد الأقصى هو 100',
  DOCUMENT_MESSAGE_FIELD_MAX: 'الحد الأقصى هو 500',
  OTP_VERIFICATION_TITLE: 'التحقق من كلمة المرور لمرة واحدة',
  OTP_VERIFICATION_DESCRIPTION: 'تم إرسال رمز التحقق إلى رقم الهاتف, الذي حدده مالك العقد',
  OTP_VERIFICATION_CONTACT_OWNER:
    'إذا لم تستلم رمزًا في رسالة نصية قصيرة بسبب تحديد رقم هاتف خاطئ - يرجى الاتصال بمالك العقد لتصحيحه',
  CODE_IS_CORRECT: 'الرمز صحيح',
  RECIPIENT_ATTEMPTS_FINISHED: 'لقد انتهيت من جميع محاولاتك. نقترح عليك الاتصال بالدعم أو بمرسل العقد',
  INVALID_OTP: 'رمز غير صحيح',
  LAST_ATTEMPT: 'أمامك محاولة أخيرة',
  NEW_CODE_IS_SENT: 'تم إرسال رمز جديد',
  PROCESSING_DOCUMENTS: 'تجهيز المستندات المقدمة‚Ä¶',
  MULTIPLE_PERSONS_NO_ACCESS: 'لا يمكنك إرسال مستندك إلا إلى شخص واحد. قم بترقية اشتراكك الآن لضم مستلمين متعددين',
  LOADING: 'جارِ التحميل...',
  YOU_HAVE: 'أمامك',
  ATTEMPTS: 'محاولات',
  NETWORK_IS_BUSY: 'الشبكة مشغولة, يرجى المحاولة مرة أخرى لاحقًا',
  EXPIRED_CONTRACT_NOTIFICATION: 'لقد مر الموعد النهائي للعقد. يرجى الاتصال بمالك العقد لمزيدٍ من الإجراءات',
  USER_TERM_EXPIRED: 'انتهت مدة الصلاحية',
  EKYC_ACTIONS_STUB_DESCRIPTION:
    'يجب تأكيد إجراءاتك في هذا العقد باستخدام تطبيق الجوال. وبالتالي لن تكون قادرًا على المواصلة من إصدار الويب. يرجى التحويل إلى تطبيق الجوال ومواصلة إجراءاتك في هذا العقد من خلاله.',
  EKYC_STUB_TITLE: 'يجب عليك اجتياز عملية التحقق باستخدام تطبيق الجوال',
  EKYC_STUB_DESCRIPTION:
    'يرجى تثبيت التطبيق على هاتفك الذكي وافتح المستند مرة أخرى من البريد الإلكتروني على هاتفك الذكي',
  CONTRACT_UPDATED_SUCCESSFULLY: 'تم تحديث العقد بنجاح',
  KEEP_IN_ON_PLACE: 'هل تريد الاحتفاظ بعقودك الموقعة في مكان واحد؟ بادر بالتسجيل والانضمام إلى منصة عقود',
  CREATE_ONLY_SIGNATURE_DESCRIPTION:
    'ارسم توقيعك الجديد في المناطق المشار إليها. بمجرد الانتهاء, اضغط على زر قبول لحفظ توقيعاتك. بعد الحفظ, اضغط على زر "إرسال" لإنشاء توقيع آخر',
  CREATE_ONLY_INITIALS_DESCRIPTION:
    'ارسم حروفك الأولى الجديدة في المناطق المشار إليها. بمجرد الانتهاء, اضغط على زر قبول لحفظ حروفك الأولى. بعد الحفظ, اضغط على زر إرسال لإنشاء حروف أولى أخرى',
  CREATE_NEW_SIGNATURE: 'إنشاء توقيع جديد',
  CREATE_NEW_INITIALS: 'إنشاء حروف أولى جديدة',
  SEE_PROGRESS: 'عاين التقدم',
  RECIPIENT_INFO: 'معلومات المستلم',
  ACTION: 'الإجراء',
  CANT_START_CYCLE: 'لا يمكنك بدء دورة بدون مستلمين. يرجى إضافة مستلمين',
  EXIT_APP: 'الخروج من التطبيق',
  DO_YOU_WANNA_EXIT: 'هل تريد الخروج من التطبيق؟',
  CONTRACT_VERIFY_FAILED: 'فشلتَ أثناء التحقق من العقد',
  CONTACT_SENDER: 'يرجى الاتصال بالدعم أو مرسل العقد',
  SAVE_RECIPIENT_FOR_RESEND_CONFIRMATION: 'هل تريد حفظ بيانات المستلمين؟ يرجى العلم أنها لم تُرسل بعد',
  RECIPIENTS_CYCLE_ORDER_LESS_THAN_ORIGINAL: 'لا يمكن أن يكون حجم طلبات المُتلقين أقل من الحالي',
  RECIPIENT_DELETE_CONFIRMATION_CONTRACT_RESEND:
    'أترغب في حذف هذا المستلم من العقد؟ يرجى العلم أن هذا من شأنه التأثير على تدفق العقد',
  NOT_SENT: 'لم يتم الإرسال',
  RECEIVED: 'تم الاستلام',
  COMPLETE: 'إكمال',
  RESEND_CONTRACT: 'إعادة إرسال العقد',
  RESEND_CONTRACT_MODAL_HEADER_TEXT:
    'سيحصل مستلمو العقد على التحديثات التالية. تحقق من بياناتهم قبل الإرسال. لن يكون أي تحرير إضافي متاحًا دون إيقاف التدفق',
  RESEND_CONTRACT_MODAL_FOOTER_TEXT:
    'هل ترغب في تطبيق التغييرات على المستلمين؟ يرجى العلم أن التقدم المحرز في التوقيع الحالي لن يضيع',
  RECIPIENTS_WITH_EXPIRED_DEADLINE:
    'خطأ! لديك مستخدم بموعد نهائي منتهي الصلاحية. عيّن له موعدًا نهائيًا جديدًا للمتابعة',
  CONTRACT_RESENT: 'تمت إعادة إرسال العقد بنجاح إلى المستلمين',
  CORRECTION_INPUT_PLACEHOLDER: 'إضافة نص هنا',
  CONTRACT_UPDATED_SUCCESSFULLY_UNREGISTERED: 'تم تحديث العقد بنجاح',
  COMMENT_FIELD_PLACEHOLDER: 'إضافة تعليق',
  NOTIFICATION_CONTRACT_PENDING: 'إجراء العقد ملعق',
  NOTIFICATION_CONTRACT_SIGNED: 'تم توقيع/ مراجعة/ التوقيع بالحروف الأولى على العقد',
  NOTIFICATION_CONTRACT_DECLINED: 'تم رفض العقد',
  NOTIFICATION_CONTRACT_CHANGED: 'تم طلب إدخال تغييرات على العقد',
  NOTIFICATION_CONTRACT_COMMENTED: 'تم التعليق على العقد',
  NOTIFICATION_CONTRACT_MENTIONED: 'ورد ذكر اسمك في التعليقات',
  NOTIFICATION_CONTRACT_SENT: 'تم إرسال العقد',
  NOTIFICATION_CONTRACT_UPDATED: 'تم تحديث العقد',
  NOTIFICATION_CONTRACT_DELETED: 'تم حذف العقد',
  NOTIFICATION_CONTRACT_COMPLETED: 'اكتمل توقيع العقد',
  NOTIFICATION_CONTRACT_RECIPIENTS_UPDATED: 'تم تحديث قائمة مستلمي العقد',
  NOTIFICATION_CONTRACT_VOIDED: 'تم إبطال العقد',
  NOTIFICATION_CONTRACT_CORRECTIONS_REMOVED: 'حذف المالك جميع التصحيحات وواصل تدفق التوقيع',
  NOTIFICATION_REMINDER_BEFORE_AND_ON_DAY: 'قبل يوم واحد وفي الموعد النهائي للعقد',
  NOTIFICATION_CHASERS_DEADLINE_MISSED: 'بعد ثلاثة أيام من تفويت الموعد النهائي للعقد',
  IN_APP: 'داخل التطبيق',
  EMAIL: 'البريد الإلكتروني',
  PUSH: 'دفع',
  COMMENT_UNSAVED_WARNING: 'لقد تركت تعليقًا غير محفوظ. للمتابعة احذفه أو احفظه (لإرساله إلى مستلمين آخرين)',
  CONTRACT_ANNOTATION_SAVED: 'تم حفظ التغييرات بنجاح',
  COMMENT_REPLY: 'رد',
  CLEAR_ALL_NOTIFICATIONS: 'هل ترغب بالتأكيد في مسح جميع الإشعارات؟',
  DEADLINE_IS_EXPIRED: 'انتهت صلاحية الموعد النهائي',
  STRIKE_UNSAVED_WARNING: 'لقد تركت تحويلًا غير محفوظ. للمتابعة احذفه أو احفظه (لإرساله إلى مستلمين آخرين)',
  CORRECTION_UNSAVED_WARNING: 'لقد تركت تصحيحًا غير محفوظ. للمتابعة احذفه أو احفظه (لإرساله إلى مستلمين آخرين)',
  DAY: 'يوم',
  DAYS: 'أيام',
  WE_SUGGEST_SUPPORT: 'نقترح عليك الاتصال بالدعم وبمرسل العقد',
  SAVE_CONTRACT_WITH_FIELDS:
    'هل ترغب في حفظ البيانات المدخلة في العقد؟ تأكد من تعبئة جميع الحقول بصورة صحيحة, حيث لا يمكنك تغييرها لاحقًا في العقد',
  RENAMED: 'تمت إعادة التسمية',
  REUPLOADED: 'تمت إعادة التحميل',
  CROSSED_OVER: 'تم التحويل',
  LEFT_CORRECTION: 'ترك التصحيح',
  ACCEPTED: 'مقبول',
  REJECTED: 'مرفوض',
  CANCELLED_ACCEPTATION: 'تم إلغاء القبول',
  CANCELLED_REJECTION: 'تم إلغاء الرفض',
  CLEARED_CORRECTIONS: 'تم مسح التصحيحات',
  DOWNLOADED: 'تم التنزيل',
  RESENT: 'تمت إعادة الإرسال',
  ACCEPT: 'قبول',
  REJECT: 'رفض',
  ACCEPTED_BY: 'مقبول بواسطة',
  REJECTED_BY: 'مرفوض بواسطة',
  CONFIRMATION_ATTENTION:
    'تأكد من أنه يقع على عاتقك اتخاذ قرار بشأن هذا البند. ولا يمكنك إلغاء الإجراء بعد التأكيد. متابعة؟',
  ACCEPTED_SUCCESSFULLY: 'تم القبول بنجاح',
  REJECTED_SUCCESSFULLY: 'تم الرفض بنجاح',
  CANCEL_ACCEPTATION_ATTENTION: 'هل ترغب في إلغاء القبول؟ سيتم مسحه وسيتمكن مستلمون آخرون من اتخاذ قرار مجددًا',
  CANCEL_REJECTION_ATTENTION: 'هل ترغب في إلغاء الرفض؟ سيتم مسحه وسيتمكن مستلمون آخرون من اتخاذ قرار مجددًا',
  CANCEL_ACCEPTATION_SUCCESSFULLY: 'تم مسح القبول بنجاح',
  CANCEL_REJECTION_SUCCESSFULLY: 'تم مسح الرفض بنجاح',
  UPLOAD_FROM: 'تحميل من',
  DEVICE: 'الجهاز',
  TEMPLATE: 'القالب',
  RECIPIENT: 'المستلم',
  ADD_ONE_MORE_RECIPIENT: 'إضافة مستلم آخر',
  RETURNED_WITH_CORRECTIONS: 'تمت الإعادة مع تصحيحات',
  OWNER: 'المالك',
  OPEN_THE_CONTRACT_AS: 'أنت ترغب في فتح العقد باسم:',
  ENTER_PASSWORD_TO_OPEN_DOCUMENT: 'أدخل كلمة المرور لفتح المستند',
  SIGN_UP_FOR: 'التسجيل في',
  PAYMENT: 'السداد',
  AMOUNT: 'المبلغ',
  ANNUAL_SUBSCRIPTION: 'الاشتراك السنوي',
  MONTHLY_SUBSCRIPTION: 'الاشتراك الشهري',
  SELECT_OPTION: 'تحديد خيار',
  WAITING_PAYMENT: 'انتظار اكتمال الدفعة',
  SELECT_ANOTHER_PLAN: 'هذه خطتك الحالية. يرجى تحديد خطة أخرى.',
  SAVE_CARD: 'حفظ البطاقة',
  CARD: 'البطاقة',
  HAS_BEEN_SUCCESSFULLY_SAVED: 'تم حفظها بنجاح',
  SUBSCRIPTION_UPDATED_SUCCESSFULLY: 'تم تحديث الاشتراك بنجاح',
  PAY: 'دفع',
  USE_ANOTHER_CARD: 'استخدم بطاقة أخرى',
  MONTH: 'شهر',
  MANY_CARDS_ERROR: 'لديك العديد من البطاقات الآن. يرجى حذف بعض منها وإضافة بطاقة مرة أخرى في قسم السداد.',
  FAILED_TO_ADD_CARD: 'فشلت إضافة بطاقة',
  SPECIFY_CARD: 'تحديد بطاقة',
  FILL_CARD_DETAILS: 'يرجى تعبئة تفاصيل البطاقة للمتابعة',
  SUCCESSFUL_PAYMENT: 'سداد ناجح',
  SHOW_ALL: 'إظهار الكل',
  CARD_SUCCESSFULLY_ADDED: 'تمت إضافة البطاقة بنجاح',
  START_FREE_TRIAL: 'يمكنك بدء تجربتك المجانية',
  FREE_TRIAL: 'تجربة مجانية',
  WANT_TO_LOG_OUT: 'هل ترغب في تسجيل الخروج؟',
  SELECT_SUBSCRIPTION: 'تحديد الاشتراك',
  START_FREE_TRIAL_BUTTON: 'بدء التجربة المجانية',
  SUBSCRIBE_NOW: 'اشترك الآن',
  SELECT_SUBSCRIPTION_TERM: 'يرجى تحديد مدة الاشتراك',
  MONTHLY: 'شهري',
  YEARLY: 'سنوي',
  PLAN: 'الخطة',
  FREE_TRIAL_CHARGE: 'لتأكيد بطاقتك, سنخصم من حسابك رسومًا قليلة ثم سنعيدها إلى حسابك.',
  PLAN_IS_CONNECTED: 'تم توصيل الخطة',
  ERROR_CHECK_URL: 'خطأ, يرجى التحقق من عنوان الرابط والمحاولة مجددًا',
  ERROR_FORBIDDEN: 'يرجى التحقق من عنوان الرابط والمحاولة مجددًا',
  OPENED: 'مفتوح',
  UPGRADED_SUBSCRIPTION: 'سيتم ترقية اشتراكك الآن ، لكن لن تتمكن من الرجوع إلى إصدار سابق حتى الدفعة التالية. موافق؟',
  DOWNGRADED_SUBSCRIPTION: 'سيتم تخفيض اشتراكك بعد انتهاء صلاحية الاشتراك الحالي. موافق؟',

  // TODO: DELETE AFTER hooks translation depends on keys instead of index.
  'Make sure decision on this item is your responsibility. You could not cancel the action after confirmation. Continue?':
    'تأكد من أنه يقع على عاتقك اتخاذ قرار بشأن هذا البند. ولا يمكنك إلغاء الإجراء بعد التأكيد. متابعة؟',
  Cancel: 'إلغاء',
}

export default translations
