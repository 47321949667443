export enum AddContactFields {
  fullName = 'fullName',
  email = 'email',
  addMore = 'addMore',
  department = 'department',
  phone = 'phone',
}

export interface AddContactForm {
  fullName: string
  email: string
  phone?: string
  department?: string
}

export interface AddContactsForm extends AddContactForm {
  addMore: boolean
}

export type AddContactsRequestBody = AddContactForm
