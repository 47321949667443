import React from 'react'
import styled from 'styled-components'

import { Size, SvgIconProps } from './SvgIcon.types'

const StyledSvg = styled.svg<{ size: Size }>`
  display: inline-block;
  width: 1em;
  height: 1em;
  flex-shrink: 0;
  color: ${({ theme, color }) => (color ? theme.palette[color] : 'inherit')};
  font-size: ${({ theme, size }) => theme.sizes.icon[size]};
  fill: currentColor;
  transition: fill 400ms;
  user-select: none;
`

export const SvgIcon: React.FC<SvgIconProps> = ({
  color,
  viewBox = '0 0 24 24',
  size = 'default',
  children,
  ...props
}) => (
  <StyledSvg focusable="false" viewBox={viewBox} color={color} size={size} {...props}>
    {children}
  </StyledSvg>
)
