import React from 'react'

export const SequenceSvg: React.FC = (props) => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M26.875 11.9531V10.0781C26.875 9.90625 26.7344 9.76562 26.5625 9.76562H11.5625C11.3906 9.76562 11.25 9.90625 11.25 10.0781V11.9531C11.25 12.125 11.3906 12.2656 11.5625 12.2656H26.5625C26.7344 12.2656 26.875 12.125 26.875 11.9531ZM11.5625 15.3906C11.3906 15.3906 11.25 15.5312 11.25 15.7031V17.5781C11.25 17.75 11.3906 17.8906 11.5625 17.8906H18.75C18.9219 17.8906 19.0625 17.75 19.0625 17.5781V15.7031C19.0625 15.5312 18.9219 15.3906 18.75 15.3906H11.5625ZM18.75 33.2812H8.125V5.78125H30V17.3437C30 17.5156 30.1406 17.6562 30.3125 17.6562H32.5C32.6719 17.6562 32.8125 17.5156 32.8125 17.3437V4.21875C32.8125 3.52734 32.2539 2.96875 31.5625 2.96875H6.5625C5.87109 2.96875 5.3125 3.52734 5.3125 4.21875V34.8437C5.3125 35.5352 5.87109 36.0937 6.5625 36.0937H18.75C18.9219 36.0937 19.0625 35.9531 19.0625 35.7812V33.5937C19.0625 33.4219 18.9219 33.2812 18.75 33.2812Z"
      fill="#6085B3"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.6389 24.7257C15.8009 26.4017 15.4167 28.4968 15.4167 30H12.9167C12.9167 28.1699 13.3658 25.6816 14.4028 23.6076C15.4313 21.5507 17.223 19.5833 20 19.5833C21.5013 19.5833 22.9383 20.4532 24.0726 21.4616C24.5349 21.8725 24.9784 22.334 25.3913 22.8261C26.2486 22.3656 27.2359 22.0833 28.3333 22.0833C30.5962 22.0833 32.1874 23.5132 33.1479 25.074C34.1044 26.6284 34.5833 28.5224 34.5833 30H32.0833C32.0833 28.9776 31.7289 27.5383 31.0188 26.3843C30.3126 25.2368 29.4038 24.5833 28.3333 24.5833C27.8061 24.5833 27.3064 24.6991 26.8477 24.9009C26.9127 25.0131 26.9757 25.1256 27.0366 25.238C27.7531 26.5606 28.2857 28.0808 28.1582 29.3918C28.0909 30.0842 27.8277 30.8052 27.2172 31.3422C26.6116 31.8749 25.8267 32.0833 25 32.0833C24.2912 32.0833 23.6289 31.9227 23.0683 31.5641C22.5019 31.2018 22.1175 30.687 21.8949 30.1145C21.4667 29.0136 21.6359 27.7316 22.0642 26.6303C22.3612 25.8664 22.8264 25.0847 23.4396 24.3897C23.113 24.0043 22.7668 23.6457 22.4117 23.3301C21.4367 22.4634 20.5821 22.0833 20 22.0833C18.6103 22.0833 17.4854 23.0327 16.6389 24.7257ZM24.9083 26.56C24.6984 26.8699 24.5251 27.1997 24.3942 27.5364C24.0933 28.31 24.1062 28.9031 24.2249 29.2084C24.2757 29.339 24.3405 29.4102 24.4154 29.458C24.4961 29.5096 24.6671 29.5833 25 29.5833C25.4233 29.5833 25.5499 29.4793 25.566 29.4651C25.5774 29.4551 25.6461 29.3946 25.6699 29.1499C25.7227 28.6069 25.4943 27.6787 24.9083 26.56Z"
      fill="#6085B3"
    />
  </svg>
)
