import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useDispatchUnmount } from '../../../hooks/useDispatchUnmount'
import { cleanDownload } from '../../../store/contracts/download/download.actions'
import {
  downloadErrorSelector,
  downloadLinkSelector,
  downloadFetchStatusSelector,
} from '../../../store/contracts/download/download.selectors'
import { openModal } from '../../../store/modals/modals.actions'
import { CONTRACT_MODALS } from '../../constants/contract'
import { useShowFetchError } from '../useShowFetchError'

const DOCUMENT_NOT_READY_FOR_DOWNLOAD = 'DOCUMENT_NOT_READY_FOR_DOWNLOAD'
const EXCLUDE_CODES = [DOCUMENT_NOT_READY_FOR_DOWNLOAD]

export const useDownloadContract = (contractId: string) => {
  const dispatch = useDispatch()
  const link = useSelector(downloadLinkSelector)
  const error = useSelector(downloadErrorSelector)
  const fetchStatus = useSelector(downloadFetchStatusSelector)

  useEffect(() => {
    if (link) {
      window.open(link, '_blank')
    }
  }, [link])
  useEffect(() => {
    if (error?.lastErrorCode === DOCUMENT_NOT_READY_FOR_DOWNLOAD) {
      dispatch(openModal(CONTRACT_MODALS.CONTRACT_DOWNLOAD_PROGRESS))
    }
  }, [error, contractId])
  useShowFetchError(fetchStatus, error, EXCLUDE_CODES)
  useDispatchUnmount(cleanDownload)
}
