import React, { useState, useRef, useMemo } from 'react'
import styled from 'styled-components'

import { useDidUpdate } from '../../../hooks/useDidUpdate'
import translations from '../../../translations/keys'

import { CodeInputProps } from './CodeInput.types'

const BACKSPACE_KEY = 8
const LEFT_ARROW_KEY = 37
const RIGHT_ARROW_KEY = 39
const NOT_NUMBER_REG_EXP = /[^0-9]/

const StyledWrapper = styled.div`
  height: 63px;
  position: relative;
`
const StyledInputWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`
const StyledInput = styled.input<{ error?: string }>`
  width: 40px;
  height: 40px;
  font-size: 18px;
  text-align: center;
  caret-color: transparent;
  border-color: ${(props) => (props.error ? props.theme.palette.errorColor : props.theme.palette.grey)};
  border-width: 1px;
  border-style: solid;
  border-radius: 4px;
  outline: none;
  box-sizing: border-box;
  appearance: none;

  &:focus,
  &:active {
    border-color: ${(props) => props.theme.palette.blueDark};
  }

  & ~ & {
    margin-left: 16px;
  }
`
const StyledError = styled.div`
  margin-top: 2px;
  color: ${(props) => props.theme.palette.errorColor};
`

export const CodeInput: React.FC<CodeInputProps> = ({
  length = 4,
  loading = false,
  error,
  name,
  onChange,
  onComplete,
  formikValue,
  disabled,
}) => {
  const initialCode = useMemo(() => [...Array(length)].map(() => ''), [length])
  const [code, setCode] = useState(initialCode)
  const inputs = useRef<(HTMLInputElement | null)[]>([])

  const focusNext = (idx: number) => {
    inputs.current[idx + 1]?.focus()
  }

  const focusPrev = (idx: number) => {
    inputs.current[idx - 1]?.focus()
  }

  const triggerChange = (
    e: React.ChangeEvent<HTMLInputElement> | React.KeyboardEvent<HTMLInputElement>,
    code: string[]
  ) => {
    Object.defineProperty(e, 'target', {
      writable: false,
      value: { value: code.join(''), name },
    })
    onChange(e)
  }

  const handleChange = (idx: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const prevValue = code[idx]
    const number = e.target.value
    const limitedToOneNumber = prevValue ? number.replace(prevValue, '') : number

    if (NOT_NUMBER_REG_EXP.test(number) || limitedToOneNumber.length > 1) {
      return
    }

    const newCode = [...code]
    newCode[idx] = limitedToOneNumber
    setCode(newCode)

    if (idx !== length - 1) {
      focusNext(idx)
    }
    if (newCode.every((num) => num !== '') && idx === length - 1) {
      onComplete && onComplete(newCode.join(''))
    }
    triggerChange(e, newCode)
  }

  const handleKeyUp = (idx: number) => (e: React.KeyboardEvent<HTMLInputElement>) => {
    // e.key has partial support on IE11, e.keyCode is used instead
    if (e.keyCode === BACKSPACE_KEY && idx !== 0) {
      const newCode = [...code]
      newCode[idx - 1] = ''
      setCode(newCode)
      triggerChange(e, newCode)
      focusPrev(idx)
    }
    if (e.keyCode === LEFT_ARROW_KEY && idx !== 0) {
      focusPrev(idx)
    }
    if (e.keyCode === RIGHT_ARROW_KEY && idx !== length - 1) {
      focusNext(idx)
    }
  }

  useDidUpdate(() => {
    if (formikValue === '') {
      setCode(initialCode)
    }
  }, [formikValue, initialCode])

  return (
    <StyledWrapper data-testid="codeinput-wrapper">
      <StyledInputWrapper data-testid="codeinput-input-wrapper">
        {code.map((num, idx) => (
          <StyledInput
            key={idx}
            type="text"
            inputMode="numeric"
            data-testid={`codeinput-input-${idx}`}
            ref={(ref) => inputs.current.push(ref)}
            value={num}
            autoFocus={!code[0].length && idx === 0}
            readOnly={loading}
            error={error}
            onChange={handleChange(idx)}
            onKeyUp={handleKeyUp(idx)}
            disabled={disabled}
          />
        ))}
      </StyledInputWrapper>
      {error && <StyledError data-testid="codeinput-error">{translations[error]}</StyledError>}
    </StyledWrapper>
  )
}
