import { FetchError } from '../../types/fetch'
import { Phones } from '../../types/phones'

import * as TYPES from './phones.types'

export const fetchPhones = (): TYPES.FetchPhonesAction => ({
  type: TYPES.PhonesActions.FETCH_PHONES,
})

export const fetchPhonesSuccess = (phones: Phones): TYPES.FetchPhonesSuccessAction => ({
  type: TYPES.PhonesActions.FETCH_PHONES_SUCCESS,
  payload: phones,
})

export const fetchPhonesFailure = (error: FetchError): TYPES.FetchPhonesFailureAction => ({
  type: TYPES.PhonesActions.FETCH_PHONES_FAILURE,
  payload: error,
})
