import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  cursor: default;
  width: 100%;
`
export const ButtonWrapper = styled.div`
  width: 49%;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.palette.white};
  background-color: ${({ theme }) => theme.palette.blueDark};
  border-radius: 4px;
`
