import {
  FetchBlockedNotificationsResponse,
  UpdateNotificationsBody,
  UserNotificationsValues,
  PreferencesNotifications,
  PreferencesNotificationsTypes,
} from '../../types/preferencesNotifications'

export const prepareNotifications = (
  blockedNotifications: FetchBlockedNotificationsResponse
): UserNotificationsValues =>
  Object.values(PreferencesNotifications).reduce(
    (notifications, notification) => ({
      ...notifications,
      [notification]: {
        [PreferencesNotificationsTypes.SOCKET]: !blockedNotifications[PreferencesNotificationsTypes.SOCKET].includes(
          notification
        ),
        [PreferencesNotificationsTypes.PUSH]: !blockedNotifications[PreferencesNotificationsTypes.PUSH].includes(
          notification
        ),
        [PreferencesNotificationsTypes.EMAIL]: !blockedNotifications[PreferencesNotificationsTypes.EMAIL].includes(
          notification
        ),
      },
    }),
    {} as UserNotificationsValues
  )

export const prepareUpdatingNotifications = (
  prevNotifications: UserNotificationsValues,
  { notifications }: UpdateNotificationsBody
): UserNotificationsValues =>
  notifications.reduce(
    (newNotifications, { notification, blocked, way }) => ({
      ...newNotifications,
      [notification]: {
        ...newNotifications[notification],
        [way]: !blocked,
      },
    }),
    { ...prevNotifications }
  )
