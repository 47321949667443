import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTheme } from 'styled-components'

import { isBannerVisibleSelector } from '../../../store/banner/banner.selectors'
import { OpenInAppBanner as Banner } from '../../ui/OpenInAppBanner/OpenInAppBanner'
import { useNavigateToMobileApp } from '../../hooks/useNavigateToMobileApp'
import useMediaQuery from '../../hooks/useMediaQuery'
import { StyledThemeScheme } from '../../../themes/light'
import { closeBanner, openBanner } from '../../../store/banner/banner.actions'

export const OpenInAppBanner: React.FC = ({ children }) => {
  const theme = useTheme() as StyledThemeScheme
  const dispatch = useDispatch()
  const isMobileScreen = useMediaQuery(theme.devices.mobile)
  const isBannerVisible = useSelector(isBannerVisibleSelector)
  const { handleNavigate } = useNavigateToMobileApp()

  const handleMenuClose = () => {
    dispatch(closeBanner())
  }

  useEffect(() => {
    if (isMobileScreen) {
      dispatch(openBanner())
    }
  }, [isMobileScreen])

  return (
    <Banner
      handleMenuClose={handleMenuClose}
      handleNavigate={handleNavigate}
      isMobileScreen={isMobileScreen}
      isBannerVisible={isBannerVisible}
    >
      {children}
    </Banner>
  )
}
