import { Action } from 'redux'

import { FetchFailurePayload, FETCH_STATUSES } from '../../../types/fetch'
import { SubscriptionPlan, IUnsubscribe } from '../../../types/subscription'

export interface PlansState {
  list: {
    fetchStatus: FETCH_STATUSES
    error: FetchFailurePayload | null
    data: SubscriptionPlan[] | null
  }
  plan: {
    fetchStatus: FETCH_STATUSES
    error: FetchFailurePayload | null
    data: SubscriptionPlan | null
  }
}

export enum PlansActions {
  'FETCH_SUBSCRIPTION_PLANS' = 'FETCH_SUBSCRIPTION_PLANS',
  'CLEAR_SUBSCRIPTION_PLANS' = 'CLEAR_SUBSCRIPTION_PLANS',
  'FETCH_SUBSCRIPTION_PLAN' = 'FETCH_SUBSCRIPTION_PLAN',
}

export type FetchPlans = Action
export interface FetchPlansSuccess extends Action {
  payload: { data: SubscriptionPlan[] }
}

export interface FetchPlan extends Action {
  payload: { subscriptionId: string }
}
export interface FetchPlanSuccess extends Action {
  payload: { data: SubscriptionPlan }
}


export interface Unsubscribe extends Action {
  payload: { subscriptionId: string }
}
export interface UnsubscribeSuccess extends Action {
  payload: { data: IUnsubscribe }
}
export interface UnsubscribeFailure extends Action {
  payload: { data: IUnsubscribe }
}