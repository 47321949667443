import React, { FC } from 'react'

import translations from '../../../../translations/keys'
import Modal from '../../Modal'
import AccessGuard from '../../AccessGuard'

import { StyledContent } from './VerificationPasswordModal.styles'
import { VerificationPasswordModalProps } from './VerificationPasswordModal.types'

export const VerificationPasswordModal: FC<VerificationPasswordModalProps> = ({
  onClose,
  onSubmit,
  onAccessed,
  access,
  isError,
  submitText,
  title,
}) => (
  <Modal onClose={onClose}>
    <StyledContent>
      <AccessGuard
        access={access}
        onSubmit={onSubmit}
        title={title || translations.ENTER_YOUR_PASSWORD_TO_CONTINUE}
        isError={isError}
        onAccessed={onAccessed}
        submitText={submitText}
      />
    </StyledContent>
  </Modal>
)
