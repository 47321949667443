import React from 'react'

import { SvgIconProps } from '../../ui/SvgIcon/SvgIcon.types'
import SvgIcon from '../../ui/SvgIcon'

export const FolderIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <path d="M20.625 6.99375H12.2109L9.46172 4.36406C9.42674 4.33132 9.38073 4.31291 9.33281 4.3125H3.375C2.96016 4.3125 2.625 4.64766 2.625 5.0625V18.9375C2.625 19.3523 2.96016 19.6875 3.375 19.6875H20.625C21.0398 19.6875 21.375 19.3523 21.375 18.9375V7.74375C21.375 7.32891 21.0398 6.99375 20.625 6.99375ZM19.6875 18H4.3125V6H8.73047L11.5336 8.68125H19.6875V18Z" />
  </SvgIcon>
)
