import React, { useState, useRef } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import Popover from '../../../../ui/Popover'
import { BUTTON_VARIANTS } from '../../../../ui/Button/Button.types'
import Button from '../../../../ui/Button'
import { KebabIcon } from '../../../../assets/icons'

import { PreviewMenuProps } from './PreviewMenu.types'

const StyledMenu = styled.ul`
  list-style: none;
`
const StyledMenuLink = styled(Button).attrs({ fullWidth: true, variant: BUTTON_VARIANTS.TEXT })`
  display: flex;
  justify-content: flex-start;
  padding: 8px 16px;
  color: ${({ theme }) => theme.palette.textColor};
  font-size: 16px;
  line-height: 1;
  transition: padding 300ms 200ms, background-color 200ms;

  &:hover {
    background-color: ${({ theme }) => theme.palette.blueExtraLight};
    text-decoration: none;
  }
`
const StyledIconWrapper = styled.div<{ disabled?: boolean }>`
  display: inline-flex;
  margin-right: 8px;

  ${({ disabled }) => disabled && 'opacity: 0.5'}
`

export const PreviewMenu: React.FC<PreviewMenuProps> = ({ actions, buttons }) => {
  const [visible, setVisible] = useState(false)
  const targetRef = useRef(null)
  const { t } = useTranslation()

  const handleDropdownOpen = () => {
    setVisible((prevValue) => !prevValue)
  }

  const handleClose = () => {
    setVisible(false)
  }

  const handleMenuClick = (onClick: () => void) => () => {
    setVisible(false)
    onClick()
  }

  const newButtons = [...buttons]

  return (
    <div>
      <Button fullWidth ref={targetRef} variant={BUTTON_VARIANTS.TEXT} onClick={handleDropdownOpen}>
        <KebabIcon color="grey" />
      </Button>
      <Popover
        width="208px"
        padding="2px 0"
        offsetTop={undefined}
        targetRef={targetRef}
        visible={visible}
        onClose={handleClose}
      >
        <StyledMenu>
          {[...newButtons.reverse(), ...actions].map((item) =>
            item ? (
              <li key={item.label}>
                <StyledMenuLink onClick={handleMenuClick(item.onClick)}>
                  <StyledIconWrapper>
                    <item.MobileIcon color="grey" />
                  </StyledIconWrapper>
                  {t(item.label)}
                </StyledMenuLink>
              </li>
            ) : null
          )}
        </StyledMenu>
      </Popover>
    </div>
  )
}
