import React from 'react'

import { SvgIconProps } from '../../ui/SvgIcon/SvgIcon.types'
import SvgIcon from '../../ui/SvgIcon'

export const CommentIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <path d="M3.75 3.83333C3.24374 3.83333 2.83333 4.24374 2.83333 4.75V19.5372L6.90819 16.8206C7.05877 16.7202 7.23569 16.6667 7.41667 16.6667H20.25C20.7563 16.6667 21.1667 16.2563 21.1667 15.75V4.75C21.1667 4.24374 20.7563 3.83333 20.25 3.83333H3.75ZM1 4.75C1 3.23122 2.23122 2 3.75 2H20.25C21.7688 2 23 3.23122 23 4.75V15.75C23 17.2688 21.7688 18.5 20.25 18.5H7.69421L2.42514 22.0127C2.14386 22.2002 1.78219 22.2177 1.48413 22.0582C1.18607 21.8987 1 21.5881 1 21.25V4.75Z" />
  </SvgIcon>
)
