import { FetchFailureAction, FETCH_STATUSES } from '../../../types/fetch'
import { createReducer } from '../../createReducer'
import { addCommentById, updateCommentById, removeCommentById } from '../../../utils/comment'

import * as TYPES from './comments.types'
import * as ACTIONS from './comments.actions'

type Actions =
  | TYPES.AddCommentAction
  | TYPES.SetCommentAction
  | TYPES.UnsetCommentAction
  | TYPES.ClearCommentsAction
  | TYPES.ClearThreadAction
  | TYPES.FetchCommentsSuccessAction
  | TYPES.FetchCommentsThreadSuccessAction
  | TYPES.FetchUpdateCommentsSuccessAction
  | FetchFailureAction

export const initialState: TYPES.CommentsState = {
  byId: {},
  byPages: {},
  thread: [],
  fetch: {
    status: FETCH_STATUSES.IDLE,
    error: null,
  },
  fetchThread: {
    status: FETCH_STATUSES.IDLE,
    error: null,
  },
  fetchCreate: {
    status: FETCH_STATUSES.IDLE,
    error: null,
  },
  update: {
    status: FETCH_STATUSES.IDLE,
    error: null,
  },
  confirm: {
    status: FETCH_STATUSES.IDLE,
    error: null,
    confirmStatus: null,
  },
  cancelConfirmation: {
    status: FETCH_STATUSES.IDLE,
    error: null,
    confirmStatus: null,
  },
}

export const comments = createReducer<TYPES.CommentsState, Actions>(initialState, {
  [TYPES.CommentsActions.ADD_COMMENT]: (state, action) => ({
    ...state,
    ...addCommentById((action as TYPES.AddCommentAction).payload.comment, state.byId),
  }),
  [TYPES.CommentsActions.SET_COMMENT]: (state, action) => ({
    ...state,
    byId: updateCommentById((action as TYPES.SetCommentAction).payload.comment, state.byId),
  }),
  [TYPES.CommentsActions.UNSET_COMMENT]: (state, action) => ({
    ...state,
    ...removeCommentById((action as TYPES.UnsetCommentAction).payload.id, state.byId),
  }),
  [TYPES.CommentsActions.CLEAR_COMMENTS]: () => ({
    ...initialState,
  }),
  [TYPES.CommentsActions.CLEAR_THREAD]: (state) => ({
    ...state,
    thread: [],
    fetchThread: {
      status: FETCH_STATUSES.IDLE,
      error: null,
    },
  }),

  [ACTIONS.fetchCommentsTypes.request]: (state) => ({
    ...state,
    fetch: {
      ...state.fetch,
      status: FETCH_STATUSES.REQUEST,
      error: null,
    },
  }),
  [ACTIONS.fetchCommentsTypes.success]: (state, action) => ({
    ...state,
    ...(action as TYPES.FetchCommentsSuccessAction).payload,
    fetch: {
      ...state.fetch,
      status: FETCH_STATUSES.SUCCESS,
      error: null,
    },
  }),
  [ACTIONS.fetchCommentsTypes.failure]: (state, action) => ({
    ...state,
    fetch: {
      ...state.fetch,
      status: FETCH_STATUSES.FAILURE,
      error: (action as FetchFailureAction).payload,
    },
  }),

  [ACTIONS.fetchCommentsThreadTypes.request]: (state) => ({
    ...state,
    fetchThread: {
      ...state.fetchThread,
      status: FETCH_STATUSES.REQUEST,
      error: null,
    },
  }),
  [ACTIONS.fetchCommentsThreadTypes.success]: (state, action) => ({
    ...state,
    thread: (action as TYPES.FetchCommentsThreadSuccessAction).payload.comments,
    fetchThread: {
      ...state.fetchThread,
      status: FETCH_STATUSES.SUCCESS,
      error: null,
    },
  }),
  [ACTIONS.fetchCommentsThreadTypes.failure]: (state, action) => ({
    ...state,
    fetchThread: {
      ...state.fetchThread,
      status: FETCH_STATUSES.FAILURE,
      error: (action as FetchFailureAction).payload,
    },
  }),

  [ACTIONS.fetchCreateCommentTypes.request]: (state) => ({
    ...state,
    fetchCreate: {
      ...state.fetchCreate,
      status: FETCH_STATUSES.REQUEST,
      error: null,
    },
  }),
  [ACTIONS.fetchCreateCommentTypes.success]: (state) => ({
    ...state,
    fetchCreate: {
      ...state.fetchCreate,
      status: FETCH_STATUSES.SUCCESS,
      error: null,
    },
  }),
  [ACTIONS.fetchCreateCommentTypes.failure]: (state, action) => ({
    ...state,
    fetchCreate: {
      ...state.fetchCreate,
      status: FETCH_STATUSES.FAILURE,
      error: (action as FetchFailureAction).payload,
    },
  }),

  [ACTIONS.fetchUpdateCommentsTypes.request]: (state) => ({
    ...state,
    update: {
      ...state.update,
      status: FETCH_STATUSES.REQUEST,
      error: null,
    },
  }),
  [ACTIONS.fetchUpdateCommentsTypes.success]: (state) => ({
    ...state,
    update: {
      ...state.update,
      status: FETCH_STATUSES.SUCCESS,
      error: null,
    },
  }),
  [ACTIONS.fetchUpdateCommentsTypes.failure]: (state, action) => ({
    ...state,
    update: {
      ...state.update,
      status: FETCH_STATUSES.FAILURE,
      error: (action as FetchFailureAction).payload,
    },
  }),
  [ACTIONS.fetchConfirmCommentsTypes.request]: (state) => ({
    ...state,
    confirm: {
      ...state.confirm,
      status: FETCH_STATUSES.REQUEST,
    },
  }),
  [ACTIONS.fetchConfirmCommentsTypes.success]: (state, action) => ({
    ...state,
    confirm: {
      ...state.confirm,
      status: FETCH_STATUSES.SUCCESS,
      confirmStatus: (action as TYPES.FetchConfirmCommentsSuccessAction).payload.confirmStatus,
    },
  }),
  [ACTIONS.fetchConfirmCommentsTypes.failure]: (state, action) => ({
    ...state,
    confirm: {
      ...state.confirm,
      status: FETCH_STATUSES.FAILURE,
      error: (action as FetchFailureAction).payload,
    },
  }),
  [ACTIONS.fetchCancelCommentsConfirmationTypes.request]: (state) => ({
    ...state,
    cancelConfirmation: {
      ...state.cancelConfirmation,
      status: FETCH_STATUSES.REQUEST,
    },
  }),
  [ACTIONS.fetchCancelCommentsConfirmationTypes.success]: (state, action) => ({
    ...state,
    cancelConfirmation: {
      ...state.cancelConfirmation,
      status: FETCH_STATUSES.SUCCESS,
      confirmStatus: (action as TYPES.FetchCancelCommentsConfirmationSuccessAction).payload.confirmStatus,
    },
  }),
  [ACTIONS.fetchCancelCommentsConfirmationTypes.failure]: (state, action) => ({
    ...state,
    cancelConfirmation: {
      ...state.cancelConfirmation,
      status: FETCH_STATUSES.FAILURE,
      error: (action as FetchFailureAction).payload,
    },
  }),
})
