import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Formik, FormikProps } from 'formik'
import { useTranslation } from 'react-i18next'

import translations from '../../../translations/keys'
import { DocumentMessageForm, DocumentMessageFields } from '../../../types/documents'
import { ContractRouteParams } from '../../types/signing'
import { RecipientActions, RecipientData } from '../../../types/recipients'
import { RECIPIENTS_DIAGRAM_MODALS } from '../../constants/recipientsDiagram'
import { documentMessageValidationSchema } from '../../../validation/documents/documentMessageValidationSchema'
import omit from '../../../utils/omit'
import { prepareRecipientForSubmit, validateRecipients } from '../../../utils/recipients'
import { location } from '../../../utils/location'
import { removeExtension } from '../../../utils/file/removeExtension'
import { useDispatchUnmount } from '../../../hooks/useDispatchUnmount'
import { useShowFetchError } from '../../hooks/useShowFetchError'
import { openModal } from '../../../store/modals/modals.actions'
import * as SELECTORS from '../../../store/contracts/recipients/recipients.selectors'
import * as ACTIONS from '../../../store/contracts/recipients/recipients.actions'
import {
  clearPlacement,
  fetchPlacement,
  setShouldRegenerate,
} from '../../../store/contracts/placement/placement.actions'
import { clearContractMessage } from '../../../store/contracts/message/message.actions'
import { COMMON_MODALS_IDS } from '../../ui/Modals/CommonModals/CommonModals.constants'
import { NOTIFICATION_MODAL_TYPES } from '../../ui/Modals/NotificationModal/NotificationModal.constants'
import FullScreenTemplate from '../../components/Main/FullScreenTemplate'
import { PreviewHeader } from '../../components/PreviewHeader/PreviewHeader'
import Typography from '../../ui/Typography'
import { TYPOGRAPHY_NAMES } from '../../ui/Typography/Typography.types'
import FormTextArea from '../../ui/Forms/FormTextArea'
import FormTextInput from '../../ui/Forms/FormTextInput'
import Box from '../../ui/Box'
import { RecipientsDiagram } from '../../ui/RecipientsDiagram'

import { useActions } from './hooks/useActions'
import { useButtons } from './hooks/useButtons'
import { Modals } from './components/Modals'

export const ContractRecipientsDiagram = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const formRef: React.Ref<FormikProps<DocumentMessageForm>> = useRef(null)
  const { contractId } = useParams<ContractRouteParams>()
  const contractName = useSelector(SELECTORS.recipientsContractNameSelector)
  const sender = useSelector(SELECTORS.recipientsSenderSelector)
  const byId = useSelector(SELECTORS.recipientsByIdSelector)
  const byOrder = useSelector(SELECTORS.recipientsByOrderSelector)
  const sorted = useSelector(SELECTORS.recipientsSortedSelector)
  const order = useSelector(SELECTORS.recipientsOrderSelector)
  const fetchUpdate = useSelector(SELECTORS.recipientsFetchUpdateSelector)
  const fetchDelete = useSelector(SELECTORS.recipientsFetchDeleteSelector)
  const messageInitialValues = useSelector(SELECTORS.recipientsMessageInitialValuesSelector)
  const actions = useActions()
  const { buttons, handleSubmit } = useButtons(formRef)
  const recipients = { byId, byOrder, sorted }

  const handleEdit = (id: string) => {
    const recipient = byId[id]
    dispatch(openModal(RECIPIENTS_DIAGRAM_MODALS.EDIT_DIAGRAM_RECIPIENT, { recipient, order }))
  }

  const handleUpdate = (values: RecipientData) => {
    const { id } = values

    if (id) {
      const recipient = byId[id]
      const nextRecipients = { ...omit([id], byId), [id]: values }
      const errorCode = validateRecipients(Object.values(nextRecipients))

      // TODO: remove placement regeneration when API will handle this by itself
      if (
        values.action !== RecipientActions.VIEW &&
        (values.action !== recipient.action || values.signatureType !== recipient.signatureType)
      ) {
        dispatch(setShouldRegenerate(true))
      }

      if (errorCode) {
        dispatch(
          openModal(COMMON_MODALS_IDS.NOTIFICATION, {
            type: NOTIFICATION_MODAL_TYPES.WARNING,
            description: translations[errorCode],
          })
        )
      } else {
        dispatch(ACTIONS.fetchUpdateRecipient(id, prepareRecipientForSubmit(order)(values)))
      }
    }
  }

  const handleConfirmDelete = (id: string) => {
    dispatch(openModal(RECIPIENTS_DIAGRAM_MODALS.DELETE_DIAGRAM_RECIPIENT, { id }))
  }

  const handleDelete = (id: string) => {
    dispatch(ACTIONS.fetchDeleteRecipient(id))
  }

  useEffect(() => {
    dispatch(ACTIONS.setContractId(contractId))
    dispatch(ACTIONS.fetchContractInfo(contractId))
    dispatch(ACTIONS.fetchSenderInfo())
    dispatch(ACTIONS.fetchRecipients(contractId))
  }, [])

  // TODO: remove when API is ready
  useEffect(() => {
    if (sorted.length > 0) {
      dispatch(fetchPlacement(contractId, byId))
    }
  }, [sorted])

  useShowFetchError(fetchUpdate.status, fetchUpdate.error)
  useShowFetchError(fetchDelete.status, fetchDelete.error)

  useDispatchUnmount(ACTIONS.clearRecipients, clearPlacement, clearContractMessage)

  return (
    <FullScreenTemplate
      headerContent={<PreviewHeader actions={actions} buttons={buttons} title={removeExtension(contractName || '')} />}
      backButtonText={t(translations.BACK_TO_ADD_RECIPIENTS)}
      backPath={location.contractsAddRecipientsUrl(contractId)}
    >
      <Box mr={4} ml={4} mb={4} mbTablet={0}>
        <Box display="flex" alignItems="center" mb={5}>
          <Typography name={TYPOGRAPHY_NAMES.H1}>{t(translations.ADD_RECIPIENTS_TITLE)}</Typography>
        </Box>
        <RecipientsDiagram
          sender={sender}
          recipients={recipients}
          order={order}
          onEdit={handleEdit}
          onDelete={handleConfirmDelete}
        />
      </Box>
      {messageInitialValues && (
        <Formik
          innerRef={formRef}
          initialValues={messageInitialValues}
          validationSchema={documentMessageValidationSchema}
          onSubmit={handleSubmit}
        >
          <Box mt={4.5} mr={23.5} ml={23.5} mb={13.5} mrTablet={0} mlTablet={0} mbTablet={0}>
            <Typography name={TYPOGRAPHY_NAMES.H3}>{t(translations.MESSAGE_TO_RECIPIENTS)}</Typography>
            <Box mt={3}>
              <FormTextInput name={DocumentMessageFields.title} label={t(translations.TITLE)} />
              <FormTextArea name={DocumentMessageFields.message} label={t(translations.MESSAGE)} height={224} />
            </Box>
          </Box>
        </Formik>
      )}
      <Modals onUpdate={handleUpdate} onDelete={handleDelete} />
    </FullScreenTemplate>
  )
}
