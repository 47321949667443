import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { CONTRACTS_MODALS } from '../../../../../constants/contracts'
import { openModal } from '../../../../../store/modals/modals.actions'
import translations from '../../../../../translations/keys'
import UploadDocumentModal from '../../DocumentModals/UploadDocumentModal'

import { UploadContractModalProps } from './UploadContractModal.types'

export const UploadContractModal: React.FC<UploadContractModalProps> = ({ onClose }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const handleUploadContractSuccess = (fileName: string, file: File) => {
    dispatch(openModal(CONTRACTS_MODALS.UPLOAD_CONTRACT_TO, { fileName, file }))
  }

  return (
    <UploadDocumentModal
      onClose={onClose}
      onSuccess={handleUploadContractSuccess}
      title={t(translations.UPLOAD_FILE)}
    />
  )
}
