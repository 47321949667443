import { combineEpics, Epic, ofType } from 'redux-observable'
import { catchError, map, mergeMap, takeUntil } from 'rxjs/operators'

import { catchFetchError } from '../../../utils/catchFetchError'
import { contractsService } from '../../../api'

import * as ACTIONS from './message.actions'

export const fetchSaveContractMessage: Epic = (action$) =>
  action$.pipe(
    ofType(ACTIONS.fetchSaveContractMessageTypes.request),
    mergeMap(({ payload }) =>
      contractsService.fetchSaveContractMessage(payload.contractId, payload.body).pipe(
        map(() => ACTIONS.fetchSaveContractMessageSuccess()),
        catchError(catchFetchError(ACTIONS.fetchSaveContractMessageFailure)),
        takeUntil(
          action$.pipe(
            ofType(ACTIONS.fetchSaveContractMessageTypes.success, ACTIONS.fetchSaveContractMessageTypes.failure)
          )
        )
      )
    )
  )

export const messageEpics = combineEpics(fetchSaveContractMessage)
