import { FetchFailureAction, FETCH_STATUSES } from '../../../types/fetch'
import { createReducer } from '../../createReducer'
import { addStrikeById, updateStrikeById, removeStrikeById } from '../../../utils/strikethrough'

import * as TYPES from './strikes.types'
import * as ACTIONS from './strikes.actions'

type Actions =
  | TYPES.AddStrikeAction
  | TYPES.SetStrikeAction
  | TYPES.UnsetStrikeAction
  | TYPES.ClearStrikesAction
  | TYPES.FetchStrikesSuccessAction
  | TYPES.FetchCreateStrikeSuccessAction
  | FetchFailureAction
  | TYPES.FetchConfirmStrikeSuccessAction
  | TYPES.FetchCancelStrikeConfirmationSuccessAction

export const initialState: TYPES.StrikesState = {
  byId: {},
  byPages: {},
  fetch: {
    status: FETCH_STATUSES.IDLE,
    error: null,
  },
  fetchCreate: {
    status: FETCH_STATUSES.IDLE,
    error: null,
  },
  confirm: {
    status: FETCH_STATUSES.IDLE,
    error: null,
    confirmStatus: null,
  },
  cancelConfirmation: {
    status: FETCH_STATUSES.IDLE,
    error: null,
    confirmStatus: null,
  },
}

export const strikes = createReducer<TYPES.StrikesState, Actions>(initialState, {
  [TYPES.StrikesActions.ADD_STRIKE]: (state, action) => {
    const { strike, currentUserId } = (action as TYPES.AddStrikeAction).payload
    return {
      ...state,
      ...addStrikeById({ strike, strikesById: state.byId, currentUserId }),
    }
  },
  [TYPES.StrikesActions.SET_STRIKE]: (state, action) => ({
    ...state,
    byId: updateStrikeById((action as TYPES.SetStrikeAction).payload.strike, state.byId),
  }),
  [TYPES.StrikesActions.UNSET_STRIKE]: (state, action) => {
    const { id, currentUserId } = (action as TYPES.UnsetStrikeAction).payload
    return {
      ...state,
      ...removeStrikeById({ id, strikesById: state.byId, currentUserId }),
    }
  },
  [TYPES.StrikesActions.CLEAR_STRIKES]: () => ({
    ...initialState,
  }),

  [ACTIONS.fetchStrikesTypes.request]: (state) => ({
    ...state,
    fetch: {
      ...state.fetch,
      status: FETCH_STATUSES.REQUEST,
      error: null,
    },
  }),
  [ACTIONS.fetchStrikesTypes.success]: (state, action) => ({
    ...state,
    ...(action as TYPES.FetchStrikesSuccessAction).payload,
    fetch: {
      ...state.fetch,
      status: FETCH_STATUSES.SUCCESS,
      error: null,
    },
  }),
  [ACTIONS.fetchStrikesTypes.failure]: (state, action) => ({
    ...state,
    fetch: {
      ...state.fetch,
      status: FETCH_STATUSES.FAILURE,
      error: (action as FetchFailureAction).payload,
    },
  }),

  [ACTIONS.fetchCreateStrikeTypes.request]: (state) => ({
    ...state,
    fetchCreate: {
      ...state.fetchCreate,
      status: FETCH_STATUSES.REQUEST,
      error: null,
    },
  }),
  [ACTIONS.fetchCreateStrikeTypes.success]: (state) => ({
    ...state,
    fetchCreate: {
      ...state.fetchCreate,
      status: FETCH_STATUSES.SUCCESS,
      error: null,
    },
  }),
  [ACTIONS.fetchCreateStrikeTypes.failure]: (state, action) => ({
    ...state,
    fetchCreate: {
      ...state.fetchCreate,
      status: FETCH_STATUSES.FAILURE,
      error: (action as FetchFailureAction).payload,
    },
  }),
  [ACTIONS.fetchConfirmStrikeTypes.request]: (state) => ({
    ...state,
    confirm: {
      ...state.confirm,
      status: FETCH_STATUSES.REQUEST,
    },
  }),
  [ACTIONS.fetchConfirmStrikeTypes.success]: (state, action) => ({
    ...state,
    confirm: {
      ...state.confirm,
      status: FETCH_STATUSES.SUCCESS,
      confirmStatus: (action as TYPES.FetchConfirmStrikeSuccessAction).payload.confirmStatus,
    },
  }),
  [ACTIONS.fetchConfirmStrikeTypes.failure]: (state, action) => ({
    ...state,
    confirm: {
      ...state.confirm,
      status: FETCH_STATUSES.FAILURE,
      error: (action as FetchFailureAction).payload,
    },
  }),
  [ACTIONS.fetchCancelStrikeConfirmationTypes.request]: (state) => ({
    ...state,
    cancelConfirmation: {
      ...state.cancelConfirmation,
      status: FETCH_STATUSES.REQUEST,
    },
  }),
  [ACTIONS.fetchCancelStrikeConfirmationTypes.success]: (state, action) => ({
    ...state,
    cancelConfirmation: {
      ...state.cancelConfirmation,
      status: FETCH_STATUSES.SUCCESS,
      confirmStatus: (action as TYPES.FetchCancelStrikeConfirmationSuccessAction).payload.confirmStatus,
    },
  }),
  [ACTIONS.fetchCancelStrikeConfirmationTypes.failure]: (state, action) => ({
    ...state,
    cancelConfirmation: {
      ...state.cancelConfirmation,
      status: FETCH_STATUSES.FAILURE,
      error: (action as FetchFailureAction).payload,
    },
  }),
})
