import { Formik } from 'formik'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { useDispatchUnmount } from '../../../hooks/useDispatchUnmount'
import { prepareApiIntegrationInitialValues } from '../../../initialValues/apiIntegrationInitialValues'
import { fetchPublicToken, reset } from '../../../store/apiIntegration/apiIntegration.actions'
import {
  publicTokenGenerateSuccessSelector,
  publicTokenSelector,
  publicTokenSuccessSelector,
} from '../../../store/apiIntegration/apiIntegration.selectors'
import { openModal } from '../../../store/modals/modals.actions'
import translations from '../../../translations/keys'
import { ApiIntegrationData, ApiIntegrationFields } from '../../../types/apiIntegration'
import { FormikRenderForm } from '../../../types/formik'
import { CopyIcon } from '../../assets/icons'
import { COMMON_MODALS_IDS } from '../../ui/Modals/CommonModals/CommonModals.constants'
import { NOTIFICATION_MODAL_TYPES } from '../../ui/Modals/NotificationModal/NotificationModal.constants'
import NotificationBadge from '../../ui/NotificationBadge'
import TextInput from '../../ui/TextInput'
import Typography from '../../ui/Typography'
import { TYPOGRAPHY_COMPONENTS, TYPOGRAPHY_NAMES } from '../../ui/Typography/Typography.types'
import Box from '../../ui/Box'
import { API_INTEGRATION_MODALS } from '../../constants/apiGenerate'

import {
  MessageContainer,
  StyledButtonWrapper,
  StyledCopyIconWrapper,
  StyledDescription,
  StyledSubmitButtonWrapper,
  StyledTextInputWrapper,
} from './ApiIntegration.styles'
import { ApiIntegrationModals } from './ApiIntegrationModals'

export const ApiIntegration = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const publicTokenData = useSelector(publicTokenSelector)
  const publicTokenSuccess = useSelector(publicTokenSuccessSelector)
  const publicTokenGenerateSuccess = useSelector(publicTokenGenerateSuccessSelector)
  const [showNotificationBadge, setShowNotificationBadge] = useState(false)
  const formRef = useRef(null)
  const inputRef = useRef<HTMLInputElement>(null)
  const copyToClipboard = (values: ApiIntegrationData) => {
    inputRef.current?.focus()
    navigator.clipboard.writeText(values.publicToken)
    setShowNotificationBadge(true)
  }
  const handleSubmit = () => {
    dispatch(openModal(API_INTEGRATION_MODALS.CONFIRM_GENERATE))
  }
  const handleNotificationBadgeClose = () => setShowNotificationBadge(false)

  useDispatchUnmount(reset)

  useEffect(() => {
    if (publicTokenGenerateSuccess) {
      dispatch(
        openModal(COMMON_MODALS_IDS.NOTIFICATION, {
          type: NOTIFICATION_MODAL_TYPES.SUCCESS,
          description: t(translations.CHANGES_SAVED_SUCCESSFULLY),
        })
      )
    }
  }, [publicTokenGenerateSuccess])

  useEffect(() => {
    dispatch(fetchPublicToken())
  }, [])

  const renderForm = ({ values, handleSubmit }: FormikRenderForm<ApiIntegrationData>) => (
    <>
      <Box pt={4} pb={1}>
        <Box pb={1}>
          <StyledTextInputWrapper>
            <TextInput
              ref={inputRef}
              readOnly
              name={ApiIntegrationFields.publicToken}
              label={t(translations.PUBLIC_TOKEN_LABEL)}
              placeholder={values.publicToken}
              value=""
              error={values.publicToken ? undefined : t(translations.TOKEN_HAS_BEEN_EXPIRED)}
            />
            <Box pl={2.5}>
              <StyledCopyIconWrapper onClick={() => copyToClipboard(values)}>
                <CopyIcon color="grey" />
              </StyledCopyIconWrapper>
            </Box>
          </StyledTextInputWrapper>
        </Box>
        <StyledSubmitButtonWrapper onClick={handleSubmit}>{t(translations.GENERATE_NEW)}</StyledSubmitButtonWrapper>
      </Box>
    </>
  )

  if (!publicTokenSuccess && !publicTokenGenerateSuccess) {
    return null
  }

  return (
    <>
      <Box mb={3}>
        <Typography name={TYPOGRAPHY_NAMES.H3} component={TYPOGRAPHY_COMPONENTS.h3}>
          {t(translations.API_INTEGRATION)}
        </Typography>
      </Box>
      <Box mb={3}>
        <StyledDescription name={TYPOGRAPHY_NAMES.body16} component={TYPOGRAPHY_COMPONENTS.p}>
          {t(translations.API_INTEGRATION_DESCRIPTION)}
        </StyledDescription>
      </Box>
      <StyledButtonWrapper>{t(translations.GO_TO_API_DESCRIPTION)}</StyledButtonWrapper>
      <Formik
        innerRef={formRef}
        initialValues={prepareApiIntegrationInitialValues(publicTokenData)}
        onSubmit={handleSubmit}
      >
        {renderForm}
      </Formik>
      <ApiIntegrationModals />
      {showNotificationBadge && (
        <MessageContainer>
          <NotificationBadge
            isShow
            isAutoClose
            message={t(translations.TOKEN_COPIED)}
            onClose={handleNotificationBadgeClose}
          />
        </MessageContainer>
      )}
    </>
  )
}
