import styled from 'styled-components'

import Box from '../../ui/Box'

export const StyledMain = styled.div`
  padding-top: ${({ theme }) => theme.sizes.layout.headerHeight};
  ${({ theme }) => `@media ${theme.devices.tablet} {
    padding-top: ${theme.sizes.layout.headerHeightMobile};
  }`}
`
export const StyledFaqContent = styled(Box).attrs({
  pb: 8,
  pl: 4,
  pr: 4,
  plMobile: 2,
  prMobile: 2,
})`
  max-width: 936px;
  margin: 0 auto;
`
