import { Action } from 'redux'

export type Languages = 'ar' | 'en'

export interface LanguageState {
  language: Languages
}

export interface ChangeLanguageAction extends Action {
  payload: LanguageState
}

export enum LanguagesActions {
  'CHANGE_LANGUAGE' = 'CHANGE_LANGUAGE',
}
