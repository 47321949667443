import styled, { css } from 'styled-components'
import InputMask from 'react-input-mask'

import fancyBorder from '../../utils/fancyBorder'
import { BUTTON_VARIANTS } from '../Button/Button.types'
import Button from '../Button'

const selectedStyles = css`
  background-image: ${({ theme }) => fancyBorder(theme.palette.blueOcean, 4)};
  z-index: 100;
`

export const Input = styled(InputMask).attrs({ type: 'text' })<{
  fontSize: number
}>`
  width: 100%;
  margin: 0;
  padding: 8px 16px;
  font-family: Tahoma;
  font-size: ${({ fontSize }) => `${fontSize}px`};
  line-height: 1;
  vertical-align: middle;
  background: none;
  border: 0;
  outline: 0;
  appearance: none;
  ${({ disabled }) => disabled && 'pointer-events: none;'}

  &::placeholder {
    color: ${({ theme }) => theme.palette.grey};
  }
`
export const Wrapper = styled.div<{ isSelected?: boolean; isError?: boolean | null }>`
  position: absolute;
  cursor: pointer;
  transform: translateZ(0);
  background-color: transparent;
  background-image: ${({ theme, isError }) => fancyBorder(isError ? theme.palette.errorColor : theme.palette.grey, 2)};
  ${({ isSelected }) => isSelected && selectedStyles}

  ${Input} {
    color: ${({ theme, isError }) => (isError ? theme.palette.errorColor : theme.palette.grey)};
  }
`
export const Actions = styled.div`
  display: flex;
  position: absolute;
  right: 0;
  top: -44px;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.palette.white};
  background-color: ${({ theme }) => theme.palette.blueDark};
  border-radius: 4px;
`
export const Action = styled.div`
  display: flex;
`
export const RemoveButton = styled(Button).attrs({ icon: 'closeCircle', variant: BUTTON_VARIANTS.INACTIVE })`
  width: 40px;
  height: 40px;

  * {
    pointer-events: none;
  }
`
