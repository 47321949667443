import { parse, isValid } from 'date-fns'

import { DEFAULT_DATE_FORMAT } from '../../constants/date'
import {
  DocField,
  DocFieldWithText,
  DocFieldsRequestBody,
  DocFieldsById,
  DocFieldsByPages,
  DEFAULT_FIELD_FONT_SIZE,
} from '../../types/documentField'
import { EditableElem } from '../../types/editableElem'
import omit from '../omit'

type DocFieldsWithPages = { byId: DocFieldsById; byPages: DocFieldsByPages }

export const addFieldById = (field: DocField, fieldsById: DocFieldsById): DocFieldsWithPages => {
  const id = field.id
  const nextFieldsById = id && !fieldsById[id] ? { ...fieldsById, [id]: field } : fieldsById
  const [byId, byPages] = prepareFieldsForStore(Object.values(nextFieldsById))

  return { byId, byPages }
}

export const updateFieldById = (field: EditableElem, fieldsById: DocFieldsById): DocFieldsById => {
  const id = field.id!
  const nextField = { ...fieldsById[id], ...field }
  return { ...omit([id], fieldsById), [id]: nextField }
}

export const updateFieldsFontSize = (fontSize: number, fieldsById: DocFieldsById): DocFieldsById => {
  const fields = Object.values(fieldsById).map((field) => ({ ...field, fontSize }))
  const byId = fields.reduce<DocFieldsById>((result, field) => {
    result[field.id!] = field
    return result
  }, {})

  return byId
}

export const updateFieldText = (text: string, id: string, fieldsById: DocFieldsById): DocFieldsById => {
  const nextField = { ...fieldsById[id], text }
  return { ...omit([id], fieldsById), [id]: nextField }
}

export const clearFieldsText = (fieldsById: DocFieldsById): DocFieldsById => {
  const fields = Object.values(fieldsById).map((field) => ({ ...field, text: '' }))
  const byId = fields.reduce<DocFieldsById>((result, field) => {
    result[field.id!] = field
    return result
  }, {})

  return byId
}

export const getFirstEmptyField = (fieldsById: DocFieldsById, fieldsByPages: DocFieldsByPages) => {
  const pages = Object.keys(fieldsByPages)

  for (const page of pages) {
    const fields = fieldsByPages[page].map((id) => fieldsById[id]).sort((a, b) => a.y - b.y)
    const foundField = fields.find((field) => !field.text)

    if (foundField) {
      return foundField.id
    }
  }
}

export const removeFieldById = (id: string, fieldsById: DocFieldsById): DocFieldsWithPages => {
  const nextFieldsById = {
    ...omit([id], fieldsById),
  }
  const [byId, byPages] = prepareFieldsForStore(Object.values(nextFieldsById))

  return { byId, byPages }
}

export const prepareFieldsForStore = (fields: DocField[]): [DocFieldsById, DocFieldsByPages, number] => {
  const byId: DocFieldsById = {}
  const byPages: DocFieldsByPages = {}
  const fontSize = fields[0]?.fontSize || DEFAULT_FIELD_FONT_SIZE

  fields.forEach((field) => {
    const { id, page } = field

    if (id) {
      byId[id] = field
      byPages[page] = (byPages[page] || []).concat(id)
    }
  })

  return [byId, byPages, fontSize]
}

export const prepareFieldsForRequest = (byId: DocFieldsById): DocFieldsRequestBody => {
  const fields = Object.values(byId).map((field) => omit(['id', 'text'], field))

  return {
    fields,
  }
}

export const prepareFieldsWithTextForRequest = (byId: DocFieldsById): DocFieldWithText[] => {
  const fields = Object.values(byId)
    .map((field) => ({ id: field.id, text: field.text }))
    .filter((field) => Boolean(field.text))

  return fields
}

export const validateFieldDate = (dateString?: string) => {
  if (!dateString) {
    return true
  }

  const date = parse(dateString, DEFAULT_DATE_FORMAT, new Date())

  return isValid(date)
}

export const validateAllFields = (byId: DocFieldsById) => {
  const error = Object.values(byId).find(
    (field) => !field.text?.trim() || (field.type === 'DATE' && !validateFieldDate(field.text))
  )

  return Boolean(error)
}
