import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FormikProps } from 'formik'

import { location } from '../../../../../utils/location'
import { UploadDocumentFields, UploadDocumentForm } from '../../../../../types/uploadDocument'
import { CONTRACTS_MODALS } from '../../../../../constants/contracts'
import { openModal } from '../../../../../store/modals/modals.actions'
import { fetchUpload, cleanUpload } from '../../../../../store/contracts/upload/upload.actions'
import * as SELECTORS from '../../../../../store/contracts/upload/upload.selectors'
import { setSuccessMessage } from '../../../../../store/contracts/preview/preview.actions'
import { SuccessMessages } from '../../../../../store/contracts/preview/preview.types'
import { modalsPayloadSelector } from '../../../../../store/modals/modals.selectors'
import { useShowFetchError } from '../../../../hooks/useShowFetchError'
import { useContractsFoldersFetch } from '../../../../../hooks/contracts/useContractsFoldersFetch'
import { useHistoryPushAfterSuccess } from '../../../../hooks/useHistoryPushAfterSuccess'
import { useDispatchUnmount } from '../../../../../hooks/useDispatchUnmount'
import * as FOLDERS_SELECTORS from '../../../../../store/contracts/folders/folders.selectors'
import UploadDocumentToModal from '../../DocumentModals/UploadDocumentToModal'

import { UploadContractToModalProps } from './UploadContractToModal.types'

export const UploadContractToModal: React.FC<UploadContractToModalProps> = ({ onClose }) => {
  const dispatch = useDispatch()
  const formRef: React.RefObject<FormikProps<UploadDocumentForm>> = useRef(null)
  const contractId = useSelector(SELECTORS.uploadIdSelector)
  const foldersIsLoading = useSelector(FOLDERS_SELECTORS.foldersIsLoadingSelector)
  const folders = useSelector(FOLDERS_SELECTORS.foldersDataSelector)
  const modalPayload = useSelector(modalsPayloadSelector(CONTRACTS_MODALS.UPLOAD_CONTRACT_TO))
  const uploadFetchStatus = useSelector(SELECTORS.uploadFetchStatusSelector)
  const uploadError = useSelector(SELECTORS.uploadErrorSelector)
  const lastAddedFolder = useSelector(FOLDERS_SELECTORS.lastAddedFolderSelector)
  const uploadIsRequest = useSelector(SELECTORS.uploadIsRequestSelector)
  const uploadIsSuccess = useSelector(SELECTORS.uploadIsSuccessSelector)

  const showSuccessMessage = () => setSuccessMessage(SuccessMessages.DOCUMENT_UPLOADED_SUCCESS)

  useEffect(() => {
    const form = formRef.current

    if (lastAddedFolder && form) {
      form.setFieldValue(UploadDocumentFields.folderName, lastAddedFolder.value)
    }
  }, [lastAddedFolder])
  useEffect(() => {
    if (uploadIsSuccess) {
      onClose()
    }
  }, [uploadIsSuccess])

  useHistoryPushAfterSuccess(location.contractsPreviewUrl(contractId), uploadFetchStatus, showSuccessMessage)
  useContractsFoldersFetch()
  useDispatchUnmount(cleanUpload)
  useShowFetchError(uploadFetchStatus, uploadError)

  const onSubmit = (values: UploadDocumentForm) => {
    const folderName = folders.find((folder) => folder.value === values.folderName)?.name || values.folderName

    dispatch(
      fetchUpload({
        ...values,
        file: modalPayload.file,
        folderName,
      })
    )
  }
  const onCreateFolder = () => {
    dispatch(openModal(CONTRACTS_MODALS.UPLOAD_CONTRACT_CREATE_FOLDER))
  }

  return (
    <UploadDocumentToModal
      onClose={onClose}
      fileName={modalPayload.fileName}
      folders={folders}
      foldersIsLoading={foldersIsLoading}
      onCreateFolder={onCreateFolder}
      onSubmit={onSubmit}
      uploading={uploadIsRequest}
      ref={formRef}
    />
  )
}
