import { combineEpics, Epic, ofType } from 'redux-observable'
import { catchError, map, mergeMap, takeUntil } from 'rxjs/operators'

import { supportService } from '../../../api'
import { catchFetchError } from '../../../utils/catchFetchError'

import * as ACTIONS from './contactUs.actions'

const fetchContactUs: Epic = (action$) =>
  action$.pipe(
    ofType(ACTIONS.fetchContactUsTypes.request),
    mergeMap(({ payload }) =>
      supportService.fetchContactUs(payload).pipe(
        map(() => ACTIONS.fetchContactUsSuccess()),
        catchError(catchFetchError(ACTIONS.fetchContactUsFailure)),
        takeUntil(action$.pipe(ofType(ACTIONS.fetchContactUsTypes.success, ACTIONS.fetchContactUsTypes.failure)))
      )
    )
  )

export const contactUsEpics = combineEpics(fetchContactUs)
