import React from 'react'

import ErrorBoundary from '../../components/ErrorBoundary/ErrorBoundary'

import { TemplatePlacement as TemplatePlacementPage } from './TemplatePlacement'

export const TemplatePlacement = () => (
  <ErrorBoundary>
    <TemplatePlacementPage />
  </ErrorBoundary>
)
