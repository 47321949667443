import { Epic, combineEpics, ofType } from 'redux-observable'
import { catchError, map, mergeMap, takeUntil } from 'rxjs/operators'

import { templateService, profileService } from '../../../api'
import { catchFetchError } from '../../../utils/catchFetchError'
import { prepareSenderInfo } from '../../../utils/recipients'
import { prepareMessageInitialValues } from '../../../utils/recipients/recipients'
import { subscriptionUserPlanEnabledFeaturesSelector } from '../../subscription/userPlan/userPlan.selectors'

import * as ACTIONS from './recipients.actions'

const fetchRecipients: Epic = (action$, state$) =>
  action$.pipe(
    ofType(ACTIONS.fetchRecipientsTypes.request),
    mergeMap(({ payload }) =>
      templateService.fetchRecipients(payload.templateId).pipe(
        map((response) =>
          ACTIONS.fetchRecipientsSuccess(response, subscriptionUserPlanEnabledFeaturesSelector(state$.value) || {})
        ),
        catchError(catchFetchError(ACTIONS.fetchRecipientsFailure)),
        takeUntil(action$.pipe(ofType(ACTIONS.fetchRecipientsTypes.success, ACTIONS.fetchRecipientsTypes.failure)))
      )
    )
  )

const fetchAddRecipients: Epic = (action$) =>
  action$.pipe(
    ofType(ACTIONS.fetchAddRecipientsTypes.request),
    mergeMap(({ payload }) =>
      templateService.fetchAddRecipients(payload.templateId, payload.body).pipe(
        map(() => ACTIONS.fetchAddRecipientsSuccess(payload.body.recipients)),
        catchError(catchFetchError(ACTIONS.fetchAddRecipientsFailure)),
        takeUntil(
          action$.pipe(ofType(ACTIONS.fetchAddRecipientsTypes.success, ACTIONS.fetchAddRecipientsTypes.failure))
        )
      )
    )
  )

const fetchAddClmOnlyRecipients: Epic = (action$) =>
  action$.pipe(
    ofType(ACTIONS.fetchAddClmOnlyRecipientsTypes.request),
    mergeMap(({ payload }) =>
      templateService.fetchAddClmOnlyRecipients(payload).pipe(
        map(({ response }) => ACTIONS.fetchAddClmOnlyRecipientsSuccess(response.id)),
        catchError(catchFetchError(ACTIONS.fetchAddClmOnlyRecipientsFailure)),
        takeUntil(
          action$.pipe(
            ofType(ACTIONS.fetchAddClmOnlyRecipientsTypes.success, ACTIONS.fetchAddClmOnlyRecipientsTypes.failure)
          )
        )
      )
    )
  )

const fetchTemplateInfo: Epic = (action$) =>
  action$.pipe(
    ofType(ACTIONS.fetchTemplateInfoTypes.request),
    mergeMap(({ payload }) =>
      templateService.fetchTemplate(payload.templateId).pipe(
        map((response) =>
          ACTIONS.fetchTemplateInfoSuccess({
            name: response.name,
            isDocumentExists: !!response.document.id,
            messageInitialValues: prepareMessageInitialValues(response),
          })
        ),
        catchError(catchFetchError(ACTIONS.fetchTemplateInfoFailure)),
        takeUntil(action$.pipe(ofType(ACTIONS.fetchTemplateInfoTypes.success, ACTIONS.fetchTemplateInfoTypes.failure)))
      )
    )
  )

const fetchSenderInfo: Epic = (action$) =>
  action$.pipe(
    ofType(ACTIONS.fetchSenderInfoTypes.request),
    mergeMap(() =>
      profileService.fetchEditProfileInitialData().pipe(
        map((response) => ACTIONS.fetchSenderInfoSuccess(prepareSenderInfo(response))),
        catchError(catchFetchError(ACTIONS.fetchSenderInfoFailure)),
        takeUntil(action$.pipe(ofType(ACTIONS.fetchSenderInfoTypes.success, ACTIONS.fetchSenderInfoTypes.failure)))
      )
    )
  )

export const recipientsEpics = combineEpics(
  fetchRecipients,
  fetchAddRecipients,
  fetchAddClmOnlyRecipients,
  fetchTemplateInfo,
  fetchSenderInfo
)
