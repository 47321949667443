import React from 'react'

export const Error404Svg: React.FC = (props) => (
  <svg width="320" height="205" viewBox="0 0 320 205" fill="none" {...props}>
    <path
      d="M255.586 189.209V195.875C255.586 200.902 251.538 205 246.561 205H212.754C211.387 205 210.293 203.907 210.293 202.541C210.293 201.175 211.387 200.082 212.754 200.082H246.561C248.803 200.082 250.663 198.17 250.663 195.875V191.667H211.168V193.143C211.168 198.552 206.792 202.978 201.431 202.978C195.906 202.978 191.585 198.552 191.585 193.143V133.693C191.585 130.852 189.342 128.557 186.552 128.557C184.036 128.557 181.738 129.923 181.738 133.693V156.588C181.738 161.232 178.62 164.784 174.517 164.784C170.251 164.784 166.859 161.123 166.859 156.588V89.7613C166.859 86.155 163.96 83.259 160.349 83.259C156.63 83.259 155.317 86.2096 155.317 89.7613C155.317 91.1273 154.223 92.2202 152.855 92.2202C151.488 92.2202 150.394 91.1273 150.394 89.7613C150.394 83.2043 153.84 78.3413 160.349 78.3413C161.334 78.3413 208.871 78.3413 208.871 78.3413C215.708 78.3413 221.233 83.8601 221.233 90.6902V117.027C221.233 118.393 220.139 119.486 218.772 119.486C217.404 119.486 216.31 118.393 216.31 117.027V90.6902C216.31 86.5921 212.973 83.259 208.871 83.259H169.704C170.962 85.1168 171.728 87.3571 171.728 89.7613V156.588C171.728 158.391 172.931 159.812 174.353 159.812C175.94 159.812 176.705 158.172 176.705 156.588V133.693C176.705 126.59 181.683 123.967 185.348 123.639L230.314 123.584C236.112 123.584 240.871 128.393 240.871 134.294V182.051H235.948V134.294C235.948 131.125 233.432 128.557 230.314 128.557H195.031C195.906 130.087 196.453 131.835 196.453 133.693V193.143C196.453 195.82 198.586 198.06 201.212 198.06C204.002 198.06 206.135 195.875 206.135 193.143V189.209C206.135 187.843 207.229 186.75 208.597 186.75H253.07C254.492 186.75 255.586 187.843 255.586 189.209Z"
      fill="#6085B3"
    />
    <path
      d="M230.096 141.398H202.088C200.721 141.398 199.626 140.305 199.626 138.939C199.626 137.573 200.721 136.48 202.088 136.48H230.096C231.463 136.48 232.557 137.573 232.557 138.939C232.557 140.305 231.463 141.398 230.096 141.398Z"
      fill="#DFE1E6"
    />
    <path
      d="M230.096 151.67H202.088C200.721 151.67 199.626 150.304 199.626 148.992C199.626 147.626 200.721 146.315 202.088 146.315H230.096C231.463 146.315 232.557 147.681 232.557 148.992C232.557 150.304 231.463 151.67 230.096 151.67Z"
      fill="#DFE1E6"
    />
    <path
      d="M230.096 162.981H202.088C200.721 162.981 199.626 161.888 199.626 160.522C199.626 159.156 200.721 158.063 202.088 158.063H230.096C231.463 158.063 232.557 159.156 232.557 160.522C232.557 161.943 231.463 162.981 230.096 162.981Z"
      fill="#DFE1E6"
    />
    <path
      d="M230.096 174.346H202.088C200.721 174.346 199.626 173.254 199.626 171.888C199.626 170.522 200.721 169.429 202.088 169.429H230.096C231.463 169.429 232.557 170.522 232.557 171.888C232.557 173.308 231.463 174.346 230.096 174.346Z"
      fill="#DFE1E6"
    />
    <path
      d="M208.707 98.6672H177.362C175.994 98.6672 174.9 97.5744 174.9 96.2084C174.9 94.8423 175.994 93.7495 177.362 93.7495H208.652C210.019 93.7495 211.113 94.8423 211.113 96.2084C211.168 97.629 210.074 98.6672 208.707 98.6672Z"
      fill="#DFE1E6"
    />
    <path
      d="M210.347 110.58H179.057C177.69 110.58 176.596 109.214 176.596 107.903C176.596 106.537 177.69 105.225 179.057 105.225H210.347C211.715 105.225 212.809 106.591 212.809 107.903C212.809 109.214 211.715 110.58 210.347 110.58Z"
      fill="#DFE1E6"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M163.455 128.66C163.262 128.661 163.069 128.662 162.876 128.662C154.187 128.662 146.827 127.306 140.796 124.595C134.764 121.828 129.838 117.705 126.02 112.227C122.257 106.803 119.518 100.135 117.802 92.2217C116.087 84.2529 115.229 74.9561 115.229 64.3311C115.229 53.374 116.059 44.0218 117.719 36.2744C119.435 28.527 122.257 21.8587 126.186 16.2695C130.06 10.791 135.04 6.72363 141.128 4.06738C147.215 1.35579 154.464 0 162.876 0C171.785 0 179.2 1.41113 185.122 4.2334C191.098 7.00033 195.996 11.1507 199.814 16.6846C203.577 22.1077 206.289 28.8037 207.949 36.7725C209.664 44.7412 210.522 53.8997 210.522 64.248C210.522 67.9282 210.42 71.4506 210.217 74.8153H179.154C179.314 71.571 179.394 68.0486 179.394 64.248C179.394 48.9746 178.121 38.2666 175.576 32.124C173.086 25.9261 168.852 22.8271 162.876 22.8271C156.954 22.8271 152.721 25.9261 150.175 32.124C147.63 38.2666 146.357 49.0299 146.357 64.4141C146.357 76.8935 147.232 86.3679 148.982 92.8372V93.7496H149.24C149.53 94.7265 149.842 95.6283 150.175 96.4551C152.721 102.708 156.954 105.835 162.876 105.835C163.07 105.835 163.263 105.832 163.455 105.825V128.66ZM101.868 97.2852H85.1836V126.421H55.2178V97.2852H0.847656V74.209L54.2217 2.49023H85.1836V75.0391H101.868V97.2852ZM55.2178 75.0391V32.9541L23.9238 75.0391H55.2178ZM308.468 97.2852H325.153V75.0391H308.468V2.49023H277.506L224.132 74.209V97.2852H278.502V126.421H308.468V97.2852ZM278.502 32.9541V75.0391H247.208L278.502 32.9541Z"
      fill="#6085B3"
    />
  </svg>
)
