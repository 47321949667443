import { FetchFailureAction, FETCH_STATUSES } from '../../types/fetch'
import { createReducer } from '../createReducer'

import * as ACTIONS from './apiIntegration.actions'
import * as TYPES from './apiIntegration.types'

type Actions =
  | TYPES.FetchPublicTokenAction
  | TYPES.FetchPublicTokenSuccessAction
  | FetchFailureAction
  | TYPES.FetchGeneratePublicTokenSuccessAction

const initialState: TYPES.ApiIntegrationState = {
  publicToken: '',
  fetchPublicToken: {
    status: FETCH_STATUSES.IDLE,
    error: null,
  },
  generatePublicToken: {
    status: FETCH_STATUSES.IDLE,
    error: null,
  },
}

export const apiIntegration = createReducer<TYPES.ApiIntegrationState, Actions>(initialState, {
  [ACTIONS.fetchPublicTokenTypes.request]: (state) => ({
    ...state,
    fetchPublicToken: {
      ...state.fetchPublicToken,
      status: FETCH_STATUSES.REQUEST,
    },
  }),
  [ACTIONS.fetchPublicTokenTypes.success]: (state, action) => ({
    ...state,
    publicToken: (action as TYPES.FetchPublicTokenSuccessAction).payload.publicToken,
    fetchPublicToken: {
      ...state.fetchPublicToken,
      status: FETCH_STATUSES.SUCCESS,
      error: null,
    },
  }),
  [ACTIONS.fetchPublicTokenTypes.failure]: (state, action) => ({
    ...state,
    fetchPublicToken: {
      ...state.fetchPublicToken,
      status: FETCH_STATUSES.FAILURE,
      error: (action as FetchFailureAction).payload,
    },
  }),
  [ACTIONS.fetchGeneratePublicTokenTypes.request]: () => ({
    ...initialState,
    generatePublicToken: {
      ...initialState.generatePublicToken,
      status: FETCH_STATUSES.REQUEST,
    },
  }),
  [ACTIONS.fetchGeneratePublicTokenTypes.success]: (state, action) => ({
    ...state,
    publicToken: (action as TYPES.FetchGeneratePublicTokenSuccessAction).payload.publicToken,
    generatePublicToken: {
      ...state.generatePublicToken,
      status: FETCH_STATUSES.SUCCESS,
      error: null,
    },
  }),
  [ACTIONS.fetchGeneratePublicTokenTypes.failure]: (state, action) => ({
    ...state,
    generatePublicToken: {
      ...state.generatePublicToken,
      status: FETCH_STATUSES.FAILURE,
      error: (action as FetchFailureAction).payload,
    },
  }),
  [TYPES.ApiIntegrationActions.RESET]: () => ({ ...initialState }),
})
