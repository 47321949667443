import * as TYPES from './modals.types'

export const openModal = <D>(id: string, data?: D): TYPES.OpenModalAction<D> => ({
  type: TYPES.ModalsActions.OPEN_MODAL,
  payload: {
    id,
    data,
  },
})

export const closeModal = (id: string): TYPES.CloseModalAction => ({
  type: TYPES.ModalsActions.CLOSE_MODAL,
  payload: { id },
})
