import { FetchFailureAction, FETCH_STATUSES } from '../../types/fetch'
import { createReducer } from '../createReducer'

import * as TYPES from './contacts.types'
import * as ACTIONS from './contacts.actions'
import { addContactToList, updateContactInList } from './contacts.utils'

type Actions =
  | TYPES.SetSorting
  | TYPES.SetSearchText
  | TYPES.SetOffset
  | TYPES.ClearSuccessMessageAction
  | TYPES.ClearContactsAction
  | TYPES.FetchContactsAction
  | TYPES.FetchContactsSuccessAction
  | TYPES.FetchCreateContactAction
  | TYPES.FetchCreateContactSuccessAction
  | TYPES.FetchUpdateContactAction
  | TYPES.FetchUpdateContactSuccessAction
  | TYPES.FetchDeleteContactAction
  | TYPES.FetchDeleteContactSuccessAction
  | TYPES.SetContactsNotificationAction
  | FetchFailureAction

const SORTING = { field: 'fullName', direction: 'ASC' as const }
const LIMIT = 20

export const initialState: TYPES.ContactsState = {
  sorted: [],
  sorting: SORTING,
  searchText: '',
  offset: 0,
  total: 0,
  limit: LIMIT,
  isFirstLoad: true,
  loading: false,
  fetchStatus: FETCH_STATUSES.IDLE,
  error: null,
  successMessage: TYPES.SuccessMessages.NONE,
  loadMore: false,
  notification: null,
}

export const contacts = createReducer<TYPES.ContactsState, Actions>(initialState, {
  [TYPES.ContactsActions.SET_SORTING]: (state, action) => ({
    ...state,
    sorting: (action as TYPES.SetSorting).payload,
    offset: state.loadMore ? initialState.offset : state.offset,
  }),
  [TYPES.ContactsActions.SET_SEARCH_TEXT]: (state, action) => ({
    ...state,
    offset: initialState.offset,
    searchText: (action as TYPES.SetSearchText).payload.searchText,
  }),
  [TYPES.ContactsActions.SET_OFFSET]: (state, action) => {
    const act = action as TYPES.SetOffset
    return {
      ...state,
      offset: act.payload.offset,
      loadMore: act.payload.loadMore,
    }
  },
  [TYPES.ContactsActions.CLEAR_SUCCESS_MESSAGE]: (state) => ({
    ...state,
    successMessage: TYPES.SuccessMessages.NONE,
  }),
  [TYPES.ContactsActions.CLEAR_FETCH_STATUS]: (state) => ({
    ...state,
    fetchStatus: initialState.fetchStatus,
  }),
  [TYPES.ContactsActions.CLEAR_ERROR]: (state) => ({
    ...state,
    error: initialState.error,
  }),
  [TYPES.ContactsActions.CLEAR_CONTACTS]: () => ({
    ...initialState,
  }),

  [ACTIONS.fetchContactsTypes.request]: (state) => ({
    ...state,
    loading: true,
    fetchStatus: FETCH_STATUSES.REQUEST,
  }),
  [ACTIONS.fetchContactsTypes.success]: (state, action) => {
    const { data, totalCount } = (action as TYPES.FetchContactsSuccessAction).payload

    return {
      ...state,
      sorted: state.loadMore && state.offset !== initialState.offset ? [...state.sorted, ...data] : data,
      isFirstLoad: false,
      loading: false,
      offset: state.offset,
      total: totalCount,
      fetchStatus: FETCH_STATUSES.SUCCESS,
      error: null,
    }
  },
  [ACTIONS.fetchContactsTypes.failure]: (state, action) => ({
    ...state,
    isFirstLoad: false,
    loading: false,
    fetchStatus: FETCH_STATUSES.FAILURE,
    successMessage: TYPES.SuccessMessages.NONE,
    error: (action as FetchFailureAction).payload,
  }),

  [ACTIONS.fetchCreateContactTypes.request]: (state) => ({
    ...state,
    fetchStatus: FETCH_STATUSES.REQUEST,
    successMessage: TYPES.SuccessMessages.NONE,
  }),
  [ACTIONS.fetchCreateContactTypes.success]: (state, action) => ({
    ...state,
    sorted: addContactToList((action as TYPES.FetchCreateContactSuccessAction).payload.contact, state.sorted),
    fetchStatus: FETCH_STATUSES.SUCCESS,
    successMessage: TYPES.SuccessMessages.CONTACT_CREATED,
    error: null,
  }),
  [ACTIONS.fetchCreateContactTypes.failure]: (state, action) => ({
    ...state,
    fetchStatus: FETCH_STATUSES.FAILURE,
    successMessage: TYPES.SuccessMessages.NONE,
    error: (action as FetchFailureAction).payload,
  }),

  [ACTIONS.fetchUpdateContactTypes.request]: (state) => ({
    ...state,
    fetchStatus: FETCH_STATUSES.REQUEST,
    successMessage: TYPES.SuccessMessages.NONE,
  }),
  [ACTIONS.fetchUpdateContactTypes.success]: (state, action) => ({
    ...state,
    sorted: updateContactInList((action as TYPES.FetchUpdateContactSuccessAction).payload.contact, state.sorted),
    fetchStatus: FETCH_STATUSES.SUCCESS,
    successMessage: TYPES.SuccessMessages.CONTACT_SAVED,
    error: null,
  }),
  [ACTIONS.fetchUpdateContactTypes.failure]: (state, action) => ({
    ...state,
    fetchStatus: FETCH_STATUSES.FAILURE,
    successMessage: TYPES.SuccessMessages.NONE,
    error: (action as FetchFailureAction).payload,
  }),

  [ACTIONS.fetchDeleteContactTypes.request]: (state) => ({
    ...state,
    fetchStatus: FETCH_STATUSES.REQUEST,
  }),
  [ACTIONS.fetchDeleteContactTypes.success]: (state) => ({
    ...state,
    fetchStatus: FETCH_STATUSES.SUCCESS,
    successMessage: TYPES.SuccessMessages.CONTACT_DELETED,
    error: null,
  }),
  [ACTIONS.fetchDeleteContactTypes.failure]: (state, action) => ({
    ...state,
    fetchStatus: FETCH_STATUSES.FAILURE,
    successMessage: TYPES.SuccessMessages.NONE,
    error: (action as FetchFailureAction).payload,
  }),
  [TYPES.ContactsActions.SET_CONTACTS_NOTIFICATION]: (state, action) => ({
    ...state,
    notification: (action as TYPES.SetContactsNotificationAction).payload,
  }),
  [TYPES.ContactsActions.CLEAN_CONTACTS_NOTIFICATION]: (state) => ({
    ...state,
    notification: initialState.notification,
  }),
})
