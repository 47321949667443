import { SubscriptionFeatures } from '../types/subscription'

export const SUBSCRIPTION_IS_EXPIRED = 'SUBSCRIPTION_IS_EXPIRED'

export const SUBSCRIPTION_FEATURES_ERRORS: {
  [key: string]: string
} = {
  [SubscriptionFeatures.FEATURE_USER_TEMPLATES]: 'TEMPLATES_NO_ACCESS',
  [SubscriptionFeatures.FEATURE_EKYC]: 'EKYC_NO_ACCESS',
  [SubscriptionFeatures.FEATURE_CLM]: 'SEQUENTIAL_SIGNING_NO_ACCESS',
  [SubscriptionFeatures.FEATURE_API_INTEGRATION]: 'API_INTEGRATION_NO_ACCESS',
  [SubscriptionFeatures.FEATURE_UNLIMITED_BULK_SENDING]: 'MULTIPLE_PERSONS_NO_ACCESS',
}

export enum SUBSCRIPTION_MODALS {
  CONTACT_US_MODAL = 'CONTACT_US_MODAL',
  SELECT_TERM_MODAL = 'SELECT_TERM_MODAL',
  CHANGE_PLAN_MODAL = 'CHANGE_PLAN_MODAL',
}

export const SUBSCRIPTION_YEAR = 365
export const SUBSCRIPTION_MONTH = 30
