import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { removeExtension } from '../../../utils/file/removeExtension'
import * as PREVIEW_SELECTORS from '../../../store/templates/preview/preview.selectors'
import * as RECIPIENTS_SELECTORS from '../../../store/templates/recipients/recipients.selectors'
import * as REUPLOAD_SELECTORS from '../../../store/templates/reupload/reupload.selectors'
import {
  fetchSenderInfo,
  fetchRecipients,
  clearRecipients,
} from '../../../store/templates/recipients/recipients.actions'
import * as PLACEMENT_SELECTORS from '../../../store/templates/placement/placement.selectors'
import * as FIELDS_SELECTORS from '../../../store/templates/fields/fields.selectors'
import * as ACTIONS from '../../../store/templates/placement/placement.actions'
import { clearFields } from '../../../store/templates/fields/fields.actions'
import { useDispatchUnmount } from '../../../hooks/useDispatchUnmount'
import { useTemplatePreviewFetch } from '../../../hooks/templatePreview/useTemplatePreviewFetch'
import { useShowFetchError } from '../../hooks/useShowFetchError'
import { useTemplateFieldsFetch } from '../../../hooks/templates/useTemplateFieldsFetch'
import DocViewer from '../../ui/DocViewer'
import { PreviewHeader } from '../../components/PreviewHeader/PreviewHeader'
import FullScreenTemplate from '../../components/Main/FullScreenTemplate'
import { TemplateRouteParams } from '../../types/template'
import Box from '../../ui/Box'
import NotificationBadge from '../../ui/NotificationBadge'
import translations from '../../../translations/keys'
import { clearSuccessMessage } from '../../../store/templates/preview/preview.actions'

import { useButtons } from './hooks/useButtons'
import * as Styled from './TemplateView.styles'
import PlacementSidebar from './components/PlacementSidebar'

export const TemplateView = () => {
  const dispatch = useDispatch()
  const { templateId } = useParams<TemplateRouteParams>()
  const previewFetchStatus = useSelector(PREVIEW_SELECTORS.previewFetchStatusSelector)
  const error = useSelector(PREVIEW_SELECTORS.previewErrorSelector)
  const data = useSelector(PREVIEW_SELECTORS.previewDataSelector)
  const sender = useSelector(RECIPIENTS_SELECTORS.recipientsSenderSelector)
  const byId = useSelector(RECIPIENTS_SELECTORS.recipientsByIdSelector)
  const byOrder = useSelector(RECIPIENTS_SELECTORS.recipientsByOrderSelector)
  const sorted = useSelector(RECIPIENTS_SELECTORS.recipientsSortedSelector)
  const placementById = useSelector(PLACEMENT_SELECTORS.placementByIdSelector)
  const placementByPages = useSelector(PLACEMENT_SELECTORS.placementByPagesSelector)
  const fieldsById = useSelector(FIELDS_SELECTORS.fieldsByIdSelector)
  const fieldsByPages = useSelector(FIELDS_SELECTORS.fieldsByPagesSelector)
  const title = removeExtension(data?.name || '')
  const recipients = { byId, byOrder, sorted }
  const shouldRefetch = useSelector(PLACEMENT_SELECTORS.placementGenerateIsSuccess)
  const fetchGenerateStatus = useSelector(PLACEMENT_SELECTORS.placementFetchGenerateSelector)
  const reuploadStatus = useSelector(REUPLOAD_SELECTORS.reuploadFetchStatusSelector)
  const reuploadError = useSelector(REUPLOAD_SELECTORS.reuploadErrorSelector)
  const isReuploadSuccess = useSelector(REUPLOAD_SELECTORS.reuploadIsSuccessSelector)
  const isGeneratePlacementSuccess = useSelector(PLACEMENT_SELECTORS.placementGenerateIsSuccess)
  const successMessage = useSelector(PREVIEW_SELECTORS.previewSuccessMessageSelector)
  const fetchFieldsRequest = useSelector(FIELDS_SELECTORS.fieldsFetchSelector)
  const buttons = useButtons()

  const handleNotificationBadgeClose = () => {
    dispatch(clearSuccessMessage())
  }

  useEffect(() => {
    dispatch(fetchSenderInfo())
    dispatch(fetchRecipients(templateId))
  }, [])

  useEffect(() => {
    if (recipients.sorted.length > 0) {
      dispatch(ACTIONS.fetchPlacement(templateId, byId))
    }
  }, [recipients.sorted, shouldRefetch])

  useEffect(() => {
    if (isReuploadSuccess && !isGeneratePlacementSuccess) {
      dispatch(ACTIONS.fetchGeneratePlacement(templateId))
    } else if (isReuploadSuccess && isGeneratePlacementSuccess) {
      history.go(0)
    }
  }, [isReuploadSuccess, isGeneratePlacementSuccess])

  useTemplatePreviewFetch(templateId)
  useTemplateFieldsFetch(templateId)
  useShowFetchError(previewFetchStatus, error)
  useShowFetchError(fetchGenerateStatus.status, fetchGenerateStatus.error)
  useShowFetchError(reuploadStatus, reuploadError)
  useShowFetchError(fetchFieldsRequest.status, fetchFieldsRequest.error)
  useDispatchUnmount(clearRecipients, ACTIONS.clearPlacement, clearFields)

  return (
    <FullScreenTemplate
      headerContent={<PreviewHeader buttons={buttons} title={title} />}
      sidebarContent={<PlacementSidebar sender={sender} recipients={recipients} />}
    >
      <Styled.Container>
        {data && (
          <DocViewer
            readonly
            pages={data.pages}
            placementById={placementById}
            placementByPages={placementByPages}
            fieldsById={fieldsById}
            fieldsByPages={fieldsByPages}
          />
        )}
        {successMessage && (
          <Styled.MessageContainer>
            <Box ml={4} mt={4}>
              <NotificationBadge
                isShow
                isAutoClose
                message={translations[successMessage]}
                onClose={handleNotificationBadgeClose}
              />
            </Box>
          </Styled.MessageContainer>
        )}
      </Styled.Container>
    </FullScreenTemplate>
  )
}
