import { TranslationKeys } from '../keys'

const translations: TranslationKeys = {
  WARNING: 'Warning',
  SUCCESS: 'Success!',
  INFO: 'Info',
  ARE_YOU_SURE: 'Are you sure?',
  OK: 'ok',
  GENERATE: 'Generate',
  TERMS_OF_USE: 'Terms of Use',
  AND: 'and',
  PRIVACY_POLICY: 'Privacy Policy',
  SIGN_UP: 'Sign Up',
  LOG_IN: 'Log In',
  LOGOUT: 'Logout',
  UQOUD: 'Uqoud',
  OPEN_IN_UQOUD_APP_BUTTON: 'OPEN',
  OPEN_IN_UQOUD_APP: 'Open in Uqoud app',
  LEGAL_NOTICE: 'Legal Notice!',
  LANGUAGE: 'Language',
  COPYRIGHT: 'Copyright © 2020 Uqoud. All rights reserved',
  PASSWORD_LABEL: 'Password',
  PASSWORD_PLACEHOLDER: 'Enter your password',
  LOGIN_REMEMBER_ME: 'Remember me',
  LOGIN_FORGOT_PASSWORD: 'Forgot your password?',
  LOGIN_NO_ACCOUNT: 'No account?',
  LOGIN_SIGN_UP_NOW: 'Sign Up now',
  REGISTRATION_TITLE: 'Create account',
  FIRST_NAME_LABEL: 'First name',
  FIRST_NAME_PLACEHOLDER: 'Enter your first name',
  LAST_NAME_LABEL: 'Last name',
  TOKEN_HAS_BEEN_EXPIRED: 'Token has been expired',
  LAST_NAME_PLACEHOLDER: 'Enter your last name',
  COUNTRY_LABEL: 'Country',
  COUNTRY_PLACEHOLDER: 'Select your country',
  INDUSTRY_LABEL: 'Industry',
  INDUSTRY_PLACEHOLDER: 'Select industry',
  EMAIL_LABEL: 'Email',
  EMAIL_YOUR_PLACEHOLDER: 'Enter your email',
  PHONE_LABEL: 'Mobile phone number',
  REGISTRATION_PASSWORD_PLACEHOLDER: 'Create password',
  CONFIRM_PASSWORD_LABEL: 'Confirm password',
  API_INTEGRATION: 'API Integration',
  PUBLIC_TOKEN_LABEL: 'Public token',
  API_INTEGRATION_DESCRIPTION:
    'You can get access to our API if you follow the instructions on API description page.\nUse the token below to perform available operations',
  GO_TO_API_DESCRIPTION: 'Go to API description',
  CONFIRM_GENERATE_MODAL_DESCRIPTION: 'Generating a new token will automatically expire the current one',
  GENERATE_NEW: 'Generate New',
  CLEAR_AND_CONTINUE: 'Clear and continue',
  TOKEN_COPIED: 'Token copied to clipboard',
  REGISTRATION_CONFIRM_PASSWORD_PLACEHOLDER: 'Confirm password',
  PASSWORD_VALIDATION_INFO:
    'Password must contain 8 or more symbols, 1 digit, 1 special character, 1 uppercase letter, and 1 lowercase letter',
  REGISTRATION_ACCEPT: 'I accept',
  REGISTRATION_HAVE_ACCOUNT: 'Already have account?',
  SOME_ERROR_OCCURRED: 'Some error occurred',
  INVALID_EMAIL: 'Invalid email address',
  EMAIL_REQUIRED: 'Email is required',
  EMAIL_EXIST: 'Specified email is already registered',
  CONTACT_EMAIL_EXIST: 'Contact with this email already exists',
  CONTACT_EXIST: 'This contact already exists',
  LONG_EMAIL: 'Email is too long',
  LONG_NAME: 'Name is too long',
  LONG_PASSWORD: 'Your password is too long',
  PASSWORD_INVALID: 'Invalid password',
  PASSWORD_REQUIRED: 'Password is required',
  PASSWORDS_DO_NOT_MATCH: "Passwords don't match",
  INVALID_REQUEST_BODY: 'Invalid request body',
  INCORRECT_DATA_ENTERED: 'Incorrect data entered',
  INVALID_PHONE: 'Invalid phone number',
  SELECT_COUNTRY: 'Please, select your country',
  SELECT_INDUSTRY: 'Please, select your industry',
  SMTP_IS_NOT_ACTIVE: 'SMTP service is not active',
  SOMETHING_WRONG: 'Something wrong has happened',
  PLEASE_ACCEPT_TERMS: 'Please accept Terms of Use and Privacy Policy',
  CONFIRM_EMAIL_TITLE: 'Confirm your email',
  CONFIRM_EMAIL_DESCRIPTION:
    'The verification link has been sent to your email address. Please check your email to verify your account',
  CONFIRM_EDIT_EMAIL_DESCRIPTION:
    'The verification link has been sent to the new email address. Please, check your email',
  CONFIRM_PHONE_TITLE: 'Confirm phone number',
  CONFIRM_PHONE_DESCRIPTION: 'The verification code was sent to the specified phone number',
  CONFIRM_PHONE_CODE_INPUT_LABEL: 'Please enter the code',
  CONFIRM_PHONE_RESEND_BUTTON: 'Resend code',
  INCORRECT_CODE: 'Invalid code',
  PHONE_REQUIRED: 'Phone number is required',
  BACK_TO_LOG_IN: 'Back to Log In',
  BACK_TO_PROFILE: 'Back to Profile',
  FORGOT_PASSWORD: 'Forgot password',
  FORGOT_PASSWORD_DESCRIPTION: 'Enter your email to reset password',
  FORGOT_PASSWORD_EMAIL_PLACEHOLDER: 'Enter your email',
  RESET_PASSWORD: 'Reset Password',
  CONTINUE: 'Continue',
  CHECK_EMAIL_TITLE: 'Check your email',
  CHECK_EMAIL_DESCRIPTION:
    'Your password reset instructions were sent to your email. If you are unable to find them, please be sure to check your spam folder',
  RESEND_NOTIFICATION: 'Resend verification email',
  RESTORE_PASSWORD_TITLE: 'Set password',
  RESTORE_CONFIRM_PASSWORD_PLACEHOLDER: 'Confirm your password',
  RESTORE_PASSWORD_PLACEHOLDER: 'Create new password',
  RESTORE_PASSWORD_SUCCESS: 'Your password has been changed',
  EXPIRED_LINK_MODAL_TITLE: 'Your link has expired',
  EXPIRED_LINK_MODAL_DESCRIPTION: 'Please, try to log in to request another verification link',
  BACK_TO_SIGNUP: 'Back to Sign Up',
  REQUIRED_FIELD: "The field can't be empty",
  RESEND_CODE: 'Resend code',
  BASE_URL_NOT_EXIST: 'Not found',
  EDIT_EMAIL: 'Edit email',
  PHONE: 'Phone',
  EDIT_PROFILE_PHONE_EDIT: 'Edit mobile phone number',
  PERSONAL_DATA: 'Personal Data',
  JOB_DATA: 'Job Data',
  COMPANY_LABEL: 'Company',
  COMPANY_PLACEHOLDER: 'Enter your company name',
  JOB_TITLE_LABEL: 'Job title',
  JOB_TITLE_PLACEHOLDER: 'Enter your job title',
  ADDRESS: 'Address',
  ADDRESS_PLACEHOLDER: 'Enter your address',
  CITY_LABEL: 'City',
  CITY_PLACEHOLDER: 'Enter you city',
  REGION_LABEL: 'Region/Province',
  REGION_PLACEHOLDER: 'Enter your region',
  POSTAL_CODE_LABEL: 'Postal code',
  POSTAL_CODE_PLACEHOLDER: 'Enter postal code',
  UPDATE: 'Update',
  USER_NOT_EXISTS: 'You have filled invalid Email',
  WRONG_CREDENTIALS: 'You have filled invalid Email or Password',
  VERIFY_EMAIL_FIRST: 'Please verify your account before logging in',
  RESEND_EMAIL_DESCRIPTION: 'Please verify your account before logging in',
  RESEND_EMAIL_SUCCESS: 'The email has been resent. Please, check your inbox',
  CONFIRMED_EMAIL: 'Your email has successfully been confirmed',
  CONFIRMED_NEW_EMAIL: 'New email is successfully confirmed',
  PASSWORD_REQUIREMENTS: 'Password Requirements',
  PASSWORD_CONTAIN: 'Password must contain',
  ONLY_LATIN_LETTERS: 'Only Latin letters',
  EIGHT_OR_MORE_SYMBOLS: '8 or more symbols',
  AT_LEAST_ONE: 'At least one',
  DIGIT: 'Digit',
  SPECIAL_CHARACTER: 'Special character',
  UPPERCASE_LETTER: 'Uppercase letter',
  LOWERCASE_LETTER: 'Lowercase letter',
  CURRENT_PASSWORD_LABEL: 'Current password',
  CURRENT_PASSWORD_PLACEHOLDER: 'Enter current password',
  NEW_PASSWORD_LABEL: 'New password',
  NEW_PASSWORD_PLACEHOLDER: 'Enter new password',
  CONFIRM_NEW_PASSWORD: 'Confirm new password',
  SAVE: 'Save',
  INCORRECT_CURRENT_PASSWORD: 'You have entered incorrect current password',
  PASSWORD_CHANGED_SUCCESSFULLY: 'Your password was changed',
  PHONE_CHANGED_SUCCESSFULLY: 'Your phone was changed',
  TOKEN_GENERATED_SUCCESSFULLY: 'New token generated successfully',
  CHANGE_PHONE_NUMBER_TITLE: 'Change your mobile phone number',
  CHANGE_PHONE_NUMBER_DESCRIPTION:
    'Enter your new mobile phone number. We will send your new number a verification code via SMS to confirm',
  SEND_CODE: 'Send code',
  CHANGE_EMAIL_TITLE: 'Change your email',
  CHANGE_EMAIL_DESCRIPTION: 'We will send a verification email to your new email address to confirm this change',
  CHANGE_EMAIL_SUCCESS: 'The verification email was sent to your new email',
  CHANGE_EMAIL_LABEL: 'New email',
  CHANGE_EMAIL_PLACEHOLDER: 'Enter new email',
  CHANGE_EMAIL_BUTTON: 'Send verification email',
  VERIFY_ACCOUNT_EMAIL_FIRST: 'Please verify your account email first',
  FILLED_INVALID_EMAIL: 'You have filled invalid Email',
  WAITING_FOR_CONFIRMATION: 'waiting for confirmation',
  RESEND_VERIFICATION: 'Resend verification',
  CANCEL_CHANGES: 'Cancel changes',
  DELETE_CONTACT: 'Delete contact',
  DELETE_CONTACT_DESCRIPTION: 'Are you sure you want to delete this contact?',
  CANCEL: 'Cancel',
  ADD_NEW_CONTACT: 'Add new contact',
  ADD_ONE_MORE: 'Add one more',
  ADD_CONTACT: 'Add contact',
  SEARCH_CONTACT: 'Search contact',
  CONTACT_SAVED: 'Changes saved successfully',
  CONTACT_CREATED: 'Contact is successfully added',
  CONTACT_DELETED: 'Contact deleted successfully',
  CONTACTS_PLACEHOLDER_TEXT: 'You have no contacts yet. Please add new contact',
  CONTACTS_EMPTY_RESULTS: 'Contacts are not found. Please try to change your request',
  CONTACTS_OPEN: 'Open',
  CONTACTS_RENAME: 'Rename',
  CONTACTS_VIEW_HISTORY: 'View history',
  CONTACTS_MOVE_TO: 'Move to',
  CONTACTS_DELETE: 'Delete',
  FULL_NAME_LABEL: 'Full name',
  FULL_NAME_PLACEHOLDER: 'Enter name',
  EMAIL_PLACEHOLDER: 'Enter email',
  HIDE_BUTTON: 'Hide',
  MAIN_MENU_DASHBOARD_LINK: 'Dashboard',
  MAIN_MENU_CONTRACTS_LINK: 'Contracts',
  SIDEBAR_EKYC_LINK: 'eKYC',
  MAIN_MENU_TEMPLATES_LINK: 'Templates',
  SIDEBAR_EDIT_PROFILE_LINK: 'Edit profile',
  SIDEBAR_CHANGE_PASSWORD_LINK: 'Change password',
  SIDEBAR_SIGNATURE_INITIALS_LINK: 'Signature and initials',
  SIDEBAR_CONTACTS_LINK: 'Contacts',
  SIDEBAR_DOCUMENT_BRANDING_LINK: 'Document branding',
  SIDEBAR_PAYMENT_LINK: 'Payment',
  SIDEBAR_SUBSCRIPTION_LINK: 'Subscription',
  TERMS_AND_CONDITIONS: 'Terms and Conditions',
  SIDEBAR_PRIVACY_LINK: 'Privacy Policy',
  SIDEBAR_CONTRACTS_ALL: 'All contracts',
  SIDEBAR_CONTRACTS_RECEIVED: 'Received',
  SIDEBAR_CONTRACTS_SENT: 'Sent',
  SIDEBAR_CONTRACTS_DRAFTS: 'Drafts',
  SIDEBAR_CONTRACTS_DELETED: 'Deleted',
  SIDEBAR_API_INTEGRATION_LINK: 'API Integration',
  USER_MENU_MANAGE_PROFILE_LINK: 'Manage profile',
  USER_MENU_PREFERENCES_LINK: 'Preferences',
  USER_MENU_ANALYTICS_LINK: 'Analytics',
  CHANGES_SAVED_SUCCESSFULLY: 'All changes saved successfully',
  SAVE_CHANGES_BEFORE_LEAVING: 'Do you want to save changes before leaving?',
  YES: 'yes',
  NO: 'no',
  NAME_NOTE: 'Note: your name must be the same as in your official documents (passport, etc.) to pass identification',
  UPGRADE: 'Upgrade',
  SUPPORT: 'Support',
  ENTER_YOUR_PASSWORD_TITLE: 'Enter your password',
  ENTER_YOUR_PASSWORD_DESCRIPTION: 'Enter your password to change your email address',
  ENTER: 'Enter',
  SENT_NEW_VERIFICATION_TO_EMAIL: 'New verification email has been sent',
  NEW_EMAIL_VERIFIED_SUCCESSFULLY: 'Your new email has been verified successfully. Please, use it to log in again',
  EXPIRED_NEW_EMAIL_VERIFICATION_LINK:
    'Link is invalid or expired. Please, click on Resend Email button to request another verification link',
  ERROR: 'Error',
  RESEND_EMAIL: 'Resend email',
  CANCEL_CHANGES_DESCRIPTION: 'Do you want to cancel your changes and return to your current email address?',
  CHANGING_EMAIL_CANCELED: 'Changing email has been canceled',
  NO_CONTACTS: 'You have no contacts yet. Please add new contact',
  PLEASE_ENTER_PASSWORD: 'Please enter your password',
  ACCESS_TO_SIGNATURE_DESCRIPTION: 'To access your signatures, please enter your password below',
  PASSWORD_INVALID_TRY_AGAIN: 'Incorrect password. Please try again',
  ENTER_PASSWORD: 'Enter password',
  CONFIRM_ACCOUNT: 'Please enter your password\nfirst to confirm account',
  INVALID_CODE_OR_NUMBER: 'Invalid code',
  REACHED_MAX_NUMBER_OF_SIGNATURES:
    'You have reached the maximum number of signatures. To create a new one, please delete one of the existing signatures',
  ADD_SIGNATURE: 'Add signature',
  ADD_ONE_MORE_SIGNATURE: 'You can add one more signature',
  CLICK_TO_SEE_SIGNATURE: 'Click to see Signature',
  CLICK_TO_SEE_INITIALS: 'Click to see Initials',
  SHOW_SIGNATURE: 'Show Signature',
  SHOW_INITIALS: 'Show Initials',
  CLICK_UNBLUR: 'Click to unblur the signature',
  NO_SIGNATURES: 'You have no signatures yet. Please add a signature below.',
  LIMIT_SIGNATURES: 'You can add up to two signatures',
  EDIT_PROFILE: 'Edit profile',
  CHANGE_PASSWORD: 'Change password',
  CONTACTS: 'Contacts',
  SIGNATURE_AND_INITIALS: 'Signature and initials',
  CREATE_SIGNATURE: 'Create signature',
  APPLY: 'Apply',
  CLEAR: 'Clear',
  PREVIOUS: 'Previous',
  NEXT: 'Next',
  CLEAR_ALL: 'Clear All',
  MORE: 'More',
  NOTIFICATIONS: 'Notifications',
  NOTIFICATIONS_SETTINGS: 'Notifications settings',
  EDIT: 'Edit',
  DELETE: 'Delete',
  CREATE_SIGNATURE_DESCRIPTION:
    'Draw your new signature on the indicated areas. Once finished, tap the Accept button to save your signatures. After saving, tap the Submit button to create another signature',
  CREATE_DYNAMIC_SIGNATURE_DESCRIPTION_1: 'Draw your new signature below. You need to enter it ',
  CREATE_DYNAMIC_SIGNATURE_DESCRIPTION_2: ' times so we can learn the way you draw it',
  CREATE_STATIC_SIGNATURE_DESCRIPTION: 'Now draw your initials below. It would be enough if you enter it only once',
  SIGNATURE: 'Signature',
  INITIALS: 'Initials',
  DRAW_SIGNATURE: 'Draw signature here',
  DRAW_SIGNATURE_MANY_TIMES:
    'Draw your new signature. You need to enter it 5 times so we can learn the way you draw it',
  DRAW_SIGNATURE_HERE: 'Draw a signature here',
  DRAW_INITIALS_HERE: 'Draw initials here',
  DRAW_INITIALS: 'Now draw your initials',
  DRAW_INITIALS_ONLY_ONES: 'Now draw your initials. It would be enough if you enter it only once',
  SUBMIT: 'Submit',
  CHANGE: 'Change',
  ADD_INITIALS: 'Add initials',
  PREVIEW: 'Preview',
  ENTER_YOUR_PASSWORD_TO_CONTINUE: 'Enter your password to continue',
  SIGNATURE_ADDED: 'Signature added successfully',
  SIGNATURE_DELETE_CONFIRMATION: 'Are you sure you want to delete this signature? This action cannot be undone',
  SIGNATURE_DELETED: 'Signature deleted successfully',
  ADD_NEW_SIGNATURE: 'Add new signature',
  ADD_NEW_SIGNATURE_DESCRIPTION:
    'Smart signatures can be created through the Uqoud mobile app. Download the app using the links below',
  PASSWORD_INCORRECT_AGAIN: 'Invalid password. Try again',
  EXPIRED_VERIFICATION_LINK: 'Link is invalid or expired',
  NAME: 'Name',
  EDIT_CONTACT: 'Edit contact',
  UPLOAD_FILE: 'Upload file',
  DROP_FILE_HERE: 'Drag and drop file here',
  OR: 'Or',
  BROWSE_FILES: 'Browse files',
  UPLOAD_CONTACT_FORMATS: 'Files for uploading can be of the following formats only: word, pdf, jpg, png',
  UPLOAD_CONTACT_SIZE: 'and must not exceed 100Mb',
  FILE_MAX_SIZE: 'Files for uploading must not exceed 100Mb',
  FILE_WRONG_FORMAT: 'Files for uploading can be of the following formats only: word, pdf, jpg, png',
  UPLOAD_TO: 'Upload to',
  FOLDER_LABEL: 'Select folder',
  CREATE_NEW_FOLDER: 'Create new folder',
  UPLOAD: 'Upload',
  CREATE_FOLDER: 'Create folder',
  CREATE: 'Create',
  FOLDER_NAME_LABEL: 'Folder name',
  FOLDER_NAME_PLACEHOLDER: 'Enter folder name',
  INCORRECT_FILE_NAME: 'Incorrect file name',
  INCORRECT_FOLDER_NAME: 'Incorrect folder name',
  ADD_NEW_CONTRACT: 'Add new contract',
  ADD_FOLDER: 'Add folder',
  CONTRACTS_PLACEHOLDER_TEXT: 'You have no contracts yet. Please add a new contract below',
  CONTRACTS_EMPTY_RESULTS: 'Contract not found. Please try again',
  ADD_CONTRACT: 'Add contract',
  UPLOAD_FROM_COMPUTER: 'Upload',
  UPLOAD_FROM_TEMPLATE: 'Load from template',
  UPLOAD_FROM_GOOGLE: 'Google Drive',
  UPLOAD_FROM_ONE_DRIVE: 'One Drive',
  MORE_TIMES: 'more times',
  MORE_DYNAMIC_SIGNATURES: 'Some of the signatures you entered has been classified as invalid. Please, draw it again',
  DYNAMIC_SIGNATURES_INVALID: 'Insufficient coherence between signatures',
  FOLDER_EXISTS_ERROR: 'This folder already exists',
  DOCUMENT_UPLOADED_SUCCESS: 'New document uploaded successfully!',
  CONTRACT_ASSIGN_TO_SELF: 'Assign to myself',
  CONTRACT_SAVE_AS_TEMPLATE: 'Save as template',
  CONTRACT_DELETE_AND_CLOSE: 'Delete and close',
  CONTRACT_SAVE_AND_CLOSE: 'Save and close',
  PROCEED_TO_CLM: 'Proceed to CLM',
  CONTRACT_START: 'Start contract',
  SELECT_SIGNATURE_SEQUENCE: 'Please select a signature sequence',
  SEQUENTIAL_SIGNING: 'Sequential signing',
  SEPARATELY_SIGNING: 'Separately signing',
  ADD_RECIPIENTS_TITLE: 'Contract Lifecycle Management',
  RECIPIENTS: 'Recipients',
  VIEW_AND_SEND: 'To view and send',
  SAVE_AND_CLOSE: 'Save and close',
  VIEW_DOCUMENT: 'View document',
  RECIPIENT_NAME_LABEL: 'Recipient name',
  RECIPIENT_NAME_PLACEHOLDER: 'Enter name',
  RECIPIENT_EMAIL_LABEL: 'Recipient email',
  RECIPIENT_EMAIL_PLACEHOLDER: 'Enter email',
  ADD_RECIPIENT: 'Add recipient',
  ADD_RECIPIENTS: 'Add recipients',
  DOCUMENT_WITH_NAME_EXIST: 'Document with this name already exists',
  DOCUMENT_NAME_INVALID: 'Incorrect document name',
  SIGNATURE_MODAL_FINISHED: 'Signing finished. Is the signature ok?',
  SIGNATURE_INITIALS_MODAL_FINISHED: 'Signing finished. Is the initials ok?',
  SIGNATURE_MODAL_YES: 'Yes, It’s OK',
  SIGNATURE_MODAL_NO: 'No, repeat',
  CHOOSE_CONTACT: 'Choose contact',
  SEARCH: 'Search',
  SEND_CONTRACT: 'Send contract',
  SENDER: 'Sender',
  HEAD_OF_PRODUCT: 'Head of Product',
  VIEW: 'View',
  INITIAL: 'Initial',
  SIGN: 'Sign',
  APPROVAL_CYCLE: 'APPROVAL CYCLE',
  APPROVAL_CYCLES: 'Approval Cycles',
  COMPLETED: 'Completed',
  CONTRACT_DEADLINE: 'Contract deadline',
  FINAL_SLA: 'Final SLA',
  MESSAGE_TO_RECIPIENTS: 'Message to recipients',
  TITLE: 'Title',
  MESSAGE: 'Message',
  CUSTOM_SUBSCRIPTION_REQUEST_MESSAGE_SENT:
    'Thank you! Our manager will answer you shortly on the email, specified in your account',
  CONTACT_US_TITLE: 'Tell us about your requests. Our manager will contact you via email',
  BACK_TO_ADD_RECIPIENTS: 'Back',
  DELETE_RECIPIENT: 'Delete recipient',
  ARE_SURE: 'Are you sure that',
  SHOULD_BE_DELETED: 'should be deleted from the Contract Recipient list?',
  DELETE_RECIPIENT_DESCRIPTION: 'Are you sure that you want to delete recipient?',
  DELETE_CONTRACT_DESCRIPTION: 'Are you sure that you want to delete this contract?',
  SHOW_INFO: 'Show full info',
  HIDE_INFO: 'Hide full info',
  DELETE_OWN_CONTRACT_DESCRIPTION:
    'Are you sure you want to delete this contract? Signing flow will be stopped for all users',
  DELETE_DRAFT_CONTRACT_DESCRIPTION: 'Are you sure you want to delete this contract?',
  DELETE_FOLDER_DESCRIPTION: 'Are you sure you want to delete this folder?',
  RENAME_FILE: 'Rename file',
  RENAME_FOLDER: 'Rename folder',
  RENAME: 'Rename',
  DOCUMENT_NAME_PLACEHOLDER: 'Enter name',
  MOVE_TO_FOLDER: 'Move to folder',
  MOVE: 'Move',
  SELECT_FOLDER_LABEL: 'Select folder',
  DUPLICATE_FILE: 'Duplicate file',
  CONTRACT_DUPLICATE_FILE_DESCRIPTION: 'Please rename this contract before duplicating',
  DUPLICATE: 'Duplicate',
  SELECT_FOLDER_ERROR: 'Please select a folder',
  CHOOSE_FOLDER: 'Choose folder',
  CONTRACT_EXISTS: 'Contract with this name already exists',
  DEPARTMENT_LABEL: 'Department',
  DEPARTMENT_PLACEHOLDER: 'Enter department',
  OPTIONAL: 'optional',
  PHONE_NUMBER: 'Phone number',
  ADD_ANOTHER: 'Add another',
  CHOOSE_ACTION: 'Choose action',
  CYCLE_ORDER: 'Cycle order',
  CHANGE_ORDER: 'Change order',
  DEADLINE_SLA_LABEL: 'Deadline SLA',
  DEADLINE_SLA_PLACEHOLDER: 'Select date',
  SIGNATURE_FIELD: 'Signature field',
  INITIALS_FIELD: 'Initials field',
  PAGE: 'Page',
  LAST_PAGE: 'Last page',
  EVERY_PAGE: 'Every page',
  AUTHENTICATION: 'Authentication',
  EDIT_RECIPIENT: 'Edit recipient',
  DOCUMENT_EXTENSIONS_INVALID: 'Files for uploading can be of the following formats only: word, pdf, jpg, png',
  RECIPIENTS_EMAIL_FROM_CONTACT: 'You can change the email on Contacts page',
  RECIPIENTS_DEPARTMENT_FROM_CONTACT: 'You can change the department on Contacts page',
  RECIPIENTS_PHONE_FROM_CONTACT: 'You can change the phone number on Contacts page',
  RECIPIENTS_MAXIMUM_REACHED: 'You have added the maximum number of recipients',
  RECIPIENTS_EMAIL_CONTACT: 'You can change the email on Contacts page',
  RECIPIENTS_MAX_SIGNATURES_EXCEEDED: 'Maximum 4 recipients can sign the document',
  RECIPIENTS_MAX_INITIALS_EXCEEDED: 'Maximum 10 recipients can initial the document',
  RECIPIENTS_EMAIL_NOT_UNIQUE: "Error. You can't use the same email few times in one cycle order",
  INVALID_CYCLE_ORDER: 'Please make sure the cycle order entered is correct',
  INCORRECT_CYCLE_ORDER: 'Please make sure the cycle order entered is correct',
  RECIPIENT_EMAIL_EXIST: 'Recipient with this email already exists',
  CYCLE_ORDER_MIN: 'Minimum is 1',
  CYCLE_ORDER_MAX: 'Maximum is 50',
  CYCLE_ORDER_REQUIRED: 'Field is required',
  OPEN: 'Open',
  VIEW_HISTORY: 'View history',
  HISTORY: 'History',
  IP: 'IP',
  RESEND: 'Resend',
  VIEW_INFO: 'View info',
  VOID: 'Void',
  FILTERS: 'Filters',
  RESET_ALL: 'Reset all',
  STATUS: 'Status',
  PENDING_SIGN: 'Pending signature',
  EXPIRING_SOON: 'Expiring Soon',
  SIGNED: 'Signed',
  WAITING_FOR_SIGN: 'Waiting for sign',
  REQUIRED_TO_VIEW: 'Required to View',
  DATE_ADDED: 'Date added',
  FROM: 'From',
  TO: 'To',
  CHOOSE: 'Choose',
  PROGRESS: 'Progress',
  CREATED_BY: 'Created / by',
  UPDATED_BY: 'Updated / by',
  SENT: 'Sent',
  COMMENTED: 'Commented',
  VOIDED: 'Voided',
  DELETED: 'Deleted',
  EXPIRED: 'Expired',
  DATE_CREATED: 'Date created',
  RESET: 'Reset',
  DRAFT: 'Draft',
  CREATED_BY_SPACE: 'Created By',
  CONTRACTS_SEARCH_INFO: 'You search among all your documents',
  LAST_MODIFIED_BY: 'Last Modified By',
  CREATED: 'Created',
  UPDATED: 'Updated',
  CONTRACT_DELETED: 'Contract deleted successfully',
  FOLDER_DELETED: 'Folder with all contracts deleted successfully',
  NAME_CHANGED: 'Name changed successfully',
  CONTRACT_REUPLOAD: 'Reupload document',
  DOCUMENT_REUPLOAD: 'Reupload document',
  CONTRACT_FINISH_LATER: 'Finish later',
  SIGNATURE_BOX_TEXT: 'Signature here',
  ME: 'Me',
  CONTRACT_MOVED_TO_FOLDER: 'Contract moved to another folder successfully',
  NO_CONTRACTS: 'You have no contracts yet',
  CONTRACT_DUPLICATED: 'Contract duplicated successfully',
  SIGNERS: 'Signers',
  BACK: 'Back',
  MODIFIED: 'Modified',
  EMPTY_FOLDER: 'The folder is empty',
  FOLDER_ALREADY_EXIST: 'This folder already exists',
  CHOOSE_INITIALS: 'Choose your initials',
  CHOOSE_SIGNATURE: 'Choose your signature',
  SELECT_DOCUMENT_INITIALS: 'Select your initials to be used in this document.',
  SELECT_DOCUMENT_SIGNATURE: 'Select your signature to be used in this document.',
  SELECTION_APPLIED_NOTE: 'Please note that these initials will be applied to all documents',
  MANAGE_SIGNATURE: 'You can manage your signatures in the "Manage profile" section',
  FOLDER_UPLOADED: 'New folder added successfully',
  COMMENTS: 'COMMENTS',
  COMMENT: 'Comment',
  CROSSOVER: 'Crossover',
  NO_SIGNATURE_IN_ACCOUNT: 'You have no signatures in your account',
  NEW_TO_CREATE_SIGNATURE: 'You need to create one to continue',
  ADD_SIGNATURES: 'Add signatures',
  SEND_CONTRACT_TITLE: 'Send the contract?',
  SEND_CONTRACT_DESCRIPTION: 'Please ensure that all signatures and initials are correctly placed on all pages',
  SEND_CONTRACT_DESCRIPTION_MOBILE:
    'Please ensure that all the necessary actions are performed before sending the contract',
  SEND: 'Send',
  CONTRACT_SENT_SUCCESSFULLY: 'Contract has been successfully sent to the recipients',
  DECLINE_TO_SIGN: 'Decline to sign',
  DOWNLOAD: 'Download',
  FINISH_CONTRACT_LATER: 'Finish contract actions later? Changes will not be saved',
  FINISH_CONTRACT_ACTIONS_LATER: 'Finish contract actions later?',
  CHANGES_WILL_NOT_BE_SAVED: 'Changes will not be saved',
  NOT_SIGNED_YET: 'Contract not signed yet, please complete requested action to be able to send',
  NOT_INITIALED_YET: 'Contract not initialed yet, please complete requested action to be able to send',
  SEND_CONTRACT_TO_RECIPIENTS: 'Send contract to other recipients?',
  ADD_SIGNATURE_HERE: 'Add your signature here',
  NO_DATA: 'No Data',
  PENDING_RECIPIENTS: 'Pending recipients',
  READY_TO_SIGN: 'Ready to sign',
  VERIFICATION_FAILED: 'Verification failed',
  CONTRACT_READY_TO_SEND: 'All actions complete! Your contract is ready to be sent',
  DECLINE_SIGNING_TITLE: 'Are you sure you want to decline this contract?',
  DECLINE_REASON_LABEL: 'Reason for declining',
  DECLINE_REASON_PLACEHOLDER: 'Add comment',
  DECLINE: 'Decline',
  EXPLAIN_DECLINE: 'Please, explain why you wish to decline',
  CONTRACT_DECLINED: 'Contract declined successfully',
  DECLINED: 'Declined',
  SIGNATURE_CHANGED: 'Signature changed successfully',
  INITIALS_CHANGED: 'Initials changed successfully',
  CYCLE_ORDER_HELP: 'The order of receiving the contract for this recipient from the overall cycle',
  DEADLINE_SLA_HELP: 'The deadline date for the recipient to take action on the contract',
  EMPTY_RECIPIENTS: 'Contract cannot be with only "view" actions',
  GRID: 'Show grid',
  DISPLAY_NAMES: 'Display names',
  GROUP_EDIT: 'Group edit',
  APPLY_TO_ALL_PAGES: 'Apply to all pages',
  UNDO: 'Undo',
  REDO: 'Redo',
  PLACEMENT_RESET_CONFIRMATION:
    'Are you sure you want to reset signatures and initials placement inside current contract?',
  HELP: 'Help',
  DOCUMENT_NOT_READY_FOR_DOWNLOAD: 'We are preparing a file for you. It can take some time, please wait',
  SOCKET_CONNECT_ERROR: 'Socket connect error',
  PREPARING_FILE: 'We are preparing a file for you',
  WAIT_PREPARING_FILE: 'It can take some time, please wait',
  STOP_SIGNING: 'Do you want to stop signing of your contract?',
  DELETE_CORRECTIONS:
    'Do you want to delete all corrections from the document and continue signing its initial version',
  SIGNING_STOPPED: 'The signature of this contract has been stopped temporarily',
  EDIT_FLOW: 'Edit flow',
  REQUEST_TO_VIEW: 'It has been requested that you view this contract',
  SIGNING_FINISHED: 'Contract signing has been successfully finished',
  SIDEBAR_TEMPLATES_ALL: 'All templates',
  SIDEBAR_TEMPLATES_STARRED: 'Starred',
  SIDEBAR_TEMPLATES_DOCUMENTS: 'With documents',
  SIDEBAR_TEMPLATES_PROCEDURES: 'Procedures',
  SIDEBAR_TEMPLATES_DELETED: 'Deleted',
  REUPLOAD: 'Re-upload',
  REUPLOAD_DOCUMENT: 'Re-upload file',
  REUPLOAD_DOCUMENT_CONFIRMATION:
    'This action may cause changes in current placement of boxes in your contract. Note that replacing document cannot be undone',
  TEMPLATES_EMPTY_RESULTS: 'Template not found. Please try again',
  CLM: 'CLM',
  DOCUMENT: 'Document',
  DOCUMENT_CLM: 'Document+CLM',
  CREATE_TEMPLATE: 'Create template',
  TYPE: 'Type',
  UPLOAD_DOCUMENT: 'Upload document',
  CLM_WITHOUT_DOCUMENT: 'CLM without document',
  LOAD_EXISTING_CONTRACT: 'Load from existing contract',
  MARK_AS_STARRED: 'Mark as starred',
  UNMARK_AS_STARRED: 'Unmark as starred',
  NO_TEMPLATES: 'You have no templates yet',
  TEMPLATE_EXISTS: 'Template with this name already exists',
  TEMPLATE_DELETED: 'Template deleted successfully',
  TEMPLATE_FOLDER_DELETED: 'Folder with all templates deleted successfully',
  DELETE_TEMPLATE_DESCRIPTION: 'Are you sure you want to delete this template? This action cannot be undone',
  DELETE_TEMPLATE_FOLDER_DESCRIPTION:
    'Are you sure you want to delete this folder? This will delete all templates inside it with no undo',
  TEMPLATE_MOVED_TO_FOLDER: 'Moved to another folder successfully',
  TEMPLATE_DUPLICATE_FILE_DESCRIPTION: 'Please rename this template before duplicating',
  TEMPLATE_DUPLICATED: 'Template duplicated successfully',
  UPLOAD_TEMPLATE: 'Upload a document for the template',
  DELETED_BY_OWNER: 'Contract has been deleted by the owner',
  START_EKYC: 'Start eKYC',
  EKYC_WARNING_TEXT: 'Please make sure you allow access to your camera',
  DETECTING_FACE: 'Detecting face',
  PLACE_YOUR_FACE: 'Please keep your face within the oval\nuntil the next step of results',
  SUCCESSFUL_REGISTRATION_COMPLETED: 'Successful registration\ncompleted!',
  REGISTRATION_IS_FAILED: 'Registration is failed!',
  SUCCESSFUL_VERIFICATION: 'Successful eKYC verification completed!',
  VERIFICATION_IS_FAILED: 'EKYC Verification is failed',
  ACCEPT_LEGAL_NOTICE: 'Do you accept the Legal notice? ',
  TAP_TO_SEE_SIGNATURE: 'Tap to see Signature',
  TAP_TO_SEE_INITIALS: 'Tap to see Initials',
  RECIPIENTS_WITHOUT_EMAIL_AND_NAME: 'Continue without name and email',
  TEMPLATES_CLM_NOT_SPECIFIED: 'Recipients and signing order are not specified',
  TEMPLATES_ADD_CLM: 'Add CLM',
  TEMPLATES_SAVE_AND_CLOSE: 'Save and close',
  TEMPLATES_DELETE_AND_CLOSE: 'Delete and close',
  TEMPLATES_DISCARD_CONFIRMATION: 'Do you want to discard changes in the template?',
  TEMPLATES_UPLOAD_DOCUMENT_FOR_CLM_ONLY: 'Upload a document for the template',
  TEMPLATES_CLM_ONLY_UPLOAD_DOCUMENT_DISCLAIMER:
    'Note, that you could not delete a document from template, but you could re-upload it.',
  TEMPLATES_CREATE_CLM_MODAL: 'Create CLM template',
  TEMPLATES_SUCCESSFUL_CREATION: 'Template created successfully',
  CREATE_TEMPLATE_FROM_CONTRACT: 'Create template from existing contract',
  SELECT_CONTRACT: 'Select contract',
  TEMPLATE_NAME: 'Template name',
  SELECT_CONTRACT_FOR_TEMPLATE: 'Please select contract to create a template',
  DISCARD: 'Discard',
  NO_NAME: 'No name',
  SAVE_WITHOUT_SLM: 'Do you want to save this template without a signing flow?',
  SIDEBAR_PREFERENCES_NOTIFICATIONS: 'Notifications',
  SIDEBAR_PREFERENCES_REGIONAL_SETTINGS: 'Regional settings',
  SIDEBAR_PREFERENCES_CLOUD_STORAGE: 'Cloud storage',
  REGIONAL_SETTINGS_TITLE: 'Date, Time, and Language Settings',
  DATA_AND_TIME_FORMAT: 'Date and time format',
  CONTRACT_NOTIFICATIONS: 'Contract Notifications',
  REMINDERS: 'Reminders',
  CHASERS: 'Chasers',
  UPGRADE_REQUIRED: 'Subscription upgrade required',
  RECIPIENTS_WRONG_DEADLINES:
    'Deadline SLA for recipient cannot be earlier, then Deadlines SLA in cycle order before him',
  CONTRACT_FOLDER_DELETED: 'Folder with all contracts deleted successfully',
  ANDROID_PERMISSION_CAMERA_TITLE: 'Permission to use camera',
  ANDROID_PERMISSION_CAMERA_MESSAGE: 'We need your permission to use your camera',
  ID_CHECK: 'ID check',
  SELFIE: 'Selfie',
  EKYC: 'eKYC',
  PLACE_YOUR_ID: 'Please keep the ID within the box until the next step of face capture and box changes to oval shape',
  DETECTING_ID: 'Detecting document ID',
  SENDING_IMAGE_TO_SERVER: 'Sending image to server',
  TRY_AGAIN: 'Try Again',
  PLEASE_TRY_AGAIN:
    'Please try again. Please try to improve the light and correct position of your document and face on the photos',
  PLEASE_TRY_VERIFICATION_AGAIN:
    'Please try again. Please try to improve the light and correct position of your face on the photo',
  EKYC_VERIFICATION_CONFIRMED: 'Verification is successfully\nconfirmed',
  EKYC_REGISTRATION_FAILED: 'eKYC registration is failed!',
  EKYC_CONTACT_SUPPORT: 'You expired all your attempts.\nWe suggest you to contact Support or Contract sender',
  CONTACT_SUPPORT: 'Contact support',
  DELETE_AND_CLOSE: 'Delete and close',
  CANCEL_CREATING_CONTRACT: 'Cancel creating a contract from a template?',
  CREATE_NEW_CONTRACT: 'Create new contract',
  SELECT_TEMPLATE_FOR_CONTRACT: 'Please select template to create a contract',
  CREATE_CONTRACT_FROM_TEMPLATE: 'Create contract from template',
  SELECT_TEMPLATE: 'Select template',
  CREATING_CONTRACT_ABORTED: 'Creating contract aborted successfully',
  NO_DOCUMENT_NOTIFICATION: 'Selected template contains no document. You need to upload it to create a contract',
  ASK_CANCEL_CHANGES: 'Do you really want to cancel changes?',
  FOLDER_CONTAINS_RECEIVED_CONTRACT:
    'This folder contains some items that cannot be deleted right now. Please open a folder to manage them',
  EKYC_INSTRUCTIONS_FACE_TITLE: 'Instructions for Face detecting',
  EKYC_INSTRUCTIONS_FACE_SUBTITLE:
    'You are going to take a picture of your face. Here are some tips to take it in the best conditions.',
  EKYC_INSTRUCTIONS_FACE_TEXT1: '1. Hold your phone still while taking the picture',
  EKYC_INSTRUCTIONS_FACE_TEXT2: '2. Remove your hair from the face and take your glasses off',
  EKYC_INSTRUCTIONS_FACE_TEXT3: '3. Check lighting conditions (medium brightness)',
  EKYC_INSTRUCTIONS_FACE_TEXT4: '4. Your face should cover the whole oval space',
  EKYC_INSTRUCTIONS_ID_TITLE: 'Instructions for ID card detecting',
  EKYC_INSTRUCTIONS_ID_SUBTITLE:
    'You are going to take a picture of your ID card. Here are some tips to take it in the best conditions.',
  EKYC_INSTRUCTIONS_ID_TEXT1: '1. Hold your phone still while taking the picture',
  EKYC_INSTRUCTIONS_ID_TEXT2: '2. Check lighting conditions (medium brightness)',
  EKYC_INSTRUCTIONS_ID_TEXT3: '3. Your ID card should cover the whole rectangular space',
  SUBSCRIPTION_PLANS: 'Subscription Plans',
  YEAR: 'year',
  MO: 'mo',
  FEATURE_CONTRACTS_COUNT: 'contracts',
  FEATURE_CONTRACTS_UNLIMITED: 'Unlimited contracts',
  FEATURE_USER_TEMPLATES: 'User templates',
  FEATURE_UNLIMITED_BULK_SENDING: 'Unlimited bulk sending',
  FEATURE_DASHBOARD: 'Dashboard',
  FEATURE_CLM: 'CLM',
  FEATURE_EKYC: 'eKYC',
  FEATURE_SIGNATURE_MATCHING: 'Signature matching',
  FEATURE_API_INTEGRATION: 'API Integration',
  DO_NOT_LIKE_PLAN: "Don't like any of offered plans? Contact us to discuss some custom options",
  CONTACT_US: 'Contact us',
  YOUR_PLAN: 'Your Plan',
  EXPIRATION_DATE: 'Expiration date',
  CONTRACTS: 'Contracts',
  CHANGE_PLAN: 'Change plan',
  UNSUBSCRIBE: 'Unsubscribe',
  SUBSCRIPTION_IS_EXPIRED: 'Your subscription is expired',
  VERIFICATION_CONFIRMED: 'Verification is successfully confirmed',
  GO_TO_SETTINGS: 'Go to settings',
  TEXT_FIELD: 'Text field',
  TEXT_FIELD_PLACEHOLDER:
    'Adjust this field width to be long enough for the text. You will enter your text here when you will be creating a contract from this template',
  DATE_FIELD: 'Date field',
  DATE_FIELD_PLACEHOLDER: 'dd.mm.yyyy',
  TEMPLATES_NO_ACCESS:
    'You have no access to User templates features. Upgrade your subscription now to get access to it',
  API_INTEGRATION_NO_ACCESS:
    'You have no access to API Integration features. Upgrade your subscription now to get access to it',
  UPGRADE_YOUR_PLAN: 'Upgrade your subscription plan',
  EKYC_NO_ACCESS: "You don't have access to using eKYC-features. Upgrade your subscription now to get it",
  USE_MOBILE_EKYC: 'Please use our Mobile Application to be verified with eKYC',
  CONTACT_US_MESSAGE: 'Tell us about your requests. Our manager will contact you via email',
  INVALID_MESSAGE: 'Invalid message',
  SIZE: 'Size',
  SEQUENTIAL_SIGNING_NO_ACCESS:
    'You cannot use sequential signing of a contract. Upgrade your subscription now to get access to it',
  CONTACT_US_SUCCESS: 'Thank you! Our manager will answer you shortly on the email, specified in your account',
  TEMPLATES_CUSTOM_FIELDS_PROMPT:
    'Please fill all the input fields in a document with appropriate data to create a contract',
  TEMPLATES_DATE_FIELD_INCORRECT: 'Incorrect date',
  TEMPLATES_INCORRECT_FIELDS: 'Please fill in all the required fields correctly to create a contract',
  TEMPLATES_FIELDS_FILLED: 'You have filled all additional fields. Now we are ready to create a contract',
  FLIP_THE_PHONE: 'Flip the phone horizontally to sign. Check that\nthe landscape mode is unlocked for your phone',
  HOME: 'HOME',
  PAGE_NOT_FOUND: 'Page not found',
  CONNECT: 'CONNECT',
  DISCONNECT: 'DISCONNECT',
  CONNECT_CLOUD_STORAGE: 'Connect Cloud Storage',
  FAQ_HEADER_TITLE: 'Hello! How can we help you?',
  FAQ_HEADER_SUB_TITLE: 'or choose the category',
  DOCUMENT_TITLE_FIELD_MAX: 'Maximum is 100',
  DOCUMENT_MESSAGE_FIELD_MAX: 'Maximum is 500',
  OTP_VERIFICATION_TITLE: 'Passcode OTP verification',
  OTP_VERIFICATION_DESCRIPTION: 'The verification code has been sent to the phone number, specified by Contract Owner',
  OTP_VERIFICATION_CONTACT_OWNER:
    "If you don't receive a code in SMS due to wrong phone number specified - please, contact Contract Owner to fix it",
  CODE_IS_CORRECT: 'Code is correct',
  RECIPIENT_ATTEMPTS_FINISHED: 'You expired all your attempts. We suggest you to contact Support or Contract sender',
  INVALID_OTP: 'Incorrect code',
  LAST_ATTEMPT: 'You have the last attempt',
  NEW_CODE_IS_SENT: 'New code is sent',
  PROCESSING_DOCUMENTS: 'Processing provided documents…',
  MULTIPLE_PERSONS_NO_ACCESS:
    'You can send your document only to 1 person. Upgrade your subscription now to include multiple recipients',
  LOADING: 'Loading...',
  YOU_HAVE: 'You have',
  ATTEMPTS: 'attempts',
  NETWORK_IS_BUSY: 'The network is busy, please try again later',
  EXPIRED_CONTRACT_NOTIFICATION:
    'Your deadline on the contract has passed. Please, contact contract owner for further actions',
  USER_TERM_EXPIRED: 'Expired',
  EKYC_ACTIONS_STUB_DESCRIPTION:
    'Your actions on this contract must be confirmed using mobile app. Thus you will not be able to continue from web-version. Please, switch to the mobile application and continue acting on this contract using it.',
  EKYC_STUB_TITLE: 'You need to pass verification using mobile application',
  EKYC_STUB_DESCRIPTION:
    'Please, install the application on your smartphone and then open document again from email on your smartphone',
  CONTRACT_UPDATED_SUCCESSFULLY: 'Contract has been updated successfully',
  KEEP_IN_ON_PLACE: 'Want to keep all your signed contracts in one place? Sign up and join Uqoud platform',
  CREATE_ONLY_SIGNATURE_DESCRIPTION:
    'Draw your new signature on the indicated areas. Once finished, tap the Accept button to save your signatures. After saving, tap the Submit button to create another signature',
  CREATE_ONLY_INITIALS_DESCRIPTION:
    'Draw your new initials on the indicated areas. Once finished, tap the Accept button to save your initials. After saving, tap the Submit button to create another initials',
  CREATE_NEW_SIGNATURE: 'Create new signature',
  CREATE_NEW_INITIALS: 'Create new initials',
  SEE_PROGRESS: 'See progress',
  RECIPIENT_INFO: 'Recipient info',
  ACTION: 'Action',
  CANT_START_CYCLE: "You can't start cycle without recipients. Please add recipients",
  EXIT_APP: 'Exit App',
  DO_YOU_WANNA_EXIT: 'Do you want to exit?',
  CONTRACT_VERIFY_FAILED: 'You have failed contract verification',
  CONTACT_SENDER: 'Please, contact Support or the sender of the contract',
  SAVE_RECIPIENT_FOR_RESEND_CONFIRMATION: 'Save recipients data? Note, that it will not be sent yet',
  RECIPIENTS_CYCLE_ORDER_LESS_THAN_ORIGINAL: 'Cycle order cannot be less than the current one',
  RECIPIENT_DELETE_CONFIRMATION_CONTRACT_RESEND:
    'Delete this recipient from a contract? Note, that it will effect contract flow',
  NOT_SENT: 'Not sent',
  RECEIVED: 'Received',
  OPENED: 'Opened',
  COMPLETE: 'Complete',
  RESEND_CONTRACT: 'Resend contract',
  RESEND_CONTRACT_MODAL_HEADER_TEXT:
    'The contract recipients will have the following updates. Check their data before sending. Further editing will not be available without stopping the flow',
  RESEND_CONTRACT_MODAL_FOOTER_TEXT:
    'Apply changes to the recipients? Note, that current signing progress will not be lost',
  RECIPIENTS_WITH_EXPIRED_DEADLINE: 'Error! You have a user with expired deadline. Set him a new deadline to continue',
  CONTRACT_RESENT: 'Contract successfully resent to recipients',
  CORRECTION_INPUT_PLACEHOLDER: 'Add text here',
  CONTRACT_UPDATED_SUCCESSFULLY_UNREGISTERED: 'Contract has been updated successfully',
  COMMENT_FIELD_PLACEHOLDER: 'Add a comment',
  NOTIFICATION_CONTRACT_PENDING: 'Contract pending action',
  NOTIFICATION_CONTRACT_SIGNED: 'Contract was signed/ viewed/ initialed',
  NOTIFICATION_CONTRACT_DECLINED: 'Contract was declined',
  NOTIFICATION_CONTRACT_CHANGED: 'Changes for contract requested',
  NOTIFICATION_CONTRACT_COMMENTED: 'Contract was commented',
  NOTIFICATION_CONTRACT_MENTIONED: 'You were mentioned in comments',
  NOTIFICATION_CONTRACT_SENT: 'Contract was sent',
  NOTIFICATION_CONTRACT_UPDATED: 'Contract was updated',
  NOTIFICATION_CONTRACT_DELETED: 'Contract was deleted',
  NOTIFICATION_CONTRACT_COMPLETED: 'Contract signing was completed',
  NOTIFICATION_CONTRACT_RECIPIENTS_UPDATED: "Contract recipients' list has been updated",
  NOTIFICATION_CONTRACT_VOIDED: 'Contract has been voided',
  NOTIFICATION_CONTRACT_CORRECTIONS_REMOVED: 'Owner removed all corrections and continued signing flow',
  NOTIFICATION_REMINDER_BEFORE_AND_ON_DAY: 'One day before and on the day of contract deadline',
  NOTIFICATION_CHASERS_DEADLINE_MISSED: 'Three days after contract deadline missed',
  IN_APP: 'In-app',
  EMAIL: 'Email',
  PUSH: 'Push',
  COMMENT_UNSAVED_WARNING:
    'You have left unsaved comment. To continue delete it or save it (to send it to other recipients)',
  CONTRACT_ANNOTATION_SAVED: 'Changes saved successfully',
  COMMENT_REPLY: 'Reply',
  CLEAR_ALL_NOTIFICATIONS: 'Are you sure you want to clear all notifications?',
  DEADLINE_IS_EXPIRED: 'Deadline is expired',
  STRIKE_UNSAVED_WARNING:
    'You have left unsaved crossover. To continue delete it or save it (to send it to other recipients)',
  CORRECTION_UNSAVED_WARNING:
    'You have left unsaved correction. To continue delete it or save it (to send it to other recipients)',
  DAY: 'day',
  DAYS: 'days',
  WE_SUGGEST_SUPPORT: 'We suggest you to contact Support and Contract sender',
  SAVE_CONTRACT_WITH_FIELDS:
    'Save entered data in a contract? Make sure you filled all fields correctly, because you could not change it later in a contract',
  RENAMED: 'Renamed',
  REUPLOADED: 'Reuploaded',
  CROSSED_OVER: 'Crossed over',
  LEFT_CORRECTION: 'Left correction',
  ACCEPTED: 'Accepted',
  REJECTED: 'Rejected',
  CANCELLED_ACCEPTATION: 'Cancelled acceptation',
  CANCELLED_REJECTION: 'Cancelled rejection',
  CLEARED_CORRECTIONS: 'Cleared corrections',
  DOWNLOADED: 'Downloaded',
  RESENT: 'Resent',
  ACCEPT: 'Accept',
  REJECT: 'Reject',
  ACCEPTED_BY: 'Accepted by',
  REJECTED_BY: 'Rejected by',
  CONFIRMATION_ATTENTION:
    'Make sure decision on this item is your responsibility. You could not cancel the action after confirmation. Continue?',
  ACCEPTED_SUCCESSFULLY: 'Accepted successfully',
  REJECTED_SUCCESSFULLY: 'Rejected successfully',
  CANCEL_ACCEPTATION_ATTENTION:
    'Cancel acceptation? It will be cleared and other recipients will be able to make a decision again',
  CANCEL_REJECTION_ATTENTION:
    'Cancel rejection? It will be cleared and other recipients will be able to make a decision again',
  CANCEL_ACCEPTATION_SUCCESSFULLY: 'Acceptation cleared successfully',
  CANCEL_REJECTION_SUCCESSFULLY: 'Rejection cleared successfully',
  UPLOAD_FROM: 'Upload From',
  DEVICE: 'Device',
  TEMPLATE: 'Template',
  RECIPIENT: 'Recipient',
  ADD_ONE_MORE_RECIPIENT: 'Add one more recipient',
  RETURNED_WITH_CORRECTIONS: 'Returned with corrections',
  OWNER: 'Owner',
  OPEN_THE_CONTRACT_AS: 'You want to open the contract as:',
  ENTER_PASSWORD_TO_OPEN_DOCUMENT: 'Enter your password to open the document',
  SIGN_UP_FOR: 'Sign Up For',
  PAYMENT: 'payment',
  AMOUNT: 'Amount',
  ANNUAL_SUBSCRIPTION: 'Annual subscription',
  MONTHLY_SUBSCRIPTION: 'Monthly subscription',
  SELECT_OPTION: 'Select option',
  WAITING_PAYMENT: 'Waiting for the payment to be processed',
  SELECT_ANOTHER_PLAN: 'This is your current plan. Please select another one.',
  SAVE_CARD: 'Save card',
  CARD: 'Card',
  HAS_BEEN_SUCCESSFULLY_SAVED: 'has been successfully saved',
  SUBSCRIPTION_UPDATED_SUCCESSFULLY: 'Subscription updated successfully',
  PAY: 'Pay',
  USE_ANOTHER_CARD: 'Use another card',
  MONTH: 'month',
  MANY_CARDS_ERROR: 'You have to many cards now. Please delete some of them and add a card again on Payment section.',
  FAILED_TO_ADD_CARD: 'Failed to add card',
  SPECIFY_CARD: 'Specify card',
  FILL_CARD_DETAILS: 'Please fill your card details to continue',
  SUCCESSFUL_PAYMENT: 'Successful payment',
  SHOW_ALL: 'Show all',
  CARD_SUCCESSFULLY_ADDED: 'Card has been successfully added',
  START_FREE_TRIAL: 'You can start your free trial',
  FREE_TRIAL: 'Free trial',
  WANT_TO_LOG_OUT: 'Do you want to log out?',
  SELECT_SUBSCRIPTION: 'Select subscription',
  START_FREE_TRIAL_BUTTON: 'Start free trial',
  SUBSCRIBE_NOW: 'Subscribe now',
  SELECT_SUBSCRIPTION_TERM: 'Please select subscription term',
  MONTHLY: 'Monthly',
  YEARLY: 'Yearly',
  PLAN: 'plan',
  FREE_TRIAL_CHARGE: 'To confirm your card, we will charge you a minimal fee and return it back.',
  PLAN_IS_CONNECTED: 'plan is connected',
  ERROR_CHECK_URL: 'Error, please check your URL and try again',
  ERROR_FORBIDDEN: 'Please check your URL and try again',
  UPGRADED_SUBSCRIPTION:
    'Your subscription will be upgraded right now, but you will not be able to downgrade until the next payment. Continue?',
  DOWNGRADED_SUBSCRIPTION: 'Your subscription will be downgraded after the current one will expire. Continue?',
  // TODO: DELETE AFTER hooks translation depends on keys instead of index.
  'Make sure decision on this item is your responsibility. You could not cancel the action after confirmation. Continue?':
    'Make sure decision on this item is your responsibility. You could not cancel the action after confirmation. Continue?',
  Cancel: 'Cancel',
}

export default translations
