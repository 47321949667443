import React from 'react'

export const FlagUAESvg: React.FC = (props) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M23.9999 9.37937H7.44824V4.138H23.5862C23.8147 4.138 24 4.3233 24 4.55181L23.9999 9.37937Z"
      fill="#73AF00"
    />
    <path
      d="M23.5861 19.8621H7.44824V14.6207H23.9999V19.4483C23.9999 19.6768 23.8146 19.8621 23.5861 19.8621Z"
      fill="#464655"
    />
    <path d="M23.9998 9.37918H7.44824V14.6203H23.9998V9.37918Z" fill="#F5F5F5" />
    <path
      d="M7.44828 19.8621H0.413812C0.185297 19.8621 0 19.6768 0 19.4483V4.55175C0 4.32324 0.185297 4.13794 0.413812 4.13794H7.44828V19.8621Z"
      fill="#FF4B55"
    />
  </svg>
)
