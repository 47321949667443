import { DefaultTheme } from 'styled-components'

import * as colors from './colors'

export interface StyledThemeScheme extends DefaultTheme {
  breakpoints: typeof breakpoints
  devices: {
    mobile: string
    tablet: string
    laptop: string
  }
  textStyles: {
    [key: string]: { [key: string]: string }
  }
  palette: {
    [key: string]: string
  }
  sizes: {
    layout: { [key: string]: string }
    icon: { [key: string]: string }
  }
  shadow: string
  spacing8x: Array<string>
  spacing4x: Array<string>
}

const breakpoints = {
  mobile: '767px',
  tablet: '1024px',
  laptop: '1439px',
}

const StyledTheme: StyledThemeScheme = {
  breakpoints,
  devices: {
    mobile: `(max-width: ${breakpoints.mobile})`,
    tablet: `(max-width: ${breakpoints.tablet})`,
    laptop: `(max-width: ${breakpoints.laptop})`,
  },
  sizes: {
    layout: {
      headerHeight: '80px',
      headerHeightMobile: '70px',
      menuWidth: '80px',
    },
    icon: {
      default: '24px',
      small: '14px',
      extraSmall: '16px',
      medium: '18px',
      high: '40px',
      large: '48px',
      inherit: 'inherit',
    },
  },
  textStyles: {
    bodySmall12: {
      'font-family': 'Tahoma',
      'font-size': '12px',
      'line-height': '18px',
    },
    body14: {
      'font-family': 'Tahoma',
      'font-size': '14px',
      'line-height': '21px',
    },
    body16: {
      'font-family': 'Tahoma',
      'font-size': '16px',
      'line-height': '21px',
    },
    H1: {
      'font-family': 'Tahoma-Bold',
      'font-size': '24px',
      'line-height': '29px',
    },
    H2: {
      'font-family': 'Tahoma-Bold',
      'font-size': '20px',
      'line-height': '24px',
    },
    H3: {
      'font-family': 'Tahoma-Bold',
      'font-size': '18px',
      'line-height': '21px',
    },
    H4: {
      'font-family': 'Tahoma-Bold',
      'font-size': '16px',
      'line-height': '24px',
    },
    bold14: {
      'font-family': 'Tahoma-Bold',
      'font-size': '14px',
      'line-height': '20px',
    },
    bold16: {
      'font-family': 'Tahoma-Bold',
      'font-size': '16px',
      'line-height': '20px',
    },
    tableColumn: {
      'font-family': 'Tahoma',
      'font-size': '14px',
      'line-height': '16px',
      'text-transform': 'uppercase',
    },
    buttons_14: {
      'font-family': 'Tahoma-Bold',
      'font-size': '14px',
      'line-height': '16px',
      'text-transform': 'uppercase',
    },
    regularH1: {
      'font-family': 'Tahoma',
      'font-size': '24px',
      'line-height': '29px',
    },
    errorTitle32: {
      'font-family': 'Tahoma',
      'font-size': '32px',
      'line-height': '39px',
    },
    errorTitle28: {
      'font-family': 'Tahoma',
      'font-size': '28px',
      'line-height': '34px',
    },
  },
  palette: {
    fontFamily: 'Tahoma',
    lineHeight: '21px',
    ...colors,
  },
  shadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
  spacing8x: ['0px', '8px', '16px', '24px', '32px', '40px', '48px', '56px', '64px', '72px', '80px', '88px', '96px'],
  spacing4x: ['0px', '4px', '8px', '12px', '16px', '20px'],
}

export default StyledTheme
