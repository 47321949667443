import React from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { subscriptionCardsLastAddedCardSelector } from '../../../../../store/subscription/cards/cards.selectors'
import translations from '../../../../../translations/keys'
import { CardPaymentOption } from '../../../../../types/cards'
import { SubscriptionPaymentTerms } from '../../../../../types/subscriptionPayment'
import { generateCardMask } from '../../../../../utils/cards/generateCardMask'
import { location } from '../../../../../utils/location'
import { HandshakeIcon } from '../../../../assets/HandshakeIcon'
import AuthorizationBox from '../../../../components/Authorization/AuthorizationBox'
import Box from '../../../../ui/Box'
import Button from '../../../../ui/Button'
import Typography from '../../../../ui/Typography'
import { TYPOGRAPHY_NAMES } from '../../../../ui/Typography/Typography.types'
import { LOGOS } from '../../CardSpecification.constants'
import ConnectedPlan from '../ConnectedPlan'

import { SuccessfulPaymentProps } from './SuccessfulPayment.types'

export const SuccessfulPayment: React.FC<SuccessfulPaymentProps> = ({ plan, term }) => {
  const history = useHistory()

  const card = useSelector(subscriptionCardsLastAddedCardSelector)

  const onContinue = () => {
    history.push(location.editProfileUrl())
  }

  if (!card) {
    return null
  }

  return (
    <AuthorizationBox noLogo>
      <Box pl={10} pr={10} pb={8}>
        <Box display="flex" flexDirection="column" alignItems="center" width={320}>
          <HandshakeIcon />
          <Box pt={3} pb={3}>
            <Typography name={TYPOGRAPHY_NAMES.H1}>{translations.SUCCESSFUL_PAYMENT}!</Typography>
          </Box>
          <ConnectedPlan features={plan.features} />
          <Box mt={4} display="flex" justifyContent="space-between" alignItems="center" width="100%">
            <Typography name={TYPOGRAPHY_NAMES.body16} color="grey">
              {translations.CARD}
            </Typography>
            <Box display="flex" alignItems="center">
              {LOGOS[CardPaymentOption.VISA]}
              <Box ml={1.5}>
                <Typography name={TYPOGRAPHY_NAMES.body16}>{generateCardMask(card.lastDigits)}</Typography>
              </Box>
            </Box>
          </Box>
          <Box mt={1} display="flex" justifyContent="space-between" alignItems="center" width="100%">
            <Typography name={TYPOGRAPHY_NAMES.body16} color="grey">
              {translations.AMOUNT}
            </Typography>
            <Typography name={TYPOGRAPHY_NAMES.bold16}>
              ${term === SubscriptionPaymentTerms.YEAR ? plan.yearPrice : plan.monthPrice}
            </Typography>
          </Box>
          <Box width="100%" mt={5}>
            <Button fullWidth onClick={onContinue}>
              {translations.CONTINUE}
            </Button>
          </Box>
        </Box>
      </Box>
    </AuthorizationBox>
  )
}
