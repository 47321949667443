import React from 'react'

import { TYPOGRAPHY_COMPONENTS, TYPOGRAPHY_NAMES } from '../../ui/Typography/Typography.types'
import translations from '../../../translations/keys'
import { OneDriveSvg } from '../../assets/OneDriveSvg'
import { GoogleDriveSvg } from '../../assets/GoogleDriveSvg'
import Typography from '../../ui/Typography'
import Box from '../../ui/Box'

import { CloudStorageItem } from './components/CloudStorageItem/CloudStorageItem'
import { StyledStorages } from './CloudStorage.styles'

const cloudStoragesMock = [
  {
    title: 'Google Drive',
    statusText: 'Disconnected',
    icon: <GoogleDriveSvg />,
    isConnected: true,
  },
  {
    title: 'OneDrive',
    statusText: 'Connected (a.shalhoub@gmail.com)',
    icon: <OneDriveSvg />,
    isConnected: false,
  },
]

export const CloudStorage = () => {
  const items = cloudStoragesMock.map((item, index) => <CloudStorageItem key={index} item={item} />)
  return (
    <Box pt={1}>
      <Typography name={TYPOGRAPHY_NAMES.H3} component={TYPOGRAPHY_COMPONENTS.h3}>
        {translations.CONNECT_CLOUD_STORAGE}
      </Typography>
      <StyledStorages>{items}</StyledStorages>
    </Box>
  )
}
