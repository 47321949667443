import { RECIPIENT_ATTEMPTS_FINISHED } from '../../../constants/contractOTPVerification'
import { FETCH_STATUSES } from '../../../types/fetch'
import { RootState } from '../../rootReducer.types'

export const otpVerificationRecipientId = (state: RootState) => state.contracts.otpVerification.recipientId
export const otpVerificationNotification = (state: RootState) => state.contracts.otpVerification.notification
export const otpVerificationAttemptsFinished = (state: RootState) =>
  state.contracts.otpVerification.sendCode.error?.lastErrorCode === RECIPIENT_ATTEMPTS_FINISHED ||
  state.contracts.otpVerification.verify.error?.lastErrorCode === RECIPIENT_ATTEMPTS_FINISHED ||
  state.contracts.otpVerification.attempts.count === 0

export const otpVerificationPhoneNumber = (state: RootState) => state.contracts.otpVerification.phoneNumber

export const otpVerificationVerifyFetchStatus = (state: RootState) => state.contracts.otpVerification.verify.fetchStatus
export const otpVerificationVerifyError = (state: RootState) => state.contracts.otpVerification.verify.error
export const otpVerificationVerifyIsSuccess = (state: RootState) =>
  state.contracts.otpVerification.verify.fetchStatus === FETCH_STATUSES.SUCCESS
export const otpVerificationToken = (state: RootState) => state.contracts.otpVerification.verify.token
export const otpVerificationVerifyIsError = (state: RootState) =>
  state.contracts.otpVerification.verify.fetchStatus === FETCH_STATUSES.FAILURE

export const otpVerificationSendCodeError = (state: RootState) => state.contracts.otpVerification.sendCode.error
export const otpVerificationSendCodeFetchStatus = (state: RootState) =>
  state.contracts.otpVerification.sendCode.fetchStatus

export const otpVerificationAttemptsCount = (state: RootState) => state.contracts.otpVerification.attempts.count
