import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'

import { TEMPLATE_PREVIEW_MODALS } from '../../../../constants/templatePreview'
import { closeModal } from '../../../../../store/modals/modals.actions'
import { modalsVisibleSelector } from '../../../../../store/modals/modals.selectors'
import SaveWithoutSLMModal from '../../../../components/Modals/TemplatePreviewModals/SaveWithoutSLMModal'
import { TEMPLATES_MODALS } from '../../../../../constants/templates'
import DeleteTemplateModal from '../../../../components/Modals/TemplatesModals/DeleteTemplateModal'
import { location } from '../../../../../utils/location'

export const Modals = () => {
  const history = useHistory()
  const dispatch = useDispatch()

  const showSaveWithoutSLM = useSelector(modalsVisibleSelector(TEMPLATE_PREVIEW_MODALS.SAVE_TEMPLATE_WITHOUT_SLM))
  const handleSaveWithoutSLMClose = () => {
    dispatch(closeModal(TEMPLATE_PREVIEW_MODALS.SAVE_TEMPLATE_WITHOUT_SLM))
  }

  const showDeleteTemplate = useSelector(modalsVisibleSelector(TEMPLATES_MODALS.DELETE_TEMPLATE))
  const handleDeleteTemplateClose = () => {
    dispatch(closeModal(TEMPLATES_MODALS.DELETE_TEMPLATE))
  }
  const onDeleted = useCallback(() => {
    history.push(location.templatesAllUrl())
  }, [])

  return (
    <>
      {showSaveWithoutSLM && <SaveWithoutSLMModal onClose={handleSaveWithoutSLMClose} />}
      {showDeleteTemplate && <DeleteTemplateModal onClose={handleDeleteTemplateClose} onDeleted={onDeleted} />}
    </>
  )
}
