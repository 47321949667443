import { combineEpics, Epic, ofType } from 'redux-observable'
import { catchError, map, mergeMap, takeUntil } from 'rxjs/operators'

import { catchFetchError } from '../../../utils/catchFetchError'
import { contractsService } from '../../../api'

import * as ACTIONS from './upload.actions'

export const fetchUpload: Epic = (action$) =>
  action$.pipe(
    ofType(ACTIONS.fetchUploadTypes.request),
    mergeMap(({ payload }) =>
      contractsService.fetchUploadContract(payload.body).pipe(
        map(({ response }) => ACTIONS.fetchUploadSuccess(response)),
        catchError(catchFetchError(ACTIONS.fetchUploadFailure)),
        takeUntil(action$.pipe(ofType(ACTIONS.fetchUploadTypes.success, ACTIONS.fetchUploadTypes.failure)))
      )
    )
  )

export const uploadEpics = combineEpics(fetchUpload)
