import { combineEpics } from 'redux-observable'

import { authEpics } from './auth/auth.epics'
import { countriesEpics } from './countries/countries.epics'
import { phonesEpics } from './phones/phones.epics'
import { industriesEpics } from './industries/industries.epics'
import { registrationEpics } from './registration/registration.epics'
import { forgotPasswordEpics } from './forgotPassword/forgotPassword.epics'
import { restorePasswordEpics } from './restorePassword/restorePassword.epics'
import { changePasswordEpics } from './changePassword/changePassword.epics'
import { editProfileEpics } from './editProfile/editProfile.epics'
import { verifyPasswordEpics } from './verifyPassword/verifyPassword.epics'
import { contactsEpics } from './contacts/contacts.epics'
import { createSignatureEpics } from './signatures/signatures.epics'
import { contractsEpics } from './contracts/contracts.epics'
import { templatesEpics } from './templates/templates.epics'
import { ekycEpics } from './ekyc/ekyc.epics'
import { subscriptionEpics } from './subscription/subscription.epics'
import { apiIntegrationEpics } from './apiIntegration/apiIntegration.epics'
import { preferencesEpics } from './preferences/preferences.epics'
import { userNotificationsEpics } from './userNotifications/userNotifications.epics'

export default combineEpics(
  authEpics,
  countriesEpics,
  phonesEpics,
  industriesEpics,
  registrationEpics,
  forgotPasswordEpics,
  restorePasswordEpics,
  changePasswordEpics,
  editProfileEpics,
  verifyPasswordEpics,
  contactsEpics,
  createSignatureEpics,
  contractsEpics,
  templatesEpics,
  ekycEpics,
  subscriptionEpics,
  apiIntegrationEpics,
  preferencesEpics,
  userNotificationsEpics
)
