import React from 'react'

export const FlagUKSvg: React.FC = (props) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M23.5861 19.862H0.413811C0.185296 19.862 0 19.6767 0 19.4482V4.55172C0 4.3232 0.185296 4.13791 0.413811 4.13791H23.5861C23.8147 4.13791 24 4.3232 24 4.55172V19.4482C23.9999 19.6767 23.8146 19.862 23.5861 19.862Z"
      fill="#41479B"
    />
    <path
      d="M23.9999 4.55172C23.9999 4.3232 23.8146 4.13791 23.5861 4.13791H21.7348L14.0689 9.1604V4.13791H9.93095V9.1604L2.26508 4.13791H0.413811C0.185296 4.13791 0 4.3232 0 4.55172V5.62192L6.57696 9.93098H0V14.0689H6.57696L0 18.378V19.4482C0 19.6767 0.185296 19.862 0.413811 19.862H2.26513L9.93104 14.8395V19.862H14.069V14.8395L21.7349 19.862H23.5862C23.8147 19.862 24 19.6767 24 19.4482V18.378L17.423 14.0689H23.9999V9.93103H17.4229L23.9999 5.62192V4.55172Z"
      fill="#F5F5F5"
    />
    <path
      d="M23.9999 10.7586H13.2413V4.13791H10.7586V10.7586H0V13.2413H10.7586V19.862H13.2413V13.2413H23.9999V10.7586Z"
      fill="#FF4B55"
    />
    <path
      d="M8.38815 14.0689L0.0117188 19.5062C0.0410155 19.7061 0.20578 19.862 0.413764 19.862H0.983715L9.90821 14.069H8.38815V14.0689Z"
      fill="#FF4B55"
    />
    <path
      d="M16.2369 14.0689H14.7168L23.6283 19.8534C23.836 19.8313 24 19.6617 24 19.4482V19.108L16.2369 14.0689Z"
      fill="#FF4B55"
    />
    <path
      d="M0 5.0085L7.58336 9.931H9.10342L0.241077 4.17825C0.0995152 4.24402 0 4.38535 0 4.5517V5.0085Z"
      fill="#FF4B55"
    />
    <path
      d="M15.5894 9.93103L23.9859 4.48058C23.9511 4.28736 23.7898 4.13797 23.5865 4.13797H22.9937L14.0693 9.93103H15.5894Z"
      fill="#FF4B55"
    />
  </svg>
)
