import { FetchFailureAction, FETCH_STATUSES } from '../../../types/fetch'
import { createReducer } from '../../createReducer'

import * as ACTIONS from './briefList.actions'
import * as TYPES from './briefList.types'

type Actions = FetchFailureAction | TYPES.FetchBriefListSuccess

export const initialState: TYPES.BriefListState = {
  fetchStatus: FETCH_STATUSES.IDLE,
  error: null,
  data: [],
}

export const briefList = createReducer<TYPES.BriefListState, Actions>(initialState, {
  [ACTIONS.fetchBriefListTypes.request]: (state) => ({
    ...state,
    fetchStatus: FETCH_STATUSES.REQUEST,
  }),
  [ACTIONS.fetchBriefListTypes.success]: (state, action) => ({
    ...state,
    data: (action as TYPES.FetchBriefListSuccess).payload.data,
    fetchStatus: FETCH_STATUSES.SUCCESS,
  }),
  [ACTIONS.fetchBriefListTypes.failure]: (state, action) => ({
    ...state,
    fetchStatus: FETCH_STATUSES.FAILURE,
    error: (action as FetchFailureAction).payload,
  }),
  [TYPES.BriefListActions.CLEAN_BRIEF_TEMPLATES]: () => ({ ...initialState }),
})
