import { useHistory } from 'react-router-dom'

import { useGoToAfterSuccess } from '../../hooks/useGoToAfterSuccess'
import { FETCH_STATUSES } from '../../types/fetch'

export const useHistoryPushAfterSuccess = (path: string, fetchStatus: FETCH_STATUSES, unmountHandler?: () => void) => {
  const history = useHistory()
  const cb = () => {
    history.push(path)
  }

  useGoToAfterSuccess(cb, fetchStatus, unmountHandler)
}
