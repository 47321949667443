import { Action } from 'redux'

import { FetchFailurePayload, FETCH_STATUSES } from '../../types/fetch'
import { VerificationPasswordBody } from '../../types/verificationPassword'
import { VerifyPasswordKeys } from '../../types/verifyPassword'

export interface VerifyPasswordState {
  [key: string]: {
    fetchStatus: FETCH_STATUSES
    error?: FetchFailurePayload
    token: string
  }
}

export enum VerifyPasswordActions {
  'FETCH_VERIFY_PASSWORD' = 'FETCH_VERIFY_PASSWORD',
  'CLEAN_VERIFY_PASSWORD' = 'CLEAN_VERIFY_PASSWORD',
}

export interface FetchVerifyPasswordAction extends Action {
  payload: { body: VerificationPasswordBody; key: VerifyPasswordKeys }
}

export interface FetchVerifyPasswordSuccessAction extends Action {
  payload: { token: string; key: VerifyPasswordKeys }
}

export interface CleanVerifyPassword extends Action {
  payload: { key: VerifyPasswordKeys }
}
