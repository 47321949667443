import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { useTheme } from 'styled-components'

import useAuth from '../../../hooks/useAuth'
import { authUserSelector } from '../../../store/auth/auth.selectors'
import { isBannerVisibleSelector } from '../../../store/banner/banner.selectors'
import { languagesSelector } from '../../../store/language/language.selectors'
import { subscriptionUserPlanDataSelector } from '../../../store/subscription/userPlan/userPlan.selectors'
import { StyledThemeScheme } from '../../../themes/light'
import translations from '../../../translations/keys'
import { location } from '../../../utils/location'
import { HamburgerIcon } from '../../assets/icons/HamburgerIcon'
import useMediaQuery from '../../hooks/useMediaQuery'
import useNavControls from '../../hooks/useNavControls'
import Button from '../../ui/Button'
import { BUTTON_VARIANTS } from '../../ui/Button/Button.types'
import LangMenu from '../../ui/LangMenu'
import Link from '../../ui/Link'
import UserMenu from '../../ui/UserMenu'
import Notifications from '../Notifications'

import * as Styled from './Header.styles'
import { HeaderProps } from './Header.types'

export const Header: React.FC<HeaderProps> = ({ simple = false }) => {
  const history = useHistory()
  const { logout } = useAuth()
  const { setMainmenuOpen } = useNavControls()
  const theme = useTheme() as StyledThemeScheme
  const mobileScreen = useMediaQuery(theme.devices.mobile)
  const tabletScreen = useMediaQuery(theme.devices.tablet)
  const user = useSelector(authUserSelector)
  const userPlan = useSelector(subscriptionUserPlanDataSelector)
  const isBannerVisible = useSelector(isBannerVisibleSelector)
  const lang = useSelector(languagesSelector).language
  const { t } = useTranslation()

  const handleMainmenuOpen = () => {
    setMainmenuOpen((prevValue) => !prevValue)
  }

  const handleLogout = () => {
    logout()
  }

  const onUpgrade = () => {
    history.push(location.subscriptionPlansUrl())
  }

  return (
    <Styled.Header data-testid="header-wrapper" isBannerVisible={isBannerVisible}>
      <Styled.LogoWrapper data-testid="header-logo-wrapper">
        <Link data-testid="header-logo-link" to={location.dashboardUrl()}>
          <Styled.Logo data-testid="header-logo-icon" withText={false} />
        </Link>
      </Styled.LogoWrapper>
      {!simple && tabletScreen && (
        <Button variant={BUTTON_VARIANTS.LINK} onClick={handleMainmenuOpen}>
          <HamburgerIcon color="grey" />
        </Button>
      )}
      <Styled.Nav data-testid="header-nav">
        {/* {!simple && user && ( */}
        {user && (
          <UserMenu
            firstName={user.firstName}
            lastName={user.lastName}
            plan={userPlan?.name || ''}
            mobile={mobileScreen}
            tablet={tabletScreen}
            onLogout={handleLogout}
          />
        )}
        <Styled.Box>
          <LangMenu lang={lang} />
        </Styled.Box>
      </Styled.Nav>
      {!simple && !mobileScreen && !userPlan?.highest && (
        <Styled.Box>
          <Button compact onClick={onUpgrade}>
            {t(translations.UPGRADE)}
          </Button>
        </Styled.Box>
      )}
      <Styled.Box>
        <Notifications />
      </Styled.Box>
    </Styled.Header>
  )
}
