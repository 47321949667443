import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { useDispatchUnmount } from '../useDispatchUnmount'
import { clearCorrections, fetchCorrections } from '../../store/contracts/corrections/corrections.actions'

export const useCorrectionsFetch = (id: string) => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchCorrections(id))
  }, [id])

  useDispatchUnmount(clearCorrections)
}
