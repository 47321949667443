import React, { useEffect } from 'react'
import debounce from 'lodash.debounce'

import { UnderlineProps } from './Underline.types'
import { StyledLine } from './Underline.styles'

const RECALCULATE_ATTRS_WAIT = 500

export const Underline: React.FC<UnderlineProps> = ({ refs, activeId, finishAnimating, animating }) => {
  const [{ x, width }, setAttributes] = React.useState({
    x: 0,
    width: 0,
  })

  const updateAttributes = React.useCallback(() => {
    if (refs && refs[activeId] && refs[activeId].current) {
      setAttributes({
        x: refs[activeId].current?.offsetLeft || 0,
        width: refs[activeId].current?.getBoundingClientRect().width || 0,
      })
    }
  }, [activeId, refs])

  useEffect(() => {
    updateAttributes()
  }, [updateAttributes])

  useEffect(() => {
    const recalculateAttrs = debounce(() => {
      updateAttributes()
    }, RECALCULATE_ATTRS_WAIT)

    window.addEventListener('resize', recalculateAttrs)
    return () => {
      window.removeEventListener('resize', recalculateAttrs)
    }
  }, [updateAttributes])

  return (
    <StyledLine
      animate={{
        x,
        width,
      }}
      onAnimationComplete={finishAnimating}
      animating={animating}
    />
  )
}
