import { useSelector } from 'react-redux'

import * as PLANS_SELECTORS from '../../../../store/subscription/plans/plans.selectors'
import { useShowFetchError } from '../../../hooks/useShowFetchError'

export const useErrors = () => {
  const fetchStatus = useSelector(PLANS_SELECTORS.subscriptionPlansFetchStatusSelector)
  const error = useSelector(PLANS_SELECTORS.subscriptionPlansErrorSelector)

  useShowFetchError(fetchStatus, error)
}
