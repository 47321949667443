import { DocumentPickerResponse } from 'react-native-document-picker'

export enum UploadDocumentFields {
  fileName = 'fileName',
  folderName = 'folderName',
}

export interface UploadDocumentForm {
  fileName: string
  folderName: string
}

export interface UploadDocumentBody extends UploadDocumentForm {
  [key: string]: string | File
  file: File
}

export interface UploadDocumentBodyNative extends UploadDocumentForm {
  file: DocumentPickerResponse
}

export interface UploadDocumentResponse {
  createAt: string
  id: string
  mime: string
  name: string
  user: { id: string }
}
export interface UploadDocumentFolderResponse {
  [key: string]: string
  id: string
  name: string
}

export type UploadDocumentFoldersResponse = {
  data: UploadDocumentFolderResponse[]
  totalCount: number
}

export interface UploadDocumentFolder {
  name: string
}
