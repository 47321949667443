import { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import translations from '../../../translations/keys'
import * as PLACEMENT_SELECTORS from '../../../store/contracts/placement/placement.selectors'
import * as SIGNING_SELECTORS from '../../../store/contracts/signing/signing.selectors'
import { getISODate } from '../../../utils/date/date'
import { RecipientActions, RecipientAuthentication } from '../../../types/recipients'
import { PlacementById } from '../../../types/placement'
import { setSignature } from '../../../store/contracts/signing/signing.actions'
import { openModal } from '../../../store/modals/modals.actions'
import { CONTRACT_SIGN_MODALS } from '../../constants/contractSign'

interface Props {
  handleChangeSignature: (placeId: string, isInitials: boolean) => void
  handleEmptySignature: (placeId: string, isInitials: boolean) => void
}

export const usePlacementById = ({ handleChangeSignature, handleEmptySignature }: Props): PlacementById => {
  const dispatch = useDispatch()
  const placementById = useSelector(PLACEMENT_SELECTORS.placementByIdSelector)
  const defaultSign = useSelector(SIGNING_SELECTORS.signingDefaultSignDataSelector)
  const data = useSelector(SIGNING_SELECTORS.signingContractDocumentDataSelector)
  const recipientAuthentication = useSelector(SIGNING_SELECTORS.signingRecipientAuthentication)
  const isInitials = data?.recipient.action === RecipientActions.INITIAL

  return useMemo(
    () =>
      Object.keys(placementById).reduce<PlacementById>((acc, id) => {
        const placementData = placementById[id]

        if (!placementData.readonly) {
          const handleDefaultSign = () => {
            if (
              recipientAuthentication === RecipientAuthentication.EKYC ||
              recipientAuthentication === RecipientAuthentication.EKYC_ID
            ) {
              dispatch(openModal(CONTRACT_SIGN_MODALS.SIGNING_ACTIONS_EKYC_VERIFICATION))
            } else if (defaultSign.id) {
              dispatch(
                setSignature({
                  placeId: id,
                  signatureId: defaultSign.id,
                  image: defaultSign.image,
                  timestamp: getISODate(new Date()),
                })
              )
            } else {
              handleEmptySignature(id, isInitials)
            }
          }

          return {
            ...acc,
            [id]: {
              ...placementById[id],
              onSign: handleDefaultSign,
              actions: [
                {
                  name: translations.CHANGE,
                  onClick: () => handleChangeSignature(id || '', isInitials),
                  icon: 'interaction',
                },
                {
                  name: translations.CLEAR,
                  onClick: () => {
                    dispatch(setSignature({ placeId: id }))
                  },
                  icon: 'closeCircle',
                },
              ],
            },
          }
        }

        return {
          ...acc,
          [id]: {
            ...placementById[id],
          },
        }
      }, {}),
    [placementById, defaultSign, isInitials, handleChangeSignature, handleEmptySignature, recipientAuthentication]
  )
}
