import { Ajax } from '../ajax'
import {
  SigningContractTotalPagesResponse,
  SigningContractResponse,
  FetchSignArgs,
  FetchUnregisteredSignArgs,
} from '../../types/signingContract'
import { FetchDeclineArgs } from '../../types/declineSigning'
import { ContractVerificationResponse } from '../../types/contractVerificaiton'
import { OTPVerificationAttemptsResponse, OTPVerificationRequestBody } from '../../types/otpVerification'
import { EKYCAttemptsResponse } from '../../types/ekyc'
import { convertToFormData } from '../../utils/convertToFormData/convertToFormData.native'
import { getRecipientHeaderFormData, getRecipientHeaders } from '../../utils/signing/getRecipientHeaders'
import {
  DocCancelCommentsConfirmation,
  DocCommentRequestBody,
  DocCommentsConfirmRequest,
  DocCommentsResponseBody,
  DocCommentsThreadResponse,
} from '../../types/comment'
import { Params, prepareUrlParams } from '../../utils/prepareUrlParams/prepareUrlParams'
import { FileForFormData } from '../../types/formData'

class SigningService extends Ajax {
  pathname = 'signing'

  fetchContract = (contractId: string, recipientToken: string) =>
    this.getJSON<SigningContractResponse>(`${this.pathname}/${contractId}`, getRecipientHeaders(recipientToken))

  fetchContractTotalPages = (id: string, recipientToken: string) =>
    this.getJSON<SigningContractTotalPagesResponse>(
      `${this.pathname}/${id}/document/pages`,
      getRecipientHeaders(recipientToken)
    )

  getContractPageSrc = (id: string, index: number) => `${this.serverUrl}/${this.pathname}/${id}/document/${index}`

  fetchSign = ({ recipientId, queryParams, body }: FetchSignArgs) =>
    this.post(`${this.pathname}/sign/${recipientId}?verify=${queryParams.verify}`, body)

  fetchUnregisteredSign = ({ recipientId, queryParams, body, recipientToken }: FetchUnregisteredSignArgs) =>
    this.post(
      `${this.pathname}/unregisteredsign/${recipientId}?verify=${queryParams.verify}`,
      body,
      getRecipientHeaders(recipientToken)
    )

  fetchDecline = ({ recipientId, body, recipientToken, queryParams }: FetchDeclineArgs) =>
    this.post(
      `${this.pathname}/decline/${recipientId}?verify=${queryParams.verify}`,
      body,
      getRecipientHeaders(recipientToken)
    )

  fetchDownloadContract = (contractId: string, recipientToken: string) =>
    this.request({
      url: `${this.pathname}/${contractId}/download`,
      responseType: 'text',
      headers: getRecipientHeaders(recipientToken),
    })

  fetchClearAndContinueContract = (contractId: string) => this.delete(`${this.pathname}/corrections/${contractId}`)

  fetchVerifyOTP = (recipientId: string, body: OTPVerificationRequestBody, recipientToken: string) =>
    this.post(`${this.pathname}/${recipientId}/verifyotp`, body, getRecipientHeaders(recipientToken))

  fetchVerification = (contractId: string, recipientToken: string) =>
    this.getJSON<ContractVerificationResponse>(
      `${this.pathname}/${contractId}/verified`,
      getRecipientHeaders(recipientToken)
    )

  fetchSendOTP = (recipientId: string, recipientToken: string) =>
    this.getJSON(`${this.pathname}/${recipientId}/sendotp`, getRecipientHeaders(recipientToken))

  fetchAttempts = (contractId: string, recipientToken: string) =>
    this.getJSON<OTPVerificationAttemptsResponse>(
      `${this.pathname}/${contractId}/attempts`,
      getRecipientHeaders(recipientToken)
    )

  fetchVerifyNone = (recipientId: string, recipientToken: string) =>
    this.post(`${this.pathname}/${recipientId}/verifynone`, undefined, getRecipientHeaders(recipientToken))

  fetchVerifyEKYC = (
    fileForFormData: { [key: string]: FileForFormData },
    headers: { [key: string]: string },
    recipientId: string,
    recipientToken: string
  ) =>
    this.post(`${this.pathname}/${recipientId}/verifyekyc`, convertToFormData(fileForFormData), {
      ...headers,
      ...getRecipientHeaderFormData(recipientToken),
    })

  fetchVerifyEKYC_ID = (
    fileForFormData: { [key: string]: FileForFormData },
    headers: { [key: string]: string },
    recipientId: string,
    recipientToken: string
  ) =>
    this.post(`${this.pathname}/${recipientId}/verifyekycplusid`, convertToFormData(fileForFormData), {
      ...headers,
      ...getRecipientHeaderFormData(recipientToken),
    })

  fetchEKYCAttempts = (contractId: string, recipientToken: string) =>
    this.getJSON<EKYCAttemptsResponse>(`${this.pathname}/${contractId}/attempts`, getRecipientHeaders(recipientToken))

  fetchComments = (contractId: string) =>
    this.getJSON<DocCommentsResponseBody>(`${this.pathname}/comment/${contractId}/places`)

  fetchCommentsThread = (params: Params) =>
    this.getJSON<DocCommentsThreadResponse>(`${this.pathname}/comment/${params.contractId}?${prepareUrlParams(params)}`)

  fetchCreateComment = (contractId: string, comment: DocCommentRequestBody) =>
    this.post(`${this.pathname}/comment/${contractId}`, comment)

  fetchConfirmComments = ({ contractId, status, x, y, page }: DocCommentsConfirmRequest) =>
    this.patch(`${this.pathname}/comment/${contractId}?${prepareUrlParams({ x, y, page })}`, {
      status,
    })

  fetchCancelCommentsConfirmation = ({ contractId, x, y, page }: DocCancelCommentsConfirmation) =>
    this.patch(`${this.pathname}/comment/${contractId}?${prepareUrlParams({ x, y, page })}`, {
      status: null,
    })
}

export const signingService = new SigningService()
