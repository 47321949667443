import styled from 'styled-components'

export const StyledSearchWrapper = styled.div`
  flex: 1;
  max-width: 384px;

  ${({ theme }) => `@media ${theme.devices.mobile} {
    max-width: 100%;
  }`}
`

export const StyledUploadMenuWrapper = styled.div`
  width: 208px;
  margin-left: auto;
`

export const StyledMessageWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 615px;
  max-width: 100%;
`

export const StyledContractsWrapper = styled.div`
  position: relative;

  ${({ theme }) => `@media ${theme.devices.mobile} {
    display: flex;
    flex-direction: column;
    max-height: calc(100% - 80px);
    overflow: hidden;
  }`}
`
