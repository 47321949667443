import React, { useState, useEffect, useLayoutEffect } from 'react'
import { useSelector } from 'react-redux'

import Resizer from '../Resizer'
import { PointerModes } from '../DocViewer/DocViewer.types'
import ConfirmationActions from '../ConfirmationActions'
import ConfirmationLabel from '../ConfirmationLabel'
import { BoxSize } from '../ConfirmationLabel/ConfirmationLabel.types'
import { strikesByIdSelector } from '../../../store/contracts/strikes/strikes.selectors'
import Tooltip from '../../components/Tooltip'
import Box from '../Box'

import { StrikethroughProps } from './Strikethrough.types'
import * as Styled from './Strikethrough.styles'

const MINIMAL_SIZE = 16

const SvgLines: React.FC = (props) => (
  <Styled.Svg width="100%" height="100%" {...props}>
    <Styled.Line data-testid="strikethrough-line" x1="0" y1="0" x2="100%" y2="100%" />
    <Styled.Line data-testid="strikethrough-line" x1="100%" y1="0" x2="0" y2="100%" />
  </Styled.Svg>
)

export const Strikethrough: React.FC<StrikethroughProps> = ({
  id,
  isSelected = false,
  isEditable = false,
  x,
  y,
  width,
  height,
  status,
  onSave,
  onDelete,
  onConfirm,
  onCancelConfirmation,
  acceptOrRejectByName,
  strikeOwner,
  acceptOrRejectTimestamp,
  currentUserEmail,
  ...props
}) => {
  const strike = useSelector(strikesByIdSelector)
  const [visible, setVisible] = useState(true)
  const [isFullName, setIsFullName] = useState(false)
  const [isHide, setIsHide] = useState(false)
  const [clientCoords, setCoords] = useState({ x: 0, y: 0 })
  const [expandLabel, setExpandLabel] = useState(false)
  const wrapperRef = React.useRef<HTMLDivElement>(null)
  const labelRef = React.useRef<HTMLDivElement>(null)
  const confirmationActionsRef = React.useRef<HTMLDivElement>(null)
  const topActionsRef = React.useRef<HTMLDivElement>(null)

  const node = wrapperRef.current
  useEffect(() => {
    node?.addEventListener('mousemove', handleEvent)
    node?.addEventListener('mouseleave', hideTooltip)

    return () => {
      node?.removeEventListener('mousemove', handleEvent)
      node?.removeEventListener('mouseleave', hideTooltip)
    }
  }, [node])

  useLayoutEffect(() => {
    if (!isFullName && isHide) {
      setIsFullName(true)
    }
  }, [clientCoords.x, clientCoords.y])

  useEffect(() => {
    if (!isSelected) {
      setVisible(true)
    }
  }, [isSelected])

  useEffect(() => {
    const onClick = (event: MouseEvent) => {
      const isLabelClick = labelRef?.current?.contains(event.target as HTMLElement)
      if (!isLabelClick && !isSelected) {
        setExpandLabel(false)
      } else if (!isLabelClick && isSelected) {
        setExpandLabel(true)
      } else if (isLabelClick && !isSelected) {
        setExpandLabel(!expandLabel)
      }
    }
    document.addEventListener('click', onClick)
    return () => document.removeEventListener('click', onClick)
  }, [expandLabel, isSelected])

  const handleHide = () => {
    setVisible((prev) => !prev)
  }

  const handleSave = () => {
    onSave && onSave(id)
  }

  const handleDelete = () => {
    onDelete && onDelete(id)
  }

  const isShowConfirmationActions = () => {
    const isCorrectionOrStrikeOwner = currentUserEmail !== strikeOwner
    return !status && isSelected && visible && isCorrectionOrStrikeOwner
  }

  let timeout: number

  const handleEvent = (event: MouseEvent): void => {
    const isLabelHover = labelRef?.current?.contains(event.target as HTMLElement)
    const isConfirmationActionsHover = confirmationActionsRef?.current?.contains(event.target as HTMLElement)
    const isEyeRefHover = topActionsRef?.current?.contains(event.target as HTMLElement)
    if (isLabelHover || isConfirmationActionsHover || isEyeRefHover) {
      return
    }
    const offSetX = 15
    const offSetY = 20
    const target = event.target as HTMLElement
    const bounds = target.getBoundingClientRect()
    if (!node) {
      return
    }
    if (timeout) {
      clearTimeout(timeout)
    }
    timeout = setTimeout(() => {
      const clientX = event.clientX - Math.round(bounds.left) + offSetX
      const clientY = event.clientY - Math.round(bounds.top) + offSetY
      setIsHide(true)
      setCoords({
        x: clientX,
        y: clientY,
      })
    }, 1000)
  }

  const hideTooltip = () => {
    setIsFullName(false)
    if (timeout) {
      clearTimeout(timeout)
    }
  }

  const showLabel = () => {
    setExpandLabel(!expandLabel)
  }

  return (
    <Styled.Wrapper
      ref={wrapperRef}
      data-testid="strikethrough-wrapper"
      data-id={id}
      data-type={PointerModes.DRAG}
      data-readonly={!isEditable ? true : undefined}
      data-min-size={MINIMAL_SIZE}
      isSelected={isSelected}
      style={{ left: x, top: y, width, height }}
      {...props}
    >
      {isFullName && strike[id]?.owner?.name && (
        <Tooltip top={clientCoords.y} left={clientCoords.x} title={strike[id]?.owner!.name} />
      )}
      {isSelected && isEditable && <Resizer isEditable={isEditable} />}
      {visible && <SvgLines data-testid="strikethrough-lines" />}
      {isSelected && (
        <Styled.TopActions ref={topActionsRef}>
          {!isEditable && (
            <Styled.Action>
              <Styled.ActionButton data-action icon={visible ? 'eyeClosed' : 'eyeOpened'} onClick={handleHide} />
            </Styled.Action>
          )}
          {isEditable && (
            <>
              <Styled.Action>
                <Styled.ActionButton data-action icon="checkCircle" onClick={handleSave} />
              </Styled.Action>
              <Styled.Action>
                <Styled.ActionButton data-action icon="closeCircle" onClick={handleDelete} />
              </Styled.Action>
            </>
          )}
        </Styled.TopActions>
      )}
      {isShowConfirmationActions() && onConfirm && (
        <Box ref={confirmationActionsRef}>
          <ConfirmationActions onConfirm={onConfirm} id={id} />
        </Box>
      )}
      {status && acceptOrRejectByName && acceptOrRejectTimestamp && visible && (
        <Box ref={labelRef}>
          <Styled.Confirmation>
            <ConfirmationLabel
              status={status}
              fullName={acceptOrRejectByName}
              date={acceptOrRejectTimestamp}
              size={expandLabel ? BoxSize.default : BoxSize.compact}
              iconSize="extraSmall"
              id={id}
              onCancel={onCancelConfirmation}
              expandLabel={expandLabel}
              showLabel={showLabel}
            />
          </Styled.Confirmation>
        </Box>
      )}
    </Styled.Wrapper>
  )
}
