import styled from 'styled-components'

export const List = styled.ul`
  width: 100%;
  list-style: none;
`
export const ListItem = styled.li`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 16px;
`
