import React, { useCallback } from 'react'
import { Scrollbars } from 'react-custom-scrollbars'

import { VerticalThumb } from './Scrollbar.styles'
import { ScrollbarProps } from './Scrollbar.types'

const HIDE_TIME = 500

export const Scrollbar: React.FC<ScrollbarProps> = ({ children, maxHeight, autoHeight = true }) => {
  const renderThumbVertical = useCallback(() => <VerticalThumb />, [])

  return (
    <Scrollbars
      renderThumbVertical={renderThumbVertical}
      autoHideTimeout={HIDE_TIME}
      autoHideDuration={HIDE_TIME}
      autoHide
      autoHeight={autoHeight}
      autoHeightMax={maxHeight}
      data-testid="scrollbar"
    >
      {children}
    </Scrollbars>
  )
}
