import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { FETCH_STATUSES } from '../types/fetch'

export const useGoToAfterSuccess = (cb: () => void, fetchStatus: FETCH_STATUSES, unmountHandler?: () => void) => {
  const dispatch = useDispatch()

  useEffect(() => {
    if (fetchStatus === FETCH_STATUSES.SUCCESS) {
      cb()
    }
  }, [fetchStatus])

  useEffect(
    () => () => {
      if (fetchStatus === FETCH_STATUSES.SUCCESS && unmountHandler) {
        dispatch(unmountHandler())
      }
    },
    [fetchStatus]
  )
}
