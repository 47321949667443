import React from 'react'
import { useTranslation } from 'react-i18next'

import translations from '../../../translations/keys'
import { getFeature } from '../../../utils/subscription/getFeature'
import { CheckIcon, CloseSmallIcon } from '../../assets/icons'
import Button from '../../ui/Button'
import Typography from '../../ui/Typography'
import { TYPOGRAPHY_COMPONENTS, TYPOGRAPHY_NAMES } from '../../ui/Typography/Typography.types'
import Box from '../../ui/Box'

import { StyledPlan, StyledFeatures, StyledButtonWrapper } from './SubscriptionPlan.styles'
import { SubscriptionPlanProps } from './SubscriptionPlan.types'

export const SubscriptionPlan: React.FC<SubscriptionPlanProps> = ({ data, isActive, buttons, featuresList }) => {
  const { name, yearPrice, monthPrice } = data
  const { t } = useTranslation()
  const renderFeatures = () => (
    <StyledFeatures>
      {featuresList.map((feature) => (
        <Box key={feature.name} display="flex" mb={1}>
          {feature.enabled ? <CheckIcon color="blue" /> : <CloseSmallIcon color="grey" />}
          <Typography
            name={feature.highlighted ? TYPOGRAPHY_NAMES.bold14 : TYPOGRAPHY_NAMES.body14}
            color={feature.enabled ? undefined : 'grey'}
          >
            {getFeature(feature, true)}
          </Typography>
        </Box>
      ))}
    </StyledFeatures>
  )

  return (
    <StyledPlan isActive={isActive}>
      <Typography name={TYPOGRAPHY_NAMES.H4} component={TYPOGRAPHY_COMPONENTS.h4}>
        {name}
      </Typography>
      <Box mt={2} mb={3} display="flex" flexDirection="column" alignItems="center">
        <Typography name={TYPOGRAPHY_NAMES.regularH1}>
          ${yearPrice}/{t(translations.YEAR)}
        </Typography>
        <Box mt={1}>
          <Typography name={TYPOGRAPHY_NAMES.body14} color="grey">
            ${monthPrice}/{t(translations.MO)}
          </Typography>
        </Box>
      </Box>
      {renderFeatures()}
      {buttons.map((button, index) => (
        <StyledButtonWrapper key={index}>
          <Button variant={button.variant} fullWidth onClick={button.onClick}>
            {button.label}
          </Button>
        </StyledButtonWrapper>
      ))}
    </StyledPlan>
  )
}
