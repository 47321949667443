import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { closeModal } from '../../../../../store/modals/modals.actions'
import { modalsVisibleSelector } from '../../../../../store/modals/modals.selectors'
import { CreateSignatureModal } from '../../../../components/Modals/SignaturesModals/CreateSignatureModal/CreateSignatureModal'
import { CreateSignatureVerificationModal } from '../../../../components/Modals/SignaturesModals/CreateSignatureVerificationModal/CreateSignatureVerificationModal'
import { DeleteSignatureModal } from '../../../../components/Modals/SignaturesModals/DeleteSignatureModal/DeleteSignatureModal'
import { DeleteSignatureVerificationModal } from '../../../../components/Modals/SignaturesModals/DeleteSignatureVerificationModal/DeleteSignatureVerificationModal'
import { SmartSignatureModal } from '../../../../components/Modals/SignaturesModals/SmartSignatureModal/SmartSignatureModal'
import { SIGNATURES_MODALS } from '../../../../constants/signatures'

export const SignaturesModals = () => {
  const dispatch = useDispatch()

  const showCreateSignature = useSelector(modalsVisibleSelector(SIGNATURES_MODALS.CREATE_SIGNATURE))
  const onCloseCreateSignature = () => {
    dispatch(closeModal(SIGNATURES_MODALS.CREATE_SIGNATURE))
  }

  const showCreateSignatureVerification = useSelector(
    modalsVisibleSelector(SIGNATURES_MODALS.CREATE_SIGNATURE_VERIFICATION)
  )
  const onCloseCreateSignatureVerification = () => {
    dispatch(closeModal(SIGNATURES_MODALS.CREATE_SIGNATURE_VERIFICATION))
  }

  const showDeleteSignature = useSelector(modalsVisibleSelector(SIGNATURES_MODALS.DELETE_SIGNATURE))
  const onCloseDeleteSignature = () => {
    dispatch(closeModal(SIGNATURES_MODALS.DELETE_SIGNATURE))
  }

  const showDeleteSignatureVerification = useSelector(
    modalsVisibleSelector(SIGNATURES_MODALS.DELETE_SIGNATURE_VERIFICATION)
  )
  const onCloseDeleteSignatureVerification = () => {
    dispatch(closeModal(SIGNATURES_MODALS.DELETE_SIGNATURE_VERIFICATION))
  }

  const showSmartSignature = useSelector(modalsVisibleSelector(SIGNATURES_MODALS.SMART_SIGNATURE))
  const onCloseSmartSignature = () => {
    dispatch(closeModal(SIGNATURES_MODALS.SMART_SIGNATURE))
  }

  return (
    <>
      {showCreateSignature && <CreateSignatureModal onClose={onCloseCreateSignature} />}
      {showCreateSignatureVerification && (
        <CreateSignatureVerificationModal onClose={onCloseCreateSignatureVerification} />
      )}
      {showDeleteSignature && <DeleteSignatureModal onClose={onCloseDeleteSignature} />}
      {showDeleteSignatureVerification && (
        <DeleteSignatureVerificationModal onClose={onCloseDeleteSignatureVerification} />
      )}
      {showSmartSignature && <SmartSignatureModal onClose={onCloseSmartSignature} />}
    </>
  )
}
