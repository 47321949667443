import { FetchFailureAction, FETCH_STATUSES } from '../../../types/fetch'
import { createReducer } from '../../createReducer'

import * as ACTIONS from './message.actions'
import * as TYPES from './message.types'

type Actions = FetchFailureAction

export const initialState: TYPES.MessageState = {
  fetchStatus: FETCH_STATUSES.IDLE,
  error: null,
}

export const message = createReducer<TYPES.MessageState, Actions>(initialState, {
  [ACTIONS.fetchSaveContractMessageTypes.request]: (state) => ({
    ...state,
    fetchStatus: FETCH_STATUSES.REQUEST,
  }),
  [ACTIONS.fetchSaveContractMessageTypes.success]: (state) => ({
    ...state,
    fetchStatus: FETCH_STATUSES.SUCCESS,
  }),
  [ACTIONS.fetchSaveContractMessageTypes.failure]: (state, action) => ({
    ...state,
    fetchStatus: FETCH_STATUSES.FAILURE,
    error: (action as FetchFailureAction).payload,
  }),
  [TYPES.MessageActions.CLEAR_CONTRACT_MESSAGE]: () => ({ ...initialState }),
})
