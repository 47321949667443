import styled from 'styled-components'

import Button from '../../../../ui/Button'
import { BUTTON_VARIANTS } from '../../../../ui/Button/Button.types'
import Box from '../../../../ui/Box'

export const StyledWrapper = styled(Box).attrs({
  mb: 4,
  mbMobile: 1,
})`
  position: relative;
  border: 1px solid ${({ theme }) => theme.palette.greyExtraLight};
  padding: 32px;

  ${({ theme }) => `@media ${theme.devices.mobile} {
    padding: 24px;
  }`}
`

export const StyledImgWrapper = styled(Box).attrs({
  mb: 4,
  mbMobile: 2,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
})<{ blurred: boolean }>`
  text-align: center;
  ${({ blurred }) =>
    blurred &&
    `
  filter: blur(16px);
  filter:progid:DXImageTransform.Microsoft.Blur(PixelRadius='16');
  `}

  width: 200px;
  height: 200px;

  ${({ theme }) => `@media ${theme.devices.mobile} {
    width: 80px;
    height: 80px;
  }`}

  img {
    max-width: 100%;
    max-height: 100%;
  }
`

export const StyledRow = styled(Box).attrs({
  mt: 4,
  mtMobile: 5,
  display: 'flex',
})`
  ${({ theme }) => `@media ${theme.devices.mobile} {
    justify-content: space-between;
  }`}
`

export const StyledCell = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  cursor: pointer;

  & ~ & {
    margin-left: 160px;
  }

  ${({ theme }) => `@media ${theme.devices.tablet} {
    & ~ & {
      margin-left: 72px;
    }
  }`}

  ${({ theme }) => `@media ${theme.devices.mobile} {
    & ~ & {
      margin-left: 0;
    }
  }`}
`

export const StyledDelete = styled(Button).attrs({ icon: 'delete', variant: BUTTON_VARIANTS.INACTIVE })`
  position: absolute;
  top: 24px;
  right: 24px;
`
