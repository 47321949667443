import { FetchFailureAction, FetchFailurePayload } from '../../../types/fetch'
import { createFetchActionTypes } from '../../../utils/createFetchActionTypes'

import * as TYPES from './reupload.types'

export const fetchReuploadTypes = createFetchActionTypes(TYPES.ReuploadActions.FETCH_REUPLOAD)

export const fetchReupload = (id: string, file: File): TYPES.FetchReupload => ({
  type: fetchReuploadTypes.request,
  payload: { id, file },
})

export const fetchReuploadSuccess = (): TYPES.FetchReuploadSuccess => ({
  type: fetchReuploadTypes.success,
})

export const fetchReuploadFailure = (payload: FetchFailurePayload): FetchFailureAction => ({
  type: fetchReuploadTypes.failure,
  payload,
})

export const clearReupload = () => ({
  type: TYPES.ReuploadActions.CLEAR_REUPLOAD,
})
