import React from 'react'

import SvgIcon from '../../ui/SvgIcon'
import { SvgIconProps } from '../../ui/SvgIcon/SvgIcon.types'

export const CloseSmallIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <path d="M14.8278 7.75684L11.9998 10.5858L9.17184 7.75684L7.75684 9.17184L10.5858 11.9998L7.75684 14.8278L9.17184 16.2428L11.9998 13.4138L14.8278 16.2428L16.2428 14.8278L13.4138 11.9998L16.2428 9.17184L14.8278 7.75684Z" />
  </SvgIcon>
)
