import React from 'react'
import { useTheme } from 'styled-components'

import useMediaQuery from '../../hooks/useMediaQuery'
import { StyledThemeScheme } from '../../../themes/light'
import CommentModal from '../../ui/Modals/CommentModal'
import CommentPopover from '../../ui/CommentPopover'

import { CommentPopUpProps } from './CommentPopUp.types'

export const CommentPopUp = ({ onModalClose, onClose, ...props }: CommentPopUpProps) => {
  const theme = useTheme() as StyledThemeScheme
  const isMobile = useMediaQuery(theme.devices.mobile)

  if (isMobile) {
    return <CommentModal {...props} onModalClose={onModalClose} />
  }
  return <CommentPopover {...props} onClose={onClose} />
}
