import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  position: absolute;
  left: 12px;
  top: 100%;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.palette.white};
  background-color: ${({ theme }) => theme.palette.blueDark};
  border-radius: 4px;
  height: 40px;
  padding: 0 10px;
  margin-top: 4px;
  cursor: default;
`
