import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Formik } from 'formik'
import { useTranslation } from 'react-i18next'

import translations from '../../../../../translations/keys'
import { location } from '../../../../../utils/location'
import { SigningOrderFields, SigningOrderForm, SIGNING_TYPES } from '../../../../../types/signingOrder'
import { setOrder } from '../../../../../store/contracts/recipients/recipients.actions'
import { signingOrderInitialValues } from '../../../../../initialValues/signingOrderInitialValues'
import Box from '../../../../ui/Box'
import Modal from '../../../Modal'
import Button from '../../../../ui/Button'
import FormRadioButton from '../../../../ui/Forms/FormRadioButton'
import Typography from '../../../../ui/Typography'
import { TYPOGRAPHY_NAMES } from '../../../../ui/Typography/Typography.types'
import { SequenceSvg } from '../../../../assets/SequenceSvg'
import { useAccessFeature } from '../../../../../hooks/subscription/useAccessFeature'
import { SubscriptionFeatures } from '../../../../../types/subscription'

import { SigningOrderModalProps } from './SigningOrderModal.types'
import { StyledForm } from './SigningOrderModal.styles'

export const SigningOrderModal: React.FC<SigningOrderModalProps> = ({ contractId, onClose }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const history = useHistory()
  const { checkAccess } = useAccessFeature()

  const onSubmit = ({ order }: SigningOrderForm) => {
    if (order !== SIGNING_TYPES.SEQUENTIAL || checkAccess(SubscriptionFeatures.FEATURE_CLM)) {
      dispatch(setOrder(order))
      history.push(location.contractsAddRecipientsUrl(contractId))
    }
  }

  useEffect(() => () => onClose(), [])

  const renderForm = () => (
    <StyledForm>
      <FormRadioButton
        name={SigningOrderFields.order}
        label={t(translations.SEQUENTIAL_SIGNING)}
        id={SIGNING_TYPES.SEQUENTIAL}
      />
      <FormRadioButton
        name={SigningOrderFields.order}
        label={t(translations.SEPARATELY_SIGNING)}
        id={SIGNING_TYPES.SEPARATE}
      />
      <Box width="100%" mt={1}>
        <Button type="submit" fullWidth>
          {t(translations.SUBMIT)}
        </Button>
      </Box>
    </StyledForm>
  )

  return (
    <Modal onClose={onClose}>
      <Box display="flex" alignItems="center" flexDirection="column" width={320}>
        <SequenceSvg />
        <Box mt={3} mb={2} textAlign="center">
          <Typography name={TYPOGRAPHY_NAMES.H1}>{t(translations.SELECT_SIGNATURE_SEQUENCE)}</Typography>
        </Box>
        <Formik onSubmit={onSubmit} initialValues={signingOrderInitialValues}>
          {renderForm}
        </Formik>
      </Box>
    </Modal>
  )
}
