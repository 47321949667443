import React from 'react'
import { useTheme } from 'styled-components'
import ReactTooltip from 'react-tooltip'
import { useDispatch } from 'react-redux'

import { StyledThemeScheme } from '../../../../../themes/light'
import translations from '../../../../../translations/keys'
import Button from '../../../../ui/Button'
import { BUTTON_VARIANTS } from '../../../../ui/Button/Button.types'
import Typography from '../../../../ui/Typography'
import { TYPOGRAPHY_NAMES } from '../../../../ui/Typography/Typography.types'
import useMediaQuery from '../../../../hooks/useMediaQuery'
import { openModal } from '../../../../../store/modals/modals.actions'
import { SIGNATURES_MODALS } from '../../../../constants/signatures'
import { SIGNATURE_TYPE } from '../../../../../types/signatures'

import { StyledCell, StyledDelete, StyledImgWrapper, StyledRow, StyledWrapper } from './SignaturesItem.styles'
import { SignaturesItemProps } from './SignaturesItem.types'

const TOOLTIP = 'tooltip'

export const SignaturesItem: React.FC<SignaturesItemProps> = ({ name, signature, initials, onShow, id, visible }) => {
  const dispatch = useDispatch()
  const theme = useTheme() as StyledThemeScheme
  const mobileScreen = useMediaQuery(theme.devices.mobile)
  const blurredSignature = !(visible.id === id && visible.type === SIGNATURE_TYPE.SIGNATURE)
  const blurredInitials = !(visible.id === id && visible.type === SIGNATURE_TYPE.INITIALS)

  const onShowSignature = () => {
    onShow({ type: SIGNATURE_TYPE.SIGNATURE, id })
  }
  const onShowInitials = () => {
    onShow({ type: SIGNATURE_TYPE.INITIALS, id })
  }
  const onDelete = () => {
    dispatch(openModal(SIGNATURES_MODALS.DELETE_SIGNATURE, { id }))
  }

  return (
    <StyledWrapper>
      <StyledDelete onClick={onDelete} />
      <Typography name={TYPOGRAPHY_NAMES.H3}>{name}</Typography>
      <StyledRow>
        <StyledCell onClick={onShowSignature}>
          <StyledImgWrapper
            data-for={TOOLTIP}
            data-tip={blurredSignature ? translations.CLICK_UNBLUR : null}
            blurred={blurredSignature}
          >
            <img src={signature} alt="signature" />
          </StyledImgWrapper>
          <Button variant={BUTTON_VARIANTS.INACTIVE} icon="eye" textColor="textColor">
            {mobileScreen ? translations.SHOW_SIGNATURE : translations.CLICK_TO_SEE_SIGNATURE}
          </Button>
        </StyledCell>
        <StyledCell onClick={onShowInitials}>
          <StyledImgWrapper
            data-for={TOOLTIP}
            data-tip={blurredInitials ? translations.CLICK_UNBLUR : null}
            blurred={blurredInitials}
          >
            <img src={initials} alt="initials" />
          </StyledImgWrapper>
          <Button variant={BUTTON_VARIANTS.INACTIVE} icon="eye" textColor="textColor">
            {mobileScreen ? translations.SHOW_INITIALS : translations.CLICK_TO_SEE_INITIALS}
          </Button>
        </StyledCell>
      </StyledRow>
      <ReactTooltip id={TOOLTIP} globalEventOff="click" />
    </StyledWrapper>
  )
}
