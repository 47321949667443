import React, { useMemo } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import range from '../../../utils/range'
import translations from '../../../translations/keys'
import Button from '../Button'
import { BUTTON_VARIANTS } from '../Button/Button.types'

import { PaginationProps } from './Pagination.types'

const StyledPagination = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`
const StyledPageBtn = styled(Button).attrs({ variant: BUTTON_VARIANTS.TEXT })<{ active?: boolean }>`
  margin: 0 2px;
  padding: 10px 12px;
  color: ${({ active, theme }) => (active ? theme.palette.black : theme.palette.grey)};
  border-bottom-width: 3px;
  border-bottom-style: solid;
  border-bottom-color: ${({ active, theme }) => (active ? theme.palette.blue : 'transparent')};

  &:hover {
    color: ${({ theme }) => theme.palette.blueDark};
  }
`

const ELLIPSIS = '...'
const BOUNDARY_COUNT = 1
const SIBLING_COUNT = 1
const DEFAULT_LIMIT = 20

export const Pagination: React.FC<PaginationProps> = ({ total, offset, limit = DEFAULT_LIMIT, onChange }) => {
  if (total <= limit) {
    return null
  }

  const totalPages = useMemo(() => Math.ceil(total / limit), [total, limit])
  const currentPage = useMemo(() => Math.ceil(offset / limit) + 1, [offset, limit])
  const { t } = useTranslation()
  const disablePrevButton = currentPage === 1
  const disableNextButton = currentPage >= totalPages

  const startPages = range(1, Math.min(BOUNDARY_COUNT, totalPages))
  const endPages = range(Math.max(totalPages - BOUNDARY_COUNT + 1, BOUNDARY_COUNT + 1), totalPages)
  const siblingsStart = useMemo(
    () =>
      Math.max(
        Math.min(currentPage - SIBLING_COUNT, totalPages - BOUNDARY_COUNT - SIBLING_COUNT * 2 - 1),
        BOUNDARY_COUNT + 2
      ),
    [currentPage, totalPages]
  )
  const siblingsEnd = useMemo(
    () => Math.min(Math.max(currentPage + SIBLING_COUNT, BOUNDARY_COUNT + SIBLING_COUNT * 2 + 2), endPages[0] - 2),
    [currentPage, endPages[0]]
  )

  const siblingsStartCount = () => {
    if (siblingsStart > BOUNDARY_COUNT + 2) {
      return [ELLIPSIS]
    }

    if (BOUNDARY_COUNT + 1 < totalPages - BOUNDARY_COUNT) {
      return [BOUNDARY_COUNT + 1]
    }

    return []
  }

  const siblingsEndCount = () => {
    if (siblingsEnd < totalPages - BOUNDARY_COUNT - 1) {
      return [ELLIPSIS]
    }

    if (totalPages - BOUNDARY_COUNT > BOUNDARY_COUNT) {
      return [totalPages - BOUNDARY_COUNT]
    }

    return []
  }

  const pagesList = [
    ...startPages,
    ...siblingsStartCount(),
    ...range(siblingsStart, siblingsEnd),
    ...siblingsEndCount(),
    ...endPages,
  ]

  const handlePrevPageClick = () => {
    if (!disablePrevButton) {
      onChange(offset - limit)
    }
  }

  const handleNextPageClick = () => {
    if (!disableNextButton) {
      onChange(offset + limit)
    }
  }

  const handlePageClick = (page: number | string) => () => {
    if (typeof page === 'number') {
      const nextOffset = (page - 1) * limit
      onChange(nextOffset)
    }
  }

  return (
    <StyledPagination data-testid="pagination">
      <StyledPageBtn data-testid="pagination-prev-btn" disabled={disablePrevButton} onClick={handlePrevPageClick}>
        {t(translations.PREVIOUS)}
      </StyledPageBtn>
      {pagesList.map((p, i) => (
        <StyledPageBtn
          data-testid="pagination-page-btn"
          key={i}
          disabled={p === ELLIPSIS}
          active={currentPage === p}
          onClick={handlePageClick(p)}
        >
          {p}
        </StyledPageBtn>
      ))}
      <StyledPageBtn data-testid="pagination-next-btn" disabled={disableNextButton} onClick={handleNextPageClick}>
        {t(translations.NEXT)}
      </StyledPageBtn>
    </StyledPagination>
  )
}
