import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { SUBSCRIPTION_MODALS, SUBSCRIPTION_MONTH, SUBSCRIPTION_YEAR } from '../../../../../constants/subscription'
import { modalsPayloadSelector } from '../../../../../store/modals/modals.selectors'
import { SubscriptionUserPlan } from '../../../../../types/subscription'
import {
  subscriptionUserPlanDataSelector,
  subscriptionPlanslistSelector,
} from '../../../../../store/subscription/userPlan/userPlan.selectors'
import translations from '../../../../../translations/keys'
import { SubscriptionPaymentTerms } from '../../../../../types/subscriptionPayment'
import { getFeature } from '../../../../../utils/subscription/getFeature'
import { CheckIcon, CloseSmallIcon } from '../../../../assets/icons'
import Box from '../../../../ui/Box'
import Button from '../../../../ui/Button'
import { BUTTON_VARIANTS } from '../../../../ui/Button/Button.types'
import Modal from '../../../../components/Modal'
import Typography from '../../../../ui/Typography'
import { TYPOGRAPHY_NAMES } from '../../../../ui/Typography/Typography.types'
import { openModal } from '../../../../../store/modals/modals.actions'
import { location } from '../../../../../utils/location'

import { SelectTermModalPayload, SelectTermModalProps } from './SelectTermModal.types'

export const SelectTermModal: React.FC<SelectTermModalProps> = ({ onClose }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { t } = useTranslation()
  const [alreadySubscribed, setAlreadySubscribed] = useState<boolean>(false)
  const userPlan = useSelector(subscriptionUserPlanDataSelector)
  const subscriptionPlanslist = useSelector(subscriptionPlanslistSelector)

  const {
    subscription: { id, name, yearPrice, monthPrice },
    featuresList,
  }: SelectTermModalPayload = useSelector(modalsPayloadSelector(SUBSCRIPTION_MODALS.SELECT_TERM_MODAL))

  const onChangeTerm = (term: SubscriptionPaymentTerms) => () => {
    const currentPlanIndex = subscriptionPlanslist
      ?.map((el) => el.name)
      .indexOf((userPlan as SubscriptionUserPlan).name)
    const newPlanIndex = subscriptionPlanslist?.map((el) => el.name).indexOf(name)
    const isYear = term === SubscriptionPaymentTerms.YEAR && userPlan?.term === SUBSCRIPTION_YEAR
    const isMonth = term === SubscriptionPaymentTerms.MONTH && userPlan?.term === SUBSCRIPTION_MONTH
    const showUpgradedPlanModal = currentPlanIndex! < newPlanIndex!
    const showDowngradePlanModal = currentPlanIndex! > newPlanIndex!

    if (!userPlan) {
      return
    } else if (userPlan?.id === id && (isYear || isMonth)) {
      setAlreadySubscribed(true)
      return
    } else if (showUpgradedPlanModal) {
      dispatch(
        openModal(SUBSCRIPTION_MODALS.CHANGE_PLAN_MODAL, {
          id,
          term,
          description: t(translations.UPGRADED_SUBSCRIPTION),
        })
      )
    } else if (showDowngradePlanModal) {
      dispatch(
        openModal(SUBSCRIPTION_MODALS.CHANGE_PLAN_MODAL, {
          id,
          term,
          description: t(translations.DOWNGRADED_SUBSCRIPTION),
        })
      )
    } else {
      history.push(location.subscriptionPaymentUrl(id, term))
    }
    onClose()
  }

  const generateSubscribedError = (term: number) =>
    userPlan?.term === term && alreadySubscribed ? t(translations.SELECT_ANOTHER_PLAN) : undefined

  return (
    <Modal onClose={onClose}>
      <Box display="flex" flexDirection="column" alignItems="center" width={320} pt={3} pb={3}>
        <Typography name={TYPOGRAPHY_NAMES.H1}>{t(translations.SELECT_OPTION)}</Typography>
        <Box mt={3} mb={2}>
          <Typography name={TYPOGRAPHY_NAMES.H4}>{name}</Typography>
        </Box>
        <Box mb={5}>
          {featuresList.map((feature) => (
            <Box key={feature.name} display="flex" mb={1}>
              {feature.enabled ? <CheckIcon color="blue" /> : <CloseSmallIcon color="grey" />}
              <Typography
                name={feature.highlighted ? TYPOGRAPHY_NAMES.bold14 : TYPOGRAPHY_NAMES.body14}
                color={feature.enabled ? undefined : 'grey'}
              >
                {getFeature(feature, true)}
              </Typography>
            </Box>
          ))}
        </Box>
        <Box mb={3} width="100%">
          <Button
            onClick={onChangeTerm(SubscriptionPaymentTerms.YEAR)}
            fullWidth
            error={generateSubscribedError(SUBSCRIPTION_YEAR)}
          >
            {t(translations.ANNUAL_SUBSCRIPTION)} ${yearPrice}
          </Button>
        </Box>
        <Box width="100%">
          <Button
            onClick={onChangeTerm(SubscriptionPaymentTerms.MONTH)}
            variant={BUTTON_VARIANTS.SECONDARY}
            fullWidth
            error={generateSubscribedError(SUBSCRIPTION_MONTH)}
          >
            {t(translations.MONTHLY_SUBSCRIPTION)} ${monthPrice}
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}
