import { FetchError } from '../../types/fetch'

import * as TYPES from './industries.types'

export const fetchIndustries = (): TYPES.FetchIndustriesAction => ({
  type: TYPES.IndustriesActions.FETCH_INDUSTRIES,
})

export const fetchIndustriesSuccess = (industries: TYPES.Industries): TYPES.FetchIndustriesSuccessAction => ({
  type: TYPES.IndustriesActions.FETCH_INDUSTRIES_SUCCESS,
  payload: industries,
})

export const fetchIndustriesFailure = (error: FetchError): TYPES.FetchIndustriesFailureAction => ({
  type: TYPES.IndustriesActions.FETCH_INDUSTRIES_FAILURE,
  payload: error,
})
