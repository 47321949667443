import React, { useRef } from 'react'
import { FormikProps } from 'formik'
import { useDispatch, useSelector } from 'react-redux'

import { CreateFolderBody, CreateFolderFields, CREATE_FOLDER_ERRORS } from '../../../../../types/createFolder'
import { createTemplatesFolder } from '../../../../../store/templates/folders/folders.actions'
import { templatesFoldersDataSelector } from '../../../../../store/templates/folders/folders.selectors'
import UploadDocumentCreateFolderModal from '../../DocumentModals/UploadDocumentCreateFolderModal'

import { CreateFolderForTemplateModalProps } from './CreateFolderForTemplateModal.types'

export const CreateFolderForTemplateModal: React.FC<CreateFolderForTemplateModalProps> = ({ onClose }) => {
  const dispatch = useDispatch()
  const formRef: React.RefObject<FormikProps<CreateFolderBody>> = useRef(null)
  const folders = useSelector(templatesFoldersDataSelector)

  const onSubmit = (values: CreateFolderBody) => {
    const form = formRef.current
    const folderName = values.folderName.toLocaleLowerCase()
    const alreadyExist = folders.some((folder) => folder.name.toLocaleLowerCase() === folderName)

    if (form && alreadyExist) {
      form.setFieldError(CreateFolderFields.folderName, CREATE_FOLDER_ERRORS.FOLDER_EXISTS_ERROR)
      return
    }

    dispatch(
      createTemplatesFolder({
        name: values.folderName,
        value: values.folderName,
      })
    )
    onClose()
  }

  return <UploadDocumentCreateFolderModal onClose={onClose} onSubmit={onSubmit} ref={formRef} />
}
