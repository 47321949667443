import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import { authUserSelector } from '../../../store/auth/auth.selectors'
import translations from '../../../translations/keys'
import useIntersection from '../../hooks/useIntersection'
import Comment from '../Comment'
import Correction from '../Correction'
import { DocDateField, DocTextField } from '../DocField'
import SignatureBox from '../SignatureBox'
import Strikethrough from '../Strikethrough'

import PageImage from './components/PageImage'
import { DOC_VIEWER_DRAWING_ID, drawingCompMap } from './DocViewer.constants'
import { DocPageProps } from './DocViewer.types'

const StyledWrapper = styled.div<{ drawingMode?: boolean; height: number; width: number }>`
  position: relative;
  min-width: ${({ width }) => `${width}px`};
  min-height: ${({ height }) => `${height}px`};
  margin: 0 auto;
  background: ${({ theme }) => theme.palette.white};
  cursor: ${({ drawingMode }) => (drawingMode ? 'crosshair' : 'default')};

  & ~ & {
    margin-top: 16px;
  }
`

export const DocPage: React.FC<DocPageProps> = ({
  page,
  src,
  drawingComp,
  width,
  height,
  selectedElem,
  movingElem,
  resizingElem,
  placesById,
  placesSorted,
  fieldsById,
  fieldsSorted,
  fieldsFontSize,
  strikesById,
  strikesSorted,
  correctionsById,
  correctionsSorted,
  commentsById,
  commentsSorted,
  readonly,
  isSignatureEditable,
  onCurrentPageChange,
  onFieldChange,
  onElemClick,
  onElemSave,
  onElemDelete,
  onElemConfirm,
  onElemCancelConfirmation,
  recipientToken,
  isSignatureNamesVisible,
}) => {
  const [isReady, setReady] = useState(false)
  const [isIntersecting, setTarget] = useIntersection()
  const { t } = useTranslation()
  const drawingMode = Boolean(drawingComp)
  const showDrawingComp = drawingComp?.page === page
  const DrawingComp = drawingComp && drawingCompMap[drawingComp.name].Component

  const currentUser = useSelector(authUserSelector)

  useEffect(() => {
    if (isIntersecting) {
      setReady(true)
      onCurrentPageChange(page + 1)
    }
  }, [isIntersecting])

  return (
    <StyledWrapper
      id={'doc-viewer-content'}
      ref={setTarget}
      data-page={page}
      drawingMode={drawingMode}
      width={width}
      height={height}
    >
      {isReady && <PageImage src={src} page={page} recipientToken={recipientToken} pageHeight={height} />}
      {showDrawingComp && DrawingComp && (
        <DrawingComp
          data-drawing-comp
          id={DOC_VIEWER_DRAWING_ID}
          x={0}
          y={0}
          width={0}
          height={0}
          fontSize={fieldsFontSize}
        />
      )}
      {placesById &&
        placesSorted &&
        placesSorted.map((id) => {
          const place = placesById[id]
          const showPlaceholder = place.onSign && place.type !== 'initial'
          const fullName = isSignatureNamesVisible ? place.caption || place.title : undefined

          return (
            <SignatureBox
              type={place.type}
              key={id}
              id={id}
              x={place.x}
              y={place.y}
              width={place.width}
              height={place.height}
              title={showPlaceholder ? t(translations.ADD_SIGNATURE_HERE) : t(place.title)}
              subtitle={showPlaceholder ? undefined : place.subtitle}
              caption={place.caption}
              isSelected={selectedElem === id}
              isMoving={movingElem === id}
              isResizing={resizingElem === id}
              onSign={place.onSign}
              isEditable={!readonly && isSignatureEditable}
              readonly={place.readonly}
              signSrc={place.image}
              actions={place.actions}
              fullName={fullName}
            />
          )
        })}
      {fieldsById &&
        fieldsSorted &&
        fieldsSorted.map((id) => {
          const field = fieldsById[id]
          const FieldComponent = field.type === 'TEXT' ? DocTextField : DocDateField

          return (
            <FieldComponent
              id={id}
              key={id}
              isSelected={selectedElem === id}
              isEditable={!readonly}
              x={field.x}
              y={field.y}
              width={field.width}
              text={field.text}
              fontSize={field.fontSize}
              onChange={onFieldChange}
              onDelete={onElemDelete}
            />
          )
        })}
      {commentsById &&
        commentsSorted &&
        commentsSorted.map((id) => {
          const comment = commentsById[id]

          return (
            <Comment
              id={id}
              key={id}
              x={comment.x}
              y={comment.y}
              counter={comment.counter}
              status={comment.confirmation.status}
              onClick={onElemClick}
              autoFocus
            />
          )
        })}
      {correctionsById &&
        correctionsSorted &&
        correctionsSorted.map((id) => {
          const correction = correctionsById[id]

          return (
            <Correction
              id={id}
              key={id}
              isSelected={selectedElem === id}
              isEditable={correction.isEditable}
              x={correction.x}
              y={correction.y}
              width={correction.width}
              height={correction.height}
              text={correction.text}
              status={correction.status}
              acceptOrRejectTimestamp={correction.acceptOrRejectTimestamp}
              acceptOrRejectByName={correction.confirmation?.name}
              correctionOwner={correction.owner?.email}
              onChange={onFieldChange}
              onSave={onElemSave}
              onDelete={onElemDelete}
              onConfirm={correction.canConfirm ? onElemConfirm : undefined}
              onCancelConfirmation={onElemCancelConfirmation}
              currentUserEmail={currentUser?.email}
            />
          )
        })}
      {strikesById &&
        strikesSorted &&
        strikesSorted.map((id) => {
          const strike = strikesById[id]

          return (
            <Strikethrough
              id={id}
              key={id}
              isSelected={selectedElem === id}
              isEditable={strike.isEditable}
              x={strike.x}
              y={strike.y}
              width={strike.width}
              height={strike.height}
              status={strike.status}
              acceptOrRejectTimestamp={strike.acceptOrRejectTimestamp}
              acceptOrRejectByName={strike.confirmation?.name}
              strikeOwner={strike.owner?.email}
              onSave={onElemSave}
              onDelete={onElemDelete}
              onConfirm={strike.canConfirm ? onElemConfirm : undefined}
              onCancelConfirmation={onElemCancelConfirmation}
              currentUserEmail={currentUser?.email}
            />
          )
        })}
    </StyledWrapper>
  )
}
