import React from 'react'
import ReactDOM from 'react-dom'

import Button from '../../ui/Button'
import { BUTTON_VARIANTS } from '../../ui/Button/Button.types'
import Box from '../../ui/Box'
import Typography from '../../ui/Typography'
import { TYPOGRAPHY_NAMES } from '../../ui/Typography/Typography.types'
import { COMMON_MODAL_NODE_ID } from '../../constants/modals'

import { MobilePopupButtonProps } from './MobilePopup.types'
import { StyledPopup, StyledTitle } from './MobilePopup.styles'

export const MobilePopup: React.FC<MobilePopupButtonProps> = ({ popupContent, popupTitle, handleClose }) => {
  const commonModalNode = document.getElementById(COMMON_MODAL_NODE_ID)

  if (!commonModalNode) {
    return null
  }

  return ReactDOM.createPortal(
    <StyledPopup>
      <Box display="flex" flexDirection="column" height="100%">
        <Box width="100%" display="flex" alignItems="center" justifyContent="space-between" mb={3}>
          <Button variant={BUTTON_VARIANTS.LINK} onClick={handleClose} icon="chevronLeft" />
          <StyledTitle>
            <Typography name={TYPOGRAPHY_NAMES.H3}>{popupTitle}</Typography>
          </StyledTitle>
        </Box>
        {popupContent({ handleClose })}
      </Box>
    </StyledPopup>,
    commonModalNode
  )
}
