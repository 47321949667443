import React from 'react'
import { motion } from 'framer-motion'

import { TabPanelProps } from './TabPanel.types'

export const TabPanel: React.FC<TabPanelProps> = ({ isActive, children }) => {
  if (isActive) {
    const hide = { opacity: 0 }
    const show = { opacity: 1 }

    return (
      <motion.div initial={hide} animate={show} exit={hide} data-testid="tab-panel">
        {children}
      </motion.div>
    )
  }

  return null
}
