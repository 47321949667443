import React from 'react'

import MainTemplate from '../../components/Main/MainTemplate'

import { EditProfile as EditProfilePage } from './EditProfile'

export const EditProfile = () => (
  <MainTemplate>
    <EditProfilePage />
  </MainTemplate>
)
