import styled from 'styled-components'

import Box from '../../../../ui/Box'

export const Notifications = styled.div`
  position: relative;
  padding: 0 24px;
  height: 100%;

  ${({ theme }) => `@media ${theme.devices.laptop} {
    padding: 0 16px;
  }`}

  ${({ theme }) => `@media ${theme.devices.mobile} {
    padding: 0;
  }`}
`
export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`
export const Footer = styled.div`
  text-align: center;
  height: 40px;
  line-height: 40px;
`
export const Item = styled(Box).attrs({ pt: 1, pb: 1 })<{ clickable: boolean }>`
  position: relative;
  height: auto;
  min-height: 46px;
  ${({ clickable }) => (clickable ? 'cursor: pointer;' : '')}
  ${({ theme }) => theme.textStyles.body14};
`
export const Title = styled.div`
  color: ${({ theme }) => theme.palette.textColor};
  ${({ theme }) => theme.textStyles.H3};
`
export const ReadStatus = styled.div`
  position: absolute;
  width: 8px;
  height: 8px;
  right: 0;
  top: 16px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.palette.blue};
`
export const Text = styled.div`
  color: ${({ theme }) => theme.palette.textColor};
  max-width: 90%;
  margin-bottom: 2px;
`
export const DateField = styled.div`
  color: ${({ theme }) => theme.palette.grey};
`
