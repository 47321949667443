import React from 'react'
import { useTranslation } from 'react-i18next'

import translations from '../../../translations/keys'
import { mainMenu } from '../../routes/menus'
import useLocationInfo from '../../hooks/useLocationInfo'
import { useAccessFeature } from '../../../hooks/subscription/useAccessFeature'

import * as Styled from './Menu.styles'

export const Menu: React.FC = () => {
  const { section } = useLocationInfo()
  const { checkAccess } = useAccessFeature()
  const { t } = useTranslation()
  return (
    <Styled.Nav data-testid="menu-wrapper">
      <Styled.Content data-testid="menu-content">
        <Styled.List data-testid="menu-navigation">
          {mainMenu.map((item) => {
            const active = Boolean(section && item.route.includes(section))

            const handleClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
              if (!checkAccess(item.feature)) {
                event.preventDefault()
              }
            }

            return (
              <Styled.ListItem key={item.name}>
                <Styled.MenuLink active={active} disabled={!item.route} to={item.route} onClick={handleClick}>
                  <Styled.IconWrapper>
                    <item.Icon color="inherit" />
                  </Styled.IconWrapper>
                  {t(translations[item.name])}
                </Styled.MenuLink>
              </Styled.ListItem>
            )
          })}
        </Styled.List>
        <Styled.Footer data-testid="menu-footer">
          {/* TODO UQD-1410 uncomment when this will work */}
          {/* <Styled.MenuLink disabled to={location.rootUrl()} onClick={() => {}}>
            <Styled.IconWrapper>
              <SupportIcon color="inherit" />
            </Styled.IconWrapper>
            {t(translations.SUPPORT)}
          </Styled.MenuLink> */}
        </Styled.Footer>
      </Styled.Content>
    </Styled.Nav>
  )
}
