import React from 'react'

import translations from '../../../../../translations/keys'
import { NOTIFICATION_MODAL_TYPES } from '../../../../ui/Modals/NotificationModal/NotificationModal.constants'
import { NotificationModalView } from '../../../../ui/Modals/NotificationModal/NotificationModalView'

import { ReuploadConfirmModalProps } from './ReuploadConfirmModal.types'

export const ReuploadConfirmModal: React.FC<ReuploadConfirmModalProps> = ({ onReuploadConfirm, onClose }) => {
  const handleSuccess = () => {
    onReuploadConfirm()
    onClose()
  }

  return (
    <NotificationModalView
      type={NOTIFICATION_MODAL_TYPES.WARNING}
      description={translations.REUPLOAD_DOCUMENT_CONFIRMATION}
      onSuccess={handleSuccess}
      onClose={onClose}
    />
  )
}
