import React from 'react'

import translations from '../../../../../translations/keys'
import { SIGNING_TYPES } from '../../../../../types/signingOrder'
import { location } from '../../../../../utils/location'
import { BackButton } from '../../../../ui/BackButton/BackButton'
import { RecipientsDiagram } from '../../../../ui/RecipientsDiagram'
import Typography from '../../../../ui/Typography'
import { TYPOGRAPHY_NAMES } from '../../../../ui/Typography/Typography.types'
import Box from '../../../../ui/Box'

import { PlacementSidebarProps } from './PlacementSidebar.types'

export const PlacementSidebar: React.FC<PlacementSidebarProps> = ({ templateId, sender, recipients }) => (
  <>
    <BackButton compact path={location.templatesRecipientsDiagramUrl(templateId)} label={translations.BACK} />
    <Box mb={3}>
      <Typography name={TYPOGRAPHY_NAMES.H4}>{translations.SIGNERS}</Typography>
    </Box>
    <RecipientsDiagram compact sender={sender} recipients={recipients} order={SIGNING_TYPES.SEPARATE} />
  </>
)
