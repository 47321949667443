import { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'

import translations from '../../../../translations/keys'
import { TemplateRouteParams } from '../../../types/template'
import { PLACEMENT_MODALS } from '../../../constants/placement'
import { TEMPLATE_MODALS } from '../../../constants/template'
import { openModal } from '../../../../store/modals/modals.actions'
import * as PREVIEW_SELECTORS from '../../../../store/templates/preview/preview.selectors'
import { DiscardIcon, UserIcon } from '../../../assets/icons'

export const useActions = () => {
  const dispatch = useDispatch()
  const { templateId } = useParams<TemplateRouteParams>()
  const data = useSelector(PREVIEW_SELECTORS.previewDataSelector)
  const templateName = data?.name

  const actions = useMemo(
    () => [
      {
        buttonIcon: 'discard',
        label: translations.DISCARD,
        onClick: () => {
          dispatch(openModal(TEMPLATE_MODALS.DISCARD_CONFIRMATION))
        },
        MobileIcon: DiscardIcon,
      },
      {
        buttonIcon: 'reupload',
        label: translations.DOCUMENT_REUPLOAD,
        onClick: () => {
          dispatch(openModal(PLACEMENT_MODALS.REUPLOAD_CONFIRMATION))
        },
        MobileIcon: UserIcon,
      },
    ],
    [templateId, templateName]
  )
  return actions
}
