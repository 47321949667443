import React from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { subscriptionCardsLastAddedCardSelector } from '../../../../../store/subscription/cards/cards.selectors'
import translations from '../../../../../translations/keys'
import { CardPaymentOption } from '../../../../../types/cards'
import { generateCardMask } from '../../../../../utils/cards/generateCardMask'
import { location } from '../../../../../utils/location'
import { HandshakeIcon } from '../../../../assets/HandshakeIcon'
import AuthorizationBox from '../../../../components/Authorization/AuthorizationBox'
import Box from '../../../../ui/Box'
import Button from '../../../../ui/Button'
import Typography from '../../../../ui/Typography'
import { TYPOGRAPHY_COMPONENTS, TYPOGRAPHY_NAMES } from '../../../../ui/Typography/Typography.types'
import { LOGOS } from '../../CardSpecification.constants'

export const AddedCard = () => {
  const history = useHistory()

  const card = useSelector(subscriptionCardsLastAddedCardSelector)

  const onContinue = () => {
    history.push(location.editProfileUrl())
  }

  if (!card) {
    return null
  }

  return (
    <AuthorizationBox noLogo>
      <Box pl={13} pr={13} pb={8}>
        <Box display="flex" flexDirection="column" alignItems="center" width={320}>
          <HandshakeIcon />
          <Box pt={3} pb={4} textAlign="center">
            <Typography name={TYPOGRAPHY_NAMES.H1} component={TYPOGRAPHY_COMPONENTS.h1}>
              {translations.CARD_SUCCESSFULLY_ADDED}
            </Typography>
          </Box>
          <Box display="flex" alignItems="center" mb={3}>
            {LOGOS[CardPaymentOption.VISA]}
            <Box ml={1.5}>
              <Typography name={TYPOGRAPHY_NAMES.body16}>{generateCardMask(card.lastDigits)}</Typography>
            </Box>
          </Box>
          <Typography name={TYPOGRAPHY_NAMES.bold16}>{translations.START_FREE_TRIAL}</Typography>
          <Box width="100%" mt={5}>
            <Button fullWidth onClick={onContinue}>
              {translations.CONTINUE}
            </Button>
          </Box>
        </Box>
      </Box>
    </AuthorizationBox>
  )
}
