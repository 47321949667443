import React from 'react'

import { SvgIconProps } from '../../ui/SvgIcon/SvgIcon.types'
import SvgIcon from '../../ui/SvgIcon'

export const ContourCrossIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <path d="M16.0656 8.31445C16.0656 8.21133 15.9812 8.12695 15.8781 8.12695L14.3312 8.13398L12.0015 10.9113L9.67421 8.13633L8.125 8.1293C8.02187 8.1293 7.9375 8.21133 7.9375 8.3168C7.9375 8.36133 7.95391 8.40352 7.98203 8.43867L11.0312 12.0715L7.98203 15.702C7.95371 15.7363 7.938 15.7793 7.9375 15.8238C7.9375 15.927 8.02187 16.0113 8.125 16.0113L9.67421 16.0043L12.0015 13.227L14.3289 16.002L15.8758 16.009C15.9789 16.009 16.0633 15.927 16.0633 15.8215C16.0633 15.777 16.0468 15.7348 16.0187 15.6996L12.9742 12.0691L16.0234 8.43633C16.0515 8.40352 16.0656 8.35898 16.0656 8.31445Z" />
    <path d="M12 1.52344C6.20156 1.52344 1.5 6.225 1.5 12.0234C1.5 17.8219 6.20156 22.5234 12 22.5234C17.7984 22.5234 22.5 17.8219 22.5 12.0234C22.5 6.225 17.7984 1.52344 12 1.52344ZM12 20.7422C7.18594 20.7422 3.28125 16.8375 3.28125 12.0234C3.28125 7.20938 7.18594 3.30469 12 3.30469C16.8141 3.30469 20.7188 7.20938 20.7188 12.0234C20.7188 16.8375 16.8141 20.7422 12 20.7422Z" />
  </SvgIcon>
)
