import styled from 'styled-components'

import { ConfirmationStatus } from '../../../types/confirmationActions'
import { ArrowDownIcon } from '../../assets/icons'
import Box from '../Box'
import Button from '../Button'

export const Wrapper = styled.div`
  position: relative;
  width: 320px;
  padding: 30px 16px 24px;
  background-color: ${({ theme }) => theme.palette.white};
  border: ${({ theme }) => `1px solid ${theme.palette.strokeGrey}`};
  border-radius: 8px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  z-index: 300;
`
export const Content = styled(Box).attrs({
  pl: 1,
  pr: 1,
})`
  max-height: 300px;
`
export const ActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: absolute;
  top: 10px;
  right: 12px;
  z-index: 10;
`
export const ArrowIcon = styled(ArrowDownIcon)<{ flip?: boolean }>`
  margin: 0 5px;
  backface-visibility: hidden;
  transform: ${({ flip }) => (flip ? 'rotate(180deg)' : 'rotate(0deg)')};
  transition: transform 200ms;
`

export const SubmitButton = styled(Button).attrs({ compact: true })<{ status: ConfirmationStatus | null }>`
  display: ${({ status }) => (!!status ? 'none' : 'initial')};
  width: 112px;
  margin-top: 30px;
`
export const ActionButton = styled(Button)`
  &:last-child {
    margin-left: 15px;
  }
  svg {
    margin: 0;
  }
`
export const ThreadItem = styled.div`
  padding-bottom: 16px;
  margin-bottom: 16px;
  border-bottom: ${({ theme }) => `1px solid ${theme.palette.pattensBlue}`};
`
