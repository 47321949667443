import React, { useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation, useParams } from 'react-router-dom'

import translations from '../../../translations/keys'
import { SubscriptionPaymentTerms } from '../../../types/subscriptionPayment'
import AuthorizationBox from '../../components/Authorization/AuthorizationBox'
import PaymentCard from '../../components/PaymentCard'
import Box from '../../ui/Box'
import Button from '../../ui/Button'
import { BUTTON_VARIANTS } from '../../ui/Button/Button.types'
import Typography from '../../ui/Typography'
import { TYPOGRAPHY_COMPONENTS, TYPOGRAPHY_NAMES } from '../../ui/Typography/Typography.types'
import * as PAYMENT_SELECTORS from '../../../store/subscription/payment/payment.selectors'
import { CardSpecificationRouteParams, CardSpecificationTypes } from '../../../types/cardSpecification'
import { usePlanFetch } from '../../../hooks/subscription/usePlanFetch'
import { HandleTokenizationCompleted } from '../../../hooks/sockets/useSubscribeOnPayment'
import { SUBSCRIPTION_MONTH, SUBSCRIPTION_YEAR } from '../../../constants/subscription'
import { fetchActivateSubscription } from '../../../store/subscription/payment/payment.actions'
import { openModal } from '../../../store/modals/modals.actions'
import { CARD_SPECIFICATION_MODALS } from '../../../constants/cardSpecification'
import * as PLANS_SELECTORS from '../../../store/subscription/plans/plans.selectors'
import { location } from '../../../utils/location'

import AddedCard from './components/AddedCard'
import SuccessfulPayment from './components/SuccessfulPayment'
import PaymentProcessing from './components/PaymentProcessing'
import Modals from './components/Modals'

export const CardSpecification = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const search = useLocation().search
  const params = new URLSearchParams(search)
  const type = params.get(location.queryParams.TYPE)
  const term = params.get(location.queryParams.TERM) as SubscriptionPaymentTerms | null

  const { subscriptionId } = useParams<CardSpecificationRouteParams>()

  const isTrial = type === CardSpecificationTypes.TRIAL

  const [success, setSuccess] = useState(false)

  const paymentProcessing = useSelector(PAYMENT_SELECTORS.subscriptionPaymentProcessingSelector)
  const selectedPlan = useSelector(PLANS_SELECTORS.subscriptionPlanDataSelector)

  usePlanFetch(subscriptionId)

  const handleVerifyCard = useCallback(() => {
    setSuccess(true)
  }, [])

  const handleTokenizationCompleted: HandleTokenizationCompleted = useCallback(
    (data) => {
      dispatch(
        fetchActivateSubscription(
          {
            tokenName: data.token,
            expiredDate: data.expiryDate,
            subscriptionId,
            term: term === SubscriptionPaymentTerms.YEAR ? SUBSCRIPTION_YEAR : SUBSCRIPTION_MONTH,
          },
          isTrial
        )
      )
    },
    [term, subscriptionId, isTrial]
  )

  const openLogoutModal = () => {
    dispatch(openModal(CARD_SPECIFICATION_MODALS.CARD_SPECIFICATION_LOGOUT_MODAL))
  }

  const handleChangePlan = () => {
    history.push(location.subscriptionSelectUrl())
  }

  if (!selectedPlan) {
    return null
  }

  if (selectedPlan && success && !isTrial && term) {
    return <SuccessfulPayment plan={selectedPlan} term={term} />
  }

  if (success && isTrial) {
    return <AddedCard />
  }

  const renderPrice = () =>
    term === SubscriptionPaymentTerms.YEAR
      ? `$${selectedPlan.yearPrice}/${translations.YEAR}`
      : `$${selectedPlan.monthPrice}/${translations.MONTH}`

  return (
    <AuthorizationBox title={paymentProcessing ? '' : translations.SPECIFY_CARD} noLogo pt={4}>
      <Box pb={4} pl={5} pr={5} display="flex" flexDirection="column" justifyContent="center" alignItems="center">
        {!paymentProcessing && (
          <>
            <Box mt={2} mb={3}>
              {translations.FILL_CARD_DETAILS}
            </Box>
            <Typography
              name={isTrial ? TYPOGRAPHY_NAMES.H2 : TYPOGRAPHY_NAMES.H4}
              component={isTrial ? TYPOGRAPHY_COMPONENTS.h2 : TYPOGRAPHY_COMPONENTS.h4}
            >
              {isTrial ? translations.FREE_TRIAL : `${selectedPlan.name} ${translations.PLAN} ${translations.PAYMENT}`}
            </Typography>
            <Box mt={2} mb={3}>
              {isTrial ? (
                <Box textAlign="center" width={263}>
                  {translations.FREE_TRIAL_CHARGE}
                </Box>
              ) : (
                <Typography name={TYPOGRAPHY_NAMES.regularH1} component={TYPOGRAPHY_COMPONENTS.span}>
                  {renderPrice()}
                </Typography>
              )}
            </Box>
            <Box width={208} mb={2}>
              <Button variant={BUTTON_VARIANTS.SECONDARY} fullWidth onClick={handleChangePlan}>
                {translations.CHANGE_PLAN}
              </Button>
            </Box>
          </>
        )}
        <PaymentCard
          Loader={PaymentProcessing}
          handleVerifyCard={handleVerifyCard}
          handleTokenizationCompleted={handleTokenizationCompleted}
        />
        {!paymentProcessing && (
          <Button variant={BUTTON_VARIANTS.LINK} onClick={openLogoutModal}>
            {translations.LOGOUT}
          </Button>
        )}
      </Box>
      <Modals />
    </AuthorizationBox>
  )
}
