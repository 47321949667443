import { FETCH_STATUSES } from '../../../types/fetch'
import { RootState } from '../../rootReducer.types'

export const templatesListDataSelector = (state: RootState) => state.templates.list.data
export const templatesListSortingSelector = (state: RootState) => state.templates.list.sorting
export const templatesListLimitSelector = (state: RootState) => state.templates.list.limit
export const templatesListOffsetSelector = (state: RootState) => state.templates.list.offset
export const templatesListSearchTextSelector = (state: RootState) => state.templates.list.searchText
export const templatesListTotalSelector = (state: RootState) => state.templates.list.total
export const templatesListLoadingSelector = (state: RootState) =>
  state.templates.list.fetchStatus === FETCH_STATUSES.REQUEST
export const templatesListIsFirstLoadSelector = (state: RootState) => state.templates.list.isFirstLoad
export const templatesListErrorSelector = (state: RootState) => state.templates.list.error
export const templatesListFetchStatusSelector = (state: RootState) => state.templates.list.fetchStatus
export const templatesListNotificationSelector = (state: RootState) => state.templates.list.notification
export const templatesListForceUpdateSelector = (state: RootState) => state.templates.list.forceUpdate
export const templatesListBreadcrumbs = (state: RootState) => state.templates.list.breadcrumbs
export const templatesListFolderId = (state: RootState) => state.templates.list.folderId
export const templatesListIsEmptyContracts = (state: RootState) => state.templates.list.isEmptyContracts
export const templatesListIsEmptyFolder = (state: RootState) => state.templates.list.isEmptyFolder
