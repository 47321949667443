import { createFetchActionTypes } from '../../../utils/createFetchActionTypes'
import { FetchFailureAction, FetchFailurePayload } from '../../../types/fetch'
import { RecipientsById } from '../../../types/recipients'
import { EditableElem } from '../../../types/editableElem'
import { PlacementBody, PlacementById, PlacementByPages, PlacementSorted } from '../../../types/placement'

import * as TYPES from './placement.types'

export const fetchPlacementTypes = createFetchActionTypes(TYPES.PlacementActions.TEMPLATES_FETCH_PLACEMENT)
export const fetchUpdatePlacementTypes = createFetchActionTypes(TYPES.PlacementActions.TEMPLATES_FETCH_UPDATE_PLACEMENT)
export const fetchGeneratePlacementTypes = createFetchActionTypes(
  TYPES.PlacementActions.TEMPLATES_FETCH_GENERATE_PLACEMENT
)

export const setPlacement = (place: EditableElem): TYPES.SetPlacementAction => ({
  type: TYPES.PlacementActions.TEMPLATES_SET_PLACEMENT,
  payload: { place },
})
export const clearPlacement = (): TYPES.ClearPlacementAction => ({
  type: TYPES.PlacementActions.TEMPLATES_CLEAR_PLACEMENT,
})
export const clearPlacementStatus = (): TYPES.ClearPlacementStatusAction => ({
  type: TYPES.PlacementActions.TEMPLATES_CLEAR_PLACEMENT_STATUS,
})

export const fetchPlacement = (templateId: string, recipientsById: RecipientsById): TYPES.FetchPlacementAction => ({
  type: fetchPlacementTypes.request,
  payload: { templateId, recipientsById },
})
export const fetchPlacementSuccess = (
  byId: PlacementById,
  byPages: PlacementByPages,
  sorted: PlacementSorted
): TYPES.FetchPlacementSuccessAction => ({
  type: fetchPlacementTypes.success,
  payload: { byId, byPages, sorted },
})
export const fetchPlacementFailure = (payload: FetchFailurePayload): FetchFailureAction => ({
  type: fetchPlacementTypes.failure,
  payload,
})

export const fetchUpdatePlacement = (
  byId: PlacementById,
  sorted: PlacementSorted
): TYPES.FetchUpdatePlacementAction => ({
  type: fetchUpdatePlacementTypes.request,
  payload: { byId, sorted },
})
export const fetchUpdatePlacementSuccess = (response: PlacementBody): TYPES.FetchUpdatePlacementSuccessAction => ({
  type: fetchUpdatePlacementTypes.success,
  payload: response,
})
export const fetchUpdatePlacementFailure = (payload: FetchFailurePayload): FetchFailureAction => ({
  type: fetchUpdatePlacementTypes.failure,
  payload,
})

export const fetchGeneratePlacement = (templateId: string): TYPES.FetchGeneratePlacementAction => ({
  type: fetchGeneratePlacementTypes.request,
  payload: { templateId },
})
export const fetchGeneratePlacementSuccess = (): TYPES.FetchGeneratePlacementSuccessAction => ({
  type: fetchGeneratePlacementTypes.success,
})
export const fetchGeneratePlacementFailure = (payload: FetchFailurePayload): FetchFailureAction => ({
  type: fetchGeneratePlacementTypes.failure,
  payload,
})

export const setShouldRegenerate = (shouldRegenerate: boolean): TYPES.SetShouldRegenerateAction => ({
  type: TYPES.PlacementActions.TEMPLATES_SET_SHOULD_REGENERATE,
  payload: { shouldRegenerate },
})
