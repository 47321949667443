import { RootState } from '../rootReducer.types'
import { FETCH_STATUSES } from '../../types/fetch'

export const contactsSortedSelector = (state: RootState) => state.contacts.sorted
export const contactsSortingSelector = (state: RootState) => state.contacts.sorting
export const contactsSearchTextSelector = (state: RootState) => state.contacts.searchText
export const contactsOffsetSelector = (state: RootState) => state.contacts.offset
export const contactsTotalSelector = (state: RootState) => state.contacts.total
export const contactsLimitSelector = (state: RootState) => state.contacts.limit
export const contactsIsFirstLoadSelector = (state: RootState) => state.contacts.isFirstLoad
export const contactsLoadingSelector = (state: RootState) => state.contacts.loading
export const contactsFetchStatusSelector = (state: RootState) => state.contacts.fetchStatus
export const contactsSuccessSelector = (state: RootState) => state.contacts.fetchStatus === FETCH_STATUSES.SUCCESS
export const contactsSuccessMessageSelector = (state: RootState) => state.contacts.successMessage
export const contactsErrorSelector = (state: RootState) => state.contacts.error
export const contactsNotificationSelector = (state: RootState) => state.contacts.notification
