import { FETCH_STATUSES } from '../../types/fetch'
import { createReducer } from '../createReducer'

import * as TYPES from './countries.types'

type Actions = TYPES.FetchCountriesAction | TYPES.FetchCountriesSuccessAction | TYPES.FetchCountriesFailureAction

export const initialState: TYPES.CountriesState = {
  fetchStatus: FETCH_STATUSES.IDLE,
  data: [],
}

export const countries = createReducer<TYPES.CountriesState, Actions>(initialState, {
  [TYPES.CountriesActions.FETCH_COUNTRIES]: (state) => ({
    ...state,
    fetchStatus: FETCH_STATUSES.REQUEST,
  }),
  [TYPES.CountriesActions.FETCH_COUNTRIES_SUCCESS]: (state, action) => ({
    ...state,
    fetchStatus: FETCH_STATUSES.SUCCESS,
    data: (action as TYPES.FetchCountriesSuccessAction).payload,
  }),
  [TYPES.CountriesActions.FETCH_COUNTRIES_FAILURE]: (state, action) => ({
    ...state,
    fetchStatus: FETCH_STATUSES.FAILURE,
    error: (action as TYPES.FetchCountriesFailureAction).payload,
  }),
})
