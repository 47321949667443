import styled from 'styled-components'

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100%;
`
export const Header = styled.div`
  display: flex;
`
export const HeaderSearchWrapper = styled.div`
  flex: 1;
  max-width: 384px;
`
export const HeaderBtnWrapper = styled.div`
  min-width: 208px;
  margin-left: auto;
`
export const Placeholder = styled.div`
  align-self: center;
  margin-top: 208px;
  text-align: center;

  ${({ theme }) => `@media ${theme.devices.tablet} {
    margin-top: 50px;
  }`}
`
export const PlaceholderBtn = styled.div`
  max-width: 208px;
  margin: 0 auto;
`
export const MessageContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 615px;
  max-width: 100%;
`
export const PaginationContainer = styled.div`
  margin-top: auto;
`
