import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import translations from '../../../translations/keys'
import Typography from '../../ui/Typography'
import { TYPOGRAPHY_COMPONENTS, TYPOGRAPHY_NAMES } from '../../ui/Typography/Typography.types'
import { usePlansFetch } from '../../../hooks/subscription/usePlansFetch'
import * as PLANS_SELECTORS from '../../../store/subscription/plans/plans.selectors'
import { useShowFetchError } from '../../hooks/useShowFetchError'
import { SubscriptionPlansSkeleton } from '../../components/Skeletons/SubscriptionPlansSkeleton'
import * as USER_PLAN_SELECTORS from '../../../store/subscription/userPlan/userPlan.selectors'
import ContactUs from '../../components/ContactUs'
import { openModal } from '../../../store/modals/modals.actions'
import { SUBSCRIPTION_MODALS } from '../../../constants/subscription'
import SubscriptionPlan from '../../components/SubscriptionPlan'
import { BUTTON_VARIANTS } from '../../ui/Button/Button.types'
import { getFeaturesList } from '../../../utils/subscription/getFeaturesList'

import { StyledPlans, StyledTitle } from './SubscriptionPlans.styles'
import Modals from './components/Modals'

export const SubscriptionPlans = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const plans = useSelector(PLANS_SELECTORS.subscriptionPlansDataSelector)
  const fetchStatus = useSelector(PLANS_SELECTORS.subscriptionPlansFetchStatusSelector)
  const error = useSelector(PLANS_SELECTORS.subscriptionPlansErrorSelector)
  const isLoading = useSelector(PLANS_SELECTORS.subscriptionPlansIsLoadingSelector)
  const userPlan = useSelector(USER_PLAN_SELECTORS.subscriptionUserPlanDataSelector)
  const enabledFeatures = useSelector(USER_PLAN_SELECTORS.subscriptionUserPlanEnabledFeaturesSelector)
  const enabledLimits = useSelector(USER_PLAN_SELECTORS.subscriptionUserPlanEnabledLimitsSelector)

  usePlansFetch()
  useShowFetchError(fetchStatus, error)

  const onContactUs = () => {
    dispatch(openModal(SUBSCRIPTION_MODALS.CONTACT_US_MODAL))
  }

  return (
    <div>
      <StyledTitle>
        <Typography name={TYPOGRAPHY_NAMES.H3} component={TYPOGRAPHY_COMPONENTS.h3}>
          {t(translations.SUBSCRIPTION_PLANS)}
        </Typography>
      </StyledTitle>
      {isLoading ? (
        <SubscriptionPlansSkeleton />
      ) : (
        <StyledPlans>
          {plans?.map((plan) => {
            const featuresList = getFeaturesList(plan.features, enabledFeatures, enabledLimits)
            const buttons = [
              {
                label: t(translations.CHANGE_PLAN),
                onClick: () => {
                  dispatch(openModal(SUBSCRIPTION_MODALS.SELECT_TERM_MODAL, { subscription: plan, featuresList }))
                },
                variant: BUTTON_VARIANTS.SECONDARY,
              },
            ]

            return (
              <SubscriptionPlan
                key={plan.id}
                data={plan}
                isActive={userPlan?.id === plan.id}
                buttons={buttons}
                featuresList={featuresList}
              />
            )
          })}
        </StyledPlans>
      )}
      <ContactUs onClick={onContactUs} />
      <Modals />
    </div>
  )
}
