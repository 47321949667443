import React from 'react'
import { useTranslation } from 'react-i18next'

import translations from '../../../../translations/keys'
import Typography from '../../Typography'
import { TYPOGRAPHY_NAMES } from '../../Typography/Typography.types'
import Box from '../../Box'

import * as Styled from './SenderCard.styles'
import { SenderCardProps } from './SenderCard.types'

export const SenderCard: React.FC<SenderCardProps> = ({ name, jobTitle }) => {
  const { t } = useTranslation()

  return (
    <Styled.Wrapper data-testid="sender-card-wrapper">
      <Styled.Label data-testid="sender-card-label">
        <Typography name={TYPOGRAPHY_NAMES.tableColumn}>{t(translations.SENDER)}</Typography>
      </Styled.Label>
      <Box pl={1} pr={1} textAlign="center">
        <Styled.FullName data-testid="sender-card-fullname">
          <Typography name={TYPOGRAPHY_NAMES.bold14}>{name}</Typography>
        </Styled.FullName>
        <Styled.Department data-testid="sender-card-department">
          {jobTitle && (
            <Typography data-testid="sender-card-job-title" name={TYPOGRAPHY_NAMES.body14}>
              {jobTitle}
            </Typography>
          )}
        </Styled.Department>
      </Box>
    </Styled.Wrapper>
  )
}
