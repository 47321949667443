export const convertToFormData = (obj: { [key: string]: string | File | undefined }) => {
  const formData = new FormData()

  Object.keys(obj).forEach((key) => {
    const value = obj[key]

    if (value) {
      formData.append(key, value)
    }
  })

  return formData
}
