import React from 'react'
import { useTranslation } from 'react-i18next'

import translations from '../../../../../../translations/keys'
import Button from '../../../../../ui/Button'
import { BUTTON_VARIANTS } from '../../../../../ui/Button/Button.types'
import Box from '../../../../../ui/Box'

import { StyledActions } from './SignaturePadActions.styles'
import { SignaturePadActionsProps } from './SignaturePadActions.types'

export const SignaturePadActions: React.FC<SignaturePadActionsProps> = React.memo(
  ({ onClear, dirty, onApply, applied, onChange }) => {
    const { t } = useTranslation()
    return (
      <StyledActions>
        {dirty && (
          <Box display="flex" alignItems="center">
            {applied ? (
              <Box>
                <Button variant={BUTTON_VARIANTS.INACTIVE} icon="reload" textColor="textColor" onClick={onChange}>
                  {t(translations.CHANGE)}
                </Button>
              </Box>
            ) : (
              <>
                <Box>
                  <Button variant={BUTTON_VARIANTS.INACTIVE} icon="roundCross" textColor="textColor" onClick={onClear}>
                    {t(translations.CLEAR)}
                  </Button>
                </Box>
                <Box ml={5}>
                  <Button variant={BUTTON_VARIANTS.INACTIVE} icon="roundCheck" textColor="textColor" onClick={onApply}>
                    {t(translations.APPLY)}
                  </Button>
                </Box>
              </>
            )}
          </Box>
        )}
      </StyledActions>
    )
  }
)
