import { useSelector } from 'react-redux'

import { useShowFetchError } from '../../../hooks/useShowFetchError'
import * as SIGNING_SELECTORS from '../../../../store/contracts/signing/signing.selectors'
import * as STRIKES_SELECTORS from '../../../../store/contracts/strikes/strikes.selectors'
import * as CORRECTIONS_SELECTORS from '../../../../store/contracts/corrections/corrections.selectors'

export const useErrors = () => {
  const fetchStatus = useSelector(SIGNING_SELECTORS.signingContractFetchStatusSelector)
  const error = useSelector(SIGNING_SELECTORS.signingContractErrorSelector)
  const strikeConfirmFetchStatus = useSelector(STRIKES_SELECTORS.strikesConfirmFetchStatusSelector)
  const strikeConfirmError = useSelector(STRIKES_SELECTORS.strikesConfirmErrorSelector)
  const correctionsConfirmFetchStatus = useSelector(CORRECTIONS_SELECTORS.correctionsConfirmFetchStatusSelector)
  const correctionsConfirmError = useSelector(CORRECTIONS_SELECTORS.correctionsConfirmErrorSelector)

  useShowFetchError(fetchStatus, error)
  useShowFetchError(strikeConfirmFetchStatus, strikeConfirmError)
  useShowFetchError(correctionsConfirmFetchStatus, correctionsConfirmError)
}
