import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import * as ACTIONS from '../../store/subscription/plans/plans.actions'
import { useDispatchUnmount } from '../useDispatchUnmount'

export const usePlansFetch = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(ACTIONS.fetchPlans())
  }, [])

  useDispatchUnmount(ACTIONS.clearPlans)
}
