import React, { useState } from 'react'
import { useTheme } from 'styled-components'
import { useTranslation } from 'react-i18next'

import { StyledThemeScheme } from '../../../themes/light'
import translations from '../../../translations/keys'
import useLocationInfo from '../../hooks/useLocationInfo'
import { useAccessFeature } from '../../../hooks/subscription/useAccessFeature'
import useNavControls from '../../hooks/useNavControls'
import useMediaQuery from '../../hooks/useMediaQuery'

import * as Styled from './MobileMenu.styles'
import { MobileMenuLinkProps } from './MobileMenu.types'

export const MobileMenuLink: React.FC<MobileMenuLinkProps> = ({ route, name, feature, children, Icon }) => {
  const { section, pathname } = useLocationInfo()
  const { setMainmenuOpen } = useNavControls()
  const { checkAccess } = useAccessFeature()
  const { t } = useTranslation()
  const theme = useTheme() as StyledThemeScheme
  const mobileScreen = useMediaQuery(theme.devices.mobile)
  const active = Boolean(section && route.includes(section))
  const [open, setOpen] = useState(active)

  const handleButtonClick = () => {
    setOpen((prevState) => !prevState)
  }

  const handleLinkClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    if (!checkAccess(feature)) {
      event.preventDefault()
    }

    setMainmenuOpen(false)
  }

  if (children && mobileScreen) {
    return (
      <Styled.ListItem key={name}>
        <Styled.MenuButton active={active} disabled={!route} onClick={handleButtonClick}>
          <Styled.IconWrapper>
            <Icon color="inherit" />
          </Styled.IconWrapper>
          {t(translations[name])}
          <Styled.TriangleIcon isFlipped={open} />
        </Styled.MenuButton>
        {open && (
          <Styled.List>
            {children.map((item) => (
              <Styled.ListItem key={item.name}>
                <Styled.MenuLink
                  nested
                  active={item.route === pathname}
                  disabled={!item.route}
                  to={item.route}
                  onClick={handleLinkClick}
                >
                  {t(translations[item.name])}
                </Styled.MenuLink>
              </Styled.ListItem>
            ))}
          </Styled.List>
        )}
      </Styled.ListItem>
    )
  }

  return (
    <Styled.ListItem key={name}>
      <Styled.MenuLink active={active} disabled={!route} to={route} onClick={handleLinkClick}>
        <Styled.IconWrapper>
          <Icon color="inherit" />
        </Styled.IconWrapper>
        {t(translations[name])}
      </Styled.MenuLink>
    </Styled.ListItem>
  )
}
