import styled from 'styled-components'

import Box from '../../ui/Box'

export const StyledStorages = styled(Box).attrs({
  display: 'flex',
  pr: 15,
  prLaptop: 0,
  mt: 3,
})`
  ${({ theme }) => `@media ${theme.devices.mobile} {
    flex-direction: column;
  }`}
`
