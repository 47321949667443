import { combineReducers } from 'redux'

import { TemplatesState } from './templates.types'
import { list } from './list/list.reducer'
import { rename } from './rename/rename.reducer'
import { deleteTemplate } from './delete/delete.reducer'
import { folders } from './folders/folders.reducer'
import { moveToFolder } from './moveToFolder/moveToFolder.reducer'
import { duplicate } from './duplicate/duplicate.reducer'
import { upload } from './upload/upload.reducer'
import { reupload } from './reupload/reupload.reducer'
import { recipients } from './recipients/recipients.reducer'
import { preview } from './preview/preview.reducer'
import { placement } from './placement/placement.reducer'
import { createFromContract } from './createFromContract/createFromContract.reducer'
import { createContract } from './createContract/createContract.reducer'
import { briefList } from './briefList/briefList.reducer'
import { favorite } from './favorite/favorite.reducer'
import { fields } from './fields/fields.reducer'
import { message } from './message/message.reducer'
import { viewOptions } from './viewOptions/viewOptions.reducer'

export const templates = combineReducers<TemplatesState>({
  list,
  rename,
  delete: deleteTemplate,
  folders,
  moveToFolder,
  duplicate,
  upload,
  reupload,
  recipients,
  preview,
  placement,
  createFromContract,
  createContract,
  briefList,
  favorite,
  fields,
  message,
  viewOptions,
})
