import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { EditPhoneModal } from '../../../../components/Modals/EditProfileModals/EditPhoneModal/EditPhoneModal'
import { ConfirmPhoneModal } from '../../../../components/Modals/EditProfileModals/ConfirmPhoneModal/ConfirmPhoneModal'
import { ChangeEmailModal } from '../../../../components/Modals/EditProfileModals/ChangeEmailModal/ChangeEmailModal'
import { modalsVisibleSelector } from '../../../../../store/modals/modals.selectors'
import { closeModal } from '../../../../../store/modals/modals.actions'
import { EDIT_PROFILE_MODALS } from '../../../../constants/editProfile'
import { LeaveModal } from '../../../../components/Modals/EditProfileModals/LeaveModal/LeaveModal'
import { CheckEmailModal } from '../../../../components/Modals/CheckEmailModal/CheckEmailModal'
import translations from '../../../../../translations/keys'
import { ExpiredLinkModal } from '../../../../components/Modals/EditProfileModals/ExpiredLinkModal/ExpiredLinkModal'
import { CancelChangesModal } from '../../../../components/Modals/EditProfileModals/CancelChangesModal/CancelChangesModal'
import { fetchEditProfilePreventEmailUpdate } from '../../../../../store/editProfile/editProfile.actions'

import { EditProfileModalProps } from './EditProfileModal.types'

export const EditProfileModals: React.FC<EditProfileModalProps> = (props) => {
  const dispatch = useDispatch()

  const showEditPhone = useSelector(modalsVisibleSelector(EDIT_PROFILE_MODALS.EDIT_PROFILE_EDIT_PHONE_MODAL))
  const onCloseEditPhone = () => {
    dispatch(closeModal(EDIT_PROFILE_MODALS.EDIT_PROFILE_EDIT_PHONE_MODAL))
  }

  const showConfirmPhone = useSelector(modalsVisibleSelector(EDIT_PROFILE_MODALS.EDIT_PROFILE_CONFIRM_PHONE_MODAL))
  const onCloseConfirmPhone = () => {
    dispatch(closeModal(EDIT_PROFILE_MODALS.EDIT_PROFILE_CONFIRM_PHONE_MODAL))
  }

  const showChangeEmail = useSelector(modalsVisibleSelector(EDIT_PROFILE_MODALS.EDIT_PROFILE_CHANGE_EMAIL_MODAL))
  const onCloseChangeEmail = () => {
    dispatch(closeModal(EDIT_PROFILE_MODALS.EDIT_PROFILE_CHANGE_EMAIL_MODAL))
  }

  const showLeave = useSelector(modalsVisibleSelector(EDIT_PROFILE_MODALS.EDIT_PROFILE_LEAVE_MODAL))
  const onCloseLeave = () => {
    dispatch(closeModal(EDIT_PROFILE_MODALS.EDIT_PROFILE_LEAVE_MODAL))
  }
  const onLeaveSuccess = () => {
    props.onLeaveSuccess()
    onCloseLeave()
  }
  const onLeaveCancel = () => {
    props.onLeaveCancel()
    onCloseLeave()
  }

  const showCheckEmail = useSelector(modalsVisibleSelector(EDIT_PROFILE_MODALS.EDIT_PROFILE_CHECK_EMAIL))
  const onCloseCheckEmail = () => {
    dispatch(closeModal(EDIT_PROFILE_MODALS.EDIT_PROFILE_CHECK_EMAIL))
  }

  const showExpiredLink = useSelector(modalsVisibleSelector(EDIT_PROFILE_MODALS.EDIT_PROFILE_EXPIRED_LINK))
  const onCloseExpiredLink = () => {
    dispatch(closeModal(EDIT_PROFILE_MODALS.EDIT_PROFILE_EXPIRED_LINK))
  }
  const onExpiredLinkSuccess = () => {
    props.onExpiredLinkSuccess()
  }

  const showCancelChanges = useSelector(modalsVisibleSelector(EDIT_PROFILE_MODALS.EDIT_PROFILE_CANCEL_CHANGES))
  const onCloseCancelChanges = () => {
    dispatch(closeModal(EDIT_PROFILE_MODALS.EDIT_PROFILE_CANCEL_CHANGES))
  }
  const onCancelChangesSuccess = () => {
    dispatch(fetchEditProfilePreventEmailUpdate())
    onCloseCancelChanges()
  }

  return (
    <>
      {showEditPhone && <EditPhoneModal onClose={onCloseEditPhone} />}
      {showConfirmPhone && <ConfirmPhoneModal onClose={onCloseConfirmPhone} />}
      {showChangeEmail && <ChangeEmailModal onClose={onCloseChangeEmail} />}
      <LeaveModal
        onClose={onCloseLeave}
        onSuccess={onLeaveSuccess}
        onCancel={onLeaveCancel}
        canLeave={props.canLeave}
        show={showLeave}
      />
      {showCheckEmail && (
        <CheckEmailModal onClose={onCloseCheckEmail} description={translations.SENT_NEW_VERIFICATION_TO_EMAIL} />
      )}
      {showExpiredLink && <ExpiredLinkModal onClose={onCloseExpiredLink} onSuccess={onExpiredLinkSuccess} />}
      {showCancelChanges && <CancelChangesModal onClose={onCloseCancelChanges} onSuccess={onCancelChangesSuccess} />}
    </>
  )
}
