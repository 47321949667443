import React, { useState, useMemo } from 'react'
import { useSelector } from 'react-redux'

import translations from '../../../../../translations/keys'
import { getFeature } from '../../../../../utils/subscription/getFeature'
import { CheckIcon } from '../../../../assets/icons'
import { SmallArrowDownIcon } from '../../../../assets/SmallArrowDownIcon'
import Box from '../../../../ui/Box'
import Button from '../../../../ui/Button'
import { BUTTON_VARIANTS } from '../../../../ui/Button/Button.types'
import Typography from '../../../../ui/Typography'
import { TYPOGRAPHY_NAMES } from '../../../../ui/Typography/Typography.types'
import { subscriptionUserPlanDataSelector } from '../../../../../store/subscription/userPlan/userPlan.selectors'

import { StyledWrapper } from './ConnectedPlan.styles'
import { ConnectedPlanProps } from './ConnectedPlan.types'

const SHORT_VIEW_LENGTH = 3

export const ConnectedPlan: React.FC<ConnectedPlanProps> = ({ features }) => {
  const data = useSelector(subscriptionUserPlanDataSelector)
  const [fullVisible, setFullVisible] = useState(false)

  const visibleFeatures = useMemo(() => features.slice(0, fullVisible ? features.length : SHORT_VIEW_LENGTH), [
    features,
    fullVisible,
  ])

  const onShowAll = () => {
    setFullVisible(true)
  }

  const getPlanText = () =>
    data?.name ? `${data.name}${translations.PLAN_IS_CONNECTED}` : translations.PLAN_IS_CONNECTED

  return (
    <StyledWrapper pb={fullVisible ? 3 : 4}>
      <div>
        <Typography name={TYPOGRAPHY_NAMES.H4}>{getPlanText()}</Typography>
        <Box mt={2}>
          {visibleFeatures.map((feature) => (
            <Box key={feature.name} display="flex" mb={1}>
              <CheckIcon color="blue" />
              <span>{getFeature(feature)}</span>
            </Box>
          ))}
          {features.length > SHORT_VIEW_LENGTH && !fullVisible && (
            <Box display="flex" justifyContent="center">
              <Button variant={BUTTON_VARIANTS.TEXT} onClick={onShowAll} textColor="grey">
                {translations.SHOW_ALL}{' '}
                <Box ml={1} display="flex">
                  <SmallArrowDownIcon />
                </Box>
              </Button>
            </Box>
          )}
        </Box>
      </div>
    </StyledWrapper>
  )
}
