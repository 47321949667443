import * as yup from 'yup'

export const FILE_NAME_MAX_LENGTH = 208

const SPECIAL_CHARS = "!@#$%^&()\\-_=\\[\\]{};,'`~. "
const FILE_NAME_REG_EXP = new RegExp(
  `^([0-9a-zA-Z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\u0600-\u065F\u066A-\u06EF\u06FA-\u06FF\\d${SPECIAL_CHARS}]*)$`
)

export const fileNameValidationSchema = (errorCode = 'INCORRECT_FILE_NAME', emptyErrorCode = 'REQUIRED_FIELD') =>
  yup
    .string()
    .trim(emptyErrorCode)
    .required(emptyErrorCode)
    .matches(FILE_NAME_REG_EXP, errorCode)
    .max(FILE_NAME_MAX_LENGTH, errorCode)
