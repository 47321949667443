import styled, { createGlobalStyle, ThemedStyledProps } from 'styled-components'

import { StyledThemeScheme } from '../../../../themes/light'
import Link from '../../../ui/Link'
import Typography from '../../../ui/Typography'

export const AuthorizationGlobalStyle = createGlobalStyle`
  body {
    ${({ theme }: ThemedStyledProps<unknown, StyledThemeScheme>) => `background-color: ${theme.palette.blueDark};`}
    ${({ theme }) => `@media ${theme.devices.mobile} {
      background-color: ${theme.palette.white};
    }`}
  }
`
export const StyledWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  flex-direction: column;
`
export const StyledContent = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  align-items: stretch;
  ${({ theme }) => `@media ${theme.devices.mobile} {
  width: 100%;
}`}
`
export const StyledFooter = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 64px 0 24px;
  width: 764px;
  box-sizing: border-box;
  ${({ theme }) => `@media ${theme.devices.laptop} {
  width: 100%;
  padding: 69px 32px 24px;
}`}
  ${({ theme }) => `@media ${theme.devices.mobile} {
  background: ${theme.palette.white};
  flex-direction: column;
  justify-content: center;
  padding: 0 16px 24px;
}`}
`
export const StyledLinks = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${({ theme }) => `@media ${theme.devices.mobile} {
  width: 100%;
}`}
`
export const StyledLink = styled(Link)`
  margin-right: 32px;
  && {
    color: ${({ theme }) => theme.palette.white};
    ${({ theme }) => `@media ${theme.devices.mobile} {
    color: ${theme.palette.grey};
    margin-right: 0;
  }`}
  }
`
export const StyledCopyright = styled(Typography)`
  margin-left: auto;
  ${({ theme }) => `color: ${theme.palette.white};`}
  ${({ theme }) => `@media ${theme.devices.mobile} {
  color: ${theme.palette.grey};
  margin-left: 0;
  margin-top: 9px;
}`}
`
