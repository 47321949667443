import { FetchBlockedNotificationsResponse, UpdateNotificationsBody } from '../../types/preferencesNotifications'
import { ReadNotificationsBody, UserNotificationsResponse } from '../../types/userNotifications'
import { Params, prepareUrlParams } from '../../utils/prepareUrlParams/prepareUrlParams'
import { Ajax } from '../ajax'

class NotificationService extends Ajax {
  pathname = 'notification'

  fetchBlockedNotifications = () => this.getJSON<FetchBlockedNotificationsResponse>(`${this.pathname}/allblocked`)

  fetchUpdateBlockedNotifications = (body: UpdateNotificationsBody) => this.put(`${this.pathname}/updateblocked`, body)

  fetchNotifications = (params: Params) =>
    this.getJSON<UserNotificationsResponse>(`${this.pathname}?${prepareUrlParams(params)}`)

  fetchReadNotifications = (body: ReadNotificationsBody) => this.put(this.pathname, body)
}

export const notificationService = new NotificationService()
