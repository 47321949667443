import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { CARD_SPECIFICATION_MODALS } from '../../../../../constants/cardSpecification'
import { closeModal } from '../../../../../store/modals/modals.actions'
import { modalsVisibleSelector } from '../../../../../store/modals/modals.selectors'
import LogoutModal from '../../../../components/Modals/CardSpecificationModals/LogoutModal'

export const Modals = () => {
  const dispatch = useDispatch()

  const showLogout = useSelector(modalsVisibleSelector(CARD_SPECIFICATION_MODALS.CARD_SPECIFICATION_LOGOUT_MODAL))
  const handleLogoutClose = () => {
    dispatch(closeModal(CARD_SPECIFICATION_MODALS.CARD_SPECIFICATION_LOGOUT_MODAL))
  }

  return showLogout ? <LogoutModal onClose={handleLogoutClose} /> : null
}
