import React from 'react'

import { SvgIconProps } from '../../ui/SvgIcon/SvgIcon.types'
import SvgIcon from '../../ui/SvgIcon'

export const InboxIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <path d="M20.7455 10.4599L20.7408 10.4411L18.1111 3.75908C17.9939 3.38174 17.6447 3.12158 17.2486 3.12158H6.58924C6.1908 3.12158 5.83689 3.38643 5.72439 3.76846L3.2658 10.3825L3.25877 10.3989L3.25408 10.4177C3.22361 10.5325 3.21424 10.6497 3.23064 10.7645C3.2283 10.802 3.22596 10.8396 3.22596 10.8771V19.4528C3.22658 19.8306 3.37691 20.1927 3.64401 20.4598C3.91112 20.7269 4.27321 20.8772 4.65096 20.8778H19.351C20.1361 20.8778 20.776 20.238 20.7783 19.4528V10.8771C20.7783 10.8466 20.7783 10.8161 20.776 10.7903C20.7853 10.6755 20.776 10.5653 20.7455 10.4599ZM13.8127 9.45205L13.8056 9.82002C13.7869 10.8724 13.0603 11.5802 11.9986 11.5802C11.4806 11.5802 11.0353 11.4138 10.7142 11.0974C10.3931 10.781 10.2174 10.3403 10.208 9.82002L10.201 9.45205H5.37752L7.2408 4.92158H16.5971L18.5119 9.45205H13.8127ZM5.02361 11.2521H8.71033C9.27986 12.5903 10.4916 13.3802 12.001 13.3802C12.7908 13.3802 13.5244 13.1599 14.1174 12.7427C14.6377 12.3771 15.0431 11.8661 15.3056 11.2521H18.9736V19.0778H5.02361V11.2521Z" />
  </SvgIcon>
)
