import React, { useEffect, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Form, Formik, FormikProps } from 'formik'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { location } from '../../../utils/location'
import { FETCH_STATUSES } from '../../../types/fetch'
import translations from '../../../translations/keys'
import { cleanAuth, cleanLoginError, fetchLogin } from '../../../store/auth/auth.actions'
import { authLoginFetchStatusSelector, authLoginErrorSelector } from '../../../store/auth/auth.selectors'
import { closeModal, openModal } from '../../../store/modals/modals.actions'
import { modalsVisibleSelector } from '../../../store/modals/modals.selectors'
import Box from '../../ui/Box'
import AuthorizationBox from '../../components/Authorization/AuthorizationBox'
import FormTextInput from '../../ui/Forms/FormTextInput'
import FormPassword from '../../ui/Forms/FormPassword'
import FormCheckbox from '../../ui/Forms/FormCheckbox'
import Link from '../../ui/Link'
import Button from '../../ui/Button'
import FormWrapper from '../../components/FormWrapper'
import { FormError } from '../../components/FormError'
import { AUTH_NOTIFICATIONS } from '../../components/Modals/LoginModals/LoginModals.contants'
import { ResendEmailModal } from '../../components/Modals/LoginModals/ResendEmailModal'
import useAuth from '../../../hooks/useAuth'
import { EMAIL_MAX_LENGTH } from '../../../validation/emailValidationSchema'
import { NotificationBadge } from '../../ui/NotificationBadge/NotificationBadge'
import { LoginFields } from '../../../types/login'
import { loginValidationSchema } from '../../../validation/login/loginValidationSchema'

import { LoginFormValues } from './Login.types'
import { useShowNotifications } from './hooks/useShowNotifications'

const StyledTitle = styled.span`
  display: inline-block;
  margin-bottom: 40px;
`

export const Login = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { setAuthenticated } = useAuth()
  const loginFetchStatus = useSelector(authLoginFetchStatusSelector)
  const loginError = useSelector(authLoginErrorSelector)
  const { t } = useTranslation()
  const initialValues: LoginFormValues = {
    [LoginFields.email]: '',
    [LoginFields.password]: '',
    [LoginFields.rememberMe]: false,
  }
  const formRef: React.Ref<FormikProps<LoginFormValues>> = useRef(null)

  const showNotificationVerifyEmail = useSelector(modalsVisibleSelector(AUTH_NOTIFICATIONS.VERIFY_EMAIL_FIRST))
  const { setShowVerifiedBadge, showVerifiedBadge } = useShowNotifications()

  const handleCloseNotificationVerifyEmail = () => {
    dispatch(cleanAuth())
    dispatch(closeModal(AUTH_NOTIFICATIONS.VERIFY_EMAIL_FIRST))
  }

  const handleSubmit = (values: LoginFormValues) => {
    // TODO: change after native app update
    dispatch(fetchLogin(values))
  }

  const onHideVerifiedBadge = () => {
    setShowVerifiedBadge(false)
  }

  const onChangePassword = () => {
    if (loginError) {
      dispatch(cleanLoginError())
    }
  }

  useEffect(() => {
    if (loginFetchStatus === FETCH_STATUSES.SUCCESS) {
      setAuthenticated && setAuthenticated(true)
      history.push(location.contractsAllUrl())
    } else if (loginFetchStatus === FETCH_STATUSES.FAILURE && loginError?.lastErrorCode === 'VERIFY_EMAIL_FIRST') {
      dispatch(openModal(AUTH_NOTIFICATIONS.VERIFY_EMAIL_FIRST))
    } else if (
      loginFetchStatus === FETCH_STATUSES.FAILURE &&
      loginError?.lastErrorCode === 'WRONG_CREDENTIALS' &&
      formRef.current
    ) {
      formRef.current.setFieldValue(LoginFields.password, '', false)
    }
  }, [loginFetchStatus, loginError])

  useEffect(
    () => () => {
      dispatch(cleanAuth())
    },
    []
  )

  return (
    <AuthorizationBox title={<StyledTitle>{t(translations.LOG_IN)}</StyledTitle>}>
      <FormWrapper>
        <Formik
          validationSchema={loginValidationSchema}
          initialValues={initialValues}
          onSubmit={handleSubmit}
          innerRef={formRef}
        >
          {(props) => (
            <>
              <Form>
                <FormTextInput
                  name={LoginFields.email}
                  label={t(translations.EMAIL_LABEL)}
                  placeholder={t(translations.EMAIL_YOUR_PLACEHOLDER)}
                  maxLength={EMAIL_MAX_LENGTH}
                  trim
                />
                <FormPassword
                  name={LoginFields.password}
                  label={t(translations.PASSWORD_LABEL)}
                  placeholder={t(translations.PASSWORD_PLACEHOLDER)}
                  autoComplete="new-password"
                  onChange={onChangePassword}
                />
                <Button fullWidth type="submit">
                  {t(translations.LOG_IN)}
                </Button>
                <FormError error={loginError} />
                <Box textAlign="center" mb={2}>
                  <FormCheckbox name={LoginFields.rememberMe} label={t(translations.LOGIN_REMEMBER_ME)} />
                </Box>
                <Box textAlign="center" mb={2}>
                  <Link to={location.forgotPasswordUrl()}>{t(translations.LOGIN_FORGOT_PASSWORD)}</Link>
                </Box>
                <Box textAlign="center" mb={2}>
                  {t(translations.LOGIN_NO_ACCOUNT)}{' '}
                  <Link to={location.registrationUrl()}>{t(translations.LOGIN_SIGN_UP_NOW)}</Link>
                </Box>
                <Box textAlign="center" mb={5}>
                  <NotificationBadge
                    isShow={showVerifiedBadge}
                    message={t(translations.CONFIRMED_EMAIL)}
                    onClose={onHideVerifiedBadge}
                  />
                </Box>
              </Form>
              {showNotificationVerifyEmail && (
                <ResendEmailModal email={props.values.email} onClose={handleCloseNotificationVerifyEmail} />
              )}
            </>
          )}
        </Formik>
      </FormWrapper>
    </AuthorizationBox>
  )
}
