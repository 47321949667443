import React from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import translations from '../../../../../translations/keys'
import DropdownButton from '../../../../ui/DropdownButton'
import { BUTTON_VARIANTS } from '../../../../ui/Button/Button.types'
import { FileIcon, LaptopIcon, UsersIcon } from '../../../../assets/icons'
import { Item } from '../../../../ui/DropdownButton/DropdownButton.types'
import { TEMPLATES_MODALS } from '../../../../../constants/templates'
import { openModal } from '../../../../../store/modals/modals.actions'

import { StyledText } from './UploadMenu.styles'

export const UploadMenu: React.FC = React.memo(() => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const items: Item[] = [
    {
      name: t(translations.UPLOAD_DOCUMENT),
      icon: LaptopIcon,
      onClick: () => {
        dispatch(openModal(TEMPLATES_MODALS.UPLOAD_TEMPLATE))
      },
    },
    {
      name: t(translations.LOAD_EXISTING_CONTRACT),
      icon: FileIcon,
      onClick: () => {
        dispatch(openModal(TEMPLATES_MODALS.CREATE_TEMPLATE_FROM_CONTRACT))
      },
    },
    {
      name: t(translations.CLM_WITHOUT_DOCUMENT),
      icon: UsersIcon,
      onClick: () => {
        dispatch(openModal(TEMPLATES_MODALS.CHOOSE_SIGNING_ORDER))
      },
    },
    // TODO UQD-1410 uncomment when this will work
    // {
    //   name: translations.UPLOAD_FROM_GOOGLE,
    //   icon: GoogleDriveIcon,
    //   disabled: true,
    // },
    // {
    //   name: translations.UPLOAD_FROM_ONE_DRIVE,
    //   icon: OneDriveIcon,
    //   disabled: true,
    // },
  ]

  return (
    <DropdownButton
      fullWidth
      triangleColor="white"
      iconColor="blue"
      variant={BUTTON_VARIANTS.PRIMARY}
      items={items}
      popoverStyles={{
        width: '262px',
        offsetTop: 5,
        placement: 'bottom-end',
      }}
    >
      <StyledText>{t(translations.CREATE_TEMPLATE)}</StyledText>
    </DropdownButton>
  )
})
