import { createFetchActionTypes } from '../../../utils/createFetchActionTypes'
import { FetchFailureAction, FetchFailurePayload } from '../../../types/fetch'
import { EditableElem } from '../../../types/editableElem'
import {
  DocCorrectionsResponseBody,
  DocCorrectionsById,
  DocCorrectionsByPages,
  DocCorrection,
  DocCorrectionsConfirmRequest,
  DocCancelCorrectionConfirmation,
} from '../../../types/correction'
import { ConfirmationStatus } from '../../../types/confirmationActions'

import * as TYPES from './corrections.types'

export const fetchCorrectionsTypes = createFetchActionTypes(TYPES.CorrectionsActions.FETCH_CORRECTIONS)
export const fetchCreateCorrectionTypes = createFetchActionTypes(TYPES.CorrectionsActions.FETCH_CREATE_CORRECTION)
export const fetchConfirmCorrectionTypes = createFetchActionTypes(TYPES.CorrectionsActions.FETCH_CONFIRM_CORRECTION)
export const fetchCancelCorrectionConfirmationTypes = createFetchActionTypes(
  TYPES.CorrectionsActions.FETCH_CANCEL_CORRECTION_CONFIRMATION
)

export const addCorrection = (correction: DocCorrection, currentUserId: string): TYPES.AddCorrectionAction => ({
  type: TYPES.CorrectionsActions.ADD_CORRECTION,
  payload: { correction, currentUserId },
})
export const setCorrection = (correction: EditableElem): TYPES.SetCorrectionAction => ({
  type: TYPES.CorrectionsActions.SET_CORRECTION,
  payload: { correction },
})
export const setCorrectionText = (id: string, text: string): TYPES.SetCorrectionTextAction => ({
  type: TYPES.CorrectionsActions.SET_CORRECTION_TEXT,
  payload: { id, text },
})
export const unsetCorrection = (id: string, currentUserId: string): TYPES.UnsetCorrectionAction => ({
  type: TYPES.CorrectionsActions.UNSET_CORRECTION,
  payload: { id, currentUserId },
})
export const clearCorrections = (): TYPES.ClearCorrectionsAction => ({
  type: TYPES.CorrectionsActions.CLEAR_CORRECTIONS,
})

export const fetchCorrections = (contractId: string): TYPES.FetchCorrectionsAction => ({
  type: fetchCorrectionsTypes.request,
  payload: { contractId },
})
export const fetchCorrectionsSuccess = (
  byId: DocCorrectionsById,
  byPages: DocCorrectionsByPages
): TYPES.FetchCorrectionsSuccessAction => ({
  type: fetchCorrectionsTypes.success,
  payload: { byId, byPages },
})
export const fetchCorrectionsFailure = (payload: FetchFailurePayload): FetchFailureAction => ({
  type: fetchCorrectionsTypes.failure,
  payload,
})

export const fetchCreateCorrection = (
  contractId: string,
  correction: DocCorrection
): TYPES.FetchCreateCorrectionAction => ({
  type: fetchCreateCorrectionTypes.request,
  payload: { contractId, correction },
})
export const fetchCreateCorrectionSuccess = (
  response: DocCorrectionsResponseBody
): TYPES.FetchCreateCorrectionSuccessAction => ({
  type: fetchCreateCorrectionTypes.success,
  payload: response,
})
export const fetchCreateCorrectionFailure = (payload: FetchFailurePayload): FetchFailureAction => ({
  type: fetchCreateCorrectionTypes.failure,
  payload,
})

export const fetchConfirmCorrection = (payload: DocCorrectionsConfirmRequest): TYPES.FetchConfirmCorrectionAction => ({
  type: fetchConfirmCorrectionTypes.request,
  payload,
})
export const fetchConfirmCorrectionSuccess = (
  confirmStatus: ConfirmationStatus
): TYPES.FetchConfirmCorrectionSuccessAction => ({
  type: fetchConfirmCorrectionTypes.success,
  payload: { confirmStatus },
})
export const fetchConfirmCorrectionFailure = (payload: FetchFailurePayload): FetchFailureAction => ({
  type: fetchConfirmCorrectionTypes.failure,
  payload,
})

export const fetchCancelCorrectionConfirmation = (
  payload: DocCancelCorrectionConfirmation
): TYPES.FetchCancelCorrectionConfirmationAction => ({
  type: fetchCancelCorrectionConfirmationTypes.request,
  payload,
})
export const fetchCancelCorrectionConfirmationSuccess = (
  confirmStatus: ConfirmationStatus
): TYPES.FetchCancelCorrectionConfirmationSuccessAction => ({
  type: fetchCancelCorrectionConfirmationTypes.success,
  payload: { confirmStatus },
})
export const fetchCancelCorrectionConfirmationFailure = (payload: FetchFailurePayload): FetchFailureAction => ({
  type: fetchCancelCorrectionConfirmationTypes.failure,
  payload,
})
