import { FetchFailureAction } from '../../../types/fetch'
import { createReducer } from '../../createReducer'

import * as TYPES from './viewOptions.types'

type Actions = FetchFailureAction | TYPES.SetConfirmationLabelAction

export const initialState: TYPES.ViewOptionsState = {
  isSignatureNamesVisible: false,
  confirmationLabel: null,
}

export const viewOptions = createReducer<TYPES.ViewOptionsState, Actions>(initialState, {
  [TYPES.ViewOptionsActions.CONTRACTS_TOGGLE_SIGNATURE_NAMES_VISIBLE]: (state) => ({
    ...state,
    isSignatureNamesVisible: !state.isSignatureNamesVisible,
  }),
  [TYPES.ViewOptionsActions.SET_CONFIRMATION_LABEL]: (state, action) => ({
    ...state,
    confirmationLabel: (action as TYPES.SetConfirmationLabelAction).payload,
  }),
  [TYPES.ViewOptionsActions.UNSET_CONFIRMATION_LABEL]: (state) => ({
    ...state,
    confirmationLabel: initialState.confirmationLabel,
  }),
  [TYPES.ViewOptionsActions.CLEAN_CONTRACTS_VIEW_OPTIONS]: () => ({ ...initialState }),
})
