import { combineEpics } from 'redux-observable'

import { uploadEpics } from './upload/upload.epics'
import { reuploadEpics } from './reupload/reupload.epics'
import { previewEpics } from './preview/preview.epics'
import { foldersEpics } from './folders/folders.epics'
import { recipientsEpics } from './recipients/recipients.epics'
import { contractsListEpics } from './contractsList/contractsList.epics'
import { placementEpics } from './placement/placement.epics'
import { signingEpics } from './signing/signing.epics'
import { downloadEpics } from './download/download.epics'
import { voidEpics } from './void/void.epics'
import { ownerViewEpics } from './ownerView/ownerView.epics'
import { saveAsTemplateEpics } from './saveAsTemplate/saveAsTemplate.epics'
import { briefListEpics } from './briefList/briefList.epics'
import { messageEpics } from './message/message.epics'
import { otpVerificationEpics } from './otpVerification/otpVerification.epics'
import { verificationEpics } from './verification/verification.epics'
import { noneVerificationEpics } from './noneVerification/noneVerification.epics'
import { strikesEpics } from './strikes/strikes.epics'
import { correctionsEpics } from './corrections/corrections.epics'
import { commentsEpics } from './comments/comments.epics'
import { historyEpics } from './history/history.epics'
import { clearAndContinueEpics } from './clearAndContinue/clearAndContinue.epics'

export const contractsEpics = combineEpics(
  uploadEpics,
  reuploadEpics,
  previewEpics,
  foldersEpics,
  recipientsEpics,
  contractsListEpics,
  placementEpics,
  signingEpics,
  downloadEpics,
  voidEpics,
  ownerViewEpics,
  saveAsTemplateEpics,
  briefListEpics,
  messageEpics,
  otpVerificationEpics,
  verificationEpics,
  noneVerificationEpics,
  strikesEpics,
  correctionsEpics,
  commentsEpics,
  historyEpics,
  clearAndContinueEpics
)
