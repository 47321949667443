import React from 'react'

import translations from '../../../../../translations/keys'
import StubEKYCModal from '../../VerificationsModals/StubEKYCModal'

import { SigningActionsStubEKYCModalProps } from './SigningActionsStubEKYCModal.types'

export const SigningActionsStubEKYCModal: React.FC<SigningActionsStubEKYCModalProps> = ({ onClose }) => (
  <StubEKYCModal onClose={onClose} description={translations.EKYC_ACTIONS_STUB_DESCRIPTION} />
)
