import React from 'react'
import { useTranslation } from 'react-i18next'

import { USER_NOTIFICATIONS_TYPES } from '../../../../../constants/userNotifications'
import translations from '../../../../../translations/keys'
import { PreferencesNotificationsTypes } from '../../../../../types/preferencesNotifications'
import Checkbox from '../../../../ui/Checkbox'
import Typography from '../../../../ui/Typography'
import { TYPOGRAPHY_NAMES } from '../../../../ui/Typography/Typography.types'
import Box from '../../../../ui/Box'

import { StyledTitle, StyledOption } from './NotificationsBox.styles'
import { NotificationsBoxProps } from './NotificationsBox.types'

const CHECKBOXES_WIDTH = 247

export const NotificationsBox: React.FC<NotificationsBoxProps> = ({
  title,
  values,
  list,
  disabledFields,
  allTypes,
  onToggleAllType,
  onChange,
}) => {
  const { t } = useTranslation()
  return (
    <Box mb={5}>
      <StyledTitle>
        <Typography name={TYPOGRAPHY_NAMES.H3}>{title}</Typography>
        {onToggleAllType && allTypes && (
          <Box display="flex" width={CHECKBOXES_WIDTH} justifyContent="space-between">
            {USER_NOTIFICATIONS_TYPES.map((notificationType) => {
              const onChange = () => {
                onToggleAllType(notificationType.value)
              }
              return notificationType.value === PreferencesNotificationsTypes.EMAIL ? (
                <Box key={notificationType.value} pr={3}>
                  {t(translations[notificationType.label])}
                </Box>
              ) : (
                <Checkbox
                  key={notificationType.value}
                  onChange={onChange}
                  checked={allTypes[notificationType.value]}
                  label={t(translations[notificationType.label])}
                />
              )
            })}
          </Box>
        )}
      </StyledTitle>
      {list.map((item) => (
        <StyledOption key={item}>
          <span>{t(translations[item])}</span>
          <Box display="flex" width={CHECKBOXES_WIDTH} justifyContent="space-between">
            {USER_NOTIFICATIONS_TYPES.map((notificationType) => {
              const checked = values[item][notificationType.value]
              const disabledField = disabledFields && disabledFields[notificationType.value]
              const disabled = disabledField ? disabledField[item] : undefined
              const onToggle = () => {
                onChange({ type: notificationType.value, notification: item }, !checked)
              }

              return (
                <Checkbox
                  key={notificationType.value}
                  onChange={onToggle}
                  checked={checked}
                  label={t(translations[notificationType.label])}
                  invisibleLabel
                  disabled={disabled}
                />
              )
            })}
          </Box>
        </StyledOption>
      ))}
    </Box>
  )
}
