import React from 'react'

import { StyledCard } from './Card.styles'
import { CardProps } from './Card.types'

export const Card: React.FC<CardProps> = (props) => {
  const { icon, text, isActive } = props
  return (
    <StyledCard isActive={isActive}>
      {icon}
      {text}
    </StyledCard>
  )
}
