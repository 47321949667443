import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { modalsPayloadSelector } from '../../../../../store/modals/modals.selectors'
import translations from '../../../../../translations/keys'
import { CONTRACTS_MODALS } from '../../../../../constants/contracts'
import { NOTIFICATION_MODAL_TYPES } from '../../../../ui/Modals/NotificationModal/NotificationModal.constants'
import { NotificationModalView } from '../../../../ui/Modals/NotificationModal/NotificationModalView'
import {
  clearDeleteContract,
  fetchDeleteContract,
} from '../../../../../store/contracts/contractsList/contractsList.actions'
import {
  contractsListDeleteSuccessSelector,
  contractsListDeleteFetchStatusSelector,
  contractsListDeleteErrorSelector,
} from '../../../../../store/contracts/contractsList/contractsList.selectors'
import { useShowFetchError } from '../../../../hooks/useShowFetchError'
import { useDispatchUnmount } from '../../../../../hooks/useDispatchUnmount'

import { DeleteContractModalProps } from './DeleteContractModal.types'

export const DeleteContractModal: React.FC<DeleteContractModalProps> = ({ onClose }) => {
  const dispatch = useDispatch()
  const { description, id, isFolder, recipient } = useSelector(modalsPayloadSelector(CONTRACTS_MODALS.DELETE_CONTRACT))
  const deletedSuccessfully = useSelector(contractsListDeleteSuccessSelector)
  const fetchStatus = useSelector(contractsListDeleteFetchStatusSelector)
  const error = useSelector(contractsListDeleteErrorSelector)

  useEffect(() => {
    if (deletedSuccessfully) {
      onClose()
    }
  }, [deletedSuccessfully])
  useShowFetchError(fetchStatus, error)
  useDispatchUnmount(clearDeleteContract)

  const onSuccess = () => {
    dispatch(fetchDeleteContract(id, isFolder, recipient))
  }

  return (
    <NotificationModalView
      type={NOTIFICATION_MODAL_TYPES.WARNING}
      onClose={onClose}
      description={description}
      successButtonText={translations.DELETE}
      onSuccess={onSuccess}
    />
  )
}
