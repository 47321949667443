import React from 'react'

import MainTemplate from '../../components/Main/MainTemplate'

import { Contacts as ContactsPage } from './Contacts'

export const Contacts = () => (
  <MainTemplate>
    <ContactsPage />
  </MainTemplate>
)
