import { FetchFailureAction, FETCH_STATUSES } from '../../../types/fetch'
import { createReducer } from '../../createReducer'

import * as TYPES from './signing.types'
import * as ACTIONS from './signing.actions'

type Actions =
  | TYPES.FetchSigningContractDocumentSuccessAction
  | FetchFailureAction
  | TYPES.FetchDefaultSignSuccess
  | TYPES.ScrollToNearestSignSuccess
  | TYPES.SetSigningSuccessMessageAction
  | TYPES.SetSignatureAction
  | TYPES.SetRecipientToken

export const initialState: TYPES.SigningState = {
  contract: {
    data: null,
    fetchStatus: FETCH_STATUSES.IDLE,
    error: null,
  },
  sign: {
    fetchStatus: FETCH_STATUSES.IDLE,
    error: null,
  },
  defaultSign: {
    data: {
      id: '',
      image: '',
    },
    fetchStatus: FETCH_STATUSES.IDLE,
    error: null,
  },
  scrollToSign: {
    placementId: '',
    error: null,
  },
  notification: null,
  decline: {
    fetchStatus: FETCH_STATUSES.IDLE,
    error: null,
  },
  viewed: {
    fetchStatus: FETCH_STATUSES.IDLE,
    error: null,
  },
  totalPages: {
    fetchStatus: FETCH_STATUSES.IDLE,
    error: null,
  },
  recipientToken: '',
}

export const signing = createReducer<TYPES.SigningState, Actions>(initialState, {
  [ACTIONS.fetchSigningContractDocumentTypes.request]: (state) => ({
    ...state,
    contract: {
      ...state.contract,
      fetchStatus: FETCH_STATUSES.REQUEST,
    },
  }),
  [ACTIONS.fetchSigningContractDocumentTypes.success]: (state, action) => ({
    ...state,
    contract: {
      ...state.contract,
      data: (action as TYPES.FetchSigningContractDocumentSuccessAction).payload,
      fetchStatus: FETCH_STATUSES.SUCCESS,
    },
  }),
  [ACTIONS.fetchSigningContractDocumentTypes.failure]: (state, action) => ({
    ...state,
    contract: {
      ...state.contract,
      fetchStatus: FETCH_STATUSES.FAILURE,
      error: (action as FetchFailureAction).payload,
    },
  }),

  [TYPES.SigningActions.CLEAR_SIGNING]: () => ({ ...initialState }),

  [ACTIONS.fetchSignContractTypes.request]: (state) => ({
    ...state,
    sign: {
      ...state.sign,
      fetchStatus: FETCH_STATUSES.REQUEST,
    },
  }),
  [ACTIONS.fetchSignContractTypes.success]: (state) => ({
    ...state,
    sign: {
      ...state.sign,
      fetchStatus: FETCH_STATUSES.SUCCESS,
    },
  }),
  [ACTIONS.fetchSignContractTypes.failure]: (state, action) => ({
    ...state,
    sign: {
      ...state.sign,
      fetchStatus: FETCH_STATUSES.FAILURE,
      error: (action as FetchFailureAction).payload,
    },
  }),

  [ACTIONS.fetchDefaultSignTypes.request]: (state) => ({
    ...state,
    defaultSign: {
      ...state.defaultSign,
      fetchStatus: FETCH_STATUSES.REQUEST,
    },
  }),
  [ACTIONS.fetchDefaultSignTypes.success]: (state, action) => ({
    ...state,
    defaultSign: {
      ...state.defaultSign,
      data: (action as TYPES.FetchDefaultSignSuccess).payload,
      fetchStatus: FETCH_STATUSES.SUCCESS,
    },
  }),
  [ACTIONS.fetchDefaultSignTypes.failure]: (state, action) => ({
    ...state,
    defaultSign: {
      ...state.defaultSign,
      fetchStatus: FETCH_STATUSES.FAILURE,
      error: (action as FetchFailureAction).payload,
    },
  }),

  [ACTIONS.scrollToNearestTypes.request]: (state) => ({
    ...state,
    scrollToSign: {
      ...state.scrollToSign,
      placementId: initialState.scrollToSign.placementId,
    },
  }),
  [ACTIONS.scrollToNearestTypes.success]: (state, action) => ({
    ...state,
    scrollToSign: {
      ...state.scrollToSign,
      placementId: (action as TYPES.ScrollToNearestSignSuccess).payload,
    },
  }),
  [ACTIONS.scrollToNearestTypes.failure]: (state, action) => ({
    ...state,
    scrollToSign: {
      ...state.scrollToSign,
      error: (action as FetchFailureAction).payload,
    },
  }),

  [TYPES.SigningActions.SET_SIGNING_SUCCESS_MESSAGE]: (state, action) => ({
    ...state,
    notification: (action as TYPES.SetSigningSuccessMessageAction).payload,
  }),
  [TYPES.SigningActions.CLEAR_SIGNING_SUCCESS_MESSAGE]: (state) => ({
    ...state,
    notification: initialState.notification,
  }),

  [ACTIONS.fetchDeclineSigningTypes.request]: (state) => ({
    ...state,
    decline: {
      ...state.decline,
      fetchStatus: FETCH_STATUSES.REQUEST,
    },
  }),
  [ACTIONS.fetchDeclineSigningTypes.success]: (state) => ({
    ...state,
    decline: {
      ...state.decline,
      fetchStatus: FETCH_STATUSES.SUCCESS,
    },
  }),
  [ACTIONS.fetchDeclineSigningTypes.failure]: (state, action) => ({
    ...state,
    decline: {
      ...state.decline,
      fetchStatus: FETCH_STATUSES.FAILURE,
      error: (action as FetchFailureAction).payload,
    },
  }),

  [ACTIONS.setSignatureTypes.success]: (state, action) => {
    const payload = (action as TYPES.SetSignatureAction).payload

    return payload.signatureId && payload.image
      ? {
          ...state,
          defaultSign: {
            ...state.defaultSign,
            data: {
              ...state.defaultSign.data,
              id: payload.signatureId,
              image: payload.image,
            },
          },
        }
      : state
  },
  [ACTIONS.fetchDefaultSignTypes.failure]: (state, action) => ({
    ...state,
    defaultSign: {
      ...state.defaultSign,
      error: (action as FetchFailureAction).payload,
    },
  }),

  [ACTIONS.fetchViewedContractTypes.request]: (state) => ({
    ...state,
    viewed: {
      ...state.viewed,
      fetchStatus: FETCH_STATUSES.REQUEST,
    },
  }),
  [ACTIONS.fetchViewedContractTypes.success]: (state) => ({
    ...state,
    viewed: {
      ...state.viewed,
      fetchStatus: FETCH_STATUSES.SUCCESS,
    },
  }),
  [ACTIONS.fetchViewedContractTypes.failure]: (state, action) => ({
    ...state,
    viewed: {
      ...state.viewed,
      fetchStatus: FETCH_STATUSES.FAILURE,
      error: (action as FetchFailureAction).payload,
    },
  }),

  [ACTIONS.fetchTotalPagesTypes.request]: (state) => ({
    ...state,
    totalPages: {
      ...state.totalPages,
      fetchStatus: FETCH_STATUSES.REQUEST,
    },
  }),
  [ACTIONS.fetchTotalPagesTypes.success]: (state, action) => ({
    ...state,
    contract: {
      ...state.contract,
      data: (action as TYPES.FetchTotalPagesSuccess).payload,
    },
    totalPages: {
      ...state.totalPages,
      fetchStatus: FETCH_STATUSES.SUCCESS,
    },
  }),
  [ACTIONS.fetchTotalPagesTypes.failure]: (state, action) => ({
    ...state,
    totalPages: {
      ...state.totalPages,
      fetchStatus: FETCH_STATUSES.FAILURE,
      error: (action as FetchFailureAction).payload,
    },
  }),
  [TYPES.SigningActions.SET_RECIPIENT_TOKEN]: (state, action) => ({
    ...state,
    recipientToken: (action as TYPES.SetRecipientToken).payload.recipientToken,
  }),
})
