import React, { createContext } from 'react'

import useProvideAuth from '../hooks/useProvideAuth'

interface AuthContext {
  authenticated: boolean
  setAuthenticated?: React.Dispatch<React.SetStateAction<boolean>>
  loading: boolean
  logout: () => void
  setAuthHeaders: () => void
}

export const authContext = createContext<AuthContext>({
  authenticated: false,
  loading: true,
  logout: () => {},
  setAuthHeaders: () => {},
})

const ProvideAuth: React.FC = ({ children }) => {
  const auth = useProvideAuth()

  return <authContext.Provider value={auth}>{children}</authContext.Provider>
}

export default ProvideAuth
