import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { openModal } from '../../../../../store/modals/modals.actions'
import { ContractStatusTypes } from '../../../../../types/contracts'
import { RecipientActions, RecipientData } from '../../../../../types/recipients'
import { RECIPIENTS_DIAGRAM_MODALS } from '../../../../constants/recipientsDiagram'
import { RecipientsDiagram } from '../../../../ui/RecipientsDiagram'
import * as RECIPIENTS_SELECTORS from '../../../../../store/contracts/recipients/recipients.selectors'
import { LogSidebarProps } from '../../LogSidebar.types'
import { SIGNING_TYPES } from '../../../../../types/signingOrder'
import { ContractProgressSkeleton } from '../../../Skeletons/ContractProgressSkeleton'

export const Progress: React.FC<LogSidebarProps> = ({ onDelete, onEdit }) => {
  const dispatch = useDispatch()
  const order = useSelector(RECIPIENTS_SELECTORS.recipientsOrderSelector)
  const recipientsById = useSelector(RECIPIENTS_SELECTORS.recipientsByIdSelector)
  const recipientsByOrder = useSelector(RECIPIENTS_SELECTORS.recipientsByOrderSelector)
  const recipientsSorted = useSelector(RECIPIENTS_SELECTORS.recipientsSortedSelector)
  const isLoading = useSelector(RECIPIENTS_SELECTORS.recipientsIsLoadingSelector)
  const recipients = { byId: recipientsById, byOrder: recipientsByOrder, sorted: recipientsSorted }
  const editCondition = (recipient: RecipientData) => recipient.status?.type === ContractStatusTypes.NEGATIVE
  const deleteCondition = (recipient: RecipientData) =>
    recipient.status?.type === ContractStatusTypes.NEGATIVE && recipient.action === RecipientActions.VIEW

  const handleRecipientClick = useCallback(
    (id: string) => {
      dispatch(openModal(RECIPIENTS_DIAGRAM_MODALS.VIEW_DIAGRAM_RECIPIENT, { order, recipient: recipientsById[id] }))
    },
    [recipientsById]
  )

  if (isLoading) {
    return <ContractProgressSkeleton />
  }

  return (
    <RecipientsDiagram
      compact
      displayStatus
      sender={null}
      recipients={recipients}
      order={SIGNING_TYPES.SEPARATE}
      editCondition={editCondition}
      deleteCondition={deleteCondition}
      onClick={handleRecipientClick}
      onEdit={onEdit}
      onDelete={onDelete}
    />
  )
}
