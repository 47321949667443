import { FETCH_STATUSES } from '../../../types/fetch'
import { RootState } from '../../rootReducer.types'

export const strikesByIdSelector = (state: RootState) => state.contracts.strikes.byId
export const strikesByPagesSelector = (state: RootState) => state.contracts.strikes.byPages
export const strikesCountSelector = (state: RootState) => Object.keys(state.contracts.strikes.byId).length
export const strikesFetchSelector = (state: RootState) => state.contracts.strikes.fetch

export const strikesIsConfirmedSelector = (state: RootState) =>
  state.contracts.strikes.confirm.status === FETCH_STATUSES.SUCCESS
export const strikesConfirmStatusSelector = (state: RootState) => state.contracts.strikes.confirm.confirmStatus
export const strikesConfirmFetchStatusSelector = (state: RootState) => state.contracts.strikes.confirm.status
export const strikesConfirmErrorSelector = (state: RootState) => state.contracts.strikes.confirm.error

export const strikesCancelConfirmationSuccessSelector = (state: RootState) =>
  state.contracts.strikes.cancelConfirmation.status === FETCH_STATUSES.SUCCESS
export const strikesCancelConfirmationStatusSelector = (state: RootState) =>
  state.contracts.strikes.cancelConfirmation.confirmStatus
export const strikesCancelConfirmationFetchStatusSelector = (state: RootState) =>
  state.contracts.strikes.cancelConfirmation.status
export const strikesCancelConfirmationErrorSelector = (state: RootState) =>
  state.contracts.strikes.cancelConfirmation.error
