import { Action } from 'redux'

export enum ProgressActions {
  'OPEN_PROGRESS' = 'OPEN_PROGRESS',
  'CLOSE_PROGRESS' = 'CLOSE_PROGRESS',
}

export interface ProgressState {
  visible: boolean
}

export type OpenProgressAction = Action

export type CloseProgressAction = Action
