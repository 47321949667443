import { Epic, combineEpics, ofType } from 'redux-observable'
import { concat, of } from 'rxjs'
import { catchError, map, mergeMap, takeUntil } from 'rxjs/operators'

import { contractsService, profileService, recipientsService } from '../../../api'
import { catchFetchError } from '../../../utils/catchFetchError'
import { prepareMessageInitialValues, prepareSenderInfo } from '../../../utils/recipients/recipients'
import { subscriptionUserPlanEnabledFeaturesSelector } from '../../subscription/userPlan/userPlan.selectors'

import * as ACTIONS from './recipients.actions'
import { recipientsContractIdSelector } from './recipients.selectors'

const fetchRecipients: Epic = (action$, state$) =>
  action$.pipe(
    ofType(ACTIONS.fetchRecipientsTypes.request),
    mergeMap(({ payload }) =>
      recipientsService.fetchRecipients(payload.contractId).pipe(
        map((response) =>
          ACTIONS.fetchRecipientsSuccess(response, subscriptionUserPlanEnabledFeaturesSelector(state$.value) || {})
        ),
        catchError(catchFetchError(ACTIONS.fetchRecipientsFailure)),
        takeUntil(action$.pipe(ofType(ACTIONS.fetchRecipientsTypes.success, ACTIONS.fetchRecipientsTypes.failure)))
      )
    )
  )

const fetchAddRecipients: Epic = (action$) =>
  action$.pipe(
    ofType(ACTIONS.fetchAddRecipientsTypes.request),
    mergeMap(({ payload }) =>
      recipientsService.fetchAddRecipients(payload.contractId, payload.body).pipe(
        map(() => ACTIONS.fetchAddRecipientsSuccess(payload.body.recipients)),
        catchError(catchFetchError(ACTIONS.fetchAddRecipientsFailure)),
        takeUntil(
          action$.pipe(ofType(ACTIONS.fetchAddRecipientsTypes.success, ACTIONS.fetchAddRecipientsTypes.failure))
        )
      )
    )
  )

const fetchUpdateRecipient: Epic = (action$, state$) =>
  action$.pipe(
    ofType(ACTIONS.fetchUpdateRecipientTypes.request),
    mergeMap(({ payload }) =>
      concat(
        recipientsService.fetchUpdateRecipient(payload.id, payload.body).pipe(
          map(() => ACTIONS.fetchUpdateRecipientSuccess(payload.id, payload.body.recipients)),
          catchError(catchFetchError(ACTIONS.fetchUpdateRecipientFailure)),
          takeUntil(
            action$.pipe(ofType(ACTIONS.fetchUpdateRecipientTypes.success, ACTIONS.fetchUpdateRecipientTypes.failure))
          )
        ),
        of(ACTIONS.fetchRecipients(recipientsContractIdSelector(state$.value) as string))
      )
    )
  )

const fetchDeleteRecipient: Epic = (action$, state$) =>
  action$.pipe(
    ofType(ACTIONS.fetchDeleteRecipientTypes.request),
    mergeMap(({ payload }) =>
      concat(
        recipientsService.fetchDeleteRecipient(payload.id).pipe(
          map(() => ACTIONS.fetchDeleteRecipientSuccess(payload.id)),
          catchError(catchFetchError(ACTIONS.fetchDeleteRecipientFailure)),
          takeUntil(
            action$.pipe(ofType(ACTIONS.fetchDeleteRecipientTypes.success, ACTIONS.fetchDeleteRecipientTypes.failure))
          )
        ),
        of(ACTIONS.fetchRecipients(recipientsContractIdSelector(state$.value) as string))
      )
    )
  )

const fetchResend: Epic = (action$) =>
  action$.pipe(
    ofType(ACTIONS.fetchResendTypes.request),
    mergeMap(({ payload }) =>
      concat(
        recipientsService.fetchAddRecipients(payload.contractId, payload.body).pipe(
          map(() => ACTIONS.fetchResendSuccess(payload.body.recipients)),
          catchError(catchFetchError(ACTIONS.fetchResendFailure)),
          takeUntil(action$.pipe(ofType(ACTIONS.fetchResendTypes.success, ACTIONS.fetchResendTypes.failure)))
        ),
        of(ACTIONS.fetchRecipients(payload.contractId))
      )
    )
  )

const fetchContractInfo: Epic = (action$) =>
  action$.pipe(
    ofType(ACTIONS.fetchContractInfoTypes.request),
    mergeMap(({ payload }) =>
      contractsService.fetchContract(payload.contractId).pipe(
        map((response) => ACTIONS.fetchContractInfoSuccess(response.name, prepareMessageInitialValues(response))),
        catchError(catchFetchError(ACTIONS.fetchContractInfoFailure)),
        takeUntil(action$.pipe(ofType(ACTIONS.fetchContractInfoTypes.success, ACTIONS.fetchContractInfoTypes.failure)))
      )
    )
  )

const fetchSenderInfo: Epic = (action$) =>
  action$.pipe(
    ofType(ACTIONS.fetchSenderInfoTypes.request),
    mergeMap(() =>
      profileService.fetchEditProfileInitialData().pipe(
        map((response) => ACTIONS.fetchSenderInfoSuccess(prepareSenderInfo(response))),
        catchError(catchFetchError(ACTIONS.fetchSenderInfoFailure)),
        takeUntil(action$.pipe(ofType(ACTIONS.fetchSenderInfoTypes.success, ACTIONS.fetchSenderInfoTypes.failure)))
      )
    )
  )

export const recipientsEpics = combineEpics(
  fetchRecipients,
  fetchAddRecipients,
  fetchUpdateRecipient,
  fetchDeleteRecipient,
  fetchResend,
  fetchContractInfo,
  fetchSenderInfo
)
