import { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'

import translations from '../../../../translations/keys'
import { ContractRouteParams } from '../../../types/signing'
import { PLACEMENT_MODALS } from '../../../constants/placement'
import { openModal } from '../../../../store/modals/modals.actions'
import { FileIcon, UserIcon } from '../../../assets/icons'
import { CONTRACTS_MODALS } from '../../../../constants/contracts'
import * as PREVIEW_SELECTORS from '../../../../store/contracts/preview/preview.selectors'
import { useAccessFeature } from '../../../../hooks/subscription/useAccessFeature'
import { SubscriptionFeatures } from '../../../../types/subscription'

export const useActions = () => {
  const dispatch = useDispatch()
  const { contractId } = useParams<ContractRouteParams>()
  const data = useSelector(PREVIEW_SELECTORS.previewDataSelector)
  const { checkAccess } = useAccessFeature()
  const contractName = data?.name

  const actions = useMemo(
    () => [
      {
        buttonIcon: 'file',
        label: translations.CONTRACT_SAVE_AS_TEMPLATE,
        onClick: () => {
          if (checkAccess(SubscriptionFeatures.FEATURE_USER_TEMPLATES)) {
            dispatch(openModal(CONTRACTS_MODALS.SAVE_CONTRACT_AS_TEMPLATE, { id: contractId, oldName: contractName }))
          }
        },
        MobileIcon: FileIcon,
      },
      {
        buttonIcon: 'reupload',
        label: translations.CONTRACT_REUPLOAD,
        onClick: () => {
          dispatch(openModal(PLACEMENT_MODALS.REUPLOAD_CONFIRMATION))
        },
        MobileIcon: UserIcon,
      },
    ],
    [contractId, contractName]
  )
  return actions
}
