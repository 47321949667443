import { FETCH_STATUSES } from '../../../types/fetch'
import { RootState } from '../../rootReducer.types'

export const downloadFetchStatusSelector = (state: RootState) => state.contracts.download.fetchStatus
export const downloadErrorSelector = (state: RootState) => state.contracts.download.error
export const downloadLinkSelector = (state: RootState) => state.contracts.download.link

export const downloadProgressIsSuccessSelector = (state: RootState) =>
  state.contracts.download.progress.fetchStatus === FETCH_STATUSES.SUCCESS
export const downloadProgressErrorSelector = (state: RootState) => state.contracts.download.progress.error
