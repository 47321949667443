import { useSelector } from 'react-redux'

import * as PLANS_SELECTORS from '../../../../store/subscription/plans/plans.selectors'
import * as PAYMENTS_SELECTORS from '../../../../store/subscription/payment/payment.selectors'
import * as CARDS_SELECTORS from '../../../../store/subscription/cards/cards.selectors'
import { useShowFetchError } from '../../../hooks/useShowFetchError'

export const useErrors = () => {
  const plansFetchStatus = useSelector(PLANS_SELECTORS.subscriptionPlansFetchStatusSelector)
  const plansError = useSelector(PLANS_SELECTORS.subscriptionPlansErrorSelector)
  const formFetchStatus = useSelector(PAYMENTS_SELECTORS.subscriptionPaymentFormFetchStatusSelector)
  const formError = useSelector(PAYMENTS_SELECTORS.subscriptionPaymentFormErrorSelector)
  const cardsFetchStatus = useSelector(CARDS_SELECTORS.subscriptionCardsFetchStatusSelector)
  const cardsError = useSelector(CARDS_SELECTORS.subscriptionCardsErrorSelector)

  useShowFetchError(plansFetchStatus, plansError)
  useShowFetchError(formFetchStatus, formError)
  useShowFetchError(cardsFetchStatus, cardsError)
}
