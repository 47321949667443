import { SessionStorageKeys } from '../../constants/sessionStorage'

const SessionStorage = () => {}

SessionStorage.get = (key: SessionStorageKeys) => sessionStorage[key]
SessionStorage.set = (key: SessionStorageKeys, value: string) => {
  sessionStorage[key] = value
  return value
}
SessionStorage.remove = (key: SessionStorageKeys) => {
  sessionStorage.removeItem(key)
}

export default SessionStorage
