import { Action } from 'redux'

import { FetchError, FETCH_STATUSES } from '../../types/fetch'
import { Option } from '../../types/options'

export type Countries = Option[]

export interface CountriesState {
  fetchStatus: FETCH_STATUSES
  data: Countries
  error?: FetchError
}

export enum CountriesActions {
  'FETCH_COUNTRIES' = 'FETCH_COUNTRIES',
  'FETCH_COUNTRIES_SUCCESS' = 'FETCH_COUNTRIES_SUCCESS',
  'FETCH_COUNTRIES_FAILURE' = 'FETCH_COUNTRIES_FAILURE',
}

export type FetchCountriesAction = Action

export interface FetchCountriesSuccessAction extends Action {
  payload: Countries
}

export interface FetchCountriesFailureAction extends Action {
  payload: FetchError
}
