import styled from 'styled-components'

const IFRAME_FORM_HEIGHT = 427
const IFRAME_ERROR_SPACE_HEIGHT = 50
const IFRAME_WIDTH = 445
const IFRAME_HEIGHT = IFRAME_ERROR_SPACE_HEIGHT + IFRAME_FORM_HEIGHT

export const StyledHiddenInput = styled.input`
  height: 0;
  width: 0;
  visibility: hidden;
`

export const StyledIFrame = styled.iframe`
  position: relative;
  width: ${IFRAME_WIDTH}px;
  height: ${IFRAME_HEIGHT}px;
`

export const StyledWrapper = styled.div`
  position: relative;
`

export const StyledSkeletonWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: ${IFRAME_WIDTH}px;
  height: ${IFRAME_FORM_HEIGHT}px;
`
