import React from 'react'
import { useTranslation } from 'react-i18next'

import useAuth from '../../../../../hooks/useAuth'
import translations from '../../../../../translations/keys'
import { LeftLogout } from '../../../../assets/icons'
import Box from '../../../../ui/Box'
import Button from '../../../../ui/Button'
import { BUTTON_VARIANTS } from '../../../../ui/Button/Button.types'
import Modal from '../../../Modal'
import Typography from '../../../../ui/Typography'
import { TYPOGRAPHY_NAMES } from '../../../../ui/Typography/Typography.types'

import { LogoutModalProps } from './LogoutModal.types'

export const LogoutModal: React.FC<LogoutModalProps> = ({ onClose }) => {
  const { logout } = useAuth()
  const { t } = useTranslation()
  const handleLogout = () => {
    logout()
    onClose()
  }

  return (
    <Modal onClose={onClose}>
      <Box width={295} display="flex" flexDirection="column" alignItems="center">
        <LeftLogout size="high" color="blue" />
        <Box mt={3} mb={3}>
          <Typography name={TYPOGRAPHY_NAMES.H1}>{t(translations.WANT_TO_LOG_OUT)}</Typography>
        </Box>
        <Box width="100%">
          <Button fullWidth onClick={handleLogout}>
            {t(translations.YES)}
          </Button>
        </Box>
        <Box width="100%" mt={3}>
          <Button onClick={onClose} variant={BUTTON_VARIANTS.SECONDARY} fullWidth>
            {t(translations.NO)}
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}
