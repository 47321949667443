import React from 'react'

import translations from '../../../../../../translations/keys'
import { AttentionIcon } from '../../../../../assets/icons'
import Modal from '../../../../Modal'
import Typography from '../../../../../ui/Typography'
import { TYPOGRAPHY_NAMES } from '../../../../../ui/Typography/Typography.types'
import Box from '../../../../../ui/Box'

import { LockPortraitModalProps } from './LockPortraitModal.types'

export const LockPortraitModal: React.FC<LockPortraitModalProps> = ({ onClose }) => (
  <Modal onClose={onClose}>
    <Box display="flex" flexDirection="column" alignItems="center" textAlign="center">
      <AttentionIcon color="blue" size="high" />
      <Box mt={3} mb={2}>
        <Typography name={TYPOGRAPHY_NAMES.H1}>{translations.WARNING}</Typography>
      </Box>
      <span>{translations.FLIP_THE_PHONE}</span>
    </Box>
  </Modal>
)
