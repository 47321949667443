import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import translations from '../../../../translations/keys'
import { RecipientControl } from '../../../../types/recipients'
import { ContractStatusTypes } from '../../../../types/contracts'
import { getDeadlineDays } from '../../../../utils/date'
import Box from '../../Box'
import Typography from '../../Typography'
import { TYPOGRAPHY_NAMES } from '../../Typography/Typography.types'
import DropdownButton from '../../DropdownButton'
import { Item } from '../../DropdownButton/DropdownButton.types'
import { DeleteIcon, EditIcon, KebabIcon } from '../../../assets/icons'

import { RecipientCardProps } from './RecipientCard.types'
import * as Styled from './RecipientCard.styles'

const MAX_VIEW_DAYS = 99
const MAX_DAYS_TEXT = '99+'

export const RecipientCard: React.FC<RecipientCardProps> = ({
  data,
  displayStatus,
  editCondition,
  deleteCondition,
  onClick,
  onEdit,
  onDelete,
}) => {
  const isStatusVisible = data.status && displayStatus
  const isNegativeStatus = data.status?.type === ContractStatusTypes.NEGATIVE
  const statusName = data.status?.name
  const isEditable = editCondition ? editCondition(data) : true
  const isDeletable = deleteCondition ? deleteCondition(data) : true
  const { t } = useTranslation()
  const items = useMemo(
    () =>
      [
        onEdit &&
          isEditable && {
            name: t(translations.EDIT),
            value: RecipientControl.EDIT,
            icon: EditIcon,
          },
        onDelete &&
          isDeletable && {
            name: t(translations.DELETE),
            value: RecipientControl.DELETE,
            icon: DeleteIcon,
          },
      ].filter(Boolean) as Item[],
    [onEdit, onDelete]
  )

  const renderDeadline = () => {
    const deadlineDays = getDeadlineDays(data.deadline as Date)

    if (!deadlineDays) {
      return null
    }
    const text = deadlineDays > MAX_VIEW_DAYS ? MAX_DAYS_TEXT : `${deadlineDays}d`
    return <Styled.Deadline>{text}</Styled.Deadline>
  }

  const handleClick = () => {
    if (onClick && data.id) {
      onClick(data.id)
    }
  }

  const handleChange = (control: string) => {
    if (control === RecipientControl.EDIT) {
      onEdit && onEdit(data.id!)
    }
    if (control === RecipientControl.DELETE) {
      onDelete && onDelete(data.id!)
    }
  }

  return (
    <Styled.Wrapper
      data-testid="recipient-card-wrapper"
      isNegative={displayStatus && isNegativeStatus}
      onClick={onClick && handleClick}
    >
      <Styled.Action data-testid="recipient-card-action" action={data.action}>
        {renderDeadline()}
      </Styled.Action>
      <Styled.Content>
        <Box width="100%" minWidth={0}>
          <Styled.FullName>
            <Typography data-testid="recipient-card-name" name={TYPOGRAPHY_NAMES.bold14}>
              {data.name}
            </Typography>
          </Styled.FullName>
          <Box mt={1}>
            <Styled.SubtitleWrapper>
              <Typography data-testid="recipient-card-department" name={TYPOGRAPHY_NAMES.body14} color="grey">
                {data.department}
              </Typography>
            </Styled.SubtitleWrapper>
            {isStatusVisible && statusName && (
              <Styled.SubtitleWrapper>
                <Typography
                  data-testid="recipient-card-status"
                  name={TYPOGRAPHY_NAMES.body14}
                  color={isNegativeStatus ? 'errorColor' : 'black'}
                >
                  {t(translations[statusName])}
                </Typography>
              </Styled.SubtitleWrapper>
            )}
          </Box>
        </Box>
        {items.length > 0 && (
          <Box display="flex" justifyContent="center" alignItems="center" width={24}>
            <DropdownButton data-testid="recipient-card-button" hideTriangle items={items} onChange={handleChange}>
              <KebabIcon data-testid="recipient-card-icon" color="grey" />
            </DropdownButton>
          </Box>
        )}
      </Styled.Content>
    </Styled.Wrapper>
  )
}
