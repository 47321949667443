import React from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { NOTIFICATION_MODAL_TYPES } from '../../../../ui/Modals/NotificationModal/NotificationModal.constants'
import { NotificationModalView } from '../../../../ui/Modals/NotificationModal/NotificationModalView'
import translations from '../../../../../translations/keys'
import { modalsPayloadSelector } from '../../../../../store/modals/modals.selectors'
import { EDIT_PROFILE_MODALS } from '../../../../constants/editProfile'
import { useHistoryLeave } from '../../../../hooks/useHistoryLeave'

import { LeaveModalProps } from './LeaveModal.types'

export const LeaveModal: React.FC<LeaveModalProps> = (props) => {
  const history = useHistory()
  const { t } = useTranslation()
  const { unBlock } = useHistoryLeave(EDIT_PROFILE_MODALS.EDIT_PROFILE_LEAVE_MODAL, props.canLeave)
  const modalPayload = useSelector(modalsPayloadSelector(EDIT_PROFILE_MODALS.EDIT_PROFILE_LEAVE_MODAL))
  const onCancel = () => {
    unBlock && unBlock()
    props.onCancel()
    history.push(modalPayload.pathname)
  }

  if (!props.show) {
    return null
  }

  return (
    <NotificationModalView
      type={NOTIFICATION_MODAL_TYPES.WARNING}
      onClose={props.onClose}
      description={t(translations.SAVE_CHANGES_BEFORE_LEAVING)}
      successButtonText={t(translations.YES)}
      cancelButtonText={t(translations.NO)}
      onSuccess={props.onSuccess}
      onCancel={onCancel}
    />
  )
}
