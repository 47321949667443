import React from 'react'
import { findAll } from 'highlight-words-core'

export const highlightText = (
  textToHighlight: string,
  highlighted = '',
  Highlighted: React.ElementType,
  Default: React.ElementType
) => {
  if (!highlighted) {
    return textToHighlight
  }

  const chunks = findAll({
    textToHighlight,
    searchWords: [highlighted],
    autoEscape: true,
  })

  return chunks.map((chunk, index) => {
    const text = textToHighlight.substr(chunk.start, chunk.end - chunk.start)

    return chunk.highlight ? <Highlighted key={index}>{text}</Highlighted> : <Default key={index}>{text}</Default>
  })
}
