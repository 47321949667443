import { ApiHeaders } from '../../constants/apiHeaders'

export const getRecipientHeaders = (recipientToken?: string) =>
  recipientToken
    ? { [ApiHeaders.recipientAccess]: recipientToken, [ApiHeaders.contentType]: 'application/json' }
    : undefined

export const getRecipientHeaderFormData = (recipientToken?: string) =>
  recipientToken
    ? { [ApiHeaders.recipientAccess]: recipientToken, [ApiHeaders.contentType]: 'multipart/form-data' }
    : undefined
