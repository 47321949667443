import { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import translations from '../../../translations/keys'
import { DrawableCompNames } from '../../../types/drawableElem'
import { RecipientAuthentication } from '../../../types/recipients'
import { CONTRACT_SIGN_MODALS } from '../../constants/contractSign'
import { scrollToNearestSign } from '../../../store/contracts/signing/signing.actions'
import * as SIGNING_SELECTORS from '../../../store/contracts/signing/signing.selectors'
import { openModal } from '../../../store/modals/modals.actions'
import { ToolbarControl } from '../../ui/DocViewerToolbar/DocViewerToolbar.types'
import { PointerModes } from '../../ui/DocViewer/DocViewer.types'
import { CommentIcon, CrossoverIcon, EditIcon, SignIcon } from '../../assets/icons'
import { viewOptionsIsSignatureNamesVisibleSelector } from '../../../store/contracts/viewOptions/viewOptions.selectors'

export const useToolbarActions = () => {
  const dispatch = useDispatch()
  const isView = useSelector(SIGNING_SELECTORS.signingIsViewPageSelector)
  const isSuccess = useSelector(SIGNING_SELECTORS.signingContractFetchedFullySelector)
  const isCompleted = useSelector(SIGNING_SELECTORS.signingContractIsCompletedSelector)
  const isDeleted = useSelector(SIGNING_SELECTORS.signingContractIsDeletedSelector)
  const recipientAuthentication = useSelector(SIGNING_SELECTORS.signingRecipientAuthentication)
  const isSignatureNamesVisible = useSelector(viewOptionsIsSignatureNamesVisibleSelector)

  const toolbarActions = useMemo(() => {
    if (!isSuccess) {
      return []
    }

    const actions: ToolbarControl[] = [
      {
        name: translations.COMMENT,
        Icon: CommentIcon,
        pointerMode: PointerModes.DRAW,
        drawingCompName: DrawableCompNames.COMMENT,
      },
      {
        name: translations.EDIT,
        Icon: EditIcon,
        pointerMode: PointerModes.DRAW,
        drawingCompName: DrawableCompNames.CORRECTION,
      },
      {
        name: translations.CROSSOVER,
        Icon: CrossoverIcon,
        pointerMode: PointerModes.DRAW,
        drawingCompName: DrawableCompNames.STRIKETHROUGH,
      },
    ]

    if (!isView) {
      actions.push({
        name: translations.SIGN,
        Icon: SignIcon,
        onClick: () => {
          if (
            recipientAuthentication === RecipientAuthentication.EKYC ||
            recipientAuthentication === RecipientAuthentication.EKYC_ID
          ) {
            dispatch(openModal(CONTRACT_SIGN_MODALS.SIGNING_ACTIONS_EKYC_VERIFICATION))
          } else {
            dispatch(scrollToNearestSign())
          }
        },
      })
    }
    return actions
  }, [isView, isSuccess, isCompleted, isDeleted, recipientAuthentication, isSignatureNamesVisible])

  return toolbarActions
}
