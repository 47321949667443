import React from 'react'

import AuthorizationTemplate from '../../components/Authorization/AuthorizationTemplate'

import { CardSpecification as CardSpecificationView } from './CardSpecification'

export const CardSpecification = () => (
  <AuthorizationTemplate>
    <CardSpecificationView />
  </AuthorizationTemplate>
)
