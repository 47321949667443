import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { useDispatchUnmount } from '../useDispatchUnmount'
import * as CARDS_ACTIONS from '../../store/subscription/cards/cards.actions'

import { usePlanFetch } from './usePlanFetch'

export const usePaymentFetch = (id: string) => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(CARDS_ACTIONS.fetchCards())
  }, [])
  usePlanFetch(id)

  useDispatchUnmount(CARDS_ACTIONS.cleanCards)
}
