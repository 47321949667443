import React, { useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Formik, FormikProps } from 'formik'
import { useTranslation } from 'react-i18next'

import translations from '../../../../../translations/keys'
import { RecipientData } from '../../../../../types/recipients'
import { FormikRenderForm } from '../../../../../types/formik'
import { SubscriptionFeatures } from '../../../../../types/subscription'
import { ADD_RECIPIENTS_MODALS } from '../../../../constants/addRecipients'
import { RECIPIENTS_DIAGRAM_MODALS } from '../../../../constants/recipientsDiagram'
import { recipientValidationSchema } from '../../../../../validation/recipientValidationSchema'
import { checkEKYCOption } from '../../../../../utils/recipients'
import { useAccessFeature } from '../../../../../hooks/subscription/useAccessFeature'
import { modalsPayloadSelector } from '../../../../../store/modals/modals.selectors'
import { openModal } from '../../../../../store/modals/modals.actions'
import Box from '../../../../ui/Box'
import Modal from '../../../Modal'
import Typography from '../../../../ui/Typography'
import { TYPOGRAPHY_NAMES } from '../../../../ui/Typography/Typography.types'
import { prepareRecipientForForm } from '../../../../../utils/recipients/recipients'

import { EditRecipientModalProps } from './EditRecipientModal.types'
import Form from './components/Form'

export const EditRecipientModal: React.FC<EditRecipientModalProps> = ({
  templatesMode = false,
  shouldConfirmUpdate = false,
  onUpdate,
  onClose,
}) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const formRef: React.Ref<FormikProps<RecipientData>> = useRef(null)
  const { order, recipient, disabledFields = {} } = useSelector(
    modalsPayloadSelector(RECIPIENTS_DIAGRAM_MODALS.EDIT_DIAGRAM_RECIPIENT)
  )
  const initialValues = prepareRecipientForForm(recipient, templatesMode)
  const { checkAccess } = useAccessFeature()

  const handleSubmit = (values: RecipientData) => {
    const isDirty = formRef.current?.dirty

    if (!checkEKYCOption([values]) || checkAccess(SubscriptionFeatures.FEATURE_EKYC)) {
      if (shouldConfirmUpdate && isDirty) {
        dispatch(openModal(RECIPIENTS_DIAGRAM_MODALS.CONFIRM_SAVE_DIAGRAM_RECIPIENT, { recipient: values }))
      } else {
        if (isDirty) {
          onUpdate && onUpdate(values)
        }
        onClose()
      }
    }
  }

  const handleConfirmUpdate = () => {
    const values = formRef.current?.values

    if (onUpdate && values) {
      onUpdate && onUpdate(values)
      onClose()
    }
  }

  const handleClose = () => {
    const form = formRef.current
    if (form?.dirty) {
      dispatch(openModal(ADD_RECIPIENTS_MODALS.CANCEL_RECIPIENT_CHANGES))
    } else {
      onClose()
    }
  }

  const renderForm = ({ values, setValues, setFieldValue }: FormikRenderForm<RecipientData>) => (
    <Form
      values={values}
      order={order}
      templatesMode={templatesMode}
      disabledFields={disabledFields}
      setValues={setValues}
      setFieldValue={setFieldValue}
      onConfirmUpdate={handleConfirmUpdate}
      onCloseRecipientModal={onClose}
    />
  )

  return (
    <Modal onClose={handleClose}>
      <Box width={664} display="flex" justifyContent="center" alignItems="center" flexDirection="column" pt={3} pb={3}>
        <Typography name={TYPOGRAPHY_NAMES.H1}>{t(translations.EDIT_RECIPIENT)}</Typography>
        <Box mt={3} width="100%">
          <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={recipientValidationSchema(templatesMode)}
            innerRef={formRef}
          >
            {renderForm}
          </Formik>
        </Box>
      </Box>
    </Modal>
  )
}
