import { FetchFailureAction, FETCH_STATUSES } from '../../../types/fetch'
import { createReducer } from '../../createReducer'

import * as TYPES from './ownerView.types'
import * as ACTIONS from './ownerView.actions'

type Actions = TYPES.FetchOwnerContractDocumentSuccessAction | FetchFailureAction | TYPES.SetOwnerViewNotificationAction

export const initialState: TYPES.OwnerViewState = {
  contract: {
    data: null,
    fetchStatus: FETCH_STATUSES.IDLE,
    error: null,
  },
  notification: null,
}

export const ownerView = createReducer<TYPES.OwnerViewState, Actions>(initialState, {
  [ACTIONS.fetchOwnerContractDocumentTypes.request]: (state) => ({
    ...state,
    contract: {
      ...state.contract,
      fetchStatus: FETCH_STATUSES.REQUEST,
    },
  }),
  [ACTIONS.fetchOwnerContractDocumentTypes.success]: (state, action) => ({
    ...state,
    contract: {
      ...state.contract,
      data: (action as TYPES.FetchOwnerContractDocumentSuccessAction).payload,
      fetchStatus: FETCH_STATUSES.SUCCESS,
    },
  }),
  [ACTIONS.fetchOwnerContractDocumentTypes.failure]: (state, action) => ({
    ...state,
    contract: {
      ...state.contract,
      fetchStatus: FETCH_STATUSES.FAILURE,
      error: (action as FetchFailureAction).payload,
    },
  }),
  [TYPES.OwnerViewActions.CLEAN_OWNER_VIEW]: () => ({ ...initialState }),
  [TYPES.OwnerViewActions.SET_OWNER_NOTIFICATION]: (state, action) => ({
    ...state,
    notification: (action as TYPES.SetOwnerViewNotificationAction).payload,
  }),
  [TYPES.OwnerViewActions.CLEAN_OWNER_NOTIFICATION]: (state) => ({ ...state, notification: initialState.notification }),
})
