import { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'

import translations from '../../../translations/keys'
import * as SIGNING_SELECTORS from '../../../store/contracts/signing/signing.selectors'
import * as CORRECTIONS_SELECTORS from '../../../store/contracts/corrections/corrections.selectors'
import * as STRIKES_SELECTORS from '../../../store/contracts/strikes/strikes.selectors'
import { RecipientActions, RecipientAuthentication } from '../../../types/recipients'
import { CloseCircleIcon, PlayIcon } from '../../assets/icons'
import { openModal } from '../../../store/modals/modals.actions'
import { CONTRACT_SIGN_MODALS } from '../../constants/contractSign'
import { COMMON_MODALS_IDS } from '../../ui/Modals/CommonModals/CommonModals.constants'
import { NOTIFICATION_MODAL_TYPES } from '../../ui/Modals/NotificationModal/NotificationModal.constants'
import { scrollToNearestSign, setSigningNotification } from '../../../store/contracts/signing/signing.actions'
import { PlacementById } from '../../../types/placement'
import { Button } from '../../components/PreviewHeader/PreviewHeader.types'
import { SigningNotificationMessages } from '../../../store/contracts/signing/signing.types'
import { Variant } from '../../../types/notifications'
import { location } from '../../../utils/location'

interface AdditionalButtons {
  cancelButton?: Button
  finishLater?: Button
  progress?: Button
}

export const useButtons = (
  placementById: PlacementById,
  { cancelButton, finishLater, progress }: AdditionalButtons = {}
) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const data = useSelector(SIGNING_SELECTORS.signingContractDocumentDataSelector)
  const isView = useSelector(SIGNING_SELECTORS.signingIsViewPageSelector)
  const isSuccess = useSelector(SIGNING_SELECTORS.signingContractFetchedFullySelector)
  const isCompleted = useSelector(SIGNING_SELECTORS.signingContractIsCompletedSelector)
  const isDeleted = useSelector(SIGNING_SELECTORS.signingContractIsDeletedSelector)
  const recipientAuthentication = useSelector(SIGNING_SELECTORS.signingRecipientAuthentication)
  const isExpired = useSelector(SIGNING_SELECTORS.signingContractIsExpiredSelector)
  const corrections = useSelector(CORRECTIONS_SELECTORS.correctionsCountSelector)
  const strikes = useSelector(STRIKES_SELECTORS.strikesCountSelector)

  return useMemo(() => {
    if (!isSuccess) {
      return []
    }

    if(corrections || strikes){

      const done = {
        label: translations.DONE,
        onClick: () => history.push(location.contractsAllUrl()),
        MobileIcon: CloseCircleIcon,
      }
      return isCompleted || isDeleted ? [cancelButton] : [cancelButton, done]

    }

    if (isView) {
      // TODO: implement real action 'onClick'
      const send = {
        label: translations.SEND,
        onClick: () => {},
        MobileIcon: CloseCircleIcon,
        disabled: true,
      }
      return isCompleted || isDeleted ? [cancelButton] : [cancelButton, send]
    }

    return [
      finishLater,
      progress,
      {
        label: translations.SEND,
        onClick: () => {
          if (!data?.signingPlaces) {
            return
          }

          if (
            recipientAuthentication === RecipientAuthentication.EKYC ||
            recipientAuthentication === RecipientAuthentication.EKYC_ID
          ) {
            dispatch(openModal(CONTRACT_SIGN_MODALS.SIGNING_ACTIONS_EKYC_VERIFICATION))
            return
          }

          if (isExpired) {
            dispatch(
              setSigningNotification({
                message: SigningNotificationMessages.EXPIRED_CONTRACT_NOTIFICATION,
                variant: Variant.WARNING,
              })
            )
            return
          }

          const notSigned =
            data.signingPlaces.filter((place) => (place.id ? !placementById[place.id].image : true)).length !== 0

          if (notSigned) {
            dispatch(scrollToNearestSign())
            dispatch(
              openModal(COMMON_MODALS_IDS.NOTIFICATION, {
                type: NOTIFICATION_MODAL_TYPES.WARNING,
                description:
                  data.recipient.action === RecipientActions.INITIAL
                    ? translations.NOT_INITIALED_YET
                    : translations.NOT_SIGNED_YET,
              })
            )
          } else {
            dispatch(openModal(CONTRACT_SIGN_MODALS.SEND_CONTRACT))
          }
        },
        MobileIcon: PlayIcon,
      },
    ]
  }, [isView, isSuccess, placementById, isCompleted, isDeleted, recipientAuthentication, cancelButton, isExpired])
}
