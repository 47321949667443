import { Ajax } from '../ajax'
import { DocStrikesConfirmRequest, DocStrikesRequestBody, DocStrikesResponseBody } from '../../types/strikethrough'

class StrikeService extends Ajax {
  pathname = 'strike'

  fetchStrikes = (contractId: string) => this.getJSON<DocStrikesResponseBody>(`${this.pathname}/${contractId}`)

  fetchCreateStrike = (contractId: string, body: DocStrikesRequestBody) =>
    this.post(`${this.pathname}/${contractId}`, body)

  fetchConfirmStrike = ({ contractId, strikeId, ...body }: DocStrikesConfirmRequest) =>
    this.patch(`${this.pathname}/${contractId}/${strikeId}`, body)

  fetchCancelStrikeConfirmation = (contractId: string, strikeId: string) =>
    this.patch(`${this.pathname}/${contractId}/${strikeId}`, {
      status: null,
    })
}

export const strikeService = new StrikeService()
