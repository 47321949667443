import React, { FC } from 'react'

import TextInput from '../TextInput'

import { NumberProps } from './Number.types'

const onlyNumbersRegExp = /^[0-9\b]+$/

export const Number: FC<NumberProps> = ({ name, value, onChange, ...rest }) => {
  const onChangeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value

    if ((value === '' || onlyNumbersRegExp.test(value)) && onChange) {
      Object.defineProperty(e, 'target', {
        writable: false,
        value: { value: value ? +value : '', name },
      })
      onChange(e)
    }
  }

  return <TextInput {...rest} name={name} value={value} onChange={onChangeValue} />
}
