import styled from 'styled-components'

import { RecipientActionBackground } from '../../../utils/recipients'
import Box from '../../Box'

export const Wrapper = styled.div<{ isNegative?: boolean; onClick?: () => void }>`
  display: flex;
  width: 200px;
  min-height: 64px;
  border-width: 1px;
  border-style: solid;
  border-color: ${({ theme, isNegative }) => (isNegative ? theme.palette.errorColor : theme.palette.grey)};
  border-radius: 4px;
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'default')};
`
export const Action = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  ${RecipientActionBackground}
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
`
export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  width: 150px;
  padding: 8px 0 4px 8px;
`
export const Deadline = styled(Box).attrs({
  width: 32,
  height: 24,
  pl: 0.5,
})`
  line-height: 24px;
  background: ${({ theme }) => theme.palette.white};
  border-radius: 4px;
`
export const FullName = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`
export const SubtitleWrapper = styled.div`
  overflow: hidden;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;

  & + & {
    margin-top: 3px;
  }
`
