import React from 'react'

import MainTemplate from '../../components/Main/MainTemplate'

import { PrivacyPolicy as PrivacyPolicyPage } from './PrivacyPolicy'

export const PrivacyPolicy = () => (
  <MainTemplate simple>
    <PrivacyPolicyPage />
  </MainTemplate>
)
