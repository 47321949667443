import React from 'react'
import { useTheme } from 'styled-components'

import { StyledThemeScheme } from '../../../../themes/light'
import useMediaQuery from '../../../hooks/useMediaQuery'

import { ModalMobile } from './components/ModalMobile/ModalMobile'
import ModalWeb from './components/ModalWeb'
import { CreateSignatureModalProps } from './CreateSignatureModal.types'

export const CreateSignatureModal: React.FC<CreateSignatureModalProps> = ({ onClose, mode, onCreateSignature }) => {
  const theme = useTheme() as StyledThemeScheme
  const mobileScreen = useMediaQuery(theme.devices.mobile)

  //TODO hack for drawing in single ratio
  const win: any = window
  win.devicePixelRatio = 1

  if (mobileScreen) {
    return <ModalMobile onClose={onClose} onCreateSignature={onCreateSignature} mode={mode} />
  }

  return <ModalWeb onClose={onClose} onCreateSignature={onCreateSignature} mode={mode} />
}
