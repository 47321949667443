import React from 'react'

import MainTemplate from '../../components/Main/MainTemplate'

import { Terms as TermsPage } from './Terms'

export const Terms = () => (
  <MainTemplate simple>
    <TermsPage />
  </MainTemplate>
)
