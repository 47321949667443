import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { fetchVoidContract } from '../../../../../store/contracts/void/void.actions'
import { location } from '../../../../../utils/location'
import AskModal from '../../ContractModals/AskModal'
import * as VOID_SELECTORS from '../../../../../store/contracts/void/void.selectors'
import { useShowFetchError } from '../../../../hooks/useShowFetchError'
import translations from '../../../../../translations/keys'
import { ContractRouteParams } from '../../../../types/signing'

import { VoidContractModalProps } from './VoidContractModal.types'

export const VoidContractModal: React.FC<VoidContractModalProps> = ({ onClose }) => {
  const { contractId } = useParams<ContractRouteParams>()
  const history = useHistory()
  const dispatch = useDispatch()
  const fetchStatus = useSelector(VOID_SELECTORS.voidFetchStatusSelector)
  const error = useSelector(VOID_SELECTORS.voidErrorSelector)
  const isSuccess = useSelector(VOID_SELECTORS.voidIsSuccessSelector)
  const { t } = useTranslation()
  const onSuccess = () => {
    dispatch(fetchVoidContract(contractId))
  }

  useEffect(() => {
    if (isSuccess) {
      onClose()
      history.push(location.contractsAllUrl())
    }
  }, [isSuccess])

  useShowFetchError(fetchStatus, error)

  return <AskModal onSuccess={onSuccess} onClose={onClose} title={t(translations.STOP_SIGNING)} onCancel={onClose} />
}
