import React from 'react'

import { CardPaymentOption } from '../../../../../types/cards'
import { GreyVisaSvg } from '../../../../assets/GreyVisaSvg'
import { SmallAmexSvg } from '../../../../assets/SmallAmexSvg'
import { SmallMastercardSvg } from '../../../../assets/SmallMastercardSvg'

export const LOGOS: {
  [key in keyof typeof CardPaymentOption]: React.ReactNode
} = {
  [CardPaymentOption.VISA]: <GreyVisaSvg />,
  [CardPaymentOption.MASTERCARD]: <SmallMastercardSvg />,
  [CardPaymentOption.AMEX]: <SmallAmexSvg />,
}

export const CARDS_TYPES: {
  [key in keyof typeof CardPaymentOption]: string
} = {
  [CardPaymentOption.VISA]: 'Visa',
  [CardPaymentOption.MASTERCARD]: 'Mastercard',
  [CardPaymentOption.AMEX]: 'American Express',
}

export const CARD_OPTION_NAME = 'card'
export const ANOTHER_OPTION = 'another'

export const MAX_CARDS_COUNT = 5
