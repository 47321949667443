import { combineEpics, Epic, ofType } from 'redux-observable'
import { of } from 'rxjs'
import { catchError, delay, map, mergeMap, switchMap, takeUntil } from 'rxjs/operators'

import { signatureService, signingService } from '../../../api'
import { PlacementById } from '../../../types/placement'
import { Variant } from '../../../types/notifications'
import { NO_DATA } from '../../../constants/errors'
import { SessionStorageKeys } from '../../../constants/sessionStorage'
import { catchFetchError } from '../../../utils/catchFetchError'
import { updatePlacementById } from '../../../utils/placement'
import { addMIME } from '../../../utils/signatures/addMIME'
import SessionStorage from '../../../utils/SessionStorage'
import { fetchPlacementSuccess } from '../placement/placement.actions'
import { placementByIdSelector } from '../placement/placement.selectors'
import { prepareSigningPlacement } from '../placement/placement.utils'
import { fetchSignRequest, fetchViewRequest, prepareImages, showViewPage } from '../signing/signing.utils'
import { fetchVerification } from '../verification/verification.actions'

import * as ACTIONS from './signing.actions'
import * as SELECTORS from './signing.selectors'
import * as TYPES from './signing.types'

const fetchSigningContractDocument: Epic = (action$, state$) =>
  action$.pipe(
    ofType(ACTIONS.fetchSigningContractDocumentTypes.request),
    mergeMap(({ payload }) =>
      signingService.fetchContract(payload.contractId, SELECTORS.signingRecipientTokenSelector(state$.value)).pipe(
        mergeMap((response) => {
          const contractData = {
            ...response,
            total: 0,
            pages: [],
            id: payload.contractId,
          }

          return of(fetchVerification(payload.contractId), ACTIONS.fetchSigningContractDocumentSuccess(contractData))
        }),
        catchError(catchFetchError(ACTIONS.fetchSigningContractDocumentFailure)),
        takeUntil(
          action$.pipe(
            ofType(ACTIONS.fetchSigningContractDocumentTypes.success, ACTIONS.fetchSigningContractDocumentTypes.failure)
          )
        )
      )
    )
  )

const fetchContractTotalPages: Epic = (action$, state$) =>
  action$.pipe(
    ofType(ACTIONS.fetchTotalPagesTypes.request),
    mergeMap(() => {
      const contract = SELECTORS.signingContractDocumentDataSelector(state$.value)!
      return signingService
        .fetchContractTotalPages(contract.recipient.id, SELECTORS.signingRecipientTokenSelector(state$.value))
        .pipe(
          mergeMap(({ totalPages }) => {
            const newContract = {
              ...contract,
              total: totalPages,
              pages: prepareImages(contract.recipient.id, totalPages),
            }

            return of(
              fetchPlacementSuccess(
                ...prepareSigningPlacement(
                  newContract.metadata.signing,
                  newContract.signingPlaces,
                  newContract.recipient,
                  showViewPage(newContract)
                )
              ),
              ACTIONS.fetchTotalPagesSuccess(newContract)
            )
          }),
          catchError(catchFetchError(ACTIONS.fetchTotalPagesFailure)),
          takeUntil(action$.pipe(ofType(ACTIONS.fetchTotalPagesTypes.success, ACTIONS.fetchTotalPagesTypes.failure)))
        )
    })
  )

const fetchSignContract: Epic = (action$, state$) =>
  action$.pipe(
    ofType(ACTIONS.fetchSignContractTypes.request),
    mergeMap(() => {
      const signingData = SELECTORS.signingContractDocumentDataSelector(state$.value)
      const placementById = placementByIdSelector(state$.value)
      const recipientToken = SELECTORS.signingRecipientTokenSelector(state$.value)
      const defaultSign = SELECTORS.signingDefaultSignDataSelector(state$.value)

      if (!signingData?.signingPlaces) {
        return of(
          ACTIONS.fetchSignContractFailure({
            errorCodes: [NO_DATA],
            errorMessage: NO_DATA,
            lastErrorCode: NO_DATA,
          })
        )
      }

      return fetchSignRequest({ signingData, defaultSign, placementById, recipientToken }).pipe(
        map(() => ACTIONS.fetchSignContractSuccess(signingData.id || '')),
        catchError(catchFetchError(ACTIONS.fetchSignContractFailure)),
        takeUntil(action$.pipe(ofType(ACTIONS.fetchSignContractTypes.success, ACTIONS.fetchSignContractTypes.failure)))
      )
    })
  )

const fetchDefaultSign: Epic = (action$, state$) =>
  action$.pipe(
    ofType(ACTIONS.fetchDefaultSignTypes.request),
    mergeMap(() =>
      signatureService.fetchDefaultSign().pipe(
        map((response) =>
          ACTIONS.fetchDefaultSignSuccess({
            id: response.id,
            image: SELECTORS.signingIsInitialsSelector(state$.value)
              ? addMIME(response.initials)
              : addMIME(response.signature),
          })
        ),
        catchError(catchFetchError(ACTIONS.fetchDefaultSignFailure)),
        takeUntil(action$.pipe(ofType(ACTIONS.fetchDefaultSignTypes.success, ACTIONS.fetchDefaultSignTypes.failure)))
      )
    )
  )

const scrollToNearestSign: Epic = (action$, state$) =>
  action$.pipe(
    ofType(ACTIONS.scrollToNearestTypes.request),
    //TODO think how to delete delay because request and success merges and useSelector does not see changes
    delay(0),
    switchMap(() => {
      const signingData = SELECTORS.signingContractDocumentDataSelector(state$.value)
      const placementById = placementByIdSelector(state$.value)

      if (!signingData?.signingPlaces) {
        return of(
          ACTIONS.fetchSignContractFailure({
            errorCodes: [NO_DATA],
            errorMessage: NO_DATA,
            lastErrorCode: NO_DATA,
          })
        )
      }

      const nearestSign = signingData.signingPlaces.find((place) => !placementById[place.id].signatureId)

      if (nearestSign) {
        return of(ACTIONS.scrollToNearestSignSuccess(nearestSign.id))
      }

      return of(
        ACTIONS.setSigningNotification({
          message: TYPES.SigningNotificationMessages.CONTRACT_READY_TO_SEND,
          variant: Variant.SUCCESS,
        }),
        ACTIONS.scrollToNearestSignSuccess('')
      )
    })
  )

const fetchDeclineSigning: Epic = (action$, state$) =>
  action$.pipe(
    ofType(ACTIONS.fetchDeclineSigningTypes.request),
    mergeMap(({ payload }) =>
      signingService
        .fetchDecline({
          recipientId: payload.recipientId,
          body: payload.body,
          recipientToken: SELECTORS.signingRecipientTokenSelector(state$.value),
          queryParams: { verify: SessionStorage.get(SessionStorageKeys.VERIFICATION_TOKEN) },
        })
        .pipe(
          map(() => ACTIONS.fetchDeclineSigningSuccess()),
          catchError(catchFetchError(ACTIONS.fetchDeclineSigningFailure)),
          takeUntil(
            action$.pipe(ofType(ACTIONS.fetchDeclineSigningTypes.success, ACTIONS.fetchDeclineSigningTypes.failure))
          )
        )
    )
  )

const setSignature: Epic = (action$, state$) =>
  action$.pipe(
    ofType(ACTIONS.setSignatureTypes.request),
    switchMap(({ payload }: { payload: TYPES.SetSignaturePayload }) => {
      const signingData = SELECTORS.signingContractDocumentDataSelector(state$.value)
      const placementById = placementByIdSelector(state$.value)
      const isInitials = SELECTORS.signingIsInitialsSelector(state$.value)

      if (!signingData?.signingPlaces) {
        return of(
          ACTIONS.setSignatureFailure({
            errorCodes: [NO_DATA],
            errorMessage: NO_DATA,
            lastErrorCode: NO_DATA,
          })
        )
      }

      const updatedPlacementById = signingData.signingPlaces.reduce<PlacementById>((acc, place) => {
        const placeById = placementById[place.id]

        if ((payload.signatureId && placeById.signatureId) || payload.placeId === place.id) {
          return updatePlacementById(
            {
              ...placeById,
              image: payload.image,
              timestamp: payload.timestamp,
              signatureId: payload.signatureId,
            },
            acc
          )
        }

        return acc
      }, placementById)

      const actions: (TYPES.SetSignatureSuccessAction | TYPES.SetSigningSuccessMessageAction)[] = [
        ACTIONS.setSignatureSuccess({ ...payload, placementById: updatedPlacementById }),
      ]

      if (payload.isChanged) {
        actions.push(
          ACTIONS.setSigningNotification({
            message: isInitials
              ? TYPES.SigningNotificationMessages.INITIALS_CHANGED
              : TYPES.SigningNotificationMessages.SIGNATURE_CHANGED,
            variant: Variant.SUCCESS,
          })
        )
      }

      return of(...actions)
    })
  )

const fetchViewedContract: Epic = (action$, state$) =>
  action$.pipe(
    ofType(ACTIONS.fetchViewedContractTypes.request),
    mergeMap(() => {
      const signingData = SELECTORS.signingContractDocumentDataSelector(state$.value)
      const recipientToken = SELECTORS.signingRecipientTokenSelector(state$.value)

      if (!signingData) {
        return of(
          ACTIONS.setSignatureFailure({
            errorCodes: ['NO_DATA'],
            errorMessage: 'NO_DATA',
            lastErrorCode: 'NO_DATA',
          })
        )
      }

      return fetchViewRequest({ signingData, recipientToken }).pipe(
        map(() => ACTIONS.fetchViewedContractSuccess()),
        catchError(catchFetchError(ACTIONS.fetchViewedContractFailure)),
        takeUntil(
          action$.pipe(ofType(ACTIONS.fetchViewedContractTypes.success, ACTIONS.fetchViewedContractTypes.failure))
        )
      )
    })
  )

export const signingEpics = combineEpics(
  fetchSigningContractDocument,
  fetchSignContract,
  fetchDefaultSign,
  scrollToNearestSign,
  fetchDeclineSigning,
  setSignature,
  fetchViewedContract,
  fetchContractTotalPages
)
