import React, { useState, useRef, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { useTheme } from 'styled-components'
import { useTranslation } from 'react-i18next'

import { BellIcon } from '../../assets/icons'
import { BUTTON_VARIANTS } from '../../ui/Button/Button.types'
import Button from '../../ui/Button'
import Popover from '../../ui/Popover'
import { useNotificationsFetch } from '../../../hooks/userNotifications/useNotificationsFetch'
import * as USER_NOTIFICATIONS_SELECTORS from '../../../store/userNotifications/userNotifications.selectors'
import { fetchReadNotifications, setOffset } from '../../../store/userNotifications/userNotifications.actions'
import { UserNotification } from '../../../types/userNotifications'
import { StyledThemeScheme } from '../../../themes/light'
import useMediaQuery from '../../hooks/useMediaQuery'
import MobilePopup from '../MobilePopup'
import translations from '../../../translations/keys'

import List from './components/List'
import * as Styles from './Notifications.styles'

export const Notifications = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const theme = useTheme() as StyledThemeScheme
  const tabletScreen = useMediaQuery(theme.devices.tablet)
  const mobileScreen = useMediaQuery(theme.devices.mobile)
  const targetRef = useRef(null)
  const [visible, setVisible] = useState(false)
  const notifications = useSelector(USER_NOTIFICATIONS_SELECTORS.userNotificationsDataSelector)
  const limit = useSelector(USER_NOTIFICATIONS_SELECTORS.userNotificationsLimitSelector)
  const offset = useSelector(USER_NOTIFICATIONS_SELECTORS.userNotificationsOffsetSelector)
  const loading = useSelector(USER_NOTIFICATIONS_SELECTORS.userNotificationsIsLoadingSelector)
  const hasMore = useSelector(USER_NOTIFICATIONS_SELECTORS.userNotificationsHasMoreSelector)
  const hasUnread = useSelector(USER_NOTIFICATIONS_SELECTORS.userNotificationsHasUnreadSelector)
  const { t } = useTranslation()

  useNotificationsFetch()

  const onItemClick = useCallback(({ link }: UserNotification) => {
    if (link) {
      history.push(link)
    }
  }, [])

  const handleClose = () => {
    const ids = notifications.filter(({ read }) => !read).map(({ id }) => id)

    if (ids.length) {
      dispatch(
        fetchReadNotifications({
          notifications: ids,
        })
      )
    }

    setVisible(false)
  }

  const handleToggle = () => {
    if (visible) {
      handleClose()
    } else {
      setVisible(true)
    }
  }

  const onMore = useCallback(() => {
    dispatch(setOffset(offset + limit))
  }, [offset, limit])

  const renderPopup = () => {
    if (mobileScreen) {
      const popupContent = () => (
        <List
          items={notifications}
          onItemClick={onItemClick}
          onMore={onMore}
          loading={loading}
          hasMore={hasMore}
          autoHeight={false}
        />
      )

      return visible ? (
        <MobilePopup handleClose={handleClose} popupContent={popupContent} popupTitle={t(translations.NOTIFICATIONS)} />
      ) : null
    }

    return (
      <Popover
        data-testid="notifications-popover"
        width={tabletScreen ? '280px' : '366px'}
        offsetTop={tabletScreen ? 31 : 40}
        offsetLeft={tabletScreen ? 7 : -17}
        targetRef={targetRef}
        visible={visible}
        onClose={handleClose}
        padding={tabletScreen ? '16px 0' : '20px 0'}
        placement="bottom-end"
      >
        <List
          items={notifications}
          onItemClick={onItemClick}
          onMore={onMore}
          loading={loading}
          hasMore={hasMore}
          title={t(translations.NOTIFICATIONS)}
        />
      </Popover>
    )
  }

  return (
    <div data-testid="notifications">
      <Button data-testid="notifications-opener" ref={targetRef} variant={BUTTON_VARIANTS.LINK} onClick={handleToggle}>
        <Styles.Bell>
          <BellIcon data-testid="notifications-opener-icon" color="grey" />
          {hasUnread && <Styles.UnreadMark />}
        </Styles.Bell>
      </Button>
      {renderPopup()}
    </div>
  )
}
