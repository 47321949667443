import { FetchFailureAction, FETCH_STATUSES } from '../../types/fetch'
import omit from '../../utils/omit'
import { createReducer } from '../createReducer'

import * as ACTIONS from './verifyPassword.actions'
import * as TYPES from './verifyPassword.types'

type Actions =
  | FetchFailureAction
  | TYPES.FetchVerifyPasswordAction
  | TYPES.FetchVerifyPasswordSuccessAction
  | TYPES.CleanVerifyPassword

export const initialState: TYPES.VerifyPasswordState = {}

export const verifyPassword = createReducer<TYPES.VerifyPasswordState, Actions>(initialState, {
  [ACTIONS.fetchVerifyPasswordTypes.request]: (state, action) => {
    const payload = (action as TYPES.FetchVerifyPasswordAction).payload
    return {
      ...state,
      [payload.key]: {
        ...state[payload.key],
        fetchStatus: FETCH_STATUSES.REQUEST,
      },
    }
  },
  [ACTIONS.fetchVerifyPasswordTypes.success]: (state, action) => {
    const payload = (action as TYPES.FetchVerifyPasswordSuccessAction).payload
    return {
      ...state,
      [payload.key]: {
        ...state[payload.key],
        fetchStatus: FETCH_STATUSES.SUCCESS,
        token: payload.token,
      },
    }
  },
  [ACTIONS.fetchVerifyPasswordTypes.failure]: (state, action) => {
    const { key = '', ...payload } = (action as FetchFailureAction).payload
    return {
      ...state,
      [key]: {
        ...state[key],
        fetchStatus: FETCH_STATUSES.FAILURE,
        error: payload,
      },
    }
  },
  [TYPES.VerifyPasswordActions.CLEAN_VERIFY_PASSWORD]: (state, action) => {
    const act = action as TYPES.CleanVerifyPassword
    const newState = omit([act.payload.key], state)

    return { ...newState }
  },
})
