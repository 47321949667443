import { Action } from 'redux'

import { FetchFailurePayload, FETCH_STATUSES } from '../../../types/fetch'
import { DocumentPreviewDocumentData } from '../../../types/documentPreview'

export interface PreviewState {
  data: DocumentPreviewDocumentData | null
  fetchStatus: FETCH_STATUSES
  successMessage: string
  error: FetchFailurePayload | null
}

export enum PreviewActions {
  'SET_SUCCESS_MESSAGE' = 'SET_SUCCESS_MESSAGE',
  'CLEAR_SUCCESS_MESSAGE' = 'CLEAR_SUCCESS_MESSAGE',
  'CLEAR_PREVIEW' = 'CLEAR_PREVIEW',
  'FETCH_PREVIEW' = 'FETCH_PREVIEW',
}

export enum SuccessMessages {
  'NONE' = '',
  'DOCUMENT_UPLOADED_SUCCESS' = 'DOCUMENT_UPLOADED_SUCCESS',
}

export interface SetSuccessMessageAction extends Action {
  payload: { message: string }
}
export type ClearSuccessMessageAction = Action
export type ClearPreviewAction = Action

export interface FetchPreviewAction extends Action {
  payload: { contractId: string }
}
export interface FetchPreviewSuccessAction extends Action {
  payload: DocumentPreviewDocumentData
}
