import styled from 'styled-components'

import { ArrowDownIcon, DoubleArrowIcon } from '../../assets/icons'
import Button from '../Button'
import { BUTTON_VARIANTS } from '../Button/Button.types'

import { TableColumnNoHeader } from './Table.types'

const getPercents = ({ count, widthPercent }: { count?: number; widthPercent?: number }) => {
  if (widthPercent) {
    return `${widthPercent}%`
  }

  if (count) {
    return `calc(100% / ${count})`
  }

  return '100%'
}

export const Table = styled.div`
  position: relative;
  margin-top: 1px;
`
export const TableRow = styled.div<{ noBorder?: boolean }>`
  display: flex;
  position: relative;
  border-bottom: ${({ theme, noBorder }) => (noBorder ? '0' : `1px solid ${theme.palette.greyExtraLight}`)};
`
export const TableCell = styled.div<{ count?: number; widthPercent?: number; widthPercentLaptop?: number }>`
  overflow: hidden;
  width: ${({ count, widthPercent }) => getPercents({ count, widthPercent })};
  display: inline-flex;
  align-items: center;
  height: 55px;

  ${({ theme, widthPercentLaptop }) => `@media ${theme.devices.laptop} {
    ${widthPercentLaptop ? `width: ${widthPercentLaptop}%;` : ''}
  }`}
`
export const TableCellEllipsis = styled.div<{ ellipsis: boolean; onClick?: () => void }>`
  padding-right: 18px;
  ${({ onClick }) => (onClick ? 'cursor: pointer;' : undefined)}

  ${({ ellipsis }) =>
    ellipsis
      ? `
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  `
      : ''}
`

export const TableControlCell = styled.div`
  display: flex;
  justify-content: flex-end;
  flex: 1 0 90px;
  width: 90px;
  padding: 15px 0;
`
export const TableHeadCell = styled(TableCell)<{ widthPercent?: number; noHeader?: TableColumnNoHeader }>`
  display: inline-flex;
  align-items: center;
  padding-bottom: 8px;
  height: auto;
  ${({ widthPercent }) => (widthPercent ? `width: ${widthPercent}%;` : '')}
  ${({ noHeader }) => (noHeader === 'desktop' ? 'visibility: hidden;' : '')}

  ${({ theme, widthPercentLaptop, noHeader }) => `@media ${theme.devices.laptop} {
    ${widthPercentLaptop ? `width: ${widthPercentLaptop}%;` : ''}
    ${noHeader === 'laptop' ? 'visibility: hidden;' : ''}
  }`}
`
export const TableHeadControlCell = styled(TableControlCell)`
  padding: 0;
`
export const TableFormCell = styled(TableCell)<{ widthPercent?: number }>`
  padding-top: 24px;
  padding-bottom: 0;
  ${({ widthPercent }) => (widthPercent ? `width: ${widthPercent}%;` : '')}
`
export const ControlButton = styled(Button).attrs({
  variant: BUTTON_VARIANTS.TEXT,
})`
  margin-left: 22px;
  color: ${({ theme }) => theme.palette.grey};

  &:hover {
    color: ${({ theme }) => theme.palette.blueDark};
  }
  &:disabled {
    opacity: 0.5;
  }
`
export const HeadButton = styled(Button).attrs({ variant: BUTTON_VARIANTS.TEXT })`
  justify-content: flex-start;
  width: 100%;
  color: ${({ theme }) => theme.palette.grey};
  line-height: 1;
  text-transform: uppercase;

  &:hover {
    color: ${({ theme }) => theme.palette.blueDark};
  }
`
export const SortingIcon = styled(ArrowDownIcon)<{ flip: boolean }>`
  margin: 0 5px;
  backface-visibility: hidden;
  transform: ${({ flip }) => (flip ? 'rotate(180deg)' : 'rotate(0deg)')};
  transition: transform 200ms;
`
export const NeutralIcon = styled(DoubleArrowIcon)`
  margin: 0 5px;
`
export const FormField = styled.div`
  max-width: 320px;
`
export const HighlightedText = styled.span`
  position: relative;

  &::before {
    display: block;
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 28px;
    transform: translateY(-50%);
    backface-visibility: hidden;
    background-color: ${({ theme }) => theme.palette.blueExtraLight};
    z-index: -1;
  }
`
