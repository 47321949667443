import React, { useEffect, useMemo, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { FormikProps } from 'formik'
import { useTranslation } from 'react-i18next'

import translations from '../../../translations/keys'
import { AddRecipientsForm } from '../../../types/recipients'
import { FETCH_STATUSES } from '../../../types/fetch'
import { SIGNING_TYPES } from '../../../types/signingOrder'
import { TemplateRouteParams } from '../../types/template'
import { TEMPLATES_FILL_RECIPIENTS_MODALS } from '../../../constants/templatesFillRecipients'
import { TEMPLATES_MODALS } from '../../../constants/templates'
import { location } from '../../../utils/location'
import { removeExtension } from '../../../utils/file/removeExtension'
import { useShowFetchError } from '../../hooks/useShowFetchError'
import { useDispatchUnmount } from '../../../hooks/useDispatchUnmount'
import { useTemplateFieldsFetch } from '../../../hooks/templates/useTemplateFieldsFetch'
import * as SELECTORS from '../../../store/templates/recipients/recipients.selectors'
import * as ACTIONS from '../../../store/templates/recipients/recipients.actions'
import { openModal } from '../../../store/modals/modals.actions'
import { fieldsCountSelector } from '../../../store/templates/fields/fields.selectors'
import { setCreateContractRecipientsData } from '../../../store/templates/createContract/createContract.actions'
import { setSuccessMessage } from '../../../store/templates/preview/preview.actions'
import { SuccessMessages } from '../../../store/templates/preview/preview.types'
import Typography from '../../ui/Typography'
import { TYPOGRAPHY_NAMES } from '../../ui/Typography/Typography.types'
import Box from '../../ui/Box'
import FullScreenTemplate from '../../components/Main/FullScreenTemplate'
import { PreviewHeader } from '../../components/PreviewHeader/PreviewHeader'
import { FormRecipients } from '../../ui/Forms/FormRecipients/FormRecipients'
import { ChangeSigningOrder } from '../../components/ChangeSigningOrder/ChangeSigningOrder'

import { useButtons } from './hooks/useButtons'
import Modals from './components/Modals'

export const TemplatesFillRecipients = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const history = useHistory()
  const { templateId } = useParams<TemplateRouteParams>()
  const templateName = useSelector(SELECTORS.recipientsTemplateNameSelector)
  const byId = useSelector(SELECTORS.recipientsByIdSelector)
  const sorted = useSelector(SELECTORS.recipientsSortedSelector)
  const order = useSelector(SELECTORS.recipientsOrderSelector)
  const isOrderDisabled = sorted.length > 0
  const fetch = useSelector(SELECTORS.recipientsFetchSelector)
  const fetchAdd = useSelector(SELECTORS.recipientsFetchAddSelector)
  const isDocumentExists = useSelector(SELECTORS.recipientsIsDocumentExistsSelector)
  const fieldsCount = useSelector(fieldsCountSelector)
  const formRef: React.Ref<FormikProps<AddRecipientsForm>> = useRef(null)
  const recipientsList = useMemo(() => Object.values(byId), [sorted])
  const buttons = useButtons(formRef)

  const loading = sorted.length === 0 && fetch.status === FETCH_STATUSES.REQUEST

  const handleSubmit = ({ recipients }: AddRecipientsForm) => {
    if (fieldsCount > 0) {
      dispatch(setCreateContractRecipientsData(recipients, order))
      dispatch(setSuccessMessage(SuccessMessages.TEMPLATES_CUSTOM_FIELDS_PROMPT))
      history.push(location.templatesFillFieldsUrl(templateId))
    } else if (isDocumentExists) {
      dispatch(
        openModal(TEMPLATES_FILL_RECIPIENTS_MODALS.CREATE_CONTRACT_FROM_TEMPLATE, {
          fileName: templateName,
          recipients,
          order,
        })
      )
    } else {
      dispatch(openModal(TEMPLATES_MODALS.UPLOAD_FILE_FOR_CONTRACT, { recipients, order }))
    }
  }

  const handleOrderChange = (order: string) => {
    dispatch(ACTIONS.setOrder(order as SIGNING_TYPES))
  }

  useShowFetchError(fetchAdd.status, fetchAdd.error)

  useEffect(() => {
    dispatch(ACTIONS.fetchTemplateInfo(templateId))
    dispatch(ACTIONS.fetchRecipients(templateId))
  }, [])

  useTemplateFieldsFetch(templateId)
  useDispatchUnmount(ACTIONS.clearRecipients)

  if (loading) {
    return null
  }

  return (
    <FullScreenTemplate headerContent={<PreviewHeader buttons={buttons} title={removeExtension(templateName || '')} />}>
      <Box padding={4}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography name={TYPOGRAPHY_NAMES.H1}>{t(translations.ADD_RECIPIENTS_TITLE)}</Typography>
          <ChangeSigningOrder disabled={isOrderDisabled} order={order as string} onChange={handleOrderChange} />
        </Box>
        <Box mt={3} mb={3}>
          <Typography name={TYPOGRAPHY_NAMES.H3}>{t(translations.RECIPIENTS)}</Typography>
        </Box>
        <FormRecipients
          ref={formRef}
          recipients={recipientsList}
          disableOrder={order === SIGNING_TYPES.SEPARATE}
          onSubmit={handleSubmit}
          noDefaultDeadline
        />
      </Box>
      <Modals />
    </FullScreenTemplate>
  )
}
