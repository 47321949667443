import React, { forwardRef } from 'react'
import { Formik, FormikProps } from 'formik'

import Modal from '../../../Modal'
import { FolderSvg } from '../../../../assets/FolderSvg'
import Box from '../../../../ui/Box'
import Typography from '../../../../ui/Typography'
import { TYPOGRAPHY_NAMES } from '../../../../ui/Typography/Typography.types'
import translations from '../../../../../translations/keys'
import Button from '../../../../ui/Button'
import FormTextInput from '../../../../ui/Forms/FormTextInput'
import { FILE_NAME_MAX_LENGTH } from '../../../../../validation/fileNameValidationSchema'
import { RenameDocumentBody, RenameDocumentFields } from '../../../../../types/renameDocument'
import { renameDocumentValidationSchema } from '../../../../../validation/documents/renameDocumentValidationSchema'

import { RenameDocumentModalProps } from './RenameDocumentModal.types'
import { StyledWrapper, StyledForm } from './RenameDocumentModal.styles'

export const RenameDocumentModal = forwardRef<FormikProps<RenameDocumentBody>, RenameDocumentModalProps>(
  ({ onClose, title, oldName, onSubmit }, ref) => {
    const initialValues = {
      [RenameDocumentFields.name]: oldName,
    }

    const renderForm = () => (
      <StyledForm>
        <FormTextInput
          name={RenameDocumentFields.name}
          label={translations.NAME}
          placeholder={translations.DOCUMENT_NAME_PLACEHOLDER}
          maxLength={FILE_NAME_MAX_LENGTH}
        />
        <Box width="100%" mt={2}>
          <Button type="submit" fullWidth>
            {translations.SAVE}
          </Button>
        </Box>
      </StyledForm>
    )

    return (
      <Modal onClose={onClose}>
        <StyledWrapper>
          <FolderSvg />
          <Box mt={3} mb={4}>
            <Typography name={TYPOGRAPHY_NAMES.H1}>{title}</Typography>
          </Box>
          <Formik
            onSubmit={onSubmit}
            initialValues={initialValues}
            validationSchema={renameDocumentValidationSchema}
            innerRef={ref}
          >
            {renderForm}
          </Formik>
        </StyledWrapper>
      </Modal>
    )
  }
)
