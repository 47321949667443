import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import translations from '../../../../../translations/keys'
import { location } from '../../../../../utils/location'
import AskModal from '../../ContractModals/AskModal'

import { FinishLaterModalProps } from './FinishLaterModal.types'

export const FinishLaterModal: React.FC<FinishLaterModalProps> = ({ onClose }) => {
  const history = useHistory()
  const { t } = useTranslation()
  const onSuccess = () => {
    onClose()
    history.push(location.contractsAllUrl())
  }
  const onCancel = () => {
    onClose()
  }

  return (
    <AskModal
      onCancel={onCancel}
      onSuccess={onSuccess}
      onClose={onClose}
      title={t(translations.FINISH_CONTRACT_LATER)}
    />
  )
}
