import React from 'react'

import ErrorBoundary from '../../components/ErrorBoundary/ErrorBoundary'

import { ContractSign as ContractSignPage } from './ContractSign'

export const ContractSign = () => (
  <ErrorBoundary>
    <ContractSignPage />
  </ErrorBoundary>
)
