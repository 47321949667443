import { FetchFailureAction, FETCH_STATUSES } from '../../../types/fetch'
import { createReducer } from '../../createReducer'

import * as TYPES from './cards.types'
import * as ACTIONS from './cards.actions'

type Actions = FetchFailureAction | TYPES.FetchCardsSuccessAction | TYPES.AddCard

export const initialState: TYPES.CardsState = {
  fetchStatus: FETCH_STATUSES.IDLE,
  error: null,
  list: null,
  lastAddedCard: null,
  setDefault: {
    fetchStatus: FETCH_STATUSES.IDLE,
    error: null,
  },
}

export const cards = createReducer<TYPES.CardsState, Actions>(initialState, {
  [ACTIONS.fetchCardsTypes.request]: (state) => ({
    ...state,
    fetchStatus: FETCH_STATUSES.REQUEST,
  }),
  [ACTIONS.fetchCardsTypes.success]: (state, action) => ({
    ...state,
    fetchStatus: FETCH_STATUSES.SUCCESS,
    list: (action as TYPES.FetchCardsSuccessAction).payload.cards,
  }),
  [ACTIONS.fetchCardsTypes.failure]: (state, action) => ({
    ...state,
    fetchStatus: FETCH_STATUSES.FAILURE,
    error: (action as FetchFailureAction).payload,
  }),
  [TYPES.CardsActions.CLEAN_SUBSCRIPTION_CARDS]: () => ({ ...initialState }),
  [TYPES.CardsActions.ADD_SUBSCRIPTION_CARD]: (state, action) => {
    const card = (action as TYPES.AddCard).payload.card

    return {
      ...state,
      list: state.list ? [...state.list, card] : [card],
      lastAddedCard: card,
    }
  },
  [ACTIONS.fetchSetDefaultCardTypes.request]: (state) => ({
    ...state,
    setDefault: {
      ...state.setDefault,
      fetchStatus: FETCH_STATUSES.REQUEST,
    },
  }),
  [ACTIONS.fetchSetDefaultCardTypes.success]: (state) => ({
    ...state,
    setDefault: {
      ...state.setDefault,
      fetchStatus: FETCH_STATUSES.SUCCESS,
    },
  }),
  [ACTIONS.fetchSetDefaultCardTypes.failure]: (state, action) => ({
    ...state,
    setDefault: {
      ...state.setDefault,
      fetchStatus: FETCH_STATUSES.FAILURE,
      error: (action as FetchFailureAction).payload,
    },
  }),
})
