import { FetchFailureAction, FETCH_STATUSES } from '../../../types/fetch'
import { createReducer } from '../../createReducer'

import * as TYPES from './contactUs.types'
import * as ACTIONS from './contactUs.actions'

type Actions = FetchFailureAction

export const initialState: TYPES.ContactUsState = {
  fetchStatus: FETCH_STATUSES.IDLE,
  error: null,
}

export const contactUs = createReducer<TYPES.ContactUsState, Actions>(initialState, {
  [ACTIONS.fetchContactUsTypes.request]: (state) => ({
    ...state,
    fetchStatus: FETCH_STATUSES.REQUEST,
  }),
  [ACTIONS.fetchContactUsTypes.success]: (state) => ({
    ...state,
    fetchStatus: FETCH_STATUSES.SUCCESS,
  }),
  [ACTIONS.fetchContactUsTypes.failure]: (state, action) => ({
    ...state,
    fetchStatus: FETCH_STATUSES.FAILURE,
    error: (action as FetchFailureAction).payload,
  }),
  [TYPES.ContactUsActions.CLEAR_SUBSCRIPTION_CONTACT_US]: () => ({ ...initialState }),
})
