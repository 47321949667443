import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { modalsPayloadSelector } from '../../../../../store/modals/modals.selectors'
import { SIGNATURES_MODALS } from '../../../../constants/signatures'
import { VerificationPasswordModal } from '../../VerificationPasswordModal/VerificationPasswordModal'
import { cleanDeleteSignature, fetchDeleteSignature } from '../../../../../store/signatures/signatures.actions'
import {
  verifyPasswordIsFailureSelector,
  verifyPasswordSuccessSelector,
} from '../../../../../store/verifyPassword/verifyPassword.selectors'
import { VerifyPasswordKeys } from '../../../../../types/verifyPassword'
import { AccessGuardFormSubmit } from '../../../AccessGuardForm/AccessGuardForm.types'
import { cleanVerifyPassword, fetchVerifyPassword } from '../../../../../store/verifyPassword/verifyPassword.actions'
import { closeModal, openModal } from '../../../../../store/modals/modals.actions'
import translations from '../../../../../translations/keys'
import { COMMON_MODALS_IDS } from '../../../../ui/Modals/CommonModals/CommonModals.constants'
import { NOTIFICATION_MODAL_TYPES } from '../../../../ui/Modals/NotificationModal/NotificationModal.constants'
import {
  deleteSignatureIsSuccessSelector,
  deleteSignatureSelector,
  deleteSignatureIsFailureSelector,
} from '../../../../../store/signatures/signatures.selectors'
import { useShowFetchError } from '../../../../hooks/useShowFetchError'
import { useDispatchUnmount } from '../../../../../hooks/useDispatchUnmount'

import { DeleteSignatureVerificationModalProps } from './DeleteSignatureVerificationModal.types'

export const DeleteSignatureVerificationModal: React.FC<DeleteSignatureVerificationModalProps> = ({ onClose }) => {
  const dispatch = useDispatch()
  const access = useSelector(verifyPasswordSuccessSelector(VerifyPasswordKeys.DELETE_SIGNATURE))
  const isError = useSelector(verifyPasswordIsFailureSelector(VerifyPasswordKeys.DELETE_SIGNATURE))
  const { id } = useSelector(modalsPayloadSelector(SIGNATURES_MODALS.DELETE_SIGNATURE_VERIFICATION))
  const deleteSignatureIsSuccess = useSelector(deleteSignatureIsSuccessSelector)
  const deleteSignatureIsFailure = useSelector(deleteSignatureIsFailureSelector)
  const deleteSignature = useSelector(deleteSignatureSelector)

  const onAccessed = useCallback(() => {
    dispatch(fetchDeleteSignature(id))
  }, [id])
  const onSubmit: AccessGuardFormSubmit = (values) => {
    dispatch(fetchVerifyPassword(VerifyPasswordKeys.DELETE_SIGNATURE, values))
  }
  const onCleanVerifyPassword = useCallback(() => cleanVerifyPassword(VerifyPasswordKeys.DELETE_SIGNATURE), [])

  useEffect(() => {
    if (deleteSignatureIsSuccess) {
      dispatch(closeModal(SIGNATURES_MODALS.DELETE_SIGNATURE_VERIFICATION))
      dispatch(
        openModal(COMMON_MODALS_IDS.NOTIFICATION, {
          type: NOTIFICATION_MODAL_TYPES.SUCCESS,
          title: translations.SIGNATURE_DELETED,
        })
      )
    }
  }, [deleteSignatureIsSuccess])
  useShowFetchError(deleteSignature.fetchStatus, deleteSignature.error)
  useDispatchUnmount(onCleanVerifyPassword, cleanDeleteSignature)

  return (
    <VerificationPasswordModal
      onClose={onClose}
      onSubmit={onSubmit}
      onAccessed={onAccessed}
      access={access}
      isError={isError || deleteSignatureIsFailure}
      submitText={translations.CONTINUE}
    />
  )
}
