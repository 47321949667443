import React, { useMemo, useState, useEffect } from 'react'
import styled from 'styled-components'

import ClickOutsideListener from '../ClickOutsideListener'
import TextInput from '../TextInput'
import Search from '../Search'

import { DropdownProps, DropdownOption } from './Dropdown.types'

const StyledWrapper = styled.div`
  position: relative;
`
const StyledList = styled.ul<{ visible: boolean; searchable?: boolean; maxHeight: number }>`
  overflow: auto;
  display: ${({ visible }) => (visible ? 'block' : 'none')};
  position: absolute;
  top: ${({ searchable }) => (searchable ? '44px' : '69px')};
  left: 0;
  right: 0;
  max-height: ${({ maxHeight }) => `${maxHeight}px`};
  margin: 0;
  padding: 0;
  list-style: none;
  background-color: ${(props) => props.theme.palette.white};
  border: ${(props) => `1px solid ${props.theme.palette.grey}`};
  border-radius: 4px;
  z-index: 1;
`
const StyledListItem = styled.li`
  padding: 5px 14px;
  display: flex;
  align-items: center;
  overflow-wrap: anywhere;

  &:hover {
    background-color: ${(props) => props.theme.palette.blueExtraLight};
    cursor: pointer;
  }
`

const StyledIconWrapper = styled.div`
  display: inline-flex;
  margin-right: 8px;
  align-items: center;
`

export const Dropdown: React.FC<DropdownProps> = ({
  options,
  onChange,
  initialValue,
  searchable,
  autoFill = true,
  maxHeight = 250,
  ...props
}) => {
  const [visible, setVisible] = useState(false)
  const initialOption = useMemo(() => (initialValue ? options.find((opt) => opt.value === initialValue) : undefined), [
    initialValue,
    options,
  ])
  const [inputValue, setInputValue] = useState(initialOption?.name || '')
  const [selectedOption, setSelectedOption] = useState<DropdownOption | undefined>(initialOption)
  const inputValueLC = inputValue.toLowerCase()
  const filteredOptions =
    autoFill && inputValue ? options.filter((opt) => opt.name.toLowerCase().includes(inputValueLC)) : options
  const shouldDisplayDropdown = visible && filteredOptions.length > 0
  const Input = searchable ? Search : TextInput

  const handleOpen = () => {
    setVisible(true)
  }

  const handleToggle = () => {
    setVisible((prevVisible) => !prevVisible)
  }

  const handleClose = () => {
    if (selectedOption) {
      setInputValue(selectedOption.name)
    }
    setVisible(false)
    setInputValue(selectedOption?.name || '')
  }

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setVisible(true)
    setInputValue(event.target.value)
  }

  const resetValue = () => {
    setSelectedOption(undefined)
    setInputValue('')
  }

  const handleOptionClick = (value: string) => (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    const option = options.find((opt) => opt.value === value)

    if (option) {
      setSelectedOption(option)
      setInputValue(option.name)

      Object.defineProperty(event, 'target', {
        writable: false,
        value: { value, name: props.name },
      })
      onChange(event)
    }
    setVisible(false)
  }

  useEffect(() => {
    if (props.value !== selectedOption?.value) {
      const option = options.find((opt) => opt.value === props.value)
      setSelectedOption(option)
      setInputValue(props.value)
    }
  }, [props.value, selectedOption])

  const showPrefixIcon = (Icon?: () => JSX.Element) => (typeof Icon === 'function' ? <Icon /> : undefined)

  return (
    <StyledWrapper test-id="dropdown-wrapper">
      <ClickOutsideListener onClickAway={handleClose} test-id="click-outside-listener">
        <Input
          {...props}
          icon="triangle"
          flipIcon={visible}
          test-id="text-input"
          value={inputValue}
          onFocus={handleOpen}
          onClickIcon={searchable ? resetValue : handleToggle}
          onChange={handleInputChange}
          prefix={showPrefixIcon(selectedOption?.Icon)}
          readOnly={!autoFill}
        />
        <StyledList
          test-id="dropdown-list"
          visible={shouldDisplayDropdown}
          searchable={searchable}
          maxHeight={maxHeight}
        >
          {filteredOptions.map(({ Icon, value, name }) => (
            <StyledListItem test-id="dropdown-list-item" key={value} onClick={handleOptionClick(value)}>
              {Icon && (
                <StyledIconWrapper>
                  <Icon />
                </StyledIconWrapper>
              )}
              {name}
            </StyledListItem>
          ))}
        </StyledList>
      </ClickOutsideListener>
    </StyledWrapper>
  )
}
