import React from 'react'

import SvgIcon from '../../ui/SvgIcon'
import { SvgIconProps } from '../../ui/SvgIcon/SvgIcon.types'

export const WarningIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} viewBox="0 0 16 16">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.33301 13.5467C11.5804 13.5467 14.213 10.9141 14.213 7.66669C14.213 4.41925 11.5804 1.78669 8.33301 1.78669C5.08557 1.78669 2.45301 4.41925 2.45301 7.66669C2.45301 10.9141 5.08557 13.5467 8.33301 13.5467ZM8.33301 14.6667C12.199 14.6667 15.333 11.5327 15.333 7.66669C15.333 3.80069 12.199 0.666687 8.33301 0.666687C4.46701 0.666687 1.33301 3.80069 1.33301 7.66669C1.33301 11.5327 4.46701 14.6667 8.33301 14.6667Z"
    />
    <path d="M9.17301 4.48661L8.99994 8.96098H7.66607L7.49301 4.48661H9.17301ZM9.16457 10.7718H7.50145V9.65746H9.16457V10.7718Z" />
  </SvgIcon>
)
