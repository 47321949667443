import { FetchFailureAction, FetchFailurePayload } from '../../../types/fetch'
import { SubscriptionUserPlan } from '../../../types/subscription'
import { createFetchActionTypes } from '../../../utils/createFetchActionTypes'

import * as TYPES from './userPlan.types'

export const fetchUserPlanTypes = createFetchActionTypes(TYPES.UserPlanActions.FETCH_SUBSCRIPTION_USER_PLAN)

export const fetchUserPlan = (): TYPES.FetchUserPlan => ({
  type: fetchUserPlanTypes.request,
})

export const fetchUserPlanSuccess = (data: SubscriptionUserPlan): TYPES.FetchUserPlanSuccess => ({
  type: fetchUserPlanTypes.success,
  payload: { data },
})

export const fetchUserPlanFailure = (payload: FetchFailurePayload): FetchFailureAction => ({
  type: fetchUserPlanTypes.failure,
  payload,
})

export const clearUserPlan = () => ({
  type: TYPES.UserPlanActions.CLEAR_SUBSCRIPTION_USER_PLAN,
})
