import styled from 'styled-components'

import Button from '../../ui/Button'
import { BUTTON_VARIANTS } from '../../ui/Button/Button.types'
import Typography from '../../ui/Typography'

export const StyledDescription = styled(Typography)`
  white-space: pre-wrap;
  color: ${({ theme }) => theme.palette.textColor};
`

export const StyledButtonWrapper = styled(Button)`
  width: 216px;

  ${({ theme }) => `@media ${theme.devices.mobile} {
    width: 100%;
  }`}
`

export const StyledSubmitButtonWrapper = styled(Button).attrs({
  variant: BUTTON_VARIANTS.SECONDARY,
})`
  ${({ theme }) => `@media ${theme.devices.mobile} {
    width: 100%;
  }`}
`

export const StyledTextInputWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 360px;

  ${({ theme }) => `@media ${theme.devices.mobile} {
    width: 100%;
  }`}
`

export const StyledCopyIconWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
  height: 40px;
`

export const MessageContainer = styled.div`
  position: absolute;
  top: 32px;
  right: 32px;
  width: 300px;
  ${({ theme }) => `@media ${theme.devices.mobile} {
    position: absolute;
    top: 16px;
    right: 16px;
    width: 250px;
  }`}
`
