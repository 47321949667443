import styled from 'styled-components'

import Box from '../../Box'

export const Completed = styled(Box).attrs({
  height: 64,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  mb: 3,
})`
  border: 1px solid ${({ theme }) => theme.palette.green};
  box-sizing: border-box;
  border-radius: 4px;
`
