import styled from 'styled-components'

import Typography from '../../../../ui/Typography'

export const StyledTitle = styled(Typography)`
  margin-bottom: 24px;
`
export const StyledWrapper = styled.div`
  max-width: 672px;

  & ~ & {
    margin-top: 8px;
  }

  ${({ theme }) => `@media ${theme.devices.laptop} {
    max-width: 472px;
  }`}

  ${({ theme }) => `@media ${theme.devices.mobile} {
    max-width: 100%;
    & ~ & {
      margin-top: 32px;
    }
  }`}
`
