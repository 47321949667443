import { CreateSignatureBody } from '../../../../types/signatures'

export enum SignatureMode {
  both = 'both',
  signature = 'signature',
  initials = 'initials',
}

export interface CreateSignatureModalProps {
  onClose: () => void
  mode: SignatureMode
  onCreateSignature: (body: CreateSignatureBody) => void
}
