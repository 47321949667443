import { useDispatch } from 'react-redux'
import { useEffect } from 'react'

import { OwnerViewNotificationMessages } from '../../store/contracts/ownerView/ownerView.types'
import { ConfirmationStatus } from '../../types/confirmationActions'
import * as OWNER_VIEW_ACTIONS from '../../store/contracts/ownerView/ownerView.actions'
import { Variant } from '../../types/notifications'

export const useCancelConfirmationNotification = (isCanceled: boolean, cancelStatus: ConfirmationStatus | null) => {
  const dispatch = useDispatch()

  useEffect(() => {
    if (isCanceled && cancelStatus) {
      dispatch(
        OWNER_VIEW_ACTIONS.setOwnerViewNotification({
          variant: Variant.SUCCESS,
          message:
            cancelStatus === ConfirmationStatus.ACCEPTED
              ? OwnerViewNotificationMessages.CANCEL_ACCEPTATION_SUCCESSFULLY
              : OwnerViewNotificationMessages.CANCEL_REJECTION_SUCCESSFULLY,
        })
      )
    }
  }, [isCanceled, cancelStatus])
}
