import { Action } from 'redux'

import { FetchFailurePayload, FETCH_STATUSES } from '../../../types/fetch'
import { SaveAsTemplateBody } from '../../../types/saveAsTemplate'

export interface SaveAsTemplateState {
  fetchStatus: FETCH_STATUSES
  error: FetchFailurePayload | null
  id: string | null
}

export enum SaveAsTemplateActions {
  'FETCH_SAVE_CONTRACT_AS_TEMPLATE' = 'FETCH_SAVE_CONTRACT_AS_TEMPLATE',
  'CLEAN_SAVE_CONTRACT_AS_TEMPLATE' = 'CLEAN_SAVE_CONTRACT_AS_TEMPLATE',
}

export interface FetchSaveAsTemplate extends Action {
  payload: { body: SaveAsTemplateBody }
}

export interface FetchSaveAsTemplateSuccess extends Action {
  payload: { id: string }
}
