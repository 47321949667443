import { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import translations from '../../../../translations/keys'
import { DrawableCompNames } from '../../../../types/drawableElem'
import { RecipientAuthentication } from '../../../../types/recipients'
import { CONTRACT_SIGN_MODALS } from '../../../constants/contractSign'
import { scrollToNearestSign, setSigningNotification } from '../../../../store/contracts/signing/signing.actions'
import * as SIGNING_SELECTORS from '../../../../store/contracts/signing/signing.selectors'
import { openModal } from '../../../../store/modals/modals.actions'
import { CommentIcon, SignIcon, CloseCircleIcon } from '../../../assets/icons'
import { viewOptionsIsSignatureNamesVisibleSelector } from '../../../../store/contracts/viewOptions/viewOptions.selectors'
import { SigningNotificationMessages } from '../../../../store/contracts/signing/signing.types'
import { Variant } from '../../../../types/notifications'
import { MobileAction } from '../../../ui/DocViewerMobileToolbar/DocViewerMobileToolbar.types'
import { PointerModes } from '../../../ui/DocViewer/DocViewer.types'

export const useMobileActions = () => {
  const dispatch = useDispatch()
  const isView = useSelector(SIGNING_SELECTORS.signingIsViewPageSelector)
  const isSuccess = useSelector(SIGNING_SELECTORS.signingContractFetchedFullySelector)
  const isCompleted = useSelector(SIGNING_SELECTORS.signingContractIsCompletedSelector)
  const isDeleted = useSelector(SIGNING_SELECTORS.signingContractIsDeletedSelector)
  const recipientAuthentication = useSelector(SIGNING_SELECTORS.signingRecipientAuthentication)
  const isSignatureNamesVisible = useSelector(viewOptionsIsSignatureNamesVisibleSelector)
  const isExpired = useSelector(SIGNING_SELECTORS.signingContractIsExpiredSelector)

  const mobileActions = useMemo(() => {
    if (!isSuccess) {
      return []
    }

    const actions: MobileAction[] = []

    if (!isView) {
      actions.push({
        name: translations.SIGN,
        Icon: SignIcon,
        onClick: () => {
          if (
            recipientAuthentication === RecipientAuthentication.EKYC ||
            recipientAuthentication === RecipientAuthentication.EKYC_ID
          ) {
            dispatch(openModal(CONTRACT_SIGN_MODALS.SIGNING_ACTIONS_EKYC_VERIFICATION))
          } else {
            dispatch(scrollToNearestSign())
          }
        },
      })

      actions.push({
        name: translations.DECLINE_TO_SIGN,
        Icon: CloseCircleIcon,
        onClick: () => {
          if (
            recipientAuthentication === RecipientAuthentication.EKYC ||
            recipientAuthentication === RecipientAuthentication.EKYC_ID
          ) {
            dispatch(openModal(CONTRACT_SIGN_MODALS.SIGNING_ACTIONS_EKYC_VERIFICATION))
          } else if (isExpired) {
            dispatch(
              setSigningNotification({
                message: SigningNotificationMessages.EXPIRED_CONTRACT_NOTIFICATION,
                variant: Variant.WARNING,
              })
            )
          } else {
            dispatch(openModal(CONTRACT_SIGN_MODALS.DECLINE_SIGNING))
          }
        },
      })
    }

    actions.push({
      name: translations.COMMENT,
      Icon: CommentIcon,
      pointerMode: PointerModes.DRAW,
      drawingCompName: DrawableCompNames.COMMENT,
    })

    return actions
  }, [isView, isSuccess, isCompleted, isDeleted, recipientAuthentication, isSignatureNamesVisible])

  return mobileActions
}
