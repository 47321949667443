import React, { useEffect } from 'react'

import { AccessGuardForm } from '../AccessGuardForm/AccessGuardForm'

import { AccessGuardProps } from './AccessGuard.types'

export const AccessGuard: React.FC<AccessGuardProps> = ({
  access,
  children,
  onSubmit,
  title,
  description,
  submitText,
  isError,
  Wrapper = React.Fragment,
  onAccessed,
}) => {
  useEffect(() => {
    if (access && onAccessed) {
      onAccessed()
    }
  }, [access, onAccessed])

  return access && children ? (
    <div data-testid="access-guard-wrapper">{children}</div>
  ) : (
    <Wrapper>
      <AccessGuardForm
        onSubmit={onSubmit}
        title={title}
        description={description}
        submitText={submitText}
        isError={isError}
      />
    </Wrapper>
  )
}
