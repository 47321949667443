import styled from 'styled-components'

import Box from '../Box'

export const StyledWrapper = styled(Box).attrs({
  display: 'flex',
  alignItems: 'center',
})<{ isShow: boolean }>`
  background-color: ${(props) => props.theme.palette.blueMidnight};
  border-radius: 4px;
  color: ${(props) => props.theme.palette.white};
  position: relative;
  visibility: ${(props) => (props.isShow ? 'visible' : 'hidden')};
  padding: 10px 40px 9px 16px;
`

export const StyledMessage = styled(Box).attrs({
  ml: 1,
})``

export const StyledClose = styled(Box).attrs({
  display: 'flex',
  alignItems: 'center',
})`
  cursor: pointer;
  position: absolute;
  top: 12px;
  right: 16px;
`
