import { useDispatch } from 'react-redux'
import { useCallback, useMemo } from 'react'
import { useHistory } from 'react-router'
import { History } from 'history'
import { Dispatch } from 'redux'

import translations from '../../../../../../translations/keys'
import { CopyIcon, DeleteIcon, FolderIcon, ForwardIcon, RenameIcon, StarIcon } from '../../../../../assets/icons'
import { TableAction } from '../../../../../ui/TableActions/TableActions.types'
import { Template } from '../../../../../../types/templates'
import { TableData } from '../../../../../ui/Table/Table.types'
import { openModal } from '../../../../../../store/modals/modals.actions'
import { TEMPLATES_MODALS } from '../../../../../../constants/templates'
import { location } from '../../../../../../utils/location'
import { templateListGoToFolder } from '../../../../../../store/templates/list/list.actions'
import { fetchFavoriteTemplate } from '../../../../../../store/templates/favorite/favorite.actions'

export const openTemplate = (dispatch: Dispatch, history: History, template: Template) => {
  if (template.isFolder) {
    dispatch(templateListGoToFolder({ name: template.name, value: template.id }))
  } else {
    history.push(location.templatesViewUrl(template.id))
  }
}

export const useActions = (): TableAction[] => {
  const dispatch = useDispatch()
  const history = useHistory()

  const hideForFolder = useCallback((data: TableData) => {
    const template = data as Template

    return !template.isFolder
  }, [])

  return useMemo(
    () => [
      {
        name: translations.OPEN,
        Icon: ForwardIcon,
        callback: (data) => {
          openTemplate(dispatch, history, data as Template)
        },
      },
      {
        name: translations.RENAME,
        Icon: RenameIcon,
        callback: ({ id, isFolder, name }) => {
          dispatch(
            openModal(TEMPLATES_MODALS.RENAME_TEMPLATE, {
              id,
              title: isFolder ? translations.RENAME_FOLDER : translations.RENAME_FILE,
              oldName: name,
              isFolder,
            })
          )
        },
      },
      {
        name: (data) => {
          const template = data as Template

          return template.favorite ? translations.UNMARK_AS_STARRED : translations.MARK_AS_STARRED
        },
        Icon: StarIcon,
        callback: ({ id, favorite }) => {
          dispatch(fetchFavoriteTemplate(id, !favorite))
        },
        show: hideForFolder,
      },
      {
        name: translations.DUPLICATE,
        Icon: CopyIcon,
        callback: ({ id, name }) => {
          dispatch(openModal(TEMPLATES_MODALS.DUPLICATE_TEMPLATE, { id, oldName: name }))
        },
        show: hideForFolder,
      },
      {
        name: translations.MOVE_TO_FOLDER,
        Icon: FolderIcon,
        callback: ({ id }) => {
          dispatch(openModal(TEMPLATES_MODALS.MOVE_TEMPLATE_TO_FOLDER, { id }))
        },
        show: hideForFolder,
      },
      {
        name: translations.DELETE,
        Icon: DeleteIcon,
        callback: (data) => {
          const { id, isFolder } = data as Template

          dispatch(
            openModal(TEMPLATES_MODALS.DELETE_TEMPLATE, {
              description: isFolder
                ? translations.DELETE_TEMPLATE_FOLDER_DESCRIPTION
                : translations.DELETE_TEMPLATE_DESCRIPTION,
              id,
              isFolder,
            })
          )
        },
      },
    ],
    [hideForFolder]
  )
}
