export interface UpdateDocumentRequestBody {
  name?: string
  folderId?: string | null
  favorite?: boolean
}

export interface DocumentMessageForm {
  title: string
  message: string
}

export enum DocumentMessageFields {
  title = 'title',
  message = 'message',
}

export type DocumentMessageBody = DocumentMessageForm
