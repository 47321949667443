import React from 'react'

import translations from '../../../translations/keys'
import Resizer from '../Resizer'
import { PointerModes } from '../DocViewer/DocViewer.types'

import { DocFieldProps } from './DocField.types'
import * as Styled from './DocField.styles'

const MIN_SIZE = 8

export const DocTextField: React.FC<DocFieldProps> = ({
  id,
  isSelected = false,
  isEditable = false,
  x,
  y,
  width,
  fontSize = 12,
  placeholder = translations.TEXT_FIELD_PLACEHOLDER,
  mask = '',
  text = '',
  error = null,
  onChange,
  onBlur,
  onDelete,
  ...props
}) => {
  const isError = Boolean(error)

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isOverflowing = event.target.scrollWidth <= event.target.offsetWidth
    const isOnlySpaces = text === '' && !event.target.value.trim().length

    if (onChange && (isOverflowing || mask) && !isOnlySpaces) {
      onChange(event)
    }
  }

  const handleDelete = () => {
    onDelete && onDelete(id)
  }

  return (
    <Styled.Wrapper
      data-testid="doc-field-wrapper"
      data-constraints="height"
      data-id={id}
      data-type={PointerModes.DRAG}
      data-min-size={MIN_SIZE}
      isSelected={isSelected}
      isError={isError}
      style={{ left: x, top: y, width }}
      {...props}
    >
      {isSelected && <Resizer isEditable={isEditable} />}
      <Styled.Input
        autoComplete="off"
        data-testid="doc-field-input"
        name={id}
        value={text}
        mask={mask}
        tabIndex={-1}
        disabled={!onChange}
        fontSize={fontSize}
        placeholder={placeholder}
        onChange={handleChange}
        onBlur={onBlur}
      />
      {isSelected && (
        <Styled.Actions>
          <Styled.Action>
            <Styled.RemoveButton data-action onClick={handleDelete} />
          </Styled.Action>
        </Styled.Actions>
      )}
    </Styled.Wrapper>
  )
}
