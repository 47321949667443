import { Epic, combineEpics, ofType } from 'redux-observable'
import { catchError, map, mergeMap, takeUntil } from 'rxjs/operators'

import { notificationService } from '../../api'
import { catchFetchError } from '../../utils/catchFetchError'

import * as ACTIONS from './userNotifications.actions'

const fetchUserNotifications: Epic = (action$) =>
  action$.pipe(
    ofType(ACTIONS.fetchUserNotificationsTypes.request),
    mergeMap(({ payload }) =>
      notificationService.fetchNotifications(payload.params).pipe(
        map((response) => ACTIONS.fetchUserNotificationsSuccess(response)),
        catchError(catchFetchError(ACTIONS.fetchUserNotificationsFailure)),
        takeUntil(
          action$.pipe(ofType(ACTIONS.fetchUserNotificationsTypes.success, ACTIONS.fetchUserNotificationsTypes.failure))
        )
      )
    )
  )

const fetchReadNotifications: Epic = (action$) =>
  action$.pipe(
    ofType(ACTIONS.fetchReadNotificationsTypes.request),
    mergeMap(({ payload }) =>
      notificationService.fetchReadNotifications(payload.body).pipe(
        map(() => ACTIONS.fetchReadNotificationsSuccess(payload.body)),
        catchError(catchFetchError(ACTIONS.fetchReadNotificationsFailure)),
        takeUntil(
          action$.pipe(ofType(ACTIONS.fetchReadNotificationsTypes.success, ACTIONS.fetchReadNotificationsTypes.failure))
        )
      )
    )
  )

const fetchUnreadCount: Epic = (action$) =>
  action$.pipe(
    ofType(ACTIONS.fetchUnreadCountTypes.request),
    mergeMap(() =>
      notificationService
        .fetchNotifications({
          limit: 0,
          offset: 0,
          newOnly: 'true',
        })
        .pipe(
          map((response) => ACTIONS.fetchUnreadCountSuccess(response.totalCount)),
          catchError(catchFetchError(ACTIONS.fetchUnreadCountFailure)),
          takeUntil(action$.pipe(ofType(ACTIONS.fetchUnreadCountTypes.success, ACTIONS.fetchUnreadCountTypes.failure)))
        )
    )
  )

export const userNotificationsEpics = combineEpics(fetchUserNotifications, fetchReadNotifications, fetchUnreadCount)
