import React from 'react'

import MainTemplate from '../../components/Main/MainTemplate'

import { SubscriptionView as SubscriptionViewPage } from './SubscriptionView'

export const SubscriptionView = () => (
  <MainTemplate>
    <SubscriptionViewPage />
  </MainTemplate>
)
