import styled from 'styled-components'

import { Header as StyledHeader } from '../../Header/Header.styles'

export const HEADER_HEIGHT = '70px'
export const HEADER_TABLET_HEIGHT = '112px'
const SIDEBAR_WIDTH = '264px'

export const Header = styled(StyledHeader)<{ isBannerVisible: boolean }>`
  height: ${HEADER_HEIGHT};
  padding: 14px 32px;
  border-bottom: ${({ theme }) => `2px solid ${theme.palette.blue}`};
  top: ${({ isBannerVisible }) => (isBannerVisible ? '50px' : 0)};

  ${({ theme }) => `@media ${theme.devices.laptop} {
    height: ${HEADER_TABLET_HEIGHT};
  }`}

  ${({ theme }) => `@media ${theme.devices.mobile} {
    height: ${HEADER_HEIGHT};
  }`}
`
export const Main = styled.main<{ isBannerVisible: boolean }>`
  display: flex;
  height: 100%;
  padding-top: ${({ isBannerVisible }) => (isBannerVisible ? '160px' : HEADER_HEIGHT)};

  ${({ theme, isBannerVisible }) => `@media ${theme.devices.laptop} {
    padding-top: ${isBannerVisible ? '120px' : HEADER_TABLET_HEIGHT};
  }`}
  ${({ theme, isBannerVisible }) => `@media ${theme.devices.mobile} {
    padding-top: ${isBannerVisible ? '120px' : HEADER_HEIGHT};
  }`}
`
export const Sidebar = styled.div<{ isSidebarOpen?: boolean }>`
  display: ${({ isSidebarOpen }) => (isSidebarOpen ? 'block' : 'none')};
  overflow: auto;
  height: 100%;
  width: ${SIDEBAR_WIDTH};
  flex: 1 0 ${SIDEBAR_WIDTH};
  padding: 16px 32px;
  box-shadow: ${({ theme }) => theme.shadow};
  z-index: 90;
`
export const Content = styled.div<{ withSidebar?: boolean; isSidebarOpen?: boolean }>`
  width: ${({ withSidebar, isSidebarOpen }) =>
    withSidebar && isSidebarOpen ? `calc(100% - ${SIDEBAR_WIDTH})` : '100%'};
`
