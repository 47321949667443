import { FETCH_STATUSES } from '../../types/fetch'
import { RootState } from '../rootReducer.types'

export const registrationUserIdSelector = (state: RootState) => state.registration.userId

export const registrationFormSelector = (state: RootState) => state.registration.registrationForm

export const registrationConfirmPhoneFetchStatusSelector = (state: RootState) =>
  state.registration.confirmPhoneFetchStatus

export const registrationConfirmPhoneErrorSelector = (state: RootState) => state.registration.confirmPhoneError

export const registrationSignUpFetchStatusSelector = (state: RootState) => state.registration.signUpFetchStatus

export const registrationSignUpErrorSelector = (state: RootState) => state.registration.signUpError

export const registrationSignUpIsFailureSelector = (state: RootState) =>
  state.registration.signUpFetchStatus === FETCH_STATUSES.FAILURE

export const registrationResendCodeFetchStatusSelector = (state: RootState) => state.registration.resendCodeFetchStatus

export const registrationResendCodeErrorSelector = (state: RootState) => state.registration.resendCodeError

export const registrationLinkIdSelector = (state: RootState) => state.registration.linkId
