import { FETCH_STATUSES } from '../../../types/fetch'
import { RootState } from '../../rootReducer.types'

export const uploadIsRequestSelector = (state: RootState) =>
  state.templates.upload.fetchStatus === FETCH_STATUSES.REQUEST

export const uploadIsSuccessSelector = (state: RootState) =>
  state.templates.upload.fetchStatus === FETCH_STATUSES.SUCCESS

export const uploadFetchStatusSelector = (state: RootState) => state.templates.upload.fetchStatus

export const uploadErrorSelector = (state: RootState) => state.templates.upload.error

export const uploadIdSelector = (state: RootState) => state.templates.upload.id
