import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { EditableElem } from '../../../types/editableElem'
import { PLACEMENT_MODALS } from '../../constants/placement'
import * as PREVIEW_SELECTORS from '../../../store/contracts/preview/preview.selectors'
import * as RECIPIENTS_SELECTORS from '../../../store/contracts/recipients/recipients.selectors'
import * as REUPLOAD_SELECTORS from '../../../store/contracts/reupload/reupload.selectors'
import {
  setContractId,
  fetchSenderInfo,
  fetchRecipients,
  clearRecipients,
} from '../../../store/contracts/recipients/recipients.actions'
import * as PLACEMENT_SELECTORS from '../../../store/contracts/placement/placement.selectors'
import { openModal } from '../../../store/modals/modals.actions'
import * as ACTIONS from '../../../store/contracts/placement/placement.actions'
import { fetchReupload } from '../../../store/contracts/reupload/reupload.actions'
import { removeExtension } from '../../../utils/file/removeExtension'
import { useDispatchUnmount } from '../../../hooks/useDispatchUnmount'
import { useContractPreviewFetch } from '../../../hooks/contractPreview/useContractPreviewFetch'
import { useShowFetchError } from '../../hooks/useShowFetchError'
import DocViewer from '../../ui/DocViewer'
import { PreviewHeader } from '../../components/PreviewHeader/PreviewHeader'
import FullScreenTemplate from '../../components/Main/FullScreenTemplate'
import { ContractRouteParams } from '../../types/signing'
import { viewOptionsIsSignatureNamesVisibleSelector } from '../../../store/contracts/viewOptions/viewOptions.selectors'
import { cleanViewOptions } from '../../../store/contracts/viewOptions/viewOptions.actions'

import PlacementSidebar from './components/PlacementSidebar'
import * as Styled from './ContractPlacement.styles'
import PlacementModals from './components/PlacementModals'
import { useToolbarActions } from './hooks/useToolbarActions'
import { useActions } from './hooks/useActions'
import { useButtons } from './hooks/useButtons'

export const ContractPlacement = () => {
  const dispatch = useDispatch()
  const { contractId } = useParams<ContractRouteParams>()
  const previewFetchStatus = useSelector(PREVIEW_SELECTORS.previewFetchStatusSelector)
  const error = useSelector(PREVIEW_SELECTORS.previewErrorSelector)
  const data = useSelector(PREVIEW_SELECTORS.previewDataSelector)
  const sender = useSelector(RECIPIENTS_SELECTORS.recipientsSenderSelector)
  const byId = useSelector(RECIPIENTS_SELECTORS.recipientsByIdSelector)
  const byOrder = useSelector(RECIPIENTS_SELECTORS.recipientsByOrderSelector)
  const sorted = useSelector(RECIPIENTS_SELECTORS.recipientsSortedSelector)
  const placementById = useSelector(PLACEMENT_SELECTORS.placementByIdSelector)
  const placementSorted = useSelector(PLACEMENT_SELECTORS.placementSortedSelector)
  const placementByPages = useSelector(PLACEMENT_SELECTORS.placementByPagesSelector)
  const title = removeExtension(data?.name || '')
  const recipients = { byId, byOrder, sorted }
  const toolbarActions = useToolbarActions()
  const shouldRefetch = useSelector(PLACEMENT_SELECTORS.placementGenerateIsSuccess)
  const fetchGenerateStatus = useSelector(PLACEMENT_SELECTORS.placementFetchGenerateSelector)
  const reuploadStatus = useSelector(REUPLOAD_SELECTORS.reuploadFetchStatusSelector)
  const reuploadError = useSelector(REUPLOAD_SELECTORS.reuploadErrorSelector)
  const isReuploadSuccess = useSelector(REUPLOAD_SELECTORS.reuploadIsSuccessSelector)
  const reuploading = useSelector(REUPLOAD_SELECTORS.reuploadIsRequestSelector)
  const isGeneratePlacementSuccess = useSelector(PLACEMENT_SELECTORS.placementGenerateIsSuccess)
  const isSignatureNamesVisible = useSelector(viewOptionsIsSignatureNamesVisibleSelector)
  const actions = useActions()
  const buttons = useButtons()

  const handlePlaceChange = (place: EditableElem) => {
    dispatch(ACTIONS.fetchUpdatePlacement(place, placementById, placementSorted))
  }

  const handleReset = () => {
    dispatch(ACTIONS.fetchGeneratePlacement(contractId))
  }

  const handleReuploadConfirm = () => {
    dispatch(ACTIONS.clearPlacementStatus())
    dispatch(openModal(PLACEMENT_MODALS.REUPLOAD))
  }

  const handleReupload = (file: File) => {
    dispatch(fetchReupload(contractId, file))
  }

  useEffect(() => {
    dispatch(setContractId(contractId))
    dispatch(fetchSenderInfo())
    dispatch(fetchRecipients(contractId))
  }, [])

  useEffect(() => {
    if (recipients.sorted.length > 0) {
      dispatch(ACTIONS.fetchPlacement(contractId, byId))
    }
  }, [recipients.sorted, shouldRefetch])

  useEffect(() => {
    if (isReuploadSuccess && !isGeneratePlacementSuccess) {
      dispatch(ACTIONS.fetchGeneratePlacement(contractId))
    } else if (isReuploadSuccess && isGeneratePlacementSuccess) {
      history.go(0)
    }
  }, [isReuploadSuccess, isGeneratePlacementSuccess])

  useContractPreviewFetch(contractId)
  useShowFetchError(previewFetchStatus, error)
  useShowFetchError(fetchGenerateStatus.status, fetchGenerateStatus.error)
  useShowFetchError(reuploadStatus, reuploadError)
  useDispatchUnmount(clearRecipients, ACTIONS.clearPlacement, cleanViewOptions)

  return (
    <FullScreenTemplate
      headerContent={<PreviewHeader actions={actions} buttons={buttons} title={title} />}
      sidebarContent={<PlacementSidebar contractId={contractId} sender={sender} recipients={recipients} />}
    >
      <Styled.Container test-id="contract-preview-container">
        {data && placementSorted.length > 0 && (
          <DocViewer
            pages={data.pages}
            placementById={placementById}
            placementByPages={placementByPages}
            actions={toolbarActions}
            isSignatureNamesVisible={isSignatureNamesVisible}
            onElemChange={handlePlaceChange}
          />
        )}
      </Styled.Container>
      <PlacementModals
        reuploading={reuploading}
        onReset={handleReset}
        onReuploadConfirm={handleReuploadConfirm}
        onReupload={handleReupload}
      />
    </FullScreenTemplate>
  )
}
