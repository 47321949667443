import React, { useState, useRef } from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router'
import { useTranslation } from 'react-i18next'

import translations from '../../../translations/keys'
import { location } from '../../../utils/location'
import useNavControls from '../../hooks/useNavControls'
import { userMenu } from '../../routes/menus'
import Popover from '../Popover'
import MenuLink from '../MenuLink'
import Avatar from '../Avatar'
import Typography from '../Typography'
import { TYPOGRAPHY_NAMES } from '../Typography/Typography.types'
import DropdownButton from '../DropdownButton'

import { UserMenuProps } from './UserMenu.types'

const StyledUserMenuWrapper = styled.div``
const StyledMenu = styled.ul`
  list-style: none;
`
const StyledUserInfo = styled.div`
  margin-left: 16px;
`
const StyledUserName = styled.div``

export const UserMenu: React.FC<UserMenuProps> = ({ firstName, lastName, plan, mobile, tablet, onLogout }) => {
  const history = useHistory()
  const { setSidebarOpen } = useNavControls()
  const [visible, setVisible] = useState(false)
  const targetRef = useRef(null)
  const shortName = firstName && lastName ? `${firstName[0]}. ${lastName}` : ''
  const { t } = useTranslation()
  const handleDropdownOpen = () => {
    if (mobile) {
      history.push(location.editProfileUrl())
      setSidebarOpen((prevValue) => !prevValue)
    } else {
      setVisible((prevValue) => !prevValue)
    }
  }

  const handleClose = () => {
    setVisible(false)
  }

  const handleMenuClick = (route: string) => (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    if (route === location.logoutUrl()) {
      event.preventDefault()
      onLogout()
    }
  }

  return (
    <StyledUserMenuWrapper data-testid="user-menu-wrapper">
      <DropdownButton
        data-testid="user-menu-dropdown"
        targetRef={targetRef}
        open={visible}
        hideTriangle={tablet}
        onClick={handleDropdownOpen}
      >
        <Avatar firstName={firstName} lastName={lastName} />
        {!tablet && (
          <StyledUserInfo>
            <StyledUserName>{shortName}</StyledUserName>
            <Typography name={TYPOGRAPHY_NAMES.bodySmall12}>
              <b>{plan}</b>
            </Typography>
          </StyledUserInfo>
        )}
      </DropdownButton>
      <Popover
        data-testid="user-menu-popover"
        offsetTop={30}
        targetRef={targetRef}
        visible={visible}
        onClose={handleClose}
      >
        <StyledMenu data-testid="user-menu-styleMenu">
          {userMenu.map((item) => (
            <li key={item.name} data-testid={`user-menu-${item.name}`}>
              <MenuLink compact disabled={!item.route} to={item.route} onClick={handleMenuClick(item.route)}>
                {t(translations[item.name])}
              </MenuLink>
            </li>
          ))}
        </StyledMenu>
      </Popover>
    </StyledUserMenuWrapper>
  )
}
