import React from 'react'
import ContentLoader from 'react-content-loader'

import { blueExtraLight, glowColor } from '../../../themes/colors'

const FILL_COLOR = blueExtraLight
const GLOW_COLOR = glowColor
const SPEED = 1.5
const SVG_WIDTH = '100%'
const SVG_HEIGHT = 216
const RADIUS = 3
const FIELD_HEIGHT = 30

export const CommentThreadSkeleton: React.FC = () => (
  <ContentLoader
    display="block"
    width={SVG_WIDTH}
    height={SVG_HEIGHT}
    speed={SPEED}
    backgroundColor={FILL_COLOR}
    foregroundColor={GLOW_COLOR}
  >
    <rect width="65%" height="14" x="0" y="0" rx={RADIUS} ry={RADIUS} />
    <rect width="27%" height="12" x="0" y="25" rx={RADIUS} ry={RADIUS} />
    <rect width="100%" height={FIELD_HEIGHT} x="0" y="50" rx={RADIUS} ry={RADIUS} />

    <rect width="48%" height="14" x="0" y="100" rx={RADIUS} ry={RADIUS} />
    <rect width="27%" height="12" x="0" y="125" rx={RADIUS} ry={RADIUS} />
    <rect width="100%" height={FIELD_HEIGHT} x="0" y="150" rx={RADIUS} ry={RADIUS} />
  </ContentLoader>
)
