import { combineEpics, Epic, ofType } from 'redux-observable'
import { of } from 'rxjs'
import { catchError, mergeMap, takeUntil } from 'rxjs/operators'

import { templateService, templateFolderService } from '../../../api'
import { TemplatesMessages } from '../../../types/templates'
import { catchFetchError } from '../../../utils/catchFetchError'
import * as LIST_ACTIONS from '../list/list.actions'
import { prepareTemplatesOffset } from '../list/list.utils'
import { Variant } from '../../../types/notifications'

import * as DELETE_ACTIONS from './delete.actions'

export const fetchDeleteTemplate: Epic = (action$, state$) =>
  action$.pipe(
    ofType(DELETE_ACTIONS.fetchDeleteTemplateTypes.request),
    mergeMap(({ payload }) => {
      const request = payload.isFolder ? templateFolderService.fetchDeleteFolder : templateService.fetchDeleteTemplate

      return request(payload.id).pipe(
        mergeMap(() =>
          of(
            LIST_ACTIONS.templatesListSetOffset(prepareTemplatesOffset(state$.value.templates.list)),
            LIST_ACTIONS.templatesListForceUpdate(),
            DELETE_ACTIONS.fetchDeleteTemplateSuccess({
              message: payload.isFolder
                ? TemplatesMessages.TEMPLATE_FOLDER_DELETED
                : TemplatesMessages.TEMPLATE_DELETED,
              variant: Variant.SUCCESS,
            })
          )
        ),
        catchError(catchFetchError(DELETE_ACTIONS.fetchDeleteTemplateFailure)),
        takeUntil(
          action$.pipe(
            ofType(DELETE_ACTIONS.fetchDeleteTemplateTypes.success, DELETE_ACTIONS.fetchDeleteTemplateTypes.failure)
          )
        )
      )
    })
  )

export const deleteEpics = combineEpics(fetchDeleteTemplate)
