import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { TEMPLATES_MODALS } from '../../../../../constants/templates'
import { closeModal } from '../../../../../store/modals/modals.actions'
import { modalsVisibleSelector } from '../../../../../store/modals/modals.selectors'
import CreateFolderForTemplateModal from '../../../../components/Modals/TemplatesModals/CreateFolderForTemplateModal'
import CreateTemplateFromContractModal from '../../../../components/Modals/TemplatesModals/CreateTemplateFromContractModal'
import DeleteTemplateModal from '../../../../components/Modals/TemplatesModals/DeleteTemplateModal'
import DuplicateTemplateModal from '../../../../components/Modals/TemplatesModals/DuplicateTemplateModal'
import MoveTemplateToFolderModal from '../../../../components/Modals/TemplatesModals/MoveTemplateToFolderModal'
import RenameTemplateModal from '../../../../components/Modals/TemplatesModals/RenameTemplateModal'
import SigningOrderModal from '../../../../components/Modals/TemplatesModals/SigningOrderModal'
import TemplatesCreateFolderModal from '../../../../components/Modals/TemplatesModals/TemplatesCreateFolderModal'
import UploadTemplateModal from '../../../../components/Modals/TemplatesModals/UploadTemplateModal'
import UploadTemplateToModal from '../../../../components/Modals/TemplatesModals/UploadTemplateToModal'

export const Modals = () => {
  const dispatch = useDispatch()

  const showRenameTemplate = useSelector(modalsVisibleSelector(TEMPLATES_MODALS.RENAME_TEMPLATE))
  const handleRenameTemplateClose = () => {
    dispatch(closeModal(TEMPLATES_MODALS.RENAME_TEMPLATE))
  }

  const showDeleteTemplate = useSelector(modalsVisibleSelector(TEMPLATES_MODALS.DELETE_TEMPLATE))
  const handleDeleteTemplateClose = () => {
    dispatch(closeModal(TEMPLATES_MODALS.DELETE_TEMPLATE))
  }

  const showMoveTemplateToFolder = useSelector(modalsVisibleSelector(TEMPLATES_MODALS.MOVE_TEMPLATE_TO_FOLDER))
  const handleMoveTemplateToFolderClose = () => {
    dispatch(closeModal(TEMPLATES_MODALS.MOVE_TEMPLATE_TO_FOLDER))
  }

  const showDuplicateTemplate = useSelector(modalsVisibleSelector(TEMPLATES_MODALS.DUPLICATE_TEMPLATE))
  const handleDuplicateTemplateClose = () => {
    dispatch(closeModal(TEMPLATES_MODALS.DUPLICATE_TEMPLATE))
  }

  const showUploadTemplate = useSelector(modalsVisibleSelector(TEMPLATES_MODALS.UPLOAD_TEMPLATE))
  const handleUploadTemplateClose = () => {
    dispatch(closeModal(TEMPLATES_MODALS.UPLOAD_TEMPLATE))
  }

  const showUploadTemplateTo = useSelector(modalsVisibleSelector(TEMPLATES_MODALS.UPLOAD_TEMPLATE_TO))
  const handleUploadTemplateToClose = () => {
    dispatch(closeModal(TEMPLATES_MODALS.UPLOAD_TEMPLATE_TO))
  }

  const showCreateFolderForTemplate = useSelector(modalsVisibleSelector(TEMPLATES_MODALS.CREATE_FOLDER_FOR_TEMPLATE))
  const handleCreateFolderForTemplateClose = () => {
    dispatch(closeModal(TEMPLATES_MODALS.CREATE_FOLDER_FOR_TEMPLATE))
  }

  const showTemplatesCreateFolder = useSelector(modalsVisibleSelector(TEMPLATES_MODALS.TEMPLATES_CREATE_FOLDER))
  const handleTemplatesCreateFolderClose = () => {
    dispatch(closeModal(TEMPLATES_MODALS.TEMPLATES_CREATE_FOLDER))
  }

  const showCreateTemplateFromContract = useSelector(
    modalsVisibleSelector(TEMPLATES_MODALS.CREATE_TEMPLATE_FROM_CONTRACT)
  )
  const handleCreateTemplateFromContractClose = () => {
    dispatch(closeModal(TEMPLATES_MODALS.CREATE_TEMPLATE_FROM_CONTRACT))
  }

  const showSigningOrder = useSelector(modalsVisibleSelector(TEMPLATES_MODALS.CHOOSE_SIGNING_ORDER))
  const handleSigningOrderClose = () => {
    dispatch(closeModal(TEMPLATES_MODALS.CHOOSE_SIGNING_ORDER))
  }

  return (
    <>
      {showRenameTemplate && <RenameTemplateModal onClose={handleRenameTemplateClose} />}
      {showDeleteTemplate && <DeleteTemplateModal onClose={handleDeleteTemplateClose} />}
      {showMoveTemplateToFolder && <MoveTemplateToFolderModal onClose={handleMoveTemplateToFolderClose} />}
      {showDuplicateTemplate && <DuplicateTemplateModal onClose={handleDuplicateTemplateClose} />}
      {showUploadTemplate && <UploadTemplateModal onClose={handleUploadTemplateClose} />}
      {showUploadTemplateTo && <UploadTemplateToModal onClose={handleUploadTemplateToClose} />}
      {showTemplatesCreateFolder && <TemplatesCreateFolderModal onClose={handleTemplatesCreateFolderClose} />}
      {showCreateTemplateFromContract && (
        <CreateTemplateFromContractModal onClose={handleCreateTemplateFromContractClose} />
      )}
      {showCreateFolderForTemplate && <CreateFolderForTemplateModal onClose={handleCreateFolderForTemplateClose} />}
      {showSigningOrder && <SigningOrderModal onClose={handleSigningOrderClose} />}
    </>
  )
}
