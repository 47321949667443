import { FETCH_STATUSES } from '../../types/fetch'
import { RootState } from '../rootReducer.types'

export const userNotificationsDataSelector = (state: RootState) => state.userNotifications.data
export const userNotificationsFetchStatusSelector = (state: RootState) => state.userNotifications.fetchStatus
export const userNotificationsErrorSelector = (state: RootState) => state.userNotifications.error
export const userNotificationsLimitSelector = (state: RootState) => state.userNotifications.limit
export const userNotificationsOffsetSelector = (state: RootState) => state.userNotifications.offset
export const userNotificationsTotalSelector = (state: RootState) => state.userNotifications.total
export const userNotificationsIsLoadingSelector = (state: RootState) =>
  state.userNotifications.fetchStatus === FETCH_STATUSES.REQUEST
export const userNotificationsIsIdleSelector = (state: RootState) =>
  state.userNotifications.fetchStatus === FETCH_STATUSES.IDLE
export const userNotificationsHasMoreSelector = (state: RootState) => {
  const { total, data } = state.userNotifications
  return total > data.length
}

export const userNotificationsHasUnreadSelector = (state: RootState) => state.userNotifications.unread.count > 0
