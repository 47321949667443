import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { RECIPIENTS_DIAGRAM_MODALS } from '../../../constants/recipientsDiagram'
import { TEMPLATES_MODALS } from '../../../../constants/templates'
import { modalsVisibleSelector } from '../../../../store/modals/modals.selectors'
import { closeModal } from '../../../../store/modals/modals.actions'
import { DeleteRecipientModal } from '../../../components/Modals/RecipientsModals/DeleteRecipientModal/DeleteRecipientModal'
import { EditRecipientModal } from '../../../components/Modals/RecipientsModals/EditRecipientModal/EditRecipientModal'
import DiscardConfirmModal from '../../../components/Modals/DocumentModals/DiscardConfirmModal'
import UploadIntoClmOnlyModal from '../../../components/Modals/TemplatesModals/UploadIntoClmOnlyModal'

import { ModalsProps } from './Modals.types'

export const Modals: React.FC<ModalsProps> = ({
  templatesMode,
  uploading,
  onUpdate,
  onDiscard,
  onUpload,
  onDelete,
}) => {
  const dispatch = useDispatch()

  const showDeleteRecipientModal = useSelector(
    modalsVisibleSelector(RECIPIENTS_DIAGRAM_MODALS.DELETE_DIAGRAM_RECIPIENT)
  )
  const onCloseDeleteRecipientModal = () => {
    dispatch(closeModal(RECIPIENTS_DIAGRAM_MODALS.DELETE_DIAGRAM_RECIPIENT))
  }

  const showEditRecipientModal = useSelector(modalsVisibleSelector(RECIPIENTS_DIAGRAM_MODALS.EDIT_DIAGRAM_RECIPIENT))
  const onCloseEditRecipientModal = () => {
    dispatch(closeModal(RECIPIENTS_DIAGRAM_MODALS.EDIT_DIAGRAM_RECIPIENT))
  }

  const showDiscardConfirmModal = useSelector(modalsVisibleSelector(TEMPLATES_MODALS.DISCARD_CONFIRMATION))
  const onCloseDiscardConfirmModal = () => {
    dispatch(closeModal(TEMPLATES_MODALS.DISCARD_CONFIRMATION))
  }

  const showUpload = useSelector(modalsVisibleSelector(TEMPLATES_MODALS.UPLOAD_TO_CLM_ONLY))
  const onCloseReupload = () => {
    dispatch(closeModal(TEMPLATES_MODALS.UPLOAD_TO_CLM_ONLY))
  }

  return (
    <>
      {showDeleteRecipientModal && <DeleteRecipientModal onDelete={onDelete} onClose={onCloseDeleteRecipientModal} />}
      {showEditRecipientModal && (
        <EditRecipientModal templatesMode={templatesMode} onUpdate={onUpdate} onClose={onCloseEditRecipientModal} />
      )}
      {showDiscardConfirmModal && <DiscardConfirmModal onDiscard={onDiscard} onClose={onCloseDiscardConfirmModal} />}
      {showUpload && <UploadIntoClmOnlyModal loading={uploading} onUpload={onUpload} onClose={onCloseReupload} />}
    </>
  )
}
