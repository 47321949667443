import { Action } from 'redux'

export enum ModalsActions {
  'OPEN_MODAL' = 'OPEN_MODAL',
  'CLOSE_MODAL' = 'CLOSE_MODAL',
}

export interface ModalsState {
  visible: {
    [key: string]: boolean
  }
  payloads: {
    [key: string]: any
  }
}

export interface OpenModalAction<D> extends Action {
  payload: {
    id: string
    data?: D
  }
}

export interface CloseModalAction extends Action {
  payload: { id: string }
}
