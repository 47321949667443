import { Action } from 'redux'

import { FetchFailurePayload, FETCH_STATUSES } from '../../../types/fetch'
import { EnabledFeatures, SubscriptionUserPlan, EnabledLimits } from '../../../types/subscription'

export interface UserPlanState {
  fetchStatus: FETCH_STATUSES
  error: FetchFailurePayload | null
  data: SubscriptionUserPlan | null
  enabledFeatures: EnabledFeatures | null
  enabledLimits: EnabledLimits | null
  isFirstLoad: boolean
}

export enum UserPlanActions {
  'FETCH_SUBSCRIPTION_USER_PLAN' = 'FETCH_SUBSCRIPTION_USER_PLAN',
  'CLEAR_SUBSCRIPTION_USER_PLAN' = 'CLEAR_SUBSCRIPTION_USER_PLAN',
}

export type FetchUserPlan = Action

export interface FetchUserPlanSuccess extends Action {
  payload: { data: SubscriptionUserPlan }
}
