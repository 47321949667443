import React from 'react'

import Modal from '../../../Modal'
import Box from '../../../../ui/Box'
import { AppStoreSvg } from '../../../../assets/AppStoreSvg'
import { GooglePlaySvg } from '../../../../assets/GooglePlaySvg'
import { PhoneWithHandSvg } from '../../../../assets/PhoneWithHandSvg'
import { useDispatchUnmount } from '../../../../../hooks/useDispatchUnmount'
import { cleanVerification } from '../../../../../store/contracts/verification/verification.actions'
import Typography from '../../../../ui/Typography'
import { TYPOGRAPHY_NAMES } from '../../../../ui/Typography/Typography.types'

import { StubEKYCModalProps } from './StubEKYCModal.types'
import { StyledStores } from './StubEKYCModal.styles'

export const StubEKYCModal: React.FC<StubEKYCModalProps> = ({ onClose, description, title }) => {
  useDispatchUnmount(cleanVerification)

  return (
    <Modal onClose={onClose}>
      <Box display="flex" justifyContent="center" flexDirection="column" alignItems="center" mt={3} mb={3}>
        <PhoneWithHandSvg />
        {title && (
          <Box textAlign="center" mt={3}>
            <Typography name={TYPOGRAPHY_NAMES.H1}>{title}</Typography>
          </Box>
        )}
        <Box textAlign="center" width={568} mobileWidth="100%" mt={3}>
          {description}
        </Box>
        <StyledStores>
          <Box>
            <AppStoreSvg />
          </Box>
          <Box ml={3} mlMobile={0} mtMobile={5}>
            <GooglePlaySvg />
          </Box>
        </StyledStores>
      </Box>
    </Modal>
  )
}
