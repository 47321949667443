import React from 'react'

import translations from '../../../../../translations/keys'
import { NOTIFICATION_MODAL_TYPES } from '../../../../ui/Modals/NotificationModal/NotificationModal.constants'
import { NotificationModalView } from '../../../../ui/Modals/NotificationModal/NotificationModalView'

import { DiscardConfirmModalProps } from './DiscardConfirmModal.types'

export const DiscardConfirmModal: React.FC<DiscardConfirmModalProps> = ({ onDiscard, onClose }) => {
  const handleSuccess = () => {
    onDiscard()
    onClose()
  }

  return (
    <NotificationModalView
      type={NOTIFICATION_MODAL_TYPES.WARNING}
      description={translations.TEMPLATES_DISCARD_CONFIRMATION}
      onSuccess={handleSuccess}
      onClose={onClose}
    />
  )
}
