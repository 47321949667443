import React from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { useTranslation } from 'react-i18next'

import translations from '../../../translations/keys'
import { location } from '../../../utils/location'
import Button from '../../ui/Button'
import { HEADER_TABLET_HEIGHT } from '../../components/Main/FullScreenTemplate/FullScreenTemplate.styles'
import Typography from '../../ui/Typography'
import { TYPOGRAPHY_NAMES } from '../../ui/Typography/Typography.types'
import Box from '../../ui/Box'
import { signingContractErrorSelector } from '../../../store/contracts/signing/signing.selectors'
import { ownerViewContractErrorSelector } from '../../../store/contracts/ownerView/ownerView.selectors'

import { StyledError403Svg } from './Error403.styles'

export const Error403 = () => {
  const ownerViewError = useSelector(ownerViewContractErrorSelector)
  const signingError = useSelector(signingContractErrorSelector)
  const history = useHistory()
  const { t } = useTranslation()

  const onUpgrade = () => {
    history.push(location.subscriptionPlansUrl())
  }

  return (
    <Box display="flex" justifyContent="center" alignItems="center" height={`calc(100% - ${HEADER_TABLET_HEIGHT})`}>
      <Box display="flex" flexDirection="column" alignItems="center">
        <StyledError403Svg />
        {ownerViewError || signingError ? (
          <>
            <Box mt={4} mb={4} textAlign="center" mobileWidth="300px">
              <Typography name={TYPOGRAPHY_NAMES.errorTitle32} mobileName={TYPOGRAPHY_NAMES.errorTitle28}>
                {t(translations.UPGRADE_YOUR_PLAN)}
              </Typography>
            </Box>
            <Box width={208}>
              <Button fullWidth onClick={onUpgrade}>
                {t(translations.UPGRADE)}
              </Button>
            </Box>
          </>
        ) : (
          <Box mt={4} mb={4} textAlign="center" mobileWidth="300px">
            <Typography name={TYPOGRAPHY_NAMES.errorTitle32} mobileName={TYPOGRAPHY_NAMES.errorTitle28}>
              {t(translations.ERROR_CHECK_URL)}
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  )
}
