import React, { FC } from 'react'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'

import { EmailIcon } from '../../../assets/EmailIcon'
import translations from '../../../../translations/keys'
import Modal from '../../Modal'
import Typography from '../../../ui/Typography'
import { TYPOGRAPHY_NAMES } from '../../../ui/Typography/Typography.types'
import Button from '../../../ui/Button'
import { fetchResendVerification } from '../../../../store/forgotPassword/forgotPassword.actions'
import { BUTTON_VARIANTS } from '../../../ui/Button/Button.types'

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 320px;
  text-align: center;
  padding-bottom: 21px;
  padding-top: 24px;
`
const StyledEmailIcon = styled(EmailIcon)`
  margin-bottom: 40px;
  margin-top: 14px;
`
const StyledTitle = styled(Typography)`
  margin-bottom: 72px;
`
const StyledButton = styled(Button)`
  margin-bottom: 24px;
`

interface VerifyEmailModalProps {
  onClose: () => void
}

export const VerifyEmailModal: FC<VerifyEmailModalProps> = ({ onClose }) => {
  const dispatch = useDispatch()
  const onResend = () => {
    dispatch(fetchResendVerification())
  }

  return (
    <Modal onClose={onClose}>
      <StyledContent>
        <StyledEmailIcon />
        <StyledTitle name={TYPOGRAPHY_NAMES.H1}>{translations.VERIFY_ACCOUNT_EMAIL_FIRST}</StyledTitle>
        <StyledButton variant={BUTTON_VARIANTS.LINK} onClick={onResend}>
          {translations.RESEND_NOTIFICATION}
        </StyledButton>
        <Button onClick={onClose} fullWidth>
          {translations.OK}
        </Button>
      </StyledContent>
    </Modal>
  )
}
