import React from 'react'

import { SvgIconProps } from '../../ui/SvgIcon/SvgIcon.types'
import SvgIcon from '../../ui/SvgIcon'

export const CheckCircleIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <path d="M12 3C7.02999 3 3 7.02999 3 12C3 16.97 7.02999 21 12 21C16.97 21 21 16.97 21 12C21 7.02999 16.97 3 12 3ZM1.5 12C1.5 6.20156 6.20156 1.5 12 1.5C17.7984 1.5 22.5 6.20156 22.5 12C22.5 17.7984 17.7984 22.5 12 22.5C6.20156 22.5 1.5 17.7984 1.5 12Z" />
    <path d="M9.82373 10.8789L11.4925 13.1945L15.1769 8.08516C15.2763 7.94755 15.418 7.84865 15.5779 7.80255C15.644 7.78348 15.7132 7.77344 15.7839 7.77344H16.8831C17.0355 7.77344 17.1245 7.94688 17.0355 8.07109L12.0995 14.9148C12.0305 15.0111 11.9396 15.0896 11.8342 15.1437C11.7289 15.1978 11.6121 15.2261 11.4937 15.2261C11.3752 15.2261 11.2585 15.1978 11.1531 15.1437C11.0477 15.0896 10.9568 15.0111 10.8878 14.9148L7.96514 10.8648C7.87608 10.7406 7.96514 10.5672 8.11748 10.5672H9.2167C9.28702 10.5672 9.35632 10.5771 9.4227 10.5962C9.582 10.6418 9.72447 10.7399 9.82373 10.8789Z" />
  </SvgIcon>
)
