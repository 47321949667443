import React from 'react'
import ContentLoader from 'react-content-loader'

import { blueExtraLight, glowColor } from '../../../themes/colors'

const FILL_COLOR = blueExtraLight
const GLOW_COLOR = glowColor
const SPEED = 1.5
const SVG_HEIGHT = 506
const RADIUS = 3
const ICON_HEIGHT = 96
const TEXT_OFFSET = 40
const TEXT_HEIGHT = 48

export const EKYCSkeleton = () => (
  <ContentLoader
    display="block"
    width="100%"
    height={SVG_HEIGHT}
    speed={SPEED}
    backgroundColor={FILL_COLOR}
    foregroundColor={GLOW_COLOR}
  >
    <rect width="10%" height={ICON_HEIGHT} x="45%" y="0" rx={RADIUS} ry={RADIUS} />
    <rect width="30%" height={TEXT_HEIGHT} x="35%" y={ICON_HEIGHT + TEXT_OFFSET} rx={RADIUS} ry={RADIUS} />
  </ContentLoader>
)
