import React from 'react'
import { useDispatch } from 'react-redux'

import { openModal } from '../../../../../store/modals/modals.actions'
import { CreateSignatureBody } from '../../../../../types/signatures'
import { SIGNATURES_MODALS } from '../../../../constants/signatures'
import { CreateSignatureModal as CommonCreateSignatureModal } from '../../CreateSignatureModal/CreateSignatureModal'
import { CreateSignatureModalProps, SignatureMode } from '../../CreateSignatureModal/CreateSignatureModal.types'

export const CreateSignatureModal: React.FC<Pick<CreateSignatureModalProps, 'onClose'>> = ({ onClose }) => {
  const dispatch = useDispatch()

  const onCreateSignature = (body: CreateSignatureBody) => {
    dispatch(openModal(SIGNATURES_MODALS.CREATE_SIGNATURE_VERIFICATION, { body }))
  }

  return (
    <CommonCreateSignatureModal onClose={onClose} mode={SignatureMode.both} onCreateSignature={onCreateSignature} />
  )
}
