export enum DeclineSigningFields {
  reason = 'reason',
}

export interface DeclineSigningForm {
  reason: string
}

export type DeclineSigningBody = DeclineSigningForm

export interface FetchDeclineQueryParams {
  verify: string
}

export interface FetchDeclineArgs {
  recipientId: string
  body: DeclineSigningBody
  queryParams: FetchDeclineQueryParams
  recipientToken: string
}
