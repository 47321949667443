import React from 'react'

import CommonPaymentProcessing from '../../../../components/PaymentProcessing'
import Box from '../../../../ui/Box'

export const PaymentProcessing = () => (
  <Box pl={8} pr={8} pb={4}>
    <Box width={320}>
      <CommonPaymentProcessing />
    </Box>
  </Box>
)
