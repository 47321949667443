import finger from '../../../../../../assets/finger.svg'

interface Options {
  halfCanvasWidth: number
  halfCanvasHeight: number
  offset: number
}

const FINGER_WIDTH = 40
const FINGER_HEIGHT = 40

export const addImgToCanvas = (
  ctx: CanvasRenderingContext2D,
  { halfCanvasWidth, halfCanvasHeight, offset }: Options
) => {
  const img = new Image()
  img.onload = function () {
    ctx.drawImage(img, halfCanvasWidth - FINGER_WIDTH / 2, halfCanvasHeight - FINGER_HEIGHT / 2 - offset)
  }
  img.src = finger
}
