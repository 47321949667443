import React, { useMemo } from 'react'
import styled from 'styled-components'
import Dotdotdot from 'react-dotdotdot'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { useTranslation } from 'react-i18next'

import translations from '../../../../../../translations/keys'
import { Template } from '../../../../../../types/templates'
import { TableData } from '../../../../../ui/Table/Table.types'
import { TableCellEllipsis, HighlightedText } from '../../../../../ui/Table/Table.styles'
import highlightText from '../../../../../../utils/highlightText'
import { FilledStarIcon, FolderIcon } from '../../../../../assets/icons'
import Box from '../../../../../ui/Box'
import { TEMPLATES_TYPES_INFO } from '../../../../../../constants/templates'
import { renderDate } from '../../../../../utils/table'
import { authUserEmailSelector } from '../../../../../../store/auth/auth.selectors'

import { openTemplate } from './useActions'

const StyledStar = styled.div`
  position: absolute;
  height: 16px;
  left: -8px;
  top: 50%;
  transform: translate(-100%, -50%);
  margin-top: 1px;
`

export const useColumns = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const userEmail = useSelector(authUserEmailSelector)
  const { t } = useTranslation()

  return useMemo(
    () => [
      {
        field: 'name',
        name: t(translations.TITLE),
        widthPercent: 17,
        sortable: true,
        searchable: true,
        render: (data: TableData, highlightedText?: string) => {
          const template = data as Template
          const onClick = () => {
            openTemplate(dispatch, history, template)
          }

          return (
            <>
              <TableCellEllipsis ellipsis onClick={onClick}>
                {template.isFolder && (
                  <Box display="inline-block" mr={1} verticalAlign="middle" height={24}>
                    <FolderIcon color="grey" />
                  </Box>
                )}
                <Box display="inline" verticalAlign="middle">
                  {highlightText(template.name, highlightedText, HighlightedText, 'span')}
                </Box>
              </TableCellEllipsis>
              {template.favorite && (
                <StyledStar>
                  <FilledStarIcon size="extraSmall" color="yellow" />
                </StyledStar>
              )}
            </>
          )
        },
      },
      {
        field: 'type',
        name: t(translations.TYPE),
        widthPercent: 15,
        sortable: true,
        render: (data: TableData) => {
          const template = data as Template

          return template.isFolder ? '' : TEMPLATES_TYPES_INFO[template.type]?.name
        },
      },
      {
        field: 'createDate',
        name: t(translations.CREATED_BY),
        widthPercent: 19.5,
        sortable: true,
        render: renderDate('createDate', userEmail),
      },
      {
        field: 'updateDate',
        name: t(translations.UPDATED_BY),
        widthPercent: 19.5,
        sortable: true,
        render: renderDate('updateDate', userEmail),
      },
      {
        field: 'recipients',
        name: t(translations.RECIPIENTS),
        widthPercent: 26,
        render: (data: TableData) => {
          const template = data as Template

          if (!template.recipients) {
            return null
          }

          const recipients = template.recipients.join(' ')

          return (
            <Dotdotdot clamp="2" useNativeClamp>
              {recipients}
            </Dotdotdot>
          )
        },
      },
    ],
    [userEmail]
  )
}
