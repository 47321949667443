import { Action } from 'redux'

import { FetchError, FETCH_STATUSES } from '../../types/fetch'
import { Phones } from '../../types/phones'

export interface PhonesState {
  fetchStatus: FETCH_STATUSES
  data: Phones
  error?: FetchError
}

export enum PhonesActions {
  'FETCH_PHONES' = 'FETCH_PHONES',
  'FETCH_PHONES_SUCCESS' = 'FETCH_PHONES_SUCCESS',
  'FETCH_PHONES_FAILURE' = 'FETCH_PHONES_FAILURE',
}

export type FetchPhonesAction = Action

export interface FetchPhonesSuccessAction extends Action {
  payload: Phones
}

export interface FetchPhonesFailureAction extends Action {
  payload: FetchError
}
