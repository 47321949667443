import { useCallback, useState } from 'react'
import { SuggestionDataItem } from 'react-mentions'
import uniqBy from 'lodash.uniqby'

import { prepareMentions } from '../utils/prepareData'
import { useDidUpdate } from '../../../../hooks/useDidUpdate'
import { MentionsProps } from '../Mentions.types'

export const useMentionsChange = ({ onMentionsChange }: Pick<MentionsProps, 'onMentionsChange'>) => {
  const [mentions, setMentions] = useState<SuggestionDataItem[]>([])

  useDidUpdate(() => {
    onMentionsChange(prepareMentions(mentions))
  }, [mentions])

  const updateMentions = useCallback(
    (newMentions: SuggestionDataItem[]) => {
      const uniqueMentions = uniqBy(newMentions, 'id')

      if (mentions.length !== uniqueMentions.length) {
        setMentions(uniqueMentions)
      }
    },
    [mentions]
  )

  return {
    updateMentions,
  }
}
