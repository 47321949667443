import { useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { catchError, mergeMap } from 'rxjs/operators'

import { authService, profileService } from '../api'
import { useNativeToken } from '../native/hooks/useNativeToken'
import { clearAsyncStorage } from '../native/utils/NativeAsyncStorage'
import { cleanAuth, setUserInfo } from '../store/auth/auth.actions'
import { User } from '../types/user'
import { useLocationPathName } from '../web/hooks/useLocationPathName'
import * as USER_PLAN_ACTIONS from '../store/subscription/userPlan/userPlan.actions'
import * as USER_PLAN_SELECTORS from '../store/subscription/userPlan/userPlan.selectors'
import SessionStorage from '../utils/SessionStorage'
import { SessionStorageKeys } from '../constants/sessionStorage'

const useProvideAuth = () => {
  const dispatch = useDispatch()
  const [authenticated, setAuthenticated] = useState(false)
  const [loading, setLoading] = useState(true)
  const locationPathName = useLocationPathName()
  const userPlanIsIdle = useSelector(USER_PLAN_SELECTORS.subscriptionUserPlanIsIdleSelector)
  const { refreshProfileBrief, refreshHeader, setAuthHeaders } = useNativeToken()

  const unAuth = useCallback(() => {
    setAuthenticated(false)
    dispatch(USER_PLAN_ACTIONS.clearUserPlan())
  }, [])

  const logout = () => {
    setLoading(true)

    const fbToken = SessionStorage.get(SessionStorageKeys.FIREBASE_TOKEN)

    if (fbToken) {
      profileService.fetchDeleteFirebaseToken(fbToken).subscribe()
    }

    clearAsyncStorage()

    authService
      .fetchLogout()
      .pipe(catchError(() => authService.fetchReshresh(refreshHeader).pipe(mergeMap(() => authService.fetchLogout()))))
      .subscribe(
        () => {
          unAuth()
          setLoading(false)
          dispatch(cleanAuth())
        },
        (response) => {
          if (response.status !== 500) {
            unAuth()
            dispatch(cleanAuth())
          }
          setLoading(false)
        }
      )
  }

  useEffect(() => {
    if (refreshProfileBrief) {
      profileService
        .fetchProfileBrief()
        .pipe(catchError(() => authService.fetchReshresh(refreshHeader)))
        .forEach((response) => {
          setAuthenticated(true)
          setLoading(false)
          dispatch(setUserInfo(response as User))
        })
        .catch(() => {
          unAuth()
          setLoading(false)
          dispatch(cleanAuth())
        })
    }
  }, [refreshProfileBrief, locationPathName])

  useEffect(() => {
    if (authenticated && userPlanIsIdle) {
      dispatch(USER_PLAN_ACTIONS.fetchUserPlan())
    }
  }, [authenticated, userPlanIsIdle])

  return { authenticated, setAuthenticated, loading, logout, setAuthHeaders }
}

export default useProvideAuth
