import { Action } from 'redux'

import { FetchFailurePayload, FETCH_STATUSES } from '../../types/fetch'
import { EKYCAttemptsResponse, EKYCEnrollBody, EKYCEnrollResponse } from '../../types/ekyc'
import { FileForFormData } from '../../types/formData'

export interface EKYCState {
  ekycEnroll: {
    fetchStatus: FETCH_STATUSES
    data: EKYCEnrollResponse | null
    error: FetchFailurePayload | null
  }
  ekycConfirmation: {
    fetchStatus: FETCH_STATUSES
    isConfirmed: boolean
    error: FetchFailurePayload | null
  }
  ekycVerification: {
    fetchStatus: FETCH_STATUSES
    error: FetchFailurePayload | null
    isVerified: boolean
  }
  eKYCAttempts: {
    fetchStatus: FETCH_STATUSES
    error: FetchFailurePayload | null
    value: number | null
  }
}

export enum EKYCActions {
  FETCH_ENROLL = 'FETCH_ENROLL',
  FETCH_ENROLL_STATUS = 'FETCH_ENROLL_STATUS',
  FETCH_VERIFICATION = 'FETCH_VERIFICATION',
  CLEAR_ENROLL = 'CLEAR_ENROLL',
  CLEAR_CONFIRMATION = 'CLEAR_CONFIRMATION',
  CLEAR_VERIFICATION = 'CLEAR_VERIFICATION',
  CLEAR_EKYC = 'CLEAR_EKYC',
  CLEAR_ATTEMPTS = 'CLEAR_ATTEMPTS',
  FETCH_EKYC_VERIFY = 'FETCH_EKYC_VERIFY',
  FETCH_EKYC_ATTEMPTS = 'FETCH_EKYC_ATTEMPTS',
}

export interface FetchEnrollAction extends Action {
  payload: {
    body: EKYCEnrollBody
  }
}

export interface FetchEnrollSuccessAction extends Action {
  payload: EKYCEnrollResponse
}

export interface File {
  [key: string]: FileForFormData
}

export interface Headers {
  [key: string]: string
}

export interface FetchEKYCAttemptsSuccessAction extends Action {
  payload: EKYCAttemptsResponse
}
