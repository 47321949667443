import { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import translations from '../../../../translations/keys'
import { DrawableCompNames } from '../../../../types/drawableElem'
import {
  ownerViewContractIsCompletedSelector,
  ownerViewContractIsDeletedSelector,
} from '../../../../store/contracts/ownerView/ownerView.selectors'
import { ToolbarControl } from '../../../ui/DocViewerToolbar/DocViewerToolbar.types'
import { PointerModes } from '../../../ui/DocViewer/DocViewer.types'
import { CommentIcon, CrossoverIcon, DisplayIcon, EditIcon } from '../../../assets/icons'
import { toggleSignatureNamesVisible } from '../../../../store/contracts/viewOptions/viewOptions.actions'
import { viewOptionsIsSignatureNamesVisibleSelector } from '../../../../store/contracts/viewOptions/viewOptions.selectors'

export const useToolbarActions = () => {
  const dispatch = useDispatch()
  const isCompleted = useSelector(ownerViewContractIsCompletedSelector)
  const isDeleted = useSelector(ownerViewContractIsDeletedSelector)
  const isSignatureNamesVisible = useSelector(viewOptionsIsSignatureNamesVisibleSelector)

  // TODO: use real actions when ready
  const toolbarActions = useMemo(() => {
    const displayNames = {
      name: translations.DISPLAY_NAMES,
      Icon: DisplayIcon,
      selected: isSignatureNamesVisible,
      onClick: () => {
        dispatch(toggleSignatureNamesVisible())
      },
    }

    if (isCompleted || isDeleted) {
      return [displayNames]
    }

    const actions: ToolbarControl[] = [
      displayNames,
      {
        name: translations.COMMENT,
        Icon: CommentIcon,
        pointerMode: PointerModes.DRAW,
        drawingCompName: DrawableCompNames.COMMENT,
      },
      {
        name: translations.EDIT,
        Icon: EditIcon,
        pointerMode: PointerModes.DRAW,
        drawingCompName: DrawableCompNames.CORRECTION,
      },
      {
        name: translations.CROSSOVER,
        Icon: CrossoverIcon,
        pointerMode: PointerModes.DRAW,
        drawingCompName: DrawableCompNames.STRIKETHROUGH,
      },
    ]

    return actions
  }, [isCompleted, isDeleted, isSignatureNamesVisible])

  return toolbarActions
}
