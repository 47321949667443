import React from 'react'

import { SvgIconProps } from '../../ui/SvgIcon/SvgIcon.types'
import SvgIcon from '../../ui/SvgIcon'

export const LeftLogout: React.FC<SvgIconProps> = (props) => (
  <SvgIcon viewBox="0 0 41 40" {...props}>
    <g clipPath="url(#clip0)">
      <path d="M8.83333 18.333H22.1667V21.6663H8.83333V26.6663L0.5 19.9997L8.83333 13.333V18.333ZM7.16667 29.9997H11.68C13.6045 31.6969 15.9778 32.8028 18.5152 33.1846C21.0526 33.5664 23.6463 33.2079 25.985 32.1522C28.3237 31.0964 30.3081 29.3883 31.7001 27.2327C33.0921 25.0771 33.8325 22.5656 33.8325 19.9997C33.8325 17.4337 33.0921 14.9222 31.7001 12.7667C30.3081 10.6111 28.3237 8.90291 25.985 7.84717C23.6463 6.79144 21.0526 6.43297 18.5152 6.81478C15.9778 7.19658 13.6045 8.30245 11.68 9.99968H7.16667C8.71777 7.9283 10.7306 6.2472 13.0451 5.08992C15.3597 3.93263 17.9122 3.33107 20.5 3.33301C29.705 3.33301 37.1667 10.7947 37.1667 19.9997C37.1667 29.2047 29.705 36.6663 20.5 36.6663C17.9122 36.6683 15.3597 36.0667 13.0451 34.9094C10.7306 33.7522 8.71777 32.0711 7.16667 29.9997Z" />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="40" height="40" fill="white" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </SvgIcon>
)
