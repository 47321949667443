import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { addYears, startOfTomorrow } from 'date-fns'
import { useTranslation } from 'react-i18next'

import {
  AddRecipientsFields,
  RecipientAuthentication,
  RecipientAuthOptions,
  RecipientSignatureTypes,
  RecipientActions,
} from '../../../../types/recipients'
import { openModal } from '../../../../store/modals/modals.actions'
import translations from '../../../../translations/keys'
import { prepareArrayFieldName } from '../../../../utils/formik/prepareArrayFieldName'
import { getRecipientsActionsOptions } from '../../../utils/recipients'
import { ADD_RECIPIENTS_MODALS } from '../../../constants/addRecipients'
import { EMAIL_MAX_LENGTH } from '../../../../validation/emailValidationSchema'
import { NAME_MAX_LENGTH } from '../../../../validation/nameValidationSchema'
import { DEPARTMENT_MAX_LENGTH } from '../../../../validation/departmentValidationSchema'
import Box from '../../Box'
import FormDatePicker from '../../Forms/FormDatePicker'
import FormDropdown from '../../Forms/FormDropdown'
import FormNumber from '../../Forms/FormNumber'
import FormPhone from '../../Forms/FormPhone'
import FormRadioButton from '../../Forms/FormRadioButton'
import FormTextInput from '../../Forms/FormTextInput'
import FormCheckbox from '../FormCheckbox'
import Typography from '../../Typography'
import { TYPOGRAPHY_NAMES } from '../../Typography/Typography.types'
import { CrossIcon } from '../../../assets/icons'

import { StyledWrapper, StyledCross, StyledField } from './FormRecipient.styles'
import { FormRecipientProps } from './FormRecipient.types'

export const FormRecipient: React.FC<FormRecipientProps> = ({
  data,
  index,
  templatesMode = false,
  showRemoveButton,
  disableOrder,
  onRemove,
  setFieldValue,
  noDefaultDeadline = false,
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const isPhoneVisible = data[AddRecipientsFields.recipientAuthentication] === RecipientAuthentication.OTP
  const nameAndEmailDisabled = templatesMode && data[AddRecipientsFields.recipientNoEmailAndName]
  const deadline = data[AddRecipientsFields.recipientDeadline] as Date
  const maxDeadline = addYears(deadline || new Date(), 1)
  const isDeadlineEnabled = data[AddRecipientsFields.recipientAction] !== RecipientActions.VIEW

  const openContacts = () => {
    dispatch(openModal(ADD_RECIPIENTS_MODALS.ADD_RECIPIENTS_CONTACTS, { recipientIndex: index }))
  }

  useEffect(() => {
    if (templatesMode) {
      return
    } else if (!noDefaultDeadline && isDeadlineEnabled && !deadline) {
      setFieldValue(
        prepareArrayFieldName(AddRecipientsFields.recipients, AddRecipientsFields.recipientDeadline, index),
        startOfTomorrow()
      )
    } else if (!isDeadlineEnabled && deadline) {
      setFieldValue(
        prepareArrayFieldName(AddRecipientsFields.recipients, AddRecipientsFields.recipientDeadline, index),
        null
      )
    }
  }, [isDeadlineEnabled, deadline, setFieldValue, templatesMode, noDefaultDeadline])

  return (
    <StyledWrapper>
      {showRemoveButton && (
        <StyledCross onClick={() => onRemove(index)}>
          <CrossIcon color="grey" size="small" />
        </StyledCross>
      )}
      <Box display="flex" flexWrap="wrap">
        <StyledField>
          <FormTextInput
            icon="contact"
            iconColor="grey"
            disabled={nameAndEmailDisabled}
            name={prepareArrayFieldName(AddRecipientsFields.recipients, AddRecipientsFields.recipientName, index)}
            label={t(translations.RECIPIENT_NAME_LABEL)}
            placeholder={t(translations.RECIPIENT_NAME_PLACEHOLDER)}
            maxLength={NAME_MAX_LENGTH}
            onClickIcon={openContacts}
          />
        </StyledField>
        <StyledField>
          <FormTextInput
            icon="contact"
            iconColor="grey"
            disabled={nameAndEmailDisabled}
            name={prepareArrayFieldName(AddRecipientsFields.recipients, AddRecipientsFields.recipientEmail, index)}
            label={t(translations.RECIPIENT_EMAIL_LABEL)}
            placeholder={t(translations.RECIPIENT_EMAIL_PLACEHOLDER)}
            maxLength={EMAIL_MAX_LENGTH}
            onClickIcon={openContacts}
            trim
          />
        </StyledField>
        <StyledField>
          <FormDropdown
            name={prepareArrayFieldName(AddRecipientsFields.recipients, AddRecipientsFields.recipientAction, index)}
            label={t(translations.CHOOSE_ACTION)}
            options={getRecipientsActionsOptions}
            autoFill={false}
          />
        </StyledField>
        <StyledField display="flex">
          <Box mr={4} width="50%">
            <FormNumber
              disabled={disableOrder}
              name={prepareArrayFieldName(
                AddRecipientsFields.recipients,
                AddRecipientsFields.recipientCycleOrder,
                index
              )}
              label={t(translations.CYCLE_ORDER)}
              help={
                <Typography name={TYPOGRAPHY_NAMES.bodySmall12} color="textColor">
                  {t(translations.CYCLE_ORDER_HELP)}
                </Typography>
              }
            />
          </Box>
          <Box width="50%">
            {!templatesMode && (
              <FormDatePicker
                minDate={startOfTomorrow()}
                maxDate={maxDeadline}
                name={prepareArrayFieldName(
                  AddRecipientsFields.recipients,
                  AddRecipientsFields.recipientDeadline,
                  index
                )}
                label={t(translations.DEADLINE_SLA_LABEL)}
                placeholder={isDeadlineEnabled ? t(translations.DEADLINE_SLA_PLACEHOLDER) : undefined}
                help={
                  <Typography name={TYPOGRAPHY_NAMES.bodySmall12} color="textColor">
                    {t(translations.DEADLINE_SLA_HELP)}
                  </Typography>
                }
                disabled={!isDeadlineEnabled}
              />
            )}
          </Box>
        </StyledField>
      </Box>
      {templatesMode && (
        <Box display="flex" flexWrap="wrap">
          <Box mt={0} mb={3}>
            <FormCheckbox
              name={prepareArrayFieldName(
                AddRecipientsFields.recipients,
                AddRecipientsFields.recipientNoEmailAndName,
                index
              )}
              label={t(translations.RECIPIENTS_WITHOUT_EMAIL_AND_NAME)}
            />
          </Box>
        </Box>
      )}
      <Box display="flex" flexWrap="wrap">
        {data.action !== RecipientActions.VIEW && (
          <StyledField display="flex" alignItems="center" flexDirection="column">
            <Box width="100%" mb={2}>
              {data.action === RecipientActions.SIGN ? t(translations.SIGNATURE_FIELD) : t(translations.INITIALS_FIELD)}
            </Box>
            <Box width="100%" display="flex">
              <Box mr={4}>
                <FormRadioButton
                  name={prepareArrayFieldName(
                    AddRecipientsFields.recipients,
                    AddRecipientsFields.recipientSignatureType,
                    index
                  )}
                  label={t(translations.LAST_PAGE)}
                  id={RecipientSignatureTypes.LAST_PAGE}
                />
              </Box>
              <Box>
                <FormRadioButton
                  name={prepareArrayFieldName(
                    AddRecipientsFields.recipients,
                    AddRecipientsFields.recipientSignatureType,
                    index
                  )}
                  label={t(translations.EVERY_PAGE)}
                  id={RecipientSignatureTypes.EVERY_PAGE}
                />
              </Box>
            </Box>
          </StyledField>
        )}
        <StyledField>
          <FormTextInput
            name={prepareArrayFieldName(AddRecipientsFields.recipients, AddRecipientsFields.recipientDepartment, index)}
            label={`${t(translations.DEPARTMENT_LABEL)} (${t(translations.OPTIONAL)})`}
            placeholder={t(translations.DEPARTMENT_PLACEHOLDER)}
            maxLength={DEPARTMENT_MAX_LENGTH}
          />
        </StyledField>
        <StyledField>
          <FormDropdown
            autoFill={false}
            name={prepareArrayFieldName(
              AddRecipientsFields.recipients,
              AddRecipientsFields.recipientAuthentication,
              index
            )}
            label={t(translations.AUTHENTICATION)}
            options={RecipientAuthOptions}
          />
        </StyledField>
        {isPhoneVisible && (
          <StyledField>
            <FormPhone
              name={prepareArrayFieldName(AddRecipientsFields.recipients, AddRecipientsFields.recipientPhone, index)}
              label={t(translations.PHONE_LABEL)}
            />
          </StyledField>
        )}
      </Box>
    </StyledWrapper>
  )
}
