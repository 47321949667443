import { FetchFailureAction, FETCH_STATUSES } from '../../../types/fetch'
import { createReducer } from '../../createReducer'

import * as ACTIONS from './history.actions'
import * as TYPES from './history.types'

type Actions = FetchFailureAction | TYPES.FetchHistorySuccess

export const initialState: TYPES.HistoryState = {
  fetchStatus: FETCH_STATUSES.IDLE,
  error: null,
  data: [],
}

export const history = createReducer<TYPES.HistoryState, Actions>(initialState, {
  [ACTIONS.fetchHistoryTypes.request]: (state) => ({
    ...state,
    fetchStatus: FETCH_STATUSES.REQUEST,
  }),
  [ACTIONS.fetchHistoryTypes.success]: (state, action) => ({
    ...state,
    fetchStatus: FETCH_STATUSES.SUCCESS,
    data: (action as TYPES.FetchHistorySuccess).payload.history,
  }),
  [ACTIONS.fetchHistoryTypes.failure]: (state, action) => ({
    ...state,
    fetchStatus: FETCH_STATUSES.FAILURE,
    error: (action as FetchFailureAction).payload,
  }),
  [TYPES.HistoryActions.CLEAN_CONTRACT_HISTORY]: () => ({ ...initialState }),
})
