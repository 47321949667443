import React, { useState, FC, useEffect, useRef } from 'react'

import { PASSWORD_MAX_LENGTH } from '../../../validation/passwordValidationSchema'
import TextInput from '../TextInput'
import { TEXT_INPUT_TYPES } from '../TextInput/TextInput.types'

import { PasswordProps } from './Password.types'

export const Password: FC<PasswordProps> = (props) => {
  const textInputRef = useRef<HTMLInputElement>(null)
  const [visible, setVisible] = useState(false)
  const onClickIcon = () => {
    setVisible((prevVisible) => !prevVisible)
  }

  useEffect(() => {
    const disableCopyPaste: EventListener = (e) => {
      e.preventDefault()
    }

    const textInput = textInputRef.current

    if (!textInput) {
      return
    }

    textInput.addEventListener('paste', disableCopyPaste)
    textInput.addEventListener('copy', disableCopyPaste)
    textInput.addEventListener('cut', disableCopyPaste)
    return () => {
      textInput.removeEventListener('paste', disableCopyPaste)
      textInput.removeEventListener('copy', disableCopyPaste)
      textInput.removeEventListener('cut', disableCopyPaste)
    }
  }, [textInputRef])

  return (
    <TextInput
      {...props}
      icon={visible ? 'invisibleEye' : 'eye'}
      onClickIcon={onClickIcon}
      type={visible ? TEXT_INPUT_TYPES.TEXT : TEXT_INPUT_TYPES.PASSWORD}
      ref={textInputRef}
    />
  )
}

Password.defaultProps = {
  maxLength: PASSWORD_MAX_LENGTH,
}
