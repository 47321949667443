import { FormikValues } from 'formik'
import { ReactNode } from 'react'
import { ObjectSchema } from 'yup'

import { Sorting } from '../../../types/table'
import { TableAction } from '../TableActions/TableActions.types'

export enum TABLE_COLUMN_TYPES {
  TEXT = 'TEXT',
  PHONE = 'PHONE',
  EMAIL = 'EMAIL',
}

export type TableColumnNoHeader = 'laptop' | 'desktop'

export interface TableColumn {
  field: string
  name: string
  maxLength?: number
  type?: TABLE_COLUMN_TYPES
  widthPercent?: number
  widthPercentLaptop?: number
  sortable?: boolean
  searchable?: boolean
  render?: (column: TableData, highlightedText?: string) => React.ReactNode
  noHeader?: TableColumnNoHeader
}

export interface TableData {
  [key: string]: string | Omit<TableData, 'id'> | number | null
  id: string
}

export interface TableProps {
  columns: TableColumn[]
  data: TableData[]
  validationSchema?: ObjectSchema
  sorting: Sorting
  highlightedText?: string
  customActions?: TableAction[]
  onSort: (sorting: Sorting) => void
  onDelete?: (id: string) => void
  onUpdate?: (id: string, values: FormikValues) => void
  emptyResultsMessage: ReactNode
}

export type TableRowProps = Omit<TableProps, 'data' | 'sorting' | 'onSort' | 'onSubmit' | 'emptyResultsMessage'> & {
  data: TableData
  editMode: boolean
  withControls: boolean
  highlightedText?: string
  onEdit?: (id: string | null) => void
  onSubmit: (values: FormikValues) => void
}
