import { NEGATIVE_STATUSES } from '../../../constants/contracts'
import { CONTRACT_STATUS } from '../../../types/contracts'
import { FETCH_STATUSES } from '../../../types/fetch'
import { RootState } from '../../rootReducer.types'

export const ownerViewContractDocumentDataSelector = (state: RootState) => state.contracts.ownerView.contract.data
export const ownerViewContractFetchStatusSelector = (state: RootState) => state.contracts.ownerView.contract.fetchStatus
export const ownerViewContractErrorSelector = (state: RootState) => state.contracts.ownerView.contract.error
export const ownerViewContractSuccessSelector = (state: RootState) =>
  state.contracts.ownerView.contract.fetchStatus === FETCH_STATUSES.SUCCESS
export const ownerViewContractIsNegativeSelector = (state: RootState) => {
  const status = state.contracts.ownerView.contract.data?.status
  return status ? NEGATIVE_STATUSES.includes(status) : false
}
export const ownerViewContractIsLeftCorrectionSelector = (state: RootState) =>
  state.contracts.ownerView.contract.data?.status === CONTRACT_STATUS.LEFT_CORRECTION
export const ownerViewContractIsCompletedSelector = (state: RootState) =>
  state.contracts.ownerView.contract.data?.status === CONTRACT_STATUS.COMPLETED
export const ownerViewContractIsDeletedSelector = (state: RootState) =>
  state.contracts.ownerView.contract.data?.status === CONTRACT_STATUS.DELETED

export const ownerViewNotificationSelector = (state: RootState) => state.contracts.ownerView.notification
