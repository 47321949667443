import React from 'react'

import { SvgIconProps } from '../../ui/SvgIcon/SvgIcon.types'
import SvgIcon from '../../ui/SvgIcon'

export const KeyIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <path d="M14.251 2.625C10.3158 2.625 7.12595 5.81484 7.12595 9.75C7.12595 11.3977 7.68611 12.9141 8.62361 14.1211L7.66033 15.0844L6.20017 13.6242C6.16448 13.5893 6.11652 13.5697 6.06658 13.5697C6.01664 13.5697 5.96868 13.5893 5.93298 13.6242L5.00017 14.557C4.96524 14.5927 4.94568 14.6407 4.94568 14.6906C4.94568 14.7406 4.96524 14.7885 5.00017 14.8242L6.46033 16.2844L5.40798 17.3367L3.94783 15.8766C3.91213 15.8416 3.86418 15.8221 3.81423 15.8221C3.76429 15.8221 3.71634 15.8416 3.68064 15.8766L2.74783 16.8094C2.7129 16.8451 2.69334 16.893 2.69334 16.943C2.69334 16.9929 2.7129 17.0409 2.74783 17.0766L4.20798 18.5367L2.67752 20.0672C2.64262 20.1024 2.62305 20.15 2.62305 20.1996C2.62305 20.2492 2.64262 20.2968 2.67752 20.332L3.66892 21.3234C3.74158 21.3961 3.86111 21.3961 3.93377 21.3234L9.87752 15.3797C11.1287 16.3502 12.6675 16.8763 14.251 16.875C18.1861 16.875 21.376 13.6852 21.376 9.75C21.376 5.81484 18.1861 2.625 14.251 2.625ZM18.0291 13.5281C17.0213 14.5383 15.6783 15.0938 14.251 15.0938C12.8236 15.0938 11.4806 14.5383 10.4728 13.5281C9.46267 12.5203 8.9072 11.1773 8.9072 9.75C8.9072 8.32266 9.46267 6.97969 10.4728 5.97188C11.4806 4.96172 12.8236 4.40625 14.251 4.40625C15.6783 4.40625 17.0213 4.96172 18.0291 5.97188C19.0392 6.97969 19.5947 8.32266 19.5947 9.75C19.5947 11.1773 19.0392 12.5203 18.0291 13.5281Z" />
  </SvgIcon>
)
