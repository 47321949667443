import { combineEpics, Epic, ofType } from 'redux-observable'
import { catchError, map, mergeMap, takeUntil } from 'rxjs/operators'
import { merge, of } from 'rxjs'

import { catchFetchError } from '../../../utils/catchFetchError'
import { signingService } from '../../../api'
import { SessionStorageKeys } from '../../../constants/sessionStorage'
import { Variant } from '../../../types/notifications'
import SessionStorage from '../../../utils/SessionStorage'
import { signingRecipientTokenSelector } from '../signing/signing.selectors'

import * as ACTIONS from './otpVerification.actions'
import * as SELECTORS from './otpVerification.selectors'
import * as TYPES from './otpVerification.types'

const fetchVerifyOTP: Epic = (action$, state$) =>
  action$.pipe(
    ofType(ACTIONS.fetchVerifyOTPTypes.request),
    mergeMap(({ payload }) =>
      signingService
        .fetchVerifyOTP(
          SELECTORS.otpVerificationRecipientId(state$.value),
          payload.body,
          signingRecipientTokenSelector(state$.value)
        )
        .pipe(
          map(({ response }) => 
            // SessionStorage.set(SessionStorageKeys.VERIFICATION_TOKEN, response?.signingToken)
            // return ACTIONS.fetchVerifyOTPSuccess(response?.signingToken)
             ACTIONS.fetchVerifyOTPSuccess()
          ),
          catchError(catchFetchError(ACTIONS.fetchVerifyOTPFailure)),
          takeUntil(action$.pipe(ofType(ACTIONS.fetchVerifyOTPTypes.success, ACTIONS.fetchVerifyOTPTypes.failure)))
        )
    )
  )

const fetchSendCode: Epic = (action$, state$) =>
  action$.pipe(
    ofType(ACTIONS.fetchSendCodeTypes.request),
    mergeMap(({ payload }) =>
      signingService.fetchContract(payload.contractId, signingRecipientTokenSelector(state$.value)).pipe(
        mergeMap(({ recipient }) =>
          merge(
            of(ACTIONS.setPhoneNumber(recipient.phone || '')),
            signingService.fetchSendOTP(recipient.id, signingRecipientTokenSelector(state$.value)).pipe(
              map(() => ACTIONS.fetchSendCodeSuccess(recipient.id)),
              catchError(catchFetchError(ACTIONS.fetchSendCodeFailure)),
              takeUntil(action$.pipe(ofType(ACTIONS.fetchSendCodeTypes.success, ACTIONS.fetchSendCodeTypes.failure)))
            )
          )
        ),
        catchError(catchFetchError(ACTIONS.fetchSendCodeFailure)),
        takeUntil(action$.pipe(ofType(ACTIONS.fetchSendCodeTypes.success, ACTIONS.fetchSendCodeTypes.failure)))
      )
    )
  )

const fetchAttempts: Epic = (action$, state$) =>
  action$.pipe(
    ofType(ACTIONS.fetchAttemptsTypes.request),
    mergeMap(({ payload }) =>
      signingService.fetchAttempts(payload.contractId, signingRecipientTokenSelector(state$.value)).pipe(
        map((response) => ACTIONS.fetchAttemptsSuccess(response)),
        catchError(catchFetchError(ACTIONS.fetchAttemptsFailure)),
        takeUntil(action$.pipe(ofType(ACTIONS.fetchAttemptsTypes.success, ACTIONS.fetchAttemptsTypes.failure)))
      )
    )
  )

const fetchResendCode: Epic = (action$, state$) =>
  action$.pipe(
    ofType(ACTIONS.fetchResendCodeTypes.request),
    mergeMap(() =>
      signingService
        .fetchSendOTP(SELECTORS.otpVerificationRecipientId(state$.value), signingRecipientTokenSelector(state$.value))
        .pipe(
          mergeMap(() =>
            of(
              ACTIONS.setOTPVerificationNotification({
                message: TYPES.OTPVerificationNotificationMessages.NEW_CODE_IS_SENT,
                variant: Variant.SUCCESS,
              }),
              ACTIONS.fetchResendCodeSuccess()
            )
          ),
          catchError(catchFetchError(ACTIONS.fetchResendCodeFailure)),
          takeUntil(action$.pipe(ofType(ACTIONS.fetchResendCodeTypes.success, ACTIONS.fetchResendCodeTypes.failure)))
        )
    )
  )

export const otpVerificationEpics = combineEpics(fetchVerifyOTP, fetchSendCode, fetchAttempts, fetchResendCode)
