import React, { forwardRef } from 'react'

import Typography from '../../../Typography'
import { TYPOGRAPHY_NAMES } from '../../../Typography/Typography.types'

import { StyledTab, StyledErrorsCount } from './Tab.styles'
import { TabProps } from './Tab.types'

export const Tab = forwardRef<HTMLDivElement, TabProps>(
  ({ onClick, isActive, name, animating, errorsCount = 0 }, ref) => (
    <StyledTab ref={ref} isActive={isActive} onClick={onClick} animating={animating} data-testid="tab">
      <Typography name={TYPOGRAPHY_NAMES.bold14} data-testid={`tab-name${isActive ? '-active' : ''}`}>
        {name}
      </Typography>
      {errorsCount > 0 && <StyledErrorsCount data-testid="tab-errors-count">{errorsCount}</StyledErrorsCount>}
    </StyledTab>
  )
)
