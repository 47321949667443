import { getOrderBasedOnRecipients, mapRecipients, mapRecipientsFromForm } from '../../../utils/recipients'
import { FetchFailureAction, FETCH_STATUSES } from '../../../types/fetch'
import { SIGNING_TYPES } from '../../../types/signingOrder'
import { createReducer } from '../../createReducer'
import { deleteRecipientById } from '../../../utils/recipients/recipients'
import { ContractStatusTypes } from '../../../types/contracts'

import * as TYPES from './recipients.types'
import * as ACTIONS from './recipients.actions'

type Actions =
  | TYPES.SetContractIdAction
  | TYPES.SetOrderAction
  | TYPES.SetRecipientsAction
  | TYPES.ClearRecipientsAction
  | TYPES.FetchRecipientsSuccessAction
  | TYPES.FetchAddRecipientsSuccessAction
  | TYPES.FetchUpdateRecipientSuccessAction
  | TYPES.FetchDeleteRecipientSuccessAction
  | TYPES.FetchContractInfoSuccessAction
  | TYPES.FetchSenderInfoSuccessAction
  | TYPES.FetchResendSuccessAction
  | FetchFailureAction

export const initialState: TYPES.RecipientsState = {
  contractId: null,
  contractName: null,
  sender: null,
  order: SIGNING_TYPES.SEQUENTIAL,
  byId: {},
  byOrder: [],
  sorted: [],
  isNegativeStatus: false,
  fetch: {
    status: FETCH_STATUSES.IDLE,
    error: null,
  },
  add: {
    status: FETCH_STATUSES.IDLE,
    error: null,
  },
  update: {
    status: FETCH_STATUSES.IDLE,
    error: null,
  },
  delete: {
    status: FETCH_STATUSES.IDLE,
    error: null,
  },
  resend: {
    status: FETCH_STATUSES.IDLE,
    error: null,
  },
  messageInitialValues: null,
}

export const recipients = createReducer<TYPES.RecipientsState, Actions>(initialState, {
  [TYPES.RecipientsActions.SET_CONTRACT_ID]: (state, action) => ({
    ...state,
    contractId: (action as TYPES.SetContractIdAction).payload.id,
  }),
  [TYPES.RecipientsActions.SET_ORDER]: (state, action) => ({
    ...state,
    order: (action as TYPES.SetOrderAction).payload.order,
  }),
  [TYPES.RecipientsActions.SET_RECIPIENTS]: (state, action) => ({
    ...state,
    ...mapRecipientsFromForm((action as TYPES.SetRecipientsAction).payload.recipients),
  }),
  [TYPES.RecipientsActions.CLEAR_RECIPIENTS]: () => ({
    ...initialState,
  }),

  [ACTIONS.fetchRecipientsTypes.request]: (state) => ({
    ...state,
    fetch: {
      status: FETCH_STATUSES.REQUEST,
      error: null,
    },
  }),
  [ACTIONS.fetchRecipientsTypes.success]: (state, action) => {
    const { recipients, enabledFeatures } = (action as TYPES.FetchRecipientsSuccessAction).payload

    return {
      ...state,
      ...mapRecipients(recipients),
      order: getOrderBasedOnRecipients({ recipients, enabledFeatures, order: state.order }),
      isNegativeStatus: recipients.some((recipient) => recipient.status.type === ContractStatusTypes.NEGATIVE),
      fetch: {
        status: FETCH_STATUSES.SUCCESS,
        error: null,
      },
    }
  },
  [ACTIONS.fetchRecipientsTypes.failure]: (state, action) => ({
    ...state,
    fetch: {
      status: FETCH_STATUSES.FAILURE,
      error: (action as FetchFailureAction).payload,
    },
  }),

  [ACTIONS.fetchAddRecipientsTypes.request]: (state) => ({
    ...state,
    add: {
      status: FETCH_STATUSES.REQUEST,
      error: null,
    },
  }),
  [ACTIONS.fetchAddRecipientsTypes.success]: (state) => ({
    ...state,
    add: {
      status: FETCH_STATUSES.SUCCESS,
      error: null,
    },
  }),
  [ACTIONS.fetchAddRecipientsTypes.failure]: (state, action) => ({
    ...state,
    add: {
      status: FETCH_STATUSES.FAILURE,
      error: (action as FetchFailureAction).payload,
    },
  }),

  [ACTIONS.fetchUpdateRecipientTypes.request]: (state) => ({
    ...state,
    update: {
      status: FETCH_STATUSES.REQUEST,
      error: null,
    },
  }),
  [ACTIONS.fetchUpdateRecipientTypes.success]: (state) => ({
    ...state,
    update: {
      status: FETCH_STATUSES.SUCCESS,
      error: null,
    },
  }),
  [ACTIONS.fetchUpdateRecipientTypes.failure]: (state, action) => ({
    ...state,
    update: {
      status: FETCH_STATUSES.FAILURE,
      error: (action as FetchFailureAction).payload,
    },
  }),

  [ACTIONS.fetchDeleteRecipientTypes.request]: (state) => ({
    ...state,
    delete: {
      status: FETCH_STATUSES.REQUEST,
      error: null,
    },
  }),
  [ACTIONS.fetchDeleteRecipientTypes.success]: (state, action) => {
    const id = (action as TYPES.FetchDeleteRecipientSuccessAction).payload.id

    return {
      ...state,
      ...deleteRecipientById(id, { byId: state.byId, byOrder: state.byOrder, sorted: state.sorted }),
      delete: {
        status: FETCH_STATUSES.SUCCESS,
        error: null,
      },
    }
  },
  [ACTIONS.fetchDeleteRecipientTypes.failure]: (state, action) => ({
    ...state,
    delete: {
      status: FETCH_STATUSES.FAILURE,
      error: (action as FetchFailureAction).payload,
    },
  }),

  [ACTIONS.fetchContractInfoTypes.request]: (state) => ({
    ...state,
    fetchContractName: {
      status: FETCH_STATUSES.REQUEST,
      error: null,
    },
  }),
  [ACTIONS.fetchContractInfoTypes.success]: (state, action) => {
    const { name, messageInitialValues } = (action as TYPES.FetchContractInfoSuccessAction).payload

    return {
      ...state,
      contractName: name,
      messageInitialValues,
      fetchContractName: {
        status: FETCH_STATUSES.SUCCESS,
        error: null,
      },
    }
  },
  [ACTIONS.fetchContractInfoTypes.failure]: (state, action) => ({
    ...state,
    fetchContractName: {
      status: FETCH_STATUSES.FAILURE,
      error: (action as FetchFailureAction).payload,
    },
  }),

  [ACTIONS.fetchSenderInfoTypes.request]: (state) => ({
    ...state,
    fetchSender: {
      status: FETCH_STATUSES.REQUEST,
      error: null,
    },
  }),
  [ACTIONS.fetchSenderInfoTypes.success]: (state, action) => ({
    ...state,
    sender: (action as TYPES.FetchSenderInfoSuccessAction).payload,
    fetchSender: {
      status: FETCH_STATUSES.SUCCESS,
      error: null,
    },
  }),
  [ACTIONS.fetchSenderInfoTypes.failure]: (state, action) => ({
    ...state,
    fetchSender: {
      status: FETCH_STATUSES.FAILURE,
      error: (action as FetchFailureAction).payload,
    },
  }),

  [ACTIONS.fetchResendTypes.request]: (state) => ({
    ...state,
    resend: {
      status: FETCH_STATUSES.REQUEST,
      error: null,
    },
  }),
  [ACTIONS.fetchResendTypes.success]: (state) => ({
    ...state,
    resend: {
      status: FETCH_STATUSES.SUCCESS,
      error: null,
    },
  }),
  [ACTIONS.fetchResendTypes.failure]: (state, action) => ({
    ...state,
    resend: {
      status: FETCH_STATUSES.FAILURE,
      error: (action as FetchFailureAction).payload,
    },
  }),
})
