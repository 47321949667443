import { Action } from 'redux'

import { FetchFailurePayload, FETCH_STATUSES } from '../../../types/fetch'

export interface MoveToFolderState {
  fetchStatus: FETCH_STATUSES
  error: FetchFailurePayload | null
}

export enum MoveToFolderActions {
  'MOVE_TEMPLATE_TO_FOLDER' = 'MOVE_TEMPLATE_TO_FOLDER',
  'CLEAR_MOVE_TEMPLATE_TO_FOLDER' = 'CLEAR_MOVE_TEMPLATE_TO_FOLDER',
}

export interface FetchMoveTemplateToFolder extends Action {
  payload: { id: string; folderId: string }
}

export type FetchMoveTemplateToFolderSuccess = Action

export type ClearMoveTemplateToFolder = Action
