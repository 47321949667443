import { FetchFailureAction, FETCH_STATUSES } from '../../../types/fetch'
import { createReducer } from '../../createReducer'
import { TemplatesMessages } from '../../../types/templates'
import { fetchDeleteTemplateTypes } from '../delete/delete.actions'
import { FetchDeleteTemplateSuccess } from '../delete/delete.types'
import { fetchRenameTemplateTypes } from '../rename/rename.actions'
import { fetchMoveTemplateToFolderTypes } from '../moveToFolder/moveToFolder.actions'
import { fetchDuplicateTemplateTypes } from '../duplicate/duplicate.actions'
import { Variant } from '../../../types/notifications'
import { FetchRenameTemplateSuccess } from '../rename/rename.types'
import { fetchTemplatesUploadFolderTypes } from '../folders/folders.actions'

import * as TYPES from './list.types'
import * as ACTIONS from './list.actions'

type Actions =
  | FetchFailureAction
  | TYPES.FetchListSuccess
  | TYPES.ListSetSorting
  | TYPES.ListSetOffset
  | TYPES.ListSetSearchText
  | FetchDeleteTemplateSuccess
  | FetchRenameTemplateSuccess
  | TYPES.TemplatesListGoToFolder

const SORTING = { field: 'name', direction: 'ASC' as const }
const LIMIT = 20

export const initialState: TYPES.ListState = {
  data: [],
  sorting: SORTING,
  searchText: '',
  offset: 0,
  total: 0,
  limit: LIMIT,
  isFirstLoad: true,
  loading: false,
  fetchStatus: FETCH_STATUSES.IDLE,
  error: null,
  loadMore: false,
  notification: null,
  forceUpdate: 0,
  breadcrumbs: [
    {
      name: 'All templates',
      value: '',
    },
  ],
  folderId: '',
  isEmptyFolder: false,
  isEmptyContracts: true,
}

export const list = createReducer<TYPES.ListState, Actions>(initialState, {
  [ACTIONS.fetchTemplatesListDataTypes.request]: (state) => ({
    ...state,
    fetchStatus: FETCH_STATUSES.REQUEST,
  }),
  [ACTIONS.fetchTemplatesListDataTypes.success]: (state, action) => {
    const { data, offset, totalCount } = (action as TYPES.FetchListSuccess).payload.body
    const newData = state.loadMore && state.offset !== initialState.offset ? [...state.data, ...data] : data
    const noData = !newData.length

    return {
      ...state,
      data: newData,
      loading: false,
      offset,
      total: totalCount,
      fetchStatus: FETCH_STATUSES.SUCCESS,
      error: null,
      isFirstLoad: (state.isFirstLoad || (!state.searchText && !state.folderId)) && noData,
      isEmptyFolder: !state.searchText && !!state.folderId && noData,
      isEmptyContracts: (!!state.searchText || !state.folderId) && noData,
    }
  },
  [ACTIONS.fetchTemplatesListDataTypes.failure]: (state, action) => ({
    ...state,
    fetchStatus: FETCH_STATUSES.FAILURE,
    error: (action as FetchFailureAction).payload,
    isFirstLoad: false,
  }),
  [TYPES.ListActions.TEMPLATES_LIST_SET_SORTING]: (state, action) => ({
    ...state,
    sorting: (action as TYPES.ListSetSorting).payload,
    offset: state.loadMore ? initialState.offset : state.offset,
  }),
  [TYPES.ListActions.CLEAN_TEMPLATES]: () => ({ ...initialState }),
  [TYPES.ListActions.TEMPLATES_LIST_SET_OFFSET]: (state, action) => {
    const act = action as TYPES.ListSetOffset
    return {
      ...state,
      offset: act.payload.offset,
      loadMore: act.payload.loadMore,
    }
  },
  [TYPES.ListActions.TEMPLATES_LIST_SET_SEARCH_TEXT]: (state, action) => ({
    ...state,
    offset: initialState.offset,
    searchText: (action as TYPES.ListSetSearchText).payload.searchText,
  }),
  [TYPES.ListActions.TEMPLATES_LIST_CLEAN_NOTIFICATION]: (state) => ({
    ...state,
    notification: initialState.notification,
  }),
  [TYPES.ListActions.TEMPLATES_LIST_FORCE_UPDATE]: (state) => ({
    ...state,
    forceUpdate: state.forceUpdate + 1,
    isFirstLoad: initialState.isFirstLoad,
  }),
  [TYPES.ListActions.CLEAN_TEMPLATES]: () => ({ ...initialState }),
  [fetchDeleteTemplateTypes.success]: (state, action) => ({
    ...state,
    notification: (action as FetchDeleteTemplateSuccess).payload.notification,
  }),
  [fetchRenameTemplateTypes.success]: (state, action) => {
    const payload = (action as FetchRenameTemplateSuccess).payload
    const data = state.data.map((item) => (item.id === payload.id ? { ...item, name: payload.name } : item))

    return {
      ...state,
      data,
      notification: {
        ...state.notification,
        message: TemplatesMessages.NAME_CHANGED,
        variant: Variant.SUCCESS,
      },
    }
  },
  [fetchMoveTemplateToFolderTypes.success]: (state) => ({
    ...state,
    notification: {
      ...state.notification,
      message: TemplatesMessages.TEMPLATE_MOVED_TO_FOLDER,
      variant: Variant.SUCCESS,
    },
  }),
  [fetchDuplicateTemplateTypes.success]: (state) => ({
    ...state,
    notification: {
      ...state.notification,
      message: TemplatesMessages.TEMPLATE_DUPLICATED,
      variant: Variant.SUCCESS,
    },
  }),
  [fetchTemplatesUploadFolderTypes.success]: (state) => ({
    ...state,
    successMessage: TemplatesMessages.FOLDER_UPLOADED,
  }),
  [TYPES.ListActions.TEMPLATES_LIST_GO_TO_FOLDER]: (state, action) => {
    const breadcrumb = (action as TYPES.TemplatesListGoToFolder).payload.breadcrumb
    const rootFolderId = state.breadcrumbs[0].value

    return {
      ...state,
      breadcrumbs:
        rootFolderId === breadcrumb.value ? [...initialState.breadcrumbs] : [...state.breadcrumbs, breadcrumb],
      folderId: breadcrumb.value,
      fetchStatus: FETCH_STATUSES.REQUEST,
      offset: initialState.offset,
    }
  },
})
