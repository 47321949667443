import { combineEpics, Epic, ofType } from 'redux-observable'
import { catchError, map, mergeMap, takeUntil } from 'rxjs/operators'

import { signingService } from '../../../api'
import { catchFetchError } from '../../../utils/catchFetchError'

import * as ACTIONS from './clearAndContinue.actions'

export const fetchClearAndContinueContract: Epic = (action$) =>
  action$.pipe(
    ofType(ACTIONS.fetchClearAndContinueContractTypes.request),
    mergeMap(({ payload }) =>
      signingService.fetchClearAndContinueContract(payload.contractId).pipe(
        map(() => ACTIONS.fetchClearAndContinueContractSuccess(payload.contractId)),
        catchError(catchFetchError(ACTIONS.fetchClearAndContinueContractFailure)),
        takeUntil(
          action$.pipe(
            ofType(
              ACTIONS.fetchClearAndContinueContractTypes.success,
              ACTIONS.fetchClearAndContinueContractTypes.failure
            )
          )
        )
      )
    )
  )

export const clearAndContinueEpics = combineEpics(fetchClearAndContinueContract)
