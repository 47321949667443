import React from 'react'
import { Route, RouteProps, Redirect } from 'react-router-dom'

import { location } from '../../../../utils/location'
import useAuth from '../../../../hooks/useAuth'
import { useLogoutByParams } from '../../../hooks/useLogoutByParams'

interface UnauthorizedRouteProps extends RouteProps {
  logoutQueryParams?: string[]
}

export const UnauthorizedRoute: React.FC<UnauthorizedRouteProps> = ({ children, logoutQueryParams, ...props }) => {
  const { authenticated, loading } = useAuth()
  const { checkingParams } = useLogoutByParams(logoutQueryParams)

  if (loading || checkingParams) {
    return null
  }

  return <Route {...props} render={() => (authenticated ? <Redirect to={location.editProfileUrl()} /> : children)} />
}
