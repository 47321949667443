import React from 'react'

export const FileSvg: React.FC = (props) => (
  <svg width="40" height="41" viewBox="0 0 40 41" fill="none" {...props}>
    <path
      d="M33.3828 11.7734L24.9766 3.36719C24.7422 3.13281 24.4258 3 24.0938 3H7.5C6.80859 3 6.25 3.55859 6.25 4.25V36.75C6.25 37.4414 6.80859 38 7.5 38H32.5C33.1914 38 33.75 37.4414 33.75 36.75V12.6602C33.75 12.3281 33.6172 12.0078 33.3828 11.7734ZM30.8672 13.2344H23.5156V5.88281L30.8672 13.2344ZM30.9375 35.1875H9.0625V5.8125H20.8594V14.25C20.8594 14.6851 21.0322 15.1024 21.3399 15.4101C21.6476 15.7178 22.0649 15.8906 22.5 15.8906H30.9375V35.1875Z"
      fill="#6085B3"
    />
  </svg>
)
