import { FetchFailureAction, FETCH_STATUSES } from '../../types/fetch'
import { createReducer } from '../createReducer'

import * as ACTIONS from './restorePassword.actions'
import * as TYPES from './restorePassword.types'

type Actions = FetchFailureAction

const initialState: TYPES.RestorePasswordState = {
  fetchStatus: FETCH_STATUSES.IDLE,
}

export const restorePassword = createReducer<TYPES.RestorePasswordState, Actions>(initialState, {
  [ACTIONS.fetchRestorePasswordTypes.request]: (state) => ({
    ...state,
    fetchStatus: FETCH_STATUSES.REQUEST,
  }),
  [ACTIONS.fetchRestorePasswordTypes.success]: (state) => ({
    ...state,
    fetchStatus: FETCH_STATUSES.SUCCESS,
  }),
  [ACTIONS.fetchRestorePasswordTypes.failure]: (state, action) => ({
    ...state,
    fetchStatus: FETCH_STATUSES.FAILURE,
    error: (action as FetchFailureAction).payload,
  }),
  [TYPES.RestorePasswordActions.RESET_RESTORE_PASSWORD]: () => ({ ...initialState }),
})
