import styled from 'styled-components'

import Box from '../../../../ui/Box'

export const StyledPlan = styled(Box).attrs({
  pt: 4,
  pl: 4,
  pr: 4,
  pb: 4,
  display: 'flex',
  flexDirection: 'column',
})<{ expired?: boolean }>`
  border: 1px solid ${({ theme, expired }) => (expired ? theme.palette.errorColor : theme.palette.strokeGrey)};
  border-radius: 4px;
  box-shadow: ${({ theme }) => theme.shadow};

  ${({ theme }) => `@media ${theme.devices.mobile} {
    padding: 0;
    border: 0;
    border-radius: 0;
    box-shadow: none;
  }`}
`

export const StyledMark = styled(Box).attrs({
  mr: 1,
  width: 8,
  height: 8,
})`
  background-color: ${({ theme }) => theme.palette.blue};
  border-radius: 50%;
`

export const StyledFeatures = styled(Box).attrs({
  mt: 3,
  pb: 1,
  display: 'flex',
  flexWrap: 'wrap',
})`
  border-bottom: 1px solid ${({ theme }) => theme.palette.strokeGrey};
`

export const StyledButtons = styled(Box).attrs({
  display: 'flex',
  mt: 4,
})`
  ${({ theme }) => `@media ${theme.devices.mobile} {
    flex-direction: column;
  }`}
`

export const StyledUnsubscribe = styled(Box).attrs({
  width: 208,
  ml: 2,
  mobileWidth: '100%',
})`
  ${({ theme }) => `@media ${theme.devices.mobile} {
    margin-left: 0;
    margin-top: 16px;
  }`}
`
