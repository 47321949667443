import React from 'react'

import MainTemplate from '../../components/Main/MainTemplate'

import { Templates as TemplatesPage } from './Templates'
import { TemplatesProps } from './Templates.types'

export const Templates: React.FC<TemplatesProps> = (props) => (
  <MainTemplate>
    <TemplatesPage {...props} />
  </MainTemplate>
)
