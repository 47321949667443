import { FieldArray } from 'formik'
import React from 'react'

import Box from '../../Box'
import { FormCheckbox } from '../FormCheckbox/FormCheckbox'

import { FormCheckboxesProps } from './FormCheckboxes.types'

export const FormCheckboxes: React.FC<FormCheckboxesProps> = ({ name, options, values, itemStyle }) => (
  <FieldArray
    name={name}
    render={({ push, remove }) => {
      const onChange = (value: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.checked) {
          push(value)
        } else {
          const idx = values.indexOf(value)
          remove(idx)
        }
      }

      return (
        <>
          {options.map(({ value, name }) => (
            <Box key={value} {...itemStyle}>
              <FormCheckbox name={value} label={name} checked={values.includes(value)} onChange={onChange(value)} />
            </Box>
          ))}
        </>
      )
    }}
  />
)
