import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import * as FOLDERS_SELECTORS from '../../../../../store/templates/folders/folders.selectors'
import { useShowFetchError } from '../../../../hooks/useShowFetchError'
import { DocumentMoveToFolderForm } from '../../../../../types/documentMoveToFolder'
import { modalsPayloadSelector } from '../../../../../store/modals/modals.selectors'
import * as MOVE_TO_FOLDER_SELECTORS from '../../../../../store/templates/moveToFolder/moveToFolder.selectors'
import {
  clearMoveTemplateToFolder,
  fetchMoveTemplateToFolder,
} from '../../../../../store/templates/moveToFolder/moveToFolder.actions'
import { useDispatchUnmount } from '../../../../../hooks/useDispatchUnmount'
import MoveDocumentToFolderModal from '../../DocumentModals/MoveDocumentToFolderModal'
import { TEMPLATES_MODALS } from '../../../../../constants/templates'
import { useTemplatesFoldersFetch } from '../../../../../hooks/templates/useTemplatesFoldersFetch'

import { MoveTemplateToFolderModalProps } from './MoveTemplateToFolderModal.types'

export const MoveTemplateToFolderModal: React.FC<MoveTemplateToFolderModalProps> = ({ onClose }) => {
  const dispatch = useDispatch()
  const folders = useSelector(FOLDERS_SELECTORS.templatesFoldersDataSelector)
  const foldersError = useSelector(FOLDERS_SELECTORS.templatesFoldersDataErrorSelector)
  const foldersFetchStatus = useSelector(FOLDERS_SELECTORS.templatesFoldersDataFetchStatusSelector)
  const foldersIsLoading = useSelector(FOLDERS_SELECTORS.templatesFoldersIsLoadingSelector)
  const movedSuccessfully = useSelector(MOVE_TO_FOLDER_SELECTORS.templatesMoveToFolderSuccessSelector)
  const moveFetchStatus = useSelector(MOVE_TO_FOLDER_SELECTORS.templatesMoveToFolderFetchStatusSelector)
  const moveError = useSelector(MOVE_TO_FOLDER_SELECTORS.templatesMoveToFolderErrorSelector)
  const { id } = useSelector(modalsPayloadSelector(TEMPLATES_MODALS.MOVE_TEMPLATE_TO_FOLDER))

  useEffect(() => {
    if (movedSuccessfully) {
      onClose()
    }
  }, [movedSuccessfully])
  useTemplatesFoldersFetch()
  useShowFetchError(foldersFetchStatus, foldersError)
  useShowFetchError(moveFetchStatus, moveError)
  useDispatchUnmount(clearMoveTemplateToFolder)

  const onSubmit = (values: DocumentMoveToFolderForm) => {
    dispatch(fetchMoveTemplateToFolder(id, values.folder))
  }

  return (
    <MoveDocumentToFolderModal onClose={onClose} folders={folders} loading={foldersIsLoading} onSubmit={onSubmit} />
  )
}
