import styled from 'styled-components'

import Box from '../../Box'

export const Wrapper = styled(Box).attrs({
  height: 40,
  width: '100%',
  mt: -2.5,
  mb: 0.5,
})`
  position: relative;
`
export const Line = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.palette.grey};
  position: absolute;
  top: 50%;
  margin-top: -1px;
`
export const Arrow = styled.div`
  width: 1px;
  height: 100%;
  background-color: ${({ theme }) => theme.palette.grey};
  position: absolute;
  left: 50%;
  transform: translateX(-50%);

  &::before,
  &::after {
    content: '';
    width: 1px;
    height: 5px;
    position: absolute;
    background-color: inherit;
    top: 100%;
  }
  &::before {
    margin-top: -5px;
    margin-left: -2px;
    transform: rotate(-45deg);
  }
  &::after {
    margin-top: -5px;
    left: 100%;
    margin-left: 1px;
    transform: rotate(45deg);
  }
`
