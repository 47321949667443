import { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import translations from '../../../../translations/keys'
import { DrawableCompNames } from '../../../../types/drawableElem'
import { fontSizeOptions } from '../../../../types/documentField'
import { PLACEMENT_MODALS } from '../../../constants/placement'
import { placementGenerateIsSuccess } from '../../../../store/templates/placement/placement.selectors'
import { fieldsFontSizeSelector } from '../../../../store/templates/fields/fields.selectors'
import { openModal } from '../../../../store/modals/modals.actions'
import { ToolbarControls } from '../../../ui/DocViewerToolbar/DocViewerToolbar.types'
import { PointerModes } from '../../../ui/DocViewer/DocViewer.types'
import { CalendarIcon, ReloadIcon, TextIcon } from '../../../assets/icons'
import { viewOptionsIsSignatureNamesVisibleSelector } from '../../../../store/templates/viewOptions/viewOptions.selectors'

export const useToolbarActions = () => {
  const dispatch = useDispatch()
  const resetDisabled = useSelector(placementGenerateIsSuccess)
  const fontSize = useSelector(fieldsFontSizeSelector)
  const isSignatureNamesVisible = useSelector(viewOptionsIsSignatureNamesVisibleSelector)

  const toolbarActions = useMemo(() => {
    const actions: ToolbarControls = [
      // TODO UQD-1410 uncomment when this will work
      // {
      //   name: translations.GRID,
      //   disabled: true,
      //   Icon: GridIcon,
      //   onClick: () => {
      //     console.log('Grid')
      //   },
      // },
      // {
      //   name: translations.GROUP_EDIT,
      //   disabled: true,
      //   Icon: ResizeIcon,
      //   onClick: () => {
      //     console.log('Group edit')
      //   },
      // },
      // {
      //   name: translations.APPLY_TO_ALL_PAGES,
      //   disabled: true,
      //   Icon: CheckCircleIcon,
      //   onClick: () => {
      //     console.log('Apply to all pages')
      //   },
      // },
      {
        name: translations.RESET,
        disabled: resetDisabled,
        Icon: ReloadIcon,
        onClick: () => {
          dispatch(openModal(PLACEMENT_MODALS.PLACEMENT_RESET))
        },
      },
      {
        name: translations.TEXT_FIELD,
        Icon: TextIcon,
        pointerMode: PointerModes.DRAW,
        drawingCompName: DrawableCompNames.TEXT,
      },
      {
        name: translations.DATE_FIELD,
        Icon: CalendarIcon,
        pointerMode: PointerModes.DRAW,
        drawingCompName: DrawableCompNames.DATE,
      },
      {
        name: translations.SIZE,
        value: fontSize,
        options: fontSizeOptions,
      },
      // {
      //   name: translations.UNDO,
      //   disabled: true,
      //   Icon: BackwardIcon,
      //   onClick: () => {
      //     console.log('Undo')
      //   },
      // },
      // {
      //   name: translations.REDO,
      //   disabled: true,
      //   Icon: ForwardIcon,
      //   onClick: () => {
      //     console.log('Redo')
      //   },
      // },
    ]

    return actions
  }, [resetDisabled, fontSize, isSignatureNamesVisible])

  return toolbarActions
}
