import { Action } from 'redux'

import { FetchFailurePayload, FETCH_STATUSES } from '../../../types/fetch'
import { Notification } from '../../../types/notifications'
import { OTPVerificationAttemptsResponse, OTPVerificationRequestBody } from '../../../types/otpVerification'

export enum OTPVerificationNotificationMessages {
  'NONE' = '',
  'RECIPIENT_ATTEMPTS_FINISHED' = 'RECIPIENT_ATTEMPTS_FINISHED',
  'LAST_ATTEMPT' = 'LAST_ATTEMPT',
  'NEW_CODE_IS_SENT' = 'NEW_CODE_IS_SENT',
}

export interface OTPVerificationState {
  phoneNumber: string
  verify: {
    fetchStatus: FETCH_STATUSES
    error: FetchFailurePayload | null
    token: string | null
  }
  sendCode: {
    fetchStatus: FETCH_STATUSES
    error: FetchFailurePayload | null
  }
  recipientId: string
  notification: Notification | null
  attempts: {
    fetchStatus: FETCH_STATUSES
    error: FetchFailurePayload | null
    count: number | null
  }
  resendCode: {
    fetchStatus: FETCH_STATUSES
    error: FetchFailurePayload | null
  }
}

export enum OTPVerificationActions {
  'CLEAN_OTP_VERIFICATION' = 'CLEAN_OTP_VERIFICATION',
  'FETCH_VERIFY_OTP' = 'FETCH_VERIFY_OTP',
  'FETCH_SEND_OTP_VERIFICATION_CODE' = 'FETCH_SEND_OTP_VERIFICATION_CODE',
  'SET_OTP_VERIFICATION_NOTIFICATION' = 'SET_OTP_VERIFICATION_NOTIFICATION',
  'CLEAN_OTP_VERIFICATION_NOTIFICATION' = 'CLEAN_OTP_VERIFICATION_NOTIFICATION',
  'FETCH_OTP_VERIFICATION_ATTEMPTS' = 'FETCH_OTP_VERIFICATION_ATTEMPTS',
  'FETCH_RESEND_CODE' = 'FETCH_RESEND_CODE',
  'SET_PHONE_NUMBER' = 'SET_PHONE_NUMBER',
}

export type FetchOTPVerificationPhoneNumberAction = Action
export interface FetchOTPVerificationPhoneNumberSuccessAction extends Action {
  payload: { phoneNumber: string }
}

export interface FetchVerifyOTPAction extends Action {
  payload: { body: OTPVerificationRequestBody }
}
// export interface FetchVerifyOTPSuccessAction extends Action {
//   payload: { token: string }
// }
export interface FetchVerifyOTPSuccessAction extends Action {
  payload: any
}

export interface FetchSendCode extends Action {
  payload: { contractId: string }
}
export interface FetchSendCodeSuccess extends Action {
  payload: { recipientId: string }
}

export interface SetNotificationAction extends Action {
  payload: Notification
}

export interface FetchOTPVerificationAttempts extends Action {
  payload: { contractId: string }
}
export interface FetchOTPVerificationAttemptsSuccess extends Action {
  payload: { data: OTPVerificationAttemptsResponse }
}

export interface SetPhoneNumber extends Action {
  payload: { phoneNumber: string }
}
