import { Epic, combineEpics, ofType } from 'redux-observable'
import { catchError, map, mergeMap, takeUntil, tap } from 'rxjs/operators'

import { saveTokensToAsyncStorage } from '../../native/utils/NativeAsyncStorage'
import { catchFetchError } from '../../utils/catchFetchError'
import { authService, profileService } from '../../api'
import { registerFirebaseToken } from '../../native/utils/pushNotificationsFirebaseToken'
import SessionStorage from '../../utils/SessionStorage'
import { SessionStorageKeys } from '../../constants/sessionStorage'

import * as AUTH_ACTIONS from './auth.actions'

const fetchLogin: Epic = (action$) =>
  action$.pipe(
    ofType(AUTH_ACTIONS.fetchLoginTypes.request),
    tap(async () => registerFirebaseToken()),
    mergeMap(({ payload }) => {
      const fbToken = SessionStorage.get(SessionStorageKeys.FIREBASE_TOKEN)

      if (payload.isMobile && fbToken) {
        return authService.fetchLogin(payload.body).pipe(
          tap(({ response }) => saveTokensToAsyncStorage(response)),
          mergeMap(() => profileService.fetchSaveFirebaseToken({ deviceId: fbToken })),
          map(({ response }) => AUTH_ACTIONS.fetchLoginSuccess(response)),
          catchError(catchFetchError(AUTH_ACTIONS.fetchLoginFailure)),
          takeUntil(action$.pipe(ofType(AUTH_ACTIONS.fetchLoginTypes.success, AUTH_ACTIONS.fetchLoginTypes.failure)))
        )
      }

      return authService.fetchLogin(payload.body).pipe(
        map(({ response }) => {
          saveTokensToAsyncStorage(response)
          return AUTH_ACTIONS.fetchLoginSuccess(response)
        }),
        catchError(catchFetchError(AUTH_ACTIONS.fetchLoginFailure)),
        takeUntil(action$.pipe(ofType(AUTH_ACTIONS.fetchLoginTypes.success, AUTH_ACTIONS.fetchLoginTypes.failure)))
      )
    })
  )

const fetchResendEmail: Epic = (action$) =>
  action$.pipe(
    ofType(AUTH_ACTIONS.fetchResendEmailTypes.request),
    mergeMap(({ payload }) =>
      authService.fetchResendEmail(payload.email).pipe(
        map(() => AUTH_ACTIONS.fetchResendEmailSuccess()),
        catchError(catchFetchError(AUTH_ACTIONS.fetchResendEmailFailure)),
        takeUntil(
          action$.pipe(ofType(AUTH_ACTIONS.fetchResendEmailTypes.success, AUTH_ACTIONS.fetchResendEmailTypes.failure))
        )
      )
    )
  )

export const authEpics = combineEpics(fetchLogin, fetchResendEmail)
