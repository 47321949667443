import { AddRecipientsFields } from '../types/recipients'

export const MAX_SIGNATURES_PER_PAGE = 4
export const MAX_INITIALS_PER_PAGE = 10

export enum RECIPIENTS_MODALS {
  EDIT_RECIPIENT = 'EDIT_RECIPIENT',
  CONFIRM_UPDATE_RECIPIENT = 'CONFIRM_UPDATE_RECIPIENT',
  CANCEL_RECIPIENT_CHANGES = 'CANCEL_RECIPIENT_CHANGES',
  DELETE_RECIPIENT = 'DELETE_RECIPIENT',
  ADD_RECIPIENTS_CONTACTS = 'ADD_RECIPIENTS_CONTACTS',
  RECIPIENT_INFO = 'RECIPIENT_INFO',
}

export const EDIT_RECIPIENT_DISABLED_FIELDS = {
  [AddRecipientsFields.recipientAction]: true,
  [AddRecipientsFields.recipientSignatureType]: true,
  [AddRecipientsFields.recipientAuthentication]: true,
}
