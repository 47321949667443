import styled, { css } from 'styled-components'

export const MOBILE_ACTIONS_HIEGHT = '84px'
const disabledStyles = css`
  opacity: 0.4;
  pointer-events: none;
`
const selectedStyles = css`
  color: ${({ theme }) => theme.palette.white};
  background-color: ${({ theme }) => theme.palette.blue} !important;
`
const noPointerEventsStyles = css`
  & * {
    pointer-events: none;
  }
`

export const Nav = styled.nav`
  display: none;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: ${({ theme }) => theme.palette.white};
  z-index: 99;
  height: ${MOBILE_ACTIONS_HIEGHT};
  ${({ theme }) => `@media ${theme.devices.tablet} {
    display: block;
  }`}
`

export const List = styled.ul`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  list-style: none;
  display: flex;
  justify-content: space-evenly;
`
export const ListItem = styled.li`
  width: 100%;
`
export const IconWrapper = styled.span`
  display: inline-flex;
  width: 48px;
  height: 48px;
  background: ${({ theme }) => theme.palette.blueDark};
  border-radius: 50%;
  align-items: center;
  justify-content: center;
`
export const Button = styled.div<{
  disabled?: boolean
  selected?: boolean
  noPointerEvents?: boolean
}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8px;
  color: ${({ theme }) => theme.palette.black};
  ${({ theme }) => theme.textStyles.bodySmall12}
  font-weight: 400;
  width: 100%;
  ${({ disabled }) => disabled && disabledStyles}
  ${({ selected }) => selected && selectedStyles}
  ${({ noPointerEvents }) => noPointerEvents && noPointerEventsStyles}
`
