import React, { useEffect, useRef } from 'react'
import { FormikProps } from 'formik'
import { useDispatch, useSelector } from 'react-redux'

import { useContractsFoldersFetch } from '../../../../../hooks/contracts/useContractsFoldersFetch'
import {
  foldersDataSelector,
  foldersDataErrorSelector,
  foldersDataFetchStatusSelector,
  foldersIsLoadingSelector,
} from '../../../../../store/contracts/folders/folders.selectors'
import { useShowFetchError } from '../../../../hooks/useShowFetchError'
import { DuplicateDocumentFields, DuplicateDocumentForm } from '../../../../../types/duplicateDocument'
import { modalsPayloadSelector } from '../../../../../store/modals/modals.selectors'
import { CONTRACTS_MODALS, CONTRACT_EXISTS } from '../../../../../constants/contracts'
import { DOCUMENT_WITH_NAME_EXIST } from '../../../../../constants/documents'
import {
  contractsListDuplicateErrorSelector,
  contractsListDuplicateFetchStatusSelector,
  contractsListDuplicateSuccessSelector,
} from '../../../../../store/contracts/contractsList/contractsList.selectors'
import { useDispatchUnmount } from '../../../../../hooks/useDispatchUnmount'
import {
  clearDuplicateContract,
  fetchDuplicateContract,
} from '../../../../../store/contracts/contractsList/contractsList.actions'
import DuplicateDocumentModal from '../../DocumentModals/DuplicateDocumentModal'
import translations from '../../../../../translations/keys'

import { DuplicateContractModalProps } from './DuplicateContractModal.types'

const EXCLUDE_CODES = [DOCUMENT_WITH_NAME_EXIST]

export const DuplicateContractModal: React.FC<DuplicateContractModalProps> = ({ onClose }) => {
  const dispatch = useDispatch()
  const formRef: React.RefObject<FormikProps<DuplicateDocumentForm>> = useRef(null)
  const { id, oldName } = useSelector(modalsPayloadSelector(CONTRACTS_MODALS.DUPLICATE_CONTRACT))
  const folders = useSelector(foldersDataSelector)
  const foldersError = useSelector(foldersDataErrorSelector)
  const foldersFetchStatus = useSelector(foldersDataFetchStatusSelector)
  const foldersIsLoading = useSelector(foldersIsLoadingSelector)
  const duplicatedSuccessfully = useSelector(contractsListDuplicateSuccessSelector)
  const duplicateFetchStatus = useSelector(contractsListDuplicateFetchStatusSelector)
  const duplicateError = useSelector(contractsListDuplicateErrorSelector)

  useEffect(() => {
    if (duplicatedSuccessfully) {
      onClose()
    }
  }, [duplicatedSuccessfully])
  useEffect(() => {
    if (duplicateError?.lastErrorCode === DOCUMENT_WITH_NAME_EXIST) {
      const form = formRef.current

      if (form) {
        form.setFieldError(DuplicateDocumentFields.name, CONTRACT_EXISTS)
      }
    }
  }, [duplicateError])
  useContractsFoldersFetch()
  useShowFetchError(foldersFetchStatus, foldersError)
  useShowFetchError(duplicateFetchStatus, duplicateError, EXCLUDE_CODES)
  useDispatchUnmount(clearDuplicateContract)

  const onSubmit = (values: DuplicateDocumentForm) => {
    dispatch(fetchDuplicateContract(id, values))
  }

  return (
    <DuplicateDocumentModal
      loading={foldersIsLoading}
      folders={folders}
      oldName={oldName}
      onClose={onClose}
      onSubmit={onSubmit}
      ref={formRef}
      description={translations.CONTRACT_DUPLICATE_FILE_DESCRIPTION}
    />
  )
}
