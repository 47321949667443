import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { modalsVisibleSelector } from '../../../../../store/modals/modals.selectors'
import { closeModal } from '../../../../../store/modals/modals.actions'
import { SUBSCRIPTION_PAYMENT_MODALS } from '../../../../constants/subscriptionPayment'
import SubscriptionUpdatedModal from '../../../../components/Modals/SubscriptionPayment/SubscriptionUpdatedModal'

export const Modals = () => {
  const dispatch = useDispatch()

  const showSubscriptionUpdate = useSelector(modalsVisibleSelector(SUBSCRIPTION_PAYMENT_MODALS.SUBSCRIPTION_UPDATED))
  const handleSubscriptionUpdateClose = () => {
    dispatch(closeModal(SUBSCRIPTION_PAYMENT_MODALS.SUBSCRIPTION_UPDATED))
  }

  return <>{showSubscriptionUpdate && <SubscriptionUpdatedModal onClose={handleSubscriptionUpdateClose} />}</>
}
