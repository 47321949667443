import React, { MutableRefObject, useRef, useState } from 'react'

import Popover from '../Popover'
import Box from '../Box'

import { HelpTooltipProps } from './HelpTooltip.types'
import { StyledIcon } from './HelpTooltip.styles'

export const HelpTooltip: React.FC<HelpTooltipProps> = ({ content }) => {
  const [visible, setVisible] = useState(false)
  const dropdownRef: MutableRefObject<null> = useRef(null)

  const handleDropdownOpen = () => {
    setVisible((prevValue) => !prevValue)
  }
  const handleClose = () => {
    setVisible(false)
  }
  const handleButtonClick = () => {
    handleDropdownOpen()
  }

  return (
    <div>
      <Box onClick={handleButtonClick} ref={dropdownRef} ml={1}>
        <StyledIcon />
      </Box>
      <Popover
        targetRef={dropdownRef}
        visible={visible}
        onClose={handleClose}
        padding="0"
        width="auto"
        placement="bottom-start"
        offsetTop={8}
        offsetLeft={-24}
      >
        <Box display="flex" pt={3} pr={3} pb={3} pl={3} minWidth={240}>
          {content}
        </Box>
      </Popover>
    </div>
  )
}
