import { Cards } from '../../types/cards'
import {
  FetchActivateSubscriptionBody,
  FetchFormTokenizationResponse,
  FetchRegisterCardBody,
} from '../../types/subscriptionPayment'
import { Ajax } from '../ajax'

class PaymentService extends Ajax {
  pathname = 'payment'

  fetchTokenization = () => this.getJSON<FetchFormTokenizationResponse>(`${this.pathname}/tokenization`)

  fetchRegisterCard = (body: FetchRegisterCardBody) => this.post(`${this.pathname}/register-card`, body)

  fetchCards = () => this.getJSON<Cards>(`${this.pathname}/cards`)

  fetchSetDefaultCard = (cardId: string) => this.patch(`${this.pathname}/setdefault/${cardId}`)

  fetchActivateSubscription = (body: FetchActivateSubscriptionBody) =>
    this.post(`${this.pathname}/activate/subscription`, body)

  fetchActivateTrialSubscription = (body: FetchActivateSubscriptionBody) =>
    this.post(`${this.pathname}/activate/subscription/trial`, body)
}

export const paymentService = new PaymentService()
