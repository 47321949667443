import { FetchFailureAction, FETCH_STATUSES } from '../../../types/fetch'
import { createReducer } from '../../createReducer'
import { updatePlacementById } from '../../../utils/placement'
import * as SIGNING_ACTIONS from '../signing/signing.actions'
import * as SIGNING_TYPES from '../signing/signing.types'

import * as TYPES from './placement.types'
import * as ACTIONS from './placement.actions'

type Actions =
  | TYPES.ClearPlacementAction
  | TYPES.ClearPlacementStatusAction
  | TYPES.FetchPlacementSuccessAction
  | TYPES.FetchUpdatePlacementSuccessAction
  | TYPES.FetchGeneratePlacementSuccessAction
  | TYPES.SetShouldRegenerateAction
  | FetchFailureAction

export const initialState: TYPES.PlacementState = {
  byId: {},
  byPages: {},
  sorted: [],
  shouldRegenerate: false,
  fetch: {
    status: FETCH_STATUSES.IDLE,
    error: null,
  },
  update: {
    status: FETCH_STATUSES.IDLE,
    error: null,
  },
  generate: {
    status: FETCH_STATUSES.IDLE,
    error: null,
  },
  send: {
    fetchStatus: FETCH_STATUSES.IDLE,
    error: null,
  },
  status: {
    fetchStatus: FETCH_STATUSES.IDLE,
    status: '',
    error: null,
  },
}

export const placement = createReducer<TYPES.PlacementState, Actions>(initialState, {
  [TYPES.PlacementActions.CLEAR_PLACEMENT]: () => ({
    ...initialState,
  }),
  [TYPES.PlacementActions.CLEAR_PLACEMENT_STATUS]: (state) => ({
    ...state,
    generate: {
      ...initialState.generate,
    },
  }),

  [ACTIONS.fetchPlacementTypes.request]: (state) => ({
    ...state,
    fetch: {
      ...state.fetch,
      status: FETCH_STATUSES.REQUEST,
      error: null,
    },
  }),
  [ACTIONS.fetchPlacementTypes.success]: (state, action) => ({
    ...state,
    ...(action as TYPES.FetchPlacementSuccessAction).payload,
    fetch: {
      ...state.fetch,
      status: FETCH_STATUSES.SUCCESS,
      error: null,
    },
  }),
  [ACTIONS.fetchPlacementTypes.failure]: (state, action) => ({
    ...state,
    fetch: {
      ...state.fetch,
      status: FETCH_STATUSES.FAILURE,
      error: (action as FetchFailureAction).payload,
    },
  }),

  [ACTIONS.fetchUpdatePlacementTypes.request]: (state, action) => ({
    ...state,
    byId: updatePlacementById((action as TYPES.FetchUpdatePlacementAction).payload.place, state.byId),
    update: {
      ...state.update,
      status: FETCH_STATUSES.REQUEST,
      error: null,
    },
  }),
  [ACTIONS.fetchUpdatePlacementTypes.success]: (state) => ({
    ...state,
    update: {
      ...state.update,
      status: FETCH_STATUSES.SUCCESS,
      error: null,
    },
    generate: {
      ...state.generate,
      status: FETCH_STATUSES.IDLE,
    },
  }),
  [ACTIONS.fetchUpdatePlacementTypes.failure]: (state, action) => ({
    ...state,
    update: {
      ...state.update,
      status: FETCH_STATUSES.FAILURE,
      error: (action as FetchFailureAction).payload,
    },
  }),

  [ACTIONS.fetchGeneratePlacementTypes.request]: (state) => ({
    ...state,
    generate: {
      ...state.generate,
      status: FETCH_STATUSES.REQUEST,
      error: null,
    },
  }),
  [ACTIONS.fetchGeneratePlacementTypes.success]: (state) => ({
    ...state,
    generate: {
      ...state.generate,
      status: FETCH_STATUSES.SUCCESS,
      error: null,
    },
  }),
  [ACTIONS.fetchGeneratePlacementTypes.failure]: (state, action) => ({
    ...state,
    generate: {
      ...state.generate,
      status: FETCH_STATUSES.FAILURE,
      error: (action as FetchFailureAction).payload,
    },
  }),
  [ACTIONS.fetchSendContractTypes.request]: (state) => ({
    ...state,
    send: {
      ...state.send,
      fetchStatus: FETCH_STATUSES.REQUEST,
    },
  }),
  [ACTIONS.fetchSendContractTypes.success]: (state) => ({
    ...state,
    send: {
      ...state.send,
      fetchStatus: FETCH_STATUSES.SUCCESS,
    },
  }),
  [ACTIONS.fetchSendContractTypes.failure]: (state, action) => ({
    ...state,
    send: {
      ...state.send,
      fetchStatus: FETCH_STATUSES.FAILURE,
      error: (action as FetchFailureAction).payload,
    },
  }),

  [ACTIONS.fetchContractStatusTypes.request]: (state) => ({
    ...state,
    status: {
      ...state.status,
      fetchStatus: FETCH_STATUSES.REQUEST,
    },
  }),
  [ACTIONS.fetchContractStatusTypes.success]: (state, action) => ({
    ...state,
    status: {
      ...state.status,
      fetchStatus: FETCH_STATUSES.SUCCESS,
      status: (action as TYPES.FetchContractStatusSuccessAction).payload.status,
    },
  }),
  [ACTIONS.fetchContractStatusTypes.failure]: (state, action) => ({
    ...state,
    status: {
      ...state.status,
      fetchStatus: FETCH_STATUSES.FAILURE,
      error: (action as FetchFailureAction).payload,
    },
  }),

  [TYPES.PlacementActions.CLEAR_SEND_CONTRACT]: (state) => ({
    ...state,
    send: initialState.send,
  }),
  [SIGNING_ACTIONS.setSignatureTypes.success]: (state, action) => {
    const payload = (action as SIGNING_TYPES.SetSignatureSuccessAction).payload
    return {
      ...state,
      byId: payload.placementById,
    }
  },

  [TYPES.PlacementActions.SET_SHOULD_REGENERATE]: (state, action) => ({
    ...state,
    shouldRegenerate: (action as TYPES.SetShouldRegenerateAction).payload.shouldRegenerate,
  }),
})
