import { Ajax } from '../ajax'
import { ChangePasswordRequestBody } from '../../types/changePassword'
import {
  EditProfileFormBody,
  EditProfileInitialDataResponse,
  EditProfileUnconfirmedEmailResponse,
} from '../../types/editProfile'
import { ChangeEmailBody, ChangeEmailQueryParams } from '../../types/changeEmail'
import { ChangePhoneBody } from '../../types/changePhone'
import { ConfirmPhoneRequestBody } from '../../types/confirmPhone'
import { PushNotificationsFirebaseToken } from '../../types/firebase'

class ProfileService extends Ajax {
  pathname = 'profile'

  fetchResetPassword = (body: ChangePasswordRequestBody) => this.put(`${this.pathname}/resetpassword`, body)

  fetchEditProfile = (body: EditProfileFormBody) => this.put(`${this.pathname}/edit`, body)

  fetchResetEmail = (body: ChangeEmailBody, queryParams: ChangeEmailQueryParams) =>
    this.post(`${this.pathname}/resetemail?verify=${queryParams.verify}`, body)

  fetchResendEmailReset = () => this.getJSON(`${this.pathname}/resendemailreset`)

  fetchUnconfirmedEmail = () => this.getJSON<EditProfileUnconfirmedEmailResponse>(`${this.pathname}/unconfirmedemail`)

  fetchPreventEmailUpdate = () => this.delete(`${this.pathname}/preventemailupdate`)

  fetchEditProfileInitialData = () => this.getJSON<EditProfileInitialDataResponse>(`${this.pathname}`)

  fetchProfileBrief = () => this.getJSON(`${this.pathname}/brief`)

  fetchResetPhone = (body: ChangePhoneBody) => this.post(`${this.pathname}/resetphone`, body)

  fetchVerifyPhone = (body: ConfirmPhoneRequestBody) => this.post(`${this.pathname}/verifyphone`, body)

  fetchSaveFirebaseToken = (body: PushNotificationsFirebaseToken) => this.post(`${this.pathname}/savedevice`, body)

  fetchDeleteFirebaseToken = (deviceid: string) => this.delete(`${this.pathname}/deletedevice/${deviceid}`)
}

export const profileService = new ProfileService()
