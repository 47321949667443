import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { SUBSCRIPTION_FEATURES_ERRORS } from '../../constants/subscription'
import { openModal } from '../../store/modals/modals.actions'
import { subscriptionUserPlanEnabledFeaturesSelector } from '../../store/subscription/userPlan/userPlan.selectors'
import { SubscriptionFeatures } from '../../types/subscription'
import { accessFeature } from '../../utils/subscription/accessFeature'
import { COMMON_MODALS_IDS } from '../../web/ui/Modals/CommonModals/CommonModals.constants'

export const useAccessFeature = () => {
  const dispatch = useDispatch()
  const enabledFeatures = useSelector(subscriptionUserPlanEnabledFeaturesSelector)

  const checkAccess = useCallback(
    (feature?: SubscriptionFeatures, showWarning = true) => {
      if (!feature || accessFeature(enabledFeatures, feature)) {
        return true
      }

      if (showWarning) {
        dispatch(
          openModal(COMMON_MODALS_IDS.SUBSCRIPTION_WARNING, {
            descriptionKey: SUBSCRIPTION_FEATURES_ERRORS[feature],
          })
        )
      }

      return false
    },
    [enabledFeatures]
  )

  return { checkAccess }
}
