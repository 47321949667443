import React from 'react'
import { useField, useFormikContext } from 'formik'
import { useTranslation } from 'react-i18next'

import DatePicker from '../../DatePicker'

import { FormDatePickerProps } from './FormDatePicker.types'

export const FormDatePicker = (props: FormDatePickerProps) => {
  const formik = useFormikContext()
  const [field, meta] = useField({ ...props })
  const { t } = useTranslation()

  const onChange = (value: Date) => {
    formik.setFieldValue(props.name, value)
  }

  return (
    <DatePicker
      {...props}
      {...field}
      {...meta}
      error={meta.touched ? meta.error && t(meta.error) : ''}
      onChange={onChange}
    />
  )
}
