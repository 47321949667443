import { DRAFTS_VALUE } from '../../../constants/documents'

import { ListState } from './list.types'

export const prepareTemplatesOffset = (state: ListState) => {
  const { data, offset, limit } = state

  // If there's only one contact left on a page then go to a previus one if it exists
  const correctedOffset = offset > 0 && data.length === 1 ? offset - limit : offset

  return correctedOffset
}

export const prepareFolderId = (folderId: string) => (folderId === DRAFTS_VALUE ? null : folderId)
