import styled, { css } from 'styled-components'

import Button from '../Button'
import { BUTTON_VARIANTS } from '../Button/Button.types'

export const controlsBackdropCss = css`
  color: ${({ theme }) => theme.palette.white};
  background-color: rgb(15, 21, 34, 0.8);
  border-radius: 12px;
`

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background: #d0d9e1;
  touch-action: none;
`
export const Canvas = styled.div<{ isVisible: boolean }>`
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  user-select: none;
  opacity: ${({ isVisible }) => (isVisible ? '1' : '0')};
  visibility: ${({ isVisible }) => (isVisible ? 'visible' : 'hidden')};
  transition: opacity 400ms 200ms;
`
export const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: fit-content;
  height: fit-content;
  margin: 0;
  padding: 32px 0;
  transform-origin: 0% 0%;
`
export const ZoomBtn = styled(Button).attrs({ variant: BUTTON_VARIANTS.TEXT })`
  background-color: #656972;
  border-radius: 50%;
  transition: background-color 200ms;

  &:hover {
    background-color: #9fa2a7;
  }
  &:active {
    box-shadow: inset 1px 1px 5px rgba(0, 0, 0, 0.35);
  }
`
export const ZoomIndicator = styled.div`
  margin: 0 10px;
  user-select: none;
`
export const ZoomControlsWrapper = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  padding: 8px 7px;
  backface-visibility: hidden;

  ${controlsBackdropCss}

  ${({ theme }) => `@media ${theme.devices.tablet} {
    display: none;
  }`}
`
export const PageIndicator = styled.div<{ show: boolean }>`
  position: absolute;
  top: 36px;
  right: 20px;
  padding: 10px 20px;
  user-select: none;
  opacity: ${({ show }) => (show ? '1' : '0')};
  visibility: ${({ show }) => (show ? 'visible' : 'hidden')};
  transition: opacity 300ms, visibility 300ms;
  z-index: 120;

  ${controlsBackdropCss}
`
export const Scrollbar = styled.div`
  position: absolute;
  right: 4px;
  width: 12px;
  cursor: pointer;

  &::after {
    display: block;
    content: '';
    width: 4px;
    height: 100%;
    margin: 0 auto;
    background-color: ${({ theme }) => theme.palette.grey};
    border-radius: 2px;
    transition: width 300ms, border-radius 300ms;
  }
  &:hover,
  &:active {
    &::after {
      width: 8px;
      border-radius: 4px;
      box-shadow: 1px 1px 20px rgba(0, 0, 0, 0.1);
    }
  }
`
