import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { fetchClearAndContinueContract } from '../../../../../store/contracts/clearAndContinue/clearAndContinue.actions'
import * as CLEAR_AND_CONTINUE_SELECTORS from '../../../../../store/contracts/clearAndContinue/clearAndContinue.selectors'
import { fetchCorrections } from '../../../../../store/contracts/corrections/corrections.actions'
import { fetchOwnerContractDocument } from '../../../../../store/contracts/ownerView/ownerView.actions'
import { fetchStrikes } from '../../../../../store/contracts/strikes/strikes.actions'
import translations from '../../../../../translations/keys'
import { useShowFetchError } from '../../../../hooks/useShowFetchError'
import { ContractRouteParams } from '../../../../types/signing'
import AskModal from '../../ContractModals/AskModal'

import { ClearAndContinueContractModalProps } from './ClearAndContinueContractModal.types'

export const ClearAndContinueContractModal: React.FC<ClearAndContinueContractModalProps> = ({ onClose }) => {
  const { contractId } = useParams<ContractRouteParams>()
  const dispatch = useDispatch()

  const fetchStatus = useSelector(CLEAR_AND_CONTINUE_SELECTORS.clearAndContinueFetchStatusSelector)
  const error = useSelector(CLEAR_AND_CONTINUE_SELECTORS.clearAndContinueErrorSelector)
  const isSuccess = useSelector(CLEAR_AND_CONTINUE_SELECTORS.clearAndContinueIsSuccessSelector)

  const onSuccess = () => {
    dispatch(fetchClearAndContinueContract(contractId))
    dispatch(fetchOwnerContractDocument(contractId))
  }

  useEffect(() => {
    if (isSuccess) {
      dispatch(fetchStrikes(contractId))
      dispatch(fetchCorrections(contractId))
      onClose()
    }
  }, [isSuccess])

  useShowFetchError(fetchStatus, error)

  return <AskModal onSuccess={onSuccess} onClose={onClose} title={translations.DELETE_CORRECTIONS} onCancel={onClose} />
}
