import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { clearPlacement } from '../../store/contracts/placement/placement.actions'
import * as SIGNING_SELECTORS from '../../store/contracts/signing/signing.selectors'
import * as ACTIONS from '../../store/contracts/signing/signing.actions'
import * as VERIFICATION_ACTIONS from '../../store/contracts/verification/verification.actions'
import * as VERIFICATION_SELECTORS from '../../store/contracts/verification/verification.selectors'
import { useDispatchUnmount } from '../useDispatchUnmount'

export const useCommonSigningFetch = (id: string) => {
  const dispatch = useDispatch()
  const isFirstView = useSelector(SIGNING_SELECTORS.signingIsFirstViewSelector)
  const isVerified = useSelector(VERIFICATION_SELECTORS.contractsVerificationVerified)

  useEffect(() => {
    if (id) {
      dispatch(ACTIONS.fetchSigningContractDocument(id))
    }
  }, [id])
  useEffect(() => {
    if (isVerified) {
      dispatch(ACTIONS.fetchTotalPages())
    }
  }, [isVerified])
  useEffect(() => {
    if (isFirstView && isVerified) {
      dispatch(ACTIONS.fetchViewedContract())
    }
  }, [isFirstView, isVerified])

  useDispatchUnmount(ACTIONS.clearSigning, clearPlacement, VERIFICATION_ACTIONS.cleanVerification)
}
