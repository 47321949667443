import React from 'react'
import styled, { css } from 'styled-components'

import { AvatarProps } from './Avatar.types'

const defaultSizeStyles = css`
  width: 32px;
  height: 32px;
`
const largeSizeStyles = css`
  width: 64px;
  height: 64px;
  ${({ theme }) => theme.textStyles.H1};
`

const StyledAvatarWrapper = styled.div<Pick<AvatarProps, 'size'>>`
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.palette.white};
  text-transform: uppercase;
  line-height: 1;
  background-color: ${({ theme }) => theme.palette.blueDark};
  border-radius: 50%;
  ${({ size }) => (size === 'large' ? largeSizeStyles : defaultSizeStyles)};
`
const StyledImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  pointer-events: none;
`

export const Avatar: React.FC<AvatarProps> = ({ size, src, firstName, lastName }) => {
  const fullName = `${firstName} ${lastName}`
  const initials = `${firstName[0] || ''}${lastName[0] || ''}`.trim()

  const children = src ? (
    <StyledImg data-testid="avatar-img" alt={fullName} src={src} />
  ) : (
    <b data-testid="avatar-initials">{initials}</b>
  )

  return (
    <StyledAvatarWrapper data-testid="avatar-wrapper" size={size}>
      {children}
    </StyledAvatarWrapper>
  )
}
