import { SubscriptionPlanResponse, SubscriptionUserPlan } from '../../types/subscription'
import { Ajax } from '../ajax'

class SubscriptionService extends Ajax {
  pathname = 'subscription'

  fetchPlans = () => this.getJSON<SubscriptionPlanResponse[]>(`${this.pathname}/available`)

  fetchUserPlan = () => this.getJSON<SubscriptionUserPlan>(this.pathname)

  unsubscribe = () => this.post(`${this.pathname}/unsubscribe`);
}

export const subscriptionService = new SubscriptionService()
