import React from 'react'
import { useField } from 'formik'
import { useTranslation } from 'react-i18next'

import TextArea from '../../TextArea'

import { FormTextAreaProps } from './FormTextArea.types'

export const FormTextArea = (props: FormTextAreaProps) => {
  const [field, meta] = useField(props)
  const { t } = useTranslation()

  return <TextArea {...props} {...field} {...meta} error={meta.touched ? meta.error && t(meta.error) : ''} />
}
