import styled from 'styled-components'

import { ArrowDownIcon, DoubleArrowIcon } from '../../assets/icons'
import Button from '../Button'
import { BUTTON_VARIANTS } from '../Button/Button.types'
import Box from '../Box'

export const StyledTable = styled(Box).attrs({
  height: '100%',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
})`
  min-height: 0;
`

export const StyledTableHead = styled(Box).attrs({
  display: 'flex',
  justifyContent: 'space-between',
})``

export const StyledTableHeadColumns = styled(Box).attrs({
  width: '100%',
  display: 'flex',
})``

export const StyledTableHeadCell = styled.div`
  & ~ & {
    margin-left: 8px;
  }
`

export const StyledTableHeadFullInfo = styled(Box).attrs({
  display: 'flex',
  alignItems: 'center',
})`
  white-space: nowrap;
`

export const StyledHeadButton = styled(Button).attrs({ variant: BUTTON_VARIANTS.TEXT })`
  justify-content: flex-start;
  width: 100%;
  color: ${({ theme }) => theme.palette.grey};
  line-height: 1;
  text-transform: uppercase;

  &:hover {
    color: ${({ theme }) => theme.palette.blueDark};
  }
`

export const StyledSortingIcon = styled(ArrowDownIcon)<{ flip: boolean }>`
  margin: 0 5px;
  backface-visibility: hidden;
  transform: ${({ flip }) => (flip ? 'rotate(180deg)' : 'rotate(0deg)')};
  transition: transform 200ms;
`

export const StyledNeutralIcon = styled(DoubleArrowIcon)`
  margin: 0 5px;
`

export const StyledRow = styled(Box).attrs({
  pb: 2,
  pt: 2,
  display: 'flex',
  justifyContent: 'space-between',
})`
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: ${({ theme }) => theme.palette.strokeGrey};
  position: relative;
`

export const StyledColumn = styled(Box).attrs({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
})``

export const StyledKebab = styled(Box).attrs({
  display: 'flex',
  alignItems: 'center',
})``

export const ScrollableWrapper = styled.div`
  height: 100%;
  overflow-y: auto;
`
