import { Breadcrumb } from '../../../types/breadcrumb'
import { ContactsListResponse, ContractsCategory, CONTRACT_STATUS } from '../../../types/contracts'
import { ContractsFiltersForm } from '../../../types/contractsFilters'
import { DuplicateDocumentRequestBody } from '../../../types/duplicateDocument'
import { FetchFailureAction, FetchFailurePayload } from '../../../types/fetch'
import { Sorting } from '../../../types/table'
import { createFetchActionTypes } from '../../../utils/createFetchActionTypes'
import { Params } from '../../../utils/prepareUrlParams/prepareUrlParams'
import { Notification } from '../../../types/notifications'

import * as TYPES from './contractsList.types'

export const fetchContractsListDataTypes = createFetchActionTypes(TYPES.ContractsListActions.FETCH_CONTRACTS_LIST_DATA)
export const fetchDeleteContractTypes = createFetchActionTypes(TYPES.ContractsListActions.DELETE_CONTRACT)
export const fetchRenameContractTypes = createFetchActionTypes(TYPES.ContractsListActions.RENAME_CONTRACT)
export const fetchMoveContractToFolderTypes = createFetchActionTypes(TYPES.ContractsListActions.MOVE_CONTRACT_TO_FOLDER)
export const fetchDuplicateContractTypes = createFetchActionTypes(TYPES.ContractsListActions.DUPLICATE_CONTRACT)

export const fetchContractsListData = (params: Params): TYPES.FetchContractsList => ({
  type: fetchContractsListDataTypes.request,
  payload: { params },
})

export const fetchContractsListDataSuccess = (body: ContactsListResponse): TYPES.FetchContractsListSuccess => ({
  type: fetchContractsListDataTypes.success,
  payload: { body },
})

export const fetchContractsListDataFailure = (payload: FetchFailurePayload): FetchFailureAction => ({
  type: fetchContractsListDataTypes.failure,
  payload,
})

export const contractsListSetSorting = (sorting: Sorting): TYPES.ContractsListSetSorting => ({
  type: TYPES.ContractsListActions.CONTRACTS_LIST_SET_SORTING,
  payload: sorting,
})

export const clearContracts = () => ({
  type: TYPES.ContractsListActions.CLEAR_CONTRACTS,
})

export const clearContractsData = () => ({
  type: TYPES.ContractsListActions.CLEAR_CONTRACTS_DATA,
})

export const contractsListSetOffset = (offset: number, loadMore = false): TYPES.ContractsListSetOffset => ({
  type: TYPES.ContractsListActions.CONTRACTS_LIST_SET_OFFSET,
  payload: { offset, loadMore },
})

export const contractsListSetSearchText = (searchText: string): TYPES.ContractsListSetSearchText => ({
  type: TYPES.ContractsListActions.CONTRACTS_LIST_SET_SEARCH_TEXT,
  payload: { searchText },
})

export const contractsListSetFilters = (filters: ContractsFiltersForm) => ({
  type: TYPES.ContractsListActions.CONTRACTS_LIST_SET_FILTERS,
  payload: { filters },
})

export const fetchDeleteContract = (
  id: string,
  isFolder = false,
  category?: ContractsCategory,
  recipient = false,
  isMobile = false
): TYPES.FetchDeleteContract => ({
  type: fetchDeleteContractTypes.request,
  payload: { id, isFolder, category, recipient, isMobile },
})

export const fetchDeleteContractSuccess = (isFolder: boolean): TYPES.FetchDeleteContractSuccess => ({
  type: fetchDeleteContractTypes.success,
  payload: { isFolder },
})

export const fetchDeleteContractFailure = (payload: FetchFailurePayload): FetchFailureAction => ({
  type: fetchDeleteContractTypes.failure,
  payload,
})

export const clearDeleteContract = () => ({
  type: TYPES.ContractsListActions.CLEAR_DELETE_CONTRACT,
})

export const contractsListForceUpdate = (): TYPES.ContractsListForceUpdate => ({
  type: TYPES.ContractsListActions.CONTRACTS_LIST_FORCE_UPDATE,
})

export const fetchRenameContract = (id: string, name: string, isFolder = false): TYPES.FetchRenameContract => ({
  type: fetchRenameContractTypes.request,
  payload: { id, name, isFolder },
})

export const fetchRenameContractSuccess = (id: string, name: string): TYPES.FetchRenameContractSuccess => ({
  type: fetchRenameContractTypes.success,
  payload: { id, name },
})

export const fetchRenameContractFailure = (payload: FetchFailurePayload): FetchFailureAction => ({
  type: fetchRenameContractTypes.failure,
  payload,
})

export const clearRenameContract = () => ({
  type: TYPES.ContractsListActions.CLEAR_RENAME_CONTRACT,
})

export const fetchMoveContractToFolder = (
  id: string,
  folderId: string,
  recipient = false,
  category?: ContractsCategory,
  isMobile = false
): TYPES.FetchMoveContractToFolder => ({
  type: fetchMoveContractToFolderTypes.request,
  payload: { id, folderId, recipient, category, isMobile },
})

export const fetchMoveContractToFolderSuccess = (): TYPES.FetchMoveContractToFolderSuccess => ({
  type: fetchMoveContractToFolderTypes.success,
})

export const fetchMoveContractToFolderFailure = (payload: FetchFailurePayload): FetchFailureAction => ({
  type: fetchMoveContractToFolderTypes.failure,
  payload,
})

export const clearMoveContractToFolder = (): TYPES.ClearMoveContractToFolder => ({
  type: TYPES.ContractsListActions.CLEAR_MOVE_CONTRACT_TO_FOLDER,
})

export const contractsListSetIsFirstLoad = (): TYPES.ContractsListSetIsFirstLoad => ({
  type: TYPES.ContractsListActions.CONTRACTS_LIST_SET_IS_FIRST_LOAD,
})

export const fetchDuplicateContract = (
  id: string,
  body: DuplicateDocumentRequestBody,
  category?: ContractsCategory,
  isMobile = false
): TYPES.FetchDuplicateContract => ({
  type: fetchDuplicateContractTypes.request,
  payload: { id, body, category, isMobile },
})

export const fetchDuplicateContractSuccess = (): TYPES.FetchDuplicateContractSuccess => ({
  type: fetchDuplicateContractTypes.success,
})

export const fetchDuplicateContractFailure = (payload: FetchFailurePayload): FetchFailureAction => ({
  type: fetchDuplicateContractTypes.failure,
  payload,
})

export const clearDuplicateContract = (): TYPES.ClearDuplicateContract => ({
  type: TYPES.ContractsListActions.CLEAR_DUPLICATE_CONTRACT,
})

export const contractsListGoToFolder = (breadcrumb: Breadcrumb): TYPES.ContractsListGoToFolder => ({
  type: TYPES.ContractsListActions.CONTRACTS_LIST_GO_TO_FOLDER,
  payload: { breadcrumb },
})

export const setContractsNotification = (notification: Notification): TYPES.SetContractsNotificationAction => ({
  type: TYPES.ContractsListActions.SET_CONTRACTS_NOTIFICATION,
  payload: notification,
})
export const cleanContractsNotification = () => ({
  type: TYPES.ContractsListActions.CLEAN_CONTRACTS_NOTIFICATION,
})

export const setContractStatus = (contractId: string, status: CONTRACT_STATUS): TYPES.SetContractStatusAction => ({
  type: TYPES.ContractsListActions.SET_CONTRACT_STATUS,
  payload: { contractId, status },
})
