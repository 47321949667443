import styled from 'styled-components'

import Box from '../../ui/Box'

import { SubscriptionPlanProps } from './SubscriptionPlan.types'

const BUTTON_WIDTH = 216

export const StyledPlan = styled(Box).attrs({
  pt: 4,
  pb: 4,
  pl: 2,
  pr: 2,
  mr: 2,
  mb: 4,
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
})<Pick<SubscriptionPlanProps, 'isActive'>>`
  background-color: ${({ theme }) => theme.palette.white};
  border-radius: 10px;
  box-shadow: ${({ theme }) => theme.shadow};
  ${({ isActive, theme }) => (isActive ? `border: 1px solid ${theme.palette.blue};` : '')}

  ${({ theme }) => `@media ${theme.devices.mobile} {
    width: 100%;
    margin-right: 0;
    margin-bottom: 24px;
  }`}
`

export const StyledFeatures = styled(Box).attrs({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  mobileWidth: `${BUTTON_WIDTH}px`,
})``

export const StyledButtonWrapper = styled(Box).attrs({
  width: BUTTON_WIDTH,
  mt: 3,
})``
