import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { modalsVisibleSelector } from '../../../../../store/modals/modals.selectors'
import { closeModal } from '../../../../../store/modals/modals.actions'
import { TestModal } from '../../../../components/Modals/DashboardModals/TestModal'
import { DashboardModalsIds } from '../../Dashboard.constants'

export const DashboardModals = () => {
  const dispatch = useDispatch()
  const showTestModal = useSelector(modalsVisibleSelector(DashboardModalsIds.testModal))
  const onCloseTestModal = () => {
    dispatch(closeModal(DashboardModalsIds.testModal))
  }

  return <>{showTestModal && <TestModal onClose={onCloseTestModal} />}</>
}
