import * as yup from 'yup'

import { EditProfileFields } from '../../types/editProfile'
import { countryValidationSchema } from '../countryValidationSchema'
import { industryValidationSchema } from '../industryValidationSchema'
import { nameValidationSchema } from '../nameValidationSchema'
import { textValidationSchema } from '../textValidationSchema'

const JOB_DATA_TITLE_MIN_LENGTH = 3
export const JOB_DATA_TITLE_MAX_LENGTH = 255
const ADDRESS_TITLE_MIN_LENGTH = 1
export const ADDRESS_TITLE_MAX_LENGTH = 255

export const editProfileValidationSchema = yup.object().shape({
  [EditProfileFields.firstName]: nameValidationSchema,
  [EditProfileFields.lastName]: nameValidationSchema,
  [EditProfileFields.country]: countryValidationSchema,
  [EditProfileFields.industry]: industryValidationSchema,
  [EditProfileFields.job]: textValidationSchema(JOB_DATA_TITLE_MIN_LENGTH, JOB_DATA_TITLE_MAX_LENGTH),
  [EditProfileFields.company]: textValidationSchema(JOB_DATA_TITLE_MIN_LENGTH, JOB_DATA_TITLE_MAX_LENGTH),
  [EditProfileFields.address]: textValidationSchema(ADDRESS_TITLE_MIN_LENGTH, ADDRESS_TITLE_MAX_LENGTH),
  [EditProfileFields.city]: textValidationSchema(ADDRESS_TITLE_MIN_LENGTH, ADDRESS_TITLE_MAX_LENGTH),
  [EditProfileFields.region]: textValidationSchema(ADDRESS_TITLE_MIN_LENGTH, ADDRESS_TITLE_MAX_LENGTH),
  [EditProfileFields.postalCode]: textValidationSchema(ADDRESS_TITLE_MIN_LENGTH, ADDRESS_TITLE_MAX_LENGTH),
})
