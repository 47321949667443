import React from 'react'
import { useField } from 'formik'
import { useTranslation } from 'react-i18next'

import CodeInput from '../../CodeInput'

import { FormCodeInputProps } from './FormCodeInput.types'

export const FormCodeInput = (props: FormCodeInputProps) => {
  const [field, meta] = useField({ ...props })
  const { t } = useTranslation()

  return (
    <CodeInput
      {...props}
      {...field}
      {...meta}
      error={meta.touched ? meta.error && t(meta.error) : ''}
      formikValue={field.value}
    />
  )
}
