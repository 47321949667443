import React from 'react'
import { useTranslation } from 'react-i18next'

import translations from '../../../translations/keys'
import Button from '../../ui/Button'
import Typography from '../../ui/Typography'
import { TYPOGRAPHY_NAMES } from '../../ui/Typography/Typography.types'

import { StyledWrapper, StyledButtonWrapper, StyledDescription } from './ContactUs.styles'
import { ContactUsProps } from './ContactUs.types'

export const ContactUs: React.FC<ContactUsProps> = ({ onClick }) => {
  const { t } = useTranslation()
  return (
    <StyledWrapper>
      <StyledDescription>
        <Typography name={TYPOGRAPHY_NAMES.H3} laptopName={TYPOGRAPHY_NAMES.H4}>
          {t(translations.DO_NOT_LIKE_PLAN)}
        </Typography>
      </StyledDescription>
      <StyledButtonWrapper>
        <Button fullWidth onClick={onClick}>
          {t(translations.CONTACT_US)}
        </Button>
      </StyledButtonWrapper>
    </StyledWrapper>
  )
}
