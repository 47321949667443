import * as yup from 'yup'

import { UpdateContactFields } from '../../types/updateContact'
import { departmentValidationSchema } from '../departmentValidationSchema'
import { emailValidationSchema } from '../emailValidationSchema'
import { nameValidationSchema } from '../nameValidationSchema'
import { phoneValidationSchema } from '../phoneValidationSchema'

export const updateContactValidationSchema = yup.object().shape({
  [UpdateContactFields.fullName]: nameValidationSchema,
  [UpdateContactFields.email]: emailValidationSchema,
  ...phoneValidationSchema(UpdateContactFields.phone, false).fields,
  [UpdateContactFields.department]: departmentValidationSchema,
})
