import { FetchFailureAction, FetchFailurePayload } from '../../../types/fetch'
import { SIGNING_TYPES } from '../../../types/signingOrder'
import { AddRecipientsForm, RecipientData, Sender } from '../../../types/recipients'
import { createFetchActionTypes } from '../../../utils/createFetchActionTypes'
import { EnabledFeatures } from '../../../types/subscription'
import { DocumentMessageForm } from '../../../types/documents'

import * as TYPES from './recipients.types'

export const fetchRecipientsTypes = createFetchActionTypes(TYPES.RecipientsActions.FETCH_RECIPIENTS)
export const fetchAddRecipientsTypes = createFetchActionTypes(TYPES.RecipientsActions.FETCH_ADD_RECIPIENTS)
export const fetchUpdateRecipientTypes = createFetchActionTypes(TYPES.RecipientsActions.FETCH_UPDATE_RECIPIENT)
export const fetchDeleteRecipientTypes = createFetchActionTypes(TYPES.RecipientsActions.FETCH_DELETE_RECIPIENT)
export const fetchContractInfoTypes = createFetchActionTypes(TYPES.RecipientsActions.FETCH_CONTRACT_INFO)
export const fetchSenderInfoTypes = createFetchActionTypes(TYPES.RecipientsActions.FETCH_SENDER_INFO)
export const fetchResendTypes = createFetchActionTypes(TYPES.RecipientsActions.FETCH_RESEND)

export const setContractId = (id: string): TYPES.SetContractIdAction => ({
  type: TYPES.RecipientsActions.SET_CONTRACT_ID,
  payload: { id },
})
export const setOrder = (order: SIGNING_TYPES): TYPES.SetOrderAction => ({
  type: TYPES.RecipientsActions.SET_ORDER,
  payload: { order },
})
export const setRecipients = (recipients: Required<RecipientData>[]): TYPES.SetRecipientsAction => ({
  type: TYPES.RecipientsActions.SET_RECIPIENTS,
  payload: { recipients },
})
export const clearRecipients = (): TYPES.ClearRecipientsAction => ({
  type: TYPES.RecipientsActions.CLEAR_RECIPIENTS,
})

export const fetchRecipients = (contractId: string): TYPES.FetchRecipientsAction => ({
  type: fetchRecipientsTypes.request,
  payload: { contractId },
})
export const fetchRecipientsSuccess = (
  recipients: Required<RecipientData>[],
  enabledFeatures: EnabledFeatures
): TYPES.FetchRecipientsSuccessAction => ({
  type: fetchRecipientsTypes.success,
  payload: { recipients, enabledFeatures },
})
export const fetchRecipientsFailure = (payload: FetchFailurePayload): FetchFailureAction => ({
  type: fetchRecipientsTypes.failure,
  payload,
})

export const fetchAddRecipients = (contractId: string, body: AddRecipientsForm): TYPES.FetchAddRecipientsAction => ({
  type: fetchAddRecipientsTypes.request,
  payload: { contractId, body },
})
export const fetchAddRecipientsSuccess = (recipients: RecipientData[]): TYPES.FetchAddRecipientsSuccessAction => ({
  type: fetchAddRecipientsTypes.success,
  payload: { recipients },
})
export const fetchAddRecipientsFailure = (payload: FetchFailurePayload): FetchFailureAction => ({
  type: fetchAddRecipientsTypes.failure,
  payload,
})

export const fetchUpdateRecipient = (
  id: string,
  body: Omit<RecipientData, 'id'>
): TYPES.FetchUpdateRecipientAction => ({
  type: fetchUpdateRecipientTypes.request,
  payload: { id, body },
})
export const fetchUpdateRecipientSuccess = (
  id: string,
  recipient: Omit<RecipientData, 'id'>
): TYPES.FetchUpdateRecipientSuccessAction => ({
  type: fetchUpdateRecipientTypes.success,
  payload: { id, recipient },
})
export const fetchUpdateRecipientFailure = (payload: FetchFailurePayload): FetchFailureAction => ({
  type: fetchUpdateRecipientTypes.failure,
  payload,
})

export const fetchDeleteRecipient = (id: string): TYPES.FetchDeleteRecipientAction => ({
  type: fetchDeleteRecipientTypes.request,
  payload: { id },
})
export const fetchDeleteRecipientSuccess = (id: string): TYPES.FetchDeleteRecipientSuccessAction => ({
  type: fetchDeleteRecipientTypes.success,
  payload: { id },
})
export const fetchDeleteRecipientFailure = (payload: FetchFailurePayload): FetchFailureAction => ({
  type: fetchDeleteRecipientTypes.failure,
  payload,
})

export const fetchContractInfo = (contractId: string): TYPES.FetchContractInfoAction => ({
  type: fetchContractInfoTypes.request,
  payload: { contractId },
})
export const fetchContractInfoSuccess = (
  name: string,
  messageInitialValues: DocumentMessageForm
): TYPES.FetchContractInfoSuccessAction => ({
  type: fetchContractInfoTypes.success,
  payload: { name, messageInitialValues },
})
export const fetchContractInfoFailure = (payload: FetchFailurePayload): FetchFailureAction => ({
  type: fetchContractInfoTypes.failure,
  payload,
})

export const fetchSenderInfo = (): TYPES.FetchSenderInfoAction => ({
  type: fetchSenderInfoTypes.request,
})
export const fetchSenderInfoSuccess = (sender: Sender): TYPES.FetchSenderInfoSuccessAction => ({
  type: fetchSenderInfoTypes.success,
  payload: sender,
})
export const fetchSenderInfoFailure = (payload: FetchFailurePayload): FetchFailureAction => ({
  type: fetchSenderInfoTypes.failure,
  payload,
})

export const fetchResend = (contractId: string, body: AddRecipientsForm): TYPES.FetchResendAction => ({
  type: fetchResendTypes.request,
  payload: { contractId, body },
})
export const fetchResendSuccess = (recipients: RecipientData[]): TYPES.FetchResendSuccessAction => ({
  type: fetchResendTypes.success,
  payload: { recipients },
})
export const fetchResendFailure = (payload: FetchFailurePayload): FetchFailureAction => ({
  type: fetchResendTypes.failure,
  payload,
})
