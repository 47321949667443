import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { modalsVisibleSelector } from '../../../../../store/modals/modals.selectors'
import { closeModal } from '../../../../../store/modals/modals.actions'
import { CONTACTS_MODALS } from '../../../../constants/contacts'
import { AddContactModal } from '../../../../components/Modals/ContactsModals/AddContactModal'
import { DeleteContactModal } from '../../../../components/Modals/ContactsModals/DeleteContactModal'

interface ContactsModalsProps {
  onConfirmDelete: () => void
}

export const ContactsModals: React.FC<ContactsModalsProps> = ({ onConfirmDelete }) => {
  const dispatch = useDispatch()

  const showAddContact = useSelector(modalsVisibleSelector(CONTACTS_MODALS.CONTACTS_ADD_CONTACT))
  const handleAddContactClose = () => {
    dispatch(closeModal(CONTACTS_MODALS.CONTACTS_ADD_CONTACT))
  }

  const showDeleteContact = useSelector(modalsVisibleSelector(CONTACTS_MODALS.CONTACTS_DELETE_CONTACT))
  const handleDeleteContactClose = () => {
    dispatch(closeModal(CONTACTS_MODALS.CONTACTS_DELETE_CONTACT))
  }
  const handleConfirmDeleteContact = () => {
    dispatch(closeModal(CONTACTS_MODALS.CONTACTS_DELETE_CONTACT))
    onConfirmDelete()
  }

  return (
    <>
      {showAddContact && <AddContactModal onClose={handleAddContactClose} />}
      {showDeleteContact && (
        <DeleteContactModal onConfirmDelete={handleConfirmDeleteContact} onClose={handleDeleteContactClose} />
      )}
    </>
  )
}
