import { combineEpics, Epic, ofType } from 'redux-observable'
import { catchError, map, mergeMap, takeUntil } from 'rxjs/operators'

import { catchFetchError } from '../../../utils/catchFetchError'
import { signingService } from '../../../api'
import { signingRecipientTokenSelector } from '../signing/signing.selectors'

import * as ACTIONS from './verification.actions'

const fetchVerification: Epic = (action$, state$) =>
  action$.pipe(
    ofType(ACTIONS.fetchVerificationTypes.request),
    mergeMap(({ payload }) =>
      signingService.fetchVerification(payload.contractId, signingRecipientTokenSelector(state$.value)).pipe(
        map((response) => ACTIONS.fetchVerificationSuccess(response)),
        catchError(catchFetchError(ACTIONS.fetchVerificationFailure)),
        takeUntil(action$.pipe(ofType(ACTIONS.fetchVerificationTypes.success, ACTIONS.fetchVerificationTypes.failure)))
      )
    )
  )

export const verificationEpics = combineEpics(fetchVerification)
