import { Ajax } from '../ajax'
import { RecipientData, AddRecipientsForm } from '../../types/recipients'
import { PlacementBody } from '../../types/placement'

class RecipientsService extends Ajax {
  pathname = 'recipient'

  fetchRecipients = (contractId: string) => this.getJSON<Required<RecipientData>[]>(`${this.pathname}/${contractId}`)

  fetchAddRecipients = (contractId: string, body: AddRecipientsForm) =>
    this.post(`${this.pathname}/${contractId}`, body)

  fetchUpdateRecipient = (id: string, body: RecipientData) => this.put(`${this.pathname}/${id}`, body)

  fetchDeleteRecipient = (id: string) => this.delete(`${this.pathname}/${id}`)

  fetchPlacement = (contractId: string) => this.getJSON<PlacementBody>(`${this.pathname}/placement/${contractId}`)

  fetchAddPlacement = (body: PlacementBody) => this.post(`${this.pathname}/placement`, body)
}

export const recipientsService = new RecipientsService()
