import { FetchFailureAction, FetchFailurePayload } from '../../types/fetch'
import { ReadNotificationsBody, UserNotification, UserNotificationsResponse } from '../../types/userNotifications'
import { createFetchActionTypes } from '../../utils/createFetchActionTypes'
import { Params } from '../../utils/prepareUrlParams/prepareUrlParams'

import * as TYPES from './userNotifications.types'

export const fetchUserNotificationsTypes = createFetchActionTypes(
  TYPES.UserNotificationsActions.FETCH_USER_NOTIFICATIONS
)
export const fetchReadNotificationsTypes = createFetchActionTypes(
  TYPES.UserNotificationsActions.USER_NOTIFICATIONS_READ
)
export const fetchUnreadCountTypes = createFetchActionTypes(
  TYPES.UserNotificationsActions.FETCH_USER_NOTIFICATIONS_UNREAD_COUNT
)

export const fetchUserNotifications = (params: Params): TYPES.FetchUserNotificationsAction => ({
  type: fetchUserNotificationsTypes.request,
  payload: { params },
})
export const fetchUserNotificationsSuccess = (
  notifications: UserNotificationsResponse
): TYPES.FetchUserNotificationsSuccessAction => ({
  type: fetchUserNotificationsTypes.success,
  payload: { notifications },
})
export const fetchUserNotificationsFailure = (error: FetchFailurePayload): FetchFailureAction => ({
  type: fetchUserNotificationsTypes.failure,
  payload: error,
})

export const setOffset = (offset: number): TYPES.SetOffsetAction => ({
  type: TYPES.UserNotificationsActions.USER_NOTIFICATIONS_SET_OFFSET,
  payload: { offset },
})

export const fetchReadNotifications = (body: ReadNotificationsBody): TYPES.ReadNotificationsAction => ({
  type: fetchReadNotificationsTypes.request,
  payload: { body },
})
export const fetchReadNotificationsSuccess = (body: ReadNotificationsBody): TYPES.ReadNotificationsSuccessAction => ({
  type: fetchReadNotificationsTypes.success,
  payload: { body },
})
export const fetchReadNotificationsFailure = (error: FetchFailurePayload): FetchFailureAction => ({
  type: fetchReadNotificationsTypes.failure,
  payload: error,
})

export const fetchUnreadCount = (): TYPES.FetchUnreadCountAction => ({
  type: fetchUnreadCountTypes.request,
})
export const fetchUnreadCountSuccess = (unreadCount: number): TYPES.FetchUnreadCountSuccessAction => ({
  type: fetchUnreadCountTypes.success,
  payload: { unreadCount },
})
export const fetchUnreadCountFailure = (error: FetchFailurePayload): FetchFailureAction => ({
  type: fetchUnreadCountTypes.failure,
  payload: error,
})

export const addUserNotification = (notification: UserNotification): TYPES.AddUserNotificationAction => ({
  type: TYPES.UserNotificationsActions.ADD_USER_NOTIFICATION,
  payload: { notification },
})

export const clearNotifications = () => ({
  type: TYPES.UserNotificationsActions.CLEAR_NOTIFICATIONS,
})

export const clearNotificationsOffset = () => ({
  type: TYPES.UserNotificationsActions.CLEAR_NOTIFICATIONS_OFFSET,
})
