import React from 'react'
import { useTranslation } from 'react-i18next'

import translations from '../../../translations/keys'
import { PaymentSvg } from '../../assets/PaymentSvg'
import Box from '../../ui/Box'
import { Spinner } from '../../ui/Spinner/Spinner'
import Typography from '../../ui/Typography'
import { TYPOGRAPHY_COMPONENTS, TYPOGRAPHY_NAMES } from '../../ui/Typography/Typography.types'

export const PaymentProcessing = () => {
  const { t } = useTranslation()
  return (
    <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
      <PaymentSvg />
      <Box mt={6} mb={5} textAlign="center">
        <Typography name={TYPOGRAPHY_NAMES.H1} component={TYPOGRAPHY_COMPONENTS.span}>
          {t(translations.WAITING_PAYMENT)}
        </Typography>
      </Box>
      <Spinner show />
    </Box>
  )
}
