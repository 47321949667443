import React from 'react'
import { useField } from 'formik'

import RadioButton from '../../RadioButton'

import { FormRadioButtonProps } from './FormRadioButton.types'

export const FormRadioButton: React.FC<FormRadioButtonProps> = (props) => {
  const [field, meta] = useField({ ...props, type: 'radio' })
  return <RadioButton {...props} {...field} {...meta} checked={props.id === meta.value} id={props.id} />
}
