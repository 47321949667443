import { Card, Cards } from '../../../types/cards'
import { FetchFailureAction, FetchFailurePayload } from '../../../types/fetch'
import { createFetchActionTypes } from '../../../utils/createFetchActionTypes'

import * as TYPES from './cards.types'

export const fetchCardsTypes = createFetchActionTypes(TYPES.CardsActions.FETCH_SUBSCRIPTION_CARDS)
export const fetchSetDefaultCardTypes = createFetchActionTypes(TYPES.CardsActions.FETCH_SET_DEFAULT_CARD)

export const fetchCards = (): TYPES.FetchCardsAction => ({
  type: fetchCardsTypes.request,
})
export const fetchCardsSuccess = (cards: Cards): TYPES.FetchCardsSuccessAction => ({
  type: fetchCardsTypes.success,
  payload: { cards },
})
export const fetchCardsFailure = (payload: FetchFailurePayload): FetchFailureAction => ({
  type: fetchCardsTypes.failure,
  payload,
})

export const cleanCards = () => ({
  type: TYPES.CardsActions.CLEAN_SUBSCRIPTION_CARDS,
})

export const addCard = (card: Card): TYPES.AddCard => ({
  type: TYPES.CardsActions.ADD_SUBSCRIPTION_CARD,
  payload: { card },
})

export const fetchSetDefaultCard = (cardId: string): TYPES.FetchSetDefaultCardAction => ({
  type: fetchSetDefaultCardTypes.request,
  payload: { cardId },
})
export const fetchSetDefaultCardSuccess = (): TYPES.FetchSetDefaultCardSuccessAction => ({
  type: fetchSetDefaultCardTypes.success,
})
export const fetchSetDefaultCardFailure = (payload: FetchFailurePayload): FetchFailureAction => ({
  type: fetchSetDefaultCardTypes.failure,
  payload,
})
