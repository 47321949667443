import { combineEpics, Epic, ofType } from 'redux-observable'
import { catchError, map, mergeMap, takeUntil } from 'rxjs/operators'
import { of } from 'rxjs'

import { catchFetchError } from '../../../utils/catchFetchError'
import { foldersService } from '../../../api'
import { prepareOptions } from '../../../utils/dropdown/prepareOptions'
import { DRAFTS_FOLDER } from '../../../constants/contracts'
import * as CONTRACTS_ACTIONS from '../contractsList/contractsList.actions'
import { prepareContractsListFetch } from '../contractsList/contractsList.utils'

import * as FOLDERS_ACTIONS from './folders.actions'

export const fetchFolders: Epic = (action$) =>
  action$.pipe(
    ofType(FOLDERS_ACTIONS.fetchFoldersTypes.request),
    mergeMap(() =>
      foldersService.fetchFolders().pipe(
        map((action) =>
          FOLDERS_ACTIONS.fetchFoldersSuccess([
            DRAFTS_FOLDER,
            ...prepareOptions(action.data, { nameKey: 'name', valueKey: 'id' }),
          ])
        ),
        catchError(catchFetchError(FOLDERS_ACTIONS.fetchFoldersFailure)),
        takeUntil(
          action$.pipe(ofType(FOLDERS_ACTIONS.fetchFoldersTypes.success, FOLDERS_ACTIONS.fetchFoldersTypes.failure))
        )
      )
    )
  )

export const fetchUploadFolder: Epic = (action$, state$) =>
  action$.pipe(
    ofType(FOLDERS_ACTIONS.fetchUploadFolderTypes.request),
    mergeMap(({ payload }) =>
      foldersService.fetchUploadFolder(payload.folder).pipe(
        mergeMap(() =>
          payload.isMobile
            ? of(
                CONTRACTS_ACTIONS.contractsListSetOffset(state$.value.contracts.contractsList.offset),
                CONTRACTS_ACTIONS.fetchContractsListData(
                  prepareContractsListFetch(state$.value.contracts.contractsList)
                ),
                FOLDERS_ACTIONS.fetchUploadFolderSuccess()
              )
            : of(CONTRACTS_ACTIONS.contractsListForceUpdate(), FOLDERS_ACTIONS.fetchUploadFolderSuccess())
        ),
        catchError(catchFetchError(FOLDERS_ACTIONS.fetchUploadFolderFailure)),
        takeUntil(
          action$.pipe(
            ofType(FOLDERS_ACTIONS.fetchUploadFolderTypes.success, FOLDERS_ACTIONS.fetchUploadFolderTypes.failure)
          )
        )
      )
    )
  )

export const foldersEpics = combineEpics(fetchFolders, fetchUploadFolder)
