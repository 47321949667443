import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

import { CountDownProps } from './CountDown.types'

const RADIUS = 15.915
const POSITION = RADIUS + 1
const WIDTH = POSITION * 2
const PERCENT_NUMBER = 100
const PER_SECOND = 1000
const VIEWBOX = `0 0 ${WIDTH} ${WIDTH}`

const StyledWrapper = styled.div`
  width: 42px;
  height: 42px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  line-height: 12px;
`
const StyledSVG = styled.svg`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`
const StyledCircle = styled.circle`
  stroke: ${(props) => props.theme.palette.blue};
  stroke-width: 1px;
  fill: none;
  transform: rotate(-90deg);
  transform-origin: center;
  transition: stroke-dasharray 1s;
`
const StyledCircleBg = styled.circle`
  stroke: ${(props) => props.theme.palette.greyExtraLight};
  stroke-width: 1px;
  fill: none;
`
const StyledSeconds = styled.div`
  position: relative;
  font-weight: 700;
`
const StyledLabel = styled.div`
  position: relative;
  color: ${(props) => props.theme.palette.grey};
`

export const CountDown: React.FC<CountDownProps> = ({ seconds = 30, onComplete }) => {
  const [counter, setCounter] = useState(seconds)
  const percent = PERCENT_NUMBER - Math.floor((counter / seconds) * PERCENT_NUMBER)
  const strokeDasharray = `${percent},100`

  useEffect(() => {
    let timeoutId: ReturnType<typeof setTimeout>

    if (counter > 0) {
      timeoutId = setTimeout(() => setCounter(counter - 1), PER_SECOND)
    } else {
      onComplete()
    }

    return () => clearTimeout(timeoutId)
  }, [counter])

  return (
    <StyledWrapper data-testid="countdown-wrapper">
      <StyledSVG data-testid="countdown-svg" viewBox={VIEWBOX} xmlns="http://www.w3.org/2000/svg">
        <StyledCircleBg data-testid="countdown-circlebg" cx={POSITION} cy={POSITION} r={RADIUS} />
        <StyledCircle
          data-testid="countdown-circle"
          cx={POSITION}
          cy={POSITION}
          r={RADIUS}
          strokeDasharray={strokeDasharray}
        />
      </StyledSVG>
      <StyledSeconds data-testid="countdown-seconds">{counter}</StyledSeconds>
      <StyledLabel data-testid="countdown-label">sec</StyledLabel>
    </StyledWrapper>
  )
}
