import React from 'react'
import { useSelector } from 'react-redux'

import { NOTIFICATION_MODAL_TYPES } from '../../../../ui/Modals/NotificationModal/NotificationModal.constants'
import { NotificationModalView } from '../../../../ui/Modals/NotificationModal/NotificationModalView'
import translations from '../../../../../translations/keys'
import { editProfileUnconfirmedEmailNewEmailSelector } from '../../../../../store/editProfile/editProfile.selectors'

import { ExpiredLinkModalProps } from './ExpiredLinkModal.types'

export const ExpiredLinkModal: React.FC<ExpiredLinkModalProps> = (props) => {
  const newEmail = useSelector(editProfileUnconfirmedEmailNewEmailSelector)

  return (
    <NotificationModalView
      type={NOTIFICATION_MODAL_TYPES.WARNING}
      onClose={props.onClose}
      title={translations.ERROR}
      description={newEmail ? translations.EXPIRED_NEW_EMAIL_VERIFICATION_LINK : translations.EXPIRED_VERIFICATION_LINK}
      successButtonText={newEmail ? translations.RESEND_EMAIL : undefined}
      onSuccess={newEmail ? props.onSuccess : undefined}
    />
  )
}
