export interface EditableElem {
  id?: string
  x: number
  y: number
  width: number
  height: number
}

export enum ResizeControls {
  TOP_LEFT = 'top-left',
  TOP_RIGHT = 'top-right',
  BOTTOM_RIGHT = 'bottom-right',
  BOTTOM_LEFT = 'bottom-left',
}
