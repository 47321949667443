import { useMemo, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'

import translations from '../../../../translations/keys'
import { CONTRACT_SIGN_MODALS } from '../../../constants/contractSign'
import { CONTRACT_MODALS } from '../../../../constants/contract'
import { isDeadlineExpired } from '../../../../utils/recipients'
import * as OWNER_SELECTORS from '../../../../store/contracts/ownerView/ownerView.selectors'
import * as RECIPIENTS_SELECTORS from '../../../../store/contracts/recipients/recipients.selectors'
import { openModal } from '../../../../store/modals/modals.actions'
import { COMMON_MODALS_IDS } from '../../../ui/Modals/CommonModals/CommonModals.constants'
import { NOTIFICATION_MODAL_TYPES } from '../../../ui/Modals/NotificationModal/NotificationModal.constants'
import { BUTTON_VARIANTS } from '../../../ui/Button/Button.types'
import { CloseCircleIcon } from '../../../assets/icons'
import { location } from '../../../../utils/location'
import { CONTRACT_PREVIEW_MODALS } from '../../../constants/contractPreview'

export const useButtons = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const recipientsById = useSelector(RECIPIENTS_SELECTORS.recipientsByIdSelector)
  const recipientsSorted = useSelector(RECIPIENTS_SELECTORS.recipientsSortedSelector)
  const isCompleted = useSelector(OWNER_SELECTORS.ownerViewContractIsCompletedSelector)
  const isSuccess = useSelector(OWNER_SELECTORS.ownerViewContractSuccessSelector)
  const isDeleted = useSelector(OWNER_SELECTORS.ownerViewContractIsDeletedSelector)
  const isNegative = useSelector(RECIPIENTS_SELECTORS.recipientsIsNegativeStatus)
  const resendFetchStatus = useSelector(RECIPIENTS_SELECTORS.recipientsFetchResendStatusSelector)

  const handleResend = () => {
    if (isDeadlineExpired(recipientsById, recipientsSorted)) {
      dispatch(
        openModal(COMMON_MODALS_IDS.NOTIFICATION, {
          type: NOTIFICATION_MODAL_TYPES.WARNING,
          description: translations.RECIPIENTS_WITH_EXPIRED_DEADLINE,
        })
      )
    } else {
      dispatch(openModal(CONTRACT_MODALS.CONTRACT_RESEND))
    }
  }

  const handleStartCycle = () => {
    dispatch(openModal(CONTRACT_PREVIEW_MODALS.SIGNING_ORDER_MODAL))
  }

  // if(resendFetchStatus === 'SUCCESS') history.push(location.contractsAllUrl())
  
  const buttons = useMemo(() => {
    if (!isSuccess) {
      return []
    }

    const cancel = {
      label: translations.CONTRACT_FINISH_LATER,
      onClick: () => {
        dispatch(openModal(CONTRACT_SIGN_MODALS.FINISH_SIGN_LATER))
      },
      MobileIcon: CloseCircleIcon,
      variant: BUTTON_VARIANTS.SECONDARY,
    }
    const send = {
      label: translations.SEND,
      onClick: () => {
        console.log('send')
      },
      // onClick: handleResend,
      MobileIcon: CloseCircleIcon,
      //TODO change when comments will be ready
    }
    const resend = {
      label: translations.RESEND,
      onClick: handleResend,
      MobileIcon: CloseCircleIcon,
      //TODO change when comments will be ready
    }

    return isCompleted || isDeleted ? [cancel] : [cancel, isNegative ? resend : send]
  }, [recipientsById, recipientsSorted, isCompleted, isSuccess, isDeleted, isNegative])

  return buttons
}
