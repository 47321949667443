import { RegistrationFields, RegistrationFormValues } from '../types/registration'

export const registrationInitialValues: RegistrationFormValues = {
  [RegistrationFields.firstName]: '',
  [RegistrationFields.lastName]: '',
  [RegistrationFields.phone]: '',
  [RegistrationFields.country]: '',
  [RegistrationFields.industry]: '',
  [RegistrationFields.password]: '',
  [RegistrationFields.confirmPassword]: '',
  [RegistrationFields.email]: '',
  [RegistrationFields.accepted]: false,
}
