import React from 'react'

import { TYPOGRAPHY_COMPONENTS, TYPOGRAPHY_NAMES } from '../../../../ui/Typography/Typography.types'
import translations from '../../../../../translations/keys'
import Typography from '../../../../ui/Typography'
import Search from '../../../../ui/Search'
import Box from '../../../../ui/Box'

import { StyledHeader, StyledSearchWrapper } from './FaqHeader.styles'

export const FaqHeader = () => (
  <StyledHeader>
    <Box mb={4}>
      <Typography name={TYPOGRAPHY_NAMES.H1} component={TYPOGRAPHY_COMPONENTS.h1} color="white">
        {translations.FAQ_HEADER_TITLE}
      </Typography>
    </Box>
    <StyledSearchWrapper>
      <Search placeholder={translations.SEARCH} value="" />
    </StyledSearchWrapper>
    <Typography name={TYPOGRAPHY_NAMES.body14} color="white">
      {translations.FAQ_HEADER_SUB_TITLE}
    </Typography>
  </StyledHeader>
)
