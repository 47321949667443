import React, { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'

import { getEnvs } from '../../../../../api/getEnvs'
import * as PAYMENT_SELECTORS from '../../../../../store/subscription/payment/payment.selectors'
import { PaymentNewCardSkeleton } from '../../../Skeletons/PaymentNewCardSkeleton'

import { StyledIFrame, StyledHiddenInput, StyledWrapper, StyledSkeletonWrapper } from './CardIFrame.styles'

const { REACT_APP_PAYFORT_URL } = getEnvs()

export const CardIFrame = () => {
  const formRef = useRef<HTMLFormElement>(null)
  const iFrameRef = useRef<HTMLIFrameElement>(null)
  const fields = useSelector(PAYMENT_SELECTORS.subscriptionPaymentFormFieldsSelector)

  useEffect(() => {
    if (formRef.current && fields) {
      const payFields = Object.keys(fields)

      payFields.forEach((key) => {
        const input = document.createElement('input')

        input.setAttribute('type', 'hidden')
        input.setAttribute('name', key)
        input.setAttribute('value', fields[key])

        formRef.current?.appendChild(input)
      })

      formRef.current.submit()
    }
  }, [])

  return (
    <StyledWrapper>
      <StyledSkeletonWrapper>
        <PaymentNewCardSkeleton />
      </StyledSkeletonWrapper>
      <StyledIFrame ref={iFrameRef} name="payment" id="pay-frame" />
      <form ref={formRef} action={`${REACT_APP_PAYFORT_URL}/FortAPI/paymentPage`} method="post" target="payment">
        <StyledHiddenInput type="submit" />
      </form>
    </StyledWrapper>
  )
}
