import { Epic, combineEpics, ofType } from 'redux-observable'
import { catchError, map, mergeMap, takeUntil } from 'rxjs/operators'

import { catchFetchError } from '../../utils/catchFetchError'
import { ekycService, signingService } from '../../api'
import SessionStorage from '../../utils/SessionStorage'
import { SessionStorageKeys } from '../../constants/sessionStorage'
import * as SIGNING_SELECTORS from '../contracts/signing/signing.selectors'

import * as ACTIONS from './ekyc.actions'

const fetchEnroll: Epic = (action$) =>
  action$.pipe(
    ofType(ACTIONS.fetchEKYCTypes.request),
    mergeMap(({ payload }) =>
      ekycService.fetchEnroll(payload.fileForFormData, payload.headers).pipe(
        map(({ response }) => ACTIONS.fetchEnrollSuccess(response)),
        catchError(catchFetchError(ACTIONS.fetchEnrollFailure)),
        takeUntil(action$.pipe(ofType(ACTIONS.fetchEKYCTypes.success, ACTIONS.fetchEKYCTypes.failure)))
      )
    )
  )

const fetchEnrollStatus: Epic = (action$) =>
  action$.pipe(
    ofType(ACTIONS.fetchEKYCStatusTypes.request),
    mergeMap(() =>
      ekycService.fetchEnrollStatus().pipe(
        map(() => ACTIONS.fetchEnrollStatusSuccess()),
        catchError(catchFetchError(ACTIONS.fetchEnrollStatusFailure)),
        takeUntil(action$.pipe(ofType(ACTIONS.fetchEKYCStatusTypes.success, ACTIONS.fetchEKYCStatusTypes.failure)))
      )
    )
  )

const fetchVerification: Epic = (action$) =>
  action$.pipe(
    ofType(ACTIONS.fetchEKYCVerificationTypes.request),
    mergeMap(({ payload }) =>
      ekycService.fetchVerification(payload.fileForFormData, payload.headers).pipe(
        map(({ response }) => {
          console.log("response in fetch verification", response);

          return ACTIONS.fetchVerificationSuccess(response);
        }),
        catchError(catchFetchError(ACTIONS.fetchVerificationFailure)),
        takeUntil(
          action$.pipe(ofType(ACTIONS.fetchEKYCVerificationTypes.success, ACTIONS.fetchEKYCVerificationTypes.failure))
        )
      )
    )
  )

const fetchVerifyEKYC: Epic = (action$, state$) =>
  action$.pipe(
    ofType(ACTIONS.fetchVerifyEKYCTypes.request),
    mergeMap(({ payload }) => {
      const signingData = SIGNING_SELECTORS.signingContractDocumentDataSelector(state$.value)
      const recipientId = signingData?.recipient?.id || ''
      const recipientToken = SIGNING_SELECTORS.signingRecipientTokenSelector(state$.value)
  
      // signingService.fetchVerifyEKYC(payload.fileForFormData, payload.headers, recipientId, recipientToken).subscribe(res => console.log(res));
  
      return (payload.isEKYC_ID
        ? signingService.fetchVerifyEKYC_ID(payload.fileForFormData, payload.headers, recipientId, recipientToken)
        : signingService.fetchVerifyEKYC(payload.fileForFormData, payload.headers, recipientId, recipientToken)
      ).pipe(
        map(({ response }) => {
          console.log("response in fetch verification: in 69", response);
          SessionStorage.set(SessionStorageKeys.VERIFICATION_TOKEN, response?.signingToken)
          return ACTIONS.fetchVerifyEKYCSuccess()
        }),
        catchError(catchFetchError(ACTIONS.fetchVerifyEKYCFailure)),
        takeUntil(action$.pipe(ofType(ACTIONS.fetchVerifyEKYCTypes.success, ACTIONS.fetchVerifyEKYCTypes.failure)))
      )
    })
  )

const fetchEKYCAttempts: Epic = (action$, state$) =>
  action$.pipe(
    ofType(ACTIONS.fetchEKYCAttemptsTypes.request),
    mergeMap(({ payload }) => {
      const recipientToken = SIGNING_SELECTORS.signingRecipientTokenSelector(state$.value)

      return signingService.fetchEKYCAttempts(payload.contractId, recipientToken).pipe(
        map((response) => ACTIONS.fetchEKYCAttemptsSuccess(response)),
        catchError(catchFetchError(ACTIONS.fetchEKYCAttemptsFailure)),
        takeUntil(action$.pipe(ofType(ACTIONS.fetchEKYCAttemptsTypes.success, ACTIONS.fetchEKYCAttemptsTypes.failure)))
      )
    })
  )

export const ekycEpics = combineEpics(
  fetchEnroll,
  fetchEnrollStatus,
  fetchVerification,
  fetchVerifyEKYC,
  fetchEKYCAttempts
)
