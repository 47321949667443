import { useDispatch } from 'react-redux'
import { useEffect } from 'react'

import { OwnerViewNotificationMessages } from '../../store/contracts/ownerView/ownerView.types'
import { ConfirmationStatus } from '../../types/confirmationActions'
import * as OWNER_VIEW_ACTIONS from '../../store/contracts/ownerView/ownerView.actions'
import { Variant } from '../../types/notifications'

export const useConfirmationNotification = (isConfirmed: boolean, confirmStatus: ConfirmationStatus | null) => {
  const dispatch = useDispatch()

  useEffect(() => {
    if (isConfirmed && confirmStatus) {
      dispatch(
        OWNER_VIEW_ACTIONS.setOwnerViewNotification({
          variant: Variant.SUCCESS,
          message:
            confirmStatus === ConfirmationStatus.ACCEPTED
              ? OwnerViewNotificationMessages.ACCEPTED_SUCCESSFULLY
              : OwnerViewNotificationMessages.REJECTED_SUCCESSFULLY,
        })
      )
    }
  }, [isConfirmed, confirmStatus])
}
