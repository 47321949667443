import { FetchFailureAction, FETCH_STATUSES } from '../../../types/fetch'
import { createReducer } from '../../createReducer'

import * as ACTIONS from './notifications.actions'
import * as TYPES from './notifications.types'

type Actions = FetchFailureAction | TYPES.FetchNotificationsSuccess | TYPES.UpdateNotificationsSuccess

export const initialState: TYPES.NotificationsState = {
  fetchStatus: FETCH_STATUSES.IDLE,
  error: null,
  data: null,
  update: {
    fetchStatus: FETCH_STATUSES.IDLE,
    error: null,
  },
}

export const notifications = createReducer<TYPES.NotificationsState, Actions>(initialState, {
  [ACTIONS.fetchNotificationsTypes.request]: (state) => ({
    ...state,
    fetchStatus: FETCH_STATUSES.REQUEST,
  }),
  [ACTIONS.fetchNotificationsTypes.success]: (state, action) => ({
    ...state,
    fetchStatus: FETCH_STATUSES.SUCCESS,
    data: (action as TYPES.FetchNotificationsSuccess).payload.notifications,
  }),
  [ACTIONS.fetchNotificationsTypes.failure]: (state, action) => ({
    ...state,
    fetchStatus: FETCH_STATUSES.FAILURE,
    error: (action as FetchFailureAction).payload,
  }),
  [ACTIONS.updateNotificationsTypes.request]: (state) => ({
    ...state,
    update: {
      ...state.update,
      fetchStatus: FETCH_STATUSES.REQUEST,
    },
  }),
  [ACTIONS.updateNotificationsTypes.success]: (state, action) => ({
    ...state,
    update: {
      ...state.update,
      fetchStatus: FETCH_STATUSES.SUCCESS,
    },
    data: (action as TYPES.UpdateNotificationsSuccess).payload.notifications,
  }),
  [ACTIONS.updateNotificationsTypes.failure]: (state, action) => ({
    ...state,
    update: {
      ...state.update,
      fetchStatus: FETCH_STATUSES.FAILURE,
      error: (action as FetchFailureAction).payload,
    },
  }),
  [TYPES.NotificationsActions.CLEAN_PREFERENCES_NOTIFICATIONS]: () => ({ ...initialState }),
})
