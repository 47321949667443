import React from 'react'
import { useTheme } from 'styled-components'
import { useTranslation } from 'react-i18next'

import { StyledThemeScheme } from '../../../themes/light'
import useMediaQuery from '../../hooks/useMediaQuery'
import Typography from '../../ui/Typography'
import { TYPOGRAPHY_NAMES } from '../../ui/Typography/Typography.types'
import Link from '../../ui/Link'
import { location } from '../../../utils/location'

import { PreviewMenu } from './components/PreviewMenu/PreviewMenu'
import { PreviewHeaderProps } from './PreviewHeader.types'
import {
  StyledHeaderWrapper,
  StyledBtn,
  StyledBtnsContainer,
  StyledInactiveBtn,
  StyledInactiveBtnsContainer,
  StyledTitle,
  Logo,
  LogoWrapper,
} from './PreviewHeader.styles'

export const PreviewHeader: React.FC<PreviewHeaderProps> = ({ actions = [], buttons, title }) => {
  const theme = useTheme() as StyledThemeScheme
  const { t } = useTranslation()
  const mobileScreen = useMediaQuery(theme.devices.mobile)

  return (
    <StyledHeaderWrapper data-testid="contract-preview-header">
      <LogoWrapper data-testid="header-logo-wrapper">
        <Link to={location.registrationUrl()}>
          <Logo withText={false} />
        </Link>
      </LogoWrapper>
      <StyledTitle>
        <Typography name={TYPOGRAPHY_NAMES.H2}>{title}</Typography>
      </StyledTitle>
      {mobileScreen ? (
        <PreviewMenu actions={actions} buttons={buttons} />
      ) : (
        <>
          <StyledInactiveBtnsContainer>
            {actions.map((action) => (
              <StyledInactiveBtn key={action.label} icon={action.buttonIcon} onClick={action.onClick}>
                {t(action.label)}
              </StyledInactiveBtn>
            ))}
          </StyledInactiveBtnsContainer>
          <StyledBtnsContainer>
            {buttons.map((button) =>
              button && !button.isMobileOnly ? (
                <StyledBtn
                  key={button.label}
                  variant={button.variant}
                  onClick={button.onClick}
                  disabled={button.disabled}
                >
                  {t(button.label)}
                </StyledBtn>
              ) : null
            )}
          </StyledBtnsContainer>
        </>
      )}
    </StyledHeaderWrapper>
  )
}
