import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { modalsVisibleSelector } from '../../../../../store/modals/modals.selectors'
import { closeModal } from '../../../../../store/modals/modals.actions'
import { CONTRACTS_MODALS } from '../../../../../constants/contracts'
import { PLACEMENT_MODALS } from '../../../../constants/placement'
import { TEMPLATE_MODALS } from '../../../../constants/template'
import ResetModal from '../../../../components/Modals/PlacementModals/ResetModal'
import ReuploadConfirmModal from '../../../../components/Modals/PlacementModals/ReuploadConfirmModal'
import ReuploadModal from '../../../../components/Modals/PlacementModals/ReuploadModal'
import CreateFolderForTemplateModal from '../../../../components/Modals/TemplatesModals/CreateFolderForTemplateModal'
import DiscardConfirmModal from '../../../../components/Modals/DocumentModals/DiscardConfirmModal'

import { PlacementModalsProps } from './PlacementModals.types'

export const PlacementModals: React.FC<PlacementModalsProps> = ({
  reuploading,
  onReset,
  onDiscard,
  onReuploadConfirm,
  onReupload,
}) => {
  const dispatch = useDispatch()

  const showCreateFolderForTemplate = useSelector(modalsVisibleSelector(CONTRACTS_MODALS.CREATE_FOLDER_FOR_TEMPLATE))
  const handleCreateFolderForTemplateClose = () => {
    dispatch(closeModal(CONTRACTS_MODALS.CREATE_FOLDER_FOR_TEMPLATE))
  }

  const showResetPlacement = useSelector(modalsVisibleSelector(PLACEMENT_MODALS.PLACEMENT_RESET))
  const onCloseResetPlacement = () => {
    dispatch(closeModal(PLACEMENT_MODALS.PLACEMENT_RESET))
  }

  const showDiscardConfirmModal = useSelector(modalsVisibleSelector(TEMPLATE_MODALS.DISCARD_CONFIRMATION))
  const onCloseDiscardConfirmModal = () => {
    dispatch(closeModal(TEMPLATE_MODALS.DISCARD_CONFIRMATION))
  }

  const showReuploadConfirm = useSelector(modalsVisibleSelector(PLACEMENT_MODALS.REUPLOAD_CONFIRMATION))
  const onCloseReuploadConfirmation = () => {
    dispatch(closeModal(PLACEMENT_MODALS.REUPLOAD_CONFIRMATION))
  }

  const showReupload = useSelector(modalsVisibleSelector(PLACEMENT_MODALS.REUPLOAD))
  const onCloseReupload = () => {
    dispatch(closeModal(PLACEMENT_MODALS.REUPLOAD))
  }

  return (
    <>
      {showCreateFolderForTemplate && <CreateFolderForTemplateModal onClose={handleCreateFolderForTemplateClose} />}
      {showResetPlacement && <ResetModal onReset={onReset} onClose={onCloseResetPlacement} />}
      {showDiscardConfirmModal && <DiscardConfirmModal onDiscard={onDiscard} onClose={onCloseDiscardConfirmModal} />}
      {showReuploadConfirm && (
        <ReuploadConfirmModal onReuploadConfirm={onReuploadConfirm} onClose={onCloseReuploadConfirmation} />
      )}
      {showReupload && <ReuploadModal loading={reuploading} onReupload={onReupload} onClose={onCloseReupload} />}
    </>
  )
}
