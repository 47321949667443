import { createReducer } from '../createReducer'

import * as TYPES from './banner.types'

type Actions = TYPES.CloseBannerAction | TYPES.OpenBannerAction

export const initialState: TYPES.BannerState = {
  visible: false,
}

export const banner = createReducer<TYPES.BannerState, Actions>(initialState, {
  [TYPES.BannerActions.OPEN_BANNER]: () => ({
    visible: true,
  }),
  [TYPES.BannerActions.CLOSE_BANNER]: () => ({
    visible: false,
  }),
})
