import React from 'react'
import ContentLoader from 'react-content-loader'

import { blueExtraLight, glowColor } from '../../../themes/colors'

const FILL_COLOR = blueExtraLight
const GLOW_COLOR = glowColor
const SPEED = 1.5
const SVG_HEIGHT = 506
const RADIUS = 3

export const SubscriptionPaymentSkeleton = () => (
  <ContentLoader
    display="block"
    width="100%"
    height={SVG_HEIGHT}
    speed={SPEED}
    backgroundColor={FILL_COLOR}
    foregroundColor={GLOW_COLOR}
  >
    <rect width="100%" height="100%" x="0" y="0" rx={RADIUS} ry={RADIUS} />
  </ContentLoader>
)
