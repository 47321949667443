import React from 'react'

import translations from '../../../../../../../../translations/keys'
import Button from '../../../../../../../ui/Button'
import { BUTTON_VARIANTS } from '../../../../../../../ui/Button/Button.types'
import Typography from '../../../../../../../ui/Typography'
import { TYPOGRAPHY_NAMES } from '../../../../../../../ui/Typography/Typography.types'
import Box from '../../../../../../../ui/Box'
import { MOBILE_CREATE_SIGNATURE_STEPS } from '../../ModalMobile.constants'

import { PreviewProps } from './Preview.types'
import { StyledImgWrapper } from './Preview.styles'

export const Preview: React.FC<PreviewProps> = ({ onChangeStep, signature, initials, onSubmit }) => (
  <Box display="flex" flexDirection="column" width="100%" height="100%" justifyContent="space-between">
    {signature && (
      <Box display="flex" flexDirection="column" alignItems="center">
        <Box display="flex" justifyContent="space-between" mb={4} width="100%">
          <Typography name={TYPOGRAPHY_NAMES.body16}>{translations.SIGNATURE}</Typography>
          <Button
            variant={BUTTON_VARIANTS.INACTIVE}
            icon="reload"
            onClick={onChangeStep(MOBILE_CREATE_SIGNATURE_STEPS.SIGNATURE)}
          >
            {translations.CHANGE}
          </Button>
        </Box>
        <StyledImgWrapper>
          <img src={signature} alt="signature" />
        </StyledImgWrapper>
      </Box>
    )}
    {initials && (
      <Box display="flex" flexDirection="column" alignItems="center" mt={5}>
        <Box display="flex" justifyContent="space-between" mb={4} width="100%">
          <Typography name={TYPOGRAPHY_NAMES.body16}>{translations.INITIALS}</Typography>
          <Button
            variant={BUTTON_VARIANTS.INACTIVE}
            icon="reload"
            onClick={onChangeStep(MOBILE_CREATE_SIGNATURE_STEPS.INITIALS)}
          >
            {translations.CHANGE}
          </Button>
        </Box>
        <StyledImgWrapper>
          <img src={initials} alt="signature" />
        </StyledImgWrapper>
      </Box>
    )}
    <Box mt={8}>
      <Button fullWidth onClick={onSubmit}>
        {translations.SUBMIT}
      </Button>
    </Box>
  </Box>
)
