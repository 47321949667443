import styled from 'styled-components'

import { Error403Svg } from '../../assets/Error403Svg'

export const StyledError403Svg = styled(Error403Svg)`
  ${({ theme }) => `@media ${theme.devices.mobile} {
    width: 208px;
    height: 133px;
  }`}
`
