
import * as Cookies from "js-cookie";

import {Ajax} from "../../api/ajax";

type AuthData = {
  id: string
  authentication: string
  refresh: string
}

export const saveTokensToAsyncStorage = (authData: AuthData) => {
  console.log(authData)
  Cookies.set('Authentication', authData.authentication);
  Ajax.mobileHeaders = { 'cookie': 'Authentication='+authData.authentication }

  console.log(Ajax.mobileHeaders);
}

export const clearAsyncStorage = () => {}
