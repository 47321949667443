import React, { useState, useRef } from 'react'
import styled from 'styled-components'
import ReactDOM from 'react-dom'

import Button from '../Button'
import { BUTTON_VARIANTS } from '../Button/Button.types'
import Popover from '../Popover'

import { TableAction, TableActionsProps } from './TableActions.types'
import { TableActionsKebab } from './TableActionsKebab'

const StyledMenu = styled.ul`
  list-style: none;
`
const StyledMenuLink = styled(Button).attrs({ fullWidth: true, variant: BUTTON_VARIANTS.TEXT })`
  display: flex;
  justify-content: flex-start;
  padding: 10px 18px;
  color: ${({ theme }) => theme.palette.textColor};
  font-size: 16px;
  line-height: 1;
  transition: padding 300ms 200ms, background-color 200ms;

  &:hover {
    background-color: ${({ theme }) => theme.palette.blueExtraLight};
    text-decoration: none;
  }
`
const StyledIconWrapper = styled.div<{ disabled?: boolean }>`
  display: inline-flex;
  margin-right: 8px;

  ${({ disabled }) => disabled && 'opacity: 0.5'}
`

export const TableActions: React.FC<TableActionsProps> = ({ data, actions }) => {
  const [visible, setVisible] = useState(false)
  const targetRef = useRef(null)

  const handleDropdownOpen = () => {
    setVisible((prevValue) => !prevValue)
  }

  const handleClose = () => {
    setVisible(false)
  }

  const handleMenuClick = (callback: TableAction['callback']) => () => {
    setVisible(false)
    callback(data)
  }

  return (
    <TableActionsKebab ref={targetRef} visible={visible} onClick={handleDropdownOpen}>
      {ReactDOM.createPortal(
        <Popover
          width="auto"
          padding="4px 0"
          offsetLeft={-12}
          targetRef={targetRef}
          visible={visible}
          onClose={handleClose}
          placement="bottom-start"
        >
          <StyledMenu>
            {actions.map(({ name, Icon, callback, show = () => true, disable = () => false }) => {
              const actionName = typeof name === 'function' ? name(data) : name

              return show(data) ? (
                <li key={actionName}>
                  <StyledMenuLink onClick={handleMenuClick(callback)} disabled={disable(data)}>
                    <StyledIconWrapper>
                      <Icon color="grey" />
                    </StyledIconWrapper>
                    {actionName}
                  </StyledMenuLink>
                </li>
              ) : null
            })}
          </StyledMenu>
        </Popover>,
        document.getElementsByTagName('body')[0]
      )}
    </TableActionsKebab>
  )
}
