import React from 'react'

import ErrorBoundary from '../../components/ErrorBoundary/ErrorBoundary'

import { ContractAddRecipients as ContractAddRecipientsView } from './ContractAddRecipients'

export const ContractAddRecipients = () => (
  <ErrorBoundary>
    <ContractAddRecipientsView />
  </ErrorBoundary>
)
