import { EditProfileInitialDataResponse } from '../../types/editProfile'
import { FetchFailureAction, FETCH_STATUSES } from '../../types/fetch'
import { createReducer } from '../createReducer'

import * as ACTIONS from './editProfile.actions'
import * as TYPES from './editProfile.types'

type Actions =
  | FetchFailureAction
  | TYPES.EditProfileChangePhoneAction
  | TYPES.EditProfileChangePhoneSuccessAction
  | TYPES.EditProfileUnconfirmedEmailSuccessAction
  | TYPES.EditProfileChangeEmailSuccessAction
  | TYPES.EditProfileInitialDataActionSuccessAction
  | TYPES.EditProfileConfirmPhoneSuccessAction

const initialState: TYPES.EditProfileState = {
  phone: {
    fetchStatus: FETCH_STATUSES.IDLE,
    phoneNumber: '',
  },
  confirmPhone: {
    fetchStatus: FETCH_STATUSES.IDLE,
  },
  email: {
    fetchStatus: FETCH_STATUSES.IDLE,
  },
  data: {
    fetchStatus: FETCH_STATUSES.IDLE,
  },
  resendVerification: {
    fetchStatus: FETCH_STATUSES.IDLE,
  },
  unconfirmedEmail: {
    fetchStatus: FETCH_STATUSES.IDLE,
    newEmail: undefined,
  },
  preventEmailUpdate: {
    fetchStatus: FETCH_STATUSES.IDLE,
  },
  initialData: {
    fetchStatus: FETCH_STATUSES.IDLE,
  },
}

export const editProfile = createReducer<TYPES.EditProfileState, Actions>(initialState, {
  [ACTIONS.fetchEditProfileChangePhoneTypes.request]: (state) => ({
    ...state,
    phone: {
      ...state.phone,
      fetchStatus: FETCH_STATUSES.REQUEST,
    },
  }),
  [ACTIONS.fetchEditProfileChangePhoneTypes.success]: (state, action) => ({
    ...state,
    phone: {
      ...state.phone,
      fetchStatus: FETCH_STATUSES.SUCCESS,
      phoneNumber: (action as TYPES.EditProfileChangePhoneSuccessAction).payload.phone,
    },
  }),
  [ACTIONS.fetchEditProfileChangePhoneTypes.failure]: (state, action) => ({
    ...state,
    phone: {
      ...state.phone,
      fetchStatus: FETCH_STATUSES.FAILURE,
      error: (action as FetchFailureAction).payload,
    },
  }),
  [TYPES.EditProfileActions.RESET_EDIT_PROFILE_PHONE_FETCH_STATUS]: (state) => ({
    ...state,
    phone: {
      ...state.phone,
      fetchStatus: initialState.phone.fetchStatus,
    },
  }),
  [TYPES.EditProfileActions.RESET_EDIT_PROFILE]: () => ({
    ...initialState,
  }),
  [ACTIONS.fetchEditProfileConfirmPhoneTypes.request]: (state) => ({
    ...state,
    confirmPhone: {
      ...state.confirmPhone,
      fetchStatus: FETCH_STATUSES.REQUEST,
    },
  }),
  [ACTIONS.fetchEditProfileConfirmPhoneTypes.success]: (state, action) => ({
    ...state,
    confirmPhone: {
      ...state.confirmPhone,
      fetchStatus: FETCH_STATUSES.SUCCESS,
    },
    initialData: {
      ...state.initialData,
      values: {
        ...(state.initialData.values as EditProfileInitialDataResponse),
        phone: (action as TYPES.EditProfileConfirmPhoneSuccessAction).payload.phone,
      },
    },
  }),
  [ACTIONS.fetchEditProfileConfirmPhoneTypes.failure]: (state, action) => ({
    ...state,
    confirmPhone: {
      ...state.confirmPhone,
      fetchStatus: FETCH_STATUSES.FAILURE,
      error: (action as FetchFailureAction).payload,
    },
  }),
  [TYPES.EditProfileActions.RESET_EDIT_PROFILE_PHONE_DATA]: (state) => ({
    ...state,
    phone: initialState.phone,
    confirmPhone: initialState.confirmPhone,
  }),
  [ACTIONS.fetchEditProfileChangeEmailTypes.request]: (state) => ({
    ...state,
    email: {
      ...state.email,
      fetchStatus: FETCH_STATUSES.REQUEST,
    },
  }),
  [ACTIONS.fetchEditProfileChangeEmailTypes.success]: (state, action) => ({
    ...state,
    email: {
      ...state.email,
      fetchStatus: FETCH_STATUSES.SUCCESS,
    },
    unconfirmedEmail: {
      ...state.unconfirmedEmail,
      newEmail: (action as TYPES.EditProfileChangeEmailSuccessAction).payload.newEmail,
    },
  }),
  [ACTIONS.fetchEditProfileChangeEmailTypes.failure]: (state, action) => ({
    ...state,
    email: {
      ...state.email,
      fetchStatus: FETCH_STATUSES.FAILURE,
      error: (action as FetchFailureAction).payload,
    },
  }),
  [TYPES.EditProfileActions.RESET_EDIT_PROFILE_EMAIL_DATA]: (state) => ({
    ...state,
    email: {
      ...initialState.email,
    },
  }),
  [ACTIONS.fetchEditProfileTypes.request]: (state) => ({
    ...state,
    data: {
      ...state.data,
      fetchStatus: FETCH_STATUSES.REQUEST,
    },
  }),
  [ACTIONS.fetchEditProfileTypes.success]: (state) => ({
    ...state,
    data: {
      ...state.data,
      fetchStatus: FETCH_STATUSES.SUCCESS,
    },
  }),
  [ACTIONS.fetchEditProfileTypes.failure]: (state, action) => ({
    ...state,
    data: {
      ...state.data,
      fetchStatus: FETCH_STATUSES.FAILURE,
      error: (action as FetchFailureAction).payload,
    },
  }),
  [ACTIONS.fetchEditProfileResendVerificationTypes.request]: (state) => ({
    ...state,
    resendVerification: {
      ...state.resendVerification,
      fetchStatus: FETCH_STATUSES.REQUEST,
    },
  }),
  [ACTIONS.fetchEditProfileResendVerificationTypes.success]: (state) => ({
    ...state,
    resendVerification: {
      ...state.resendVerification,
      fetchStatus: FETCH_STATUSES.SUCCESS,
    },
  }),
  [ACTIONS.fetchEditProfileResendVerificationTypes.failure]: (state, action) => ({
    ...state,
    resendVerification: {
      ...state.resendVerification,
      fetchStatus: FETCH_STATUSES.FAILURE,
      error: (action as FetchFailureAction).payload,
    },
  }),
  [ACTIONS.fetchEditProfileUnconfirmedEmailTypes.request]: (state) => ({
    ...state,
    unconfirmedEmail: {
      ...state.unconfirmedEmail,
      fetchStatus: FETCH_STATUSES.REQUEST,
    },
  }),
  [ACTIONS.fetchEditProfileUnconfirmedEmailTypes.success]: (state, action) => ({
    ...state,
    unconfirmedEmail: {
      ...state.unconfirmedEmail,
      fetchStatus: FETCH_STATUSES.SUCCESS,
      newEmail: (action as TYPES.EditProfileUnconfirmedEmailSuccessAction).payload.newEmail,
    },
  }),
  [ACTIONS.fetchEditProfileUnconfirmedEmailTypes.failure]: (state, action) => ({
    ...state,
    unconfirmedEmail: {
      ...state.unconfirmedEmail,
      fetchStatus: FETCH_STATUSES.FAILURE,
      error: (action as FetchFailureAction).payload,
    },
  }),
  [ACTIONS.fetchEditProfilePreventEmailUpdateTypes.request]: (state) => ({
    ...state,
    preventEmailUpdate: {
      ...state.preventEmailUpdate,
      fetchStatus: FETCH_STATUSES.REQUEST,
    },
  }),
  [ACTIONS.fetchEditProfilePreventEmailUpdateTypes.success]: (state) => ({
    ...state,
    preventEmailUpdate: {
      ...state.preventEmailUpdate,
      fetchStatus: FETCH_STATUSES.SUCCESS,
    },
    unconfirmedEmail: {
      ...state.unconfirmedEmail,
      newEmail: initialState.unconfirmedEmail.newEmail,
    },
  }),
  [ACTIONS.fetchEditProfilePreventEmailUpdateTypes.failure]: (state, action) => ({
    ...state,
    preventEmailUpdate: {
      ...state.preventEmailUpdate,
      fetchStatus: FETCH_STATUSES.FAILURE,
      error: (action as FetchFailureAction).payload,
    },
  }),
  [ACTIONS.fetchEditProfileInitialDataTypes.request]: (state) => ({
    ...state,
    initialData: {
      ...state.initialData,
      fetchStatus: FETCH_STATUSES.REQUEST,
    },
  }),
  [ACTIONS.fetchEditProfileInitialDataTypes.success]: (state, action) => ({
    ...state,
    initialData: {
      ...state.initialData,
      fetchStatus: FETCH_STATUSES.SUCCESS,
      values: (action as TYPES.EditProfileInitialDataActionSuccessAction).payload.body,
    },
  }),
  [ACTIONS.fetchEditProfileInitialDataTypes.failure]: (state, action) => ({
    ...state,
    initialData: {
      ...state.initialData,
      fetchStatus: FETCH_STATUSES.FAILURE,
      error: (action as FetchFailureAction).payload,
    },
  }),
})
