import { FetchFailureAction, FETCH_STATUSES } from '../../types/fetch'
import { createReducer } from '../createReducer'

import * as ACTIONS from './registration.actions'
import * as TYPES from './registration.types'

type Actions =
  | TYPES.FetchRegistrationAction
  | TYPES.FetchRegistrationSuccessAction
  | TYPES.FetchRegistrationPhoneConfirmAction
  | TYPES.FetchRegistrationPhoneConfirmSuccessAction
  | FetchFailureAction
  | TYPES.FetchRegistrationResendCodeSuccessAction

export const initialState: TYPES.RegistrationState = {
  signUpFetchStatus: FETCH_STATUSES.IDLE,
  confirmPhoneFetchStatus: FETCH_STATUSES.IDLE,
  resendCodeFetchStatus: FETCH_STATUSES.IDLE,
  userId: '',
  linkId: '',
}

export const registration = createReducer<TYPES.RegistrationState, Actions>(initialState, {
  [ACTIONS.fetchSignUpTypes.request]: (state) => ({
    ...state,
    signUpFetchStatus: FETCH_STATUSES.REQUEST,
  }),
  [ACTIONS.fetchSignUpTypes.success]: (state, action) => {
    const act = action as TYPES.FetchRegistrationSuccessAction
    return {
      ...state,
      signUpFetchStatus: FETCH_STATUSES.SUCCESS,
      userId: act.payload.response.userId,
      linkId: act.payload.response.linkId,
      registrationForm: act.payload.registrationForm,
    }
  },
  [ACTIONS.fetchSignUpTypes.failure]: (state, action) => ({
    ...state,
    signUpFetchStatus: FETCH_STATUSES.FAILURE,
    signUpError: (action as FetchFailureAction).payload,
  }),
  [TYPES.RegistrationActions.FETCH_REGISTRATION_CLEAN_FETCH_STATUS]: (state) => ({
    ...state,
    signUpFetchStatus: FETCH_STATUSES.IDLE,
  }),
  [ACTIONS.fetchPhoneConfirmTypes.request]: (state) => ({
    ...state,
    confirmPhoneFetchStatus: FETCH_STATUSES.REQUEST,
  }),
  [ACTIONS.fetchPhoneConfirmTypes.success]: (state) => ({
    ...state,
    confirmPhoneFetchStatus: FETCH_STATUSES.SUCCESS,
  }),
  [ACTIONS.fetchPhoneConfirmTypes.failure]: (state, action) => ({
    ...state,
    confirmPhoneFetchStatus: FETCH_STATUSES.FAILURE,
    confirmPhoneError: (action as FetchFailureAction).payload,
  }),
  [ACTIONS.fetchResendCodeTypes.request]: (state) => ({
    ...state,
    resendCodeFetchStatus: FETCH_STATUSES.REQUEST,
  }),
  [ACTIONS.fetchResendCodeTypes.success]: (state, action) => ({
    ...state,
    resendCodeFetchStatus: FETCH_STATUSES.SUCCESS,
    linkId: (action as TYPES.FetchRegistrationResendCodeSuccessAction).payload.linkId,
  }),
  [ACTIONS.fetchResendCodeTypes.failure]: (state, action) => ({
    ...state,
    resendCodeFetchStatus: FETCH_STATUSES.FAILURE,
    resendCodeError: (action as FetchFailureAction).payload,
  }),
  [TYPES.RegistrationActions.RESET_REGISTRATION]: () => ({ ...initialState }),
})
