import React from 'react'
import { useSelector } from 'react-redux'

import * as SELECTORS from '../../../store/templates/list/list.selectors'
import { useShowFetchError } from '../../hooks/useShowFetchError'
import { useTemplatesListFetch } from '../../../hooks/templates/useTemplatesListFetch'
import { TemplatesListSkeleton } from '../../components/Skeletons/TemplatesListSkeleton'

import Modals from './components/Modals'
import List from './components/List'
import EmptyTemplates from './components/EmptyTemplates'
import { TemplatesProps } from './Templates.types'

export const Templates: React.FC<TemplatesProps> = ({ category }) => {
  const templates = useSelector(SELECTORS.templatesListDataSelector)
  const loading = useSelector(SELECTORS.templatesListLoadingSelector)
  const isFirstLoad = useSelector(SELECTORS.templatesListIsFirstLoadSelector)
  const searchText = useSelector(SELECTORS.templatesListSearchTextSelector)
  const fetchStatus = useSelector(SELECTORS.templatesListFetchStatusSelector)
  const error = useSelector(SELECTORS.templatesListErrorSelector)
  const folderId = useSelector(SELECTORS.templatesListFolderId)
  const isEmptyTemplates = !templates.length
  const isFirstLoading = isEmptyTemplates && loading && isFirstLoad
  const showPlaceholder = isEmptyTemplates && !searchText && !loading && !folderId

  useTemplatesListFetch(category)
  useShowFetchError(fetchStatus, error)

  const renderContent = () => {
    if (isFirstLoading) {
      return <TemplatesListSkeleton />
    } else if (showPlaceholder) {
      return <EmptyTemplates />
    }
    return <List />
  }

  return (
    <>
      {renderContent()}
      <Modals />
    </>
  )
}
