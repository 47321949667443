import { SuggestionDataItem } from 'react-mentions'

import { Suggestion } from '../../../../types/comment'
import { MENTION_TRIGGER } from '../../../../constants/mentions'

export const prepareSuggestionData = (suggestions: Suggestion[]): SuggestionDataItem[] =>
  suggestions.map((suggestion) => ({
    id: suggestion.email,
    display: suggestion.name,
  }))

export const prepareMentions = (mentions: SuggestionDataItem[]): Suggestion[] =>
  mentions.map((mention) => {
    const display = mention.display
    const nameWithoutAtSymbol = display?.[0] === MENTION_TRIGGER ? display?.slice(1) : display

    return {
      email: String(mention.id),
      name: nameWithoutAtSymbol || '',
    }
  })
