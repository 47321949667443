import { FetchFailureAction, FetchFailurePayload } from '../../../types/fetch'
import { createFetchActionTypes } from '../../../utils/createFetchActionTypes'

import * as TYPES from './clearAndContinue.types'

export const fetchClearAndContinueContractTypes = createFetchActionTypes(
  TYPES.ClearAndContinueActions.FETCH_CLEAR_AND_CONTINUE_CONTRACT
)

export const fetchClearAndContinueContract = (contractId: string): TYPES.FetchClearAndContinueContract => ({
  type: fetchClearAndContinueContractTypes.request,
  payload: { contractId },
})

export const fetchClearAndContinueContractSuccess = (contractId = ''): TYPES.FetchClearAndContinueContractSuccess => ({
  type: fetchClearAndContinueContractTypes.success,
  payload: { contractId },
})

export const fetchClearAndContinueContractFailure = (payload: FetchFailurePayload): FetchFailureAction => ({
  type: fetchClearAndContinueContractTypes.failure,
  payload,
})
