import React from 'react'
import { useTranslation } from 'react-i18next'

import Modal from '../../../Modal'
import Box from '../../../../ui/Box'
import translations from '../../../../../translations/keys'
import Typography from '../../../../ui/Typography'
import { TYPOGRAPHY_NAMES } from '../../../../ui/Typography/Typography.types'
import { AppStoreSvg } from '../../../../assets/AppStoreSvg'
import { GooglePlaySvg } from '../../../../assets/GooglePlaySvg'
import { PhoneWithHandSvg } from '../../../../assets/PhoneWithHandSvg'

import { SmartSignatureModalProps } from './SmartSignatureModal.types'
import { StyledStores } from './SmartSignatureModal.styles'

export const SmartSignatureModal: React.FC<SmartSignatureModalProps> = ({ onClose }) => {
  const { t } = useTranslation()

  return (
    <Modal onClose={onClose}>
      <Box display="flex" justifyContent="center" flexDirection="column" alignItems="center" mt={3} mb={3}>
        <PhoneWithHandSvg />
        <Box mt={3} mb={3} mtMobile={5}>
          <Typography name={TYPOGRAPHY_NAMES.H1}>{t(translations.ADD_NEW_SIGNATURE)}</Typography>
        </Box>
        <Box textAlign="center" width={568} mobileWidth="100%">
          {t(translations.ADD_NEW_SIGNATURE_DESCRIPTION)}
        </Box>
        <StyledStores>
          <Box>
            <AppStoreSvg />
          </Box>
          <Box ml={3} mlMobile={0} mtMobile={5}>
            <GooglePlaySvg />
          </Box>
        </StyledStores>
      </Box>
    </Modal>
  )
}
