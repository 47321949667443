import { FetchFailureAction, FETCH_STATUSES } from '../../types/fetch'
import { createReducer } from '../createReducer'

import * as ACTIONS from './auth.actions'
import * as TYPES from './auth.types'

type Actions = TYPES.SetUserInfoAction | TYPES.FetchLoginAction | TYPES.FetchLoginSuccessAction | FetchFailureAction

export const initialState: TYPES.AuthState = {
  loginFetchStatus: FETCH_STATUSES.IDLE,
  resendEmailFetchStatus: FETCH_STATUSES.IDLE,
  user: null,
}

export const auth = createReducer<TYPES.AuthState, Actions>(initialState, {
  [TYPES.AuthActions.SET_USER_INFO]: (state, action) => ({
    ...state,
    user: (action as TYPES.SetUserInfoAction).payload.user,
  }),
  [ACTIONS.fetchLoginTypes.request]: (state) => ({
    ...state,
    loginFetchStatus: FETCH_STATUSES.REQUEST,
  }),
  [ACTIONS.fetchLoginTypes.success]: (state) => ({
    ...state,
    loginFetchStatus: FETCH_STATUSES.SUCCESS,
  }),
  [ACTIONS.fetchLoginTypes.failure]: (state, action) => ({
    ...state,
    loginFetchStatus: FETCH_STATUSES.FAILURE,
    loginError: (action as FetchFailureAction).payload,
  }),
  [TYPES.AuthActions.FETCH_LOGIN_CLEAN_FETCH_STATUS]: (state) => ({
    ...state,
    loginFetchStatus: FETCH_STATUSES.IDLE,
  }),

  [ACTIONS.fetchResendEmailTypes.request]: (state) => ({
    ...state,
    resendEmailFetchStatus: FETCH_STATUSES.REQUEST,
  }),
  [ACTIONS.fetchResendEmailTypes.success]: (state) => ({
    ...state,
    resendEmailFetchStatus: FETCH_STATUSES.SUCCESS,
  }),
  [ACTIONS.fetchResendEmailTypes.failure]: (state, action) => ({
    ...state,
    resendEmailFetchStatus: FETCH_STATUSES.FAILURE,
    resendEmailError: (action as FetchFailureAction).payload,
  }),

  [TYPES.AuthActions.CLEAN_AUTH]: () => ({
    ...initialState,
  }),
  [TYPES.AuthActions.CLEAN_LOGIN_ERROR]: (state) => ({
    ...state,
    loginError: initialState.loginError,
  }),
})
