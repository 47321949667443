import React from 'react'
import { useTheme } from 'styled-components'

import translations from '../../../../../translations/keys'
import { ContractSvg } from '../../../../assets/ContractSvg'
import Box from '../../../../ui/Box'
import { StyledThemeScheme } from '../../../../../themes/light'
import useMediaQuery from '../../../../hooks/useMediaQuery'
import Modal from '../../..//Modal'
import Typography from '../../../../ui/Typography'
import { TYPOGRAPHY_NAMES } from '../../../../ui/Typography/Typography.types'
import { UploadFile } from '../../../../ui/UploadFile/UploadFile'

import { ReuploadModalProps } from './ReuploadModal.types'
import { StyledWrapper } from './ReuploadModal.styles'

export const ReuploadModal: React.FC<ReuploadModalProps> = ({ loading = false, onReupload, onClose }) => {
  const theme = useTheme() as StyledThemeScheme
  const mobileScreen = useMediaQuery(theme.devices.mobile)

  const validationInfo = mobileScreen ? (
    <p>
      {translations.UPLOAD_CONTACT_FORMATS} {translations.UPLOAD_CONTACT_SIZE}
    </p>
  ) : (
    <div>
      <p>{translations.UPLOAD_CONTACT_FORMATS}</p>
      <p>{translations.UPLOAD_CONTACT_SIZE}</p>
    </div>
  )

  const handleClose = () => {
    if (!loading) {
      onClose()
    }
  }

  const handleSuccess = (file: File) => {
    onReupload(file)
  }

  return (
    <Modal onClose={handleClose}>
      <StyledWrapper>
        <ContractSvg />
        <Box mt={5} mb={5}>
          <Typography name={TYPOGRAPHY_NAMES.H1}>{translations.REUPLOAD_DOCUMENT}</Typography>
        </Box>
        <UploadFile validationInfo={validationInfo} loading={loading} onSuccess={handleSuccess} />
      </StyledWrapper>
    </Modal>
  )
}
