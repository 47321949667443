export enum OPEN_CONTRACT_AS_TYPES {
  OWNER = 'Owner',
  RECIPIENT = 'Recipient',
}

export enum OpenContractAsFields {
  order = 'order',
}

export interface OpenContractAsForm {
  order: OPEN_CONTRACT_AS_TYPES
}
