import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FormikProps } from 'formik'
import { useParams } from 'react-router'

import translations from '../../../../../translations/keys'
import { FETCH_STATUSES } from '../../../../../types/fetch'
import { UploadDocumentFields, UploadDocumentForm } from '../../../../../types/uploadDocument'
import { TemplateRouteParams } from '../../../../types/template'
import { TEMPLATES_FILL_RECIPIENTS_MODALS } from '../../../../../constants/templatesFillRecipients'
import { location } from '../../../../../utils/location'
import { prepareAllRecipientsForSubmit } from '../../../../../utils/recipients'
import { prepareFieldsWithTextForRequest } from '../../../../../utils/documentField/documentField'
import { useDispatchUnmount } from '../../../../../hooks/useDispatchUnmount'
import { useShowFetchError } from '../../../../hooks/useShowFetchError'
import { useContractsFoldersFetch } from '../../../../../hooks/contracts/useContractsFoldersFetch'
import { useHistoryPushAfterSuccess } from '../../../../hooks/useHistoryPushAfterSuccess'
import { openModal } from '../../../../../store/modals/modals.actions'
import { modalsPayloadSelector } from '../../../../../store/modals/modals.selectors'
import * as CONTRACTS_FOLDERS_SELECTORS from '../../../../../store/contracts/folders/folders.selectors'
import * as CONTRACTS_RECIPIENTS_ACTIONS from '../../../../../store/contracts/recipients/recipients.actions'
import * as CONTRACTS_RECIPIENTS_SELECTORS from '../../../../../store/contracts/recipients/recipients.selectors'
import * as TEMPLATES_CREATE_CONTRACT_ACTIONS from '../../../../../store/templates/createContract/createContract.actions'
import * as TEMPLATES_CREATE_CONTRACT_SELECTORS from '../../../../../store/templates/createContract/createContract.selectors'
import * as TEMPLATES_FIELDS_SELECTORS from '../../../../../store/templates/fields/fields.selectors'
import * as CONTRACTS_UPLOAD_ACTIONS from '../../../../../store/contracts/upload/upload.actions'
import * as CONTRACTS_UPLOAD_SELECTORS from '../../../../../store/contracts/upload/upload.selectors'
import UploadDocumentToModal from '../../DocumentModals/UploadDocumentToModal'

import { CreateContractModalProps } from './CreateContractModal.types'

export const CreateContractModal: React.FC<CreateContractModalProps> = ({ onClose }) => {
  const { templateId } = useParams<TemplateRouteParams>()
  const dispatch = useDispatch()
  const formRef: React.RefObject<FormikProps<UploadDocumentForm>> = useRef(null)
  const { fileName, file, recipients, order } = useSelector(
    modalsPayloadSelector(TEMPLATES_FILL_RECIPIENTS_MODALS.CREATE_CONTRACT_FROM_TEMPLATE)
  )
  const creatingFromTemplateFetchStatus = useSelector(
    TEMPLATES_CREATE_CONTRACT_SELECTORS.createContractFetchStatusSelector
  )
  const fieldsById = useSelector(TEMPLATES_FIELDS_SELECTORS.fieldsByIdSelector)
  const creatingFromTemplateError = useSelector(TEMPLATES_CREATE_CONTRACT_SELECTORS.createContractErrorSelector)
  const creatingFromTemplateIsRequest = useSelector(TEMPLATES_CREATE_CONTRACT_SELECTORS.createContractIsRequestSelector)
  const creatingFromTemplateContractId = useSelector(TEMPLATES_CREATE_CONTRACT_SELECTORS.createContractIdSelector)
  const uploadingFetchStatus = useSelector(CONTRACTS_UPLOAD_SELECTORS.uploadFetchStatusSelector)
  const uploadingError = useSelector(CONTRACTS_UPLOAD_SELECTORS.uploadErrorSelector)
  const uploadingIsRequest = useSelector(CONTRACTS_UPLOAD_SELECTORS.uploadIsRequestSelector)
  const uploadingContractId = useSelector(CONTRACTS_UPLOAD_SELECTORS.uploadIdSelector)
  const foldersIsLoading = useSelector(CONTRACTS_FOLDERS_SELECTORS.foldersIsLoadingSelector)
  const folders = useSelector(CONTRACTS_FOLDERS_SELECTORS.foldersDataSelector)
  const lastAddedFolder = useSelector(CONTRACTS_FOLDERS_SELECTORS.lastAddedFolderSelector)
  const recipientsFetchAdd = useSelector(CONTRACTS_RECIPIENTS_SELECTORS.recipientsFetchAddSelector)
  const contractId = creatingFromTemplateContractId || uploadingContractId
  const creatingIsRequest =
    creatingFromTemplateIsRequest || uploadingIsRequest || recipientsFetchAdd.status === FETCH_STATUSES.REQUEST

  useEffect(() => {
    const form = formRef.current

    if (lastAddedFolder && form) {
      form.setFieldValue(UploadDocumentFields.folderName, lastAddedFolder.value)
    }
  }, [lastAddedFolder])
  useEffect(() => {
    if (contractId) {
      dispatch(
        CONTRACTS_RECIPIENTS_ACTIONS.fetchAddRecipients(
          contractId,
          prepareAllRecipientsForSubmit({ recipients }, order)
        )
      )
    }
  }, [contractId, order])
  useEffect(
    () => () => {
      onClose()
    },
    []
  )
  useHistoryPushAfterSuccess(location.contractsPreviewUrl(contractId), recipientsFetchAdd.status)
  useContractsFoldersFetch()
  useDispatchUnmount(
    TEMPLATES_CREATE_CONTRACT_ACTIONS.cleanCreateContract,
    CONTRACTS_RECIPIENTS_ACTIONS.clearRecipients,
    CONTRACTS_UPLOAD_ACTIONS.cleanUpload
  )
  useShowFetchError(uploadingFetchStatus, uploadingError)
  useShowFetchError(creatingFromTemplateFetchStatus, creatingFromTemplateError)
  useShowFetchError(recipientsFetchAdd.status, recipientsFetchAdd.error)

  const onSubmit = (values: UploadDocumentForm) => {
    const folderName = folders.find((folder) => folder.value === values.folderName)?.name || values.folderName
    const action = file
      ? CONTRACTS_UPLOAD_ACTIONS.fetchUpload({
          ...values,
          file,
          folderName,
        })
      : TEMPLATES_CREATE_CONTRACT_ACTIONS.fetchCreateContract({
          name: values.fileName,
          folderName,
          templateId,
          fields: prepareFieldsWithTextForRequest(fieldsById),
        })

    dispatch(action)
  }
  const onCreateFolder = () => {
    dispatch(openModal(TEMPLATES_FILL_RECIPIENTS_MODALS.CREATE_CONTRACTS_FOLDER))
  }

  return (
    <UploadDocumentToModal
      onClose={onClose}
      fileName={fileName}
      folders={folders}
      foldersIsLoading={foldersIsLoading}
      onCreateFolder={onCreateFolder}
      onSubmit={onSubmit}
      uploading={creatingIsRequest}
      ref={formRef}
      title={translations.CREATE_NEW_CONTRACT}
      submitText={translations.CREATE}
    />
  )
}
