import React from 'react'
import ContentLoader from 'react-content-loader'

import { blueExtraLight, glowColor } from '../../../themes/colors'

const FILL_COLOR = blueExtraLight
const GLOW_COLOR = glowColor
const SPEED = 1.5
const SVG_WIDTH = '100%'
const SVG_HEIGHT = 500
const ROW_HEIGHT = 55
const CELL_Y = 109
const CELL_BORDER_Y = 153
const RADIUS = 3
const ROWS = ['35%', '50%', '45%']
const ACTION_WIDTH = 24
const ACTION_OFFSET = 16
const ACTIONS_COUNT = 3

const getActionWidth = (count: number) => `calc(100% - ${ACTION_WIDTH + count * (ACTION_WIDTH + ACTION_OFFSET)}px)`

export const MobileContractsListSkeleton: React.FC = () => (
  <ContentLoader
    display="block"
    width={SVG_WIDTH}
    height={SVG_HEIGHT}
    speed={SPEED}
    backgroundColor={FILL_COLOR}
    foregroundColor={GLOW_COLOR}
  >
    <rect width={getActionWidth(ACTIONS_COUNT)} height="40" x="0" y="0" rx={RADIUS} ry={RADIUS} />
    {Array(ACTIONS_COUNT)
      .fill(0)
      .map((_, index) => (
        <rect key={index} width={ACTION_WIDTH} height="24" x={getActionWidth(index)} y="8" rx={RADIUS} ry={RADIUS} />
      ))}

    <rect width="15%" height="14" x="0" y="78" rx={RADIUS} ry={RADIUS} />
    <rect width="15%" height="14" x="85%" y="78" rx={RADIUS} ry={RADIUS} />

    {ROWS.map((width, i) => (
      <React.Fragment key={i}>
        <rect width={width} height={32} x={0} y={CELL_Y + ROW_HEIGHT * i} rx={RADIUS} ry={RADIUS} />
        <rect width="15" height={32} x="95%" y={CELL_Y + ROW_HEIGHT * i} rx={RADIUS} ry={RADIUS} />
        <rect width="100%" height="1" x="0" y={CELL_BORDER_Y + ROW_HEIGHT * i} rx={RADIUS} ry={RADIUS} />
      </React.Fragment>
    ))}
  </ContentLoader>
)
