import { FetchResponseWithPagination } from './fetch'

export enum TEMPLATE_TYPES {
  CLM = 'CLM',
  DOCUMENT = 'Document',
  DOCUMENT_CLM = 'DocumentCLM',
}

export interface TemplateDate {
  [key: string]: string
  date: string
  userEmail: string
  userName: string
}

export interface CommonTemplate {
  [key: string]: string | TemplateDate | TEMPLATE_TYPES | boolean | string[] | null
  id: string
  name: string
  type: TEMPLATE_TYPES
  isFolder: boolean
  favorite: boolean
  deleted: boolean
  recipients: string[]
}

export interface Template extends CommonTemplate {
  createDate: TemplateDate | null
  updateDate: TemplateDate | null
}

export interface TemplateResponse extends CommonTemplate {
  createDate: string
  updateDate: string | null
}

export interface TemplatesList extends FetchResponseWithPagination {
  data: Template[]
}
export interface TemplatesListResponse extends FetchResponseWithPagination {
  data: TemplateResponse[]
}

export enum TemplatesMessages {
  'NONE' = '',
  'TEMPLATE_DELETED' = 'TEMPLATE_DELETED',
  'TEMPLATE_FOLDER_DELETED' = 'TEMPLATE_FOLDER_DELETED',
  'NAME_CHANGED' = 'NAME_CHANGED',
  'TEMPLATE_MOVED_TO_FOLDER' = 'TEMPLATE_MOVED_TO_FOLDER',
  'TEMPLATE_DUPLICATED' = 'TEMPLATE_DUPLICATED',
  'FOLDER_UPLOADED' = 'FOLDER_UPLOADED',
}

export enum TEMPLATES_CATEGORIES {
  PROCEDURES = 'PROCEDURES',
  WITH_DOCUMENTS = 'WITH_DOCUMENTS',
  STARRED = 'STARRED',
}

interface BriefTemplate {
  id: string
  name: string
}

export type TemplatesListBriefResponse = BriefTemplate[]
