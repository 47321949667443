import { combineEpics, Epic, ofType } from 'redux-observable'
import { of } from 'rxjs'
import { catchError, mergeMap, switchMap, takeUntil } from 'rxjs/operators'

import { documentsService, contractsService } from '../../../api'
import { catchFetchError } from '../../../utils/catchFetchError'
import prepareForPreview from '../../../utils/documents/prepareForPreview'
import { fetchPlacementSuccess } from '../placement/placement.actions'
import { prepareSigningPlacement } from '../placement/placement.utils'

import * as ACTIONS from './ownerView.actions'

const fetchOwnerContractDocument: Epic = (action$) =>
  action$.pipe(
    ofType(ACTIONS.fetchOwnerContractDocumentTypes.request),
    mergeMap(({ payload }) =>
      contractsService.fetchContract(payload.contractId).pipe(
        switchMap((contractResponse) =>
          documentsService.fetchDocumentTotalPages(contractResponse.document.id).pipe(
            mergeMap(({ totalPages }) =>
              of(
                fetchPlacementSuccess(...prepareSigningPlacement(contractResponse.metadata.signing, [])),
                ACTIONS.fetchOwnerContractDocumentSuccess({
                  ...contractResponse,
                  total: totalPages,
                  pages: prepareForPreview(contractResponse.document.id, totalPages),
                  contractName: contractResponse.name,
                })
              )
            )
          )
        ),
        catchError(catchFetchError(ACTIONS.fetchOwnerContractDocumentFailure)),
        takeUntil(
          action$.pipe(
            ofType(ACTIONS.fetchOwnerContractDocumentTypes.success, ACTIONS.fetchOwnerContractDocumentTypes.failure)
          )
        )
      )
    )
  )

export const ownerViewEpics = combineEpics(fetchOwnerContractDocument)
