import { Action } from 'redux'

import { FetchFailurePayload, FETCH_STATUSES } from '../../../types/fetch'
import { Notification } from '../../../types/notifications'
import { OwnerViewDocumentData } from '../../../types/ownerViewContract'

export enum OwnerViewNotificationMessages {
  'NONE' = '',
  'SIGNING_STOPPED' = 'SIGNING_STOPPED',
  'SIGNING_FINISHED' = 'SIGNING_FINISHED',
  'DELETED_BY_OWNER' = 'DELETED_BY_OWNER',
  'CONTRACT_RESENT' = 'CONTRACT_RESENT',
  'ACCEPTED_SUCCESSFULLY' = 'ACCEPTED_SUCCESSFULLY',
  'REJECTED_SUCCESSFULLY' = 'REJECTED_SUCCESSFULLY',
  'CANCEL_ACCEPTATION_SUCCESSFULLY' = 'CANCEL_ACCEPTATION_SUCCESSFULLY',
  'CANCEL_REJECTION_SUCCESSFULLY' = 'CANCEL_REJECTION_SUCCESSFULLY',
}

export interface OwnerViewState {
  contract: {
    data: OwnerViewDocumentData | null
    fetchStatus: FETCH_STATUSES
    error: FetchFailurePayload | null
  }
  notification: Notification | null
}

export enum OwnerViewActions {
  'FETCH_OWNER_CONTRACT_DOCUMENT' = 'FETCH_OWNER_CONTRACT_DOCUMENT',
  'CLEAN_OWNER_VIEW' = 'CLEAN_OWNER_VIEW',
  'SET_OWNER_NOTIFICATION' = 'SET_OWNER_NOTIFICATION',
  'CLEAN_OWNER_NOTIFICATION' = 'CLEAN_OWNER_NOTIFICATION',
}

export interface FetchOwnerContractDocumentAction extends Action {
  payload: { contractId: string }
}
export interface FetchOwnerContractDocumentSuccessAction extends Action {
  payload: OwnerViewDocumentData
}

export interface SetOwnerViewNotificationAction extends Action {
  payload: Notification
}
