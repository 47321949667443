import { FETCH_STATUSES } from '../../types/fetch'
import { RootState } from '../rootReducer.types'

export const validateSignatureIsRequestSelector = (state: RootState) =>
  state.signatures.validateSignature.fetchStatus === FETCH_STATUSES.REQUEST
export const validateSignatureIsSuccessSelector = (state: RootState) =>
  state.signatures.validateSignature.fetchStatus === FETCH_STATUSES.SUCCESS
export const validateSignatureIsFailureSelector = (state: RootState) =>
  state.signatures.validateSignature.fetchStatus === FETCH_STATUSES.FAILURE
export const validateSignatureErrorSelector = (state: RootState) => state.signatures.validateSignature.error

export const createSignatureIsSuccessSelector = (state: RootState) =>
  state.signatures.createSignature.fetchStatus === FETCH_STATUSES.SUCCESS
export const createSignatureIsFailureSelector = (state: RootState) =>
  state.signatures.createSignature.fetchStatus === FETCH_STATUSES.FAILURE
export const createSignatureSelector = (state: RootState) => state.signatures.createSignature

export const signaturesListFetchStatusSelector = (state: RootState) => state.signatures.list.fetchStatus
export const signaturesListDataSelector = (state: RootState) => state.signatures.list.data
export const signaturesListIsLoadingSelector = (state: RootState) =>
  state.signatures.list.fetchStatus === FETCH_STATUSES.IDLE ||
  state.signatures.list.fetchStatus === FETCH_STATUSES.REQUEST
export const signaturesListErrorSelector = (state: RootState) => state.signatures.list.error
export const signaturesListIsSuccessSelector = (state: RootState) =>
  state.signatures.list.fetchStatus === FETCH_STATUSES.SUCCESS

export const deleteSignatureIsSuccessSelector = (state: RootState) =>
  state.signatures.deleteSignature.fetchStatus === FETCH_STATUSES.SUCCESS
export const deleteSignatureIsFailureSelector = (state: RootState) =>
  state.signatures.deleteSignature.fetchStatus === FETCH_STATUSES.FAILURE
export const deleteSignatureSelector = (state: RootState) => state.signatures.deleteSignature
