import React from 'react'

import { SvgIconProps } from '../../ui/SvgIcon/SvgIcon.types'
import SvgIcon from '../../ui/SvgIcon'

export const DisplayIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <path d="M21 3H3.75C3.33516 3 3 3.33516 3 3.75V15C3 15.4148 3.33516 15.75 3.75 15.75H21C21.4148 15.75 21.75 15.4148 21.75 15V3.75C21.75 3.33516 21.4148 3 21 3ZM20.0625 14.0625H4.6875V4.6875H20.0625V14.0625Z" />
    <path d="M21.25 20.0625H6.9375C6.83437 20.0625 6.75 19.9781 6.75 19.875V18.75C6.75 18.6469 6.83437 18.5625 6.9375 18.5625H21.25C21.3531 18.5625 21.4375 18.6469 21.4375 18.75V19.875C21.4375 19.9781 21.3531 20.0625 21.25 20.0625Z" />
    <path d="M3.64959 19.9754C3.47377 19.7996 3.375 19.5611 3.375 19.3125C3.375 19.0639 3.47377 18.8254 3.64959 18.6496C3.8254 18.4738 4.06386 18.375 4.3125 18.375C4.56114 18.375 4.7996 18.4738 4.97541 18.6496C5.15123 18.8254 5.25 19.0639 5.25 19.3125C5.25 19.5611 5.15123 19.7996 4.97541 19.9754C4.7996 20.1512 4.56114 20.25 4.3125 20.25C4.06386 20.25 3.8254 20.1512 3.64959 19.9754Z" />
  </SvgIcon>
)
