import React from 'react'

import { CardPaymentOption } from '../../../types/cards'
import { AmexSvg } from '../../assets/AmexSvg'
import { MastercardSvg } from '../../assets/MastercardSvg'
import { VisaSvg } from '../../assets/VisaSvg'

export const LOGOS: { [key in keyof typeof CardPaymentOption]: React.ReactNode } = {
  [CardPaymentOption.VISA]: <VisaSvg />,
  [CardPaymentOption.MASTERCARD]: <MastercardSvg />,
  [CardPaymentOption.AMEX]: <AmexSvg />,
}
