import React from 'react'
import { useTranslation } from 'react-i18next'

import translations from '../../../translations/keys'
import { BulletList } from '../../ui/BulletList'
import Typography from '../../ui/Typography'
import { TYPOGRAPHY_NAMES } from '../../ui/Typography/Typography.types'
import Box from '../../ui/Box'

import { StyledParagraph } from './PrivacyPolicy.styles'
import { TitledParagraph } from './components/TitledParagraph'

export const PrivacyPolicy = () => {
  const { t } = useTranslation()
  return (
    <Box display="flex" justifyContent="center">
      <Box width={704}>
        <Box mb={3}>
          <Typography name={TYPOGRAPHY_NAMES.H1}>{t(translations.PRIVACY_POLICY)}</Typography>
        </Box>

        <TitledParagraph title="INTRODUCTION">
          <StyledParagraph>
            Kryptos DMCC (“Company”, “Kryptos”, “we”, “us” or “our”), a company duly incorporated and registered in the
            Dubai Multi Commodities Centre (the “DMCC”), whose registered office is at Unit No: OneJLT-06-94 One JLT,
            Plot No: DMCC-EZ1-1AB Jumeirah Lakes Towers Dubai, United Arab Emirates, and whose registration number is
            DMCC771756, is a company that operates in the software industry. Our products and services include “Uqoud”,
            an application interface that provides customers, clients and /or users (“User”, “you”, Customer(s), or
            “your”) with contract lifecycle management, e-signature, and electronic Know-Your-Client (“EKYC”) solutions.
          </StyledParagraph>
          <StyledParagraph>
            Definitions and interpretations used in this this policy (the “Policy”), shall have the same meaning
            ascribed thereto into the General Terms and Conditions for Uqoud, unless otherwise expressly defined or
            demanded by context.
          </StyledParagraph>
          <StyledParagraph>
            Definitions and interpretations used in this this policy (the “Policy”), shall have the same meaning
            ascribed thereto into the General Terms and Conditions for Uqoud, unless otherwise expressly defined or
            demanded by context.
          </StyledParagraph>
          <StyledParagraph>
            We are committed to safeguarding the privacy of the personal information that is provided to us or collected
            by us during the course of our business, as well as the personal information we receive from you, for the
            purpose of providing any services offered through Uqoud (the “Services”). This Policy describes how and why
            we collect, store and use personal information, and provides information about your rights in relation
            thereto.
          </StyledParagraph>
          <StyledParagraph>
            Please read thoroughly this Policy and ensure your understanding of the terms thereof. Kryptos reserves the
            right to amend or update this Policy from time to time at their sole discretion; kindly refer to the Section
            on “Changes to this Privacy Policy” hereunder for more details. You can view the most recent version of this
            Policy by accessing this online address.
          </StyledParagraph>
          <StyledParagraph>
            For the purposes of applicable data protection law, Kryptos is the “data controller” of your personal
            information.
          </StyledParagraph>
          <StyledParagraph>
            By using any of the Services or otherwise interacting in your capacity as a Customer with Uqoud, you hereby
            provide your consent for your personal data and any other data, including financial credentials, referenced
            under this Policy to be collected by Kryptos and used in accordance with the terms set forth hereunder. If
            you do not consent to the terms of this Policy, or any amendments added thereto at any later date, please
            exit Uqoud and refrain from using any of the Services provided through it.
          </StyledParagraph>
        </TitledParagraph>
        <TitledParagraph title="COLLECTION OF PERSONAL INFORMATION">
          <StyledParagraph>
            By using any of the Services, you consent to our collection, use and disclosure of your personal information
            in accordance with the terms of this Policy. You may withdraw such consent at any time. However, this will
            not affect the lawfulness of the collection or processing of any data based on your consent prior to the
            withdrawal, or where we have other legitimate or otherwise legally required purposes for processing your
            personal data.
          </StyledParagraph>
          <Box mb={1}>
            <StyledParagraph>We may collect and process the following personal information:</StyledParagraph>
          </Box>
          <Box mb={1}>
            <StyledParagraph>Information you give us:</StyledParagraph>
          </Box>
          <BulletList
            items={[
              'If you are enquiring through the Services, then we may request and process with your consent your personal information including but not limited to, your name, e-mail address, telephone number, ID, passport, address and date of birth;',
              `We may also request permission to process your sensitive personal information, including but not limited
          to, your: genetic data and biometric data used to identify you, signature and any data collected and
          processed from the contracts you enter into this could include health, financial or other sensitive
          personal date;`,
              `You may give us information about you when using the Services or by corresponding with us by phone, e-mail
          or other electronic means, or in writing, as well as other information you provide directly to us,
          including in conversation with our employees and staff.`,
            ]}
            mbItem={1}
          />

          <Box mb={1}>
            <StyledParagraph>Information we collect about you:</StyledParagraph>
          </Box>
          <Box mb={1}>
            <StyledParagraph>
              With regard to your use of the Services, we may automatically collect the following information:
            </StyledParagraph>
          </Box>
          <BulletList
            items={[
              'technical information, including the Internet protocol (IP) address used to connect your computer to the Internet, browser type and version, time zone setting, browser plug-in types and versions, operating system; location (home and / or office); and',
              `information about your use of the Services; Services you viewed or searched for; page response times, download errors, length of visits, page interaction information (such as scrolling, clicks, and mouse-overs). `,
            ]}
            mbItem={1}
          />
          <StyledParagraph>
            We may also receive information about you from other sources such as our local authorised business partners
            and service providers.
          </StyledParagraph>
        </TitledParagraph>
        <TitledParagraph title="PURPOSE">
          <StyledParagraph>
            We use your personal information for the following purposes, to the extent permitted by applicable law:
          </StyledParagraph>
        </TitledParagraph>
        <TitledParagraph title="SHARING YOUR PERSONAL INFORMATION">
          <StyledParagraph>
            We use your personal information for the following purposes, to the extent permitted by applicable law:
          </StyledParagraph>
          <StyledParagraph>
            We may also share your personal information with third parties, including certain service providers we have
            retained in connection with our business activities or other entities as found necessary.
          </StyledParagraph>
          <StyledParagraph>Moreover, we may disclose your personal information to third parties:</StyledParagraph>
          <BulletList
            items={[
              `If we sell or buy any business or assets, in which case we may disclose your personal information to the prospective seller or buyer of such business or assets;`,
              `If Kryptos, its business, or its assets are acquired by a third party, in which case personal information held by it about its Users will be one of the transferred assets;`,
              `If we are under a duty to disclose or share your personal information in order to comply with any legal obligation or if we are required to do so by a legal process, or in order to enforce or apply our terms and conditions and other agreements; or if we reasonably consider this necessary; or to protect the rights, property, or safety of Kryptos or our Customers, or others; and`,
              `For the purposes of crime prevention and fraud protection.`,
            ]}
            mbItem={1}
          />
          <StyledParagraph>
            We may also disclose your personal information to law enforcement authorities or other government officials.
          </StyledParagraph>
          <StyledParagraph>
            We may also disclose your personal information to any competent administrative or judicial authority in
            prevention of money laundering and terrorist financing in compliance with any anti-money laundering
            regulation for the purposes of preventing any operation related to money laundering or terrorism financing.
          </StyledParagraph>
          <StyledParagraph>
            We will use all reasonable efforts to ensure the safeguarding, security and confidentiality of all collected
            personal information subject to data transfers by implementing appropriate technical and organisational
            measures to ensure a level of security to the risk, and to have in place generally accepted information
            security mechanisms to prevent unlawful destruction, loss, alteration, unauthorised disclosure of, or access
            to personal data Kryptos collects. These measures include, to the extent allowed by law, policies,
            procedures, firewalls, access control procedures, encryption, and technical elements relating to personal
            data access controls.
          </StyledParagraph>
        </TitledParagraph>
        <TitledParagraph title="COOKIES AND INTERNET ADVERTISING">
          <StyledParagraph>
            We use ‘cookies’ to improve our user’s experience. Cookies are small files that are sent to a {"computer's"}{' '}
            hard drive by a webserver and enable websites and mobile applications to remember who you are. Information
            from cookies may include information relating to your use of Uqoud, information about your computer (such as
            IP address and browser type), and demographic data. The list below sets out the types of cookies we use, and
            the purposes for which we use them.
          </StyledParagraph>
          <Box component="div" mb={2} mt={2}>
            <table>
              <thead>
                <tr>
                  <th>Type of cookie</th>
                  <th>Why we use these cookies</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Authentication</td>
                  <td style={{ padding: '10px' }}>
                    This cookie is essential for a single user to login (using a username and password) and access
                    restricted areas of Uqoud.
                  </td>
                </tr>
                <tr>
                  <td>Necessary</td>
                  <td>
                    We use these cookies to run Uqoud, and to identify and prevent security risks. For example, we may
                    use these cookies to store your session information to prevent others from changing your password
                    without your username and password. These cookies are by definition necessary for the operation of
                    Uqoud and cannot be switched off for purposes of accessing Uqoud or using the Services.
                  </td>
                </tr>
                <tr>
                  <td>Preferences</td>
                  <td>
                    We use these cookies to remember your settings and preferences, and to improve your experience on
                    Uqoud. For example, we may use these cookies to remember your language preferences.{' '}
                  </td>
                </tr>
                <tr>
                  <td>Performance</td>
                  <td>
                    We use these cookies to collect information about how you use Uqoud, monitor its performance, and
                    improve its performance, our Services, and your experience. For example, we can use these cookies to
                    learn more about which features are the most popular with our users and which ones might need some
                    tweaks. All information collected via these cookies is anonymous.
                  </td>
                </tr>
                <tr>
                  <td>Marketing</td>
                  <td>
                    We use these cookies to deliver adverts, to make them more relevant and meaning to you, and to track
                    the efficiency of our advertising campaigns, both on our services and on other sites or mobile
                    applications.
                  </td>
                </tr>
              </tbody>
            </table>
          </Box>

          <StyledParagraph>
            We also use advertising identifiers which are similar to cookies and are found on many mobile devices and
            tablets. Like cookies, advertising identifiers are used to make online advertising more relevant.
            Advertising cookies and advertising identifiers use information about your use of this and other websites
            and applications, your response to ads and emails, and to deliver ads that are more relevant to you. These
            types of ads are called “internet based advertising”.
          </StyledParagraph>
        </TitledParagraph>
        <TitledParagraph title="USE OF LINKS">
          <StyledParagraph>
            Uqoud may contain links to other third-party websites. Kryptos has no control over the content, policies or
            actions of these websites. Notwithstanding any legislative provisions in any of the territories wherein
            Kryptos provides Services, the use of any information you may provide to third parties on other websites, or
            which such parties may otherwise collect on other websites, is not governed by this Policy. You should
            carefully review the privacy policies and protection of personal data measures of any third party websites
            and contact the operators of those websites if you have any questions about their use of your information.
            Kryptos shall not be responsible for any third party, its affiliates or agents, for failing to use your
            personal information in accordance with such third party’s privacy policy and/or practices, or any
            contractual or other legal obligations to which such third party, its affiliates or agents, may be subject
            to.
          </StyledParagraph>
        </TitledParagraph>
        <TitledParagraph title="SECURITY">
          <StyledParagraph>
            We use up-to-date information storage and security to hold your personal information securely in electronic
            and physical form to protect your personal information from unauthorised access, improper use or disclosure,
            unauthorised modification or unlawful destruction or accidental loss. Whilst we have implemented adequate
            physical, electronic and managerial procedures to secure and safeguard your personal information, guaranteed
            security does not exist either on or off the internet; we shall practice commercially reasonable efforts to
            make the collection and security of your personal data compliant with this Policy and all applicable laws,
            regulations and best practice standards.
          </StyledParagraph>
          <StyledParagraph>
            We have put in place procedures to deal with any suspected personal data breach and will notify you and any
            applicable regulator of a breach where and to the extent we are legally required to do so.
          </StyledParagraph>
          <StyledParagraph>
            We will regularly evaluate and test the effectiveness of the safeguards referenced under this clause 7 to
            ensure security of our processing of your personal data access by third parties, and we cannot guarantee in
            this respect that the personal information provided by you or that is transmitted via the Services or by
            e-mail is totally secure and safe, and you provide it at your own risk.
          </StyledParagraph>
        </TitledParagraph>
        <TitledParagraph title="CROSS-BORDER TRANSFERS">
          <StyledParagraph>
            Your personal information may be transferred to and stored in servers and facilities located in your region
            or another country. Some of these countries do not provide the same level of data protection as the country
            in which you reside and are not recognised by the European Commission as providing an adequate level of
            protection.
          </StyledParagraph>{' '}
          <StyledParagraph>
            When we transfer your information to other countries, we will use, share and safeguard that information as
            described in this Policy. To conduct the services, we may transfer the personal information we collect to
            countries which do not provide the same level of data protection as the country in which you reside and are
            not recognised by the European Commission as providing an adequate level of data protection. We only
            transfer personal information to these countries, with your consent, when it is necessary to provide the
            Services, or subject to appropriate safeguards that assure the protection of your personal information, such
            as standard contractual clauses.{' '}
          </StyledParagraph>
        </TitledParagraph>
        <TitledParagraph title="MARKETING COMMUNICATIONS">
          <StyledParagraph>
            We, or trusted third parties on our behalf, may contact you by email or post with information about our
            services or promotions that might be of interest to you. Where necessary, at the time that you provide your
            personal information to us, you will be given the opportunity to indicate whether or not you are happy for
            us to use your personal information in order to tell you about such services and promotions.
          </StyledParagraph>{' '}
          <StyledParagraph>
            Withdrawal of consent to receive marketing communications will not affect the processing of personal
            information for the provision of our services. You may, at any time, withdraw your consent to receive
            commercial marketing communications by email at privacy@uqoud.com{' '}
          </StyledParagraph>
        </TitledParagraph>
        <TitledParagraph title="YOUR RIGHTS">
          <StyledParagraph>
            You have the right to ask us to provide a copy of the personal information we hold about you, and to have
            personal information removed or any inaccurate personal information about you corrected.
          </StyledParagraph>{' '}
          <StyledParagraph>
            If you would like your personal information to be removed from our records or if your personal information
            requires amending then we will endeavor to correct, update or remove your personal information as swiftly as
            possible. You can make such request by privacy@uqoud.com{' '}
          </StyledParagraph>
        </TitledParagraph>
        <TitledParagraph title="HOW LONG WE KEEP YOUR PERSONAL INFORMATION">
          <StyledParagraph>
            We will retain your personal information for the length of time needed to fulfill the purposes outlined in
            this Policy unless a longer retention period is required or permitted by law.{' '}
          </StyledParagraph>
          <StyledParagraph>
            Your personal information that is processed for marketing purposes will be stored until you exercise the
            right to withdraw consent, and once such right is exercised, your personal information will be kept only
            during the applicable statute of limitations period for any disputes or liabilities that may arise as a
            consequence of the processing of that information. Once potential actions are time barred we will proceed to
            delete the personal data. In any other circumstances, we will only retain your personal information for a
            shorter or longer period as required by any applicable law.{' '}
          </StyledParagraph>
        </TitledParagraph>
        <TitledParagraph title="CHANGES TO THIS PRIVACY POLICY">
          <StyledParagraph>
            By using Uqoud, you consent to the collection, use and disclosure of your personal information as described
            in this Policy. This Policy reflects Kryptos’ current business practices and is subject to changes,
            alterations and updates. In the event of any changes, alterations or updates, this Policy will be updated
            accordingly, with the last date of update noted on the bottom of the page. Please check this Policy
            periodically to remain informed of any changes or updates to Kryptos’ privacy policies and practices. If you
            allow Kryptos to retain your personal information you provide to us via Uqoud in its customer database, and
            Kryptos intends to disclose that information to third parties or affiliates in a manner that is not already
            outlined in this Policy, Kryptos will notify you as soon as possible by e-mail and provide you with an
            opportunity to refuse any further use or disclosure of your personal information.
          </StyledParagraph>
        </TitledParagraph>
        <TitledParagraph title="CONTACT US">
          <StyledParagraph>
            If you have any questions about this Policy, or our processing of your personal data, please contact us via
            privacy@uqoud.com
          </StyledParagraph>
        </TitledParagraph>
      </Box>
    </Box>
  )
}
