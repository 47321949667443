import { Signature } from '../../types/signatures'

import { addMIME } from './addMIME'

export const prepareSignaturesItem = (item: Signature) => ({
  ...item,
  signature: addMIME(item.signature),
  initials: addMIME(item.initials),
})

export const prepareSignaturesList = (list: Signature[]) => list.map(prepareSignaturesItem)
