import styled from 'styled-components'

import { TriangleDownIcon } from '../../assets/icons'
import { Box } from '../Box/Box'

export const StyledTitle = styled(Box).attrs({
  pr: 2,
  pb: 2,
  pl: 2,
  pt: 2,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
})`
  cursor: pointer;
`
export const StyledAccordion = styled(Box).attrs({
  mb: 3,
})`
  background: ${({ theme }) => theme.palette.white};
  box-shadow: ${({ theme }) => theme.shadow};
  border-radius: 4px;
`
export const StyledTriangleDownIcon = styled(TriangleDownIcon)<{ flip: boolean }>`
  transform: ${({ flip }) => (flip ? 'rotate(180deg)' : 'rotate(0)')};
  transition: transform 300ms;
  backface-visibility: hidden;
`
