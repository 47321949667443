import { CONFIRM_BUTTONS } from '../native/components/Forms/FormRecipients/FormRecipients.constants'

import { ContractStatusTypes } from './contracts'
import { SIGNING_TYPES } from './signingOrder'
import { EnabledFeatures } from './subscription'

export type RecipientsById = { [id: string]: RecipientData }
export type RecipientsByOrder = [number, string[]][]
export type RecipientsSorted = string[]
export type Sender = { name: string; jobTitle: string }

export enum RecipientStatuses {
  NOT_SENT = 'NOT_SENT',
  RECEIVED = 'RECEIVED',
  READY_TO_SIGN = 'READY_TO_SIGN',
  OPENED = 'OPENED',
  COMMENTED = 'COMMENTED',
  COMPLETE = 'COMPLETE',
  PENDING_RECIPIENTS = 'PENDING_RECIPIENTS',
  DECLINED = 'DECLINED',
  VERIFICATION_FAILED = 'VERIFICATION_FAILED',
  USER_TERM_EXPIRED = 'USER_TERM_EXPIRED',
}

export enum RecipientSignatureTypes {
  LAST_PAGE = 'LAST_PAGE',
  EVERY_PAGE = 'EVERY_PAGE',
}

export enum RecipientSignatureSequenceTypes {
  SEQUENTIAL_SIGNING = 'SEQUENTIAL_SIGNING',
  SEPARATELY_SIGNING = 'SEPARATELY_SIGNING',
}

export enum RecipientRoleSelectTypes {
  RECIPIENT = 'RECIPIENT',
  OWNER = 'OWNER',
}

export enum RecipientAuthentication {
  NONE = 'None',
  OTP = 'Passcode OTP',
  EKYC = 'eKYC',
  EKYC_ID = 'eKYC with ID',
}

export enum RecipientActions {
  SIGN = 'SIGN',
  INITIAL = 'INITIAL',
  VIEW = 'VIEW',
}

export enum RecipientControl {
  EDIT = 'EDIT',
  DELETE = 'DELETE',
}

export interface RecipientData {
  id?: string
  name: string
  email: string
  action: RecipientActions
  cycleOrder: number
  deadline: Date | string | null
  signatureType: RecipientSignatureTypes
  department?: string
  authentication: string
  phone?: string
  noEmailAndName?: boolean
  status?: {
    name: RecipientStatuses
    type: ContractStatusTypes
  }
}

export interface AddRecipientsForm {
  recipients: RecipientData[]
}

export interface AddRecipientsFormMobile {
  recipients: RecipientData[]
  tappedConfirmButton: CONFIRM_BUTTONS
}

export interface AddRecipientsSignatureSequentialForm {
  signatureSequence: RecipientSignatureSequenceTypes
}

export interface RoleSelectForm {
  roleSelect: RecipientRoleSelectTypes
}

export interface AddClmOnlyRecipientsBody extends AddRecipientsForm {
  name: string
  folderName: string
}

export enum AddRecipientsFields {
  recipients = 'recipients',
  recipientName = 'name',
  recipientEmail = 'email',
  recipientAction = 'action',
  recipientCycleOrder = 'cycleOrder',
  recipientDeadline = 'deadline',
  recipientSignatureType = 'signatureType',
  recipientDepartment = 'department',
  recipientAuthentication = 'authentication',
  recipientPhone = 'phone',
  recipientNoEmailAndName = 'noEmailAndName',
}

export enum AddRecipientsSignatureSequentialFields {
  signatureSequence = 'signatureSequence',
}

export enum RoleSelectFields {
  roleSelect = 'roleSelect',
}

export const RecipientAuthOptions = [
  { name: RecipientAuthentication.NONE, value: RecipientAuthentication.NONE },
  { name: RecipientAuthentication.OTP, value: RecipientAuthentication.OTP },
  { name: RecipientAuthentication.EKYC, value: RecipientAuthentication.EKYC },
  { name: 'eKYC + ID', value: RecipientAuthentication.EKYC_ID },
]

export interface GetOrderBasedOnRecipientsProps {
  recipients: RecipientData[]
  enabledFeatures: EnabledFeatures | null
  order: SIGNING_TYPES
}

export interface RecipientsStores {
  byId: RecipientsById
  byOrder: RecipientsByOrder
  sorted: RecipientsSorted
}
