import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { RECIPIENTS_DIAGRAM_MODALS } from '../../../constants/recipientsDiagram'
import { modalsVisibleSelector } from '../../../../store/modals/modals.selectors'
import { closeModal } from '../../../../store/modals/modals.actions'
import { DeleteRecipientModal } from '../../../components/Modals/RecipientsModals/DeleteRecipientModal/DeleteRecipientModal'
import { EditRecipientModal } from '../../../components/Modals/RecipientsModals/EditRecipientModal/EditRecipientModal'
import { CONTRACTS_MODALS } from '../../../../constants/contracts'
import SaveAsTemplateModal from '../../../components/Modals/ContractsModals/SaveAsTemplateModal'
import CreateFolderForTemplateModal from '../../../components/Modals/TemplatesModals/CreateFolderForTemplateModal'

import { ModalsProps } from './Modals.types'

export const Modals: React.FC<ModalsProps> = ({ templatesMode, onUpdate, onDelete }) => {
  const dispatch = useDispatch()

  const showDeleteRecipientModal = useSelector(
    modalsVisibleSelector(RECIPIENTS_DIAGRAM_MODALS.DELETE_DIAGRAM_RECIPIENT)
  )
  const onCloseDeleteRecipientModal = () => {
    dispatch(closeModal(RECIPIENTS_DIAGRAM_MODALS.DELETE_DIAGRAM_RECIPIENT))
  }

  const showEditRecipientModal = useSelector(modalsVisibleSelector(RECIPIENTS_DIAGRAM_MODALS.EDIT_DIAGRAM_RECIPIENT))
  const onCloseEditRecipientModal = () => {
    dispatch(closeModal(RECIPIENTS_DIAGRAM_MODALS.EDIT_DIAGRAM_RECIPIENT))
  }

  const showSaveContractAsTemplate = useSelector(modalsVisibleSelector(CONTRACTS_MODALS.SAVE_CONTRACT_AS_TEMPLATE))
  const handleSaveContractAsTemplateClose = () => {
    dispatch(closeModal(CONTRACTS_MODALS.SAVE_CONTRACT_AS_TEMPLATE))
  }

  const showCreateFolderForTemplate = useSelector(modalsVisibleSelector(CONTRACTS_MODALS.CREATE_FOLDER_FOR_TEMPLATE))
  const handleCreateFolderForTemplateClose = () => {
    dispatch(closeModal(CONTRACTS_MODALS.CREATE_FOLDER_FOR_TEMPLATE))
  }

  return (
    <>
      {showDeleteRecipientModal && <DeleteRecipientModal onDelete={onDelete} onClose={onCloseDeleteRecipientModal} />}
      {showEditRecipientModal && (
        <EditRecipientModal templatesMode={templatesMode} onUpdate={onUpdate} onClose={onCloseEditRecipientModal} />
      )}
      {showSaveContractAsTemplate && <SaveAsTemplateModal onClose={handleSaveContractAsTemplateClose} />}
      {showCreateFolderForTemplate && <CreateFolderForTemplateModal onClose={handleCreateFolderForTemplateClose} />}
    </>
  )
}
