import { combineEpics, Epic, ofType } from 'redux-observable'
import { of } from 'rxjs'
import { catchError, mergeMap, takeUntil } from 'rxjs/operators'

import { paymentService } from '../../../api'
import { catchFetchError } from '../../../utils/catchFetchError'

import * as ACTIONS from './payment.actions'

const THREE_D_URL = '3ds_url'

const fetchFormFields: Epic = (action$) =>
  action$.pipe(
    ofType(ACTIONS.fetchPaymentFormFieldsTypes.request),
    mergeMap(() =>
      paymentService.fetchTokenization().pipe(
        mergeMap((response) => of(ACTIONS.fetchPaymentFormFieldsSuccess(response))),
        catchError(catchFetchError(ACTIONS.fetchPaymentFormFieldsFailure)),
        takeUntil(
          action$.pipe(ofType(ACTIONS.fetchPaymentFormFieldsTypes.success, ACTIONS.fetchPaymentFormFieldsTypes.failure))
        )
      )
    )
  )

const fetchRegisterCard: Epic = (action$) =>
  action$.pipe(
    ofType(ACTIONS.fetchRegisterCardTypes.request),
    mergeMap(({ payload }) =>
      paymentService.fetchRegisterCard(payload.body).pipe(
        mergeMap(({ response }) => of(ACTIONS.fetchRegisterCardSuccess(response[THREE_D_URL]))),
        catchError(catchFetchError(ACTIONS.fetchRegisterCardFailure)),
        takeUntil(action$.pipe(ofType(ACTIONS.fetchRegisterCardTypes.success, ACTIONS.fetchRegisterCardTypes.failure)))
      )
    )
  )

const fetchActivateSubscription: Epic = (action$) =>
  action$.pipe(
    ofType(ACTIONS.fetchActivateSubscriptionTypes.request),
    mergeMap(({ payload }) => {
      const request = payload.trial
        ? paymentService.fetchActivateTrialSubscription
        : paymentService.fetchActivateSubscription

      return request(payload.body).pipe(
        mergeMap(({ response }) => of(ACTIONS.fetchActivateSubscriptionSuccess(response[THREE_D_URL]))),
        catchError(catchFetchError(ACTIONS.fetchActivateSubscriptionFailure)),
        takeUntil(
          action$.pipe(
            ofType(ACTIONS.fetchActivateSubscriptionTypes.success, ACTIONS.fetchActivateSubscriptionTypes.failure)
          )
        )
      )
    })
  )

export const paymentEpics = combineEpics(fetchFormFields, fetchRegisterCard, fetchActivateSubscription)
