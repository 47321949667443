import React from 'react'
import styled from 'styled-components'

import { CheckIcon } from '../InputIcons/CheckIcon'

import { CheckboxProps } from './Checkbox.types'

const StyledWrapper = styled.label<Pick<CheckboxProps, 'disabled'>>`
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  ${({ disabled }) => (disabled ? 'opacity: 0.5;' : '')}
`
const StyledIconWrapper = styled.div``
const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  // Hide checkbox visually but remain accessible to screen readers.
  // Source: https://polished.js.org/docs/#hidevisually
  overflow: hidden;
  width: 1px;
  height: 1px;
  position: absolute;
  clip: rect(0 0 0 0);
  margin: -1px;
  padding: 0;
  white-space: nowrap;
  border: 0;
`
const StyledCheckbox = styled.div<{ checked?: boolean }>`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  background: ${(props) => (props.checked ? props.theme.palette.blue : 'transparent')};
  border-width: 1px;
  border-style: solid;
  border-color: ${(props) => props.theme.palette.blue};
  border-radius: 2px;
  transition: all 100ms;

  ${StyledIconWrapper} {
    visibility: ${(props) => (props.checked ? 'visible' : 'hidden')};
  }
`
const StyledLabel = styled.span<Pick<CheckboxProps, 'invisibleLabel'>>`
  margin-left: 8px;
  font-size: 0.875rem;
  ${({ invisibleLabel }) => (invisibleLabel ? `visibility: hidden;` : '')}
`
export const Checkbox: React.FC<CheckboxProps> = ({
  label,
  checked,
  className,
  disabled,
  invisibleLabel,
  ...props
}) => (
  <StyledWrapper test-id="checkbox-wrapper" className={className} disabled={disabled}>
    <HiddenCheckbox test-id="checkbox-hidden" checked={checked} disabled={disabled} {...props} />
    <StyledCheckbox test-id="checkbox-styled" checked={checked}>
      <StyledIconWrapper test-id="checkbox-icon-wrapper">
        <CheckIcon test-id="checkbox-icon" />
      </StyledIconWrapper>
    </StyledCheckbox>
    {label && (
      <StyledLabel test-id="checkbox-label" invisibleLabel={invisibleLabel}>
        {label}
      </StyledLabel>
    )}
  </StyledWrapper>
)

Checkbox.defaultProps = {
  disabled: false,
  checked: false,
  invisibleLabel: false,
}
