import React, { MutableRefObject, useRef, useState } from 'react'
import styled from 'styled-components'

import { TriangleDownIcon } from '../../assets/icons/TriangleDownIcon'
import Button from '../Button'
import { ButtonProps, BUTTON_VARIANTS } from '../Button/Button.types'
import Popover from '../Popover'

import { DropdownItem } from './components/DropdownItem/DropdownItem'
import { StyledMenu } from './DropdownButton.styles'
import { DropdownButtonProps, FunctionChildren } from './DropdownButton.types'

const StyledIconWrapper = styled.div`
  margin-left: 12px;
`
const StyledIcon = styled(TriangleDownIcon)<{ flip: boolean }>`
  transform: ${({ flip }) => (flip ? 'rotate(180deg)' : 'rotate(0)')};
  transition: transform 300ms;
  backface-visibility: hidden;
`

export const DropdownButton: React.FC<DropdownButtonProps & ButtonProps> = ({
  targetRef,
  triangleColor = 'blue',
  open = false,
  hideTriangle = false,
  children,
  variant = BUTTON_VARIANTS.TEXT,
  items,
  iconColor,
  onClick = () => {},
  onChange = () => {},
  popoverContent,
  capture,
  buttonIcon,
  buttonIconColor,
  openStyles,
  popoverStyles,
  ...otherProps
}) => {
  const [visible, setVisible] = useState(false)
  const dropdownRef: MutableRefObject<null> = useRef(null)
  const ref = targetRef || dropdownRef

  const handleDropdownOpen = () => {
    setVisible((prevValue) => !prevValue)
  }
  const handleClose = () => {
    setVisible(false)
  }
  const handleButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    handleDropdownOpen()
    onClick()
  }

  return (
    <div>
      <Button
        ref={ref}
        variant={variant}
        onClick={handleButtonClick}
        icon={buttonIcon}
        iconColor={(visible && openStyles?.buttonIcon?.color) || buttonIconColor}
        {...otherProps}
      >
        {typeof children === 'function' ? (children as FunctionChildren)({ visible }) : children}
        {!hideTriangle && (
          <StyledIconWrapper>
            <StyledIcon size="small" color={triangleColor} flip={items ? visible : open} />
          </StyledIconWrapper>
        )}
      </Button>
      {(items || popoverContent) && (
        <Popover
          targetRef={ref}
          visible={visible}
          onClose={handleClose}
          padding="0"
          capture={capture}
          width={popoverStyles?.width || 'auto'}
          offsetTop={popoverStyles?.offsetTop}
          placement={popoverStyles?.placement}
        >
          <StyledMenu>
            {popoverContent
              ? popoverContent({ handleClose })
              : items &&
                items.map((item) => {
                  const handleMenuClick = (value?: string) => (event: React.MouseEvent<HTMLLIElement>) => {
                    event.stopPropagation()
                    handleClose()
                    value && onChange(value)
                    item.onClick && item.onClick(item.value)
                  }

                  return (
                    <li key={item.name} onClick={item.disabled ? undefined : handleMenuClick(item.value)}>
                      <DropdownItem label={item.name} Icon={item.icon} iconColor={iconColor} disabled={item.disabled} />
                    </li>
                  )
                })}
          </StyledMenu>
        </Popover>
      )}
    </div>
  )
}
