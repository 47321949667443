import { Ajax } from '../ajax'
import { DocFieldsRequestBody, DocFieldsResponseBody } from '../../types/documentField'

class FieldService extends Ajax {
  pathname = 'fields'

  fetchFields = (templateId: string) => this.getJSON<DocFieldsResponseBody>(`${this.pathname}/${templateId}`)

  fetchAddFields = (templateId: string, body: DocFieldsRequestBody) => this.post(`${this.pathname}/${templateId}`, body)
}

export const fieldService = new FieldService()
