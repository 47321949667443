import { Action } from 'redux'

import { FetchFailurePayload, FETCH_STATUSES } from '../../../types/fetch'

export interface FavoriteState {
  fetchStatus: FETCH_STATUSES
  error: FetchFailurePayload | null
}

export enum FavoriteActions {
  'TOGGLE_FAVORITE_TEMPLATE' = 'TOGGLE_FAVORITE_TEMPLATE',
}

export interface FetchFavoriteTemplate extends Action {
  payload: { id: string; favorite: boolean }
}

export type FetchFavoriteTemplateSuccess = Action
