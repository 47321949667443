import { FetchFailureAction, FetchFailurePayload } from '../../../types/fetch'
import { Notification } from '../../../types/notifications'
import { OTPVerificationAttemptsResponse, OTPVerificationRequestBody } from '../../../types/otpVerification'
import { createFetchActionTypes } from '../../../utils/createFetchActionTypes'

import * as TYPES from './otpVerification.types'

export const fetchVerifyOTPTypes = createFetchActionTypes(TYPES.OTPVerificationActions.FETCH_VERIFY_OTP)
export const fetchSendCodeTypes = createFetchActionTypes(TYPES.OTPVerificationActions.FETCH_SEND_OTP_VERIFICATION_CODE)
export const fetchAttemptsTypes = createFetchActionTypes(TYPES.OTPVerificationActions.FETCH_OTP_VERIFICATION_ATTEMPTS)
export const fetchResendCodeTypes = createFetchActionTypes(TYPES.OTPVerificationActions.FETCH_RESEND_CODE)

export const fetchVerifyOTP = (body: OTPVerificationRequestBody): TYPES.FetchVerifyOTPAction => ({
  type: fetchVerifyOTPTypes.request,
  payload: { body },
})
// export const fetchVerifyOTPSuccess = (token: string): TYPES.FetchVerifyOTPSuccessAction => ({
//   type: fetchVerifyOTPTypes.success,
//   payload: { token },
// })

export const fetchVerifyOTPSuccess = (): TYPES.FetchVerifyOTPSuccessAction => ({
  type: fetchVerifyOTPTypes.success,
  payload: {}
})

export const fetchVerifyOTPFailure = (payload: FetchFailurePayload): FetchFailureAction => ({
  type: fetchVerifyOTPTypes.failure,
  payload,
})

export const cleanOTPVerification = () => ({
  type: TYPES.OTPVerificationActions.CLEAN_OTP_VERIFICATION,
})

export const fetchSendCode = (contractId: string): TYPES.FetchSendCode => ({
  type: fetchSendCodeTypes.request,
  payload: { contractId },
})
export const fetchSendCodeSuccess = (recipientId: string): TYPES.FetchSendCodeSuccess => ({
  type: fetchSendCodeTypes.success,
  payload: { recipientId },
})
export const fetchSendCodeFailure = (payload: FetchFailurePayload): FetchFailureAction => ({
  type: fetchSendCodeTypes.failure,
  payload,
})

export const setOTPVerificationNotification = (notification: Notification): TYPES.SetNotificationAction => ({
  type: TYPES.OTPVerificationActions.SET_OTP_VERIFICATION_NOTIFICATION,
  payload: notification,
})
export const cleanOTPVerificationNotification = () => ({
  type: TYPES.OTPVerificationActions.CLEAN_OTP_VERIFICATION_NOTIFICATION,
})

export const fetchAttempts = (contractId: string): TYPES.FetchOTPVerificationAttempts => ({
  type: fetchAttemptsTypes.request,
  payload: { contractId },
})
export const fetchAttemptsSuccess = (
  data: OTPVerificationAttemptsResponse
): TYPES.FetchOTPVerificationAttemptsSuccess => ({
  type: fetchAttemptsTypes.success,
  payload: { data },
})
export const fetchAttemptsFailure = (payload: FetchFailurePayload): FetchFailureAction => ({
  type: fetchAttemptsTypes.failure,
  payload,
})

export const fetchResendCode = () => ({
  type: fetchResendCodeTypes.request,
})
export const fetchResendCodeSuccess = () => ({
  type: fetchResendCodeTypes.success,
})
export const fetchResendCodeFailure = (payload: FetchFailurePayload): FetchFailureAction => ({
  type: fetchResendCodeTypes.failure,
  payload,
})

export const setPhoneNumber = (phoneNumber: string): TYPES.SetPhoneNumber => ({
  type: TYPES.OTPVerificationActions.SET_PHONE_NUMBER,
  payload: { phoneNumber },
})
