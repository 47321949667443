import React, { FC, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

import { useDispatchUnmount } from '../../../../../hooks/useDispatchUnmount'
import { cleanVerifyPassword, fetchVerifyPassword } from '../../../../../store/verifyPassword/verifyPassword.actions'
import {
  verifyPasswordIsFailureSelector,
  verifyPasswordSuccessSelector,
} from '../../../../../store/verifyPassword/verifyPassword.selectors'
import translations from '../../../../../translations/keys'
import { VerifyPasswordKeys } from '../../../../../types/verifyPassword'
import AccessGuard from '../../../AccessGuard'
import { AccessGuardFormSubmit } from '../../../AccessGuardForm/AccessGuardForm.types'
import Modal from '../../../Modal'

import { ChangeEmailModalProps } from './ChangeEmailModal.types'
import { ChangeEmailForm } from './components/ChangeEmailForm/ChangeEmailForm'

export const StyledContent = styled.div`
  width: 320px;
  padding-bottom: 21px;
  padding-top: 24px;
`

export const ChangeEmailModal: FC<ChangeEmailModalProps> = ({ onClose }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const access = useSelector(verifyPasswordSuccessSelector(VerifyPasswordKeys.EDIT_EMAIL))
  const isError = useSelector(verifyPasswordIsFailureSelector(VerifyPasswordKeys.EDIT_EMAIL))

  const onSubmit: AccessGuardFormSubmit = (values) => {
    dispatch(fetchVerifyPassword(VerifyPasswordKeys.EDIT_EMAIL, values))
  }
  const onCleanVerifyPassword = useCallback(() => cleanVerifyPassword(VerifyPasswordKeys.EDIT_EMAIL), [])

  useDispatchUnmount(onCleanVerifyPassword)

  return (
    <Modal onClose={onClose}>
      <StyledContent>
        <AccessGuard
          access={access}
          onSubmit={onSubmit}
          title={t(translations.ENTER_YOUR_PASSWORD_TITLE)}
          description={t(translations.ENTER_YOUR_PASSWORD_DESCRIPTION)}
          isError={isError}
        >
          <ChangeEmailForm onClose={onClose} />
        </AccessGuard>
      </StyledContent>
    </Modal>
  )
}
