import { FetchFailureAction, FETCH_STATUSES } from '../../../types/fetch'
import { createReducer } from '../../createReducer'

import * as TYPES from './folders.types'
import * as ACTIONS from './folders.actions'

type Actions = FetchFailureAction | TYPES.FetchFoldersSuccessAction | TYPES.CreateFolder

export const initialState: TYPES.FoldersState = {
  upload: {
    fetchStatus: FETCH_STATUSES.IDLE,
    error: null,
  },
  data: [],
  error: null,
  lastAddedFolder: null,
  fetchStatus: FETCH_STATUSES.IDLE,
}

export const folders = createReducer<TYPES.FoldersState, Actions>(initialState, {
  [ACTIONS.fetchFoldersTypes.request]: (state) => ({
    ...state,
    fetchStatus: FETCH_STATUSES.REQUEST,
  }),
  [ACTIONS.fetchFoldersTypes.success]: (state, action) => ({
    ...state,
    fetchStatus: FETCH_STATUSES.SUCCESS,
    data: (action as TYPES.FetchFoldersSuccessAction).payload.data,
  }),
  [ACTIONS.fetchFoldersTypes.failure]: (state, action) => ({
    ...state,
    fetchStatus: FETCH_STATUSES.FAILURE,
    error: (action as FetchFailureAction).payload,
  }),
  [TYPES.FoldersActions.CREATE_FOLDER]: (state, action) => {
    const folder = (action as TYPES.CreateFolder).payload.folder

    return {
      ...state,
      data: [...state.data, folder],
      lastAddedFolder: folder,
    }
  },
  [ACTIONS.fetchUploadFolderTypes.request]: (state) => ({
    ...state,
    upload: {
      ...state.upload,
      fetchStatus: FETCH_STATUSES.REQUEST,
    },
  }),
  [ACTIONS.fetchUploadFolderTypes.success]: (state) => ({
    ...state,
    upload: {
      ...state.upload,
      fetchStatus: FETCH_STATUSES.SUCCESS,
    },
  }),
  [ACTIONS.fetchUploadFolderTypes.failure]: (state, action) => ({
    ...state,
    upload: {
      ...state.upload,
      fetchStatus: FETCH_STATUSES.FAILURE,
      error: (action as FetchFailureAction).payload,
    },
  }),
  [TYPES.FoldersActions.CLEAN_FOLDER]: () => ({ ...initialState }),
})
