import { FetchFailureAction, FETCH_STATUSES } from '../../../types/fetch'
import { createReducer } from '../../createReducer'

import * as TYPES from './otpVerification.types'
import * as ACTIONS from './otpVerification.actions'

type Actions =
  | TYPES.FetchOTPVerificationPhoneNumberSuccessAction
  | FetchFailureAction
  | TYPES.FetchVerifyOTPSuccessAction
  | TYPES.FetchSendCodeSuccess
  | TYPES.SetNotificationAction
  | TYPES.FetchOTPVerificationAttemptsSuccess
  | TYPES.SetPhoneNumber

export const initialState: TYPES.OTPVerificationState = {
  phoneNumber: '',
  verify: {
    fetchStatus: FETCH_STATUSES.IDLE,
    error: null,
    token: null,
  },
  sendCode: {
    fetchStatus: FETCH_STATUSES.IDLE,
    error: null,
  },
  recipientId: '',
  notification: null,
  attempts: {
    fetchStatus: FETCH_STATUSES.IDLE,
    error: null,
    count: null,
  },
  resendCode: {
    fetchStatus: FETCH_STATUSES.IDLE,
    error: null,
  },
}

export const otpVerification = createReducer<TYPES.OTPVerificationState, Actions>(initialState, {
  [ACTIONS.fetchVerifyOTPTypes.request]: (state) => ({
    ...state,
    verify: {
      ...state.verify,
      fetchStatus: FETCH_STATUSES.REQUEST,
    },
  }),
  [ACTIONS.fetchVerifyOTPTypes.success]: (state, action) => ({
    ...state,
    verify: {
      ...state.verify,
      fetchStatus: FETCH_STATUSES.SUCCESS,
      token: (action as TYPES.FetchVerifyOTPSuccessAction).payload.token,
    },
  }),
  [ACTIONS.fetchVerifyOTPTypes.failure]: (state, action) => ({
    ...state,
    verify: {
      ...state.verify,
      fetchStatus: FETCH_STATUSES.FAILURE,
      error: (action as FetchFailureAction).payload,
    },
  }),

  [ACTIONS.fetchSendCodeTypes.request]: (state) => ({
    ...state,
    sendCode: {
      ...state.sendCode,
      fetchStatus: FETCH_STATUSES.REQUEST,
    },
  }),
  [ACTIONS.fetchSendCodeTypes.success]: (state, action) => ({
    ...state,
    sendCode: {
      ...state.sendCode,
      fetchStatus: FETCH_STATUSES.SUCCESS,
    },
    recipientId: (action as TYPES.FetchSendCodeSuccess).payload.recipientId,
  }),
  [ACTIONS.fetchSendCodeTypes.failure]: (state, action) => ({
    ...state,
    sendCode: {
      ...state.sendCode,
      fetchStatus: FETCH_STATUSES.FAILURE,
      error: (action as FetchFailureAction).payload,
    },
  }),

  [TYPES.OTPVerificationActions.CLEAN_OTP_VERIFICATION]: () => ({ ...initialState }),
  [TYPES.OTPVerificationActions.SET_OTP_VERIFICATION_NOTIFICATION]: (state, action) => ({
    ...state,
    notification: (action as TYPES.SetNotificationAction).payload,
  }),
  [TYPES.OTPVerificationActions.CLEAN_OTP_VERIFICATION_NOTIFICATION]: (state) => ({
    ...state,
    notification: initialState.notification,
  }),

  [ACTIONS.fetchAttemptsTypes.request]: (state) => ({
    ...state,
    attempts: {
      ...state.attempts,
      fetchStatus: FETCH_STATUSES.REQUEST,
    },
  }),
  [ACTIONS.fetchAttemptsTypes.success]: (state, action) => ({
    ...state,
    attempts: {
      ...state.attempts,
      fetchStatus: FETCH_STATUSES.SUCCESS,
      count: (action as TYPES.FetchOTPVerificationAttemptsSuccess).payload.data.attempts,
    },
  }),
  [ACTIONS.fetchAttemptsTypes.failure]: (state, action) => ({
    ...state,
    attempts: {
      ...state.attempts,
      fetchStatus: FETCH_STATUSES.FAILURE,
      error: (action as FetchFailureAction).payload,
    },
  }),

  [ACTIONS.fetchResendCodeTypes.request]: (state) => ({
    ...state,
    resendCode: {
      ...state.resendCode,
      fetchStatus: FETCH_STATUSES.REQUEST,
    },
  }),
  [ACTIONS.fetchResendCodeTypes.success]: (state) => ({
    ...state,
    resendCode: {
      ...state.resendCode,
      fetchStatus: FETCH_STATUSES.SUCCESS,
    },
  }),
  [ACTIONS.fetchResendCodeTypes.failure]: (state, action) => ({
    ...state,
    resendCode: {
      ...state.resendCode,
      fetchStatus: FETCH_STATUSES.FAILURE,
      error: (action as FetchFailureAction).payload,
    },
  }),

  [TYPES.OTPVerificationActions.SET_PHONE_NUMBER]: (state, action) => ({
    ...state,
    phoneNumber: (action as TYPES.SetPhoneNumber).payload.phoneNumber,
  }),
})
