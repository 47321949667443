import { FetchFailureAction, FETCH_STATUSES } from '../../types/fetch'
import { createReducer } from '../createReducer'

import * as ACTIONS from './forgotPassword.actions'
import * as TYPES from './forgotPassword.types'

type Actions = TYPES.FetchForgotPasswordAction | TYPES.FetchForgotPasswordSuccessAction | FetchFailureAction

const initialState: TYPES.ForgotPasswordState = {
  forgotPasswordFetchStatus: FETCH_STATUSES.IDLE,
  resendVerificationFetchStatus: FETCH_STATUSES.IDLE,
  email: '',
}

export const forgotPassword = createReducer<TYPES.ForgotPasswordState, Actions>(initialState, {
  [ACTIONS.fetchForgotPasswordTypes.request]: (state) => ({
    ...state,
    forgotPasswordFetchStatus: FETCH_STATUSES.REQUEST,
  }),
  [ACTIONS.fetchForgotPasswordTypes.success]: (state, action) => {
    const act = action as TYPES.FetchForgotPasswordSuccessAction
    return {
      ...state,
      forgotPasswordFetchStatus: FETCH_STATUSES.SUCCESS,
      email: act.payload.email,
      forgotPasswordMessage: act.payload.message,
    }
  },
  [ACTIONS.fetchForgotPasswordTypes.failure]: (state, action) => ({
    ...state,
    forgotPasswordFetchStatus: FETCH_STATUSES.FAILURE,
    forgotPasswordError: (action as FetchFailureAction).payload,
  }),
  [ACTIONS.fetchResendVerificationTypes.request]: (state) => ({
    ...state,
    resendVerificationFetchStatus: FETCH_STATUSES.REQUEST,
  }),
  [ACTIONS.fetchResendVerificationTypes.success]: (state) => ({
    ...state,
    resendVerificationFetchStatus: FETCH_STATUSES.SUCCESS,
  }),
  [ACTIONS.fetchResendVerificationTypes.failure]: (state, action) => ({
    ...state,
    resendVerificationFetchStatus: FETCH_STATUSES.FAILURE,
    resendVerificationError: (action as FetchFailureAction).payload,
  }),
  [TYPES.ForgotPasswordActions.RESET_FORGOT_PASSWORD]: () => ({ ...initialState }),
})
