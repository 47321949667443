import styled, { css } from 'styled-components'

import Box from '../Box'

export const StyledWrapper = styled(Box).attrs({
  display: 'flex',
  justifyContent: 'center',
})<{ isDragActive: boolean }>`
  background-color: ${(props) => props.theme.palette.blueExtraLight};
  border-width: 1px;
  border-style: dashed;
  border-color: ${(props) => (props.isDragActive ? props.theme.palette.black : props.theme.palette.grey)};
  border-radius: 4px;
  color: ${(props) => props.theme.palette.grey};
  padding: 32px;
  width: 100%;
  cursor: pointer;
`

export const StyledValidationInfo = styled(Box).attrs({
  mt: 2,
})`
  color: ${(props) => props.theme.palette.grey};
`

export const StyledError = styled(Box).attrs({
  mt: 2,
})`
  color: ${(props) => props.theme.palette.errorColor};
`

export const StyledDropzone = styled.div<{ disabled: boolean }>`
  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
    `}

  &:focus {
    outline: none;
  }
`
