import { FetchFailureAction, FetchFailurePayload } from '../../../types/fetch'
import { UploadDocumentBody, UploadDocumentBodyNative, UploadDocumentResponse } from '../../../types/uploadDocument'
import { createFetchActionTypes } from '../../../utils/createFetchActionTypes'

import * as TYPES from './upload.types'

export const fetchUploadTypes = createFetchActionTypes(TYPES.UploadActions.FETCH_UPLOAD)

export const fetchUpload = (body: UploadDocumentBody | UploadDocumentBodyNative): TYPES.FetchUpload => ({
  type: fetchUploadTypes.request,
  payload: { body },
})

export const fetchUploadSuccess = (response: UploadDocumentResponse): TYPES.FetchUploadSuccess => ({
  type: fetchUploadTypes.success,
  payload: { id: response.id },
})

export const fetchUploadFailure = (payload: FetchFailurePayload): FetchFailureAction => ({
  type: fetchUploadTypes.failure,
  payload,
})

export const cleanUpload = () => ({
  type: TYPES.UploadActions.CLEAN_UPLOAD,
})
