import React from 'react'

import MainTemplate from '../../components/Main/MainTemplate'

import { SubscriptionPayment as SubscriptionPaymentView } from './SubscriptionPayment'

export const SubscriptionPayment = () => (
  <MainTemplate>
    <SubscriptionPaymentView />
  </MainTemplate>
)
