import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { useDispatchUnmount } from '../useDispatchUnmount'
import { fetchNotifications, cleanNotifications } from '../../store/preferences/notifications/notifications.actions'

export const useNotificationsFetch = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchNotifications())
  }, [])

  useDispatchUnmount(cleanNotifications)
}
