import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { socketService } from '../../api'
import { PROCESS_PAYMENT_ERROR, SOCKET_CONNECT_ERROR } from '../../constants/errors'
import { SocketEvents } from '../../constants/sockets'
import { authUserIdSelector } from '../../store/auth/auth.selectors'
import { addCard } from '../../store/subscription/cards/cards.actions'
import { setPaymentProcessError, verifyPaymentCard } from '../../store/subscription/payment/payment.actions'
import translations from '../../translations'
import { CardVerifiedResponse, PaymentEventError, TokenizationResponse } from '../../types/subscriptionPayment'

export type HandleTokenizationCompleted = (data: TokenizationResponse) => void

export const useSubscribeOnPayment = (props: { handleTokenizationCompleted: HandleTokenizationCompleted }) => {
  const dispatch = useDispatch()
  const [socket, setSocket] = useState<SocketIOClient.Socket | null>(null)
  const userId = useSelector(authUserIdSelector)

  const closeSocket = useCallback(() => {
    if (socket) {
      socket.close()
      setSocket(null)
    }
  }, [socket])

  useEffect(() => {
    if (userId && !socket) {
      setSocket(socketService.io())
    }

    if (!userId && socket) {
      closeSocket()
    }
  }, [userId, socket, closeSocket])

  useEffect(() => {
    if (socket) {
      const handleError = (errorMessage: string) => {
        dispatch(
          setPaymentProcessError({
            lastErrorCode: PROCESS_PAYMENT_ERROR,
            errorCodes: [PROCESS_PAYMENT_ERROR],
            errorMessage,
          })
        )
      }
      const handleTokenizationCompleted = (data: TokenizationResponse) => {
        if (data.error) {
          handleError(data.error)
        } else {
          props.handleTokenizationCompleted(data)
        }
      }
      const handleCardVerified = ({ error, ...card }: CardVerifiedResponse) => {
        if (error) {
          handleError(error)
        } else {
          dispatch(addCard(card))
          dispatch(verifyPaymentCard())
        }
      }
      const handlePurchasedCompleted = (data: PaymentEventError) => {
        if (data?.error) {
          handleError(data.error)
        }
      }
      const handleRefundCompleted = (data: PaymentEventError) => {
        if (data?.error) {
          handleError(data.error)
        }
      }

      socket.on(`${userId}.${SocketEvents.TOKENIZATION_COMPLETED}`, handleTokenizationCompleted)
      socket.on(`${userId}.${SocketEvents.CARD_VERIFIED}`, handleCardVerified)
      socket.on(`${userId}.${SocketEvents.PURCHASE_COMPLETED}`, handlePurchasedCompleted)
      socket.on(`${userId}.${SocketEvents.REFUND_COMPLETED}`, handleRefundCompleted)
    }

    return () => closeSocket()
  }, [socket, userId, closeSocket, props.handleTokenizationCompleted])

  useEffect(() => {
    if (socket) {
      socket.on('connect_error', (error: Error) => {
        console.error(error)
        dispatch(
          setPaymentProcessError({
            lastErrorCode: SOCKET_CONNECT_ERROR,
            errorCodes: [SOCKET_CONNECT_ERROR],
            errorMessage: translations[SOCKET_CONNECT_ERROR],
          })
        )
      })
    }
  }, [socket])
}
