import styled, { css } from 'styled-components'

import fancyBorder from '../../utils/fancyBorder'
import { BUTTON_VARIANTS } from '../Button/Button.types'
import Button from '../Button'

const selectedStyles = css`
  background-image: ${({ theme }) => fancyBorder(theme.palette.blueOcean, 4)};
  z-index: 100;
`
const editModeStyles = css`
  background-color: rgba(255, 255, 255, 0.95);
  background-image: ${({ theme }) => fancyBorder(theme.palette.grey, 2)};
`
const readonlyModeStyles = css<{ isVisible?: boolean }>`
  background-color: ${({ theme, isVisible }) =>
    isVisible ? theme.palette.blueExtraLight : 'rgba(255, 255, 255, 0.5)'};
`

export const TextArea = styled.textarea<{
  fontSize: number
}>`
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 8px 16px;
  font-family: Tahoma;
  font-size: ${({ fontSize }) => `${fontSize}px`};
  line-height: 18px;
  vertical-align: middle;
  background: none;
  border: 0;
  outline: 0;
  resize: none;
  appearance: none;
  ${({ disabled }) => disabled && 'pointer-events: none;'}

  &::placeholder {
    color: ${({ theme }) => theme.palette.grey};
  }
`
export const Wrapper = styled.div<{
  isSelected?: boolean
  isEditMode?: boolean
  isVisible?: boolean
  isEditable?: boolean
}>`
  position: absolute;
  cursor: pointer;
  transform: translateZ(0);
  border-radius: 4px;
  ${({ isEditable }) => (isEditable ? editModeStyles : readonlyModeStyles)}
  ${({ isSelected }) => isSelected && selectedStyles}

  &: hover {
    z-index: 100;
  }
`
export const Actions = styled.div`
  display: flex;
  position: absolute;
  right: 0;
  top: -44px;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.palette.white};
  background-color: ${({ theme }) => theme.palette.blueDark};
  border-radius: 4px;
`
export const Action = styled.div`
  display: flex;
`
export const ActionButton = styled(Button).attrs({ variant: BUTTON_VARIANTS.INACTIVE })`
  width: 40px;
  height: 40px;

  * {
    pointer-events: none;
  }
`

export const Confirmation = styled.div`
  position: absolute;
  top: 100%;
  margin-top: 4px;
`
