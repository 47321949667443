import { useCallback } from 'react'
import { useDispatch } from 'react-redux'

import { openModal } from '../../../store/modals/modals.actions'
import translations from '../../../translations/keys'
import { location } from '../../../utils/location'
import { COMMON_MODALS_IDS } from '../../ui/Modals/CommonModals/CommonModals.constants'
import { NOTIFICATION_MODAL_TYPES } from '../../ui/Modals/NotificationModal/NotificationModal.constants'

export const useShowNoSignature = () => {
  const dispatch = useDispatch()
  const showModal = useCallback(() => {
    dispatch(
      openModal(COMMON_MODALS_IDS.NOTIFICATION, {
        type: NOTIFICATION_MODAL_TYPES.WARNING,
        title: translations.NO_SIGNATURE_IN_ACCOUNT,
        description: translations.NEW_TO_CREATE_SIGNATURE,
        successButtonText: translations.ADD_SIGNATURES,
        onSuccess: () => {
          window.open(location.signatureAndInitialsUrl(), '_blank')
        },
        onlySuccess: true,
      })
    )
  }, [])

  return { showNoSignatureModal: showModal }
}
