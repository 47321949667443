import React from 'react'

import highlightText from '../../../../../../../utils/highlightText'
import { HighlightedText } from '../../../../../Table/Table.styles'

import { ContactProps } from './Contact.types'
import { StyledWrapper } from './Contact.styles'

export const Contact: React.FC<ContactProps> = ({ data, onSelect, searchText }) => {
  const onClick = () => {
    onSelect(data)
  }

  return (
    <StyledWrapper onClick={onClick}>
      <span>{highlightText(data.fullName, searchText, HighlightedText, 'span')}</span>
      <span>{highlightText(data.email, searchText, HighlightedText, 'span')}</span>
    </StyledWrapper>
  )
}
