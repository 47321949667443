import React from 'react'
import { useSelector } from 'react-redux'

import { useNotificationsFetch } from '../../../hooks/preferences/useNotificationsFetch'
import * as NOTIFICATIONS_SELECTORS from '../../../store/preferences/notifications/notifications.selectors'
import { PreferencesNotificationsSkeleton } from '../../components/Skeletons/PreferencesNotificationsSkeleton'
import Box from '../../ui/Box'

import Notifications from './components/Notifications'

export const PreferencesNotifications = () => {
  const notificationsData = useSelector(NOTIFICATIONS_SELECTORS.notificationsDataSelector)

  useNotificationsFetch()

  return (
    <Box width={777}>
      {notificationsData ? <Notifications data={notificationsData} /> : <PreferencesNotificationsSkeleton />}
    </Box>
  )
}
