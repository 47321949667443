import { Action } from 'redux'

import { FetchFailurePayload, FETCH_STATUSES } from '../../../types/fetch'
import { EditableElem } from '../../../types/editableElem'
import {
  DocCommentsResponseBody,
  DocCommentsById,
  DocCommentsByPages,
  DocComment,
  DocCommentsThreadRequestBody,
  DocCommentsThread,
  DocCommentsConfirmRequest,
  DocCancelCommentsConfirmation,
} from '../../../types/comment'
import { ConfirmationStatus } from '../../../types/confirmationActions'

export interface CommentsState {
  byId: DocCommentsById
  byPages: DocCommentsByPages
  thread: DocCommentsThread[]
  fetch: {
    status: FETCH_STATUSES
    error: FetchFailurePayload | null
  }
  fetchThread: {
    status: FETCH_STATUSES
    error: FetchFailurePayload | null
  }
  fetchCreate: {
    status: FETCH_STATUSES
    error: FetchFailurePayload | null
  }
  update: {
    status: FETCH_STATUSES
    error: FetchFailurePayload | null
  }
  confirm: {
    status: FETCH_STATUSES
    error: FetchFailurePayload | null
    confirmStatus: ConfirmationStatus | null
  }
  cancelConfirmation: {
    status: FETCH_STATUSES
    error: FetchFailurePayload | null
    confirmStatus: ConfirmationStatus | null
  }
}

export enum CommentsActions {
  'ADD_COMMENT' = 'ADD_COMMENT',
  'SET_COMMENT' = 'SET_COMMENT',
  'UNSET_COMMENT' = 'UNSET_COMMENT',
  'CLEAR_COMMENTS' = 'CLEAR_COMMENTS',
  'CLEAR_THREAD' = 'CLEAR_THREAD',
  'FETCH_COMMENTS' = 'FETCH_COMMENTS',
  'FETCH_COMMENTS_THREAD' = 'FETCH_COMMENTS_THREAD',
  'FETCH_CREATE_COMMENT' = 'FETCH_CREATE_COMMENT',
  'FETCH_UPDATE_COMMENTS' = 'FETCH_UPDATE_COMMENTS',
  'FETCH_CONFIRM_COMMENTS' = 'FETCH_CONFIRM_COMMENTS',
  'FETCH_CANCEL_COMMENTS_CONFIRMATION' = 'FETCH_CANCEL_COMMENTS_CONFIRMATION',
}

export interface AddCommentAction extends Action {
  payload: { comment: DocComment }
}
export interface SetCommentAction extends Action {
  payload: { comment: EditableElem }
}
export interface UnsetCommentAction extends Action {
  payload: { id: string }
}
export type ClearCommentsAction = Action
export type ClearThreadAction = Action

export interface FetchCommentsAction extends Action {
  payload: { contractId: string }
}
export interface FetchCommentsSuccessAction extends Action {
  payload: { byId: DocCommentsById; byPages: DocCommentsByPages }
}

export interface FetchCommentsThreadAction extends Action {
  payload: DocCommentsThreadRequestBody
}
export interface FetchCommentsThreadSuccessAction extends Action {
  payload: { comments: DocCommentsThread[] }
}

export interface FetchCreateCommentAction extends Action {
  payload: { contractId: string; comment: DocComment }
}
export interface FetchCreateCommentSuccessAction extends Action {
  payload: DocCommentsResponseBody
}

export interface FetchUpdateCommentsAction extends Action {
  payload: { contractId: string; byId: DocCommentsById }
}
export interface FetchUpdateCommentsSuccessAction extends Action {
  payload: DocCommentsResponseBody
}

export interface FetchConfirmCommentsAction extends Action {
  payload: DocCommentsConfirmRequest
}
export interface FetchConfirmCommentsSuccessAction extends Action {
  payload: { confirmStatus: ConfirmationStatus }
}

export interface FetchCancelCommentsConfirmationAction extends Action {
  payload: DocCancelCommentsConfirmation
}
export interface FetchCancelCommentsConfirmationSuccessAction extends Action {
  payload: { confirmStatus: ConfirmationStatus }
}
