import { FetchFailureAction, FetchFailurePayload } from '../../../types/fetch'
import { createFetchActionTypes } from '../../../utils/createFetchActionTypes'
import { SigningContractDocumentData, DefaultSign } from '../../../types/signingContract'
import { DeclineSigningBody } from '../../../types/declineSigning'
import { Notification } from '../../../types/notifications'

import * as TYPES from './signing.types'

export const fetchSigningContractDocumentTypes = createFetchActionTypes(
  TYPES.SigningActions.FETCH_SIGNING_CONTRACT_DOCUMENT
)
export const fetchSignContractTypes = createFetchActionTypes(TYPES.SigningActions.FETCH_SIGN_CONTRACT)
export const fetchDefaultSignTypes = createFetchActionTypes(TYPES.SigningActions.FETCH_DEFAULT_SIGN)
export const scrollToNearestTypes = createFetchActionTypes(TYPES.SigningActions.SCROLL_TO_NEAREST_SIGN)
export const fetchDeclineSigningTypes = createFetchActionTypes(TYPES.SigningActions.FETCH_DECLINE_SIGNING)
export const setSignatureTypes = createFetchActionTypes(TYPES.SigningActions.SET_SIGNATURE)
export const fetchViewedContractTypes = createFetchActionTypes(TYPES.SigningActions.FETCH_VIEWED_CONTRACT)
export const fetchTotalPagesTypes = createFetchActionTypes(TYPES.SigningActions.FETCH_TOTAL_PAGES)

export const fetchSigningContractDocument = (contractId: string): TYPES.FetchSigningContractDocumentAction => ({
  type: fetchSigningContractDocumentTypes.request,
  payload: { contractId },
})
export const fetchSigningContractDocumentSuccess = (
  data: SigningContractDocumentData
): TYPES.FetchSigningContractDocumentSuccessAction => ({
  type: fetchSigningContractDocumentTypes.success,
  payload: data,
})
export const fetchSigningContractDocumentFailure = (payload: FetchFailurePayload): FetchFailureAction => ({
  type: fetchSigningContractDocumentTypes.failure,
  payload,
})

export const clearSigning = () => ({
  type: TYPES.SigningActions.CLEAR_SIGNING,
})

export const fetchSignContract = (): TYPES.FetchSignContract => ({
  type: fetchSignContractTypes.request,
})
export const fetchSignContractSuccess = (contractId: string): TYPES.FetchSignContractSuccess => ({
  type: fetchSignContractTypes.success,
  payload: { contractId },
})
export const fetchSignContractFailure = (payload: FetchFailurePayload): FetchFailureAction => ({
  type: fetchSignContractTypes.failure,
  payload,
})

export const fetchDefaultSign = (): TYPES.FetchDefaultSign => ({
  type: fetchDefaultSignTypes.request,
})
export const fetchDefaultSignSuccess = (signature: DefaultSign): TYPES.FetchDefaultSignSuccess => ({
  type: fetchDefaultSignTypes.success,
  payload: signature,
})
export const fetchDefaultSignFailure = (payload: FetchFailurePayload): FetchFailureAction => ({
  type: fetchDefaultSignTypes.failure,
  payload,
})

export const scrollToNearestSign = () => ({
  type: scrollToNearestTypes.request,
})
export const scrollToNearestSignSuccess = (id: string): TYPES.ScrollToNearestSignSuccess => ({
  type: scrollToNearestTypes.success,
  payload: id,
})
export const scrollToNearestSignFailure = (payload: FetchFailurePayload): FetchFailureAction => ({
  type: fetchDefaultSignTypes.failure,
  payload,
})

export const setSigningNotification = (notification: Notification): TYPES.SetSigningSuccessMessageAction => ({
  type: TYPES.SigningActions.SET_SIGNING_SUCCESS_MESSAGE,
  payload: notification,
})
export const clearSigningSuccessMessage = () => ({
  type: TYPES.SigningActions.CLEAR_SIGNING_SUCCESS_MESSAGE,
})

export const fetchDeclineSigning = (
  recipientId: string,
  body: DeclineSigningBody
): TYPES.FetchDeclineSigningAction => ({
  type: fetchDeclineSigningTypes.request,
  payload: { recipientId, body },
})
export const fetchDeclineSigningSuccess = (): TYPES.FetchDeclineSigningSuccessAction => ({
  type: fetchDeclineSigningTypes.success,
})
export const fetchDeclineSigningFailure = (payload: FetchFailurePayload): FetchFailureAction => ({
  type: fetchDeclineSigningTypes.failure,
  payload,
})
export const setSignature = ({
  placeId,
  image,
  signatureId,
  timestamp,
  isChanged,
}: TYPES.SetSignaturePayload): TYPES.SetSignatureAction => ({
  type: setSignatureTypes.request,
  payload: { placeId, image, signatureId, timestamp, isChanged },
})

export const setSignatureSuccess = ({
  placeId,
  image,
  signatureId,
  timestamp,
  placementById,
}: TYPES.SetSignaturePayloadSuccess): TYPES.SetSignatureSuccessAction => ({
  type: setSignatureTypes.success,
  payload: { placeId, image, signatureId, timestamp, placementById },
})
export const setSignatureFailure = (payload: FetchFailurePayload): FetchFailureAction => ({
  type: setSignatureTypes.failure,
  payload,
})

export const fetchViewedContract = (): TYPES.FetchViewedContract => ({
  type: fetchViewedContractTypes.request,
})
export const fetchViewedContractSuccess = (): TYPES.FetchViewedContractSuccess => ({
  type: fetchViewedContractTypes.success,
})
export const fetchViewedContractFailure = (payload: FetchFailurePayload): FetchFailureAction => ({
  type: fetchViewedContractTypes.failure,
  payload,
})

export const fetchTotalPages = (): TYPES.FetchTotalPages => ({
  type: fetchTotalPagesTypes.request,
})
export const fetchTotalPagesSuccess = (payload: SigningContractDocumentData): TYPES.FetchTotalPagesSuccess => ({
  type: fetchTotalPagesTypes.success,
  payload,
})
export const fetchTotalPagesFailure = (payload: FetchFailurePayload): FetchFailureAction => ({
  type: fetchTotalPagesTypes.failure,
  payload,
})

export const setRecipientToken = (recipientToken: string): TYPES.SetRecipientToken => ({
  type: TYPES.SigningActions.SET_RECIPIENT_TOKEN,
  payload: { recipientToken },
})
