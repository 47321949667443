import styled, { css } from 'styled-components'

import Box from '../Box'
import Button from '../Button'
import { BUTTON_VARIANTS } from '../Button/Button.types'

const disabledStyles = css`
  opacity: 0.4;
  pointer-events: none;
`
const selectedStyles = css`
  color: ${({ theme }) => theme.palette.white};
  background-color: ${({ theme }) => theme.palette.blue} !important;
`
const noPointerEventsStyles = css`
  & * {
    pointer-events: none;
  }
`

export const Wrapper = styled.div`
  position: absolute;
  top: 24px;
  right: 56px;
  z-index: 110;
  ${({ theme }) => `@media ${theme.devices.tablet} {
    display: none;
  }`}
`
export const Controls = styled(Box).attrs({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
})`
  overflow: hidden;
  background-color: ${({ theme }) => theme.palette.white};
  border-radius: 4px;
  box-shadow: ${({ theme }) => theme.shadow};
`
export const Control = styled(Box).attrs({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  pt: 1,
  pr: 2,
  pb: 1,
  pl: 2,
  width: '100%',
})<{ disabled?: boolean; selected?: boolean; noPointerEvents?: boolean }>`
  cursor: pointer;
  transition: background-color 200ms;
  user-select: none;
  line-height: 18px;
  ${({ disabled }) => disabled && disabledStyles}
  ${({ selected }) => selected && selectedStyles}
  ${({ noPointerEvents }) => noPointerEvents && noPointerEventsStyles}

  & ~ & {
    margin-top: 8px;
  }
  &:hover {
    background-color: ${({ theme }) => theme.palette.blueExtraLight};
  }
  &:first-child {
    padding-top: 16px;
  }
  &:last-child {
    padding-bottom: 16px;
  }
`
export const Option = styled(Button).attrs({ variant: BUTTON_VARIANTS.TEXT })`
  display: block;
  width: 100%;
  padding: 5px 25px;
  text-align: center;

  &:hover {
    background-color: ${({ theme }) => theme.palette.blueExtraLight};
  }
`
export const Separator = styled.div`
  width: 70%;
  margin-top: 15px;
  margin-bottom: 8px;
  border-bottom: ${({ theme }) => `1px solid ${theme.palette.grey}`};
`
