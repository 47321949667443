import { useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { FormikProps } from 'formik'

import translations from '../../../../translations/keys'
import { TEMPLATES_MODALS } from '../../../../constants/templates'
import { FETCH_STATUSES } from '../../../../types/fetch'
import { TemplateRouteParams } from '../../../types/template'
import { location } from '../../../../utils/location'
import { prepareAllRecipientsForSubmit } from '../../../../utils/recipients'
import { useHistoryPushAfterSuccess } from '../../../hooks/useHistoryPushAfterSuccess'
import { useDispatchUnmount } from '../../../../hooks/useDispatchUnmount'
import { useShowFetchError } from '../../../hooks/useShowFetchError'
import { clearPlacement, fetchGeneratePlacement } from '../../../../store/templates/placement/placement.actions'
import {
  placementFetchSelector,
  placementShouldRegenerateSelector,
  placementSortedSelector,
  placementFetchGenerateSelector,
  placementGenerateIsSuccess,
} from '../../../../store/templates/placement/placement.selectors'
import { fetchAddRecipients, clearRecipients } from '../../../../store/templates/recipients/recipients.actions'
import { openModal } from '../../../../store/modals/modals.actions'
import {
  recipientsFetchAddSelector,
  recipientsByIdSelector,
  recipientsIsDocumentExistsSelector,
  recipientsOrderSelector,
  recipientsFetchInfoSelector,
} from '../../../../store/templates/recipients/recipients.selectors'
import { reuploadIsSuccessSelector } from '../../../../store/templates/reupload/reupload.selectors'
import { BUTTON_VARIANTS } from '../../../ui/Button/Button.types'
import { PlayIcon } from '../../../assets/icons'
import { DocumentMessageForm } from '../../../../types/documents'
import { fetchSaveTemplateMessage } from '../../../../store/templates/message/message.actions'

export const useButtons = (formRef: React.MutableRefObject<FormikProps<DocumentMessageForm> | null>) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { templateId } = useParams<TemplateRouteParams>()
  const [redirectUrl, setRedirectUrl] = useState('')
  const byId = useSelector(recipientsByIdSelector)
  const placementSorted = useSelector(placementSortedSelector)
  const order = useSelector(recipientsOrderSelector)
  const fetchAdd = useSelector(recipientsFetchAddSelector)
  const fetchPlacementStatus = useSelector(placementFetchSelector)
  const fetchGenerateStatus = useSelector(placementFetchGenerateSelector)
  const fetchInfo = useSelector(recipientsFetchInfoSelector)
  const isReuploadSuccess = useSelector(reuploadIsSuccessSelector)
  const isGeneratePlacementSuccess = useSelector(placementGenerateIsSuccess)
  const isDocumentExists = useSelector(recipientsIsDocumentExistsSelector)
  const recipientsBody = useMemo(
    () => prepareAllRecipientsForSubmit({ recipients: Object.values(byId) }, order, true),
    [byId, templateId]
  )
  const isTemplateInfoReady = fetchInfo.status === FETCH_STATUSES.SUCCESS

  // TODO: remove when API is ready for placement
  const shouldRegenerate = useSelector(placementShouldRegenerateSelector)
  const emptyPlacement =
    fetchPlacementStatus.status === FETCH_STATUSES.FAILURE ||
    (fetchPlacementStatus.status === FETCH_STATUSES.SUCCESS && placementSorted.length === 0)

  const handleSubmit = useCallback(
    (values: DocumentMessageForm) => {
      dispatch(fetchSaveTemplateMessage(templateId, values))
    },
    [templateId]
  )

  const buttons = useMemo(() => {
    if (!isTemplateInfoReady) {
      return []
    }

    const saveAndClose = {
      label: translations.SAVE_AND_CLOSE,
      onClick: () => {
        formRef.current?.handleSubmit()
        dispatch(fetchAddRecipients(templateId, recipientsBody))
        if (emptyPlacement || shouldRegenerate) {
          dispatch(fetchGeneratePlacement(templateId))
          setRedirectUrl(location.templatesAllUrl())
        } else {
          history.push(location.templatesAllUrl())
        }
      },
      MobileIcon: PlayIcon,
      variant: BUTTON_VARIANTS.SECONDARY,
    }
    const saveClmOnly = {
      label: translations.SAVE,
      onClick: () => {
        formRef.current?.handleSubmit()
        dispatch(fetchAddRecipients(templateId, recipientsBody))
        setRedirectUrl(location.templatesViewUrl(templateId))
      },
      MobileIcon: PlayIcon,
    }
    const uploadDocument = {
      label: translations.UPLOAD_DOCUMENT,
      onClick: () => {
        dispatch(openModal(TEMPLATES_MODALS.UPLOAD_TO_CLM_ONLY))
      },
      MobileIcon: PlayIcon,
      variant: BUTTON_VARIANTS.SECONDARY,
    }
    const next = {
      label: translations.NEXT,
      onClick: () => {
        formRef.current?.handleSubmit()
        dispatch(fetchAddRecipients(templateId, recipientsBody))
        if (emptyPlacement || shouldRegenerate) {
          dispatch(fetchGeneratePlacement(templateId))
          setRedirectUrl(location.templatesPlacementUrl(templateId))
        } else {
          history.push(location.templatesPlacementUrl(templateId))
        }
      },
      MobileIcon: PlayIcon,
    }

    if (isDocumentExists) {
      return [saveAndClose, next]
    }

    return [uploadDocument, saveClmOnly]
  }, [
    byId,
    templateId,
    placementSorted,
    fetchPlacementStatus,
    fetchGenerateStatus,
    shouldRegenerate,
    emptyPlacement,
    isTemplateInfoReady,
    isDocumentExists,
  ])

  useEffect(() => {
    if (isReuploadSuccess) {
      if (isGeneratePlacementSuccess) {
        history.push(location.templatesPlacementUrl(templateId))
      } else {
        setRedirectUrl(location.templatesPlacementUrl(templateId))
        dispatch(fetchGeneratePlacement(templateId))
      }
    }
    if (!isDocumentExists && redirectUrl && fetchAdd.status === FETCH_STATUSES.SUCCESS) {
      history.push(redirectUrl)
    }
  }, [isReuploadSuccess, isGeneratePlacementSuccess, fetchAdd, isDocumentExists])

  useHistoryPushAfterSuccess(redirectUrl, fetchGenerateStatus.status)
  useShowFetchError(fetchAdd.status, fetchAdd.error)
  useDispatchUnmount(clearRecipients, clearPlacement)

  return { buttons, handleSubmit }
}
