import { Formik, FormikProps } from 'formik'
import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import translations from '../../../../../translations/keys'
import { ContractSvg } from '../../../../assets/ContractSvg'
import Modal from '../../../Modal'
import Typography from '../../../../ui/Typography'
import { TYPOGRAPHY_NAMES } from '../../../../ui/Typography/Typography.types'
import Box from '../../../../ui/Box'
import FormDropdown from '../../../../ui/Forms/FormDropdown'
import FormTextInput from '../../../../ui/Forms/FormTextInput'
import { FILE_NAME_MAX_LENGTH } from '../../../../../validation/fileNameValidationSchema'
import { useTemplatesFoldersFetch } from '../../../../../hooks/templates/useTemplatesFoldersFetch'
import * as FOLDERS_SELECTORS from '../../../../../store/templates/folders/folders.selectors'
import Button from '../../../../ui/Button'
import { BUTTON_VARIANTS } from '../../../../ui/Button/Button.types'
import { openModal } from '../../../../../store/modals/modals.actions'
import { CONTRACTS_MODALS } from '../../../../../constants/contracts'
import { SaveAsTemplateFields, SaveAsTemplateForm } from '../../../../../types/saveAsTemplate'
import { saveAsTemplateInitialValues } from '../../../../../initialValues/saveAsTemplateInitialValues'
import { saveAsTemplateValidationSchema } from '../../../../../validation/contracts/saveAsTemplateValidationSchema'
import { modalsPayloadSelector } from '../../../../../store/modals/modals.selectors'
import { SaveAsTemplateModalSkeleton } from '../../../Skeletons/SaveAsTemplateModal'
import {
  fetchSaveAsTemplate,
  cleanSaveAsTemplate,
} from '../../../../../store/contracts/saveAsTemplate/saveAsTemplate.actions'
import * as SAVE_AS_TEMPLATE_SELECTORS from '../../../../../store/contracts/saveAsTemplate/saveAsTemplate.selectors'
import { location } from '../../../../../utils/location'
import { useDispatchUnmount } from '../../../../../hooks/useDispatchUnmount'
import { useShowFetchError } from '../../../../hooks/useShowFetchError'
import { useHistoryPushAfterSuccess } from '../../../../hooks/useHistoryPushAfterSuccess'

import { StyledForm } from './SaveAsTemplateModal.styles'
import { SaveAsTemplateModalProps } from './SaveAsTemplateModal.types'

const DROPDOWN_HEIGHT = 150

export const SaveAsTemplateModal: React.FC<SaveAsTemplateModalProps> = ({ onClose }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const formRef: React.RefObject<FormikProps<SaveAsTemplateForm>> = useRef(null)
  const foldersIsLoading = useSelector(FOLDERS_SELECTORS.templatesFoldersIsLoadingSelector)
  const folders = useSelector(FOLDERS_SELECTORS.templatesFoldersDataSelector)
  const lastAddedFolder = useSelector(FOLDERS_SELECTORS.templatesLastAddedFolderSelector)
  const templateId = useSelector(SAVE_AS_TEMPLATE_SELECTORS.saveAsTemplateIdSelector)
  const saveAsTemplateError = useSelector(SAVE_AS_TEMPLATE_SELECTORS.saveAsTemplateErrorSelector)
  const saveAsTemplateFetchStatus = useSelector(SAVE_AS_TEMPLATE_SELECTORS.saveAsTemplateFetchStatusSelector)
  const savedSuccessfully = useSelector(SAVE_AS_TEMPLATE_SELECTORS.saveAsTemplateIsSuccessSelector)
  const { id, oldName } = useSelector(modalsPayloadSelector(CONTRACTS_MODALS.SAVE_CONTRACT_AS_TEMPLATE))
  const initialValues = {
    ...saveAsTemplateInitialValues,
    [SaveAsTemplateFields.name]: oldName,
  }

  useEffect(() => {
    const form = formRef.current

    if (lastAddedFolder && form) {
      form.setFieldValue(SaveAsTemplateFields.folderName, lastAddedFolder.value)
    }
  }, [lastAddedFolder])
  useEffect(() => {
    if (savedSuccessfully) {
      onClose()
    }
  }, [savedSuccessfully])
  useTemplatesFoldersFetch()
  useDispatchUnmount(cleanSaveAsTemplate)
  useShowFetchError(saveAsTemplateFetchStatus, saveAsTemplateError)
  useHistoryPushAfterSuccess(location.templatesViewUrl(templateId), saveAsTemplateFetchStatus)

  const onSubmit = (values: SaveAsTemplateForm) => {
    const folderName = folders.find((folder) => folder.value === values.folderName)?.name || values.folderName

    dispatch(fetchSaveAsTemplate({ contractId: id, folderName, name: values.name }))
  }
  const onCreateFolder = () => {
    dispatch(openModal(CONTRACTS_MODALS.CREATE_FOLDER_FOR_TEMPLATE))
  }

  const renderForm = () => (
    <StyledForm>
      <FormTextInput
        name={SaveAsTemplateFields.name}
        label={t(translations.TEMPLATE_NAME)}
        maxLength={FILE_NAME_MAX_LENGTH}
      />
      <FormDropdown
        name={SaveAsTemplateFields.folderName}
        label={t(translations.FOLDER_LABEL)}
        options={folders}
        maxHeight={DROPDOWN_HEIGHT}
        noError
      />
      <Box mb={4}>
        <Button icon="folderAdd" variant={BUTTON_VARIANTS.INACTIVE} type="button" onClick={onCreateFolder}>
          {t(translations.CREATE_NEW_FOLDER)}
        </Button>
      </Box>
      <Button type="submit" fullWidth>
        {t(translations.CREATE)}
      </Button>
    </StyledForm>
  )

  return (
    <Modal onClose={onClose}>
      <Box mt={3} mb={3} display="flex" flexDirection="column" width={320} alignItems="center">
        <ContractSvg />
        <Box mt={5} mb={3} textAlign="center">
          <Typography name={TYPOGRAPHY_NAMES.H1}>{t(translations.CONTRACT_SAVE_AS_TEMPLATE)}</Typography>
        </Box>
        {foldersIsLoading ? (
          <SaveAsTemplateModalSkeleton />
        ) : (
          <Formik
            innerRef={formRef}
            onSubmit={onSubmit}
            initialValues={initialValues}
            validationSchema={saveAsTemplateValidationSchema}
          >
            {renderForm}
          </Formik>
        )}
      </Box>
    </Modal>
  )
}
