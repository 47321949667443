import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { useDispatchUnmount } from '../useDispatchUnmount'
import { fetchFolders, cleanFolders } from '../../store/contracts/folders/folders.actions'

export const useContractsFoldersFetch = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchFolders())
  }, [])

  useDispatchUnmount(cleanFolders)
}
