import { ContractHistory } from '../../../types/contractHistory'
import { FetchFailureAction, FetchFailurePayload } from '../../../types/fetch'
import { createFetchActionTypes } from '../../../utils/createFetchActionTypes'

import * as TYPES from './history.types'

export const fetchHistoryTypes = createFetchActionTypes(TYPES.HistoryActions.FETCH_CONTRACT_HISTORY)

export const fetchHistory = (contractId: string): TYPES.FetchHistory => ({
  type: fetchHistoryTypes.request,
  payload: { contractId },
})

export const fetchHistorySuccess = (history: ContractHistory): TYPES.FetchHistorySuccess => ({
  type: fetchHistoryTypes.success,
  payload: { history },
})

export const fetchHistoryFailure = (payload: FetchFailurePayload): FetchFailureAction => ({
  type: fetchHistoryTypes.failure,
  payload,
})

export const cleanHistory = () => ({
  type: TYPES.HistoryActions.CLEAN_CONTRACT_HISTORY,
})
