import React from 'react'

export const AppStoreSvg: React.FC = (props) => (
  <svg width="216" height="64" viewBox="0 0 216 64" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M208 0H8C3.58172 0 0 3.58172 0 8V56C0 60.4183 3.58172 64 8 64H208C212.418 64 216 60.4183 216 56V8C216 3.58172 212.418 0 208 0Z"
      fill="black"
    />
    <path
      d="M208 1.28C209.782 1.28 211.492 1.988 212.752 3.24824C214.012 4.50849 214.72 6.21775 214.72 8V56C214.72 57.7823 214.012 59.4915 212.752 60.7518C211.492 62.012 209.782 62.72 208 62.72H8C6.21775 62.72 4.50849 62.012 3.24824 60.7518C1.988 59.4915 1.28 57.7823 1.28 56V8C1.28 6.21775 1.988 4.50849 3.24824 3.24824C4.50849 1.988 6.21775 1.28 8 1.28H208ZM208 0H8C5.87827 0 3.84344 0.842855 2.34315 2.34315C0.842855 3.84344 0 5.87827 0 8V56C0 58.1217 0.842855 60.1566 2.34315 61.6569C3.84344 63.1571 5.87827 64 8 64H208C210.122 64 212.157 63.1571 213.657 61.6569C215.157 60.1566 216 58.1217 216 56V8C216 5.87827 215.157 3.84344 213.657 2.34315C212.157 0.842855 210.122 0 208 0Z"
      fill="#A6A6A6"
    />
    <path
      d="M51.3818 30.9008C51.3376 25.9874 55.405 23.597 55.5909 23.4857C53.2874 20.1272 49.7171 19.6684 48.4624 19.6318C45.4638 19.3162 42.555 21.4261 41.0275 21.4261C39.4695 21.4261 37.1172 19.6623 34.5819 19.7141C31.3195 19.7644 28.2675 21.6533 26.5936 24.5864C23.1391 30.5669 25.7155 39.3556 29.0252 44.1898C30.6808 46.5573 32.6154 49.2008 35.1475 49.1078C37.6248 49.0057 38.5502 47.5284 41.5397 47.5284C44.5018 47.5284 45.3708 49.1078 47.9533 49.0483C50.612 49.0057 52.2859 46.6701 53.8835 44.2813C55.7968 41.5677 56.5651 38.8952 56.5956 38.758C56.5331 38.7367 51.4321 36.7899 51.3818 30.9008Z"
      fill="white"
    />
    <path
      d="M46.5018 16.4514C47.8342 14.7851 48.7458 12.5182 48.4927 10.2178C46.5643 10.3031 44.1525 11.5517 42.7637 13.1814C41.535 14.6175 40.4373 16.9713 40.7209 19.1848C42.8872 19.3464 45.1114 18.0918 46.5018 16.4514Z"
      fill="white"
    />
    <path
      d="M87.2331 48.7674H83.771L81.8745 42.8082H75.2826L73.4761 48.7674H70.1055L76.6364 28.4795H80.6702L87.2331 48.7674ZM81.3029 40.308L79.5878 35.0104C79.4064 34.4692 79.0664 33.1947 78.5649 31.1885H78.5039C78.3042 32.0514 77.9825 33.3258 77.5404 35.0104L75.8558 40.308H81.3029Z"
      fill="white"
    />
    <path
      d="M104.029 41.2738C104.029 43.7618 103.357 45.7284 102.012 47.1721C100.808 48.4572 99.3124 49.099 97.5273 49.099C95.6003 49.099 94.2161 48.4069 93.373 47.0227H93.3121V54.729H90.0618V38.9551C90.0618 37.3909 90.0207 35.7856 89.9414 34.1392H92.7998L92.9812 36.4579H93.0422C94.1261 34.7109 95.7711 33.8389 97.9785 33.8389C99.7042 33.8389 101.145 34.5203 102.297 35.8847C103.453 37.2507 104.029 39.0465 104.029 41.2738ZM100.718 41.3927C100.718 39.9688 100.398 38.795 99.7545 37.8711C99.0518 36.9077 98.1081 36.4259 96.9251 36.4259C96.1232 36.4259 95.3945 36.6942 94.7435 37.2232C94.0911 37.7568 93.6642 38.4535 93.4645 39.3164C93.3639 39.7188 93.3136 40.0481 93.3136 40.3073V42.7465C93.3136 43.8106 93.6398 44.7085 94.2923 45.4418C94.9448 46.175 95.7924 46.5409 96.8351 46.5409C98.0593 46.5409 99.0121 46.0683 99.6936 45.1262C100.377 44.1825 100.718 42.9385 100.718 41.3927Z"
      fill="white"
    />
    <path
      d="M120.856 41.2738C120.856 43.7618 120.184 45.7284 118.837 47.1721C117.635 48.4572 116.139 49.099 114.354 49.099C112.427 49.099 111.043 48.4069 110.201 47.0227H110.14V54.729H106.89V38.9551C106.89 37.3909 106.849 35.7856 106.77 34.1392H109.628L109.809 36.4579H109.87C110.953 34.7109 112.598 33.8389 114.807 33.8389C116.531 33.8389 117.971 34.5203 119.127 35.8847C120.278 37.2507 120.856 39.0465 120.856 41.2738ZM117.545 41.3927C117.545 39.9688 117.223 38.795 116.58 37.8711C115.877 36.9077 114.936 36.4259 113.752 36.4259C112.948 36.4259 112.221 36.6942 111.569 37.2232C110.916 37.7568 110.491 38.4535 110.291 39.3164C110.192 39.7188 110.14 40.0481 110.14 40.3073V42.7465C110.14 43.8106 110.466 44.7085 111.116 45.4418C111.768 46.1735 112.616 46.5409 113.662 46.5409C114.886 46.5409 115.839 46.0683 116.52 45.1262C117.203 44.1825 117.545 42.9385 117.545 41.3927Z"
      fill="white"
    />
    <path
      d="M139.668 43.0784C139.668 44.8041 139.069 46.2082 137.866 47.2921C136.545 48.4766 134.704 49.0681 132.34 49.0681C130.157 49.0681 128.407 48.6474 127.082 47.8043L127.835 45.0953C129.262 45.9582 130.828 46.3911 132.534 46.3911C133.758 46.3911 134.711 46.1137 135.395 45.5618C136.077 45.0099 136.416 44.269 136.416 43.3452C136.416 42.522 136.136 41.8283 135.573 41.2658C135.014 40.7033 134.079 40.1804 132.774 39.6971C129.222 38.3723 127.448 36.4316 127.448 33.8796C127.448 32.2118 128.07 30.8444 129.315 29.7803C130.556 28.7147 132.212 28.1826 134.282 28.1826C136.128 28.1826 137.662 28.5043 138.886 29.1461L138.074 31.7957C136.93 31.1737 135.637 30.8627 134.191 30.8627C133.047 30.8627 132.154 31.1447 131.514 31.7057C130.973 32.2073 130.701 32.8186 130.701 33.5427C130.701 34.3446 131.011 35.0078 131.633 35.5291C132.174 36.0109 133.157 36.5322 134.584 37.0948C136.33 37.7976 137.612 38.6193 138.436 39.5614C139.258 40.5005 139.668 41.6759 139.668 43.0784Z"
      fill="white"
    />
    <path
      d="M150.413 36.5784H146.83V43.681C146.83 45.4875 147.462 46.39 148.727 46.39C149.308 46.39 149.79 46.3397 150.171 46.2391L150.261 48.7072C149.62 48.9466 148.777 49.067 147.733 49.067C146.449 49.067 145.446 48.6752 144.722 47.8931C144.001 47.1096 143.638 45.7954 143.638 43.9493V36.5753H141.504V34.1361H143.638V31.4576L146.83 30.4941V34.1361H150.413V36.5784Z"
      fill="white"
    />
    <path
      d="M166.575 41.3327C166.575 43.5813 165.931 45.4275 164.648 46.8712C163.302 48.3576 161.515 49.0985 159.288 49.0985C157.141 49.0985 155.432 48.3865 154.158 46.9627C152.883 45.5388 152.246 43.7414 152.246 41.5751C152.246 39.3082 152.902 37.4514 154.217 36.0077C155.53 34.5625 157.301 33.8398 159.529 33.8398C161.675 33.8398 163.402 34.5518 164.706 35.9772C165.953 37.3599 166.575 39.1451 166.575 41.3327ZM163.203 41.4379C163.203 40.0887 162.914 38.9316 162.331 37.9666C161.649 36.7989 160.675 36.2165 159.413 36.2165C158.106 36.2165 157.114 36.8004 156.432 37.9666C155.848 38.9332 155.56 40.1085 155.56 41.4989C155.56 42.8481 155.848 44.0051 156.432 44.9686C157.135 46.1364 158.117 46.7187 159.384 46.7187C160.625 46.7187 161.599 46.1242 162.302 44.9381C162.901 43.9548 163.203 42.7856 163.203 41.4379Z"
      fill="white"
    />
    <path
      d="M177.139 36.9976C176.818 36.9382 176.475 36.9077 176.115 36.9077C174.971 36.9077 174.087 37.3391 173.465 38.2035C172.924 38.9657 172.653 39.9292 172.653 41.0924V48.7682H169.404L169.434 38.7462C169.434 37.0601 169.393 35.525 169.312 34.1407H172.143L172.262 36.9397H172.352C172.695 35.9777 173.237 35.2033 173.977 34.6225C174.702 34.0996 175.484 33.8389 176.327 33.8389C176.627 33.8389 176.898 33.8602 177.139 33.8983V36.9976Z"
      fill="white"
    />
    <path
      d="M191.677 40.7617C191.677 41.344 191.639 41.8349 191.558 42.2359H181.807C181.845 43.6811 182.316 44.7863 183.222 45.5486C184.044 46.23 185.106 46.5715 186.411 46.5715C187.855 46.5715 189.172 46.3413 190.356 45.8794L190.866 48.1356C189.481 48.7393 187.847 49.0397 185.961 49.0397C183.693 49.0397 181.912 48.3719 180.616 47.038C179.324 45.7041 178.676 43.9128 178.676 41.6657C178.676 39.4598 179.278 37.6227 180.484 36.1577C181.746 34.5936 183.452 33.8115 185.598 33.8115C187.707 33.8115 189.303 34.5936 190.387 36.1577C191.245 37.4002 191.677 38.9369 191.677 40.7617ZM188.577 39.9186C188.599 38.9552 188.387 38.1228 187.946 37.42C187.384 36.516 186.519 36.0647 185.356 36.0647C184.294 36.0647 183.429 36.5053 182.769 37.3895C182.228 38.0923 181.906 38.9353 181.807 39.9171H188.577V39.9186Z"
      fill="white"
    />
    <path
      d="M80.2278 15.9987C80.2278 17.793 79.6896 19.1437 78.6149 20.0508C77.6194 20.8878 76.2046 21.307 74.3722 21.307C73.4636 21.307 72.6861 21.2674 72.0352 21.1881V11.3841C72.8843 11.2469 73.799 11.1768 74.7869 11.1768C76.5324 11.1768 77.848 11.5564 78.7353 12.3156C79.7293 13.1738 80.2278 14.4011 80.2278 15.9987ZM78.5432 16.0429C78.5432 14.8797 78.2353 13.9879 77.6194 13.3659C77.0035 12.7455 76.104 12.4345 74.9195 12.4345C74.4164 12.4345 73.988 12.468 73.6328 12.5381V19.9914C73.8295 20.0218 74.1893 20.0356 74.7122 20.0356C75.9348 20.0356 76.8785 19.6956 77.5431 19.0157C78.2078 18.3358 78.5432 17.3448 78.5432 16.0429Z"
      fill="white"
    />
    <path
      d="M89.1597 17.5659C89.1597 18.6711 88.8441 19.5767 88.213 20.2871C87.5513 21.0173 86.6747 21.3817 85.5802 21.3817C84.5252 21.3817 83.6852 21.0326 83.0587 20.3313C82.4336 19.6315 82.1211 18.7489 82.1211 17.6848C82.1211 16.5719 82.4428 15.6587 83.0891 14.9498C83.7355 14.2409 84.6045 13.8857 85.6991 13.8857C86.754 13.8857 87.6016 14.2348 88.2434 14.9346C88.8532 15.6145 89.1597 16.4926 89.1597 17.5659ZM87.5025 17.6177C87.5025 16.9545 87.3592 16.3859 87.0742 15.9118C86.7388 15.3386 86.2616 15.052 85.6411 15.052C84.9993 15.052 84.5115 15.3386 84.1761 15.9118C83.8895 16.3859 83.7477 16.9637 83.7477 17.6467C83.7477 18.3098 83.891 18.8784 84.1761 19.3526C84.5222 19.9258 85.0039 20.2124 85.6259 20.2124C86.2357 20.2124 86.7144 19.9212 87.0589 19.3373C87.3547 18.8541 87.5025 18.2808 87.5025 17.6177Z"
      fill="white"
    />
    <path
      d="M101.135 14.0322L98.8863 21.2187H97.4228L96.4913 18.098C96.255 17.319 96.0629 16.5446 95.9135 15.7762H95.8846C95.7458 16.5659 95.5538 17.3388 95.3068 18.098L94.3174 21.2187H92.8371L90.7227 14.0322H92.3645L93.1771 17.4486C93.3737 18.2566 93.5353 19.0265 93.6649 19.7552H93.6939C93.8128 19.1545 94.0095 18.3892 94.2869 17.4639L95.3068 14.0338H96.6087L97.5859 17.3907C97.8222 18.2093 98.0143 18.9975 98.1622 19.7567H98.2064C98.3146 19.0173 98.4777 18.2291 98.6942 17.3907L99.5662 14.0338H101.135V14.0322Z"
      fill="white"
    />
    <path
      d="M109.42 21.2182H107.823V17.1021C107.823 15.8337 107.341 15.1995 106.374 15.1995C105.9 15.1995 105.518 15.3733 105.22 15.7224C104.926 16.0716 104.777 16.4832 104.777 16.9542V21.2167H103.179V16.0853C103.179 15.4541 103.159 14.7696 103.121 14.0287H104.525L104.6 15.1523H104.644C104.83 14.8032 105.108 14.515 105.472 14.2848C105.905 14.0165 106.39 13.8809 106.92 13.8809C107.591 13.8809 108.149 14.0973 108.592 14.5318C109.144 15.0639 109.42 15.8581 109.42 16.9131V21.2182Z"
      fill="white"
    />
    <path d="M113.823 21.2183H112.227V10.7344H113.823V21.2183Z" fill="white" />
    <path
      d="M123.23 17.5659C123.23 18.6711 122.914 19.5767 122.283 20.2871C121.622 21.0173 120.744 21.3817 119.65 21.3817C118.594 21.3817 117.754 21.0326 117.129 20.3313C116.504 19.6315 116.191 18.7489 116.191 17.6848C116.191 16.5719 116.513 15.6587 117.159 14.9498C117.806 14.2409 118.675 13.8857 119.768 13.8857C120.824 13.8857 121.67 14.2348 122.314 14.9346C122.924 15.6145 123.23 16.4926 123.23 17.5659ZM121.571 17.6177C121.571 16.9545 121.428 16.3859 121.143 15.9118C120.809 15.3386 120.33 15.052 119.711 15.052C119.068 15.052 118.58 15.3386 118.246 15.9118C117.96 16.3859 117.818 16.9637 117.818 17.6467C117.818 18.3098 117.961 18.8784 118.246 19.3526C118.592 19.9258 119.074 20.2124 119.696 20.2124C120.306 20.2124 120.783 19.9212 121.128 19.3373C121.425 18.8541 121.571 18.2808 121.571 17.6177Z"
      fill="white"
    />
    <path
      d="M130.962 21.2185H129.528L129.409 20.3907H129.365C128.874 21.0508 128.174 21.3817 127.265 21.3817C126.587 21.3817 126.038 21.1637 125.625 20.7307C125.25 20.3374 125.062 19.848 125.062 19.2672C125.062 18.3891 125.428 17.7198 126.165 17.2564C126.9 16.7929 127.933 16.5658 129.264 16.5765V16.4423C129.264 15.4956 128.767 15.023 127.772 15.023C127.063 15.023 126.438 15.2014 125.898 15.5551L125.573 14.5062C126.241 14.0931 127.066 13.8857 128.038 13.8857C129.916 13.8857 130.859 14.8767 130.859 16.8585V19.505C130.859 20.223 130.894 20.7947 130.962 21.2185ZM129.304 18.7489V17.6406C127.541 17.6101 126.66 18.0933 126.66 19.0888C126.66 19.4639 126.761 19.7444 126.967 19.9319C127.172 20.1194 127.435 20.2124 127.747 20.2124C128.098 20.2124 128.426 20.1011 128.724 19.88C129.025 19.6575 129.209 19.3754 129.278 19.0294C129.294 18.9516 129.304 18.8571 129.304 18.7489Z"
      fill="white"
    />
    <path
      d="M140.04 21.2183H138.622L138.547 20.0643H138.503C138.05 20.9424 137.279 21.3814 136.195 21.3814C135.329 21.3814 134.608 21.0415 134.036 20.3615C133.465 19.6816 133.18 18.7989 133.18 17.715C133.18 16.5518 133.489 15.6097 134.111 14.8901C134.713 14.2194 135.451 13.884 136.329 13.884C137.294 13.884 137.97 14.2087 138.354 14.8596H138.384V10.7344H139.983V19.2822C139.983 19.9819 140.002 20.6268 140.04 21.2183ZM138.384 18.1876V16.9894C138.384 16.782 138.369 16.6143 138.34 16.4863C138.25 16.1021 138.057 15.7789 137.762 15.5182C137.465 15.2575 137.107 15.1264 136.694 15.1264C136.098 15.1264 135.631 15.3627 135.288 15.8368C134.948 16.311 134.776 16.9162 134.776 17.6556C134.776 18.366 134.939 18.9422 135.267 19.3859C135.613 19.8584 136.079 20.0947 136.663 20.0947C137.188 20.0947 137.607 19.8981 137.925 19.5032C138.233 19.1389 138.384 18.6998 138.384 18.1876Z"
      fill="white"
    />
    <path
      d="M153.707 17.5659C153.707 18.6711 153.391 19.5767 152.76 20.2871C152.098 21.0173 151.223 21.3817 150.127 21.3817C149.074 21.3817 148.234 21.0326 147.606 20.3313C146.98 19.6315 146.668 18.7489 146.668 17.6848C146.668 16.5719 146.99 15.6587 147.636 14.9498C148.282 14.2409 149.151 13.8857 150.247 13.8857C151.301 13.8857 152.15 14.2348 152.79 14.9346C153.4 15.6145 153.707 16.4926 153.707 17.5659ZM152.051 17.6177C152.051 16.9545 151.908 16.3859 151.623 15.9118C151.286 15.3386 150.81 15.052 150.188 15.052C149.548 15.052 149.06 15.3386 148.723 15.9118C148.436 16.3859 148.295 16.9637 148.295 17.6467C148.295 18.3098 148.438 18.8784 148.723 19.3526C149.069 19.9258 149.551 20.2124 150.173 20.2124C150.783 20.2124 151.263 19.9212 151.607 19.3373C151.902 18.8541 152.051 18.2808 152.051 17.6177Z"
      fill="white"
    />
    <path
      d="M162.294 21.2182H160.698V17.1021C160.698 15.8337 160.216 15.1995 159.248 15.1995C158.774 15.1995 158.391 15.3733 158.095 15.7224C157.8 16.0716 157.652 16.4832 157.652 16.9542V21.2167H156.053V16.0853C156.053 15.4541 156.034 14.7696 155.996 14.0287H157.399L157.473 15.1523H157.518C157.705 14.8032 157.983 14.515 158.345 14.2848C158.78 14.0165 159.263 13.8809 159.795 13.8809C160.464 13.8809 161.022 14.0973 161.466 14.5318C162.019 15.0639 162.294 15.8581 162.294 16.9131V21.2182Z"
      fill="white"
    />
    <path
      d="M173.049 15.2287H171.289V18.7197C171.289 19.607 171.602 20.0506 172.221 20.0506C172.507 20.0506 172.745 20.0262 172.933 19.9759L172.974 21.1879C172.658 21.3068 172.244 21.3663 171.733 21.3663C171.102 21.3663 170.611 21.1742 170.256 20.79C169.899 20.4058 169.722 19.7594 169.722 18.8524V15.2287H168.672V14.0319H169.722V12.7148L171.288 12.2422V14.0304H173.047V15.2287H173.049Z"
      fill="white"
    />
    <path
      d="M181.507 21.2183H179.908V17.1327C179.908 15.8445 179.426 15.1996 178.461 15.1996C177.72 15.1996 177.214 15.5731 176.936 16.3201C176.889 16.4771 176.862 16.6692 176.862 16.8948V21.2168H175.266V10.7344H176.862V15.0655H176.892C177.395 14.2773 178.116 13.884 179.051 13.884C179.713 13.884 180.26 14.1004 180.694 14.5349C181.236 15.0761 181.507 15.8811 181.507 16.9451V21.2183Z"
      fill="white"
    />
    <path
      d="M190.229 17.2854C190.229 17.572 190.208 17.8129 190.17 18.0095H185.378C185.399 18.7199 185.628 19.2611 186.072 19.6362C186.477 19.9716 187 20.1392 187.64 20.1392C188.349 20.1392 188.996 20.0264 189.578 19.7993L189.828 20.9091C189.147 21.2049 188.345 21.3527 187.416 21.3527C186.303 21.3527 185.427 21.025 184.793 20.3694C184.155 19.7139 183.84 18.8343 183.84 17.7306C183.84 16.6466 184.134 15.7441 184.727 15.0246C185.346 14.2562 186.183 13.8721 187.239 13.8721C188.273 13.8721 189.058 14.2562 189.589 15.0246C190.017 15.6344 190.229 16.389 190.229 17.2854ZM188.704 16.8723C188.717 16.3981 188.611 15.9896 188.395 15.6451C188.118 15.2014 187.695 14.9789 187.124 14.9789C186.602 14.9789 186.177 15.1953 185.852 15.6298C185.587 15.9759 185.43 16.389 185.378 16.8723H188.704Z"
      fill="white"
    />
  </svg>
)
