import { CreateContractFromTemplateBody } from '../../../types/createContractFromTemplate'
import { FetchFailureAction, FetchFailurePayload } from '../../../types/fetch'
import { RecipientData } from '../../../types/recipients'
import { SIGNING_TYPES } from '../../../types/signingOrder'
import { createFetchActionTypes } from '../../../utils/createFetchActionTypes'

import * as TYPES from './createContract.types'

export const fetchCreateContractTypes = createFetchActionTypes(
  TYPES.CreateContractActions.FETCH_CREATE_CONTRACT_FROM_TEMPLATE
)

export const fetchCreateContract = (body: CreateContractFromTemplateBody): TYPES.FetchCreateContract => ({
  type: fetchCreateContractTypes.request,
  payload: { body },
})

export const fetchCreateContractSuccess = (contractId: string): TYPES.FetchCreateContractSuccess => ({
  type: fetchCreateContractTypes.success,
  payload: { contractId },
})

export const fetchCreateContractFailure = (payload: FetchFailurePayload): FetchFailureAction => ({
  type: fetchCreateContractTypes.failure,
  payload,
})

export const setCreateContractRecipientsData = (
  recipients: RecipientData[],
  order: SIGNING_TYPES
): TYPES.SetCreateContractRecipientData => ({
  type: TYPES.CreateContractActions.SET_CREATE_CONTRACT_RECIPIENTS_DATA,
  payload: { recipients, order },
})

export const cleanCreateContract = () => ({
  type: TYPES.CreateContractActions.CLEAN_CREATE_CONTRACT_FROM_TEMPLATE,
})
