import React from 'react'
import ContentLoader from 'react-content-loader'

import { blueExtraLight, glowColor } from '../../../themes/colors'

const FILL_COLOR = blueExtraLight
const GLOW_COLOR = glowColor
const SPEED = 1.5
const SVG_WIDTH = '100%'
const SVG_HEIGHT = 500
const ROW_HEIGHT = 55
const CELL_Y = 116
const CELL_BORDER_Y = 160
const RADIUS = 3
const ROWS = [
  ['15%', '8%', '4%', '10%', '10%'],
  ['15%', '8%', '4%', '10%', '10%'],
  ['15%', '8%', '4%', '10%', '10%'],
]
const columnsOffsets = ['0', '32%', '48%', '60%', '80%']
const cellHeights = [16, 16, 16, 32, 32]
const cellY = [CELL_Y + 8, CELL_Y + 8, CELL_Y + 8, CELL_Y, CELL_Y]

export const ContractsListSkeleton: React.FC = () => (
  <ContentLoader
    display="block"
    width={SVG_WIDTH}
    height={SVG_HEIGHT}
    speed={SPEED}
    backgroundColor={FILL_COLOR}
    foregroundColor={GLOW_COLOR}
  >
    <rect width="384" height="40" x="0" y="0" rx={RADIUS} ry={RADIUS} />
    <rect width="56" height="24" x="424" y="8" rx={RADIUS} ry={RADIUS} />
    <rect width="56" height="24" x="536" y="8" rx={RADIUS} ry={RADIUS} />

    <rect width="20%" height="40" x="80%" y="0" rx={RADIUS} ry={RADIUS} />

    {columnsOffsets.map((offset, index) => (
      <rect key={index} width="5%" height="14" x={offset} y="72" rx={RADIUS} ry={RADIUS} />
    ))}
    <rect width="100%" height="1" x="0" y="97" rx={RADIUS} ry={RADIUS} />

    {ROWS.map((widths, i) => (
      <React.Fragment key={i}>
        {columnsOffsets.map((offset, index) => (
          <rect
            key={index}
            width={widths[index]}
            height={cellHeights[index]}
            x={offset}
            y={cellY[index] + ROW_HEIGHT * i}
            rx={RADIUS}
            ry={RADIUS}
          />
        ))}
        <rect width="8" height="24" x="99%" y={CELL_Y + ROW_HEIGHT * i} rx={RADIUS} ry={RADIUS} />
        <rect width="100%" height="1" x="0" y={CELL_BORDER_Y + ROW_HEIGHT * i} rx={RADIUS} ry={RADIUS} />
      </React.Fragment>
    ))}
  </ContentLoader>
)
