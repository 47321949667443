import React from 'react'

import AuthorizationTemplate from '../../components/Authorization/AuthorizationTemplate'

import { Login as LoginPage } from './Login'

export const Login = () => (
  <AuthorizationTemplate>
    <LoginPage />
  </AuthorizationTemplate>
)
