import React from 'react'
import { Redirect, Switch } from 'react-router-dom'

import { location } from '../../utils/location'

import PublicRoute from './components/PublicRoute'

const PaymentRoutes = () => (
  <Switch>
    <PublicRoute exact path={location.paymentPurchaseUrl()}></PublicRoute>
    <PublicRoute exact path={location.paymentRegistrationCardUrl()}></PublicRoute>
    <Redirect to={location.error404Url()} />
  </Switch>
)
export default PaymentRoutes
