import * as yup from 'yup'
import { startOfTomorrow } from 'date-fns'

import { AddRecipientsFields, RecipientActions, RecipientAuthentication } from '../types/recipients'

import { emailValidationSchema } from './emailValidationSchema'
import { nameValidationSchema } from './nameValidationSchema'
import { departmentValidationSchema } from './departmentValidationSchema'
import { PHONE_MIN_LENGTH, PHONE_MAX_LENGTH } from './phoneValidationSchema'

const CYCLE_ORDER_MIN = 1
const CYCLE_ORDER_MAX = 50

const phoneSchema = yup.string().min(PHONE_MIN_LENGTH, 'INVALID_PHONE').max(PHONE_MAX_LENGTH, 'INVALID_PHONE')
const cycleOrderSchema = yup
  .number()
  .required('CYCLE_ORDER_REQUIRED')
  .min(CYCLE_ORDER_MIN, 'CYCLE_ORDER_MIN')
  .max(CYCLE_ORDER_MAX, 'CYCLE_ORDER_MAX')

export const recipientValidationSchema = (templatesMode: boolean) =>
  yup.object().shape({
    [AddRecipientsFields.recipientName]: yup.string().when(AddRecipientsFields.recipientNoEmailAndName, {
      is: false,
      then: nameValidationSchema,
    }),
    [AddRecipientsFields.recipientEmail]: yup.string().when(AddRecipientsFields.recipientNoEmailAndName, {
      is: false,
      then: emailValidationSchema,
    }),
    [AddRecipientsFields.recipientCycleOrder]: cycleOrderSchema,
    [AddRecipientsFields.recipientPhone]: phoneSchema.when(
      [AddRecipientsFields.recipientNoEmailAndName, AddRecipientsFields.recipientAuthentication],
      {
        is: (noEmailAndName, auth) => !noEmailAndName && auth === RecipientAuthentication.OTP,
        then: phoneSchema.required('INVALID_PHONE'),
      }
    ),
    [AddRecipientsFields.recipientDepartment]: departmentValidationSchema,
    [AddRecipientsFields.recipientNoEmailAndName]: yup.boolean(),
    [AddRecipientsFields.recipientDeadline]: yup
      .date()
      .nullable()
      .when(AddRecipientsFields.recipientAction, {
        is: (action: RecipientActions) =>
          !templatesMode && (action === RecipientActions.SIGN || action === RecipientActions.INITIAL),
        then: yup.date().required('REQUIRED_FIELD').min(startOfTomorrow(), 'DEADLINE_IS_EXPIRED'),
      }),
  })
