import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { format } from 'date-fns'
import { useTranslation } from 'react-i18next'

import {
  contractsListFiltersSelector,
  contractsListIsAppliedFiltersSelector,
} from '../../../../../store/contracts/contractsList/contractsList.selectors'
import Box from '../../../../ui/Box'
import Button from '../../../../ui/Button'
import { BUTTON_VARIANTS } from '../../../../ui/Button/Button.types'
import translations from '../../../../../translations/keys'
import { SLASHES_DATE_FORMAT } from '../../../../../constants/date'
import { contractsListSetFilters } from '../../../../../store/contracts/contractsList/contractsList.actions'
import { ContractsFiltersForm } from '../../../../../types/contractsFilters'
import { contractsFiltersInitialValues } from '../../../../../initialValues/contractsFiltersInitialValues'
import { CONTRACT_STATUS } from '../../../../../types/contracts'

import { StyledBadge, StyledDateTo } from './AppliedFilters.styles'

export const AppliedFilters = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const filters = useSelector(contractsListFiltersSelector)
  const isFiltersApplied = useSelector(contractsListIsAppliedFiltersSelector)

  const applyFilters = (values: ContractsFiltersForm) => {
    dispatch(contractsListSetFilters(values))
  }
  const onClear = () => {
    applyFilters(contractsFiltersInitialValues)
  }
  const onClearDate = () => {
    applyFilters({
      ...filters,
      createDateFrom: null,
      createDateTo: null,
    })
  }
  const onClearStatus = (removedStatus: CONTRACT_STATUS) => () => {
    applyFilters({
      ...filters,
      statuses: filters.statuses.filter((status) => status !== removedStatus),
    })
  }

  if (!isFiltersApplied) {
    return null
  }

  return (
    <Box display="flex" mb={1} mt={-1} flexWrap="wrap" alignItems="baseline">
      {filters.statuses.map((status) => (
        <StyledBadge key={status}>
          <Box mr={1}>{t(status)}</Box>
          <Button
            icon="filledCloseCircle"
            variant={BUTTON_VARIANTS.INACTIVE}
            iconColor="strokeGrey"
            onClick={onClearStatus(status)}
          />
        </StyledBadge>
      ))}
      {(filters.createDateFrom || filters.createDateTo) && (
        <StyledBadge>
          <Box mr={1}>
            {filters.createDateFrom &&
              `${t(translations.FROM)} ${format(new Date(filters.createDateFrom), SLASHES_DATE_FORMAT)}`}
            {filters.createDateFrom && filters.createDateTo ? ' ' : null}
            {filters.createDateTo && (
              <StyledDateTo lowerCase={Boolean(filters.createDateFrom)}>{`${t(translations.TO)} ${format(
                new Date(filters.createDateTo),
                SLASHES_DATE_FORMAT
              )}`}</StyledDateTo>
            )}
          </Box>
          <Button
            icon="filledCloseCircle"
            variant={BUTTON_VARIANTS.INACTIVE}
            iconColor="strokeGrey"
            onClick={onClearDate}
          />
        </StyledBadge>
      )}
      <Button variant={BUTTON_VARIANTS.INACTIVE} onClick={onClear}>
        {t(translations.CLEAR_ALL)}
      </Button>
    </Box>
  )
}
