import React, { useMemo, useState } from 'react'
import styled from 'styled-components'
import { usePopper } from 'react-popper'

import ClickOutsideListener from '../ClickOutsideListener'

import { PopoverProps } from './Popover.types'

const StyledPopoverContent = styled.div<Omit<PopoverProps, 'targetRef'>>`
  width: ${({ width }) => width || '160px'};
  padding: ${({ padding }) => padding};
  background-color: ${({ theme, backgroundColor }) => (backgroundColor ? theme.palette[backgroundColor] : 'inherit')};
  box-shadow: ${({ theme }) => theme.shadow};
  border-radius: 4px;
  z-index: 1;
`

export const Popover: React.FC<PopoverProps> = ({
  targetRef,
  width,
  visible = false,
  offsetTop = 10,
  offsetLeft = 0,
  padding = '20px 0',
  children,
  onClose = () => {},
  capture,
  placement,
  backgroundColor = 'white',
}) => {
  const [popperRef, setPopperRef] = useState<HTMLDivElement | null>(null)
  const options = useMemo(
    () => ({
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [offsetLeft, offsetTop],
          },
        },
      ],
      placement,
    }),
    [offsetTop, offsetLeft]
  )
  const { styles, attributes } = usePopper(targetRef.current, popperRef, options)
  if (!visible) {
    return null
  }

  return (
    <ClickOutsideListener onClickAway={onClose} test-id="click-outside-listener" capture={capture}>
      <StyledPopoverContent
        data-testid="popover-content"
        ref={setPopperRef}
        width={width}
        padding={padding}
        style={styles.popper}
        backgroundColor={backgroundColor}
        {...attributes.popper}
      >
        {children}
      </StyledPopoverContent>
    </ClickOutsideListener>
  )
}
