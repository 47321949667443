import { Action } from 'redux'

import { DocumentMessageBody } from '../../../types/documents'
import { FetchFailurePayload, FETCH_STATUSES } from '../../../types/fetch'

export interface MessageState {
  fetchStatus: FETCH_STATUSES
  error: FetchFailurePayload | null
}

export enum MessageActions {
  'FETCH_SAVE_CONTRACT_MESSAGE' = 'FETCH_SAVE_CONTRACT_MESSAGE',
  'CLEAR_CONTRACT_MESSAGE' = 'CLEAR_CONTRACT_MESSAGE',
}

export interface FetchSaveContractMessage extends Action {
  payload: { contractId: string; body: DocumentMessageBody }
}
export type FetchSaveContractMessageSuccess = Action

export type ClearContractMessageAction = Action
