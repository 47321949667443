import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { useDispatchUnmount } from '../useDispatchUnmount'
import { clearStrikes, fetchStrikes } from '../../store/contracts/strikes/strikes.actions'

export const useStrikesFetch = (id: string) => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchStrikes(id))
  }, [id])

  useDispatchUnmount(clearStrikes)
}
