import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { clearPlacement } from '../../store/contracts/placement/placement.actions'
import { cleanOwnerView, fetchOwnerContractDocument } from '../../store/contracts/ownerView/ownerView.actions'
import { useDispatchUnmount } from '../useDispatchUnmount'

export const useOwnerViewFetch = (id: string) => {
  const dispatch = useDispatch()

  useEffect(() => {
    if (id) {
      dispatch(fetchOwnerContractDocument(id))
    }
  }, [id])

  useDispatchUnmount(cleanOwnerView, clearPlacement)
}
