import { Action } from 'redux'

import { FetchFailurePayload, FETCH_STATUSES } from '../../../types/fetch'
import { EditableElem } from '../../../types/editableElem'
import {
  DocStrikesResponseBody,
  DocStrikesById,
  DocStrikesByPages,
  DocStrike,
  DocStrikesConfirmRequest,
  DocCancelStrikeConfirmation,
} from '../../../types/strikethrough'
import { ConfirmationStatus } from '../../../types/confirmationActions'

export interface StrikesState {
  byId: DocStrikesById
  byPages: DocStrikesByPages
  fetch: {
    status: FETCH_STATUSES
    error: FetchFailurePayload | null
  }
  fetchCreate: {
    status: FETCH_STATUSES
    error: FetchFailurePayload | null
  }
  confirm: {
    status: FETCH_STATUSES
    error: FetchFailurePayload | null
    confirmStatus: ConfirmationStatus | null
  }
  cancelConfirmation: {
    status: FETCH_STATUSES
    error: FetchFailurePayload | null
    confirmStatus: ConfirmationStatus | null
  }
}

export enum StrikesActions {
  'ADD_STRIKE' = 'ADD_STRIKE',
  'SET_STRIKE' = 'SET_STRIKE',
  'UNSET_STRIKE' = 'UNSET_STRIKE',
  'CLEAR_STRIKES' = 'CLEAR_STRIKES',
  'FETCH_STRIKES' = 'FETCH_STRIKES',
  'FETCH_CREATE_STRIKE' = 'FETCH_CREATE_STRIKE',
  'FETCH_CONFIRM_STRIKE' = 'FETCH_CONFIRM_STRIKE',
  'FETCH_CANCEL_STRIKE_CONFIRMATION' = 'FETCH_CANCEL_STRIKE_CONFIRMATION',
}

export interface AddStrikeAction extends Action {
  payload: { strike: DocStrike; currentUserId: string }
}
export interface SetStrikeAction extends Action {
  payload: { strike: EditableElem }
}
export interface UnsetStrikeAction extends Action {
  payload: { id: string; currentUserId: string }
}
export type ClearStrikesAction = Action

export interface FetchStrikesAction extends Action {
  payload: { contractId: string }
}
export interface FetchStrikesSuccessAction extends Action {
  payload: { byId: DocStrikesById; byPages: DocStrikesByPages }
}

export interface FetchCreateStrikeAction extends Action {
  payload: { contractId: string; strike: DocStrike }
}
export interface FetchCreateStrikeSuccessAction extends Action {
  payload: DocStrikesResponseBody
}

export interface FetchConfirmStrikeAction extends Action {
  payload: DocStrikesConfirmRequest
}
export interface FetchConfirmStrikeSuccessAction extends Action {
  payload: { confirmStatus: ConfirmationStatus }
}

export interface FetchCancelStrikeConfirmationAction extends Action {
  payload: DocCancelStrikeConfirmation
}
export interface FetchCancelStrikeConfirmationSuccessAction extends Action {
  payload: { confirmStatus: ConfirmationStatus }
}
