import {
  DocCorrection,
  DocCorrectionsRequestBody,
  DocCorrectionsById,
  DocCorrectionsByPages,
} from '../../types/correction'
import { EditableElem } from '../../types/editableElem'
import omit from '../omit'

type DocCorrectionsWithPages = { byId: DocCorrectionsById; byPages: DocCorrectionsByPages }

interface AddCorrectionByIdArgs {
  correction: DocCorrection
  correctionsById: DocCorrectionsById
  currentUserId: string
}

export const addCorrectionById = ({
  correctionsById,
  correction,
  currentUserId,
}: AddCorrectionByIdArgs): DocCorrectionsWithPages => {
  const id = correction.id
  const nextCorrectionsById = id && !correctionsById[id] ? { ...correctionsById, [id]: correction } : correctionsById
  const [byId, byPages] = prepareCorrectionsForStore(Object.values(nextCorrectionsById), currentUserId)

  return { byId, byPages }
}

export const updateCorrectionById = (
  correction: EditableElem,
  correctionsById: DocCorrectionsById
): DocCorrectionsById => {
  const id = correction.id!
  const nextCorrection = { ...correctionsById[id], ...correction }
  return { ...omit([id], correctionsById), [id]: nextCorrection }
}

export const updateCorrectionText = (
  text: string,
  id: string,
  correctionsById: DocCorrectionsById
): DocCorrectionsById => {
  const nextCorrection = { ...correctionsById[id], text }
  return { ...omit([id], correctionsById), [id]: nextCorrection }
}

interface RemoveCorrectionByIdArgs {
  id: string
  correctionsById: DocCorrectionsById
  currentUserId: string
}

export const removeCorrectionById = ({
  id,
  correctionsById,
  currentUserId,
}: RemoveCorrectionByIdArgs): DocCorrectionsWithPages => {
  const nextCorrectionsById = {
    ...omit([id], correctionsById),
  }
  const [byId, byPages] = prepareCorrectionsForStore(Object.values(nextCorrectionsById), currentUserId)

  return { byId, byPages }
}

export const prepareCorrectionsForStore = (
  corrections: DocCorrection[],
  currentUserId?: string
): [DocCorrectionsById, DocCorrectionsByPages] => {
  const byId: DocCorrectionsById = {}
  const byPages: DocCorrectionsByPages = {}

  corrections.forEach((correction) => {
    const { id, page } = correction

    if (id) {
      byId[id] = {
        ...correction,
        canConfirm: !!currentUserId && currentUserId !== correction.strikeOrCorrectionOwner,
      }
      byPages[page] = (byPages[page] || []).concat(id)
    }
  })

  return [byId, byPages]
}

export const prepareCorrectionForRequest = (correction: DocCorrection): DocCorrectionsRequestBody => ({
  corrections: [
    omit(
      [
        'id',
        'isEditable',
        'canConfirm',
        'owner',
        'status',
        'strikeOrCorrectionOwner',
        'acceptOrRejectTimestamp',
        'confirmation',
        'type',
      ],
      correction
    ),
  ],
})
