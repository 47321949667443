import { useEffect, useRef, useState } from 'react'

import { DrawableCompNames, DrawableElem } from '../../../types/drawableElem'
import { EditableElem, ResizeControls } from '../../../types/editableElem'
import { ESC_KEYCODE } from '../../../constants/keyboard'
import uid from '../../../utils/uid'
import { animate } from '../../../utils/animation'
import { isNumber } from '../../../utils/isNumber'
import { getNumberLimitedByRange } from '../../../utils/numbers/numbers'

import { drawingCompMap } from './DocViewer.constants'
import { Pointer, Zoom, Coords, PointerModes, DrawingComp } from './DocViewer.types'
import {
  getBounds,
  getElemBounds,
  getPointersMiddlePosition,
  getInitialScale,
  getPositionFromPointer,
  getPointersDistance,
  getCanvasMiddlePosition,
  getScaleFromPinch,
  limitPositionToBounds,
  getPositionFromOffset,
  getScrollRatio,
  checkElementsCollision,
  getScrollbarProperties,
  resizeElem,
  checkIfElementChanged,
  getElemConstraints,
  checkIfElementWithinBounds,
} from './DocViewer.utils'

const ANIMATION_DURATION = 400
const ZOOM_STEP = 0.2
const MIN_SCALE = 0.2
const MAX_SCALE = 2
const SINGLE_POINTER = 1
const PINCH_GESTURE = 2
const DRAWING_COMP_ATTR = '[data-drawing-comp]'
const DRAWING_SCROLL_IGNORE_ATTR = '[data-ignore-scroll]'
const SAVE_DISABLED_ATTR = '[data-save-disabled]'

const initialPointerRef = {
  mode: null,
  events: [],
  elem: null,
  bounds: null,
  elemBounds: null,
  scale: 1,
  startCoords: { x: 0, y: 0 },
  contentPosition: { x: 0, y: 0 },
  prevPosition: { x: 0, y: 0 },
  prevSize: { width: 0, height: 0 },
  pinchStartDistance: null,
  pinchStartScale: null,
  resizeDirection: ResizeControls.BOTTOM_LEFT,
  currentPage: null,
}

const useViewer = (
  canvas: HTMLDivElement | null,
  content: HTMLDivElement | null,
  scrollbar: HTMLDivElement | null,
  readonly: boolean,
  onElemChange?: (elem: EditableElem) => void,
  onElemCreate?: (elem: DrawableElem) => void,
  onElemBlur?: (id: string, isSaveDisabled: boolean) => void
) => {
  const [drawingComp, setDrawingComp] = useState<DrawingComp | null>(null)
  const [zoomIndicator, setZoomIndicator] = useState(1)
  const [selectedElem, setSelectedElem] = useState<string | null>(null)
  const [movingElem, setMovingElem] = useState<string | null>(null)
  const [resizingElem, setResizingElem] = useState<string | null>(null)
  const animationRef = useRef<number | null>(null)
  const pointerRef = useRef<Pointer>(initialPointerRef)

  const onInit = () => {
    if (canvas && content) {
      const initialScale = getInitialScale(canvas, content)
      const bounds = getBounds(canvas, content, initialScale)

      pointerRef.current = {
        ...pointerRef.current,
        bounds,
        contentPosition: limitPositionToBounds(0, 0, bounds),
        scale: initialScale,
      }

      transformContent()
      setZoomIndicator(initialScale)
    }
  }

  const transformContent = () => {
    if (content) {
      const { contentPosition, scale } = pointerRef.current
      const transform = `translate(${contentPosition.x}px, ${contentPosition.y}px) scale(${scale})`

      content.style.transform = transform
      transformScrollbar()
    }
  }

  const transformScrollbar = () => {
    if (canvas && content && scrollbar) {
      const { contentPosition, scale } = pointerRef.current
      const scrollbarProps = getScrollbarProperties(canvas, content, contentPosition.y, scale)

      scrollbar.style.height = scrollbarProps.height
      scrollbar.style.top = scrollbarProps.top
    }
  }

  const cancelAnimation = () => {
    if (animationRef.current) {
      cancelAnimationFrame(animationRef.current)
    }
  }

  const animateContent = (targetState: { x: number; y: number; scale: number }) => {
    const { contentPosition, scale } = pointerRef.current
    cancelAnimation()

    const scaleDiff = targetState.scale - scale
    const xDiff = targetState.x - contentPosition.x
    const yDiff = targetState.y - contentPosition.y

    animate(ANIMATION_DURATION, animationRef, (step) => {
      const nextScale = scale + scaleDiff * step
      const nextX = contentPosition.x + xDiff * step
      const nextY = contentPosition.y + yDiff * step

      pointerRef.current = { ...pointerRef.current, contentPosition: { x: nextX, y: nextY }, scale: nextScale }
      transformContent()

      if (step === 1 && scale !== nextScale) {
        setZoomIndicator(nextScale)
      }
    })
  }

  const animateElem = (targetPosition: Coords, size?: { width: number; height: number }) => {
    cancelAnimation()

    const { elem } = pointerRef.current

    if (elem) {
      const elemX = elem.offsetLeft
      const elemY = elem.offsetTop
      const elemWidth = elem.offsetWidth
      const elemHeight = elem.offsetHeight

      const xDiff = targetPosition.x - elemX
      const yDiff = targetPosition.y - elemY
      const widthDiff = size ? size.width - elemWidth : 0
      const heightDiff = size ? size.height - elemHeight : 0

      animate(300, animationRef, (step) => {
        const nextX = elemX + xDiff * step
        const nextY = elemY + yDiff * step
        const nextWidth = elemWidth + widthDiff * step
        const nextHeight = elemHeight + heightDiff * step

        elem.style.left = `${nextX}px`
        elem.style.top = `${nextY}px`
        elem.style.width = `${nextWidth}px`
        elem.style.height = `${nextHeight}px`
      })
    }
  }

  const exitDrawingMode = () => {
    pointerRef.current = {
      ...pointerRef.current,
      mode: null,
      elem: null,
      currentPage: null,
    }
    setDrawingComp(null)
  }

  const onZoom = (direction: Zoom) => {
    if (canvas && content && pointerRef.current.bounds) {
      const { contentPosition, scale } = pointerRef.current
      cancelAnimation()

      const nextScale = getNumberLimitedByRange(scale + ZOOM_STEP * direction, MIN_SCALE, MAX_SCALE)
      const nextBounds = getBounds(canvas, content, nextScale)
      const middlePosition = getCanvasMiddlePosition(canvas, contentPosition.x, contentPosition.y, scale)
      const nextPosition = getPositionFromOffset(contentPosition, middlePosition, scale, nextScale, nextBounds)

      pointerRef.current.bounds = nextBounds
      animateContent({ scale: nextScale, ...nextPosition })
    }
  }

  const handleElemChange = (target: HTMLDivElement) => {
    const id = target.dataset.id!
    const x = target.offsetLeft
    const y = target.offsetTop
    const width = target.offsetWidth
    const height = target.offsetHeight

    onElemChange && onElemChange({ id, x, y, width, height })
  }

  const handleElemCreate = (target: HTMLDivElement) => {
    const { elemBounds, currentPage } = pointerRef.current

    if (elemBounds) {
      const isWithinBounds = checkIfElementWithinBounds(target, elemBounds)
      const isCollided = checkElementsCollision(target)

      if (drawingComp && isNumber(currentPage) && isWithinBounds && !isCollided) {
        const id = uid()
        const x = target.offsetLeft
        const y = target.offsetTop
        const width = target.offsetWidth
        const height = target.offsetHeight

        setSelectedElem(id)
        onElemCreate && onElemCreate({ id, type: drawingComp.name, x, y, width, height, page: currentPage! })
      }
    }
  }

  const handlePointerDown = (event: PointerEvent) => {
    if (!canvas || !content) {
      return
    }

    pointerRef.current.events.push(event)
    const { mode, events, contentPosition, scale } = pointerRef.current

    // TODO: find common approach for dragging and panning
    if (events.length === SINGLE_POINTER) {
      if (!readonly && (event.target as HTMLDivElement).dataset.type === PointerModes.DRAW) {
        const type = (event.target as HTMLDivElement).dataset.compType
        pointerRef.current = {
          ...pointerRef.current,
          mode: PointerModes.DRAW,
        }
        if (type) {
          setDrawingComp({ name: type as DrawableCompNames })
        }
        setSelectedElem(null)
      } else if (mode === PointerModes.DRAW) {
        const page = event.target as HTMLDivElement
        const pageNumber = Number(page.dataset.page)

        if (isNumber(pageNumber) && drawingComp) {
          setDrawingComp({ name: drawingComp.name, page: pageNumber })
          pointerRef.current.currentPage = pageNumber

          const drawingCompNode = page.querySelector<HTMLDivElement>(DRAWING_COMP_ATTR)
          const pageOffset = page.getBoundingClientRect()

          if (drawingCompNode) {
            const elemWidth = drawingCompNode.offsetWidth
            const elemHeight = drawingCompNode.offsetHeight
            const coords = getPositionFromPointer(event)

            // First step of drawing
            if (drawingCompNode.offsetLeft === 0 && drawingCompNode.offsetTop === 0) {
              const elemNextX = (coords.x - pageOffset.left) / scale
              const elemNextY = (coords.y - pageOffset.top) / scale

              drawingCompNode.style.left = `${elemNextX}px`
              drawingCompNode.style.top = `${elemNextY}px`

              const bounds = getElemBounds(page, drawingCompNode)

              pointerRef.current = {
                ...pointerRef.current,
                elem: drawingCompNode,
                elemBounds: bounds,
                prevPosition: { x: elemNextX, y: elemNextY },
                prevSize: { width: elemWidth, height: elemHeight },
                startCoords: coords,
              }
            }
          }
        }
      } else if ((event.target as HTMLDivElement).dataset.type === PointerModes.DRAG) {
        setSelectedElem((event.target as HTMLDivElement).dataset.id!)

        if ((event.target as HTMLDivElement).dataset.readonly) {
          return
        }

        const pointer = pointerRef.current
        pointer.elem = event.target as HTMLDivElement
        const elemX = pointer.elem.offsetLeft
        const elemY = pointer.elem.offsetTop
        const coords = getPositionFromPointer(event, {
          x: elemX * scale,
          y: elemY * scale,
        })
        const bounds = getElemBounds(pointer.elem.parentElement as HTMLDivElement, pointer.elem, scale)
        pointerRef.current = {
          ...pointerRef.current,
          mode: PointerModes.DRAG,
          elemBounds: bounds,
          prevPosition: { x: elemX, y: elemY },
          prevSize: { width: pointer.elem.offsetWidth, height: pointer.elem.offsetHeight },
          startCoords: coords,
        }
      } else if ((event.target as HTMLDivElement).dataset.type === PointerModes.RESIZE) {
        const elem = (event.target as HTMLDivElement).parentElement as HTMLDivElement
        const bounds = getElemBounds(elem.parentElement as HTMLDivElement, elem)

        pointerRef.current = {
          ...pointerRef.current,
          mode: PointerModes.RESIZE,
          elem,
          prevPosition: { x: elem.offsetLeft, y: elem.offsetTop },
          prevSize: { width: elem.offsetWidth, height: elem.offsetHeight },
          startCoords: getPositionFromPointer(event),
          elemBounds: bounds,
          resizeDirection: (event.target as HTMLDivElement).dataset.dir as ResizeControls,
        }
      } else if ((event.target as HTMLDivElement).dataset.action) {
        // TODO: find a better way to block panning when element is being deleted
      } else if (
        !(event.target as HTMLElement).closest(DRAWING_SCROLL_IGNORE_ATTR) &&
        canvas.contains(event.target as Node)
      ) {
        cancelAnimation()

        const nextBounds = getBounds(canvas, content, scale)
        const coords = getPositionFromPointer(event, contentPosition)

        pointerRef.current = {
          ...pointerRef.current,
          mode: PointerModes.PAN,
          bounds: nextBounds,
          startCoords: coords,
        }
        setSelectedElem(null)
      } else if (event.target === scrollbar) {
        pointerRef.current = {
          ...pointerRef.current,
          mode: PointerModes.SCROLL,
          startCoords: getPositionFromPointer(event),
        }
      } else {
        setSelectedElem(null)
      }

      // Elem blur detection
      if (selectedElem && onElemBlur) {
        const currentElem = document.querySelector(`[data-id="${selectedElem}"]`)
        if (currentElem && currentElem !== event.target && !currentElem.contains(event.target as Node)) {
          const isSaveDisabled = currentElem.getAttribute(SAVE_DISABLED_ATTR) === 'true'
          onElemBlur(selectedElem, isSaveDisabled)
        }
      }
    } else if (events.length === PINCH_GESTURE) {
      cancelAnimation()

      const distance = getPointersDistance(pointerRef.current.events)

      pointerRef.current = {
        ...pointerRef.current,
        pinchStartDistance: distance,
        pinchStartScale: scale,
      }
    }
  }

  const handlePointerMove = (event: PointerEvent) => {
    event.preventDefault()

    const {
      mode,
      events,
      elem,
      scale,
      startCoords,
      contentPosition,
      prevPosition,
      prevSize,
      bounds,
      elemBounds,
      resizeDirection,
    } = pointerRef.current

    if (mode === PointerModes.DRAW && elem && elemBounds) {
      const position = getPositionFromPointer(event, startCoords)
      const constraints = getElemConstraints(elem)

      const { width, height } = resizeElem(
        prevPosition,
        prevSize,
        position,
        scale,
        elemBounds,
        ResizeControls.BOTTOM_RIGHT,
        constraints
      )

      // Second step of drawing if applicable
      elem.style.width = `${width}px`
      elem.style.height = `${height}px`
    } else if (events.length === SINGLE_POINTER) {
      if (mode === PointerModes.DRAG && elemBounds) {
        event.stopPropagation()

        const rawPosition = getPositionFromPointer(event, startCoords)
        const nextPosition = limitPositionToBounds(rawPosition.x, rawPosition.y, elemBounds)

        if (elem) {
          elem.style.left = `${nextPosition.x / scale}px`
          elem.style.top = `${nextPosition.y / scale}px`
          setMovingElem(elem.dataset.id!)
        }
      } else if (mode === PointerModes.RESIZE && elem && elemBounds) {
        const constraints = getElemConstraints(elem)
        const isHeightConstrained = constraints.includes('height')

        // TODO: move these calculation into a separate function
        const position = getPositionFromPointer(event, startCoords)
        const { x, y, width, height } = resizeElem(
          prevPosition,
          prevSize,
          position,
          scale,
          elemBounds,
          resizeDirection,
          constraints
        )

        elem.style.left = `${x}px`
        elem.style.top = `${y}px`
        elem.style.width = `${width}px`

        if (!isHeightConstrained) {
          elem.style.height = `${height}px`
        }

        setResizingElem(elem.dataset.id!)
      } else if (mode === PointerModes.PAN) {
        event.preventDefault()
        event.stopPropagation()

        const nextPosition = getPositionFromPointer(event, startCoords)

        if (nextPosition.x === contentPosition.x && nextPosition.y === contentPosition.y) {
          return
        }

        pointerRef.current = { ...pointerRef.current, contentPosition: nextPosition }
        transformContent()
      } else if (mode === PointerModes.SCROLL && bounds) {
        const diff = getPositionFromPointer(event, startCoords)

        if (diff.y) {
          const scrollRatio = getScrollRatio(canvas, content, scale)
          const nextY = contentPosition.y - diff.y / scrollRatio
          const nextPosition = limitPositionToBounds(contentPosition.x, nextY, bounds)

          pointerRef.current = { ...pointerRef.current, contentPosition: nextPosition }
          transformContent()
        }

        pointerRef.current.startCoords = getPositionFromPointer(event)
      }
    } else if (events.length === PINCH_GESTURE) {
      pointerRef.current.events = events.map((e) => (e.pointerId === event.pointerId ? event : e))
      handlePinch(pointerRef.current.events)
    }
  }

  const handlePointerUp = () => {
    const { mode, elem, bounds, scale, contentPosition, prevPosition, prevSize } = pointerRef.current

    if ((mode === PointerModes.DRAG || mode === PointerModes.RESIZE) && elem) {
      const isCollided = checkElementsCollision(elem)
      const isChanged = checkIfElementChanged(elem, prevPosition, prevSize)

      if (isCollided) {
        animateElem(prevPosition, prevSize)
      } else if (isChanged) {
        handleElemChange(elem)
      }
      setMovingElem(null)
      setResizingElem(null)
    }
    if (mode === PointerModes.PAN) {
      cancelAnimation()

      if (bounds) {
        const nextPosition = limitPositionToBounds(contentPosition.x, contentPosition.y, bounds)
        const targetState = { scale, ...nextPosition }

        animateContent(targetState)
      }
    }
    if (mode === PointerModes.DRAW && drawingComp) {
      // if elem is not null then the actual drawing has started
      if (elem) {
        const drawingStyle = drawingCompMap[drawingComp.name].style
        if (drawingStyle === 'one-step' || (elem.offsetWidth !== 0 && elem.offsetHeight !== 0)) {
          handleElemCreate(elem)
          exitDrawingMode()
        }
      }
    } else {
      pointerRef.current = {
        ...pointerRef.current,
        mode: null,
        elem: null,
      }
    }

    pointerRef.current.events = []
    handleStopPinch()
  }

  const handlePinch = (events: PointerEvent[]) => {
    const { scale, contentPosition, pinchStartDistance, pinchStartScale } = pointerRef.current

    if (content && canvas && pinchStartDistance && pinchStartScale) {
      const middlePosition = getPointersMiddlePosition(events, scale, content)

      if (!isNumber(middlePosition.x) || !isNumber(middlePosition.y)) {
        return
      }

      const nextScale = getScaleFromPinch(events, pinchStartDistance, pinchStartScale)

      if (nextScale !== null && isNumber(nextScale) && nextScale !== scale) {
        const nextBounds = getBounds(canvas, content, nextScale)
        const nextPosition = getPositionFromOffset(contentPosition, middlePosition, scale, nextScale, nextBounds)

        pointerRef.current = { ...pointerRef.current, contentPosition: nextPosition, scale: nextScale }
        transformContent()
      }
    }
  }

  const handleStopPinch = () => {
    const { scale, contentPosition, pinchStartScale, bounds } = pointerRef.current

    if (isNumber(pinchStartScale) && canvas && content) {
      pointerRef.current = {
        ...pointerRef.current,
        pinchStartScale: null,
        pinchStartDistance: null,
      }

      const correctedScale = getNumberLimitedByRange(scale, MIN_SCALE, MAX_SCALE)

      if (correctedScale !== scale && bounds) {
        const nextBounds = getBounds(canvas, content, correctedScale)
        const middlePosition = getCanvasMiddlePosition(canvas, contentPosition.x, contentPosition.y, scale)
        const nextPosition = getPositionFromOffset(contentPosition, middlePosition, scale, correctedScale, nextBounds)
        const targetState = { scale: correctedScale, ...nextPosition }

        animateContent(targetState)
      }
    }
  }

  const handleWheel = (event: WheelEvent) => {
    if (event.target && (event.target as HTMLElement).closest(DRAWING_SCROLL_IGNORE_ATTR)) {
      return
    }

    event.preventDefault()

    const { scale, contentPosition, bounds } = pointerRef.current

    if ((event.deltaY !== 0 || event.deltaX !== 0) && bounds) {
      if (event.ctrlKey) {
        // TODO: refactor together with onZoom and handlePinch
        const nextScale = getNumberLimitedByRange(event.deltaY * -0.01 + scale, MIN_SCALE, MAX_SCALE)
        const middlePosition = getCanvasMiddlePosition(canvas!, contentPosition.x, contentPosition.y, scale)

        if (nextScale !== null && isNumber(nextScale) && nextScale !== scale) {
          const nextBounds = getBounds(canvas!, content!, nextScale)
          const nextPosition = getPositionFromOffset(contentPosition, middlePosition, scale, nextScale, nextBounds)

          pointerRef.current.bounds = nextBounds
          pointerRef.current = { ...pointerRef.current, scale: nextScale, contentPosition: nextPosition }
          transformContent()
          setZoomIndicator(nextScale)
        }
      } else {
        const deltaX = contentPosition.x + event.deltaX * -1
        const deltaY = contentPosition.y + event.deltaY * -1
        const nextPosition = limitPositionToBounds(deltaX, deltaY, bounds)

        if (contentPosition.x !== nextPosition.x || contentPosition.y !== nextPosition.y) {
          pointerRef.current = { ...pointerRef.current, contentPosition: nextPosition }
          transformContent()
        }
      }
    }
  }

  const scrollToElem = (id: string) => {
    cancelAnimation()

    const { scale } = pointerRef.current
    const elem = document.querySelector<HTMLDivElement>(`[data-id="${id}"]`)
    const page = elem?.parentElement

    if (canvas && page && elem) {
      const pageX = page.offsetLeft * scale
      const pageY = page.offsetTop * scale
      const elemX = (elem.offsetLeft - elem.offsetWidth) * scale
      const elemY = (elem.offsetTop - elem.offsetHeight) * scale

      const nextX = -pageX - elemX
      const nextY = -pageY - elemY

      const nextPosition = limitPositionToBounds(nextX, nextY, pointerRef.current.bounds!)
      animateContent({ ...nextPosition, scale })
    }
  }

  const handleKeyPress = (event: KeyboardEvent) => {
    if (event.keyCode === ESC_KEYCODE) {
      setSelectedElem(null)
      exitDrawingMode()
    }
  }

  useEffect(() => {
    if (canvas && content && scrollbar) {
      window.addEventListener('pointerdown', handlePointerDown)
      window.addEventListener('pointermove', handlePointerMove)
      window.addEventListener('pointerup', handlePointerUp)
      window.addEventListener('pointercancel', handlePointerUp)
      window.addEventListener('keyup', handleKeyPress)

      canvas.addEventListener('wheel', handleWheel)
    }

    return () => {
      window.removeEventListener('pointerdown', handlePointerDown)
      window.removeEventListener('pointermove', handlePointerMove)
      window.removeEventListener('pointerup', handlePointerUp)
      window.removeEventListener('pointercancel', handlePointerUp)
      window.removeEventListener('keyup', handleKeyPress)

      if (canvas) {
        canvas.removeEventListener('wheel', handleWheel)
      }
    }
  }, [
    canvas,
    content,
    scrollbar,
    readonly,
    drawingComp,
    zoomIndicator,
    selectedElem,
    resizingElem,
    movingElem,
    onElemChange,
    onElemCreate,
  ])

  return {
    drawingComp,
    zoomIndicator,
    selectedElem,
    movingElem,
    resizingElem,
    scrollToElem,
    onInit,
    onZoom,
  }
}

export default useViewer
