import { CreateSignatureBody, ValidateSignatureBody, Signature } from '../../types/signatures'
import { VerifyQueryParams } from '../../types/verify'
import { Ajax } from '../ajax'

class SignatureService extends Ajax {
  pathname = 'signature'

  fetchValidateSignature = (body: ValidateSignatureBody) => this.post(`${this.pathname}/validate`, body)

  fetchCreateSignature = (body: CreateSignatureBody, queryParams: VerifyQueryParams) =>
    this.post(`${this.pathname}${body.dynamicSignatures ? '/dynamic' : ''}?verify=${queryParams.verify}`, body)

  fetchSignaturesList = (queryParams: VerifyQueryParams) =>
    this.getJSON<Signature[]>(`${this.pathname}?verify=${queryParams.verify}`)

  fetchDeleteSignature = (id: string, queryParams: VerifyQueryParams) =>
    this.delete(`${this.pathname}/${id}?verify=${queryParams.verify}`)

  fetchDefaultSign = () => this.getJSON<Signature>(`${this.pathname}/default`)
}

export const signatureService = new SignatureService()
