import styled from 'styled-components'

import Box from '../../ui/Box'

export const Wrapper = styled(Box).attrs({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  mt: 26,
})``
