import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { cleanHistory, fetchHistory } from '../../store/contracts/history/history.actions'
import { useDispatchUnmount } from '../useDispatchUnmount'

export const useHistoryFetch = (contractId: string) => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchHistory(contractId))
  }, [contractId])

  useDispatchUnmount(cleanHistory)
}
