import styled from 'styled-components'

import { StyledThemeScheme } from '../../../themes/light'
import Box from '../Box'

export const StyledWrapper = styled.div`
  textarea {
    &::placeholder {
      color: ${({ theme }) => theme.palette.grey};
    }
  }

  // styles for Safari
  @media not all and (min-resolution: 0.001dpcm) {
    strong {
      letter-spacing: -1px;
    }
  }

  // styles for Firefox
  @supports (-moz-appearance: none) {
    strong {
      letter-spacing: -0.3px;
    }
  }
`

export const StyledSuggestion = styled(Box).attrs({
  pt: 1,
  pb: 1,
  pr: 2,
  pl: 2,
  display: 'flex',
  flexDirection: 'column',
})<{ focused: boolean }>`
  ${({ focused, theme }) => (focused ? `background: ${theme.palette.blueExtraLight};` : '')}
  border-bottom: 1px solid ${({ theme }) => theme.palette.strokeGrey};
`

export const getMentionsInputStyles = (theme: StyledThemeScheme) => {
  const fontStyle = {
    fontFamily: theme.textStyles.body14['font-family'],
    fontSize: theme.textStyles.body14['font-size'],
    lineHeight: theme.textStyles.body14['line-height'],
    color: theme.palette.textColor,
  }

  return {
    control: {
      ...fontStyle,
      fontWeight: 'normal',
    },

    '&multiLine': {
      control: {
        ...fontStyle,
      },
      highlighter: {
        ...fontStyle,
        border: 0,
      },
      input: {
        ...fontStyle,
        border: 0,
        outline: 0,
        padding: 0,
      },
    },

    '&singleLine': {
      display: 'inline-block',

      highlighter: {
        ...fontStyle,
        border: 0,
      },
      input: {
        ...fontStyle,
        border: 0,
        outline: 0,
        padding: 0,
      },
    },

    suggestions: {
      list: {
        ...fontStyle,
        overflow: 'auto',
        maxHeight: 150,
      },
      item: {
        ...fontStyle,
      },
    },
  }
}

export const getMentionStyle = (theme: StyledThemeScheme) => {
  const fontStyle = {
    fontFamily: theme.textStyles.body14['font-family'],
    fontSize: theme.textStyles.body14['font-size'],
    lineHeight: theme.textStyles.body14['line-height'],
    color: theme.palette.textColor,
  }

  return {
    ...fontStyle,
    position: 'relative',
    zIndex: 1,
    pointerEvents: 'none',
    fontWeight: 'bold',
  }
}
