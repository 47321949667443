import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { fetchCountries } from '../../store/countries/countries.actions'
import {
  fetchEditProfileInitialData,
  fetchEditProfileUnconfirmedEmail,
  resetEditProfile,
} from '../../store/editProfile/editProfile.actions'
import { fetchIndustries } from '../../store/industries/industries.actions'

export const useEditProfileFetch = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchCountries())
    dispatch(fetchIndustries())
    dispatch(fetchEditProfileUnconfirmedEmail())
    dispatch(fetchEditProfileInitialData())

    return () => {
      dispatch(resetEditProfile())
    }
  }, [])
}
