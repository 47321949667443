import { combineEpics, Epic, ofType } from 'redux-observable'
import { concat, of } from 'rxjs'
import { catchError, map, mergeMap, takeUntil } from 'rxjs/operators'

import { templateService } from '../../../api'
import { catchFetchError } from '../../../utils/catchFetchError'
import { templatesListForceUpdate } from '../list/list.actions'
import { prepareFolderId } from '../list/list.utils'

import * as ACTIONS from './duplicate.actions'

const fetchDuplicate: Epic = (action$) =>
  action$.pipe(
    ofType(ACTIONS.fetchDuplicateTemplateTypes.request),
    mergeMap(({ payload }) =>
      concat(
        templateService
          .fetchDuplicateTemplate(payload.id, {
            ...payload.body,
            folderId: prepareFolderId(payload.body.folderId),
          })
          .pipe(
            map(() => ACTIONS.fetchDuplicateTemplateSuccess()),
            catchError(catchFetchError(ACTIONS.fetchDuplicateTemplateFailure)),
            takeUntil(
              action$.pipe(
                ofType(ACTIONS.fetchDuplicateTemplateTypes.success, ACTIONS.fetchDuplicateTemplateTypes.failure)
              )
            )
          ),
        of(templatesListForceUpdate())
      )
    )
  )

export const duplicateEpics = combineEpics(fetchDuplicate)
