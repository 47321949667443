import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { openModal } from '../../../../store/modals/modals.actions'
import translations from '../../../../translations/keys'
import { location } from '../../../../utils/location'
import { COMMON_MODALS_IDS } from '../../../ui/Modals/CommonModals/CommonModals.constants'
import { NOTIFICATION_MODAL_TYPES } from '../../../ui/Modals/NotificationModal/NotificationModal.constants'

export const useShowNotifications = () => {
  const dispatch = useDispatch()
  const search = useLocation().search
  const params = new URLSearchParams(search)
  const emailVerified = params.get(location.queryParams.EMAIL_VERIFIED)
  const expiredPassword = params.get(location.queryParams.EXPIRED_PASSWORD)
  const emailChanged = params.get(location.queryParams.EMAIL_CHANGED)
  const [showVerifiedBadge, setShowVerifiedBadge] = useState(emailVerified === 'true')

  useEffect(() => {
    if (emailVerified === 'false' || expiredPassword === 'true') {
      dispatch(
        openModal(COMMON_MODALS_IDS.NOTIFICATION, {
          type: NOTIFICATION_MODAL_TYPES.WARNING,
          title: translations.EXPIRED_LINK_MODAL_TITLE,
          description: translations.EXPIRED_LINK_MODAL_DESCRIPTION,
        })
      )
    }
  }, [emailVerified, expiredPassword])

  useEffect(() => {
    if (emailChanged === 'true') {
      dispatch(
        openModal(COMMON_MODALS_IDS.NOTIFICATION, {
          type: NOTIFICATION_MODAL_TYPES.SUCCESS,
          description: translations.NEW_EMAIL_VERIFIED_SUCCESSFULLY,
        })
      )
    }
  }, [emailChanged])

  return { showVerifiedBadge, setShowVerifiedBadge }
}
