import * as yup from 'yup'

import { ConfirmPhoneFields } from '../../types/confirmPhone'
import { phoneValidationSchema } from '../phoneValidationSchema'
import { verificationCodeValidationSchema } from '../verificationCodeValidationSchema'

export const confirmPasswordValidationSchema = yup.object().shape({
  [ConfirmPhoneFields.verificationCode]: verificationCodeValidationSchema,
  ...phoneValidationSchema(ConfirmPhoneFields.phone).fields,
})
