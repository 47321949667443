import styled, { css } from 'styled-components'

import { CardPaymentOption } from '../../../../../types/cards'
import Box from '../../../../ui/Box'

const CARD_WIDTH = '132px'

const visaStyles = css`
  background: linear-gradient(117.53deg, #122241 2.19%, #081327 99.28%),
    linear-gradient(127.83deg, #415f85 -0.97%, #0c2557 92.99%), #324c7e;
  border-radius: 5px;
`

const mastercardStyles = css`
  background: linear-gradient(127.83deg, #415f85 -0.97%, #0c2557 92.99%), #324c7e;
  box-shadow: 0px 2.2px 11px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
`

const amexStyles = css`
  background: linear-gradient(295.98deg, #46648a 3.82%, #6085b3 101.12%);
  box-shadow: 0px 2.2px 11px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
`

const cardStyles = {
  [CardPaymentOption.VISA]: visaStyles,
  [CardPaymentOption.MASTERCARD]: mastercardStyles,
  [CardPaymentOption.AMEX]: amexStyles,
}

export const StyledCard = styled(Box).attrs({
  pt: 2,
  pb: 2,
  pl: 2,
  pr: 2,
  ml: 2,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
})<{ cardType: CardPaymentOption }>`
  font-family: 'CourierPrime';
  font-size: 8.3px;
  line-height: 10px;
  letter-spacing: 0.03em;
  width: ${CARD_WIDTH};
  height: 88px;
  ${({ cardType }) => cardStyles[cardType] || ''}
  color: ${({ theme }) => theme.palette.white};
`

export const StyledCVV = styled(Box).attrs({
  width: CARD_WIDTH,
  ml: 4,
  mt: -2,
})``
