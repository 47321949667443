import React, { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import * as CARDS_SELECTORS from '../../../../../store/subscription/cards/cards.selectors'
import translations from '../../../../../translations/keys'
import { generateCardMask } from '../../../../../utils/cards/generateCardMask'
import { location } from '../../../../../utils/location'
import { useShowFetchError } from '../../../../hooks/useShowFetchError'
import { NOTIFICATION_MODAL_TYPES } from '../../../../ui/Modals/NotificationModal/NotificationModal.constants'
import { NotificationModalView } from '../../../../ui/Modals/NotificationModal/NotificationModalView'

import { SubscriptionUpdatedModalProps } from './SubscriptionUpdatedModal.types'

export const SubscriptionUpdatedModal: React.FC<SubscriptionUpdatedModalProps> = ({ onClose }) => {
  const history = useHistory()
  const { t } = useTranslation()
  const lastAddedCard = useSelector(CARDS_SELECTORS.subscriptionCardsLastAddedCardSelector)
  const setDefaultFetchStatus = useSelector(CARDS_SELECTORS.subscriptionCardsSetDefaultFetchStatusSelector)
  const setDefaultError = useSelector(CARDS_SELECTORS.subscriptionCardsSetDefaultErrorSelector)
  const isSetDefaultSuccess = useSelector(CARDS_SELECTORS.subscriptionCardsSetDefaultSuccessSelector)

  const cardNumber = lastAddedCard ? generateCardMask(lastAddedCard.lastDigits) : ''
  const description = `${t(translations.CARD)} ${cardNumber} ${t(translations.HAS_BEEN_SUCCESSFULLY_SAVED)}`

  const handleClose = useCallback(() => {
    history.push(location.subscriptionViewUrl())
    onClose()
  }, [])

  useEffect(() => {
    if (isSetDefaultSuccess) {
      handleClose()
    }
  }, [handleClose, isSetDefaultSuccess])
  useShowFetchError(setDefaultFetchStatus, setDefaultError)

  return (
    <NotificationModalView
      onClose={handleClose}
      type={NOTIFICATION_MODAL_TYPES.SUCCESS}
      title={t(translations.SUBSCRIPTION_UPDATED_SUCCESSFULLY)}
      description={description}
      cancelButtonText={t(translations.OK)}
      onCancel={handleClose}
    />
  )
}
