import React from 'react'
import styled from 'styled-components'
import { Link as ReactLink } from 'react-router-dom'

import omit from '../../../utils/omit'

import { LinkProps } from './Link.types'

const StyledLink = styled(ReactLink)`
  color: ${(props) => props.theme.palette.blue};
  font-family: ${(props) => props.theme.palette.fontFamily};
  font-size: 0.875rem;
  text-decoration: none;
  outline: 0;

  &:hover {
    text-decoration: underline;
  }
  &:active,
  &:focus {
    color: ${(props) => props.theme.palette.blueExtraDark};
  }
`

export const Link: React.FC<LinkProps> = ({ children, to, ...props }) => {
  const cleanedProps = omit(['active', 'collapsed', 'smallPadding', 'showTriangle', 'compact', 'nested'], props)

  return (
    <StyledLink to={to} {...cleanedProps}>
      {children}
    </StyledLink>
  )
}
