import { Form, Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import ReactTooltip from 'react-tooltip'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import translations from '../../../../../../../translations/keys'
import Typography from '../../../../../../ui/Typography'
import { TYPOGRAPHY_NAMES } from '../../../../../../ui/Typography/Typography.types'
import Box from '../../../../../../ui/Box'
import Button from '../../../../../../ui/Button'
import { BUTTON_VARIANTS } from '../../../../../../ui/Button/Button.types'
import { ChooseSignFields, ChooseSignForm } from '../../../../../../../types/chooseSign'
import FormRadioButton from '../../../../../../ui/Forms/FormRadioButton'
import { cleanSignaturesList, fetchSignaturesList } from '../../../../../../../store/signatures/signatures.actions'
import {
  signaturesListDataSelector,
  signaturesListIsLoadingSelector,
  signaturesListErrorSelector,
  signaturesListFetchStatusSelector,
  signaturesListIsSuccessSelector,
} from '../../../../../../../store/signatures/signatures.selectors'
import { useShowFetchError } from '../../../../../../hooks/useShowFetchError'
import { useDispatchUnmount } from '../../../../../../../hooks/useDispatchUnmount'
import { ChooseSignModalSkeleton } from '../../../../../Skeletons/ChooseSignModalSkeleton'
import { modalsPayloadSelector } from '../../../../../../../store/modals/modals.selectors'
import { CONTRACT_SIGN_MODALS } from '../../../../../../constants/contractSign'
import { getISODate } from '../../../../../../../utils/date/date'
import { useShowNoSignature } from '../../../../../../hooks/signatures/useShowNoSignature'
import { setSignature } from '../../../../../../../store/contracts/signing/signing.actions'

import { StyledCell, StyledImgWrapper, StyledSign } from './SignsForm.styles'
import { SignsFormProps } from './SignsForm.types'

const TOOLTIP = 'tooltip'

export const SignsForm: React.FC<SignsFormProps> = ({ onClose }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [blurredId, setBlurredId] = useState<string | undefined>()
  const loading = useSelector(signaturesListIsLoadingSelector)
  const error = useSelector(signaturesListErrorSelector)
  const fetchStatus = useSelector(signaturesListFetchStatusSelector)
  const isSuccess = useSelector(signaturesListIsSuccessSelector)
  const signatures = useSelector(signaturesListDataSelector)
  const { isInitials, placeId } = useSelector(modalsPayloadSelector(CONTRACT_SIGN_MODALS.CONTRACT_VIEW_CHOOSE_SIGN))
  const { showNoSignatureModal } = useShowNoSignature()

  useEffect(() => {
    dispatch(fetchSignaturesList())
  }, [])
  useEffect(() => {
    if (isSuccess && !signatures.length) {
      showNoSignatureModal()
      onClose()
    }
  }, [isSuccess, signatures, onClose])
  useDispatchUnmount(cleanSignaturesList)
  useShowFetchError(fetchStatus, error)

  if (loading || !signatures.length) {
    return <ChooseSignModalSkeleton />
  }

  const data = signatures.map((item) => ({
    id: item.id,
    imgSrc: isInitials ? item.initials : item.signature,
  }))

  const onShow = (id: string) => () => {
    setBlurredId(id)
  }
  const onSubmit = (values: ChooseSignForm) => {
    const image = data.find((item) => item.id === values.sign)?.imgSrc
    if (image) {
      dispatch(
        setSignature({ placeId, image, signatureId: values.sign, timestamp: getISODate(new Date()), isChanged: true })
      )
      onClose()
    }
  }
  const initialValues: ChooseSignForm = {
    [ChooseSignFields.sign]: data[0].id,
  }
  const renderForm = () => (
    <Form>
      <Box display="flex" flexDirection="column" alignItems="center">
        <Box display="flex">
          {data.map((item, index) => {
            const blurred = item.id !== blurredId
            const label = `${isInitials ? t(translations.INITIALS) : t(translations.SIGNATURE)} ${index + 1}`

            return (
              <StyledCell key={item.id}>
                <FormRadioButton
                  name={ChooseSignFields.sign}
                  label={label}
                  id={item.id}
                  typographyName={TYPOGRAPHY_NAMES.H4}
                />
                <StyledSign onClick={onShow(item.id)}>
                  <StyledImgWrapper
                    data-for={TOOLTIP}
                    data-tip={blurred ? t(translations.CLICK_UNBLUR) : null}
                    blurred={blurred}
                  >
                    <img src={item.imgSrc} alt={label} />
                  </StyledImgWrapper>
                  <Button variant={BUTTON_VARIANTS.INACTIVE} icon="eye" textColor="textColor" type="button">
                    {isInitials ? t(translations.CLICK_TO_SEE_INITIALS) : t(translations.CLICK_TO_SEE_SIGNATURE)}
                  </Button>
                </StyledSign>
              </StyledCell>
            )
          })}
        </Box>
        <Box mt={2} mb={5} textAlign="center">
          {t(translations.MANAGE_SIGNATURE)}
        </Box>
        <Box width={320}>
          <Button type="submit" fullWidth>
            {t(translations.SUBMIT)}
          </Button>
        </Box>
      </Box>
    </Form>
  )

  return (
    <Box display="flex" flexDirection="column" justifyContent="center" textAlign="center">
      <Typography name={TYPOGRAPHY_NAMES.H1}>
        {isInitials ? t(translations.CHOOSE_INITIALS) : t(translations.CHOOSE_SIGNATURE)}
      </Typography>
      <Box mt={2} mb={4.5} textAlign="center">
        <p>{isInitials ? t(translations.SELECT_DOCUMENT_INITIALS) : t(translations.SELECT_DOCUMENT_SIGNATURE)}</p>
        <p>{t(translations.SELECTION_APPLIED_NOTE)}</p>
      </Box>
      <Formik initialValues={initialValues} onSubmit={onSubmit}>
        {renderForm}
      </Formik>
      <ReactTooltip id={TOOLTIP} globalEventOff="click" />
    </Box>
  )
}
