import * as yup from 'yup'

const SPECIAL_CHARS = '!@#$%^&*()\\-_=+\\\\|\\[\\]{};:/?.><,"\'`~ '

export const textValidationSchema = (min: number, max: number, noNumbers = false) => {
  const textRegExp = new RegExp(
    `^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\u0600-\u065F\u066A-\u06EF\u06FA-\u06FF\\${
      noNumbers ? '' : 'd'
    }${SPECIAL_CHARS}]*)$`
  )
  return yup
    .string()
    .nullable()
    .trim()
    .matches(textRegExp, 'INCORRECT_DATA_ENTERED')
    .min(min, 'INCORRECT_DATA_ENTERED')
    .max(max, 'INCORRECT_DATA_ENTERED')
}
