import React from 'react'

import { AmexSvg } from '../../../../assets/AmexSvg'
import { MastercardSvg } from '../../../../assets/MastercardSvg'
import { VisaSvg } from '../../../../assets/VisaSvg'
import Box from '../../../../ui/Box'

export const Logos = () => (
  <Box display="flex" mb={3}>
    <MastercardSvg />
    <Box ml={3} mr={3}>
      <VisaSvg />
    </Box>
    <AmexSvg />
  </Box>
)
