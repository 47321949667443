import React, { forwardRef } from 'react'
import { Formik, FormikProps, FieldArray } from 'formik'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import translations from '../../../../translations/keys'
import { FormikRenderForm } from '../../../../types/formik'
import { AddRecipientsFields, AddRecipientsForm } from '../../../../types/recipients'
import { addRecipientsValidationSchema } from '../../../../validation/addRecipients/addRecipientsValidationSchema'
import { addRecipientsInitialValues, emptyRecipient } from '../../../../initialValues/addRecipientsInitialValues'
import { openModal } from '../../../../store/modals/modals.actions'
import Box from '../../Box'
import { COMMON_MODALS_IDS } from '../../Modals/CommonModals/CommonModals.constants'
import { NOTIFICATION_MODAL_TYPES } from '../../Modals/NotificationModal/NotificationModal.constants'
import { BUTTON_VARIANTS } from '../../Button/Button.types'
import Button from '../../Button'
import FormRecipient from '../../Forms/FormRecipient'
import { prepareAllRecipientsForForm } from '../../../utils/recipients'
import { importContactIntoRecipient, validateRecipients, checkEKYCOption } from '../../../../utils/recipients'
import { Contact } from '../../../../types/contacts'
import { useAccessFeature } from '../../../../hooks/subscription/useAccessFeature'
import { SubscriptionFeatures } from '../../../../types/subscription'

import { Modals } from './Modals/Modals'
import { FormRecipientsProps } from './FormRecipients.types'

const SINGLE_RECIPIENT = 1
const MAXIMUM_RECIPIENTS = 50

export const FormRecipients = forwardRef<FormikProps<AddRecipientsForm>, FormRecipientsProps>(
  ({ recipients, disableOrder = false, templatesMode = false, onSubmit, noDefaultDeadline }, ref) => {
    const dispatch = useDispatch()
    const initialValues =
      recipients.length > 0
        ? { [AddRecipientsFields.recipients]: prepareAllRecipientsForForm(recipients, templatesMode) }
        : addRecipientsInitialValues
    const { checkAccess } = useAccessFeature()
    const { t } = useTranslation()

    const handleSubmit = (values: AddRecipientsForm) => {
      const errorCode = validateRecipients(values.recipients)

      if (errorCode) {
        dispatch(
          openModal(COMMON_MODALS_IDS.NOTIFICATION, {
            type: NOTIFICATION_MODAL_TYPES.WARNING,
            description: translations[errorCode],
          })
        )
        return
      }

      if (!checkEKYCOption(values.recipients) || checkAccess(SubscriptionFeatures.FEATURE_EKYC)) {
        onSubmit(values)
      }
    }

    const renderForm = ({ values, setFieldValue }: FormikRenderForm<AddRecipientsForm>) => {
      const handleSelectContact = (contact: Contact, index?: number) => {
        if (typeof index !== 'undefined') {
          const recipient = values[AddRecipientsFields.recipients][index]
          const newItem = importContactIntoRecipient(recipient, contact)

          setFieldValue(`${AddRecipientsFields.recipients}[${index}]`, newItem)
        }
      }

      return (
        <FieldArray
          name={AddRecipientsFields.recipients}
          render={({ remove, push, form: { setFieldValue } }) => {
            const showRemoveButton = values[AddRecipientsFields.recipients].length !== SINGLE_RECIPIENT

            const handleRemove = (index: number) => {
              remove(index)
            }

            const handleAddRecipient = () => {
              if (values[AddRecipientsFields.recipients].length === MAXIMUM_RECIPIENTS) {
                dispatch(
                  openModal(COMMON_MODALS_IDS.NOTIFICATION, {
                    type: NOTIFICATION_MODAL_TYPES.WARNING,
                    description: translations.RECIPIENTS_MAXIMUM_REACHED,
                  })
                )
              } else {
                push(emptyRecipient)
              }
            }

            return (
              <div>
                {values[AddRecipientsFields.recipients].map((recipient, index) => (
                  <FormRecipient
                    templatesMode={templatesMode}
                    showRemoveButton={showRemoveButton}
                    key={index}
                    index={index}
                    data={recipient}
                    disableOrder={disableOrder}
                    onRemove={handleRemove}
                    setFieldValue={setFieldValue}
                    noDefaultDeadline={noDefaultDeadline}
                  />
                ))}
                <Box width={208}>
                  <Button onClick={handleAddRecipient} variant={BUTTON_VARIANTS.SECONDARY} fullWidth>
                    + {t(translations.ADD_RECIPIENT)}
                  </Button>
                </Box>
                <Modals onSelectContact={handleSelectContact} />
              </div>
            )
          }}
        />
      )
    }

    return (
      <Formik
        innerRef={ref}
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={addRecipientsValidationSchema(templatesMode)}
      >
        {renderForm}
      </Formik>
    )
  }
)
