import React from 'react'
import { useSelector } from 'react-redux'

import { modalsPayloadSelector } from '../../../../store/modals/modals.selectors'
import { COMMON_MODALS_IDS } from '../CommonModals/CommonModals.constants'

import { NotificationModalProps } from './NotificationModal.types'
import { NotificationModalView } from './NotificationModalView'

export const NotificationModal: React.FC<NotificationModalProps> = (props) => {
  const modalData = useSelector(modalsPayloadSelector(COMMON_MODALS_IDS.NOTIFICATION))

  return <NotificationModalView {...props} {...modalData} />
}
