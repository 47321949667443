import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { socketService } from '../../api'
import { SOCKET_CONNECT_ERROR } from '../../constants/errors'
import { SocketEvents } from '../../constants/sockets'
import { authUserIdSelector } from '../../store/auth/auth.selectors'
import {
  addUserNotification,
  fetchUserNotificationsFailure,
} from '../../store/userNotifications/userNotifications.actions'
import translations from '../../translations'
import { UserNotification } from '../../types/userNotifications'

export const useSubscribeOnNotifications = () => {
  const dispatch = useDispatch()
  const [socket, setSocket] = useState<SocketIOClient.Socket | null>(null)
  const userId = useSelector(authUserIdSelector)

  useEffect(() => {
    if (userId && !socket) {
      setSocket(socketService.io())
    }

    if (!userId && socket) {
      socket.close()
      setSocket(null)
    }
  }, [userId, socket])

  useEffect(() => {
    if (socket) {
      const addNotification = (notification: UserNotification) => {
        dispatch(addUserNotification(notification))
      }

      socket.on(`${userId}.${SocketEvents.NOTIFICATION}`, addNotification)
    }
  }, [socket, userId])

  useEffect(() => {
    if (socket) {
      socket.on('connect_error', (error: Error) => {
        console.error(error)
        dispatch(
          fetchUserNotificationsFailure({
            lastErrorCode: SOCKET_CONNECT_ERROR,
            errorCodes: [SOCKET_CONNECT_ERROR],
            errorMessage: translations[SOCKET_CONNECT_ERROR],
          })
        )
      })
    }
  }, [socket])
}
