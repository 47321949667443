import { Formik, FormikProps } from 'formik'
import React, { useRef } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { useTranslation } from 'react-i18next'

import { createContractFromTemplateInitialValues } from '../../../../../initialValues/createContractFromTemplateInitialValues'
import translations from '../../../../../translations/keys'
import {
  CreateContractFromTemplateFields,
  CreateContractFromTemplateForm,
} from '../../../../../types/createContractFromTemplate'
import { ContractSvg } from '../../../../assets/ContractSvg'
import Modal from '../../.././Modal'
import Typography from '../../../../ui/Typography'
import { TYPOGRAPHY_NAMES } from '../../../../ui/Typography/Typography.types'
import Box from '../../../../ui/Box'
import FormDropdown from '../../../../ui/Forms/FormDropdown'
import Button from '../../../../ui/Button'
import { createContractFromTemplateValidationSchema } from '../../../../../validation/templates/createContractFromTemplateValidationSchema'
import { CreateContractFromTemplateModalSkeleton } from '../../../Skeletons/CreateContractFromTemplateModalSkeleton'
import * as BRIEF_TEMPLATES_SELECTORS from '../../../../../store/templates/briefList/briefList.selectors'
import { cleanCreateFromContract } from '../../../../../store/templates/createFromContract/createFromContract.actions'
import { useDispatchUnmount } from '../../../../../hooks/useDispatchUnmount'
import { location } from '../../../../../utils/location'
import { useTemplatesBriefListFetch } from '../../../../../hooks/templates/useTemplatesBriefListFetch'

import { CreateContractFromTemplateModalProps } from './CreateContractFromTemplateModal.types'
import { StyledForm } from './CreateContractFromTemplateModal.styles'

const DROPDOWN_HEIGHT = 100

export const CreateContractFromTemplateModal: React.FC<CreateContractFromTemplateModalProps> = ({ onClose }) => {
  const { t } = useTranslation()
  const history = useHistory()
  const formRef: React.RefObject<FormikProps<CreateContractFromTemplateForm>> = useRef(null)
  const templates = useSelector(BRIEF_TEMPLATES_SELECTORS.briefListDataSelector)
  const templatesIsLoading = useSelector(BRIEF_TEMPLATES_SELECTORS.briefListIsLoadingSelector)

  useTemplatesBriefListFetch()
  useDispatchUnmount(cleanCreateFromContract)

  const onSubmit = (values: CreateContractFromTemplateForm) => {
    onClose()
    history.push(location.templatesFillRecipientsUrl(values.templateId))
  }

  const renderForm = () => (
    <StyledForm>
      <Box mb={3}>
        <FormDropdown
          name={CreateContractFromTemplateFields.templateId}
          label={t(translations.SELECT_TEMPLATE)}
          options={templates}
          maxHeight={DROPDOWN_HEIGHT}
        />
      </Box>
      <Button type="submit" fullWidth>
        {t(translations.CREATE)}
      </Button>
    </StyledForm>
  )

  return (
    <Modal onClose={onClose}>
      <Box mt={3} mb={3} display="flex" flexDirection="column" width={320} alignItems="center">
        <ContractSvg />
        <Box mt={5} mb={3} textAlign="center">
          <Typography name={TYPOGRAPHY_NAMES.H1}>{t(translations.CREATE_CONTRACT_FROM_TEMPLATE)}</Typography>
        </Box>
        {templatesIsLoading ? (
          <CreateContractFromTemplateModalSkeleton />
        ) : (
          <Formik
            innerRef={formRef}
            onSubmit={onSubmit}
            initialValues={createContractFromTemplateInitialValues}
            validationSchema={createContractFromTemplateValidationSchema}
          >
            {renderForm}
          </Formik>
        )}
      </Box>
    </Modal>
  )
}
